import Vue, { PropType } from "vue";
export default Vue.component("MultiSelect", {
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedValues: {
      type: Array as PropType<string[]>,
      required: true,
    },
    isEditable: {
      type: Boolean as PropType<Boolean>,
      required: true,
    },
  },
  data(): {
    isOpen: boolean;
    selectedValues: string[];
  } {
    return {
      isOpen: false,
      selectedValues: [] as string[],
    };
  },
  computed: {
    selectedOptions(): string[] {
      return this.options.filter((option) =>
        this.selectedValues.includes(option)
      );
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    emitSelection() {
      this.$emit("selection-changed", this.selectedValues);
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (!this.$el.contains(target)) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
});
