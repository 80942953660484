// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-a00484e0] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-a00484e0] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-a00484e0] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-a00484e0] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-a00484e0] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-a00484e0] {
    margin: 5px 0 5px 0;
}
.elemSubmenu[data-v-a00484e0] {
    color: #fff;
    margin: 2px;
}
.elemOK[data-v-a00484e0] {
    font-size: 14px;
    text-align: center;
    padding:10px 7px 6px 7px;
    color: #ccc;
    max-width: 120px;
    border-radius: 3px;
    border: 1px solid #00c700;
    margin-left: 12px;
    width: 36px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/tf004_sandprincess/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".sm-game-spec-content[data-v-a00484e0] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-a00484e0] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-a00484e0] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-a00484e0] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-a00484e0] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-a00484e0] {\n    margin: 5px 0 5px 0;\n}\n.elemSubmenu[data-v-a00484e0] {\n    color: #fff;\n    margin: 2px;\n}\n.elemOK[data-v-a00484e0] {\n    font-size: 14px;\n    text-align: center;\n    padding:10px 7px 6px 7px;\n    color: #ccc;\n    max-width: 120px;\n    border-radius: 3px;\n    border: 1px solid #00c700;\n    margin-left: 12px;\n    width: 36px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
