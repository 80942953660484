/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */
/* tslint:disable:radix */
import Vue from "vue";

export const bus = new Vue();

export function TODO(str) {
    trace(`SM - TODO ${str}`, `#ff0000; color: white; font-weight:800;`);
}
export function trace(str, color= "#72d8ff") {
    if (typeof console === "undefined" || typeof console.log === "undefined" ) {
        // console not available
    } else {
        if (typeof str === "string") {
            console.log(`%c SM - ${str}`, `background: ${color}; color: black;`);
        } else {
            console.log(str);
        }
    }
}

export const getAssetsPath = () => window["params"] ? window["params"].assetEndpoint : "";

/*
if index is outside the bounds of the array (either lower than 0, or higher than arr.length-1),
wrap it around, just like in a circular buffer e.g.
let array = [ 0, 1, 2 ]; //length 3, min index: 0, max index: 2
wrapIndex( -1, array ) === 2
wrapIndex( -2, array ) === 1
wrapIndex( -3, array ) === 0
wrapIndex( -4, array ) === 2
wrapIndex( 3, array ) === 0
wrapIndex( 1, array ) === 1
*/
export function wrapIndex(i, arr) {
    const len = arr.length;
    return (i % len + len) % len;
}

export function isNumeric(str) {
    if (str == null) {
        return false;
    }
    const regx = /^[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
    return regx.test(str);
}
export function randomItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}

// Returns an array like: [0,1,2,3,4,5] when range(6)
export function range(a, b: any = null, step: any = null) {
    if (!b && !step) {
        b = a;
        a = 0;
    }

    if (b < a) {
        return range(b + 1, a + 1, step).reverse();
    }

    step = step || 1;

    let x; const r: any = [];

    for (x = a; (b - x) * step > 0; x += step) {
        r.push(x);
    }
    return r;

}
export function randRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randString(howMany, src: any = null) {
    if (src == null)     { src = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; }
    if (typeof howMany === "undefined") { howMany = 5; }
    let text = ""; 
    for (let i = 0; i < howMany; i++ ) {
        text += src.charAt(Math.floor(Math.random() * src.length));
    }
    return text;
}
export function deg2rad(deg) {
    return deg / 180.0 * Math.PI;
}
export function rad2deg(rad) {
    return rad / Math.PI * 180.0;
}

// allows for a promise to be resolve|reject externally
export class PromiseObj {
    public name = "";
    public promise: Promise<{}> = new Promise(() => {});
    public promiseExists: boolean = false;
    public resolve: any = null;
    public reject: any = null;
}
export function setupPromise(obj: PromiseObj, name= "", action: any = null, log = true) {
    obj.name = name;
    obj.promiseExists = true;
    obj.promise = new Promise((resolve, reject) => {
        // create an external resolve so it can be canceled or resolved quickly
        obj.resolve = () => {
            if (obj.promiseExists) {
                if (log) {
                    trace(`Promise ${obj.name} resolved`, "#758eea; color:white;");
                }
                resolve(true); 
                obj.promiseExists = false;
            }
        };
        // create an external reject so it can be canceled or resolved quickly
        obj.reject = () => {
            if (obj.promiseExists) {
                // it resolves to false, so if(!await...) can be used
                resolve(false); 
                obj.promiseExists = false;
            }
        };

        if (action) { action(obj.resolve, obj.reject); }
    });
}

export function compareValues(key, order = "asc") {
  return function(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
        return 0; 
    } 
    const varA = (typeof a[key] === "string") ? 
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === "string") ? 
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return ( (order === "desc") ? (comparison * -1) : comparison );
  };
}

export function createDefaultSlotStep(reelStripPositions, isFeature = false, order = 0, variation = "", name = "Base") {
    return {
        name,
        reelStripPositions,
        isFeature,
        variation, // to be used to set specific variations (?)
    };
}

export function createDefaultBingoStep(ballsDrawn, flambingosLeftHistory)
{
    return {
        ballsDrawn,
        flambingosLeftHistory
    };
}

export function rowsFromIndexes(reelStrips = [], reelStripPositions = [], reels = 5, rows = 3) {
    return range(rows).map(i => range(reels).map(n => reelStrips[n] [wrapIndex(reelStripPositions[n] + i, reelStrips[n])] ) );
}
export function createRandomSlotStep(state, isFeature = false, order = 0, variation = "") {
    const md = state.getModelDefinition();
    const reelstrips = isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
    const nReels = state.getNumberOfReels(); // 5
    const reelStripPositions = range(nReels).map(i => 0); // randRange(0, reelstrips[i].length - 1)); 
    return createDefaultSlotStep(reelStripPositions, isFeature, order, variation);  
}
export function getReelWindowFromIndex(reelStrip: string[] = [], index = 0, amount = 3) {
    return range(amount).map(i => reelStrip[wrapIndex(Number(index) + i, reelStrip)]);
}
export function setOrder(array) {
    array.forEach((it, index) => it.order_number = index );
}
