import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectFeatureSymbols from "./components/selectFeatureSymbols.vue";
import SelectWheelSpinResult from "./components/selectWheelSpinResult.vue";

@Component({
    components: {
        reel: Reel,
        selectFeatureSymbols: SelectFeatureSymbols,
        selectWheelSpinResult: SelectWheelSpinResult,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public reelsNo = 5;
    public isRespin = false;

    public typeOfBaseSpinStep = "";
    public baseReelSet = "";
    public typeOfFreeSpinStep = "";
    public numberOfFreeSpins = 0;
    public initialMultiplier = 0;
    public increaseMultiplier = 0;
    public featureSymbols = new Array(25).fill("None");
    public typeOfRespinStep = "";
    public wheelSpinResult = "";

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ?? false;
        this.typeOfBaseSpinStep = step.json.typeOfBaseSpinStep ?? "";
        this.baseReelSet = step.json.baseReelSet ?? "";
        this.typeOfFreeSpinStep = step.json.typeOfFreeSpinStep ?? "";
        this.numberOfFreeSpins = step.json.numberOfFreeSpins ?? 0;
        this.initialMultiplier = step.json.initialMultiplier ?? 0;
        this.increaseMultiplier = step.json.increaseMultiplier ?? 0;
        if (this.step.featureSymbols)
            this.featureSymbols = this.step.featureSymbols;
        this.isRespin = step.json.isRespin ?? false;
        this.typeOfRespinStep = step.json.typeOfRespinStep ?? "";
        this.wheelSpinResult = step.json.wheelSpinResult ?? "";
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFreeSpinTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinTriggerFromBaseGame());
    }

    public addFreeSpinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinStep());
    }

    public addRespinTrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinTrigger());
    }

    public addRespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStep());
    }

    public addRespinAndFreeSpinTrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinAndFreeSpinTrigger());
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public onChangeBaseSpinStep() {
        this.step.json.typeOfBaseSpinStep = this.typeOfBaseSpinStep;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public isTypeOfBaseSpinStepSelected(typeOfBaseSpinStep: string): boolean {
        if (!this.step || !this.step.json) {
            return false;
        }
        if (!this.typeOfBaseSpinStep) {
            return false;
        }
        return this.step.json.typeOfBaseSpinStep === typeOfBaseSpinStep;
    }

    public onChangeBaseReelSet() {
        this.step.json.baseReelSet = this.baseReelSet;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeFreeSpinStep() {
        this.step.json.typeOfFreeSpinStep = this.typeOfFreeSpinStep;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public isTypeOfFreeSpinStepSelected(typeOfFreeSpinStep: string): boolean {
        if (!this.step || !this.step.json) {
            return false;
        }
        if (!this.typeOfFreeSpinStep) {
            return false;
        }
        return this.step.json.typeOfFreeSpinStep === typeOfFreeSpinStep;
    }

    public updateNumberOfFreeSpins() {
        this.step.json.numberOfFreeSpins = this.numberOfFreeSpins;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public updateInitialMultiplier() {
        this.step.json.initialMultiplier = this.initialMultiplier;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public updateIncreaseMultiplier() {
        this.step.json.increaseMultiplier = this.increaseMultiplier;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateFeatureSymbols(event) {
        if (this.step.json.featureSymbols == null) {
            this.step.json.featureSymbols = new Array(25).fill("None");
        }
        this.step.json.featureSymbols[event.target.id - 1] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeRespinStep() {
        this.step.json.typeOfRespinStep = this.typeOfRespinStep;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public isTypeOfRespinStepSelected(typeOfRespinStep: string): boolean {
        if (!this.step || !this.step.json) {
            return false;
        }
        if (!this.typeOfRespinStep) {
            return false;
        }
        return this.step.json.typeOfRespinStep === typeOfRespinStep;
    }

    public updateWheelSpinResult() {
        this.step.json.wheelSpinResult = this.wheelSpinResult;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onUpdateStep() {
        const currentFeatureSymbols =
            this.step.json.freeSpinSymbols ?? this.step.json.respinSymbols;
        if (
            this.step.json.featureSymbols === undefined &&
            currentFeatureSymbols
        ) {
            let upfeatureSymbols: string[] = [];
            const reelsNum =
                State.state.getModelDefinition().reelsLayout.length;
            const symbolsPerReelNum =
                State.state.getModelDefinition().reelsLayout[0];
            for (let i = 0; i < symbolsPerReelNum; i++) {
                for (let j = 0; j < reelsNum; j++) {
                    upfeatureSymbols = [
                        ...upfeatureSymbols,
                        currentFeatureSymbols[j][i],
                    ];
                }
            }
            this.step.json.featureSymbols = upfeatureSymbols;
        }

        if (this.step.json.featureSymbols != null) {
            this.featureSymbols = this.step.json.featureSymbols;
        } else {
            const defaultSymbolValue = "None";
            const totalSymbolNum =
                State.state.getModelDefinition().totalSymbolNum;
            this.featureSymbols = new Array(totalSymbolNum).fill(
                defaultSymbolValue
            );
        }
    }
}
