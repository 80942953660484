// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol[data-v-12172275] {
  flex-direction: column;

  border-radius: 10px;
  background: #707070;
  box-shadow:
    inset 5px 5px 6px #676767,
    inset -5px -5px 6px #797979;
  position: relative;
}
.wild-modifier[data-v-12172275] {
  margin: 0;
  padding-bottom: 5px;

  font-size: 1em;
  color: white;
  text-align: center;

  background-color: transparent;

  border: none;

  position: absolute;
  bottom: 0;
}
select.wild-modifier[data-v-12172275] {
  transform: translateX(0.5ch);
}
.current-name[data-v-12172275] {
  margin: 0;
  padding: 0;
}
.original-name[data-v-12172275] {
  margin: 0;
  padding: 0;

  font-size: 0.8em;

  color: #dddddd;
}
.wild[data-v-12172275] {
  border-radius: 10px;
  background: linear-gradient(145deg, #656565, #787878);
  box-shadow:
    5px 5px 6px #676767,
    -5px -5px 6px #797979;
}
.new-wild[data-v-12172275] {
  outline: 1px solid gold;
}
.scatter[data-v-12172275] {
  outline: 1px solid lime;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw089/slot-window/reel-symbol/reel-symbol.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;;EAEtB,mBAAmB;EACnB,mBAAmB;EACnB;;+BAE6B;EAC7B,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,mBAAmB;;EAEnB,cAAc;EACd,YAAY;EACZ,kBAAkB;;EAElB,6BAA6B;;EAE7B,YAAY;;EAEZ,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,SAAS;EACT,UAAU;;EAEV,gBAAgB;;EAEhB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,qDAAqD;EACrD;;yBAEuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".symbol[data-v-12172275] {\n  flex-direction: column;\n\n  border-radius: 10px;\n  background: #707070;\n  box-shadow:\n    inset 5px 5px 6px #676767,\n    inset -5px -5px 6px #797979;\n  position: relative;\n}\n.wild-modifier[data-v-12172275] {\n  margin: 0;\n  padding-bottom: 5px;\n\n  font-size: 1em;\n  color: white;\n  text-align: center;\n\n  background-color: transparent;\n\n  border: none;\n\n  position: absolute;\n  bottom: 0;\n}\nselect.wild-modifier[data-v-12172275] {\n  transform: translateX(0.5ch);\n}\n.current-name[data-v-12172275] {\n  margin: 0;\n  padding: 0;\n}\n.original-name[data-v-12172275] {\n  margin: 0;\n  padding: 0;\n\n  font-size: 0.8em;\n\n  color: #dddddd;\n}\n.wild[data-v-12172275] {\n  border-radius: 10px;\n  background: linear-gradient(145deg, #656565, #787878);\n  box-shadow:\n    5px 5px 6px #676767,\n    -5px -5px 6px #797979;\n}\n.new-wild[data-v-12172275] {\n  outline: 1px solid gold;\n}\n.scatter[data-v-12172275] {\n  outline: 1px solid lime;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
