import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public internal_name = "";
    public isOpen = false;
    public showJumboSymbols = false;
    public showJumboFSSymbols = false;
    public showJumboInfo = false;
    public jumboSymbolSet = 0;
    public jumboSymbolFSSet = 0;
    public jumboDimensionSet = 0;
    public jumboColumnSet = 0;
    public jumboPositionSet = 0;

    public selectColumn = [
        {"id":0, "name": "1"},
        {"id":1, "name": "2"},
        {"id":2, "name": "3"},
        {"id":3, "name": "4"},
    ];
    public selectPosition = [
        {"id":0, "name": "Position 1(top)"},
        {"id":1, "name": "Position 2"},
        {"id":2, "name": "Position 3"}
    ]
    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.showJumboSymbols = false;
        this.showJumboFSSymbols = false;
        this.showJumboInfo = false;
        this.jumboSymbolSet = this.step.json.jumboSymbolSet ? this.step.json.jumboSymbolSet : 0;
        this.jumboSymbolFSSet = this.step.json.jumboSymbolFSSet ? this.step.json.jumboSymbolFSSet : 0;
        this.jumboDimensionSet = this.step.json.jumboDimensionSet ? parseInt(this.step.json.jumboDimensionSet.toString()) - 2 : 0;
        this.jumboColumnSet = this.step.json.jumboColumnSet ? parseInt(this.step.json.jumboColumnSet.toString()) : 0;
        this.jumboPositionSet = this.step.json.jumboPositionSet ? parseInt(this.step.json.jumboPositionSet.toString()) : 0;
        if (step.json.internal_name == "Base") {
            this.showJumboSymbols = true;
            if (this.jumboSymbolSet == 0 || this.jumboSymbolSet == 1) {
                this.showJumboInfo = false;
            } else {
                this.showJumboInfo = true;
            }
        }

        if (step.json.internal_name == "FS Step") {
            this.showJumboFSSymbols = true;
            if (this.jumboSymbolFSSet == 0 || this.jumboSymbolFSSet == 1) {
                this.showJumboInfo = false;
            } else {
                this.showJumboInfo = true;
            }
        }
        this.setColumnSelect();
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.reelsNo = 5;
        this.isOpen = false;
        this.showJumboSymbols = false;
        this.showJumboFSSymbols = false;
        this.showJumboInfo = false;
        trace("reels mounted");
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getBasePRStep());
    }

    public viewJumboSymbols() {
        this.showJumboSymbols = true;
        this.showJumboInfo = false;
        this.showJumboFSSymbols = false;
    }
    public viewJumboFSSymbols() {
        this.showJumboSymbols = false;
        this.showJumboInfo = false;
        this.showJumboFSSymbols = true;
    }

    public viewJumboInfo() {
        if (this.jumboSymbolSet == 1) {
            const state = State.state;
            bus.$emit(events.ADD_STEP, state.getBasePRStep());
        } else {
            this.showJumboSymbols = true;
            this.showJumboInfo = true;
        }
    }

    public viewJumboFSInfo() {
        if (this.jumboSymbolFSSet == 1) {
            const state = State.state;
            bus.$emit(events.ADD_STEP, state.getFSPRStep());
        } else {
            this.showJumboFSSymbols = true;
            this.showJumboInfo = true;
        }
    }

    public setColumnSelect(){
        switch (this.jumboDimensionSet.toString()) {
            case "0":
                this.$data.columns = this.selectColumn;
                this.$data.positions = this.selectPosition;
                break;
            case "1":
                this.$data.columns = this.selectColumn.slice(0,-1);
                this.$data.positions = this.selectPosition.slice(0,-1);
                break;
            case "2":
                this.$data.columns = this.selectColumn.slice(0, -2);
                this.$data.positions = this.selectPosition.slice(0, -2);
                break;
        }
    }

    public setJumboSymbols() {
        if(this.jumboSymbolSet && !this.jumboSymbolFSSet) {
            this.addBaseJumboSymbolSet();
        } else {
            this.addFSJumboSymbolSet();
        }
    }

    public addBaseJumboSymbolSet() {
        let jumboSymbolSet = this.jumboSymbolSet ? this.jumboSymbolSet : 0;
        let jumboDimensionSet = this.jumboDimensionSet ? parseInt(this.jumboDimensionSet.toString()) : 0;
        let jumboPositionSet = this.jumboPositionSet ? parseInt(this.jumboPositionSet.toString()) : 0;
        let jumboColumnSet = this.jumboColumnSet ? parseInt(this.jumboColumnSet.toString()) : 0;
        let internal_name = "Base";
        let reels = "reels";
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getJumboSymbolSet(reels, internal_name, jumboSymbolSet, jumboDimensionSet, jumboPositionSet, jumboColumnSet) );
    }

    public addFSJumboSymbolSet() {
        let internal_name = "FS Step";
        let reels = "featureReels";
        let jumboSymbolFSSet = this.jumboSymbolFSSet ? this.jumboSymbolFSSet : 0;
        let jumboDimensionSet = this.jumboDimensionSet ? parseInt(this.jumboDimensionSet.toString()) : 0;
        let jumboPositionSet = this.jumboPositionSet ? parseInt(this.jumboPositionSet.toString()) : 0;
        let jumboColumnSet = this.jumboColumnSet ? parseInt(this.jumboColumnSet.toString()) : 0;
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getJumboSymbolSet(reels, internal_name, jumboSymbolFSSet, jumboDimensionSet, jumboPositionSet, jumboColumnSet) );
    }
    public addFeatureStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }

    public addFeatureTriggerFSStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromFSGameStep() );
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGameStep() );
    }
}
