// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-d42e957e] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-d42e957e] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-d42e957e] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-d42e957e] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-d42e957e] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-d42e957e] {
    margin: 5px 0 5px 0;
}
.jumbo[data-v-d42e957e] {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}
.jumboOK[data-v-d42e957e] {
    font-size: 14px;
    text-align: center;
    padding: 12px 7px 12px 7px;
    color: #fff;
    background-color: #474747;
    max-width: 137px;
    border-radius: 3px;
    margin: 2px;
    width: 36px;
    font-weight: bold;
}
.jumboSymbol[data-v-d42e957e] {
    padding-right: 5px;
    padding-top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/pf005_pugroyale/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,0BAA0B;IAC1B,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".sm-game-spec-content[data-v-d42e957e] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-d42e957e] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-d42e957e] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-d42e957e] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-d42e957e] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-d42e957e] {\n    margin: 5px 0 5px 0;\n}\n.jumbo[data-v-d42e957e] {\n    flex-direction: column;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n.jumboOK[data-v-d42e957e] {\n    font-size: 14px;\n    text-align: center;\n    padding: 12px 7px 12px 7px;\n    color: #fff;\n    background-color: #474747;\n    max-width: 137px;\n    border-radius: 3px;\n    margin: 2px;\n    width: 36px;\n    font-weight: bold;\n}\n.jumboSymbol[data-v-d42e957e] {\n    padding-right: 5px;\n    padding-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
