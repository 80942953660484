export interface StepJson {
    name: string;
    originatorId: number;
    gameState: 'clear' | 'continue';
    reelStripPositions: number[];
    freeSpinsPicked?: number;
    cheeses?: (0 | 1)[][];
    cheeseBlockPrizes?: CheeseBlockPrize[];
}

export type CheeseBlockPrize = 'MINI' | 'MINOR' | 'MAJOR' | 'GRAND' | number;

export function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        originatorId,
        gameState: 'clear',
        reelStripPositions: [0, 0, 0, 0, 0],
    };
}

export function createSpinStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [0, 0, 0, 0, 0],
    };
}

export function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [31, 32, 60, 3, 25],
        freeSpinsPicked: 6,
    };
}

export function createHoldAndSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [34, 11, 27, 11, 24],
    };
}

export const originatorId = new Date().getTime();
