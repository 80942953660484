/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels");
            step.rows = State.state.getModelDefinition().reels;
            step.reelOption = 0;
            step.expandingSymbols = [];
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([35, 0, 11, 3, 11], false, 0, "", "Respin trigger");
            step.reelOption = 0;
            step.expandingSymbols = [];
            return step;
        },
        getRespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Respin step");
            step.isRespin = true;
            step.scattersSelectedMultipliers = [];
            step.reelOption = 0;
            step.expandingSymbols = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().respinNoWinSymbol);
            return step;
        },
        getFreespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Freespin step");
            step.isRespin = false;
            step.isFeature = true;
            step.scattersSelectedMultipliers = [];
            step.reelOption = 0;
            step.expandingSymbols = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().respinNoWinSymbol);
            return step;
        },

        prepareStepForDB: (step) => {
            if (step.isRespin) {
                step.reelStripPositions = [0, 0, 0, 0, 0];
            }

            const scatters = step.scattersSelectedMultipliers;
            if (scatters) {
                step.respinScatterPrizes = [];
                for (let i = 0; i < State.state.getModelDefinition().reelsLayout.length; i++) {
                    step.respinScatterPrizes.push([
                        convertScatterInfo(scatters[i]),
                        convertScatterInfo(scatters[i + 5]),
                        convertScatterInfo(scatters[i + 10]),
                    ]);
                }
            }
            step.scattersSelectedMultipliers = undefined;
            step.rows = undefined;
            step.randoms = undefined;
            return step;
        },

        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Clear");
            step.gameState = "clear";
            return step;
        },
    };
}

function convertScatterInfo(info: string): string | number {
    return isNaN(Number(info)) ? info : Number(info);
}
