var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Base - FS Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Add Free spins Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [_vm._v("FS Wheel result:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.freeSpinWheelResult,
                expression: "freeSpinWheelResult",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "freeSpinWheelResult", id: "freeSpinWheelResult" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.freeSpinWheelResult = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.updateWheelResult,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "0", selected: "" } }, [
              _vm._v("10 spins, x2 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [
              _vm._v("10 spins, x3 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "2" } }, [
              _vm._v("12 spins, x2 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "3" } }, [
              _vm._v("12 spins, x3 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "4" } }, [
              _vm._v("15 spins, x2 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "5" } }, [
              _vm._v("20 spins, x2 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "6" } }, [
              _vm._v("20 spins, x3 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "7" } }, [
              _vm._v("25 spins, x3 multiplier"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "8" } }, [
              _vm._v("30 spins, x3 multiplier"),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }