import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ['index', 'diceValue'],
    data: function() {
        return {
             dice:["Random", "1", "2", "3", "4", "5", "6"]
        }
    }
})
export default class hands extends Vue {
    constructor() {
        super();
    }

    updateDiceValue(card, index, handId) {
        (<Content>this.$parent).updateDiceValue(card, index, handId);
    }
}
