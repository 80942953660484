/* tslint:disable:variable-name */
import State from '../../../state';
import init from './config/init';

export function setStateParams() {
    return {
        variations: [''],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Reels');
            step.rows = State.state.getModelDefinition().reels;
            step.reelSetIndex = 0;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],

        getReelStrips: (index) => {
            switch (index) {
                case 0:
                    return State.state.getModelDefinition().reels;
                    break;
                case 1:
                    return State.state.getModelDefinition().freeSpinExtra5Reels;
                    break;
                case 2:
                    return State.state.getModelDefinition().freeSpinExtra15Reels;
                    break;
                case 3:
                    return State.state.getModelDefinition().freeSpinExtra30Reels;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        getFeatureReelStrips: () => State.state.getModelDefinition().freeSpinExtra5Reels,
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([34, 0, 38, 0, 49], false, 0, '', 'Feature trigger');
            return step;
        },
        getRespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Respin step');
            step.isRespin = true;
            step.scattersSelectedMultipliers = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().emptySymbol);
            return step;
        },
        getMixAndMatchStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Mix and Match step');
            step.isMixAndMatchStep = true;
            step.mixAndMatchPrizes = [];
            step.scattersSelectedMultipliers = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().emptySymbol);
            return step;
        },
        getDummyStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Dummy step');
            return step;
        },
        getRespinTrigger: () => {
            const step = State.state.createStep([19, 0, 14, 0, 0], false, 0, '', 'Respin Trigger');
            step.isRespinTrigger = true;
            step.scattersSelectedMultipliers = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().emptySymbol);
            return step;
        },
        getFreespinExtra5Step: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Extra5');
            step.reelSetIndex = 1;
            step.isFeature = true;
            return step;
        },
        getFreespinExtra15Step: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Extra15');
            step.reelSetIndex = 2;
            step.isFeature = true;
            return step;
        },
        getFreespinExtra30Step: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Extra30');
            step.reelSetIndex = 3;
            step.isFeature = true;
            return step;
        },
        getFreespinSelectStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Freespin select step');
            step.isFreespinSelectStep = true;
            step.scattersSelectedMultipliers = [];
            for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
                step.scattersSelectedMultipliers.push(State.state.getModelDefinition().emptySymbol);
            return step;
        },
        prepareStepForDB: (step) => {
            //if (step.isRespin) {
            //    step.reelStripPositions = [0, 0, 0, 0, 0];
            // }
            //const reelStrips = state.getReelStrips(step.reelSetIndex);

            const scatters = step.scattersSelectedMultipliers;
            if (scatters) {
                step.respinScatterPrizes = [];
                for (let i = 0; i < State.state.getModelDefinition().reelsLayout.length; i++) {
                    step.respinScatterPrizes.push([
                        convertScatterInfo(scatters[i]),
                        convertScatterInfo(scatters[i + 5]),
                        convertScatterInfo(scatters[i + 10]),
                    ]);
                }
            }
            step.scattersSelectedMultipliers = undefined;
            step.rows = undefined;
            step.randoms = undefined;
            step.mixAndMatchTrigger = step.mixAndMatchTrigger == 'true' ? true : false;
            return step;
        },
        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Clear');
            step.gameState = 'clear';
            return step;
        },
    };
}

function convertScatterInfo(info: string): string | number {
    return isNaN(Number(info)) ? info : Number(info);
}
