var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreeGameStep()
              },
            },
          },
          [_vm._v("Free Game")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReSpinInput,
              expression: "showReSpinInput",
            },
          ],
          staticStyle: { margin: "1rem" },
          attrs: { align: "center" },
        },
        [
          _c("div", [
            _vm._v("\n      Respin Count:\n      "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reSpinCount,
                  expression: "reSpinCount",
                },
              ],
              staticClass: "text-input",
              attrs: {
                type: "number",
                min: "0",
                oninput: "this.value = Math.abs(this.value)",
              },
              domProps: { value: _vm.reSpinCount },
              on: {
                change: _vm.onRespinUpdate,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.reSpinCount = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRespinBox,
              expression: "showRespinBox",
            },
          ],
          attrs: { align: "center" },
        },
        [_vm._v("Enter respin values, separated by comma.")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRespinBox,
              expression: "showRespinBox",
            },
          ],
          class: ["flex-center"],
          staticStyle: { margin: "0.5rem" },
        },
        [
          _c(
            "ol",
            _vm._l(parseInt(_vm.reSpinCount), function (respinIndex) {
              return _c(
                "li",
                { key: respinIndex, staticClass: "flex-center" },
                [
                  _c("p", { staticStyle: { margin: "0.5rem" } }, [
                    _vm._v(_vm._s(respinIndex)),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reSpinInput[respinIndex - 1],
                        expression: "reSpinInput[respinIndex - 1]",
                      },
                    ],
                    staticClass: "text-input",
                    staticStyle: { margin: "0.2rem" },
                    attrs: {
                      min: "0",
                      type: "text",
                      placeholder: "0,0,0,0,0",
                      index: respinIndex,
                    },
                    domProps: { value: _vm.reSpinInput[respinIndex - 1] },
                    on: {
                      change: function ($event) {
                        return _vm.updateRespinData(respinIndex)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.reSpinInput,
                          respinIndex - 1,
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "margin-left": "1rem" } }, [
                    _vm._v("Bonus Factor"),
                  ]),
                  _vm._v(" "),
                  _vm._l(2, function (index) {
                    return _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reSpinBF[index - 1][respinIndex - 1],
                            expression: "reSpinBF[index - 1][respinIndex - 1]",
                          },
                        ],
                        key: index,
                        staticStyle: { margin: "0.2rem" },
                        attrs: {
                          name: "bonus",
                          id: "bonus",
                          index: respinIndex,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.reSpinBF[index - 1],
                                respinIndex - 1,
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.updateRespinData(respinIndex)
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.factors, function (factor) {
                        return _c(
                          "option",
                          { key: factor, domProps: { value: factor } },
                          [_vm._v(_vm._s(factor))]
                        )
                      }),
                      0
                    )
                  }),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "margin-left": "1rem" } }, [
                    _vm._v("Enable Factor"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reSpinFactor[respinIndex - 1],
                          expression: "reSpinFactor[respinIndex - 1]",
                        },
                      ],
                      staticStyle: { margin: "0.2rem" },
                      attrs: {
                        name: "factor",
                        id: "factor",
                        index: respinIndex,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.reSpinFactor,
                              respinIndex - 1,
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.updateRespinData(respinIndex)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("true"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("false"),
                      ]),
                    ]
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }