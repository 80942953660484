import { modelDefinition } from '../../../model-definition';

type SlotWindow = string[][];

type SingleWild = [number, number];

export type SingleWildsFeatureResult = {
    slotWindow: SlotWindow;
    singleWilds: SingleWild[];
};

export function playSingleWildsFeature({
    slotWindow,
    singleWilds,
}: {
    slotWindow: SlotWindow;
    singleWilds?: SingleWild[];
}): SingleWildsFeatureResult {
    singleWilds = singleWilds ?? [];
    const validSingleWilds: SingleWild[] = [];
    const newSlotWindow = slotWindow.map((column) => [...column]);
    for (const singleWild of singleWilds) {
        const symbol = newSlotWindow[singleWild[1]][singleWild[0]];
        if (symbol === modelDefinition.wildSymbol || symbol === modelDefinition.scatterSymbol) continue;

        validSingleWilds.push(singleWild);
        newSlotWindow[singleWild[1]][singleWild[0]] = modelDefinition.wildSymbol;
    }

    return {
        slotWindow: newSlotWindow,
        singleWilds: validSingleWilds,
    };
}
