/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "grs001_volatile",

        "wildSymbol": "WI",
        "freeSpinTrigger": "WI",

        "countToTriggerFreeSpin" : 3,
        "freeSpinCount": 10,
        "freeSpinEndingValidationMultiplier": 10,

        "reelsLayout": [3, 3, 3, 3, 3],

        "coinType1Values": [ 100, 500, 2000, 5000, 20000, 50000, 500000 ],
        "coinType4Values": [ 1, 5, 15, 25, 50, 150, 250 ],

        "coinType1_min_bet": "100",
        "coinType1_max_bet": "500000",
        "coinType1_spin_max_bet": "10000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "250",
        "coinType4_spin_max_bet": "5000",

        "reelSetDefinitions": [
            [0, 0, 0, 0, 0],
            [1, 0, 0, 1, 1],
            [0, 0, 1, 0, 0],
            [0, 1, 0, 0, 1],
            [0, 0, 1, 1, 0],
            [1, 1, 1, 0, 1],
            [1, 1, 0, 1, 0],
            [0, 1, 1, 1, 0],
            [2, 2, 2, 2, 2],
            [2, 2, 2, 1, 2],
            [2, 2, 1, 2, 2],
            [2, 1, 2, 2, 2],
            [2, 2, 1, 1, 2],
            [2, 1, 1, 2, 2],
            [2, 1, 2, 1, 2],
            [2, 1, 1, 1, 2]
        ],

         // Dummy data, to be removed.
        "reels":  [
            [ "L5", "L5", "L5", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "L4", "L5", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "L5", "L5", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "L2", "L2", "M3" ],
            [ "L5", "L5", "L5", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "L4", "L5", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "L5", "L5", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "L2", "L2", "M3" ],
            [ "H1", "H1", "H1", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "M2", "M2", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "M1", "M1", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "M3", "M3", "M3" ],
            [ "H1", "H1", "H1", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "M2", "M2", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "M1", "M1", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "M3", "M3", "M3" ],
            [ "H1", "H1", "H1", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "M2", "M2", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "M1", "M1", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "M3", "M3", "M3" ]
        ],

        "reelsColumns": [
            [
                [ "L5", "L5", "L5", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "L4", "L5", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "L5", "L5", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "L2", "L2", "M3" ],
                [ "L5", "L5", "L5", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "L4", "L5", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "L5", "L5", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "L2", "L2", "M3" ],
                [ "H1", "H1", "H1", "L4", "L4", "L5", "L1", "M3", "L5", "M2", "M2", "M2", "L2", "L2", "L5", "L4", "L4", "L5", "L3", "M1", "M1", "M1", "L5", "L4", "L4", "L5", "L4", "L5", "L5", "M1", "L2", "L5", "L2", "L5", "L5", "H1", "L2", "L2", "L3", "L3", "L2", "L4", "M2", "L2", "L1", "L4", "L2", "M3", "M3", "M3" ]
            ],
            [
                [ "M3", "L1", "L1", "L1", "L2", "L1", "L5", "L1", "M2", "L5", "L5", "L5", "M3", "L4", "L2", "L3", "L1", "L3", "L1", "L3", "L1", "L1", "L3", "L3", "M2", "L3", "L1", "L3", "L1", "L4", "L3", "L3", "L3", "L5", "L5", "L4", "L5", "M1", "L3", "L3", "L5", "H1", "L3", "L5", "L3", "L5", "M3", "L3", "L3", "L3" ],
                [ "M3", "L1", "L1", "L1", "L2", "L1", "L5", "H1", "WI", "H1", "L5", "L5", "M3", "L4", "L2", "L3", "L1", "L3", "L1", "L3", "L1", "L1", "L3", "L3", "WI", "L3", "L1", "L3", "L1", "L4", "L3", "L3", "L3", "L5", "L5", "L4", "L5", "WI", "L3", "L3", "L5", "WI", "L3", "L5", "L3", "L5", "WI", "L3", "L3", "L3" ],
                [ "M3", "H1", "H1", "H1", "L2", "L1", "L5", "L1", "M2", "M2", "M2", "L5", "M3", "M3", "M3", "L3", "L1", "L3", "L1", "L3", "L1", "L1", "M3", "M3", "M3", "L3", "L1", "L3", "L1", "L4", "L3", "L3", "L3", "L5", "L5", "L4", "L5", "M1", "M1", "M1", "L5", "H1", "L3", "L5", "L3", "L5", "M3", "L3", "L3", "L3" ]
            ],
            [
                [ "L4", "L4", "L4", "L2", "L2", "L4", "L1", "L4", "M2", "L5", "L4", "L4", "L4", "L3", "L3", "L4", "M3", "L4", "L5", "L3", "L4", "L4", "M1", "L2", "L4", "M2", "L4", "L3", "L3", "L3", "L4", "M1", "L4", "L4", "L3", "H1", "L3", "L2", "L2", "M3", "L2", "L1", "L3", "L2", "L2", "L5", "L2", "L2", "L2", "L3" ],
                [ "L4", "L4", "L4", "L2", "L2", "L4", "L1", "H1", "WI", "H1", "L4", "L4", "L4", "L3", "L3", "L4", "M3", "L4", "L5", "L3", "L4", "L4", "WI", "L2", "L4", "WI", "L4", "L3", "L3", "L3", "L4", "M1", "L4", "L4", "H1", "WI", "H1", "L2", "L2", "M3", "L2", "L1", "L3", "L2", "L2", "L5", "L2", "L2", "L2", "L3" ],
                [ "M2", "M2", "M2", "L2", "L2", "L4", "L1", "L4", "M2", "M2", "M2", "L4", "L4", "L3", "M3", "M3", "M3", "L4", "L5", "L3", "M1", "M1", "M1", "L2", "L4", "M2", "L4", "L3", "L3", "L3", "L4", "M1", "M1", "M1", "L3", "H1", "H1", "H1", "L2", "M3", "L2", "L1", "L3", "L2", "L2", "L5", "L2", "L2", "L2", "L3" ]
            ],
            [
                [ "L1", "L1", "L1", "L4", "L4", "L1", "M1", "L1", "L3", "L3", "L1", "L1", "L5", "M3", "L5", "L3", "L1", "L1", "L1", "L5", "L1", "M2", "L4", "L4", "L4", "L3", "L5", "L4", "L2", "L4", "L5", "L3", "H1", "L3", "L2", "L4", "L4", "M3", "L4", "L3", "M2", "L5", "L5", "L3", "L3", "L3", "M3", "L5", "L5", "L5" ],
                [ "L1", "L1", "L1", "L4", "L4", "H1", "WI", "H1", "L3", "L3", "L1", "L1", "L5", "WI", "L5", "L3", "L1", "L1", "L1", "L5", "L1", "WI", "L4", "L4", "L4", "L3", "L5", "L4", "L2", "L4", "L5", "L3", "WI", "L3", "L2", "L4", "L4", "WI", "L4", "L3", "WI", "L5", "L5", "L3", "L3", "L3", "M3", "L5", "L5", "L5" ],
                [ "L1", "L1", "L1", "L4", "L4", "L1", "M1", "M1", "M1", "L3", "L1", "L1", "M3", "M3", "M3", "L3", "L1", "L1", "L1", "L5", "L1", "M2", "M2", "M2", "L4", "L3", "L5", "L4", "L2", "L4", "H1", "H1", "H1", "L3", "L2", "L4", "L4", "M3", "L4", "L3", "M2", "L5", "L5", "L3", "L3", "L3", "M3", "M3", "M3", "L5" ]
            ],
            [
                [ "L5", "L5", "L5", "M2", "L3", "L5", "L2", "L5", "L2", "L1", "L5", "L5", "M1", "M3", "L5", "L3", "L2", "L5", "L1", "L1", "L1", "L3", "L5", "M2", "L3", "L3", "L3", "L4", "M3", "L1", "L4", "L1", "L4", "L3", "L2", "L2", "L2", "L4", "L3", "L4", "L3", "H1", "L4", "L4", "L3", "L3", "L4", "L4", "L4", "M1" ],
                [ "L5", "L5", "L5", "M2", "L3", "L5", "L2", "L5", "L2", "L1", "L5", "L5", "M1", "M3", "L5", "L3", "L2", "L5", "L1", "L1", "L1", "L3", "L5", "M2", "L3", "L3", "L3", "L4", "M3", "L1", "L4", "L1", "L4", "L3", "L2", "L2", "L2", "L4", "L3", "L4", "L3", "H1", "L4", "L4", "L3", "L3", "L4", "L4", "L4", "M1" ],
                [ "L5", "L5", "M2", "M2", "M2", "L5", "L2", "L5", "L2", "L1", "L5", "L5", "M1", "M3", "M3", "M3", "L2", "L5", "L1", "L1", "L1", "L3", "M2", "M2", "M2", "L3", "L3", "L4", "M3", "M3", "M3", "L1", "L4", "L3", "L2", "L2", "L2", "L4", "L3", "L4", "L3", "H1", "H1", "H1", "L3", "L3", "L4", "M1", "M1", "M1" ]
            ]
        ],

        "baseReelSetWeights": [ 75, 30, 30, 30, 10, 10, 10, 45, 20, 10, 10, 10, 5, 5, 5, 18],
        "featureReelSetWeights": [0, 0, 0, 0, 0, 0, 0, 25, 0, 0, 0, 0, 0, 0, 0, 15],

        "baseWildMultipliers": [10, 6, 3, 1],
        "featureWildMultipliers": [0, 20, 15, 10],

        "winTable": [
            {
                "symbol": "H1",
                "multipliers": [400, 80, 40, 8]
            }, {
                "symbol": "M1",
                "multipliers": [150, 40, 20, 4]
            }, {
                "symbol": "M2",
                "multipliers": [120, 30, 10, 4]
            }, {
                "symbol": "M3",
                "multipliers": [110, 25, 8, 4]
            }, {
                "symbol": "L1",
                "multipliers": [100, 20, 5]
            },{
                "symbol": "L2",
                "multipliers": [80, 15, 5]
            }, {
                "symbol": "L3",
                "multipliers": [60, 10, 4]
            }, {
                "symbol": "L4",
                "multipliers": [60, 10, 4]
            }, {
                "symbol": "L5",
                "multipliers": [60, 10, 4]
            }
        ],

        "playLines": [
            [1,	1, 1, 1, 1],
            [0,	0, 0, 0, 0],
            [2,	2, 2, 2, 2],
            [0,	1, 2, 1, 0],
            [2,	1, 0, 1, 2],
            [1,	0, 0, 0, 1],
            [1,	2, 2, 2, 1],
            [1,	0, 1, 2, 1],
            [0,	2, 0, 2, 0],
            [2,	0, 2, 0, 2]
        ]
    },
}
