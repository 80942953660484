/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.extraReels = 0;
            step.levelNo = -1;
            step.reelsLayout = [4,4,4];
            step.reelIds = [0,1,2];
            step.scattersSelectedMultipliers = [];
            for (let i=0;i<12;i++)
                step.scattersSelectedMultipliers.push("Random");
            return step;
        },
        getBaseStep: function(stepReelsPos) {
            const name = "Base";
            const step = State.state.createStep([0, 0, 0], false, 0, "", name);
            step.reelsType = "reels";
            step.internal_name = name;
            step.levelNo = -1;
            step.extraReels = 0;
            step.reelsLayout = [4,4,4];
            step.reelIds = [0,1,2];
            step.scattersSelectedMultipliers = [];
            for (let i=0;i<12;i++)
                step.scattersSelectedMultipliers.push("Random");
            return step;
        },
        getClearingStep: function() {
            const step = State.state.createStep([0, 0, 0], false, 0, "", "Clear");
            step.gameState = 'clear';
            return step;
        },

        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getNewModelDefinition: () => State.state.getModelDefinition(),
        getReelStrips: () => State.state.getModelDefinition().reels, 
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,

        // example on creating custom indexes
        getRespinTriggerStep (stepTitle, reelsType) {
            const step = State.state.createStep([14, 64, 28], false, 0, "", stepTitle);
            step.reelsType = reelsType;
            step.reelIds = [0,1,2];
            step.levelNo = -1;
            step.internal_name = "Respin Trigger";
            step.scattersSelectedMultipliers = [];
            for (let i=0;i<12;i++)
                step.scattersSelectedMultipliers.push("Random");
            return step;
        },
        getRespinStep (stepTitle, reelsType) {
            const step = State.state.createStep([0, 0, 0], true, 0, "", stepTitle);
            step.internal_name = stepTitle;
            step.reelIds = [0,1,2];
            step.levelNo = -1;
            step.reelsType = reelsType;
            step.isFeature = true;
            step.scattersSelectedMultipliers = [];
            for (let i=0;i<12;i++)
                step.scattersSelectedMultipliers.push("Random");
            step.internal_name = "Respin Step";
            return step;
        },

        prepareStepForDB: (step) => {

            console.log( "prepareStepForDB tf108" );

            const state = State.state;

            let reelstrip_pos_list = step.reelStripPositions;
            let reel_id_list = step.reelIds;

            const oldReelsstripPos = JSON.parse(JSON.stringify(reelstrip_pos_list));
            const reelsNum = step.extraReels + 3;
            const posLen = reelstrip_pos_list.length;

            if ( reelsNum < oldReelsstripPos.length )
            {
                console.log( "remove reel " + reelsNum );
                reelstrip_pos_list = reelstrip_pos_list.slice(0, reelsNum);
                reel_id_list = reel_id_list.slice(0, reelsNum);
            }
            else
            {
                const newLength = reelsNum - posLen;
                for(let i=0; i < newLength; i++)
                {
                    reelstrip_pos_list[reelstrip_pos_list.length] = 0;
                    reel_id_list[reel_id_list.length] = 3;
                }
            }

            step.reelStripPositions = reelstrip_pos_list;
            step.reelIds = reel_id_list;
            state.getNewModelDefinition().reelStripPositions = step.reelStripPositions;
            state.getNewModelDefinition().reelIds = step.reelIds;
            return step;

        }
    };
}
