import type { Choices } from "../../choices";
import { modelDefinition } from "../../model-definition";
import { createWinsEvaluator } from "./create-wins-evaluator";
import type { GameRequest } from "./game-request";
import type { SpinOutcome } from "./outcome";
import { getWinType } from "./get-win-type";
import { createLampsSlotWindowGenerator } from "../../create-lamps-slot-window-generator";
import type {
    CoinPrize,
    CoinPrizes,
    FeatureColour,
    GameState,
    Position,
    GenieBonusLamps,
    GenieBonusLamp,
} from "../shared";

import { filterSlotWindow } from "@vgw/gdk-math-model-tools";
import { applyGenieFeatures } from "./genie-bonus/apply-genie-features";
import { getAvailablePositions } from "./get-available-positions";
import {getWinCells} from "./get-win-cells";

export function spinBase({ gameRequest, coinType }: BaseSpinInputs, choices: Choices): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;
    const coinWeight = coinAmount * (coinType === 'GC' ? 0.05 : 1);

    // Generate Slot Window
    const reelStripPositions = choices.base.chooseReelStripPositions();
    const mysterySymbol = choices.base.chooseMysterySymbol();
    const slotWindow = generateSlotWindow(reelStripPositions, mysterySymbol);

    // Evaluate line wins
    const winInfo = evaluateWins({ slotWindow, coinAmount, playAmount });
    const winAmount = winInfo.winAmount;

    const scatters: Position[] = filterSlotWindow(slotWindow, (symbol) => symbol === modelDefinition.scatterSymbol);
    const genieBonusTriggers = choices.genie.chooseTriggerCount(scatters);
    const genieBonusColours = choices.genie.chooseFeatureColour(genieBonusTriggers);

    const winType = getWinType({
        winAmount,
        isGenieBonusTrigger: genieBonusColours.length !== 0,
    });

    const genieBonusPhase = winType === 'SCATTER_WIN' ? 'START' : undefined;
    const genieBonusCount = genieBonusPhase === 'START' ? 3 : 0;

    const isGenieBonusTriggered = genieBonusPhase !== undefined;

    let coinPrizes: CoinPrizes | undefined = undefined;
    let genieBonusLamps: (GenieBonusLamp & { isTrigger: boolean })[] | undefined = undefined;

    let genieFeatureProperties:
        | {
              coinPrizes: CoinPrizes;
              genieBonusLamps: (GenieBonusLamp & { isTrigger: boolean })[];
              hardPosition: Position;
          }
        | undefined;

    if (isGenieBonusTriggered) {
        const scattersWithColors: { colour: FeatureColour; position: Position }[] = assignColorsToScatters(
            genieBonusColours,
            scatters,
            choices,
        );
        genieBonusLamps = identifyTriggersAndNonTriggers(scattersWithColors, genieBonusColours);

        const triggeringFeatures = genieBonusLamps.filter((coin) => coin.isTrigger);
        coinPrizes = getCoinPrizes(triggeringFeatures, choices, coinType, coinAmount);

        applyGenieFeatures({
            genieBonusFeatureColours: triggeringFeatures.map((feature) => {
                return {
                    position: feature.position,
                    colour: feature.colour,
                };
            }),
            coinPrizes,
            choices,
            coinAmount,
            coinWeight,
        });

        const availablePositions = getAvailablePositions(slotWindow, [
            ...coinPrizes.map((feature) => feature.position),
        ]);
        genieFeatureProperties = {
            hardPosition: choices.genie.chooseHardPosition({ availablePositions }),
            coinPrizes,
            genieBonusLamps,
        };
    }

    const winCells = getWinCells({
        lineWins: winInfo.lineWins,
        playLines: modelDefinition.base.playLines,
    });
    const roundComplete = !isGenieBonusTriggered;

    const cumulativeWinAmount = roundComplete ? 0 : winAmount;

    const gameState: GameState | undefined =
        !roundComplete && genieFeatureProperties
            ? {
                  coinAmount,
                  reelStripPositions,
                  slotWindow,
                  mysterySymbol,
                  genieBonusPhase,
                  genieBonusCount,
                  cumulativeWinAmount,
                  coinPrizes: genieFeatureProperties.coinPrizes,
                  hardPosition: genieFeatureProperties.hardPosition,
                  genieBonusColours,
              }
            : undefined;

    return {
        playSummary: {
            playAmount,
            winAmount,
            roundComplete,
        },
        gameResponse: {
            playAmount,
            winAmount,
            winCells,
            cumulativeWinAmount,
            winType,
            reelStripPositions,
            slotWindow,
            genieBonusPhase,
            genieBonusCount,
            coinAmount,
            coinPrizes,
            lineWins: winInfo.lineWins,
            scatterWin: winInfo.scatterWin,
            genieBonusLamps,
            mysterySymbol,
        },
        gameState,
    };
}

export const identifyTriggersAndNonTriggers = (
    featuresAndNonTriggeringScats: Array<{ position: Position; colour: FeatureColour }>,
    genieBonusColours: FeatureColour[],
): Array<GenieBonusLamp & { isTrigger: boolean }> => {
    genieBonusColours = [...genieBonusColours];
    const triggersAndNonTriggers = featuresAndNonTriggeringScats.reverse().map((scat) => {
        const nonTrigger = {
            position: scat.position,
            colour: scat.colour,
            isTrigger: false,
        };
        const colourIndex = genieBonusColours.findIndex((colour) => colour === scat.colour);
        if (colourIndex === -1) { return nonTrigger; }

        const trigger = {
            colour: scat.colour,
            position: scat.position,
            isTrigger: true,
        };
        genieBonusColours.splice(colourIndex, 1);
        return trigger;
    });
    triggersAndNonTriggers.sort((a, b) => {
        return b.position[1] - a.position[1];
    });
    return triggersAndNonTriggers;
};

export interface BaseSpinInputs {
    gameRequest: GameRequest;
    coinType: "GC" | "SC";
}

const evaluateWins = createWinsEvaluator({
    ...modelDefinition,
    ...modelDefinition.base,
});
const generateSlotWindow = createLampsSlotWindowGenerator(modelDefinition.base);

export const getCoinPrizes = (
    triggeringCoinPrizes: GenieBonusLamps,
    choices: Choices,
    coinType: "GC" | "SC",
    coinAmount: number,
): CoinPrizes => {
    const newCoinPrizes: CoinPrizes = [];
    const MAX_COINS = modelDefinition.triggerCoinsCount;
    const positions: Position[] = new Array(5)
        .fill(undefined)
        .map((_, index) => [
            [0, index] as [number, number],
            [1, index] as [number, number],
            [2, index] as [number, number],
        ])
        .flat();

    const availablePositions: Position[] = positions.filter(
        (position) =>
            !triggeringCoinPrizes.find(
                (prize) => prize.position[0] === position[0] && prize.position[1] === position[1],
            ),
    );
    const coinWeight = coinAmount * (coinType === "GC" ? 0.05 : 1);

    for (let i = 0; i < MAX_COINS; i++) {
        const randomPositionIndex = choices.genie.chooseRandomPosition(availablePositions, i);
        const position = availablePositions[randomPositionIndex];
        const positionChosen: [number, number] = [position[0], position[1]];
        availablePositions.splice(randomPositionIndex, 1);
        const coinPrizeType = choices.genie.chooseTriggeringCoinPrize({
            coinWeight,
            position: positionChosen,
        });
        let coinPrize: CoinPrize | undefined;

        if (typeof coinPrizeType === "number") {
            const winAmount = coinPrizeType * coinAmount;
            coinPrize = {
                type: "COIN",
                value: winAmount,
                position: positionChosen,
            };
        } else if (coinPrizeType === "JACKPOT_MINI" || coinPrizeType === "JACKPOT_MINOR") {
            const fixedjackpotKey = coinPrizeType === "JACKPOT_MINI" ? "MINI" : "MINOR";
            const winAmount = modelDefinition.jackpots.fixedJackpots[fixedjackpotKey] * coinAmount;
            coinPrize = {
                type: coinPrizeType,
                value: winAmount,
                position: positionChosen,
            };
        } else {
            coinPrize = {
                type: coinPrizeType,
                position: positionChosen,
            };
        }

        newCoinPrizes.push(coinPrize);
    }
    return newCoinPrizes;
};

export const assignColorsToScatters = (
    triggeringColors: FeatureColour[],
    scatterPositions: Position[],
    choices: Choices,
): Array<{ colour: FeatureColour; position: Position }> => {
    const allColours: FeatureColour[] = ["G", "P", "R"];
    const featuresAndNonTriggerringScats: Array<{ colour: FeatureColour; position: Position }> = [];

    triggeringColors.forEach((triggeringColour) => {
        const randomScatterIndex = choices.genie.chooseRandomScatterPosition({
            array: scatterPositions,
            triggeringColour,
        });
        const coinPrize: { colour: FeatureColour; position: Position } = {
            colour: triggeringColour,
            position: scatterPositions[randomScatterIndex],
        };
        featuresAndNonTriggerringScats.push(coinPrize);
        scatterPositions.splice(randomScatterIndex, 1);
    });

    scatterPositions.forEach((position) => {
        const randomIndex = choices.genie.chooseRandomColorPosition({
            array: allColours,
            position,
            isFeature: false,
        });
        if (randomIndex > -1) {
            const colour = allColours[randomIndex];

            const nonScat: { colour: FeatureColour; position: Position } = {
                colour,
                position,
            };
            featuresAndNonTriggerringScats.push(nonScat);
        }
    });

    return featuresAndNonTriggerringScats;
};
