// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-82ade2c4] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-82ade2c4] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-82ade2c4] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-82ade2c4] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-82ade2c4] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-82ade2c4] {
    margin: 5px 0 5px 0;
}
.carrots-selector-wrapper[data-v-82ade2c4] {
    position: relative;
}
.carrots-selector[data-v-82ade2c4] {
    padding: 20px;
    margin-bottom: 30px;
}
.carrots-selector select[data-v-82ade2c4] {
    background-color: #2A2A2A;
    height: 29px;
    overflow: hidden;
    color: gray;
    top: -8px;
    position: relative;
    margin-left: 10px;
    width: auto;
    border: 1px solid grey;
    border-radius: 5px;
}
.selected-carrots[data-v-82ade2c4] {
    position: absolute;
    top: 50px;
    margin-left: 18px;
}
.selected-carrot[data-v-82ade2c4] {
    float: left;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/crazybunny/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".sm-game-spec-content[data-v-82ade2c4] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-82ade2c4] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-82ade2c4] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-82ade2c4] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-82ade2c4] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-82ade2c4] {\n    margin: 5px 0 5px 0;\n}\n.carrots-selector-wrapper[data-v-82ade2c4] {\n    position: relative;\n}\n.carrots-selector[data-v-82ade2c4] {\n    padding: 20px;\n    margin-bottom: 30px;\n}\n.carrots-selector select[data-v-82ade2c4] {\n    background-color: #2A2A2A;\n    height: 29px;\n    overflow: hidden;\n    color: gray;\n    top: -8px;\n    position: relative;\n    margin-left: 10px;\n    width: auto;\n    border: 1px solid grey;\n    border-radius: 5px;\n}\n.selected-carrots[data-v-82ade2c4] {\n    position: absolute;\n    top: 50px;\n    margin-left: 18px;\n}\n.selected-carrot[data-v-82ade2c4] {\n    float: left;\n    display: inline-block;\n    margin-right: 10px;\n    margin-left: 10px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
