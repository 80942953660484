// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-wheel-pay[data-v-02f8383e] {
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw065/components/wheelPrize.vue.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".bonus-wheel-pay[data-v-02f8383e] {\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
