import { WinType } from "../../../v3/math-model";

export function getWinType(info: {
  totalWinAmount: number;
  isScatterWin: boolean;
  isFreeSpinTrigger: boolean;
  isFreeSpin: boolean;
  isJackpotTrigger: boolean;
}): WinType {
  if (info.isJackpotTrigger) return "JACKPOT_WIN";
  if (info.isFreeSpinTrigger) return "BONUS_WIN";
  if (info.isScatterWin) return "SCATTER_WIN";

  if (info.isFreeSpin) {
    return info.totalWinAmount > 0 ? "FREE_SPIN_WIN" : "FREE_SPIN_NO_WIN";
  }
  return info.totalWinAmount > 0 ? "NORMAL_WIN" : "NO_WIN";
}
