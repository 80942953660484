const winLengths = [2, 3, 4, 5];

export function createGetWinCells(playLines: number[][]) {
  const winCellsByLineAndLength = precomputeAllWinCells(playLines);

  return function (playLineIndex: number, winLength: number) {
    return winCellsByLineAndLength[playLineIndex][winLength];
  };
}

function precomputeAllWinCells(playLines: number[][]) {
  const winCellsByLineAndLength: [row: number, col: number][][][] = [];
  const playLineIndices = playLines.map((_, i) => i);

  for (const playLineIndex of playLineIndices) {
    winCellsByLineAndLength[playLineIndex] = [];
    const playLine = playLines[playLineIndex];
    for (const winLength of winLengths) {
      const winCells = playLine
        .slice(0, winLength)
        .map((row, col): [row: number, col: number] => [row, col]);
      winCellsByLineAndLength[playLineIndex][winLength] = winCells;
    }
  }
  return winCellsByLineAndLength;
}
