import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public isJackpot = false;
    public jackpotType = "MAJOR"; // GRAND|MAJOR|MINI
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.jackpotType = step.json.jackpotType || "GRAND";
        this.isJackpot = this.isFeature && State.state.isStepAJackpot(step.json);
    }
    public mounted() { 
        trace("reels mounted");

        bus.$on("tbg-update-step-from-reel", (step) => this.updateStepOnDb(step)); 
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        this.updateStepOnDb(this.step);
    }
    public doChangeJackpotType() {
        this.step.json.jackpotType = this.jackpotType;
        this.updateStepOnDb(this.step);
    }
    public updateStepOnDb(step) {
        this.isJackpot = this.isFeature && State.state.isStepAJackpot(step.json); 
        bus.$emit(events.UPDATE_STEPS_DB, step);
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep() );
    }
}
