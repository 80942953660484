import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import { findWildPics } from './math-model';
import Chests from './chests.vue';
import Reel from './reel.vue';
import {
    createClearingStep,
    createSpinStep,
    createFreeSpinTriggerStep,
    createFireshotTriggerStep,
    originatorId,
    modelDefinition,
    StepJson,
    CoinPrize,
    WildPic,
    ChestPrize,
} from '../../../../state/models/slots/vgw096';
import { getReelWindowFromIndex } from '../../../../helpers/helpers';

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
        Chests,
    },

    data: () => ({
        allSteps: [] as Step[],
        selectedWildPic: undefined as WildPic | undefined,
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        content(): any {
            return this;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        previousStep(): Step | undefined {
            return this.allSteps[(this.currentStepIndex ?? 0) - 1];
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '???';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'C-Trigger') return 'Fireshot Trigger';
            if (this.stepName === 'F-Trigger') return 'Free Spin Trigger';
            if (this.stepName === 'F-Retrigger') return 'Free Spin Retrigger';
            if (this.stepName === 'J-Trigger') return 'GRAND Jackpot Trigger';
            if (this.isFireshotRespin) return 'Fireshot Respin';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },
        isClearingStep(): boolean {
            return this.stepName === 'Clear';
        },
        canAddClearingStep(): boolean {
            return true;
        },
        canAddSpinStep(): boolean {
            return true;
        },
        labelForAddSpinStep(): string {
            if (this.finalStep?.fireshotCount) return 'Add Respin';
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin';
            return 'Add Base Spin';
        },

        // ----- Base game state -----
        reels(): string[][] {
            return modelDefinition.reels;
        },
        showReelStripPositions(): boolean {
            return this.show;
        },
        canChangeReelStripPositions(): boolean {
            return !this.isClearingStep && !this.isFireshotRespin;
        },

        // ----- Free spin state -----
        isFreeSpin(): boolean {
            if (this.isFireshotRespin) return false;
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        canAddFreeSpinTriggerStep(): boolean {
            return !this.finalStep?.fireshotCount;
        },
        labelForAddFreeSpinTriggerStep(): string {
            return `${this.finalStep?.freeSpinCount ? 'Ret' : 'T'}rigger Free Spins`;
        },

        // ----- Fireshot state -----
        canAddFireshotTriggerStep(): boolean {
            return !this.finalStep?.fireshotCount;
        },
        isFireshotTrigger(): boolean {
            return this.currentStep?.fireshotPhase === 'START';
        },
        isFireshotRespin(): boolean {
            const fireshotPhase = this.currentStep?.fireshotPhase;
            return fireshotPhase === 'IN_PROGRESS' || fireshotPhase === 'END';
        },
        fireshotRespinsRemaining(): number {
            return this.currentStep?.fireshotCount ?? 0;
        },
        fireshotCoinsCollected(): number {
            return this.currentStep?.cumulativeCoinCount ?? 0;
        },
        coinPrizeLevels(): Array<{ value: unknown; label: string }> {
            const isGrandInstawin = !this.currentStep?.fireshotPhase && this.fireshotCoinsCollected === modelDefinition.countToTriggerGrand;
            const isInitialFireshotSpin = this.currentStep?.fireshotPhase === 'START' || isGrandInstawin;
            const keys = Object.keys(modelDefinition.coinPrizeWeights.initial);
            if (isInitialFireshotSpin) keys.splice(keys.indexOf('CHEST'), 1);
            return keys.map(k => ({
                value: isNaN(Number(k)) ? k : Number(k),
                label: String(k),
            }));
        },
        chests(): ChestPrize[] {
            return this.currentStep?.json.chests ?? [];
        },

        // ----- WILD PIC state -----
        wildPicMultipliers(): Array<{ value: number | undefined; label: string }> {
            return [
                {value: undefined, label: 'Not activated'},
                {value: 2, label: 'WILD PIC1 x2'},
                {value: 3, label: 'WILD PIC1 x3'},
            ];
        },
    },

    methods: {
        // ----- Step methods -----
        addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
        addSpinStep: () => bus.$emit(events.ADD_STEP, createSpinStep()),
        addFreeSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
        addFireshotTriggerStep: () => bus.$emit(events.ADD_STEP, createFireshotTriggerStep()),

        // ----- Fireshot methods -----
        isCoin({ row, col }: { row: number; col: number }): boolean {
            return !!this.currentStep?.cumulativeCoinPrizes?.[col]?.[row];
        },
        canSetOrUnsetCoin({ row, col }: { row: number; col: number }): boolean {
            if (!this.isFireshotRespin) return false;
            return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
        },
        canSetCoinPrize({ row, col }: { row: number; col: number }): boolean {
            if (this.isFireshotTrigger) {
                return this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] !== 0;
            }
            else if (this.isFireshotRespin) {
                return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
            }
            else {
                return true;
            }
        },
        getCoinPrize({ row, col }: { row: number; col: number }): string {
            return (this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] ?? 0).toString();
        },
        setCoinPrize({ row, col, value }: { row: number; col: number; value: string }) {
            if (!this.currentStep) return;
            const cp = toCoinPrize(value);
            this.currentStep.json.coinPrizes = [0, 1, 2, 3, 4, 5].map((c) => {
                return [0, 1, 2, 3].map((r) => {
                    return c === col && r === row ? cp : this.currentStep?.json.coinPrizes?.[c]?.[r] ?? 0;
                });
            });
            this.saveChangesToStep();
        },

        // ----- WILD PICs methods -----
        selectCell({ row, col }: { row: number; col: number }): void {
            if (this.isFireshotRespin) return;
            this.selectedWildPic = this.findWildPicAt({row, col});
        },
        findWildPicAt({ row, col }: { row: number; col: number }): WildPic | undefined {
            const wildPics = this.currentStep?.json.wildPics ?? [];
            return wildPics.find(({pos}) => col === pos[1] && (row === pos[0] || row === pos[0] + 1));
        },
        isWildPicTop({ row, col }: { row: number; col: number }): boolean {
            const wildPic = this.findWildPicAt({row, col});
            return row === wildPic?.pos[0];
        },
        isWildPicBottom({ row, col }: { row: number; col: number }): boolean {
            const wildPic = this.findWildPicAt({row, col});
            return row - 1 === wildPic?.pos[0];
        },
        isWildPicSelected({ row, col }: { row: number; col: number }): boolean {
            return !!this.selectedWildPic && this.selectedWildPic === this.findWildPicAt({row, col});
        },
        getWildPicDescription({ row, col }: { row: number; col: number }): string | undefined {
            const wildPic = this.findWildPicAt({row, col});
            if (!wildPic) return undefined;
            if (wildPic.type === 'PIC1') {
                if (!wildPic.multiplier) return 'PIC1';
                return `WILD PIC1 x${wildPic.multiplier}`;
            }
            else if (wildPic.type === 'PIC2') {
                return wildPic.extraWilds?.length ? 'WILD PIC2' : 'PIC2';
            }
        },
        canSetWildPicMultiplier({ row, col }: { row: number; col: number }): boolean {
            return this.isWildPicSelected({row, col})
                && this.isWildPicTop({row, col})
                && this.selectedWildPic?.type === 'PIC1';
        },
        getWildPicMultiplier({ row, col }: { row: number; col: number }): string {
            return String(this.findWildPicAt({row, col})?.multiplier ?? '');
        },
        setWildPicMultiplier({ row, col, value }: { row: number; col: number; value: string }) {
            const wildPic = this.findWildPicAt({row, col});
            if (!wildPic) return;
            wildPic.multiplier = value ? Number(value) : undefined;
            this.saveChangesToStep();
        },
        isExtraWild({ row, col }: { row: number; col: number }): boolean {
            const wildPics = this.currentStep?.json.wildPics ?? [];
            for (const {extraWilds = []} of wildPics) {
                if (extraWilds.some(ew => ew[0] === row && ew[1] === col)) return true;
            }
            return false;
        },
        canSetOrUnsetExtraWild({ row, col, sym }: { row: number; col: number, sym: string }): boolean {
            const wp = this.selectedWildPic;
            if (!wp || wp.type !== 'PIC2') return false;
            if (['WILD', 'PIC1', 'PIC2', 'COIN', 'SCAT'].includes(sym)) return false;
            const wildPics = this.currentStep?.json.wildPics ?? [];
            const match = wildPics.find(wp => wp.extraWilds?.some(c => c[0] === row && c[1] === col));
            return !match || match === wp;
        },
        toggleExtraWild({ row, col }: { row: number; col: number }) {
            const wp = this.selectedWildPic;
            if (!wp || wp.type !== 'PIC2') return;
            const extras = wp.extraWilds ?? [];
            const i = extras.findIndex(c => c[0] === row && c[1] === col);
            if (i === -1) extras.push([row, col]); else extras.splice(i, 1);
            wp.extraWilds = extras.length > 0 ? extras : undefined;
            this.selectedWildPic = undefined;
            this.selectedWildPic = wp;
            this.saveChangesToStep();
        },
        invalidateWildPics() {
            this.selectedWildPic = undefined;
            const wildPics = this.currentStep?.json.wildPics ?? [];
            for (const wildPic of wildPics) {
                wildPic.multiplier = undefined;
                wildPic.extraWilds = undefined;
            }
        },

        // ----- Scenario persistence methods -----
        notifyReelStripPositionsChanged() {
            this.invalidateWildPics();
            this.saveChangesToStep();
        },
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: { steps: Array<{ test_scenario_step_id: number; json: StepJson }> }) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }

            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;

            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },

    watch: {
        currentStep(newVal, oldVal) {
            if (oldVal?.test_scenario_step_id === newVal?.test_scenario_step_id && this.selectedWildPic) {
                const old = this.selectedWildPic;
                const wp = this.currentStep?.json.wildPics?.find(wp => wp.pos[0] === old.pos[0] && wp.pos[1] === old.pos[1]);
                this.selectedWildPic = wp;
            }
            else {
                this.selectedWildPic = undefined;
            }
        },
    }
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    fireshotPhase?: 'START' | 'IN_PROGRESS' | 'END';
    fireshotCount?: number;
    cumulativeCoinPrizes?: CoinPrize[][];
    cumulativeCoinCount?: number;
}

function updateStepsInPlace(steps: Step[]) {
    let name = '';
    let coinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4, 5].map(_ => [0, 0, 0, 0]);
    let cumulativeCoinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4, 5].map(_ => [0, 0, 0, 0]);
    let cumulativeCoinCount = 0;
    let isFreeSpin = false;
    let isFireshotRespin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;
    let fireshotPhase: Step['fireshotPhase'];
    let fireshotCount = 0;
    let wildPics: WildPic[] = [];
    let chests: ChestPrize[] = [];

    let isAnyStepModified = false;
    for (const step of steps) {
        wildPics = [];
        chests = [];

        if (step.json.name === 'Clear') {
            // Handle clearing steps by clearing all state
            name = step.json.name;
            isFreeSpin = false;
            isFireshotRespin = false;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
            fireshotPhase = undefined;
            fireshotCount = 0;
            coinPrizes = [];
            cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(_ => [0, 0, 0, 0]);
            cumulativeCoinCount = 0;
        }
        else {
            isFireshotRespin = fireshotCount > 0;
            isFreeSpin = freeSpinCount > 0 && !isFireshotRespin;

            // Update state for fireshot respins
            if (isFireshotRespin) {
                const prevCoinCount = cumulativeCoinCount;
                coinPrizes = step.json.coinPrizes ?? [0, 1, 2, 3, 4, 5].map(_ => [0, 0, 0, 0]);
                coinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => cumulativeCoinPrizes?.[col]?.[row] ? 0 : coinPrizes?.[col]?.[row] || 0));
                cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => cumulativeCoinPrizes?.[col]?.[row] || coinPrizes?.[col]?.[row]) || 0);
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
                name = 'Respin';
                fireshotCount = cumulativeCoinCount === modelDefinition.countToTriggerGrand
                    ? 0
                    : cumulativeCoinCount > prevCoinCount
                        ? modelDefinition.fireshotRespinCount
                        : fireshotCount - 1;
                fireshotPhase = fireshotCount > 0 ? 'IN_PROGRESS' : 'END';
            }

            // Update state for base and free spins
            else {
                name = 'Spin';
                if (isFreeSpin) {
                    name = 'Free spin';
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';
                }
                else {
                    freeSpinPhase = undefined;
                }

                const scatCells = findCells(step.json.reelStripPositions, modelDefinition.scatterSymbol);
                const isFreeSpinTrigger = getFreeSpinsAwarded(scatCells.length) > 0;
                if (isFreeSpinTrigger) {
                    name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                    const addedSpins = getFreeSpinsAwarded(scatCells.length);
                    freeSpinCount += addedSpins;
                    freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
                }

                const coinCells = findCells(step.json.reelStripPositions, modelDefinition.coinSymbol);
                const isGrandTrigger = coinCells.length === modelDefinition.countToTriggerGrand;
                const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot && !isGrandTrigger;
                if (isFireshotTrigger) {
                    name = cumulativeCoinCount < modelDefinition.countToTriggerGrand ? 'C-Trigger' : 'J-Trigger';
                    fireshotCount = modelDefinition.fireshotRespinCount;
                    fireshotPhase = 'START';
                }
                else {
                    fireshotPhase = undefined;
                }

                coinPrizes = [0, 1, 2, 3, 4, 5].map(_ => [0, 0, 0, 0]);
                for (const [row, col] of coinCells) coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
                cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => coinPrizes?.[col]?.[row] || 0));
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
            }

            // Update state for WILD PICs
            if (isFreeSpin) {
                wildPics = findWildPics(step.json.reelStripPositions);
                wildPics.forEach((wildPic, i) => {
                    if (wildPic.type === 'PIC1') wildPic.multiplier = step.json.wildPics?.[i]?.multiplier;
                    if (wildPic.type === 'PIC2') wildPic.extraWilds = step.json.wildPics?.[i]?.extraWilds;
                });
            }

            // Update state for CHESTs
            const coinCells = findCells(step.json.reelStripPositions, modelDefinition.coinSymbol);
            const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot;
            if (isFireshotTrigger || isFireshotRespin) {
                coinPrizes.flatMap(cp => cp).forEach(prize => {
                    if (prize !== 'CHEST') return;
                    chests.push({
                        type: step.json.chests?.[chests.length]?.type ?? 'A',
                        standard: step.json.chests?.[chests.length]?.standard ?? [5, 5, 5],
                        special: step.json.chests?.[chests.length]?.special,
                    });
                });
            }
        }

        // Save all the step details
        Object.assign(step, {
            freeSpinPhase,
            freeSpinCount,
            fireshotPhase,
            fireshotCount,
            cumulativeCoinPrizes,
            cumulativeCoinCount,
        });
        const json = { ...step.json, name, coinPrizes, wildPics, chests };
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }
    return isAnyStepModified;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = modelDefinition.reels;
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function toCoinPrize(value: string): CoinPrize {
    const n = Number(value);
    return isNaN(n) ? value : n as any;
}

function getFreeSpinsAwarded(scatCount: number): number {
    return modelDefinition.freeSpinsAwarded[modelDefinition.reelsLayout.length - scatCount] ?? 0;
}
