import Vue from "vue";
import { trace } from "./../../../../helpers/helpers";
import { setStateParams } from "../../../../state/models/slots/vgw073";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./../reels/reel.vue";

const state = setStateParams();

type Data = {
  freeSpinMultipliers: number[];
  freeSpinMultiplierOptions: number[];
  temporaryFreeSpinMultiplier: string;
  triggerJackpots: { MINI: boolean; MINOR: boolean; GRAND: boolean };
};

const DEFAULT_FREE_SPIN_MULTIPLIER = "";

export default Vue.component("Content", {
  props: ["step"],

  components: {
    reel: Reel,
  },

  data: (): Data => ({
    freeSpinMultipliers: [],
    freeSpinMultiplierOptions: [2, 5, 8, 10, 15],
    temporaryFreeSpinMultiplier: DEFAULT_FREE_SPIN_MULTIPLIER,
    triggerJackpots: {
      MINI: false,
      MINOR: false,
      GRAND: false,
    },
  }),

  computed: {
    show(): boolean {
      return this.step?.json !== undefined;
    },
  },

  methods: {
    addClearingStep() {
      bus.$emit(events.ADD_STEP, state.getClearingStep());
    },
    addBaseStep() {
      bus.$emit(events.ADD_STEP, state.getBaseStep());
    },
    freeSpinMultiplierAdded(event: any) {
      const value = event.target.value;
      this.freeSpinMultipliers.push(value);
      this.sendChanges();

      this.temporaryFreeSpinMultiplier = DEFAULT_FREE_SPIN_MULTIPLIER;
    },
    sendChanges() {
      this.step.json.freeSpinMultipliers = this.freeSpinMultipliers;
      this.step.json.triggerJackpots = this.triggerJackpots;
      bus.$emit(events.UPDATE_STEPS_DB, this.step);
    },
  },

  watch: {
    step(value) {
      const json = value?.json || {};
      this.freeSpinMultipliers = json.freeSpinMultipliers ?? [];
      this.triggerJackpots = json.triggerJackpots ?? {
        MINI: false,
        MINOR: false,
        GRAND: false,
      };
    },
  },

  mounted() {
    trace("reels mounted");
  },
});
