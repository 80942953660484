/* in order to support standalone, please insert the init response from the server here */
export default {
  "success": true,
  "messages": [],
  "gold_balance": 1833500,
  "sweeps_balance": 200,
  "sweeps_deposit_balance": 200,
  "sweeps_promo_balance": 0,
  "sweeps_win_balance": 0,
  "sweeps_available_balance": 0,
  "serverTime": "2020-12-04 06:40:36",
  "modelId": "ingenuity032",
  "gameSessionId": 908444,
  "reelStripPositions": [
    26,
    18,
    1,
    37,
    19
  ],
  "slotWindow": [
    [
      "HV3",
      "QUEEN",
      "KING",
      "MV3"
    ],
    [
      "JACK",
      "MV3",
      "ACE",
      "HV2"
    ],
    [
      "QUEEN",
      "HV3",
      "KING",
      "MV1"
    ],
    [
      "HV1",
      "JACK",
      "HV2",
      "HV3"
    ],
    [
      "HV3",
      "ACE",
      "QUEEN",
      "HV2"
    ]
  ],
  "modelDefinition": {
    "reelsLayout": [
      4,
      4,
      4,
      4,
      4
    ],
    "winLevels": [
      {
        "name": "normal1",
        "threshold": 5
      },
      {
        "name": "normal2",
        "threshold": 10
      },
      {
        "name": "normal3",
        "threshold": 15
      },
      {
        "name": "bigwin",
        "threshold": 30
      },
      {
        "name": "megawin",
        "threshold": 70
      },
      {
        "name": "epicwin"
      }
    ],
    "currency_symbol": "$",
    "coinType1Values": [
      200,
      500,
      1000,
      3000,
      5000,
      10000,
      25000
    ],
    "coinType4Values": [
      1,
      2,
      5,
      10,
      50,
      100,
      200
    ],
    "reels": [
      [
        "HV3",
        "MV1",
        "QUEEN",
        "HV1",
        "KING",
        "MV2",
        "ACE",
        "HV2",
        "JACK",
        "KING",
        "MV1",
        "HV3",
        "ACE",
        "QUEEN",
        "BONUS",
        "JACK",
        "MV3",
        "KING",
        "MV1",
        "HV3",
        "ACE",
        "QUEEN",
        "HV2",
        "MV3",
        "KING",
        "ACE",
        "HV3",
        "QUEEN",
        "KING",
        "MV3",
        "HV1",
        "ACE",
        "MV2",
        "QUEEN",
        "BONUS",
        "JACK",
        "MV3",
        "MV1",
        "ACE",
        "QUEEN",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "JACK",
        "KING",
        "HV2",
        "QUEEN",
        "MV2",
        "ACE",
        "HV3",
        "QUEEN",
        "JACK",
        "MV1",
        "MV3",
        "ACE",
        "BONUS",
        "KING",
        "HV2",
        "JACK",
        "ACE",
        "MV1",
        "HV1",
        "KING",
        "MV2",
        "HV3",
        "QUEEN",
        "HV1",
        "KING"
      ],
      [
        "ACE",
        "HV2",
        "MV1",
        "KING",
        "MV2",
        "HV3",
        "QUEEN",
        "HV2",
        "JACK",
        "HV1",
        "ACE",
        "MV3",
        "KING",
        "HV2",
        "MV1",
        "QUEEN",
        "MV2",
        "HV3",
        "JACK",
        "MV3",
        "ACE",
        "HV2",
        "KING",
        "BONUS",
        "QUEEN",
        "JACK",
        "HV1",
        "HV2",
        "ACE",
        "MV2",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "QUEEN",
        "HV3",
        "KING",
        "MV1",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "QUEEN",
        "ACE",
        "HV2",
        "KING",
        "MV1",
        "MV3",
        "QUEEN",
        "HV1",
        "KING",
        "ACE",
        "HV3",
        "MV2",
        "KING",
        "ACE",
        "HV1",
        "MV2",
        "QUEEN",
        "MV3"
      ],
      [
        "MV2",
        "QUEEN",
        "HV3",
        "KING",
        "MV1",
        "MV3",
        "QUEEN",
        "JACK",
        "HV1",
        "KING",
        "HV3",
        "HV2",
        "ACE",
        "QUEEN",
        "HV1",
        "JACK",
        "MV2",
        "ACE",
        "HV3",
        "MV1",
        "KING",
        "QUEEN",
        "HV3",
        "MV3",
        "ACE",
        "KING",
        "BONUS",
        "QUEEN",
        "ACE",
        "MV3",
        "KING",
        "HV1",
        "MV2",
        "ACE",
        "MV1",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV3",
        "KING",
        "MV2",
        "HV2",
        "HV3",
        "QUEEN",
        "JACK",
        "MV3",
        "HV1",
        "HV2",
        "ACE",
        "HV3",
        "MV1",
        "KING",
        "QUEEN",
        "HV2",
        "ACE",
        "JACK"
      ],
      [
        "HV2",
        "KING",
        "MV3",
        "MV1",
        "QUEEN",
        "MV2",
        "HV2",
        "ACE",
        "HV3",
        "MV3",
        "KING",
        "MV2",
        "HV2",
        "ACE",
        "HV1",
        "MV1",
        "JACK",
        "KING",
        "MV2",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "QUEEN",
        "HV2",
        "JACK",
        "ACE",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV1",
        "QUEEN",
        "HV1",
        "JACK",
        "HV2",
        "HV3",
        "QUEEN",
        "MV3",
        "BONUS",
        "KING",
        "ACE",
        "HV3",
        "QUEEN",
        "HV2",
        "MV2",
        "ACE",
        "HV1",
        "KING",
        "HV3",
        "MV1",
        "ACE",
        "QUEEN",
        "HV1",
        "MV2",
        "KING",
        "MV3",
        "ACE",
        "QUEEN"
      ],
      [
        "HV3",
        "MV1",
        "QUEEN",
        "HV1",
        "KING",
        "MV2",
        "ACE",
        "HV2",
        "JACK",
        "KING",
        "MV1",
        "HV3",
        "ACE",
        "QUEEN",
        "BONUS",
        "JACK",
        "MV3",
        "KING",
        "MV1",
        "HV3",
        "ACE",
        "QUEEN",
        "HV2",
        "MV3",
        "KING",
        "ACE",
        "HV3",
        "QUEEN",
        "KING",
        "MV3",
        "HV1",
        "ACE",
        "MV2",
        "QUEEN",
        "BONUS",
        "JACK",
        "MV3",
        "MV1",
        "ACE",
        "QUEEN",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "JACK",
        "KING",
        "HV2",
        "QUEEN",
        "MV2",
        "ACE",
        "HV3",
        "QUEEN",
        "JACK",
        "MV1",
        "MV3",
        "ACE",
        "BONUS",
        "KING",
        "HV2",
        "JACK",
        "ACE",
        "MV1",
        "HV1",
        "KING",
        "MV2",
        "HV3",
        "QUEEN",
        "HV1",
        "KING"
      ]
    ],
    "freeSpinTrigger": "BONUS",
    "countToTriggerFreeSpin": 3,
    "scatterSymbol": "BONUS",
    "wildSymbol": "WILD",
    "modelId": "ingenuity032",
    "mystSymbol": "MYST",
    "freeSpinTypeOne": 1,
    "freeSpinTypeTwo": 2,
    "featureSymbols": [
      "HV1",
      "HV2",
      "HV3",
      "MV1"
    ],
    "freeSpinTriggerData": [
      {
        "count": 3,
        "numberOfFreeSpin": 10
      },
      {
        "count": 4,
        "numberOfFreeSpin": 15
      },
      {
        "count": 5,
        "numberOfFreeSpin": 20
      }
    ],
    "winTable": [
      {
        "symbol": "WILD",
        "multipliers": [
          400,
          100,
          40
        ]
      },
      {
        "symbol": "HV1",
        "multipliers": [
          400,
          100,
          40
        ]
      },
      {
        "symbol": "HV2",
        "multipliers": [
          250,
          75,
          30
        ]
      },
      {
        "symbol": "HV3",
        "multipliers": [
          200,
          60,
          25
        ]
      },
      {
        "symbol": "MV1",
        "multipliers": [
          150,
          50,
          20
        ]
      },
      {
        "symbol": "MV2",
        "multipliers": [
          120,
          35,
          15
        ]
      },
      {
        "symbol": "MV3",
        "multipliers": [
          100,
          25,
          10
        ]
      },
      {
        "symbol": "ACE",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "KING",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "QUEEN",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "JACK",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "BONUS",
        "multipliers": [
          10,
          5,
          1
        ]
      }
    ],
    "featureWinTable": [
      {
        "symbol": "WILD",
        "multipliers": [
          1200,
          300,
          100,
          4
        ]
      },
      {
        "symbol": "HV1GOLD",
        "multipliers": [
          1200,
          300,
          100,
          4
        ]
      },
      {
        "symbol": "HV2GOLD",
        "multipliers": [
          1200,
          300,
          100,
          4
        ]
      },
      {
        "symbol": "HV3GOLD",
        "multipliers": [
          1200,
          300,
          100,
          4
        ]
      },
      {
        "symbol": "MV1GOLD",
        "multipliers": [
          1200,
          300,
          100,
          4
        ]
      },
      {
        "symbol": "MV2",
        "multipliers": [
          120,
          35,
          15
        ]
      },
      {
        "symbol": "MV3",
        "multipliers": [
          100,
          25,
          10
        ]
      },
      {
        "symbol": "ACE",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "KING",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "QUEEN",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "JACK",
        "multipliers": [
          40,
          15,
          5
        ]
      },
      {
        "symbol": "BONUS",
        "multipliers": [
          10,
          5,
          1
        ]
      }
    ],
    "playLines": [
      [
        1,
        1,
        1,
        1,
        1
      ],
      [
        0,
        0,
        0,
        0,
        0
      ],
      [
        2,
        2,
        2,
        2,
        2
      ],
      [
        3,
        3,
        3,
        3,
        3
      ],
      [
        2,
        1,
        0,
        0,
        0
      ],
      [
        2,
        2,
        1,
        0,
        0
      ],
      [
        3,
        2,
        1,
        0,
        0
      ],
      [
        1,
        0,
        0,
        1,
        0
      ],
      [
        2,
        1,
        1,
        1,
        0
      ],
      [
        1,
        2,
        2,
        1,
        0
      ],
      [
        2,
        3,
        2,
        1,
        0
      ],
      [
        3,
        3,
        2,
        1,
        0
      ],
      [
        0,
        0,
        1,
        0,
        1
      ],
      [
        1,
        0,
        1,
        0,
        1
      ],
      [
        3,
        2,
        1,
        0,
        1
      ],
      [
        2,
        1,
        1,
        0,
        0
      ],
      [
        1,
        1,
        0,
        1,
        1
      ],
      [
        3,
        2,
        1,
        1,
        1
      ],
      [
        1,
        2,
        3,
        2,
        1
      ],
      [
        3,
        2,
        3,
        2,
        1
      ],
      [
        2,
        3,
        3,
        2,
        3
      ],
      [
        3,
        3,
        3,
        2,
        1
      ],
      [
        0,
        0,
        0,
        1,
        2
      ],
      [
        0,
        0,
        0,
        1,
        1
      ],
      [
        0,
        1,
        0,
        1,
        2
      ],
      [
        2,
        1,
        0,
        1,
        2
      ],
      [
        0,
        1,
        2,
        2,
        2
      ],
      [
        2,
        2,
        3,
        2,
        2
      ],
      [
        1,
        2,
        2,
        3,
        3
      ],
      [
        0,
        1,
        2,
        3,
        2
      ],
      [
        2,
        3,
        2,
        3,
        2
      ],
      [
        3,
        3,
        2,
        3,
        2
      ],
      [
        0,
        0,
        1,
        2,
        3
      ],
      [
        1,
        0,
        1,
        2,
        3
      ],
      [
        2,
        1,
        1,
        2,
        3
      ],
      [
        1,
        2,
        2,
        2,
        3
      ],
      [
        3,
        3,
        3,
        2,
        2
      ],
      [
        0,
        1,
        2,
        3,
        3
      ],
      [
        1,
        1,
        2,
        3,
        3
      ],
      [
        1,
        2,
        3,
        3,
        3
      ]
    ],
    "featureReels": [
      [
        "HV3",
        "MV1",
        "QUEEN",
        "MV3",
        "HV1",
        "ACE",
        "MV1",
        "KING",
        "HV2",
        "JACK",
        "MV3",
        "MV1",
        "HV3",
        "ACE",
        "MV2",
        "QUEEN",
        "BONUS",
        "JACK",
        "MV3",
        "KING",
        "HV1",
        "MV2",
        "HV3",
        "QUEEN",
        "HV2",
        "MV3",
        "MV1",
        "ACE",
        "HV3",
        "MV2",
        "KING",
        "MV3",
        "HV1",
        "ACE",
        "MV2",
        "QUEEN",
        "BONUS",
        "JACK",
        "HV3",
        "ACE",
        "MV2",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV3",
        "KING",
        "HV2",
        "JACK",
        "MV1",
        "QUEEN",
        "HV1",
        "ACE",
        "MV2",
        "MV1",
        "HV3",
        "ACE",
        "BONUS",
        "KING",
        "HV2",
        "QUEEN",
        "MV1",
        "HV1",
        "KING",
        "MV2",
        "HV3",
        "QUEEN",
        "HV1",
        "MV2",
        "KING"
      ],
      [
        "HV2",
        "KING",
        "MV3",
        "MV1",
        "QUEEN",
        "MV2",
        "HV2",
        "ACE",
        "HV3",
        "KING",
        "QUEEN",
        "MV2",
        "HV2",
        "ACE",
        "JACK",
        "HV1",
        "KING",
        "HV3",
        "MV2",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "QUEEN",
        "HV2",
        "MV3",
        "ACE",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV1",
        "KING",
        "HV1",
        "JACK",
        "HV2",
        "QUEEN",
        "MV3",
        "BONUS",
        "ACE",
        "MV1",
        "HV3",
        "KING",
        "HV2",
        "MV2",
        "JACK",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "MV3",
        "HV1",
        "QUEEN",
        "ACE",
        "HV3"
      ],
      [
        "MV2",
        "JACK",
        "HV1",
        "KING",
        "MV1",
        "MV3",
        "HV2",
        "QUEEN",
        "ACE",
        "MV2",
        "HV3",
        "KING",
        "MV3",
        "QUEEN",
        "HV1",
        "JACK",
        "MV2",
        "ACE",
        "HV3",
        "MV1",
        "KING",
        "QUEEN",
        "HV2",
        "MV3",
        "ACE",
        "JACK",
        "BONUS",
        "KING",
        "MV3",
        "QUEEN",
        "HV1",
        "MV2",
        "ACE",
        "MV1",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV3",
        "KING",
        "MV2",
        "HV2",
        "MV1",
        "QUEEN",
        "MV3",
        "HV1",
        "JACK",
        "ACE",
        "HV3",
        "MV2",
        "KING",
        "MV1",
        "HV2",
        "QUEEN",
        "ACE",
        "HV3"
      ],
      [
        "HV2",
        "KING",
        "MV3",
        "MV1",
        "QUEEN",
        "MV2",
        "HV2",
        "ACE",
        "HV3",
        "MV3",
        "KING",
        "MV2",
        "HV2",
        "ACE",
        "HV1",
        "MV1",
        "JACK",
        "HV3",
        "MV2",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "QUEEN",
        "HV2",
        "MV3",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV1",
        "KING",
        "HV1",
        "JACK",
        "HV2",
        "HV3",
        "QUEEN",
        "MV3",
        "BONUS",
        "ACE",
        "MV1",
        "HV3",
        "KING",
        "HV2",
        "MV2",
        "JACK",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "HV1",
        "QUEEN",
        "HV3",
        "ACE"
      ],
      [
        "HV1",
        "QUEEN",
        "JACK",
        "HV3",
        "MV1",
        "ACE",
        "MV2",
        "KING",
        "HV3",
        "JACK",
        "HV2",
        "MV2",
        "KING",
        "ACE",
        "HV1",
        "MV3",
        "MV1",
        "QUEEN",
        "BONUS",
        "KING",
        "HV3",
        "MV3",
        "JACK",
        "MV1",
        "MV2",
        "QUEEN",
        "HV2",
        "HV3",
        "ACE",
        "MV2",
        "HV1",
        "KING",
        "MV3",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MYST",
        "MV1",
        "HV3",
        "QUEEN",
        "MV2",
        "KING",
        "ACE",
        "MV1",
        "MV3",
        "JACK",
        "BONUS",
        "QUEEN",
        "MV2",
        "MV1",
        "HV2",
        "KING",
        "HV1",
        "MV3",
        "ACE",
        "HV3",
        "MV1",
        "QUEEN",
        "HV2",
        "MV2",
        "HV3",
        "KING",
        "HV1",
        "MV3",
        "ACE",
        "BONUS",
        "QUEEN",
        "HV2",
        "MV2",
        "ACE"
      ]
    ],
    "coinType1_min_bet": 200,
    "coinType1_max_bet": 25000,
    "coinType1_spin_max_bet": 1000000,
    "coinType4_min_bet": 1,
    "coinType4_max_bet": 200,
    "coinType4_spin_max_bet": 8000
  }
};