import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";
import { trace } from "src/helpers/helpers";

@Component({
    props: ['index', 'jackpotPickables'],
})
export default class selectJackpotPickable extends Vue {
    constructor() {
        super();
    }

    updateJackpot() {
        (<Content>this.$parent).updateBonusJackpotPickable(event);
    }
}
