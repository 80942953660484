export default {
  "success": true,
  "messages": [],
  "modelDefinition": {
    "modelId": "amazonia",
    "scatterSymbol": "FS",
    "wildSymbol": "WI",
    "bonusSymbol": "BO",
    "freeSpinTrigger": "FS",
    "multiplierSymbol": "MU",
    "countToTriggerBonusGame": 3,
    "countToTriggerMultiplierGame": 3,
    "countToTriggerFreeSpin" : 3,
    "freeSpinCountList": [6, 10, 15], //3 SC -> 6 FS ; 5 SC -> 15 FS
    "winTable": [
      {
        "symbol": "WI",
        "multipliers": [4000, 1200, 50, 5]
      }, {
        "symbol": "S1",
        "multipliers": [2000, 500, 25]
      }, {
        "symbol": "S2",
        "multipliers": [500, 200, 20]
      }, {
        "symbol": "S3",
        "multipliers": [400, 150, 15]
      }, {
        "symbol": "S4",
        "multipliers": [350, 125, 15]
      }, {
        "symbol": "S5",
        "multipliers": [200, 100, 10]
      }, {
        "symbol": "S6",
        "multipliers": [120, 80, 10]
      }, {
        "symbol": "S7",
        "multipliers": [100, 75, 5]
      }, {
        "symbol": "S8",
        "multipliers": [75, 30, 5]
      }, {
        "symbol": "FS",
        "multipliers": [25, 5, 1]
      }
    ],
    "reelsLayout": [3, 3, 3, 3, 3],
    "playLines": [
      [1, 1, 1, 1, 1], // 1
      [0, 0, 0, 0, 0], // 2
      [2, 2, 2, 2, 2], // 3
      [0, 1, 2, 1, 0], // 4
      [2, 1, 0, 1, 2], // 5
      [0, 0, 1, 2, 2], // 6
      [2, 2, 1, 0, 0], // 7
      [1, 0, 1, 2, 1], // 8
      [1, 2, 1, 0, 1], // 9
      [0, 1, 1, 1, 2], // 10
      [2, 1, 1, 1, 0], // 11
      [1, 0, 0, 1, 2], // 12
      [1, 2, 2, 1, 0], // 13
      [1, 1, 0, 1, 2], // 14
      [1, 1, 2, 1, 0], // 15
      [0, 0, 1, 2, 1], // 16
      [2, 2, 1, 0, 1], // 17
      [1, 0, 1, 2, 2], // 18
      [1, 2, 1, 0, 0], // 19
      [0, 0, 0, 1, 2], // 20
      [2, 2, 2, 1, 0], // 21
      [0, 1, 2, 2, 2], // 22
      [2, 1, 0, 0, 0], // 23
      [0, 1, 0, 1, 0], // 24
      [2, 1, 2, 1, 2], // 25
      [0, 1, 1, 1, 0], // 26
      [2, 1, 1, 1, 2], // 27
      [1, 0, 0, 0, 1], // 28
      [1, 2, 2, 2, 1], // 29
      [0, 1, 0, 1, 2]  // 30
    ],
  
    "bananaMultipliers": [
      [1, 1, 1, 2, 2, 2, -1, -1, -1],
      [1, 1, 2, 2, 3, 3, -1, -1, 0],
      [2, 2, 2, 3, 4, 5, -1, 0, 0],
      [2, 3, 3, 5, 6, 10, 0, 0, 0]
    ],
  
    "multiplierGame": {
      "3": [2, 3, 4],
      "4": [5, 10, 25, 50],
      "5": [3, 5, 10, 50, 100]
    },
  
    "nutCrackerBins": [
      [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4],
      [1, 1, 1, 2, 2, 2, 4, 4, 4, 9, 9, 9],
      [2, 2, 2, 3, 3, 3, 5, 5, 5, 7, 7, 7],
      [2, 2, 2, 3, 3, 3, 4, 4, 4, 9, 9, 9],
      [2, 2, 2, 4, 4, 4, 5, 5, 5, 7, 7, 7],
      [2, 2, 2, 5, 5, 5, 6, 6, 6, 10, 10, 10],
      [3, 3, 3, 4, 4, 4, 5, 5, 5, 10, 10, 10],
      [3, 3, 3, 5, 5, 5, 7, 7, 7, 25, 25, 25],
      [4, 4, 4, 5, 5, 5, 10, 10, 10, 50, 50, 50],
      [1, 1, 1, 7, 7, 7, 10, 10, 10, 100, 100, 100],
      [3, 3, 3, 8, 8, 8, 20, 20, 20, 200, 200, 200],
      [4, 4, 4, 5, 5, 5, 10, 10, 10, 600, 600, 600],
      [2, 2, 2, 5, 5, 5, 25, 25, 25, 700, 700, 700],
      [1, 1, 1, 3, 3, 3, 10, 10, 10, 800, 800, 800],
      [3, 3, 3, 10, 10, 10, 25, 25, 25, 1000, 1000, 1000]
    ],
  
    "nutCrackerWeights": [67, 110, 110, 100, 92, 73, 75, 60, 50, 23, 15, 14, 5, 4, 2],
  
    "coinType1Values": [250, 2500, 10000, 50000, 100000],
    "coinType4Values": [1, 2, 5, 10, 50],
    "coinType1_min_bet": "250",
    "coinType1_max_bet": "100000",
    "coinType1_spin_max_bet": "3000000",
    "coinType4_min_bet": "1",
    "coinType4_max_bet": "50",
    "coinType4_spin_max_bet": "1500",
    "reels": [
      ["S1", "S6", "S8", "WI", "S6", "S7", "S8", "S4", "S6", "S8", "S3", "S7", "WI", "MU", "S7", "S8", "S3", "BO", "S4", "S8", "BO", "S7", "S2", "BO", "S4", "S7", "S5", "S2", "FS", "S7", "S5", "S2", "FS", "S4", "S8", "S3", "S7", "S1", "S5", "S7"],
      ["S5", "S4", "S7", "S2", "S8", "S5", "FS", "S4", "S5", "S7", "S1", "WI", "S6", "S3", "S5", "S1", "S7", "MU", "S8", "S3", "MU", "S5", "S6", "MU", "S2", "S8", "S6", "S7", "S8", "S6", "S3", "S7", "S6", "S8", "S6", "S2", "S5", "S6", "S7", "S6"],
      ["S8", "S4", "S8", "S1", "WI", "S7", "S8", "S4", "MU", "S3", "WI", "S8", "S1", "S6", "BO", "S2", "S6", "BO", "S4", "S6", "FS", "S2", "S5", "BO", "S8", "S5", "S3", "S8", "S4", "S5", "S7", "S3", "S5", "S7", "BO", "S4", "S3", "S8", "S4", "S5"],
      ["S2", "S4", "S8", "WI", "S6", "S7", "S4", "S8", "S6", "WI", "S4", "S6", "S8", "S3", "MU", "S8", "S3", "FS", "S8", "S3", "S4", "S8", "S3", "FS", "S7", "S3", "S6", "S5", "S2", "S7", "WI", "S6", "S1", "S8", "S5", "S6", "S2", "S7", "S5", "S7"],
      ["S6", "S2", "S8", "S4", "WI", "S6", "S4", "S8", "S6", "S2", "BO", "S1", "S6", "S3", "FS", "S8", "S2", "MU", "S1", "S3", "MU", "S6", "S5", "BO", "S5", "S2", "S8", "S5", "S7", "S5", "S7", "S5", "S7", "S5", "S7", "S8", "WI", "S5", "S6", "S7"]
    ]
  
  }
}
