/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { trace } from "./../../../../helpers/helpers";

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Step");
            step.wheelIndex = 0;
            step.elementIndex = 0;
            step.currency = "SWEEPS";
            step.isWheelStep = false;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        addExtraDataToStep: (step) => {
            step.wheelIndex = 0;
            step.elementIndex = 0;
            step.currency = "SWEEPS";
            step.isWheelStep = step.isWheelStep ? step.isWheelStep : false;
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = step.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
            step.randoms = step.isWheelStep ? [step.elementIndex] : [step.wheelIndex];
            step.rows = State.state.getRowsFromIndexes(step, reelStrips);
            return step;
        },

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        addWheelStepTrigger: () => State.state.createStep([46, 0, 12, 0, 42], false, 0, "", "Wheel Trigger"),
        addWheelStep: (wheelIndex = 0) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "WHEEL");
            step.wheelIndex = wheelIndex;
            step.isWheelStep = true;
            return step;
        },
    };
}
