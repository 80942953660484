import { SpinInputs } from "./spin-inputs";
import {
  createLineWinChecker,
  filterSlotWindow,
} from "../../../../v3/math-model";
import { GameState, modelDefinition } from "../config";
import { getWinCells } from "./get-win-cells";
import { getWinType } from "./get-win-type";
import { SpinOutcome } from "./spin-outcome";
import { generateSlotWindow } from "../choices";
import { SpinOutputs } from "./spin-outputs";

export function baseSpin(inputs: SpinInputs): SpinOutputs {
  const { coinSize, choices } = inputs;

  let gameState: GameState | undefined = undefined;

  const totalPlayAmount = coinSize * coinAmountMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions();
  const slotWindow = generateSlotWindow(reelStripPositions);

  const wins = checkLineWins(slotWindow);
  const winLines = wins.map((win) => win.playLineIndex + win.length / 10);
  const lineWins = wins.map((win) => win.multiplier * coinSize);
  const winCells = wins.map((win) =>
    getWinCells(win.playLineIndex, win.length)
  );

  const scatterWin = checkScatterWin(slotWindow);
  const isFreeSpinTrigger = scatterWin !== undefined;

  if (scatterWin) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount);
    winCells.push(scatterWin.cells);
  }

  const totalWinAmount = lineWins.reduce((sum, amt) => sum + amt, 0);
  const winType = getWinType({ totalWinAmount, isFreeSpinTrigger });

  if (isFreeSpinTrigger) {
    gameState = {
      freeSpinPhase: "START",
      freeSpinCount: modelDefinition.freeSpinsTriggerCount,
      cumulativeWinAmount: totalWinAmount,
    };
  }

  const spinOutcome: SpinOutcome = {
    coinSize,
    totalPlayAmount,
    reelStripPositions,
    slotWindow,
    totalWinAmount,
    winType,
    winLines,
    lineWins,
    winCells,
    freeSpinPhase: gameState?.freeSpinPhase,
    freeSpinCount: gameState?.freeSpinCount,
    cumulativeWinAmount: gameState?.cumulativeWinAmount,
  };

  return {
    spinOutcome,
    gameState,
  };
}

const { coinAmountMultiplier, scatterSymbol, winTable } = modelDefinition;
const checkLineWins = createLineWinChecker(modelDefinition);

function checkScatterWin(slotWindow: string[][]) {
  const cells = filterSlotWindow(slotWindow, (sym) => sym === scatterSymbol);
  const tableIndex = slotWindow.length - cells.length;
  const multiplier =
    tableIndex < scatterWinMultipliers.length
      ? scatterWinMultipliers[tableIndex]
      : 0;
  return multiplier ? { cells, multiplier } : undefined;
}
const scatterWinMultipliers =
  winTable.find((entry) => entry.symbol === scatterSymbol)?.multipliers ?? [];
