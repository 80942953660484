var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFrequentWildStep()
              },
            },
          },
          [_vm._v("\n      Frequent Wild FG\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addGoldenMermaidFGStep()
              },
            },
          },
          [_vm._v("\n      Golden Mermaid FG\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { margin: "0.5rem" } }, [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showWildMermaid,
                expression: "showWildMermaid",
              },
            ],
            staticClass: "text",
            staticStyle: { "margin-left": "1rem" },
          },
          [_vm._v("\n      Is Wild Mermaid:\n    ")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isWildMermaid,
                expression: "isWildMermaid",
              },
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showWildMermaid,
                expression: "showWildMermaid",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "isWildMermaid", id: "isWildMermaid" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.isWildMermaid = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeData,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "true" } }, [_vm._v("true")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "false", selected: "" } }, [
              _vm._v("false"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("Mystery Symbol:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mysterySymbol,
                expression: "mysterySymbol",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "mysterySymbol", id: "mysterySymbol" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.mysterySymbol = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeData,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "HV1", selected: "" } }, [
              _vm._v("HV1"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "HV2" } }, [_vm._v("HV2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "HV3" } }, [_vm._v("HV3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "MV1" } }, [_vm._v("MV1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "MV2" } }, [_vm._v("MV2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "MV3" } }, [_vm._v("MV3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "ACE" } }, [_vm._v("ACE")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "KING" } }, [_vm._v("KING")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "QUEEN" } }, [_vm._v("QUEEN")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "JACK" } }, [_vm._v("JACK")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }