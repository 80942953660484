/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "crazybunny",
        "scatterSymbol": "FS1",
        "scatterSymbols": ["DI1", "DI2", "DI3"],
        "bonusSymbol": "SC1",
        "wildSymbol": "WI",
        "freeSpinTrigger": "FS1",
        "countToTriggerBonusGame": 3,
        "countToTriggerFreeSpin" : 3,
        "freeSpinCountArray": [8, 12, 20],
        "wildSymbols": ["WI1", "WI2", "WI3", "WI4"],
        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [100, 20, 5]
            }, {
                "symbol": "LV2",
                "multipliers": [100, 20, 5]
            }, {
                "symbol": "LV3",
                "multipliers": [100, 20, 5]
            }, {
                "symbol": "LV4",
                "multipliers": [120, 30, 5]
            }, {
                "symbol": "LV5",
                "multipliers": [120, 30, 5]
            }, {
                "symbol": "HV1",
                "multipliers": [250, 50, 10]
            }, {
                "symbol": "HV2",
                "multipliers": [500, 75, 20]
            }, {
                "symbol": "HV3",
                "multipliers": [1500, 150, 30]
            }, {
                "symbol": "HV4",
                "multipliers": [5000, 500, 100]
            }, {
                "symbol": "WI",
                "multipliers": [5000, 500, 100]
            }
        ],

        "winTableFreeSpins": [
            {
                "symbol": "LV6",
                "multipliers": [20, 5]
            }, {
                "symbol": "LV7",
                "multipliers": [20, 5]
            }, {
                "symbol": "LV8",
                "multipliers": [20, 5]
            }, {
                "symbol": "LV9",
                "multipliers": [30, 5]
            }, {
                "symbol": "LVA",
                "multipliers": [30, 5]
            }, {
                "symbol": "HV5",
                "multipliers": [50, 10]
            }, {
                "symbol": "HV6",
                "multipliers": [75, 20]
            }, {
                "symbol": "HV7",
                "multipliers": [150, 30]
            }, {
                "symbol": "HV8",
                "multipliers": [500, 100]
            }, {
                "symbol": "WI",
                "multipliers": [500, 100]
            }, {
                "symbol": "DI1",
                "multipliers": [4, 3, 2, 1]
            }, {
                "symbol": "DI2",
                "multipliers": [8, 6, 4, 2]
            }, {
                "symbol": "DI3",
                "multipliers": [12, 9, 6, 3]
            }
        ],

        "digSymbols": {
            "DI1": 1,
            "DI2" : 2,
            "DI3" : 3
        },
        "reelsLayout": [3, 3, 3, 3, 3],
        "reelsLayoutFreeSpin": [3, 3, 3, 3],
        "playLines": [
            [1, 1, 1, 1, 1], // 1
            [0, 0, 0, 0, 0], // 2
            [2, 2, 2, 2, 2], // 3
            [0, 1, 2, 1, 0], // 4
            [2, 1, 0, 1, 2], // 5
            [0, 0, 1, 0, 0], // 6
            [0, 1, 0, 1, 0], // 7
            [0, 1, 1, 1, 0], // 8
            [1, 0, 0, 0, 1], // 9
            [1, 0, 1, 0, 1], // 10
            [1, 1, 0, 1, 1], // 11
            [1, 1, 2, 1, 1], // 12
            [1, 2, 1, 2, 1], // 13
            [1, 2, 2, 2, 1], // 14
            [2, 1, 1, 1, 2], // 15
            [2, 1, 2, 1, 2], // 16
            [2, 2, 1, 2, 2], // 17
            [0, 2, 0, 2, 0], // 18
            [2, 0, 2, 0, 2], // 19
            [0, 0, 2, 0, 0]  // 20
        ],

        "bonusRound":[
            [1, 2, 3, 4],
            [0, 5, 8, 14],
            [0, 0, 20, 25],
            [0, 0, 0, 100]
        ],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"1500000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"750",

        "reels": [
            ["LV3", "LV4" ,"HV1" ,"LV5" ,"LV2" ,"LV4" ,"LV5" ,"LV2" ,"HV1" ,"LV5" ,"LV3" ,"HV4" ,"LV4" ,"FS1" ,"LV5" ,"HV2" ,"LV3" ,"LV4" ,"HV1" ,"LV5" ,"LV2" ,"LV4" ,"LV3" ,"HV3" ,"LV1" ,"LV5" ,"LV4" ,"HV2" ,"LV3" ,"LV4" ,"HV1" ,"LV2" ,"LV5" ,"LV4" ,"LV3" ,"HV1" ,"LV4" ,"LV2" ,"HV2" ,"LV3" ,"LV1" ,"LV5" ,"LV2" ,"HV4" ,"LV4" ,"LV3" ,"HV2" ,"LV5" ,"LV1" ,"LV5" ,"LV3" ,"LV4" ,"LV2" ,"LV5" ,"LV3" ,"LV1" ,"LV5" ,"LV2" ,"HV2" ,"LV3" ,"LV4" ,"LV5" ,"HV2" ,"LV3" ,"LV4" ,"HV1" ,"LV2" ,"LV4" ,"LV3" ,"HV3" ,"LV1" ,"LV4" ,"HV2" ,"LV3" ,"LV4" ,"HV1" ,"LV2" ,"LV5" ,"LV4" ,"LV3" ,"HV1" ,"LV4" ,"FS1" ,"LV2" ,"HV2" ,"LV3" ,"LV1" ,"LV2" ,"HV3" ,"LV4" ,"LV3" ,"HV2" ,"LV5" ,"LV1" ,"LV5" ,"HV2" ,"LV3" ,"LV4" ,"LV2" ,"LV5" ,"LV3" ,"LV1" ,"WI2" ,"LV5" ,"LV2" ,"HV2" ,"LV5" ,"LV3" ,"HV2" ,"LV4" ,"LV5" ,"HV2" ,"LV3" ,"LV4" ,"HV1" ,"LV5" ,"LV2" ,"LV4" ,"LV3" ,"HV4" ,"LV1" ,"LV4" ,"HV2" ,"LV3" ,"LV4" ,"HV2" ,"LV2" ,"LV5" ,"LV4" ,"LV3" ,"HV1" ,"LV4" ,"LV2" ,"HV1" ,"LV3" ,"LV1" ,"LV5" ,"HV2" ,"LV2" ,"HV3" ,"LV4" ,"LV3" ,"HV2" ,"LV5" ,"FS1" ,"LV1" ,"HV4" ,"LV5" ,"LV3" ,"LV4" ,"HV2" ,"LV2" ,"LV5"],
            ["LV4", "LV5", "LV2", "LV1", "LV4", "HV1", "LV5", "LV1", "LV2", "HV4", "LV4", "LV5", "LV2", "HV1", "LV4", "LV1", "HV1", "LV2", "LV4", "HV3", "LV2", "HV1", "LV3", "LV1", "FS1", "LV4", "LV5", "SC1", "LV1", "LV4", "HV1", "LV2", "LV1", "SC1", "LV4", "HV1", "LV2", "LV1", "LV4", "HV4", "LV3", "LV2", "LV1", "HV1", "LV2", "LV4", "SC1", "LV5", "HV2", "LV2", "LV1", "LV4", "LV3", "LV1", "LV2", "LV3", "LV1", "FS1", "LV2", "HV3", "LV4", "LV5", "LV2", "HV1", "LV4", "LV1", "HV1", "LV2", "LV4", "HV3", "LV3", "LV1", "LV4", "LV5", "SC1", "LV2", "LV1", "LV4", "LV2", "LV1", "LV4", "HV1", "LV2", "LV1", "LV4", "LV3", "LV2", "LV1", "HV1", "LV2", "LV4", "SC1", "LV5", "HV2", "LV2", "LV1", "LV4", "LV3", "LV1", "LV2", "LV3", "WI3", "LV5", "LV1", "LV2", "HV1", "LV4", "SC1", "LV5", "LV2", "HV1", "LV4", "LV1", "HV1", "LV2", "LV4", "HV4", "LV3", "LV1", "LV4", "LV5", "HV3", "LV1", "LV4", "LV2", "LV1", "LV4", "LV2", "HV1", "LV2", "LV1", "LV4", "HV4", "LV3", "LV2", "LV1", "HV1", "LV2", "LV4", "FS1", "LV5", "HV2", "LV2", "LV1", "LV4", "LV3", "LV1"],
            ["LV2", "LV1", "HV1", "LV3", "HV1", "LV5", "WI1", "LV1", "LV4", "HV3", "LV5", "LV3", "HV3", "LV1", "SC1", "LV3", "HV4", "LV2", "LV1", "HV1", "LV3", "HV1", "LV5", "HV2", "LV4", "FS1", "LV5", "HV1", "LV2", "SC1", "LV5", "LV1", "HV1", "LV2", "LV3", "HV3", "LV5", "LV1", "HV1", "LV3", "LV2", "SC1", "LV1", "HV3", "LV4", "LV5", "SC1", "LV1", "HV1", "LV2", "LV3", "HV2", "LV1", "LV5", "HV1", "LV2", "LV3", "LV1", "LV2", "LV5", "LV1", "LV3", "LV4", "HV1", "LV5", "HV2", "LV3", "HV3", "LV1", "FS1", "LV3", "LV2", "LV1", "HV2", "LV3", "LV5", "HV2", "LV4", "HV1", "LV2", "LV1", "HV3", "LV2", "SC1", "LV3", "LV5", "LV1", "HV1", "LV3", "HV2", "LV2", "LV1", "HV3", "LV4", "LV5", "LV1", "HV1", "LV2", "LV3", "HV2", "LV1", "LV5", "HV1", "LV2", "LV3", "LV1", "LV2", "WI2", "LV5", "LV1", "HV2", "LV4", "HV3", "LV5", "LV3", "HV3", "LV1", "HV1", "LV3", "HV4", "LV2", "SC1", "LV1", "HV2", "LV3", "HV1", "LV5", "HV1", "LV4", "LV5", "LV2", "LV1", "HV1", "LV2", "LV3", "LV5", "LV1", "HV1", "LV3", "FS1", "LV2", "LV1", "HV3", "LV4", "SC1", "LV5", "LV1", "HV1", "LV2", "LV3", "HV2", "LV1", "LV5", "HV1", "LV2", "LV3", "LV1"],
            ["LV4", "LV2", "LV1", "LV3", "HV2", "LV4", "LV1", "LV4", "HV2", "LV5", "LV3", "HV3", "LV5", "SC1", "LV3", "HV2", "LV4", "LV2", "LV1", "LV3", "HV2", "LV4", "SC1", "LV5", "HV2", "LV3", "FS1", "LV4", "LV3", "LV5", "HV4", "LV2", "HV1", "LV3", "LV1", "HV3", "LV4", "HV1", "LV1", "SC1", "LV5", "LV3", "SC1", "LV4", "HV2", "LV1", "SC1", "LV5", "HV1", "LV2", "LV1", "LV3", "FS1", "LV4", "HV3", "LV1", "SC1", "LV2", "LV3", "HV2", "LV1", "LV5", "HV3", "LV2", "LV1", "LV4", "LV5", "LV3", "LV3", "HV2", "LV4", "HV1", "LV2", "FS1", "LV1", "LV3", "HV2", "LV4", "SC1", "LV5", "HV2", "LV3", "LV4", "LV3", "HV1", "LV3", "LV1", "HV3", "LV4", "HV1", "LV5", "HV2", "LV2", "FS1", "LV3", "HV2", "LV4", "SC1", "LV1", "HV1", "LV2", "LV1", "LV3", "LV4", "HV3", "LV1", "SC1", "LV2", "LV3", "HV2", "LV1", "SC1", "LV5", "LV3", "LV2", "WI3", "LV1", "LV4", "LV5", "SC1", "LV3", "HV3", "LV5", "HV2", "LV4", "LV2", "HV4", "LV1", "LV3", "HV2", "LV4", "LV5", "HV2", "LV3", "LV4", "SC1", "LV2", "LV3", "LV5", "HV1", "LV3", "LV1", "HV3", "LV4", "SC1", "LV2", "HV2", "LV5", "HV1", "LV3", "FS1", "LV4", "HV2", "LV1", "SC1", "LV5", "HV1", "LV2", "LV1", "SC1", "LV3", "LV4", "HV3", "LV1", "LV5", "FS1", "LV2", "LV3", "HV2", "LV1", "LV5"],
            ["LV1", "HV1", "LV3", "LV5", "HV1", "LV4", "HV2", "LV2", "HV2", "LV3", "LV5", "HV2", "LV4", "LV3", "FS1", "LV1", "HV1", "LV3", "HV1", "LV5", "HV2", "LV2", "HV3", "LV3", "HV2", "LV5", "LV1", "LV3", "LV2", "HV3", "LV5", "LV4", "HV2", "LV1", "LV3", "HV1", "LV5", "FS1", "LV2", "HV1", "LV4", "HV3", "LV3", "HV4", "LV2", "FS1", "LV1", "HV2", "LV4", "LV2", "HV2", "LV1", "LV5", "LV4", "HV1", "LV2", "LV4", "LV2", "HV2", "LV3", "HV2", "LV4", "LV3", "LV1", "HV1", "LV3", "LV5", "HV1", "LV2", "HV3", "LV3", "HV2", "LV2", "LV1", "FS1", "LV3", "LV2", "HV3", "LV5", "LV4", "HV2", "LV2", "LV3", "HV1", "LV5", "LV1", "HV1", "LV4", "HV3", "LV3", "FS1", "LV1", "HV2", "LV4", "LV2", "LV5", "LV4", "LV1", "LV2", "WI2", "LV4", "LV2", "HV2", "LV3", "LV5", "HV2", "LV4", "HV4", "LV3", "FS1", "LV1", "HV1", "LV3", "LV5", "HV1", "LV2", "HV3", "LV3", "HV2", "LV5", "LV1", "LV3", "LV2", "HV3", "LV5", "LV4", "HV2", "LV3", "HV1", "LV5", "FS1", "LV1", "HV1", "LV4", "HV3", "LV3", "HV2", "LV2", "LV1", "HV2", "LV4", "FS1", "LV2", "HV4", "LV5", "LV4"]
        ],
        "freeSpinsSetThree": [
            ["HV5", "HV5", "HV5", "WI4", "HV7", "HV7", "HV7", "DI2", "LVA", "LVA", "LVA", "LV6", "LV6", "LV6", "LVA", "WI4", "LV6", "LV8", "LV7", "LV7", "LVA", "HV6", "HV6", "HV8", "LV9", "LV9", "LV9", "WI4", "LV8", "LV8", "LV8", "HV6", "LV7", "LV7", "LV7", "LV9", "HV5", "HV5", "HV5", "WI4", "LV6", "HV7", "HV7", "DI3", "LVA", "LVA", "LVA", "LV6", "LV6", "LV6", "LVA", "WI4", "HV7", "LV8", "LV7", "LV7", "LVA", "HV6", "HV6", "DI1", "LV9", "LV9", "LV9", "WI4", "LV8", "LV8", "LV8", "HV6", "HV8", "HV8", "HV8", "LV9"],
            ["HV8", "HV8", "HV8", "WI4", "LV9", "LV9", "LV9", "WI4", "HV5", "HV5", "HV5", "LV8", "LV8", "LV8", "LV8", "HV8", "LV6", "LV6", "LVA", "WI4", "LVA", "LV7", "LV7", "LV7", "HV7", "HV7", "HV7", "DI3", "HV6", "HV6", "HV6", "LV9", "LVA", "LVA", "LVA", "LV7", "LV6", "LV6", "LV6", "WI4", "LV9", "LV9", "LV9", "WI4", "HV5", "HV5", "HV5", "LV8", "LV8", "LV8", "LV8", "DI1", "LV6", "LV6", "LVA", "WI4", "LVA", "LV7", "LV7", "LV7", "HV7", "HV7", "HV7", "DI2", "HV6", "HV6", "HV6", "LV9", "LVA", "LVA", "LVA", "LV7"],
            ["HV5", "HV5", "HV5", "LVA", "HV8", "HV8", "HV8", "LV7", "HV7", "HV7", "HV7", "DI3", "LVA", "LV6", "LV6", "WI4", "LV8", "LV8", "LV8", "LV9", "LVA", "LVA", "LVA", "HV8", "HV6", "HV6", "HV6", "WI4", "LV9", "LV9", "LV9", "LV7", "LV8", "LV6", "LV6", "WI4", "HV5", "HV5", "HV5", "LVA", "LV7", "LV7", "LV7", "LV7", "HV7", "HV7", "HV7", "DI2", "LVA", "LV6", "LV6", "WI4", "LV8", "LV8", "LV8", "LV9", "LVA", "LVA", "LVA", "DI1", "HV6", "HV6", "HV6", "WI4", "LV9", "LV9", "LV9", "LV7", "LV8", "LV6", "LV6", "WI4"],
            ["LVA", "LVA", "LVA", "WI4", "HV6", "HV6", "HV6", "DI2", "LV7", "LV8", "LV8", "LV8", "LV8", "LV7", "LV7", "HV8", "HV7", "HV7", "HV7", "LVA", "LVA", "LV6", "LV6", "WI4", "HV5", "HV5", "HV5", "LV7", "LV6", "LV6", "LV6", "WI4", "LV9", "LV9", "LV9", "LV9", "LVA", "LVA", "LVA", "WI4", "HV6", "HV6", "HV6", "DI3", "LV7", "LV8", "LV8", "LV8", "LV8", "LV7", "LV7", "DI1", "HV7", "HV7", "HV7", "LVA", "LVA", "LV6", "LV6", "WI4", "HV5", "HV5", "HV5", "LV7", "HV8", "HV8", "HV8", "WI4", "LV9", "LV9", "LV9", "LV9"]
        ],
        "freeSpinsSetFour": [
            ["HV5", "HV5", "HV5", "WI4", "HV7", "HV7", "HV7", "DI2", "LVA", "LVA", "LVA", "HV8", "HV8", "HV8", "LVA", "WI4", "LV6", "LV8", "LV7", "DI1", "LVA", "HV6", "HV6", "DI3", "LV9", "LV9", "LV9", "WI4", "LV8", "LV8", "LV8", "HV6", "LV7", "LV7", "LV7", "DI2", "HV5", "HV5", "HV5", "WI4", "LV6", "HV7", "HV7", "DI3", "LVA", "LVA", "LVA", "LV6", "LV6", "LV6", "LVA", "WI4", "HV7", "LV8", "LV7", "LV7", "LVA", "HV6", "HV6", "DI1", "LV9", "LV9", "LV9", "WI4", "LV8", "LV8", "LV8", "HV6", "HV8", "HV8", "HV8", "LV9"],
            ["HV8", "HV8", "HV8", "WI4", "LV9", "LV9", "LV9", "WI4", "HV5", "HV5", "HV5", "DI3", "LV8", "LV8", "LV8", "HV8", "LV6", "LV6", "LVA", "WI4", "LVA", "HV8", "HV8", "DI2", "HV7", "HV7", "HV7", "DI3", "HV6", "HV6", "HV6", "DI1", "LVA", "LVA", "LVA", "LV7", "LV6", "LV6", "LV6", "WI4", "LV9", "LV9", "LV9", "WI4", "HV5", "HV5", "HV5", "LV8", "LV8", "LV8", "LV8", "DI1", "LV6", "LV6", "LVA", "WI4", "LVA", "LV7", "LV7", "LV7", "HV7", "HV7", "HV7", "DI2", "HV6", "HV6", "HV6", "LV9", "LVA", "LVA", "LVA", "LV7"],
            ["HV5", "HV5", "HV5", "LVA", "HV8", "HV8", "HV8", "LV7", "HV7", "HV7", "HV7", "DI3", "LVA", "HV8", "HV8", "WI4", "LV8", "LV8", "LV8", "DI1", "LVA", "LVA", "LVA", "DI3", "HV6", "HV6", "HV6", "WI4", "LV9", "LV9", "LV9", "DI2", "LV8", "LV6", "HV8", "WI4", "HV5", "HV5", "HV5", "LVA", "LV7", "LV7", "LV7", "LV7", "HV7", "HV7", "HV7", "DI2", "LVA", "LV6", "LV6", "WI4", "LV8", "LV8", "LV8", "LV9", "LVA", "LVA", "LVA", "DI1", "HV6", "HV6", "HV6", "WI4", "LV9", "LV9", "LV9", "LV7", "LV8", "LV6", "LV6", "WI4"],
            ["LVA", "LVA", "LVA", "WI4", "HV6", "HV6", "HV6", "DI2", "LV7", "LV8", "LV8", "LV8", "HV8", "HV8", "HV8", "DI2", "HV7", "HV7", "HV7", "DI1", "LVA", "LV6", "LV6", "WI4", "HV5", "HV5", "HV5", "DI3", "LV6", "LV6", "LV6", "WI4", "LV9", "LV9", "LV9", "LV9", "LVA", "LVA", "LVA", "WI4", "HV6", "HV6", "HV6", "DI3", "LV7", "LV8", "LV8", "LV8", "LV8", "LV7", "LV7", "DI1", "HV7", "HV7", "HV7", "LVA", "LVA", "LV6", "LV6", "WI4", "HV5", "HV5", "HV5", "LV7", "HV8", "HV8", "HV8", "WI4", "LV9", "LV9", "LV9", "LV9"]
        ],
        "freeSpinsMultipliers": [ 1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4, 5, 5, 5, 6, 6, 6, 7, 7, 7, 8, 8, 8, 9, 9, 9, 10],

        "bonusMultipliers": [[50, 161], [20, 164], [10, 1615], [6, 1615], [5, 1615], [4, 1610], [3, 1610], [2, 1610] ],
        "bonusRepeatChances": [100, 100, 50, 50, 25, 25, 25, 0 ]
    },
}
