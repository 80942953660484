var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      {
        domProps: { value: _vm.value },
        on: { change: _vm.updateWheelSpinResult },
      },
      [
        _c("option", { attrs: { value: "UPGRADE" } }, [
          _vm._v("Upgrade (Lvl1-2)"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "10" } }, [_vm._v("10 (Lvl1)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "15" } }, [_vm._v("15 (Lvl1)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "20" } }, [_vm._v("20 (Lvl1)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "25" } }, [_vm._v("25 (Lvl1)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "50" } }, [_vm._v("50 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "60" } }, [_vm._v("60 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "80" } }, [_vm._v("80 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "100" } }, [_vm._v("100 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "150" } }, [_vm._v("150 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "200" } }, [_vm._v("200 (Lvl2)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "500" } }, [_vm._v("500 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "750" } }, [_vm._v("750 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1000" } }, [_vm._v("1000 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1500" } }, [_vm._v("1500 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2000" } }, [_vm._v("2000 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3000" } }, [_vm._v("3000 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "5000" } }, [_vm._v("5000 (Lvl3)")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "10000" } }, [_vm._v("10000 (Lvl3)")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }