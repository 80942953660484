// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content .flex[data-v-bee15318] {
  display: flex;
}
.sm-game-spec-content .flex-center[data-v-bee15318] {
  display: flex;
  justify-content: center;
}
.sm-game-spec-content .sub-title-menu[data-v-bee15318] {
  padding-top: 20px;
  padding-bottom: 20px;
}
.sm-game-spec-content .label[data-v-bee15318] {
  font-size: 12px;
  text-align: right;
  padding: 5px 2px 2px 20px;
  color: #a0a0a0;
  max-width: 150px;
  border-radius: 3px;
  margin: 1px;
}
.sm-game-spec-content .button[data-v-bee15318] {
 max-width: none;
}
.sm-game-spec-content .button[data-v-bee15318]:has(.picSelect) {
  padding: 0;
}
.sm-game-spec-content .picSelect[data-v-bee15318] {
  appearance: none;
  border: none;
  background: none;
  color: #a0a0a0;
  cursor: pointer;
  height: 100%;
  padding: 12px 7px;
}
.sm-game-spec-content .picSelect[data-v-bee15318]:focus-visible {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw086/content.vue.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;AACA;CACC,eAAe;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,aAAa;AACf","sourcesContent":[".sm-game-spec-content .flex[data-v-bee15318] {\n  display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-bee15318] {\n  display: flex;\n  justify-content: center;\n}\n.sm-game-spec-content .sub-title-menu[data-v-bee15318] {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.sm-game-spec-content .label[data-v-bee15318] {\n  font-size: 12px;\n  text-align: right;\n  padding: 5px 2px 2px 20px;\n  color: #a0a0a0;\n  max-width: 150px;\n  border-radius: 3px;\n  margin: 1px;\n}\n.sm-game-spec-content .button[data-v-bee15318] {\n max-width: none;\n}\n.sm-game-spec-content .button[data-v-bee15318]:has(.picSelect) {\n  padding: 0;\n}\n.sm-game-spec-content .picSelect[data-v-bee15318] {\n  appearance: none;\n  border: none;\n  background: none;\n  color: #a0a0a0;\n  cursor: pointer;\n  height: 100%;\n  padding: 12px 7px;\n}\n.sm-game-spec-content .picSelect[data-v-bee15318]:focus-visible {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
