/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.reelsNo = 3;
            step.extraReels = 0;
            step.levelNo = -1;
            step.reelsLayout = [4,4,4];
            return step;
        },
        getBaseStep: function(stepReelsPos) {
            const name = "Base";
            const step = State.state.createStep([0, 0, 0], false, 0, "", name);
            step.reelsType = "reels";
            step.internal_name = name;
            step.reelsNo = 3;
            step.levelNo = -1;
            step.extraReels = 0;
            step.reelsLayout = [4,4,4];
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getNewModelDefinition: () => State.state.getModelDefinition(),
        getReelStrips: (type, extra) => {
            let reels;
            const state = State.state;
            switch(type) {
                case "reels":
                    reels =  state.getNewModelDefinition().reels;
                    break;
                case"reelsFS1":
                    reels =  state.getNewModelDefinition().reelsFS1;
                    break;
                case"reelsFS2":
                    reels =  state.getNewModelDefinition().reelsFS2;
                    break;
                case"reelsFS3":
                    reels =  state.getNewModelDefinition().reelsFS3;
                    break;
                case"reelsFS4":
                    reels =  state.getNewModelDefinition().reelsFS4;
                    break;
                default:
                    reels =  state.getNewModelDefinition().reels;
            }
// create state clone
            let i;
            state.getNewModelDefinition().reelStripPositions = state.getNewModelDefinition().reelStripPositions ? state.getNewModelDefinition().reelStripPositions : [0,0,0];
            reels = reels.slice(0, 3);
            state.getNewModelDefinition().reelsLayout = state.getNewModelDefinition().reelsLayout.slice(0, 3);
            const reelsLength = state.getNewModelDefinition().reelStripPositions.length;

            if(extra + 3 < reelsLength) {
                state.getNewModelDefinition().reelStripPositions = state.getNewModelDefinition().reelStripPositions.slice(0, extra + 3);
            } else {
                const newLength = extra + 3 - reelsLength;
                for(i=0; i< newLength; i++) {
                    state.getNewModelDefinition().reelStripPositions[reelsLength] = 0;
                }
            }

            let addReels = extra + 3 - reels.length;

            let reelsLayout = state.getNewModelDefinition().reelsLayout;
            for(i=0; i< addReels; i++) {
                reels[reels.length] = reels[2];
                reelsLayout[reelsLayout.length] = 4;
            }

            return reels;
        },

        // example on creating custom indexes
        getFSTriggerStep (stepTitle, reelsType) {
            const step = State.state.createStep([58, 64, 78], false, 0, "", stepTitle);
            step.reelsType = reelsType;
            step.levelNo = -1;
            step.isGreen = 2;
            step.internal_name = "FS Trigger";
            return step;
        },
        getFSStep (stepTitle, reelsType) {
            const step = State.state.createStep([0, 0, 0], true, 0, "", stepTitle);
            step.internal_name = stepTitle;
            step.levelNo = -1;
            step.reelsType = reelsType;
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            let i;
            let stepReelsPos =  step.reelStripPositions;
            let reels = [];
            const initialReelsPos = JSON.parse(JSON.stringify(stepReelsPos));
            switch(step.reelsType) {
                case "reels":
                    reels =  state.getNewModelDefinition().reels;
                    break;
                case"reelsFS1":
                    reels =  state.getNewModelDefinition().reelsFS1;
                    break;
                case"reelsFS2":
                    reels =  state.getNewModelDefinition().reelsFS2;
                    break;
                case"reelsFS3":
                    reels =  state.getNewModelDefinition().reelsFS3;
                    break;
                case"reelsFS4":
                    reels =  state.getNewModelDefinition().reelsFS4;
                    break;
                default:
                    reels =  state.getNewModelDefinition().reels;
            }

            reels = reels.slice(0, 3);
            state.getNewModelDefinition().reelsLayout = state.getNewModelDefinition().reelsLayout.slice(0, 3);
            const updateReels = step.extraReels + 3;
            const reelsLength = stepReelsPos.length;

            if(updateReels < initialReelsPos.length) {
                stepReelsPos = stepReelsPos.slice(0, updateReels);
            } else {
                const newLength = updateReels - reelsLength;
                for(i=0; i< newLength; i++) {
                    stepReelsPos[stepReelsPos.length] = 0;
                }
            }

            let reelsLayout = state.getNewModelDefinition().reelsLayout;

            if(updateReels >= initialReelsPos.length) { //save reelStripPositions
                for (i = 0; i < step.extraReels; i++) {
                    reels[reels.length] = reels[2];
                    reelsLayout[reelsLayout.length] = 4;
                }
            }
            const reelStrips = reels;
            step.reelStripPositions = stepReelsPos;
            state.getNewModelDefinition().reelStripPositions = step.reelStripPositions;
            // step.reelsType = "reels";
            step.reelsLayout = reelsLayout;
            step.reelsNo = reelsLayout.length;
            step.rows = rowsFromIndexes(reelStrips, stepReelsPos, reelsLayout.length, 4);
            step.randoms = stepReelsPos;
            return step;

        }
    };
}
