/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "modelId" : "vgw045",
    "reelsLayout": [4, 4, 4, 4, 4],
    "scatterSymbol":"SCAT",
    "wildSymbol":"WILD",
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 15,
    "winTable": [
      {
          "symbol" : "WILD",
          "multipliers" : [300, 100, 50]
      },
      {
          "symbol" : "PIC1",
          "multipliers" : [125, 50, 25]
      },
  {
          "symbol" : "PIC2",
          "multipliers" : [75, 40, 20]
      },
  {
          "symbol" : "PIC3",
          "multipliers" : [50, 25, 15]
      },
  {
          "symbol" : "PIC4",
          "multipliers" : [40, 20, 10]
      },
      {
          "symbol" : "PIC5",
          "multipliers" : [40, 20, 10]
      },
  {
          "symbol" : "A",
          "multipliers" : [30, 15, 8]
      },
  {
          "symbol" : "K",
          "multipliers" : [30, 15, 8]
      },
  {
          "symbol" : "Q",
          "multipliers" : [30, 15, 8]
      },
  {
          "symbol" : "J",
          "multipliers" : [25, 10, 5]
      },
  {
          "symbol" : "10",
          "multipliers" : [25, 10, 5]
      },
      {
          "symbol" : "9",
          "multipliers" : [25, 10, 5]
      },
      {
          "symbol" : "SCAT",
          "multipliers" : [0, 0, 0]
      }
  ],

  "playLines" : [
      [1, 1, 1, 1, 1], // 1
  [2, 2, 2, 2, 2], // 2
  [0, 0, 0, 0, 0], // 3
  [3, 3, 3, 3, 3], // 4
  [0, 0, 1, 1, 1], // 5
  [1, 1, 0, 0, 0], // 6
  [0, 0, 0, 1, 1], // 7
  [1, 1, 1, 0, 0], // 8
  [1, 1, 2, 2, 2], // 9
  [2, 2, 1, 1, 1], // 10
  [1, 1, 1, 2, 2], // 11
  [2, 2, 2, 1, 1], // 12
  [2, 2, 3, 3, 3], // 13
  [3, 3, 2, 2, 2], // 14
  [2, 2, 2, 3, 3], // 15
  [3, 3, 3, 2, 2], // 16
  [0, 0, 1, 2, 2], // 17
  [2, 2, 1, 0, 0], // 18
  [1, 1, 2, 3, 3], // 19
  [3, 3, 2, 1, 1], // 20
  [0, 1, 2, 1, 2], // 21
  [2, 1, 0, 1, 0], // 22
  [0, 1, 0, 1, 2], // 23
  [2, 1, 2, 1, 0], // 24
  [1, 2, 3, 2, 3], // 25
  [3, 2, 1, 2, 1], // 26
  [1, 2, 1, 2, 3], // 27
  [3, 2, 3, 2, 1], // 28
  [0, 1, 1, 1, 0], // 29
  [1, 0, 0, 0, 1], // 30
  [1, 2, 2, 2, 1], // 31
  [2, 1, 1, 1, 2], // 32
  [2, 3, 3, 3, 2], // 33
  [3, 2, 2, 2, 3], // 34
  [0, 1, 0, 1, 0], // 35
  [1, 0, 1, 0, 1], // 36
  [1, 2, 1, 2, 1], // 37
  [2, 1, 2, 1, 2], // 38
  [2, 3, 2, 3, 2], // 39
  [3, 2, 3, 2, 3], // 40
  [0, 1, 0, 2, 0], // 41
  [2, 1, 2, 0, 2], // 42
  [1, 2, 1, 3, 1], // 43
  [3, 2, 3, 1, 3], // 44
  [0, 1, 0, 2, 1], // 45
  [2, 1, 2, 0, 1], // 46
  [1, 2, 1, 3, 2], // 47
  [3, 2, 3, 1, 2], // 48
  [0, 3, 2, 0, 2], // 49
  [3, 0, 1, 3, 1]  // 50
  ],

  "reels" : [
      ["PIC4","PIC4","9","PIC3","A","PIC5","PIC5","J","PIC2","10","SCAT","A","J","PIC2","PIC2","Q","PIC4","PIC1","J","A","PIC2","10","9","PIC3","PIC3","K","PIC4","9","PIC1","J","PIC2","PIC2","Q","A","SCAT","PIC1","PIC5","Q","PIC2","A","PIC4","10","10","J","WILD","WILD","WILD","WILD","9","A","PIC3","PIC3","Q","PIC4","K","PIC5","PIC5","PIC2","Q","PIC1","Q","PIC2","A","K","PIC2","J","PIC5","10","PIC1","PIC1","9","10","PIC4","PIC5","A","SCAT","9","Q","PIC2","PIC2","PIC5","PIC5","K","PIC3","PIC4","J","K","PIC5","PIC5","PIC1","J","Q","10","PIC1","Q","10","WILD","WILD","WILD","WILD","PIC2","K","PIC4","PIC4","J","PIC3","PIC5","PIC5","K","PIC3","J","Q","9","10","SCAT","PIC3","K","PIC5","J","PIC2","PIC2","K","A","J","PIC1","PIC1","A","Q","PIC3"],
      ["J","K","PIC3","PIC3","PIC5","PIC5","J","PIC1","Q","A","PIC3","A","PIC4","PIC1","K","WILD","WILD","WILD","WILD","Q","K","PIC2","PIC2","10","10","PIC4","PIC4","A","A","J","Q","PIC1","PIC1","PIC3","PIC3","A","PIC1","J","PIC4","PIC3","Q","PIC5","PIC4","J","10","9","PIC2","Q","PIC4","PIC1","Q","A","PIC3","PIC3","A","PIC5","Q","PIC3","PIC3","PIC1","PIC1","A","9","PIC5","PIC5","J","PIC5","PIC5","10","10","PIC1","K","PIC4","J","K","PIC2","9","Q","PIC1","PIC1","Q","PIC4","K","PIC5","J","Q","10","PIC2","9","A","Q","PIC2","PIC2","PIC4","A","J","PIC5","PIC4","K","J","10","K","PIC1","9","A","Q","PIC2","PIC4","PIC4","A","PIC5","PIC5","PIC4","K","PIC1","J","PIC2","10","K","PIC4","PIC4","J","9"],
      ["PIC3","Q","K","PIC2","A","10","Q","9","PIC2","PIC2","J","PIC3","9","K","PIC2","J","10","PIC3","Q","SCAT","PIC1","PIC1","A","PIC5","PIC5","9","10","K","PIC4","PIC3","PIC3","A","Q","9","PIC3","PIC3","PIC4","PIC4","A","PIC2","PIC2","Q","PIC1","A","10","J","PIC5","Q","K","SCAT","PIC4","10","A","9","J","10","PIC5","PIC5","A","J","10","PIC2","PIC4","PIC1","PIC1","K","PIC4","WILD","WILD","WILD","WILD","PIC3","A","Q","PIC2","PIC4","PIC4","PIC3","Q","PIC2","9","J","PIC2","K","PIC5","PIC4","J","PIC1","PIC3","PIC4","K","PIC3","SCAT","Q","9","PIC2","Q","PIC3","K","WILD","WILD","WILD","WILD","10","PIC4","PIC4","J","K","PIC3","A","K","PIC1","J","Q","10","PIC2","Q","PIC5","K","J","SCAT","PIC5","PIC5","PIC4"],
      ["PIC3","A","K","PIC3","10","10","PIC4","PIC4","PIC2","J","10","9","Q","PIC4","PIC1","9","PIC3","PIC5","10","Q","K","PIC3","J","K","PIC1","PIC1","J","PIC2","PIC2","K","A","Q","PIC5","PIC5","Q","PIC4","A","A","J","PIC1","PIC3","PIC4","Q","PIC2","K","K","PIC4","PIC4","J","9","10","A","PIC4","WILD","WILD","WILD","WILD","PIC4","A","PIC3","PIC3","J","PIC2","Q","PIC1","PIC1","J","K","PIC4","PIC5","PIC5","A","Q","PIC1","9","PIC3","K","PIC4","J","PIC2","PIC2","A","10","K","PIC1","PIC1","J","PIC3","PIC3","PIC5","PIC5","9","A","Q","10","9","PIC3","10","J","K","PIC1","PIC2","PIC2","A","Q","K","PIC5","PIC5","J","9","PIC3","PIC4","K","PIC3","J","10","K","9","PIC2","PIC5","K","PIC1","J","PIC2","PIC2"],
      ["K","9","Q","PIC1","SCAT","PIC4","J","PIC3","PIC3","K","PIC5","PIC5","K","PIC4","PIC4","10","10","PIC2","PIC2","WILD","WILD","WILD","WILD","10","PIC3","J","PIC1","9","PIC2","A","SCAT","PIC4","A","PIC1","PIC1","Q","PIC5","PIC5","10","10","J","J","PIC3","Q","PIC2","9","PIC1","PIC1","J","K","PIC4","PIC1","J","A","PIC2","A","PIC1","SCAT","K","J","PIC5","PIC5","10","PIC2","PIC2","9","9","PIC4","K","PIC3","PIC4","Q","PIC5","K","PIC1","K","SCAT","10","PIC2","J","9","PIC4","PIC5","PIC2","A","Q","PIC1","10","PIC3","WILD","WILD","WILD","WILD","PIC2","J","K","PIC4","PIC5","PIC5","A","Q","K","PIC4","PIC4","J","9","10","PIC4","K","PIC3","J","9","SCAT","PIC5","10","PIC3","K","A","J","PIC2","PIC2"]
  ],
  "featureReels" : [
      ["PIC4","PIC4","9","PIC3","A","WILD","WILD","WILD","WILD","10","SCAT","A","J","PIC2","PIC2","Q","PIC4","PIC1","J","A","PIC2","10","9","PIC3","PIC3","K","PIC4","9","PIC1","J","PIC2","PIC2","Q","A","SCAT","PIC1","PIC5","Q","PIC2","A","PIC4","10","10","J","WILD","WILD","WILD","WILD","9","A","PIC3","PIC3","Q","PIC4","K","PIC5","PIC5","PIC2","Q","PIC1","Q","SCAT","A","K","PIC2","J","PIC5","10","PIC1","PIC1","9","10","PIC4","PIC5","A","9","9","Q","PIC2","PIC2","SCAT","PIC5","K","PIC3","PIC4","J","K","PIC5","PIC5","PIC1","J","Q","10","PIC1","Q","10","WILD","WILD","WILD","WILD","PIC2","K","PIC4","PIC4","J","PIC3","PIC5","PIC5","K","PIC3","J","Q","9","10","SCAT","PIC3","K","PIC5","J","PIC2","PIC2","K","A","J","PIC1","PIC1","A","Q","PIC3"],
      ["J","K","PIC3","PIC3","PIC5","PIC5","J","PIC1","Q","A","PIC3","A","PIC4","PIC1","K","WILD","WILD","WILD","WILD","Q","K","PIC2","PIC2","10","10","PIC4","PIC4","A","A","J","Q","PIC1","PIC1","PIC3","PIC3","A","PIC1","J","PIC4","PIC3","Q","PIC5","PIC4","J","10","9","PIC2","Q","PIC4","PIC1","Q","A","PIC3","PIC3","A","PIC5","Q","PIC3","PIC3","PIC1","PIC1","A","9","PIC5","PIC5","J","PIC5","PIC5","10","10","PIC1","K","PIC4","J","K","PIC2","9","Q","PIC1","PIC1","Q","PIC4","K","PIC5","J","WILD","WILD","WILD","WILD","A","Q","PIC2","PIC2","PIC4","A","J","PIC5","PIC4","K","J","10","K","PIC1","9","A","Q","PIC2","PIC4","PIC4","A","PIC5","PIC5","PIC4","K","PIC1","J","PIC2","10","K","PIC4","PIC4","J","9"],
      ["PIC3","Q","K","PIC2","A","10","Q","9","PIC2","PIC2","J","PIC3","9","K","PIC2","J","10","PIC3","Q","SCAT","PIC1","PIC1","A","PIC5","PIC5","9","10","K","PIC4","PIC3","PIC3","A","Q","9","PIC3","SCAT","PIC4","PIC4","A","PIC2","PIC2","Q","PIC1","A","WILD","WILD","WILD","WILD","K","PIC4","PIC4","10","A","9","J","10","PIC5","PIC5","SCAT","J","10","PIC2","PIC4","PIC1","PIC1","K","PIC4","WILD","WILD","WILD","WILD","PIC3","A","Q","PIC2","SCAT","PIC4","PIC3","Q","PIC2","9","J","PIC2","K","PIC5","PIC4","J","PIC1","PIC3","PIC4","K","PIC3","SCAT","Q","9","PIC2","Q","PIC3","K","WILD","WILD","WILD","WILD","10","PIC4","PIC4","J","K","PIC3","SCAT","K","PIC1","J","Q","10","PIC2","Q","PIC5","K","J","SCAT","PIC5","PIC5","PIC4"],
      ["PIC3","A","K","PIC3","10","10","PIC4","PIC4","PIC2","J","10","9","Q","PIC4","PIC1","9","PIC3","PIC5","WILD","WILD","WILD","WILD","J","K","PIC1","PIC1","J","PIC2","PIC2","K","A","Q","PIC5","PIC5","Q","PIC4","A","A","J","PIC1","PIC3","PIC4","Q","PIC2","K","K","PIC4","PIC4","J","9","10","A","PIC4","WILD","WILD","WILD","WILD","PIC4","A","PIC3","PIC3","J","PIC2","Q","PIC1","PIC1","J","K","PIC4","PIC5","PIC5","A","Q","PIC1","9","PIC3","K","PIC4","J","PIC2","PIC2","A","10","K","PIC1","PIC1","J","PIC3","PIC3","PIC5","PIC5","9","A","Q","10","9","PIC3","10","J","K","WILD","WILD","WILD","WILD","Q","K","PIC5","PIC5","J","9","PIC3","PIC4","K","PIC3","J","10","K","9","PIC2","PIC5","K","PIC1","J","PIC2","PIC2"],
      ["K","9","Q","PIC1","PIC1","PIC4","J","WILD","WILD","WILD","WILD","PIC5","K","PIC4","PIC4","10","10","PIC2","PIC2","PIC5","PIC5","A","J","10","PIC3","J","PIC1","9","PIC2","A","SCAT","PIC4","A","PIC1","PIC1","Q","PIC5","PIC5","10","10","J","J","PIC3","Q","PIC2","9","WILD","WILD","WILD","WILD","PIC4","PIC1","J","A","PIC2","A","PIC1","SCAT","K","J","PIC5","PIC5","10","PIC2","PIC2","9","9","PIC4","K","PIC3","PIC4","Q","PIC5","K","PIC1","K","SCAT","10","PIC2","J","9","PIC4","PIC5","PIC2","A","Q","10","10","PIC3","WILD","WILD","WILD","WILD","PIC2","J","K","PIC4","PIC5","PIC5","A","Q","K","PIC4","PIC4","J","9","10","PIC4","K","PIC3","J","9","SCAT","PIC5","10","PIC3","K","A","J","PIC2","PIC2"]
  ],
  "goldReelsBase" : [
    ["0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0"],
    ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0"],
    ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
    ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
    ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"]
  ],
  "goldReelsFeature" : [
      ["0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      ["0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      ["0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","1","1","1","1","1","1","1","1","0","0","0","0","0","0","0","0","1","1","1","1","0","0","0","0","0","0","0","0","0","0","0","0"]
  ],
  "jackpotTypes": [
    "GRAND", "MAJOR", "MINOR", "MINI"
  ],

"jackpotPercentToPool": {
  "grand": 0.25,
  "major": 0.30,
  "minor": 0.45,
  "mini":  0.5
},

"jackpotInitialPool": {
  "gold":{
    "grand": 25000000,
    "major": 2500000,
    "minor": 500000,
    "mini":  250000
  },
  "sweeps":{
    "grand": 100000,
    "major": 10000,
    "minor": 2000,
    "mini":  1000
  }
},

    "defaultSelectLine" : 0,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType1_min_bet":250,
    "coinType1_max_bet":100000,
    "coinType1_spin_max_bet":2500000,

    "coinType4Values" : [ 1, 2, 5, 10, 50, 100 ],
    "coinType4_min_bet":1,
    "coinType4_max_bet":100,
    "coinType4_spin_max_bet":5000,
    "winLevels" : [ {
      "name" : "normal1",
      "threshold" : 5
    }, {
      "name" : "normal2",
      "threshold" : 15
    }, {
      "name" : "normal3",
      "threshold" : 25
    }, {
      "name" : "bigwin",
      "threshold" : 50
    }, {
      "name" : "megawin",
      "threshold" : 150
    }, {
      "name" : "epicwin",
      "threshold" : 2147483647
    } ],
    "freeSpinTrigger" : "SCAT",
    "countToTriggerFreeSpin" : 3,
    "windowRowsForRectReels" : 3,
    "wildDistribution": [1, 2, 3, 4, 5],
    "currency_symbol" : "$"
  },
  "modelId" : "vgw045",
  "gameSessionId" : 17,
  "reelStripPositions" : [ 5, 43, 22, 43, 26 ],
  "slotWindow" : [ [ "SCAT", "K", "PIC2","PIC1" ], [ "K", "A", "Q","PIC1" ], [ "Q", "J", "PIC2","PIC1" ], [ "PIC5", "9", "Q","PIC1" ], [ "PIC5", "J", "Q","PIC1" ] ],
  "gold_balance" : 2008650000,
  "sweeps_balance" : 206529229,
  "sweeps_deposit_balance" : 200001120,
  "sweeps_promo_balance" : 0,
  "sweeps_win_balance" : 6528109,
  "sweeps_available_balance" : 6528109,
  "serverTime" : "2019-05-30 10:46:57"
};
