import { Choices } from '../../choices';
import { modelDefinition } from '../../config';
import { orientatePath } from './orientate-path';

const { wildPaths, reelsLayout } = modelDefinition;

export function getWildPath(featureId: 'ID_1' | 'ID_2' | 'ID_3' | 'ID_4' | 'ID_5' | 'ID_6', choices: Choices) {
    const pathLength = choices.chooseWildPathLength(featureId);
    const selectedPath = wildPaths[featureId];
    const pathStartingPoint = choices.chooseWildPathStartingPosition(selectedPath.path, selectedPath.loops, pathLength);
    const flipX = choices.chooseIfXAxisFlips();
    const flipY = choices.chooseIfYAxisFlips();
    const swapAxis = choices.chooseIfAxisSwap();
    const currentPath = selectedPath.loops
        ? loopPath(selectedPath.path, pathStartingPoint, pathLength)
        : selectedPath.path.slice(pathStartingPoint, pathStartingPoint + pathLength);
    return orientatePath({
        currentPath,
        numberOfReels: reelsLayout.length,
        numberOfSymbols: reelsLayout[0],
        flipX,
        flipY,
        swapAxis,
    });
}

function loopPath(existingPath: number[][], startPosition: number, pathLength: number) {
    const newPath: number[][] = [];
    for (let i = 0; i < pathLength; i++) {
        const position = (startPosition + i) % existingPath.length;
        newPath.push(existingPath[position]);
    }
    return newPath;
}
