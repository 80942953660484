var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "table",
    [
      _c("th", [_vm._v("#")]),
      _vm._v(" "),
      _c("th", [_vm._v("CHEST TYPE")]),
      _vm._v(" "),
      _c("th", [_vm._v("STANDARD PRIZES")]),
      _vm._v(" "),
      _c("th", [_vm._v("SPECIAL PRIZE")]),
      _vm._v(" "),
      _vm._l(_vm.chests, function (chest, i) {
        return [
          _c("tr", [
            _c("td", [_vm._v(_vm._s(i + 1))]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  domProps: { value: _vm.getChestType(i) },
                  on: {
                    input: function ($event) {
                      return _vm.setChestType(i, $event.target.value)
                    },
                  },
                },
                [
                  _vm._l(_vm.chestTypeOptions, function (opt) {
                    return [
                      _c("option", { domProps: { value: opt.value } }, [
                        _vm._v(_vm._s(opt.label)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                class: {
                  input: true,
                  invalid: !_vm.isStandardPrizesTextValid[i],
                },
                attrs: { type: "text" },
                domProps: { value: _vm.getStandardPrizes(i) },
                on: {
                  input: function ($event) {
                    return _vm.setStandardPrizes(i, $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: {
                    "error-indicator": true,
                    invalid: !_vm.isStandardPrizesTextValid[i],
                  },
                },
                [
                  _c("span", { staticClass: "error-text" }, [
                    _vm._v(
                      "\n                        Expected a comma-separated list of numbers. Valid prizes are: " +
                        _vm._s(_vm.standardPrizeOptions.join(", ")) +
                        ".\n                    "
                    ),
                  ]),
                  _vm._v("\n                    ⚠️\n                "),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  domProps: { value: _vm.getSpecialPrize(i) },
                  on: {
                    input: function ($event) {
                      return _vm.setSpecialPrize(i, $event.target.value)
                    },
                  },
                },
                [
                  _vm._l(_vm.specialPrizeOptions, function (opt) {
                    return [
                      _c("option", { domProps: { value: opt.value } }, [
                        _vm._v(_vm._s(opt.label)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }