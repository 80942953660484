var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        symbol: true,
        nuttCell: _vm.isNuttCell,
        candyCaneCell: _vm.isCandyCaneCell,
        stolenRoyalsReplacementSymbols: _vm.isStolenRoyal,
        isWildPathCell: _vm.isWildPathCell,
      },
      style: { "grid-column": _vm.colIndex + 1, "grid-row": _vm.rowIndex + 1 },
    },
    [
      _c("p", { staticClass: "current-name" }, [
        _vm._v(_vm._s(_vm.symbolName)),
      ]),
      _vm._v(" "),
      _vm.isStolenRoyal
        ? [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.stolenRoyal,
                    expression: "stolenRoyal",
                  },
                ],
                staticClass: "stolen-royal-modifier",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.stolenRoyal = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: "WILD" } }, [_vm._v("WILD")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC1" } }, [_vm._v("PIC1")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC2" } }, [_vm._v("PIC2")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC3" } }, [_vm._v("PIC3")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC4" } }, [_vm._v("PIC4")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC5" } }, [_vm._v("PIC5")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "A" } }, [_vm._v("A")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "K" } }, [_vm._v("K")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "Q" } }, [_vm._v("Q")]),
                _vm._v(" "),
                _c("option", { domProps: { value: "J" } }, [_vm._v("J")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 9 } }, [_vm._v("9")]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }