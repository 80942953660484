export const modelDefinition = {
  modelId: "starCatchJackpots",
  gameIds: ["starCatchJackpots"],
  reelsLayout: [3, 3, 3, 3, 3],

  // prettier-ignore
  reels: [
        ['PIC5', 'HEART', 'BONUS', 'PIC4', 'PIC4', 'HEART', 'PIC3', 'PIC5', 'CLUB', 'DIAMOND', 'DIAMOND', 'PIC3', 'CLUB', 'DIAMOND', 'CLUB', 'SPADE', 'SPADE', 'HEART', 'BONUS', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'CLUB', 'PIC2', 'CLUB', 'PIC4', 'DIAMOND', 'CLUB', 'PIC3', 'CLUB', 'PIC5', 'BONUS', 'PIC3', 'SPADE', 'SPADE', 'HEART', 'PIC2', 'HEART', 'PIC3', 'PIC5', 'PIC4', 'SPADE', 'SPADE', 'SCAT', 'SPADE', 'SPADE', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'HEART', 'SPADE', 'HEART', 'PIC3', 'HEART', 'PIC3', 'PIC5', 'PIC1', 'PIC4', 'PIC4', 'SCAT', 'PIC1', 'SPADE', 'SPADE'],
        ['CLUB', 'PIC2', 'BONUS', 'PIC5', 'SPADE', 'SPADE', 'PIC5', 'PIC2', 'DIAMOND', 'DIAMOND', 'DIAMOND', 'HEART', 'HEART', 'SPADE', 'PIC4', 'SPADE', 'PIC5', 'HEART', 'PIC4', 'DIAMOND', 'WILD', 'PIC5', 'DIAMOND', 'PIC5', 'CLUB', 'PIC3', 'WILD', 'DIAMOND', 'PIC1', 'CLUB', 'CLUB', 'PIC2', 'PIC2', 'HEART', 'DIAMOND', 'PIC4', 'PIC4', 'DIAMOND', 'DIAMOND', 'CLUB', 'SCAT', 'SCAT', 'CLUB', 'PIC5', 'HEART', 'HEART', 'SPADE', 'SPADE', 'PIC1', 'DIAMOND', 'PIC1', 'PIC2', 'DIAMOND', 'BONUS', 'DIAMOND', 'PIC1', 'PIC2', 'SCAT', 'SCAT', 'PIC1', 'CLUB', 'PIC4', 'HEART', 'CLUB', 'PIC1'],
        ['PIC3', 'PIC3', 'PIC4', 'CLUB', 'BONUS', 'HEART', 'PIC4', 'PIC5', 'PIC1', 'HEART', 'WILD', 'DIAMOND', 'DIAMOND', 'PIC5', 'HEART', 'DIAMOND', 'HEART', 'HEART', 'CLUB', 'CLUB', 'CLUB', 'DIAMOND', 'SCAT', 'SCAT', 'SCAT', 'PIC4', 'CLUB', 'HEART', 'PIC1', 'BONUS', 'PIC4', 'SPADE', 'DIAMOND', 'SPADE', 'PIC2', 'CLUB', 'SPADE', 'PIC3', 'WILD', 'PIC4', 'HEART', 'CLUB', 'HEART', 'PIC2', 'SPADE', 'PIC3', 'PIC3', 'WILD', 'CLUB', 'SPADE', 'PIC3', 'DIAMOND', 'PIC4', 'HEART', 'PIC3', 'SCAT', 'SCAT', 'SCAT', 'HEART', 'PIC4', 'SPADE', 'DIAMOND', 'SPADE', 'PIC2', 'PIC5'],
        ['PIC5', 'WILD', 'WILD', 'WILD', 'CLUB', 'CLUB', 'PIC3', 'PIC2', 'PIC2', 'PIC1', 'DIAMOND', 'DIAMOND', 'PIC5', 'PIC5', 'PIC3', 'PIC1', 'DIAMOND', 'PIC4', 'PIC5', 'PIC5', 'PIC1', 'PIC4', 'DIAMOND', 'SCAT', 'SCAT', 'HEART', 'SPADE', 'PIC1', 'PIC5', 'PIC5', 'HEART', 'PIC2', 'PIC2', 'HEART', 'SCAT', 'SCAT', 'HEART', 'PIC1', 'PIC5', 'BONUS', 'SPADE', 'SPADE', 'DIAMOND', 'HEART', 'CLUB', 'HEART', 'PIC2', 'PIC1', 'PIC1', 'PIC1', 'HEART', 'SPADE', 'PIC2', 'HEART', 'HEART', 'PIC1', 'PIC5', 'BONUS', 'PIC4', 'PIC4', 'DIAMOND', 'PIC3', 'CLUB', 'DIAMOND'],
        ['PIC2', 'HEART', 'BONUS', 'SPADE', 'CLUB', 'HEART', 'PIC3', 'PIC2', 'HEART', 'PIC5', 'PIC3', 'PIC3', 'WILD', 'WILD', 'WILD', 'PIC3', 'PIC3', 'HEART', 'PIC3', 'PIC4', 'CLUB', 'CLUB', 'PIC4', 'PIC5', 'PIC4', 'SPADE', 'SCAT', 'HEART', 'SPADE', 'PIC1', 'CLUB', 'SPADE', 'HEART', 'PIC2', 'PIC2', 'PIC2', 'CLUB', 'BONUS', 'CLUB', 'SPADE', 'HEART', 'PIC1', 'PIC2', 'HEART', 'PIC5', 'SPADE', 'PIC1', 'PIC1', 'DIAMOND', 'PIC5', 'SPADE', 'SPADE', 'HEART', 'PIC3', 'PIC4', 'HEART', 'CLUB', 'PIC2', 'PIC5', 'SCAT', 'SPADE', 'HEART', 'PIC3', 'PIC4']
    ],
};
