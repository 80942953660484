// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locked-symbol .vgw060-coin-activate > img[data-v-43ff7638] {
    opacity: 1;
}
.invisible[data-v-43ff7638] {
    opacity: 0;
}
.symbol .active[data-v-43ff7638] {
    background-color: #777;
}
.symbol .pic[data-v-43ff7638] {
    background-color: #ba9ebf;
}
.symbol .suit[data-v-43ff7638] {
    background-color: #8ba5b1;
}
.symbol .scat[data-v-43ff7638] {
    background-color: #e08530;
}
.symbol .wild[data-v-43ff7638] {
    background-color: #bb4285;
}
.symbol .gold-coin[data-v-43ff7638] {
    background-color: #bf9816;
}
.vgw060-selector-wrapper .half-size-selectable[data-v-43ff7638] {
    width: 66%;
    float: right;
    margin-right: 1px;
    font-size: 1em;
}
.symbol .previous-coin[data-v-43ff7638] {
    background-color: #5f4b09;
}
.symbol .locked-symbol[data-v-43ff7638] {
    background-color: rgba(0,0,0,0.4);
}
.symbol .invalid[data-v-43ff7638] {
    background-color: #b93434;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw060/components/SymbolPickable.vue.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,iCAAiC;AACrC;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".locked-symbol .vgw060-coin-activate > img[data-v-43ff7638] {\n    opacity: 1;\n}\n.invisible[data-v-43ff7638] {\n    opacity: 0;\n}\n.symbol .active[data-v-43ff7638] {\n    background-color: #777;\n}\n.symbol .pic[data-v-43ff7638] {\n    background-color: #ba9ebf;\n}\n.symbol .suit[data-v-43ff7638] {\n    background-color: #8ba5b1;\n}\n.symbol .scat[data-v-43ff7638] {\n    background-color: #e08530;\n}\n.symbol .wild[data-v-43ff7638] {\n    background-color: #bb4285;\n}\n.symbol .gold-coin[data-v-43ff7638] {\n    background-color: #bf9816;\n}\n.vgw060-selector-wrapper .half-size-selectable[data-v-43ff7638] {\n    width: 66%;\n    float: right;\n    margin-right: 1px;\n    font-size: 1em;\n}\n.symbol .previous-coin[data-v-43ff7638] {\n    background-color: #5f4b09;\n}\n.symbol .locked-symbol[data-v-43ff7638] {\n    background-color: rgba(0,0,0,0.4);\n}\n.symbol .invalid[data-v-43ff7638] {\n    background-color: #b93434;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
