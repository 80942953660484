// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.multi-select {
  position: relative;
  width: 200px;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}
.selected-items {
  display: flex;
  flex-wrap: wrap;
}
.arrow {
  margin-left: 10px;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: rgb(16, 210, 16);
  z-index: 1000;
}
.options label {
  display: block;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/questOfThe3Lamps/components/multi-select.vue"],"names":[],"mappings":";AAsDA;EACA,kBAAA;EACA,YAAA;AACA;AACA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;AACA;AACA;EACA,aAAA;EACA,eAAA;AACA;AACA;EACA,iBAAA;AACA;AACA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;AACA;AACA;EACA,cAAA;EACA,YAAA;AACA","sourcesContent":["<template>\n    <div class=\"multi-select\">\n      <div class=\"select-box\" @click.stop=\"toggleDropdown\">\n        <div class=\"selected-items\">\n          <span v-if=\"selectedValues.length !== 0\">{{ selectedValues.length }} selected</span>\n          <span v-if=\"selectedValues.length === 0\">select prizes</span>\n        </div>\n        <div class=\"arrow\">&#9662;</div>\n      </div>\n      <div class=\"options\" v-if=\"isOpen\" @click.stop>\n        <label v-for=\"option in options\" :key=\"option\">\n          <input :disabled=\"!isEditable\" type=\"checkbox\" :value=\"option\" v-model=\"selectedValues\" @change=\"emitSelection\" />\n          {{ option }}\n        </label>\n      </div>\n    </div>\n  </template>\n  \n  <style>\n  .multi-select {\n    position: relative;\n    width: 200px;\n  }\n  .select-box {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border: 1px solid #ccc;\n    padding: 5px;\n    cursor: pointer;\n  }\n  .selected-items {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  .arrow {\n    margin-left: 10px;\n  }\n  .options {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 100%;\n    border: 1px solid #ccc;\n    background: rgb(16, 210, 16);\n    z-index: 1000;\n  }\n  .options label {\n    display: block;\n    padding: 5px;\n  }\n  </style>\n\n<script lang=\"ts\" src=\"./multi-select.vue.ts\"></script>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
