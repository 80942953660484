var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-steps" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add clearing step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addStep()
                },
              },
            },
            [_vm._v("Add step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addFreeSpinTriggerStep()
                },
              },
            },
            [_vm._v("Trigger Free Spins")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addScatterWinStep()
                },
              },
            },
            [_vm._v("Trigger Scatter win")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addSuperStarSpinStep()
                },
              },
            },
            [_vm._v("Add Super Star Spin")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addJackpotTriggerStep()
                },
              },
            },
            [_vm._v("Add Jackpot Trigger")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reel-position-selectors" },
          [
            _c("label", [_vm._v("Reel strips:")]),
            _vm._v(" "),
            _vm._l(_vm.scenario.reelStripPositions, function (position) {
              return _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: position.value,
                    expression: "position.value",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number" },
                domProps: { value: position.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(position, "value", _vm._n($event.target.value))
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              })
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "super-star-selectors" }, [
          _c("label", { attrs: { for: "superStarCheckbox" } }, [
            _vm._v("Super Star:"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scenario.isSuperStarAwarded,
                expression: "scenario.isSuperStarAwarded",
              },
            ],
            attrs: { id: "superStarCheckbox", type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.scenario.isSuperStarAwarded)
                ? _vm._i(_vm.scenario.isSuperStarAwarded, null) > -1
                : _vm.scenario.isSuperStarAwarded,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.scenario.isSuperStarAwarded,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.scenario,
                          "isSuperStarAwarded",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.scenario,
                          "isSuperStarAwarded",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.scenario, "isSuperStarAwarded", $$c)
                  }
                },
                _vm.handleSuperStarSelected,
              ],
            },
          }),
          _vm._v(" "),
          _vm.scenario.isSuperStarAwarded
            ? _c("label", { attrs: { for: "superStarMultiplierSelect" } }, [
                _vm._v("Super Star Multiplier"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.scenario.isSuperStarAwarded
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.superStarMultiplier,
                      expression: "scenario.superStarMultiplier",
                    },
                  ],
                  attrs: { id: "superStarMultiplierSelect" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "superStarMultiplier",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.superStarMultiplierList,
                  function (superStarMultiplier) {
                    return _c(
                      "option",
                      {
                        key: "superstar-" + superStarMultiplier,
                        domProps: { value: superStarMultiplier },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(superStarMultiplier) +
                            "\n        "
                        ),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("slot-window", { attrs: { spinOutcome: _vm.spinOutcome } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }