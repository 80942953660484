export interface LineWin {
  playLineIndex: number;
  symbol: string;
  length: number;
  multiplier: number;
}

/**
 * Returns a function that checks for line wins for the given model definition.
 */
export function createLineWinChecker(modelDefn: {
  wildSymbol: string;
  scatterSymbol: string;
  playLines: number[][];
  winTable: Array<{ symbol: string; multipliers: number[] }>;
}) {
  const { wildSymbol, scatterSymbol, playLines, winTable } = modelDefn;

  /** Returns details about every line win for the given `slotWindow`. */
  return function checkLineWins(slotWindow: string[][]): LineWin[] {
    const result: LineWin[] = [];
    const playLineSymbols = slotWindow.map(() => ""); // This is resued across iterations to avoid needless GC waste.

    // Check every playline in turn.
    for (
      let playLineIndex = 0;
      playLineIndex < playLines.length;
      ++playLineIndex
    ) {
      const playLine = playLines[playLineIndex];

      // Construct the list of symbols on this playline.
      for (let reelPos = 0; reelPos < slotWindow.length; ++reelPos) {
        const row = playLine[reelPos];
        playLineSymbols[reelPos] = slotWindow[reelPos][row];
      }

      // Look for a winning combination on this playline. Allow wild substitution, but exclude the scatter symbol.
      let winningSymbol = "";
      let countOfSymbol = 0;
      for (
        let reelPos = 0;
        reelPos < slotWindow.length;
        ++reelPos, ++countOfSymbol
      ) {
        const symbolAtPosition = playLineSymbols[reelPos];
        if (symbolAtPosition === wildSymbol) continue;
        if (symbolAtPosition === scatterSymbol) break;
        if (winningSymbol && symbolAtPosition !== winningSymbol) break;
        winningSymbol = symbolAtPosition;
      }

      // We may have a normal symbol line win, a pure wild line win, or both or neither. Check all possibilities.
      const symbolLineWinLength = winningSymbol ? countOfSymbol : 0;
      const symbolLineWinMultiplier = getMultiplierFromWinTable(
        winningSymbol,
        symbolLineWinLength,
        slotWindow.length,
      );
      let wildLineWinLength = 0;
      for (const sym of playLineSymbols) {
        if (sym !== wildSymbol) break;
        wildLineWinLength++;
      }
      const wildLineWinMultiplier = getMultiplierFromWinTable(
        wildSymbol,
        wildLineWinLength,
        slotWindow.length,
      );

      if (symbolLineWinMultiplier === 0 && wildLineWinMultiplier === 0)
        continue;

      // Check if this is a pure WILD line win. Otherwise, it's a normal line win.
      if (wildLineWinMultiplier > symbolLineWinMultiplier) {
        winningSymbol = wildSymbol;
        countOfSymbol = wildLineWinLength;
      }

      result.push({
        playLineIndex,
        symbol: winningSymbol,
        length: countOfSymbol,
        multiplier: Math.max(symbolLineWinMultiplier, wildLineWinMultiplier),
      });
    }

    return result;
  };

  function getMultiplierFromWinTable(
    symbol: string,
    countOfSymbol: number,
    slotWindowWidth: number,
  ) {
    const winTableEntry = winTable.find((entry) => entry.symbol === symbol);
    const multiplierIndex = slotWindowWidth - countOfSymbol;
    if (!winTableEntry || multiplierIndex >= winTableEntry.multipliers.length)
      return 0;
    return winTableEntry.multipliers[slotWindowWidth - countOfSymbol];
  }
}
