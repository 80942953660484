var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Free Spin")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Free Spin Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFeature,
              expression: "!isFeature",
            },
          ],
          staticClass: "flex-center vgw075-selector-wrapper",
        },
        [
          _c("div", [_vm._v("PICX Replacement:  ")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.picxReplacement,
                  expression: "picxReplacement",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.picxReplacement = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updatePicxReplacementSymbol()
                  },
                ],
              },
            },
            [
              _c("option", { domProps: { value: undefined } }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC2" } }, [_vm._v("PIC2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC3" } }, [_vm._v("PIC3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC4" } }, [_vm._v("PIC4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC5" } }, [_vm._v("PIC5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC6" } }, [_vm._v("PIC6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC7" } }, [_vm._v("PIC7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC8" } }, [_vm._v("PIC8")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC9" } }, [_vm._v("PIC9")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature,
              expression: "isFeature",
            },
          ],
          staticClass: "flex-center vgw075-selector-wrapper",
        },
        [
          _c("div", [_vm._v("Burger Reel Position:  ")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.burgerReelPosition,
                  expression: "burgerReelPosition",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.burgerReelPosition = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.addBurgerReelPosition()
                  },
                ],
              },
            },
            [
              _c("option", { domProps: { value: 1 } }, [_vm._v("Meat Patty")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 2 } }, [_vm._v("Bacon")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 6 } }, [_vm._v("Cheese")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 7 } }, [_vm._v("Egg")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 9 } }, [_vm._v("Tomato")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 14 } }, [_vm._v("Lettuce")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 4 } }, [_vm._v("Onion")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 19 } }, [_vm._v("Pickle")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 8 } }, [
                _vm._v("Jackpot Fries"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 3 } }, [_vm._v("Blank")]),
            ]
          ),
          _vm._v("\n\n          "),
          _c("div", [_vm._v("Jackpot Fries:  ")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isJackpotFriesVisible,
                  expression: "isJackpotFriesVisible",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.isJackpotFriesVisible = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.addIsJackpotFriesVisible()
                  },
                ],
              },
            },
            [
              _c("option", { domProps: { value: undefined } }, [
                _vm._v("[Not set]"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: true } }, [_vm._v("Visible")]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v("Invisible"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }