import {createDefaultSlotStep} from "./../../../../helpers/helpers";
import State from '../../../state';
import {modelDefinition} from './config/init';

export function setStateParams() {
    return {
        variations: [],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = '', name= 'Base', jackpotPicks?) => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.gameState = isFeature ? 'continue' : 'clear';

            if (jackpotPicks) {
                step.jackpotPicks = jackpotPicks;
            }

            return step;
        },

        // Must implement
        createDefaultStep: (order = 0, variation = '') => State.state.createStep([0, 0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),

        // unique per model
        getModelDefinition: () => State.state.standalone ? modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFreeSpinTriggerFromBaseGame: (order = 0) => State.state.createStep([85, 84, 1, 0, 1, 8], false, order, '', 'F-Trigger'),
        getFreeSpinReTriggerStep: (order = 0) => State.state.createStep([85, 19, 1, 27, 1, 8], true, order, "", "F-Retrigger"),
        getClearingStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0, 0], false, order, '', 'Clear'),

        getGrandJackpotTriggerFromBaseGame: (order = 0) => State.state.createStep([85, 84, 29, 0, 7, 10], false, order, '', 'Grand-J-Trigger', ["GRAND", "MAJOR", "MINI", "GRAND", "MINI", "MINOR", "GRAND"]),
        getMajorJackpotTriggerFromBaseGame: (order = 0) => State.state.createStep([85, 84, 29, 0, 7, 10], false, order, '', 'Major-J-Trigger', ["MINI", "MINI", "MINOR", "MAJOR", "GRAND", "MAJOR", "MAJOR"]),
        getMinorJackpotTriggerFromBaseGame: (order = 0) => State.state.createStep([85, 84, 29, 0, 7, 10], false, order, '', 'Minor-J-Trigger', ["MINOR", "MINOR", "GRAND", "MINOR"]),
        getMiniJackpotTriggerFromBaseGame: (order = 0) => State.state.createStep([85, 84, 29, 0, 7, 10], false, order, '', 'Mini-J-Trigger', ["MINI", "GRAND", "MAJOR", "MINI", "MINI"]),

        getJackpotFreeSpinTriggerStep: (order = 0) => State.state.createStep([85, 84, 29, 0, 7, 10], true, order, "", "JF-Trigger", ["MINI", "MAJOR", "GRAND", "MAJOR", "MAJOR"]),

        getFeatureStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0, 0], true, order, '', 'F-Step'),
    };
}
