import type { Cell } from "./data-types";

/**
 * Calls the given `predicate` callback once for each cell in the given slot window. Returns the cells for which
 * the predicate functioned returns `true`.
 */
export function filterSlotWindow(
  slotWindow: string[][],
  predicate: FilterSlotWindowPredicate,
): Cell[] {
  const result: Cell[] = [];
  for (let col = 0; col < slotWindow.length; ++col) {
    for (let row = 0; row < slotWindow[col].length; ++row) {
      const cell: Cell = [row, col];
      const symbol = slotWindow[col][row];
      if (predicate(symbol, cell, slotWindow)) {
        result.push(cell);
      }
    }
  }
  return result;
}

/** Callback signature used as the predicate for the `filterSlotWindow` function. */
export type FilterSlotWindowPredicate = (
  symbol: string,
  cell: Cell,
  slotWindow: string[][],
) => boolean;
