export default {
    "success": true,
    "messages": [],
    "gold_balance": 6025950000,
    "sweeps_balance": 413300411,
    "sweeps_deposit_balance": 199974345,
    "sweeps_promo_balance": 6527948,
    "sweeps_win_balance": 206798118,
    "sweeps_available_balance": 206798118,
    "serverTime": "2020-08-26 11:03:47",
    "modelId": "ingenuity031",
    "gameSessionId": 54,
    "reelStripPositions": [
    2,
    22,
    26,
    4,
    12
],
    "slotWindow": [
    [
        "HV1",
        "HV1",
        "HV1"
    ],
    [
        "MV1",
        "MV2",
        "King"
    ],
    [
        "Queen",
        "HV1",
        "HV1"
    ],
    [
        "MV2",
        "MV1",
        "King"
    ],
    [
        "MV1",
        "King",
        "Jack"
    ]
],
    "modelDefinition": {
    "reelsLayout": [
        3,
        3,
        3,
        3,
        3
    ],
        "winLevels": [
        {
            "name": "normal1",
            "threshold": 5
        },
        {
            "name": "normal2",
            "threshold": 10
        },
        {
            "name": "normal3",
            "threshold": 15
        },
        {
            "name": "bigwin",
            "threshold": 30
        },
        {
            "name": "megawin",
            "threshold": 70
        },
        {
            "name": "epicwin"
        }
    ],
        "currency_symbol": "$",
        "coinType1Values": [
        500,
        5000,
        20000,
        40000,
        100000,
        200000,
        400000
    ],
        "coinType4Values": [
        1,
        2,
        5,
        10,
        50,
        100,
        200
    ],
        "reels": [
        [
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ten",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "Ten",
            "HV2",
            "King",
            "Jack",
            "MV3",
            "HV3",
            "Queen",
            "Ace",
            "HV1",
            "HV1",
            "King",
            "MV3",
            "Queen",
            "MV1",
            "HV3",
            "Jack",
            "Ace",
            "HV2",
            "Queen",
            "Jack",
            "HV3",
            "MV1",
            "MV2",
            "Ace",
            "MV3",
            "Queen",
            "HV2",
            "HV3",
            "King",
            "MV1",
            "Ace",
            "HV2",
            "Jack",
            "King",
            "HV2",
            "Queen",
            "MV2",
            "MV3",
            "Jack",
            "MV1",
            "HV3"
        ],
        [
            "Jack",
            "Bonus",
            "Ten",
            "MV3",
            "HV3",
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ace",
            "MV2",
            "MV1",
            "Jack",
            "Queen",
            "HV3",
            "Ace",
            "HV2",
            "Jack",
            "Bonus",
            "Queen",
            "MV1",
            "MV2",
            "King",
            "MV3",
            "Queen",
            "HV2",
            "MV2",
            "Ace",
            "HV3",
            "HV2",
            "Queen",
            "King",
            "HV1",
            "HV1",
            "Jack",
            "HV3",
            "Ace",
            "HV2",
            "Queen",
            "Bonus",
            "King",
            "Ace",
            "HV3",
            "MV1",
            "MV3",
            "Queen",
            "HV2",
            "MV2",
            "King"
        ],
        [
            "Queen",
            "Volcano",
            "Volcano",
            "Volcano",
            "Jack",
            "HV3",
            "Ace",
            "Queen",
            "Bonus",
            "King",
            "Ten",
            "HV2",
            "MV1",
            "Jack",
            "Queen",
            "HV2",
            "MV3",
            "Ace",
            "MV2",
            "HV3",
            "Queen",
            "King",
            "Bonus",
            "Ace",
            "MV1",
            "MV3",
            "Queen",
            "HV1",
            "HV1",
            "HV1",
            "King",
            "Jack",
            "Bonus",
            "Ace",
            "HV2",
            "HV3",
            "Queen",
            "MV2",
            "HV2",
            "MV1",
            "Volcano",
            "Volcano",
            "Volcano",
            "Ace",
            "HV1",
            "HV1",
            "Jack",
            "MV3",
            "Bonus",
            "Queen",
            "MV2",
            "HV2",
            "MV3",
            "Jack",
            "Bonus",
            "King",
            "HV3"
        ],
        [
            "King",
            "Bonus",
            "Ten",
            "Jack",
            "MV2",
            "MV1",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ace",
            "HV3",
            "MV1",
            "Queen",
            "MV3",
            "HV2",
            "HV3",
            "Ace",
            "Jack",
            "Bonus",
            "Queen",
            "MV2",
            "King",
            "MV1",
            "MV3",
            "Queen",
            "HV2",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "MV2",
            "King",
            "Queen",
            "HV3",
            "Jack",
            "Ace",
            "MV3",
            "MV2",
            "Queen",
            "Ten",
            "Bonus",
            "King",
            "Ace",
            "MV1",
            "HV1",
            "HV1",
            "Jack",
            "Queen",
            "HV2",
            "HV2",
            "HV3"
        ],
        [
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "MV3",
            "HV2",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "MV1",
            "King",
            "Jack",
            "MV3",
            "HV3",
            "Queen",
            "Ace",
            "HV1",
            "HV1",
            "King",
            "MV3",
            "Queen",
            "MV1",
            "MV2",
            "Ten",
            "Ace",
            "HV3",
            "HV2",
            "Queen",
            "Jack",
            "HV3",
            "MV1",
            "MV2",
            "Ace",
            "MV3",
            "Queen",
            "HV2",
            "HV2",
            "King",
            "HV3",
            "MV1",
            "MV2",
            "King",
            "HV2",
            "Jack",
            "HV3"
        ]
    ],
        "freeSpinTrigger": "Bonus",
        "countToTriggerFreeSpin": 3,
        "scatterSymbol": "Bonus",
        "wildSymbol": "Volcano",
        "modelId": "ingenuity031",
        "featureName": "reSpin",
        "featureReelsLayout": [
        3,
        3,
        3,
        3,
        3,
        3
    ],
        "freeSpinCount": 8,
        "krakatoaColumn": 2,
        "winTable": [
        {
            "symbol": "HV1",
            "multipliers": [
                500,
                100,
                40
            ]
        },
        {
            "symbol": "HV2",
            "multipliers": [
                400,
                80,
                35
            ]
        },
        {
            "symbol": "HV3",
            "multipliers": [
                200,
                60,
                30
            ]
        },
        {
            "symbol": "MV1",
            "multipliers": [
                150,
                50,
                25
            ]
        },
        {
            "symbol": "MV2",
            "multipliers": [
                100,
                40,
                20
            ]
        },
        {
            "symbol": "MV3",
            "multipliers": [
                75,
                30,
                15
            ]
        },
        {
            "symbol": "Ace",
            "multipliers": [
                50,
                20,
                10
            ]
        },
        {
            "symbol": "King",
            "multipliers": [
                50,
                20,
                10
            ]
        },
        {
            "symbol": "Queen",
            "multipliers": [
                30,
                10,
                5
            ]
        },
        {
            "symbol": "Jack",
            "multipliers": [
                30,
                10,
                5
            ]
        },
        {
            "symbol": "Ten",
            "multipliers": [
                30,
                10,
                5
            ]
        },
        {
            "symbol": "Bonus",
            "multipliers": [
                null,
                null,
                1
            ]
        }
    ],
        "playLines": [
        [
            1,
            1,
            1,
            1,
            1
        ],
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            2,
            2,
            2,
            2,
            2
        ],
        [
            0,
            1,
            2,
            1,
            0
        ],
        [
            2,
            1,
            0,
            1,
            2
        ],
        [
            1,
            0,
            1,
            2,
            1
        ],
        [
            2,
            1,
            2,
            1,
            2
        ],
        [
            0,
            0,
            1,
            2,
            2
        ],
        [
            2,
            2,
            1,
            0,
            0
        ],
        [
            0,
            1,
            1,
            1,
            0
        ],
        [
            2,
            1,
            1,
            1,
            2
        ],
        [
            1,
            1,
            0,
            1,
            1
        ],
        [
            1,
            1,
            2,
            1,
            1
        ],
        [
            1,
            0,
            1,
            0,
            1
        ],
        [
            1,
            2,
            1,
            2,
            1
        ],
        [
            1,
            0,
            0,
            0,
            1
        ],
        [
            1,
            2,
            2,
            2,
            1
        ],
        [
            1,
            0,
            2,
            1,
            0
        ],
        [
            1,
            2,
            0,
            1,
            2
        ],
        [
            0,
            2,
            2,
            2,
            0
        ],
        [
            2,
            0,
            0,
            0,
            2
        ],
        [
            0,
            2,
            1,
            2,
            0
        ],
        [
            2,
            0,
            1,
            0,
            2
        ],
        [
            0,
            2,
            0,
            2,
            0
        ],
        [
            2,
            0,
            2,
            0,
            2
        ],
        [
            0,
            0,
            2,
            0,
            0
        ],
        [
            2,
            2,
            0,
            2,
            2
        ],
        [
            0,
            0,
            1,
            0,
            0
        ],
        [
            2,
            2,
            1,
            2,
            2
        ],
        [
            1,
            2,
            1,
            0,
            1
        ]
    ],
        "countToReTriggerFreeSpin": 2,
        "noOfReTriggerSpins": 2,
        "featureWinTable": [
        {
            "symbol": "HV1",
            "multipliers": [
                2500,
                500,
                100,
                40
            ]
        },
        {
            "symbol": "HV2",
            "multipliers": [
                750,
                400,
                80,
                35
            ]
        },
        {
            "symbol": "HV3",
            "multipliers": [
                400,
                200,
                60,
                30
            ]
        },
        {
            "symbol": "MV1",
            "multipliers": [
                300,
                150,
                50,
                25
            ]
        },
        {
            "symbol": "MV2",
            "multipliers": [
                200,
                100,
                40,
                20
            ]
        },
        {
            "symbol": "MV3",
            "multipliers": [
                150,
                75,
                30,
                15
            ]
        },
        {
            "symbol": "Ace",
            "multipliers": [
                100,
                50,
                20,
                10
            ]
        },
        {
            "symbol": "King",
            "multipliers": [
                100,
                50,
                20,
                10
            ]
        },
        {
            "symbol": "Queen",
            "multipliers": [
                50,
                30,
                10,
                5
            ]
        },
        {
            "symbol": "Jack",
            "multipliers": [
                50,
                30,
                10,
                5
            ]
        },
        {
            "symbol": "Ten",
            "multipliers": [
                50,
                30,
                10,
                5
            ]
        }
    ],
        "featurePlayLines": [
        [
            1,
            1,
            1,
            1,
            1,
            1
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            2,
            2,
            2,
            2,
            2,
            2
        ],
        [
            0,
            1,
            2,
            2,
            1,
            0
        ],
        [
            2,
            1,
            0,
            0,
            1,
            2
        ],
        [
            1,
            0,
            1,
            1,
            2,
            1
        ],
        [
            2,
            1,
            2,
            2,
            1,
            2
        ],
        [
            0,
            0,
            1,
            1,
            2,
            2
        ],
        [
            2,
            2,
            1,
            1,
            0,
            0
        ],
        [
            0,
            1,
            1,
            1,
            1,
            0
        ],
        [
            2,
            1,
            1,
            1,
            1,
            2
        ],
        [
            1,
            1,
            0,
            0,
            1,
            1
        ],
        [
            1,
            1,
            2,
            2,
            1,
            1
        ],
        [
            1,
            0,
            1,
            1,
            0,
            1
        ],
        [
            1,
            2,
            1,
            1,
            2,
            1
        ],
        [
            1,
            0,
            0,
            0,
            0,
            1
        ],
        [
            1,
            2,
            2,
            2,
            2,
            1
        ],
        [
            1,
            0,
            2,
            2,
            1,
            0
        ],
        [
            1,
            2,
            0,
            0,
            1,
            2
        ],
        [
            0,
            2,
            2,
            2,
            2,
            0
        ],
        [
            2,
            0,
            0,
            0,
            0,
            2
        ],
        [
            0,
            2,
            1,
            1,
            2,
            0
        ],
        [
            2,
            0,
            1,
            1,
            0,
            2
        ],
        [
            0,
            2,
            0,
            0,
            2,
            0
        ],
        [
            2,
            0,
            2,
            2,
            0,
            2
        ],
        [
            0,
            0,
            2,
            2,
            0,
            0
        ],
        [
            2,
            2,
            0,
            0,
            2,
            2
        ],
        [
            0,
            0,
            1,
            1,
            0,
            0
        ],
        [
            2,
            2,
            1,
            1,
            2,
            2
        ],
        [
            1,
            2,
            1,
            1,
            0,
            1
        ]
    ],
        "reSpinReels": [
        [
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ten",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "Ten",
            "HV2",
            "King",
            "Jack",
            "MV3",
            "HV3",
            "Queen",
            "Ace",
            "HV1",
            "HV1",
            "King",
            "MV3",
            "Queen",
            "MV1",
            "HV3",
            "Jack",
            "Ace",
            "HV2",
            "Queen",
            "Jack",
            "HV3",
            "MV1",
            "MV2",
            "Ace",
            "MV3",
            "Queen",
            "HV2",
            "HV3",
            "King",
            "MV1",
            "Ace",
            "HV2",
            "Jack",
            "King",
            "HV2",
            "Queen",
            "MV2",
            "MV3",
            "Jack",
            "MV1",
            "HV3"
        ],
        [
            "Jack",
            "Bonus",
            "Ten",
            "MV3",
            "HV3",
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ace",
            "MV2",
            "MV1",
            "Jack",
            "Queen",
            "HV3",
            "Ace",
            "HV2",
            "Jack",
            "Bonus",
            "Queen",
            "MV1",
            "MV2",
            "King",
            "MV3",
            "Queen",
            "HV2",
            "MV2",
            "Ace",
            "HV3",
            "HV2",
            "Queen",
            "King",
            "HV1",
            "HV1",
            "Jack",
            "HV3",
            "Ace",
            "HV2",
            "Queen",
            "Bonus",
            "King",
            "Ace",
            "HV3",
            "MV1",
            "MV3",
            "Queen",
            "HV2",
            "MV2",
            "King"
        ],
        [
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano"
        ],
        [
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano",
            "Volcano"
        ],
        [
            "King",
            "Bonus",
            "Ten",
            "Jack",
            "MV2",
            "MV1",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ace",
            "HV3",
            "MV1",
            "Queen",
            "MV3",
            "HV2",
            "HV3",
            "Ace",
            "Jack",
            "Bonus",
            "Queen",
            "MV2",
            "King",
            "MV1",
            "MV3",
            "Queen",
            "HV2",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "MV2",
            "King",
            "Queen",
            "HV3",
            "Jack",
            "Ace",
            "MV3",
            "MV2",
            "Queen",
            "Ten",
            "Bonus",
            "King",
            "Ace",
            "MV1",
            "HV1",
            "HV1",
            "Jack",
            "Queen",
            "HV2",
            "HV2",
            "HV3"
        ],
        [
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "MV3",
            "HV2",
            "HV2",
            "Ace",
            "Queen",
            "HV3",
            "MV1",
            "King",
            "Jack",
            "MV3",
            "HV3",
            "Queen",
            "Ace",
            "HV1",
            "HV1",
            "King",
            "MV3",
            "Queen",
            "MV1",
            "MV2",
            "Ten",
            "Ace",
            "HV3",
            "HV2",
            "Queen",
            "Jack",
            "HV3",
            "MV1",
            "MV2",
            "Ace",
            "MV3",
            "Queen",
            "HV2",
            "HV2",
            "King",
            "HV3",
            "MV1",
            "MV2",
            "King",
            "HV2",
            "Jack",
            "HV3"
        ]
    ],
        "featureReels": [
        [
            "Jack",
            "HV3",
            "MV3",
            "Queen",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Ace",
            "Ten",
            "MV1",
            "King",
            "MV2",
            "Queen",
            "Jack",
            "HV2",
            "HV2",
            "HV2",
            "King",
            "Ten",
            "MV3",
            "HV3",
            "Queen",
            "Ace",
            "MV2",
            "MV3",
            "Ten",
            "MV1",
            "HV3",
            "King",
            "Jack",
            "HV1",
            "HV1",
            "Queen",
            "Ten",
            "MV3",
            "MV2",
            "Ace",
            "King",
            "MV1",
            "Jack",
            "MV2",
            "Queen",
            "King",
            "HV2",
            "HV2",
            "Ace",
            "Jack",
            "MV3",
            "Queen",
            "MV1",
            "Jack",
            "MV2",
            "Queen",
            "Ace"
        ],
        [
            "HV2",
            "HV2",
            "Queen",
            "Jack",
            "MV3",
            "HV3",
            "King",
            "Ten",
            "Bonus",
            "Ace",
            "MV2",
            "MV1",
            "Queen",
            "Jack",
            "Bonus",
            "King",
            "MV3",
            "Ace",
            "HV3",
            "Queen",
            "Jack",
            "HV1",
            "HV1",
            "HV1",
            "Ten",
            "MV3",
            "Ace",
            "Bonus",
            "Queen",
            "MV2",
            "MV1",
            "Jack",
            "MV3",
            "HV3",
            "Ten",
            "King",
            "Bonus",
            "Queen",
            "MV2",
            "MV1",
            "Jack",
            "Ace",
            "HV2",
            "HV2",
            "HV2",
            "King",
            "MV3",
            "HV3",
            "Ace",
            "Ten",
            "Bonus",
            "Queen",
            "Jack",
            "MV1",
            "MV2",
            "MV3",
            "King",
            "Ace",
            "HV1",
            "HV1",
            "Queen",
            "Jack",
            "Bonus",
            "Ace",
            "HV3",
            "MV3",
            "King"
        ],
        [
            "Volcano",
            "Volcano",
            "Volcano"
        ],
        [
            "Volcano",
            "Volcano",
            "Volcano"
        ],
        [
            "Ace",
            "Ten",
            "Bonus",
            "Queen",
            "Jack",
            "HV2",
            "HV2",
            "King",
            "MV3",
            "HV3",
            "Ace",
            "MV2",
            "MV1",
            "Ten",
            "Jack",
            "Bonus",
            "King",
            "Queen",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ace",
            "MV2",
            "MV1",
            "King",
            "MV3",
            "HV3",
            "MV2",
            "Ace",
            "Queen",
            "HV2",
            "HV2",
            "HV2",
            "Jack",
            "King",
            "Bonus",
            "Queen",
            "MV2",
            "MV1",
            "MV3",
            "Jack",
            "HV3",
            "King",
            "Queen",
            "MV2",
            "MV3",
            "Ace",
            "MV1",
            "Queen",
            "King",
            "MV3"
        ],
        [
            "Queen",
            "HV3",
            "MV3",
            "Jack",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Queen",
            "Ten",
            "MV1",
            "MV2",
            "Ace",
            "King",
            "MV3",
            "HV3",
            "Jack",
            "Ace",
            "HV2",
            "HV2",
            "HV2",
            "Queen",
            "Ten",
            "MV2",
            "MV1",
            "Jack",
            "King",
            "HV3",
            "MV3",
            "Ace",
            "Queen",
            "HV1",
            "HV1",
            "Jack",
            "King",
            "MV1",
            "MV2",
            "Ace",
            "Queen",
            "MV3",
            "Jack",
            "King",
            "HV2",
            "HV2",
            "Queen",
            "Ace",
            "MV1",
            "MV2"
        ]
    ],
    "coinType1_min_bet": 500,
    "coinType1_max_bet": 400000,
    "coinType4_min_bet": 1,
    "coinType4_max_bet": 200
    }
}