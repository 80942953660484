import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    private featureReelsToUse = 6;
    constructor() {
        super();
    }
    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.featureReelsToUse = step.json.featureReelsToUse || 6;
        this.$nextTick(() => bus.$emit("step-updated", step));
    }
    public mounted() {
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep(this.featureReelsToUse) );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep(this.featureReelsToUse) );
    }
    public addWildsGalore() {
        bus.$emit(events.ADD_STEP, State.state.getWildsGalore() );
    }
    public doChangeFeatureReelsToUse() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        if(this.isFeature) {
            this.step.json.featureReelsToUse = this.featureReelsToUse;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
