var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wild-path-selector" }, [
    _c("h2", [_vm._v("Wild Path Control Panel:")]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Flip Wild Path (y-axis):")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathFlipY,
              expression: "step.wildPathFlipY",
            },
          ],
          attrs: { id: "wild-path-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathFlipY",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: true } }, [_vm._v("Yes")]),
          _vm._v(" "),
          _c("option", { domProps: { value: false } }, [_vm._v("No")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Flip Wild Path (x-axis):")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathFlipX,
              expression: "step.wildPathFlipX",
            },
          ],
          attrs: { id: "wild-path-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathFlipX",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: true } }, [_vm._v("Yes")]),
          _vm._v(" "),
          _c("option", { domProps: { value: false } }, [_vm._v("No")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Swap Wild Path Axis:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathSwapAxis,
              expression: "step.wildPathSwapAxis",
            },
          ],
          attrs: { id: "wild-path-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathSwapAxis",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: true } }, [_vm._v("Yes")]),
          _vm._v(" "),
          _c("option", { domProps: { value: false } }, [_vm._v("No")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Select Wild Path Id:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathId,
              expression: "step.wildPathId",
            },
          ],
          attrs: { id: "wild-path-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathId",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: "ID_1" } }, [_vm._v("ID_1")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "ID_2" } }, [_vm._v("ID_2")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "ID_3" } }, [_vm._v("ID_3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "ID_4" } }, [_vm._v("ID_4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "ID_5" } }, [_vm._v("ID_5")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "ID_6" } }, [_vm._v("ID_6")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Select Wild Path Starting Point:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathStartingPosition,
              expression: "step.wildPathStartingPosition",
            },
          ],
          attrs: { id: "nutt-prize-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathStartingPosition",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: 0 } }, [_vm._v("1")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 1 } }, [_vm._v("2")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 2 } }, [_vm._v("3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 3 } }, [_vm._v("4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 4 } }, [_vm._v("5")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 5 } }, [_vm._v("6")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 6 } }, [_vm._v("7")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 7 } }, [_vm._v("8")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 8 } }, [_vm._v("9")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 9 } }, [_vm._v("10")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 10 } }, [_vm._v("11")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 11 } }, [_vm._v("12")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 12 } }, [_vm._v("13")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 13 } }, [_vm._v("14")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 14 } }, [_vm._v("15")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 15 } }, [_vm._v("16")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 16 } }, [_vm._v("17")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 17 } }, [_vm._v("18")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "wild-path-picks" } },
        [_vm._v("Select Wild length:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.wildPathLength,
              expression: "step.wildPathLength",
            },
          ],
          attrs: { id: "nutt-prize-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "wildPathLength",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: 7 } }, [_vm._v("7")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 8 } }, [_vm._v("8")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 9 } }, [_vm._v("9")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 11 } }, [_vm._v("11")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 12 } }, [_vm._v("12")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 13 } }, [_vm._v("13")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 14 } }, [_vm._v("14")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 15 } }, [_vm._v("15")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 16 } }, [_vm._v("16")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 17 } }, [_vm._v("17")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }