import { Card } from "src/components/game_content/slots/vgw092/math-model/choices";
import { Colour } from "src/components/game_content/slots/vgw092/math-model/spin";

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  reelSpinFeatureId?: number;
  boardGamePicks?: number;
  boardGameSteps?: Array<{
    card: Card;
    prize: number;
    colour: Colour;
  }>;
  jackpotType?: "GRAND" | "MAJOR" | "MINOR" | "MINI";
  featureSetId?: number;
  multiplier?: number;
  nuttSymbol?:
    | "PIC1"
    | "PIC2"
    | "PIC3"
    | "PIC4"
    | "PIC5"
    | "A"
    | "K"
    | "Q"
    | "J"
    | "10"
    | "9";
  nuttPrize?: number;
  replacementSymbols?: Array<string>;
  candyCaneReplacementSymbol?: string;
  candyCanePlacements?: Array<{
    reel: number;
    reelPosition: number;
    candyCaneType: "TOP_LEFT" | "TOP_RIGHT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT";
  }>;
  stolenRoyals?: Array<string>;
  wildPathFlipX?: boolean;
  wildPathFlipY?: boolean;
  wildPathSwapAxis?: boolean;
  wildPathId?: "ID_1" | "ID_2" | "ID_3" | "ID_4" | "ID_5" | "ID_6";
  wildPathStartingPosition?: number;
  wildPathLength?: number;
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [5, 5, 5, 5, 5],
    boardGameSteps: [
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
    ],
    candyCanePlacements: [
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
    ],
    stolenRoyals: ["A", "Q", "J"],
    replacementSymbols: [
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
    ],
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [5, 5, 5, 5, 5],
    boardGameSteps: [
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
    ],
    candyCanePlacements: [
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
    ],
    stolenRoyals: ["A", "Q", "J"],
    replacementSymbols: [
      "PIC2",
      "PIC1",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
    ],
  };
}

export function createFreeSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [38, 51, 28, 1, 9],
    boardGameSteps: [
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
    ],
    candyCanePlacements: [
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
    ],
    stolenRoyals: ["A", "K", "Q"],
    replacementSymbols: [
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
    ],
  };
}

export function createBoardGameStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [11, 91, 4, 69, 28],
    boardGamePicks: 4,
    boardGameSteps: [
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
      {
        card: "SINGLE",
        prize: 1,
        colour: "PINK",
      },
    ],
    candyCanePlacements: [
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
      {
        reel: 1,
        reelPosition: 1,
        candyCaneType: "TOP_LEFT",
      },
    ],
    stolenRoyals: ["A", "K", "Q"],
    replacementSymbols: [
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
      "PIC2",
    ],
  };
}
export const originatorId = new Date().getTime();
