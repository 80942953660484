export function getSMUrl() {
    return getBaseUrl() + "/scenario_manager";
}
export function getBaseUrl() {
    return window["chumba"].constants.baseURL;
}
export function getSMSocketURL() {
    return window["chumba"].constants.scenarioManagerSocketUrl;
}
export function getSMSocketURLIsSecure() {
    const v = window["chumba"].constants.scenarioManagerSocketUrlSecure;
    return v !== undefined ? v : true;
}
export function keyboard(b = false) {
    window["chumba"].controller.ui.dispatch(window["ChumbaCasino"].events.ui.KEYBOARD_EVENT, b);
}
export function getUserId(b = false) {
    return window["chumba"].user.u_userid;
}
