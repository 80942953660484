export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
    "modelId" : "vgw031",
        "reelsLayout" : [ 3, 3, 3 ],
        "scatterSymbol" : "SC",
        "freeSpinMultiplier" : 0,
        "freeSpinCount" : 10,
        "playLines" : [ [ 1, 1, 1 ], [ 0, 0, 0 ], [ 2, 2, 2 ], [ 2, 1, 0 ], [ 0, 1, 2 ] ],
        "reels" : [ [ "BLANK", "SC", "BLANK", "2B", "BLANK", "R7", "BLANK", "3B", "BLANK", "DD", "BLANK", "TD", "BLANK", "B7", "BLANK", "SC", "BLANK", "1B", "BLANK", "2B", "BLANK", "CH" ], [ "BLANK", "1B", "BLANK", "B7", "BLANK", "DD", "BLANK", "TD", "BLANK", "R7", "BLANK", "3B", "BLANK", "SC", "BLANK", "2B", "BLANK", "R7", "BLANK", "1B", "BLANK", "CH" ], [ "BLANK", "B7", "BLANK", "2B", "BLANK", "3B", "BLANK", "SC", "BLANK", "R7", "BLANK", "DD", "BLANK", "TD", "BLANK", "CH", "BLANK", "B7", "BLANK", "3B", "BLANK", "1B" ] ],
        "featureReels" : [ [ "BLANK", "SC", "BLANK", "2B", "BLANK", "R7", "BLANK", "3B", "BLANK", "DD", "BLANK", "TD", "BLANK", "B7", "BLANK", "SC", "BLANK", "1B", "BLANK", "2B", "BLANK", "CH" ], [ "BLANK", "1B", "BLANK", "B7", "BLANK", "DD", "BLANK", "TD", "BLANK", "R7", "BLANK", "3B", "BLANK", "SC", "BLANK", "2B", "BLANK", "R7", "BLANK", "1B", "BLANK", "CH" ], [ "BLANK", "B7", "BLANK", "2B", "BLANK", "3B", "BLANK", "SC", "BLANK", "R7", "BLANK", "DD", "BLANK", "TD", "BLANK", "CH", "BLANK", "B7", "BLANK", "3B", "BLANK", "1B" ] ],
        "defaultSelectLine" : 0,
        "coinType1Values" : [ 2500, 12500, 50000, 250000, 500000 ],
        "coinType4Values" : [ 10, 20, 50, 100, 300 ],
        "coinType1_min_bet" : 250,
        "coinType1_max_bet" : 500000,
        "coinType1_spin_max_bet" : 2500000,
        "coinType4_min_bet" : 1,
        "coinType4_max_bet" : 300,
        "coinType4_spin_max_bet" : 1500,
        "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
    }, {
        "name" : "normal2",
        "threshold" : 10
    }, {
        "name" : "normal3",
        "threshold" : 24
    }, {
        "name" : "bigwin",
        "threshold" : 50
    }, {
        "name" : "megawin",
        "threshold" : 100
    }, {
        "name" : "epicwin",
        "threshold" : 2147483647
    } ],
        "freeSpinTrigger" : "SC",
        "countToTriggerFreeSpin" : 3,
        "maxFreeSpinReTriggerCount" : 9,
        "anySymbol" : "ANY",
        "currency_symbol" : "$"
},
    "modelId" : "vgw031",
    "gameSessionId" : 41,
    "reelStripPositions" : [ 2, 19, 12 ],
    "slotWindow" : [ [ "BLANK", "2B", "BLANK" ], [ "1B", "BLANK", "CH" ], [ "BLANK", "TD", "BLANK" ] ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 545751499,
    "sweeps_deposit_balance" : 199999345,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 345752154,
    "sweeps_available_balance" : 345752154,
    "serverTime" : "2018-06-14 06:49:00"
}
