/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "modelId" : "vgw020",
      "gameId" : null,
      "reelsLayout" : [ 3, 3, 3, 3, 3 ],
      "scatterSymbol" : "SC",
      "wildSymbol" : "WILD",
      "bonusSymbol" : null,
      "freeSpinTrigger" : "SC",
      "countToTriggerFreeSpin" : 3,
      "freeSpinMultiplier" : 0,
      "freeSpinCount" : 10,
      "winTable": [
        {
            "symbol" : "PIC1",
            "multipliers" : [600, 300, 40, 3]
        },
		{
            "symbol" : "PIC2",
            "multipliers" : [300, 125, 30]
        },
		{
            "symbol" : "PIC3",
            "multipliers" : [150, 100, 30]
        },
		{
            "symbol" : "PIC4",
            "multipliers" : [150, 50, 30]
        },
		{
            "symbol" : "A",
            "multipliers" : [100, 30, 10]
        },	
		{
            "symbol" : "K",
            "multipliers" : [100, 30, 10]
        },
		{
            "symbol" : "Q",
            "multipliers" : [50, 20, 3]
        },
		{
            "symbol" : "J",
            "multipliers" : [50, 20, 3]
        },
		{
            "symbol" : "10",
            "multipliers" : [50, 20, 3]
        },
        {
            "symbol" : "SC",
            "multipliers": [0,0,5,1]
        }
    ],
      "featureWinTable" : null,
      "playLines" : [
		[1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [0, 0, 1, 2, 2], // 6
        [2, 2, 1, 0, 0], // 7
        [1, 0, 1, 2, 1], // 8
        [1, 2, 1, 0, 1], // 9
        [1, 0, 0, 1, 0], // 10
        [1, 2, 2, 1, 2], // 11
        [0, 1, 0, 0, 1], // 12
        [2, 1, 2, 2, 1], // 13
        [0, 2, 0, 2, 0], // 14
        [2, 0, 2, 0, 2], // 15
        [1, 0, 2, 0, 1], // 16
        [1, 2, 0, 2, 1], // 17
        [0, 1, 1, 1, 0], // 18
        [2, 1, 1, 1, 2], // 19
        [0, 2, 2, 2, 0], // 20
        [2, 0, 0, 0, 2], // 21
        [0, 1, 2, 2, 2], // 22
        [2, 1, 0, 0, 0], // 23
        [2, 0, 1, 0, 2], // 24
        [0, 2, 1, 2, 0], // 25
        [0, 0, 2, 0, 0], // 26
        [2, 2, 0, 2, 2], // 27
        [0, 0, 2, 2, 2], // 28
        [2, 2, 0, 0, 0], // 29
        [2, 2, 2, 2, 1], // 30
        [0, 0, 0, 0, 1], // 31
        [0, 0, 0, 0, 2], // 32
        [0, 2, 2, 2, 2], // 33
        [1, 1, 1, 1, 0], // 34
        [1, 1, 1, 1, 2], // 35
        [1, 1, 1, 0, 2], // 36
        [1, 1, 1, 2, 0], // 37
        [0, 0, 0, 2, 1], // 38
        [2, 2, 2, 0, 1], // 39
        [2, 2, 2, 1, 0], // 40
        [0, 0, 0, 1, 2], // 41
        [2, 2, 2, 0, 2], // 42
        [0, 0, 0, 2, 0], // 43
        [1, 1, 0, 2, 1], // 44
        [1, 1, 2, 0, 1], // 45
        [1, 2, 2, 0, 2], // 46
        [0, 2, 2, 0, 0], // 47
        [2, 0, 2, 2, 1], // 48
        [0, 2, 0, 0, 1], // 49
        [2, 2, 1, 2, 1]  // 50
    ],

        
        "reels" : [
            ["A","PIC3","10","PIC2","A","PIC1","10","PIC3","K","PIC2","10","PIC3","A","SC","J","PIC4","K","A","PIC4","K","PIC3","10","Q","PIC1","A","PIC4","J","PIC3","K","PIC2","A","PIC1","10","J","PIC2","10","SC","K","J","PIC2","Q"],
            ["K","PIC4","J","PIC3","10","J","PIC4","A","Q","PIC2","K","PIC1","Q","PIC3","J","PIC2","K","10","PIC1","Q","WILD","10","PIC1","J","PIC2","10","A","PIC3","J","PIC4","K","Q","PIC4","10","K","WILD","Q","PIC1","K","PIC4","A","PIC3","Q","10","PIC2","Q","PIC4","J","WILD","Q","K","PIC3","J","PIC2","A","PIC3","J","K","A","PIC4","K","A","J","PIC1","K","A","J","PIC3","10"],
            ["PIC4","A","PIC2","Q","WILD","A","PIC4","10","Q","J","WILD","10","Q","SC","J","K","PIC4","10","PIC3","A","WILD","10","J","A","PIC1","10","J","WILD","K","10","SC","K","PIC2","10","Q","PIC2","J","PIC3","Q","PIC4","A","PIC3","Q","J","PIC2","K","PIC3","A","J","PIC4","Q","10","SC","Q","J","PIC1","K","J","PIC4","Q","J","PIC1","Q","SC","J","PIC1","Q","PIC2","J","SC","Q","10","PIC1","J"],
            ["Q","K","10","WILD","J","PIC2","A","K","PIC1","10","PIC4","K","PIC2","J","PIC1","10","Q","PIC2","A","10","WILD","K","10","PIC3","A","Q","PIC2","A","K","PIC1","10","PIC2","A","Q","PIC1","J","K","WILD","10","J","PIC4","A","Q","PIC3","J","PIC4","Q","A","K","WILD","10","J","Q","PIC4","10","PIC3","Q","J","PIC4","K","A","PIC2","Q","PIC3","10","PIC1","J","K","PIC3"],
            ["PIC2","10","A","PIC3","Q","PIC2","K","J","PIC1","10","PIC2","J","PIC3","A","J","SC","Q","10","PIC1","K","PIC2","Q","10","PIC2","A","PIC3","10","PIC1","K","PIC3","J","SC","Q","PIC4","A","SC","Q","PIC2","J","K","PIC4","10","A","J","PIC1","Q","J","SC","Q","J","PIC1","Q","PIC4","A","PIC3","J","SC","Q","10","PIC4","A"]
        ],
      "featureReels" : [ ],
      "featureReelsStops" : null,
      "defaultSelectLine" : 50,
      "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
      "coinType4Values" : [ 1, 2, 5, 10, 50 ],
      "coinType1_min_bet" : 250,
      "coinType1_max_bet" : 100000,
      "coinType1_spin_max_bet" : 5000000,
      "coinType4_min_bet" : 1,
      "coinType4_max_bet" : 50,
      "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
      }, {
        "name" : "normal2",
        "threshold" : 15
      }, {
        "name" : "normal3",
        "threshold" : 25
      }, {
        "name" : "bigwin",
        "threshold" : 50
      }, {
        "name" : "megawin",
        "threshold" : 150
      }, {
        "name" : "epicwin",
        "threshold" : 2147483647
      } ],
      "coinType4_spin_max_bet" : 2500,
      "flavor" : "93",
      "currency_symbol" : "$",
      "normal_count" : "2"
    },
    "modelId" : "vgw020",
    "gameSessionId" : 1,
    "reelStripPositions" : [ 18, 2, 22, 1, 29 ],
    "slotWindow" : [ [ "A", "PIC4", "Q" ], [ "PIC2", "K", "SC" ], [ "PIC3", "J", "10" ], [ "SCAT", "Q", "PIC4" ], [ "J", "9", "10" ] ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 206529343,
    "sweeps_deposit_balance" : 200001395,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 6527948,
    "sweeps_available_balance" : 6527948,
    "serverTime" : "2018-02-21 13:09:43"
};
