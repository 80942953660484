/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {

        "modelId": "tf108",
        "version": "1.0",
    
        "betMultiplier" : 20,
        "reelsLayout" : [ 4, 4, 4 ],
        "scatterSymbol" : "S",
        "wildSymbol" : "Z",
    
        "defaultFreeSpinCount": 8,
        "alreadyFreeSpinCount": 4,
        "countToTriggerRespin" : 6,
        "countSymbolsWin" : 5,
        "jackpotMultiplier" : 888,
        "countJackpotWin" : 12,
        "winCapMulti": -1,
    
        "playLines": [],
    
        "winTable" : [ {
          "symbol" : "S",
          "multipliers" : [ 0 ]
        }, {
          "symbol" : "B",
          "multipliers" : [ 5 ]
        }, {
          "symbol" : "C",
          "multipliers" : [ 4 ]
        }, {
          "symbol" : "D",
          "multipliers" : [ 3 ]
        }, {
          "symbol" : "E",
          "multipliers" : [ 3 ]
        }, {
          "symbol" : "A",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "K",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "Q",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "J",
          "multipliers" : [ 1 ]
        }, {
          "symbol" : "T",
          "multipliers" : [ 1 ]
        }, {
          "symbol" : "N",
          "multipliers" : [ 1 ]
        } ],
    
        "reels" : [ 
          ["A","A","K","K","N","N","J","J","T","T","D","D","C","C","N","N","S","S","K","K","B","B","E","E","T","T","A","A","E","E","N","N","Q","Q","J","J","A","A","S","S","T","T","N","N","E","E","K","K","J","J","T","T","N","N","Q","Q","S","S","D","D","C","C","J","J","T","T","K","K","J","J","N","N","Q","Q","A","A","E","E","K","K","J","J","D","D","C","C","Q","Q","T","T","N","N","A","A","B","B","D","D","Q","Q","T","T","Q","Q"],
          ["B","B","Q","Q","S","S","T","T","D","D","A","A","E","E","Z","Z","J","J","T","T","A","A","J","J","N","N","Q","Q","T","T","Q","Q","C","C","E","E","T","T","J","J","K","K","D","D","N","N","D","D","K","K","A","A","Q","Q","E","E","Z","Z","C","C","J","J","N","N","S","S","C","C","N","N","D","D","Z","Z","Q","Q","T","T","J","J","Z","Z","E","E","B","B","N","N","T","T","K","K","S","S","N","N","K","K","A","A","N","N","A","A","K","K","Q","Q","Z","Z","J","J","T","T"],
          ["N","N","Q","Q","K","K","Q","Q","D","D","E","E","D","D","E","E","A","A","C","C","T","T","N","N","D","D","K","K","A","A","S","S","J","J","Q","Q","T","T","Q","Q","Z","Z","N","N","J","J","S","S","T","T","J","J","N","N","Q","Q","S","S","D","D","N","N","K","K","A","A","J","J","N","N","C","C","Z","Z","A","A","K","K","S","S","N","N","Z","Z","J","J","K","K","B","B","T","T","Z","Z","T","T","E","E","T","T","Q","Q","T","T","E","E","A","A","B","B","J","J","C","C","S","S","N","N","Q","Q","K","K","Q","Q","D","D","E","E","J","J","D","D","E","E","A","A","C","C","T","T","N","N","D","D","K","K","A","A","S","S","J","J","Q","Q","T","T","Q","Q","Z","Z","N","N","J","J","T","T","J","J","N","N","Q","Q","S","S","D","D","N","N","K","K","A","A","J","J","N","N","C","C","Z","Z","A","A","S","K","K","N","N","Z","Z","J","J","K","K","B","B","T","T","Z","Z","T","T","E","E","T","T","S","Q","Q","T","T","E","E","A","A","B","B","J","J","C","C"],
          ["N","N","Q","Q","K","K","Q","Q","D","D","E","E","D","D","E","E","A","A","C","C","T","T","N","N","D","D","K","K","A","A","S","S","J","J","Q","Q","T","T","Q","Q","Z","Z","N","N","J","J","E","E","T","T","J","J","N","N","Q","Q","S","S","D","D","N","N","K","K","A","A","J","J","N","N","C","C","Z","Z","A","A","K","K","S","S","N","N","Z","Z","J","J","K","K","B","B","T","T","Z","Z","T","T","E","E","T","T","Q","Q","T","T","E","E","A","A","B","B","J","J","C","C","S","S","N","N","Q","Q","K","K","Q","Q","D","D","E","E","J","J","D","D","E","E","A","A","C","C","T","T","N","N","D","D","K","K","A","A","E","E","J","J","Q","Q","T","T","Q","Q","Z","Z","N","N","J","J","T","T","J","J","N","N","Q","Q","A","A","D","D","N","N","K","K","A","A","J","J","N","N","C","C","Z","Z","A","A","S","K","K","N","N","Z","Z","J","J","K","K","B","B","T","T","E","E","T","T","S","Q","Q","T","T","E","E","A","A","B","B","J","J","C","C","T","T","A","A","J","J","D","D","E","E"]
        ],
    
        "bonusMulti": [
          { "name": "Grand", "multi":	1000 },
          { "name": "Major", "multi": 100 },
          { "name": "Minor","multi": 25 },
          { "name": "Mini","multi": 10 },
        ],
    
        "coinType1Values" : [ 500, 1000, 2000, 3000, 5000, 10000, 20000, 40000, 100000, 200000 ],
        "coinType4Values" : [ 1, 2, 4, 6, 10, 20, 40, 80, 200, 400 ],
        "coinType1_min_bet" : 500,
        "coinType1_max_bet" : 200000,
        "coinType1_spin_max_bet" : 5000000,
        "coinType4_min_bet" : 1,
        "coinType4_max_bet" : 400,
        "coinType4_spin_max_bet" : 10000,
    
        "winLevels" : [ {
          "name" : "normal1",
          "threshold" : 5
        }, {
          "name" : "normal2",
          "threshold" : 15
        }, {
          "name" : "normal3",
          "threshold" : 25
        }, {
          "name" : "bigwin",
          "threshold" : 50
        }, {
          "name" : "megawin",
          "threshold" : 150
        }, {
          "name" : "epicwin",
          "threshold" : 2147483647
        } ],
    
    
        "featureStopGroupProb": [
          {"option": 0, "weight": 18},
          {"option": 1, "weight": 32},
          {"option": 2, "weight": 8},
        
          {"option": 3, "weight": 32},
          {"option": 4, "weight": 36},
          {"option": 5, "weight": 4},
        
          {"option": 6, "weight": 32},
          {"option": 7, "weight": 26},
          {"option": 8, "weight": 16},
        
          {"option": 9,"weight": 24},
          {"option": 10,"weight": 24},
          {"option": 11,"weight": 12},
        
          {"option": 12,"weight": 18},
          {"option": 13,"weight": 8},
          {"option": 14,"weight": 6},
        
          {"option": 15,"weight": 5},
          {"option": 16,"weight": 6},
          {"option": 17,"weight": 5},
        
          {"option": 18,"weight": 1},
          {"option": 19,"weight": 1},
          {"option": 20,"weight": 1},
        ],
    
    }
}
