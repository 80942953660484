import * as ingenuity033 from "./slots/ingenuity033/state";
import * as vgw005 from "./slots/vgw005/state";
import * as vgw005_p from "./slots/vgw005_p/state";
import * as vgw008 from "./slots/vgw008/state";
import * as vgw013 from "./slots/vgw013/state";
import * as vgw014 from "./slots/vgw014/state";
import * as vgw016 from "./slots/vgw016/state";
import * as vgw017 from "./slots/vgw017/state";
import * as vgw018 from "./slots/vgw018/state";
import * as vgw021 from "./slots/vgw021/state";
import * as vgw022 from "./slots/vgw022/state";
import * as vgw024 from "./slots/vgw024/state";
import * as vgw025 from "./slots/vgw025/state";
import * as vgw026 from "./slots/vgw026/state";
import * as vgw027 from "./slots/vgw027/state";
import * as vgw028 from "./slots/vgw028/state";
import * as vgw033 from "./slots/vgw033/state";
import * as vgw030 from "./slots/vgw030/state";
import * as vgw031 from "./slots/vgw031/state";
import * as vgw032 from "./slots/vgw032/state";
import * as vgw034 from "./slots/vgw034/state";
import * as vgw035 from "./slots/vgw035/state";
import * as vgw036 from "./slots/vgw036/state";
import * as vgw040 from "./slots/vgw040/state";
import * as vgw042 from "./slots/vgw042/state";
import * as vgw041 from "./slots/vgw041/state";
import * as vgw045 from "./slots/vgw045/state";
import * as vgw046 from "./slots/vgw046/state";
import * as vgw050 from "./slots/vgw050/state";
import * as vgw053 from "./slots/vgw053/state";
import * as vgw054 from "./slots/vgw054/state";
import * as vgw056 from "./slots/vgw056/state";
import * as vgw057 from "./slots/vgw057/state";
import * as vgw060 from "./slots/vgw060/state";
import * as vgw064 from "./slots/vgw064/state";
import * as vgw065 from "./slots/vgw065/state";
import * as vgw066 from "./slots/vgw066/state";
import * as vgw075 from "./slots/vgw075/state";
import * as vgw080 from "./slots/vgw080/state";
import * as vgw082 from "./slots/vgw082/state";
import * as vgw086 from "./slots/vgw086";
import * as vgw088 from "./slots/vgw088";
import * as vgw089 from "./slots/vgw089";
import * as vgw091 from "./slots/vgw091/state";
import * as vgw092 from "./slots/vgw092";
import * as vgw096 from "./slots/vgw096";
import * as vgw097 from "./slots/vgw097";
import * as questOfThe3Lamps from "./slots/quest-of-the-3-lamps";
import * as starCatchJackpots from "./slots/star-catch-jackpots";
import * as ghostFaceLives from "./slots/ghost-face-lives";
import * as dealOrNoDeal from "./slots/deal-or-no-deal";
import * as bigBoss from "./slots/bigBoss/state";
import * as dragonsDen from "./slots/dragonsden/state";
import * as mayanGold from "./slots/mayangold/state";
import * as mauiMagic from "./slots/mauimagic/state";
import * as valkyrie from "./slots/valkyrie/state";
import * as vgw020 from "./slots/vgw020/state";
import * as amazonia from "./slots/amazonia/state";
import * as crazybunny from "./slots/crazybunny/state";
import * as pf002_samureel from "./slots/pf002_samureel/state";
import * as pf003_monsoon from "./slots/pf003_monsoon/state";
import * as flambingo from "./bingo/flambingo/state";
import * as pf005_pugroyale from "./slots/pf005_pugroyale/state";
import * as pf006_fishandchips from "./slots/pf006_fishandchips/state";
import * as hanselandgretel from "./slots/hanselandgretel/state";
import * as vgw037 from "./slots/vgw037/state";
import * as pf007_vikingodyssey from "./slots/pf007_vikingodyssey/state";
import * as tf001_spellofodin from "./slots/tf001_spellofodin/state";
import * as tf002_celticgoddess from "./slots/tf002_celticgoddess/state";
import * as pf008_reelberryblast from "./slots/pf008_reelberryblast/state";
import * as tf003_medusasgoldengaze from "./slots/tf003_medusasgoldengaze/state";
import * as pf009_outerspaceinvaders from "./slots/pf009_outerspaceinvaders/state";
import * as rv001_squish from "./slots/rv001_squish/state";
import * as pf010_gustavoelluchador from "./slots/pf010_gustavoelluchador/state";
import * as tf004_sandprincess from "./slots/tf004_sandprincess/state";
import * as grs001_volatile from "./slots/grs001_volatile/state";
import * as rl001_europeaneoulette from "./roulettes/rl001_europeanroulette/state";
import * as rl002_americanroulette from "./roulettes/rl002_americanroulette/state";
import * as backblackjack from "./cards/backblackjack/state";
import * as rp001_busterHammerCarnival from "./slots/rp001_busterHammerCarnival/state";
import * as vgw047 from "./slots/vgw047/state";
import * as vgw048 from "./slots/vgw048/state";
import * as tf005_legendof9suns from "./slots/tf005_legendof9suns/state";
import * as rp002_hypernovaMegaways from "./slots/rp002_hypernovaMegaways/state";
import * as rl003_americanroulettex2 from "./roulettes/rl003_americanroulettex2/state";
import * as rp003_eldorado from "./slots/rp003_eldorado/state";
import * as rl004_europeanroulettex2 from "./roulettes/rl004_europeanroulettex2/state";
import * as ingenuity031 from "./slots/ingenuity031/state";
import * as ingenuity032 from "./slots/ingenuity032/state";
import * as tf102 from "./cards/tf102/state";
import * as ingenuity034 from "./slots/ingenuity034/state";
import * as tf101 from "./slots/tf101/state";
import * as tf103 from "./slots/tf103/state";
import * as ingenuity036 from "./slots/ingenuity036/state";
import * as vgw070 from "./slots/vgw070/state";
import * as vgw076 from "./slots/vgw076/state";
import * as vgw077 from "./slots/vgw077/state";
import * as ingenuity037 from "./slots/ingenuity037/state";
import * as tf104 from "./slots/tf104/state";
import * as tf105 from "./slots/tf105/state";
import * as tf106 from "./slots/tf106/state";
import * as vgw078 from "./slots/vgw078";
import * as tf107 from "./slots/tf107/state";
import * as tf108 from "./slots/tf108/state";
import * as vgw044 from "./slots/vgw044";
import * as vgw073 from "./slots/vgw073";
import * as tf109 from "./slots/tf109/state";
import * as tf110 from "./slots/tf110/state";
import * as tf111 from "./slots/tf111/state";
import * as tf112 from "./slots/tf112/state";
import * as tf113 from "./slots/tf113/state";
import * as tf114 from "./slots/tf114/state";
import * as vgw071 from "./slots/vgw071";
import * as vgw051 from "./slots/vgw051";
import * as tf116 from "./slots/tf116/state";
import * as tf115 from "./slots/tf115/state";
import * as tf117 from "./slots/tf117/state";
import * as tf118 from "./dice/tf118/state";
import * as tf119 from "./slots/tf119/state";
import * as tf120 from "./slots/tf120/state";
import * as tf121 from "./slots/tf121/state";
import * as tf122 from "./slots/tf122/state";
import * as tf123 from "./slots/tf123/state";
import * as vgw067 from "./slots/vgw067";
import * as vgw099 from "./slots/vgw099";
import * as tf125 from "./roulettes/tf125_amroulette/state";
import * as tf124 from "./slots/tf124/state";
import * as tf126 from "./slots/tf126/state";
import * as tf128 from "./slots/tf128/state";
import * as tf129 from "./slots/tf129/state";
import * as tf127 from "./slots/tf127/state";
import * as tf130 from "./slots/tf130/state";
import * as patriotParty from "./slots/patriotParty/state";

export default {
  goldenTides: ingenuity032,
  aztecTempleTreasures: ingenuity033,
  charmingWilds: vgw013,
  gemHuntress: vgw014,
  theGemHuntress: vgw014,
  nefertitisGold: vgw014,
  monsterSpins: vgw016,
  classicSpins: vgw017,
  dragonsRiver: vgw021,
  theBigGoreela: vgw022,
  wildGold: vgw018,
  chilliMamas: vgw018,
  duskmoonFaire: vgw005,
  duskmoonFaire_p: vgw005_p,
  alaskanFortune: vgw024,
  reelinnRockin: vgw025,
  stampedeFury: vgw028,
  lightningNudge: vgw033,
  goldenEagles: vgw031,
  hallowsWilds: vgw024,
  drVoltage: vgw032,
  dragonsDen: dragonsDen,
  mayanBounty: mayanGold,
  mauiMagic: mauiMagic,
  vgw_2by2_002: valkyrie,
  kittyGlamour: vgw020,
  diamondPanther: vgw036,
  theDiamondPanther: vgw036,
  goldenOz: vgw040,
  amazonia: amazonia,
  safariSelfies: vgw027,
  crazybunny: crazybunny,
  samureel: pf002_samureel,
  monsoonDragons: pf003_monsoon,
  aztecWealth: vgw030,
  longZhiBaoZang: vgw035,
  greatHeist: vgw053,
  lastEmpress: vgw060,
  superHot7s: vgw026,
  flamBingo: flambingo,
  pugRoyal: pf005_pugroyale,
  bombShellJackpots: vgw034,
  fishAndChips: pf006_fishandchips,
  hanselAndGretel: hanselandgretel,
  rioRevelry: vgw037,
  vikingOdyssey: pf007_vikingodyssey,
  spellOfOdin: tf001_spellofodin,
  celticGoddess: tf002_celticgoddess,
  reelBerryBlast: pf008_reelberryblast,
  medusasGoldenGaze: tf003_medusasgoldengaze,
  wildRoads: vgw041,
  disco: vgw042,
  squish: rv001_squish,
  gustavoElLuchador: pf010_gustavoelluchador,
  pearlsOfFortune: vgw008,
  outerspaceInvaders: pf009_outerspaceinvaders,
  bigBoss: bigBoss,
  cupidsFortune: vgw056,
  mariachiMagnifico: vgw046,
  vikingsNorth: vgw045,
  theStallion: vgw047,
  dancingGold: vgw048,
  crusadersGold: vgw057,
  sandPrincess: tf004_sandprincess,
  europeanRoulette: rl001_europeaneoulette,
  americanRoulette: rl002_americanroulette,
  volatileGRS: grs001_volatile,
  backblackjack: backblackjack,
  westernGold: vgw054,
  busterHammerCarnival: rp001_busterHammerCarnival,
  legendOf9Suns: tf005_legendof9suns,
  luckyShow: vgw050,
  hypernovaMegaways: rp002_hypernovaMegaways,
  americanRouletteX2: rl003_americanroulettex2,
  elDorado: rp003_eldorado,
  europeanRouletteX2: rl004_europeanroulettex2,
  wildKrakatoa: ingenuity031,
  reallyReelyRich: vgw064,
  krakensBounty: vgw065,
  classicBlackjack: tf102,
  megaWinvasion: ingenuity034,
  bigBucksBandits: tf101,
  slotsOfBeer: vgw066,
  wildWildWest2120Deluxe: tf103,
  piratesPlenty: ingenuity036,
  shakaShaka: vgw070,
  seasonsJoy: vgw076,
  stampedeFury2: vgw077,
  dynamiteRiches: ingenuity037,
  odinInfinityReels: tf104,
  zodiacInfinityReels: tf105,
  burgerBoss: vgw075,
  vickyVentura: tf106,
  cascadeBeats: vgw078,
  atlantis10kways: tf107,
  hypernovaInfinityReels: tf108,
  fuQianQian: vgw044,
  wildWhiskers: vgw073,
  bananaz10kways: tf109,
  mysteryReels: tf110,
  theTrollsTreasure: tf111,
  yosemite: tf112,
  aztecQuest: tf113,
  imperialKoi: vgw071,
  mega7: tf114,
  jadesJackpots: vgw080,
  goblinGrand: vgw051,
  shamrock10kways: tf116,
  expandasaurus: vgw082,
  americanStacks: vgw086,
  vivaFrostVegas: vgw088,
  roaringStrike: vgw089,
  jackpotCatch: vgw091,
  popNPaysMore: tf115,
  arrr10kways: tf117,
  craps: tf118,
  theGodfather: tf119,
  wonders10kways: tf120,
  frankiesHoliday: tf121,
  luckyCat: tf122,
  queenOfTheSea: tf123,
  candyLand: vgw092,
  blackTideFortunes: vgw096,
  drumFestival: vgw097,
  ghostFaceLives,
  dealOrNoDeal,
  starCatchJackpots,
  theGoldenCheese: vgw067,
  freaky13: vgw099,
  amRoulette: tf125,
  nineFlags: tf124,
  cheers: tf126,
  rockyHorrorShow: tf128,
  prosperityFortunes: tf129,
  starTrek: tf127,
  nineGoldenHearts: tf130,
  questOfThe3Lamps,
  patriotParty,
};
