import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace, wrapIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 3;
    public reel = "";
    public reelsType = "";
    public isOpen = false;
    public isActive = false;
    public isFsStep = false;
    public internal_name = "";
    public isBase = false;
    public levelNo = -1;
    public extraReels = 0;
    public isGreen = 0;
    public isFSTrigger = false;
    public reelIds = [];
    public fsNum = 0;
    public fsMulti = 0;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isActive = false;
        this.levelNo = step.json.levelNo ? parseInt(step.json.levelNo) : -1;
        this.extraReels = step.json.extraReels ? step.json.extraReels : 0;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.isGreen = step.json.isGreen ? parseInt(step.json.isGreen) : 0;
        this.fsMulti = step.json.fsMulti ? parseInt(step.json.fsMulti) : 0;
        this.fsNum = step.json.fsNum ? parseInt(step.json.fsNum) : 0;

        if(step.json.internal_name == "FS") {
            this.isFSTrigger = false;
            this.isFsStep = true;
            this.isBase = true;
        } else if(step.json.internal_name == "Base") {
            this.isFSTrigger = false;
            this.isFsStep = false;
            this.isBase = true;
        }else if(step.json.internal_name == "FS Trigger") {
            this.isFSTrigger = true;
            this.isFsStep = false;
            this.isBase = true;
        }


        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

    }
    @Watch("extraReels")
    public textChanged(val) {
        this.extraReels = Number(val);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getBaseStep("reels"));
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.isFsStep = false;
        this.isGreen = 2;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep("FS Trigger", this.reelsType));
    }

    public addFreeSpinStep() {
        const state = State.state;
        this.isFsStep = true;
        let fsName = "FS step";
        this.reelsType = "FS";
        bus.$emit(events.ADD_STEP, state.getFSStep(fsName, this.reelsType));
    }

    public updateStep(isGreen, levelNo, extraReels) {
        this.step.json.levelNo = parseInt(levelNo);
        this.step.json.extraReels = parseInt(extraReels);
        if(isGreen) {
            this.step.json.isGreen = parseInt(isGreen);
        }
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public changeMulti()
    {
        this.step.json.fsMulti = this.fsMulti;
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public changeFsNum()
    {
        this.step.json.fsNum = this.fsNum;
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }


}
