var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFeatureTriggerStep,
                expression: "canAddFeatureTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFeatureTriggerStep,
                expression: "canAddFeatureTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addHoldAndSpinTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Hold and Spin")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "reel-feature-bar flex-center" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMultiplier,
                expression: "showMultiplier",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelFeatureMultiplier,
                    expression: "reelFeatureMultiplier",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reelFeatureMultiplier = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option"),
                _vm._v(" "),
                _vm._l(_vm.multipliers, function (mult) {
                  return [
                    _c("option", { domProps: { value: mult } }, [
                      _vm._v("x" + _vm._s(mult)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select-style" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addedWilds,
                  expression: "addedWilds",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.addedWilds = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _vm._l(_vm.wildsOptions, function (opts) {
                return [
                  _c("option", { domProps: { value: opts } }, [
                    _vm._v(_vm._s(opts)),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showShakyShaky,
                expression: "showShakyShaky",
              },
            ],
            staticClass: "select-style",
          },
          [
            _vm._v("\n            ShakyShaky: "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.shakyShaky,
                  expression: "shakyShaky",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.shakyShaky)
                  ? _vm._i(_vm.shakyShaky, null) > -1
                  : _vm.shakyShaky,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.shakyShaky,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.shakyShaky = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.shakyShaky = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.shakyShaky = $$c
                  }
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReelStripPositions,
              expression: "showReelStripPositions",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", { attrs: { content: this, index: 0 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 1 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 2 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 3 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 4 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 5 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, isTopReel: true } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canShowMultiplierSection,
              expression: "canShowMultiplierSection",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c(
            "div",
            { staticClass: "multiplier-section" },
            [
              _c("div", { staticClass: "shared title space column" }, [
                _vm._v("MULTIPLIER COIN\n            "),
                _c("div", { staticClass: "select-style" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.multiplierCoin,
                          expression: "multiplierCoin",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.multiplierCoin = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _vm._l(_vm.multiplierCoins, function (mult, index) {
                        return [
                          _c("option", { domProps: { value: index } }, [
                            _vm._v(_vm._s(index + 1) + "." + _vm._s(mult)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddMultiplierCoin,
                        expression: "canAddMultiplierCoin",
                      },
                    ],
                    staticClass: "space button",
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addMultiplierCoin()
                          },
                        },
                      },
                      [_vm._v("\n                    Add +\n                ")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._l([0, 1, 2, 3, 4, 5, 6], function (i) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMultiplierTab(i),
                        expression: "showMultiplierTab(i)",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showMultiplierTab(i),
                            expression: "showMultiplierTab(i)",
                          },
                        ],
                        staticClass: "shared tabs column",
                      },
                      [
                        _c("div", { staticClass: "select-style" }, [
                          _c(
                            "select",
                            {
                              domProps: {
                                value: _vm.getMultiplierCoinMultipliers(i),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setMultiplierCoinMultipliers(
                                    i,
                                    $event.target.value
                                  )
                                },
                              },
                            },
                            [
                              _vm._l(_vm.multiplierCoinValues, function (mult) {
                                return [
                                  _c("option", { domProps: { value: mult } }, [
                                    _vm._v(_vm._s(mult)),
                                  ]),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "select-style" }, [
                          _c(
                            "select",
                            {
                              domProps: {
                                value: _vm.getMultiplierCoinAppliedCell(i),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setMultiplierCoinAppliedCell(
                                    i,
                                    $event.target.value
                                  )
                                },
                              },
                            },
                            [
                              _vm._l(
                                _vm.coinsThatCanBeMultiplied,
                                function (col) {
                                  return [
                                    _c("option", { domProps: { value: col } }, [
                                      _vm._v(_vm._s(col)),
                                    ]),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "space button",
                            on: {
                              click: function ($event) {
                                return _vm.removeMultiplierCoin(i)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Remove ❌\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canShowMultiplierSection,
              expression: "canShowMultiplierSection",
            },
          ],
          staticClass: "note-text flex-center",
        },
        [
          _vm._v(
            "\n            NOTE: The first number will be the reel from 0 - 6, with 6 being the top reel.\n            The second number is the symbol from 0 - 3, with 0 being the top symbol.\n        "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }