import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import { GenieBonusPhaseSchema, CoinPrizesSchema, GenieBonusLamps } from "../shared/index";
import { JackpotWinCounts } from "../shared/genie-bonus";

export const GameResponseSchema = Type.Object({
    playAmount: Type.Integer(),
    winAmount: Type.Integer(),
    cumulativeWinAmount: Type.Number(),
    coinAmount: Type.Integer(),
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),

    lineWins: Type.Array(
        Type.Object({
            playLineIndex: Type.Integer(),
            symbol: Type.String(),
            length: Type.Number(),
            multiplier: Type.Number(),
            winAmount: Type.Number(),
        }),
    ),
    scatterWin: Type.Optional(
        Type.Object({
            cells: Type.Array(Type.Tuple([Type.Integer(), Type.Integer()])),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
        }),
    ),
    winType: Type.Union([
        Type.Literal('NO_WIN'),
        Type.Literal('NORMAL_WIN'),
        Type.Literal('SCATTER_WIN'),
        Type.Literal('FREE_SPIN_NO_WIN'),
        Type.Literal('FREE_SPIN_WIN'),
        Type.Literal('JACKPOT_WIN'),
    ]),
    genieBonusPhase: Type.Optional(GenieBonusPhaseSchema),
    genieBonusCount: Type.Optional(Type.Integer()),
    coinPrizes: CoinPrizesSchema,
    coinPrizesThisSpin: CoinPrizesSchema,
    genieBonusLamps: Type.Optional(GenieBonusLamps),
    jackpotWinCounts: Type.Optional(JackpotWinCounts),
    mysterySymbol: Type.String(),
    winCells: Type.Optional(Type.Array(Type.Array(Type.Integer()))),
});
export type GameResponse = Static<typeof GameResponseSchema>;
