/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.reelsType = "reels";
            return step;
        },
        getLayout: () =>{
            State.state.getModelDefinition().reelsLayout.join("-");
        } ,
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getReelStrips: (type) => {
            console.log(type);
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "featureReels": 
                    return State.state.getModelDefinition().featureReels;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: function() {
            const step = State.state.createStep([14, 23, 24, 25, 11], false, 0, "", "Feature Trigger 3 FS");
            step.reelsType = "reels";
            return step;
        },
        
        getFeatureStep: function(){
            const step = State.state.createStep([0,0,0,0,0], true, 0, "", "Feature Step");
            step.reelsType = "featureReels";
            return step;
        },
        
        getFeatureReTriggerStep: function(){
            const step = State.state.createStep([13,23,24,25,11], true, 0, "", "Feature Re-trigger");
            step.reelsType = "featureReels";
            return step;
        },
        
        prepareStepForDB: (step) => {
            console.log(step);
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = (step.reelsType == "reels" || step.reelsType == "featureReels") ? 5: 5;
            const numOfRows = step.reelsType == "reels" ? 4:5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), numOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
