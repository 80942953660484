var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("Base")
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FSTrigger")
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FreeSpinStep")
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("BonusTrigger")
              },
            },
          },
          [_vm._v("Bonus Trigger")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bonusOption,
              expression: "bonusOption",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
            _c("div", { staticClass: "select-style" }, [
              _c("label", { attrs: { for: "bg_number" } }, [
                _vm._v("Ship number: "),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shipNo,
                      expression: "shipNo",
                    },
                  ],
                  staticStyle: {
                    color: "#00c700",
                    "font-weight": "bold",
                    "font-size": "small",
                    "padding-left": "50px",
                  },
                  attrs: { id: "bg_number" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.shipNo = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "11" } }, [_vm._v("11")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "13" } }, [_vm._v("13")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "14" } }, [_vm._v("14")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "16" } }, [_vm._v("16")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "17" } }, [_vm._v("17")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-center fs-selector sm-space" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "elemOK pointer",
                class: [_vm.isActive ? "blue" : "elemOK"],
                attrs: { id: "ok" },
                on: {
                  click: function ($event) {
                    return _vm.updateStep(_vm.shipNo)
                  },
                },
              },
              [_vm._v("OK")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }