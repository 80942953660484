/* tslint:disable:variable-name */
import State from '../../../state';
import init from './config/init';

export function setStateParams() {
    return {
        variations: [''],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Dice roll');
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => 0,
        getNumberOfRows: () => 0,
        getReelStrips: () => [],
        getFeatureReelStrips: () => [],
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureStep: () => {},

        getClearingStep: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, '', 'Clear');
            step.gameState = 'clear';
            return step;
        },

        prepareStepForDB: step => {
            return step;
        }
    };
}
