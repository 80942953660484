import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from './../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import Reel from './reels/reel.vue';

@Component({
    components: {
        reel: Reel,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = '';
    public isOpen = false;
    public reelsNo = 5;
    public freeSpinWheelResult = 0;

    constructor() {
        super();
    }

    @Watch('step')
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if (step.json.freeSpinWheelResult === null || step.json.freeSpinWheelResult === undefined) {
            this.freeSpinWheelResult = 0;
        } else {
            this.freeSpinWheelResult = step.json.freeSpinWheelResult;
        }
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public mounted() {
        this.isOpen = false;
        trace('reels mounted');
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep());
    }

    public addClearStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateWheelResult(event) {
        this.step.json.freeSpinWheelResult = parseInt(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
