import type { Position } from "../shared/index";

export const getAvailablePositions = (slotWindow: string[][], occupiedPositions: Position[]): Position[] => {
    return new Array(slotWindow.length)
        .fill(undefined)
        .map((_, reelIndex) => [0, 1, 2].map((rowIndex) => [rowIndex, reelIndex]))
        .flat()
        .filter(
            (position) =>
                occupiedPositions.find(
                    (featurePosition) => featurePosition[0] === position[0] && featurePosition[1] === position[1],
                ) === undefined,
        ) as Position[];
};
