/* tslint:disable:variable-name */
import State from '../../../state';
import init from './config/init';

export function setStateParams() {
    return {
        variations: [''],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Reels');
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reelsFreespin,
        getBaseStep: () => State.state.createDefaultStep(),
        addExtraDataToStep: (step) => {
            step.freeSpinWheelResult = 0;
            return step;
        },
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([2, 5, 15, 12, 1], false, 0, '', 'Free Spins trigger');
            return step;
        },
        getFeatureStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Free Spins step');
            return step;
        },
        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Clear');
            step.gameState = 'clear';
            return step;
        },
    };
}
