import { mathModel } from "../../../v3";
type WinType = mathModel.WinType;

/** Returns the most appropriate win type for the given spin outcome details. */
export function getWinType(info: {
  totalWinAmount: number;
  isFreeSpinTrigger?: boolean;
  isFreeSpin?: boolean;
  isBoardGameSpin?: boolean;
  isJackpotTrigger?: boolean;
}): WinType {
  if (info.isJackpotTrigger) return "JACKPOT_WIN";
  else if (info.isBoardGameSpin) return "BONUS_WIN";
  else if (info.isFreeSpinTrigger) return "SCATTER_WIN";
  else if (info.isFreeSpin)
    return info.totalWinAmount > 0 ? "FREE_SPIN_WIN" : "FREE_SPIN_NO_WIN";
  return info.totalWinAmount > 0 ? "NORMAL_WIN" : "NO_WIN";
}
