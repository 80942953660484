import type { Choices } from '../../../../choices/choices';
import { modelDefinition } from '../../../../model-definition';

export function validateFeatureResults({
    featureId,
    lineWinTotalAfterFeatures,
    playAmount,
    lineWinTotalBeforeFeatures,
    choices,
}: {
    featureId: number;
    lineWinTotalAfterFeatures: number;
    playAmount: number;
    lineWinTotalBeforeFeatures: number;
    choices: Choices;
}) {
    if (lineWinTotalBeforeFeatures >= lineWinTotalAfterFeatures) return false;

    const reelSpinChanceInHundredWeights = modelDefinition.reelSpinChanceInHundredWeights;
    const winMultiplierMaxRanges = reelSpinChanceInHundredWeights.winMultiplierMaxRange;
    const newLineWinIntermsOfTotalPlay = lineWinTotalAfterFeatures / playAmount;
    const newLineWinIntermsOfTotalPlayRangeIndex = winMultiplierMaxRanges.findIndex(
        (range) => range >= newLineWinIntermsOfTotalPlay,
    );

    if (newLineWinIntermsOfTotalPlayRangeIndex === -1) {
        return false;
    }

    const featureWeights = reelSpinChanceInHundredWeights.featureWeights[featureId - 1];
    const shouldAwardReelSpinFeatures = choices.chooseWhetherReelSpinFeaturesApplied(
        featureWeights[newLineWinIntermsOfTotalPlayRangeIndex],
        100,
    );

    return shouldAwardReelSpinFeatures;
}
