import { createLineWinChecker, filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/choices';
import { modelDefinition } from '../../../model-definition/index';
import type { WinInfo } from '../create-wins-evaluator';
import { type GameResponse } from '../game-response';

export function evaluateBankerScatFeature(
    slotWindow: string[][],
    winInfo: WinInfo,
    choices: Choices,
    isFreeSpin: boolean,
): GameResponse['bankerScat'] {
    // Determine if banker SCAT is awarded.
    const scatCount = slotWindow.flat().filter((symbol) => symbol === 'SCAT').length;
    const caseCount = slotWindow.flat().filter((symbol) => symbol === 'CASE').length;
    const isBankerScatAwarded =
        scatCount === 3 &&
        caseCount < 6 &&
        winInfo.winAmount - (winInfo.scatterWin?.winAmount ?? 0) === 0 &&
        choices.chooseIfBankerScatIsAwarded(isFreeSpin);
    if (!isBankerScatAwarded) return;

    // Determine which SCAT symbol in the slot window to replace.
    const candidateCells = filterSlotWindow(slotWindow, (symbol) => symbol === 'SCAT');
    const cell = choices.chooseBankerScatCell(candidateCells);

    // Determine the replacement symbol.
    const candidateSymbols = ['A', 'K', 'Q', 'J', 'T', 'N'].filter((symbol) => {
        // Never choose a symbol that appears in the slot window column where the replacement is being made,
        // since that would create stack of two suit symbols, which would never occur naturally.
        const [_, col] = cell;
        if (slotWindow[col].includes(symbol)) return false;

        // Never choose a symbol that would add a line win.
        const newSlotWindow = slotWindow.map((r) => r.slice());
        newSlotWindow[cell[1]][cell[0]] = symbol;
        const lineWins = evaluateLineWins(newSlotWindow);
        return lineWins.length === 0;
    });
    if (candidateSymbols.length === 0) return;
    const symbol = choices.chooseBankerScatSymbol(candidateSymbols);
    return { cell, symbol };
}

const evaluateLineWins = createLineWinChecker(modelDefinition);
