import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from './../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import Reel from './reels/reel.vue';
import SelectScatterMultiplier from './components/selectScatterMultiplier.vue';

@Component({
    components: {
        reel: Reel,
        selectScatterMultiplier: SelectScatterMultiplier
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = '';
    public isOpen = false;
    public reelsNo = 5;
    public scattersNumber = 30;
    public prizeIndices = new Array(16).fill(-1);
    public baseReelHeightOne = 4;
    public baseReelHeightTwo = 4;
    public baseReelHeightThree = 4;
    public baseReelHeightFour = 4;
    public baseReelHeightFive = 4;
    private baseReelHeights = [4, 4, 4, 4, 4];
    private featureReelHeights = [4, 5, 5, 5, 5];
    private prizeList = [0.6, 1, 1.2, 1.8, 2, 2.4, 3, 3.6, 4, 5, 10, 15, 20, 25, 50, 100, 'MINOR', 'MAJOR', 'MEGA'];

    constructor() {
        super();
    }

    @Watch('step')
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public mounted() {
        this.isOpen = false;
        trace('reels mounted');
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFreeSpinTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addFreeSpinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep());
    }

    public updateBaseReelHeight(index, value) {
        if (!this.step.json.reelHeights)
            this.step.json.reelHeights = this.step.json.isFeature ? this.featureReelHeights : this.baseReelHeights;
        this.step.json.reelHeights[index] = Number(value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBaseReelsHeights() {
        if (!this.step.json.reelHeights)
            this.step.json.reelHeights = this.step.json.isFeature ? this.featureReelHeights : this.baseReelHeights;
        this.baseReelHeightOne = this.step.json.reelHeights[0];
        this.baseReelHeightTwo = this.step.json.reelHeights[1];
        this.baseReelHeightThree = this.step.json.reelHeights[2];
        this.baseReelHeightFour = this.step.json.reelHeights[3];
        this.baseReelHeightFive = this.step.json.reelHeights[4];
    }

    public resetAll() {
        this.step.json.prizeIndices = new Array(this.scattersNumber).fill(-1);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updatePopPrizeIndices(event) {
        if (this.step.json.prizeIndices == null) {
            this.step.json.prizeIndices = new Array(this.scattersNumber).fill(-1);
        }
        if (this.step.json.popPrizes == null) {
            this.step.json.popPrizes = new Array(this.scattersNumber).fill(-1);
        }

        let value = event.target.value;
        if (event.target.value >= 0) {
            value = this.prizeList[event.target.value];
        }
        this.step.json.prizeIndices[event.target.id - 1] = event.target.value;
        this.step.json.popPrizes[event.target.id - 1] = value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.prizeIndices != null) {
            this.prizeIndices = this.step.json.prizeIndices;
        } else {
            this.prizeIndices = new Array(this.scattersNumber).fill(-1);
        }
    }
}
