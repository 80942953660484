import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace, wrapIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 3;
    public reel = "";
    public reelsType = "";
    public isOpen = false;
    public isActive = false;
    public isFsStep = false;
    public internal_name = "";
    public isBase = false;
    public levelNo = -1;
    public extraReels = 0;
    public isFSTrigger = false;
    public reelIds = [];

    public wheel0Index = -1;
    public wheel1Index = -1;
    public wheel2Index = -1;
    public wheel3Index = -1;
    public wheel4Index = -1;


    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isActive = false;
        this.levelNo = step.json.levelNo ? parseInt(step.json.levelNo) : -1;
        this.extraReels = step.json.extraReels ? step.json.extraReels : 0;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        if(step.json.internal_name == "FS") {
            this.isFSTrigger = false;
            this.isFsStep = true;
            this.isBase = true;
        } else if(step.json.internal_name == "Base") {
            this.isFSTrigger = false;
            this.isFsStep = false;
            this.isBase = true;
        }else if(step.json.internal_name == "FS Trigger") {
            this.isFSTrigger = true;
            this.isFsStep = false;
            this.isBase = true;


            this.wheel0Index = (step.json.wheelIndices && step.json.wheelIndices.length==5) ? step.json.wheelIndices[0] : -1;
            this.wheel1Index = (step.json.wheelIndices && step.json.wheelIndices.length==5) ? step.json.wheelIndices[1] : -1;
            this.wheel2Index = (step.json.wheelIndices && step.json.wheelIndices.length==5) ? step.json.wheelIndices[2] : -1;
            this.wheel3Index = (step.json.wheelIndices && step.json.wheelIndices.length==5) ? step.json.wheelIndices[3] : -1;
            this.wheel4Index = (step.json.wheelIndices && step.json.wheelIndices.length==5) ? step.json.wheelIndices[4] : -1;
        }


        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

    }
    @Watch("extraReels")
    public textChanged(val) {
        this.extraReels = Number(val);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getBaseStep("reels"));
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.isFsStep = false;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep("FS Trigger", this.reelsType));
    }

    public addFreeSpinStep() {
        const state = State.state;
        this.isFsStep = true;
        let fsName = "FS step";
        this.reelsType = "FS";
        bus.$emit(events.ADD_STEP, state.getFSStep(fsName, this.reelsType));
    }

    public updateStep(levelNo, extraReels) {
        this.step.json.levelNo = parseInt(levelNo);
        this.step.json.extraReels = parseInt(extraReels);
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public changeWheel0Index()
    {
        this.setWheelIndex( 0, this.wheel0Index );
    }
    public changeWheel1Index()
    {
        this.setWheelIndex( 1, this.wheel1Index );
    }
    public changeWheel2Index()
    {
        this.setWheelIndex( 2, this.wheel2Index );
    }
    public changeWheel3Index()
    {
        this.setWheelIndex( 3, this.wheel3Index );
    }
    public changeWheel4Index()
    {
        this.setWheelIndex( 4, this.wheel4Index );
    }
    private setWheelIndex( index: number, value )
    {
        if (this.step.json.wheelIndices == undefined || this.step.json.wheelIndices.length != 5)
            this.step.json.wheelIndices = [-1,-1,-1,-1,-1];

        this.step.json.wheelIndices[index] = parseInt(value);
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}
