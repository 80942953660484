export function createClearingStep(): Vgw088StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [52, 112, 31, 42, 55],
  };
}

export function getBaseStep(): Vgw088StepJson {
  return {
    name: "Base",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 112, 31, 42, 55],
    addedWilds: [],
  };
}

export function getSnowBlastTrigger(): Vgw088StepJson {
  return {
    name: "Base SB",
    originatorId,
    gameState: "clear",
    reelStripPositions: [52, 112, 31, 42, 55],
    addedWilds: [],
  };
}

export function getWheelSpinStep(): Vgw088StepJson {
  return {
    name: "Wheel Spin",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 112, 31, 42, 55],
    wheelSelection: { 'type': 'START' },
  };
}

export function getJackpotSpinStep(): Vgw088StepJson {
  return {
    name: "Jackpot Spin",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 112, 31, 42, 55],
    jackpotType: "MINI"
  };
}

export function getFreeSpinStep(): Vgw088StepJson {
  return {
    name: "Free Spin",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 112, 31, 42, 55],
  };
}

export function createSpinStep(): Vgw088StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 112, 31, 42, 55],
  };
}

export function getFreeSpinTriggerFromBaseGame(): Vgw088StepJson {
  return {
    name: "FS Trigger",
    originatorId,
    gameState: "clear",
    reelStripPositions: [29, 104, 178, 151, 117],
  };
}
export function getFreeSpinTriggerFromFreeSpinGame(): Vgw088StepJson {
  return {
    name: "FS Trigger",
    originatorId,
    gameState: "continue",
    reelStripPositions: [67, 69, 67, 96, 19],
  };
}


export const originatorId = new Date().getTime();

interface WheelSpinFreeSpin {
  type: 'FREE_SPINS';
  value: number;
  winningSegmentIndex: number;
}

interface WheelSpinCoinPrize {
  type: 'COIN_PRIZE';
  value: number;
  winningSegmentIndex: number;
}

interface WheelSnowBlastUpgrade {
  type: 'SNOW_BLAST_LEVEL_UPGRADE';
  value: number;
}

interface WheelStart {
  type: 'START';
}

export type JackpotType = 'GRAND' | 'MAJOR' | 'MINI' | 'MINOR';

interface WheelJackpot {
  type: 'JACKPOT';
}

export interface Vgw088StepJson {
  addedWilds?: number[][];
  name: string;
  originatorId: number;
  gameState: 'clear' | 'continue';
  reelStripPositions: number[];
  wheelSelection?: WheelSpinFreeSpin | WheelSpinCoinPrize | WheelSnowBlastUpgrade | WheelStart | WheelJackpot;
  jackpotType?: JackpotType;
}