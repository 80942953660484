export const modelDefinition = {
  modelId: 'vgw080',
  gameIds: ['jadesJackpots'],
  coinType1Values: [100, 200, 500, 1000, 2000, 4000, 6000, 10000],
  coinType4Values: [1, 2, 5, 10, 20, 40, 60, 100],
  coinSizeMultiplier: 25,
  reelsLayout: [4, 4, 4, 4, 4],
  wildSymbol: 'WILD',
  scatterSymbol: 'SCAT',
  freeSpinsAwarded: [20, 15, 10],

  winTable: [
      {
          symbol: 'PIC1',
          multipliers: [200, 50, 20],
      },
      {
          symbol: 'PIC2',
          multipliers: [125, 40, 15],
      },
      {
          symbol: 'PIC3',
          multipliers: [100, 30, 10],
      },
      {
          symbol: 'PIC4',
          multipliers: [75, 25, 10],
      },
      {
          symbol: 'A',
          multipliers: [20, 10, 2],
      },
      {
          symbol: 'K',
          multipliers: [20, 10, 2],
      },
      {
          symbol: 'Q',
          multipliers: [15, 5, 2],
      },
      {
          symbol: 'J',
          multipliers: [15, 5, 2],
      },
      {
          symbol: '10',
          multipliers: [10, 5, 2],
      },
      {
          symbol: '9',
          multipliers: [10, 5, 2],
      },
      {
          symbol: 'SCAT',
          multipliers: [100, 10, 2],
      },
  ],

  // This is a ways game, so there are zero lines.
  playLines: [],

  /* eslint-disable */
  reels: [
      ['PIC4', 'Q', '10', 'J', 'J', 'K', 'PIC3', 'J', 'PIC4', 'Q', 'A', 'J', 'J', 'PIC2', 'Q', 'J', '9', 'K', 'Q', 'PIC4', '9', 'PIC2', 'Q', 'Q', 'J', 'PIC3', '10', '9', 'Q', 'PIC4', 'A', 'J', 'J', 'SCAT', 'A', 'Q', 'Q', 'PIC3', '10', 'PIC1', 'J', 'Q', 'PIC2', '10', 'Q', 'Q', 'Q', 'PIC1', '9', '9', 'PIC3', 'J', 'J', '10', '10', 'K', 'J', '9'],
      ['10', 'SCAT', '9', 'J', '10', 'PIC3', '9', 'PIC4', 'J', 'K', 'PIC2', '10', '9', 'PIC4', 'Q', 'PIC1', 'PIC1', 'PIC1', '10', '9', 'PIC3', 'K', 'Q', 'PIC1', 'A', '10', 'K', 'PIC3', 'A', 'PIC2', 'K', 'K', 'PIC4', 'PIC4', 'PIC4', 'K', '9', 'A', 'SCAT', '10', '10', 'A', 'WILD', 'WILD', 'WILD', 'WILD', 'K', 'K', '10', '10', '10', 'A', 'PIC2', 'PIC2', 'PIC2', 'A', '9', '10', 'PIC1', 'K', 'K', 'K', 'PIC4', 'A', 'A', 'A', '9', 'PIC3', 'PIC3', 'PIC3', '10', 'K', '9', '9', '9', 'PIC4', 'A', 'A', 'A', 'PIC1', '9', '9', 'PIC2'],
      ['10', 'SCAT', 'J', 'J', 'PIC3', '9', 'PIC1', 'A', '10', 'K', 'SCAT', 'J', '10', 'Q', 'WILD', 'WILD', 'WILD', 'WILD', 'A', 'Q', 'PIC4', '9', '9', 'PIC1', '10', 'PIC3', '9', 'J', 'PIC4', 'A', '10', 'PIC2', 'PIC2', 'PIC2', 'Q', 'Q', 'Q', 'PIC4', 'A', 'A', 'A', 'PIC2', 'K', 'K', 'K', 'PIC3', 'K', 'Q', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', '9', 'PIC4', 'K', 'PIC1', '9', 'PIC2', 'Q', 'PIC4', '10', 'J', 'K', 'PIC2', 'K', '9', 'PIC3', 'PIC3', 'PIC3', 'K', 'J', '9', 'PIC2', 'A', 'J', 'Q', 'PIC3', '10', 'PIC4', 'PIC4', 'PIC4', 'A', '10'],
      ['PIC3', 'PIC3', 'PIC3', '10', 'Q', 'Q', 'PIC4', 'PIC4', 'J', '10', 'Q', '9', 'PIC1', 'PIC1', 'PIC1', 'A', 'A', 'A', 'PIC2', 'Q', '10', 'PIC3', '9', 'K', 'K', 'PIC2', '9', 'PIC3', 'A', 'A', '10', 'PIC4', 'PIC4', 'PIC4', 'K', '9', 'SCAT', '10', 'PIC4', 'J', 'PIC1', 'A', 'PIC3', 'K', 'K', 'K', 'PIC1', '9', 'J', 'SCAT', 'K', 'Q', 'A', 'WILD', 'WILD', 'WILD', 'WILD', 'J', 'A', 'PIC3', '9', 'K', 'PIC4', 'A', 'A', 'A', 'PIC2', 'J', 'J', 'PIC4', '10', 'K', 'PIC2', 'PIC2', 'PIC2', '9', 'Q', 'J', 'PIC1', '10', 'K', 'A', 'PIC2', 'Q', 'K'],
      ['PIC3', 'K', 'Q', '10', 'PIC4', 'J', 'K', '10', 'PIC2', 'Q', 'PIC3', 'K', 'K', '9', 'PIC1', 'A', '10', 'J', 'PIC4', '9', 'PIC1', '10', 'J', 'PIC4', '9', 'A', 'SCAT', '9', 'Q', 'PIC3', 'K', 'PIC2', '10', '9', '9', 'PIC4', 'Q', 'J', 'A', 'PIC3', '10', 'K', 'J', 'SCAT', '10', 'A', 'A', 'PIC3', '9', 'PIC2', 'K', 'A', 'Q', 'PIC1', 'A', '9', 'PIC4', '10', 'J', 'J', 'PIC2', 'K', 'Q', 'Q', 'PIC4', 'A', 'Q', 'J']
  ],

  coinType1_min_bet: 100,
  coinType1_max_bet: 10_000,
  coinType1_spin_max_bet: 250_000,
  coinType4_min_bet: 1,
  coinType4_max_bet: 100,
  coinType4_spin_max_bet: 2_500,

  jackpotTriggers: {
    'PIC1': 'GRAND',
    'PIC2': 'MAJOR',
    'PIC3': 'MINOR',
    'PIC4': 'MINI',
} as const,
};