var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonusGame } },
      [
        _c("option", { attrs: { value: "-1" } }, [_vm._v("None")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1" } }, [_vm._v("Cliff's Cards")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2" } }, [_vm._v("Coach's Cold Ones")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3" } }, [_vm._v("Rebecca's Rewards")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "4" } }, [
          _vm._v("Carla's Crazy Choice"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "5" } }, [_vm._v("Diane's Destiny")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "6" } }, [_vm._v("Sam's Super Spins")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "7" } }, [
          _vm._v("Fraiser's Freespins"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "8" } }, [
          _vm._v("Norm's Enormous Jackpots"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }