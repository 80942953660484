export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";

  // TODO: Replace this with the scenario step definition from the
  // math model
  reelStripPositions?: Array<number>;
  mysterySymbol?: string;
  wildFury?: boolean;
  wildMultipliers?: Array<number | 'MINI' | 'MINOR' | 'MAJOR' | 'GRAND'>;
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [4, 4, 4, 4, 4],
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [4, 4, 4, 4, 4],
  };
}

export function createWildFury(): StepJson {
  return {
    name: "WF Trigger",
    originatorId,
    gameState: "clear",
    reelStripPositions: [4, 4, 4, 4, 4],
    wildFury: true
  };
}

export function createFreeSpinTrigger(): StepJson {
  return {
    name: "FS Trigger",
    originatorId,
    gameState: "clear",
    reelStripPositions: [4, 23, 40, 18, 4],
  };
}


export const originatorId = new Date().getTime();
