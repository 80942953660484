// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card[data-v-09ffa6c9] {
  display: flex;
  gap: 1ch;
}
.card.red[data-v-09ffa6c9] {
  background-color: rgb(253, 118, 118);
}
.card.purple[data-v-09ffa6c9] {
  background-color: rgb(204, 153, 255);
}
.card.yellow[data-v-09ffa6c9] {
  background-color: rgb(255, 255, 153);
}
.card.blue[data-v-09ffa6c9] {
  background-color: rgb(153, 204, 255);
}
.card.orange[data-v-09ffa6c9] {
  background-color: rgb(255, 204, 153);
}
.card.green[data-v-09ffa6c9] {
  background-color: rgb(153, 255, 153);
}
.card.pink[data-v-09ffa6c9] {
  background-color: rgb(255, 153, 204);
}
.card-type[data-v-09ffa6c9] {
  color: #28282B;
  font-size: 18px; 
  font-weight: bold;
  padding-right: 20px;
}
.card-colour[data-v-09ffa6c9] {
  color: #28282B;
  font-size: 18px; 
  font-weight: bold; 
  padding-right: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/board-game-controls/card/card.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".card[data-v-09ffa6c9] {\n  display: flex;\n  gap: 1ch;\n}\n.card.red[data-v-09ffa6c9] {\n  background-color: rgb(253, 118, 118);\n}\n.card.purple[data-v-09ffa6c9] {\n  background-color: rgb(204, 153, 255);\n}\n.card.yellow[data-v-09ffa6c9] {\n  background-color: rgb(255, 255, 153);\n}\n.card.blue[data-v-09ffa6c9] {\n  background-color: rgb(153, 204, 255);\n}\n.card.orange[data-v-09ffa6c9] {\n  background-color: rgb(255, 204, 153);\n}\n.card.green[data-v-09ffa6c9] {\n  background-color: rgb(153, 255, 153);\n}\n.card.pink[data-v-09ffa6c9] {\n  background-color: rgb(255, 153, 204);\n}\n.card-type[data-v-09ffa6c9] {\n  color: #28282B;\n  font-size: 18px; \n  font-weight: bold;\n  padding-right: 20px;\n}\n.card-colour[data-v-09ffa6c9] {\n  color: #28282B;\n  font-size: 18px; \n  font-weight: bold; \n  padding-right: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
