import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import selectCoinMultiplier from "./reels/selectCoinMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        selectCoinMultiplier: selectCoinMultiplier
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 5;
    public reel = "";
    public reelsType = "";
    public isOpen = false;
    public isActive = false;
    public isFsStep = false;
    public internal_name = "";
    public featureType = 0;
    public coinNumber = 1;
    public isBase = false;
    public coinValue = new Array(9).fill(0);


    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isActive = false;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.featureType = step.json.featureType ? step.json.featureType : 0;
        if(step.json.internal_name == "FS Step") {
            this.isFsStep = true;
            this.isBase = true;
        } else {
            this.isFsStep = false;
            this.isBase = true;
        }

        if(step.json.internal_name == "Bonus Trigger") {
            this.isOpen = true;
            this.coinNumber = step.json.coinNumber ? step.json.coinNumber : 1;
            this.coinValue = step.json.coinValue ? step.json.coinValue : new Array(this.coinNumber).fill(0);;
        }

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        this.isFsStep = false;
        bus.$emit(events.ADD_STEP, state.setBaseStep());
    }

    public updateStep(featureType) {
        this.isActive = true;
        this.step.json.reelStripPositions = this.step.json.reelStripPositions ? this.step.json.reelStripPositions: [0,5,58,47,0];
        this.step.json.featureType = parseInt(featureType);
        this.step.json.coinNumber = this.coinNumber != this.step.json.coinNumber ? parseInt(this.coinNumber.toString()): this.step.json.coinNumber;
        this.step.json.coinValue = new Array(this.step.json.coinNumber).fill(0);
         bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.isFsStep = false;
        bus.$emit(events.ADD_STEP, state.setFSTriggerStep("FS Trigger") );
    }

    public addFreeSpinStep() {
        const state = State.state;
        this.isFsStep = true;
        bus.$emit(events.ADD_STEP, state.addFSStep("FS Step") );
    }

    public addBonusTrigger() {
        const state = State.state;
        this.isFsStep = true;
        bus.$emit(events.ADD_STEP, state.addBonusTrigger("Bonus Trigger") );
    }

    public updateBonusGameCoinMultipliers(event) {
        if (this.step.json.coinValue == null) {
            this.step.json.coinValue = new Array(this.coinNumber).fill(0);
        }

        this.step.json.coinValue[event.target.id - 1] = parseInt(event.target.value);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}
