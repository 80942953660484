// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-popup {
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.sm-popup .info-window {
    width: 400px;
    background-color: #535353;
    border: 1px solid #61ca50;
    color: white;
    padding: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}
.sm-popup .info-window .text {
    padding: 20px 0 20px 0;
}
.sm-popup .info-window .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.sm-popup .info-window .buttons .button {
    padding: 10px 20px 10px 20px;
    margin: 5px;
    text-transform: uppercase;
    font-weight: 300;
    background-color: #333;
    cursor: pointer;
    border: black;
}
.sm-popup .info-window .buttons .default {
    border: 1px solid #00FF00;
}`, "",{"version":3,"sources":["webpack://./src/components/popup/popup.vue.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;AAChB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,yCAAyC;IACzC,eAAe;AACnB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;AACA;IACI,4BAA4B;IAC5B,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".sm-popup {\n    position: absolute;\n    z-index: 10000;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n}\n.sm-popup .info-window {\n    width: 400px;\n    background-color: #535353;\n    border: 1px solid #61ca50;\n    color: white;\n    padding: 15px;\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: 16px;\n}\n.sm-popup .info-window .text {\n    padding: 20px 0 20px 0;\n}\n.sm-popup .info-window .buttons {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n.sm-popup .info-window .buttons .button {\n    padding: 10px 20px 10px 20px;\n    margin: 5px;\n    text-transform: uppercase;\n    font-weight: 300;\n    background-color: #333;\n    cursor: pointer;\n    border: black;\n}\n.sm-popup .info-window .buttons .default {\n    border: 1px solid #00FF00;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
