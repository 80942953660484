// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "vgw065",
        reelsLayout: [3, 3, 3, 3, 3],
        scatterSymbol: "SCAT",
        wildSymbol: "WILD",
        freeSpinMultiplier: 2,
        freeSpinCount: 10,
        winTable: [
            {
                symbol: "WILD",
                multipliers: [450, 75, 12],
            },
            {
                symbol: "PIC1",
                multipliers: [450, 75, 12],
            },
            {
                symbol: "PIC2",
                multipliers: [300, 45, 10],
            },
            {
                symbol: "PIC3",
                multipliers: [300, 45, 10],
            },
            {
                symbol: "PIC4",
                multipliers: [200, 40, 8],
            },
            {
                symbol: "PIC5",
                multipliers: [200, 40, 8],
            },
            {
                symbol: "PIC6",
                multipliers: [200, 40, 8],
            },
            {
                symbol: "HEART",
                multipliers: [150, 15, 5],
            },
            {
                symbol: "DIAMOND",
                multipliers: [150, 15, 5],
            },
            {
                symbol: "CLUB",
                multipliers: [150, 15, 5],
            },
            {
                symbol: "SPADE",
                multipliers: [150, 15, 5],
            },
            {
                symbol: "SCAT",
                multipliers: [100, 20, 5, 1],
            },
        ],
        playLines: [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],
            [0, 0, 1, 2, 2],
            [2, 2, 1, 0, 0],
            [1, 0, 1, 2, 1],
            [1, 2, 1, 0, 1],
            [1, 0, 0, 1, 0],
            [1, 2, 2, 1, 2],
            [0, 1, 0, 0, 1],
            [2, 1, 2, 2, 1],
            [0, 2, 0, 2, 0],
            [2, 0, 2, 0, 2],
            [1, 0, 2, 0, 1],
            [1, 2, 0, 2, 1],
            [0, 1, 1, 1, 0],
            [2, 1, 1, 1, 2],
            [0, 2, 2, 2, 0],
            [2, 0, 0, 0, 2],
            [0, 1, 2, 2, 2],
            [2, 1, 0, 0, 0],
            [2, 0, 1, 0, 2],
            [0, 2, 1, 2, 0],
            [0, 0, 2, 0, 0],
            [2, 2, 0, 2, 2],
            [0, 0, 2, 2, 2],
            [2, 2, 0, 0, 0],
            [2, 2, 2, 2, 1],
            [0, 0, 0, 0, 1],
            [0, 0, 0, 0, 2],
            [0, 2, 2, 2, 2],
            [1, 1, 1, 1, 0],
            [1, 1, 1, 1, 2],
            [1, 1, 1, 0, 2],
            [1, 1, 1, 2, 0],
            [0, 0, 0, 2, 1],
            [2, 2, 2, 0, 1],
            [2, 2, 2, 1, 0],
            [0, 0, 0, 1, 2],
            [2, 2, 2, 0, 2],
            [0, 0, 0, 2, 0],
            [1, 1, 0, 2, 1],
            [1, 1, 2, 0, 1],
            [1, 2, 2, 0, 2],
            [0, 2, 2, 0, 0],
            [2, 0, 2, 2, 1],
            [0, 2, 0, 0, 1],
            [2, 2, 1, 2, 1],
        ],
        reels: [
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "HEART",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "WILD",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "DIAMOND",
                "PIC5",
                "PIC6",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "HEART",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "WILD",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "PIC1",
                "HEART",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "DIAMOND",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "HEART",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC5",
            ],
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "HEART",
                "PIC4",
                "PIC5",
                "SCAT",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "PIC5",
            ],
        ],
        featureReels: [
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "HEART",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "WILD",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "DIAMOND",
                "PIC5",
                "PIC6",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "WILD",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "WILD",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "PIC1",
                "WILD",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC2",
                "PIC5",
            ],
            [
                "DIAMOND",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "WILD",
                "PIC4",
                "PIC5",
                "CLUB",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "HEART",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "DIAMOND",
                "PIC5",
            ],
            [
                "PIC6",
                "WILD",
                "PIC5",
                "SPADE",
                "PIC3",
                "PIC4",
                "DIAMOND",
                "WILD",
                "PIC4",
                "PIC5",
                "SCAT",
                "PIC4",
                "PIC1",
                "PIC1",
                "PIC1",
                "PIC2",
                "PIC2",
                "PIC2",
                "DIAMOND",
                "WILD",
                "PIC3",
                "PIC3",
                "PIC3",
                "CLUB",
                "PIC6",
                "PIC4",
                "SPADE",
                "SCAT",
                "CLUB",
                "PIC4",
                "PIC5",
            ],
        ],
        defaultSelectLine: 50,
        coinType1Values: [250, 2500, 10000, 50000, 100000],
        coinType4Values: [1, 2, 5, 10, 15, 20, 30, 50, 100],
        coinType1_min_bet: 250,
        coinType1_max_bet: 100000,
        coinType1_spin_max_bet: 5000000,
        coinType4_min_bet: 1,
        coinType4_max_bet: 50,
        coinType4_spin_max_bet: 5000,
        winLevels: [
            {
                name: "normal1",
                threshold: 5,
            },
            {
                name: "normal2",
                threshold: 15,
            },
            {
                name: "normal3",
                threshold: 20,
            },
            {
                name: "bigwin",
                threshold: 35,
            },
            {
                name: "megawin",
                threshold: 70,
            },
            {
                name: "epicwin",
                threshold: 2147483647,
            },
        ],
        freeSpinTrigger: "Badge",
        countToTriggerFreeSpin: 2147483647,
        fortuneCartPrizes: ["1", "2", "3", "4", "big wheel", "free spins"],
        whichBigWheel: ["bigWheelJackpots", "bigWheelPrizes"],
        bigWheelJackpots: ["mini", "minor", "grand"],
        bigWheelPrizes: ["4", "5", "10", "15", "25", "30", "50"],
        sweepsMiniInitialJackpotValue: 1000,
        sweepsMinorInitialJackpotValue: 5000,
        sweepsGrandInitialJackpotValue: 30000000,
        goldMiniInitialJackpotValue: 100000,
        goldMinorInitialJackpotValue: 500000,
        goldGrandInitialJackpotValue: 3000000000,
        currency_symbol: "$",
    },
    modelId: "vgw065",
    gameSessionId: 238,
    reelStripPositions: [3, 2, 27, 31, 17],
    gold_balance: 2013035000,
    sweeps_balance: 206530723,
    sweeps_deposit_balance: 199989245,
    sweeps_promo_balance: 0,
    sweeps_win_balance: 6541478,
    sweeps_available_balance: 6541478,
    miniJackpotPool: 1000,
    grandJackpotPool: 30000000,
    serverTime: "2020-02-20 18:47:52",
    minorJackpotPool: 5000,
};
