export interface StepJson {
    name: string;
    originatorId: number;
    gameState: 'clear' | 'continue';
    reelStripPositions: number[];
    coinPrizes?: CoinPrize[][];
    wildPics?: WildPic[];
    chests?: ChestPrize[];
}

export type Cell = [number, number];

export type CoinPrize = number | 'MINI' | 'MINOR' | 'MAJOR' | 'CHEST';

export type WildPic = {
    pos: Cell;
    type: 'PIC1' | 'PIC2';
    multiplier?: number;
    extraWilds?: Cell[];
};

export type ChestPrize = {
    type: 'A' | 'B' | 'C';
    standard: number[];
    special?: 'MINI' | 'MINOR' | 'BOOSTx1' | 'BOOSTx2' | 'BOOSTx3' | 'BOOSTx5' | 'BOOSTx10' | 'COLLECT'
};

export function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        originatorId,
        gameState: 'clear',
        reelStripPositions: [0, 0, 0, 0, 0, 0],
    };
}

export function createSpinStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [60, 60, 60, 60, 60, 60],
    };
}

export function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [4, 2, 122, 29, 25, 0],
    };
}

export function createFireshotTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [12, 73, 54, 82, 36, 84],
    };
}

export const originatorId = new Date().getTime();
