import { render, staticRenderFns } from "./list_of_steps.vue?vue&type=template&id=5b728f04"
import script from "./list_of_steps.vue.ts?vue&type=script&lang=ts&external"
export * from "./list_of_steps.vue.ts?vue&type=script&lang=ts&external"
import style0 from "./list_of_steps.vue.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports