const coinSizeMultiplier = 20;

export const modelDefinition = {
  modelId: "vgw091",
  gameIds: ["jackpotCatch"],
  coinType1Values: [25, 50, 500, 1000, 2500, 5000, 10000, 50000],
  coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2000],
  coinSizeMultiplier,
  reelsLayout: [3, 3, 3, 3, 3],
  wildSymbol: "WILD",
  scatterSymbol: "SCAT",
  coinSymbol: "COIN",
  freeSpinsAwarded: [30, 20, 10],

  coinType1_min_bet: 25,
  coinType1_max_bet: 50_000,
  coinType1_spin_max_bet: 50_000 * coinSizeMultiplier,
  coinType4_min_bet: 1,
  coinType4_max_bet: 2000,
  coinType4_spin_max_bet: 2000 * coinSizeMultiplier,

  winTable: [
    {
      symbol: "SCAT",
      multipliers: [100, 10, 2],
    },
    {
      symbol: "PIC1",
      multipliers: [100, 40, 20],
    },
    {
      symbol: "PIC2",
      multipliers: [50, 20, 10],
    },
    {
      symbol: "PIC3",
      multipliers: [50, 20, 10],
    },
    {
      symbol: "PIC4",
      multipliers: [40, 20, 5],
    },
    {
      symbol: "PIC5",
      multipliers: [40, 20, 5],
    },
    {
      symbol: "A",
      multipliers: [20, 4, 2],
    },
    {
      symbol: "K",
      multipliers: [20, 4, 2],
    },
    {
      symbol: "Q",
      multipliers: [20, 4, 2],
    },
    {
      symbol: "J",
      multipliers: [20, 4, 2],
    },
    {
      symbol: "10",
      multipliers: [20, 4, 2],
    },
  ],

  playLines: [], //ways game

  // prettier-ignore
  reels: [
        ['Q', 'WILD', 'J', 'PIC5', 'K', 'PIC2', 'A', 'WILD', '10', 'PIC3', 'J', 'WILD', 'K', 'PIC4', 'J', 'SCAT', '10', 'PIC3', 'A', 'PIC4', 'J', 'PIC1', 'K', 'PIC5', 'Q', 'WILD', 'A', 'PIC4', 'J', 'K', 'PIC2', 'Q', 'PIC1', 'J', 'PIC3', 'Q', 'WILD', 'J', 'PIC2', 'A', 'PIC3', 'K', 'PIC1', 'A', 'PIC5', 'J', 'PIC3', '10', 'PIC1', 'A', 'PIC2', 'Q', 'SCAT', '10', 'PIC4', 'J', 'PIC1', '10', 'PIC5', 'A', 'PIC2', 'J', 'WILD', 'A', 'PIC5', 'Q', 'PIC3', 'K', 'WILD', '10', 'PIC2', 'J', 'PIC4', 'K', 'PIC2', '10', 'SCAT', 'J', 'PIC5', 'Q', 'PIC4', '10', 'WILD', 'Q', 'PIC3', 'A', 'PIC2', 'J', 'PIC4', 'K', 'PIC2', 'Q', 'PIC3', 'K', 'PIC2', 'J', 'PIC5', '10', 'WILD', 'K', 'PIC5', 'Q', 'A', 'PIC3', '10', 'PIC2', 'A', 'SCAT', 'J', 'PIC4', '10', 'PIC3', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', 'PIC5', '10', 'PIC4'],
        ['PIC2', 'PIC3', 'Q', 'WILD', 'A', 'PIC4', '10', 'PIC2', 'A', 'SCAT', 'Q', 'PIC3', 'A', 'PIC1', '10', 'PIC3', 'K', 'PIC1', 'A', 'PIC3', '10', 'PIC5', 'A', 'PIC2', 'J', 'WILD', 'Q', 'PIC5', 'A', 'PIC4', 'J', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC2', 'J', 'SCAT', 'Q', 'PIC3', 'J', 'PIC1', 'A', 'PIC2', '10', 'PIC5', 'A', 'WILD', 'J', 'PIC4', 'K', 'PIC2', 'Q', 'WILD', 'K', 'PIC3', 'Q', 'PIC1', '10', 'WILD', 'A'],
        ['SCAT', '10', 'PIC5', 'PIC5', 'PIC5', 'K', 'COIN', 'A', 'PIC3', 'PIC3', 'PIC3', 'Q', 'PIC5', '10', 'PIC2', 'A', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'A', 'PIC3', '10', 'PIC4', 'A', 'Q', 'SCAT', 'J', 'PIC5', 'PIC5', 'PIC5', 'A', 'COIN', '10', 'PIC2', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'K', 'SCAT', '10', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC3', '10', 'COIN', 'COIN', 'COIN', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'COIN', 'A', 'COIN', 'Q', 'PIC5', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC1', 'Q', 'PIC4', 'J', 'COIN', 'K', 'COIN', 'A', 'COIN', 'Q', 'COIN', 'K', 'PIC4', '10', 'PIC1', 'Q', 'PIC4', 'K', 'PIC3', 'A'],
        ['PIC4', 'PIC4', 'PIC4', '10', 'COIN', 'A', 'PIC2', 'PIC2', 'PIC2', 'A', 'COIN', 'Q', 'J', 'PIC5', 'PIC5', 'PIC5', 'Q', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'Q', 'PIC2', '10', 'J', 'COIN', 'Q', 'PIC3', '10', 'PIC5', 'J', 'SCAT', 'K', 'PIC4', 'Q', 'PIC1', '10', 'PIC3', 'PIC3', 'PIC3', 'A', 'PIC5', 'K', 'PIC4', 'Q', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', '10', 'A', 'SCAT', 'J', 'PIC3', 'Q', 'K', 'COIN', '10', 'COIN', 'J', 'COIN', '10', 'PIC3', 'K', 'PIC1', '10', 'PIC4', 'J', 'PIC2', 'A', 'PIC1', 'J', 'PIC4', 'PIC4', 'PIC4', 'A', 'COIN', 'K', 'PIC5', 'PIC5', 'PIC5', 'K', 'SCAT', 'J', 'PIC4', '10', 'PIC2', 'PIC2', 'PIC2', 'J', 'COIN', 'COIN', 'COIN', 'A', 'J'],
        ['PIC2', 'PIC4', 'PIC4', 'PIC4', 'J', 'PIC5', 'PIC5', 'PIC5', 'A', 'PIC1', 'K', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'Q', 'PIC2', 'PIC2', 'PIC2', 'A', 'SCAT', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC2', 'PIC2', 'PIC5', 'A', 'COIN', 'K', 'PIC2', 'PIC1', 'A', 'COIN', 'COIN', 'COIN', 'A', 'PIC4', 'PIC2', 'Q', 'COIN', '10', 'PIC1', 'J', 'PIC3', 'PIC2', 'A', 'PIC1', 'PIC3', 'A', 'SCAT', 'K', 'PIC1', 'Q', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'COIN', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'SCAT', 'Q', 'PIC3', 'PIC3', 'PIC3', 'Q', 'PIC1', 'PIC2', '10', 'COIN', 'J', 'PIC3', 'PIC4', 'PIC4', 'PIC4', 'Q', 'PIC1', 'K']
    ],

  // prettier-ignore
  freeSpinReels: [
        ['Q', 'WILD', 'J', 'PIC5', 'K', 'PIC2', 'A', 'WILD', '10', 'PIC3', 'J', 'WILD', 'K', 'PIC4', 'J', 'SCAT', '10', 'PIC3', 'A', 'PIC4', 'J', 'PIC1', 'K', 'PIC5', 'Q', 'WILD', 'A', 'PIC4', 'J', 'K', 'PIC2', 'Q', 'PIC1', 'J', 'PIC3', 'Q', 'WILD', 'J', 'PIC2', 'A', 'PIC3', 'K', 'PIC1', 'A', 'PIC5', 'J', 'PIC3', '10', 'PIC1', 'A', 'PIC2', 'Q', 'WILD', '10', 'PIC4', 'J', 'PIC1', '10', 'PIC5', 'A', 'PIC2', 'J', 'WILD', 'A', 'PIC5', 'Q', 'PIC3', 'K', 'WILD', '10', 'PIC2', 'J', 'PIC4', 'K', 'PIC2', '10', 'SCAT', 'J', 'PIC5', 'Q', 'PIC4', '10', 'WILD', 'Q', 'PIC3', 'A', 'PIC2', 'J', 'PIC4', 'K', 'PIC2', 'Q', 'PIC3', 'K', 'PIC2', 'J', 'PIC5', '10', 'WILD', 'K', 'PIC5', 'Q', 'A', 'PIC3', '10', 'PIC2', 'A', 'SCAT', 'J', 'PIC4', '10', 'PIC3', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', 'PIC5', '10', 'PIC4'],
        ['PIC2', 'PIC3', 'Q', 'WILD', 'A', 'PIC4', '10', 'PIC2', 'A', 'Q', 'PIC3', 'A', 'PIC1', '10', 'PIC3', 'K', 'PIC1', 'A', 'PIC3', '10', 'PIC5', 'A', 'PIC2', 'J', 'WILD', 'Q', 'PIC5', 'A', 'PIC4', 'J', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC2', 'J', 'SCAT', '10', 'J', 'PIC5', 'A', 'WILD', 'J', 'PIC4', 'K', 'PIC2', 'Q', 'WILD', 'K', 'PIC3', 'Q', 'WILD', '10'],
        ['SCAT', '10', 'PIC5', 'PIC5', 'PIC5', 'K', 'COIN', 'A', 'PIC3', 'PIC3', 'PIC3', 'Q', 'PIC5', '10', 'PIC2', 'A', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'A', 'PIC3', '10', 'PIC4', 'A', 'Q', 'SCAT', 'J', 'PIC5', 'PIC5', 'PIC5', 'A', 'COIN', '10', 'PIC2', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'K', 'SCAT', '10', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC3', '10', 'COIN', 'COIN', 'COIN', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'COIN', 'A', 'COIN', 'Q', 'PIC5', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC1', 'Q', 'PIC4', 'J', 'COIN', 'K', 'COIN', 'A', 'COIN', 'Q', 'COIN', 'K', 'PIC4', '10', 'PIC1', 'Q', 'PIC4', 'K', 'PIC3', 'A'],
        ['PIC4', 'PIC4', 'PIC4', '10', 'COIN', 'A', 'PIC2', 'PIC2', 'PIC2', 'A', 'COIN', 'Q', 'J', 'PIC5', 'PIC5', 'PIC5', 'Q', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'Q', 'PIC2', '10', 'J', 'COIN', 'Q', 'PIC3', '10', 'PIC5', 'J', 'SCAT', 'K', 'PIC4', 'Q', 'PIC1', '10', 'PIC3', 'PIC3', 'PIC3', 'A', 'PIC5', 'K', 'PIC4', 'Q', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', '10', 'A', 'SCAT', 'J', 'PIC3', 'Q', 'K', 'COIN', '10', 'COIN', 'J', 'COIN', '10', 'PIC3', 'K', 'PIC1', '10', 'PIC4', 'J', 'PIC2', 'A', 'PIC1', 'J', 'PIC4', 'PIC4', 'PIC4', 'A', 'COIN', 'K', 'PIC5', 'PIC5', 'PIC5', 'K', 'SCAT', 'J', 'PIC4', '10', 'PIC2', 'PIC2', 'PIC2', 'J', 'COIN', 'COIN', 'COIN', 'A', 'J'],
        ['PIC2', 'PIC4', 'PIC4', 'PIC4', 'J', 'PIC5', 'PIC5', 'PIC5', 'A', 'PIC1', 'K', 'COIN', 'COIN', 'COIN', 'COIN', 'COIN', 'Q', 'PIC2', 'PIC2', 'PIC2', 'A', 'SCAT', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC2', 'PIC2', 'PIC5', 'A', 'COIN', 'K', 'PIC2', 'PIC1', 'A', 'COIN', 'COIN', 'COIN', 'A', 'PIC4', 'PIC2', 'Q', 'COIN', '10', 'PIC1', 'J', 'PIC3', 'PIC2', 'A', 'PIC1', 'PIC3', 'A', 'SCAT', 'K', 'PIC1', 'Q', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'COIN', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'SCAT', 'Q', 'PIC3', 'PIC3', 'PIC3', 'Q', 'PIC1', 'PIC2', '10', 'COIN', 'J', 'PIC3', 'PIC4', 'PIC4', 'PIC4', 'Q', 'PIC1', 'K'],
    ],

  winLevels: [
    { name: "normal1", threshold: 2 },
    { name: "normal2", threshold: 5 },
    { name: "normal3", threshold: 20 },
    { name: "bigwin", threshold: 35 },
    { name: "megawin", threshold: 70 },
    { name: "epicwin" },
  ],
  jackpots: {
    fixedMultipliers: {
      MINI: 1000,
      MINOR: 2500,
    },
    seedValues: {
      MAJOR: {
        GOLD: 125_000_000,
        SWEEPS: 5_000_000,
      },
    },
    percentToJackpotPool: {
      MAJOR: 0.5,
    },
  },
  coinWeights: { SWEEPS: 1, GOLD: 0.04 },
  coinPrizesWeights: {
    reel3: {
      40: 10,
      60: 5,
      80: 2,
      100: 1,
    },
    reel4: {
      100: 18,
      120: 13,
      160: 10,
      200: 5,
    },
    reel5: {
      200: 0,
      300: 0,
      400: 0,
      500: 0,
      MINI: 0,
      MINOR: 0,
      MAJOR: 0,
    },
  },
  reelsExpansionWeights: {
    baseSpin: [
      { outcome: 3, weight: 27 },
      { outcome: 4, weight: 10 },
      { outcome: 5, weight: 5 },
      { outcome: 6, weight: 5 },
    ],
    freeSpin: [
      { outcome: 3, weight: 23 },
      { outcome: 4, weight: 7 },
      { outcome: 5, weight: 5 },
      { outcome: 6, weight: 5 },
    ],
  },
  reelsLayoutByExpandedHeight: {
    4: [3, 3, 4, 4, 4],
    5: [3, 3, 5, 5, 5],
    6: [3, 3, 6, 6, 6],
  },
};
