import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public mystSymbolChanging = "H1";
    public columnChanging = 0;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public isOpen = false;
    public mystOption = false;
    public fsShow = false;
    public internal_name = "";

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.mystOption = false;
        this.fsShow = false
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
        const mystReelset = ['Base', 'FreeSpin Step', 'EF FreeSpin Step'];

        if (mystReelset.indexOf(step.json.internal_name) > -1) {
            this.mystOption = true

            if(step.json.internal_name == 'Base') {
                this.fsShow = true;
            }else{
                this.fsShow = false;
            }

            this.mystSymbolChanging = this.step.json.mystSymbolChanging ? this.step.json.mystSymbolChanging : "H1";
            this.columnChanging = this.step.json.columnChanging ? this.step.json.columnChanging.join() : "0";

        }
        if (step.json.internal_name == "FS Trigger") {
            this.mystOption = true;
            this.fsShow = true;
            this.mystSymbolChanging = this.step.json.mystSymbolChanging ? this.step.json.mystSymbolChanging : "FS";
            this.columnChanging = this.step.json.columnChanging ? this.step.json.columnChanging.join() : "0";

        }

    }

    public mounted() {
        this.reelsNo = 5;
        this.isOpen = false;
        this.mystOption = false;
        trace("reels mounted");
    }

    public addNewStep(typeStep) {
        const state = State.state;
        this.reelsNo = 5;
        switch(typeStep){
            case 'Base':
                bus.$emit(events.ADD_STEP, state.setBaseStep());
                break;
            case 'FSTrigger':
                bus.$emit(events.ADD_STEP, state.setFSTriggerStep());
                break;
            case 'FreeSpinStep':
                bus.$emit(events.ADD_STEP, state.addFSStep('FreeSpin Step'));
                break;
            case 'EFFreeSpinStep':
                bus.$emit(events.ADD_STEP, state.addFSStep('EF FreeSpin Step'));
                break;
            default:
                bus.$emit(events.ADD_STEP, state.getBaseStep());
        }
    }


    public updateBaseStep(mystChangingSymbol, columnChanging) {
        this.reelsNo = 5;
        this.internal_name = "typeStep";
        this.step.json.mystSymbolChanging = mystChangingSymbol;
        if(columnChanging != "0") {
            const arrColumn = columnChanging.split(',');
            this.step.json.columnChanging = arrColumn;
        }else {
            delete this.step.json.columnChanging;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}
