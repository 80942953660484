import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 5;
    public reel = "";
    public reelsType = "";
    public isOpen = false;
    public isActive = false;
    public isFsStep = false;
    public isSpellSymbol = false;
    public levelOption = true;
    public internal_name = "";
    public levelNo = 1;
    public isBase = false;
    public hammerNo = [];
    public wildHammerNo = [];


    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isActive = false;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.levelNo = step.json.levelNo ? step.json.levelNo : 1;
        if(step.json.internal_name == "FS Step") {
            this.isFsStep = true;
            this.isBase = false;
            this.wildHammerNo = step.json.wildHammerNo ? step.json.wildHammerNo : [1, 1, 1];
        } else {
            this.isFsStep = false;
            this.isBase = true;
            this.hammerNo = step.json.hammerNo ? step.json.hammerNo : [1, 1, 1];
        }

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep(buster) {
        const state = State.state;
        this.isFsStep = false;
        this.reelsType = "reels"+this.levelNo;
        bus.$emit(events.ADD_STEP, state.getBaseStep(buster, ("reels"+this.levelNo)));
    }

    public updateStep(levelNo, hammerNo) {
        switch(levelNo) {
            case 1:
                hammerNo[0] = 1;
                hammerNo[1] = parseInt(hammerNo[1]);
                hammerNo[2] = 1;
                break;
            case 2:
                hammerNo[0] = 1;
                hammerNo[1] = parseInt(hammerNo[1]);
                hammerNo[2] = parseInt(hammerNo[2]);
                break;
            case 3:
                hammerNo[0] = parseInt(hammerNo[0]);
                hammerNo[1] = parseInt(hammerNo[1]);
                hammerNo[2] = parseInt(hammerNo[2]);
        }
        this.isActive = true;

        this.step.json.levelNo = parseInt(levelNo);

        if(this.isFsStep) {
            this.step.json.reelStripPositions = this.step.json.reelStripPositions ? this.step.json.reelStripPositions: [0,7,7,7,0];
            this.step.json.wildHammerNo = hammerNo;
            this.step.json.reelsType = "reelsFeature"+levelNo;
        } else {
            this.step.json.reelStripPositions = this.step.json.reelStripPositions ? this.step.json.reelStripPositions: [0, 0, 0, 0, 0];
            this.step.json.hammerNo = hammerNo;
            this.step.json.reelsType = "reels"+levelNo;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.isFsStep = false;
        this.reelsType = "reels"+this.levelNo;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep("FS Trigger", this.reelsType));
    }

    public addFreeSpinStep(type) {
        const state = State.state;
        this.isFsStep = true;
        const fsName = type ? "FS Step Buster" : "FS Step";
        this.reelsType = "reelsFeature"+this.levelNo;
        bus.$emit(events.ADD_STEP, state.getFSStep(fsName, this.reelsType));
    }
}
