var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _c("label", { attrs: { for: "fruits_number" } }, [
            _vm._v("Forced number of Mystery Fruits:   "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forceMysteryFruit,
                  expression: "forceMysteryFruit",
                },
              ],
              staticStyle: { width: "50px" },
              attrs: { id: "fruits_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.forceMysteryFruit = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateMysteryFruitNumber(_vm.forceMysteryFruit)
                  },
                ],
              },
            },
            _vm._l(4, function (n) {
              return _c("option", [_vm._v(_vm._s(n - 1) + " ")])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _c("label", { attrs: { for: "bonus_number" } }, [
            _vm._v("Forced number of Bonus symbols:   "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forceBonus,
                  expression: "forceBonus",
                },
              ],
              staticStyle: { width: "50px" },
              attrs: { id: "bonus_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.forceBonus = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBonusNumber(_vm.forceBonus)
                  },
                ],
              },
            },
            _vm._l(16, function (n) {
              return _c("option", [_vm._v(_vm._s(n - 1) + " ")])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Reel set ID:   "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reelsetId,
                  expression: "reelsetId",
                },
              ],
              attrs: { id: "scatters_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reelsetId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updatereelsetId(_vm.reelsetId)
                  },
                ],
              },
            },
            _vm._l(8, function (n) {
              return _c("option", [_vm._v(_vm._s(n - 1) + " ")])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center select-swap" },
        [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Mystery Fruit swap symbol:   "),
          ]),
          _vm._v(" "),
          _vm._l(parseInt(_vm.forceMysteryFruit) ? 1 : 0, function (n) {
            return _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.swapSymbol,
                    expression: "swapSymbol",
                  },
                ],
                key: n,
                attrs: { index: n },
                domProps: { value: 1 },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.swapSymbol = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateSwapSymbol(_vm.swapSymbol)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("Random")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1 - Cherry")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2 - Lemon")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3 - Orange")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4 - Plum")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5 - Grape")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [
                  _vm._v("6 - Watermelon"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("7 - Bell")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "8" } }, [_vm._v("8 - Seven")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [
                  _vm._v("10 - Bonus"),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }