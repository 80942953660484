/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf128",

        reelsLayout: [3, 3, 3, 3, 3],
        freeSpinTrigger: 'S2',
        scatterSymbol: 'S2',
        holdAndWinSymbol: 'S1',
        wildSymbol: 'WD',
        emptySymbol: 'BLANK',
        coinSizeMultiplier: 25,
        respinsCount: 3,
        countToTriggerRespins: 6,
        totalSymbolNum: 15,
        countToTriggerFreeSpin: 3,
        countToRetriggerFreeSpin: 5,
        awarededFreeSpins: 5,
        awarededRetriggerFreeSpins: 3,


        playLines: [
            [0, 0, 0, 0, 0], // 1
            [2, 2, 2, 2, 2], // 2
            [1, 1, 1, 1, 1], // 3
            [0, 1, 2, 1, 0], // 4
            [2, 1, 0, 1, 2], // 5
    
            [1, 2, 2, 2, 1], // 6
            [0, 0, 1, 2, 2], // 7
            [2, 2, 1, 0, 0], // 8
            [1, 2, 1, 0, 1], // 9
            [1, 0, 1, 2, 1], // 10
    
            [2, 1, 1, 1, 2], //
            [0, 1, 0, 1, 0], //
            [2, 1, 2, 1, 2], //
            [1, 1, 0, 1, 1], //
            [1, 1, 2, 1, 1], //
    
            [2, 2, 0, 2, 2], //
            [0, 2, 2, 2, 0], //
            [2, 0, 0, 0, 2], //
            [1, 2, 0, 2, 1], //
            [1, 0, 2, 0, 1], //
    
            [0, 2, 0, 2, 0], //
            [1, 0, 0, 0, 1], //
            [0, 1, 1, 1, 0], //
            [0, 0, 2, 0, 0], //
            [2, 0, 2, 0, 2], //
        ],

        winTable: [
            {
                symbol: 'WD',
                multipliers: [500, 250, 25],
            },
            {
                symbol: 'H1', //Columbia
                multipliers: [500, 250, 25],
            },
            {
                symbol: 'H2', //Riff Raff & Magenta
                multipliers: [400, 150, 20],
            },
            {
                symbol: 'H3', //Eddie
                multipliers: [300, 100, 15],
            },
            {
                symbol: 'H4', // Brad & Janet
                multipliers: [200, 50, 10],
            },
            {
                symbol: 'L1', //Stabbed heart
                multipliers: [50, 20, 10],
            },
            {
                symbol: 'L2', //Pearls
                multipliers: [50, 20, 5],
            },
            {
                symbol: 'L3', //high heels
                multipliers: [50, 20, 5],
            },
            {
                symbol: 'L4', //A Corset
                multipliers: [50, 20, 5],
            },
            {
                symbol: 'S1', //coin
                multipliers: [],
            },
            {
                symbol: 'S2', //FG symbol
                multipliers: [1, 1, 1],
            },
        ],

        // prettier-ignore
        reels: [
            [ 'L3', 'H3', 'L4', 'L3', 'L1', 'H3', 'H4', 'L1', 'L2', 'L3', 'S2', 'H1', 'H2', 'H3', 'L3', 'H2', 'H4', 'L2', 'L3', 'L4', 'L2', 'S1', 'S1', 'S1', 'L3', 'L4', 'L1', 'H4', 'L3', 'L4', 'H4', 'L2', 'L3', 'H4', 'WD', 'WD', 'WD', 'H2', 'L2', 'H1', 'L1', 'H3', 'L2', 'L1', 'L4', 'H3', 'L2', 'S2', 'L4', 'H1', 'S2', 'L1', 'L4', 'L2', 'H2', 'H4', 'L1', 'L4', 'S2' ],
            [ 'L4', 'H2', 'H3', 'L4', 'L1', 'H3', 'H1', 'L2', 'L1', 'H4', 'L2', 'L3', 'H2', 'L2', 'L3', 'L4', 'L1', 'L3', 'L2', 'L1', 'WD', 'WD', 'WD', 'H2', 'L3', 'L1', 'L4', 'H1', 'L1', 'L4', 'L2', 'H1', 'L4', 'S1', 'S1', 'S1', 'S1', 'H4', 'L3', 'H3', 'L4', 'L3', 'H3', 'L2', 'H4', 'L4', 'L2', 'L3', 'H4', 'L1', 'H2', 'H4', 'L3', 'H3', 'H4', 'L2' ],
            [ 'L1', 'H2', 'L2', 'H4', 'L4', 'L1', 'H4', 'L2', 'L1', 'H3', 'S2', 'L2', 'L1', 'H2', 'H4', 'L2', 'H3', 'L4', 'L3', 'H3', 'L4', 'WD', 'WD', 'WD', 'L3', 'H4', 'H1', 'L4', 'H4', 'L3', 'L4', 'H1', 'L3', 'L4', 'S1', 'S1', 'S1', 'H2', 'L1', 'L4', 'H3', 'L3', 'L2', 'H3', 'L3', 'L2', 'L4', 'S2', 'H2', 'L1', 'L3', 'H1', 'L2', 'L3', 'L1', 'L2', 'H4', 'S2' ],
            [ 'L4', 'L3', 'L1', 'L4', 'L3', 'L2', 'H4', 'L1', 'L3', 'H2', 'L2', 'L1', 'L3', 'L2', 'L1', 'L4', 'L2', 'H2', 'H3', 'L1', 'WD', 'WD', 'WD', 'L3', 'L4', 'L1', 'L3', 'L2', 'H4', 'L4', 'H3', 'L2', 'L4', 'S1', 'S1', 'S1', 'L1', 'H1', 'L3', 'L2', 'L4', 'H4', 'L3', 'L2', 'L4', 'H1', 'L2', 'L3', 'H4', 'H2', 'L4', 'H3', 'H4', 'L1', 'H3' ],
            [ 'L2', 'H3', 'L3', 'L1', 'H2', 'H3', 'L2', 'L1', 'L3', 'H4', 'S2', 'L2', 'L1', 'H4', 'L4', 'H2', 'H4', 'L3', 'L1', 'L2', 'H4', 'WD', 'WD', 'WD', 'L3', 'H3', 'L4', 'L3', 'L1', 'L4', 'L2', 'L1', 'L4', 'H2', 'S1', 'S1', 'S1', 'H1', 'L2', 'H2', 'L1', 'L2', 'L4', 'L1', 'L2', 'H4', 'L3', 'S2', 'H1', 'H3', 'L4', 'H1', 'L3', 'L4', 'L1', 'L3', 'L4', 'S2' ],
        ],

        // prettier-ignore
        reelsFreespin: [
            [ 'L3', 'H3', 'L4', 'L3', 'L1', 'H3', 'H4', 'L1', 'L2', 'L3', 'S2', 'H1', 'H2', 'H3', 'L3', 'H2', 'H4', 'L2', 'L3', 'L4', 'L2', 'S1', 'L3', 'L4', 'L1', 'H4', 'L3', 'L4', 'H4', 'L2', 'L3', 'H4', 'WD', 'H2', 'L2', 'H1', 'L1', 'H3', 'L2', 'L1', 'L4', 'H3', 'L2', 'S2', 'L4', 'H1', 'S2', 'L1', 'L4', 'L2', 'H2', 'H4', 'L1', 'L4', 'S2' ],
            [ 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'L3', 'S2', 'H3', 'L1', 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'S2', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'WD', 'L3', 'S2', 'H3', 'L1' ],
            [ 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'L3', 'S2', 'H3', 'L1', 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'S2', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'WD', 'L3', 'S2', 'H3', 'L1' ],
            [ 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'L3', 'S2', 'H3', 'L1', 'L4', 'H3', 'S2', 'L2', 'L3', 'S1', 'H4', 'H1', 'S2', 'H2', 'L1', 'WD', 'S2', 'L2', 'L4', 'S2', 'H4', 'L3', 'S2', 'L1', 'L4', 'L2', 'WD', 'L3', 'S2', 'H3', 'L1' ],
            [ 'L3', 'H3', 'L4', 'L3', 'L1', 'H3', 'H4', 'L1', 'L2', 'L3', 'S2', 'H1', 'H2', 'H3', 'L3', 'H2', 'H4', 'L2', 'L3', 'L4', 'L2', 'S1', 'L3', 'L4', 'L1', 'H4', 'L3', 'L4', 'H4', 'L2', 'L3', 'H4', 'WD', 'H2', 'L2', 'H1', 'L1', 'H3', 'L2', 'L1', 'L4', 'H3', 'L2', 'S2', 'L4', 'H1', 'S2', 'L1', 'L4', 'L2', 'H2', 'H4', 'L1', 'L4', 'S2' ]                
        ],

        coinType1Values: [500, 1000, 2500, 3500, 5000, 12500, 25000, 50000, 125000, 250000, 500000, 1000000],
        coinType4Values: [1, 2, 5, 7, 10, 25, 50, 100, 250, 500, 1000, 2000],
        coinType1_min_bet: 500,
        coinType1_max_bet: 1000000,
        coinType1_spin_max_bet: 20000000,
        coinType4_min_bet: 1,
        coinType4_max_bet: 2000,
        coinType4_spin_max_bet: 40000,

        // prettier-ignore
        winLevels: [
            {name: 'normal1',threshold: 0,},
            {name: 'NICE WIN',threshold: 20,},
            {name: 'BIG WIN',threshold: 50,},
            {name: 'HUGE WIN',threshold: 100,},
            {name: 'MONSTER WIN',threshold: 250,},
            {name: 'MEGA WIN',threshold: 500,},
        ],

        winCapMulti: 10_000,


        respin: {
            // prettier-ignore
            reelStrip: ['BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'COIN', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK', 'BLANK'],
    
            symbolAward: [
                { outcome: '1', weight: 100 },
                { outcome: '2', weight: 92 },
                { outcome: '3', weight: 22 },
                { outcome: '4', weight: 11 },
                { outcome: '5', weight: 7 },
                { outcome: '6', weight: 7 },
                { outcome: '7', weight: 2 },
                { outcome: '8', weight: 2 },
                { outcome: '10', weight: 2 },
                { outcome: '14', weight: 2 },
                { outcome: '16', weight: 2 },
                { outcome: '18', weight: 2 },
                { outcome: '20', weight: 2 },
                { outcome: '24', weight: 2 },
                { outcome: 'SPECIAL', weight: 1 },
            ],
    
            jackpots: [
                { name: 'MINI', multi: 30 },
                { name: 'MAXI', multi: 100 },
                { name: 'GRAND', multi: 1000 },
            ],
    
            specialAwardWithoutJackpot: [
                { outcome: 'MINI', weight: 5 },
                { outcome: 'MAXI', weight: 1 },
            ],
    
            specialAwardWithJackpot: [
                { outcome: '1', weight: 1 },
                { outcome: '2', weight: 1 },
            ],
        },

        
    },
};
