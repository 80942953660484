var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Result:")]),
    _vm._v(" "),
    !_vm.spinOutcome.gameResponse.genieBonusPhase ||
    _vm.spinOutcome.gameResponse.genieBonusPhase === "START"
      ? _c(
          "div",
          { ref: "slotWindow", staticClass: "slot-window card" },
          [
            _vm._l(
              _vm.spinOutcome.gameResponse.slotWindow,
              function (reel, colIndex) {
                return _vm._l(reel, function (symbolName, rowIndex) {
                  return _c(
                    "reel-symbol",
                    _vm._g(
                      {
                        key: `${colIndex} ${rowIndex}`,
                        attrs: {
                          position:
                            _vm.spinOutcome.gameResponse.reelStripPositions[
                              colIndex
                            ],
                          colIndex: colIndex,
                          rowIndex: rowIndex,
                          symbolName: _vm.getSymbolName(symbolName),
                          originalName: _vm.isMysterySymbol(rowIndex, colIndex),
                          spinType: _vm.spinType,
                          colour: _vm.getColour(rowIndex, colIndex),
                          toggleColour: _vm.toggleColour,
                          "is-win-cell": _vm.isWinCell(rowIndex, colIndex),
                        },
                      },
                      _vm.$listeners
                    )
                  )
                })
              }
            ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Text Output):"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("p", [
        _c("b", [_vm._v("Spin Type:")]),
        _vm._v(" " + _vm._s(_vm.spinType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Type:")]),
        _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.winType)),
      ]),
    ]),
    _vm._v(" "),
    _vm.isFreeSpins
      ? _c("div", { staticClass: "card-generic-info" }, [
          _c("p", [
            _c("b", [_vm._v("Genie Bonus Phase:")]),
            _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.genieBonusPhase)),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Genie Bonus Count:")]),
            _vm._v("  " + _vm._s(_vm.spinOutcome.gameResponse.genieBonusCount)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("details", [
      _c("summary", [_vm._v("Full Spin Outcome:")]),
      _vm._v(" "),
      _c("p", [_c("pre", [_vm._v(_vm._s(_vm.spinOutcome.gameResponse))])]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }