import Vue from "vue"; 
import {PropType} from "vue";
import {getReelWindowFromIndex, wrapIndex} from "../../../../helpers/helpers";
import {config as modelDefinition} from "../../../../state/models/slots/vgw076/config/init";
import bus from "../../../../common/bus";
import events from "../../../../common/events";

export default Vue.component('Reel', {
    props: {
        step: {
            type: Object as PropType<{json: any}>,
        },
        index: {
            type: Number,
        },
    },

    data() {
        return {
            reelIndex: 0,
            prizeValues: Object.keys(modelDefinition.prizeWeights).map(k => Number(k)),
            prize: -1,
        };
    },

    computed: {
        show(): boolean {
            return true; //!!this.content.step;
        },
        reel(): string[] {
            return modelDefinition.reels[this.index];
        },
        windowHeight(): number {
            return modelDefinition.reelsLayout[this.index];
        },
        symbolsAboveWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex - 2, 2);
        },
        symbolsBelowWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex + this.windowHeight, 2);
        },
        reelWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex, this.windowHeight);
        },
        canUpdate(): boolean {
            return true;
        },
        prizeIndex(): number | undefined {
            if (!this.reelWindow.includes(modelDefinition.scatterSymbol)) return;
            let result = 0;
            for (let col = 0; col < this.index; ++col) {
                const reelIndex = this.step?.json?.reelStripPositions?.[col] ?? 0;
                const syms: string[] = getReelWindowFromIndex(modelDefinition.reels[col], reelIndex, modelDefinition.reelsLayout[col]);
                const hasPrize = syms.includes(modelDefinition.scatterSymbol);
                if (hasPrize) ++result;
            }
            return result;
        },
    },

    methods: {
        up() {
            ++this.reelIndex;
        },
        down() {
            --this.reelIndex;
        },
        handleWheelEvent(e) { 
            if (e.deltaY > 0) this.up();
            if (e.deltaY < 0) this.down();
            e.preventDefault();
        },
        updatePrizes() {
            if (!this.step?.json) return;
            if (this.prizeIndex === undefined) return;
            const prizes = this.step.json.prizes || (this.step.json.prizes = []);
            prizes[this.prizeIndex] = this.prize;
            this.updateDB();
        },
        updateDB() {
            if (!this.step) return;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        },
    },

    watch: {
        step(step) { 
            this.reelIndex = step?.json?.reelStripPositions?.[this.index] ?? 0;
            this.prize = step?.json?.prizes?.[this.prizeIndex ?? -1] ?? this.prizeValues[0];
        },
        reelIndex(val) {
            const indexInRange = wrapIndex(val, this.reel);
            if (indexInRange !== val) {
                this.reelIndex = indexInRange;
                return;
            }
            this.step.json.reelStripPositions[this.index] = this.reelIndex;
            this.updateDB();
        },
        prize() {
            this.updatePrizes();
        },
        prizeIndex() {
            this.updatePrizes();
        },
    },

    mounted() {
        this.$refs.reel && this.$refs.reel['addEventListener']('wheel', this.handleWheelEvent);
    },

    beforeDestroy() {
        this.$refs.reel && this.$refs.reel['removeEventListener']('wheel', this.handleWheelEvent);
    }
});
