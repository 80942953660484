// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-reel[data-v-63ce77ca] {
    position: relative;
    margin: 10px 5px 0 5px;
    border-right: 1px solid #1f1f1f;
    border-left: 1px solid #1f1f1f;
    width: 115px;
}
.sm-reel .symbol[data-v-63ce77ca] {
    height: 35px;
    font-size: 12px;
}
.sm-reel .symbol .text[data-v-63ce77ca] {
    padding: 10px 0 0 0;
    text-align: center;
}
.sm-reel .reel-text[data-v-63ce77ca] {
    font-size: 12px;
    text-align: center;
    padding: 5px 0 5px 0;
    background-color: #383838;
    border-radius: 4px;
    color: #777;
}
.sm-reel .on[data-v-63ce77ca] {
    color: #00c700;
}
.sm-reel .active-reels[data-v-63ce77ca] {
    background: #565656;
    background: -moz-linear-gradient(top, #565656 0%, #3a3a3a 100%);
    background: -webkit-linear-gradient(top, #565656 0%,#3a3a3a 100%);
    background: linear-gradient(to bottom, #565656 0%,#3a3a3a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#3a3a3a',GradientType=0 );
}
.sm-reel .dim .text[data-v-63ce77ca] {
    color: #777;
}
.sm-reel .reel-index[data-v-63ce77ca] {
    width: 50px;
}
.sm-reel .border[data-v-63ce77ca] {
    border-bottom: 1px solid #1f1f1f;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/patriotParty/reels/reel.vue.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,+BAA+B;IAC/B,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,oBAAoB;IACpB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,+DAA+D;IAC/D,iEAAiE;IACjE,+DAA+D;IAC/D,mHAAmH;AACvH;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".sm-reel[data-v-63ce77ca] {\n    position: relative;\n    margin: 10px 5px 0 5px;\n    border-right: 1px solid #1f1f1f;\n    border-left: 1px solid #1f1f1f;\n    width: 115px;\n}\n.sm-reel .symbol[data-v-63ce77ca] {\n    height: 35px;\n    font-size: 12px;\n}\n.sm-reel .symbol .text[data-v-63ce77ca] {\n    padding: 10px 0 0 0;\n    text-align: center;\n}\n.sm-reel .reel-text[data-v-63ce77ca] {\n    font-size: 12px;\n    text-align: center;\n    padding: 5px 0 5px 0;\n    background-color: #383838;\n    border-radius: 4px;\n    color: #777;\n}\n.sm-reel .on[data-v-63ce77ca] {\n    color: #00c700;\n}\n.sm-reel .active-reels[data-v-63ce77ca] {\n    background: #565656;\n    background: -moz-linear-gradient(top, #565656 0%, #3a3a3a 100%);\n    background: -webkit-linear-gradient(top, #565656 0%,#3a3a3a 100%);\n    background: linear-gradient(to bottom, #565656 0%,#3a3a3a 100%);\n    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#3a3a3a',GradientType=0 );\n}\n.sm-reel .dim .text[data-v-63ce77ca] {\n    color: #777;\n}\n.sm-reel .reel-index[data-v-63ce77ca] {\n    width: 50px;\n}\n.sm-reel .border[data-v-63ce77ca] {\n    border-bottom: 1px solid #1f1f1f;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
