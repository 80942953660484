var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c("div", { attrs: { align: "center" } }, [
        _c(
          "div",
          { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
          [
            _c(
              "p",
              { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
              [_vm._v("reel:")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelId,
                    expression: "reelId",
                  },
                ],
                staticStyle: { margin: "0.5rem" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.changeReel,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "A", selected: "" } }, [
                  _vm._v("A"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.down()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-up",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.up()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-down",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p0top))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p1top))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reels, function (r) {
          return _c("div", { staticClass: "symbol on" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(r))]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p0bottom))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p1bottom))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }