import { createClearingStep } from "./steps";
import State from "../../../state";
import { modelDefinition } from "./model-definition";

export function setStateParams() {
  return {
    variations: [],

    // None Game Specific Logic:
    createDefaultStep: () => createClearingStep(),
    getLayout: () => State.state.getModelDefinition().reelsLayout,

    // Game specific
    getModelDefinition: () => modelDefinition,
    getNumberOfReels: () => modelDefinition.reelsLayout.length,
    getNumberOfRows: () => modelDefinition.reelsLayout[0],
    getReelStrips: () => modelDefinition.reels,
  };
}