import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events'; 
import {getReelWindowFromIndex} from "../../../../helpers/helpers";
import {createClearingStep, createSpinStep, createFreeSpinTriggerStep, createFireshotTriggerStep} from '../../../../state/models/slots/vgw071';
import {CoinMode, CoinPrize, FreeSpinSet, modelDefinition, originatorId, StepJson} from '../../../../state/models/slots/vgw071';
import Reel from "./reel.vue";

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
    },

    data: () => ({
        allSteps: [] as Step[],
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        previousStep(): Step | undefined {
            return this.allSteps[(this.currentStepIndex ?? 0) - 1];
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '???';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'C-Trigger') return 'Fireshot Trigger';
            if (this.stepName === 'F-Trigger') return 'Free Spin Trigger';
            if (this.stepName === 'Pick') return 'Pick Step';
            if (this.stepName === 'F-Retrigger') return 'Free Spin Retrigger';
            if (this.stepName === 'J-Trigger') return 'GRAND Jackpot Trigger';
            if (this.isFireshotRespin) return 'Fireshot Respin';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },
        isClearingStep(): boolean {
            return this.stepName === 'Clear';
        },
        canAddClearingStep(): boolean {
            return true;
        },
        canAddSpinStep(): boolean {
            return true;
        },
        canAddFreeSpinTriggerStep(): boolean {
            return !this.finalStep?.isNextStepPick && !this.finalStep?.fireshotCount;
        },
        canAddFireshotTriggerStep(): boolean {
            return !this.finalStep?.isNextStepPick && !this.finalStep?.fireshotCount;
        },
        labelForAddSpinStep(): string {
            if (this.finalStep?.isNextStepPick) return 'Add Pick Step';
            if (this.finalStep?.fireshotCount) return 'Add Respin';
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin';
            return 'Add Base Spin';
        },
        labelForAddFreeSpinTriggerStep(): string {
            return `${this.finalStep?.freeSpinCount ? 'Ret' : 'T'}rigger Free Spins`;
        },

        // ----- Base game state -----
        reels(): string[][] {
            return this.isFreeSpin ? modelDefinition.freeSpinReels[this.reelSet] : modelDefinition.reels;
        },
        showReelStripPositions(): boolean {
            return this.show && !this.isPickStep;
        },
        canChangeReelStripPositions(): boolean {
            return !this.isClearingStep && !this.isPickStep && !this.isFireshotRespin;
        },
        stackSymbolReplacement: {
            get(): string {
                return this.currentStep?.json?.stackSymbolReplacement ?? 'PIC1';
            },
            set(value: string) {
                if (!this.currentStep) return;
                this.currentStep.json.stackSymbolReplacement = value;
                this.saveChangesToStep();
            },
        },
        showStackSymbolReplacement(): boolean {
            return this.show && !this.isPickStep && !this.isFireshotRespin;
        },
        canChangeStackSymbolReplacement(): boolean {
            return !this.isClearingStep && !this.isFireshotRespin;
        },

        // ----- Free spin state -----
        isPickStep(): boolean {
            return this.stepName === 'Pick';
        },
        isFreeSpin(): boolean {
            if (this.isFireshotRespin) return false;
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        reelSet: {
            get(): FreeSpinSet {
                return this.currentStep?.json?.reelSet ?? 'set1';
            },
            set(value: FreeSpinSet) {
                if (!this.currentStep) return;
                this.currentStep.json.reelSet = value;
                this.saveChangesToStep();
            },
        },
        multiplierSet: {
            get(): FreeSpinSet {
                return this.currentStep?.json?.multiplierSet ?? 'set1';
            },
            set(value: FreeSpinSet) {
                if (!this.currentStep) return;
                this.currentStep.json.multiplierSet = value;
                this.saveChangesToStep();
            },
        },
        showReelSetAndMultiplierSet(): boolean {
            return this.show && (this.isPickStep || this.isFreeSpin);
        },
        canChangeReelSetAndMultiplierSet(): boolean {
            return this.isPickStep;
        },
        freeSpinMultiplier: {
            get(): number {
                return this.currentStep?.json?.freeSpinMultiplier ?? this.freeSpinMultipliers[0];
            },
            set(value: number) {
                if (!this.currentStep) return;
                this.currentStep.json.freeSpinMultiplier = value;
                this.saveChangesToStep();
            },
        },
        showFreeSpinMultiplier(): boolean {
            return this.show && this.isFreeSpin;
        },
        freeSpinMultipliers(): number[] {
            return modelDefinition.freeSpinMultiplierWeights[this.multiplierSet].map(o => o.outcome);
        },

        // ----- Fireshot state -----
        isFireshotTrigger(): boolean {
            return this.currentStep?.fireshotPhase === 'START';
        },
        isFireshotRespin(): boolean {
            const fireshotPhase = this.currentStep?.fireshotPhase;
            return fireshotPhase === 'IN_PROGRESS' || fireshotPhase === 'END';
        },
        fireshotRespinsRemaining(): number {
            return this.currentStep?.fireshotCount ?? 0;
        },
        fireshotCoinsCollected(): number {
            return this.currentStep?.cumulativeCoinCount ?? 0;
        },
        coinMode: {
            get(): CoinMode {
                return this.currentStep?.json?.coinMode ?? 'NORMAL_COIN';
            },
            set(value: CoinMode) {
                if (!this.currentStep) return;
                this.currentStep.json.coinMode = value;
                this.saveChangesToStep();
            },
        },
        showCoinMode(): boolean {
            return this.show && !this.isPickStep;
        },
        canChangeCoinMode(): boolean {
            return !this.isClearingStep && !this.isFireshotRespin;
        },
        coinPrizeLevels(): Array<{value: unknown, label: string}> {
            const keys = Object.keys(modelDefinition.coinPrizeWeights.NORMAL_COIN);
            return keys.map(k => ({
                value: isNaN(Number(k)) ? k : Number(k),
                label: `${this.coinMode === 'NORMAL_COIN' ? '🟡' : '💎'} ${k}`,
            }));
        },
    },

    methods: {
        // ----- Step methods -----
        addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
        addSpinStep: () => bus.$emit(events.ADD_STEP, createSpinStep()),
        addFreeSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
        addFireshotTriggerStep: () => bus.$emit(events.ADD_STEP, createFireshotTriggerStep()),

        // ----- Fireshot methods -----
        isCoin({row, col}: {row: number, col: number}): boolean {
            return !!this.currentStep?.cumulativeCoinPrizes?.[col]?.[row];
        },
        canSetOrUnsetCoin({row, col}: {row: number, col: number}): boolean {
            if (!this.isFireshotRespin) return false;
            return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
        },
        canSetCoinPrize({row, col}: {row: number, col: number}): boolean {
            if (this.isFireshotTrigger) {
                return this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] !== 0;
            }
            else if (this.isFireshotRespin) {
                return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
            }
            else {
                return true;
            }
        },
        getCoinPrize({row, col}: {row: number, col: number}): string {
            return (this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] ?? 0).toString();
        },
        setCoinPrize({row, col, value}: {row: number, col: number, value: string}) {
            if (!this.currentStep) return;
            const cp = toCoinPrize(value);
            this.currentStep.json.coinPrizes = [0, 1, 2, 3, 4].map(c => {
                return [0, 1, 2].map(r => {
                    return c === col && r === row ? cp : this.currentStep?.json.coinPrizes?.[c]?.[r] ?? 0;
                });
            });
            this.saveChangesToStep();
        },

        // ----- Scenario persistence methods -----
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: {steps: Array<{test_scenario_step_id: number, json: StepJson}>}) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }

            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;

            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    isNextStepPick?: boolean;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    fireshotPhase?: 'START' | 'IN_PROGRESS' | 'END';
    fireshotCount?: number;
    cumulativeCoinPrizes?: CoinPrize[][];
    cumulativeCoinCount?: number;
}

function updateStepsInPlace(steps: Step[]) {
    let name = '';
    let stackSymbolReplacement = 'PIC1';
    let reelSet: FreeSpinSet | undefined;
    let multiplierSet: FreeSpinSet | undefined;
    let coinMode: CoinMode = 'NORMAL_COIN';
    let coinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
    let cumulativeCoinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
    let cumulativeCoinCount = 0;
    let isNextStepPick = false;
    let isPick = false;
    let isFreeSpin = false;
    let isFireshotRespin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;
    let fireshotPhase: Step['fireshotPhase'];
    let fireshotCount = 0;

    let isAnyStepModified = false;
    for (const step of steps) {
        if (step.json.name === 'Clear') {
            // Handle clearing steps by clearing all state
            name = step.json.name;
            isPick = false;
            isFreeSpin = false;
            isFireshotRespin = false;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
            fireshotPhase = undefined;
            fireshotCount = 0;
            stackSymbolReplacement = step.json.stackSymbolReplacement;
            reelSet = undefined;
            multiplierSet = undefined;
            coinMode = step.json.coinMode;
            coinPrizes = [];
            cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
            cumulativeCoinCount = 0;
        }
        else {
            isPick = isNextStepPick;
            isFireshotRespin = fireshotCount > 0;
            isFreeSpin = freeSpinCount > 0 && !isFireshotRespin && !isPick;

            // Update state for pick step
            if (isPick) {
                name = 'Pick';
                reelSet = step.json.reelSet ?? 'set1';
                multiplierSet = step.json.multiplierSet ?? 'set1';
            }

            // Update state for fireshot respins
            else if (isFireshotRespin) {
                const prevCoinCount = cumulativeCoinCount;
                coinPrizes = step.json.coinPrizes ?? [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
                coinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => cumulativeCoinPrizes?.[col]?.[row] ? 0 : (coinPrizes?.[col]?.[row]) || 0));
                cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => cumulativeCoinPrizes?.[col]?.[row] || coinPrizes?.[col]?.[row]) || 0);
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
                name = 'Respin';
                fireshotCount = cumulativeCoinCount === modelDefinition.countToTriggerGrand
                    ? 0
                    : cumulativeCoinCount > prevCoinCount
                        ? modelDefinition.fireshotRespinCount
                        : fireshotCount - 1;
                fireshotPhase = fireshotCount > 0 ? 'IN_PROGRESS' : 'END';
            }

            // Update state for base and free spins
            else {
                name = 'Spin';
                if (isFreeSpin) {
                    name = 'Free spin';
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END'
                }
                else {
                    freeSpinPhase = undefined;
                    reelSet = undefined;
                    multiplierSet = undefined;
                }

                const scatCells = findCells(reelSet, step.json.reelStripPositions, modelDefinition.scatterSymbol);
                const isFreeSpinTrigger = getFreeSpinsAwarded(scatCells.length) > 0;
                if (isFreeSpinTrigger) {
                    name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                    const addedSpins = getFreeSpinsAwarded(scatCells.length);
                    freeSpinCount += addedSpins;
                    freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
                }

                const coinCells = findCells(reelSet, step.json.reelStripPositions, modelDefinition.coinSymbol);
                const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot;
                if (isFireshotTrigger) {
                    name = cumulativeCoinCount < modelDefinition.countToTriggerGrand ? 'C-Trigger' : 'J-Trigger';
                    fireshotCount = modelDefinition.fireshotRespinCount;
                    fireshotPhase = 'START';
                }
                else {
                    fireshotPhase = undefined;
                }

                stackSymbolReplacement = step.json.stackSymbolReplacement;
                coinMode = step.json.coinMode;
                coinPrizes = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
                for (const [row, col] of coinCells) coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
                cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => coinPrizes?.[col]?.[row] || 0));
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
            }
        }

        // Work out if the next step should be a pick step
        isNextStepPick = freeSpinPhase === 'START' && !fireshotCount && !isPick;

        // Save all the step details
        Object.assign(step, {
            isNextStepPick,
            fireshotPhase, fireshotCount,
            freeSpinPhase, freeSpinCount,
            cumulativeCoinPrizes, cumulativeCoinCount,
        });
        const json = {...step.json, name, stackSymbolReplacement, reelSet, multiplierSet, coinMode, coinPrizes};
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }
    return isAnyStepModified;
}

function findCells(reelSet: FreeSpinSet | undefined, reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = reelSet === undefined ? modelDefinition.reels : modelDefinition.freeSpinReels[reelSet];
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function toCoinPrize(value: string): CoinPrize {
    const n = Number(value);
    return isNaN(n) ? value : n as any;
}

function getFreeSpinsAwarded(scatCount: number): number {
    return modelDefinition.freeSpinsAwarded[modelDefinition.reelsLayout.length - scatCount] ?? 0;
}
