import type { Choices, SpinType } from "../../../../choices";
import { modelDefinition } from "../../../../model-definition";
import type { CoinPrize, CoinPrizes, Position } from "../../../shared";

export function computePurpleFeaturePrizesAndUpdateCoinPrizes(options: {
    choices: Choices;
    coinAmount: number;
    position: Position;
    coinPrizes: CoinPrizes;
    coinWeight: number;
}) {
    const { coinPrizes, position, coinWeight, coinAmount, choices } = options;
    const nonMajorCoinPrices = coinPrizes.filter((coinPrize) => coinPrize.type !== "JACKPOT_MAJOR");
    nonMajorCoinPrices.forEach((coinPrize) => {
        if (coinPrize.type !== "JACKPOT_MAJOR") {
            coinPrize.value *= modelDefinition.purpleFeatureMultiplier;
            coinPrize.multiplierApplied = (coinPrize.multiplierApplied ?? 1) * modelDefinition.purpleFeatureMultiplier;
        }
    });

    const prize: CoinPrize = getPurplePrize({
        coinAmount,
        coinWeight,
        position,
        choices,
    });

    coinPrizes.push(prize);
}
function getPurplePrize(options: {
    coinAmount: number;
    coinWeight: number;
    position: Position;
    choices: Choices;
}): CoinPrize {
    const { coinAmount, coinWeight, choices, position } = options;
    const coinPrizeType = choices.genie.choosePurpleTriggeringCoinPrize({
        coinWeight,
        position,
    });
    let coinPrize: CoinPrize | undefined;

    if (typeof coinPrizeType === "number") {
        const winAmount = coinPrizeType * coinAmount;
        coinPrize = {
            type: "FEATURE",
            colour: "P",
            prize: winAmount,
            value: winAmount,
            position,
        };
    } else if (coinPrizeType === "JACKPOT_MINI" || coinPrizeType === "JACKPOT_MINOR") {
        const fixedjackpotKey = coinPrizeType === "JACKPOT_MINI" ? "MINI" : "MINOR";
        const winAmount = modelDefinition.jackpots.fixedJackpots[fixedjackpotKey] * coinAmount;
        coinPrize = {
            type: "FEATURE",
            colour: "P",
            prize: coinPrizeType,
            value: winAmount,
            position,
        };
    } else {
        coinPrize = {
            type: "FEATURE",
            colour: "P",
            prize: coinPrizeType,
            position,
            value: 0,
        };
    }
    return coinPrize;
}
