import { modelDefinition } from '../config';
import { Choices } from './choices';
import { ScenarioStep } from './scenario-step';

export function createChoicesFromScenarioStep(scenarioStep: ScenarioStep): Choices {
    return {
        chooseReelStripPositions() {
            return scenarioStep.reelStripPositions ?? [0, 0, 0, 0, 0];
        },

        // Board Game Feature:
        chooseNumberOfPicks() {
            return scenarioStep.boardGamePicks ?? 4;
        },
        chooseCard(_, boardGameIndex) {
            if (scenarioStep.boardGameSteps === undefined || scenarioStep.boardGameSteps?.length === 0) return 'SINGLE';
            return scenarioStep.boardGameSteps[boardGameIndex]?.card ?? 'SINGLE';
        },
        chooseColour(index) {
            if (scenarioStep.boardGameSteps === undefined || scenarioStep.boardGameSteps?.length === 0) return 'RED';
            return scenarioStep.boardGameSteps[index]?.colour ?? 'RED';
        },
        choosePrize(index) {
            if (scenarioStep.boardGameSteps === undefined || scenarioStep.boardGameSteps?.length === 0) return 50;
            return scenarioStep.boardGameSteps[index]?.prize ?? 50;
        },
        choosePinkPrize(index) {
            if (scenarioStep.boardGameSteps === undefined || scenarioStep.boardGameSteps?.length === 0) return 250;
            return scenarioStep.boardGameSteps[index]?.prize ?? 250;
        },

        // Jackpot Wheel:
        chooseJackpotTable() {
            const jackpotType = scenarioStep.jackpotType ?? 'MINI';
            return jackpotType === 'MINI' || jackpotType === 'MINOR' ? 'TABLE2' : 'TABLE1';
        },
        chooseJackpotType() {
            return scenarioStep.jackpotType ?? 'MINI';
        },

        // Reel Spin Features:
        chooseIfReelSpinFeatureIsAwarded() {
            return scenarioStep.featureSetId !== undefined;
        },
        chooseReelSpinFeaturesAwarded() {
            const featureId =
                scenarioStep.featureSetId === undefined ||
                scenarioStep.featureSetId < 0 ||
                scenarioStep.featureSetId > 14
                    ? 0
                    : scenarioStep.featureSetId;
            const features = modelDefinition.reelSpinFeatureSets[featureId];
            return { featureId, features };
        },
        chooseIfReelSpinFeaturesAreKept(totalPlay, totalWinAmount) {
            if (scenarioStep.featureSetId === undefined || totalWinAmount === 0) return false;
            if (totalWinAmount > 2000 * totalPlay) return false;
            return true;
        },

        // Stolen Royals Reel Features:
        chooseStolenRoyals() {
            const stolenRoyals = scenarioStep.stolenRoyals ?? ['A', 'K', 'Q'];
            if (stolenRoyals.length < 3 || stolenRoyals.length > 6) return ['A', 'K', 'Q'];
            return stolenRoyals;
        },

        // Multiplier Reel Feature:
        chooseMultiplier() {
            return scenarioStep.multiplier ?? 2;
        },
        chooseReplacementSymbol(stolenRoyals, replacementIndex) {
            if (
                scenarioStep.replacementSymbols === undefined ||
                scenarioStep.replacementSymbols[replacementIndex] === undefined
            ) {
                return 'PIC1';
            }
            const symbol = scenarioStep.replacementSymbols[replacementIndex];
            return stolenRoyals.includes(symbol) ? 'PIC1' : symbol;
        },

        // Nutt Prize Reel Feature:
        chooseNuttSymbol(stolenRoyals) {
            if (scenarioStep.nuttSymbol === undefined || stolenRoyals.includes(scenarioStep.nuttSymbol))
                return { symbol: 'PIC1', id: 'ID_1' };
            const symbol = scenarioStep.nuttSymbol;
            const id = modelDefinition.nuttSymbolIds[symbol];
            return { symbol, id };
        },
        chooseNuttPrize() {
            return scenarioStep.nuttPrize ?? 200;
        },

        // Candy Canes Reel Feature:
        chooseNumberOfCandyCanes() {
            return scenarioStep.candyCanePlacements !== undefined && scenarioStep.candyCanePlacements.length
                ? scenarioStep.candyCanePlacements.length
                : 2;
        },
        chooseReels() {
            return scenarioStep.candyCanePlacements !== undefined && scenarioStep.candyCanePlacements.length >= 2
                ? scenarioStep.candyCanePlacements.map((value) => value.reel).sort()
                : [0, 1];
        },
        chooseCandyCaneShape(reel) {
            const defaults = ['BOTTOM_RIGHT', 'TOP_LEFT', 'TOP_LEFT', 'TOP_LEFT', 'TOP_LEFT'] as const;

            return scenarioStep.candyCanePlacements !== undefined && scenarioStep.candyCanePlacements.length > 0
                ? scenarioStep.candyCanePlacements.find((value) => value.reel === reel)?.candyCaneType ?? defaults[reel]
                : defaults[reel];
        },
        chooseReelStripPositionForCandyCane(reel) {
            return scenarioStep.candyCanePlacements !== undefined && scenarioStep.candyCanePlacements.length > 0
                ? scenarioStep.candyCanePlacements.find((value) => value.reel === reel)?.reelPosition ?? 0
                : 0;
        },
        chooseCandyCaneReplacementSymbol() {
            return scenarioStep.candyCaneReplacementSymbol ?? 'PIC1';
        },

        // Wild Path Reel Feature:
        chooseWildPathId() {
            return scenarioStep.wildPathId ?? 'ID_1';
        },
        chooseWildPathLength(id) {
            const path = modelDefinition.wildPaths[id].path;
            const length = scenarioStep.wildPathLength ?? 5;
            return length <= path.length ? length : 5;
        },
        chooseWildPathStartingPosition() {
            return scenarioStep.wildPathStartingPosition ?? 0;
        },
        chooseIfXAxisFlips() {
            return scenarioStep.wildPathFlipX ?? false;
        },
        chooseIfYAxisFlips() {
            return scenarioStep.wildPathFlipY ?? false;
        },
        chooseIfAxisSwap() {
            return scenarioStep.wildPathSwapAxis ?? false;
        },
    };
}
