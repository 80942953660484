import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, range} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./../reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isWheel = false;
    public wheelIndex = 0;
    public elementIndex = 0;
    public isFeature = false;
    public isFeatureChecked = false;
    public isWheelTriggered = false;
    public show = false;
    public currency = "GOLD";
    public currencies = ["GOLD", "SWEEPS"];
    public wheelIndexes = range(3);
    public wheelElements = [];
    public wheelElementsIndexes = [];
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.isWheel = step.json.isWheelStep;
        this.currency = step.json.currency === "SWEEPS" ? "SWEEPS" : "GOLD";
        this.wheelIndex = step.json.wheelIndex || 0;
        this.isWheelTriggered = stepIsWheel(step);
        this.wheelElements = State.state.getModelDefinition().wheels[this.wheelIndex].wheelElements[this.currency]; 
        this.wheelElementsIndexes = range(this.wheelElements.length); 
        this.elementIndex = step.json.elementIndex; 
        // trace(step);
    }
    public mounted() {
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addBaseStep() { 
        bus.$emit(events.ADD_STEP, State.state.getBaseStep() );
    }
    public addWheelStepTrigger() { 
        bus.$emit(events.ADD_STEP, State.state.addWheelStepTrigger() );
    }
    public addWheelStep() {
        bus.$emit(events.ADD_STEP, State.state.addWheelStep(this.wheelIndex) );
    }
    public doChangeWheel() {
        this.step.json.wheelIndex = Number(this.wheelIndex);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public doChangeCurrency() {
        this.step.json.currency = this.currency;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public doChangeElementIndex() {
        this.step.json.elementIndex = Number(this.elementIndex);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
const stepIsWheel = (step) => {
    let n = 0;
    step.json.rows.map(row => row.map(it => {
        if (it.toLowerCase() === "scat") { 
            n = n + 1;
        }
    }));
    return n >= 3;
}