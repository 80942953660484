var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sm-game-spec-content" }, [
    _c("div", { staticClass: "flex-center menu" }, [
      _c(
        "div",
        {
          staticClass: "button pointer",
          on: {
            click: function ($event) {
              return _vm.addSpinStep()
            },
          },
        },
        [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canAddFreeSpinTriggerStep,
              expression: "canAddFreeSpinTriggerStep",
            },
          ],
          staticClass: "button pointer",
          on: {
            click: function ($event) {
              return _vm.addFreeSpinTriggerStep()
            },
          },
        },
        [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canAddStackReSpinTriggerStep,
              expression: "canAddStackReSpinTriggerStep",
            },
          ],
          staticClass: "button pointer",
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected,
                  expression: "selected",
                },
              ],
              staticClass: "picSelect",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  (e) => _vm.addStackReSpinTriggerStep(e.target.value),
                ],
              },
            },
            [
              _c("option", { attrs: { value: "default" } }, [
                _vm._v("Trigger Stack ReSpin"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC1" } }, [_vm._v("PIC1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC2" } }, [_vm._v("PIC2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC3" } }, [_vm._v("PIC3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC4" } }, [_vm._v("PIC4")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "button pointer",
          on: {
            click: function ($event) {
              return _vm.addClearingStep()
            },
          },
        },
        [_vm._v("Add Clearing Step")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "flex-center sub-title-menu",
      },
      [
        _c("div", { staticClass: "label" }, [
          _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.stepDescription)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                expression: "isFreeSpin || freeSpinsRemaining",
              },
            ],
            staticClass: "label",
          },
          [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-center" },
      [
        _c("reel", { attrs: { content: this, index: 0, step: _vm.step } }),
        _vm._v(" "),
        _c("reel", { attrs: { content: this, index: 1, step: _vm.step } }),
        _vm._v(" "),
        _c("reel", { attrs: { content: this, index: 2, step: _vm.step } }),
        _vm._v(" "),
        _c("reel", { attrs: { content: this, index: 3, step: _vm.step } }),
        _vm._v(" "),
        _c("reel", { attrs: { content: this, index: 4, step: _vm.step } }),
        _vm._v(" "),
        _c("reel", { attrs: { content: this, index: 5, step: _vm.step } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }