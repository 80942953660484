import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import NumberBox from "./numberbox/numberbox.vue";

@Component({
    components: {
        numberbox: NumberBox
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;

    public show = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.show = true;
        
        this.$nextTick(() => bus.$emit("step-updated", step));
    }
    
    public mounted() {
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
}
