import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import WheelPrize from "./components/wheelPrize.vue";
import {IStep} from "../../../../interface/step";
import state from "../../../../state/state";

export interface IVGW065Step extends IStep {
    json: {
        name: string;
        reelStripPositions: number[];
        isFeature: boolean;
        variation: string;
        field0?: string; // Bonus game type
        field1?: string[]; // Wheel bonus round results
        randoms: number[];
        rows: string[][];
    };
}
@Component({
    components: {
        reel: Reel,
        wheelBonusRound: WheelPrize,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: IVGW065Step | any;
    public privateStep;
    public isStepBonusChecked = false;
    public isWheel = false;
    public isFreeSpins = false;
    public showReels = true;
    public show = false;
    public showFeatureOptions = false;
    public showWheelBonusOptions = true;
    public selectedBonusType: null | "free games" | "wheel" = null;
    public maxWheelBonusRounds = 10; // 8 bonus rounds, up to 2 jackpots
    public wheelBonusRounds: string[] = [];
    public isBonusTriggered = false;
    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step: IVGW065Step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.selectedBonusType = step.json.field0 === "wheel" ? "wheel" : (step.json.field0 === "free games" ? "free games" : null);
        this.wheelBonusRounds = step.json.field1 || ["1", "1", "1"];
        this.isWheel = this.selectedBonusType === "wheel";
        this.isFreeSpins = this.selectedBonusType === "free games";
        this.isStepBonusChecked = this.step.json.isFeature;
        this.showReels = !this.isStepBonusChecked || this.isFreeSpins;
        this.isBonusTriggered = this.isFeatureTrigger(step.json.rows);
    }
    public toggleFeature() {
        this.isStepBonusChecked = !this.isStepBonusChecked;
        const field0 = this.isStepBonusChecked ? (this.step.json.field0 || "wheel") : "";
        this.step.json.isFeature = this.isStepBonusChecked;
        this.step.json.field0 = field0;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public toggleBonus() {
        const field0 = this.isWheel ? "free games" : "wheel";
        this.step.json.field0 = field0;
        if (field0 === "wheel") {
            this.step.json.field1 = ["1", "1", "1"];
            this.maxWheelBonusRounds = 10;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public reelHasChanged(reelStripPositions: number[]) {
        const step = JSON.parse(JSON.stringify(this.step));
        step.json.reelStripPositions = reelStripPositions;
        const field0 = step.json.field0 || "wheel";
        step.json.field0 = field0;
        bus.$emit(events.UPDATE_STEPS_DB, step);
    }
    public mounted() {
        trace("reels mounted");
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep());
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }
    public addPickStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.addPickStep());
    }
    public isFeatureTrigger(rows: string[][]) {
        const flat = rows.reduce((acc, val) => acc.concat(val), []);
        const count: number = flat.reduce((acc, el) => (acc += el === "SCAT" ? 1 : 0), 0);
        return count >= 3;
    }
}
