export const modelDefinition = {
    coinAmountMultiplier: 20,

    validCoinAmounts: {
        SC: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2500],
        GC: [20, 40, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000],
    },

    reelsLayout: [3, 3, 3, 3, 3],

    // prettier-ignore
    reels: [
            ["PIC3", "T", "PIC4", "K", "SCAT", "N", "PIC4", "K", "PIC3", "A", "PIC1", "PIC1", "PIC1", "J", "N", "A", "J", "T", "PIC3", "PIC3", "J", "PIC1", "PIC1", "A", "J", "N", "K", "J", "PIC4", "K", "N", "A", "T", "PIC4", "J", "PIC3", "T", "A", "SCAT", "PIC4", "PIC1", "T", "PIC2", "PIC3", "PIC4", "T", "PIC1", "Q", "PIC4", "A", "PIC1", "PIC3", "A", "T", "CASE", "CASE", "PIC3", "J", "T", "PIC3", "A", "PIC4", "PIC2", "T", "PIC4", "Q", "CASE", "CASE", "T", "PIC2", "PIC4", "Q", "SCAT", "N", "PIC2", "T", "PIC3", "A", "PIC1", "PIC1", "Q", "N", "K", "PIC4", "N", "Q", "PIC4", "K", "CASE", "CASE", "PIC3", "A", "K", "PIC2", "Q", "N", "PIC4", "Q", "N", "PIC3", "PIC4", "T", "N", "J", "Q"],
            ["T", "PIC3", "PIC2", "K", "N", "A", "PIC4", "N", "A", "K", "WILD", "WILD", "WILD", "K", "PIC2", "A", "N", "PIC2", "A", "K", "PIC2", "J", "A", "Q", "PIC3", "K", "A", "WILD", "WILD", "WILD", "PIC2", "J", "PIC4", "PIC2", "K", "PIC1", "Q", "N", "SCAT", "Q", "PIC3", "J", "PIC4", "PIC3", "A", "PIC1", "N", "K", "Q", "PIC2", "PIC3", "A", "PIC2", "K", "CASE", "CASE", "CASE", "K", "N", "PIC2", "K", "WILD", "WILD", "WILD", "K", "PIC3", "T", "PIC2", "Q", "PIC3", "J", "N", "SCAT", "J", "N", "K", "J", "PIC1", "PIC1", "PIC1", "J", "N", "PIC3", "PIC2", "K", "Q", "PIC2", "N", "CASE", "CASE", "CASE", "Q", "PIC1", "PIC4", "K", "WILD", "WILD", "WILD", "Q", "J", "PIC1", "PIC4", "K", "PIC1", "PIC4"],
            ["A", "T", "PIC2", "PIC4", "SCAT", "Q", "PIC4", "A", "PIC3", "N", "WILD", "WILD", "WILD", "Q", "PIC2", "N", "A", "PIC2", "T", "PIC3", "J", "Q", "PIC4", "K", "Q", "PIC3", "PIC4", "PIC2", "WILD", "WILD", "WILD", "K", "PIC3", "Q", "PIC1", "PIC3", "PIC4", "T", "SCAT", "A", "PIC4", "K", "A", "N", "PIC1", "PIC1", "PIC1", "J", "K", "PIC2", "PIC3", "K", "T", "J", "CASE", "CASE", "CASE", "J", "PIC2", "Q", "T", "PIC2", "WILD", "WILD", "WILD", "K", "PIC2", "T", "PIC1", "Q", "T", "K", "SCAT", "T", "PIC3", "J", "PIC1", "PIC2", "K", "PIC3", "PIC1", "J", "A", "PIC2", "WILD", "WILD", "WILD", "J", "A", "Q", "PIC4", "CASE", "CASE", "CASE", "PIC2", "K", "PIC3", "A", "PIC1", "PIC3", "T", "N", "J", "PIC3", "N" ],
            ["N", "PIC4", "PIC3", "N", "SCAT", "PIC3", "J", "PIC4", "PIC3", "A", "PIC1", "PIC1", "PIC1", "K", "N", "Q", "A", "PIC2", "PIC2", "J", "N", "PIC4", "PIC3", "Q", "PIC4", "A", "K", "PIC2", "J", "WILD", "WILD", "WILD", "T", "PIC4", "PIC2", "K", "T", "N", "SCAT", "PIC3", "PIC1", "PIC4", "SCAT", "PIC3", "K", "PIC1", "PIC1", "K", "T", "PIC1", "PIC2", "PIC3", "PIC3", "J", "CASE", "CASE", "CASE", "PIC3", "T", "A", "Q", "PIC2", "J", "J", "T", "PIC3", "WILD", "WILD", "WILD", "PIC1", "PIC2", "N", "SCAT", "K", "PIC3", "PIC4", "PIC2", "A", "Q", "WILD", "WILD", "WILD", "N", "PIC3", "Q", "PIC2", "PIC2", "J", "CASE", "CASE", "CASE", "PIC3", "PIC4", "A", "Q", "PIC2", "K", "WILD", "WILD", "WILD", "N", "PIC3", "Q", "PIC1", "A",],
            ["K", "PIC4", "A", "SCAT", "PIC4", "A", "Q", "J", "PIC4", "A", "J", "K", "T", "Q", "A", "PIC2", "PIC2", "T", "PIC3", "Q", "PIC4", "PIC3", "J", "PIC4", "Q", "PIC1", "PIC1", "PIC1", "A", "K", "PIC4", "T", "PIC2", "PIC2", "J", "PIC4", "T", "J", "SCAT", "PIC3", "PIC1", "Q", "PIC4", "K", "Q", "PIC4", "K", "PIC1", "J", "PIC2", "PIC2", "N", "A", "PIC2", "CASE", "CASE", "CASE", "PIC3", "PIC2", "N", "K", "PIC1", "PIC1", "K", "SCAT", "J", "PIC3", "PIC4", "PIC1", "PIC3", "T", "J", "SCAT", "PIC3", "PIC4", "PIC2", "PIC3", "N", "PIC1", "PIC3", "K", "N", "A", "PIC2", "PIC2", "K", "A", "PIC3", "CASE", "CASE", "CASE", "Q", "PIC3", "A", "PIC1", "PIC2", "N", "K", "PIC3", "PIC3", "PIC1", "A", "PIC2", "Q", "PIC1"]
    ],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [1, 0, 0, 0, 1], // 12
        [1, 2, 2, 2, 1], // 13
        [0, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 2], // 15
        [1, 1, 0, 1, 1], // 16
        [1, 1, 2, 1, 1], // 17
        [0, 2, 0, 2, 0], // 18
        [2, 0, 2, 0, 2], // 19
        [0, 2, 2, 2, 0], // 20
    ],

    winTable: [
        {
            symbol: 'PIC1',
            multipliers: [500, 80, 20],
        },
        {
            symbol: 'PIC2',
            multipliers: [250, 50, 15],
        },
        {
            symbol: 'PIC3',
            multipliers: [150, 40, 10],
        },
        {
            symbol: 'PIC4',
            multipliers: [100, 30, 10],
        },
        {
            symbol: 'A',
            multipliers: [60, 20, 8],
        },
        {
            symbol: 'K',
            multipliers: [60, 20, 8],
        },
        {
            symbol: 'Q',
            multipliers: [50, 10, 4],
        },
        {
            symbol: 'J',
            multipliers: [40, 10, 4],
        },
        {
            symbol: 'T',
            multipliers: [20, 5, 2],
        },
        {
            symbol: 'N',
            multipliers: [20, 5, 2],
        },
    ],

    scatterWinTable: [
        {
            symbol: 'SCAT',
            multipliers: [undefined, undefined, 2, 10, 100],
        },
    ],

    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',

    freeSpinTriggerCount: 5,
    freeSpinRetriggerCount: 5,

    bankerOfferTriggeredWeightsTable: [
        {
            min: 1,
            max: 5,
            chance: 30692,
            outOf: 1000000,
        },
        {
            min: 5,
            max: 10,
            chance: 130971,
            outOf: 1000000,
        },
        {
            min: 10,
            max: 15,
            chance: 510943,
            outOf: 1000000,
        },
        {
            min: 15,
            max: 20,
            chance: 840148,
            outOf: 1000000,
        },
        {
            min: 20,
            max: 30,
            chance: 422307,
            outOf: 1000000,
        },
        {
            min: 30,
            max: 50,
            chance: 739878,
            outOf: 1000000,
        },
        {
            min: 50,
            max: 250,
            chance: 430530,
            outOf: 1000000,
        },
        {
            min: 250,
            max: Infinity,
            chance: 0,
            outOf: 1000000,
        },
    ],

    bankerOfferWinAmount: [
        {
            min: 1,
            max: 5,
            weightTable: [
                { outcome: 5, weight: 7337 },
                { outcome: 10, weight: 2317 },
                { outcome: 15, weight: 189 },
                { outcome: 20, weight: 157 },
            ],
        },
        {
            min: 5,
            max: 10,
            weightTable: [
                { outcome: 5, weight: 4448 },
                { outcome: 10, weight: 4974 },
                { outcome: 15, weight: 295 },
                { outcome: 20, weight: 244 },
                { outcome: 25, weight: 39 },
            ],
        },
        {
            min: 10,
            max: 15,
            weightTable: [
                { outcome: 5, weight: 1659 },
                { outcome: 10, weight: 6024 },
                { outcome: 15, weight: 1921 },
                { outcome: 20, weight: 313 },
                { outcome: 25, weight: 83 },
            ],
        },
        {
            min: 15,
            max: 20,
            weightTable: [
                { outcome: 5, weight: 1551 },
                { outcome: 10, weight: 3745 },
                { outcome: 15, weight: 3039 },
                { outcome: 20, weight: 1108 },
                { outcome: 25, weight: 375 },
                { outcome: 50, weight: 181 },
            ],
        },
        {
            min: 20,
            max: 30,
            weightTable: [
                { outcome: 5, weight: 387 },
                { outcome: 10, weight: 1969 },
                { outcome: 15, weight: 2058 },
                { outcome: 20, weight: 3064 },
                { outcome: 25, weight: 953 },
                { outcome: 50, weight: 1569 },
            ],
        },
        {
            min: 30,
            max: 50,
            weightTable: [
                { outcome: 5, weight: 582 },
                { outcome: 10, weight: 2367 },
                { outcome: 15, weight: 773 },
                { outcome: 20, weight: 2558 },
                { outcome: 25, weight: 1688 },
                { outcome: 50, weight: 2031 },
            ],
        },
        {
            min: 50,
            max: 250,
            weightTable: [{ outcome: 50, weight: 1 }],
        },
    ],

    bankerMultiplierTriggerWeightsTable: {
        baseSpin: [
            { outcome: true, weight: 1 },
            { outcome: false, weight: 36 },
        ],
        freeSpin: [
            { outcome: true, weight: 10 },
            { outcome: false, weight: 68 },
        ],
    },

    bankerMultiplierWeightsTable: [
        { outcome: 2, weight: 30 },
        { outcome: 3, weight: 30 },
        { outcome: 4, weight: 20 },
        { outcome: 5, weight: 10 },
        { outcome: 6, weight: 5 },
        { outcome: 7, weight: 5 },
        { outcome: 10, weight: 1 },
    ],

    bankerStacksTriggerWeightsTable: {
        baseSpin: [
            { outcome: true, weight: 10 },
            { outcome: false, weight: 494 },
        ],
        freeSpin: [
            { outcome: true, weight: 100 },
            { outcome: false, weight: 71 },
        ],
    },

    bankerStacksSymbolWeightsTable: {
        baseSpin: [
            { outcome: 'WILD' as const, weight: 63 },
            { outcome: 'CASE' as const, weight: 37 },
        ],
        freeSpin: [
            { outcome: 'WILD' as const, weight: 75 },
            { outcome: 'CASE' as const, weight: 25 },
        ],
    },

    bankerStacksCells: {
        wild: [
            { outcome: [1, 2, 3], weight: 3 },
            { outcome: [1, 2], weight: 3 },
            { outcome: [1, 3], weight: 3 },
            { outcome: [2, 3], weight: 3 },
            { outcome: [1], weight: 30 },
            { outcome: [2], weight: 30 },
            { outcome: [3], weight: 30 },
        ],
        case: [
            { outcome: [1, 2, 3], weight: 1 },
            { outcome: [1, 2], weight: 3 },
            { outcome: [1, 3], weight: 3 },
            { outcome: [2, 3], weight: 3 },
            { outcome: [1], weight: 40 },
            { outcome: [2], weight: 40 },
            { outcome: [3], weight: 40 },
        ],
    },

    bankerScatTriggerWeightsTable: {
        baseSpin: [
            { outcome: false, weight: 3 },
            { outcome: true, weight: 1 },
        ],
        freeSpin: [
            { outcome: false, weight: 3 },
            { outcome: true, weight: 1 },
        ],
    },

    bankerCaseAwardWeights: {
        baseSpin: [
            { outcome: false, weight: 3 },
            { outcome: true, weight: 1 },
        ],
        freeSpin: [
            { outcome: false, weight: 3 },
            { outcome: true, weight: 1 },
        ],
    },

    goldCaseAwardedWeights: [
        { outcome: false, weight: 115 },
        { outcome: true, weight: 10 },
    ],

    casePrizeWeightsTable: [
        { outcome: 5, weight: 900 },
        { outcome: 10, weight: 900 },
        { outcome: 20, weight: 900 },
        { outcome: 30, weight: 900 },
        { outcome: 40, weight: 900 },
        { outcome: 50, weight: 900 },
        { outcome: 80, weight: 900 },
        { outcome: 100, weight: 500 },
        { outcome: 120, weight: 250 },
        { outcome: 150, weight: 100 },
        { outcome: 200, weight: 50 },
        { outcome: 400, weight: 25 },
        { outcome: 800, weight: 8 },
        { outcome: 2000, weight: 1 },
    ],

    /** Used to calculate COIN_WEIGHT as per PAR sheet formula */
    coinWeightMultiplier: {
        GC: 0.05,
        SC: 1,
    },

    /** Weights used during case chase respins to possibly turn non-CASE symbols in the slot window into CASEs. */
    extraCaseWeights: {
        easy: {
            chance: 1,
            outOf: 15,
        },
        medium: {
            chance: 1,
            outOf: 35,
        },
        hard: {
            chance: 'COIN_WEIGHT' as const,
            outOf: 1_139_244,
        },
    },

    goldCaseFeatureWeights: [
        { outcome: 'COLLECT' as const, weight: 1 },
        { outcome: 'MULTIPLY' as const, weight: 1 },
        { outcome: 'BOOST' as const, weight: 1 },
    ],

    goldCaseMultiplierFeatureWeights: [
        {
            maxWinRatio: 5,
            weights: [
                { outcome: 2 as const, weight: 80 },
                { outcome: 3 as const, weight: 20 },
                { outcome: 4 as const, weight: 4 },
                { outcome: 5 as const, weight: 1 },
            ],
        },
        {
            maxWinRatio: 10,
            weights: [
                { outcome: 2 as const, weight: 140 },
                { outcome: 3 as const, weight: 20 },
                { outcome: 4 as const, weight: 4 },
                { outcome: 5 as const, weight: 1 },
            ],
        },
        {
            maxWinRatio: 50,
            weights: [
                { outcome: 2 as const, weight: 90 },
                { outcome: 3 as const, weight: 8 },
                { outcome: 4 as const, weight: 0 },
                { outcome: 5 as const, weight: 0 },
            ],
        },
        {
            maxWinRatio: 100,
            weights: [
                { outcome: 2 as const, weight: 16 },
                { outcome: 3 as const, weight: 1 },
                { outcome: 4 as const, weight: 0 },
                { outcome: 5 as const, weight: 0 },
            ],
        },
        {
            maxWinRatio: Infinity,
            weights: [
                { outcome: 2 as const, weight: 1 },
                { outcome: 3 as const, weight: 0 },
                { outcome: 4 as const, weight: 0 },
                { outcome: 5 as const, weight: 0 },
            ],
        },
    ],

    // NB: all `chance` values below are out of 100 (to avoid fractions)
    keepGoldCaseChances: {
        COLLECT: [
            { maxWinRatio: 0, chance: 0 },
            { maxWinRatio: 1, chance: 100 },
            { maxWinRatio: 2, chance: 100 },
            { maxWinRatio: 5, chance: 100 },
            { maxWinRatio: 10, chance: 100 },
            { maxWinRatio: 20, chance: 100 },
            { maxWinRatio: 30, chance: 100 },
            { maxWinRatio: 40, chance: 60 },
            { maxWinRatio: 60, chance: 30 },
            { maxWinRatio: 100, chance: 20 },
            { maxWinRatio: 200, chance: 10 },
            { maxWinRatio: 500, chance: 5 },
            { maxWinRatio: 1000, chance: 5 },
            { maxWinRatio: 2000, chance: 5 },
            { maxWinRatio: Infinity, chance: 0 },
        ],
        MULTIPLY: [
            { maxWinRatio: 0, chance: 0 },
            { maxWinRatio: 1, chance: 100 },
            { maxWinRatio: 2, chance: 100 },
            { maxWinRatio: 5, chance: 100 },
            { maxWinRatio: 10, chance: 100 },
            { maxWinRatio: 20, chance: 100 },
            { maxWinRatio: 30, chance: 100 },
            { maxWinRatio: 40, chance: 50 },
            { maxWinRatio: 60, chance: 30 },
            { maxWinRatio: 100, chance: 20 },
            { maxWinRatio: 200, chance: 10 },
            { maxWinRatio: 500, chance: 5 },
            { maxWinRatio: 1000, chance: 5 },
            { maxWinRatio: 2000, chance: 5 },
            { maxWinRatio: Infinity, chance: 0 },
        ],
        BOOST: [
            { maxWinRatio: 0, chance: 0 },
            { maxWinRatio: 1, chance: 100 },
            { maxWinRatio: 2, chance: 100 },
            { maxWinRatio: 5, chance: 100 },
            { maxWinRatio: 10, chance: 100 },
            { maxWinRatio: 20, chance: 100 },
            { maxWinRatio: 30, chance: 100 },
            { maxWinRatio: 40, chance: 75 },
            { maxWinRatio: 60, chance: 50 },
            { maxWinRatio: 100, chance: 40 },
            { maxWinRatio: 200, chance: 10 },
            { maxWinRatio: 500, chance: 5 },
            { maxWinRatio: 1000, chance: 5 },
            { maxWinRatio: 2000, chance: 5 },
            { maxWinRatio: Infinity, chance: 0 },
        ],
    },
};
