import Vue, { PropType } from "vue";
import { SpinOutcome } from "../model/src/operations/play";
import { ReelSymbol } from "./reel-symbol";
import { FeatureColour } from "../model/src/operations/shared";
import { getGenieBonusLamps, InternalScenario } from "../content.vue";
import { modelDefinition } from "../../../../../state/models/slots/quest-of-the-3-lamps";

export function matchPosition(
  positionA: number[],
  positionB: number[]
): boolean {
  return positionA[0] === positionB[0] && positionA[1] === positionB[1];
}

export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<SpinOutcome>,
    scenario: Object as PropType<InternalScenario>,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    isFreeSpins(): boolean {
      return this.spinOutcome.gameResponse.genieBonusPhase !== undefined;
    },
    spinType() {
      if (
        this.spinOutcome.gameResponse.genieBonusPhase &&
        this.spinOutcome.gameResponse.genieBonusPhase !== "START"
      ) {
        return "FREE_SPINS";
      }
      return "BASE";
    },
  },
  methods: {
    isWinCell(rowIndex: number, colIndex: number): boolean {
      return (
        this.spinOutcome.gameResponse.winCells?.[colIndex]?.[rowIndex] === 1
      );
    },
    handleSelectionChanged(values, rowIndex: number, colIndex: number) {
      this.$emit("selection-changed", { rowIndex, colIndex, values });
    },
    getSymbolName(name: string) {
      return name === "SCAT" ? "LAMP" : name;
    },
    isMysterySymbol(rowIndex: number, colIndex: number) {
      const rowIndexOffset =
        this.scenario?.reelStripPositions[colIndex].value ?? 0;
      const originalSymbolName =
        modelDefinition.reels[colIndex][rowIndex + rowIndexOffset];
      if (originalSymbolName === "M") {
        return "MYSTERY";
      }
    },
    getColour(rowIndex: number, colIndex: number): FeatureColour | undefined {
      // if (this.isGenieBonusTriggered === false) return undefined;
      const lamps = getGenieBonusLamps(
        this.spinOutcome?.gameResponse.slotWindow,
        this.scenario?.genieBonusLamps
      );
      let colour = lamps.find((lamp) =>
        matchPosition(lamp.position, [rowIndex, colIndex])
      )?.colour;
      if (!colour) {
        const coinPrize = this.spinOutcome.gameResponse.coinPrizes?.find(
          (lamp) => matchPosition(lamp.position, [rowIndex, colIndex])
        );
        if (coinPrize && coinPrize.type === "FEATURE") {
          colour = coinPrize.colour;
        }
      }
      return colour;
    },
    toggleColour(rowIndex: number, colIndex: number, colour: string) {
      const colours: FeatureColour[] = ["G", "P", "R"];
      let colourIndex = colours.findIndex(
        (availableColour) => availableColour === colour
      );
      if (colourIndex === -1) {
        return;
      }

      if (colourIndex + 1 >= colours.length) {
        colourIndex = 0;
      } else {
        colourIndex++;
      }

      const newColour = colours[colourIndex];
      const lamp = this.scenario.genieBonusLamps?.find((existingLamp) =>
        matchPosition(existingLamp.position, [rowIndex, colIndex])
      );
      if (lamp) {
        lamp.colour = newColour;
      }
    },
  },
});
