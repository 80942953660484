// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* outer.css */
.outer[data-v-6615d758] {
    all: revert;
    color: white;
    padding: 1em;
}
.heading[data-v-6615d758] {
  color: lightgray;
}
.reel-position-selectors[data-v-6615d758] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  padding: 1em;
  border-radius: 0.5em;
  background: #707070;
  margin-bottom: 1em;
  gap: 0.5em;
}
.reel-position-selectors > input[data-v-6615d758] {
  width: 8ch;
  padding: 0.25em;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  background: #707070; 
  color: lightgray; 
  box-shadow: inset 0 0 10px #555555; 
  font-size: 1em; 
  outline: none;
}
.reel-position-selectors > input[data-v-6615d758]:hover {
  outline: solid 2px lightgreen;
}
button[data-v-6615d758] {
  padding: 10px 20px;
  border: none;
  border-radius: 0.5em;
  background-color: #404040; 
  color: lightgray; 
  font-size: 1em;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
button[data-v-6615d758]:hover {
  background-color: #505050; 
  color: white; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
button[data-v-6615d758]:active {
  background-color: #606060; 
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw099/content.vue.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,kCAAkC;EAClC,cAAc;EACd,aAAa;AACf;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;EACb,8DAA8D;AAChE;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,wCAAwC;AAC1C;AACA;EACE,yBAAyB;EACzB,8CAA8C;AAChD","sourcesContent":["/* outer.css */\n.outer[data-v-6615d758] {\n    all: revert;\n    color: white;\n    padding: 1em;\n}\n.heading[data-v-6615d758] {\n  color: lightgray;\n}\n.reel-position-selectors[data-v-6615d758] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  justify-items: center;\n  padding: 1em;\n  border-radius: 0.5em;\n  background: #707070;\n  margin-bottom: 1em;\n  gap: 0.5em;\n}\n.reel-position-selectors > input[data-v-6615d758] {\n  width: 8ch;\n  padding: 0.25em;\n  text-align: center;\n  border-radius: 0.5em;\n  border: none;\n  background: #707070; \n  color: lightgray; \n  box-shadow: inset 0 0 10px #555555; \n  font-size: 1em; \n  outline: none;\n}\n.reel-position-selectors > input[data-v-6615d758]:hover {\n  outline: solid 2px lightgreen;\n}\nbutton[data-v-6615d758] {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 0.5em;\n  background-color: #404040; \n  color: lightgray; \n  font-size: 1em;\n  cursor: pointer;\n  outline: none;\n  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;\n}\nbutton[data-v-6615d758]:hover {\n  background-color: #505050; \n  color: white; \n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n}\nbutton[data-v-6615d758]:active {\n  background-color: #606060; \n  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
