import type { Choices, SpinType } from "../../../choices";
import type { CoinPrize, CoinPrizes, FeatureColour, Position } from "../../shared";
import {
    computeGreenFeaturePrizes, // TODO: rename this
    computePurpleFeaturePrizesAndUpdateCoinPrizes,
    computeRedFeaturePrizesAndUpdateCoinPrizes,
} from "./features";

/*
    Sort by colour GREEN, PURPLE then RED
    Sort next by top to bottom
    Sort next by left to right
 */
export const sortFeatureColours = (featureColours: Array<{ position: Position; colour: FeatureColour }>) => {
    const colorOrder: Record<FeatureColour, number> = { G: 0, P: 1, R: 2 };

    featureColours.sort((a, b) => {
        if (colorOrder[a.colour] !== colorOrder[b.colour]) {
            return colorOrder[a.colour] - colorOrder[b.colour];
        }
        if (a.position[0] !== b.position[0]) {
            return a.position[0] - b.position[0];
        }

        return a.position[1] - b.position[1];
    });
};

export function applyGenieFeatures(options: {
    genieBonusFeatureColours: Array<{ position: Position; colour: FeatureColour }>;
    coinPrizes: CoinPrizes;
    choices: Choices;
    coinAmount: number;
    coinWeight: number;
}): void {
    const { genieBonusFeatureColours, coinPrizes, choices, coinAmount, coinWeight } = options;

    sortFeatureColours(genieBonusFeatureColours);

    for (const feature of genieBonusFeatureColours) {
        if (feature.colour === "G") {
            const greenPrizes = computeGreenFeaturePrizes({ choices, coinAmount, position: feature.position });
            const greenFeature: CoinPrize = {
                type: "FEATURE",
                colour: feature.colour,
                prizes: greenPrizes.coinPrizes,
                value: greenPrizes.totalValue,
                position: feature.position,
            };
            coinPrizes.push(greenFeature);
        } else if (feature.colour === "P") {
            computePurpleFeaturePrizesAndUpdateCoinPrizes({
                choices,
                position: feature.position,
                coinAmount,
                coinPrizes,
                coinWeight,
            });
        } else {
            computeRedFeaturePrizesAndUpdateCoinPrizes({
                position: feature.position,
                coinPrizes,
            });
        }
    }
}
