import {createDefaultSlotStep} from "./../../../../helpers/helpers";
import State from '../../../state';
import init from './config/init';

export function setStateParams() {
    return {
        variations: [],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = '', name= 'Base') => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.gameState = isFeature ? 'continue' : 'clear';
            return step;
        },

        // Must implement
        createDefaultStep: (order = 0, variation = '') => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),

        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([61, 0, 52, 0, 73], false, order, '', 'F-Trigger'),
        getFeatureStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0], true, order, '', 'F-Step'),
    };
}
