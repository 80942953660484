import type { Cell, LineWin } from "@vgw/gdk-math-model-tools";
import { createLineWinChecker } from "./line-win-checker";
import { filterSlotWindow } from "@vgw/gdk-math-model-tools";

export type ScatterWin = {
  symbol: string;
  cells: Cell[];
  multiplier: number;
  winAmount: number;
};

export type EvaluateWinsArgs = {
  slotWindow: string[][];
  playAmount: number;
  coinAmount: number;
  additionalMultiplersByCell?: {
    cell: [number, number];
    multiplier: number;
  }[];
};

export type WinInfo = {
  lineWins: {
    winAmount: number;
    playLineIndex: number;
    symbol: string;
    length: number;
    multiplier: number;
  }[];
  scatterWin: ScatterWin | undefined;
  winAmount: number;
  lineWinAmount: number;
};

export type WinsEvaluator = (arg: EvaluateWinsArgs) => WinInfo;

export type CreateWinsEvaluatorArgs = {
  playLines: number[][];

  wildSymbol: string;
  scatterSymbol: string;
  winTable: {
    symbol: string;
    multipliers: number[];
  }[];
  scatterWinTable: {
    symbol: string;
    multipliers: (undefined | number)[];
  }[];
};

export function createWinsEvaluator(
  modelDefinition: CreateWinsEvaluatorArgs
): WinsEvaluator {
  const evaluateLineWins = createLineWinChecker(modelDefinition);

  function evaluateScatterWin(
    slotWindow: string[][],
    playAmount: number
  ): ScatterWin | undefined {
    const cells = filterSlotWindow(
      slotWindow,
      (sym) => sym === modelDefinition.scatterSymbol
    );

    const multiplier =
      modelDefinition.scatterWinTable[0].multipliers[cells.length - 1];

    return multiplier
      ? {
          symbol: "SCAT",
          cells,
          multiplier,
          winAmount: multiplier * playAmount,
        }
      : undefined;
  }

  function calculateLineWinAmount(args: EvaluateWinsArgs) {
    return function (lineWin: LineWin) {
      return {
        ...lineWin,
        winAmount: lineWin.multiplier * args.coinAmount,
      };
    };
  }

  return function evaluateWins(args: EvaluateWinsArgs) {
    const lineWins = evaluateLineWins(
      args.slotWindow,
      args.additionalMultiplersByCell
    ).map(calculateLineWinAmount(args));

    const scatterWin = evaluateScatterWin(args.slotWindow, args.playAmount);

    const lineWinAmount = lineWins.reduce((acc, win) => acc + win.winAmount, 0);
    const scatterWinAmount = scatterWin?.winAmount ?? 0;

    const winAmount = lineWinAmount + scatterWinAmount;

    return {
      lineWins,
      lineWinAmount,
      scatterWin,
      winAmount,
    };
  };
}
