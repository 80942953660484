var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonus6Prize } },
      [
        _c("option", { attrs: { value: "FREE_SPIN" } }, [_vm._v("Freespin")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "JACKPOT" } }, [_vm._v("Jackpot")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "COLLECT" } }, [_vm._v("COLLECT")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN10" } }, [_vm._v("SPIN 10")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN15" } }, [_vm._v("SPIN 15")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN20" } }, [_vm._v("SPIN 20")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN30" } }, [_vm._v("SPIN 30")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN40" } }, [_vm._v("SPIN 40")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN50" } }, [_vm._v("SPIN 50")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "SPIN100" } }, [_vm._v("SPIN 100")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }