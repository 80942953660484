/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf002_celticgoddess",
        "wildSymbol": "Wild",
        "scatterSymbol": "Bonus",
        "freeSpinTrigger": "Bonus",
        "freeSpinCountArray": [8, 12, 16],
        "countToTriggerFreeSpin": 3,
        "winTable": [
            {
                "symbol": "HV1",
                "multipliers": [500, 125, 40]
            }, {
                "symbol": "HV2",
                "multipliers": [300, 100, 30]
            }, {
                "symbol": "HV3",
                "multipliers": [250, 60, 25]
            }, {
                "symbol": "MV1",
                "multipliers": [200, 50, 15]
            }, {
                "symbol": "MV2",
                "multipliers": [100, 30, 10]
            }, {
                "symbol": "MV3",
                "multipliers": [100, 30, 10]
            }, {
                "symbol": "Spade",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Heart",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Diamond",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Club",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Bonus",
                "multipliers": [10, 2, 1]
            }
        ],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 20, 25, 50, 100],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"2000000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"1000",

        "reelsLayout": [3, 3, 3, 3, 3],
        "reels": [
            [ "MV2", "Spade", "Spade", "MV3", "MV1", "Heart", "HV3", "Club", "Club", "HV1", "HV1", "HV1", "Diamond", "MV1", "MV1", "Spade", "Club", "Bonus", "Diamond", "Heart", "HV2", "MV3", "MV3", "Spade", "MV1", "Diamond", "HV3", "MV3", "Club", "MV2", "MV2", "Spade", "HV2", "MV1", "Diamond", "HV3", "MV3", "Club", "MV2", "HV2", "Spade", "Diamond", "Bonus", "Heart", "Heart", "HV3", "Diamond", "Diamond", "MV3", "MV1"],
            [ "HV3", "MV1", "Heart", "Heart", "MV2", "HV2", "MV3", "Club", "Club", "HV3", "MV1", "Spade", "Heart", "Wild", "Wild", "Wild", "Diamond", "Club", "HV2", "MV2", "Heart", "MV1", "HV3", "Diamond", "Diamond", "HV1", "HV1", "HV1", "Club", "Heart", "HV2", "MV1", "HV3", "Diamond", "Spade", "Bonus", "Heart", "Diamond", "HV3", "MV2", "MV3", "Club", "HV2", "Heart", "MV1", "MV1", "MV3", "Spade", "HV3", "MV2", "Heart", "HV2", "MV1", "Diamond", "MV2", "HV3", "Club", "MV3", "MV3", "Heart", "HV2", "MV2", "Spade" ],
            [ "Diamond", "Heart", "HV1", "HV1", "HV1", "Club", "Club", "HV3", "MV1", "Diamond", "MV2", "MV2", "Spade", "Spade", "Bonus", "Heart", "Diamond", "HV2", "MV3", "Spade", "HV3", "MV1", "Heart", "MV3", "HV2", "Club", "MV3", "MV1", "HV3", "Spade", "MV2", "Heart", "HV2", "MV3", "Diamond", "Bonus", "Club", "MV1", "MV2", "HV3", "Spade", "Heart", "HV1", "HV1", "HV1", "Diamond", "Diamond", "HV2", "MV2", "Spade", "MV3", "HV3", "Heart", "MV1", "HV2", "Club", "Bonus", "Diamond", "MV2", "HV3", "MV1", "Heart", "MV3", "Spade", "HV2" ],
            [ "HV3", "Spade", "Heart", "Bonus", "Club", "MV2", "HV2", "MV3", "Heart", "Diamond", "HV3", "MV1", "Spade", "Heart", "Wild", "Wild", "Wild", "Diamond", "Club", "HV2", "MV2", "Heart", "MV1", "HV3", "Spade", "Diamond", "HV1", "HV1", "HV1", "Club", "Heart", "HV2", "MV1", "HV3", "Spade", "Club", "Bonus", "Heart", "Diamond", "HV3", "MV2", "MV3", "Club", "HV2", "Heart", "Bonus", "Spade", "MV2", "MV3", "Heart", "HV3", "MV1", "Spade", "HV2", "MV3", "Diamond", "MV2", "HV3", "Club", "MV1", "MV3", "Heart", "HV2", "MV2", "Diamond" ],
            [ "MV2", "Diamond", "Spade", "MV3", "HV2", "Heart", "HV3", "Club", "Diamond", "HV1", "HV1", "HV1", "Heart", "MV2", "MV3", "Spade", "Diamond", "Bonus", "Club", "Heart", "HV2", "MV1", "MV2", "Spade", "MV3", "Diamond", "HV3", "MV1", "Club", "MV2", "MV3", "Spade", "HV2", "MV1", "Diamond", "HV3", "MV2", "Club", "HV2", "MV3", "Spade", "MV2", "Diamond", "HV2", "Club", "Heart", "HV3", "Diamond", "Spade", "MV3", "MV1" ]
        ],

        "freeSpinsReels": [
            [ "MV3", "HV3", "MV2", "MV1", "MV3", "Spade", "HV3", "MV1", "MV3", "Heart", "HV1", "HV1", "HV1", "Diamond", "MV2", "MV1", "MV3", "Club", "MV2", "MV1", "MV3", "MV2", "HV2", "MV3", "Diamond", "MV1", "HV3", "HV2", "HV2", "HV2", "MV3", "Spade", "MV2", "MV1", "HV2" ],
            [ "HV2", "HV2", "HV2", "Heart", "HV3", "HV3", "HV3", "Diamond", "MV1", "MV1", "MV1", "Spade", "MV2", "MV2", "MV2", "Club", "HV1", "HV1", "HV1", "Diamond", "MV3", "MV3", "MV1", "Spade", "Wild", "Wild", "Wild", "Heart", "Club", "HV2", "HV2", "Diamond", "MV2", "MV2", "Club", "HV3", "HV3", "HV3", "Heart", "MV3", "MV3", "Spade", "MV2", "MV2", "MV3" ],
            [ "MV2", "MV2", "MV2", "Heart", "HV3", "HV3", "HV3", "Diamond", "MV1", "MV1", "MV1", "Spade", "MV3", "MV2", "MV2", "Club", "HV1", "HV1", "HV1", "Diamond", "MV3", "MV3", "MV3", "HV2", "HV2", "HV2", "Heart", "MV1", "MV1", "MV1", "HV1", "HV1", "HV1", "Club", "HV3", "HV3", "HV3", "MV3", "MV3", "MV3", "Spade", "MV2", "MV2", "MV2", "HV3", "MV3", "MV1", "MV1", "MV1" ],
            [ "Club", "HV2", "HV2", "Heart", "HV3", "HV3", "HV3", "Diamond", "MV1", "MV1", "MV1", "Spade", "MV2", "MV2", "MV2", "Club", "HV1", "HV1", "HV1", "Diamond", "MV3", "MV3", "MV1", "Spade", "Wild", "Wild", "Wild", "Heart", "HV2", "HV2", "HV2", "Diamond", "MV2", "MV2", "Club", "HV3", "HV3", "HV3", "MV3", "MV3", "MV3", "Spade", "MV2", "Heart", "MV2" ],
            [ "MV3", "HV3", "MV1", "Club", "HV2", "MV3", "Spade", "HV3", "MV2", "MV3", "HV2", "Heart", "MV2", "MV3", "HV1", "HV1", "HV1", "Diamond", "MV1", "MV3", "MV2", "Club", "HV2", "MV1", "MV3", "Heart", "HV3", "HV2", "MV2", "Diamond", "MV1", "MV3", "HV2", "MV2", "MV1" ]
        ],



        "playLines": [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],

            [0, 0, 1, 2, 2],
            [2, 2, 1, 0, 0],
            [1, 0, 1, 2, 1],
            [1, 2, 1, 0, 1],
            [0, 0, 1, 1, 2],

            [2, 2, 1, 1, 0],
            [0, 2, 2, 2, 0],
            [2, 0, 0, 0, 2],
            [0, 2, 0, 2, 0],
            [1, 2, 1, 2, 1],

            [0, 0, 1, 2, 1],
            [2, 2, 1, 0, 1],
            [1, 0, 1, 2, 2],
            [1, 2, 1, 0, 0],
            [0, 0, 0, 1, 2],

            [2, 2, 2, 1, 0],
            [0, 1, 2, 2, 1],
            [2, 1, 0, 0, 1],
            [0, 1, 0, 1, 0],
            [2, 1, 2, 1, 2],

            [1, 0, 0, 0, 0],
            [1, 2, 2, 2, 2],
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],
            [2, 1, 2, 1, 2]
        ],
        "suitSymbols": ["Spade", "Heart", "Diamond", "Club"],
        "highPayingSymbols": ["HV1", "HV2", "HV3", "MV1","MV2", "MV3"],
        "featureBaseGameWeight": [
            {"value": true, "weight": 32},
            {"value": false, "weight": 988}
        ],
        "highPaySymbolsWeight": [
            {"value": "HV1", "weight": 2},
            {"value": "HV2", "weight": 3},
            {"value": "HV3", "weight": 3},
            {"value": "MV1", "weight": 7},
            {"value": "MV2", "weight": 9},
            {"value": "MV3", "weight": 8}
        ],
        "highPaySymbolsFSWeight": [
            {"value": "HV1", "weight": 15},
            {"value": "HV2", "weight": 20},
            {"value": "HV3", "weight": 10},
            {"value": "MV1", "weight": 20},
            {"value": "MV2", "weight": 17},
            {"value": "MV3", "weight": 18}
        ]
    }
,
}
