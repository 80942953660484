import Vue, { PropType } from "vue";
import { ReelSymbol } from "./reel-symbol";
import { SpinOutcome } from "../model/operations/play";
export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<SpinOutcome>,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    // Free Spins Check
    isFreeSpins: function () {
      if (this.spinOutcome.gameResponse.freeSpinPhase) {
        return true;
      } else {
        return false;
      }
    },
    // Banker Multiplier:
    isBankerMultiplier: function () {
      if (this.spinOutcome.gameResponse.bankerMultiplier) {
        return true;
      } else {
        return false;
      }
    },
    // Banker Stacks:
    isBankerStacks: function () {
      if (this.spinOutcome.gameResponse.bankerStacks) {
        return true;
      } else {
        return false;
      }
    },
   // Banker Scat: 
    isBankerScat: function () {
      if (this.spinOutcome.gameResponse.bankerScat) {
        return true;
      } else {
        return false;
      }
    },
    // Banker Case:
    isBankerCase: function () {
      if (this.spinOutcome.gameResponse.bankerCase) {
        return true;
      } else {
        return false;
      }
    },
    // Banker Offer
    isBankerOffer: function () {
      if (this.spinOutcome.gameResponse.bankerOfferAmount) {
        return true;
      } else {
        return false;
      }
    },
    // Case Chase:
    isCasePrizeAwarded: function () {
      if (this.spinOutcome.gameResponse.caseChase) {
        return true;
      } else {
        return false;
      }
    },
    isGoldCases: function () {
      if (this.spinOutcome.gameState?.caseChase?.goldCasePrizes) {
        return true;
      } else {
        return false;
      }
    },
    // Jackpot
    isJackpot: function () {
      if (this.spinOutcome.gameResponse.jackpotWinCounts) {
        return true;
      } else {
        return false;
      }
    },
    spinType: function () {
      if (
        this.spinOutcome.gameResponse.freeSpinPhase &&
        this.spinOutcome.gameResponse.freeSpinPhase !== "START"
      ) {
        return "FREE_SPINS";
      }
      return "BASE";
    },
  },
});