import type { PlayArguments } from '@vgw/gdk-math-model-server';
import type { GameState } from '../shared';
import type { GameRequest } from './game-request';
import { modelDefinition } from '../../model-definition';
import { createValueValidator } from '@vgw/gdk-math-model-tools';

export type SpinInputs = PlayArguments<GameRequest, GameState>;

export function assertSpinInputsValid({ coinType, gameRequest, gameState }: SpinInputs): void {
    // Ensure a valid coin size has been sent
    if (!isValidCoinAmount(coinType, gameRequest.coinAmount)) {
        throw new Error('Invalid coin size selected');
    }

    // Ensure play amount does not change during a round
    if (gameState && gameState.coinAmount !== gameRequest.coinAmount) {
        throw new Error('Coin size changed during a round');
    }
}

const isValidCoinAmount = createValueValidator(modelDefinition.validCoinAmounts);
