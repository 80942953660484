export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  coinPrizes?: CoinPrize[][];
}

export type CoinPrize = number | "MINI" | "MINOR" | "MAJOR";
export const originatorId = new Date().getTime();

export function createClearingStep(): StepJson {
  return {
      name: 'Clear',
      originatorId,
      gameState: 'clear',
      reelStripPositions: [0, 2, 0, 0, 0],
  };
}

export function createSpinStep(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [14, 14, 14, 14, 14],
  };
}

export function createFreeSpinTriggerStep(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [63, 39, 28, 56, 26],
  };
}

export function createFireshotTriggerStep(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [20, 52, 10, 8, 47],
  };
}