import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

export const GameStateSchema = Type.Object({
    slotWindow: Type.Array(Type.Array(Type.String())),
    coinAmount: Type.Integer(),
    cumulativeWinAmount: Type.Integer(),
    freeSpinPhase: Type.Union([
        Type.Literal('START'),
        Type.Literal('IN_PROGRESS'),
        Type.Literal('RETRIGGER'),
        Type.Literal('END'),
    ]),
    freeSpinCount: Type.Integer(),
    reelStripPositions: Type.Array(Type.Integer()),
});
export type GameState = Static<typeof GameStateSchema>;
