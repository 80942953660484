/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "modelId" : "vgw032",
    "reelsLayout" : [ 3, 3, 3, 3, 3 ],
    "scatterSymbol" : "SCAT",
    "wildSymbol" : "WILD",
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 0,
    "winTable" : [ {
      "symbol" : "pic1",
      "multipliers" : [ 500, 60, 16 ]
    }, {
      "symbol" : "pic2",
      "multipliers" : [ 500, 60, 16 ]
    }, {
      "symbol" : "pic3",
      "multipliers" : [ 300, 50, 12 ]
    }, {
      "symbol" : "pic4",
      "multipliers" : [ 300, 50, 12 ]
    }, {
      "symbol" : "pic5",
      "multipliers" : [ 250, 40, 10 ]
    }, {
      "symbol" : "Heart",
      "multipliers" : [ 120, 30, 8 ]
    }, {
      "symbol" : "Diamond",
      "multipliers" : [ 120, 30, 8 ]
    }, {
      "symbol" : "Spade",
      "multipliers" : [ 120, 30, 8 ]
    }, {
      "symbol" : "Club",
      "multipliers" : [ 120, 30, 8 ]
    }, {
      "symbol" : "SCAT",
      "multipliers" : [ 200, 10, 4, 1 ]
    }, {
      "symbol" : "WILD",
      "multipliers" : [ 4000, 100, 20 ]
    } ],
    "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ] ],

    "reels" : [
      ["pic2", "Spade", "Club", "pic3", "Heart", "pic4", "Diamond", "WILD", "pic2", "Diamond", "pic1", "Spade", "pic5", "pic1", "Spade", "pic2", "Spade", "SCAT", "Club", "pic3", "Heart", "pic4", "Diamond", "pic1", "Club", "Diamond", "pic1", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "pic1"],
      ["pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "pic1", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "SCAT", "Spade", "pic5", "pic3", "Diamond", "WILD", "Club", "pic1", "Diamond", "Heart", "pic4"],
      ["Spade", "Club", "pic4", "Heart", "pic2", "pic5", "pic3", "Spade", "pic1", "Heart", "pic1", "Spade", "Diamond", "SCAT", "Spade", "Heart", "WILD", "pic3", "Spade", "pic1", "Heart", "pic3", "Spade", "pic1", "Heart", "pic3"],
      ["Diamond", "Heart", "pic1", "pic5", "Club", "WILD", "pic1", "pic2", "Club", "pic4", "pic2", "Spade", "SCAT", "Diamond", "Club", "pic4", "Spade", "pic1", "pic2", "Club", "pic4", "pic2", "pic3", "Heart", "pic5", "pic4"],
      ["SCAT", "pic4", "Diamond", "pic1", "Heart", "Spade", "pic2", "Diamond", "pic3", "pic1", "Heart", "pic2", "WILD", "Diamond", "pic2", "pic1", "Club", "pic4", "Diamond", "pic2", "pic4", "Club", "pic3", "WILD", "pic4", "Heart", "pic3", "pic5", "WILD", "Spade", "pic3", "pic2"]
    ],
    
      "defaultSelectLine" : 25,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values" : [ 1, 2, 5, 10, 50 ],
    "coinType1_min_bet" : 250,
    "coinType1_max_bet" : 100000,
    "coinType1_spin_max_bet" : 2500000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 50,
    "coinType4_spin_max_bet" : 1250,
    "winLevels" : [ {
      "name" : "normal1",
      "threshold" : 5
    }, {
      "name" : "normal2",
      "threshold" : 15
    }, {
      "name" : "normal3",
      "threshold" : 25
    }, {
      "name" : "bigwin",
      "threshold" : 50
    }, {
      "name" : "megawin",
      "threshold" : 150
    }, {
      "name" : "epicwin",
      "threshold" : 2147483647
    } ],
    "freeSpinTrigger" : "SCAT",
    "countToTriggerFreeSpin" : 3,
    "featureOptions" : {
      "8" : {
        "multipliers" : [ 2, 3, 4, 5, 10 ],
        "multipliersWeights" : [ 1, 1, 2, 2, 5 ],
        "columnOptions" : [ [ 0, 1, 2, 3 ], [ 0, 1, 2, 4 ] ]
      },
      "10" : {
        "multipliers" : [ 2, 3, 4, 5, 10 ],
        "multipliersWeights" : [ 1, 3, 4, 2, 5 ],
        "columnOptions" : [ [ 0, 1, 2 ], [ 0, 1, 3 ], [ 0, 1, 4 ], [ 0, 2, 3 ] ]
      },
      "15" : {
        "multipliers" : [ 2, 3, 4, 5, 10 ],
        "multipliersWeights" : [ 1, 5, 3, 2, 2 ],
        "columnOptions" : [ [ 0, 1 ], [ 0, 2 ], [ 0, 3 ], [ 0, 4 ], [ 1, 2 ], [ 1, 3 ], [ 1, 4 ], [ 2, 3 ], [ 2, 4 ], [ 3, 4 ] ]
      }
    },
    "currency_symbol" : "$",
    "normal_count" : "2"
  },
  "modelId" : "vgw032",
  "gameSessionId" : 1,
  "reelStripPositions" : [ 1, 16, 2, 18, 3 ],
  "slotWindow" : [ [ "Spade", "Club", "pic3" ], [ "pic5", "pic3", "Diamond" ], [ "pic4", "Heart", "pic2" ], [ "pic2", "Club", "pic4" ], [ "pic1", "Heart", "Spade" ] ],
  "gold_balance" : 2008650000,
  "sweeps_balance" : 206529343,
  "sweeps_deposit_balance" : 200001395,
  "sweeps_promo_balance" : 0,
  "sweeps_win_balance" : 6527948,
  "sweeps_available_balance" : 6527948,
  "serverTime" : "2018-08-20 16:43:58"
}
