export type Cell = [row: number, col: number];
export interface WaysWin {
    /** The symbol that has a winning pattern in the slot window. */
    symbol: string;
  
    /** The number of appearances of `symbol` in the winning pattern. */
    length: number;
  
    /** The cells making up the winning pattern. Each cell is in [row, col] format relative to the top-left of the slot window. */
    cells: Cell[];
}
export interface RunOfSymbol {
    slotWindowRows: number[];
    winningSymbol: string | undefined;
}

export function createVgw051WaysWinChecker(modelDefinition: {
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
}) {
  const waysWinChecker = createWaysWinChecker(modelDefinition);

  return function checkWaysWins(slotWindow: string[][], topReel: string[]) {
    const combinedSlotWindow = slotWindow.map((column) => [...column]);
    for (let topReelIndex = 0; topReelIndex < topReel.length; topReelIndex++) {
      const topReelSymbol = topReel[topReelIndex];
      const reelIndex = topReelIndex + 1;
      const reel = combinedSlotWindow[reelIndex];
      reel.unshift(topReelSymbol);
    }
    return waysWinChecker(combinedSlotWindow);
  };
}

function createWaysWinChecker(modelDefinition: {
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
}) {
  return function checkWaysWins(slotWindow: string[][]): WaysWin[] {
    const result: WaysWin[] = [];
    const { wildSymbol, scatterSymbol } = modelDefinition;

    for (let { slotWindowRows, winningSymbol } of findAllRunsOfSymbols(
      slotWindow,
      wildSymbol,
      scatterSymbol
    )) {
      // We may have a symbol+wild win, a pure wild win, or both or neither. Check all possibilities.
      const symbolLineWinLength = winningSymbol ? slotWindowRows.length : 0;
      const symbolLineWinMultiplier = getSymbolWinMultiplier(
        modelDefinition,
        winningSymbol ?? "",
        symbolLineWinLength,
        slotWindow.length
      );

      // Skip non-winning runs of symbols.
      if (symbolLineWinMultiplier === 0) continue;

      const winCell: Cell[] = slotWindowRows.map((row, col) => [row, col]);
      const symbolWin: WaysWin = {
        symbol: winningSymbol as string,
        cells: winCell,
        length: symbolLineWinLength,
      };

      result.push(symbolWin);
    }
    return result;
  };
}

function findAllRunsOfSymbols(
  slotWindow: string[][],
  wildSymbol: string,
  scatterSymbol: string
) {
  return findLongerRuns({ slotWindowRows: [], winningSymbol: undefined });

  /** Finds all the ways with a left-to-right run of a symbol that is longer than the passed-in run. */
  function findLongerRuns({
    slotWindowRows,
    winningSymbol,
  }: RunOfSymbol): RunOfSymbol[] {
    if (slotWindowRows.length >= slotWindow.length) return [];
    const nextSlotWindowColumn = slotWindow[slotWindowRows.length];
    const results: RunOfSymbol[] = [];
    for (let i = 0; i < nextSlotWindowColumn.length; ++i) {
      const symbol = nextSlotWindowColumn[i];
      if (symbol === scatterSymbol) continue;
      if (symbol !== wildSymbol && winningSymbol && symbol !== winningSymbol)
        continue;

      // This symbol extends the run by one.
      const longerRun: RunOfSymbol = {
        slotWindowRows: [...slotWindowRows, i],
        winningSymbol: symbol !== wildSymbol ? symbol : winningSymbol,
      };

      // Recursively check for runs even longer that the one just discovered.
      // If none are found, just use the one-longer run discovered above.
      let longerRuns = findLongerRuns(longerRun);
      if (longerRuns.length === 0) longerRuns = [longerRun];
      results.push(...longerRuns);
    }

    // Return the accumulated results.
    return results;
  }
}

function getSymbolWinMultiplier(
  modelDefinition: {
    scatterSymbol: string;
    wildSymbol: string;
    winTable?: Array<{
      symbol: string;
      multipliers: number[];
    }>;
  },
  symbol: string,
  countOfSymbol: number,
  slotWindowWidth: number
) {
  const winTableEntry = modelDefinition.winTable?.find(
    (entry) => entry.symbol === symbol
  );
  const multiplier =
    winTableEntry?.multipliers?.[slotWindowWidth - countOfSymbol];
  return multiplier || 0;
}

export function filterSlotWindow(slotWindow: string[][], predicate: FilterSlotWindowPredicate): Cell[] {
  const result: Cell[] = [];
  for (let col = 0; col < slotWindow.length; ++col) {
      for (let row = 0; row < slotWindow[col].length; ++row) {
          const cell: Cell = [row, col];
          const symbol = slotWindow[col][row];
          if (predicate(symbol, cell, slotWindow)) {
              result.push(cell);
          }
      }
  }
  return result;
}

/** Callback signature used as the predicate for the `filterSlotWindow` function. */
type FilterSlotWindowPredicate = (symbol: string, cell: Cell, slotWindow: string[][]) => boolean;

export function createVgw051ScatterWinChecker(modelDefinition: {
  scatterSymbol: string;
  countToTriggerFreeSpins: number;
}) {
  const { scatterSymbol, countToTriggerFreeSpins } = modelDefinition;
  return function checkScatterWin(slotWindow: string[][], topReelSlotWindow: string[]) {
      const slotWindowCells = filterSlotWindow(slotWindow, (sym) => sym === scatterSymbol);
      const topReelCells = filterSlotWindow([topReelSlotWindow], (sym) => sym === scatterSymbol).map(
          (cell) => (cell = [cell[0], 6])
      );
      const hasWon = slotWindowCells.length + topReelCells.length >= countToTriggerFreeSpins;
      const cells = hasWon ? slotWindowCells.concat(topReelCells) : [];
      return cells;
  };
}

export function filterVgw051SlotWindow(
  topReelSlotWindow: string[],
  slotWindow: string[][],
  filter: FilterSlotWindowPredicate
) {
  // The map sets the row to 6 for the top reel, to treat it like a 7th reel that has been added to the slot window
  const filteredTopReel = 
      filterSlotWindow([topReelSlotWindow], filter)
      .map((cell): Cell => [cell[0], 6]);
  const filteredSlotWindow = filterSlotWindow(slotWindow, filter);
  return filteredSlotWindow.concat(filteredTopReel);
}