/* tslint:disable:variable-name */
import State from "../../../state";
import {
    trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["96"],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reelset_base";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: (type, index = 0) => {
            switch(type) {
                case "reelset_base":
                    return State.state.getModelDefinition().reels;
                    break;
                case "reelset_wildReels_base":
                    return State.state.getModelDefinition().wildReels.base;
                    break;
                case "reelset_wildReels_feature":
                    let featureWildReel = "feature" + State.state.getModelDefinition().wildReels.featureWildReelProbabilityList[index].featureReelsIndex;
                    return State.state.getModelDefinition().wildReels[featureWildReel];
                    break;
                case "reelset_criminal_base":
                    return State.state.getModelDefinition().criminalReels.base;
                    break;
                case "reelset_criminal_feature":
                    return State.state.getModelDefinition().criminalReels.feature;
                    break;
                case "reelset_flyingWildReels_base":
                    return State.state.getModelDefinition().flyingWildReels.base;
                    break;
                case "reelset_flyingWildReels_feature":
                    return State.state.getModelDefinition().flyingWildReels.feature;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },


        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        // getWildReelStrips: (baseGame = true) => baseGame ? State.state.getModelDefinition().wildReels.base : State.state.getModelDefinition().wildReels.feature1,
        // getCriminalReelStrips: (baseGame = true) => baseGame ? State.state.getModelDefinition().criminalReels.base : State.state.getModelDefinition().criminalReels.feature,
        // getFlyingWildReelStrips: (baseGame = true) => baseGame ? State.state.getModelDefinition().flyingWildReels.base : State.state.getModelDefinition().flyingWildReels.feature,

        getWildReelStep: (type, wildReelNumber, baseGame = true) => {
            const name = 'WR' + (baseGame ? '' : '-F');

            let step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", name);
            step.reelsType = "reelset_" + type;

            if (baseGame == true){
                let arrbaseList = State.state.getModelDefinition().wildReels.baseWildReelProbabilityList[wildReelNumber];
                step.baseWildReelList = arrbaseList;
                step.baseWildReelNumber = wildReelNumber;
            } else {
                step.featureWildReelList = State.state.getModelDefinition().wildReels.featureWildReelProbabilityList[wildReelNumber];
                step.featureWildReelNumber = wildReelNumber;
            }
            return step;
        },
        getCriminalReelStep: (type, baseGame = true) => {
            let name = 'CR' + (baseGame ? '' : '-F');
            let step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", name);
            step.reelsType = "reelset_" + type;
            return step;
        },

        getFlyingWildReelStep: (type, baseGame = true) => {
            let name = 'FW' + (baseGame ? '' : '-F');
            let step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", name);
            step.reelsType = "reelset_" + type;
            return step;
        },
        // example on creating custom indexes
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([10, 24, 25, 35, 8], false, order, "", "F-Trigger"),
        getFeatureStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0], true, order, "", "F-Step"),
        getFeatureReTriggerStep: (order = 0) => State.state.createStep([14, 22, 14, 14, 14], true, order, "", "F-Step"),
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 3);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
