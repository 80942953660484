/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf127",

        reelsLayout: [5, 5, 5, 5, 5],
        scatterSymbol: "SC",
        mysterySymbol: "MYS",
        noSymbol: "NIL",
        wildSymbol: "Z",
        coinSizeMultiplier: 20,
        totalSymbolNum: 25,
        playLines: [],

        winTable: [
            {symbol: "H1", multipliers: [20, 12, 8]},
            {symbol: "H2", multipliers: [12, 8, 6]},
            {symbol: "H3", multipliers: [10, 6, 5]},
            {symbol: "H4", multipliers: [8, 5, 4]},
            {symbol: "H5", multipliers: [6, 4, 3]},
            {symbol: "L1", multipliers: [5, 3, 2]},
            {symbol: "L2", multipliers: [4, 3, 2]},
            {symbol: "L3", multipliers: [3, 2, 1]},
            {symbol: "L4", multipliers: [3, 2, 1]},
            {symbol: "L5", multipliers: [3, 2, 1]},
        ],

        // prettier-ignore
        reels:{
            set1: [
                ['L2','L2','L2','H1','L4','L5','L5','L1','H4','H4','H2','L3','L3','SC','L2','H3','H3','H3','L3','L2','L4','L4','L4','H2','L1','H1','H4','L5','L2','L2','H1','H1','L4','L1','L1','H2','H2','L1','L4','L4','L4','H1','H1','SC','L2','L5','L5','L5','H4','L1','L3','H1','L2','H2','L4','L4','L5','L5','H3','L1','L1','L3','H4','H4','H4','L1','L2','L4','L4','H1','L1','L1','L2','H2','L5','L5','L5','SC','H4','L1','L4','L4','L3','H3','L2','L2','L5','L5','H1','L1','L1','L1','L4','L4','L2','H2','L5','L5','L1','H5','H5','L2','L3','L3','H4','H4','L5','SC','L2','L2','L2','L4','H3','H3','L1','H3','L5','L5','L5','H4','H4','L1','L1','L4','L4','L2','L2','H3','H2','L5','L5','H3','H3','H4','H4','L1','L1','L5','L4','SC','H4','H4','H1','L4','L4','L4','H3','L1','H5','H5','L5','L2','L2','H2','L3','H3','H3','L5','L5','L2','H4','L4','L4','H3','H5','L1','L1','L1','L4','SC','H1','H1','L2','L2','L5','H5','H5','L4','L4','H4','H4','L1','L5','L5','H3','H3','L2','H5'],
                ['H1','H1','L1','L4','L4','L4','H5','L5','L5','H1','L3','L3','L3','L1','H2','H2','H2','H5','L4','H3','H3','H3','H1','L1','L1','H5','H5','L3','L3','L4','L4','H3','H1','H1','L5','L5','L3','L3','L3','H5','H3','L1','L1','H1','L2','H4','H4','L3','L4','L4','L1','H5','H5','H5','L3','L3','H1','L4','L4','L4','H3','H2','H2','L1','L1','L2','H1','L3','L3','L5','H5','L4','L4','H2','H2','L1','L1','L3','H5','H2','H2','L4','L3','L3','H5','L1','L1','L1','H2','H3','H3','L5','H4','H4','L3','H1','L2','L4','L4','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','H4','H4','L1','L3','L3','L3','H3','H3','L4','L2','L2','L1','H1','H5','H5','L3','H2','H2','L4','L4','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','H3','L5','L5','L3','H5','H5','L4','L4','H4','L1','L1','L1','H2','L4','L3','L3','H5','H5','H3','L4','L4','L4','L2','H4','H3','L1','L1','L1','H1','H5','H5'],
                ['H4','H4','L3','L3','H2','H2','L5','L5','L3','H5','H5','L2','H4','L5','L5','H3','L4','L4','H5','H5','H1','L3','L3','L3','SC','L5','L5','L1','H2','H2','H4','H4','H5','L5','L5','L2','H1','H1','L4','L3','L3','H3','H5','L2','L2','L2','L1','L3','L3','H2','L5','L5','L5','SC','H5','L2','H5','H4','L3','L3','H3','L2','L2','H5','H5','L5','L5','L5','L1','H3','L2','L3','L3','L3','L5','H1','H1','H5','L4','L4','L2','H4','H4','H4','SC','L5','L5','H1','L2','L2','H3','L3','L3','L3','L5','H5','H5','H5','L3','H4','L2','L2','L1','H2','H2','H2','L3','H1','H1','L2','L2','H4','H4','L3','L3','SC','H5','L5','L5','H3','L2','H2','H5','H5','L3','H1','H5','L5','H4','H4','L2','L2','L2','L3','H3','H3','L5','L5','H1','L3','L3','H2','L1','L1','SC','L5','H2','H2','L2','L2','H4','L3','L3','H3','L4','L4','H5','H5','L2','L5','L5','H2','H2','L2','H4','H4','L4','H2','L1','L1','H2','L5','H1','H1','SC','L2','L3','L3','H4','H4','L4','L5','L5','L5','L2','H5','H5'],
                ['L2','L2','L2','H1','H1','L4','H2','L5','L5','L1','H4','H4','L2','L3','L3','L2','H3','H3','L5','L4','L4','H5','H2','L1','H1','L4','L3','L3','H4','L5','L2','L2','H1','L1','L1','H5','L4','L3','L3','H2','H2','L1','L4','L4','L4','H1','L2','L5','L5','L5','H4','L3','H5','H5','H5','L1','L3','L3','L3','H1','L2','L2','H2','L4','L4','L5','H3','L1','L1','L2','H4','H4','H4','L3','L5','L5','H2','H2','L1','L4','L4','H5','H5','L5','L1','L1','L3','H3','H3','H3','L2','H2','L5','L5','L5','H4','L4','L4','H3','L2','L2','H5','L3','L3','H1','L1','L1','L1','H2','H2','L4','L4','H5','H5','L2','L3','L3','H2','L5','L5','L1','L1','H5','H5','L3','H4','H4','L5','L2','L2','L2','L4','H3','H3','L3','L3','L5','L5','H4','L1','L1','L4','H5','H5','L2','L2','H3','L3','H2','H2','H2','L4','L5','L5','H4','L2','H3','H3','H5','H4','H4','L1','L1','L5','L4','L3','H4','H4','H5','L5','L2','H1','H1','L3','L4','L4','L4','H3','L1','H5','H5','L2','L2','H2','H2','L3','L3','L3','H3','H3','L5','L5','H4','H5','L4','L4','H3','L1','L1','L1','H1','H1','H1','L1','L2','L3','L5','H5','H5','L4','L4','H4','H4','L1','L5','L5','H3','H3','L4','L3','L3','L2','H5'],
                ['H1','H1','H1','L2','H2','L1','L1','H4','L5','L5','H1','L4','L4','L2','SC','H5','H5','L3','H4','H4','L5','L5','L2','L3','L3','L1','H2','H2','H2','L5','H5','L2','L2','L2','H4','L4','H3','H3','L5','L5','L5','H1','L1','H5','H5','L1','L3','L3','SC','H4','H4','L5','L4','L4','L3','L2','L2','H3','L1','L2','L2','L5','H4','H4','L4','L3','L3','L3','H5','L5','L5','H3','L1','L1','H1','L2','L2','H4','H4','SC','L3','L4','L4','L1','H5','H5','H5','L3','L2','L2','H1','L5','L5','H4','L4','L4','L4','H3','L5','H2','H2','L4','L1','L1','H4','L2','L3','L3','H5','L4','L4','H2','H2','SC','L1','L1','L3','H1','H1','H4','L2','L2','L4','H3','H3','H3','H2','H2','L4','L3','L3','H5','L1','L1','L1','H2','L2','H3','H3','L5','L3','L3','H1','H1','L2','L5','L5','L5','H4','L4','L4','SC','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','L2','H4','H4','H4','L1','L3','L3','L3','L5','H3','H3','L4','L2','L2','L2','L1','H1','H5','H5','L3','SC','H2','H2','L5','L5','H4','L4','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','H3','L5','L5','L3','H5','H5','L4','L4','H4','L5','SC','L1','H2','H1','L4','L3','L3','L1','H5','H5','L5','L5','H3','L4','L4','L4','L2','H4','L1','L1','H1','L5','H5','H5'],
            ],
            set2: [
                ['H1','H1','L1','L4','L4','L4','H5','L5','L5','SC','H1','L3','L3','L3','L1','H2','H2','H2','L5','H5','L4','H3','H3','H3','H1','L1','L1','H5','H5','L3','L3','L4','L4','H3','H1','H1','H1','H4','L5','L5','SC','L3','L3','L3','H5','H3','L1','L1','H1','L2','L2','H4','H4','L3','L4','L4','L1','H5','H5','H5','L3','L3','H1','L4','L4','L4','H3','L3','L3','H2','H2','L1','L1','SC','L2','L3','L3','H5','L4','L4','H2','H2','L1','L1','L3','H5','H2','H2','L4','L3','L3','H5','L1','L1','L1','H2','H3','H3','L4','L4','L5','H4','H4','L3','SC','H1','L2','L4','L4','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','H4','H4','L1','L3','L3','L3','H3','H3','L4','L2','L1','H1','SC','H2','H2','L3','H5','H5','L4','L4','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','H3','L5','L5','L3','H5','H5','L4','L4','H4','L4','SC','L3','L3','H2','L1','L1','L1','H5','H5','H3','L4','L4','L4','L2','H4','H3','L1','L1','L1','H1','H5','H5'],
                ['H4','H4','L4','H2','H2','L5','L5','L3','H5','H5','L2','H4','L4','L4','H5','H5','H1','L3','L3','L3','L5','L5','L1','H2','H2','H4','H4','H5','L5','L5','L2','H1','H1','L4','L3','L3','H3','H5','L2','L2','L2','L1','H1','L3','L3','H2','L5','L5','L5','H5','L2','H5','H4','L3','L3','H3','L2','L2','H5','H5','L5','L5','L5','L1','H3','L2','L3','L3','L3','L5','H1','H1','H5','L4','L2','H4','H4','H4','L5','L5','L2','L2','H3','L3','L3','L3','L5','H5','H5','H5','L3','H4','L2','L2','L1','H2','H2','H2','L3','H1','H1','H1','L2','L2','H4','H4','L3','L3','H5','L5','L5','H3','L2','H2','H5','H5','L3','H5','L5','H4','H4','L2','L2','L2','L3','H3','H3','L5','L5','H1','L3','L3','H2','L1','L1','L5','H2','H2','L2','L2','H4','L3','L3','H3','L4','L4','H5','H5','L2','L5','L5','H2','H2','L2','H4','H4','L4','H2','L1','L1','H2','L5','H1','H1','L2','L3','L3','H4','H4','L4','L5','L5','L5','L2','H5','H5'],
                ['L2','L2','L2','H1','L4','L5','L5','L1','H4','H4','L3','L3','SC','L2','H3','H3','H3','L3','L4','L4','L4','L2','H2','L1','H1','H4','L5','L2','L2','H1','H1','L4','L1','L1','H5','H2','H2','L1','L4','L4','L4','L2','SC','L1','H1','H1','L5','L5','L5','H4','L3','H1','L2','H2','L4','L4','L5','L5','H3','L1','L1','L3','H4','H4','H4','L1','L4','L4','L1','L1','L2','H2','SC','L5','L5','L5','H4','L1','L4','L4','L3','H3','L2','L2','L5','L5','H1','L1','L1','L1','L4','L4','L2','H2','L5','L5','L1','H5','H5','L5','L2','SC','L3','L3','H4','H4','L2','L2','L2','L4','H3','H3','L1','H3','L5','L5','L5','L2','H4','H4','L1','L1','L4','L4','L2','L2','H3','H2','L5','L5','H3','H3','SC','L1','L1','H4','H4','L5','L4','H1','H4','H4','L4','L4','L4','H3','L1','H5','H5','L5','L2','L2','H2','L3','H3','H3','L5','L5','L2','H4','L4','L4','H3','H5','L1','L1','L1','L4','H5','H5','L5','SC','L2','L2','H1','H1','H1','L4','L4','H4','H4','L1','L5','L5','H3','H3','L2','H5'],
                ['L2','L2','L2','H1','H1','L4','H2','L5','L5','L1','H4','H4','L2','L3','L3','L2','H3','H3','L5','L4','L4','H5','H2','L1','H1','L4','L3','L3','H4','L5','L2','L2','H1','L1','L1','H5','L4','L3','L3','H2','H2','L1','L4','L4','L4','H1','L2','L5','L5','L5','H4','L3','H5','H5','H5','L1','L3','L3','L3','H1','L2','L2','H2','L4','L4','L5','H3','L1','L1','L2','H4','H4','H4','L3','L5','L5','H2','H2','L1','L4','L4','H5','H5','L5','L1','L1','L3','H3','H3','H3','L2','H2','L5','L5','L5','H4','L4','L4','H3','L2','L2','H5','L3','L3','H1','L1','L1','L1','H2','H2','L4','L4','H5','H5','L2','L3','L3','H2','L5','L5','L1','L1','H5','H5','L3','H4','H4','L5','L2','L2','L2','L4','H3','H3','L3','L3','L5','L5','H4','L1','L1','L4','H5','H5','L2','L2','H3','L3','H2','H2','H2','L4','L5','L5','H4','L2','H3','H3','H5','H4','H4','L1','L1','L5','L4','L3','H4','H4','H5','L5','L2','H1','H1','L3','L4','L4','L4','H3','L1','H5','H5','L2','L2','H2','H2','L3','L3','L3','H3','H3','L5','L5','H4','H5','L4','L4','H3','L1','L1','L1','H1','H1','H1','L1','L2','L3','L5','H5','H5','L4','L4','H4','H4','L1','L5','L5','H3','H3','L4','L3','L3','L2','H5'],
                ['H1','H1','H1','L2','L1','L1','L5','L5','H1','L4','L4','H2','L2','SC','H5','H5','L5','L3','H4','H4','L4','L5','L5','L2','L3','L3','L1','H2','H2','H2','L3','H5','L2','L2','L2','H4','L4','H3','H3','L5','L5','L5','H1','L1','H5','H5','L1','L3','L3','SC','H4','H4','L5','L4','L4','L2','L2','H3','L1','L2','L2','L5','H4','H4','L4','L3','L3','L3','H5','L5','L5','H3','L1','L1','H1','L2','L2','H4','H4','SC','L3','L4','L4','L1','H5','H5','H5','L3','L2','L2','H1','L5','L5','H4','L4','L4','L4','H3','L5','H2','H2','L1','L1','H4','L2','L3','L3','H5','L4','L4','H2','H2','SC','L1','L1','L3','H4','L2','L2','L4','H3','H3','H3','H2','H2','L4','L3','L3','H5','L1','L1','L1','H2','L2','H3','H3','L5','L3','L3','H1','H1','L2','L5','L5','L5','H4','L4','L4','SC','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','L2','H4','H4','H4','L1','L3','L3','L3','L5','H3','H3','L4','L2','L2','L2','L1','H1','H5','H5','L3','SC','H2','H2','L5','L5','H4','L4','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','H3','L5','L5','L3','H5','H5','L4','L4','H4','L5','SC','L1','H2','H1','L4','L3','L3','H4','L1','H5','H5','L5','L5','H3','H1','H1','L4','L4','L4','L2','H4','L1','L1','H1','L5','H5','H5'],
            ],
            set3: [
                ['H4','H4','H2','H2','L5','L5','L3','H5','H5','SC','L2','H4','H3','L4','L4','H5','H5','H1','L3','L3','L3','L5','L5','L1','H2','H2','H4','H4','H5','L5','L5','L2','H1','H1','L4','L3','L3','SC','H3','L5','L5','H5','L2','L2','L2','L1','H1','L3','L3','H2','L5','L5','L5','H5','L2','H5','H4','L3','L3','H3','L2','L2','H5','H5','L5','L5','L5','L1','H3','L2','SC','L3','L3','L3','L5','H1','H1','L4','L4','H5','L2','H4','H4','H4','L5','L5','L2','L2','H3','L3','L3','L3','L5','H5','H5','H5','L3','H4','L2','L2','L1','SC','H2','H2','H2','L3','H1','H1','L2','L2','H4','H4','L3','L3','H5','L5','L5','L2','H2','H5','H5','L3','H1','H5','L5','H4','H4','L2','L2','L2','L3','SC','H3','H3','L5','L5','H1','L3','L3','H2','L1','L1','L5','H2','H2','L2','L2','H4','L3','L3','H3','L4','L4','H5','H5','L2','L5','L5','H2','H2','L2','SC','H4','H4','L4','H2','L1','L1','H2','L5','H1','H1','L2','L3','L3','H4','H4','L4','L5','L5','L5','L2','H5','H5'],
                ['L2','L2','L2','H1','L4','L5','L5','L1','H4','H4','H2','L3','L3','L2','L2','H3','H3','H3','L3','L4','L4','L4','H2','L1','H1','H4','L5','L2','L2','H1','H1','L4','L1','L1','H2','H2','L1','L4','L4','L4','H1','H1','L2','L5','L5','L5','H4','L1','L3','H1','L2','H2','L4','L4','L5','L5','H3','L1','L1','L3','H4','H4','H4','L1','L4','L4','H1','L1','L1','L2','H2','L5','L5','L5','H4','L1','L4','L4','L3','H3','L2','L2','L5','L5','H1','L1','L1','L1','L4','L4','L2','L2','L2','H2','L5','L5','L1','H5','H5','L3','L3','H4','H4','L5','L2','L4','H3','H3','L1','H3','L2','L5','L5','L5','H4','H4','L2','L1','L1','L4','L4','L2','L2','H3','H2','L5','L5','H3','H3','L2','H4','H4','L1','L1','L5','L4','H4','H4','H1','L4','L4','L4','H3','L1','H5','H5','L5','L2','H2','L3','H3','H3','L5','L5','L2','H4','L4','L4','H3','H5','L1','L1','L1','L4','H1','H1','L2','L2','L5','H5','H5','L4','L4','H4','H4','L1','L5','L5','H3','H3','L2','H5'],
                ['H1','H1','L1','SC','L4','L4','L4','H5','L5','L5','H1','L3','L3','L3','L1','H2','H2','H2','H5','L4','H3','H3','H3','L4','L4','H1','L1','L1','H5','H5','L3','L3','H3','L5','L5','SC','H1','H1','L4','L4','L3','L3','L3','H5','H3','L1','L1','H1','L2','H4','H4','L3','L4','L4','L1','H5','H5','H5','H1','L4','L4','L4','SC','L3','L3','H3','H2','H2','L1','L1','H1','L2','L3','L3','L5','H5','L4','L4','H2','H2','L1','L1','L3','H5','L1','L1','L1','H2','H2','L3','L3','H2','SC','H5','L4','H3','H3','L5','H4','H4','L3','H1','L2','L4','L4','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','H4','H4','L3','L3','L3','SC','L1','H3','H3','L4','L2','L2','L1','H1','H5','H5','L3','H2','H2','L4','L4','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','H3','L5','L5','SC','L3','H5','H5','L4','L4','H4','L1','L1','L1','H2','L4','L3','L3','H5','H5','H3','L4','L4','L4','L2','H4','H3','L1','L1','L1','H1','H5','H5'],
                ['L2','L2','L2','H1','H1','L4','H2','L5','L5','L1','H4','H4','L2','L3','L3','L2','H3','H3','L5','L4','L4','H5','H2','L1','H1','L4','L3','L3','H4','L5','L2','L2','H1','L1','L1','H5','L4','L3','L3','H2','H2','L1','L4','L4','L4','H1','L2','L5','L5','L5','H4','L3','H5','H5','H5','L1','L3','L3','L3','H1','L2','L2','H2','L4','L4','L5','H3','L1','L1','L2','H4','H4','H4','L3','L5','L5','H2','H2','L1','L4','L4','H5','H5','L5','L1','L1','L3','H3','H3','H3','L2','H2','L5','L5','L5','H4','L4','L4','H3','L2','L2','H5','L3','L3','H1','L1','L1','L1','H2','H2','L4','L4','H5','H5','L2','L3','L3','H2','L5','L5','L1','L1','H5','H5','L3','H4','H4','L5','L2','L2','L2','L4','H3','H3','L3','L3','L5','L5','H4','L1','L1','L4','H5','H5','L2','L2','H3','L3','H2','H2','H2','L4','L5','L5','H4','L2','H3','H3','H5','H4','H4','L1','L1','L5','L4','L3','H4','H4','H5','L5','L2','H1','H1','L3','L4','L4','L4','H3','L1','H5','H5','L2','L2','H2','H2','L3','L3','L3','H3','H3','L5','L5','H4','H5','L4','L4','H3','L1','L1','L1','H1','H1','H1','L1','L2','L3','L5','H5','H5','L4','L4','H4','H4','L1','L5','L5','H3','H3','L4','L3','L3','L2','H5'],
                ['H1','H1','H1','L2','L1','L1','H4','L5','L5','H1','H2','L4','L4','L2','SC','H5','H5','L3','H4','H4','L5','L5','L2','H1','H1','L3','L3','L1','H2','H2','H2','H5','L2','L2','L2','H4','L4','H3','H3','L5','L5','L5','H1','L1','H5','H5','L1','L3','L3','SC','H4','H4','L5','L4','L4','L2','L2','H3','L1','L2','L2','L5','H4','H4','L4','L3','L3','L3','H5','L5','L5','H3','L1','L1','H1','L2','L2','H4','H4','SC','L3','L4','L4','L1','H5','H5','H5','L3','L2','L2','H1','L5','L5','H4','L4','L4','L4','H3','L5','H2','H2','L1','L1','H4','L2','L3','L3','H5','L4','L4','L5','H2','H2','SC','L1','L1','L3','H4','L2','L2','L4','H3','H3','H3','L3','H2','H2','L4','L3','L3','H5','L1','L1','L1','H2','L2','H3','H3','L5','L3','L3','H1','H1','L2','L5','L5','L5','H4','L4','L4','SC','H5','H5','H3','L3','L3','H2','L1','L1','L5','L4','L4','H2','L2','H4','H4','H4','L1','L3','L3','L3','L5','H3','H3','L4','L2','L2','L2','L1','H1','H5','H5','L3','SC','H2','H2','L5','L5','H4','L4','H3','H3','L1','L1','H5','L3','L3','L4','L4','H2','L2','L2','H3','H3','L5','L5','L3','H5','H5','L4','L4','H4','L5','SC','L1','H2','H1','L4','L3','L3','L1','H5','H5','L5','L5','H3','L4','L4','L4','L2','H4','L1','L1','H1','L5','H5','H5'],
            ],
        },

        // prettier-ignore
        reelsFreespin: [
            ['L2','L4','H2','L5','L5','L1','H4','H4','L2','L2','L4','L5','H1','H1','H1','H1','L2','H3','H3','L5','L4','L4','L4','H5','L2','L4','L3','L3','L3','H4','L5','L2','L2','H3','L1','L1','H5','L4','H1','L2','L5','H5','H5','H5','L1','L3','L2','L2','H2','L4','L4','L5','H3','L1','L1','L2','H4','H4','H4','L3','L5','L5','L1','L4','L4','H1','L5','H4','L1','L1','L3','H3','H3','H3','L2','L5','L5','L5','H4','L1','L4','L4','H3','L2','L3','L3','H1','L1','L1','L1','H2','L4','L4','L2','H4','H2','L5','L5','H1','L1','L1','H5','H5','H4','H4','L5','L2','L2','L2','L4','H3','H3','L3','L3','H5','L5','L5','H4','L1','L1','H1','L4','H5','H5','L2','L2','L2','H3','H2','H2','H2','L4','L5','L5','H4','L2','L1','L1','L5','L4','L3','H4','H4','H5','L5','L2','H1','L3','L4','L4','L4','H3','L1','H5','H5','L2','L2','L1','L1','H2','H2','L3','L3','L3','L4','H3','H3','L5','L5','H4','L4','L4','H3','L1','H1','L3','L2','H2','L1','L5','L4','L4','H4','H4','L1','L5','L5','L5','H3','L4','L3','L3','L2'],
            ['L1','L1','H3','L4','L4','H5','L3','L1','H4','H4','H4','L5','L5','L3','L3','L3','L1','H5','L2','L2','L2','H4','L4','H3','H3','L5','H5','H5','H5','L1','L3','L3','H2','L4','L4','H3','L1','L2','L2','H4','H4','L4','L3','L3','L3','H5','L5','L5','H3','L1','L1','H1','H1','H1','H1','L2','L2','L3','L4','L4','L4','H2','L1','L3','H5','H5','L2','H1','L1','L5','L5','H4','L4','H3','L5','H2','H2','L1','L1','L1','H1','L2','L3','L3','H5','L4','L4','H2','L1','L1','L3','H1','L2','L2','L2','L4','H3','H2','L3','L3','H5','L1','L4','L2','H4','H3','H3','L4','L3','L3','H1','L2','H2','L5','L5','L5','L4','L4','H5','H5','H3','L3','L3','H2','L1','L1','H1','L4','L4','L3','H4','H4','L1','L5','H3','H3','L4','L2','H1','H5','H5','L3','H2','H2','H2','L5','L4','H3','H3','H3','L1','L1','H5','L3','L3','L4','H2','L2','L2','H3','L5','L5','L5','L3','H5','H5','L4','L4','H4','L5','H1','L4','L3','L3','L1','H5','L4','L4','L4','H2','H4','L3','L1','L1','L1','L5','H5','H5'],
            ['H4','H4','L5','L3','H5','H5','H5','L1','H2','L2','H4','H3','L5','L4','H5','L2','H1','H1','H1','H1','L3','L3','L3','H2','H2','L5','L5','L4','H3','H3','L2','H4','H4','H4','H5','L5','L5','L2','H1','L3','L3','H2','L1','L1','H5','L2','L3','L3','H2','L5','L4','L4','L4','H5','L1','L2','H1','L5','H4','H5','L3','L3','L2','L2','H5','L3','L5','L5','H1','L1','L1','L2','L3','H5','L2','H4','H4','L3','L5','L5','H5','L2','L2','L3','L3','H1','L4','L4','L5','L3','H4','L2','L2','H3','H3','L5','L1','L3','L4','L4','L4','H5','H5','L5','L2','L2','H4','H4','L3','L3','H5','L5','L5','L2','H2','H5','H5','L5','H4','L1','L1','L1','L3','H1','L4','L4','H4','L1','L2','L2','L2','L3','H3','H3','H3','L1','L5','L5','L5','H1','L3','L3','H2','L4','L4','L5','H2','L4','L2','L2','L2','H4','L3','L3','L3','L1','L1','H1','L4','H5','H5','L2','L5','H2','H2','H2','L3','L2','H4','H4','L5','L1','L1','H2','H3','L4','L4','L5','H4','L1','H2','L3','L5','L5','L5','H3','L2','H5','H5'],
            ['L2','L2','L4','L1','H4','H4','H4','L4','L3','L3','L3','H3','H3','L4','L4','H5','H2','L2','H1','L4','L3','L3','H4','L5','L2','L2','L2','H3','L1','L1','H5','L4','L3','L3','L1','H1','L5','L3','H1','H2','L4','L4','L4','H3','L3','L1','L1','L3','L5','L5','H2','H2','L1','L4','L4','H1','H1','H1','H1','L3','H5','H4','L1','L1','L1','L3','H3','H2','L5','L5','L5','H4','L1','L4','L4','H3','L2','L2','H5','L3','L3','H1','L1','L1','L1','H2','L4','L4','H5','H5','H5','L3','L3','H2','L5','L5','H1','L1','L1','H5','H5','L3','H4','L5','L2','L2','L2','L4','H3','H3','L3','L3','H5','L5','L1','L1','H1','L4','H5','H5','L2','L3','H2','H2','H2','L4','L5','L5','H4','H3','H3','L1','L1','L4','L3','H4','H4','H5','L5','L2','L3','L4','L4','L4','H3','L1','H5','H5','L2','L2','L1','H2','H2','L3','L3','L3','L4','H3','H3','H3','L5','L5','L5','H5','L4','L4','H3','L1','H1','H5','H5','L3','L2','H2','L1','L5','H5','L4','L4','H4','H4','L1','H3','L4','L3','L3','L2','H5'],
            ['H1','L2','L1','L1','L1','H3','L5','L5','H1','L4','L4','L2','H5','H5','L1','H4','H4','L5','L5','H1','L1','L5','H5','L2','L2','L2','H4','H1','L5','L2','L1','L3','L5','L2','L2','H2','L4','L4','L4','H3','L1','L2','L2','L5','H4','H4','L4','L3','L3','L3','H5','L2','L5','L5','H3','L1','L1','L2','L2','H4','H4','L4','L4','L1','H5','H5','H5','L3','L4','L2','L2','H1','L1','L5','L5','H4','L4','H3','L5','L1','L1','H4','L2','L4','L4','H2','L1','L1','H1','H1','H1','H1','H4','L2','L2','L4','H3','H3','H3','L4','L3','L3','L1','H2','L2','H4','H3','H3','L4','L5','L3','L3','L2','H2','H2','L5','L5','L5','H4','L4','L4','H3','L3','L3','H2','L1','L1','L5','H1','L4','L4','L2','H4','H4','H4','L1','L3','L5','H3','H3','L4','L2','L1','H5','H5','L3','H2','H2','H2','L5','L5','H4','L4','H3','H3','L1','L1','L3','L3','L3','L4','H2','L2','L2','L2','H3','L5','L5','L3','H5','H5','L4','L4','H4','L5','H1','L4','L1','L5','L5','L5','H3','L4','L4','L4','L2','H4','L3','L1','L1','L5','H5','H5'],
        ],

        coinType1Values: [
            500, 1000, 2500, 3500, 5000, 12500, 25000, 50000, 125000, 250000,
            500000, 1000000,
        ],
        coinType4Values: [1, 2, 5, 7, 10, 25, 50, 100, 250, 500, 1000, 2000],
    },
};
