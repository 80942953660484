import type { Choices } from "../../choices/index";
import type { SpinOutcome } from "./outcome";
import type { SpinInputs } from "./inputs";
import { spinBase } from "./spin-base";
import { assertSpinInputsValid } from "./inputs";
import { spinGenie } from "./spin-genie-feature";

export { GameRequestSchema, type GameRequest } from "./game-request";
export { GameResponseSchema, type GameResponse } from "./game-response";
export type { SpinOutcome, SpinInputs };

export function play({ coinType, gameRequest, gameState }: SpinInputs, choices: Choices): SpinOutcome {
    assertSpinInputsValid({ coinType, gameRequest, gameState });

    if (gameState?.genieBonusPhase) return spinGenie({ gameRequest, gameState, coinType }, choices);
    return spinBase({ gameRequest, coinType }, choices);
}
