/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {
	return {
		variations: [""],
		getModelDefinition: () =>
			State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
		createDefaultStep: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Reels");
			step.topReelReelStripPosition = 0;
			step.rows = State.state.getModelDefinition().reels;
			step.wheelResults = [];
			return step;
		},
		getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
		getRowsNo: () => State.state.getModelDefinition().getLayout(),
		getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
		getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
		getReelStrips: () => State.state.getModelDefinition().reels,
		getTopReel: () => State.state.getModelDefinition().topReel,
		getBaseStep: () => State.state.createDefaultStep(),
		getFeatureTriggerFromBaseGame: () => {
			const step = State.state.createStep([0, 11, 6, 3, 11, 0], false, 0, "", "Respin trigger");
			step.topReelReelStripPosition = 0;
			return step;
		},

		getRespinStep: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0, 0], true, 0, "", "Respin step");
			step.topReelReelStripPosition = 0;
			step.wheelResults = [];
			step.scattersSelectedMultipliers = [];
			for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
				step.scattersSelectedMultipliers.push("Random");

			return step;
		},

		prepareStepForDB: (step) => {
			const state = State.state;
			const reelStrips = state.getReelStrips();

			if (step.isFeature) {
				step.reelStripPositions = [0, 0, 0, 0, 0, 0];
				step.topReelReelStripPosition = 0;
			}

			step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, reelStrips.length, state.getNumberOfRows());
			step.randoms = step.reelStripPositions;

			return step;
		},

		getClearingStep: function() {
			const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Clear");
			step.gameState = "clear";
			return step;
		},
	};
}
