var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "reel", staticClass: "sm-reel" },
    [
      _c("div", { attrs: { align: "center" } }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.down()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-up",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.up()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-down",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow(), function (sym, row) {
        return _c("div", [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow(), function (sym, row) {
          return _c("div", [
            _c("div", { class: ["symbol", "on", sym] }, [
              _vm.content.canAddWild()
                ? _c("div", [
                    !_vm.content.isAddedWild({ row, col: _vm.index })
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "text",
                              on: {
                                click: function ($event) {
                                  return _vm.content.addWild({
                                    row,
                                    col: _vm.index,
                                    multiplier: 1,
                                  })
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(sym) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "text" }, [
                          _c(
                            "select",
                            {
                              domProps: {
                                value: _vm.content.getWildMultiplier({
                                  row,
                                  col: _vm.index,
                                }),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.content.addWild({
                                    row,
                                    col: _vm.index,
                                    multiplier: $event.target.value,
                                  })
                                },
                              },
                            },
                            [
                              _vm._l([1, 2, 3, 4, 5], function (opt) {
                                return [
                                  _c("option", { domProps: { value: opt } }, [
                                    _vm._v(_vm._s(opt)),
                                  ]),
                                ]
                              }),
                              _vm._v(" "),
                              [
                                _c("option", { domProps: { value: "0" } }, [
                                  _vm._v("❌ Remove"),
                                ]),
                              ],
                            ],
                            2
                          ),
                        ]),
                  ])
                : _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(sym) +
                        "\n                "
                    ),
                  ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow(), function (sym, row) {
        return _c("div", { key: sym, staticClass: "symbol dim" }, [
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }