import { mathModel } from "../../../v3";

const { GOLD, SWEEPS } = mathModel;

export const modelDefinition = {
  modelId: 'vgw092',
  gameIds: ['candyLand'],
  coinType1Values: [20, 100, 200, 1000, 2000, 10000, 20000],
  coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000],
  coinSizeMultiplier: 50,
  reelsLayout: [5, 5, 5, 5, 5],
  wildSymbol: 'WILD',
  scatterSymbol: 'SCAT',

  freeSpinTriggerCount: [30, 20, 10],
  freeSpinRetriggerCount: [30, 20, 10],

  coinType1_spin_max_bet: 20_000 * 50,
  coinType4_spin_max_bet: 1_000 * 50,
  winTable: [
      {
          symbol: 'SCAT',
          multipliers: [100, 10, 2],
      },
      {
          symbol: 'WILD',
          multipliers: [200, 50, 20],
      },
      {
          symbol: 'PIC1',
          multipliers: [200, 50, 10],
      },
      {
          symbol: 'PIC2',
          multipliers: [150, 40, 9],
      },
      {
          symbol: 'PIC3',
          multipliers: [100, 30, 8],
      },
      {
          symbol: 'PIC4',
          multipliers: [80, 25, 7],
      },
      {
          symbol: 'PIC5',
          multipliers: [50, 20, 6],
      },
      {
          symbol: 'A',
          multipliers: [30, 15, 5],
      },
      {
          symbol: 'K',
          multipliers: [30, 15, 5],
      },
      {
          symbol: 'Q',
          multipliers: [30, 15, 5],
      },
      {
          symbol: 'J',
          multipliers: [20, 10, 5],
      },
      {
          symbol: '10',
          multipliers: [20, 10, 5],
      },
      {
          symbol: '9',
          multipliers: [20, 10, 5],
      },
  ],

  playLines: [
      [0, 0, 0, 0, 0], // 1
      [1, 1, 1, 1, 1], // 2
      [2, 2, 2, 2, 2], // 3
      [3, 3, 3, 3, 3], // 4
      [4, 4, 4, 4, 4], // 5
      [2, 1, 0, 1, 2], // 6
      [3, 2, 1, 2, 3], // 7
      [4, 3, 2, 3, 4], // 8
      [0, 1, 2, 1, 0], // 9
      [1, 2, 3, 2, 1], // 10
      [2, 3, 4, 3, 2], // 11
      [0, 0, 1, 2, 2], // 12
      [1, 1, 2, 3, 3], // 13
      [2, 2, 3, 4, 4], // 14
      [3, 3, 4, 3, 3], // 15
      [1, 1, 0, 1, 1], // 16
      [2, 2, 1, 0, 0], // 17
      [3, 3, 2, 1, 1], // 18
      [4, 4, 3, 2, 2], // 19
      [0, 1, 0, 1, 0], // 20
      [1, 2, 1, 2, 1], // 21
      [2, 3, 2, 3, 2], // 22
      [3, 4, 3, 4, 3], // 23
      [1, 0, 1, 0, 1], // 24
      [2, 1, 2, 1, 2], // 25
      [3, 2, 3, 2, 3], // 26
      [4, 3, 4, 3, 4], // 27
      [0, 1, 1, 1, 0], // 28
      [1, 2, 2, 2, 1], // 29
      [2, 3, 3, 3, 2], // 30
      [3, 4, 4, 4, 3], // 31
      [1, 0, 0, 0, 1], // 32
      [2, 1, 1, 1, 2], // 33
      [3, 2, 2, 2, 3], // 34
      [4, 3, 3, 3, 4], // 35
      [1, 3, 1, 3, 1], // 36
      [2, 4, 2, 4, 2], // 37
      [2, 0, 2, 0, 2], // 38
      [3, 1, 3, 1, 3], // 39
      [1, 3, 3, 3, 1], // 40
      [2, 4, 4, 4, 2], // 41
      [2, 0, 0, 0, 2], // 42
      [3, 1, 1, 1, 3], // 43
      [0, 0, 2, 0, 0], // 44
      [1, 1, 3, 1, 1], // 45
      [2, 2, 4, 2, 2], // 46
      [2, 2, 0, 2, 2], // 47
      [3, 3, 1, 3, 3], // 48
      [4, 4, 2, 4, 4], // 49
      [2, 1, 2, 3, 2], // 50
  ],

  // prettier-ignore
  reels:[
   ['PIC1', 'PIC1', 'A', 'PIC4', 'PIC4', 'PIC4', 'Q', '9', 'PIC3', 'PIC3', 'PIC3', '10', 'BONUS', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC5', 'PIC5', 'K', 'PIC1', '9', 'PIC3', 'PIC3', 'PIC3', '10', 'PIC1', 'PIC1', 'Q', 'PIC5', 'PIC5', 'PIC5', 'WILD', 'A', 'PIC2', 'PIC2', 'PIC4', 'PIC4', '9', 'SCAT', 'A', 'PIC1', 'Q', 'PIC3', 'PIC3', 'PIC3', 'K', 'PIC4', 'PIC4', 'A', 'BONUS', 'J', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC1', 'PIC1', 'PIC1', '10', 'PIC4', 'PIC4', 'Q', 'PIC2', 'PIC2', 'PIC2', '9', 'PIC3', 'PIC4', 'PIC4', 'J', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC3', 'Q', 'PIC4', 'PIC4', 'PIC5', 'A', 'PIC3', 'PIC3', 'WILD', '10', 'PIC5', 'PIC5', 'J', 'PIC2', 'PIC4', 'PIC4', '9', 'PIC5', 'PIC5', 'J', 'PIC4', 'PIC2', '10'],
     ['PIC5', 'PIC5', '10', 'PIC3', 'PIC3', 'K', 'PIC5', 'PIC5', 'PIC5', 'A', 'WILD', 'K', 'PIC1', 'PIC1', 'PIC3', 'PIC3', 'J', 'PIC5', 'PIC5', 'K', 'PIC1', '10', 'PIC4', 'PIC4', '9', 'PIC3', 'PIC3', 'Q', 'PIC2', '9', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', 'PIC4', 'PIC5', 'K', 'SCAT', 'Q', 'PIC3', 'PIC3', 'PIC3', '9', 'PIC4', 'PIC4', 'A', 'PIC2', 'PIC2', 'Q', 'SCAT', 'A', 'PIC5', 'PIC5', '10', 'PIC4', 'PIC4', 'PIC4', 'Q', 'PIC3', 'J', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC1', 'PIC3', 'Q', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC2', 'PIC2', 'PIC2', '10', 'PIC4', 'PIC4', 'PIC4', 'J', 'PIC5', 'PIC5', 'PIC5', '9', 'PIC3', 'PIC3', 'WILD', '10', 'PIC1', 'PIC1', 'J', 'PIC2', 'PIC2', 'PIC2', '9'],
     ['PIC1', 'PIC1', 'K', 'PIC3', '9', 'BONUS', 'A', 'PIC4', 'PIC4', 'PIC1', 'PIC1', 'J', 'PIC2', 'PIC2', 'K', 'SCAT', '9', 'PIC3', 'PIC3', 'PIC3', '10', 'PIC5', 'PIC4', 'Q', 'PIC3', 'PIC3', '9', 'PIC4', 'PIC4', 'PIC4', 'WILD', 'A', 'PIC5', 'PIC5', '10', 'SCAT', 'A', 'PIC2', 'Q', 'PIC3', 'PIC3', 'PIC3', 'K', 'PIC5', 'PIC5', 'PIC5', 'Q', 'BONUS', '10', 'PIC5', 'PIC5', 'K', 'PIC1', 'PIC1', 'J', 'PIC4', 'PIC5', '9', 'PIC2', 'PIC2', 'PIC2', 'Q', 'PIC4', 'PIC4', 'PIC3', 'PIC3', 'PIC5', 'PIC5', 'J', 'PIC2', 'PIC2', 'PIC4', 'PIC4', 'A', 'PIC5', 'PIC5', 'K', 'PIC1', 'J', 'PIC3', 'PIC3', 'WILD', '10', 'PIC1', 'PIC1', 'Q', 'PIC4', 'PIC4', 'PIC4', '10', 'WILD', 'PIC2', 'PIC2', 'A', 'PIC5', 'PIC5', 'PIC5', 'J', 'PIC4', 'PIC4', '9'],
     ['PIC1', 'PIC1', 'PIC1', 'Q', 'PIC5', '9', 'PIC3', 'PIC3', '10', 'WILD', 'K', 'PIC2', 'PIC2', 'PIC2', 'K', 'PIC1', '10', 'PIC3', 'PIC3', '9', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'J', 'PIC1', 'PIC1', 'Q', 'PIC5', 'PIC5', 'PIC5', 'A', 'PIC4', 'PIC4', 'PIC4', 'WILD', 'Q', 'PIC2', 'PIC2', 'A', 'PIC3', 'PIC3', 'PIC3', 'K', 'PIC4', 'PIC4', 'A', 'SCAT', 'J', 'PIC5', 'PIC5', 'K', 'PIC1', 'PIC1', 'PIC1', '10', 'PIC5', 'PIC5', 'PIC5', '9', 'SCAT', 'A', 'Q', 'PIC2', 'PIC2', '9', 'PIC4', 'PIC4', 'J', 'PIC3', 'PIC3', 'K', 'PIC5', 'PIC2', 'Q', 'PIC4', 'PIC4', 'PIC4', '10', 'PIC3', 'PIC3', 'WILD', 'A', 'PIC5', 'PIC5', '9', 'PIC4', 'PIC4', 'J', 'PIC3', 'PIC3', 'PIC1', 'PIC1', '10', 'PIC4', 'PIC4', 'PIC2', 'PIC2', 'J'],
     ['PIC1', 'PIC1', 'Q', 'PIC4', 'PIC4', 'WILD', 'A', 'PIC5', 'PIC5', 'PIC5', '9', 'SCAT', 'K', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'Q', 'PIC3', 'PIC3', 'PIC3', 'A', 'PIC4', 'PIC4', 'PIC4', 'Q', 'SCAT', '9', 'PIC3', 'PIC3', '10', 'BONUS', 'A', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'K', 'BONUS', '9', 'PIC3', 'PIC3', 'PIC4', 'PIC4', '10', 'PIC1', 'PIC1', 'K', 'PIC2', 'PIC2', 'A', 'BONUS', 'J', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC1', 'PIC1', 'PIC1', '10', 'PIC4', 'PIC4', 'Q', 'PIC2', 'PIC2', 'PIC2', '9', 'PIC3', 'PIC3', 'PIC5', 'PIC5', 'J', 'PIC2', 'PIC2', 'Q', 'PIC4', 'PIC4', 'PIC4', 'J', 'PIC5', 'PIC5', 'K', 'WILD', 'A', 'PIC3', 'PIC3', '10', 'PIC1', 'PIC1', 'J', 'PIC5', 'PIC5', 'PIC5', '9', 'PIC4', 'PIC4', '10']
  ],

  winLevels: [
      { name: 'normal1', threshold: 2 },
      { name: 'normal2', threshold: 5 },
      { name: 'normal3', threshold: 20 },
      { name: 'bigwin', threshold: 35 },
      { name: 'megawin', threshold: 70 },
      { name: 'epicwin' },
  ],

  bonusSymbol: 'BONUS',
  countToTriggerBoardGame: 3,

  board: [
      { position: 1, colour: 'RED' },
      { position: 2, colour: 'PURPLE' },
      { position: 3, colour: 'YELLOW' },
      { position: 4, colour: 'BLUE', multiplier: 2 },
      { position: 5, colour: 'ORANGE' },
      { position: 6, colour: 'GREEN' },
      { position: 7, colour: 'RED', extraPicks: 1 },
      { position: 8, colour: 'PURPLE' },
      { position: 9, colour: 'YELLOW', multiplier: 2 },
      { position: 10, colour: 'BLUE' },
      { position: 11, colour: 'ORANGE', shortcut: 24 },
      { position: 12, colour: 'GREEN', multiplier: 3 },
      { position: 13, colour: 'RED' },
      { position: 14, colour: 'PURPLE', extraPicks: 2 },
      { position: 15, colour: 'PINK' },
      { position: 16, colour: 'YELLOW' },
      { position: 17, colour: 'BLUE', shortcut: 32 },
      { position: 18, colour: 'ORANGE' },
      { position: 19, colour: 'GREEN' },
      { position: 20, colour: 'RED', multiplier: 3 },
      { position: 21, colour: 'PURPLE' },
      { position: 22, colour: 'YELLOW', extraPicks: 1 },
      { position: 23, colour: 'BLUE', multiplier: 3 },
      { position: 24, colour: 'ORANGE' },
      { position: 25, colour: 'PINK' },
      { position: 26, colour: 'GREEN', multiplier: 4 },
      { position: 27, colour: 'RED' },
      { position: 28, colour: 'PURPLE', extraPicks: 2 },
      { position: 29, colour: 'YELLOW' },
      { position: 30, colour: 'BLUE', multiplier: 3 },
      { position: 31, colour: 'ORANGE' },
      { position: 32, colour: 'GREEN' },
      { position: 33, colour: 'RED' },
      { position: 34, colour: 'PURPLE', multiplier: 4 },
      { position: 35, colour: 'YELLOW' },
      { position: 36, colour: 'BLUE' },
      { position: 37, colour: 'ORANGE', multiplier: 3 },
      { position: 38, colour: 'PINK' },
      { position: 39, colour: 'GREEN', extraPicks: 1 },
      { position: 40, colour: 'RED' },
      { position: 41, colour: 'PURPLE', multiplier: 4 },
      { position: 42, colour: 'YELLOW' },
      { position: 43, colour: 'BLUE' },
      { position: 44, colour: 'ORANGE', multiplier: 5 },
      { position: 45, colour: 'GREEN' },
      { position: 46, colour: 'RED' },
      { position: 47, colour: 'PURPLE' },
      { position: 48, colour: 'RAINBOW' },
  ] as const,

  boardGameLastPinkPosition: 38,
  rainbowPosition: 48,

  numberOfPicksWeights: [
      { outcome: 4, weight: 73 },
      { outcome: 5, weight: 27 },
      { outcome: 6, weight: 11 },
      { outcome: 7, weight: 5 },
  ],

  numberOfPicksClient: [4, 5, 6, 7],

  randomCardSelectionWeights: [
      { outcome: 'SINGLE', weight: 15 },
      { outcome: 'DOUBLE', weight: 5 },
      { outcome: 'PINK', weight: 2 },
  ] as const,

  randomCardSelectionWeightsWithoutPink: [
      { outcome: 'SINGLE', weight: 15 },
      { outcome: 'DOUBLE', weight: 5 },
  ] as const,

  pinkPrizeWeights: [
      { outcome: 250, weight: 1 },
      { outcome: 300, weight: 2 },
      { outcome: 400, weight: 2 },
      { outcome: 500, weight: 2 },
      { outcome: 600, weight: 1 },
      { outcome: 750, weight: 1 },
      { outcome: 1000, weight: 1 },
  ],

  pinkPrizeClient: [250, 300, 400, 500, 600, 750, 1000],

  singleOrDoublePrizeWeights: [
      { outcome: 50, weight: 15 },
      { outcome: 60, weight: 15 },
      { outcome: 80, weight: 15 },
      { outcome: 100, weight: 20 },
      { outcome: 150, weight: 15 },
      { outcome: 200, weight: 10 },
      { outcome: 250, weight: 4 },
      { outcome: 300, weight: 3 },
      { outcome: 400, weight: 2 },
      { outcome: 500, weight: 1 },
  ],

  colourSelectionWeights: [
      { outcome: 'RED', weight: 1 },
      { outcome: 'PURPLE', weight: 1 },
      { outcome: 'YELLOW', weight: 1 },
      { outcome: 'BLUE', weight: 1 },
      { outcome: 'ORANGE', weight: 1 },
      { outcome: 'GREEN', weight: 1 },
  ] as const,

  jackpotFeatureTableWeights: [
      {
          outcome: 'TABLE1',
          weight: 'COIN_WEIGHT',
      },
      {
          outcome: 'TABLE2',
          weight: 'JACKPOT_COIN_WEIGHT_COMPLEMENT',
      },
  ] as const,

  jackpotFeatureCoinWeightComplement: 2000,

  jackpotWeightsTable: {
      TABLE1: [
          { outcome: 'GRAND', weight: 295 },
          { outcome: 'MAJOR', weight: 43_641 },
      ],
      TABLE2: [
          { outcome: 'MINOR', weight: 1 },
          { outcome: 'MINI', weight: 3 },
      ],
  } as const,

  jackpots: {
      fixedMultipliers: {
          MINOR: 10000, // 200 * 50 (50 Being the coin size multiplier)
          MINI: 2500, // 50 * 50 (50 Being the coin size multiplier)
      },
      seedValues: {
          MAJOR: {
              GOLD: 10_000_000,
              SWEEPS: 500_000,
          },
          GRAND: {
              GOLD: 1_000_000_000,
              SWEEPS: 50_000_000,
          },
      },
      percentToJackpotPool: {
          MAJOR: 0.4,
          GRAND: 0.25,
      },
  } as const,

  coinWeightMultiplier: {
      [SWEEPS]: 1,
      [GOLD]: 0.05,
  },

  reelSpinWeightsTable: {
      baseGame: {
          chance: 'REEL_SPIN_COIN_WEIGHT_COMPLEMENT',
          outOf: 118090,
      },
      freeSpins: {
          chance: 58,
          outOf: 102,
      },
  } as const,

  reelSpinFeatureCoinWeightComplement: 21919,

  reelSpinFeatureWeightsTable: [
      { outcome: 0, weight: 80 },
      { outcome: 1, weight: 90 },
      { outcome: 2, weight: 80 },
      { outcome: 3, weight: 300 },
      { outcome: 4, weight: 300 },
      { outcome: 5, weight: 25 },
      { outcome: 6, weight: 20 },
      { outcome: 7, weight: 15 },
      { outcome: 8, weight: 20 },
      { outcome: 9, weight: 15 },
      { outcome: 10, weight: 10 },
      { outcome: 11, weight: 15 },
      { outcome: 12, weight: 3 },
      { outcome: 13, weight: 2 },
      { outcome: 14, weight: 2 },
  ] as const,

  reelSpinFeatureSets: [
      ['WILD_PATH'],
      ['CANDY_CANES'],
      ['NUTT_PRIZE'],
      ['STEAL_ROYALS'],
      ['MULTIPLIER'],
      ['STEAL_ROYALS', 'WILD_PATH'],
      ['STEAL_ROYALS', 'CANDY_CANES'],
      ['STEAL_ROYALS', 'NUTT_PRIZE'],
      ['MULTIPLIER', 'WILD_PATH'],
      ['MULTIPLIER', 'CANDY_CANES'],
      ['MULTIPLIER', 'NUTT_PRIZE'],
      ['STEAL_ROYALS', 'MULTIPLIER'],
      ['STEAL_ROYALS', 'MULTIPLIER', 'WILD_PATH'],
      ['STEAL_ROYALS', 'MULTIPLIER', 'CANDY_CANES'],
      ['STEAL_ROYALS', 'MULTIPLIER', 'NUTT_PRIZE'],
  ] as const,

  chanceIn100Numbers: [
      {
          threshold: 1,
          outOf: 100,
          values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
          threshold: 2,
          outOf: 100,
          values: [100, 100, 100, 100, 60, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      },
      {
          threshold: 5,
          outOf: 100,
          values: [100, 100, 100, 100, 80, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      },
      {
          threshold: 10,
          outOf: 100,
          values: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      },
      {
          threshold: 20,
          outOf: 100,
          values: [100, 100, 60, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      },
      {
          threshold: 50,
          outOf: 100,
          values: [40, 60, 60, 60, 60, 60, 60, 60, 80, 60, 60, 60, 60, 60, 60],
      },
      {
          threshold: 100,
          outOf: 100,
          values: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
      },
      {
          threshold: 200,
          outOf: 100,
          values: [10, 10, 10, 10, 10, 10, 10, 10, 4, 5, 10, 10, 10, 10, 10],
      },
      {
          threshold: 500,
          outOf: 100,
          values: [10, 10, 10, 10, 10, 10, 10, 10, 4, 5, 10, 10, 10, 10, 10],
      },
      {
          threshold: 1000,
          outOf: 100,
          values: [10, 10, 10, 10, 10, 10, 10, 10, 4, 5, 10, 10, 10, 10, 10],
      },
      {
          threshold: 2000,
          outOf: 100,
          values: [10, 10, 10, 10, 10, 10, 10, 10, 4, 5, 10, 10, 10, 10, 10],
      },
  ],

  stealRoyalsCountWeights: [
      { outcome: 3, weight: 7 },
      { outcome: 4, weight: 4 },
      { outcome: 5, weight: 2 },
      { outcome: 6, weight: 1 },
  ] as const,

  stealRoyalsReplacementWeights: [
      { outcome: 'WILD', weight: 1 },
      { outcome: 'PIC1', weight: 5 },
      { outcome: 'PIC2', weight: 5 },
      { outcome: 'PIC3', weight: 5 },
      { outcome: 'PIC4', weight: 5 },
      { outcome: 'PIC5', weight: 5 },
      { outcome: 'A', weight: 7 },
      { outcome: 'K', weight: 7 },
      { outcome: 'Q', weight: 7 },
      { outcome: 'J', weight: 7 },
      { outcome: '10', weight: 7 },
      { outcome: '9', weight: 7 },
  ] as const,

  stealRoyalsArray: ['A', 'K', 'Q', 'J', '10', '9'],

  multiplierSpinFeatureWeights: [
      { outcome: 2, weight: 4 },
      { outcome: 3, weight: 4 },
      { outcome: 4, weight: 4 },
      { outcome: 5, weight: 3 },
      { outcome: 7, weight: 2 },
      { outcome: 10, weight: 1 },
  ] as const,

  nuttSymbolWeights: [
      { outcome: 'PIC1', weight: 1 },
      { outcome: 'PIC2', weight: 1 },
      { outcome: 'PIC3', weight: 1 },
      { outcome: 'PIC4', weight: 1 },
      { outcome: 'PIC5', weight: 1 },
  ] as const,

  nuttSymbolIds: {
      PIC1: 'ID_1',
      PIC2: 'ID_2',
      PIC3: 'ID_2',
      PIC4: 'ID_3',
      PIC5: 'ID_3',
      A: 'ID_4',
      K: 'ID_4',
      Q: 'ID_4',
      J: 'ID_5',
      10: 'ID_5',
      9: 'ID_5',
  } as const,

  nuttPrizeWeights: {
      ID_1: [
          { outcome: 250, weight: 1 },
          { outcome: 200, weight: 2 },
          { outcome: 150, weight: 3 },
          { outcome: 100, weight: 10 },
          { outcome: 50, weight: 20 },
      ],
      ID_2: [
          { outcome: 250, weight: 1 },
          { outcome: 200, weight: 2 },
          { outcome: 150, weight: 3 },
          { outcome: 100, weight: 10 },
          { outcome: 50, weight: 20 },
      ],
      ID_3: [
          { outcome: 250, weight: 1 },
          { outcome: 200, weight: 2 },
          { outcome: 150, weight: 3 },
          { outcome: 100, weight: 10 },
          { outcome: 50, weight: 20 },
      ],
      ID_4: [
          { outcome: 250, weight: 1 },
          { outcome: 200, weight: 2 },
          { outcome: 150, weight: 3 },
          { outcome: 100, weight: 10 },
          { outcome: 50, weight: 20 },
      ],
      ID_5: [
          { outcome: 250, weight: 1 },
          { outcome: 200, weight: 2 },
          { outcome: 150, weight: 3 },
          { outcome: 100, weight: 10 },
          { outcome: 50, weight: 20 },
      ],
  } as const,

  candyCanes: [
      ['BOTTOM_RIGHT', 'TOP_RIGHT'],
      ['BOTTOM_RIGHT', 'TOP_RIGHT', 'BOTTOM_LEFT', 'TOP_LEFT'],
      ['BOTTOM_RIGHT', 'TOP_RIGHT', 'BOTTOM_LEFT', 'TOP_LEFT'],
      ['BOTTOM_RIGHT', 'TOP_RIGHT', 'BOTTOM_LEFT', 'TOP_LEFT'],
      ['BOTTOM_LEFT', 'TOP_LEFT'],
  ] as const,

  shapeMap: {
      TOP_LEFT: {
          isTop: true,
          isLeft: true,
      },
      BOTTOM_LEFT: {
          isTop: false,
          isLeft: true,
      },
      TOP_RIGHT: {
          isTop: true,
          isLeft: false,
      },
      BOTTOM_RIGHT: {
          isTop: false,
          isLeft: false,
      },
  } as const,

  candyCaneAwardedWeights: [
      { outcome: 3, weight: 5 },
      { outcome: 4, weight: 3 },
      { outcome: 5, weight: 1 },
  ] as const,

  candyCaneReplacementSymbolWeights: [
      { outcome: 'WILD', weight: 1 },
      { outcome: 'PIC1', weight: 5 },
      { outcome: 'PIC2', weight: 7 },
      { outcome: 'PIC3', weight: 10 },
      { outcome: 'PIC4', weight: 15 },
      { outcome: 'PIC5', weight: 15 },
      { outcome: 'A', weight: 5 },
      { outcome: 'K', weight: 5 },
      { outcome: 'Q', weight: 5 },
      { outcome: 'J', weight: 5 },
      { outcome: '10', weight: 5 },
      { outcome: '9', weight: 5 },
  ] as const,

  // Paths for wilds, to match other cells I am using Y,X not the typically X,Y
  wildPaths: {
      ID_1: {
          path: [
              [0, 0],
              [1, 0],
              [2, 0],
              [2, 1],
              [3, 1],
              [4, 1],
              [4, 2],
              [4, 3],
              [3, 3],
              [2, 3],
              [1, 3],
              [1, 2],
              [0, 2],
              [0, 1],
          ],
          loops: true,
      },
      ID_2: {
          path: [
              [0, 0],
              [1, 0],
              [2, 0],
              [2, 1],
              [2, 2],
              [3, 2],
              [3, 3],
              [3, 4],
              [2, 4],
              [1, 4],
              [0, 4],
              [0, 3],
              [0, 2],
              [0, 1],
          ],
          loops: true,
      },
      ID_3: {
          path: [
              [0, 0],
              [0, 1],
              [0, 2],
              [0, 3],
              [1, 3],
              [2, 3],
              [2, 2],
              [2, 1],
              [3, 1],
              [4, 1],
              [4, 2],
              [4, 3],
              [4, 4],
          ],
          loops: false,
      },
      ID_4: {
          path: [
              [0, 4],
              [1, 4],
              [2, 4],
              [2, 3],
              [2, 2],
              [3, 2],
              [4, 2],
              [4, 1],
              [4, 0],
          ],
          loops: false,
      },
      ID_5: {
          path: [
              [0, 4],
              [1, 4],
              [2, 4],
              [3, 4],
              [4, 4],
              [4, 3],
              [4, 2],
              [3, 2],
              [2, 2],
              [1, 2],
              [0, 2],
              [0, 1],
              [0, 0],
              [1, 0],
              [2, 0],
              [3, 0],
              [4, 0],
          ],
          loops: false,
      },
      ID_6: {
          path: [
              [1, 0],
              [1, 1],
              [2, 1],
              [3, 1],
              [3, 2],
              [3, 3],
              [2, 3],
              [1, 3],
              [1, 4],
          ],
          loops: false,
      },
  },

  wildPathIdWeights: [
      { outcome: 'ID_1', weight: 1 },
      { outcome: 'ID_2', weight: 1 },
      { outcome: 'ID_3', weight: 1 },
      { outcome: 'ID_4', weight: 1 },
      { outcome: 'ID_5', weight: 1 },
      { outcome: 'ID_6', weight: 1 },
  ] as const,

  wildPathLengthWeights: {
      ID_1: [
          { outcome: 7, weight: 10 },
          { outcome: 8, weight: 10 },
          { outcome: 9, weight: 9 },
          { outcome: 10, weight: 6 },
          { outcome: 11, weight: 5 },
          { outcome: 12, weight: 4 },
          { outcome: 13, weight: 3 },
          { outcome: 14, weight: 2 },
      ],
      ID_2: [
          { outcome: 7, weight: 10 },
          { outcome: 8, weight: 10 },
          { outcome: 9, weight: 9 },
          { outcome: 10, weight: 6 },
          { outcome: 11, weight: 5 },
          { outcome: 12, weight: 4 },
          { outcome: 13, weight: 3 },
          { outcome: 14, weight: 2 },
      ],
      ID_3: [
          { outcome: 7, weight: 10 },
          { outcome: 8, weight: 10 },
          { outcome: 9, weight: 9 },
          { outcome: 10, weight: 6 },
          { outcome: 11, weight: 5 },
          { outcome: 12, weight: 4 },
          { outcome: 13, weight: 3 },
      ],
      ID_4: [
          { outcome: 7, weight: 10 },
          { outcome: 8, weight: 10 },
          { outcome: 9, weight: 9 },
      ],
      ID_5: [
          { outcome: 7, weight: 5 },
          { outcome: 8, weight: 5 },
          { outcome: 9, weight: 9 },
          { outcome: 10, weight: 6 },
          { outcome: 11, weight: 5 },
          { outcome: 12, weight: 4 },
          { outcome: 13, weight: 3 },
          { outcome: 14, weight: 2 },
          { outcome: 15, weight: 1 },
          { outcome: 16, weight: 1 },
          { outcome: 17, weight: 1 },
      ],
      ID_6: [
          { outcome: 7, weight: 10 },
          { outcome: 8, weight: 10 },
          { outcome: 9, weight: 9 },
      ],
  } as const,

  // The combination of these checks create 8 possible orientations
  wildPathFlipXWeights: [
      { outcome: true, weight: 1 },
      { outcome: false, weight: 1 },
  ],

  wildPathFlipYWeights: [
      { outcome: true, weight: 1 },
      { outcome: false, weight: 1 },
  ],

  wildPathSwapAxisWeights: [
      { outcome: true, weight: 1 },
      { outcome: false, weight: 1 },
  ],
};
