var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content vgw040",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isFeature ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeatureTrigger,
              expression: "isFeatureTrigger",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c(
            "div",
            { staticClass: "small vgw040-border", attrs: { align: "center" } },
            [
              _c("h2", [_vm._v("This will only work on the feature trigger")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "mid" }, [
                _vm._v(
                  "\n                Freespins Award Count:\n            "
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.totalFreeSpinsToAward,
                        expression: "totalFreeSpinsToAward",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.totalFreeSpinsToAward = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.doChangeTotalFreeSpinsToAward()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.lsFreeSpinsAmounts, function (item) {
                    return _c(
                      "option",
                      { key: "items-" + item, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "sm-game-select" }, [
                  _c("div", { staticClass: "select-style" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fsSymbols,
                            expression: "fsSymbols",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.fsSymbols = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.doChangeTotalFreeSpinsToAward()
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.lsFreeSpinsSymbols, function (item) {
                        return _c(
                          "option",
                          { key: "sym0-" + item, domProps: { value: item } },
                          [
                            _vm._v(
                              _vm._s(
                                item
                                  .map((it) => (it === "" ? '""' : it))
                                  .join(" , ")
                              )
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mid" }, [
      _c("br"),
      _vm._v(
        "\n                    Symbols to be chosing during freespins\n                    "
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }