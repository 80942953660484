/**
 * Creates a function that generates slot windows for the given model definition.
 */
export function createSlotWindowGenerator(modelDefinition: {
  reels: string[][];
  reelsLayout: number[];
}) {
  /**
   * Returns the slot window as a two-dimensional array of symbols for the given `reelStripPositions`.
   * The outer array elements correspond to reels, and the inner arrays are the symbols visible in the slot window
   * column for that reel, according to the per-reel slot window heights given by the model definition.
   * Reel strip positions/indices are treated as modulo reel length, as if reels are an infinite repeating sequence.
   */
  return function generateSlotWindow(reelStripPositions: number[]): string[][] {
    const { reels, reelsLayout } = modelDefinition;

    const slotWindow = reels.map((reel, col) => {
      const rowsInColumn = reelsLayout[col];
      const symbolsInColumn: string[] = [];
      for (let row = 0; row < rowsInColumn; ++row) {
        const position = (reelStripPositions[col] + row) % reel.length;
        symbolsInColumn[row] = reel[position];
      }
      return symbolsInColumn;
    });
    return slotWindow;
  };
}
