import { modelDefinition } from "./model-definition";

export function setStateParams() {
  return {
    variations: [],

    // Must implement
    createDefaultStep: () => ({
      name: "Clear",
      gameState: "clear",
      reelStripPositions: [0, 0, 0, 0, 0],
      triggerJackpots: {
        MINI: false,
        MINOR: false,
        GRAND: false,
      },
    }),

    // unique per model
    getLayout: () => modelDefinition.reelsLayout.join("-"),
    getModelDefinition: () => modelDefinition,
    getNumberOfReels: () => modelDefinition.reelsLayout.length,
    getNumberOfRows: () => modelDefinition.reelsLayout[0],
    getReelStrips: () => modelDefinition.reels,

    // game-specific
    getClearingStep: (): StepJson => ({
      name: "Clear",
      gameState: "clear",
      reelStripPositions: [0, 0, 0, 0, 0],
      triggerJackpots: { MINI: false, MINOR: false, GRAND: false },
    }),

    getBaseStep: (): StepJson => ({
      name: "Base",
      reelStripPositions: [0, 0, 0, 0, 0],
      triggerJackpots: { MINI: false, MINOR: false, GRAND: false },
    }),
  };
}

export interface StepJson {
  name: string;
  gameState?: "clear";
  reelStripPositions?: number[];
  freeSpinMultipliers?: number[];
  triggerJackpots?: { MINI: boolean; MINOR: boolean; GRAND: boolean };
}
