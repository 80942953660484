var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "select-style" }, [
    _c("label", { attrs: { for: _vm.index } }, [
      _vm._v(" Coin #" + _vm._s(_vm.index) + ": "),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.coinValue[_vm.index - 1],
            expression: "coinValue[index-1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.coinValue,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updateCoin()
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "0" } }, [_vm._v("Random")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "40" } }, [_vm._v("40")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "45" } }, [_vm._v("45")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "60" } }, [_vm._v("60")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "70" } }, [_vm._v("70")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "90" } }, [_vm._v("90")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "105" } }, [_vm._v("105")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "110" } }, [_vm._v("110")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "125" } }, [_vm._v("125")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "130" } }, [_vm._v("130")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "140" } }, [_vm._v("140")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "150" } }, [_vm._v("150")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "160" } }, [_vm._v("160")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "170" } }, [_vm._v("170")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "175" } }, [_vm._v("175")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "200" } }, [_vm._v("200")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "250" } }, [_vm._v("250")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }