/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0], false, 0, "", "Reels");
            step.rows = State.state.getModelDefinition().reels;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([6, 0, 2], false, 0, "", "Feature trigger");
            return step;
        },

        getFeatureReelStrips: (reelSetIndex: number) => {
            if (reelSetIndex === 0) return State.state.getModelDefinition().reelsFreespin.set1;
            if (reelSetIndex === 1) return State.state.getModelDefinition().reelsFreespin.set2;
            return [];
        },

        getFreespinStep: () => {
            const step = State.state.createStep([0, 0, 0], true, 0, "", "Freespin step");
            step.isRespinStart = false;
            step.isRespinSelect = false;
            // step.isRespinEnd = false;
            step.isFeature = true;
            step.reelSetOption = 0;
            return step;
        },

        getRespinStartStep: () => {
            const step = State.state.createStep([0, 13, 0], true, 0, "", "Respin start step");
            step.isRespinStart = true;
            step.isRespinSelect = false;
            // step.isRespinEnd = false;
            step.isFeature = false;
            step.respinSymbol = "";
            return step;
        },
        getRespinSelect: () => {
            const step = State.state.createStep([0, 0, 0], true, 0, "", "Respin select step");
            step.isRespinStart = false;
            step.isRespinSelect = true;
            // step.isRespinEnd = false;
            step.isFeature = false;
            step.bonusGame = 0;
            return step;
        },

        getBonusGameStep: () => {
            const step = State.state.createStep([0, 0, 0], true, 0, "", "Bonus Game Step");
            step.isRespinStart = false;
            step.isRespinSelect = false;
            step.isFeature = false;
            step.isBonusGameStep = true;
            step.respinSymbol = "";
            return step;
        },

        getWheelWinStep: () => {
            const step = State.state.createStep([0, 0, 0], true, 0, "", "Wheel 2 and 3 step");
            step.isRespinStart = false;
            step.isRespinSelect = false;
            step.isFeature = false;
            step.isBonusGameStep = false;
            step.isWheelWinStep = true;
            return step;
        },

        // getRespinEndStep: () => {
        //     const step = State.state.createStep([0, 0, 0], true, 0, "", "Respin end step");
        //     step.isRespinStart = false;
        //     step.isRespinSelect = false;
        //     step.isRespinEnd = true;
        //     step.isFeature = false;
        //     return step;
        // },

        prepareStepForDB: (step) => {
            step.rows = undefined;
            step.randoms = undefined;
            return step;
        },

        getClearingStep: function () {
            const step = State.state.createStep([9, 0, 0], false, 0, "", "Clear");
            step.gameState = "clear";
            return step;
        },
    };
}
