import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import TopReel from "./reels/topreel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        topreel: TopReel,
        selectScatterMultiplier: SelectScatterMultiplier
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public topReelFirstSymbol = "NONE";
    public topReelSecondSymbol = "NONE";
    public topReelThirdSymbol = "NONE";
    public topReelForthSymbol = "NONE";
    public topReelReelStripPosition = "0";
    public reelsNo = 6;
    public scattersSelectedMultipliers = new Array(28).fill("X");

    public wheelPickResult = false;
    public maxScatterExtra = false;
    public featureLevel = 0;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ?? false;
        this.maxScatterExtra = step.json.maxScatter ?? false;
        this.wheelPickResult = step.json.wheelPickResult ?? false;
        this.featureLevel = step.json.featureLevel ?? 0;
    }

    public toggleMaxScatterOnRetrigger() {
        this.maxScatterExtra = !this.maxScatterExtra;
        this.step.json.maxScatter = this.maxScatterExtra;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addRespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStep());
    }

    public onChangeFeatureLevel() {
        this.step.json.featureLevel = this.featureLevel;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeWheelPickResult() {
        this.step.json.wheelPickResult = this.wheelPickResult;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateTopReelReelStripPosition(value) {
        this.step.json.topReelReelStripPosition = value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateTopReelSymbol(index, value) {
        switch (index) {
            case 1:
                this.step.json.topReelFirstSymbol = value;
                break;
            case 2:
                this.step.json.topReelSecondSymbol = value;
                break;
            case 3:
                this.step.json.topReelThirdSymbol = value;
                break;
            case 4:
                this.step.json.topReelForthSymbol = value;
                break;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateTopReelSymbols() {
        if (this.step.json.topReelFirstSymbol != null) {
            this.topReelFirstSymbol = this.step.json.topReelFirstSymbol;
        } else {
            this.topReelFirstSymbol = "-1";
        }

        if (this.step.json.topReelSecondSymbol != null) {
            this.topReelSecondSymbol = this.step.json.topReelSecondSymbol;
        } else {
            this.topReelSecondSymbol = "-1";
        }

        if (this.step.json.topReelThirdSymbol != null) {
            this.topReelThirdSymbol = this.step.json.topReelThirdSymbol;
        } else {
            this.topReelThirdSymbol = "-1";
        }

        if (this.step.json.topReelForthSymbol != null) {
            this.topReelForthSymbol = this.step.json.topReelForthSymbol;
        } else {
            this.topReelForthSymbol = "-1";
        }
    }

    public updateTopReelReelStripPositionFromJson() {
        if (this.step.json.topReelReelStripPosition != null) {
            this.topReelReelStripPosition = this.step.json.topReelReelStripPosition;
        } else {
            this.topReelReelStripPosition = "0";
        }
    }

    public resetRespinSymbols() {
        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateRespinScattersMultipliers(event) {
        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }
        this.step.json.scattersSelectedMultipliers[event.target.id - 1] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.scattersSelectedMultipliers == undefined && this.step.json.respinScatterPrizes) {
            this.step.json.scattersSelectedMultipliers = [];

            // top reel
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[1][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[2][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[3][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[4][0]);

            for (let i = 1; i < 5; i++) {
                for (let j = 0; j < 6; j++) {
                    this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[j][i]);
                }
            }
        }

        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        if (this.step.json.scattersSelectedMultipliers != null) {
            this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
        } else {
            const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
            this.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }
}
