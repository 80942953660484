import { createClearingStep } from "./steps";
import { modelDefinition } from "./model-definition";

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => createClearingStep(),

        // unique per model
        getLayout: () => modelDefinition.reelsLayout.join('-'),
        getModelDefinition: () => modelDefinition, 
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,
        getFeatureReelStrips: () => { throw new Error(`feature reels not available for vgw096`); },
        prepareStepForDB: step => step,
    };
}
