var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c("label", { attrs: { for: _vm.index } }, [
      _vm._v(" Prize #" + _vm._s(_vm.index) + ": "),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.scattersSelectedMultipliers[_vm.index - 1],
            expression: "scattersSelectedMultipliers[index - 1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.scattersSelectedMultipliers,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updateRespinScatterMulti()
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "Random" } }, [_vm._v("Random")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "ExtraWild" } }, [_vm._v("ExtraWild")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "X" } }, [_vm._v("X")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin1" } }, [_vm._v("coin1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin2" } }, [_vm._v("coin2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin3" } }, [_vm._v("coin3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin4" } }, [_vm._v("coin4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin5" } }, [_vm._v("coin5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin6" } }, [_vm._v("coin6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin7" } }, [_vm._v("coin7")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "coin8" } }, [_vm._v("coin8")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add1" } }, [_vm._v("add1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add2" } }, [_vm._v("add2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add3" } }, [_vm._v("add3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add4" } }, [_vm._v("add4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add5" } }, [_vm._v("add5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add6" } }, [_vm._v("add6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add7" } }, [_vm._v("add7")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "add8" } }, [_vm._v("add8")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }