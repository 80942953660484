import { modelDefinition } from "./model-definition";
import { createSpinStep } from "./steps";

export function setStateParams() {
  return {
    variations: [],

    // Must implement
    createDefaultStep: () => createSpinStep(),

    // unique per model
    getLayout: () => modelDefinition.reelsLayout.join("-"),
    getModelDefinition: () => modelDefinition,
    getNumberOfReels: () =>
      modelDefinition.reelsLayout.length + modelDefinition.topReelLayout.length,
    getNumberOfRows: () => modelDefinition.reelsLayout[0],
    getNumberOfTopReelColumns: () => modelDefinition.topReelLayout[0],
    getReelStrips: () => modelDefinition.reels,
    getTopReelStrip: () => modelDefinition.topReel,
    getFeatureReelStrips: () => {
      throw new Error(`feature reels not available for vgw051`);
    },
    prepareStepForDB: (step) => step,
  };
}
