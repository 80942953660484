var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [_vm._v("X symbol:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dynamicSymbol,
                expression: "dynamicSymbol",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "dynamicSymbol", id: "dynamicSymbol" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.dynamicSymbol = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeDynamicSymbol,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "N", selected: "" } }, [
              _vm._v("N"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "E" } }, [_vm._v("E")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "T" } }, [_vm._v("T")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c("div", { staticClass: "flex-center menu sm-label" }, [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.updatewheelResultCounter(true)
                },
              },
            },
            [_vm._v("Add Risk")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.updatewheelResultCounter(false)
                },
              },
            },
            [_vm._v("Delete Risk")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        _vm._l(_vm.wheelResults.length, function (n) {
          return _c("wheelResult", {
            key: n,
            attrs: { index: n, wheelResults: _vm.wheelResults },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }