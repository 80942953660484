var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFreeSpinTriggerStep,
                expression: "canAddFreeSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddHoldAndSpinTriggerStep,
                expression: "canAddHoldAndSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addHoldAndSpinTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Hold and Spin")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isHoldAndSpinRespin || _vm.holdAndSpinRespinsRemaining,
                  expression:
                    "isHoldAndSpinRespin || holdAndSpinRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " + _vm._s(_vm.holdAndSpinRespinsRemaining)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isHoldAndSpinRespin || _vm.holdAndSpinRespinsRemaining,
                  expression:
                    "isHoldAndSpinRespin || holdAndSpinRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Cheeses Collected: " + _vm._s(_vm.holdAndSpinCheesesCollected)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinRetriggersRemaining,
                  expression: "isFreeSpin || freeSpinRetriggersRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "FS Retriggers Remaining: " +
                  _vm._s(_vm.freeSpinRetriggersRemaining)
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFreeSpinPick,
                expression: "showFreeSpinPick",
              },
            ],
            staticClass: "label",
          },
          [_vm._v("Pick: ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFreeSpinPick,
                expression: "showFreeSpinPick",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.freeSpinPickValue,
                    expression: "freeSpinPickValue",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.freeSpinPickValue = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _vm._l(_vm.freeSpinPickOptions, function (opt) {
                  return [
                    _c("option", { domProps: { value: opt.value } }, [
                      _vm._v(_vm._s(opt.label)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showReelStripPositions,
                expression: "showReelStripPositions",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c("reel", { attrs: { content: _vm.content, index: 0 } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: _vm.content, index: 1 } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: _vm.content, index: 2 } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: _vm.content, index: 3 } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: _vm.content, index: 4 } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }