import Vue, { PropType } from "vue";
import { ScenarioStep } from "../../math-model/choices";
import { Card } from "./card";

export default Vue.component("CandyCaneControls", {
  props: {
    step: Object as PropType<ScenarioStep>,
  },
  components: {
    card: Card,
  },
  data: function () {
    return {
      numCandyCanes: 2,
    };
  },
});
