import type { CoinPrize, CoinPrizes, Position } from "../../../shared";

export function computeRedFeaturePrizesAndUpdateCoinPrizes(options: { position: Position; coinPrizes: CoinPrizes }) {
    const { coinPrizes, position } = options;

    const sumOfAllCoinPrizes = coinPrizes
        .filter((coinPrize) => coinPrize.type !== "JACKPOT_MAJOR")
        .reduce((total, coinPrize) => {
            return total + ( coinPrize.type !== "JACKPOT_MAJOR" ? coinPrize.value : 0);
        }, 0);
    const prize: CoinPrize = {
        type: "FEATURE",
        colour: "R",
        position,
        value: sumOfAllCoinPrizes,
    };

    coinPrizes.push(prize);
}
