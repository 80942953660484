var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c(
      "select",
      {
        domProps: { value: _vm.value },
        on: { change: _vm.updateRespinSymbol },
      },
      [
        _c("option", { attrs: { value: "None" } }, [_vm._v("None")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "H1" } }, [_vm._v("H1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "H2" } }, [_vm._v("H2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "H3" } }, [_vm._v("H3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "L1" } }, [_vm._v("L1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "L2" } }, [_vm._v("L2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "L3" } }, [_vm._v("L3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "L4" } }, [_vm._v("L4")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }