import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectExpandingWild from "./components/selectExpandingWild.vue";

@Component({
    components: {
        reel: Reel,
        selectExpandingWild: SelectExpandingWild,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public reelsNo = 5;

    public isPick = false;
    public numOfFreeSpins = 0;

    public expandingWild = new Array(5).fill(false);
    public bonusWild = "";

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ?? false;
        this.isPick = step.json.isPick ?? false;
        this.numOfFreeSpins = step.json.numOfFreeSpins ?? 12;
        this.bonusWild = step.json.bonusWild ?? "None";
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinStep());
    }

    public addPickStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.addPickStep());
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateExpandingWild(event) {
        const index = event.target.id - 1;
        if (this.step.json.expandingWild == null) {
            this.step.json.expandingWild = new Array(5).fill(false);
        }
        this.step.json.expandingWild[index] = event.target.value === "true" ? true : false;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateNumOfFreeSpinsWon(freespins) {
        this.step.json.numOfFreeSpins = parseInt(freespins);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onUpdateStep() {
        if (this.step.json.expandingWild != null) {
            this.expandingWild = this.step.json.expandingWild;
        } else {
            this.expandingWild = new Array(5).fill(false);
        }
        this.bonusWild = this.step.json.bonusWild;
    }

    public updateBonusWild() {
        this.step.json.bonusWild = this.bonusWild;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
