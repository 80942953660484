import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events'; 
import {getReelWindowFromIndex} from "../../../../helpers/helpers";
import {createClearingStep, createSpinStep, createFreeSpinTriggerStep, createFireshotTriggerStep} from '../../../../state/models/slots/vgw044';
import {CoinPrize, FreeSpinPrize, modelDefinition, originatorId, StepJson} from '../../../../state/models/slots/vgw044';
import Reel from "./reel.vue";

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
    },

    data: () => ({
        allSteps: [] as Step[],
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '???';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'C-Trigger') return 'Fireshot Trigger';
            if (this.stepName === 'F-Trigger') return `Free Spin Trigger`;
            if (this.stepName === 'F-Retrigger') return `Free Spin Retrigger`;
            if (this.stepName === 'J-Trigger') return `GRAND Jackpot Trigger`;
            if (this.isFireshotRespin) return 'Fireshot Respin';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },

        // ----- Fireshot state -----
        isFireshotTrigger(): boolean {
            return this.currentStep?.fireshotPhase === 'START';
        },
        isFireshotRespin(): boolean {
            const fireshotPhase = this.currentStep?.fireshotPhase;
            return fireshotPhase === 'IN_PROGRESS' || fireshotPhase === 'END';
        },
        fireshotRespinsRemaining(): number {
            return this.currentStep?.fireshotCount ?? 0;
        },
        fireshotCoinsCollected(): number {
            return this.currentStep?.cumulativeCoinCount ?? 0;
        },
        coinPrizeLevels(): Array<{value: unknown, label: string}> {
            const keys = Object.keys(modelDefinition.coinPrizeWeights.initial);
            return keys.map(k => ({
                value: isNaN(Number(k)) ? k : Number(k),
                label: `🟡 ${k}`,
            }));
        },

        // ----- Free spin state -----
        isFreeSpin(): boolean {
            if (this.isFireshotRespin) return false;
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        freeSpinPrizeLevels(): Array<{value: unknown, label: string}> {
            const keys = Object.keys(modelDefinition.freeSpinPrizeWeights);
            return keys.map(k => ({
                value: isNaN(Number(k)) ? k : Number(k),
                label: `🎁 ${k}`,
            }));
        },

        // ----- UI state -----
        canAddClearingStep(): boolean {
            return true;
        },
        canAddSpinStep(): boolean {
            return true;
        },
        canAddFreeSpinTriggerStep(): boolean {
            return !this.finalStep?.fireshotCount;
        },
        canAddFireshotTriggerStep(): boolean {
            return !this.finalStep?.fireshotCount;
        },
        labelForAddSpinStep(): string {
            if (this.finalStep?.fireshotCount) return 'Add Respin';
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin';
            return 'Add Base Spin';
        },
        labelForAddFreeSpinTriggerStep(): string {
            return `${this.finalStep?.freeSpinCount ? 'Ret' : 'T'}rigger Free Spins`;
        },
    },

    methods: {
        // ----- Methods for adding steps -----
        addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
        addSpinStep: () => bus.$emit(events.ADD_STEP, createSpinStep()),
        addFreeSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
        addFireshotTriggerStep: () => bus.$emit(events.ADD_STEP, createFireshotTriggerStep()),

        // ----- Fireshot methods -----
        isCoin({row, col}: {row: number, col: number}): boolean {
            return !!this.currentStep?.cumulativeCoinPrizes?.[col]?.[row];
        },
        canSetOrUnsetCoin({row, col}: {row: number, col: number}): boolean {
            if (!this.isFireshotRespin) return false;
            const prevStepInfo = this.allSteps[(this.currentStepIndex ?? 0) - 1];
            return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] === 0;
        },
        canSetCoinPrize({row, col}: {row: number, col: number}): boolean {
            if (this.isFireshotTrigger) {
                return this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] !== 0;
            }
            else {
                const prevStepInfo = this.allSteps[(this.currentStepIndex ?? 0) - 1];
                return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] === 0;
            }
        },
        getCoinPrize({row, col}: {row: number, col: number}): string {
            return (this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] ?? 0).toString();
        },
        setCoinPrize({row, col, value}: {row: number, col: number, value: string}) {
            if (!this.currentStep) return;
            const cp = toCoinPrize(value);
            this.currentStep.json.coinPrizes = [0, 1, 2, 3, 4].map(c => {
                return [0, 1, 2].map(r => {
                    return c === col && r === row ? cp : this.currentStep?.json.coinPrizes?.[c]?.[r] ?? 0;
                });
            });
            this.saveChangesToStep();
        },

        // ----- Free spin methods -----
        getFreeSpinPrize(reelIndex: number): string {
            return (this.currentStep?.json.freeSpinPrizes?.[reelIndex - 1] ?? 0).toString();
        },
        setFreeSpinPrize(reelIndex: number, value: string) {
            if (!this.currentStep) return;
            const fsp = toFreeSpinPrize(value);
            this.currentStep.json.freeSpinPrizes = [1, 2, 3].map((ri, i) => {
                return ri === reelIndex ? fsp : this.currentStep?.json.freeSpinPrizes?.[i] ?? 0;
            });
            this.saveChangesToStep();
        },

        // ----- Scenario persistence methods -----
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: {steps: Array<{test_scenario_step_id: number, json: StepJson}>}) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }

            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;

            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    fireshotPhase?: 'START' | 'IN_PROGRESS' | 'END';
    fireshotCount?: number;
    cumulativeCoinPrizes?: CoinPrize[][];
    cumulativeCoinCount?: number;
}

function updateStepsInPlace(steps: Step[]) {
    let name = '';
    let isFireshotRespin = false;
    let fireshotPhase: Step['fireshotPhase'];
    let fireshotCount = 0;
    let coinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
    let cumulativeCoinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
    let cumulativeCoinCount = 0;
    let isFreeSpin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;

    let isAnyStepModified = false;
    for (const step of steps) {
        if (step.json.name === 'Clear') {
            // Handle clearing steps by clearing all state
            name = step.json.name;
            isFireshotRespin = false;
            fireshotPhase = undefined;
            fireshotCount = 0;
            isFreeSpin = false;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
            coinPrizes = [];
            cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
            cumulativeCoinCount = 0;
        }
        else {
            isFireshotRespin = fireshotCount > 0;
            isFreeSpin = freeSpinCount > 0;
            const coinCells = findCells(step.json.reelStripPositions, modelDefinition.coinSymbol);
            const wildCells = findCells(step.json.reelStripPositions, modelDefinition.wildSymbol);
            const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot;
            const isFreeSpinTrigger = wildCells.length >= modelDefinition.countToTriggerFreeSpin;

            // Update state for fireshot respins
            if (isFireshotRespin) {
                const prevCoinCount = cumulativeCoinCount;
                coinPrizes = step.json.coinPrizes ?? [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
                coinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => cumulativeCoinPrizes?.[col]?.[row] ? 0 : (coinPrizes?.[col]?.[row]) || 0));
                cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => cumulativeCoinPrizes?.[col]?.[row] || coinPrizes?.[col]?.[row]) || 0);
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
                name = 'Respin';
                fireshotCount = cumulativeCoinCount === modelDefinition.countToTriggerGrand
                    ? 0
                    : cumulativeCoinCount > prevCoinCount
                        ? modelDefinition.fireshotRespinCount
                        : fireshotCount - 1;
                fireshotPhase = fireshotCount > 0 ? 'IN_PROGRESS' : 'END';
            }

            // Update state for base and free spins
            else {
                name = 'Spin';
                if (isFreeSpin) {
                    name = 'Free spin';
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END'
                }
                else {
                    freeSpinPhase = undefined;
                }
                if (isFreeSpinTrigger) {
                    name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                    const addedSpins = modelDefinition.freeSpinCount;
                    freeSpinCount += addedSpins;
                    freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
                }

                coinPrizes = [0, 1, 2, 3, 4].map(_ => [0, 0, 0]);
                for (const [row, col] of coinCells) coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
                cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => [0, 1, 2].map(row => coinPrizes?.[col]?.[row] || 0));
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);

                if (isFireshotTrigger) {
                    name = cumulativeCoinCount < modelDefinition.countToTriggerGrand ? 'C-Trigger' : 'J-Trigger';
                    fireshotCount = modelDefinition.fireshotRespinCount;
                    fireshotPhase = 'START';
                }
                else {
                    fireshotPhase = undefined;
                }
            }
        }
        Object.assign(step, {
            fireshotPhase, fireshotCount,
            freeSpinPhase, freeSpinCount,
            cumulativeCoinPrizes, cumulativeCoinCount,
        });
        const json = {...step.json, name, coinPrizes};
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }
    return isAnyStepModified;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = modelDefinition.reels;
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function toCoinPrize(value: string): CoinPrize {
    const n = Number(value);
    return isNaN(n) ? value : n as any;
}

function toFreeSpinPrize(value: string): FreeSpinPrize {
    const n = Number(value);
    return isNaN(n) ? value : n as any;
}
