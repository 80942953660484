import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from '../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import Reel from './reels/reel.vue';
import SelectScatterMultiplier from './components/selectScatterMultiplier.vue';
import MixAndMatchPrizes from './components/mixAndMatchPrizes.vue';

@Component({
    components: {
        reel: Reel,
        selectScatterMultiplier: SelectScatterMultiplier,
        mixAndMatchPrizes: MixAndMatchPrizes,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = '';
    public isOpen = false;
    public reelsNo = 5;
    public isRespin = false;
    public isRespinTrigger = false;
    public isMixAndMatchStep = false;
    public isFreespinSelectStep = false;
    public scattersSelectedMultipliers = new Array(15).fill('BLANK');
    public reelSetIndex = 0;
    public mixAndMatchTrigger = false;
    public metaStageCntr = -1;
    public mysteryCount = 15;
    public mysteryReelSet = 'extra5';
    public mixAndMatchPrizes = new Array();

    @Watch('step')
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.reelSetIndex = step.json.reelSetIndex;
        this.isFeature = step.json.isFeature ?? false;
        this.isRespin = step.json.isRespin ?? false;
        this.isRespinTrigger = step.json.isRespinTrigger ?? false;
        this.isMixAndMatchStep = step.json.isMixAndMatchStep ?? false;
        this.isFreespinSelectStep = step.json.isFreespinSelectStep ?? false;
        if (this.step.scattersSelectedMultipliers)
            this.scattersSelectedMultipliers = this.step.scattersSelectedMultipliers;

        this.mixAndMatchTrigger = step.json.mixAndMatchTrigger ?? false;
        this.metaStageCntr = step.json.metaStageCntr;
        this.mysteryCount = step.json.mysteryCount;
        this.mysteryReelSet = step.json.mysteryReelSet;
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public mounted() {
        this.isOpen = false;
        trace('reels mounted');
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addFreespinSelectStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinSelectStep());
    }

    public getFreespinExtra5Step() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinExtra5Step());
    }

    public getFreespinExtra15Step() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinExtra15Step());
    }

    public getFreespinExtra30Step() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinExtra30Step());
    }

    public addRespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStep());
    }

    public addRespinTrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinTrigger());
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateBonusWild() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateRespinScattersMultipliers(event) {
        const noWinSymbol = State.state.getModelDefinition().emptySymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }
        this.step.json.scattersSelectedMultipliers[event.target.id - 1] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public resetRespinSymbols() {
        const noWinSymbol = State.state.getModelDefinition().emptySymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onUpdateStep() {
        if (this.step.json.scattersSelectedMultipliers == undefined && this.step.json.respinScatterPrizes) {
            this.step.json.scattersSelectedMultipliers = [];

            const reelsNum = State.state.getModelDefinition().reelsLayout.length;
            const symbolsPerReelNum = State.state.getModelDefinition().reelsLayout[0];
            for (let i = 0; i < symbolsPerReelNum; i++) {
                for (let j = 0; j < reelsNum; j++) {
                    this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[j][i]);
                }
            }
        }

        const noWinSymbol = State.state.getModelDefinition().emptySymbol;
        if (this.step.json.scattersSelectedMultipliers != null) {
            this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
        } else {
            const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
            this.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }

        this.mixAndMatchTrigger = this.step.json.mixAndMatchTrigger ?? false;

        this.updateMixAndMatchPrizes();
    }

    public onChangeMixAndMatchTrigger() {
        this.step.json.mixAndMatchTrigger = this.mixAndMatchTrigger;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addMixAndMatchStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMixAndMatchStep());
    }

    public addDummyStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getDummyStep());
    }

    public onChangeMetaStageCntr() {
        this.step.json.metaStageCntr = this.metaStageCntr;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log(this.step.json.metaStageCntr);
    }

    public onChangeMysteryCount() {
        this.step.json.mysteryCount = this.mysteryCount;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log(this.step.json.mysteryCount);
    }

    public onChangeMysteryReelSet() {
        this.step.json.mysteryReelSet = this.mysteryReelSet;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log(this.step.json.mysteryReelSet);
    }

    public updateMixAndMatchPrizes() {
        if (this.step.json.mixAndMatchPrizes != null) {
            this.mixAndMatchPrizes = this.step.json.mixAndMatchPrizes;
        } else {
            this.mixAndMatchPrizes = new Array();
        }
    }

    public updateMixAndMatchPrize(index, event) {
        this.step.json.mixAndMatchPrizes[index] = event.target.value;
        this.mixAndMatchPrizes[index] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateMixAndMatchPrizeCounter(direction) {
        const wheelResultsNum = this.mixAndMatchPrizes.length;
        if (direction) {
            this.step.json.mixAndMatchPrizes[wheelResultsNum] = '';
            this.mixAndMatchPrizes[wheelResultsNum] = '';
        } else {
            if (wheelResultsNum > 0) {
                this.mixAndMatchPrizes.pop();
                this.step.json.mixAndMatchPrizes = [];
                this.mixAndMatchPrizes.forEach((element) => {
                    this.step.json.mixAndMatchPrizes.push(element);
                });
            } else {
                alert('no more to delete');
            }
        }
        this.$forceUpdate();
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
