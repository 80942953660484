/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        addExtraDataToStep: (step) => {
            step.jackpotWins = [];
            step.isJackpotPickStep = false;
            step.isBonusTriggerStep = false;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => 5,
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getBonusTriggerStep: () => {
            const step = State.state.createStep([8,23,51,35,0], false, 0, "", "B-Trigger");
            step.isBonusTriggerStep = true;
            return step;
        },
        getJackpotPickStep: () => {
            const step = State.state.createStep([8,23,51,35,0], false, 0, "", "Jackpot Pick");
            step.isJackpotPickStep = true;
            return step;
        },
        getFeatureTriggerFromBaseGame: () => State.state.createStep([11, 23, 51, 26, 0], false, 0, "", "F-Trigger"),
        getFeatureStep: () => State.state.createStep([0,0,0,0,0], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([0, 16, 17, 0, 0], true, 0, "", "F-Step") ,
    };
}
