var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.wheelPrize,
          expression: "wheelPrize",
        },
      ],
      staticClass: "bonus-wheel-pay",
      attrs: { name: "bonus-wheel-pay" },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.wheelPrize = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    [
      _c("option", { attrs: { value: "" } }),
      _vm._v(" "),
      _c("option", { attrs: { value: "1" } }, [_vm._v("1x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "2" } }, [_vm._v("2x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "3" } }, [_vm._v("3x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "4" } }, [_vm._v("4x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "5" } }, [_vm._v("5x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "10" } }, [_vm._v("10x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "20" } }, [_vm._v("20x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "100" } }, [_vm._v("100x")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "minor" } }, [_vm._v("minor")]),
      _vm._v(" "),
      _c("option", { attrs: { value: "major" } }, [_vm._v("major")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }