import type { Cell } from '@vgw/gdk-math-model-tools';
import { createWaysWinChecker, filterSlotWindow, createWaysWinSummariser } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../choices/choices';
import type { coinType } from '../../coin-type';

export type ScatterWin = {
    symbol: string;
    cells: Cell[];
    multiplier: number;
    superStarMultiplier?: number;
    winAmount: number;
};
export type BonusWin = {
    symbol: string;
    cells: Cell[];
    multiplier: number;
    winAmount: number;
};

export type EvaluateWinsArgs = {
    slotWindow: string[][];
    playAmount: number;
    coinAmount: number;
    choices: Choices;
    coinType: coinType;
};

export type WinCellsBySymbol = {
    symbol: string;
    cells: number[][];
};

export type WinInfo = {
    waysWins: number[];
    scatterWin: ScatterWin | undefined;
    bonusWin: BonusWin | undefined;
    winAmount: number;
    winCellsBySymbol: WinCellsBySymbol[];
    isSuperStarAwarded: boolean;
};

export type WinsEvaluator = (arg: EvaluateWinsArgs) => WinInfo;

export type CreateWinsEvaluatorArgs = {
    playLines: number[][];

    wildSymbol: string;
    scatterSymbol: string;
    bonusSymbol: string;
    winTable: {
        symbol: string;
        multipliers: number[];
    }[];
    scatterWinTable: {
        symbol: string;
        multipliers: (undefined | number)[];
    }[];
    bonusWinTable: {
        symbol: string;
        multipliers: (undefined | number)[];
    }[];
};

export function createWinsEvaluator(modelDefinition: CreateWinsEvaluatorArgs): WinsEvaluator {
    const evaluateWaysWins = createWaysWinChecker(modelDefinition);
    const summariseWaysWins = createWaysWinSummariser(modelDefinition);

    function evaluateScatterWin(slotWindow: string[][], playAmount: number): ScatterWin | undefined {
        const cells = filterSlotWindow(slotWindow, (sym) => sym === modelDefinition.scatterSymbol);

        const multiplier = modelDefinition.scatterWinTable[0].multipliers[cells.length - 1];

        return multiplier
            ? {
                  symbol: 'SCAT',
                  cells,
                  multiplier,
                  winAmount: multiplier * playAmount,
              }
            : undefined;
    }

    function evaluateBonusWin(slotWindow: string[][], playAmount: number): BonusWin | undefined {
        const cells = filterSlotWindow(slotWindow, (sym) => sym === modelDefinition.bonusSymbol);

        const multiplier = modelDefinition.bonusWinTable[0].multipliers[cells.length - 1];

        return multiplier
            ? {
                  symbol: 'BONUS',
                  cells,
                  multiplier,
                  winAmount: multiplier * playAmount,
              }
            : undefined;
    }

    return function evaluateWins(args: EvaluateWinsArgs) {
        const waysWins = evaluateWaysWins(args.slotWindow);

        const bonusWin = evaluateBonusWin(args.slotWindow, args.playAmount);
        const waysWinAmounts = waysWins.map((win) => win.multiplier * args.coinAmount);
        const totalWaysWinAmount = waysWinAmounts.reduce((acc, win) => acc + win, 0);

        const scatterWin = evaluateScatterWin(args.slotWindow, args.playAmount);
        const { isSuperStarAwarded, superStarMultiplier } = args.choices.chooseSuperStarMultiplier(
            scatterWin?.cells.length ?? 0,
            args.coinType,
            args.coinAmount,
        );

        if (scatterWin) {
            scatterWin.superStarMultiplier = superStarMultiplier;
            scatterWin.winAmount = (scatterWin?.winAmount ?? 0) * (superStarMultiplier ?? 1);
        }

        const scatterWinAmount = scatterWin?.winAmount ?? 0;
        const bonusWinAmount = bonusWin?.winAmount ?? 0;

        const winAmount = totalWaysWinAmount + scatterWinAmount + bonusWinAmount;
        const winCellsBySymbol = summariseWaysWins(waysWins);

        return {
            waysWins: waysWinAmounts,
            scatterWin,
            winAmount,
            bonusWin,
            winCellsBySymbol,
            isSuperStarAwarded,
        };
    };
}
