/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0]);
            step.wheelSpinValue = "0";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => State.state.getModelDefinition().reels,

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        prepareStepForDB: (step) => {
            delete step.reelStripPositions;
            delete step.reelsType;
            delete step.variation;
            delete step.isFeature;

            return step;
        }
    };
}
