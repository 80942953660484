/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf002_samureel",
        "scatterSymbol": "SC1",
        "scatterSymbols": ["WI1", "SC1", "FS1", "KA1", "KA2", "KA3", "KA4"],
        "wildSymbol": "WI1",
        "freeSpinTrigger": "FS1",
        "bonusSymbol": "SC1",
        "countToTriggerFreeSpin" : 3,
        "countToTriggerBonusGame": 3,

        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 5, 10, 20, 30, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 500, 600, 750, 1000, 1250, 1500, 1750, 2000]
            },
            {
                "symbol": "LV2",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 5, 10, 20, 30, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 500, 600, 750, 1000, 1250, 1500, 1750, 2000]
            },
            {
                "symbol": "LV3",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 5, 10, 20, 30, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 500, 600, 750, 1000, 1250, 1500, 1750, 2000]
            },
            {
                "symbol": "LV4",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 5, 10, 20, 30, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 500, 600, 750, 1000, 1250, 1500, 1750, 2000]
            },
            {
                "symbol": "HV1",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 10, 20, 40, 80, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500, 2000, 3000, 4000]
            },
            {
                "symbol": "HV2",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 20, 30, 50, 100, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500, 2000, 3000, 4000, 5000]
            },
            {
                "symbol": "HV3",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 30, 40, 60, 120, 250, 300, 350, 400, 500, 550, 600, 750, 1000, 1250, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000]
            },
            {
                "symbol": "HV4",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 40, 50, 80, 160, 300, 350, 400, 450, 600, 700, 800, 1000, 1250, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 7500]
            },
            {
                "symbol": "HV5",
                "multipliers": [0, 0, 0, 0, 0, 0, 0, 0, 50, 60, 100, 200, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 10000]
            }
        ],

        "reelsLayout": [5, 5, 5, 5, 5, 5],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 25 ],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"1500000",

        "coinType4_min_bet":"25",
        "coinType4_max_bet":"25",
        "coinType4_spin_max_bet":"25",

        "reels": [
            [ "LV1", "LV1", "LV1", "LV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "SC1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "HV4", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV4", "FS1", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "LV2", "LV2", "LV2", "HV2", "HV2", "HV2", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV2", "LV2", "LV2", "SC1", "LV3", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "HV2", "HV2", "HV2", "HV5", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "FS1", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5" ],
            [ "LV1", "LV1", "LV1", "LV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "SC1", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "FS1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "HV4", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV4", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "FS1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "LV2", "LV2", "LV2", "SC1", "HV2", "HV2", "HV2", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "HV2", "HV2", "HV2", "HV5", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "FS1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5" ],
            [ "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "SC1", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "HV2", "HV2", "FS1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV2", "HV2", "HV2", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "HV4", "SC1", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "FS1", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "SC1", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "FS1", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "LV4" ],
            [ "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "HV4", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV3", "HV3", "HV3", "HV3", "HV3", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV4", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "LV2", "LV2", "LV2", "HV2", "HV2", "HV2", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV2", "LV2", "LV2", "HV4", "HV4", "HV4", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "HV2", "HV2", "HV2", "HV5", "HV5", "HV5", "HV5", "LV2", "LV2", "LV2", "LV2", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5" ],
            [ "HV1", "HV1", "HV1", "HV1", "HV1", "HV1", "HV1", "HV1", "HV3", "HV3", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "HV2", "HV2", "HV2", "HV2", "HV2", "HV2", "HV2", "HV2", "HV4", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "HV5", "HV5", "HV5", "HV5", "HV5", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "HV1", "HV1", "LV1", "LV1", "LV1", "HV4", "HV4", "HV4", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "HV5", "HV5", "HV5", "HV5", "HV5", "HV3", "HV3", "HV3", "HV3", "HV3", "HV3", "HV3" ],
            [ "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV3", "LV3", "LV3", "KA1", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "LV2", "KA1", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "KA1", "LV4", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "HV2", "HV2", "HV2", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "HV3", "HV3", "HV3", "KA1", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "KA1", "HV3", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV1", "HV1", "HV1", "KA1", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV1", "LV1", "LV1", "KA1", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "KA1", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "KA1", "LV2", "LV2", "LV2", "KA1", "HV1", "HV1", "HV1", "HV1", "HV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA1", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "KA1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "KA1", "LV2", "LV2", "LV2", "LV2", "KA1", "HV5", "HV5", "HV5", "HV5", "HV5", "HV5", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA1", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5" ],
            [ "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "KA2", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "LV4", "KA2", "HV1", "HV1", "HV1", "HV2", "HV2", "HV2", "HV2", "HV2", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV3", "LV3", "LV3", "LV3", "KA2", "HV4", "HV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "KA2", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV1", "KA2", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "KA2", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA2", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "KA2", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "LV1", "LV1", "LV1", "KA2", "LV2", "LV2", "LV2", "LV2", "LV2", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "KA2", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5" ],
            [ "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "KA3", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV2", "HV2", "HV2", "HV2", "HV2", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV3", "HV3", "HV3", "KA3", "HV4", "HV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV1", "HV1", "HV1", "KA3", "LV2", "LV2", "LV2", "LV2", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV1", "LV1", "LV1", "KA3", "HV1", "HV1", "HV1", "HV1", "LV4", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "KA3", "LV1", "LV1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA3", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "KA3", "LV1", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "KA3", "LV2", "LV2", "LV2", "LV2", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA3", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4", "HV5", "HV5", "HV5" ],
            [ "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "LV4", "LV3", "LV3", "LV3", "HV4", "HV4", "HV4", "LV2", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "KA4", "LV4", "LV4", "LV4", "LV4", "HV1", "HV1", "HV1", "HV2", "HV2", "HV2", "HV2", "HV2", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "LV3", "LV3", "LV3", "LV3", "KA4", "HV4", "HV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "LV2", "HV3", "HV3", "HV3", "HV3", "HV3", "WI1", "WI1", "WI1", "HV4", "HV4", "HV4", "HV4", "KA4", "HV1", "HV1", "HV1", "LV3", "LV3", "LV3", "LV3", "LV3", "LV1", "LV1", "LV1", "WI1", "WI1", "WI1", "LV4", "LV4", "LV4", "LV4", "KA4", "HV1", "HV1", "HV1", "HV1", "LV1", "LV1", "LV1", "HV5", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV2", "HV2", "HV2", "HV3", "HV3", "HV3", "LV2", "LV2", "LV2", "LV2", "HV1", "HV1", "HV1", "HV1", "HV1", "WI1", "WI1", "WI1", "LV2", "LV2", "LV2", "KA4", "LV3", "LV3", "LV3", "LV3", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "LV1", "LV1", "LV1", "LV1", "LV4", "LV4", "LV4", "LV4", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "HV5", "KA4", "LV2", "LV2", "LV2", "LV2", "LV4", "LV4", "LV4", "HV4", "HV4", "HV4", "HV4", "HV4", "HV4", "WI1", "WI1", "WI1", "HV5", "HV5", "HV5", "KA4", "LV3", "LV3", "LV3", "LV3", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "LV4" ]
        ],
        "reelSetsConfiguration": [
            [ 1, 3, 1, 1, 3, 1 ],
            [ 2, 3, 2, 2, 3, 2 ],
            [ 4, 6, 4, 4, 6, 4 ],
            [ 5, 6, 5, 5, 6, 5 ],
            [ 4, 7, 4, 4, 7, 4 ],
            [ 5, 7, 5, 5, 7, 5 ],
            [ 4, 8, 4, 4, 8, 4 ],
            [ 5, 8, 5, 5, 8, 5 ],
            [ 4, 9, 4, 4, 9, 4 ],
            [ 5, 9, 5, 5, 9, 5 ]
        ],

        "playLines": [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
    },
}
