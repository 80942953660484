import { createSlotWindowGenerator } from "@vgw/gdk-math-model-tools";
import { modelDefinition } from "./model-definition";

const { mysterySymbol } = modelDefinition;

export function createLampsSlotWindowGenerator(reelDetails: { reels: string[][]; reelsLayout: number[] }) {
    /**
     * Generates a slot window replacing the mystery symbol with `replacementSymbol`.
     */
    const generateBaseWindow = createSlotWindowGenerator(reelDetails);

    // tslint:disable-next-line:only-arrow-functions
    return function(reelStripPositions: number[], replacementSymbol: string) {
        const slotWindow = generateBaseWindow(reelStripPositions);

        for (const item of slotWindow) {
            for (let j = 0; j < item.length; j++) {
                if (item[j] === mysterySymbol) {
                    item[j] = replacementSymbol;
                }
            }
        }

        return slotWindow;
    };
}
