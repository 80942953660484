import { modelDefinition } from "../config";
import { createSlotWindowGenerator } from "../create-slot-window-generator";
import { getWinType } from "./get-win-type";
import { SpinInputs } from "./spin-inputs";
import { SpinOutputs } from "./spin-outputs";
import { checkScatterWin } from "./check-scatter-win";
import { evaluateLineWinsWithWilds } from "./evaluate-line-wins-with-wilds";
import { updateSlotWindowWithWilds } from "./update-slot-window-with-wilds";
import { computeWinAmountForFeatureSpin } from "./compute-win-amount-for-feature-spin";
import { computeJackpotWinCounts } from "./compute-jackpot-win-counts";

const SPIN_TYPE = "FREE_SPINS";

export function freeSpin(inputs: SpinInputs): SpinOutputs {
  if (
    inputs.gameState?.freeSpinPhase === undefined ||
    inputs.gameState?.freeSpinCount === undefined
  ) {
    throw new Error("Missing state");
  }

  const { coinTypeId, coinSize, choices } = inputs;
  let { freeSpinCount, freeSpinPhase } = inputs.gameState;
  const { cumulativeWinAmount: previousCumulativeWinAmount } = inputs.gameState;

  // Generate the slot window
  const totalPlayAmount = coinSize * coinSizeMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions(SPIN_TYPE);
  const mysterySymbol = choices.chooseMysterySymbol(SPIN_TYPE);
  const baseSlotWindow = generateSlotWindow(reelStripPositions, mysterySymbol);

  // Substitute wilds
  const { slotWindow, wilds, newWilds } = updateSlotWindowWithWilds(
    SPIN_TYPE,
    coinTypeId,
    totalPlayAmount,
    choices,
    baseSlotWindow,
    inputs.gameState.wilds,
  );

  // Evaluate line wins
  const { winLines, lineWins, winCells } = evaluateLineWinsWithWilds(
    slotWindow,
    coinSize,
    wilds,
  );

  // Evaluate scatter win
  const scatterWin = checkScatterWin(baseSlotWindow);
  if (scatterWin) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount);
    winCells.push(scatterWin.cells);
  }

  // Compute win type and amount
  const jackpotWinCounts = computeJackpotWinCounts(newWilds);
  const { totalWinAmount, jackpotWinAmount, cumulativeWinAmount } =
    computeWinAmountForFeatureSpin(
      totalPlayAmount,
      previousCumulativeWinAmount,
      jackpotWinCounts,
      lineWins,
    );
  const winType = getWinType({
    totalWinAmount,
    isFreeSpinTrigger: !!scatterWin,
    isFreeSpins: true,
    jackpotWinCounts,
  });

  // Calculate new game state
  freeSpinCount -= 1;
  freeSpinPhase = freeSpinCount > 0 ? "IN_PROGRESS" : "END";

  if (scatterWin) {
    freeSpinPhase = "RETRIGGER";
    freeSpinCount += modelDefinition.freeSpinRetriggerCount;
  }

  const gameState =
    freeSpinPhase === "END"
      ? undefined
      : {
          coinSize,
          reelStripPositions,
          slotWindow,
          mysterySymbol,
          cumulativeWinAmount,
          wilds,
          newWilds,
          freeSpinPhase,
          freeSpinCount,
        };

  return {
    spinOutcome: {
      coinSize,
      totalPlayAmount,
      reelStripPositions,
      slotWindow,
      totalWinAmount,
      winType,
      winLines,
      lineWins,
      winCells,
      mysterySymbol,

      freeSpinCount,
      freeSpinPhase,
      wilds,
      newWilds,
      cumulativeWinAmount,
      jackpotWinAmount: jackpotWinAmount || undefined,
    },
    gameState,
    jackpotWinCounts: {
      GRAND: jackpotWinCounts.GRAND,
      MAJOR: jackpotWinCounts.MAJOR,
    },
  };
}

// Generate helper functions tailored to the model definition.
const { coinSizeMultiplier } = modelDefinition;
const generateSlotWindow = createSlotWindowGenerator(modelDefinition.freeSpins);
