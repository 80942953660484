var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        symbol: true,
        winCell: _vm.isWinCell,
        G: _vm.isGreen,
        P: _vm.isPurple,
        R: _vm.isRed,
      },
      style: { "grid-column": _vm.colIndex + 1, "grid-row": _vm.rowIndex + 1 },
      on: {
        click: function ($event) {
          return _vm.toggleColour(_vm.rowIndex, _vm.colIndex, _vm.colour)
        },
      },
    },
    [
      _c("p", { staticClass: "original-name" }, [
        _vm._v(_vm._s(_vm.originalName)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "current-name" }, [
        _vm._v(_vm._s(_vm.symbolName)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }