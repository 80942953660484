var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu newMenu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(1)
              },
            },
          },
          [_vm._v("Step FS1")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(2)
              },
            },
          },
          [_vm._v("Step FS2")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(3)
              },
            },
          },
          [_vm._v("Step FS3")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(4)
              },
            },
          },
          [_vm._v("Step FS4")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sub-title-menu newMenu spaceNewMenu" }, [
        _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "sub-title-menu",
            },
            [
              _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isBase,
                        expression: "isBase",
                      },
                    ],
                    staticClass: "select-style",
                  },
                  [
                    _c("label", { attrs: { for: "bg_number" } }, [
                      _vm._v("LevelFS: "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.levelNo,
                            expression: "levelNo",
                          },
                        ],
                        staticStyle: {
                          color: "#00c700",
                          "font-weight": "bold",
                          "font-size": "small",
                          "padding-left": "50px",
                        },
                        attrs: { id: "bg_number" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.levelNo = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "-1" } }, [
                          _vm._v("Increment"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("No level"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("Level 1"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Level 2"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "3" } }, [
                          _vm._v("Level 3"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "select-style newSelect" }, [
                  _c("label", { attrs: { for: "reel_number" } }, [
                    _vm._v("Extra reels: "),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.extraReels,
                        expression: "extraReels",
                      },
                    ],
                    staticClass: "text-input reel-text reel-index newInput",
                    attrs: { type: "number", min: "0", id: "reel_number" },
                    domProps: { value: _vm.extraReels },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.extraReels = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticClass: "select-style-wheel",
                  },
                  [
                    _vm._v("\n                        Wheel toggle:"),
                    _c("br"),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "isGreenTrue" } }, [
                      _vm._v("true "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isGreen,
                          expression: "isGreen",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "isGreenTrue",
                        name: "isGreen",
                        value: "1",
                      },
                      domProps: { checked: _vm._q(_vm.isGreen, "1") },
                      on: {
                        change: function ($event) {
                          _vm.isGreen = "1"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "isGreenFalse" } }, [
                      _vm._v("false "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isGreen,
                          expression: "isGreen",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "isGreenFalse",
                        name: "isGreen",
                        value: "0",
                      },
                      domProps: { checked: _vm._q(_vm.isGreen, "0") },
                      on: {
                        change: function ($event) {
                          _vm.isGreen = "0"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "isGreenRandom" } }, [
                      _vm._v("random "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isGreen,
                          expression: "isGreen",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "isGreenRandom",
                        name: "isGreen",
                        value: "2",
                      },
                      domProps: { checked: _vm._q(_vm.isGreen, "2") },
                      on: {
                        change: function ($event) {
                          _vm.isGreen = "2"
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFSTrigger,
                  expression: "!isFSTrigger",
                },
              ],
              staticClass: "elemOK pointer",
              class: [_vm.isActive ? "blue" : "elemOK"],
              attrs: { id: "ok" },
              on: {
                click: function ($event) {
                  return _vm.updateStep(2, _vm.levelNo, _vm.extraReels)
                },
              },
            },
            [_vm._v("OK")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFSTrigger,
                  expression: "isFSTrigger",
                },
              ],
              staticClass: "elemOK pointer",
              class: [_vm.isActive ? "blueTrigger" : "elemOKTrigger"],
              attrs: { id: "okTrigger" },
              on: {
                click: function ($event) {
                  return _vm.updateStep(
                    _vm.isGreen,
                    _vm.levelNo,
                    _vm.extraReels
                  )
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.extraReels > 3 ? "flex" : "flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }