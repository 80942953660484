import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
	components: {
		reel: Reel,
	},
})
export default class Content extends Vue {
	@Prop() public step: any;
	public isFeature = false;
	public isFeatureChecked = false;
	public show = false;
	public reelStripPositions = [];
	public reelsNo = 4;
	public reel = "";
	public isOpen = false;

	public forceMysteryFruit = 0;
	public forceBonus = 0;
	public reelsetId = 0;
	public swapSymbol = 0;

	constructor() {
		super();
	}

	@Watch("step")
	public stepWatch(step) {
		if (!step) {
			this.show = false;
			return;
		}

		this.isOpen = false;
		this.show = true;
		this.isFeature = step.json.isFeature ? step.json.isFeature : false;

		if (this.reelsNo !== this.step.json.reelStripPositions.length) {
			this.reelsNo = this.step.json.reelStripPositions.length;
			bus.$emit(events.UPDATE_STEPS_DB, this.step);
		}
	}

	public mounted() {
		this.isOpen = false;
		trace("reels mounted");
	}

	public addBaseStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getBaseStep());
	}

	public addFeatureTriggerStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
	}

	public addClearingStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getClearingStep());
	}

	public updatereelsetId(reelsetId) {
		this.step.json.reelsetId = parseInt(reelsetId);
		this.reelsetId = reelsetId;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateMysteryFruitNumber(fruitNumber) {
		if (!this.forceMysteryFruit) {
			this.step.json.swapSymbol = undefined;
		}
		this.step.json.forceMysteryFruit = parseInt(fruitNumber);
		this.step.json.forceBonus = 0;
		if (!this.forceMysteryFruit) {
			this.step.json.swapSymbol = undefined;
		}

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateBonusNumber(bonusNumber) {
		this.step.json.forceBonus = parseInt(bonusNumber);
		this.step.json.forceMysteryFruit = 0;
		this.step.json.swapSymbol = undefined;

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateSettings() {
		this.forceBonus = this.step.json.forceBonus;
		this.forceMysteryFruit = this.step.json.forceMysteryFruit;
		this.swapSymbol = this.step.json.swapSymbol;
	}

	public updateSwapSymbol(swap) {
		if (this.forceMysteryFruit > 0) {
			this.step.json.swapSymbol = Number(swap);
			if (this.step.json.swapSymbol === 0) {
				this.step.json.swapSymbol = undefined;
			}
		} else {
			this.step.json.swapSymbol = undefined;
		}
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}
}
