var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addPickStep()
              },
            },
          },
          [_vm._v("Pick Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("FreeSpins Retrigger")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "kb-margin-top" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isStepBonusChecked,
              expression: "isStepBonusChecked",
            },
          ],
          staticClass: "bonus-guide-text kb-you-must",
        },
        [
          _vm._v(
            "\n        Make sure the previous step is mirroring with the chosen bonus. "
          ),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isStepBonusChecked ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.isStepBonusChecked ? "Step is PICK" : "Step is Base"
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isStepBonusChecked,
                expression: "isStepBonusChecked",
              },
            ],
          },
          [_vm._v("  |  ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isStepBonusChecked,
                expression: "isStepBonusChecked",
              },
            ],
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isWheel ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleBonus()
              },
            },
          },
          [_vm._v("\n            WHEEL\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isStepBonusChecked,
                expression: "isStepBonusChecked",
              },
            ],
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isWheel ? "" : "btn-toggle-is-feature",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleBonus()
              },
            },
          },
          [_vm._v("\n            FREE SPINS\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "kb-margin-top" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showReels,
              expression: "!showReels",
            },
          ],
          staticClass: "flex-center sub-title-menu bonus-wheels",
        },
        [
          _c("div", { staticClass: "bonus-trigger" }, [
            _c(
              "div",
              [
                _c("p", { staticClass: "bonus-guide-text" }, [
                  _vm._v(
                    "\n                    Wheel Bonus Multipliers (3-8 multiplier rounds plus to to 2 jackpots)\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "bonus-guide-text" }, [
                  _vm._v(
                    "Non sequential rounds are ignored. Max 1 of each jackpot type."
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.maxWheelBonusRounds, function (wheelIndex) {
                  return _c("wheelBonusRound", {
                    key: wheelIndex - 1,
                    attrs: {
                      index: wheelIndex - 1,
                      initialValue: _vm.wheelBonusRounds[wheelIndex - 1],
                      step: _vm.step,
                    },
                  })
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReels,
              expression: "showReels",
            },
          ],
          staticClass: "reel-container",
          class: ["flex-center"],
        },
        [
          _c("reel", {
            staticClass: "top-reel",
            attrs: {
              index: 0,
              step: _vm.step,
              isFeature: _vm.isFreeSpins,
              hasChanged: _vm.reelHasChanged,
            },
          }),
          _vm._v(" "),
          _vm._l(4, function (reelIndex) {
            return _c("reel", {
              key: `reel-kraken-${reelIndex}`,
              attrs: {
                index: reelIndex,
                step: _vm.step,
                isFeature: _vm.isFreeSpins,
                hasChanged: _vm.reelHasChanged,
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "kb-margin-top" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusTriggered && !_vm.isStepBonusChecked,
              expression: "isBonusTriggered && !isStepBonusChecked",
            },
          ],
          staticClass: "bonus-guide-text",
        },
        [
          _c("div", { staticClass: "kb-you-must" }, [
            _vm._v(
              "Select which bonus to win: (MAKE SURE THE NEXT STEP MIRRORS THE CHOSEN BONUS)"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-center sub-title-menu" }, [
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.isWheel ? "btn-toggle-is-feature" : "",
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBonus()
                  },
                },
              },
              [_vm._v("\n                WHEEL\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.isWheel ? "" : "btn-toggle-is-feature",
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBonus()
                  },
                },
              },
              [_vm._v("\n                FREE SPINS\n            ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }