/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {
  return {
    variations: [""],
    getModelDefinition: () =>
      State.state.standalone
        ? init.modelDefinition
        : State.state.slot.state.getModelDefinition(),
    createDefaultStep: () => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0, 0, 0],
        false,
        0,
        "",
        "Reels"
      );
      step.reelsType = "reels";
      step.rows = State.state.getModelDefinition().reels;
      step.upgradeSource = "";
      step.upgradeTarget = "";
      step.megaReelStripPositions = [];
      step.removeBlockIndex = -1;
      return step;
    },
    getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
    getRowsNo: () => State.state.getModelDefinition().getLayout(),
    getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
    getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],

    getMegaReelStrips: (step, megIndex) => {
      const where = step.json ? step.json : step;
      const def = State.state.getModelDefinition();
      const levels = where.isFeature ? def.mega.featureLevels : def.mega.levels;
      return levels[megIndex].reelSet;
    },

    getReelStrips: (type) => {
      switch (type) {
        case "reels":
          return State.state.getModelDefinition().reels;
          break;
        case "featureReels":
            return State.state.getModelDefinition().featureReels;
            break;
        default:
          return State.state.getModelDefinition().reels;
      }
    },
    getReels: (step) => {
      const where = step.json ? step.json : step;
      const reels = where.isFeature
        ? State.state.getFeatureReelStrips()
        : State.state.getReelStrips();
      return reels;
    },
    getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
    getBaseStep: () => State.state.createDefaultStep(),
    getFeatureTriggerStep: (order = 0) => {
      const step = State.state.createStep(
        [19, 19, 19, 19, 0, 0, 19],
        false,
        order,
        "",
        "F-Trigger"
      );
      step.reelsType = "reels";
      step.megaReelStripPositions = [];
      step.upgradeSource = "";
      step.upgradeTarget = "";
      step.removeBlockIndex = -1;
      return step;
    },
    getFeatureStep: () => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0, 0, 0],
        true,
        0,
        "",
        "F-Step"
      );
      step.reelsType = "featureReels";
      step.megaReelStripPositions = [];
      step.upgradeSource = "";
      step.upgradeTarget = "";
      step.removeBlockIndex = -1;
      return step;
    },
    prepareStepForDB: (step) => {
      const state = State.state;
      const reelStrips = state.getReelStrips(step.reelsType);
      const noOfReels = 5;
      step.rows = rowsFromIndexes(
        reelStrips,
        step.reelStripPositions,
        noOfReels,
        7
      );
      step.randoms = step.reelStripPositions;
      return step;
    },
    getClearingStep: function() {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0, 0, 0],
        false,
        0,
        "",
        "Clear"
      );
      step.megaReelStripPositions = [];
      step.upgradeSource = "";
      step.upgradeTarget = "";
      step.removeBlockIndex = -1;
      step.gameState = "clear";
      return step;
    },
  };
}
