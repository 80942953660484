import type { Choices } from '../../../choices/index';
import type { WinInfo } from '../create-wins-evaluator';

export function evaluateBankerMultiplierFeature(
    slotWindow: string[][],
    winInfo: WinInfo,
    choices: Choices,
    isFreeSpin: boolean,
): number | undefined {
    // Calculate if banker Multiplier is awarded
    const scatCount = slotWindow.flat().filter((symbol) => symbol === 'SCAT').length;
    const caseCount = slotWindow.flat().filter((symbol) => symbol === 'CASE').length;
    const isBankerMultiplierAwarded =
        scatCount < 3 &&
        caseCount < 6 &&
        winInfo.winAmount > 0 &&
        choices.chooseIfBankerMultiplierIsAwarded(isFreeSpin);

    // Evaluate banker Multiplier feature
    if (isBankerMultiplierAwarded) {
        const bankerMultiplier = choices.chooseBankerMultiplierAmount();
        return bankerMultiplier;
    }

    return undefined;
}
