// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-5e4a99ce] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-5e4a99ce] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-5e4a99ce] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-5e4a99ce] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-5e4a99ce] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-5e4a99ce] {
    margin: 5px 0 5px 0;
}
.dice-result-selector-wrapper[data-v-5e4a99ce] {
    position: relative;
}
.dice-result-selectors[data-v-5e4a99ce] {
    position: absolute;
    top: 50px;
    margin-left: 18px;
    text-align: center;
    padding: 10px;
}
.dice-result-selector[data-v-5e4a99ce] {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/dice/tf118/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".sm-game-spec-content[data-v-5e4a99ce] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-5e4a99ce] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-5e4a99ce] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-5e4a99ce] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-5e4a99ce] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-5e4a99ce] {\n    margin: 5px 0 5px 0;\n}\n.dice-result-selector-wrapper[data-v-5e4a99ce] {\n    position: relative;\n}\n.dice-result-selectors[data-v-5e4a99ce] {\n    position: absolute;\n    top: 50px;\n    margin-left: 18px;\n    text-align: center;\n    padding: 10px;\n}\n.dice-result-selector[data-v-5e4a99ce] {\n    display: inline-block;\n    margin-top: 10px;\n    margin-right: 10px;\n    margin-left: 10px;\n    margin-bottom: 10px;\n    width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
