// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-f94498b8] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-f94498b8] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-f94498b8] {
    text-align: center;
}
.sm-game-spec-content .use-feature[data-v-f94498b8] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-f94498b8] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-f94498b8] {
    margin: 5px 0 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/bingo/flambingo/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".sm-game-spec-content[data-v-f94498b8] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-f94498b8] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-f94498b8] {\n    text-align: center;\n}\n.sm-game-spec-content .use-feature[data-v-f94498b8] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-f94498b8] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-f94498b8] {\n    margin: 5px 0 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
