/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base RS1");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.bands = [0,1,2,3,4];
            return step;
        },
        getBaseStep: function(stepTitle, bands) {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", stepTitle);
            if(bands == 1) {
                step.reelsType = "reels1";
                step.bands = [0,1,2,3,4];
            }else{
                step.reelsType = "reels2";
                step.bands = [5,6,7,8,9];
            }
            step.internal_name = "Base";

            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type, bands) => {
            switch(type) {
                case "reels":
                    return State.state.getBaseReels(bands);
                    break;
                case "featureReels":
                    return State.state.getModelDefinition().featureReels;
                    break;
                default:
                    return State.state.getBaseReels([0,1,2,3,4]);
            }
        },

        // example on creating custom indexes
        getFSTriggerStep (stepTitle, bands) {
            const step = State.state.createStep([14, 4, 23, 10, 12], false, 0, "", stepTitle);
            step.reelsType = "reels";
            step.bands = bands;
            step.internal_name = "FS_trigger";
            return step;
        },
        getBaseReels(bands) {
            let reels: any[] = [];
            for (let i = 0; i < bands.length; i++) {
                reels[i] = State.state.getModelDefinition().reels[bands[i]];
            }
            return reels;
        },
        getFSStep (stepTitle) {
            const step = State.state.createStep([3, 0, 12, 0, 4], false, 0, "", stepTitle);
            step.reelsType = "featureReels";
            step.internal_name = "FS_step";
            return step;
        },

        getBonusTriggerStep (stepTitle, bands) {
            const step = State.state.createStep([25, 12, 42, 8, 19], false, 0, "", stepTitle);
            step.reelsType = "reels";
            step.bands = bands;
            step.internal_name = "Bonus_trigger";
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType, step.bands);
            const noOfReels = 5;

            const noOfRows = (step.reelsType === "featureReels") ? 4 : 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
