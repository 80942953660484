import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import { trace, TODO, range } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isFeature = false;
    public isFeatureChecked = false;
    public reelsNo: number[]  = [];
    public reelArray: number[][] = [[2],[3],[4],[5],[2,3],[2,4],[2,5],[3,4],[3,5],[4,5],[2,3,4],[2,3,5],[2,4,5],[3,4,5],[2,3,4,5]];
    public multiplierValueArray: number[] = [2,3,4,5,7,10,100];
    public multiplierValue: number = 1;
    public reelCount : number = 5;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
    }

    /**
     * Gets the Base Game Step.
     */
    public getBaseStep() {
        const state = State.state;
        const stepTitle = "Base";
        bus.$emit(events.ADD_STEP, state.getBaseStep(stepTitle, this.multiplierValue));
    }

    /**
     * Gets the Free Game Step.
     */
    public getFreeGameStep() {
        const state = State.state;
        const stepTitle = "FreeGame";
        bus.$emit(events.ADD_STEP, state.getFreeGameStep(stepTitle, this.multiplierValue = 1 ));
    }
    /**
     * method for updatng win vasion multiplier
     */
    public onUpdateMultiplier() {
        this.step.json.winvasionMultiplier = this.multiplierValue;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    /**
     * method for updating reels no 
     */
    public onUpdateReels() {
        this.step.json.reels = this.reelsNo;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}