import Vue from "vue";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import {getReelWindowFromIndex} from "../../../../helpers/helpers";
import {modelDefinition} from "../../../../state/models/slots/vgw077";
import {getReels} from './get-reels';
import Reel from "./reel.vue";

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
    },

    data: () => ({
        isSaved: true,
        allSteps: [] as Array<StepInfo>,

        // step details
        index: 0,
        gameState: 'clear' as GameState,
        reelStripPositions: [] as number[],
        wheel1Index: undefined as number | undefined,
        wheel2Index: undefined as number | undefined,
        coinMode: undefined as CoinMode | undefined,
        coinPrizes: [] as (CoinPrize | null)[][],
    }),

    computed: {
        show(): boolean {
            return this.step?.json !== undefined;
        },
        stepInfo(): StepInfo | undefined {
            return this.allSteps.find(s => s.index === this.index);
        },
        stepName(): string {
            return this.stepInfo?.name ?? '???';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'C-Trigger') return 'Fireshot Trigger';
            if (this.stepName === 'F-Trigger') return `Free Spin Trigger`;
            if (this.stepName === 'F-Retrigger') return `Free Spin Retrigger`;
            if (this.stepName === 'G-Trigger') return `GRAND Trigger`;
            if (this.isFireshotRespin) return 'Fireshot Respin';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },
        reels(): string[][] {
            return this.stepInfo?.reels ?? getReels();
        },
        canAddClearingStep(): boolean {
            return true;
        },
        canAddSpinStep(): boolean {
            return true;
        },
        canAddFreeSpinTriggerStep(): boolean {
            const lastStep = this.allSteps[this.allSteps.length - 1];
            return !lastStep?.fireshotCount
        },
        canAddFireshotTriggerStep(): boolean {
            const lastStep = this.allSteps[this.allSteps.length - 1];
            return !lastStep?.fireshotCount
        },
        canAddGrandTriggerStep(): boolean {
            return true;
        },
        labelForAddSpinStep(): string {
            if (this.stepInfo?.fireshotCount) return 'Respin';
            if (this.stepInfo?.freeSpinCount) return 'Free Spin';
            return 'Base Spin';
        },
        labelForAddFreeSpinTriggerStep(): string {
            const lastStep = this.allSteps[this.allSteps.length - 1];
            return `${lastStep?.freeSpinCount ? 'Ret' : 'T'}rigger Free Spins`;
        },
        isFireshotTrigger(): boolean {
            return this.stepInfo?.fireshotPhase === 'START';
        },
        isFireshotRespin(): boolean {
            return !!this.stepInfo?.fireshotPhase && this.stepInfo.fireshotPhase !== 'START';
        },
        fireshotRespinsRemaining(): number | '???' {
            const count = this.stepInfo?.fireshotCount ?? 0;
            return count === Infinity ? '???' : count;
        },
        showCoinMode(): boolean {
            return this.stepName === 'C-Trigger' || this.stepName === 'G-Trigger';
        },
        coinPrizeLevels(): Array<{value: unknown, label: string}> {
            const keys = Object.keys(modelDefinition.fireshot.prizeWeights.NORMAL_COIN.initial);
            return keys.map(k => ({
                value: isNaN(Number(k)) ? k : Number(k),
                label: `${this.coinMode === 'SPECIAL_COIN' ? '💎' : '🟡'} ${k}`,
            }));
        },
        isFreeSpin(): boolean {
            return !!this.stepInfo?.freeSpinPhase && this.stepInfo.freeSpinPhase !== 'START';
        },
        freeSpinsRemaining(): number | '???' {
            const count = this.stepInfo?.freeSpinCount ?? 0;
            return count === Infinity ? '???' : count;
        },
        showWheelIndexes(): boolean {
            return this.stepInfo?.freeSpinPhase === 'START';
        },
        wheel1Options(): Array<{value: unknown, label: string}> {
            return modelDefinition.freeSpins.wheel1.map((n, i) => ({value: i, label: `[${i}] ${n} free spins`}));
        },
        wheel2Options(): Array<{value: unknown, label: string}> {
            return modelDefinition.freeSpins.wheel2.map((n, i) => ({value: i, label: `[${i}] ${n} PIC1s added`}));
        },
    },

    methods: {
        addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
        addSpinStep: () =>bus.$emit(events.ADD_STEP, createSpinStep()),
        addFreeSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
        addFireshotTriggerStep: () => bus.$emit(events.ADD_STEP, createFireshotTriggerStep()),
        addGrandTriggerStep: () => bus.$emit(events.ADD_STEP, createGrandTriggerStep()),
        isCoin({row, col}: {row: number, col: number}): boolean {
            return !!this.stepInfo?.cumulativeCoinPrizes?.[col]?.[row];
        },
        canSetOrUnsetCoin({row, col}: {row: number, col: number}): boolean {
            if (!this.isFireshotRespin) return false;
            const prevStepInfo = this.allSteps[this.index - 1];
            return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] == null;
        },
        canSetCoinPrize({row, col}: {row: number, col: number}): boolean {
            if (this.isFireshotTrigger) {
                return this.stepInfo?.cumulativeCoinPrizes?.[col]?.[row] != null;
            }
            else if (this.isFireshotRespin) {
                const prevStepInfo = this.allSteps[this.index - 1];
                return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] == null;
            }
            else {
                return false;
            }
        },
        getCoinPrize({row, col}: {row: number, col: number}): string {
            return (this.stepInfo?.cumulativeCoinPrizes?.[col]?.[row] ?? 'null').toString();
        },
        setCoinPrize({row, col, value}: {row: number, col: number, value: string}) {
            const cp = toCoinPrize(value);
            this.coinPrizes = [0, 1, 2, 3, 4, 5].map(c => {
                return [0, 1, 2, 3].map(r => {
                    return c === col && r === row ? cp : this.coinPrizes?.[c]?.[r] ?? null;
                });
            });
        },

        updateStepJsonAndSave() {
            if (this.isSaved) return;
            const json = JSON.parse(JSON.stringify({
                name: this.stepName,
                gameState: this.gameState,
                reelStripPositions: this.reelStripPositions,
                wheel1Index: this.wheel1Index,
                wheel2Index: this.wheel2Index,
                coinMode: this.coinMode,
                coinPrizes: this.coinPrizes,
            }));
            if (!this.step) return;
            this.step.json = json;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    },

    watch: {
        step(newVal) {
            this.isSaved = true;
            const json: StepJson = newVal?.json || {};
            this.index = newVal?.order_number ?? 0;
            this.gameState = json.gameState;
            this.reelStripPositions = json.reelStripPositions;
            this.wheel1Index = json.wheel1Index;
            this.wheel2Index = json.wheel2Index;
            this.coinMode = json.coinMode;
            this.coinPrizes = json.coinPrizes ?? [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
            setTimeout(() => this.isSaved = false, 500);
            if (this.allSteps.every(s => s.json !== newVal?.json)) bus.$emit(events.UPDATE_STEPS_DB, this.step);
        },
        name() { this.updateStepJsonAndSave(); },
        gameState() { this.updateStepJsonAndSave(); },
        reelStripPositions() { this.updateStepJsonAndSave(); },
        wheel1Index() { this.updateStepJsonAndSave(); },
        wheel2Index() { this.updateStepJsonAndSave(); },
        coinMode() { this.updateStepJsonAndSave(); },
        coinPrizes() { this.updateStepJsonAndSave(); },
    },

    mounted() {
        bus.$on(events.UPDATE_STEPS, scenario => {
            this.allSteps = computeStepInfo(scenario?.steps ?? []);
        });
        bus.$on(events.RECREATE_SCENARIO, steps => {
            this.allSteps = computeStepInfo(steps ?? []);
        });
    },
});

interface StepInfo {
    index: number;
    json: StepJson;
    name: string;
    reels: string[][];
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    fireshotPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    fireshotCount?: number;
    cumulativeCoinPrizes: (CoinPrize | null)[][];
    cumulativeCoinCount: number;
}

interface StepJson {
    name: string;
    gameState: GameState;
    reelStripPositions: number[];
    wheel1Index?: number;
    wheel2Index?: number;
    coinMode?: CoinMode;
    coinPrizes?: (CoinPrize | null)[][];
}

type GameState = 'clear' | 'continue';
type CoinMode = 'NORMAL_COIN' | 'SPECIAL_COIN';
type CoinPrize = 8 | 12 | 20 | 30 | 40 | 60 | 100 | 200 | 300 | 'MINI' | 'MINOR' | 'MAJOR';

function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        gameState: 'clear',
        reelStripPositions: [0, 0, 0, 0, 0, 0],
    };
}

function createSpinStep(): StepJson {
    return {
        name: 'Step',
        gameState: 'continue',
        reelStripPositions: [0, 0, 0, 0, 0, 0],
        coinPrizes: [],
    };
}

function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        gameState: 'continue',
        reelStripPositions: [67, 73, 74, 23, 132, 88],
        coinPrizes: [],
    };
}

function createFireshotTriggerStep(): StepJson {
    return {
        name: 'Step',
        gameState: 'continue',
        reelStripPositions: [15, 17, 16, 12, 12, 4],
        coinPrizes: [
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
        ],
    };
}

function createGrandTriggerStep(): StepJson {
    return {
        name: 'Step',
        gameState: 'continue',
        reelStripPositions: [88, 92, 90, 83, 97, 84],
        coinPrizes: [
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
            [20, 20, 20, 20],
        ],
    };
}

function toCoinPrize(value: string): CoinPrize | null {
    if (value === 'null') return null;
    const n = Number(value);
    return isNaN(n) ? value : n as any;
}

function computeStepInfo(steps: Array<{json: StepJson}>): StepInfo[] {
    let name = '';
    let reels = getReels();
    let isFireshotRespin = false;
    let fireshotPhase: StepInfo['fireshotPhase'] | undefined;
    let fireshotCount = 0;
    let coinMode: CoinMode | undefined;
    let coinPrizes: (CoinPrize | null)[][] = [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
    let cumulativeCoinPrizes: (CoinPrize | null)[][] = [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
    let cumulativeCoinCount = 0;
    let isFreeSpin = false;
    let freeSpinPhase: StepInfo['freeSpinPhase'] | undefined;
    let freeSpinCount = 0;
    let wheel1Index: number | undefined;
    let wheel2Index: number | undefined;
    const stepInfos: StepInfo[] = [];
    for (let index = 0; index < steps.length; ++index) {
        let json = steps[index].json;
        if (json.name === 'Clear') {
            // Handle clearing steps by clearing all state
            name = json.name;
            reels = getReels();
            isFireshotRespin = false;
            fireshotPhase = undefined;
            fireshotCount = 0;
            isFreeSpin = false;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
            wheel1Index = undefined;
            wheel2Index = undefined;
            coinMode = undefined;
            coinPrizes = [];
            cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
            cumulativeCoinCount = 0;
        }
        else {
            isFireshotRespin = fireshotCount > 0;
            isFreeSpin = freeSpinCount > 0;
            const coinCells = findCells(reels, json.reelStripPositions, modelDefinition.coinSymbol);
            const scatCells = findCells(reels, json.reelStripPositions, modelDefinition.scatterSymbol);
            const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot
            const isFreeSpinTrigger = scatCells.length >= modelDefinition.countToTriggerFreeSpin;
            fireshotPhase = undefined;
            freeSpinPhase = undefined;

            // Update state for respins
            if (isFireshotRespin) {
                const prevCoinCount = cumulativeCoinCount;
                coinPrizes = json.coinPrizes ?? [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
                coinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => cumulativeCoinPrizes?.[col]?.[row] ? null : coinPrizes?.[col]?.[row]) ?? null);
                cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => cumulativeCoinPrizes?.[col]?.[row] ?? coinPrizes?.[col]?.[row]));
                cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
                name = 'Respin';
                fireshotCount = cumulativeCoinCount === 24 ? 0 : cumulativeCoinCount > prevCoinCount ? modelDefinition.fireshotRespinCount : fireshotCount - 1;
                fireshotPhase = fireshotCount > 0 ? 'IN_PROGRESS' : 'END';
            }

            // Update state for base and free spins
            else {
                name = 'Spin';
                if (isFreeSpin) {
                    name = 'Free spin';
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END'
                }
                else {
                    wheel1Index = wheel2Index = undefined;
                }

                if (isFreeSpinTrigger) {
                    name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                    wheel1Index = json.wheel1Index ?? 0;
                    wheel2Index = json.wheel2Index ?? 0;
                    const addedSpins = modelDefinition.freeSpins.wheel1[wheel1Index];
                    freeSpinCount += addedSpins;
                    freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
                }

                if (isFireshotTrigger) {
                    coinMode = json.coinMode ?? 'NORMAL_COIN';
                    coinPrizes = [0, 1, 2, 3, 4, 5].map(_ => [null, null, null, null]);
                    for (const [row, col] of coinCells) coinPrizes[col][row] = json.coinPrizes?.[col]?.[row] ?? 20;
                    cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5].map(col => [0, 1, 2, 3].map(row => coinPrizes?.[col]?.[row]));
                    cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
                    name = cumulativeCoinCount < 24 ? 'C-Trigger' : 'G-Trigger';
                    fireshotCount = cumulativeCoinCount < 24 ? modelDefinition.fireshotRespinCount : 0;
                    fireshotPhase = fireshotCount > 0 ? 'START' : 'END';
                }
                else {
                    coinMode = undefined;
                    coinPrizes = cumulativeCoinPrizes = [];
                    cumulativeCoinCount = 0;
                }
            }
        }
        reels = getReels(wheel2Index);
        stepInfos.push({
            index, json, name, reels, fireshotPhase, fireshotCount,
            cumulativeCoinPrizes, cumulativeCoinCount, freeSpinPhase, freeSpinCount
        });

        json = {...json, name, wheel1Index, wheel2Index, coinMode, coinPrizes};
        if (JSON.stringify(json) !== JSON.stringify(steps[index].json)) {
            steps[index].json = json;
            bus.$emit(events.UPDATE_STEPS_DB, steps[index]);
        }
    }
    return stepInfos;
}

function findCells(reels: string[][], reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}
