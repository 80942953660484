import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import hands from "./components/hands.vue";
import cards from "./components/cards.vue";
import dice from "./components/dice.vue";

@Component({
    components: {
        hands: hands,
        dice: dice,
        cards: cards
    },
    data: function() {
        return {
            allCards: ["Random", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "S9", "S10", "SJ", "SQ", "SK", "SA", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "C9", "C10", "CJ", "CQ", "CK", "CA", "H2", "H3", "H4", "H5", "H6", "H7", "H8", "H9", "H10", "HJ", "HQ", "HK", "HA", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "D9", "D10", "DJ", "DQ", "DK", "DA"]

        }
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isOpen = false;
    public addCards = false;
    public handValue = [["Random"], ["Random"]];
    public handsIds = new Array(5);
    public dealerValue = ["Random", "Random"];
    public dealerRowPosition = 0;
    public cards = null;
    public cardValue = new Array();
    public cardsNo = 1;
    public diceValue = [["Random"], ["Random"], ["Random"]];

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = step.json.internalName == "Base step" ? true : false;
        // this.isOpen = true;
        this.addCards = step.json.internalName == "Add cards step" ? true : false;
        this.show = true;
        if(this.isOpen) {
            this.addCards = false;
            this.dealerValue = ["Random", "Random"];
            this.cardValue = ["Random"];
            this.diceValue = [["Random"], ["Random"],["Random"]];
            this.step.json.rows = step.json.rows;

            this.handsIds = step.json.handsIds ? step.json.handsIds : [0, 1, 2, 3, 4];
            this.dealerRowPosition = this.handsIds.length;
            this.cardsNo = step.json.cardsNo ? step.json.cardsNo : 1;
            for(let i=0; i< 2; i++) {
                this.handValue[i] = new Array(this.handsIds.length);
                for(let j=0; j < this.handsIds.length; j++) {
                    this.handValue[i][this.handsIds[j]] = step.json.rows[i][j] ? step.json.rows[i][j] : "Random";
                }
                this.dealerValue[i] = step.json.rows[i][this.dealerRowPosition] ? step.json.rows[i][this.dealerRowPosition] : "Random";
            }
            for(let s=0; s < this.cardsNo; s++) {
                let pos = step.json.handsIds.length + 1 + s;
                this.cardValue[s] = step.json.rows[1][pos] ? step.json.rows[1][pos] : "Random";
            }

            for(let m = 0; m < 3; m++) {
                this.diceValue[m] = new Array(this.handsIds.length);
                for(let n = 0; n < this.handsIds.length; n++) {
                    this.diceValue[m][this.handsIds[n]] = step.json.diceValue[m][n] ? step.json.diceValue[m][n] : "Random";
                }
            }
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addCardsStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getCardsStep());
    }

    public updateHandsNo(hands) {
        const handsNumber = new Array();
        hands.forEach((value) =>{handsNumber.push(parseInt(value))});
        let oldHandsNo = this.step.json.handsIds;
        const dealerCard1 = this.step.json.rows[0][oldHandsNo.length];
        const dealerCard2 = this.step.json.rows[1][oldHandsNo.length];

        this.step.json.handsIds = handsNumber;
        this.handsIds = this.step.json.handsIds;
        let handsNo = this.step.json.handsIds.length;
        let diffRows = handsNo - oldHandsNo.length;



        if(this.handsIds.length > 1 && diffRows > 0) {
            for(let k= 0; k < oldHandsNo.length; k++) {
                if (this.handsIds.indexOf(oldHandsNo[k]) < 0) {
                    this.step.json.rows[0].splice(k, 1);
                    this.step.json.rows[1].splice(k, 1);
                    this.step.json.diceValue[0].splice(k, 1);
                    this.step.json.diceValue[1].splice(k, 1);
                    this.step.json.diceValue[2].splice(k, 1);
                }
            }

            for(let k= 0; k < this.handsIds.length; k++) {
                if(oldHandsNo.indexOf(this.handsIds[k]) < 0) {
                    this.step.json.rows[0].splice(k, 0, "Random");
                    this.step.json.rows[1].splice(k, 0, "Random");
                    this.step.json.diceValue[0].splice(k, 0, "Random");
                    this.step.json.diceValue[1].splice(k, 0, "Random");
                    this.step.json.diceValue[2].splice(k, 0, "Random");
                }
            }
        } else {

            this.step.json.rows[0] = this.step.json.rows[0].slice(0, this.handsIds.length + 1);
            this.step.json.rows[1] = this.step.json.rows[1].slice(0, this.handsIds.length + 1);

            this.step.json.rows[0][this.handsIds.length] = dealerCard1;
            this.step.json.rows[1][this.handsIds.length] = dealerCard2;

            this.step.json.rows[0] = this.step.json.rows[0].concat(new Array(cards.length));
            this.step.json.rows[1] = this.step.json.rows[1].concat(cards);

        }
        for(let i=0; i< 2; i++) {
            this.handValue[i] = new Array(this.handsIds.length);
            for(let j=0; j < this.handsIds.length; j++) {
                this.handValue[i][this.handsIds[j]] = this.step.json.rows[i][j] ? this.step.json.rows[i][j] : "Random";
            }
        }
        this.updateDealerHandValue(dealerCard1, 0);
        this.updateDealerHandValue(dealerCard2, 1);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateHandValue(card, index, handId) {
        this.step.json.rows[index][this.handsIds.indexOf(handId)] = card;
        this.handValue[index][this.handsIds.indexOf(handId)] = this.step.json.rows[index][this.handsIds.indexOf(handId)];
        bus.$emit(events.UPDATE_STEPS_DB, this.step);

    }

    public updateDiceValue (card, index, handId) {
        this.step.json.diceValue[index][this.handsIds.indexOf(handId)] = card;
        this.diceValue[index][handId] = this.step.json.diceValue[index][this.handsIds.indexOf(handId)];
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDealerHandValue(card, index) {
        let lastHand = this.handsIds.length;
        this.step.json.rows[index][lastHand] = card;
        this.dealerValue[index] = this.step.json.rows[index][lastHand];
        bus.$emit(events.UPDATE_STEPS_DB, this.step);

    }

    public updateCardHandValue(card, index) {
        this.step.json.rows[1][index + this.handsIds.length + 1 ] = card;
        this.step.json.rows[0][index + this.handsIds.length + 1 ] = null;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);

    }

    public updateCardsNo(direction) {
        if(direction){
            this.cardsNo += 1;
            this.step.json.cardsNo = this.cardsNo;
            this.step.json.rows[0][this.step.json.rows[0].length] = null;
            this.step.json.rows[1][this.step.json.rows[1].length] = "Random";
            this.cardValue[this.cardsNo] = "Random";
        } else {
            if(this.cardsNo > 1) {
                this.cardsNo -= 1;
                this.step.json.cardsNo = this.cardsNo;
                this.step.json.rows[0].pop();
                this.step.json.rows[1].pop();
                this.cardValue.pop();
            } else {
                alert("You can't delete the last card.")
            }
        }

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }



}
