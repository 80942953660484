/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "betMultiplier" : 50,
    "modelId" : "vgw017",
    "reelsLayout" : [ 3, 3, 3, 3, 3 ],
    "scatterSymbol" : "SCAT",
    "wildSymbol" : "WILD",
    "freeSpinMultiplier" : 2,
    "freeSpinCount" : 0,
    "winTable" : [ {
      "symbol" : "PIC1",
      "multipliers" : [ 1000, 100, 50 ]
    }, {
      "symbol" : "PIC2",
      "multipliers" : [ 800, 100, 30 ]
    }, {
      "symbol" : "PIC3",
      "multipliers" : [ 300, 50, 20 ]
    }, {
      "symbol" : "PIC4",
      "multipliers" : [ 300, 35, 15 ]
    }, {
      "symbol" : "A",
      "multipliers" : [ 200, 30, 10 ]
    }, {
      "symbol" : "K",
      "multipliers" : [ 200, 20, 10 ]
    }, {
      "symbol" : "Q",
      "multipliers" : [ 100, 15, 10 ]
    }, {
      "symbol" : "J",
      "multipliers" : [ 100, 15, 10 ]
    }, {
      "symbol" : "10",
      "multipliers" : [ 100, 15, 5 ]
    }, {
      "symbol" : "9",
      "multipliers" : [ 100, 10, 5 ]
    } ],
    "reels" : [ [ "PIC1", "A", "PIC2", "K", "PIC3", "Q", "PIC4", "J", "SCAT", "10", "PIC4", "9", "PIC1", "J", "PIC2", "K", "PIC3", "10", "PIC4", "Q", "SCAT", "A", "Q", "9", "PIC1", "J", "SCAT", "A", "PIC3", "K", "PIC1", "J", "A", "PIC2", "9", "A", "PIC3", "K", "Q", "9", "SCAT", "J", "PIC1", "A", "PIC3", "K", "SCAT", "J" ], [ "10", "PIC3", "9", "PIC2", "10", "WILD", "9", "PIC2", "10", "PIC4", "K", "PIC3", "10", "PIC2", "J", "PIC1", "10", "PIC4", "9", "WILD", "A", "PIC4", "Q", "PIC1", "J", "WILD", "Q", "PIC1", "10", "PIC4", "9", "WILD", "A", "PIC3", "Q", "PIC1", "A", "PIC4", "9", "WILD", "A", "PIC3", "Q", "PIC2", "J", "PIC1", "K", "PIC2" ], [ "PIC3", "A", "PIC4", "9", "PIC3", "K", "PIC1", "A", "SCAT", "10", "PIC4", "Q", "SCAT", "J", "9", "PIC1", "K", "10", "PIC2", "K", "9", "SCAT", "Q", "J", "PIC3", "Q", "10", "9", "PIC3", "J", "A", "PIC2", "J", "K", "PIC1", "9", "PIC2", "Q", "10", "J" ], [ "9", "J", "WILD", "9", "J", "PIC2", "A", "PIC4", "10", "PIC2", "A", "PIC3", "K", "PIC1", "Q", "PIC4", "J", "PIC2", "10", "WILD", "J", "PIC4", "9", "J", "PIC4", "A", "PIC1", "10", "WILD", "A", "PIC3", "K", "PIC1", "Q", "PIC3", "K", "PIC2", "10", "PIC3", "A", "WILD", "K", "PIC4", "10", "A", "J", "PIC1", "K" ], [ "PIC1", "9", "PIC3", "A", "PIC1", "J", "PIC3", "Q", "SCAT", "K", "PIC2", "A", "PIC4", "J", "PIC3", "10", "PIC4", "A", "SCAT", "9", "PIC3", "A", "PIC4", "J", "9", "K", "PIC1", "9", "PIC2", "A", "PIC3", "J", "PIC1", "K", "PIC4", "9", "PIC2", "A", "PIC3", "10", "PIC2", "Q", "SCAT", "K", "PIC2" ] ],
    "defaultSelectLine" : 0,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values" : [ 1, 2, 5, 10, 50 ],
    "coinType1_min_bet" : 250,
    "coinType1_max_bet" : 100000,
    "coinType1_spin_max_bet" : 5000000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 50,
    "coinType4_spin_max_bet" : 2500,
    "winLevels" : [ {
      "name" : "normal1",
      "threshold" : 5
    }, {
      "name" : "normal2",
      "threshold" : 15
    }, {
      "name" : "normal3",
      "threshold" : 25
    }, {
      "name" : "bigwin",
      "threshold" : 50
    }, {
      "name" : "megawin",
      "threshold" : 150
    }, {
      "name" : "epicwin",
      "threshold" : 2147483647
    } ],
    "freeSpinTrigger" : "SCAT",
    "countToTriggerFreeSpin" : 3,
    "wheels" : [ {
      "wheelElements" : {
        "GOLD" : [ "450000", "150000", "125000000", "75000", "625000", "425000", "10", "325000", "1000000", "175000", "2000000", "300000", "750000", "125000", "20", "400000", "1250000", "225000", "2500000", "100000", "500000", "275000", "15", "375000", "1500000", "200000", "12500000", "250000", "50000", "1750000" ],
        "SWEEPS" : [ "1800", "600", "500000", "300", "2500", "1700", "10", "1300", "4000", "700", "8000", "1200", "3000", "500", "20", "1600", "5000", "900", "10000", "400", "2000", "1100", "15", "1500", "6000", "800", "50000", "1000", "200", "7000" ]
      },
      "freeSpinIndexes" : {
        "GOLD" : [ 6, 14, 22 ],
        "SWEEPS" : [ 6, 14, 22 ]
      }
    }, {
      "wheelElements" : {
        "GOLD" : [ "225000", "75000", "125000000", "37500", "312500", "212500", "10", "162500", "50000", "87500", "1000000", "150000", "375000", "62500", "20", "200000", "625000", "112500", "2500000", "56250", "250000", "137500", "15", "187500", "750000", "100000", "12500000", "125000", "25000", "875000" ],
        "SWEEPS" : [ "900", "300", "500000", "150", "1250", "850", "10", "650", "200", "350", "4000", "600", "1500", "250", "20", "800", "2500", "450", "10000", "225", "1000", "550", "15", "750", "3000", "400", "50000", "500", "100", "3500" ]
      },
      "freeSpinIndexes" : {
        "GOLD" : [ 6, 14, 22 ],
        "SWEEPS" : [ 6, 14, 22 ]
      }
    }, {
      "wheelElements" : {
        "GOLD" : [ "475000", "75000", "125000000", "87500", "625000", "450000", "10", "237500", "1000000", "150000", "2000000", "275000", "750000", "125000", "20", "550000", "1250000", "225000", "2500000", "200000", "500000", "300000", "15", "375000", "1500000", "175000", "12500000", "250000", "70000", "1750000" ],
        "SWEEPS" : [ "1900", "300", "500000", "350", "2500", "1800", "10", "950", "4000", "600", "8000", "1100", "3000", "500", "20", "2200", "5000", "900", "10000", "800", "2000", "1200", "15", "1500", "6000", "700", "50000", "1000", "280", "7000" ]
      },
      "freeSpinIndexes" : {
        "GOLD" : [ 6, 14, 22 ],
        "SWEEPS" : [ 6, 14, 22 ]
      }
    } ],
    "freeSpinWonTotalBetMultiplier" : 2,
    "currency_symbol" : "$"
  },
  "modelId" : "vgw017",
  "gameSessionId" : 1,
  "reelStripPositions" : [ 28, 6, 3, 20, 10 ],
  "slotWindow" : [ [ "PIC3", "K", "PIC1" ], [ "9", "PIC2", "10" ], [ "9", "PIC3", "K" ], [ "J", "PIC4", "9" ], [ "PIC2", "A", "PIC4" ] ],
  "gold_balance" : 2008650000,
  "sweeps_balance" : 206529343,
  "sweeps_deposit_balance" : 200001395,
  "sweeps_promo_balance" : 0,
  "sweeps_win_balance" : 6527948,
  "sweeps_available_balance" : 6527948,
  "serverTime" : "2018-11-05 05:56:10"
}
