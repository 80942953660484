import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import selectCarrotMultiplier from "./components/selectCarrotMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        selectCarrotMultiplier: selectCarrotMultiplier
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public carrotsNumber = 0;
    public isOpen = false;
    public carrotsSelectedMultipliers = new Array(8).fill(0);

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        if (step.json.name == "Bonus Trigger") {
            this.isOpen = true;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.reelsNo = 5;
        this.isOpen = false;
        trace("reels mounted");
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }

    public addFeatureStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }

    public addFSThreeStep() {
        const state = State.state;
        this.reelsNo = 4;
        bus.$emit(events.ADD_STEP, state.getFSThreeStep());
    }

    public addFSFourStep() {
        const state = State.state;
        this.reelsNo = 4;
        bus.$emit(events.ADD_STEP, state.getFSFourStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGameStep() );
    }

    public addBonusTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBonusTriggerFromBaseGame() );
    }

    public addFeatureRetrigger() {
        const state = State.state;
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep() );
    }

    public updateBonusGameCarrotsNumber(carrotsNumber) {
        this.step.json.carrotsNumber = carrotsNumber;

        // Reinitialize the carrots multipliers selectors.
        this.step.json.carrotsSelectedMultipliers = new Array(parseInt(carrotsNumber)).fill(0);
        this.carrotsSelectedMultipliers = this.step.json.carrotsSelectedMultipliers;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusGameCarrotsMultipliers(event) {
        if (this.step.json.carrotsSelectedMultipliers == null) {
            this.step.json.carrotsSelectedMultipliers = new Array(this.step.json.carrotsNumber).fill(0);
        }

        this.step.json.carrotsSelectedMultipliers[event.target.id - 1] = parseInt(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.carrotsNumber != null) {
            this.carrotsNumber = this.step.json.carrotsNumber;
        } else {
            this.carrotsNumber = 0;
        }

        if (this.step.json.carrotsSelectedMultipliers != null) {
            this.carrotsSelectedMultipliers = this.step.json.carrotsSelectedMultipliers;
        } else {
            this.carrotsSelectedMultipliers = new Array(this.carrotsNumber).fill(0);
        }
    }
}
