export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  topReelStripPosition: number;
  multiplier?: number;
  endOfSpinOrCascadeAddedWilds?: number[][];
  initialSpinAddedWilds?: number[][];
  coinPrizes?: CoinPrize[][];
  multiplierPrizes?: MultiplierPrize[];
  reverseMultiplierPrizes?: MultiplierPrize[];
  shakyShaky?: { feature: boolean; bigWin: boolean };
}

export type CoinPrize = number | "MINI" | "MINOR" | "MAJOR" | "MULTIPLIER";
export type MultiplierPrize = {
  multiplierCell: number[];
  appliedCells: {
    cell: number[];
    multiplier: number;
  }[];
};
export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [22, 94, 44, 49, 114, 4],
    topReelStripPosition: 104,
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Spin",
    originatorId,
    gameState: "continue",
    reelStripPositions: [59, 60, 60, 60, 60, 60],
    topReelStripPosition: 0,
  };
}

export function createFreeSpinTriggerStep(): StepJson {
  return {
    name: "F-Trigger",
    originatorId,
    gameState: "continue",
    reelStripPositions: [60, 94, 100, 76, 60, 60],
    topReelStripPosition: 0,
  };
}

export function createHoldAndSpinTriggerStep(): StepJson {
  return {
    name: "HS-Trigger",
    originatorId,
    gameState: "continue",
    reelStripPositions: [62, 83, 96, 60, 60, 60],
    topReelStripPosition: 86,
  };
}

export const originatorId = new Date().getTime();
