/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length, 
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // prepareDB rows
        getRowsFromIndexes:(stepJson, reelStrips) => { 
            const rows = rowsFromIndexes(reelStrips, stepJson.reelStripPositions, 5, 4);
            rows[3][0] = "";
            rows[3][4] = "";
            stepJson.jackpotType = stepJson.jackpotType || "GRAND";
            if (State.state.isStepAJackpot(stepJson)) {
                range(4).map(i => {
                    rows[i][1] = stepJson.jackpotType;
                    rows[i][2] = stepJson.jackpotType;
                    rows[i][3] = stepJson.jackpotType;
                });
            } 
            return rows;
        },
            
        isStepAJackpot: (stepJson) => { 
            let reelStripPositions = stepJson.reelStripPositions; 
            return reelStripPositions[1] === 72
            && reelStripPositions[2] === 72
            && reelStripPositions[3] === 72;
        },
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => State.state.createStep([65, 0, 0, 0, 44], false, 0, "", "F-Trigger") ,
        getFeatureStep: () => State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([64, 0, 0, 0, 48], true, 0, "", "F-Ret") ,
    };
}
