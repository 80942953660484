/* Copy paste from chu-server/src/main/resources/game/slots/vgw025/vgw025.json */
export const m93: any = {
    "modelId" : "vgw025",
    "reelsLayout" : [ 3, 3, 3, 3, 3 ],
    "scatterSymbol" : "SC",
    "wildSymbol" : "WILD",
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 10,
    "winTable" : [ {
    "symbol" : "pic1",
    "multipliers" : [ 200, 25, 10 ]
    }, {
    "symbol" : "pic2",
    "multipliers" : [ 200, 25, 10 ]
    }, {
    "symbol" : "pic3",
    "multipliers" : [ 150, 20, 8 ]
    }, {
    "symbol" : "pic4",
    "multipliers" : [ 100, 15, 8 ]
    }, {
    "symbol" : "pic5",
    "multipliers" : [ 100, 15, 8 ]
    }, {
    "symbol" : "Heart",
    "multipliers" : [ 50, 12, 5 ]
    }, {
    "symbol" : "Diamond",
    "multipliers" : [ 50, 12, 5 ]
    }, {
    "symbol" : "Spade",
    "multipliers" : [ 50, 12, 5 ]
    }, {
    "symbol" : "Club",
    "multipliers" : [ 50, 12, 5 ]
    }, {
    "symbol" : "SC",
    "multipliers" : [ 100, 5, 3, 1 ]
    }, {
    "symbol" : "WILD",
    "multipliers" : [ 500, 40, 10 ]
    } ],
    "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ], [ 0, 0, 2, 0, 0 ], [ 2, 2, 0, 2, 2 ], [ 0, 0, 2, 2, 2 ], [ 2, 2, 0, 0, 0 ], [ 2, 2, 2, 2, 1 ], [ 0, 0, 0, 0, 1 ], [ 2, 1, 0, 0, 0 ], [ 0, 2, 2, 2, 2 ], [ 1, 1, 1, 1, 0 ], [ 1, 1, 1, 1, 2 ], [ 1, 1, 1, 0, 2 ], [ 1, 1, 1, 2, 0 ], [ 0, 0, 0, 2, 1 ], [ 2, 2, 2, 0, 1 ], [ 2, 2, 2, 1, 0 ], [ 0, 0, 0, 1, 2 ], [ 2, 2, 2, 0, 2 ], [ 0, 0, 0, 2, 0 ], [ 1, 1, 0, 2, 1 ], [ 1, 1, 2, 0, 1 ], [ 1, 2, 2, 0, 2 ], [ 0, 2, 2, 0, 0 ], [ 2, 0, 2, 2, 1 ], [ 0, 2, 0, 0, 1 ], [ 2, 2, 1, 2, 1 ] ],
    "reels" : [ [ "pic2", "Spade", "SC", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "WILD", "WILD", "WILD", "Heart", "pic4", "Diamond", "pic1", "pic5", "pic2", "Diamond", "pic1", "Spade", "Diamond", "pic1", "Spade", "Coin", "Coin", "Coin", "pic2", "Spade", "Club", "pic3", "Heart", "pic4", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "Diamond", "pic5", "Heart" ], [ "pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "pic1", "pic3", "Club", "Diamond", "pic4", "pic2", "pic5", "SC", "Heart", "Spade", "pic3", "Diamond", "WILD", "WILD", "WILD", "Diamond", "pic4", "Coin", "Coin", "Coin", "pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "Spade", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "Spade", "pic3", "Diamond", "SC" ], [ "Spade", "Club", "pic4", "Heart", "pic2", "Heart", "pic3", "Spade", "pic1", "Heart", "pic3", "Spade", "pic1", "Spade", "Diamond", "SC", "Spade", "Heart", "WILD", "WILD", "WILD", "Diamond", "pic3", "Spade", "pic1", "Heart", "pic3", "Spade", "pic1", "Heart", "Coin", "Coin", "Coin", "Spade", "Club", "pic4", "Heart", "pic2", "pic5", "pic3", "Spade", "pic1", "Heart", "pic3", "Spade", "pic1", "Spade", "Diamond", "pic1" ], [ "Diamond", "pic5", "pic1", "Spade", "Club", "WILD", "WILD", "WILD", "Club", "pic4", "pic2", "Spade", "SC", "Diamond", "Club", "pic4", "Spade", "Diamond", "pic1", "pic2", "Club", "pic4", "pic2", "Coin", "Coin", "Coin", "pic3", "pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "pic1", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "Spade", "pic3", "Diamond", "SC" ], [ "SC", "Heart", "pic4", "Diamond", "pic1", "Heart", "Spade", "pic2", "Diamond", "pic3", "pic1", "Heart", "WILD", "WILD", "WILD", "WILD", "pic1", "Club", "pic4", "Diamond", "pic2", "pic4", "Club", "pic3", "pic4", "pic5", "pic3", "Heart", "pic2", "Spade", "pic3", "pic2", "Coin", "Coin", "Coin" ] ],
    "featureReels" : [ [ "pic2", "pic4", "SC", "pic1", "pic3", "pic2", "pic4", "pic2", "pic3", "WILD", "WILD", "WILD", "pic2", "pic4", "pic3", "pic1", "pic5", "pic2", "pic3", "pic1", "pic4", "pic3", "pic1", "pic4", "Coin", "Coin", "Coin", "pic2", "pic4", "pic1", "pic3", "pic2", "pic4", "pic1", "pic3", "pic2", "pic4", "pic5", "pic3", "pic2", "pic4", "pic3", "pic1", "pic2", "pic2", "pic3", "pic5", "pic2" ], [ "pic2", "pic3", "pic1", "pic4", "pic3", "pic1", "pic4", "pic1", "pic3", "pic1", "pic3", "pic4", "pic2", "pic5", "SC", "pic2", "pic4", "pic3", "pic3", "WILD", "WILD", "WILD", "pic3", "pic4", "Coin", "Coin", "Coin", "pic2", "pic3", "pic1", "pic4", "pic3", "pic1", "pic4", "pic4", "pic3", "pic1", "pic5", "pic4", "pic2", "pic2", "pic4", "pic3", "pic3", "SC" ], [ "pic4", "pic1", "pic4", "pic2", "pic2", "pic2", "pic3", "pic4", "pic1", "pic2", "pic3", "pic4", "pic1", "pic4", "pic3", "SC", "pic4", "pic2", "WILD", "WILD", "WILD", "pic3", "pic3", "pic4", "pic1", "pic2", "pic3", "pic4", "pic1", "pic2", "Coin", "Coin", "Coin", "pic4", "pic1", "pic4", "pic2", "pic5", "pic5", "pic3", "pic4", "pic1", "pic2", "pic3", "pic4", "pic1", "pic4", "pic3", "pic1" ], [ "pic3", "pic5", "pic1", "pic4", "pic1", "WILD", "WILD", "WILD", "pic1", "pic4", "pic2", "pic4", "SC", "pic3", "pic1", "pic4", "pic4", "pic3", "pic1", "pic2", "pic1", "pic4", "pic2", "Coin", "Coin", "Coin", "pic3", "pic2", "pic3", "pic1", "pic4", "pic3", "pic1", "pic4", "pic1", "pic3", "pic1", "pic5", "pic4", "pic2", "pic2", "pic4", "pic3", "pic3", "SC" ], [ "SC", "pic2", "pic4", "pic3", "pic1", "pic2", "pic4", "pic2", "pic3", "pic3", "pic1", "pic2", "WILD", "WILD", "WILD", "WILD", "pic1", "pic1", "pic4", "pic3", "pic2", "pic4", "pic1", "pic3", "pic4", "pic5", "pic3", "pic2", "pic5", "pic4", "pic3", "pic2", "Coin", "Coin", "Coin" ] ],
    "defaultSelectLine" : 0,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values" : [ 1, 2, 5, 10, 50 ],
    "coinType1_min_bet" : 250,
    "coinType1_max_bet" : 100000,
    "coinType1_spin_max_bet" : 5000000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 500,
    "coinType4_spin_max_bet" : 2500,
    "winLevels" : [ {
    "name" : "normal1",
    "threshold" : 5
    }, {
    "name" : "normal2",
    "threshold" : 15
    }, {
    "name" : "normal3",
    "threshold" : 25
    }, {
    "name" : "bigwin",
    "threshold" : 50
    }, {
    "name" : "megawin",
    "threshold" : 150
    }, {
    "name" : "epicwin",
    "threshold" : 2147483647
    } ],
    "freeSpinTrigger" : "SC",
    "countToTriggerFreeSpin" : 3,
    "coinCountToTriggerFeature" : 6,
    "coinSymbol" : "Coin",
    "jackpotFeatureLoopCount" : 3,
    "coinPricesSweeps" : {
    "jackpotTypes" : [ "MAJOR", "MINOR", "MINI" ],
    "prices" : [ 0, 100, 20, 5, 4, 3, 2, 1 ],
    "weights" : {
        "0" : {
        "1" : [ 50, 18000, 62000, 130000, 200000, 390000, 570000, 472450 ],
        "2" : [ 100, 18000, 62000, 130000, 200000, 390000, 570000, 472400 ],
        "5" : [ 250, 18000, 62000, 30000, 300000, 390000, 570000, 472250 ],
        "10" : [ 500, 18000, 62000, 30000, 200000, 490000, 570000, 472000 ],
        "50" : [ 2500, 18000, 62000, 30000, 100000, 100000, 1060000, 470000 ]
        }
    }
    },
    "coinPricesGold" : {
    "jackpotTypes" : [ "MAJOR", "MINOR", "MINI" ],
    "prices" : [ 0, 50, 10, 5, 4, 3, 2, 1 ],
    "weights" : {
        "0" : {
        "250" : [ 50, 18000, 62000, 30000, 200000, 390000, 440000, 572450 ],
        "2500" : [ 500, 18000, 62000, 30000, 200000, 490000, 340000, 572000 ],
        "10000" : [ 2000, 18000, 62000, 30000, 100000, 490000, 440000, 570500 ],
        "50000" : [ 10000, 18000, 62000, 30000, 100000, 190000, 540000, 762500 ],
        "100000" : [ 20000, 18000, 62000, 30000, 100000, 90000, 640000, 752500 ]
        }
    }
    },
    "initialJackpotAmount" : {
    "SWEEPS" : {
        "MAJOR" : 500000,
        "GRAND" : 10000000
    },
    "GOLD" : {
        "MAJOR" : 500000000,
        "GRAND" : 20000000000
    }
    },
    "maxBetMultiplier" : 50,
    "flavor" : "94",
    "currency_symbol" : "$"
}

export const m94 = Object.assign({}, m93, {
  "percentToJackpotPool": 1
});

export const m95 = Object.assign({}, m93, {
  "percentToJackpotPool": 1.5
});
