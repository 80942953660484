/* in order to support standalone, please insert the init response from the server here */
export default {
    messages: [],
    modelDefinition: {
        modelId: 'tf115',
        gameIds: ['popNPaysMore'],
        coinSizeMultiplier: 75,
        initReelsLayout: [4, 4, 4, 4, 4],
        reelsLayout: [8, 8, 8, 8, 8],
        noSymbol: 'NIL',
        wildSymbol: 'WLD',
        scatterSymbol: 'TRG2',
        freeSpinTrigger: 'TRG2',
        popTrigger: 'TRG1',
        popTriggerCount: 2,
        freeSpinsAwardedFeature: [15, 12, 8, 5],
        freeSpinsAwardedBase: [15, 12, 8],
        countToTriggerFreeSpins: 3,

        winTable: [
            {
                symbol: 'TRG2',
                multipliers: [1500, 750, 120, 0]
            },
            {
                symbol: 'PIC1',
                multipliers: [200, 100, 50, 20]
            },
            {
                symbol: 'PIC2',
                multipliers: [150, 75, 40, 10]
            },
            {
                symbol: 'PIC3',
                multipliers: [100, 50, 25]
            },
            {
                symbol: 'PIC4',
                multipliers: [100, 50, 25]
            },
            {
                symbol: 'A',
                multipliers: [75, 40, 20]
            },
            {
                symbol: 'K',
                multipliers: [75, 40, 20]
            },
            {
                symbol: 'Q',
                multipliers: [50, 25, 10]
            },
            {
                symbol: 'J',
                multipliers: [50, 25, 10]
            },
            {
                symbol: 'T',
                multipliers: [50, 15, 5]
            },
            {
                symbol: 'N',
                multipliers: [50, 15, 5]
            }
        ],

        // This is a ways game, so there are zero lines.
        playLines: [],

        // prettier-ignore
        reels: [
			["K", "J", "PIC1", "PIC1", "TRG1", "K", "PIC2", "K", "Q", "J", "PIC1", "K", "K", "Q", "J", "K", "Q", "TRG1", "J", "K", "K", "Q", "J", "PIC1", "K", "A", "Q", "J", "TRG2", "K", "TRG1", "Q", "PIC4", "J", "K", "PIC3", "Q", "TRG1", "J", "K", "J", "Q", "J", "J", "TRG1", "K", "PIC2", "Q", "K", "N", "T", "Q", "PIC4", "N", "Q", "J", "TRG1", "K", "PIC3", "A", "Q", "J", "TRG1", "K", "Q", "K", "K", "Q", "TRG1", "J", "K", "TRG2", "Q", "J", "J", "K", "Q", "K", "Q", "TRG1", "J", "J", "Q", "PIC1", "Q", "J"],
			["A", "A", "N", "PIC4", "TRG1", "TRG1", "J", "PIC2", "TRG1", "TRG1", "PIC2", "N", "PIC2", "K", "PIC2", "N", "TRG1", "Q", "PIC1", "Q", "K", "PIC4", "T", "TRG2", "A", "PIC4", "Q", "A", "PIC4", "T", "WLD", "A", "PIC4", "PIC4", "T", "A", "TRG1", "PIC4", "T", "A", "PIC4", "T", "PIC4", "T", "TRG2", "A", "T", "T", "PIC4", "A", "A", "T", "A", "PIC4", "T", "J", "A", "T", "PIC4", "T", "WLD", "A", "PIC4", "T", "PIC4", "A", "T", "A", "PIC2", "PIC1", "A", "T", "A", "T", "PIC4", "A", "T", "A", "T", "PIC3"],
			["J", "T", "N", "J", "N", "TRG1", "N", "TRG1", "T", "J", "TRG1", "T", "N", "N", "K", "T", "PIC3", "N", "TRG2", "PIC2", "PIC3", "N", "J", "T", "PIC3", "N", "N", "PIC3", "T", "N", "WLD", "T", "T", "PIC3", "N", "T", "N", "TRG1", "T", "J", "T", "J", "T", "N", "Q", "PIC3", "T", "PIC3", "T", "PIC3", "PIC3", "Q", "T", "T", "TRG1", "T", "WLD", "J", "N", "PIC3", "T", "PIC3", "PIC3", "TRG1", "T", "N", "T", "PIC3", "N", "T", "TRG2", "PIC3", "Q", "Q", "J", "N", "K", "PIC3", "N", "WLD", "PIC2", "N", "PIC3", "PIC1", "PIC4", "PIC3", "N", "A", "N", "J"],
			["A", "N", "T", "K", "T", "K", "Q", "T", "TRG1", "TRG1", "T", "K", "T", "Q", "TRG1", "TRG1", "T", "Q", "T", "N", "K", "N", "TRG2", "N", "N", "T", "N", "N", "Q", "T", "J", "PIC2", "PIC4", "PIC3", "PIC2", "N", "T", "PIC2", "TRG1", "TRG1", "PIC2", "PIC2", "N", "N", "T", "T", "N", "WLD", "T", "T", "N", "PIC2", "A", "T", "N", "N", "A", "J", "WLD", "N", "A", "A", "T", "N", "A", "WLD", "T", "A", "A", "T", "TRG1", "TRG1", "A", "A", "A", "T", "PIC1", "K", "PIC2", "Q"],
			["TRG1", "TRG1", "PIC3", "PIC4", "PIC2", "TRG1", "TRG1", "A", "PIC2", "TRG1", "TRG1", "PIC2", "PIC4", "TRG1", "TRG1", "PIC4", "A", "PIC3", "TRG1", "TRG1", "PIC2", "PIC2", "PIC4", "TRG2", "PIC2", "PIC2", "PIC3", "PIC4", "A", "PIC3", "A", "PIC4", "PIC3", "PIC3", "A", "A", "PIC3", "PIC4", "PIC3", "PIC2", "PIC3", "PIC4", "A", "TRG1", "TRG1", "PIC3", "PIC2", "PIC2", "PIC2", "PIC4", "PIC3", "PIC2", "PIC2", "PIC4", "PIC4", "PIC3", "PIC2", "TRG1", "TRG1", "PIC4", "T", "PIC3", "PIC2", "PIC4", "PIC4", "PIC3", "PIC2", "PIC4", "PIC3", "PIC2", "PIC4", "PIC4", "PIC3", "J", "PIC4", "N", "PIC3", "PIC2", "PIC4", "TRG2", "K", "PIC2", "PIC4", "A", "PIC3", "PIC4", "PIC4", "PIC1", "PIC4", "PIC2", "Q", "K"]
		],

        // prettier-ignore
        freeSpinReels: [
			['K', 'J', 'PIC1', 'PIC1', 'PIC4', 'TRG1', 'J', 'K', 'Q', 'J', 'TRG1', 'K', 'K', 'Q', 'J', 'K', 'Q', 'TRG1', 'J', 'K', 'K', 'Q', 'J', 'PIC1', 'K', 'TRG1', 'Q', 'J', 'Q', 'K', 'PIC2', 'TRG1', 'PIC4', 'J', 'K', 'J', 'Q', 'TRG1', 'J', 'K', 'J', 'K', 'J', 'J', 'Q', 'K', 'PIC2', 'Q', 'K', 'N', 'Q', 'TRG1', 'T', 'N', 'Q', 'J', 'Q', 'K', 'PIC3', 'A', 'Q', 'TRG1', 'PIC3', 'K', 'Q', 'J', 'K', 'Q', 'TRG1', 'J', 'K', 'TRG2', 'Q', 'J', 'PIC4', 'K', 'Q', 'K', 'Q', 'TRG1', 'J', 'J', 'Q', 'PIC1', 'Q', 'J'],
			['A', 'A', 'N', 'TRG1', 'A', 'J', 'PIC2', 'T', 'TRG1', 'A', 'PIC2', 'T', 'PIC2', 'K', 'PIC2', 'N', 'TRG1', 'Q', 'PIC1', 'Q', 'PIC4', 'PIC4', 'TRG1', 'TRG2', 'A', 'PIC2', 'Q', 'A', 'T', 'TRG1', 'WLD', 'A', 'PIC4', 'PIC4', 'T', 'A', 'A', 'PIC4', 'TRG1', 'TRG1', 'PIC4', 'T', 'PIC4', 'T', 'PIC4', 'T', 'TRG1', 'T', 'PIC4', 'A', 'T', 'T', 'PIC1', 'TRG1', 'T', 'T', 'A', 'T', 'PIC4', 'TRG1', 'WLD', 'A', 'PIC4', 'N', 'PIC4', 'A', 'TRG1', 'A', 'PIC2', 'PIC1', 'PIC4', 'T', 'A', 'T', 'PIC4', 'A', 'T', 'A', 'T', 'PIC3'], 
			['J', 'T', 'TRG1', 'J', 'N', 'TRG1', 'N', 'PIC2', 'T', 'J', 'N', 'T', 'TRG1', 'TRG1', 'N', 'T', 'PIC3', 'N', 'TRG2', 'PIC2', 'PIC3', 'TRG1', 'J', 'T', 'PIC3', 'TRG1', 'T', 'PIC3', 'T', 'N', 'WLD', 'T', 'T', 'PIC3', 'N', 'T', 'TRG1', 'TRG1', 'T', 'J', 'T', 'J', 'TRG1', 'T', 'Q', 'PIC3', 'T', 'PIC3', 'T', 'PIC3', 'PIC3', 'Q', 'T', 'T', 'TRG1', 'T', 'WLD', 'J', 'N', 'PIC3', 'T', 'PIC3', 'PIC3', 'TRG2', 'T', 'PIC4', 'T', 'PIC3', 'N', 'T', 'TRG1', 'PIC3', 'Q', 'Q', 'J', 'TRG1', 'K', 'PIC3', 'N', 'WLD', 'PIC2', 'N', 'PIC3', 'PIC1', 'PIC4', 'PIC3', 'N', 'A', 'N', 'J'], 
			['TRG1', 'TRG1', 'T', 'K', 'T', 'K', 'Q', 'T', 'TRG1', 'TRG1', 'T', 'K', 'T', 'Q', 'TRG1', 'TRG1', 'T', 'Q', 'T', 'N', 'K', 'N', 'TRG2', 'N', 'N', 'TRG1', 'N', 'N', 'Q', 'T', 'WLD', 'TRG1', 'PIC4', 'PIC3', 'PIC2', 'N', 'N', 'PIC2', 'TRG1', 'N', 'PIC2', 'PIC2', 'N', 'N', 'T', 'T', 'N', 'WLD', 'T', 'T', 'N', 'PIC2', 'A', 'T', 'TRG1', 'TRG1', 'A', 'J', 'WLD', 'N', 'A', 'A', 'T', 'N', 'A', 'WLD', 'T', 'A', 'A', 'T', 'A', 'TRG1', 'A', 'A', 'A', 'T', 'PIC1', 'K', 'PIC2', 'Q'], 
			['TRG1', 'TRG1', 'PIC3', 'PIC4', 'PIC2', 'PIC1', 'TRG1', 'A', 'PIC2', 'J', 'TRG1', 'TRG1', 'PIC4', 'TRG1', 'PIC2', 'PIC4', 'A', 'PIC3', 'TRG1', 'TRG1', 'PIC2', 'PIC2', 'PIC4', 'TRG1', 'PIC1', 'TRG1', 'PIC3', 'PIC4', 'A', 'PIC3', 'A', 'PIC4', 'PIC3', 'PIC3', 'A', 'A', 'PIC3', 'PIC4', 'PIC3', 'PIC2', 'PIC3', 'PIC4', 'A', 'K', 'TRG1', 'PIC3', 'PIC2', 'PIC2', 'PIC2', 'PIC4', 'PIC3', 'PIC2', 'PIC2', 'PIC4', 'PIC4', 'PIC3', 'PIC2', 'K', 'TRG1', 'PIC4', 'T', 'PIC3', 'PIC2', 'PIC4', 'PIC4', 'PIC3', 'PIC2', 'PIC4', 'PIC3', 'PIC2', 'PIC4', 'PIC4', 'PIC3', 'J', 'PIC4', 'N', 'PIC3', 'PIC2', 'PIC4', 'TRG2', 'TRG1', 'PIC2', 'PIC4', 'Q', 'PIC3', 'PIC4', 'PIC4', 'PIC1', 'PIC4', 'PIC2', 'Q', 'K']
		],

        coinType1Values: [100, 200, 500, 1000, 2000, 2500, 5000],
        coinType1_min_bet: 100,
        coinType1_max_bet: 5000,
        coinType1_spin_max_bet: 375_000,

        coinType4Values: [1, 2, 5, 10, 20, 25, 50],
        coinType4_min_bet: 1,
        coinType4_max_bet: 50,
        coinType4_spin_max_bet: 3750,

        winLevels: [
            { name: 'normal1', threshold: 5 },
            { name: 'normal2', threshold: 15 },
            { name: 'normal3', threshold: 20 },
            { name: 'bigwin', threshold: 35 },
            { name: 'megawin', threshold: 70 },
            { name: 'epicwin' }
        ]
    }
};
