import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { CharacterPic } from './character-wilds';

export const PickFeatureAward = Type.Union([
    Type.Literal('FREE_SPINS'),
    Type.Literal('PIC1'),
    Type.Literal('PIC2'),
    Type.Literal('PIC3'),
    Type.Literal('START'),
]);

export type PickFeatureAward = Static<typeof PickFeatureAward>;

const PickFreeSpins = Type.Object({
    type: Type.Literal('FREE_SPINS'),
    freeSpinsAwarded: Type.Integer(),
    pickId: Type.Integer(),
});

const PickToWild = Type.Object({
    type: Type.Literal('PIC_TO_WILD'),
    picToWildAwarded: CharacterPic,
    pickId: Type.Integer(),
});

const PickStart = Type.Object({
    type: Type.Literal('START'),
    pickId: Type.Integer(),
});

export const PickFeature = Type.Union([PickFreeSpins, PickToWild, PickStart]);
export type PickFeature = Static<typeof PickFeature>;
