var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "stolen-royals-selector" }, [
    _c("h2", [_vm._v("Stolen Royals Control Panel:")]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        {
          staticClass: "picks-type-text",
          attrs: { for: "stolen-royals-picks" },
        },
        [_vm._v("Select Stolen Royals:")]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.step.stolenRoyals.length < 3
        ? _c("span", { staticClass: "stolen-royals-invalid-text" }, [
            _vm._v("🚫 You must select 3 or more Stolen Royals 🚫"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "K", id: "K" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "K") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "K",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "K" } }, [_vm._v("K")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "Q", id: "Q" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "Q") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "Q",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "Q" } }, [_vm._v("Q")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "J", id: "J" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "J") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "J",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "J" } }, [_vm._v("J")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "A", id: "A" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "A") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "A",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "A" } }, [_vm._v("A")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "10", id: "10" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "10") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "10",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "10" } }, [_vm._v("10")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.step.stolenRoyals,
            expression: "step.stolenRoyals",
          },
        ],
        attrs: { type: "checkbox", value: "9", id: "9" },
        domProps: {
          checked: Array.isArray(_vm.step.stolenRoyals)
            ? _vm._i(_vm.step.stolenRoyals, "9") > -1
            : _vm.step.stolenRoyals,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.step.stolenRoyals,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "9",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.step, "stolenRoyals", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.step,
                    "stolenRoyals",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.step, "stolenRoyals", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "9" } }, [_vm._v("9")]),
      _vm._v(" "),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }