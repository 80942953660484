import { mathModel } from "../../../../v3";
import { Choices } from "../../choices";
import { generateNewSlotWindow } from "./generate-slot-window";
import { ReelSpinFeature } from "./reel-spin-features";
import { stealRoyals } from "./steal-royals";
import { doesWildPathPass } from "./does-wild-path-pass";
import { getWildPath } from "./get-wild-path";
import { generateCandyCaneCells } from "./generate-candy-cane-cells";
import { modelDefinition } from "../../config";
import { LineWin } from "../../../../v3/math-model/create-line-win-checker";

type CoinType = mathModel.CoinType;
type Cell = mathModel.Cell;
const filterSlotWindow = mathModel.filterSlotWindow;
const { scatterSymbol, bonusSymbol } = modelDefinition;

type ScatterWinChecker = (slotWindow: string[][]) =>
  | {
      cells: mathModel.Cell[];
      multiplier: number;
    }
  | undefined;

export function performReelSpinFeatures(
  isBaseGame: boolean,
  coinSize: number,
  coinTypeId: CoinType,
  choices: Choices,
  totalPlayAmount: number,
  slotWindow: string[][],
  lineWinChecker: (slotWindow: string[][]) => LineWin[],
  scatterWinChecker: ScatterWinChecker
): {
  reelSpinFeatures: ReelSpinFeature[] | undefined;
  multiplier: number | undefined;
  stolenRoyals: string[] | undefined;
  stolenRoyalsReplacementSymbols:
    | { cell: mathModel.Cell; symbol: string }[]
    | undefined;
  newSlotWindow: string[][] | undefined;
  nuttPrize: number | undefined;
  nuttCells: Cell[] | undefined;
  wildPath: number[][] | undefined;
  wildPathId: string | undefined;
  candyCanes:
    | {
        candyCaneCells: Cell[];
        candyCaneShape:
          | "TOP_LEFT"
          | "BOTTOM_LEFT"
          | "BOTTOM_RIGHT"
          | "TOP_RIGHT";
        reel: number;
      }[]
    | undefined;
  candyCaneReplacement: string | undefined;
} {
  const isReelFeatureTriggered = choices.chooseIfReelSpinFeatureIsAwarded(
    isBaseGame,
    coinSize,
    coinTypeId
  );
  if (!isReelFeatureTriggered) {
    return {
      reelSpinFeatures: undefined,
      multiplier: undefined,
      stolenRoyals: undefined,
      stolenRoyalsReplacementSymbols: undefined,
      newSlotWindow: undefined,
      nuttCells: undefined,
      nuttPrize: undefined,
      wildPath: undefined,
      candyCanes: undefined,
      candyCaneReplacement: undefined,
      wildPathId: undefined,
    };
  }
  // Shared Between Features
  let newSlotWindow: string[][] | undefined = undefined;
  const awardedReelSpinFeatures = choices.chooseReelSpinFeaturesAwarded();
  let hasFailed = false;

  // Multiplier Feature
  let multiplier: number | undefined = undefined;

  // Stolen Royals Feature
  let stolenRoyalsReplacementSymbols:
    | { cell: mathModel.Cell; symbol: string }[]
    | undefined = undefined;
  let stolenRoyals: string[] | undefined = undefined;

  // Nutt Prize Feature
  let nuttPrize: number | undefined = undefined;
  let nuttCells: Cell[] | undefined = undefined;

  // Wild Path Feature
  let wildPath: number[][] | undefined = undefined;
  let wildPathId: string | undefined = undefined;

  // Candy Canes Feature
  let candyCaneReplacement: string | undefined = undefined;
  let candyCanes:
    | {
        candyCaneCells: Cell[];
        candyCaneShape:
          | "TOP_LEFT"
          | "BOTTOM_LEFT"
          | "BOTTOM_RIGHT"
          | "TOP_RIGHT";
        reel: number;
      }[]
    | undefined = undefined;

  for (const feature of awardedReelSpinFeatures.features) {
    if (feature === "MULTIPLIER") {
      multiplier = choices.chooseMultiplier();
    } else if (feature === "STEAL_ROYALS") {
      const stolenRoyalsFeature = stealRoyals(choices, slotWindow);
      stolenRoyalsReplacementSymbols = stolenRoyalsFeature.replacementSymbols;
      stolenRoyals = stolenRoyalsFeature.stolenRoyals;
      newSlotWindow = generateNewSlotWindow(
        slotWindow,
        stolenRoyalsReplacementSymbols
      );
    } else if (feature === "NUTT_PRIZE") {
      const nuttFeature = choices.chooseNuttSymbol(stolenRoyals ?? []);
      const actualSlowWindow = newSlotWindow ?? slotWindow;
      nuttCells = filterSlotWindow(
        actualSlowWindow,
        (symbol) => nuttFeature.symbol === symbol
      );
      if (nuttCells.length === 0) {
        hasFailed = true;
        break;
      }
      nuttPrize =
        choices.chooseNuttPrize(nuttFeature.id) * coinSize * (multiplier ?? 1);
    } else if (feature === "WILD_PATH") {
      const featureId = choices.chooseWildPathId();
      wildPath = getWildPath(featureId, choices);
      wildPathId = featureId;
      const isPassing = doesWildPathPass(newSlotWindow ?? slotWindow, wildPath);
      if (!isPassing) {
        hasFailed = true;
        break;
      }
      newSlotWindow = generateNewSlotWindow(
        newSlotWindow ?? slotWindow,
        wildPath.map((cell) => {
          return { cell: [cell[0], cell[1]], symbol: "WILD" };
        })
      );
    } else if (feature === "CANDY_CANES") {
      const scatterCells = filterSlotWindow(
        slotWindow,
        (symbol) => symbol === scatterSymbol
      );
      const bonusCells = filterSlotWindow(
        slotWindow,
        (symbol) => symbol === bonusSymbol
      );
      const selectCandyCaneNumber = choices.chooseNumberOfCandyCanes();
      const selectCandyCaneReels = choices.chooseReels(selectCandyCaneNumber);
      const selectedCandyCanes: {
        candyCaneCells: Cell[];
        candyCaneShape:
          | "TOP_LEFT"
          | "BOTTOM_LEFT"
          | "BOTTOM_RIGHT"
          | "TOP_RIGHT";
        reel: number;
      }[] = [];
      const usedCells: Cell[] = [];
      for (const reel of selectCandyCaneReels) {
        const selectCandyCaneShape = choices.chooseCandyCaneShape(reel);
        const candyCaneMidPoint =
          choices.chooseReelStripPositionForCandyCane(reel);
        const cells = generateCandyCaneCells(
          selectCandyCaneShape,
          candyCaneMidPoint,
          reel
        );

        const isOverlapping = selectedCandyCanes.some(({ candyCaneCells }) =>
          candyCaneCells.some((existingCells) =>
            cells.some(
              (cell) =>
                cell[0] === existingCells[0] && cell[1] === existingCells[1]
            )
          )
        );
        if (!isOverlapping) {
          selectedCandyCanes.push({
            candyCaneCells: cells,
            candyCaneShape: selectCandyCaneShape,
            reel,
          });
          usedCells.push(...cells);
        }
      }
      if (
        scatterCells.length >= 3 &&
        scatterCells.some((cell) =>
          usedCells.some(
            (newCell) => cell[0] === newCell[0] && cell[1] === newCell[1]
          )
        )
      ) {
        hasFailed = true;
        break;
      }

      if (
        bonusCells.length >= 3 &&
        bonusCells.some((cell) =>
          usedCells.some(
            (newCell) => cell[0] === newCell[0] && cell[1] === newCell[1]
          )
        )
      ) {
        hasFailed = true;
        break;
      }
      const replacementSymbol = choices.chooseCandyCaneReplacementSymbol(
        stolenRoyals ?? []
      );
      candyCanes = selectedCandyCanes;
      newSlotWindow = generateNewSlotWindow(
        newSlotWindow ?? slotWindow,
        usedCells.map((cell) => {
          return { cell: [cell[0], cell[1]], symbol: replacementSymbol };
        })
      );
      candyCaneReplacement = replacementSymbol;
    }
  }

  const actualSlowWindow = newSlotWindow ?? slotWindow;

  // Evaluate Wins (lines & scatter)
  const wins = lineWinChecker(actualSlowWindow);
  const lineWins = wins.map(
    (win) => win.multiplier * coinSize * (multiplier ?? 1)
  );
  const scatterWin = scatterWinChecker(actualSlowWindow);
  if (scatterWin !== undefined)
    lineWins.push(scatterWin.multiplier * totalPlayAmount * (multiplier ?? 1));
  const nuttPrizeWinAmount = (nuttPrize ?? 0) * (nuttCells ?? []).length;
  const currentWinAmount =
    lineWins.reduce((sum, amt) => sum + amt, 0) + nuttPrizeWinAmount;

  return {
    reelSpinFeatures: [...awardedReelSpinFeatures.features],
    multiplier,
    stolenRoyals,
    stolenRoyalsReplacementSymbols,
    newSlotWindow,
    nuttCells,
    nuttPrize,
    wildPath,
    candyCanes,
    candyCaneReplacement,
    wildPathId,
  };
}
