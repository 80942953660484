var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusTriggerStep,
              expression: "isBonusTriggerStep",
            },
          ],
          staticClass: "text-message",
        },
        [
          _vm._v(
            "\n        This step triggers the bonus. You can add a jackpot pick step next.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusTriggerStep()
              },
            },
          },
          [_vm._v("Bonus Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isBonusTriggerStep,
                expression: "!isBonusTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isBonusTriggerStep,
                expression: "isBonusTriggerStep",
              },
            ],
            staticClass: "button pointer",
            staticStyle: { "background-color": "red", color: "white" },
            on: {
              click: function ($event) {
                return _vm.addJackpotPickStep()
              },
            },
          },
          [_vm._v("Jackpot Pick")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isJackpotPickStep,
              expression: "isJackpotPickStep",
            },
          ],
          staticClass: "optionsContainer",
        },
        [
          _c("div", { staticClass: "options" }, [
            _c("div", [_vm._v("\n                Jackpot Wins\n            ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-style", staticStyle: { width: "300px" } },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentJackpotPick,
                        expression: "currentJackpotPick",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currentJackpotPick = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.changeJackpotPick()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.jackpotPicks, function (pick) {
                    return _c("option", { domProps: { value: pick.index } }, [
                      _vm._v(_vm._s(pick.jackpotWins)),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isJackpotPickStep,
              expression: "!isJackpotPickStep",
            },
          ],
          staticClass: "optionsContainer",
        },
        [
          _c("div", { staticClass: "options" }, [
            _c("div", [
              _vm._v(
                "\n                Multiplier On Off Pattern\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentOnOffPattern,
                      expression: "currentOnOffPattern",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.currentOnOffPattern = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.changeOnOffPattern()
                      },
                    ],
                  },
                },
                _vm._l(_vm.onOffPatterns, function (pattern) {
                  return _c("option", { domProps: { value: pattern } }, [
                    _vm._v(_vm._s(pattern)),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "options" }, [
            _c("div", [
              _vm._v("\n                Substitution Pattern\n            "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentSubstitutionPattern,
                      expression: "currentSubstitutionPattern",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.currentSubstitutionPattern = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.changeOnOffPattern()
                      },
                    ],
                  },
                },
                _vm._l(_vm.substitutionPatterns, function (pattern) {
                  return _c("option", { domProps: { value: pattern } }, [
                    _vm._v(_vm._s(pattern)),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "options" }, [
            _c("div", [
              _vm._v("\n                Multiplier Pattern\n            "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentMultiplierPattern,
                      expression: "currentMultiplierPattern",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.currentMultiplierPattern = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.changeOnOffPattern()
                      },
                    ],
                  },
                },
                _vm._l(_vm.multiplierPatterns, function (pattern) {
                  return _c("option", { domProps: { value: pattern } }, [
                    _vm._v(_vm._s(pattern)),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isJackpotPickStep,
              expression: "!isJackpotPickStep",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }