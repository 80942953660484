/* tslint:disable:variable-name */
import { TODO, trace, createDefaultSlotStep, rowsFromIndexes, wrapIndex, range } from "./../helpers/helpers";
import * as casino from "./../helpers/casino";
import models from "./models";
import bus from "./../common/bus";
import events from "./../common/events";

export class State {
    private _state: any;
    constructor() {
        this._state = {
            slot: null,
            socket: null,
            standalone: false,
            gameId: "",
            environment: "a",
            sendCookies: false,
            userId: 0,

            pauseGame: () => this.state.slot.game.paused = true,
            resumeGame: () => this.state.slot.game.paused = false,
            disconnectSocket: () => this.state.socket && this.state.socket.destroy(),

            getUserId: () => this.state.userId,
            getGameId: () => {
                let g = this.state.gameId;
                const gs = this.state.getValidGameIds();
                if (gs.indexOf(g) === -1) { g = gs[0]; }
                return g;
            },
            getModelId: () => this.state.getModelDefinition().modelId,
            getLayout: () => "",
            initModel: () => {
                const model = models[this.state.getGameId()];
                if (!model) {
                    alert("Woops, the game id is not valid for this Scenario Manager");
                    return;
                }
                this.state = model.setStateParams();
                bus.$emit(events.MODEL_CHANGED);
                this.state.socket.emit({ type: "get-scenarios", environment: this.state.environment });
            },
            changeGameId: (val) => {
                if (models[val] === undefined) {  return; }
                this.state = { gameId: val };
                this.state.initModel();
            },
            getValidGameIds: () => Object.keys(models).sort(),
            getModelDefinition: () => { return {}; },

            // Override any of these socket calls to creating custom scenarios
            addExtraDataToStep: (step) => step, //used when creating a step
            getRowsFromIndexes:(step, reelStrips) => rowsFromIndexes(reelStrips, step.reelStripPositions, this.state.getNumberOfReels(), this.state.getNumberOfRows()),
            prepareStepForDB: (step) => {
                // temporary until chu-server accepts reelStripPositions only -----------------
                const state = this.state;
                const reelStrips = step.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
                step.randoms = step.reelStripPositions;
                step.rows = this.state.getRowsFromIndexes(step, reelStrips);
                return step;
            },
            createStep: (reelStripPositions, isFeature = false, order = 0, variation = "", name="Base") => this.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name)),

            // Socker Updates
            startScenario: (test_scenario_id) => this.state.socket.emit({ type: "start-scenario", user_id: this.state.getUserId(), test_scenario_id, environment: this.state.environment, useCookie: this.state.sendCookies }),
            addScenario: () => {
                const gameId = this.state.getGameId();
                const layout = this.state.getLayout();
                const step = this.state.prepareStepForDB(this.state.createDefaultStep());
                this.state.socket.emit({ type: "add-scenario", gameId, layout, step, environment: this.state.environment });
            },
            editScenario: (test_scenario_id, name) => this.state.socket.emit({type: "edit-scenario", test_scenario_id, name, environment: this.state.environment }),
            deleteScenario: (test_scenario_id) => this.state.socket.emit({type: "delete-scenario", test_scenario_id, environment: this.state.environment }),
            recreateScenario: (steps) => this.state.socket.emit({ type: "recreate-scenario",  steps, environment: this.state.environment }),
            addStep: (test_scenario_id, step, order_number=0) => {
                step = this.state.prepareStepForDB(step);
                this.state.socket.emit({ type: "add-step", test_scenario_id, step, order_number, environment: this.state.environment });
            },
            addSteps: (test_scenario_id, steps, order_number=0) => {
                steps = steps.map(this.state.prepareStepForDB);
                this.state.socket.emit({ type: "add-steps", test_scenario_id, steps, order_number, environment: this.state.environment });
            },
            deleteStep: (test_scenario_id, test_scenario_step_id, steps) => this.state.socket.emit({ type: "delete-step", test_scenario_step_id, test_scenario_id, steps, environment: this.state.environment }),
            editStep: (test_scenario_id, test_scenario_step_id, step, order_number) => {
                step = this.state.prepareStepForDB(step);
                this.state.socket.emit({ type: "edit-step", step, test_scenario_id, test_scenario_step_id, order_number, environment: this.state.environment });
            },
            editSteps: (test_scenario_id, test_scenario_step_ids, steps, order_number=0) => {
                steps = steps.map(this.state.prepareStepForDB);
                this.state.socket.emit({ type: "edit-steps", test_scenario_id, test_scenario_step_ids, steps, order_number, environment: this.state.environment });
            },


            // Helpers
            getNumberOfReels: () => this.state.getModelDefinition().reelsLayout.length,
            getNumberOfRows: (reel = 0) => this.state.getModelDefinition().reelsLayout[reel],
            getReels: (step) => {
                const where = step.json ? step.json : step;
                const reels = where.isFeature ? this.state.getFeatureReelStrips(where.featureReelsToUse) : this.state.getReelStrips();
                return reels;
            },
            getReelWindowPerReel: (step) => {
                const reelStrips = this.state.getReels(step);
                return step.json.reelStripPositions.map((reelIndex, i) => {
                    const cols = this.state.getNumberOfRows(i);
                    return range(cols)
                        .map((n) => {
                            const r = reelStrips[i];
                            const wrap = wrapIndex(reelIndex + n, r);
                            return r[wrap];
                        });
                })
            },
            countSymbolOnStep: (step, symbol="Coin") => {
                let count = 0;
                this.state.getReelWindowPerReel(step).map((reel, r) => { reel.map((sym, i) => { count += (symbol.toLowerCase() === sym.toLowerCase()) ? 1 : 0; }); });
                return count;
            },
        };
    }

    /*===========================================
    Getters and setters
    =============================================*/
    get state(){return Object.assign({}, this._state); }
    set state(o){
        if (typeof o !== "object") { return; }
        this._state = Object.assign({}, this._state, o);
    }
}

const state = new State();
export default state;
