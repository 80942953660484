import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ['index', 'cherriesSelectedMultipliers'],
})
export default class selectCherryMultiplier extends Vue {
    constructor() {
        super();
    }

    updateCherry() {
        console.log("called updateCherry");
        (<Content>this.$parent).updateBonusGameCherriesMultipliers(event);
    }
}
