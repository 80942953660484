var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        symbol: true,
        wild: _vm.isWild,
        "new-wild": _vm.isNewWild,
        scatter: _vm.isScatter,
      },
      style: { "grid-column": _vm.colIndex + 1, "grid-row": _vm.rowIndex + 1 },
      on: {
        "!wheel": function ($event) {
          $event.preventDefault()
          return ((e) => _vm.onReelScroll(e, _vm.colIndex)).apply(
            null,
            arguments
          )
        },
      },
    },
    [
      _vm.isWild
        ? [
            _vm.isNewWild
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wildModifier,
                        expression: "wildModifier",
                      },
                    ],
                    staticClass: "wild-modifier",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.wildModifier = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 1 } }, [_vm._v("x1")]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 2 } }, [_vm._v("x2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MINI" } }, [
                      _vm._v("MINI"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MINOR" } }, [
                      _vm._v("MINOR"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MAJOR" } }, [
                      _vm._v("MAJOR"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "GRAND" } }, [
                      _vm._v("GRAND"),
                    ]),
                  ]
                )
              : _c("p", { staticClass: "wild-modifier" }, [
                  _vm._v(_vm._s(_vm.wildModifierDisplay)),
                ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "current-name" }, [
        _vm._v(_vm._s(_vm.symbolName)),
      ]),
      _vm._v(" "),
      _vm.symbolName !== _vm.originalSymbol
        ? _c("p", { staticClass: "original-name" }, [
            _vm._v("\n    " + _vm._s(_vm.originalSymbol) + "\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }