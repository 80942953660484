var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep()))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v("Add Free spin (re)trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFireshotTriggerStep,
                expression: "canAddFireshotTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFireshotTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Fireshot")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.getCurrentStepIndex() + 1)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isFireshotRespin() || _vm.getFireshotRespinsRemaining(),
                  expression:
                    "isFireshotRespin() || getFireshotRespinsRemaining()",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " +
                  _vm._s(_vm.getFireshotRespinsRemaining())
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isFireshotRespin() || _vm.getFireshotRespinsRemaining(),
                  expression:
                    "isFireshotRespin() || getFireshotRespinsRemaining()",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Coins Collected: " + _vm._s(_vm.getFireshotCoinsCollected())
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin() || _vm.getFreeSpinsRemaining(),
                  expression: "isFreeSpin() || getFreeSpinsRemaining()",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Free Spins Remaining: " + _vm._s(_vm.getFreeSpinsRemaining())
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", { attrs: { content: this, index: 0, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 1, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 2, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 3, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 4, step: _vm.step } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }