import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { getReelWindowFromIndex, trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import ReelVgw082 from "./reel.vue";
import { Vgw082StepJson, CoinPrize } from "src/state/models/slots/vgw082/step";
import { modelDefinition } from "../../../../state/models/slots/vgw082/config/model-definition";

export type SpinType =
  | "Base"
  | "BaseWWBS"
  | "BaseFreeSpinTrigger"
  | "Freespin"
  | "FreeWWBS"
  | "FreeRetrigger"
  | "ClearGameState";

const originatorId = new Date().getTime();

@Component({
  components: {
    reel: ReelVgw082,
  },
  data: () => {
    return {};
  },
})
export default class Content extends Vue {
  @Prop() public step: Vgw082Step | undefined;
  public isFeature = false;
  public stepType = "BASE";
  public spinType: SpinType = "Base";
  private callAddStep: () => void = () => {};
  public show = false;
  public reelHeightExpansion = 3;
  private allSteps: Vgw082Step[] = [];
  public availableHotSpotMultipliers: number[] = [];

  constructor() {
    super();
    this.availableHotSpotMultipliers = [
      0,
      ...State.state.getAvailableHotSpotMultipliers(),
    ];
  }

  public getAvailableCoinPrizes(columnIndex: number) {
    return State.state.getAvailableCoinPrizes(columnIndex).map((prize) => {
      return {
        value: isNaN(Number(prize)) ? prize : Number(prize),
        label: `🟡 ${prize}`,
      };
    });
  }

  @Watch("spinType")
  public spinTypeChanged(newSpinType: SpinType) {
    switch (newSpinType) {
      case "Base":
        this.callAddStep = this.addBaseStep;
        break;
      case "BaseWWBS":
        this.callAddStep = this.addWinWinBigSpinTriggerStep;
        break;
      case "BaseFreeSpinTrigger":
        this.callAddStep = this.addFreespinTriggerStep;
        break;
      case "Freespin":
        this.callAddStep = this.addFeatureStep;
        break;
      case "FreeWWBS":
        this.callAddStep = this.addWinWinBigSpinFromFeatureTriggerStep;
        break;
      case "FreeRetrigger":
        this.callAddStep = this.addFeatureRetriggerStep;
        break;
      case "ClearGameState":
        this.callAddStep = this.addClearGameStateStep;
        break;
    }
  }

  public mounted() {
    this.callAddStep = this.addBaseStep;
    trace("reels mounted");
    bus.$on(events.UPDATE_STEPS, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
  }

  reloadStepsFromScenario(scenario?: {
    steps: Array<{ test_scenario_step_id: number; json: Vgw082StepJson }>;
  }) {
    const newSteps: Vgw082Step[] = [];
    for (const incomingStep of scenario?.steps ?? []) {
      const existingStep = this.allSteps.find(
        (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
      );
      const step =
        existingStep && incomingStep.json.originatorId === originatorId
          ? existingStep
          : incomingStep;
      newSteps.push(step);
    }

    // Traverse all steps, ensuring the state transitions are all valid and correct.
    if (newSteps.length > 0) {
      const isModified = this.updateStepsInPlace(newSteps);

      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    }
  }

  saveChangesToStep(): void {
    // Traverse all steps, ensuring the state transitions are all valid and correct.
    this.updateStepsInPlace(this.allSteps);

    // Marking all the steps as having originated here, and persist them.
    this.allSteps.forEach((step) => (step.json.originatorId = originatorId));
    bus.$emit(events.EDIT_STEPS, this.allSteps);
  }

  updateStepsInPlace(steps: Vgw082Step[]) {
    let isFreeSpin = false;
    let freeSpinCount = 0;
    let name = "Spin";
    let isAnyStepModified = false;
    let coinPrizes: CoinPrize[][] = [];
    const defaultReelExpansionheight = 3;
    let reelsExpansionHeight = defaultReelExpansionheight;

    for (const step of steps) {
      step.reelStripPositions = step.json.reelStripPositions;
      if (step.json.name === "Clear") {
        freeSpinCount = 0;
        name = step.json.name;
        coinPrizes = [];
      } else {
        name = "Spin";
        isFreeSpin = freeSpinCount > 0;

        let slotWindow: string[][] = getSlotWindow({
          isFreeSpin,
          reelStripPositions: step.json.reelStripPositions,
          reelHeight: defaultReelExpansionheight,
        });

        reelsExpansionHeight =
          checkWinWinBigSpinForSlotWindow(slotWindow) &&
          step.json.reelsExpansionHeight
            ? step.json.reelsExpansionHeight
            : defaultReelExpansionheight;

        if (reelsExpansionHeight !== defaultReelExpansionheight) {
          slotWindow = getSlotWindow({
            isFreeSpin,
            reelStripPositions: step.json.reelStripPositions,
            reelHeight: reelsExpansionHeight,
          });
        }

        const scatterSymbols = findCells(
          modelDefinition.scatterSymbol,
          slotWindow
        );

        const coinCells = findCells(modelDefinition.coinSymbol, slotWindow);
        coinPrizes = slotWindow.map((col) => new Array(col.length).fill(0));
        for (const [row, col] of coinCells)
          coinPrizes[col][row] =
            step.json.coinPrizes?.[col]?.[row] ||
            toCoinPrize(this.getAvailableCoinPrizes(col)[0].value.toString());

        const freeSpinsAwarded =
          modelDefinition.freeSpinsAwarded[
            modelDefinition.reels.length - scatterSymbols.length
          ];
        const isFreeSpinTrigger = freeSpinsAwarded > 0;
        if (isFreeSpin) {
          freeSpinCount -= 1;
          name = "Free spin";
        }

        if (isFreeSpinTrigger) {
          name = isFreeSpin ? "F-Retrigger" : "F-Trigger";
          freeSpinCount += freeSpinsAwarded;
        }
      }

      Object.assign(step, {
        freeSpinCount,
      });

      const json = { ...step.json, name, coinPrizes };
      if (JSON.stringify(json) !== JSON.stringify(step.json)) {
        isAnyStepModified = true;
        step.json = json;
        json.originatorId = originatorId;
        json.reelsExpansionHeight = reelsExpansionHeight;
      }
    }
    return isAnyStepModified;
  }

  public isWinWinBigSpinTrigger() {
    const currentStep = this.currentStep();

    if (!currentStep) return false;

    const reelStripPositions = currentStep.json.reelStripPositions;
    const reelHeight = this.currentStep()?.json.reelsExpansionHeight || 3;

    const slotWindow = getSlotWindow({
      isFreeSpin: this.getFreeSpinsRemaining() > 0,
      reelStripPositions,
      reelHeight,
    });

    return checkWinWinBigSpinForSlotWindow(slotWindow);
  }

  public callStep() {
    this.callAddStep?.();
  }

  public addBaseStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getBaseStep());
  }

  public addFeatureStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureStep());
  }

  public addFreespinTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFreeSpinTriggerFromBaseGame());
  }

  public addWinWinBigSpinTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getWinWinBigSpinFromBaseGame());
  }

  public addWinWinBigSpinFromFeatureTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getWinWinBigSpinFromFeatureGame());
  }

  public addFeatureRetriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
  }

  public addClearGameStateStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getClearGameStateStep());
  }

  currentStep(): Vgw082Step | undefined {
    if (!this.step || !this.allSteps) return;
    return this.allSteps.find(
      (s) =>
        this.step && s.test_scenario_step_id === this.step.test_scenario_step_id
    );
  }

  public getFreeSpinsRemaining(): number {
    return this.currentStep()?.freeSpinCount ?? 0;
  }

  finalStep(): Vgw082Step | undefined {
    return this.allSteps[this.allSteps.length - 1];
  }

  canAddBaseSpinStep(): boolean {
    return !this.finalStep()?.freeSpinCount;
  }

  canAddFreeSpinStep(): boolean {
    const finalStep = this.finalStep();
    if (!finalStep) return false;
    if (!finalStep.freeSpinCount) return false;

    return finalStep.freeSpinCount > 0;
  }

  canSelectHotSpots(index: number): boolean {
    return (
      index > 1 &&
      this.getFreeSpinsRemaining() > 0 &&
      this.isWinWinBigSpinTrigger()
    );
  }

  public getCoinPrize({ row, col }: { row: number; col: number }) {
    let prize = this.currentStep()?.json.coinPrizes?.[col]?.[row] ?? 0;
    return prize;
  }

  setCoinPrize({
    row,
    col,
    value,
  }: {
    row: number;
    col: number;
    value: string;
  }) {
    const currentStep = this.currentStep();
    if (!currentStep) return;
    const cp = toCoinPrize(value);
    const currentCoinPrizes = this.currentStep()?.json.coinPrizes;

    const reelStripPositions = currentStep.json.reelStripPositions;
    const reelHeight = this.currentStep()?.json.reelsExpansionHeight || 3;

    const slotWindow = getSlotWindow({
      isFreeSpin: this.getFreeSpinsRemaining() > 0,
      reelStripPositions,
      reelHeight,
    });

    if (modelDefinition.coinSymbol !== slotWindow?.[col][row]) return;

    if (!slotWindow) return;

    const newCoinPrices = slotWindow.map(
      (slotWindowColumn, slotWindowColumnIndex) => {
        if (slotWindowColumnIndex < 2) return [];

        return slotWindowColumn.map((_row, slotWindowRowIndex) => {
          return slotWindowColumnIndex === col && slotWindowRowIndex === row
            ? cp
            : currentCoinPrizes?.[slotWindowColumnIndex]?.[
                slotWindowRowIndex
              ] ?? 0;
        });
      }
    );

    (this.currentStep() as Vgw082Step).json.coinPrizes = newCoinPrices;
    this.saveChangesToStep();
  }

  public getHotSpotMultiplier({ row, col }: { row: number; col: number }) {
    return this.currentStep()?.json.hotSpots?.[col]?.[row];
  }

  public setHotSpotMultiplier({
    row,
    col,
    multiplier,
  }: {
    row: number;
    col: number;
    multiplier: number;
  }) {
    const currentStep = this.currentStep()?.json;
    if (!currentStep) return;

    const reelStripPositions = currentStep.reelStripPositions;

    const slotWindow = getSlotWindow({
      isFreeSpin: true,
      reelStripPositions,
      reelHeight: currentStep.reelsExpansionHeight || 3,
    });

    const currentHotSpots = currentStep.hotSpots;

    const newHotSpots = slotWindow.map(
      (slotWindowColumn, slotWindowColumnIndex) => {
        if (slotWindowColumnIndex < 2) return [];

        return slotWindowColumn.map((_row, slotWindowRowIndex) => {
          return slotWindowColumnIndex === col && slotWindowRowIndex === row
            ? Number(multiplier)
            : currentHotSpots?.[slotWindowColumnIndex]?.[slotWindowRowIndex] ??
                0;
        });
      }
    );

    currentStep.hotSpots = newHotSpots;
    this.saveChangesToStep();
  }

  @Watch("step")
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }
    this.show = true;
    this.isFeature = step.json.isFeature ? step.json.isFeature : false;
  }

  public setReelHeight(height: number) {
    const currentStep = this.currentStep();
    if (!currentStep) return;

    currentStep.json.reelsExpansionHeight = Number(height);
    this.saveChangesToStep();
  }

  public getReelHeight() {
    return this.currentStep()?.json.reelsExpansionHeight || 3;
  }

  public getReelHeights() {
    const reelExpansionHeight = this.getReelHeight();
    return State.state.getExpandedReelsLayout(reelExpansionHeight);
  }
}

function checkWinWinBigSpinForSlotWindow(slotWindow: string[][]) {
  const wildOnReel1 =
    slotWindow[0].find((symbol) => symbol === "WILD") !== undefined;
  const wildOnReel2 =
    slotWindow[1].find((symbol) => symbol === "WILD") !== undefined;
  return wildOnReel1 && wildOnReel2;
}

function getSlotWindow({
  isFreeSpin,
  reelStripPositions,
  reelHeight,
}: {
  isFreeSpin: boolean;
  reelStripPositions: number[];
  reelHeight: number;
}): string[][] {
  const reelsLayout = State.state.getExpandedReelsLayout(reelHeight);

  const reels = isFreeSpin
    ? modelDefinition.freeSpinReels
    : modelDefinition.reels;
  return reels.map((_r, col) =>
    getReelWindowFromIndex(
      reels[col],
      reelStripPositions[col],
      reelsLayout[col]
    )
  );
}

function findCells(
  symbol: string,
  slotWindow: string[][]
): Array<[number, number]> {
  const cells: Array<[number, number]> = [];

  for (let col = 0; col < slotWindow.length; col++) {
    for (let row = 0; row < slotWindow[col].length; row++) {
      if (slotWindow[col][row] === symbol) {
        cells.push([row, col]);
      }
    }
  }
  return cells;
}

function toCoinPrize(value: string): CoinPrize {
  const n = Number(value);
  return (isNaN(n) ? value : n) as CoinPrize;
}

export interface Vgw082Step {
  reelStripPositions?: number[];
  test_scenario_step_id: number;
  json: Vgw082StepJson;
  freeSpinCount?: number;
}
