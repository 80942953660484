import { mathModel } from "../../../../v3";
import { modelDefinition } from "../../config";

const filterSlotWindow = mathModel.filterSlotWindow;
const { wildSymbol, scatterSymbol, bonusSymbol } = modelDefinition;

export function doesWildPathPass(
  slotWindow: string[][],
  path: number[][]
): boolean {
  const scatterCells = filterSlotWindow(
    slotWindow,
    (symbol) => symbol === scatterSymbol
  );
  if (
    scatterCells.length >= 3 &&
    scatterCells.some((cell) =>
      path.some(
        (wildCell) => cell[0] === wildCell[0] && cell[1] === wildCell[1]
      )
    )
  )
    return false;

  const bonusCells = filterSlotWindow(
    slotWindow,
    (symbol) => symbol === bonusSymbol
  );
  if (
    bonusCells.length >= 3 &&
    bonusCells.some((cell) =>
      path.some(
        (wildCell) => cell[0] === wildCell[0] && cell[1] === wildCell[1]
      )
    )
  )
    return false;

  const wildCells = filterSlotWindow(
    slotWindow,
    (symbol) => symbol === wildSymbol
  );
  const nonMatching = wildCells.filter(
    (cell) =>
      !path.some(
        (wildCell) => cell[0] === wildCell[0] && cell[1] === wildCell[1]
      )
  );
  return nonMatching.length === 0;
}
