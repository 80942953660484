// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer > div[data-v-195655eb] {
  margin-block-end: 2em;
}
.slot-window[data-v-195655eb] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.2em;
  justify-items: center;

  padding: 1em;
  border-radius: 0.5em;
}
.slot-window > div[data-v-195655eb] {
  text-align: center;
  width: 100%;
  aspect-ratio: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
.generic-info[data-v-195655eb] {
  display: flex;
  gap: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw089/slot-window/slot-window.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,UAAU;EACV,qBAAqB;;EAErB,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;;EAEf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".outer > div[data-v-195655eb] {\n  margin-block-end: 2em;\n}\n.slot-window[data-v-195655eb] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 1.2em;\n  justify-items: center;\n\n  padding: 1em;\n  border-radius: 0.5em;\n}\n.slot-window > div[data-v-195655eb] {\n  text-align: center;\n  width: 100%;\n  aspect-ratio: 1;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.generic-info[data-v-195655eb] {\n  display: flex;\n  gap: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
