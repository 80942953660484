import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectRespinSymbol from "./components/selectRespinSymbol.vue";
import SelectBonusGame from "./components/selectBonusGame.vue";
import SelectPrizeForBonusGame from "./components/selectPrizeForBonusGame.vue";
import SelectBonus1Prize from "./components/selectBonus1Prize.vue";
import SelectBonus2Prize from "./components/selectBonus2Prize.vue";
import SelectBonus3Prize from "./components/selectBonus3Prize.vue";
import SelectBonus0Prize from "./components/selectBonus0Prize.vue";
import SelectBonus4Prize from "./components/selectBonus4Prize.vue";
import SelectBonus5Prize from "./components/selectBonus5Prize.vue";
import SelectBonus6Prize from "./components/selectBonus6Prize.vue";
import SelectBonus8Prize from "./components/selectBonus8Prize.vue";
import SelectWheelWin from "./components/selectWheelWin.vue";

@Component({
    components: {
        reel: Reel,
        selectRespinSymbol: SelectRespinSymbol,
        selectBonusGame: SelectBonusGame,
        selectWheelWin: SelectWheelWin,
        selectPrizeForBonusGame: SelectPrizeForBonusGame,

        selectBonus0Prize: SelectBonus0Prize,
        selectBonus1Prize: SelectBonus1Prize,
        selectBonus2Prize: SelectBonus2Prize,
        selectBonus3Prize: SelectBonus3Prize,
        selectBonus4Prize: SelectBonus4Prize,
        selectBonus5Prize: SelectBonus5Prize,
        selectBonus6Prize: SelectBonus6Prize,
        selectBonus8Prize: SelectBonus8Prize,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isRespinStart = false;
    public isRespinSelect = false;
    public isBonusGameStep = false;
    public isWheelWinStep = false;
    // public isRespinEnd = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public reelsNo = 3;

    public respinSymbol = "";
    public bonusGame = -1;
    public bonusGamePrize = "";
    public wheelWin = "";
    public reelSetOption = 0;
    public freespinMultiplier = 0;

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ?? false;
        this.respinSymbol = step.json.respinSymbol ?? "None";
        this.bonusGame = step.json.bonusGame || -1;
        this.wheelWin = step.json.wheelWin ?? "";
        this.bonusGamePrize = step.json.bonusGamePrize ?? "";
        this.isRespinStart = step.json.isRespinStart ?? false;
        this.isRespinSelect = step.json.isRespinSelect ?? false;
        this.isBonusGameStep = step.json.isBonusGameStep ?? "";
        this.isWheelWinStep = step.json.isWheelWinStep ?? "";
        this.reelSetOption = step.json.reelSetOption ?? 0;
        this.freespinMultiplier = step.json.freespinMultiplier ?? 0;
        // this.isRespinEnd = step.json.isRespinEnd ?? false;
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinStep());
    }

    public addRespinStart() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStartStep());
    }

    public addRespinSelect() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinSelect());
    }

    // public addRespinEnd() {
    //     const state = State.state;
    //     bus.$emit(events.ADD_STEP, state.getRespinEndStep());
    // }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateRespinSymbol() {
        this.step.json.respinSymbol = this.respinSymbol;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusGame() {
        this.step.json.bonusGame = this.bonusGame;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusPrize() {
        this.step.json.bonusGamePrize = this.bonusGamePrize;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onUpdateStep() {
        this.respinSymbol = this.step.json.respinSymbol;
        this.bonusGame = this.step.json.bonusGame;
        this.bonusGamePrize = this.step.json.bonusGamePrize;
        this.wheelWin = this.step.json.wheelWin;
    }

    public isBonusGameSelected(bonusGameIndex: number): boolean {
        if (!this.step || !this.step.json) {
            return false;
        }
        if (!this.isBonusGameStep) {
            return false;
        }
        return this.step.json.bonusGame == bonusGameIndex;
    }

    public addBonusGameStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBonusGameStep());
    }

    public addWheelWinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getWheelWinStep());
    }

    public updateWheelWin() {
        this.step.json.wheelWin = this.wheelWin;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeReelSetOption() {
        this.step.json.reelSetOption = this.reelSetOption;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeFreespinMultiplier() {
        this.step.json.freespinMultiplier = this.freespinMultiplier;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
