/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    getReelWindowFromIndex,
    createDefaultSlotStep,
} from "../../../../helpers/helpers";
import { m93, m94, m95 } from "./config/init";
/*
regular steps: 
```
{
    order: 1,
    reelStripPositions: [0, 0, 0, 0, 0],
    rows: [
        ["Coin", "Coin", "Heart", "Heart", "Heart"], 
        ["Coin", "Coin", "Heart", "Heart", "Heart"], 
        ["Coin", "Coin", "Heart", "Heart", "Heart"]
    ],
    isFeature: false,
    variation: "",
    symbolInfos: [ 
        [[ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ null, null, null ], [ null, null, null ], [ null, null, null ] ],
        [[ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ null, null, null ], [ null, null, null ], [ null, null, null ] ],
        [[ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ null, null, null ], [ null, null, null ], [ null, null, null ] ],
        [[ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ {"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true } ], [ null, null, null ], [ null, null, null ], [ null, null, null ] ]
    ]
}


```


 */
export function setStateParams() {
    const _coinName = "COIN";

    return {
        variations: [""],
        createStep: (reelStripPositions, isFeature = false, order=0, variation="",name="Base") => {
            const step: any = createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name);
            step.symbolInfos = [];
            step.symbolInfos.push(State.state.getEmptySymbolInfo());
            step.isSweeps = true;
            return step;
        },
        getEmptySymbolInfo: () => { return [
            [ null, null, null ], [ null, null, null ], [ null, null, null ]
        ] }, 
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([14, 10, 10]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? m93 : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: (n) => State.state.getModelDefinition().fsFeatureReels[n],
        getReelWindow: (reelIndex, step, reelStripPosition) => {
            return getReelWindowFromIndex(State.state.getReels(step)[reelIndex], reelStripPosition, 3);
        },
        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips(where.featureReelsToUse) : State.state.getReelStrips(); 
            return reels;
        },

        getPrices: (coin = 4) => State.state.getModelDefinition()[ (coin === 4 ? "coinPricesSweeps" : "coinPricesGold") ].prices,
        isJackpot: (step) => State.state.countSymbolOnStep(step, _coinName) >= 4 ,
        organizeSymbolInfos: (symbolInfos: any = [], c = 0) => {
            while(c < symbolInfos.length) {
                const n = c + 1;
                symbolInfos[c].map((row, ri) => {
                    row.map((symbol, si) => {
                        const locked = symbol != null;
                        let value = symbol === null ? null : Object.assign({}, JSON.parse(JSON.stringify(symbol)), { locked });
                        if (symbol != null && n < symbolInfos.length) {
                            symbolInfos[n][ri][si] = value;
                        }
                    });
                })
                c++;
            }
            return symbolInfos;
        },
        // called only when the textfield is changed, only changes 1 reel
        resetAllSymbolInfosOnReel: (step, r = 0) => {
            const symbolInfos = step.json.symbolInfos;
            const reelStripPosition = step.json.reelStripPositions[r];
            const reelWindow = State.state.getReelWindow(r, step, reelStripPosition);
            symbolInfos.map((whichStep, stepIndex) => {
                whichStep[r].map((reel, ri) => {
                    const val = reelWindow[ri] === _coinName ? State.state.getEnabledStep() : null;
                    if (val && stepIndex > 0) {
                        val.locked = true;
                    }
                    symbolInfos[stepIndex][r][ri] = val; 
                });
            });
        },
        lockJackpotSteps: (step, substep = 0) => {
            const symbolInfos = step.json.symbolInfos;
            let c = 1;
            while(c < symbolInfos.length) {
                symbolInfos[c].forEach((reel, reelIndex) => {
                    reel.forEach((item, ri) => { 
                        if (item) {   
                            item.locked = true;
                        }
                    });
                });
                c++;
            }
        },
        propagateInfoOnSymbolInfos: (step, substep, reelIndex, index, value) => { 
            const symbolInfos = step.json.symbolInfos; 
            let c = substep + 1;
            while(c < symbolInfos.length) {
                const nv = value ? JSON.parse(JSON.stringify(value)) : null;
                if (nv) { nv.locked = true; }
                symbolInfos[c][reelIndex][index] = nv;
                c++;
            }
        },
        propagateValueOnSymbolInfos: (step, substep, reelIndex, index, property, value) => { 
            const symbolInfos = step.json.symbolInfos; 
            let c = substep;
            while(c < symbolInfos.length) {
                if (symbolInfos[c][reelIndex][index]) { symbolInfos[c][reelIndex][index][property] = value; }
                c++;
            }
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReels(step);
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            step.symbolInfos.forEach((symbolStep) => {
                symbolStep.forEach((reel) =>{
                    reel.forEach((it) => {
                        if (it) { 
                            if (it.price === 0 || it.price > 5) {
                                it.jackpotType = state.getPriceText(it.price, step.isSweeps ? 4 : 1); 
                            } else {
                                it.jackpotType = null;
                            }
                        }
                    });
                });
            });
            return step;
        },
        getPrice: (value, currency : number = 4) => {
            if (value === "MAJOR") { return State.state.getPrices(currency)[0]; }
            if (value === "MINOR") { return State.state.getPrices(currency)[1]; }
            if (value === "MINI") { return State.state.getPrices(currency)[2]; }
            return Number(value);
        },
        getPriceText: (price : number, currency : number = 4) => {
            if (price === State.state.getPrices(currency)[0]) { return "MAJOR"}
            if (price === State.state.getPrices(currency)[1]) { return "MINOR" }
            if (price === State.state.getPrices(currency)[2]) { return "MINI"; }
            return String(price);
        },

        // example on creating custom indexesx
        getEnabledStep:() => { return {"enabled": true, "price": 4, jackpotType: null} },
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getWithCoinsStep: (order = 0) => State.state.createStep([47, 43, 48], false, order, "", "Base") ,
        getJackpotTriggerStep: (order = 0) => {
            const s = State.state;
            const step = s.createStep([46, 80, 86], false, order, "", "J-Trigger");
            step.symbolInfos[0] = [
                [ null, s.getEnabledStep(), s.getEnabledStep() ],
                [ s.getEnabledStep() , s.getEnabledStep(), null],
                [ null, null, null]
            ];
            State.state.organizeSymbolInfos(step.symbolInfos); 
            return step;
        },
        getFeatureTriggerStep: (order = 0) => {
            return State.state.createStep([76, 73, 272], false, order, "", "F-Trigger"); 
        },
        getFeatureStep: (featureReelsToUse) => {
            const step = State.state.createStep([14, 10, 10], true, 0, "", "F-Step"); 
            step.featureReelsToUse = featureReelsToUse; 
            return step;
        },
        getReelWindowPerReel: (step) => { 
            const reelStrips = State.state.getReels(step);  
            return step.json.reelStripPositions.map((reelIndex, i) => {
                return [
                    reelStrips[i][wrapIndex(reelIndex,   reelStrips[i])],
                    reelStrips[i][wrapIndex(reelIndex+1, reelStrips[i])],
                    reelStrips[i][wrapIndex(reelIndex+2, reelStrips[i])],
                ];
            })
        },
        countSymbolOnStep: (step, symbol=_coinName) => { 
            let count = 0;
            State.state.getReelWindowPerReel(step).map((reel, r) => { reel.map((sym, i) => { count += (symbol.toLowerCase() === sym.toLowerCase()) ? 1 : 0; }); });
            return count;
        },
        enableCoinsOnStep: (step) => {
            const reelNum = step.featureReelsToUse || 0;
            const reels = State.state.getReelWindowPerReel(step);
            reels.map((reel, r) => {
                reel.map((symbol, i) => {
                    step.json.symbolInfos[0][r][i] = (symbol.toLowerCase() === _coinName.toLowerCase()) ? State.state.getEnabledStep() : null;
                });
            });
            State.state.organizeSymbolInfos(step.json.symbolInfos);
        },
    };
}
