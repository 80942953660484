import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, wrapIndex, rowsFromIndexes, getReelWindowFromIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public symbolStack = false;
    public show = false;

    public stackSymbols = ["Random","PIC1","PIC2","PIC3","PIC4", "PIC5"];
    public showStackSymbolSelector = false; 
    public substitutionStackSymbol = {stackSymbol: "Random"};
    public showFeatureBonusSelector = false; 
    private featureReelsToUse = "";

    public featureReels = ["","4520", "5018", "6015", "7512", "9010", "1058", "1455"];// change this 
    public substitutionFeatureReels = {featureReel:"4520"};
    public showFeatureSelector = false;

    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        this.featureReelsToUse = step.json.featureReelsToUse || 4520;

        if(this.isFeature){
            this.showFeatureSelector = true;
            this.substitutionFeatureReels.featureReel = step.json.featureReelsToUse;
        }else{
            this.showFeatureSelector = false;
        }

        this.showStackSymbolSelector = true;
        console.log(step.json);
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }

    public doChangePattern() {
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
   
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep(this.featureReelsToUse) );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep(this.featureReelsToUse));
    }

    public doChangeStackSymbol() { 
        trace(this.substitutionStackSymbol.stackSymbol);
        this.step.json.substitutionStackSymbol = this.substitutionStackSymbol.stackSymbol=="Random"?"":this.substitutionStackSymbol.stackSymbol;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public doChangeFeatureReels(){
        trace(this.substitutionFeatureReels.featureReel);
        this.step.json.featureGameReelsPick = this.substitutionFeatureReels.featureReel==""?"4520":this.substitutionFeatureReels.featureReel;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public doChangeFeatureReelsToUse() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        if(this.isFeature){
            trace(this.substitutionFeatureReels.featureReel);
            this.step.json.isFeature = this.isFeature;
            this.step.json.featureReelsToUse = this.substitutionFeatureReels.featureReel==""?"4520":this.substitutionFeatureReels.featureReel;

            this.step.json.featureGameReelsPick = this.substitutionFeatureReels.featureReel==""?"4520":this.substitutionFeatureReels.featureReel;
            
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
      
    }

}