import { Wild } from "../wild";

export type JackpotWinCounts = {
  GRAND: number;
  MAJOR: number;
  MINOR: number;
  MINI: number;
};

export function computeJackpotWinCounts(newWilds: Wild[]): JackpotWinCounts {
  return {
    GRAND: newWilds.reduce(
      (sum, wild) => (wild.jackpotType === "GRAND" ? sum + 1 : sum),
      0,
    ),
    MAJOR: newWilds.reduce(
      (sum, wild) => (wild.jackpotType === "MAJOR" ? sum + 1 : sum),
      0,
    ),
    MINOR: newWilds.reduce(
      (sum, wild) => (wild.jackpotType === "MINOR" ? sum + 1 : sum),
      0,
    ),
    MINI: newWilds.reduce(
      (sum, wild) => (wild.jackpotType === "MINI" ? sum + 1 : sum),
      0,
    ),
  };
}
