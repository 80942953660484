/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.cascadeReels = 1;
            step.reSpinCount = 0;

            return step;
        },
        getBaseStep: (stepTitle, reSpinCount = 0) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", stepTitle);
            step.reelsType = "reels";
            step.internal_name = stepTitle;
            // Adding the reSpin count value to step
            step.reSpinCount = reSpinCount;
            return step;
        },
        getFreeGameStep: (stepTitle) => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], true, 0, "", stepTitle);
            step.internal_name = stepTitle;
            step.reSpinCount = 0;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        /***
         * Returns reels on the basis of game state type.
         * @param type
         */
        getReelStrips: (type) => {
            if (type === "reels") {
                return State.state.getModelDefinition().reels;
            }
            if (type === "reSpinReels") {
                return State.state.getModelDefinition().reSpinReels;
            }
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = step.isFeature ? state.getFeatureReelStrips() : state.getReelStrips(step.reelsType);
            const noOfReels = step.isFeature ? 6 : 5;
            const noOfRows = 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        },
    };
}
