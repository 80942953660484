import { modelDefinition } from "../../../model-definition";

type SlotWindow = string[][];
export type FullReelWildsFeatureResult = {
  slotWindow: SlotWindow;
  fullReelWilds: number[];
};
export function playFullReelWildsFeature(
  originalSlotWindow: SlotWindow,
  fullReelWilds?: number[]
): FullReelWildsFeatureResult {
  const newSlotWindow = [...originalSlotWindow];
  const fullReelWildsWithOutScats: number[] = [];
  for (const reelIndex of fullReelWilds ?? []) {
    if (newSlotWindow[reelIndex].indexOf(modelDefinition.scatterSymbol) !== -1)
      continue;

    fullReelWildsWithOutScats.push(reelIndex);
    newSlotWindow[reelIndex].fill("WILD");
  }

  return {
    fullReelWilds: fullReelWildsWithOutScats,
    slotWindow: newSlotWindow,
  };
}
