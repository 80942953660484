var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu newMenu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu newMenu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isFeature ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sub-title-menu newMenu spaceNewMenu" }, [
        _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
          _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
            _c("div", { staticClass: "select-style newSelect" }, [
              _c("label", { attrs: { for: "reel_number" } }, [
                _vm._v("extra reels: "),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.extraReels,
                    expression: "extraReels",
                  },
                ],
                staticClass: "text-input reel-text reel-index newInput",
                attrs: { type: "number", min: "0", id: "reel_number" },
                domProps: { value: _vm.extraReels },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.extraReels = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFeature,
                      expression: "!isFeature",
                    },
                  ],
                  staticClass: "flex-center elemOK",
                  class: [_vm.isActive ? "blue" : "elemOK"],
                  attrs: { id: "ok" },
                  on: {
                    click: function ($event) {
                      return _vm.updateStep(_vm.levelNo, _vm.extraReels)
                    },
                  },
                },
                [_vm._v("OK")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFeature,
                      expression: "isFeature",
                    },
                  ],
                  staticClass: "flex-center elemOK",
                  class: [_vm.isActive ? "blueTrigger" : "elemOKTrigger"],
                  attrs: { id: "okTrigger" },
                  on: {
                    click: function ($event) {
                      return _vm.updateStep(_vm.levelNo, _vm.extraReels)
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.extraReels > 3 ? "flex" : "flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center newMenu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFullRandom()
              },
            },
          },
          [_vm._v("Random")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFullNoScatter()
              },
            },
          },
          [_vm._v("NoScatter")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex scatters-selector-table" },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("div", { key: reelIndex, staticClass: "scatters-for" }, [
            _c(
              "div",
              { staticClass: "scatters-column" },
              [
                _c("label", { attrs: { for: reelIndex - 1 } }, [
                  _vm._v(" reel #" + _vm._s(reelIndex - 1) + ": "),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("selectScatterMultiplier", {
                  key: (reelIndex - 1) * 4 + 0,
                  attrs: {
                    index: (reelIndex - 1) * 4 + 0,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                }),
                _vm._v(" "),
                _c("selectScatterMultiplier", {
                  key: (reelIndex - 1) * 4 + 1,
                  attrs: {
                    index: (reelIndex - 1) * 4 + 1,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                }),
                _vm._v(" "),
                _c("selectScatterMultiplier", {
                  key: (reelIndex - 1) * 4 + 2,
                  attrs: {
                    index: (reelIndex - 1) * 4 + 2,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                }),
                _vm._v(" "),
                _c("selectScatterMultiplier", {
                  key: (reelIndex - 1) * 4 + 3,
                  attrs: {
                    index: (reelIndex - 1) * 4 + 3,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                }),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }