import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Vue from "vue";
import { model, GameState, SpinOutcome } from "./model";
import { createChoicesFromScenario } from "./model/choices";
import {
  StepJson,
  originatorId,
} from "../../../../state/models/slots/deal-or-no-deal";
import { SlotWindow } from "./slot-window";
import { LineWin } from "@vgw/gdk-math-model-tools";

type BankerFeature = 'multiplier' | 'stack' | 'scatter' |'case' | 'offer';

// ID used to identify GRAND selection.
const GRAND_ID = 5400;

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
  },
  data: function (): {
    GRAND_ID : number,
    scenario: InternalScenario;
    isBankerMultiplierSelected: boolean,
    isBankerStacksSelected: boolean,
    isBankerScatterSelected: boolean,
    isBankerCaseSelected: boolean,
    isBankerOfferSelected: boolean,
    isCoinPrizesSelected: boolean,
    allSteps: Step[];
    testScenarioId: number;
    goldCaseRow: number,
    goldCaseCol: number,
    goldCaseFeature: 'COLLECT' | 'MULTIPLYx2' | 'MULTIPLYx3' | 'MULTIPLYx4' | 'MULTIPLYx5' | 'BOOST';
  } {
    return {
      GRAND_ID,
      scenario: {
        reelStripPositions: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
        ],
        isBankerMultiplierOfferAwarded: false,
        bankerMultiplierAmount: 0,
        isBankerStacksOfferAwarded: false,
        bankerStacksSymbol: "WILD",
        bankerStacksReels: [],
        bankerScat: {
          cell: [0, 0],
          symbol: "NONE"
        },
        bankerCase: {
          cell: [0, 0],
          symbol: "NONE"
        },
        isOfferAwarded: false,
        bankerOfferWinAmount: 0,
        hasAcceptedBankerOffer: undefined,
        casePrizes: [[null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null]],
        goldCases: [],
        jackpot: 'GRAND'
      },
      isBankerMultiplierSelected: false,
      isBankerStacksSelected: false,
      isBankerScatterSelected: false,
      isBankerCaseSelected: false,
      isBankerOfferSelected: false,
      isCoinPrizesSelected: false,
      allSteps: [],
      goldCaseRow: 0,
      goldCaseCol: 0,
      goldCaseFeature: 'COLLECT',
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      let goldCasePick: [number, number] | undefined = this.currentStep?.json.goldCasePosition;
      const allPickGoldCases = this.currentStep?.gameState?.caseChase?.allPrizes.filter(({gold})=>{ return gold === 'PICK'});
      if(allPickGoldCases && allPickGoldCases.length > 0){
        let isGoldPositionNotValid = goldCasePick === undefined;
        if(goldCasePick) {
            const positionGoldCase = allPickGoldCases.find(({cell}) =>{ 
              return goldCasePick?.[0] === cell[0] && goldCasePick?.[1] === cell[1];
          });
          isGoldPositionNotValid = positionGoldCase === undefined;
        }
        if(isGoldPositionNotValid){
          if(this.currentStep?.gameState?.caseChase?.goldCasePrizes && this.currentStep?.gameState?.caseChase?.goldCasePrizes.length > 0){
              goldCasePick = allPickGoldCases?.[0].cell;
          }        
        }
      }
      const spinOutput = model.play(
        {
          coinType: "SC",
          gameRequest: { 
            coinAmount: 1, 
            hasAcceptedBankerOffer: this.currentStep?.json.hasAcceptedBankerOffer, 
            goldCasePick,
          },
          gameState: this.currentStep?.gameState,
        },
        createChoicesFromScenario({
          ...this.scenario,
          casePrizes: this.scenario.casePrizes,
          bankerStacksSymbol: this.scenario.bankerStacksSymbol === 'NONE' ? undefined : this.scenario.bankerStacksSymbol,
          reelStripPositions: this.scenario.reelStripPositions.map(
            ({ value }) => value
          ),
          bankerCase: this.scenario.bankerCase.symbol !== 'NONE' ? this.scenario.bankerCase : undefined,
          bankerScat: this.scenario.bankerScat.symbol !== 'NONE' ? this.scenario.bankerScat : undefined,
        })
      );
      if(spinOutput.gameResponse.bankerScat){
        const bankerScatPos = spinOutput.gameResponse.bankerScat.cell;
        if(spinOutput.gameResponse.slotWindow[bankerScatPos[1]][bankerScatPos[0]] !== 'SCAT'){
          let scatterPosition: [number, number] | undefined = undefined;
          for(let reelIdx = 0 ; reelIdx <  spinOutput.gameResponse.slotWindow.length; reelIdx++){
            const reelWindow = spinOutput.gameResponse.slotWindow[reelIdx];
            for(let rowIdx = 0 ; rowIdx < reelWindow.length && scatterPosition === undefined; rowIdx++){
              if(reelWindow[rowIdx] === "SCAT" ){
                scatterPosition = [rowIdx, reelIdx];
                break;
              }
            }
          }
          if(this.currentStep?.json.bankerScat && scatterPosition){
            this.currentStep.json.bankerScat.cell = scatterPosition;
          }
        }
      }

      if(spinOutput.gameResponse.bankerCase){
        const bankerCasePos = spinOutput.gameResponse.bankerCase.cell;
        if(spinOutput.gameResponse.slotWindow[bankerCasePos[1]][bankerCasePos[0]] !== 'CASE'){
          const availableCasePositions = this.availableBankerCasePositions(spinOutput);
          if(availableCasePositions.length > 0 && this.currentStep?.json.bankerCase){
            this.currentStep.json.bankerCase.cell = availableCasePositions[0];
          }
        }
      }

      const spinOutcomeWithWinCells = {
        ...spinOutput,
        winCells: getWinCells({
          lineWins: spinOutput.gameResponse.lineWins,
          playLines: model.definition.playLines,
        }),
      };
      return spinOutcomeWithWinCells;
    },
    spinOutcomePostBankerOffer: function(){
      if(this.currentStep?.json.bankerOfferWinAmount && this.currentStep.json.bankerOfferWinAmount > 0){
        const spinOutput = model.play(
          {
            coinType: "SC",
            gameRequest: { 
              coinAmount: 1, 
            },
            gameState: this.currentStep?.gameState,
          },
          createChoicesFromScenario({
            ...this.currentStep.json,
            isOfferAwarded: false,
          })
        );
        return spinOutput;    
      }
      return undefined;
    },
    isCaseNotTrigger: function (): boolean {
      return this.spinOutcome?.gameResponse?.caseChase === undefined;
    },
    isCaseEndPhase: function (): boolean {
      return this.spinOutcome?.gameResponse?.caseChase?.phase === 'END';
    },
    // Case Chase:
    isCasePrizeActive: function () {
      if (this.spinOutcome.gameResponse.caseChase?.phase === 'IN_PROGRESS' || this.spinOutcome.gameResponse.caseChase?.phase === 'END') {
        return true;
      } else {
        return false;
      }
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    isNextStepAGoldPick: function() : boolean {
      if(this.spinOutcome.gameState?.caseChase){
        const goldPickCount = this.spinOutcome.gameState?.caseChase.goldCasePicks?.length ?? 0;
        const goldPrizesCount = this.spinOutcome.gameState?.caseChase.goldCasePrizes?.length ?? 0;
        if(goldPrizesCount > 0){
           return goldPickCount < goldPrizesCount;
        }
        return false
      }
      return false;
    },
    isGoldPickStep: function() : boolean {
      if(this.spinOutcome.gameState?.caseChase){
        const goldPrizesCount = this.spinOutcome.gameState?.caseChase.goldCasePrizes?.length ?? 0;
        const goldPickCount = this.spinOutcome.gameState?.caseChase.goldCasePicks?.length ?? 0;
        return goldPrizesCount > 0 && goldPickCount > 0;
      }
      return false;
    },
    isInBankerOfferStep: function() : boolean {
      const bankerOffer = this.currentStep?.gameState?.bankerOfferAmount ?? 0;
      return bankerOffer > 0;
    },
    availableCasePrizes: function() : number[] {
      return [5, 10, 20, 30, 40, 50, 80, 100, 120, 150, 200, 400, 800, 2000];
    },
  },
  methods: {
    canSelectGrandCase: function(reelIndex: number, rowIndex: number){
      let availableCells = 0;
      let isProvidedCell = false;
      this.scenario.casePrizes.forEach((reelWindow, reelIdx) => {
        reelWindow.forEach((value, rowIdx) => {
          if(value === null || value === this.GRAND_ID){
            if(!isProvidedCell && reelIndex === reelIdx && rowIndex === rowIdx){
              isProvidedCell = true;
            }
            availableCells++;
          }
        })
      });
      if(availableCells <= 1 && isProvidedCell){
        return true;
      }
      return false;
    },
    getFirstCasePrizeAvailable(){
      for( const prize of this.availableCasePrizes){
        if(prize !== 0 && !this.isCasePrizeSelected(prize)){
          return prize;
        }
      }
    },
    isCasePrizeSelected(prize: number){
      for( const reelWindow of this.scenario.casePrizes){
        for( const casePrizeValue of reelWindow ){
          if(casePrizeValue === prize){        
            return true;
          }
        }
      }
      return false;
    },
    isBankerActive(type: BankerFeature){
      switch(type){
        case "multiplier":
          return this.scenario.bankerMultiplierAmount > 0;
        case "stack":
          return this.scenario.bankerStacksSymbol !== 'NONE' && this.scenario.bankerStacksReels.length > 0;
        case "scatter":
          return this.scenario.bankerScat.symbol !== 'NONE';
        case "case":
          return this.scenario.bankerCase.symbol !== 'NONE';
        case 'offer': 
          return this.scenario.bankerOfferWinAmount != 0;
      }
    },
    isBankerAllowed(type: BankerFeature){
      switch(type){
        case "multiplier":
          return !this.spinOutcome.gameResponse.caseChase?.phase 
            && ( this.spinOutcome.gameResponse.freeSpinPhase === undefined  || this.spinOutcome.gameResponse.freeSpinPhase === 'IN_PROGRESS')
            && this.spinOutcome.gameResponse.winAmount > 0;
        case "stack":
          return true;
        case "scatter":
          return this.spinOutcome.gameResponse.bankerScat !== undefined;
        case "case":
            return this.spinOutcome.gameResponse.bankerCase !== undefined;
        case "offer":
            return this.spinOutcome.gameResponse.bankerOfferAmount !== undefined;
      }
    },
    isBankerFeatureSelected(type: BankerFeature) {
      switch(type){
        case "multiplier":
          return this.isBankerMultiplierSelected;
        case "stack":
          return this.isBankerStacksSelected;
        case "scatter":
          return this.isBankerScatterSelected;
        case "case":
          return this.isBankerCaseSelected;
        case "offer":
            return this.isBankerOfferSelected;
      }
    },
    getBankerButtonState(type: BankerFeature){
      let state = '';
      if(this.isBankerFeatureSelected(type)) {
        state += 'btn-toggle-is-selected '
      }
      if( this.isBankerActive(type)){
        if(this.isBankerAllowed(type)) state += 'btn-toggle-is-active'
        else state += 'btn-toggle-is-not-allowed'
      }
      return state;
    },
    availableBankerCasePositions: function(spinOutcome : SpinOutcome){
      if(spinOutcome.gameResponse.caseChase?.phase === 'START'){
        const availPosition: [number, number][] = [];
        spinOutcome.gameResponse.slotWindow.forEach((reelWindow, reelIndex) => {
          const caseRowsOnReel = reelWindow.filter((symbol, rowIndex) => {
            return symbol === 'CASE'
          }).map((_symbol, rowIdx) => rowIdx);
          if(caseRowsOnReel.length === 3){
            availPosition.push([0, reelIndex]);
            availPosition.push([2, reelIndex]);
          } else if (caseRowsOnReel.length === 2){
            availPosition.push([1, reelIndex]);
          }
        });
        return availPosition;
      }
      return [];
    },
    toggleBankerFeature(type: BankerFeature){
      this.isBankerMultiplierSelected = type === 'multiplier' ? !this.isBankerMultiplierSelected : false;
      this.isBankerStacksSelected = type === 'stack' ? !this.isBankerStacksSelected : false;
      this.isBankerScatterSelected = type === 'scatter' ? !this.isBankerScatterSelected : false;
      this.isBankerCaseSelected =  type === 'case' ? !this.isBankerCaseSelected : false;
      this.isBankerOfferSelected = type === 'offer' ? !this.isBankerOfferSelected : false;
    },
    isCaseDisabled(reelIndex:number , rowIndex:number){
      if(this.isGoldPickStep) return true;
      if(this.spinOutcome?.gameState?.caseChase?.phase === 'START'){
        return this.spinOutcome?.gameState?.caseChase?.allPrizes?.find((prize) => { return prize.cell[0] === rowIndex && prize.cell[1] === reelIndex; }) === undefined
      }else {
        return this.currentStep?.gameState?.caseChase?.allPrizes?.find((prize) => { return prize.cell[0] === rowIndex && prize.cell[1] === reelIndex; }) !== undefined
      }
    },
    isGoldCaseSelected(reelIndex:number , rowIndex:number){
      const goldIndex = this.scenario.goldCases.findIndex((goldCase) => { return goldCase.cell[1] === reelIndex && goldCase.cell[0] === rowIndex});
      const prizeInfo = this.currentStep?.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      return goldIndex !== -1 || prizeInfo?.gold !== undefined;
    },
    isGoldCasePicked(reelIndex:number , rowIndex:number){
      const prizeInfo = this.currentStep?.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      return prizeInfo?.gold !== undefined && prizeInfo?.gold !== 'PICK';
    },
    isGoldCasePickable(reelIndex:number , rowIndex:number){
      const prizeInfo = this.currentStep?.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      return prizeInfo?.gold === 'PICK';
   },
    getGoldPickCaseWord(reelIndex:number , rowIndex:number){
      const prizeInfo = this.spinOutcome.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      const prizeInfoFromCurrentStep = this.currentStep?.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      return prizeInfo?.gold ?? prizeInfoFromCurrentStep?.gold ?? 'NOT GOLD';
    },
    getCasePickCaseWord(reelIndex:number , rowIndex:number){
      let prizeInfo = this.currentStep?.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      if(this.isGoldPickStep){
        prizeInfo = this.spinOutcome.gameState?.caseChase?.allPrizes.find(({cell})=> {return cell[1] === reelIndex && cell[0] === rowIndex;})
      }
      if(prizeInfo){
        if(prizeInfo?.prize !== prizeInfo?.value){
          return `${prizeInfo?.prize} (${prizeInfo?.value})`;
        }
        return `${prizeInfo?.prize ?? ''}`;
      }  
      return 'None';
    },
    toggleGoldCase(reelIndex:number , rowIndex:number){
      if(!this.isCaseDisabled(reelIndex,rowIndex)){
        const grandSelected = this.scenario.casePrizes.filter((reelWindow) => {
          return reelWindow.filter((value) => value === this.GRAND_ID).length > 0;
        }).length > 0;
        let numberOfAvailableCell = 0;
        this.scenario.casePrizes.forEach((reelWindow) => {
          numberOfAvailableCell += reelWindow.filter((value) => value === null).length;
        });
        if(!(this.canSelectGrandCase(reelIndex, rowIndex) || grandSelected || (numberOfAvailableCell === 1 && this.currentStep?.gameState?.caseChase?.allPrizes.length === 14))){
          const goldIndex = this.scenario.goldCases.findIndex((goldCase) => { return goldCase.cell[1] === reelIndex && goldCase.cell[0] === rowIndex});
          if(goldIndex === -1){
            if(this.scenario.casePrizes[reelIndex][rowIndex] === null){
              this.scenario.casePrizes[reelIndex][rowIndex] = this.getFirstCasePrizeAvailable() ?? 0;
            }
            this.scenario.goldCases.push({
              cell:[rowIndex, reelIndex],
              feature: 'COLLECT',
            })
            const goldCaseCells = this.scenario.goldCases.map((goldCaseInfo) => goldCaseInfo.cell).sort((a, b) => {
              return a[1] < b [1] ? -1 : a[1] > b [1] ? 1 : a[0] < b [0] ? -1 : a[0] > b [0] ? 1 : 0;
            })
            for(let i = 0 ; i < this.scenario.goldCases.length; i ++ ) {
              this.scenario.goldCases[i].cell = goldCaseCells[i];
            }
          } else {
            this.scenario.goldCases.splice(goldIndex,1);
          }            
        }
      }
    },
    pickGoldCase(reelIndex:number , rowIndex:number){
      this.scenario.goldCasePosition = [rowIndex,reelIndex];
    },
    toggleBankerCase(){
      this.isBankerMultiplierSelected = false;
      this.isBankerStacksSelected = false;
      this.isBankerScatterSelected = false;
      this.isBankerCaseSelected = !this.isBankerCaseSelected;
      this.isBankerOfferSelected = false;
    },
    toggleBankerOffer(){
      this.isBankerMultiplierSelected = false;
      this.isBankerStacksSelected = false;
      this.isBankerScatterSelected = false;
      this.isBankerCaseSelected = false;
      this.isBankerOfferSelected = !this.isBankerOfferSelected;
    },
    toggleCoinPrizes(){
      this.isCoinPrizesSelected = !this.isCoinPrizesSelected;
    },
    addClearingStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "clear",
        reelStripPositions: [0, 0, 0, 0, 0],
      });
    },
    addStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [3, 5, 0, 0, 3],
      });
    },
    addFreeSpinStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [72, 72, 72, 5, 5],
      });
    },
    addBankerOffer() {
      bus.$emit(events.ADD_STEP, {
        name: "Bank offer ",
        originatorId,
        gameState: "continue",
        reelStripPositions: this.scenario.reelStripPositions.map(({value})=> value),
        isOfferAwarded: true,
      });
    },
    acceptOffer() {
      this.scenario.hasAcceptedBankerOffer = true;
      if(this.currentStep) this.currentStep.json.name = 'Bank offer accepted';
    },
    declineOffer() {
      this.scenario.hasAcceptedBankerOffer = false;
      if(this.currentStep) this.currentStep.json.name = 'Bank offer declined';
    },
    addCasePrize() {
      const goldCases = this.isNextStepAGoldPick ? this.scenario.goldCases : undefined;
      bus.$emit(events.ADD_STEP, {
        name: goldCases !== undefined ? "Gold pick" : "Case chase",
        originatorId,
        gameState: "continue",
        reelStripPositions: this.scenario.reelStripPositions.map(({value})=> value),
        casePrizes: this.scenario.casePrizes,
        slotWindow: this.currentStep?.gameState?.slotWindow,
      });
    },
    addCaseChaseTrigger() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [22, 54, 54, 46, 31],
        slotWindow: this.currentStep?.gameState?.slotWindow,
        casePrizes: [[null, null, null],[5, 10, 20],[ 30, 40, 50],[null, null, null],[null, null, null]]
      });
    },
    addJackpot() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        gameState: "continue",
        reelStripPositions: [54, 54, 54, 54, 54],
        jackpot: this.scenario.jackpot
      });
    },
  
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {

        if (this.currentStepIndex === undefined) {
          return;
        }
        const convertedJson = convertToStepJson(newScenario);
        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertedJson,
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}
function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
    isBankerStacksOfferAwarded: step.json.isBankerStacksOfferAwarded ?? false,
    bankerStacksSymbol: step.json.bankerStacksSymbol ?? 'NONE',
    bankerStacksReels: step.json.bankerStacksReels ?? [],
    isBankerMultiplierOfferAwarded: step.json.isBankerMultiplierOfferAwarded ?? false,
    bankerMultiplierAmount: step.json.bankerMultiplierAmount ?? 0,
    bankerScat:  step.json.bankerScat ?? { cell: [0, 0], symbol: 'NONE' },
    bankerCase:  step.json.bankerCase ?? { cell: [0, 0], symbol: 'NONE' },
    isOfferAwarded: step.json.isOfferAwarded ?? false,
    bankerOfferWinAmount: step.json.bankerOfferWinAmount ?? 0,
    hasAcceptedBankerOffer: step.json.hasAcceptedBankerOffer ?? undefined,
    casePrizes: step.json.casePrizes ?? [[null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null]],
    goldCases: step.json.goldCases ?? [],
    goldCasePosition: step.json.goldCasePosition,
    jackpot: step.json.jackpot ??  'GRAND',
  };
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  return {
    gameState: scenario.gameState,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
    isBankerMultiplierOfferAwarded: scenario.bankerMultiplierAmount > 0,
    bankerMultiplierAmount: scenario.bankerMultiplierAmount,
    isBankerStacksOfferAwarded: scenario.bankerStacksSymbol !== 'NONE',
    bankerStacksSymbol: scenario.bankerStacksSymbol === 'NONE' ? undefined: scenario.bankerStacksSymbol,
    bankerStacksReels: scenario.bankerStacksReels,
    bankerScat: scenario.bankerScat.symbol !== 'NONE'? scenario.bankerScat: undefined,
    bankerCase: scenario.bankerCase.symbol !== 'NONE'? scenario.bankerCase: undefined,
    isOfferAwarded: scenario.bankerOfferWinAmount > 0,
    bankerOfferWinAmount: scenario.bankerOfferWinAmount,
    hasAcceptedBankerOffer: scenario.hasAcceptedBankerOffer,
    casePrizes: scenario.casePrizes,
    goldCasePosition: scenario.goldCasePosition,
    goldCases: scenario.goldCases,
    jackpot: scenario.jackpot
  };
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;

  for (const step of steps) {
    if (step.json.gameState === "clear") gameState = undefined;

    step.gameState = gameState ? {...gameState} : undefined;

    // Set the default gold pick if none has been set for this spin.
    let goldCasePick: [number, number] | undefined = undefined;
    if(step.gameState?.caseChase?.goldCasePrizes){
      const allPickGoldCases = step.gameState.caseChase.allPrizes.filter(({gold})=>{ return gold === 'PICK'});
      goldCasePick = allPickGoldCases[0]?.cell;

      if(step.json.goldCasePosition){
        const isPositionValid = allPickGoldCases.find(({cell}) =>{ 
          return step.json.goldCasePosition?.[0] === cell[0] && step.json.goldCasePosition?.[1] === cell[1];
        });
        if(isPositionValid){
          goldCasePick = step.json.goldCasePosition;
        } 
      }
    }

    // Set the default bank offer selection to decline if none has been set for this spin.
    if(step.gameState?.bankerOfferAmount && step.gameState.bankerOfferAmount > 0 && step.json.hasAcceptedBankerOffer === undefined){
      step.json.hasAcceptedBankerOffer = false;
      step.json.name = 'Bank offer declined'
    }

    // Check if the GRAND is awarded and ensure that the provided JSON is correct.
    const grandPosition = [-1, -1];
    const grandIsSelected = step.json.casePrizes?.find((reelWindow, reelIndex) => {
      grandPosition[1] = reelIndex;
      return reelWindow.find((value, rowIndex) => { 
        grandPosition[0] = rowIndex;
        return value === GRAND_ID
      });
    });
    if(grandIsSelected) {
        // Remove any gold cases if GRAND is awarded on this spin.
        if(step.json.goldCases && step.json.goldCases.length > 0){
          step.json.goldCases = undefined;
        }
        // Ensure that the GRAND is at the left and bottom most position.
        const allPositions = [
          [0,0],[1,0],[2,0],
          [0,1],[1,1],[2,1],
          [0,2],[1,2],[2,2],
          [0,3],[1,3],[2,3],
          [0,4],[1,4],[2,4]
        ];
        //step.gameState?.caseChase?.allPrizes.forEach(({cell}) => {
        for(const {cell} of step.gameState?.caseChase?.allPrizes ?? []){
          const indexCell = allPositions.findIndex((cellPos) => {
            return cellPos[1] === cell[1] && cellPos[0] === cell[0];
          })
          if(indexCell !== -1){
            allPositions.splice(indexCell,1);
          }
        }
        if(allPositions.length > 0){
          const casePositionToSwapWithGrand = allPositions.pop();
          if(casePositionToSwapWithGrand && step.json.casePrizes){
            const prize = step.json.casePrizes[casePositionToSwapWithGrand[1]][casePositionToSwapWithGrand[0]];
            step.json.casePrizes[casePositionToSwapWithGrand[1]][casePositionToSwapWithGrand[0]] = GRAND_ID;
            step.json.casePrizes[grandPosition[1]][grandPosition[0]] = prize;
          }
        }
    }

    const spinOutput = model.play(
      {
        coinType: "SC",
        gameRequest: { 
          coinAmount: 1, 
          hasAcceptedBankerOffer: step.json?.hasAcceptedBankerOffer,  
          goldCasePick,
        },
        gameState,
      },
      createChoicesFromScenario({
        ...step.json,
      })
    );
    gameState = spinOutput.gameState;
    // Readjust the step json coinPrize to always match the new game state.
    if(gameState?.caseChase){
      step.json.name = goldCasePick ? 'Gold pick' : 'Case chase';
      const casePrizes: (number | null)[][] = [[null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null]];
      const casePrizesAlreadySelected:(number)[] = [];
      const casePrizesAvailable = [5, 10, 20, 30, 40, 50, 80, 100, 120, 150, 200, 400, 800, 2000];

      gameState.caseChase?.allPrizes.forEach(({gold, cell, prize})=>{
        let prizeToSet: number | null = null;
        if(prize === 'GRAND'){
          prizeToSet = GRAND_ID;
        }
        else if(prize !== undefined){
          if( casePrizesAvailable.length > 0 && casePrizesAlreadySelected.indexOf(prize) !== -1){
            prizeToSet = casePrizesAvailable[0];
            casePrizesAvailable.splice(0,1);
          } else {
            prizeToSet = prize;
            const availablePrizeIndex = casePrizesAvailable.indexOf(prize);
            if(availablePrizeIndex !== -1){
              casePrizesAvailable.splice(availablePrizeIndex,1);
            }             
          }
          casePrizesAlreadySelected.push(prizeToSet);
        }
        if(gold){
          casePrizes[cell[1]][cell[0]] = step.json.casePrizes?.[cell[1]][cell[0]] ?? null;
          if (gold !== 'PICK'){
            casePrizes[cell[1]][cell[0]] = prizeToSet;
          }
        } else {
           casePrizes[cell[1]][cell[0]] = prizeToSet;
        }
      });
      step.json.casePrizes = casePrizes;
    }
  }
  return modified;
}

function getWinCells({
  lineWins,
  playLines,
}: {
  lineWins: LineWin[];
  playLines: number[][];
}) {
  const cells: [number, number][] = [];
  for (const lineWin of lineWins) {
    const playLineIndex = lineWin.playLineIndex;
    const playLine = playLines[playLineIndex];

    for (const [reelIndex, rowIndex] of playLine.entries()) {
      cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
      if (reelIndex < lineWin.length) {
        cells[reelIndex][rowIndex] = 1;
      }
    }
  }
  return cells;
}

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

export interface InternalScenario {
  reelStripPositions: { value: number }[];
  gameState?: "continue" | "clear";
  isBankerMultiplierOfferAwarded: boolean
  bankerMultiplierAmount: number
  isBankerStacksOfferAwarded: boolean
  bankerStacksSymbol: 'WILD' | 'CASE' | 'NONE'
  bankerStacksReels: number[]
  bankerScat: {cell:[number, number], symbol: string}
  bankerCase: {cell:[number, number], symbol: string}
  isOfferAwarded: boolean,
  bankerOfferWinAmount: number
  hasAcceptedBankerOffer: boolean | undefined
  casePrizes: (number | null)[][]
  goldCasePosition?: [number, number];
  goldCases: {
    cell: [number, number];
    feature: 'COLLECT' | 'MULTIPLYx2' | 'MULTIPLYx3' | 'MULTIPLYx4' | 'MULTIPLYx5' | 'BOOST';
  }[];

  jackpot?: 'GRAND'
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;