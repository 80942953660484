export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [5, 5, 5, 5, 5],
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [5, 5, 5, 5, 5],
  };
}

export function createFreeSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [32, 22, 25, 9, 5],
  };
}

export const originatorId = new Date().getTime();
