import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: ["value"],
})
export default class SelectBonus6Prize extends Vue {
    constructor() {
        super();
    }

    public updateBonus6Prize(event) {
        this.$emit("input", event.target.value);
    }
}
