var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("Base")
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("BaseCoins")
              },
            },
          },
          [_vm._v("CoinsFeature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FSTrigger")
              },
            },
          },
          [_vm._v("FreeSpin Trigger (Random wilds)")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FreeSpinStep")
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.baseOption,
              expression: "baseOption",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "select-style" }, [
            _c("label", { attrs: { for: "base_number" } }, [
              _vm._v("WILD 3rd Reel Feature: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.wildOptionChanging,
                    expression: "wildOptionChanging",
                  },
                ],
                staticStyle: {
                  color: "#00c700",
                  "font-weight": "bold",
                  "font-size": "small",
                },
                attrs: { id: "base_number" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.wildOptionChanging = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fsShow,
                        expression: "fsShow",
                      },
                    ],
                    attrs: { value: "2x" },
                  },
                  [_vm._v("2x Multiplier")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { value: "3x" } }, [
                  _vm._v("3x Multiplier"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4x" } }, [
                  _vm._v("4x Multiplier"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Random" } }, [
                  _vm._v("Random Wild"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild1" } }, [
                  _vm._v("Wild Reel 1"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild2" } }, [
                  _vm._v("Wild Reel 2"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild4" } }, [
                  _vm._v("Wild Reel 4"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild5" } }, [
                  _vm._v("Wild Reel 5"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.baseOption,
                  expression: "baseOption",
                },
              ],
              staticClass: "elemOK pointer",
              on: {
                click: function ($event) {
                  return _vm.updateBaseStep(_vm.wildOptionChanging)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.wildOption,
              expression: "wildOption",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "select-style" }, [
            _c("label", { attrs: { for: "wild_number" } }, [
              _vm._v("WILD 3rd Reel Feature: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.wildOptionChanging,
                    expression: "wildOptionChanging",
                  },
                ],
                staticStyle: {
                  color: "#00c700",
                  "font-weight": "bold",
                  "font-size": "small",
                },
                attrs: { id: "wild_number" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.wildOptionChanging = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fsShow,
                        expression: "fsShow",
                      },
                    ],
                    attrs: { value: "2x" },
                  },
                  [_vm._v("2x Multiplier")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { value: "3x" } }, [
                  _vm._v("3x Multiplier"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4x" } }, [
                  _vm._v("4x Multiplier"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Random" } }, [
                  _vm._v("Random Wild"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild1" } }, [
                  _vm._v("Wild Reel 1"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild2" } }, [
                  _vm._v("Wild Reel 2"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild4" } }, [
                  _vm._v("Wild Reel 4"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild5" } }, [
                  _vm._v("Wild Reel 5"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.wildOption,
                  expression: "wildOption",
                },
              ],
              staticClass: "elemOK pointer",
              on: {
                click: function ($event) {
                  return _vm.updateFSStep(_vm.wildOptionChanging)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.coinFeatureOption,
              expression: "coinFeatureOption",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "select-style" }, [
            _c("label", { attrs: { for: "bg_number" } }, [_vm._v("Coins: ")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.coinsFeature,
                    expression: "coinsFeature",
                  },
                ],
                staticStyle: {
                  color: "#00c700",
                  "font-weight": "bold",
                  "font-size": "small",
                },
                attrs: { id: "bg_number" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.coinsFeature = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.coinDefaultShow,
                        expression: "coinDefaultShow",
                      },
                    ],
                    attrs: { value: "50" },
                  },
                  [_vm._v("50")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { value: "60" } }, [_vm._v("60")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "80" } }, [_vm._v("80")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "120" } }, [_vm._v("120")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "125" } }, [_vm._v("125")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "150" } }, [_vm._v("150")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "160" } }, [_vm._v("160")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "175" } }, [_vm._v("175")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "180" } }, [_vm._v("180")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "200" } }, [_vm._v("200")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "225" } }, [_vm._v("225")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "250" } }, [_vm._v("250")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "300" } }, [_vm._v("300")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "500" } }, [_vm._v("500")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.coinFeatureOption,
                  expression: "coinFeatureOption",
                },
              ],
              staticClass: "elemOK pointer",
              on: {
                click: function ($event) {
                  return _vm.updateCoinsStep(_vm.coinsFeature)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }