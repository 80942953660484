var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseFeatureGameTrigger()
              },
            },
          },
          [_vm._v("Base - Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addCaseTwoStep()
              },
            },
          },
          [_vm._v("Case Two Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center stack-selector-wrapper" }, [
        _c("div", { staticClass: "flex-center stack-selector" }, [
          _c("label", { attrs: { for: "fullStackOfOdinReel" } }, [
            _vm._v("Full stack of odin reel: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fullStackOfOdinReel,
                  expression: "fullStackOfOdinReel",
                },
              ],
              attrs: { id: "fullStackOfOdinReel" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.fullStackOfOdinReel = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateFullStackOfOdinReel(
                      _vm.fullStackOfOdinReel
                    )
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }