var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addPickStep()
              },
            },
          },
          [_vm._v("Pick Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPick,
              expression: "!isPick",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c(
            "div",
            {
              class: ["button", "step-button", "pointer", ""],
              on: {
                click: function ($event) {
                  return _vm.toggleFeature()
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPick,
              expression: "!isPick",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPick,
              expression: "!isPick",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Expanding wild reel: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectExpandingWild", {
                  key: value,
                  attrs: { index: value, expandingWild: _vm.expandingWild },
                })
              }),
              _vm._v(" "),
              _c("br"),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature && !_vm.isPick,
              expression: "isFeature && !isPick",
            },
          ],
          staticClass: "flex-center",
        },
        [_c("label", [_vm._v(" Bonus wild symbol: ")])]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature && !_vm.isPick,
              expression: "isFeature && !isPick",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.bonusWild,
                  expression: "bonusWild",
                },
              ],
              attrs: { id: "bonus_wild" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.bonusWild = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBonusWild(_vm.bonusWild)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "None" } }, [_vm._v("None")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "O" } }, [_vm._v("O")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "P" } }, [_vm._v("P")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Y" } }, [_vm._v("Y")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPick,
              expression: "isPick",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.numOfFreeSpins,
                  expression: "numOfFreeSpins",
                },
              ],
              attrs: { id: "fs_num" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.numOfFreeSpins = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateNumOfFreeSpinsWon(_vm.numOfFreeSpins)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "16" } }, [_vm._v("16")]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }