/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: 'tf129',

        gameIds: ['tf129'],
        coinSizeMultiplier: 10,
        reelsLayout: [3, 3, 3, 3, 3],
        wildSymbol: 'WILD',
        scatterSymbol: 'SCAT',
        coinSymbol: 'COIN',
        emptySymbol: 'BLANK',

        countToTriggerFreeSpin: 3,
        respinsCount: 3,
        countToTriggerRespins: 6,
        totalSymbolNum: 15,

        playLines: [
            [0, 0, 0, 0, 0],
            [1, 1, 1, 1, 1],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],

            [1, 0, 0, 0, 1],
            [1, 2, 2, 2, 1],
            [0, 0, 1, 2, 2],
            [2, 2, 1, 0, 0],
            [1, 1, 2, 1, 1],
        ],

        // prettier-ignore
        reels: [
            ['PICA','A','K','PICA','A','Q','PICA','A','K','PICA','A','COIN','PICA','Q','J','PICA','Q','J','PICB','COIN','COIN','PICB','Q','10','PICC','Q','10','PICC','Q','10','PICD','Q','10','PICD','Q','SCAT','10','PICD','Q','10','PICD','Q','10','PICD','Q','10','PICD','Q','10','PICD','Q','10','PICD','Q','SCAT','PICD','10','9','PICD','10','9'],
            ['PICB','COIN','COIN','PICA','A','K','PICA','A','Q','WILD','A','K','PICB','A','K','PICB','A','K','WILD','A','K','PICB','A','K','PICC','J','A','COIN','PICC','J','WILD','Q','J','PICC','10','9','PICC','10','9','PICD','J','9','PICD','J','9','PICD','J','9','PICD','COIN','COIN','COIN','J','9','SCAT','J','9','SCAT','J','9','COIN','J','WILD'],
            ['WILD','PICC','A','K','WILD','A','K','PICC','A','K','WILD','A','K','PICA','COIN','PICC','PICA','PICD','COIN','COIN','PICA','A','PICD','K','WILD','Q','PICD','J','PICC','K','Q','PICA','K','Q','PICA','K','Q','PICA','K','SCAT','Q','PICD','K','Q','PICD','10','9','PICD','10','WILD','9','PICB','10','9','PICB','K','9','PICB','K','9','PICB','SCAT','J','PICB','K','J','PICC','K','J','PICC','9','J','PICC','WILD','J','PICC','SCAT','PICD','10','9','PICD','10','9','PICB','COIN','COIN','COIN','PICC','A','K','PICD','10','9'],
            ['COIN','WILD','PICA','A','K','PICA','A','K','WILD','K','Q','WILD','10','9','PICB','10','9','PICB','COIN','COIN','PICB','10','9','PICB','10','9','PICC','COIN','COIN','COIN','PICC','A','K','WILD','A','K','PICA','J','WILD','10','PICA','K','WILD','Q','PICD','K','PICC','10','PICD','K','SCAT','J','PICD','K','J','WILD','K','J','PICD','SCAT','PICC','J','Q','WILD','J','Q'],
            ['COIN','J','9','WILD','J','9','PICA','A','K','PICA','A','K','PICB','K','Q','PICB','COIN','PICA','A','K','PICA','A','K','COIN','COIN','J','9','PICC','A','K','PICC','A','Q','PICB','J','9','COIN','COIN','COIN','J','9','PICB','A','Q','PICC','A','Q','PICC','J','9','SCAT','Q','10','PICD','Q','10','PICD','WILD','10','PICD','SCAT','10','PICD']
        ],

        // prettier-ignore
        freeSpinExtra5Reels: 
        [
            ['PICA','PICA','PICA','COIN','COIN','PICB','PICB','PICB','COIN','PICC','PICD','PICC','COIN','COIN','PICD','PICA','PICB','PICC','COIN','PICD','PICD','PICA','SCAT','PICC','PICD','PICD','PICA','PICA','PICA','COIN','PICD','PICD','PICD','PICD','PICA','PICA','PICA','PICD','PICD','PICD','PICD','PICD','PICD','PICA','PICD','PICD','PICD','PICD'],
            ['COIN','COIN','PICB','PICB','PICB','COIN','PICB','PICB','PICB','COIN','PICB','PICB','PICB','COIN','COIN','COIN','WILD','PICA','PICA','PICA','WILD','PICA','PICC','PICD','PICD','WILD','PICC','PICD','PICD','WILD','PICC','PICD','PICD','COIN','COIN','PICC','PICD','PICD','SCAT','PICC','PICC','PICC','PICC'],
            ['COIN','COIN','COIN','PICA','PICA','PICA','PICA','PICA','PICA','COIN','COIN','PICA','PICA','PICA','COIN','PICA','PICA','PICA','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','PICC','PICD','PICD','SCAT','PICC','PICD','PICD','SCAT','PICC','PICD','PICD','PICC','PICC','PICC'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICB','PICB','PICB','PICC','PICD','COIN','PICB','PICB','PICB','COIN','COIN','PICC','PICC','PICC','WILD','PICD','PICD','WILD','PICD','PICD','SCAT','PICD','PICD','PICD','PICD','PICD','PICC','PICC','PICD','PICD','PICD','PICD','PICD','PICD'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICC','WILD','PICB','PICB','PICC','PICD','COIN','PICD','PICD','PICD','COIN','COIN','PICD','WILD','PICD','PICC','PICC','PICC','PICC','PICD','PICD','PICD','PICD','PICD','PICD','PICD','PICD']
        ],

        // prettier-ignore
        freeSpinExtra15Reels: 
        [
            ['PICA','PICA','PICA','COIN','COIN','PICB','PICB','PICB','PICC','PICD','PICC','PICD','PICB','PICB','PICC','PICD','PICD','PICA','SCAT','PICC','PICD','PICD','PICA','PICA','PICA','PICD','PICD','PICD','PICB','PICB','PICB','PICD','COIN','PICD','PICD','PICB'],
            ['COIN','COIN','PICB','PICB','PICB','COIN','PICB','PICB','PICB','COIN','PICA','PICA','PICC','COIN','COIN','COIN','WILD','PICC','PICD','PICD','WILD','PICC','PICD','SCAT','PICD','PICC'],
            ['COIN','COIN','COIN','PICA','PICA','PICA','PICA','PICA','PICA','COIN','COIN','PICA','PICA','PICA','COIN','PICA','PICA','PICA','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','PICC','PICD','PICD','SCAT','PICC','PICD','PICD','COIN','COIN','COIN','PICC','PICD','PICD','PICC','PICC','PICC'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICB','PICB','PICB','COIN','COIN','COIN','PICC','PICD','COIN','PICB','PICB','PICB','COIN','COIN','PICC','PICC','PICC','WILD','PICD','PICD','WILD','PICD','PICD','COIN','COIN','COIN','PICD','PICD','PICD','PICD','PICD','PICC','PICC'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICC','WILD','PICB','PICB','COIN','COIN','COIN','PICC','PICD','COIN','PICD','PICD','PICD','COIN','COIN','PICD','WILD','PICD','PICC','COIN','PICC','PICC','PICC','COIN','COIN','COIN','PICD','PICD'],
        ],

        // prettier-ignore
        freeSpinExtra30Reels: 
        [
            ['PICA','PICA','PICA','COIN','COIN','PICA','PICD','PICD','COIN','PICC','PICD','COIN','PICD','PICB','SCAT','PICB','PICD','PICD','SCAT','PICC','PICD','COIN','PICD','PICA','PICA','COIN'],
            ['COIN','COIN','PICB','PICB','SCAT','PICB','COIN','PICB','PICB','PICB','COIN','PICA','PICA','PICC','COIN','COIN','COIN','WILD','PICC','PICD','PICD','WILD','PICC','PICD','SCAT','PICD','PICC'],
            ['COIN','COIN','COIN','PICA','PICA','PICA','COIN','COIN','COIN','PICA','PICA','PICA','COIN','COIN','PICA','PICA','PICA','COIN','PICA','PICA','PICA','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','WILD','PICB','PICB','PICB','SCAT','PICC','PICD','PICD','SCAT','PICC','PICD','PICD','COIN','COIN','COIN','PICC','PICD','SCAT','PICD','PICC','COIN','COIN','COIN','PICC','PICC'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICB','PICB','PICB','COIN','COIN','COIN','PICC','PICD','COIN','PICB','PICB','SCAT','PICB','COIN','COIN','PICC','PICC','PICC','WILD','PICD','PICD','WILD','PICD','PICD','COIN','COIN','COIN','PICD','PICD','COIN','COIN','COIN','PICD','PICD','PICD','PICC','PICC'],
            ['SCAT','PICA','PICA','COIN','COIN','COIN','PICC','WILD','COIN','COIN','COIN','PICB','PICB','COIN','COIN','COIN','PICC','PICD','COIN','PICD','SCAT','PICD','PICD','COIN','COIN','PICD','WILD','PICD','PICD','COIN','PICC','PICC','PICC','COIN','COIN','COIN','PICC','PICD'],
        ],
    },
};
