import { modelDefinition } from "../config";
import { createSlotWindowGenerator } from "../create-slot-window-generator";
import { getWinType } from "./get-win-type";
import { SpinInputs } from "./spin-inputs";
import { SpinOutputs } from "./spin-outputs";
import { checkScatterWin } from "./check-scatter-win";
import { evaluateLineWinsWithWilds } from "./evaluate-line-wins-with-wilds";
import { updateSlotWindowWithWilds } from "./update-slot-window-with-wilds";
import { Wild } from "../wild";
import { GameState } from "../game-state";
import { computeWinAmountForFeatureSpin } from "./compute-win-amount-for-feature-spin";
import { computeJackpotWinCounts } from "./compute-jackpot-win-counts";

const SPIN_TYPE = "WILD_FURY";

export function wildFurySpin(inputs: SpinInputs): SpinOutputs {
  const { coinTypeId, coinSize, choices } = inputs;
  const previousCumulativeWinAmount =
    inputs.gameState?.cumulativeWinAmount ?? 0;
  const previousWilds = inputs.gameState?.wilds ?? [];
  let wildFuryScatterWins = inputs.gameState?.wildFuryScatterWins ?? 0;

  // Generate the slot window
  const totalPlayAmount = coinSize * coinSizeMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions(SPIN_TYPE);
  const mysterySymbol = choices.chooseMysterySymbol(SPIN_TYPE);
  const baseSlotWindow = generateSlotWindow(reelStripPositions, mysterySymbol);

  // Substitute wilds
  const { slotWindow, wilds, newWilds } = updateSlotWindowWithWilds(
    SPIN_TYPE,
    coinTypeId,
    totalPlayAmount,
    choices,
    baseSlotWindow,
    previousWilds,
  );

  // Evaluate line wins
  const { winLines, lineWins, winCells } = evaluateLineWinsWithWilds(
    slotWindow,
    coinSize,
    wilds,
  );

  // Evaluate scatter win
  const scatterWin = checkScatterWin(baseSlotWindow);
  if (scatterWin) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount);
    winCells.push(scatterWin.cells);
  }

  // Compute win type and amount
  const jackpotWinCounts = computeJackpotWinCounts(newWilds);
  const { totalWinAmount, jackpotWinAmount, cumulativeWinAmount } =
    computeWinAmountForFeatureSpin(
      totalPlayAmount,
      previousCumulativeWinAmount,
      jackpotWinCounts,
      lineWins,
    );
  const winType = getWinType({
    totalWinAmount,
    isFreeSpinTrigger: !!scatterWin,
    isWildFury: true,
    jackpotWinCounts,
  });

  // Calculate new game state
  if (scatterWin) {
    wildFuryScatterWins++;
  }

  const gameState = calculateNextGameState({
    coinSize,
    reelStripPositions,
    slotWindow,
    mysterySymbol,
    cumulativeWinAmount,
    wilds,
    newWilds,
    wildFuryScatterWins,
  });

  return {
    spinOutcome: {
      coinSize,
      totalPlayAmount,
      reelStripPositions,
      slotWindow,
      totalWinAmount,
      winType,
      winLines,
      lineWins,
      winCells,
      mysterySymbol,

      freeSpinCount: gameState?.freeSpinCount,
      freeSpinPhase: gameState?.freeSpinPhase,
      wildFuryPhase: newWilds.length > 0 ? "IN_PROGRESS" : "END",
      wildFuryScatterWins,
      wilds,
      newWilds,
      cumulativeWinAmount,
      jackpotWinAmount: jackpotWinAmount || undefined,
    },
    gameState,
    jackpotWinCounts: {
      GRAND: jackpotWinCounts.GRAND,
      MAJOR: jackpotWinCounts.MAJOR,
    },
  };
}

// Generate helper functions tailored to the model definition.
const { coinSizeMultiplier } = modelDefinition;
const generateSlotWindow = createSlotWindowGenerator(modelDefinition.wildFury);

type CalculateNextStateInputs = {
  coinSize: number;
  reelStripPositions: number[];
  slotWindow: string[][];
  mysterySymbol: string;
  cumulativeWinAmount: number;
  wilds: Wild[];
  newWilds: Wild[];
  wildFuryScatterWins: number;
};

function calculateNextGameState({
  coinSize,
  reelStripPositions,
  slotWindow,
  mysterySymbol,
  cumulativeWinAmount,
  wilds,
  newWilds,
  wildFuryScatterWins,
}: CalculateNextStateInputs): GameState | undefined {
  if (newWilds.length > 0) {
    return {
      coinSize,
      reelStripPositions,
      slotWindow,
      mysterySymbol,
      cumulativeWinAmount,
      wilds,
      newWilds,
      wildFuryPhase: "IN_PROGRESS",
      wildFuryScatterWins,
    };
  } else if (wildFuryScatterWins > 0) {
    return {
      coinSize,
      reelStripPositions,
      slotWindow,
      mysterySymbol,
      cumulativeWinAmount,
      wilds: [],
      newWilds: [],
      freeSpinPhase: "START",
      freeSpinCount: wildFuryScatterWins * modelDefinition.freeSpinTriggerCount,
    };
  } else {
    return undefined;
  }
}
