/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf005_pugroyale",
        "scatterSymbol": "WI",
        "wildSymbol": "WI",
        "freeSpinTrigger": "WS",
        "wildSymbols": ["WI1", "WI2"],
        "wsSymbols": ["WS1", "WS2"],

        "countToTriggerFreeSpin" : 3,
        "bonesCountToTriggerFreeSpin" : 8,

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 5, 10, 15, 25 ],
        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"1500000",

        "coinType4_min_bet":"0.1",
        "coinType4_max_bet":"25",
        "coinType4_spin_max_bet":"1000",
        "reels": [
            [ "LV5", "LV5", "HV1", "HV1", "LV4", "LV4", "LV3", "LV3", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "LV4", "LV4", "LV5", "LV5", "LV2", "HV3", "WI", "LV2", "LV2", "LV2", "LV5", "HV4", "HV4", "HV4", "HV4", "HV2", "HV1", "WI", "LV2", "LV5", "LV5", "LV4", "LV4", "LV3", "HV3", "HV1", "HV2", "HV1", "LV4", "LV1", "LV1", "LV3", "LV3", "HV2", "HV2", "LV4", "LV4", "LV4", "HV3", "LV4", "HV3", "WS", "LV5", "LV5", "HV1", "HV1", "LV4", "LV4", "LV3", "LV3", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "LV4", "LV4", "LV5", "LV5", "LV2", "HV3", "LV1", "LV2", "LV2", "LV2", "LV5", "HV4", "HV4", "HV4", "HV4", "HV2", "HV1", "HV2", "LV2", "LV5", "LV5", "LV4", "LV4", "LV3", "WI", "HV1", "HV2", "HV1", "LV4", "LV1", "LV1", "LV3", "LV3", "HV2", "HV2", "LV4", "LV4", "LV4", "WI", "LV4", "HV3", "LV5", "LV5", "HV1", "HV1", "LV4", "LV4", "LV3", "LV3", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "LV4", "LV4", "LV5", "LV5", "LV2", "HV3", "LV1", "LV2", "LV2", "LV2", "LV5", "HV4", "HV4", "HV4", "HV4", "HV2", "HV1", "HV2", "LV2", "LV5", "LV5", "LV4"],
            [ "LV2", "LV2", "LV1", "LV1", "HV3", "LV3", "LV1", "LV5", "WI", "LV3", "LV1", "LV1", "HV2", "HV1", "HV3", "HV1", "LV1", "HV1", "LV4", "LV4", "LV5", "LV3", "LV3", "WI", "HV2", "LV2", "HV2", "LV2", "LV2", "HV3", "LV5", "LV5", "LV3", "LV3", "LV3", "HV1", "LV5", "LV3", "LV3", "HV2", "HV2", "HV4", "HV4", "HV4", "HV4", "LV4", "LV3", "LV4", "HV1", "LV5", "LV5", "WI", "HV3", "LV1", "LV1", "LV1" ],
            [ "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2", "LV4", "HV1", "HV1", "HV4", "HV4", "HV4", "HV4", "LV4", "LV5", "LV4", "HV1", "HV3", "LV3", "LV2", "LV3", "HV2", "HV3", "HV2", "LV3", "WS", "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2", "LV4", "HV1", "HV1", "HV4", "HV4", "HV4", "HV4", "LV4", "LV5", "LV4", "HV1", "HV3", "LV3", "LV2", "LV3", "HV2", "HV3", "HV2", "LV3", "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2" ],
            [ "LV2", "LV2", "LV5", "HV1", "LV1", "LV4", "LV1", "LV2", "HV3", "LV2", "HV2", "HV1", "HV2", "LV1", "LV5", "HV1", "LV3", "LV2", "LV1", "LV2", "WI", "LV3", "LV3", "LV3", "HV3", "LV1", "LV5", "LV1", "LV4", "LV4", "LV3", "LV1", "LV3", "LV4", "LV5", "LV2", "HV1", "HV2", "HV2", "LV5", "LV5", "HV1", "HV2", "HV1", "HV3", "LV4", "HV3", "LV4", "LV4", "HV4", "HV4", "HV4", "HV4", "LV5", "LV4", "LV4" ],
            [ "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3", "HV1", "LV5", "LV5", "LV5", "HV1", "HV4", "HV4", "HV4", "HV4", "WI", "LV4", "LV4", "LV4", "HV2", "LV3", "HV2", "LV3", "LV1", "LV3", "WS", "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3", "HV1", "LV5", "LV5", "LV5", "HV1", "HV4", "HV4", "HV4", "HV4", "WI", "LV4", "LV4", "LV4", "HV2", "LV3", "HV2", "LV3", "LV1", "LV3", "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3" ]
        ],
        "reelsBaseCascade": [
            [ "LV5", "LV5", "HV1", "WI", "LV4", "LV4", "LV3", "WI", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "WI", "LV4", "LV5", "LV5", "LV2", "HV3", "WI", "LV2", "LV2", "LV2", "LV5", "WI", "HV4", "HV4", "HV4", "HV2", "HV1", "WI", "LV2", "LV5", "WI", "LV4", "LV4", "LV3", "WI", "HV1", "HV2", "HV1", "LV4", "WI", "LV1", "LV3", "LV3", "WI", "HV2", "LV4", "LV4", "LV4", "WI", "LV4", "HV3", "WS", "LV5", "LV5", "HV1", "WI", "LV4", "LV4", "LV3", "WI", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "WI", "LV4", "LV5", "LV5", "LV2", "HV3", "WI", "LV2", "LV2", "LV2", "LV5", "WI", "HV4", "HV4", "HV4", "HV2", "HV1", "WI", "LV2", "LV5", "WI", "LV4", "LV4", "LV3", "WI", "HV1", "HV2", "HV1", "LV4", "WI", "LV1", "LV3", "LV3", "WI", "HV2", "LV4", "LV4", "LV4", "WI", "LV4", "HV3", "LV5", "LV5", "HV1", "WI", "LV4", "LV4", "LV3", "WI", "LV2", "LV2", "LV1", "WI", "HV1", "LV2", "LV5", "WI", "LV4", "LV5", "WI", "LV2", "HV3", "WI", "LV2", "LV2", "LV2", "LV5", "WI", "HV4", "HV4", "HV4", "HV2", "HV1", "WI", "LV2", "LV5", "WI", "LV4" ],
            [ "LV2", "WI", "LV1", "LV1", "WI", "LV3", "LV1", "LV5", "WI", "LV3", "LV1", "LV1", "WI", "HV1", "HV3", "HV1", "LV1", "WI", "LV4", "LV4", "WI", "LV3", "LV3", "WI", "HV2", "LV2", "HV2", "LV2", "LV2", "WI", "LV5", "LV5", "WI", "LV3", "LV3", "WI", "LV5", "LV3", "LV3", "WI", "HV2", "HV4", "HV4", "HV4", "WI", "LV4", "LV3", "LV4", "WI", "LV5", "LV5", "WI", "HV3", "LV1", "LV1", "LV1" ],
            [ "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2", "LV4", "HV1", "HV1", "HV4", "HV4", "HV4", "HV4", "LV4", "LV5", "LV4", "HV1", "HV3", "LV3", "LV2", "LV3", "HV2", "HV3", "HV2", "LV3", "WS", "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2", "LV4", "HV1", "HV1", "HV4", "HV4", "HV4", "HV4", "LV4", "LV5", "LV4", "HV1", "HV3", "LV3", "LV2", "LV3", "HV2", "HV3", "HV2", "LV3", "LV4", "LV4", "LV1", "LV1", "LV3", "LV3", "LV3", "LV2", "HV3", "LV5", "HV2", "HV2", "HV1", "HV1", "LV5", "LV4", "LV1", "LV4", "LV4", "LV2", "LV2", "LV1", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV4", "HV3", "HV2", "LV5", "LV5", "HV1", "LV2", "LV4", "LV2" ],
            [ "LV2", "LV2", "LV5", "HV1", "LV1", "LV4", "LV1", "LV2", "HV3", "LV2", "HV2", "HV1", "HV2", "LV1", "LV5", "HV1", "LV3", "LV2", "LV1", "LV2", "WI", "LV3", "LV3", "LV3", "HV3", "LV1", "LV5", "LV1", "LV4", "LV4", "LV3", "LV1", "LV3", "LV4", "LV5", "LV2", "HV1", "HV2", "HV2", "LV5", "LV5", "HV1", "HV2", "HV1", "HV3", "LV4", "HV3", "LV4", "LV4", "HV4", "HV4", "HV4", "HV4", "LV5", "LV4", "LV4" ],
            [ "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3", "HV1", "LV5", "LV5", "LV5", "HV1", "HV4", "HV4", "HV4", "HV4", "WI", "LV4", "LV4", "LV4", "HV2", "LV3", "HV2", "LV3", "LV1", "LV3", "WS", "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3", "HV1", "LV5", "LV5", "LV5", "HV1", "HV4", "HV4", "HV4", "HV4", "WI", "LV4", "LV4", "LV4", "HV2", "LV3", "HV2", "LV3", "LV1", "LV3", "LV1", "LV3", "LV1", "LV1", "HV2", "LV2", "LV5", "LV2", "HV1", "LV3", "LV2", "LV5", "LV4", "LV4", "LV1", "LV5", "HV3", "HV1", "HV3", "LV1", "LV2", "LV1", "LV3", "HV1", "LV5", "LV5", "LV1", "LV3", "LV3", "HV2", "HV2", "LV2", "LV2", "LV2", "HV3", "HV1", "HV3" ]
        ],
        "featureReels": [
            [ "LV6", "LV6", "HV5", "HV5", "WI", "LV9", "LV8", "LV8", "LV7", "LV7", "LV6", "WI", "HV5", "LV7", "LV7", "LV9", "WI", "LV8", "LV8", "LV7", "HV7", "WI", "LV7", "LV7", "LV7", "LV9", "WI", "HV8", "HV8", "HV8", "HV6", "WI", "HV6", "LV7", "LV8", "LV8", "LV9", "WI", "LV8", "HV7", "HV5", "HV6", "HV5", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV6", "LV9", "LV9", "LV9", "WI", "LV9", "HV7" ],
            [ "LV7", "LV7", "LV6", "WI", "HV7", "LV8", "LV6", "LV8", "LV8", "WI", "LV6", "LV6", "HV6", "HV5", "WI", "HV5", "LV6", "HV5", "LV9", "WI", "LV7", "LV8", "LV8", "WI", "HV6", "LV7", "HV6", "LV7", "LV7", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV5", "HV5", "LV8", "LV8", "HV6", "HV6", "HV8", "HV8", "HV8", "WI", "LV9", "LV8", "LV9", "HV5", "HV6", "WI", "HV5", "HV7", "LV6", "LV6", "LV6", "WS", "LV7", "LV7", "LV6", "WI", "HV7", "LV8", "LV6", "LV8", "LV8", "WI", "LV6", "LV6", "HV6", "HV5", "WI", "HV5", "LV6", "HV5", "LV9", "WI", "LV7", "LV8", "LV8", "WI", "HV6", "LV7", "HV6", "LV7", "LV7", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV5", "HV5", "LV8", "LV8", "HV6", "HV6", "HV8", "HV8" ],
            [ "LV9", "LV9", "LV6", "LV6", "LV8", "LV8", "LV8", "LV7", "HV7", "HV6", "HV6", "HV6", "HV5", "HV5", "LV9", "LV9", "LV6", "LV9", "LV9", "LV7", "LV7", "LV6", "LV8", "LV8", "LV8", "LV7", "LV7", "LV7", "LV9", "HV7", "HV6", "LV6", "LV6", "HV5", "LV7", "LV9", "LV7", "LV9", "HV5", "HV5", "HV8", "HV8", "HV8", "HV8", "LV9", "LV8", "LV8", "HV5", "HV7", "LV8", "LV7", "LV8", "HV6", "HV7", "HV6", "LV8" ],
            [ "LV7", "LV7", "LV7", "HV5", "LV6", "LV9", "LV6", "LV7", "HV7", "LV7", "HV6", "HV5", "HV6", "LV6", "HV5", "HV5", "LV8", "LV7", "LV6", "LV7", "WI", "LV8", "LV8", "LV8", "HV7", "LV6", "HV7", "LV6", "LV9", "LV9", "LV8", "LV6", "LV8", "LV9", "LV9", "LV7", "HV5", "HV6", "HV6", "LV8", "LV8", "HV5", "HV6", "HV5", "HV7", "LV9", "HV7", "LV9", "LV9", "HV8", "HV8", "HV8", "HV8", "HV6", "LV9", "LV9", "WS", "LV7", "LV7", "LV7", "HV5", "LV6", "LV9", "LV6", "LV7", "HV7", "LV7", "HV6", "HV5", "HV6", "LV6", "HV5", "HV5", "LV8", "LV7", "LV6", "LV7", "WI", "LV8", "LV8", "LV8", "HV7", "LV6", "HV7", "LV6", "LV9", "LV9", "LV8", "LV6", "LV8", "LV9", "LV9", "LV7", "HV5", "HV6", "HV6", "LV8", "LV8", "HV5", "HV6" ],
            [ "LV6", "LV8", "LV6", "LV6", "HV6", "LV7", "LV7", "LV7", "HV5", "LV8", "LV7", "LV9", "LV9", "LV9", "LV6", "HV6", "HV7", "HV5", "HV7", "LV6", "LV7", "LV6", "LV8", "HV5", "LV6", "LV6", "LV6", "LV8", "LV8", "HV6", "HV6", "LV7", "LV7", "LV7", "HV7", "HV5", "HV7", "HV5", "LV8", "LV8", "HV5", "HV5", "HV8", "HV8", "HV8", "HV8", "WI", "LV9", "LV9", "LV9", "HV6", "LV8", "HV6", "LV8", "LV6", "LV8" ]
        ],
        "reelsFeatureCascade": [
            [ "LV6", "LV6", "HV5", "HV5", "WI", "LV9", "LV8", "LV8", "LV7", "LV7", "LV6", "WI", "HV5", "LV7", "LV7", "LV9", "WI", "LV8", "LV8", "LV7", "HV7", "WI", "LV7", "LV7", "LV7", "LV9", "WI", "HV8", "HV8", "HV8", "HV6", "WI", "HV6", "LV7", "LV8", "LV8", "LV9", "WI", "LV8", "HV7", "HV5", "HV6", "HV5", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV6", "LV9", "LV9", "LV9", "WI", "LV9", "HV7" ],
            [ "LV7", "LV7", "LV6", "WI", "HV7", "LV8", "LV6", "LV8", "LV8", "WI", "LV6", "LV6", "HV6", "HV5", "WI", "HV5", "LV6", "HV5", "LV9", "WI", "LV7", "LV8", "LV8", "WI", "HV6", "LV7", "HV6", "LV7", "LV7", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV5", "HV5", "LV8", "LV8", "HV6", "HV6", "HV8", "HV8", "HV8", "WI", "LV9", "LV8", "LV9", "HV5", "HV6", "WI", "HV5", "HV7", "LV6", "LV6", "LV6", "WS", "LV7", "LV7", "LV6", "WI", "HV7", "LV8", "LV6", "LV8", "LV8", "WI", "LV6", "LV6", "HV6", "HV5", "WI", "HV5", "LV6", "HV5", "LV9", "WI", "LV7", "LV8", "LV8", "WI", "HV6", "LV7", "HV6", "LV7", "LV7", "WI", "LV6", "LV6", "LV8", "LV8", "WI", "HV5", "HV5", "LV8", "LV8", "HV6", "HV6", "HV8", "HV8" ],
            [ "LV9", "LV9", "LV6", "LV6", "LV8", "LV8", "LV8", "LV7", "HV7", "HV6", "HV6", "HV6", "HV5", "HV5", "LV9", "LV9", "LV6", "LV9", "LV9", "LV7", "LV7", "LV6", "LV8", "LV8", "LV8", "LV7", "LV7", "LV7", "LV9", "HV7", "HV6", "LV6", "LV6", "HV5", "LV7", "LV9", "LV7", "LV9", "HV5", "HV5", "HV8", "HV8", "HV8", "HV8", "LV9", "LV8", "LV8", "HV5", "HV7", "LV8", "LV7", "LV8", "HV6", "HV7", "HV6", "LV8" ],
            [ "LV7", "LV7", "LV7", "HV5", "LV6", "LV9", "LV6", "LV7", "HV7", "LV7", "HV6", "HV5", "HV6", "LV6", "HV5", "HV5", "LV8", "LV7", "LV6", "LV7", "WI", "LV8", "LV8", "LV8", "HV7", "LV6", "HV7", "LV6", "LV9", "LV9", "LV8", "LV6", "LV8", "LV9", "LV9", "LV7", "HV5", "HV6", "HV6", "LV8", "LV8", "HV5", "HV6", "HV5", "HV7", "LV9", "HV7", "LV9", "LV9", "HV8", "HV8", "HV8", "HV8", "HV6", "LV9", "LV9", "WS", "LV7", "LV7", "LV7", "HV5", "LV6", "LV9", "LV6", "LV7", "HV7", "LV7", "HV6", "HV5", "HV6", "LV6", "HV5", "HV5", "LV8", "LV7", "LV6", "LV7", "WI", "LV8", "LV8", "LV8", "HV7", "LV6", "HV7", "LV6", "LV9", "LV9", "LV8", "LV6", "LV8", "LV9", "LV9", "LV7", "HV5", "HV6", "HV6", "LV8", "LV8", "HV5", "HV6" ],
            [ "LV6", "LV8", "LV6", "LV6", "HV6", "LV7", "LV7", "LV7", "HV5", "LV8", "LV7", "LV9", "LV9", "LV9", "LV6", "HV6", "HV7", "HV5", "HV7", "LV6", "LV7", "LV6", "LV8", "HV5", "LV6", "LV6", "LV6", "LV8", "LV8", "HV6", "HV6", "LV7", "LV7", "LV7", "HV7", "HV5", "HV7", "HV5", "LV8", "LV8", "HV5", "HV5", "HV8", "HV8", "HV8", "HV8", "WI", "LV9", "LV9", "LV9", "HV6", "LV8", "HV6", "LV8", "LV6", "LV8" ]
        ],
        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [20, 8, 3]
            }, {
                "symbol": "LV2",
                "multipliers": [20, 8, 3]
            }, {
                "symbol": "LV3",
                "multipliers": [25, 10, 4]
            }, {
                "symbol": "LV4",
                "multipliers": [25, 10, 4]
            }, {
                "symbol": "LV5",
                "multipliers": [30, 12, 5]
            }, {
                "symbol": "HV1",
                "multipliers": [60, 25, 10]
            }, {
                "symbol": "HV2",
                "multipliers": [80, 30, 12]
            }, {
                "symbol": "HV3",
                "multipliers": [100, 40, 15]
            }, {
                "symbol": "HV4",
                "multipliers": [500, 50, 20]
            }
        ],

        "featureWinTable": [
            {
                "symbol": "LV6",
                "multipliers": [20, 8, 3]
            }, {
                "symbol": "LV7",
                "multipliers": [25, 10, 4]
            }, {
                "symbol": "LV8",
                "multipliers": [25, 10, 4]
            }, {
                "symbol": "LV9",
                "multipliers": [30, 12, 5]
            }, {
                "symbol": "LV5",
                "multipliers": [60, 25, 10]
            }, {
                "symbol": "HV6",
                "multipliers": [80, 30, 12]
            }, {
                "symbol": "HV7",
                "multipliers": [100, 40, 15]
            }, {
                "symbol": "HV8",
                "multipliers": [500, 50, 20]
            }
        ],
        "playLines": [
            [0, 0, 0, 0, 0], // 1
            [1, 1, 1, 1, 1], // 2

            [2, 2, 2, 2, 2], // 3
            [3, 3, 3, 3, 3], // 4

            [0, 1, 2, 1, 0], // 5
            [1, 2, 3, 2, 1], // 6

            [2, 1, 0, 1, 2], // 7
            [3, 2, 1, 2, 3], // 8


            [1, 0, 1, 0, 1], // 9
            [0, 1, 0, 1, 0], // 10

            [1, 2, 1, 2, 1], // 11
            [2, 1, 2, 1, 2], // 12

            [2, 3, 2, 3, 2], // 13
            [3, 2, 3, 2, 3], // 14

            [0, 1, 1, 1, 0], // 15
            [1, 0, 0, 0, 1], // 16

            [1, 2, 2, 2, 1], // 17
            [2, 1, 1, 1, 2], // 18

            [2, 3, 3, 3, 2], // 19
            [3, 2, 2, 2, 3], // 20

            [0, 0, 1, 0, 0], // 21
            [1, 1, 0, 1, 1], // 22

            [1, 1, 2, 1, 1], // 23
            [2, 2, 1, 2, 2], // 24

            [2, 2, 3, 2, 2], // 25
            [3, 3, 2, 3, 3], // 26

            [0, 2, 0, 2, 0], // 27
            [2, 0, 2, 0, 2], // 28

            [1, 3, 1, 3, 1], // 29
            [3, 1, 3, 1, 3], // 30

            [0, 2, 2, 2, 0], // 31
            [2, 0, 0, 0, 2], // 32

            [1, 3, 3, 3, 1], // 33
            [3, 1, 1, 1, 3], // 34

            [0, 0, 2, 0, 0], // 35
            [2, 2, 0, 2, 2], // 36

            [1, 1, 3, 1, 1], // 37
            [3, 3, 1, 3, 3], // 38

            [0, 3, 0, 3, 0], // 39
            [3, 0, 3, 0, 3]  // 40
        ],
        "reelsLayout": [4, 4, 4, 4, 4],
        "baseJumboWeight": [200, 800],
        "featureJumboWeight": [250, 750],
        "baseJumboBlockSize": {
            "2x2": 8000,
            "3x3": 1538,
            "4x4": 462
        },
        "featureJumboBlockSize": {
            "2x2": 6015,
            "3x3": 3485,
            "4x4": 500
        },
        "baseJumboSymbols": {
            "LV1": 140,
            "LV2": 140,
            "LV3": 140,
            "LV4": 140,
            "LV5": 140,
            "HV1": 90,
            "HV2": 80,
            "HV3": 80,
            "HV4": 50
        },
        "featureJumboSymbols": {
            "HV5": 25,
            "HV6": 25,
            "HV7": 25,
            "HV8": 25
        },
        "Reels2x2Weight": {
            "0": 250,
            "1": 250,
            "2": 250,
            "3": 250
        },
        "Reel2x2PositionsWeight": {
            "0": 333,
            "1": 333,
            "2": 333
        },
        "Reels3x3Weight": {
            "0": 333,
            "1": 333,
            "2": 333
        },
        "Reel3x3PositionsWeight": {
            "0": 500,
            "1": 500
        },
        "Reels4x4Weight": {
            "0": 500,
            "1": 500
        },
        "Reel4x4PositionsWeight": {
            "0": 1000
        }

    }
    ,
}
