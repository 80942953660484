var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    !_vm.isCaseChaseActive
      ? _c("h2", { staticClass: "heading" }, [
          _vm._v("Scenario Result (Slot Window):"),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isCaseChaseActive
      ? _c(
          "div",
          { ref: "slotWindow", staticClass: "slot-window card" },
          [
            _vm._l(
              _vm.spinOutcome.gameResponse.slotWindow,
              function (reel, colIndex) {
                return _vm._l(reel, function (symbolName, rowIndex) {
                  return _c(
                    "reel-symbol",
                    _vm._g(
                      {
                        key: `${colIndex} ${rowIndex}`,
                        attrs: {
                          position:
                            _vm.spinOutcome.gameResponse.reelStripPositions[
                              colIndex
                            ],
                          colIndex: colIndex,
                          rowIndex: rowIndex,
                          isWinCell:
                            _vm.spinOutcome.gameResponse.winCells?.find(
                              (winCells) => {
                                return (
                                  winCells.find(
                                    (cell) =>
                                      cell[0] === rowIndex &&
                                      cell[1] === colIndex
                                  ) !== undefined
                                )
                              }
                            ) !== undefined,
                          symbolName: symbolName,
                          spinType: _vm.spinType,
                          isBankerScatAllowed:
                            _vm.spinOutcome.gameResponse.bankerScat !==
                            undefined,
                          scatterBankerPos: _vm.scenario.bankerScat.cell,
                          scenario: _vm.scenario,
                          isBankerCaseAllowed:
                            _vm.spinOutcome.gameResponse.bankerCase !==
                              undefined &&
                            _vm.availableBankerCasePositions.find((cell) => {
                              return cell[0] === rowIndex && cell[1] == colIndex
                            }) !== undefined,
                          caseBankerPos: _vm.scenario.bankerCase.cell,
                        },
                      },
                      _vm.$listeners
                    )
                  )
                })
              }
            ),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Text Output):"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("p", [
        _c("b", [_vm._v("Spin Type:")]),
        _vm._v(" " + _vm._s(_vm.spinType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Type:")]),
        _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.winType)),
      ]),
      _vm._v(" "),
      _vm.spinOutcome.gameResponse.winAmount !== undefined &&
      _vm.spinOutcome.gameResponse.winAmount > 0
        ? _c("p", [
            _c("b", [_vm._v("Win Amount:")]),
            _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.winAmount)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.isFreeSpins
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Free Spins Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Free Spin Phase:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.freeSpinPhase)),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Free Spin Count:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.freeSpinCount)),
            ]),
            _vm._v(" "),
            _vm.spinOutcome.gameResponse.cumulativeWinAmount !== undefined &&
            _vm.spinOutcome.gameResponse.cumulativeWinAmount > 0
              ? _c("p", [
                  _c("b", [_vm._v("Cumulative Win Amount")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.spinOutcome.gameResponse.cumulativeWinAmount)
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBankerMultiplier
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Banker Multiplier Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Banker Multiplier Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.bankerMultiplier) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Win Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.winAmount) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBankerStacks
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Banker Stacks Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Banker Stacks Win Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.winAmount) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBankerScat
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Banker Scat Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Banker Scat:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.bankerScat) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Banker Scat Win Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.winAmount) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBankerCase
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Banker Case Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Banker Case:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.bankerCase) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Banker Case Win Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.winAmount) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBankerOffer
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Banker Offer Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Deal Or No Deal:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.hasAcceptedBankerOffer) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Banker Offer Amount:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.bankerOfferAmount) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isCasePrizeAwarded
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Case Chase Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Win Type:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.spinOutcome.gameResponse.winType) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "case-chase-info" },
              [
                _vm.spinOutcome.gameResponse.caseChase
                  ? _vm._l(
                      _vm.spinOutcome.gameResponse.caseChase,
                      function (value, key) {
                        return _c(
                          "div",
                          { key: key, staticClass: "chase-item" },
                          [
                            _c("span", { staticClass: "chase-label" }, [
                              _vm._v(
                                _vm._s(key.replace(/([A-Z])/g, " $1").trim()) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "chase-value" }, [
                              _vm._v(_vm._s(value)),
                            ]),
                          ]
                        )
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("p"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isGoldCases
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Gold Case Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("b", [_vm._v("Gold Case Feature Values:")]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.spinOutcome.gameState?.caseChase?.goldCasePrizes) +
                "\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isJackpot
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Jackpot Information:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("b", [_vm._v("Jackpot Response Values:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.spinOutcome.gameResponse.jackpotWinCounts) +
                  "\n    "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [_c("b", [_vm._v("Case Chase Information:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }