/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf005_legendof9suns",

        "wildSymbol": "Wild",
        "scatterSymbol": "Bonus",
        "freeSpinTrigger": "Bonus",
        "freeSpinCountArray": [10, 15, 20],
        "countToTriggerFreeSpin": 3,

        "winTable": [
            {
                "symbol": "Wild",
                "multipliers": [800, 150, 50]
            },
            {
                "symbol": "HV1",
                "multipliers": [500, 125, 40]
            }, {
                "symbol": "HV2",
                "multipliers": [400, 100, 30]
            }, {
                "symbol": "HV3",
                "multipliers": [250, 75, 25]
            }, {
                "symbol": "MV1",
                "multipliers": [200, 60, 20]
            }, {
                "symbol": "MV2",
                "multipliers": [150, 50, 15]
            }, {
                "symbol": "MV3",
                "multipliers": [100, 30, 15]
            }, {
                "symbol": "Ace",
                "multipliers": [75, 20, 10]
            }, {
                "symbol": "King",
                "multipliers": [75, 20, 10]
            }, {
                "symbol": "Queen",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Jack",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Ten",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "Bonus",
                "multipliers": [10, 4, 1]
            }
        ],

        "coinType1Values": [ 100, 400, 2000, 5000, 10000, 40000,400000 ],
        "coinType4Values": [ 1, 4, 10, 20, 40, 100, 200],

        "coinType1_min_bet":"100",
        "coinType1_max_bet":"400000",
        "coinType1_spin_max_bet":"10000000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"200",
        "coinType4_spin_max_bet":"5000",

        "reelsLayout": [3, 3, 3, 3, 3],
        "reels": [
            [ "Wild", "Jack", "Queen", "HV2", "MV3", "Ace", "King", "MV3", "MV1", "Wild", "HV3", "Queen", "Ace", "MV3", "Jack", "HV3", "Ace", "Queen", "Bonus", "Jack", "Ten", "MV2", "HV2", "Ace", "Queen", "Wild", "Wild", "Wild", "King", "HV1", "MV1", "Jack", "MV2", "HV3", "King", "Queen", "MV2", "HV2", "Ace", "King", "Bonus", "Queen", "Jack", "HV3", "MV1", "Ace", "Jack", "HV3", "Wild", "MV3", "Queen", "HV1", "MV3", "MV2", "Wild", "MV1", "Ten", "MV2", "Jack", "HV1", "Ace", "MV2"],
            [ "MV2", "KingM", "Jack", "Queen", "Bonus", "Ace", "MV2", "JackM", "MV1", "Ace", "QueenM", "HV3M", "HV2M", "Jack", "HV1", "MV3", "MV2", "AceM", "Ten", "King", "Bonus", "Ace", "Queen", "HV2", "Ace", "MV2M", "HV1", "HV1", "HV1", "Queen", "KingM", "HV2", "MV1", "MV3", "Jack", "HV3M", "MV2M", "MV3M", "Queen", "King", "MV2", "HV3", "JackM", "MV1", "HV3", "Jack", "MV3M", "TenM", "MV1M", "HV3", "Queen", "MV3" ],
            [ "Jack", "King", "MV3", "Jack", "QueenM", "AceM", "MV3M", "MV2", "Queen", "HV1", "MV2", "AceM", "King", "MV1", "Queen", "MV2", "HV3M", "Ten", "Queen", "Bonus", "Ace", "Jack", "MV1M", "King", "HV1", "HV1", "HV1", "HV3M", "HV2M", "KingM", "Ace", "MV2", "MV1", "MV3", "MV2M", "Queen", "HV3", "Jack", "HV2", "MV2M", "MV1", "Ace", "TenM", "HV3", "Ace", "JackM", "MV3M", "Queen", "HV2", "Jack", "MV3", "QueenM" ],
            [ "MV2M", "KingM", "Jack", "MV2", "Queen", "King", "Bonus", "Ten", "Jack", "HV2", "Queen", "MV2M", "HV2M", "HV3M", "Jack", "HV1", "Ace", "Queen", "MV2", "MV3", "Jack", "Bonus", "Queen", "Ace", "King", "MV1M", "HV3M", "AceM", "MV2", "HV1", "HV1", "HV1", "JackM", "MV1M", "MV3M", "Queen", "Ace", "MV2", "MV3", "King", "HV2", "MV3", "MV1", "HV3", "Jack", "AceM", "MV3M", "QueenM", "MV1", "HV3", "TenM" ],
            [ "HV1", "Ace", "Queen", "HV2", "MV3", "Jack", "King", "MV2", "MV1", "HV3", "Ace", "MV2", "Queen", "MV3", "MV2", "HV3", "Ace", "Queen", "Bonus", "Jack", "Ten", "MV2", "Ace", "HV2", "Queen", "Wild", "Wild", "Wild", "King", "HV1", "Ten", "HV3", "MV2", "MV1", "King", "Queen", "MV3", "HV2", "Ace", "King", "Bonus", "Queen", "Jack", "HV3", "MV1", "MV2", "Ace", "MV3", "Wild", "Wild", "Wild", "HV1", "Jack", "MV1", "MV3", "HV3"]
        ],

        "playLines": [
            [ 1, 1, 1, 1, 1 ],
            [ 0, 0, 0, 0, 0 ],
            [ 2, 2, 2, 2, 2 ],
            [ 0, 1, 2, 1, 0 ],
            [ 2, 1, 0, 1, 2 ],
            [ 1, 0, 1, 2, 1 ],
            [ 1, 2, 1, 0, 1 ],
            [ 0, 1, 0, 1, 0 ],
            [ 2, 1, 2, 1, 2 ],
            [ 0, 0, 1, 2, 2 ],
            [ 2, 2, 1, 0, 0 ],
            [ 0, 0, 1, 0, 0 ],
            [ 2, 2, 1, 2, 2 ],
            [ 1, 1, 0, 1, 1 ],
            [ 1, 1, 2, 1, 1 ],
            [ 0, 1, 1, 1, 0 ],
            [ 2, 1, 1, 1, 2 ],
            [ 0, 1, 1, 1, 2 ],
            [ 2, 1, 1, 1, 0 ],
            [ 1, 0, 1, 0, 1 ],
            [ 1, 0, 0, 0, 2 ],
            [ 1, 2, 2, 2, 0 ],
            [ 0, 1, 0, 1, 2 ],
            [ 2, 1, 2, 1, 0 ],
            [ 1, 2, 1, 2, 1 ]
        ],
        "mysteryBase": [
            {"symbol": "Symbol", "extraMultiplier": 1, "weight": 3906},
            {"symbol": "Wild", "extraMultiplier": 1, "weight": 119},
            {"symbol": "Wild", "extraMultiplier": 2, "weight": 39},
            {"symbol": "Wild", "extraMultiplier": 3, "weight": 6}
        ],
        "mysteryFS": [
            {"symbol": "Symbol", "extraMultiplier": 1, "weight": 84},
            {"symbol": "Wild", "extraMultiplier": 1, "weight": 25},
            {"symbol": "Wild", "extraMultiplier": 2, "weight": 8},
            {"symbol": "Wild", "extraMultiplier": 3, "weight": 3}
        ],
        "coinsWeight":[
            {"coins": 1, "weight": 2},
            {"coins": 2, "weight": 16},
            {"coins": 3, "weight": 25},
            {"coins": 4, "weight": 25},
            {"coins": 5, "weight": 12},
            {"coins": 6, "weight": 13},
            {"coins": 7, "weight": 4},
            {"coins": 8, "weight": 2},
            {"coins": 9, "weight": 1}
        ],
        "coinValuesWeight":[
            {"value": 1, "bet": 25, "weight": 5},
            {"value": 1.6, "bet": 40, "weight": 6},
            {"value": 1.8, "bet": 45, "weight": 6},
            {"value": 2, "bet": 50, "weight": 7},
            {"value": 2.4, "bet": 60, "weight": 7},
            {"value": 2.8, "bet": 70, "weight": 7},
            {"value": 3, "bet": 75, "weight": 8},
            {"value": 3.6, "bet": 90, "weight": 7},
            {"value": 4, "bet": 100, "weight": 8},
            {"value": 4.2, "bet": 105, "weight": 4},
            {"value": 4.4, "bet": 110, "weight": 4},
            {"value": 5, "bet": 125, "weight": 8},
            {"value": 5.2, "bet": 130, "weight": 5},
            {"value": 5.6, "bet": 140, "weight": 4},
            {"value": 6, "bet": 150, "weight": 4},
            {"value": 6.4, "bet": 160, "weight": 3},
            {"value": 6.8, "bet": 170, "weight": 2},
            {"value": 7, "bet": 175, "weight": 2},
            {"value": 8, "bet": 200, "weight": 2},
            {"value": 10, "bet": 250, "weight": 1}
        ],

        "bonusWeight": [
            {"value": 1, "weight": 1},
            {"value": 0, "weight": 268}
        ]
    },
}
