var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.step
    ? _c(
        "div",
        { staticClass: "vgw089__outer" },
        [
          _c("new-steps"),
          _vm._v(" "),
          _c("step-controls", {
            attrs: {
              step: _vm.internalStep,
              spinOutcome: _vm.spinOutcome,
              gameState: _vm.gameState,
            },
          }),
          _vm._v(" "),
          _c("slot-window", {
            attrs: { spinOutcome: _vm.spinOutcome },
            on: {
              "reel-scroll": _vm.onReelScroll,
              "modifier-changed": _vm.onModifierChanged,
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "vgw089__outer" }, [
        _c("h2", [_vm._v("Please create a scenario.")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }