// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-window[data-v-3629f9ec] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5em;
  justify-items: center;
  background: #707070;
  padding: 1em;
  box-shadow: 0 4px 8px 0 #707070;
  padding: 1em;
  border-radius: 0.5em;
  background: #707070;
}
.slot-window > div[data-v-3629f9ec] {
  text-align: center;
  width: 100%;
  aspect-ratio: 1;
  background: #707070;
  color: lightgray;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;
  box-shadow: inset 0 0 10px #555555;
  font-size: 0.8em;
  max-height: 100px;
}
.card-generic-info[data-v-3629f9ec] {
  background-color: #404040;
  color: lightgray;
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 1em 0;
}
.heading[data-v-3629f9ec] {
  color: lightgray;
}
.case-chase-info[data-v-3629f9ec] {
  margin-top: 8px;
  padding: 10px;
  border-radius: 4px;
  background-color: #404040;
}
.chase-item[data-v-3629f9ec] {
  margin: 4px 0;
}
.chase-label[data-v-3629f9ec] {
  font-weight: 500;
  margin-right: 8px;
  text-transform: capitalize;
}
.chase-value[data-v-3629f9ec] {
  color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/dealOrNoDeal/slot-window/slot-window.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,UAAU;EACV,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,+BAA+B;EAC/B,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,gBAAgB;;EAEhB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,oBAAoB;EACpB,kCAAkC;EAClC,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,wCAAwC;EACxC,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,WAAW;AACb","sourcesContent":[".slot-window[data-v-3629f9ec] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 0.5em;\n  justify-items: center;\n  background: #707070;\n  padding: 1em;\n  box-shadow: 0 4px 8px 0 #707070;\n  padding: 1em;\n  border-radius: 0.5em;\n  background: #707070;\n}\n.slot-window > div[data-v-3629f9ec] {\n  text-align: center;\n  width: 100%;\n  aspect-ratio: 1;\n  background: #707070;\n  color: lightgray;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 0.5em;\n  box-shadow: inset 0 0 10px #555555;\n  font-size: 0.8em;\n  max-height: 100px;\n}\n.card-generic-info[data-v-3629f9ec] {\n  background-color: #404040;\n  color: lightgray;\n  padding: 0.5em;\n  border-radius: 0.5em;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  margin: 1em 0;\n}\n.heading[data-v-3629f9ec] {\n  color: lightgray;\n}\n.case-chase-info[data-v-3629f9ec] {\n  margin-top: 8px;\n  padding: 10px;\n  border-radius: 4px;\n  background-color: #404040;\n}\n.chase-item[data-v-3629f9ec] {\n  margin: 4px 0;\n}\n.chase-label[data-v-3629f9ec] {\n  font-weight: 500;\n  margin-right: 8px;\n  text-transform: capitalize;\n}\n.chase-value[data-v-3629f9ec] {\n  color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
