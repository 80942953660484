import {
  CoinType,
  MathModelError,
  createCoinSizeValidator,
} from "../../../../v3/math-model";
import { modelDefinition } from "../config";

export function ensureSpinInputsAreValid(inputs: {
  coinTypeId: CoinType;
  coinSize: number;
}): void {
  const { coinTypeId, coinSize } = inputs;

  if (!isValidCoinSize(coinTypeId, coinSize)) {
    throw new MathModelError("Invalid coin size", { coinTypeId, coinSize });
  }
}

const isValidCoinSize = createCoinSizeValidator(modelDefinition);
