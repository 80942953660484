import type { Choices } from '../../choices/index';
import { pickGoldCase, respin } from './case-chase/index';
import type { SpinOutcome } from './outcome';
import type { SpinInputs } from './inputs';
import { assertSpinInputsValid } from './inputs';
import { spinFree } from './spin-free';
import { spinBase } from './spin-base';
import { bankerOfferSpin } from './pick/banker-offer-spin';

export { GameRequestSchema, type GameRequest } from './game-request';
export { GameResponseSchema, type GameResponse } from './game-response';
export type { SpinOutcome, SpinInputs };

export function play(spinInputs: SpinInputs, choices: Choices): SpinOutcome {
    assertSpinInputsValid(spinInputs);

    const caseChase = spinInputs?.gameState?.caseChase;
    const goldCasePrizes = caseChase?.goldCasePrizes ?? [];
    const goldCasePicks = caseChase?.goldCasePicks ?? [];
    const isGoldCasePick = goldCasePicks.length < goldCasePrizes.length;
    const isCaseChase = Boolean(caseChase?.respins);
    const isFreeSpins = Boolean(spinInputs.gameState?.freeSpinCount);
    const isBankerOfferPick = spinInputs.gameRequest.hasAcceptedBankerOffer !== undefined;

    if (isGoldCasePick) return pickGoldCase(spinInputs, choices);
    if (isCaseChase) return respin(spinInputs, choices);
    if (isFreeSpins) return spinFree(spinInputs, choices);
    if (isBankerOfferPick) return bankerOfferSpin(spinInputs);
    return spinBase(spinInputs, choices);
}
