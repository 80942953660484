export function getWinType(info: {
    winAmount: number;
    isGenieBonusTrigger?: boolean;
    isGenieBonusSpin?: boolean;
    isGrandJackpotTrigger?: boolean;
}) {
    if (info.isGenieBonusTrigger) return 'SCATTER_WIN';

    if (info.isGrandJackpotTrigger) return 'JACKPOT_WIN';

    if (info.isGenieBonusSpin) {
        return info.winAmount > 0 ? 'FREE_SPIN_WIN' : 'FREE_SPIN_NO_WIN';
    }
    return info.winAmount > 0 ? 'NORMAL_WIN' : 'NO_WIN';
}