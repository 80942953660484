import type { RandomSource } from "@vgw/gdk-math-model-server";
import type { BaseSpinTypeChoices, Choices, GenieBonusSpinTypeChoices, SpinType } from "./choices";
import { modelDefinition } from "../model-definition/index";
import { createWeightedOutcomeSelector } from "@vgw/gdk-math-model-tools";
import type { FeatureColour, Position } from "../operations/shared/index";

export function createRandomChoices({ getRandomFromRange }: RandomSource): Choices {
    return {
        base: createRandomChoicesFromDefinition({
            getRandomFromRange,
            modelDefinition: modelDefinition.base,
        }),
        genie: createGenieBonusRandomChoicesFromDefinition({
            getRandomFromRange,
            modelDefinition: modelDefinition.base,
        }),
    };
}

function createGenieBonusRandomChoicesFromDefinition(options: {
    /**
     * Callback used to generate a random number between `min` and `max` inclusive, with uniform probability across
     * numbers in the range.
     */
    getRandomFromRange: (range: { min: number; max: number }) => number;

    modelDefinition: {
        reels: string[][];
    };
}): GenieBonusSpinTypeChoices {
    const featureTriggerSelector = [0, 1, 2, 3, 4].map((scatterCount) =>
        createWeightedOutcomeSelector({
            weightedOutcomes: modelDefinition.genieBonusTriggerWeights[scatterCount],
            getRandomFromRange: options.getRandomFromRange,
        }),
    );
    const genieBonusLampSelector = [0, 1, 2].map((triggerCount) =>
        createWeightedOutcomeSelector({
            weightedOutcomes: modelDefinition.genieBonusLampWeights[triggerCount],
            getRandomFromRange: options.getRandomFromRange,
        }),
    );

    const greenFeatureNumberOfPrizesSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.genieGreenFeatureNumberOfCoinPrizesWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    const greenFeatureFixedJackpotSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.genieGreenFeatureFixedJackpotWeights,
        getRandomFromRange: options.getRandomFromRange,
    });
    const createCoinWeightBasedOutcomeSelector = (args: {
        weightedOutcomes: {
            weight: "COIN_WEIGHT" | number;
            outcome: number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" | "FEATURE";
        }[];
        coinWeight: number;
        getRandomFromRange: (range: { min: number; max: number }) => number;
    }) => {
        const { weightedOutcomes, coinWeight } = args;
        return createWeightedOutcomeSelector({
            weightedOutcomes: weightedOutcomes.map((weightedOutcome) => {
                const weight = weightedOutcome.weight !== "COIN_WEIGHT" ? weightedOutcome.weight : coinWeight;
                return {
                    ...weightedOutcome,
                    weight,
                };
            }),
            getRandomFromRange: options.getRandomFromRange,
        });
    };

    return {
        chooseTriggeringCoinPrize(coinPrizeOptions: {
            coinWeight: number;
        }): number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" {
            const { coinWeight } = coinPrizeOptions;
            const weights = modelDefinition.coinPrizes.TRIGGER;

            const selector = createCoinWeightBasedOutcomeSelector({
                coinWeight,
                weightedOutcomes: weights,
                getRandomFromRange: options.getRandomFromRange,
            });

            const result = selector();
            if (result === "FEATURE") throw Error("Cannot get feature on trigger");
            return result;
        },
        chooseDoesCoinLand({ chance, outOf }: { chance: number; outOf: number }): boolean {
            return options.getRandomFromRange({ min: chance, max: outOf }) <= chance;
        },
        chooseRespinCoinPrize(coinPrizeOptions: {
            coinWeight: number;
            existingFeatureCount: number;
        }): number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" | "FEATURE" {
            const { existingFeatureCount, coinWeight } = coinPrizeOptions;

            const weights = modelDefinition.coinPrizes.RESPIN;

            const selector = createCoinWeightBasedOutcomeSelector({
                coinWeight,
                weightedOutcomes: weights,
                getRandomFromRange: options.getRandomFromRange,
            });

            let outcome: number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "FEATURE" | "JACKPOT_MAJOR" | undefined;
            do {
                outcome = selector();
                if (
                    existingFeatureCount >= modelDefinition.maxRespinFeatures &&
                    (outcome === "FEATURE" || outcome === "JACKPOT_MAJOR")
                ) {
                    outcome = undefined;
                }
            } while (outcome === undefined);

            return outcome;
        },
        choosePurpleTriggeringCoinPrize(coinPrizeOptions: {
            coinWeight: number;
        }): number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" {
            const { coinWeight } = coinPrizeOptions;

            const weightedOutcomeExcludingFeatures = modelDefinition.coinPrizes.COIN_ONLY;
            const selector = createCoinWeightBasedOutcomeSelector({
                coinWeight,
                weightedOutcomes: weightedOutcomeExcludingFeatures,
                getRandomFromRange: options.getRandomFromRange,
            });

            const result = selector();
            if (result === "FEATURE") throw Error("Cannot get feature on trigger");
            return result;
        },
        chooseTriggerCount(scatters: Position[]): number {
            if (scatters.length === 0) return 0;

            return featureTriggerSelector[scatters.length - 1]();
        },
        chooseFeatureColour(triggerCount: number): FeatureColour[] {
            if (triggerCount === 0) return [];

            return genieBonusLampSelector[triggerCount - 1]() as unknown as FeatureColour[];
        },
        chooseRespinFeatureColour(validColours: FeatureColour[]): FeatureColour {
            const index = options.getRandomFromRange({ min: 0, max: validColours.length - 1 });
            return validColours[index];
        },
        chooseHardPosition(hardPositionOptions: { availablePositions: Position[] }) {
            const { availablePositions } = hardPositionOptions;
            const randomPositionIndex = options.getRandomFromRange({ min: 0, max: availablePositions.length - 1 });
            return availablePositions[randomPositionIndex];
        },
        chooseRandomColorPosition(params: { array: unknown[]; position: Position; isFeature: boolean }): number {
            const { array } = params;
            const min = 0;
            const max = array.length - 1;
            return options.getRandomFromRange({ min, max });
        },
        chooseRandomPosition(array: unknown[]): number {
            const min = 0;
            const max = array.length - 1;
            return options.getRandomFromRange({ min, max });
        },
        chooseRandomScatterPosition(params: { array: unknown[]; triggeringColour: FeatureColour }): number {
            const min = 0;
            const max = params.array.length - 1;
            return options.getRandomFromRange({ min, max });
        },
        chooseNumberOfGreenFeatureCoinPrizes() {
            return greenFeatureNumberOfPrizesSelector();
        },
        chooseGreenFeatureCoinPrize(params: { existingCoinPrizes: number[]; position: Position; prizeIndex: number }) {
            const { existingCoinPrizes } = params;
            const allCoinPrizesWithWeights = modelDefinition.genieGreenFeatureCoinPrizeWeights;
            const availableCoinPrizeWeights = allCoinPrizesWithWeights.filter(
                (item) => !existingCoinPrizes.includes(item.outcome),
            );
            const selector = createWeightedOutcomeSelector({
                weightedOutcomes: availableCoinPrizeWeights,
                getRandomFromRange: options.getRandomFromRange,
            });
            return selector();
        },
        chooseGreenFeatureFixedJackpot(): "JACKPOT_MINI" | "JACKPOT_MINOR" | undefined {
            const chance = modelDefinition.genieGreenFeatureJackpotTriggerWeights.chance;
            const outOf = modelDefinition.genieGreenFeatureJackpotTriggerWeights.outOf;
            const isJackpotTriggered = options.getRandomFromRange({ min: chance, max: outOf }) <= chance;

            if (isJackpotTriggered) {
                return greenFeatureFixedJackpotSelector();
            }
        },
    };
}

function createRandomChoicesFromDefinition(options: {
    /**
     * Callback used to generate a random number between `min` and `max` inclusive, with uniform probability across
     * numbers in the range.
     */
    getRandomFromRange: (range: { min: number; max: number }) => number;

    modelDefinition: {
        reels: string[][];
    };
}): BaseSpinTypeChoices {
    const mysterySymbolChoicesBySpinType = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.mysterySymbolWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    return {
        chooseReelStripPositions() {
            return options.modelDefinition.reels.map((reel) =>
                options.getRandomFromRange({ min: 0, max: reel.length - 1 }),
            );
        },
        chooseMysterySymbol() {
            return mysterySymbolChoicesBySpinType();
        },
    };
}
