/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf117",
        reelsLayout: [5, 5, 5, 5, 5, 5],
        totalSymbolNum: 28,

        krakenSymbol: "E",
        captainSymbol: "B",
        respinNoWinSymbol: "X",
        freeSpinTrigger: "S",

        // prettier-ignore
        reels : [
			['N','T','Q','Q','C','E','Q','D','D','E','A','D','N','K','A','A','A','D','A','A','T','T','T','B','Q','Q','T','T','Q','Q','N','N','C','K','K','J','T','T','E','J','J','J'],
			['D','C','C','J','J','K','K','E','Q','Q','J','J','S','N','N','N','N','C','C','K','T','T','E','E','D','N','N','J','K','K','B','J','J','S','T','K','K','K','J','J','N','E','E','S','N','N','A','A'],
			['J','K','K','C','K','K','N','S','E','A','A','A','A','Q','Q','Q','S','T','B','Q','D','D','D','T','T','Q','Q','Q','Q','D','C','E','S','N','N','T','T','J','J','T','T','T','T','A','A','A',],
			['T','N','B','S','J','J','J','J','S','A','A','K','N','Q','Q','E','E','J','J','K','K','K','K','C','C','C','J','J','N','N','E','E','E','N','N','D','D','T','T','K','K','N','N'],
			['A','A','A','T','E','E','N','N','N','A','A','N','S','E','Q','Q','Q','Q','C','J','J','D','D','T','T','T','S','K','Q','A','T','T','T','C','J','J','D','D','S','T','B','Q','Q','K','K'],
			['N','N','J','J','J','N','N','N','K','D','J','K','K','J','J','N','N','Q','Q','B','T','T','N','T','T','A','A','K','K','K','E','C','K','E','E','D','Q','C','C','C','E','E','J','J']
		],
        // prettier-ignore
        topReel : [ 'N','T','Q','N','J','N','J','T','A','N','E','Q','T','C','E','N','T','A','Q','A','D','C','K','T','A','K','J','T','Q','B','J','K','E','K','D','J','E','Q','D','K','N','J','N' ]
    }
};
