var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "multi-select" }, [
    _c(
      "div",
      {
        staticClass: "select-box",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleDropdown.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "selected-items" }, [
          _vm.selectedValues.length !== 0
            ? _c("span", [
                _vm._v(_vm._s(_vm.selectedValues.length) + " selected"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedValues.length === 0
            ? _c("span", [_vm._v("select prizes")])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow" }, [_vm._v("▾")]),
      ]
    ),
    _vm._v(" "),
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "options",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          _vm._l(_vm.options, function (option) {
            return _c("label", { key: option }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedValues,
                    expression: "selectedValues",
                  },
                ],
                attrs: { disabled: !_vm.isEditable, type: "checkbox" },
                domProps: {
                  value: option,
                  checked: Array.isArray(_vm.selectedValues)
                    ? _vm._i(_vm.selectedValues, option) > -1
                    : _vm.selectedValues,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.selectedValues,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectedValues = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectedValues = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectedValues = $$c
                      }
                    },
                    _vm.emitSelection,
                  ],
                },
              }),
              _vm._v("\n      " + _vm._s(option) + "\n    "),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }