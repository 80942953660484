/**
 * Returns a function that checks whether the given lines selection is valid for the given model definition.
 */
export function createLinesValidator(modelDefinition: {
  playLines?: number[][];
}) {
  return function isValidLineSelection(selectedLines: number[]): boolean {
    if (
      !Array.isArray(selectedLines) ||
      selectedLines.some((index) => typeof index !== "number")
    )
      return false;
    const allLines = modelDefinition.playLines ?? [];
    if (allLines.length !== selectedLines.length) return false; // Given lines length matches model lines length
    const minLineIndex = 0;
    const maxLineIndex = allLines.length - 1;
    const alreadySeenLineIndices = new Set<number>();
    for (const lineIndex of selectedLines) {
      if (Math.floor(lineIndex) !== lineIndex) return false; // line is not an integer
      if (lineIndex < minLineIndex || lineIndex > maxLineIndex) return false; // line is out of range
      if (alreadySeenLineIndices.has(lineIndex)) return false; // line is repeated
      alreadySeenLineIndices.add(lineIndex);
    }
    return true;
  };
}
