/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "mayangold",
        "scatterSymbol": "FS",
        "wildSymbol": "WI",
        "bonusSymbol": "BO",
        "freeSpinTrigger": "FS",
        "countToTriggerBonusGame": 3,
        "wildBaseWinMultiplier": 2,
        "countToTriggerFreeSpin" : 3,
        "freeSpinCountList": [5, 10, 20], //3 SC -> 5 FS ; 5 SC -> 20 FS
        "winTable": [
          {
            "symbol": "WI",
            "multipliers": [5000, 1500, 150, 10]
          }, {
            "symbol": "MP",
            "multipliers": [1000, 150, 15]
          }, {
            "symbol": "PR",
            "multipliers": [1000, 150, 12]
          }, {
            "symbol": "HE",
            "multipliers": [600, 120, 12]
          }, {
            "symbol": "SN",
            "multipliers": [500, 100, 10]
          }, {
            "symbol": "AC",
            "multipliers": [300, 60, 5]
          }, {
            "symbol": "KI",
            "multipliers": [200, 50, 3]
          }, {
            "symbol": "QU",
            "multipliers": [150, 40, 3]
          }, {
            "symbol": "JA",
            "multipliers": [100, 30, 2]
          }, {
            "symbol": "TE",
            "multipliers": [100, 30, 2]
          }
        ],
      
        "reelsLayout": [3, 3, 3, 3, 3],
        "playLines": [
          [1, 1, 1, 1, 1], // 1
          [0, 0, 0, 0, 0], // 2
          [2, 2, 2, 2, 2], // 3
          [0, 1, 2, 1, 0], // 4
          [2, 1, 0, 1, 2], // 5
          [0, 0, 1, 0, 0], // 6
          [2, 2, 1, 2, 2], // 7
          [1, 0, 0, 0, 1], // 8
          [1, 2, 2, 2, 1], // 9
          [0, 1, 1, 1, 0], // 10
          [2, 1, 1, 1, 2], // 11
          [0, 1, 0, 1, 0], // 12
          [2, 1, 2, 1, 2], // 13
          [1, 0, 1, 0, 1], // 14
          [1, 2, 1, 2, 1], // 15
          [1, 1, 0, 1, 1], // 16
          [1, 1, 2, 1, 1], // 17
          [0, 2, 0, 2, 0], // 18
          [2, 0, 2, 0, 2], // 19
          [1, 0, 2, 0, 1], // 20
          [1, 2, 0, 2, 1], // 21
          [0, 0, 2, 0, 0], // 22
          [2, 2, 0, 2, 2], // 23
          [0, 2, 2, 2, 0], // 24
          [2, 0, 0, 0, 2], // 25
          [2, 0, 1, 0, 2], // 26
          [0, 2, 1, 2, 0], // 27
          [2, 1, 0, 0, 1], // 28
          [0, 1, 2, 2, 1], // 29
          [1, 0, 1, 2, 1]  // 30
        ],
      
        "bonus":{
          "allSymbol":"A",
          "multiplierRound": 2,
          "noLoseRound": 0,
          "rounds":[
            ["1", "1", "1", "2", "0", "A"],
            ["0", "0", "1", "2", "3", "A"],
            ["0", "0", "2", "2", "3", "4"],
            ["0", "0", "2", "3", "5", "A"],
            ["0", "0", "0", "5", "10", "A"],
            ["0", "0", "0", "10", "15", "A"]
          ]
        },
      
        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],
      
        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"3000000",
      
        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"1500",
      
        "reels": [
          ["TE", "AC", "PR", "BO", "KI", "PR", "WI", "QU", "HE", "PR", "MP", "TE", "PR", "JA", "QU", "FS", "PR", "HE", "SN", "TE", "AC", "HE", "MP", "WI", "QU", "MP", "KI", "JA", "PR", "TE", "SN", "JA", "MP", "HE", "AC", "SN", "KI", "TE", "JA", "PR"],
          ["HE", "TE", "JA", "AC", "MP", "JA", "WI", "KI", "PR", "AC", "TE", "MP", "KI", "QU", "MP", "WI", "KI", "JA", "TE", "KI", "JA", "SN", "KI", "FS", "QU", "SN", "PR", "TE", "SN", "PR", "MP", "HE", "AC", "TE", "SN", "BO", "JA", "PR", "QU", "SN"],
          ["KI", "QU", "HE", "SN", "QU", "TE", "WI", "JA", "TE", "BO", "QU", "HE", "KI", "SN", "JA", "FS", "QU", "AC", "KI", "TE", "AC", "HE", "KI", "QU", "JA", "HE", "TE", "FS", "PR", "TE", "AC", "KI", "HE", "AC", "JA", "TE", "AC", "HE", "QU", "MP"],
          ["SN", "TE", "JA", "HE", "AC", "TE", "MP", "QU", "TE", "BO", "JA", "TE", "SN", "HE", "JA", "FS", "KI", "AC", "TE", "KI", "AC", "BO", "QU", "KI", "JA", "QU", "WI", "JA", "AC", "KI", "QU", "JA", "TE", "PR", "FS", "AC", "QU", "MP", "KI", "PR"],
          ["QU", "TE", "PR", "SN", "JA", "AC", "WI", "KI", "JA", "QU", "AC", "TE", "BO", "SN", "KI", "FS", "QU", "JA", "SN", "BO", "HE", "JA", "MP", "SN", "TE", "PR", "AC", "WI", "TE", "PR", "KI", "BO", "HE", "JA", "FS", "AC", "MP", "SN", "HE", "PR"]
        ],
      
        "featureReels" : [
          ["TE", "AC", "PR", "BO", "KI", "PR", "WI", "QU", "HE", "PR", "MP", "TE", "PR", "JA", "QU", "FS", "PR", "HE", "SN", "TE", "AC", "HE", "MP", "WI", "QU", "MP", "KI", "JA", "PR", "TE", "SN", "JA", "MP", "HE", "AC", "SN", "KI", "TE", "JA", "PR"],
          ["HE", "TE", "JA", "AC", "MP", "JA", "WI", "KI", "PR", "AC", "TE", "MP", "KI", "QU", "MP", "WI", "KI", "JA", "TE", "WI", "JA", "SN", "KI", "FS", "QU", "SN", "PR", "TE", "SN", "PR", "MP", "HE", "AC", "TE", "SN", "BO", "JA", "PR", "QU", "SN"],
          ["KI", "QU", "HE", "SN", "QU", "TE", "WI", "JA", "TE", "BO", "QU", "HE", "KI", "SN", "JA", "FS", "QU", "AC", "KI", "TE", "AC", "HE", "KI", "WI", "JA", "HE", "TE", "FS", "PR", "TE", "AC", "KI", "HE", "AC", "JA", "TE", "AC", "HE", "QU", "MP"],
          ["SN", "TE", "JA", "HE", "AC", "TE", "MP", "QU", "TE", "BO", "JA", "TE", "SN", "HE", "JA", "FS", "KI", "AC", "TE", "KI", "AC", "BO", "QU", "KI", "JA", "QU", "WI", "JA", "AC", "KI", "QU", "JA", "TE", "PR", "FS", "AC", "QU", "MP", "KI", "PR"],
          ["QU", "TE", "PR", "SN", "JA", "AC", "WI", "KI", "JA", "QU", "AC", "TE", "BO", "SN", "KI", "FS", "QU", "JA", "SN", "BO", "HE", "JA", "MP", "SN", "TE", "PR", "AC", "TE", "JA", "PR", "KI", "BO", "HE", "JA", "FS", "AC", "MP", "SN", "HE", "PR"]
        ]
    },      
    "modelId" : "mayangold"
}
