/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf010_gustavoelluchador",
        "wildSymbol": "WI1",
        "freeSpinTrigger": "FS1",
        "scatterSymbol": "SC1",

        "countToTriggerFreeSpin" : 3,
        "freeSpinCount": 1,

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 40 ],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "4000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "40",
        "coinType4_spin_max_bet": "1600",

        "reels": [
            [ "LV4", "LV2", "WI1", "LV4", "HV1", "LV1", "LV4", "FS1", "LV3", "LV1", "HV1", "LV4", "LV3", "HV4", "LV1", "HV2", "LV3", "LV1", "HV3", "LV5", "HV1", "LV1", "LV2", "SC1", "LV1", "HV5", "HV5", "HV5", "LV1", "WI1", "LV2", "HV3", "LV1", "HV2", "LV2", "LV4", "FS1", "LV5", "LV2", "HV4", "LV4", "SC1", "LV5", "HV3", "LV2", "LV1", "HV2" ],
            [ "LV3", "HV1", "LV2", "LV3", "HV2", "LV4", "HV4", "LV3", "LV1", "HV3", "LV5", "LV2", "WI1", "LV4", "LV5", "HV3", "LV1", "HV2", "LV3", "HV1", "LV2", "LV1", "WI1", "LV3", "HV1", "LV2", "HV2", "LV5", "SC1", "LV2", "LV1", "HV5", "HV5", "HV5", "LV4", "LV2", "HV1", "LV5", "LV2", "HV4", "LV4", "LV2", "HV4", "LV5", "LV2", "HV3", "LV4" ],
            [ "LV3", "LV4", "SC1", "LV5", "LV3", "HV2", "LV1", "LV3", "HV4", "LV1", "FS1", "LV2", "LV3", "HV3", "LV2", "LV3", "SC1", "LV1", "HV3", "LV2", "LV2", "HV1", "LV3", "FS1", "LV5", "LV3", "HV3", "LV1", "HV2", "LV4", "LV3", "HV5", "HV5", "HV5", "LV5", "LV3", "HV1", "LV4", "LV5", "HV4", "LV1", "FS1", "LV4", "HV2", "LV1", "LV2", "HV1" ],
            [ "HV4", "LV4", "LV5", "HV2", "LV3", "LV4", "HV2", "LV2", "WI1", "LV5", "LV2", "SC1", "LV5", "HV1", "LV2", "LV4", "HV3", "LV3", "LV2", "HV4", "LV3", "WI1", "LV1", "HV4", "LV3", "HV1", "LV1", "LV4", "WI1", "LV5", "LV1", "HV2", "LV2", "HV5", "HV5", "HV5", "LV1", "HV2", "LV4", "HV3", "LV1", "LV4", "WI1", "LV1", "LV5", "HV1", "LV2" ],
            [ "LV2", "FS1", "LV5", "HV3", "LV1", "SC1", "LV3", "HV2", "LV5", "WI1", "LV1", "HV2", "LV5", "FS1", "LV1", "HV1", "LV3", "SC1", "LV5", "HV1", "LV3", "WI1", "LV2", "LV1", "FS1", "LV3", "HV4", "LV4", "SC1", "LV2", "LV5", "FS1", "LV4", "HV3", "LV5", "HV5", "HV5", "HV5", "LV2", "HV1", "LV2", "FS1", "LV4" ]
        ],
        "featureReels": [
            [ "LV7", "LV9", "BA3", "LV6", "LV6", "BA1", "HV7", "LV8", "BA2", "LV9", "HV6", "BA4", "LV10", "LV7", "BA2", "LV6", "LV8", "BA1", "LV7", "LV10", "BA3", "LV8", "HV7", "LV10", "BA2", "LV8", "LV8", "BA1", "LV9", "LV6", "BA3", "LV7", "LV9", "BA1", "LV9", "HV6", "LV10", "BA2", "LV10", "LV6", "BA1", "LV9", "LV7", "BA2", "LV7", "LV8", "BA3", "LV10", "LV6", "BA1" ],
            [ "LV10", "HV6", "BA3", "LV6", "LV7", "BA3", "LV9", "LV8", "BA1", "LV6", "LV10", "BA2", "LV6", "LV10", "BA1", "LV7", "LV7", "BA2", "LV8", "HV7", "BA2", "LV10", "HV6", "BA1", "LV9", "LV9", "BA4", "LV7", "HV7", "LV9", "BA1", "LV8", "LV9", "BA3", "LV6", "LV8", "BA2", "LV8", "LV7", "BA1", "LV9", "LV6", "BA3", "LV6", "LV10", "BA2", "LV10", "LV8", "BA1", "LV7" ],
            [ "LV6", "HV7", "RA3", "LV8", "LV9", "RA1", "LV9", "LV7", "RA2", "LV8", "LV8", "RA4", "LV6", "LV7", "RA2", "LV7", "LV9", "RA1", "LV10", "LV10", "RA3", "LV6", "HV7", "LV10", "RA2", "LV8", "LV10", "RA1", "LV10", "LV9", "RA3", "LV7", "LV9", "RA1", "LV6", "HV6", "LV6", "RA2", "LV8", "LV7", "RA1", "LV10", "LV9", "RA2", "HV6", "LV8", "RA3", "LV7", "LV6", "RA1" ],
            [ "LV8", "LV6", "RA4", "LV8", "LV7", "RA3", "LV6", "LV9", "RA3", "LV6", "LV10", "RA1", "HV7", "LV10", "RA2", "LV7", "LV9", "HV6", "RA1", "LV7", "LV9", "RA2", "LV8", "HV7", "LV7", "RA2", "HV6", "LV9", "RA1", "LV6", "LV10", "RA1", "LV10", "LV7", "RA1", "LV8", "LV9", "RA3", "LV9", "LV7", "RA2", "LV6", "LV8", "RA1", "LV6", "LV10", "RA3", "LV10", "LV8", "RA2" ]
        ],

        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [50, 20, 5]
            }, {
                "symbol": "LV2",
                "multipliers": [50, 20, 5]
            }, {
                "symbol": "LV3",
                "multipliers": [60, 25, 6]
            }, {
                "symbol": "LV4",
                "multipliers": [80, 30, 8]
            }, {
                "symbol": "LV5",
                "multipliers": [100, 40, 10]
            },{
                "symbol": "HV1",
                "multipliers": [120, 50, 12]
            }, {
                "symbol": "HV2",
                "multipliers": [200, 80, 20]
            }, {
                "symbol": "HV3",
                "multipliers": [300, 120, 30]
            }, {
                "symbol": "HV4",
                "multipliers": [400, 150, 40]
            }, {
                "symbol": "HV5",
                "multipliers": [500, 200, 50]
            }
        ],
        "featureWinTable": [
            {
                "symbol": "LV6",
                "multipliers": [2, 1]
            }, {
                "symbol": "LV7",
                "multipliers": [2, 1]
            }, {
                "symbol": "LV8",
                "multipliers": [4, 2]
            }, {
                "symbol": "LV9",
                "multipliers": [4, 2]
            }, {
                "symbol": "LV10",
                "multipliers": [6, 3]
            }, {
                "symbol": "HV6",
                "multipliers": [250, 25]
            }, {
                "symbol": "HV7",
                "multipliers": [500, 50]
            }, {
                "symbol": "RA1",
                "multipliers": [0, 0, 2]
            }, {
                "symbol": "RA2",
                "multipliers": [0, 0, 3]
            }, {
                "symbol": "RA3",
                "multipliers": [0, 0, 4]
            }, {
                "symbol": "RA4",
                "multipliers": [0, 0, 5]
            }, {
                "symbol": "BA1",
                "multipliers": [0, 0, 2]
            }, {
                "symbol": "BA2",
                "multipliers": [0, 0, 3]
            }, {
                "symbol": "BA3",
                "multipliers": [0, 0, 4]
            }, {
                "symbol": "BA4",
                "multipliers": [0, 0, 5]
            }
        ],

        "gustavoAndOpponentsHP": [
            {
                "gustavoHP": 6,
                "opponentHP": 6
            },
            {
                "gustavoHP": 6,
                "opponentHP": 8
            },
            {
                "gustavoHP": 6,
                "opponentHP": 10
            },
            {
                "gustavoHP": 6,
                "opponentHP": 12
            }
        ],

        "roundsWinsMultiplier": [1, 1, 2, 3, 4],
        "featureAttackValues": {
            "redAttack": {
                "RA1": 2,
                "RA2": 3,
                "RA3": 4,
                "RA4": 5
            },
            "blueAttack": {
                "BA1": 2,
                "BA2": 3,
                "BA3": 4,
                "BA4": 5
            }
        },

        "playLines": [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],
            [0, 0, 1, 0, 0],
            [0, 1, 0, 1, 0],
            [0, 1, 1, 1, 0],
            [1, 0, 0, 0, 1],
            [1, 0, 1, 0, 1],
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],
            [1, 2, 1, 2, 1],
            [1, 2, 2, 2, 1],
            [2, 1, 1, 1, 2],
            [2, 1, 2, 1, 2],
            [2, 2, 1, 2, 2],
            [0, 2, 0, 2, 0],
            [2, 0, 2, 0, 2],
            [0, 0, 2, 0, 0]
        ],

        "countToTriggerBonusGame": 3,
        "bonusGamePayTables": [
            [
                [5, 6, 7, 0, 0],
                [8, 10, 0, 0],
                [18, 50, 100]
            ],
            [
                [10, 12, 15, 0, 0],
                [25, 30, 0, 0],
                [50, 75, 150]
            ],
            [
                [20, 30, 40, 0, 0],
                [50, 75, 0, 0],
                [100, 150, 250]
            ]
        ],
        "bonusGamePayTableWeights": [
            [17, 17, 17, 25, 25],
            [50, 50, 50, 50],
            [50, 50, 50]
        ],

        "reelsLayout": [3, 3, 3, 3, 3],
        "featureReelsLayout": [3, 3, 3, 3]

    },
}
