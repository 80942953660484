import {modelDefinition} from './config/init';

export function setStateParams() {
    return {
        // Must implement
        createDefaultStep: () => ({name: 'Clear', gameState: 'clear', reelStripPositions: [0, 0, 0, 0, 0, 0]}),
        getLayout: () => modelDefinition.reelsLayout.join('-'),

        // unique per model
        getModelDefinition: () => modelDefinition, 
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,
        getFeatureReelStrips: () => { throw new Error(`feature reels not available for vgw077`); },
        prepareStepForDB: step => step,
    };
}
