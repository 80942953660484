/* in order to support standalone, please insert the init response from the server here */
export default {
	success: true,
	messages: [],
	modelDefinition: {
		modelId: 'tf110',
		betMultiplier: 20,
		reelsLayout: [3, 3, 3, 3, 3],
		mysteryFruitSymbol: '9',
		bonusSymbol: '10',

		reelsets: [
			{
				idx: 0,
				weight: 22,
				reels: [
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "1", "1", "1", "1", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "7", "7", "7", "7", "8", "8", "8", "8", "2", "2", "2", "2", "5", "5", "5", "5", "3", "3", "3", "3", "4", "4", "4", "4", "7", "7", "7", "7", "8", "8", "8", "8"],
					["1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "1", "1", "1", "1", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "5", "5", "5", "5", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7"],
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "5", "5", "5", "2", "2", "2", "2", "5", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8"],
					["8", "8", "8", "8", "7", "7", "7", "7", "3", "3", "3", "3", "7", "7", "7", "7", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "1", "1", "1", "1"],
					["2", "2", "2", "2", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "7", "7", "7", "7", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
				]
			},
			{
				idx: 1,
				weight: 20,
				reels: [
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7"],
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "2", "2", "2", "2", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "1", "1", "1", "1", "8", "8", "8", "8"],
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "6", "6", "6", "6", "8", "8", "8", "8"],
					["2", "2", "2", "2", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "7", "7", "7", "7", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
			{
				idx: 2,
				weight: 10,
				reels: [
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7"],
					["1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "3", "3", "3", "3", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "2", "2", "2", "2", "8", "8", "8", "8"],
					["1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "7", "7", "7", "7", "1", "1", "1", "1", "8", "8", "8", "8"],
					["2", "2", "2", "2", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "7", "7", "7", "7", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "7", "7", "7", "7", "5", "5", "5", "5", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
			{
				idx: 3,
				weight: 10,
				reels: [
					["1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7"],
					["1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "3", "3", "3", "3", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "7", "7", "7", "7", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "8", "8", "8", "8"],
					["1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "7", "7", "7", "7", "8", "8", "8", "8"],
					["2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "7", "7", "7", "7", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "7", "7", "7", "7", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "7", "7", "7", "7", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
			{
				idx: 4,
				weight: 8,
				reels: [
					["1", "1", "1", "1", "8", "8", "8", "8", "2", "2", "2", "2", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "8", "8", "8", "8", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "3", "3", "3", "3", "6", "6", "6", "6", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "7", "7", "7", "7"],
					["1", "1", "1", "1", "4", "4", "4", "4", "5", "5", "5", "5", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "5", "5", "5", "5", "3", "3", "3", "3", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "6", "6", "6", "6", "8", "8", "8", "8"],
					["1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "7", "7", "7", "7", "1", "1", "1", "1", "2", "2", "2", "2", "3", "3", "3", "3", "7", "7", "7", "7", "8", "8", "8", "8"],
					["7", "7", "7", "7", "5", "5", "5", "5", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "4", "4", "4", "4", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "7", "7", "7", "7", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
					["2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "8", "8", "8", "8", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
				]
			},
			{
				idx: 5,
				weight: 10,
				reels: [
					["5", "5", "5", "5", "7", "7", "7", "7", "3", "3", "3", "3", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "5", "5", "5", "5", "5", "5", "5", "5", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "6", "6", "6", "6", "3", "3", "3", "3", "7", "7", "7", "7", "6", "6", "6", "6", "6", "6", "6", "6"],
					["6", "6", "6", "6", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "8", "8", "8", "8", "8", "8", "8", "8", "2", "2", "2", "2", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "4", "4", "4", "4", "1", "1", "1", "1"],
					["2", "2", "2", "2", "8", "8", "8", "8", "2", "2", "2", "2", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "5", "5", "5", "5", "2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "3", "3", "3", "3", "7", "7", "7", "7", "4", "4", "4", "4", "8", "8", "8", "8"],
					["2", "2", "2", "2", "7", "7", "7", "7", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
			{
				idx: 6,
				weight: 10,
				reels: [
					["2", "2", "2", "2", "8", "8", "8", "8", "2", "2", "2", "2", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "5", "5", "5", "5", "2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "3", "3", "3", "3", "7", "7", "7", "7", "4", "4", "4", "4", "8", "8", "8", "8"],
					["6", "6", "6", "6", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "8", "8", "8", "8", "8", "8", "8", "8", "2", "2", "2", "2", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "4", "4", "4", "4", "1", "1", "1", "1"],
					["5", "5", "5", "5", "7", "7", "7", "7", "3", "3", "3", "3", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "5", "5", "5", "5", "5", "5", "5", "5", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "6", "6", "6", "6", "3", "3", "3", "3", "7", "7", "7", "7", "6", "6", "6", "6", "6", "6", "6", "6"],
					["2", "2", "2", "2", "7", "7", "7", "7", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
			{
				idx: 7,
				weight: 10,
				reels: [
					["2", "2", "2", "2", "8", "8", "8", "8", "2", "2", "2", "2", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "5", "5", "5", "5", "7", "7", "7", "7", "5", "5", "5", "5", "2", "2", "2", "2", "8", "8", "8", "8", "3", "3", "3", "3", "3", "3", "3", "3", "7", "7", "7", "7", "4", "4", "4", "4", "8", "8", "8", "8"],
					["5", "5", "5", "5", "7", "7", "7", "7", "3", "3", "3", "3", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "5", "5", "5", "5", "5", "5", "5", "5", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "6", "6", "6", "6", "3", "3", "3", "3", "7", "7", "7", "7", "6", "6", "6", "6", "6", "6", "6", "6"],
					["6", "6", "6", "6", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "2", "2", "2", "2", "4", "4", "4", "4", "8", "8", "8", "8", "8", "8", "8", "8", "2", "2", "2", "2", "6", "6", "6", "6", "8", "8", "8", "8", "1", "1", "1", "1", "6", "6", "6", "6", "1", "1", "1", "1", "4", "4", "4", "4", "1", "1", "1", "1"],
					["2", "2", "2", "2", "7", "7", "7", "7", "7", "7", "7", "7", "8", "8", "8", "8", "3", "3", "3", "3", "4", "4", "4", "4", "6", "6", "6", "6", "4", "4", "4", "4", "1", "1", "1", "1", "6", "6", "6", "6", "2", "2", "2", "2", "5", "5", "5", "5", "8", "8", "8", "8", "3", "3", "3", "3", "5", "5", "5", "5", "1", "1", "1", "1"],
					["8", "8", "8", "8", "7", "7", "7", "7", "7", "7", "7", "7", "5", "5", "5", "5", "3", "3", "3", "3", "1", "1", "1", "1", "4", "4", "4", "4", "2", "2", "2", "2", "8", "8", "8", "8", "4", "4", "4", "4", "3", "3", "3", "3", "5", "5", "5", "5", "2", "2", "2", "2", "6", "6", "6", "6", "6", "6", "6", "6", "1", "1", "1", "1"],
				]
			},
		]
	}
}
