var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBGTreasureReelStep()
              },
            },
          },
          [_vm._v("\n      BG Treasure Reel\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeGameStep()
              },
            },
          },
          [_vm._v("Free Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFGTreasureReelStep()
              },
            },
          },
          [_vm._v("\n      FG Treasure Reel\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c("p", { staticClass: "text" }, [_vm._v("Reel Set")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-style", staticStyle: { margin: "0.5rem" } },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelSet,
                    expression: "reelSet",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelSet = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.onReelSetChange,
                  ],
                },
              },
              _vm._l(_vm.ReelSetCount, function (set) {
                return _c("option", { key: set, domProps: { value: set } }, [
                  _vm._v("\n          " + _vm._s(set) + "\n        "),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsCount, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFreeSpin,
                expression: "!isFreeSpin",
              },
            ],
            staticClass: "title",
          },
          [_vm._v("Respin Trigger Settings")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFreeSpin,
                expression: "!isFreeSpin",
              },
            ],
            class: ["flex-center"],
            staticStyle: { margin: "0.5rem" },
          },
          [
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v("Monkey Placement Factor"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.monkeyPlacementFactor,
                      expression: "monkeyPlacementFactor",
                    },
                  ],
                  attrs: {
                    name: "monkeyPlacementFactor",
                    id: "monkeyPlacementFactor",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.monkeyPlacementFactor = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onMonkeyPlacementFactorChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "true" } }, [_vm._v("True")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "false" } }, [
                    _vm._v("False"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.monkeyPlacementFactor == "true",
                    expression: "monkeyPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Monkey Reel Position"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monkeyReelPosition,
                        expression: "monkeyReelPosition",
                      },
                    ],
                    attrs: {
                      name: "monkeyReelPosition",
                      id: "monkeyReelPosition",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.monkeyReelPosition = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onMonkeyReelPositionChange,
                      ],
                    },
                  },
                  _vm._l(_vm.reelsCount, function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(value) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.monkeyPlacementFactor == "true",
                    expression: "monkeyPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Monkey Row Position"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.monkeyRowPosition,
                        expression: "monkeyRowPosition",
                      },
                    ],
                    attrs: {
                      name: "monkeyRowPosition",
                      id: "monkeyRowPosition",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.monkeyRowPosition = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onMonkeyRowPositionChange,
                      ],
                    },
                  },
                  _vm._l(3, function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(value) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.monkeyPlacementFactor == "true",
                    expression: "monkeyPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [_vm._v("Respin Reel Set")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.respinReelSet,
                        expression: "respinReelSet",
                      },
                    ],
                    attrs: { name: "respinReelSet", id: "respinReelSet" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.respinReelSet = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onRespinReelSetChange,
                      ],
                    },
                  },
                  _vm._l(7, function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(value) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFreeSpin,
                expression: "isFreeSpin",
              },
            ],
            staticClass: "title",
          },
          [_vm._v("Free Game Settings")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFreeSpin,
                expression: "isFreeSpin",
              },
            ],
            class: ["flex-center"],
            staticStyle: { margin: "0.5rem" },
          },
          [
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v("Ghost Captain Placement Factor"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ghostyCaptainPlacementFactor,
                      expression: "ghostyCaptainPlacementFactor",
                    },
                  ],
                  attrs: {
                    name: "ghostyCaptainPlacementFactor",
                    id: "ghostyCaptainPlacementFactor",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.ghostyCaptainPlacementFactor = $event.target
                          .multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onGhostyCaptainPlacementFactorChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "true" } }, [_vm._v("True")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "false" } }, [
                    _vm._v("False"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ghostyCaptainPlacementFactor == "true",
                    expression: "ghostyCaptainPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Ghost Captain Reel Position"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ghostlyCaptainReelsPosition,
                      expression: "ghostlyCaptainReelsPosition",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "ghostlyCaptainReelsPosition",
                    id: "ghostlyCaptainReelsPosition",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.ghostlyCaptainReelsPosition },
                  on: {
                    focusout: _vm.onGhostlyCaptainReelsPositionChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.ghostlyCaptainReelsPosition = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ghostyCaptainPlacementFactor == "true",
                    expression: "ghostyCaptainPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Ghost Captain Row Position"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ghostlyRowPosition,
                      expression: "ghostlyRowPosition",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "ghostlyRowPosition",
                    id: "ghostlyRowPosition",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.ghostlyRowPosition },
                  on: {
                    focusout: _vm.onGhostlyRowPositionChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.ghostlyRowPosition = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "title", staticStyle: { "margin-top": "2rem" } },
          [_vm._v("Freespin Trigger Settings")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: ["flex-center"],
            staticStyle: { margin: "0.5rem 0.5rem 0.5rem 0.5rem" },
          },
          [
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v("Scatter Placement Factor"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scatterPlacementFactor,
                      expression: "scatterPlacementFactor",
                    },
                  ],
                  attrs: {
                    name: "scatterPlacementFactor",
                    id: "scatterPlacementFactor",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.scatterPlacementFactor = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onScatterPlacementFactorChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "true" } }, [_vm._v("True")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "false" } }, [
                    _vm._v("False"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.scatterPlacementFactor == "true",
                    expression: "scatterPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Scatter Reel Position"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scatterReelsPosition,
                      expression: "scatterReelsPosition",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "scatterReelsPosition",
                    id: "scatterReelsPosition",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.scatterReelsPosition },
                  on: {
                    focusout: _vm.onScatterReelsPositionChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.scatterReelsPosition = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.scatterPlacementFactor == "true",
                    expression: "scatterPlacementFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Scatter Row Position"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scatterRowPosition,
                      expression: "scatterRowPosition",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "scatterRowPosition",
                    id: "scatterRowPosition",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.scatterRowPosition },
                  on: {
                    focusout: _vm.onScatterRowPositionChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.scatterRowPosition = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "title", staticStyle: { "margin-top": "2rem" } },
          [_vm._v("Map Feature")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: ["flex-center"],
            staticStyle: { margin: "0.5rem 0.5rem 0.5rem 0.5rem" },
          },
          [
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [_vm._v("Map Symbol Factor")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mapSymbolFactor,
                      expression: "mapSymbolFactor",
                    },
                  ],
                  attrs: { name: "mapSymbolFactor", id: "mapSymbolFactor" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.mapSymbolFactor = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onMapSymbolFactorChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "true" } }, [_vm._v("True")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "false" } }, [
                    _vm._v("False"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mapSymbolFactor == "true",
                    expression: "mapSymbolFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [_vm._v("Map Symbol Count")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mapSymbolCount,
                        expression: "mapSymbolCount",
                      },
                    ],
                    attrs: { name: "mapSymbolCount", id: "mapSymbolCount" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.mapSymbolCount = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onMapSymbolCountChange,
                      ],
                    },
                  },
                  _vm._l(7, function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(value) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mapSymbolFactor == "true",
                    expression: "mapSymbolFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [_vm._v("Map Symbol Reel")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mapSymbolReel,
                      expression: "mapSymbolReel",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "mapSymbolReel",
                    id: "mapSymbolReel",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.mapSymbolReel },
                  on: {
                    focusout: _vm.onMapSymbolReelChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.mapSymbolReel = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mapSymbolFactor == "true",
                    expression: "mapSymbolFactor == 'true'",
                  },
                ],
                staticClass: "box",
              },
              [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Map Symbol Row Position"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mapSymbolRowPosition,
                      expression: "mapSymbolRowPosition",
                    },
                  ],
                  staticClass: "text-input",
                  attrs: {
                    name: "mapSymbolRowPosition",
                    id: "mapSymbolRowPosition",
                    placeholder: "0, 1, 2",
                  },
                  domProps: { value: _vm.mapSymbolRowPosition },
                  on: {
                    focusout: _vm.onMapSymbolRowPositionChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.mapSymbolRowPosition = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTreasureReel,
                expression: "isTreasureReel",
              },
            ],
            staticClass: "title",
            staticStyle: { "margin-top": "2rem" },
          },
          [_vm._v("\n      Fat Tiles\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTreasureReel,
                expression: "isTreasureReel",
              },
            ],
            class: ["flex-center"],
            staticStyle: { margin: "0.5rem 0.5rem 0.5rem 0.5rem" },
          },
          [
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [_vm._v("Fat Symbol ID")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fatSymbolId,
                    expression: "fatSymbolId",
                  },
                ],
                staticClass: "text-input",
                attrs: {
                  name: "fatSymbolId",
                  id: "fatSymbolId",
                  placeholder: "18, 19, 20, 21",
                },
                domProps: { value: _vm.fatSymbolId },
                on: {
                  focusout: _vm.onFatSymbolIdChange,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.fatSymbolId = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("p", { staticClass: "text" }, [_vm._v("Fat Symbol Reels")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fatSymbolReels,
                    expression: "fatSymbolReels",
                  },
                ],
                staticClass: "text-input",
                attrs: {
                  name: "fatSymbolReels",
                  id: "fatSymbolReels",
                  placeholder: "0, 1, 2",
                },
                domProps: { value: _vm.fatSymbolReels },
                on: {
                  focusout: _vm.onFatSymbolReelsChange,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.fatSymbolReels = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }