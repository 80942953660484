var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add base step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center wheel-spin-value-selector-wrapper" },
        [
          _c("div", { staticClass: "flex-center wheel-spin-value-selector" }, [
            _c("label", { attrs: { for: "wheelSpinValue" } }, [
              _vm._v("Wheel spin value: "),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.wheelSpinValue,
                  expression: "wheelSpinValue",
                },
              ],
              attrs: {
                type: "number",
                min: "0",
                max: "36",
                id: "wheelSpinValue",
              },
              domProps: { value: _vm.wheelSpinValue },
              on: {
                change: function ($event) {
                  return _vm.updateWheelSpinValue(_vm.wheelSpinValue)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.wheelSpinValue = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center dice-bonus-wrapper" }, [
        _c("div", { staticClass: "flex-center dice-bonus-selector" }, [
          _c("label", { attrs: { for: "diceBonusOne" } }, [
            _vm._v("First Dice: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.diceBonusOne,
                  expression: "diceBonusOne",
                },
              ],
              attrs: { id: "diceBonusOne" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.diceBonusOne = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateDiceBonusOne(_vm.diceBonusOne)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center dice-bonus-wrapper" }, [
        _c("div", { staticClass: "flex-center dice-bonus-selector" }, [
          _c("label", { attrs: { for: "diceBonusTwo" } }, [
            _vm._v("Second Dice: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.diceBonusTwo,
                  expression: "diceBonusTwo",
                },
              ],
              attrs: { id: "diceBonusTwo" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.diceBonusTwo = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateDiceBonusTwo(_vm.diceBonusTwo)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center dice-bonus-wrapper" }, [
        _c("div", { staticClass: "flex-center dice-bonus-selector" }, [
          _c("label", { attrs: { for: "diceBonusThree" } }, [
            _vm._v("Third Dice: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.diceBonusThree,
                  expression: "diceBonusThree",
                },
              ],
              attrs: { id: "diceBonusThree" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.diceBonusThree = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateDiceBonusThree(_vm.diceBonusThree)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center dice-bonus-wrapper" }, [
        _c("div", { staticClass: "flex-center dice-bonus-selector" }, [
          _c("label", { attrs: { for: "diceBonusFour" } }, [
            _vm._v("Forth Dice: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.diceBonusFour,
                  expression: "diceBonusFour",
                },
              ],
              attrs: { id: "diceBonusFour" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.diceBonusFour = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateDiceBonusFour(_vm.diceBonusFour)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center wheel-spin-confirm-button" }, [
      _c(
        "div",
        {
          staticClass: "button pointer",
          attrs: {
            onclick:
              "alert('Wheel spin value ' + wheelSpinValue.value + ' saved on current step!');",
          },
        },
        [_vm._v("Confirm")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }