var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center dice-result-selector-wrapper" }, [
        _c(
          "div",
          { staticClass: "dice-result-selectors" },
          _vm._l(2, function (value) {
            return _c("diceResultSelector", {
              key: value,
              attrs: { index: value, diceResults: _vm.diceResults },
            })
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }