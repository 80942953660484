/* in order to support standalone, please insert the init response from the server here */
export default {
	success: true,
	messages: [],

	modelDefinition: {
		version: "1.0.0",
		modelId: "tf111",
		reelsLayout: [4, 4, 4, 4, 4],

		wheelSymbol: "W",
		dynamicSymbol: "X",

		ZSymbol: "Z",
		wildSymbol: "Z",

		scatterSymbol: "S",
		freeSpinTrigger: "S",

		countToTriggerFreeSpin: 3,
		freeSpinCount: 15,

		countToTriggerWheelBonus: 3,

		winTable: [
			{ symbol: "N", multipliers: [1000, 100, 20, 8] },
			{ symbol: "B", multipliers: [500, 90, 18, 5] },
			{ symbol: "C", multipliers: [400, 80, 18] },
			{ symbol: "D", multipliers: [300, 75, 15] },
			{ symbol: "E", multipliers: [200, 60, 15] },
			{ symbol: "A", multipliers: [150, 50, 12] },
			{ symbol: "K", multipliers: [120, 40, 12] },
			{ symbol: "Q", multipliers: [100, 25, 10] },
			{ symbol: "J", multipliers: [100, 25, 10] },
			{ symbol: "T", multipliers: [100, 25, 10] },
			{ symbol: "W", multipliers: [] },
			{ symbol: "S", multipliers: [0, 0, 1] },
			{ symbol: "Z", multipliers: [] },
			{ symbol: "X", multipliers: [] },
		],

		playLines: [
			[1, 1, 1, 1, 1],
			[0, 0, 0, 0, 0],
			[2, 2, 2, 2, 2],
			[3, 3, 3, 3, 3],
			[0, 1, 2, 1, 0],

			[3, 2, 1, 2, 3],
			[1, 2, 3, 2, 1],
			[2, 1, 0, 1, 2],
			[0, 1, 0, 1, 0],
			[1, 2, 1, 2, 1],

			[2, 3, 2, 3, 2],
			[1, 0, 1, 0, 1],
			[2, 1, 2, 1, 2],
			[3, 2, 3, 2, 3],
			[0, 0, 1, 0, 0],

			[3, 3, 2, 3, 3],
			[0, 0, 2, 0, 0],
			[3, 3, 1, 3, 3],
			[0, 1, 1, 1, 0],
			[3, 2, 2, 2, 3], //20

			[0, 2, 0, 2, 0],
			[3, 1, 3, 1, 3],
			[0, 2, 3, 2, 0],
			[3, 1, 0, 1, 3],
			[0, 2, 2, 2, 0],

			[3, 1, 1, 1, 3],
			[0, 3, 0, 3, 0],
			[3, 0, 3, 0, 3],
			[0, 3, 1, 3, 0],
			[3, 0, 2, 0, 3], //30

			[0, 3, 3, 3, 0],
			[3, 0, 0, 0, 3],
			[1, 0, 0, 0, 1],
			[2, 3, 3, 3, 2],
			[1, 0, 2, 0, 1],

			[2, 3, 1, 3, 2],
			[1, 1, 0, 1, 1],
			[2, 2, 3, 2, 2],
			[1, 1, 2, 1, 1],
			[2, 2, 1, 2, 2], //40

			[1, 1, 3, 1, 1],
			[2, 2, 0, 2, 2],
			[1, 2, 2, 2, 1],
			[2, 1, 1, 1, 2],
			[1, 3, 1, 3, 1],

			[2, 0, 2, 0, 2],
			[1, 3, 2, 3, 1],
			[2, 0, 1, 0, 2],
			[1, 3, 3, 3, 1],
			[2, 0, 0, 0, 2],
		],

		// prettier-ignore
		allPaylines: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49],

		// prettier-ignore
		reels : [
            ['X','X','X','X','X','X','X','X','X','N','K','A','B','C','K','D','T','Q','S','K','Q','N','T','S','J','C','B','T','K','S','A','E','T','B','D','K','J','Q','T','E','J','D','K','E','Q','J','A','T','D','J','B','Q','C','K','E','S','Z','T','J','N','K','Q'],
            ['X','X','X','X','X','X','X','X','X','N','K','A','N','T','A','E','T','A','J','E','Z','T','Q','J','C','B','T','W','Q','J','K','E','J','D','K','Q','T','J','C','D','A','E','T','W','J','Q','A'],
            ['X','X','X','X','X','A','Q','T','K','E','S','J','T','A','S','T','Q','D','J','W','T','C','J','E','A','K','Q','Z','T','B','D','K','J','A','C','E','Q','T','D','N'],
            ['X','X','X','X','X','X','X','X','N','K','A','J','W','T','J','C','D','A','E','K','W','J','Q','A','K','B','T','Q','W','K','Q','N','T','A','J','C','B','T','Q','K','D','E','J','T','E','J','D','K','E','T','A','J','E','Z','T'],
            ['X','X','X','X','X','X','X','X','X','X','X','X','E','A','Q','B','C','T','K','S','Q','T','A','N','D','Q','T','K','J','S','Q','E','N','T','A','Q','C','Z','D','T','K','J','D','J','K','T','E','Q','J','K','T','B','J','A','Q','D','E','J','T','C','A']
        ],

		coinType1Values: [500, 1000, 2500, 5000, 10000, 15000, 25000],
		coinType4Values: [1, 2, 5, 10, 20, 25, 50, 100],
		coinType1_min_bet: 500,
		coinType1_max_bet: 1_250_000,
		coinType1_spin_max_bet: 1_250_000,
		coinType4_min_bet: 1,
		coinType4_max_bet: 5_000,
		coinType4_spin_max_bet: 5_000,

		winLevels: [
			{ name: "normal1", threshold: 0, text: "" },
			{ name: "bigwin", threshold: 20, text: "BIG WIN" },
			{ name: "hugewin", threshold: 50, text: "HUGE WIN" },
			{ name: "massivewin", threshold: 100, text: "MASSIVE WIN" },
			{ name: "giganticwin", threshold: 250, text: "GIGANTIC WIN" },
			{ name: "monumentalwin", threshold: 500, text: "MONUMENTAL WIN" },
			{ name: "trollossalwin", threshold: 1000, text: "TROLLOSSAL WIN" },
		],

		dynamicSymbolInBaseGame: {
			probs: [
				{ symbol: "N", weight: 1 },
				{ symbol: "B", weight: 1 },
				{ symbol: "C", weight: 40 },
				{ symbol: "D", weight: 20 },
				{ symbol: "E", weight: 20 },
				{ symbol: "A", weight: 20 },
				{ symbol: "K", weight: 20 },
				{ symbol: "Q", weight: 75 },
				{ symbol: "J", weight: 100 },
				{ symbol: "T", weight: 20 },
				{ symbol: "Z", weight: 1 },
			],
			total: 318,
		},

		dynamicSymbolInFreespin: {
			probs: [
				{ symbol: "N", weight: 100 },
				{ symbol: "B", weight: 100 },
				{ symbol: "C", weight: 70 },
				{ symbol: "D", weight: 70 },
				{ symbol: "E", weight: 70 },
				{ symbol: "A", weight: 0 },
				{ symbol: "K", weight: 0 },
				{ symbol: "Q", weight: 0 },
				{ symbol: "J", weight: 0 },
				{ symbol: "T", weight: 0 },
				{ symbol: "Z", weight: 165 },
			],
			total: 575,
		},

		wheelStepRight: "r",
		wheelStepLeft: "l",
		wheelNoStep: "",

		wheels: [
			{
				probs: [
					{ wheelSegment: { multi: 1, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 2, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 3, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 3, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 2, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 1, arrow: "r" }, weight: 1 },
				],
				total: 6,
			},

			{
				probs: [
					{ wheelSegment: { multi: 2, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 3, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 4, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 4, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 3, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 2, arrow: "r" }, weight: 1 },
				],
				total: 6,
			},

			{
				probs: [
					{ wheelSegment: { multi: 5, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 6, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 10, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 10, arrow: "r" }, weight: 1 },
					{ wheelSegment: { multi: 6, arrow: "l" }, weight: 1 },
					{ wheelSegment: { multi: 5, arrow: "r" }, weight: 1 },
				],
				total: 6,
			},

			{
				probs: [
					{ wheelSegment: { multi: 5, arrow: "" }, weight: 250 },
					{ wheelSegment: { multi: 6, arrow: "" }, weight: 250 },
					{ wheelSegment: { multi: 10, arrow: "" }, weight: 100 },
					{ wheelSegment: { multi: 5, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 6, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 20, arrow: "" }, weight: 10 },
					{ wheelSegment: { multi: 5, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 6, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 1000, arrow: "" }, weight: 10 },
					{ wheelSegment: { multi: 5, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 6, arrow: "" }, weight: 500 },
					{ wheelSegment: { multi: 50, arrow: "" }, weight: 20 },
				],
				total: 3640,
			},
		],
	},
};
