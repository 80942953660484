import Vue, { PropType } from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import type Content from './content.vue';
import { getAssetsPath, getReelWindowFromIndex, wrapIndex } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import { Vgw088Step } from "./content.vue";

@Component({
    data: () => {
        return {
            reelIndex: 0,
        };
    },
})
export default class ReelVgw088 extends Vue {
    @Prop({ type: Number, default: 0 }) public index;
    @Prop() public step: Vgw088Step | undefined;
    @Prop({ type: Object as PropType<InstanceType<typeof Content>> }) public content;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
    private reelIndex = 0;
    private text = "0";
    private reelheight = 4;
    private reels = [];

    constructor() {
        super();
        const state = State.state;
    }

    @Watch("step")
    public stepChanged(step: Vgw088Step) {
      if (!step || !step.json) {
        return;
      }
      this.reelIndex = step.json.reelStripPositions[this.index];
      
      this.text = String(this.reelIndex);
    }

    @Watch("text")
    public textChanged(val, oldval) {
        const n = Number(val);

        if (!Number.isInteger(n)) {
            this.text = oldval;
        }
        const rs = this.getReels();
        if (n >= rs.length) {
            this.text = String(wrapIndex(n, rs));
        } else if (n < 0) {
            this.reelIndex = 0;
            this.text = String(this.reelIndex);
        }
        this.updateStep();
    }

    public mounted() {
        this.$refs.reel &&
          this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
      }
    
      public beforeDestroy() {
        this.$refs.reel &&
          this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
      }

    public scrollFunction(e) {
        if (e.deltaY < 0) {
            this.down();
        } else if (e.deltaY > 0) {
            this.up();
        }
        e.preventDefault();
    }

    public updateStep() {
        if (!this.step) {
            return;
        }
        const rs = this.getReels();
        this.reelIndex = Number(this.text);

        this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.reelheight);
        this.step.json.reelStripPositions[this.index] = this.reelIndex;

        if (!this.content.currentStep()) return;
        this.content.currentStep().json.reelStripPositions[this.index] = this.reelIndex;

        this.content.saveChangesToStep();
    }

    public up() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex + 1, rs));
    }

    public down() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex - 1, rs));
    }

    private getReels() {
        const state = State.state;
        const spinType = this.content.getReelType();
        const reelStrips = spinType === 'BASE' ? state.getReelStrips() : spinType === 'BASE_SB' ? state.getBaseSnowBlastReelStrips() : state.getFeatureReelStrips();
        return reelStrips[this.index];
    }

    symbolsBelowWindow() {
        const reel = this.getReels();

        const reelCells = [0, 1].map(r => reel[wrapIndex(this.reelIndex + r, reel)]);
        return reelCells;
    }

    public symbolsAboveWindow() {
        if (!this.step) {
            return;
        }
        const reel = this.getReels();

        const aboveReelHeight = 2;
        const reelCells = getReelWindowFromIndex(
            reel,
            this.reelIndex - aboveReelHeight,
            aboveReelHeight
        );
        return reelCells;
    }

    public reelWindow() {
        const reel = this.getReels();
        const reelCells = getReelWindowFromIndex(reel, this.reelIndex, this.reelheight);
        return reelCells;
    }
}