import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public isOpen = false;
    public isSpell = 0;
    public spellSymbol = "";
    public isFsStep = false;
    public isSpellSymbol = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.spellSymbol = step.json.spellSymbol ? step.json.spellSymbol : "Random";
        this.isSpell = step.json.isSpell ? step.json.isSpell : 0;

        if(step.json.internal_name == "FS Step") {
            this.isSpellSymbol = true;
            this.isFsStep = true;
        } else {
            this.isFsStep = false;
            this.isSpellSymbol = this.isSpell == 1 ? true : false;
        }



        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.reelsNo = 5;
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsNo = 5;
        this.isFsStep = false;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.reelsNo = 5;
        this.isFsStep = false;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep("FS Trigger") );
    }

    public addFreeSpinStep() {
        const state = State.state;
        this.reelsNo = 5;
        this.isFsStep = true;
        bus.$emit(events.ADD_STEP, state.getFSStep("FS Step") );
    }

    public updateSpell(spell) {
        this.isSpell = parseInt(spell);
        if(this.isSpell != 1) {
            this.isSpellSymbol = false;
        } else {
            this.isSpellSymbol = true;
        }
    }

    public updateStep(isSpell, spellSymbol) {
        this.reelsNo = 5;
        this.step.json.spellSymbol = spellSymbol;
        this.step.json.isSpell = isSpell;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
