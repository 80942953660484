var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("span", [_vm._v("Force Free Spin:   ")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.forceFS,
              expression: "forceFS",
            },
          ],
          staticClass: "text-input",
          staticStyle: { width: "50px" },
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.forceFS)
              ? _vm._i(_vm.forceFS, null) > -1
              : _vm.forceFS,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.forceFS,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.forceFS = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.forceFS = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.forceFS = $$c
                }
              },
              function ($event) {
                return _vm.updateForceFS(_vm.forceFS)
              },
            ],
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Forced number of Skull Symbols:   "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.skulls,
                  expression: "skulls",
                },
              ],
              staticStyle: { width: "50px" },
              attrs: { id: "scatters_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.skulls = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateSkullNumber(_vm.skulls)
                  },
                ],
              },
            },
            _vm._l(4, function (n) {
              return _c("option", [_vm._v(_vm._s(n - 1) + " ")])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reelType,
                  expression: "reelType",
                },
              ],
              attrs: { id: "select_reelType" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reelType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateReelType(_vm.reelType)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [_vm._v("nowin")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("default")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("freeSpins")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("freeSpins_1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("freeSpins_2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("freeSpins_3")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center select-something" }, [
        _c("div", { staticClass: "flex-center select-something" }, [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Reel set ID:   "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reelId,
                  expression: "reelId",
                },
              ],
              attrs: { id: "scatters_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reelId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateReelId(_vm.reelId)
                  },
                ],
              },
            },
            _vm._l(13, function (n) {
              return _c("option", [_vm._v(_vm._s(n - 1) + " ")])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", { attrs: { for: "select_reelType" } }, [
      _c("span", [_vm._v("Reel set category:   ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }