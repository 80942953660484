// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export const modelDefinition = {
	modelId: "vgw075",
	scatterSymbol: "SCAT", 
	wildSymbol: "WILD", 
	freeSpinTrigger: "SCAT",
	picxSymbol: "PICX",
	blankSymbol: "-",
	jackpotFriesSymbol: "JACKPOT_FRIES",
	countToTriggerFreeSpin: 3,
	freeSpinCount: 10, 
	reelsLayout: [3, 3, 3, 3, 3],
	defaultSelectLine: "25", 

	winTable: [
		{
			symbol: "WILD",
			multipliers: [250, 50, 20]
		},
		{
			symbol: "PIC1",
			multipliers: [250, 50, 20]
		},
		{
			symbol: "PIC2",
			multipliers: [200, 50, 10]
		},
		{
			symbol: "PIC3",
			multipliers: [200, 50, 10]
		},
		{
			symbol: "PIC4",
			multipliers: [200, 50, 10]
		},
		{
			symbol: "PIC5",
			multipliers: [200, 50, 10]
		},
		{
			symbol: "PIC6",
			multipliers: [150, 40, 10]
		},
		{
			symbol: "PIC7",
			multipliers: [150, 40, 10]
		},
		{
			symbol: "PIC8",
			multipliers: [150, 40, 10]
		},
		{
			symbol: "PIC9",
			multipliers: [150, 40, 10]
		},
		{
			symbol: "A",
			multipliers: [50, 15, 5]
		},
		{
			symbol: "K",
			multipliers: [50, 15, 5]
		},
		{
			symbol: "Q",
			multipliers: [50, 15, 5]
		},
		{
			symbol: "J",
			multipliers: [50, 15, 5]
		},
		{
			symbol: "10",
			multipliers: [50, 15, 5]
		},
		{
			symbol: "SCAT",
			multipliers: [100, 5, 2]
		},
	],

	playLines: [
		[1, 1, 1, 1, 1], // 1
		[0, 0, 0, 0, 0], // 2
		[2, 2, 2, 2, 2], // 3
		[0, 1, 2, 1, 0], // 4
		[2, 1, 0, 1, 2], // 5
		[0, 0, 1, 2, 2], // 6
		[2, 2, 1, 0, 0], // 7
		[1, 0, 1, 2, 1], // 8
		[1, 2, 1, 0, 1], // 9
		[1, 0, 0, 1, 0], // 10
		[1, 2, 2, 1, 2], // 11
		[0, 1, 0, 0, 1], // 12
		[2, 1, 2, 2, 1], // 13
		[0, 2, 0, 2, 0], // 14
		[2, 0, 2, 0, 2], // 15
		[1, 0, 2, 0, 1], // 16
		[1, 2, 0, 2, 1], // 17
		[0, 1, 1, 1, 0], // 18
		[2, 1, 1, 1, 2], // 19
		[0, 2, 2, 2, 0], // 20
		[2, 0, 0, 0, 2], // 21
		[0, 1, 2, 2, 2], // 22
		[2, 1, 0, 0, 0], // 23
		[2, 0, 1, 0, 2], // 24
		[0, 2, 1, 2, 0], // 25
	],

	// base game 
	reels: [
		['PIC1','Q','Q','10','PIC9','A','SCAT','10','10','PICX','J','A','A','PIC1','J','Q','10','PICX','PICX','PICX','K','A','PIC9','Q','SCAT','J','PIC3','K','Q','PIC1','K','K','PIC6','J','J','PIC1','Q','Q','10','PICX','PICX','PICX','A','SCAT','10','10','PIC2','J','WILD','A','PIC1','J','Q','A','A','PICX','J','K','10','PIC9','Q','J','J','PIC3','K','Q','PIC1','K','K','PIC6','A','10','PIC1','Q','Q','10','PIC7','PIC7','PIC7','A','K','SCAT','10','PIC2','J','A','A','PIC1','J','Q','10','PICX','PICX','PICX','K','PIC5','J','WILD','WILD','WILD','K','Q','PIC1','K','K','PIC6','A','10','PIC1','Q','Q','J','PIC8','PIC8','PIC8','A','K','10','10','PIC3','J','A','A','PIC1','J','Q','10','PICX','PICX','PICX','K','A','SCAT','J','J','PIC5','K','Q','PIC1','K','K','PICX','A','10','PIC1','Q','Q','10','PIC4','PIC4','PIC4','A','WILD','10','10','PIC2','J','WILD','A','PIC1','J','Q','10','PICX','PICX','PICX','K','A','SCAT','J','J','PIC5','K','Q','PIC1','K','K','PICX','A','10'],
		['PIC1','Q','WILD','10','PICX','PICX','PICX','A','J','K','Q','PICX','10','SCAT','K','PIC8','Q','PICX','PICX','PICX','J','Q','PIC1','A','K','PICX','Q','J','PIC7','A','A','PIC1','J','J','10','PIC5','PIC5','PIC5','A','J','J','A','Q','PIC2','10','WILD','K','PICX','PICX','PICX','J','SCAT','PIC4','K','PIC1','10','K','PIC7','A','10','PIC1','Q','J','10','PIC6','PIC6','PIC6','A','J','PIC1','K','J','PICX','PICX','10','A','SCAT','Q','PICX','PICX','PICX','Q','PIC4','K','PIC1','10','K','WILD','J','PIC1','Q','SCAT','10','PICX','PICX','PICX','A','J','PIC1','K','Q','PIC3','10','A','PICX','K','Q','PICX','PICX','PICX','J','J','PIC3','K','PIC1','10','K','PIC2','A','10','PIC1','Q','SCAT','10','PICX','PICX','PICX','A','PIC1','K','PIC8','Q','WILD','WILD','WILD','J','PIC3','Q','PICX','PICX','PICX','Q','PIC1','K','PICX','10','K','PIC8','A','10','PIC1','Q','J','10','PIC9','PIC9','PIC9','A','J','PIC1','K','SCAT','PIC7','10','A','A','PIC2','Q','PICX','PICX','PICX','Q','PIC4','A','10','K','K','PICX','A','J'],
		['PIC1','K','SCAT','10','PIC3','PIC3','PIC3','J','WILD','WILD','WILD','K','PIC6','10','WILD','Q','PIC9','K','PICX','PICX','PICX','A','A','PIC4','Q','WILD','10','Q','PIC4','J','J','PIC1','A','A','10','PICX','PICX','PICX','J','A','PIC1','Q','K','PICX','10','SCAT','Q','PICX','PICX','PICX','A','K','PIC6','Q','WILD','10','Q','PIC7','J','J','PIC1','A','SCAT','J','PICX','PICX','PICX','J','A','PIC1','Q','K','PIC9','10','J','Q','PIC8','K','PICX','PICX','PICX','K','PIC6','Q','PIC1','10','WILD','WILD','WILD','K','PIC1','A','10','PICX','PICX','PICX','J','A','PIC1','K','SCAT','PIC5','J','J','SCAT','PIC8','K','PICX','PICX','PICX','A','K','PICX','Q','PIC1','10','Q','PICX','J','10','WILD','K','PIC1','10','PIC2','PIC2','PIC2','J','A','PIC1','Q','K','PIC9','10','WILD','Q','PIC5','K','PICX','PICX','PICX','A','PIC8','J','PIC1','10','Q','PIC7','A','A','PIC1','K','WILD','10','PICX','PICX','PICX','J','A','PIC1','Q','K','PICX','10','SCAT','PIC5','A','K','PICX','PICX','PICX','J','PIC4','Q','PIC1','10','Q','PIC7','J','10'],
		['PIC1','J','SCAT','10','PICX','PICX','PICX','K','WILD','WILD','WILD','J','PICX','Q','WILD','A','PIC3','J','PICX','PICX','PICX','Q','Q','PIC2','K','WILD','10','A','PIC8','K','10','PIC1','Q','Q','10','PICX','PICX','PICX','K','Q','PIC1','A','J','PIC6','10','SCAT','A','PICX','PICX','PICX','K','K','PIC5','Q','WILD','10','A','PIC2','K','K','PIC1','Q','SCAT','10','PICX','PICX','PICX','K','PIC1','A','PIC8','J','WILD','WILD','WILD','Q','PIC9','J','PICX','PICX','PICX','J','PIC5','A','PIC1','Q','A','K','10','PIC1','J','Q','10','PICX','PICX','PICX','K','Q','PIC1','A','SCAT','PIC7','10','K','SCAT','PIC9','J','PICX','PICX','PICX','Q','J','PIC2','A','PIC1','10','A','PIC6','K','10','WILD','J','PIC1','10','PIC4','PIC4','PIC4','K','Q','PIC1','A','J','PIC8','10','WILD','A','PIC7','J','PICX','PICX','PICX','J','PIC9','A','PIC1','Q','Q','PIC3','K','10','PIC1','J','WILD','10','PICX','PICX','PICX','K','Q','PIC1','A','J','PIC7','10','SCAT','A','PIC6','J','PICX','PICX','PICX','J','PIC5','A','PIC1','10','A','PIC3','K','K'],
		['PIC1','K','K','J','PIC8','10','SCAT','J','J','PICX','Q','10','10','PIC1','Q','K','J','PICX','PICX','PICX','A','Q','J','SCAT','K','10','PIC8','K','Q','PIC4','A','K','PIC1','A','A','PIC7','10','PIC1','K','SCAT','Q','10','SCAT','J','J','PIC5','Q','SCAT','10','Q','PIC1','K','J','PICX','PICX','Q','SCAT','10','PIC8','J','PICX','PICX','PICX','A','PIC4','K','PIC1','A','PICX','10','PIC7','J','PIC1','K','K','PIC6','PIC6','PIC6','10','WILD','WILD','WILD','J','PIC5','Q','WILD','10','PIC1','Q','K','J','PICX','PICX','PICX','A','WILD','WILD','WILD','Q','PIC2','K','K','PIC1','A','A','PIC7','10','PIC1','J','PICX','PICX','PICX','10','Q','PIC9','PIC9','PIC9','A','SCAT','J','PIC4','10','10','PIC1','Q','SCAT','J','PICX','PICX','PICX','A','WILD','WILD','WILD','Q','PIC2','A','K','PIC1','A','A','PICX','Q','Q','PIC1','K','K','PIC3','PIC3','PIC3','10','A','A','J','PIC5','A','PICX','PICX','PICX','J','PIC1','Q','WILD','J','PICX','PICX','PICX','A','10','SCAT','Q','Q','PIC2','K','PIC1','A','A','PICX','10','J'],
	],
	// free spin 
	featureReels: [
		['PIC1','Q','Q','10','PIC9','A','K','10','10','PIC8','J','A','A','PIC1','J','Q','10','PIC5','PIC5','PIC5','K','A','PIC9','Q','J','J','PIC3','K','Q','PIC1','K','K','PIC6','A','10','PIC1','Q','Q','10','PIC9','PIC9','PIC9','A','K','10','10','PIC7','J','A','A','PIC1','J','Q','10','PIC8','PIC8','J','K','A','PIC9','Q','J','J','PIC3','K','Q','PIC1','K','K','PIC6','A','10','PIC1','Q','Q','10','PIC7','PIC7','PIC7','A','K','10','10','PIC2','J','A','A','PIC1','J','Q','10','PIC3','PIC3','PIC3','K','A','Q','J','J','PIC5','K','Q','PIC1','K','K','PIC6','A','10','PIC1','Q','Q','10','PIC8','PIC8','PIC8','A','K','10','10','PIC4','J','A','A','PIC1','J','Q','10','PIC2','PIC2','PIC2','K','A','Q','J','J','PIC5','K','Q','PIC1','K','K','PIC7','A','10','PIC1','Q','Q','10','PIC4','PIC4','PIC4','A','K','10','10','PIC2','J','A','A','PIC1','J','Q','10','PIC6','PIC6','PIC6','K','A','Q','J','J','PIC5','K','Q','PIC1','K','K','PIC7','A','10'],
		['PIC1','Q','J','10','PIC2','PIC2','PIC2','A','J','PIC1','K','Q','PIC4','10','A','K','PIC8','Q','PIC3','PIC3','PIC3','J','Q','PIC6','K','PIC1','10','K','PIC7','A','10','PIC1','Q','J','10','PIC5','PIC5','PIC5','A','J','PIC1','K','Q','PIC2','10','A','K','PIC9','PIC9','PIC9','J','Q','PIC4','K','PIC1','10','K','PIC8','A','10','PIC1','Q','J','10','PIC6','PIC6','PIC6','A','J','PIC1','K','Q','PIC8','10','A','K','PIC9','Q','PIC7','PIC7','PIC7','Q','PIC4','K','PIC1','10','K','A','10','PIC1','Q','J','10','PIC4','PIC4','PIC4','A','J','PIC1','K','Q','PIC3','10','A','K','PIC9','Q','PIC5','PIC5','PIC5','J','Q','PIC3','K','PIC1','10','K','PIC2','A','10','PIC1','Q','J','10','PIC7','PIC7','PIC7','A','J','PIC1','K','Q','PIC8','10','A','K','PIC3','Q','PIC6','PIC6','PIC6','Q','PIC9','K','PIC1','10','K','PIC8','A','10','PIC1','Q','J','10','PIC9','PIC9','PIC9','A','J','PIC1','K','Q','PIC7','10','A','K','PIC2','Q','PIC8','PIC8','PIC8','Q','PIC4','K','PIC1','10','K','PIC5','A','10'],
		['PIC1','K','A','10','PIC3','PIC3','PIC3','J','A','PIC1','Q','K','PIC6','10','J','Q','PIC7','K','PIC2','PIC2','PIC2','A','K','PIC4','Q','PIC1','10','Q','PIC5','J','10','PIC1','K','A','10','PIC9','PIC9','PIC9','J','A','PIC1','Q','K','PIC3','10','J','Q','PIC8','PIC8','PIC8','A','K','PIC6','Q','PIC1','10','Q','PIC7','J','10','PIC1','K','A','10','PIC4','PIC4','PIC4','J','A','PIC1','Q','K','PIC9','10','J','Q','PIC8','K','PIC5','PIC5','PIC5','K','PIC6','Q','PIC1','10','Q','J','10','PIC1','K','A','10','PIC6','PIC6','PIC6','J','A','PIC1','Q','K','PIC5','10','J','Q','PIC8','K','PIC7','PIC7','PIC7','A','K','PIC2','Q','PIC1','10','Q','PIC3','J','10','PIC1','K','A','10','PIC2','PIC2','PIC2','J','A','PIC1','Q','K','PIC9','10','J','Q','PIC5','K','PIC4','PIC4','PIC4','K','PIC8','Q','PIC1','10','Q','PIC7','J','10','PIC1','K','A','10','PIC8','PIC8','PIC8','J','A','PIC1','Q','K','PIC5','10','J','Q','PIC3','K','PIC9','PIC9','PIC9','K','PIC6','Q','PIC1','10','Q','PIC7','J','10'],
		['PIC1','J','Q','10','PIC6','PIC6','PIC6','K','Q','PIC1','A','J','PIC5','10','K','A','PIC3','J','PIC4','PIC4','PIC4','Q','J','PIC2','A','PIC1','10','A','PIC7','K','10','PIC1','J','Q','10','PIC8','PIC8','PIC8','K','Q','PIC1','A','J','PIC6','10','K','A','PIC9','PIC9','PIC9','Q','J','PIC5','A','PIC1','10','A','PIC3','K','10','PIC1','J','Q','10','PIC2','PIC2','PIC2','K','Q','PIC1','A','J','PIC8','10','K','A','PIC9','J','PIC7','PIC7','PIC7','J','PIC5','A','PIC1','10','A','K','10','PIC1','J','Q','10','PIC5','PIC5','PIC5','K','Q','PIC1','A','J','PIC7','10','K','A','PIC9','J','PIC3','PIC3','PIC3','Q','J','PIC4','A','PIC1','10','A','PIC6','K','10','PIC1','J','Q','10','PIC4','PIC4','PIC4','K','Q','PIC1','A','J','PIC8','10','K','A','PIC7','J','PIC2','PIC2','PIC2','J','PIC9','A','PIC1','10','A','PIC3','K','10','PIC1','J','Q','10','PIC9','PIC9','PIC9','K','Q','PIC1','A','J','PIC7','10','K','A','PIC6','J','PIC8','PIC8','PIC8','J','PIC5','A','PIC1','10','A','PIC3','K','10'],
		['PIC1','K','K','J','PIC8','10','A','J','J','PIC9','Q','10','10','PIC1','Q','K','J','PIC2','PIC2','PIC2','A','10','PIC8','K','Q','Q','PIC4','A','K','PIC1','A','A','PIC7','10','J','PIC1','K','K','J','PIC8','PIC8','PIC8','10','A','J','J','PIC5','Q','10','10','PIC1','Q','K','J','PIC9','PIC9','Q','A','10','PIC8','K','Q','Q','PIC4','A','K','PIC1','A','A','PIC7','10','J','PIC1','K','K','J','PIC6','PIC6','PIC6','10','A','J','J','PIC5','Q','10','10','PIC1','Q','K','J','PIC4','PIC4','PIC4','A','10','K','Q','Q','PIC3','A','K','PIC1','A','A','PIC6','10','J','PIC1','K','K','J','PIC9','PIC9','PIC9','10','A','J','J','PIC3','Q','10','10','PIC1','Q','K','J','PIC5','PIC5','PIC5','A','10','K','Q','Q','PIC2','A','K','PIC1','A','A','PIC7','10','J','PIC1','K','K','J','PIC3','PIC3','PIC3','10','A','J','J','PIC6','Q','10','10','PIC1','Q','K','J','PIC7','PIC7','PIC7','A','10','K','Q','Q','PIC2','A','K','PIC1','A','A','PIC6','10','J'],
	],
	// burger boss specific reel; appears in free spin 
	burgerReel: ['-','PIC2','PIC3','-','PIC8','-','PIC4','PIC5','JACKPOT_FRIES','PIC6','-','PIC2','PIC4','-','PIC7','-','PIC3','PIC5','JACKPOT_FRIES','PIC9','-','PIC5','PIC2','-','PIC6','JACKPOT_FRIES','PIC3','PIC4','-','PIC7','-','PIC6','PIC2','-','PIC8','-','PIC4','PIC3','JACKPOT_FRIES','PIC5','-','PIC2','PIC7','-','PIC3','-','PIC6','PIC4','JACKPOT_FRIES','PIC5'],

	coinType1Values: [200, 1000, 2000, 10_000, 40_000], //gold 
	coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400], //sweeps 

	coinType1_min_bet: 200, 
	coinType1_max_bet: 40_000, 
	coinType1_spin_max_bet: 1000_000, // max_bet * playlines

	coinType4_min_bet: 1, 
	coinType4_max_bet: 400, 
	coinType4_spin_max_bet: 10_000,

	picWeights: {
		PIC2: 2,
		PIC3: 2,
		PIC4: 2,
		PIC5: 2,
		PIC6: 3,
		PIC7: 3,
		PIC8: 3,
		PIC9: 3,
	},

	/** The chance that JACKPOT_FRIES will appear on the burger reel, per coin type and size. 
	 * Each number X in the table means there is a 1000 in X chance of the JACKPOT_FRIES symbol appearing on the burger reel. 
	 */
	jackpotFriesWeights: {
		SWEEPS: {
				1: 2_714_550, // 2714.55 * 1000
				2: 1_356_900, // 1356.9 * 1000
				5: 542_310,   // 542.31 * 1000
				10: 270_780,  // 270.78 * 1000
				20: 135_014,  // 135.014 * 1000
				50: 53_553,   // 53.553 * 1000
				100: 26_396,  // 26.396 * 1000
				200: 12_810,  // 12.81 * 1000
				400: 6_002,   // 6.002 * 1000
		},
		GOLD: {
				100: 2_714_550, // 2714.55 * 1000
				200: 1_356_900, // 1356.9 * 1000
				500: 542_310,   // 542.31 * 1000
				1000: 270_780,  // 270.78 * 1000
				2000: 135_014,  // 135.014 * 1000
				5000: 53_553,   // 53.553 * 1000
				10_000: 26_396, // 26.396 * 1000
				20_000: 12_810, // 12.81 * 1000
				40_000: 6_002,  // 6.002 * 1000
		},
	},

	jackpots: {
		seedValue: {
			GOLD: 80_000_000,
			SWEEPS: 800_000,
		},
		percentToJackpotPool: 0.5,
	},
		
};
