// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-cf064390] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-cf064390] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-cf064390] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-cf064390] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-cf064390] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-cf064390] {
    margin: 5px 0 5px 0;
}
.selected-scatters[data-v-cf064390] {
    position: inline-block;
    top: 50px;
    margin-left: 18px;
    text-align: center;
    border: 3px solid green;
    padding: 10px;
}
.selected-scatter[data-v-cf064390] {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 75px;
}
.max-scatter-selector[data-v-cf064390] {
    padding: 10px;
    text-align: center;
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/patriotParty/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,SAAS;IACT,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;AACjB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".sm-game-spec-content[data-v-cf064390] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-cf064390] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-cf064390] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-cf064390] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-cf064390] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-cf064390] {\n    margin: 5px 0 5px 0;\n}\n.selected-scatters[data-v-cf064390] {\n    position: inline-block;\n    top: 50px;\n    margin-left: 18px;\n    text-align: center;\n    border: 3px solid green;\n    padding: 10px;\n}\n.selected-scatter[data-v-cf064390] {\n    display: inline-block;\n    margin-right: 10px;\n    margin-left: 10px;\n    margin-bottom: 10px;\n    width: 75px;\n}\n.max-scatter-selector[data-v-cf064390] {\n    padding: 10px;\n    text-align: center;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
