var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStep()
              },
            },
          },
          [_vm._v("Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreespinStep()
              },
            },
          },
          [_vm._v("Freespin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: ["button", "step-button", "pointer", ""],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isRespin,
              expression: "!isRespin",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [_vm._v("Reel option :")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.reelOption,
                  expression: "reelOption",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "reelOption", id: "reelOption" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.reelOption = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeReelOption,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "" } }, [
                _vm._v("0"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c(
                "option",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFeature,
                      expression: "isFeature",
                    },
                  ],
                  attrs: { value: "2" },
                },
                [_vm._v("2")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c(
          "div",
          { staticClass: "selected-scatters" },
          [
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value,
                attrs: {
                  index: value,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 5,
                attrs: {
                  index: value + 5,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 5 + 5,
                attrs: {
                  index: value + 5 + 5,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.resetRespinSymbols()
              },
            },
          },
          [_vm._v("Reset")]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin,
              expression: "isRespin",
            },
          ],
          staticClass: "flex-center",
        },
        [_c("label", [_vm._v(" Expanding Symbols (for respin end step): ")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin,
              expression: "isRespin",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "flex-center menu sm-label" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    return _vm.updateExpandingSymbolCounter(true)
                  },
                },
              },
              [_vm._v("Add")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    return _vm.updateExpandingSymbolCounter(false)
                  },
                },
              },
              [_vm._v("Delete")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        _vm._l(_vm.expandingSymbols.length, function (n) {
          return _c("expandingSymbol", {
            key: n,
            attrs: { index: n, expandingSymbols: _vm.expandingSymbols },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }