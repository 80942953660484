import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import type { InitArguments, InitOutcome } from "@vgw/gdk-math-model-server";
import type { Choices } from "../../choices";
import {
  CharacterWilds,
  JackpotPhase,
  PickFeature,
  type GameState,
} from "../shared";
import { modelDefinition } from "../../model-definition";
import type { GameResponse } from "../play/game-response";
import { play } from "../play";

export function init(
  { coinType, gameState }: InitArguments<GameState>,
  choices: Choices
): InitOutcome<InitResponse> {
  if (gameState) {
    return {
      initResponse: {
        coinAmount: gameState.coinAmount,
        reelStripPositions: gameState.reelStripPositions,
        slotWindow: gameState.slotWindow,
        cumulativeWinAmount: gameState.cumulativeWinAmount,
        freeSpins: gameState.freeSpins,
        picks: gameState.picks
          ? {
              phase: gameState.picks.phase,
              userPicks: gameState.picks.userPicks,
            }
          : undefined,
        jackpotPhase: gameState.jackpotPhase,
        modelDefinition: {
          validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
          reels: modelDefinition.reels,
        },
      },
    };
  }

  let spinWithNoWin: GameResponse | undefined;
  while (spinWithNoWin === undefined) {
    const { gameResponse } = play(
      {
        coinType: "SC",
        gameRequest: { coinAmount: modelDefinition.validCoinAmounts.SC[0] },
      },
      choices
    );
    const { winType } = gameResponse;
    if (winType === "NO_WIN") {
      spinWithNoWin = gameResponse;
      break;
    }
  }

  const { reelStripPositions, slotWindow } = spinWithNoWin;

  return {
    initResponse: {
      reelStripPositions,
      slotWindow,
      modelDefinition: {
        validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
        reels: modelDefinition.reels,
      },
    },
  };
}

export const InitResponseSchema = Type.Object({
  coinAmount: Type.Optional(Type.Integer()),
  reelStripPositions: Type.Array(Type.Integer()),
  slotWindow: Type.Array(Type.Array(Type.String())),
  // Restore information
  cumulativeWinAmount: Type.Optional(Type.Number()),
  freeSpins: Type.Optional(
    Type.Object({
      remaining: Type.Number(),
      phase: Type.Union([
        Type.Literal("START"),
        Type.Literal("IN_PROGRESS"),
        Type.Literal("RETRIGGER"),
        Type.Literal("END"),
      ]),
      picToWilds: Type.Optional(CharacterWilds),
    })
  ),
  picks: Type.Optional(
    Type.Object({
      phase: Type.Union([
        Type.Literal("START"),
        Type.Literal("IN_PROGRESS"),
        Type.Literal("END"),
      ]),
      userPicks: Type.Optional(Type.Array(PickFeature)),
    })
  ),
  jackpotPhase: Type.Optional(JackpotPhase),
  modelDefinition: Type.Object({
    validCoinAmounts: Type.Array(Type.Number()),
    reels: Type.Array(Type.Array(Type.String())),
  }),
});
export type InitResponse = Static<typeof InitResponseSchema>;
