export function getWinType(info: {
    winAmount: number;
    isScatterWin: boolean;
    isFreeSpinTrigger: boolean;
    isFreeSpin: boolean;
    isJackpotTrigger: boolean;
}) {
    if (info.isJackpotTrigger) return 'JACKPOT_WIN';
    if (info.isFreeSpinTrigger) return 'BONUS_WIN';
    if (info.isScatterWin) return 'SCATTER_WIN';

    if (info.isFreeSpin) {
        return info.winAmount > 0 ? 'FREE_SPIN_WIN' : 'FREE_SPIN_NO_WIN';
    }
    return info.winAmount > 0 ? 'NORMAL_WIN' : 'NO_WIN';
}
