import Vue, { PropType } from "vue";
import { type InternalScenario } from '../../content.vue'

export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    spinType: String as PropType<"FREE_SPINS" | "BASE">,
    isWinCell: Boolean,
    scenario: Object as PropType<InternalScenario>,
    isBankerScatAllowed: Boolean,
    scatterBankerPos: Array as PropType<number[]>,
    isBankerCaseAllowed: Boolean,
    caseBankerPos: Array as PropType<number[]>,
  },
  computed: {
    isScatter: function (): boolean {
      return this.symbolName === "SCAT";
    },
    isCase: function (): boolean {
      return this.symbolName === "CASE";
    },
    isWild: function (): boolean {
      return this.symbolName === "WILD";
    },
    isScatterBankerAllowed: function(): boolean {
      return this.symbolName === "SCAT" && this.isBankerScatAllowed;
    },
    isCaseBankerAllowed: function(): boolean {
      return this.symbolName === "CASE" && this.isBankerCaseAllowed;
    },
  },
  methods: {
    isBankerScatterSelected: function(reelIndex: number, rowIndex: number): boolean {
      return this.scatterBankerPos[0] === rowIndex && this.scatterBankerPos[1] === reelIndex;
    },
    isBankerCaseSelected: function(reelIndex: number, rowIndex: number): boolean {
      return this.caseBankerPos[0] === rowIndex && this.caseBankerPos[1] === reelIndex;
    },
    selectBankerScatterPosition: function(reelIndex: number, rowIndex: number){
      this.scenario.bankerScat.cell = [rowIndex, reelIndex];
    },
    selectBankerCasePosition: function(reelIndex: number, rowIndex: number){
      this.scenario.bankerCase.cell = [rowIndex, reelIndex];
    }
  },
});