import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events'; 
import {getReelWindowFromIndex} from '../../../../helpers/helpers';
import {
    createClearingStep,
    createSpinStep,
    createFreeSpinTriggerStep,
    createHoldAndSpinTriggerStep,
    modelDefinition,
    originatorId,
    StepJson,
    CheeseBlockPrize,
} from '../../../../state/models/slots/vgw067';
import { CheeseBlock, CheeseSize, findCheeseBlocks } from './math-model';
import Reel from './reel.vue';

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
    },

    data: () => ({
        allSteps: [] as Step[],
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        content(): any {
            return this;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        previousStep(): Step | undefined {
            return this.allSteps[(this.currentStepIndex ?? 0) - 1];
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '???';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'H-Trigger') return 'Hold & Spin Trigger';
            if (this.stepName === 'F-Trigger') return 'Free Spin Trigger';
            if (this.stepName === 'F-Retrigger') return 'Free Spin Retrigger';
            if (this.isHoldAndSpinRespin) return 'Hold & Spin Respin';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },
        isClearingStep(): boolean {
            return this.stepName === 'Clear';
        },
        canAddClearingStep(): boolean {
            return true;
        },
        canAddSpinStep(): boolean {
            return true;
        },
        canAddFreeSpinTriggerStep(): boolean {
            return !this.finalStep?.holdAndSpinCount;
        },
        canAddHoldAndSpinTriggerStep(): boolean {
            return !this.finalStep?.holdAndSpinCount;
        },
        labelForAddSpinStep(): string {
            if (this.finalStep?.holdAndSpinCount) return 'Add Respin';
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin';
            return 'Add Base Spin';
        },
        labelForAddFreeSpinTriggerStep(): string {
            return `${this.finalStep?.freeSpinCount ? 'Ret' : 'T'}rigger Free Spins`;
        },

        // ----- Base game state -----
        reels(): string[][] {
            return modelDefinition.reels;
        },
        showReelStripPositions(): boolean {
            return this.show;
        },
        canChangeReelStripPositions(): boolean {
            return !this.isClearingStep && !this.isHoldAndSpinRespin;
        },

        // ----- Free spin state -----
        isFreeSpin(): boolean {
            if (this.isHoldAndSpinRespin) return false;
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase !== undefined && freeSpinPhase !== 'START';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        freeSpinRetriggersRemaining(): number {
            return this.currentStep?.retriggerCount ?? 0;
        },
        showFreeSpinPick(): boolean {
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'START' || freeSpinPhase === 'RETRIGGER';
        },
        freeSpinPickValue: {
            get(): number {
                return this.currentStep?.json.freeSpinsPicked ?? 6;
            },
            set(value: number) {
                if (!this.currentStep) return;
                this.currentStep.json.freeSpinsPicked = value;
                this.saveChangesToStep();
            },
        },
        freeSpinPickOptions() {
            return [
                {label: '6 free spins, wins x5', value: 6},
                {label: '12 free spins, wins x2', value: 12},
                {label: '20 free spins', value: 20},
            ];
        },

        // ----- Hold and Spin state -----
        isHoldAndSpinTrigger(): boolean {
            return this.currentStep?.holdAndSpinPhase === 'START';
        },
        isHoldAndSpinRespin(): boolean {
            const holdAndSpinPhase = this.currentStep?.holdAndSpinPhase;
            return holdAndSpinPhase === 'IN_PROGRESS' || holdAndSpinPhase === 'END';
        },
        holdAndSpinRespinsRemaining(): number {
            return this.currentStep?.holdAndSpinCount ?? 0;
        },
        holdAndSpinCheesesCollected(): number {
            return this.currentStep?.cumulativeCheeseCount ?? 0;
        },
    },

    methods: {
        // ----- Step methods -----
        addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
        addSpinStep: () => bus.$emit(events.ADD_STEP, createSpinStep()),
        addFreeSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
        addHoldAndSpinTriggerStep: () => bus.$emit(events.ADD_STEP, createHoldAndSpinTriggerStep()),

        // ----- Hold and Spin methods -----
        isCheeseAt({row, col}: {row: number, col: number}): boolean {
            return this.currentStep?.cumulativeCheeses?.[col]?.[row] === 1;
        },
        canSetOrUnsetCheeseAt({row, col}: {row: number, col: number}): boolean {
            if (!this.isHoldAndSpinRespin) return false;
            return this.previousStep?.cumulativeCheeses?.[col]?.[row] === 0;
        },
        setCheeseAt({row, col, value}: {row: number, col: number, value: 0 | 1}): void {
            if (!this.currentStep) return;
            this.currentStep.json.cheeses = [0, 1, 2, 3, 4].map(c => {
                return [0, 1, 2, 3].map(r => {
                    return c === col && r === row ? value : this.currentStep?.json.cheeses?.[c]?.[r] ?? 0;
                });
            });
            this.saveChangesToStep();
        },
        cheeseBlockClassAt({row, col}: {row: number, col: number}): string {
            const cb = this.currentStep?.cheeseBlocks?.find(cb => cb.position[0] === row && cb.position[1] === col);
            return cb ? `cb${cb.size}` : '';
        },
        showPrizeAt({row, col}: {row: number, col: number}): boolean {
            return this.currentStep?.holdAndSpinPhase === 'END' && !!this.cheeseBlockClassAt({row, col});
        },
        getPrizeAt({row, col}: {row: number, col: number}): CheeseBlockPrize {
            const index = this.currentStep?.cheeseBlocks?.findIndex(cb => cb.position[0] === row && cb.position[1] === col) ?? -1;
            return this.currentStep?.json.cheeseBlockPrizes?.[index] ?? 0;
        },
        setPrizeAt({row, col, value}: {row: number, col: number, value: string}): void {
            const index = this.currentStep?.cheeseBlocks?.findIndex(cb => cb.position[0] === row && cb.position[1] === col) ?? -1;
            if (!this.currentStep || index === -1) return;
            const prizes = this.currentStep.json.cheeseBlockPrizes ??= [];
            prizes[index] = toCheesePrize(value);
            this.saveChangesToStep();
        },
        prizeOptionsAt({row, col}: {row: number, col: number}): CheeseBlockPrize[] {
            const cb = this.currentStep?.cheeseBlocks?.find(cb => cb.position[0] === row && cb.position[1] === col);
            return cb ? getCheeseBlockPrizeLevels(cb.size) : [];
        },

        // ----- Scenario persistence methods -----
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: {steps: Array<{test_scenario_step_id: number, json: StepJson}>}) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }

            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;

            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    retriggerCount?: number;
    holdAndSpinPhase?: 'START' | 'IN_PROGRESS' | 'END';
    holdAndSpinCount?: number;
    cumulativeCheeses?: (0 | 1)[][];
    cumulativeCheeseCount?: number;
    cheeseBlocks?: CheeseBlock[];
}

function updateStepsInPlace(steps: Step[]) {
    let name = '';
    let isFreeSpin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;
    let retriggerCount = 0;
    let isHoldAndSpinRespin = false;
    let holdAndSpinPhase: Step['holdAndSpinPhase'];
    let holdAndSpinCount = 0;
    let cheeses: (0 | 1)[][] | undefined;
    let cumulativeCheeses: (0 | 1)[][] | undefined;
    let cumulativeCheeseCount = 0;
    let cheeseBlocks: CheeseBlock[] | undefined;
    let cheeseBlockPrizes: CheeseBlockPrize[] | undefined;

    let isAnyStepModified = false;
    for (const step of steps) {
        if (step.json.name === 'Clear') {
            // Handle clearing steps by clearing all state
            name = step.json.name;
            isFreeSpin = false;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
            retriggerCount = 0;
            isHoldAndSpinRespin = false;
            holdAndSpinPhase = undefined;
            holdAndSpinCount = 0;
            cheeses = undefined;
            cumulativeCheeses = undefined;
            cumulativeCheeseCount = 0;
            cheeseBlocks = undefined;
            cheeseBlockPrizes = undefined;
        }
        else {
            isFreeSpin = freeSpinCount > 0 && holdAndSpinCount === 0;
            isHoldAndSpinRespin = holdAndSpinCount > 0;

            // Update state for hold and spin respins
            if (isHoldAndSpinRespin) {
                const prevCheeseCount = cumulativeCheeseCount;
                cheeses = step.json.cheeses ?? [0, 1, 2, 3, 4].map(_ => [0, 0, 0, 0]);
                cumulativeCheeses = [0, 1, 2, 3, 4].map(col => [0, 1, 2, 3].map(row => cumulativeCheeses?.[col]?.[row] || cheeses?.[col]?.[row] || 0) || 0);
                cumulativeCheeseCount = cumulativeCheeses.reduce((n, cps) => n + cps.filter(ch => ch === 1).length, 0);
                name = 'Respin';
                holdAndSpinCount = cumulativeCheeseCount === 20
                    ? 0
                    : cumulativeCheeseCount > prevCheeseCount
                        ? modelDefinition.holdAndSpinRespinCount
                        : holdAndSpinCount - 1;
                holdAndSpinPhase = holdAndSpinCount > 0 ? 'IN_PROGRESS' : 'END';
            }

            // Update state for base and free spins
            else {
                holdAndSpinPhase = undefined;
                cheeses = undefined;
                cumulativeCheeses = undefined;
                cumulativeCheeseCount = 0;
                cheeseBlocks = undefined;
                cheeseBlockPrizes = undefined;

                if (!isFreeSpin) {
                    name = 'Spin';
                    freeSpinPhase = undefined;
                }
                else {
                    name = 'Free spin';
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END'
                }

                // Handle free spin triggers
                const scatCells = findCells(step.json.reelStripPositions, modelDefinition.scatterSymbol);
                const isFreeSpinTrigger = scatCells.length >= 3;
                if (isFreeSpinTrigger) {
                    if (!isFreeSpin) {
                        name = 'F-Trigger';
                        freeSpinPhase = 'START';
                        freeSpinCount = step.json.freeSpinsPicked ?? 6;
                    }
                    else {
                        retriggerCount += 1;
                    }
                }

                // Handle free spin retriggers
                if (isFreeSpin && freeSpinCount === 0 && retriggerCount > 0) {
                    name = 'F-Retrigger';
                    retriggerCount -= 1;
                    freeSpinPhase = 'RETRIGGER';
                    freeSpinCount = step.json.freeSpinsPicked ?? 6;
                }

                // Handle hold and spin triggers
                const cheeseCells = findCells(step.json.reelStripPositions, modelDefinition.wildSymbol);
                const isHoldAndSpinTrigger = cheeseCells.length >= modelDefinition.countToTriggerHoldAndSpin;
                if (isHoldAndSpinTrigger) {
                    name = 'C-Trigger';
                    holdAndSpinCount = modelDefinition.holdAndSpinRespinCount;
                    holdAndSpinPhase = 'START';
                    cumulativeCheeses = [0, 1, 2, 3, 4].map(_ => [0, 0, 0, 0]);
                    for (const [row, col] of cheeseCells) cumulativeCheeses[col][row] = 1;
                    cumulativeCheeseCount = cumulativeCheeses.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
    
                }
            }

            // Update cheese blocks and prizes
            if (cumulativeCheeses) {
                const slotWindow = cumulativeCheeses.map(col => col.map(isCheeseAt => isCheeseAt ? 'WILD' : ''));
                cheeseBlocks = findCheeseBlocks(slotWindow, cheeseBlocks);
                if (holdAndSpinPhase === 'END') {
                    cheeseBlockPrizes = cheeseBlocks.map((cb, i) => {
                        return step.json.cheeseBlockPrizes?.[i] ?? getCheeseBlockPrizeLevels(cb.size)[0];
                    });
                }
            }
        }

        // Save all the step details
        Object.assign(step, {
            freeSpinPhase, freeSpinCount,
            holdAndSpinPhase, holdAndSpinCount,
            cumulativeCheeses, cumulativeCheeseCount,
            cheeseBlocks,
        });
        const json = {...step.json, name, cheeses, cheeseBlockPrizes};
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }
    return isAnyStepModified;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = modelDefinition.reels;
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function toCheesePrize(value: string): CheeseBlockPrize {
    const n = Number(value);
    return isNaN(n) ? (value as CheeseBlockPrize) : n;
}

function getCheeseBlockPrizeLevels(size: CheeseSize): CheeseBlockPrize[] {
    switch (size) {
        case '4x5': return [...modelDefinition.cheeseBlockPrizeWeights['4x5'].BASE.map(it => it.outcome), 'GRAND'];
        case '4x4': return [...modelDefinition.cheeseBlockPrizeWeights['4x4'].BASE.map(it => it.outcome), 'MAJOR'];
        case '3x3': return modelDefinition.cheeseBlockPrizeWeights['3x3'].BASE.map(it => it.outcome);
        case '2x2': return modelDefinition.cheeseBlockPrizeWeights['2x2'].BASE.map(it => it.outcome);
        case '1x1': return modelDefinition.cheeseBlockPrizeWeights['1x1'].BASE.map(it => it.outcome);
    }
}
