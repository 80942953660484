import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;

    private reelsCount = 5;
    private isTreasureReel = false;
    private activeStep = "BaseGame";

    private reelSet = "1";

    private scatterPlacementFactor = "false";
    private scatterReelsPosition = "0, 1, 2";
    private scatterRowPosition = "0, 1, 2";

    private monkeyPlacementFactor = "false";
    private monkeyReelPosition = "1";
    private monkeyRowPosition = "1";
    private respinReelSet = "1";

    private ghostyCaptainPlacementFactor = "false";
    private ghostlyCaptainReelsPosition = "0,1,2";
    private ghostlyRowPosition = "0,1,2";

    private mapSymbolFactor = "false";
    private mapSymbolCount = "1";
    private mapSymbolReel = "0,1,2";
    private mapSymbolRowPosition = "0,1,2";

    private fatSymbolId = "18, 19, 20, 21";
    private fatSymbolReels = "0, 1, 2";

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;

        if (this.reelsCount != (step.json.isTreasureReel ? 6 : 5) || this.step.json.internalName != this.activeStep) {
            this.reelsCount = step.json.isTreasureReel ? 6 : 5;

            bus.$emit(events.UPDATE_STEPS_DB, step);
        }

        this.loadValuesOnStepChange();
    }

    public mounted() {
        trace("reels mounted");
    }

    private loadValuesOnStepChange() {
        this.activeStep = this.step.json.internalName;
        this.isTreasureReel = this.step.json.isTreasureReel;
        this.reelSet = this.step.json.reelSet;

        if (["BaseGame", "BGTreasureReel"].includes(this.activeStep)) {
            this.monkeyPlacementFactor = this.step.json.monkeyPlacementFactor.toString();
            this.monkeyReelPosition = (this.step.json.monkeyReelPosition + 1).toString();
            this.monkeyRowPosition = (this.step.json.monkeyRowPosition + 1).toString();
            this.respinReelSet = this.step.json.respinReelSet.toString();
        }
        else {
            this.ghostyCaptainPlacementFactor = this.step.json.ghostyCaptainPlacementFactor.toString();
            this.ghostlyCaptainReelsPosition = this.step.json.ghostlyCaptainReelsPosition.join(',');
        }

        this.scatterPlacementFactor = this.step.json.scatterPlacementFactor.toString();
        this.scatterReelsPosition = this.step.json.scatterReelsPosition.join(',');
        this.scatterRowPosition = this.step.json.scatterRowPosition.join(',');

        this.mapSymbolFactor = this.step.json.mapSymbolFactor.toString();
        this.mapSymbolCount = (this.step.json.mapSymbolCount).toString();
        this.mapSymbolReel = this.step.json.mapSymbolReel.join(',');
        this.mapSymbolRowPosition = this.step.json.mapSymbolRowPosition.join(',');

        if (this.isTreasureReel) {
            this.fatSymbolId = this.step.json.fatSymbolId.join(',');
            this.fatSymbolReels = this.step.json.fatSymbolReels.join(',');
        }
    }

    /**
     * Adds a new base game step.
     */
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    /**
     * Adds a new base game treasure reel step.
     */
    public addBGTreasureReelStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep(true));
    }

    /**
     * Adds a new free game step.
     */
    public addFreeGameStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeGameStep());
    }

    /**
     * Adds a new base game treasure reel step.
     */
    public addFGTreasureReelStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeGameStep(true));
    }

    private onReelSetChange() {
        this.step.json.reelSet = +this.reelSet;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMonkeyPlacementFactorChange() {
        this.step.json.monkeyPlacementFactor = this.strToBool(this.monkeyPlacementFactor);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMonkeyReelPositionChange() {
        this.step.json.monkeyReelPosition = (+this.monkeyReelPosition) - 1;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMonkeyRowPositionChange() {
        this.step.json.monkeyRowPosition = (+this.monkeyRowPosition) - 1;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onRespinReelSetChange() {
        this.step.json.respinReelSet = +this.respinReelSet;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onScatterPlacementFactorChange() {
        this.step.json.scatterPlacementFactor = this.strToBool(this.scatterPlacementFactor);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onScatterReelsPositionChange() {
        this.step.json.scatterReelsPosition = [];
        this.scatterReelsPosition.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.scatterReelsPosition.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onScatterRowPositionChange() {
        this.step.json.scatterRowPosition = [];
        this.scatterRowPosition.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.scatterRowPosition.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMapSymbolFactorChange() {
        this.step.json.mapSymbolFactor = this.strToBool(this.mapSymbolFactor);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMapSymbolCountChange() {
        this.step.json.mapSymbolCount = (+this.mapSymbolCount);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMapSymbolReelChange() {
        this.step.json.mapSymbolReel = [];
        this.mapSymbolReel.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.mapSymbolReel.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onMapSymbolRowPositionChange() {
        this.step.json.mapSymbolRowPosition = [];
        this.mapSymbolRowPosition.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.mapSymbolRowPosition.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onFatSymbolIdChange() {
        this.step.json.fatSymbolId = [];
        this.fatSymbolId.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.fatSymbolId.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onFatSymbolReelsChange() {
        this.step.json.fatSymbolReels = [];
        this.fatSymbolReels.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.fatSymbolReels.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onGhostyCaptainPlacementFactorChange() {
        this.step.json.ghostyCaptainPlacementFactor = this.strToBool(this.ghostyCaptainPlacementFactor);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onGhostlyCaptainReelsPositionChange() {
        this.step.json.ghostlyCaptainReelsPosition = [];
        this.ghostlyCaptainReelsPosition.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.ghostlyCaptainReelsPosition.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private onGhostlyRowPositionChange() {
        this.step.json.ghostlyRowPosition = [];
        this.ghostlyRowPosition.split(',').forEach((value) => {
            if (!Number.isNaN(+(value.trim()))) {
                this.step.json.ghostlyRowPosition.push(+(value.trim()));
            }
        });

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private strToBool(value: string): boolean {
        return value.toLowerCase() == "true";
    }

    private get ReelSetCount(): number {
        return this.isTreasureReel ? 7 : 16;
    }

    private get isFreeSpin(): boolean {
        return !(["BaseGame", "BGTreasureReel"].includes(this.activeStep));
    }
}
