/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([13, 10, 79, 9, 9, 3]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => State.state.createStep([40, 29, 78, 7, 0, 0], false, 0, "", "F-Trigger") ,
        getFeatureStep: () => State.state.createStep([13, 10, 79, 9, 9, 3], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([40, 29, 78, 7, 0, 0], true, 0, "", "F-Retrigger") ,
        getFeatureCollect10Step: () => State.state.createStep([13, 1, 5, 29, 0, 0], true, 0, "", "FStep-Collect10") ,
        getFeatureCollect5Step: () => State.state.createStep([13, 2, 5, 31, 0, 77], true, 0, "", "FStep-Collect5") ,
    };
}
