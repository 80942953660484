import { MathModelError } from '../../../../v3/math-model';
import { GameState } from '../../game-state';
import { PickInputs, PickOptions } from './pick-inputs';
import { PickOutputs } from './pick-outputs';

export function handlePick({ gameState, pick, choices }: PickInputs): PickOutputs {
    if (!gameState) throw new MathModelError('Game state does not exist when attempting a pick');
    const isPick = true as const;

    const pickResults: { freeSpinCount?: number; freeSpinsMultiplier?: number } = {};

    if (pick === 'freeSpinWheel') {
        pickResults.freeSpinCount = choices.chooseFreeSpinsAwarded();
        gameState.freeSpinCount = pickResults.freeSpinCount;
        gameState.freeSpinsAwarded = pickResults.freeSpinCount;
    } else if (pick === 'multiplierWheel') {
        pickResults.freeSpinsMultiplier = choices.chooseFreeSpinsMultiplier();
        gameState.freeSpinsMultiplier = pickResults.freeSpinsMultiplier;
    } else {
        pickResults.freeSpinCount = choices.chooseFreeSpinsAwarded();
        pickResults.freeSpinsMultiplier = choices.chooseFreeSpinsMultiplier();
        gameState.freeSpinCount = pickResults.freeSpinCount;
        gameState.freeSpinsAwarded = pickResults.freeSpinCount;
        gameState.freeSpinsMultiplier = pickResults.freeSpinsMultiplier;
    }

    return {
        gameState,
        pickResults,
        isPick,
    };
}