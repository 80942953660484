import { createSlotWindowGenerator, filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../choices';
import { modelDefinition } from '../../model-definition';
import type { GameState } from '../shared';
import type { GameRequest } from './game-request';
import type { SpinOutcome } from './outcome';
import { getWinType } from './get-win-type';
import { createWinsEvaluator } from './create-wins-evaluator';
import type { coinType } from '../../coin-type';

export function spinFree(
    { coinType, gameRequest, gameState: lastGameState }: FreeSpinsInputs,
    choices: Choices,
): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;
    const oldCumulativeWinAmount = lastGameState.cumulativeWinAmount;

    // Generate Slot Window
    const reelStripPositions = choices.chooseReelStripPositions();
    const slotWindow = generateSlotWindow(reelStripPositions);

    // Evaluate ways wins
    const { winAmount, waysWins, bonusWin, winCellsBySymbol, scatterWin, isSuperStarAwarded } = evaluateWins({
        slotWindow,
        coinAmount,
        playAmount,
        coinType,
        choices,
    });

    // Evaluate free spin retrigger
    const bonusSpinCells = filterSlotWindow(slotWindow, (sym) => sym === modelDefinition.bonusSymbol);

    const numberOfFreeSpins = modelDefinition.freeSpinRetriggerCount[bonusSpinCells.length - 1];
    const isFreeSpinReTrigger = numberOfFreeSpins > 0;

    // Compute win type and amount
    const cumulativeWinAmount = oldCumulativeWinAmount + winAmount;
    const numberOfScatters = scatterWin?.cells.length;
    const isGrandTriggered =
        isSuperStarAwarded && numberOfScatters === modelDefinition.scatterSymbolsToTriggerProgressiveJackpots;

    const winType = getWinType({
        winAmount,
        isFreeSpinTrigger: isFreeSpinReTrigger,
        isScatterWin: scatterWin !== undefined,
        isFreeSpin: true,
        isJackpotTrigger: isGrandTriggered,
    });

    // Calculate new game state
    let { freeSpinCount, freeSpinPhase } = lastGameState;
    freeSpinCount -= 1;
    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';

    if (isFreeSpinReTrigger) {
        freeSpinPhase = 'RETRIGGER';

        freeSpinCount += numberOfFreeSpins;
    }

    const jackpotWinCounts = isGrandTriggered ? { GRAND: 1 } : undefined;

    const roundComplete = freeSpinPhase === 'END';
    const gameState: GameState | undefined = roundComplete
        ? undefined
        : {
              coinAmount,
              cumulativeWinAmount,
              freeSpinCount,
              freeSpinPhase,
              reelStripPositions,
              slotWindow,
          };

    return {
        playSummary: {
            playAmount: 0,
            winAmount,
            roundComplete,
            jackpotWinCounts,
        },
        gameResponse: {
            winAmount,
            cumulativeWinAmount: gameState?.cumulativeWinAmount,

            reelStripPositions,
            slotWindow,

            scatterWin,
            bonusWin,
            coinAmount: gameRequest.coinAmount,
            freeSpinCount,
            freeSpinPhase,
            playAmount,
            waysWins,
            winType,
            winCellsBySymbol,
            jackpotWinCounts,
            isSuperStarAwarded,
        },
        gameState,
    };
}

export type FreeSpinsInputs = {
    coinType: coinType;
    gameRequest: GameRequest;
    gameState: GameState;
};

const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
const evaluateWins = createWinsEvaluator(modelDefinition);
