/* tslint:disable:variable-name */
import State from "../../../state";
import { rowsFromIndexes } from "../../../../helpers/helpers";
import { createDefaultSlotStep } from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = '', name = 'Base', jackpotPicks?) => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.gameState = isFeature ? 'continue' : 'clear';

            if (jackpotPicks) {
                step.jackpotPicks = jackpotPicks;
            }

            return step;
        },

        // Must implement
        createDefaultStep: (order = 0, variation = '') => State.state.createStep([0, 0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),

        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: (reelID: string, reelIndex: number) => State.state.getModelDefinition()[reelID][reelIndex].reel,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        // Get the Base Game Step
        getBaseStep: (reelID: string, reelIndex: string) => {
            const step = State.state.createDefaultStep();
            step.reelID = reelID;
            step.reelIndex = reelIndex;
            step.internalName = "BaseGame";
            return step;
        },

        // Gets the Free Game Step
        getFreeGameStep: (reelID: string, reelIndex: string) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Free Game");
            step.reelID = reelID;
            step.reelIndex = reelIndex;
            step.internalName = "FreeGame";
            step.enableFreeGameFeatures = true;
            for (let i = 1; i <= 4; i++) {
                step["activateFeature" + i] = false;
            }
            return step;
        },
        getClearingStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0, 0], false, order, '', 'Clear'),
        prepareStepForDB: (step) => {
            const state = State.state;
            step.reelID = step.reelID ? step.reelID :  "_noWin";
            step.reelIndex = step.reelIndex ? step.reelIndex : "0";
            step.internalName = "BaseGame";
            for (let i = 1; i <= 4; i++) {
                step["activateFeature" + i] = false;
            }
            const reelStrips = state.getReelStrips(step.reelID, parseInt(step.reelIndex));
            const noOfReels = 5;
            const noOfRows = 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        },
    };
}
