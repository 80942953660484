
/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "modelId" : "pf004_hanselgretel",
  
    "scatterSymbol":"FS1",
    "wildSymbol":"WI1",
    "wildSymbol2":"WI2",
    "reelForWild2Symbol":2,
    "positionForWild2Symbol":2,
  
    "freeSpinTrigger" : "FS1",
    "countToTriggerFreeSpin" : 3,
    "startFeatureMultiplier": 1,
    "maxFeatureMultiplier":5,
    "freeSpinWeightCount": {
      "freeSpins": [
        {"freeSpinCount":8 , "weight":1},
        {"freeSpinCount":12, "weight":1},
        {"freeSpinCount":16, "weight":1}
      ]
    },
    "gameBonuses":{
      "baseBonus":[
        {"gameType": "NoBonus", "weight": 950000},
        {"gameType": "HanselWildBonus", "weight": 23079},
        {"gameType": "GretelWildBonus", "weight" : 26921}
      ]
    },
  
    "featureGameBonuses":{
      "featureBonus":[
        {"gameType":"NoBonus", "weight":5000},
        {"gameType":"HGBonus", "weight":5000}
      ]
    },
    "hgFeatureBonus":{
      "jumboSymbols":[
        {"jumboSymbol":"LV1", "weight":200},
        {"jumboSymbol":"LV2", "weight":200},
        {"jumboSymbol":"LV3", "weight":200},
        {"jumboSymbol":"LV4", "weight":200},
        {"jumboSymbol":"LV5", "weight":200},
        {"jumboSymbol":"HV1", "weight":50},
        {"jumboSymbol":"HV2", "weight":40},
        {"jumboSymbol":"HV3", "weight":30},
        {"jumboSymbol":"HV4", "weight":20}
      ],
      "jumboSymbolSize":[
        {"symbolSize":2, "weight":900},
        {"symbolSize":3, "weight":100}
      ]
    },
    "jumboSymbolSize2Position":{
      "reelSelected":[
        {"reelNumber":0,"weight":1},
        {"reelNumber":1,"weight":1},
        {"reelNumber":2,"weight":1},
        {"reelNumber":3,"weight":1}
      ],
      "positionSelected":[
        {"jumboPosition":0,"weight":1},
        {"jumboPosition":1,"weight":1},
        {"jumboPosition":2,"weight":1},
        {"jumboPosition":3,"weight":1}
      ]
    },
    "jumboSymbolSize3Position":{
      "reelSelected":[
        {"reelNumber":0,"weight":1},
        {"reelNumber":1,"weight":1},
        {"reelNumber":2,"weight":1}
      ],
      "positionSelected":[
        {"jumboPosition":0,"weight":1},
        {"jumboPosition":1,"weight":1},
        {"jumboPosition":2,"weight":1}
      ]
    },
  
    "winTable": [
      {
        "symbol": "WI2",
        "multipliers":[300,75,25]
      },
      {
        "symbol":"WI1",
        "multipliers":[300,75,25]
      },
      {
        "symbol":"HV4",
        "multipliers":[300,75,25]
      },
      {
        "symbol":"HV3",
        "multipliers":[60,30,15]
      },
      {
        "symbol":"HV2",
        "multipliers":[50,25,12]
      },
      {
        "symbol":"HV1",
        "multipliers":[40,20,10]
      },
      {
        "symbol":"LV5",
        "multipliers":[20,10,5]
      },
      {
        "symbol":"LV4",
        "multipliers":[20,10,5]
      },
      {
        "symbol":"LV3",
        "multipliers":[15,8,4]
      },
      {
        "symbol":"LV2",
        "multipliers":[15,8,4]
      },
      {
        "symbol":"LV1",
        "multipliers":[15,8,4]
      },
      {
        "symbol":"FS1",
        "multipliers":[]
      }
    ],
    "reels":[
      ["LV4", "LV4", "LV2", "HV1", "HV1", "LV4", "HV3", "LV2", "LV5", "LV2", "HV2", "LV4", "LV4", "LV2", "FS1", "LV5", "LV2", "LV5", "HV1", "HV4", "LV2", "LV1", "LV1", "HV1", "LV3", "LV3", "HV2", "LV2", "HV4", "HV4", "LV2", "LV4", "LV1", "LV4", "LV1", "HV3", "LV3", "HV4", "LV2", "HV1", "LV3", "LV1", "HV3", "HV3", "LV2", "HV1", "LV4", "LV4", "FS1", "HV1", "HV4", "LV2", "LV2", "HV1", "LV2", "HV1", "LV5", "HV4", "LV1", "HV2", "LV4", "HV2", "HV2", "LV4", "LV4", "LV2", "LV4", "LV3", "LV2", "HV2", "LV5", "LV4", "LV2", "HV1", "LV4", "HV4", "LV2"],
      ["LV3", "LV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV1", "LV1", "LV3", "LV1", "LV3", "HV4", "HV4", "LV2", "LV2", "HV2", "LV2", "LV5", "LV1", "HV2", "HV2", "LV1", "LV4", "LV1", "LV1", "LV5", "LV5", "LV5", "HV3", "LV3", "LV3", "LV3", "HV2", "LV2", "LV5", "LV4", "LV4", "LV1", "LV1", "LV4", "LV1", "HV2", "HV1", "LV3", "HV2", "HV1", "LV1", "HV1", "HV1", "LV3", "LV3", "HV3", "LV5", "LV1", "LV3", "HV3", "LV5", "HV2", "LV3", "LV3", "HV2", "LV3", "HV2", "LV3", "LV1", "LV1", "LV5", "LV5", "HV2", "HV3", "HV2", "LV5", "LV4", "LV5", "LV2", "HV1", "LV5", "LV3", "HV2", "LV5", "LV3", "LV5", "LV5", "LV1"],
      ["LV2", "LV2", "HV4", "HV4", "LV2", "HV1", "LV4", "HV2", "LV1", "HV2", "LV1", "LV5", "LV2", "LV1", "LV2", "LV3", "LV1", "HV3", "LV3", "LV3", "HV1", "LV3", "HV1", "HV4", "HV4", "FS1", "LV2", "LV2", "LV2", "HV1", "LV2", "HV1", "HV1", "LV2", "LV4", "LV4", "HV2", "LV4", "LV4", "HV4", "LV4", "HV3", "HV3", "LV4", "HV1", "HV1", "LV1", "LV3", "HV2", "HV2", "FS1", "LV2", "LV4", "LV2", "LV2", "HV2", "LV4", "LV4", "LV4", "HV4", "HV1", "HV1", "HV1", "LV4", "LV2", "LV2", "LV4", "LV5", "HV3", "LV5", "LV1", "LV2", "LV5", "LV2", "LV5", "FS1", "LV4", "LV4"],
      ["LV3", "LV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV1", "LV1", "LV3", "LV1", "LV3", "HV4", "HV4", "LV2", "LV2", "HV2", "LV2", "LV5", "LV1", "HV2", "HV2", "LV1", "LV4", "LV1", "LV1", "LV5", "LV5", "LV5", "HV3", "LV3", "LV3", "LV3", "HV3", "LV4", "LV5", "LV4", "LV4", "LV1", "LV1", "LV4", "LV1", "HV3", "HV1", "LV3", "HV3", "HV1", "LV1", "HV1", "HV1", "LV3", "LV3", "HV3", "LV5", "LV1", "LV5", "HV3", "LV3", "HV3", "LV3", "LV3", "HV2", "LV3", "HV2", "LV3", "LV1", "LV1", "LV5", "LV5", "HV2", "HV2", "HV2", "LV5", "LV2", "LV5", "LV2", "HV1", "LV5", "LV3", "HV2", "LV5", "LV3", "LV5", "LV5", "LV1"],
      ["LV1", "LV2", "HV1", "LV4", "LV3", "LV3", "LV3", "LV5", "HV1", "LV1", "LV2", "FS1", "HV1", "LV2", "HV1", "HV1", "HV1", "FS1", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "HV3", "LV2", "LV3", "LV2", "LV2", "FS1", "LV2", "LV2", "HV2", "LV3", "LV3", "LV4", "LV4", "HV4", "HV4", "HV4", "LV3", "HV4", "FS1", "HV2", "LV1", "LV5", "LV5", "HV1", "LV1", "LV1", "LV1", "HV2", "HV2", "LV5", "LV2", "HV2", "LV5", "LV5", "LV5", "FS1", "LV3", "HV3", "LV1", "LV1", "HV3", "LV5", "HV1", "LV4", "LV4", "LV4", "LV5", "LV4", "FS1", "LV5", "LV3", "LV3", "HV2", "HV2", "HV2", "LV4", "LV4", "HV1", "LV1", "LV3", "LV4"]
    ],
    "featureReels":[
      ["LV4", "LV4", "LV2", "HV1", "HV1", "LV4", "HV3", "LV2", "LV5", "LV2", "HV2", "LV4", "LV4", "LV2", "FS1", "LV5", "LV2", "LV5", "HV1", "HV4", "LV2", "LV1", "LV1", "HV1", "LV3", "LV3", "HV2", "LV2", "HV4", "HV4", "LV2", "LV4", "LV1", "LV4", "LV1", "HV3", "LV3", "HV4", "LV2", "HV1", "LV3", "LV1", "HV3", "HV3", "LV2", "HV1", "LV4", "LV4", "FS1", "HV1", "HV4", "LV2", "LV2", "HV1", "LV2", "HV1", "LV5", "HV4", "LV1", "HV2", "LV4", "HV2", "HV2", "LV4", "LV4", "LV2", "LV4", "LV3", "LV2", "HV2", "LV5", "LV4", "LV2", "HV1", "LV4", "HV4", "LV2"],
      ["LV3", "LV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV1", "LV1", "LV3", "LV1", "LV3", "HV4", "HV4", "LV2", "LV2", "HV2", "LV2", "LV5", "LV1", "HV2", "HV2", "LV1", "LV4", "LV1", "LV1", "LV5", "LV5", "LV5", "HV3", "LV3", "LV3", "LV3", "HV2", "LV2", "LV5", "LV4", "LV4", "LV1", "LV1", "LV4", "LV1", "HV2", "HV1", "LV3", "HV2", "HV1", "LV1", "HV1", "HV1", "LV3", "LV3", "HV3", "LV5", "LV1", "LV3", "HV3", "LV5", "HV2", "LV3", "LV3", "HV2", "LV3", "HV2", "LV3", "LV1", "LV1", "LV5", "LV5", "HV2", "HV3", "HV2", "LV5", "LV4", "LV5", "LV2", "HV1", "LV5", "LV3", "HV2", "LV5", "LV3", "LV5", "LV5", "LV1"],
      ["LV2", "LV2", "HV4", "HV4", "LV2", "HV1", "LV4", "HV2", "LV1", "HV2", "LV1", "LV5", "LV2", "LV1", "LV2", "LV3", "LV1", "HV3", "LV3", "LV3", "HV1", "LV3", "HV1", "HV4", "HV4", "FS1", "LV2", "LV2", "LV2", "HV1", "LV2", "HV1", "HV1", "LV2", "LV4", "LV4", "HV2", "LV4", "LV4", "HV4", "LV4", "HV3", "HV3", "LV4", "HV1", "HV1", "LV1", "LV3", "HV2", "HV2", "FS1", "LV2", "LV4", "LV2", "LV2", "HV2", "LV4", "LV4", "LV4", "HV4", "HV1", "HV1", "HV1", "LV4", "LV2", "LV2", "LV4", "LV5", "HV3", "LV5", "LV1", "LV2", "LV5", "LV2", "LV5", "FS1", "LV4", "LV4"],
      ["LV3", "LV3", "HV3", "HV3", "LV1", "LV1", "LV1", "HV1", "LV1", "LV3", "LV1", "LV3", "HV4", "HV4", "LV2", "LV2", "HV2", "LV2", "LV5", "LV1", "HV2", "HV2", "LV1", "LV4", "LV1", "LV1", "LV5", "LV5", "LV5", "HV3", "LV3", "LV3", "LV3", "HV3", "LV4", "LV5", "LV4", "LV4", "LV1", "LV1", "LV4", "LV1", "HV3", "HV1", "LV3", "HV3", "HV1", "LV1", "HV1", "HV1", "LV3", "LV3", "HV3", "LV5", "LV1", "LV5", "HV3", "LV3", "HV3", "LV3", "LV3", "HV2", "LV3", "HV2", "LV3", "LV1", "LV1", "LV5", "LV5", "HV2", "HV2", "HV2", "LV5", "LV2", "LV5", "LV2", "HV1", "LV5", "LV3", "HV2", "LV5", "LV3", "LV5", "LV5", "LV1"],
      ["LV1", "LV2", "HV1", "LV4", "LV3", "LV3", "LV3", "LV5", "HV1", "LV1", "LV2", "FS1", "HV1", "LV2", "HV1", "HV1", "HV1", "HV3", "LV1", "LV1", "HV3", "HV3", "HV3", "LV2", "LV2", "HV3", "LV2", "LV3", "LV2", "LV2", "FS1", "LV2", "LV2", "HV2", "LV3", "LV3", "LV4", "LV4", "HV4", "HV4", "HV4", "LV3", "HV4", "FS1", "HV2", "LV1", "LV5", "LV5", "HV1", "LV1", "LV1", "LV1", "HV2", "HV2", "LV5", "LV2", "HV2", "LV5", "LV5", "LV5", "FS1", "LV3", "HV3", "LV1", "LV1", "HV3", "LV5", "HV1", "LV4", "LV4", "LV4", "LV5", "LV4", "HV1", "LV5", "LV3", "LV3", "HV2", "HV2", "HV2", "LV4", "LV4", "HV1", "LV1", "LV3", "LV4"]
    ],
    "reelsLayout":[4,4,4,4,4],
    "reelsLayoutFreeSpin":[5,5,5,5,5],
    "hanselWildBonus":{
        "hanselWildsCount":[
          {"numberOfWilds":3, "weight":75},
          {"numberOfWilds":4, "weight":20},
          {"numberOfWilds":5, "weight":3},
          {"numberOfWilds":6, "weight":2}
        ],
        "hanselWildReelSelected":[
          {"reelNumber":1, "weight":15},
          {"reelNumber":2, "weight":35},
          {"reelNumber":3, "weight":35},
          {"reelNumber":4, "weight":15}
        ],
        "hanselWildInReelPosition":[
          {"wildPosition":0, "weight":25},
          {"wildPosition":1, "weight":25},
          {"wildPosition":2, "weight":25},
          {"wildPosition":3, "weight":25}
        ]
    },
    "gretelWildBonus":{
      "greteReelSelected":[
        {"reelNumber":1,"weight":40},
        {"reelNumber":2,"weight":40},
        {"reelNumber":3,"weight":20}
      ]
    },
  
  
    "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values": [ 1, 2, 5, 10, 50],
  
    "coinType1_min_bet": "250",
    "coinType1_max_bet": "100000",
    "coinType1_spin_max_bet": "2500000",
  
    "coinType4_min_bet": "1",
    "coinType4_max_bet": "50",
    "coinType4_spin_max_bet": "1250",
    "betMultiplier" : 25
  }
}