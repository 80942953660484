/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {

	return {
		variations: [""],
		getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
		createDefaultStep: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels");
			step.rows = State.state.getModelDefinition().reels;

			return step;
		},
		getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
		getRowsNo: () => State.state.getModelDefinition().getLayout(),
		getNumberOfReels: () => {
			return State.state.getModelDefinition().reelsLayout.length;
		},
		getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
		getReelStrips: () => State.state.getModelDefinition().reels,
		getBaseStep: () => State.state.createDefaultStep(),
		getFeatureTriggerFromBaseGame: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Feature trigger");
			return step;
		},
		getClearingStep: (order = 0) => {
			const step = State.state.createStep([0, 0, 0, 0, 0], false, order, "", "Clear");
			step.gameState = "clear";
			return step;
		},
		prepareStepForDB: (step) => {
			return step;
		},
	};
}
