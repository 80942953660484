import { type Static, Type } from '@sinclair/typebox';
import type { InitArguments, InitOutcome } from '@vgw/gdk-math-model-server';
import type { Choices } from '../../choices/index';
import { modelDefinition } from '../../model-definition/index';
import type { GameState } from '../../shared/index';
import { type GameResponse, play } from '../play/index';

export function init({ coinType, gameState }: InitArguments<GameState>, choices: Choices): InitOutcome<InitResponse> {
    if (gameState) {
        return {
            initResponse: {
                coinAmount: gameState.coinAmount,
                reelStripPositions: gameState.reelStripPositions,
                slotWindow: gameState.slotWindow,
                cumulativeWinAmount: gameState?.cumulativeWinAmount,
                freeSpinPhase: gameState?.freeSpinPhase,
                freeSpinCount: gameState?.freeSpinCount,
                modelDefinition: {
                    validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                    reels: modelDefinition.reels,
                },
                // Banker offer restore:
                hasAcceptedBankerOffer: gameState?.hasAcceptedBankerOffer,
                bankerOfferAmount: gameState?.bankerOfferAmount,
            },
        };
    }

    let spinWithNoWin: GameResponse | undefined;
    while (spinWithNoWin === undefined) {
        const { gameResponse } = play(
            { coinType: 'SC', gameRequest: { coinAmount: modelDefinition.validCoinAmounts.SC[0] } },
            choices,
        );
        const { winType } = gameResponse;
        if (winType === 'NO_WIN') {
            spinWithNoWin = gameResponse;
            break;
        }
    }

    const { reelStripPositions, slotWindow } = spinWithNoWin;

    return {
        initResponse: {
            reelStripPositions,
            slotWindow,
            modelDefinition: {
                validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                reels: modelDefinition.reels,
            },
        },
    };
}

export const InitResponseSchema = Type.Object({
    coinAmount: Type.Optional(Type.Integer()),
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),
    cumulativeWinAmount: Type.Optional(Type.Integer()),
    freeSpinPhase: Type.Optional(
        Type.Union([
            Type.Literal('START'),
            Type.Literal('IN_PROGRESS'),
            Type.Literal('RETRIGGER'),
            Type.Literal('END'),
        ]),
    ),
    freeSpinCount: Type.Optional(Type.Integer()),
    modelDefinition: Type.Object({
        validCoinAmounts: Type.Array(Type.Number()),
        reels: Type.Array(Type.Array(Type.String())),
    }),
    bankerOfferAmount: Type.Optional(Type.Integer()),
    hasAcceptedBankerOffer: Type.Optional(Type.Boolean()),
});
export type InitResponse = Static<typeof InitResponseSchema>;
