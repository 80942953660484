import { mathModel } from "../../../../v3";
import { modelDefinition } from "../../config";

type JackpotType = mathModel.JackpotType;

type JackpotWins = {
  jackpotWinCounts?: {
    GRAND: number;
    MAJOR: number;
    MINOR: number;
    MINI: number;
  };
  jackpotWinAmounts?: {
    GRAND: number;
    MAJOR: number;
    MINOR: number;
    MINI: number;
  };
  jackpotWinAmount?: number;
};

const { fixedMultipliers } = modelDefinition.jackpots;

export function computeJackpots(
  coinSize: number,
  jackpotType: JackpotType | undefined
): JackpotWins {
  if (jackpotType === undefined) {
    return { jackpotWinAmounts: undefined, jackpotWinCounts: undefined };
  }
  const jackpotWinCounts = {
    GRAND: 0,
    MAJOR: 0,
    MINOR: 0,
    MINI: 0,
  };
  const jackpotWinAmounts = {
    GRAND: 0,
    MAJOR: 0,
    MINOR: 0,
    MINI: 0,
  };

  let jackpotWinAmount = 0;

  switch (jackpotType) {
    case "GRAND":
      jackpotWinCounts.GRAND++;
      break;
    case "MAJOR":
      jackpotWinCounts.MAJOR++;
      break;
    case "MINOR":
      jackpotWinCounts.MINOR++;
      jackpotWinAmounts.MINOR = fixedMultipliers.MINOR * coinSize;
      jackpotWinAmount = fixedMultipliers.MINOR * coinSize;

      break;
    case "MINI":
      jackpotWinCounts.MINI++;
      jackpotWinAmounts.MINI = fixedMultipliers.MINI * coinSize;
      jackpotWinAmount = fixedMultipliers.MINI * coinSize;
      break;
    default:
      throw new mathModel.MathModelError(
        "computeJackpots have failed as jackpotType cannot be used"
      );
  }
  return { jackpotWinCounts, jackpotWinAmounts, jackpotWinAmount };
}
