import Vue from "vue"; 
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../../../../../helpers/casino";
import {trace, TODO, rowsFromIndexes, wrapIndex, range, getAssetsPath} from "./../../../../../helpers/helpers";
import State from "../../../../../state/state";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";
  
@Component({
    data: () => {
        return {
            reelIndex: 0,
        };
    },
})
export default class Reel extends Vue {
    @Prop({ type: Number, default: 0}) public index;
    @Prop({ type: Number, default: 0}) public substep;
    @Prop() public step: any;
    @Prop({ type: Boolean, default: false}) public isFeature;
    @Prop({ type: Boolean, default: false}) public isJackpot;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;  
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;  
    public toggleOn: string = `${getAssetsPath()}/images/toggle_on.png`;  
    public toggleOff: string = `${getAssetsPath()}/images/toggle_off.png`;   
    private reelIndex = 0; 
    private text = "0";
    private p0top = "";
    private p1top = "";
    private p0bottom = "";
    private p1bottom = "";
    private windowJackpotCoins: any = [];
    private nRows = 3; 
    private prices: any = [];
    private selectedPrices: any = [4, 4, 4];
    private state = State.state;
    private intervalTime;
    private window: any = [];
    constructor() {
        super();
        const state = State.state;
        const md = state.getModelDefinition();
        const reelstrips = md["reels"];
        this.nRows = md.reelsLayout[this.index];
    }
    public mounted() {
        if (this.substep === 0) {
            this.$refs.reel && this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
        }
        bus.$on("update-selected-prices", () => {
            if (!this.step.json.symbolInfos[this.substep]) return;
            this.selectedPrices = this.step.json.symbolInfos[this.substep][this.index].map(it => { return it ? State.state.getPriceText(it.price) : 0 });

            this.reelIndex = this.step.json.reelStripPositions[this.index];
            this.window = State.state.getReelWindowPerReel(this.step)[this.index];
        });
        
        bus.$on("step-updated", (step) => this.stepChanged(step) );
    }
    public beforeDestroy() { 
        this.$refs.reel && this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
        bus.$off("update-selected-prices");
    }
    @Watch("step")
    public stepChanged(step) { 
        if (!step || !step.json) { return; }
        this.reelIndex = step.json.reelStripPositions[this.index];
        this.text = String(this.reelIndex);
        
        this.$nextTick(() => {
            bus.$emit("update-selected-prices");
            this.updateStep();
        });
    }
    @Watch("isFeature") 
    public isFeatureChanged(val) { 
        this.updateStep();
    }
    @Watch("text")
    public textChanged(val, oldval) {
        const n = Number(val); 
        if (!Number.isInteger(n)) {
            this.reelIndex = 0;
            this.text = String(this.reelIndex);
        } 
        const rs = this.state.getReels(this.step.json)[this.index];
        if (n >= rs.length) {
            this.text = String(wrapIndex(Number(this.text), rs));
        } else if(n < 0) {
            this.reelIndex = 0;
            this.text = String(this.reelIndex);
        }

        this.updateStep();
        this.updateDB(); 
    }
    public up(direction = 1) {
        const rs = this.state.getReels(this.step.json)[this.index];
        this.reelIndex = wrapIndex(this.reelIndex + (1 * direction), rs);
        this.step.json.reelStripPositions[this.index] = this.reelIndex; 
        State.state.resetAllSymbolInfosOnReel(this.step, this.index);
        bus.$emit("update-selected-prices");
        bus.$emit("step-updated", this.step);
        
        this.text = String(this.reelIndex); 
    }
    public down() {
        this.up(-1);
    }
    public getPriceText(it) { return State.state.getPriceText(it); }
    public updateStep() {
        if (!this.step) { return; }

        const state = State.state; 
        const rs = state.getReels(this.step.json)[this.index];
        this.reelIndex = Number(this.text);
        this.p0top = rs[wrapIndex(this.reelIndex - 1, rs)];
        this.p0bottom = rs[wrapIndex(this.reelIndex + 3, rs)]; 
        this.window = State.state.getReelWindowPerReel(this.step)[this.index];
    
        this.prices = state.getPrices();
    } 
    public isCoin(index) {
        return this.window[index].toLowerCase() === "coin";
    }
    public isCoinEnabled(index) {
        if (!this.step) return;
        const c = this.step.json.symbolInfos[this.substep][this.index][index];
        return c && c.enabled
    }
    public isCoinLocked(index) {
        if (!this.step) return;
        const c = this.step.json.symbolInfos[this.substep][this.index][index]; 
        return c && c.locked
    }
    public updateDB() { 
        if (!this.step) {  return; }
        this.$nextTick(() => {
            this.step.json.reelStripPositions[this.index] = this.reelIndex;
            this.$forceUpdate(); 
            bus.$emit(events.UPDATE_STEPS_DB, this.step); 
        });
    }
    public scrollFunction(e) { 
        if (!this.step || this.step.json.symbolInfos.length > 1) {
            return;
        }
        if (e.deltaY < 0) {
            this.down();
        } else if (e.deltaY > 0) {
            this.up();
        }
        e.preventDefault();
    }
    public isToggleOn(i) {
        if (!this.step) return;
        const v = this.step.json.symbolInfos[this.substep][this.index][i];
        return v != null;
    }
    public itHasInfo(i) {
        if (!this.step) return;
        return this.step.json.symbolInfos[this.substep][this.index][i] != null;
    }
    public toggleACoinOnJackpotFeature(i) {
        const v = this.step.json.symbolInfos[this.substep][this.index][i] != null ? null : State.state.getEnabledStep();
        this.step.json.symbolInfos[this.substep][this.index][i] = v;
 
        State.state.propagateInfoOnSymbolInfos(this.step, this.substep, this.index, i , v); 
        bus.$emit("update-selected-prices");  
        this.updateDB();
    }
    public onChangeCoinSelect(i) {
        const price = State.state.getPrice(this.selectedPrices[i]);
        State.state.propagateValueOnSymbolInfos(this.step, this.substep, this.index, i, "price", price);
        bus.$emit("update-selected-prices");
        this.updateDB();
    }
}
