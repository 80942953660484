import type { Choices } from '../../choices/choices';
import { modelDefinition } from '../../model-definition';
import type { CharacterPic, PickFeature } from '../shared';
import type { GameState } from '../shared';
import type { GameRequest } from './game-request';
import type { GameResponse } from './game-response';
import type { SpinOutcome } from './outcome';

export function pickSpin({ gameRequest, gameState: lastGameState }: PickSpinsInputs, choices: Choices): SpinOutcome {
    if (lastGameState.picks === undefined) throw new Error('Game state does not exist when attempting a pick');
    if (gameRequest.pickId === undefined) throw new Error('Pick id is required when attempting a pick');

    const { pickId } = gameRequest;

    let { userPicks } = lastGameState.picks;
    const pickFeaturesAwarded = lastGameState.picks.pickFeaturesAwarded ?? choices.choosePickAwards();
    userPicks = userPicks ?? [];

    let currentPickFeature: PickFeature | undefined;
    if (userPicks.length < 3) {
        currentPickFeature = {
            type: 'FREE_SPINS',
            freeSpinsAwarded: choices.chooseFreeSpins(),
            pickId,
        };
    } else {
        const feature = pickFeaturesAwarded?.[userPicks.length - 3];

        if (feature === 'FREE_SPINS') {
            currentPickFeature = {
                type: 'FREE_SPINS',
                freeSpinsAwarded: choices.chooseFreeSpins(),
                pickId,
            };
        } else if (feature === 'PIC1' || feature === 'PIC2' || feature === 'PIC3') {
            currentPickFeature = {
                type: 'PIC_TO_WILD',
                picToWildAwarded: feature,
                pickId,
            };
        } else {
            currentPickFeature = {
                type: 'START',
                pickId,
            };
        }
    }

    userPicks.push(currentPickFeature);

    const roundComplete = false;

    const freeSpinsCollected = userPicks
        .filter((pick) => pick.type === 'FREE_SPINS')
        .reduce((total, pick) => {
            return (total += pick.type === 'FREE_SPINS'? pick.freeSpinsAwarded : 0);
        }, 0);

    const picToWildsCollected = userPicks
        .filter((pick) => pick.type === 'PIC_TO_WILD')
        .reduce((collectedPicks: CharacterPic[], pick) => {
            if (pick.type === 'PIC_TO_WILD' && !collectedPicks.includes(pick.picToWildAwarded)) {
                collectedPicks.push(pick.picToWildAwarded);
            }
            return collectedPicks;
        }, []);

    const pickPhase = userPicks.length === 12 || currentPickFeature.type === 'START' ? 'END' : 'IN_PROGRESS';

    const pickSpinResults: GameResponse['picks'] = {
        phase: pickPhase,
        userPicks,
        currentPick: currentPickFeature,
    };

    const isPickFeatureCompleted = pickPhase === 'END';

    const freeSpins: GameState['freeSpins'] = {
        remaining: freeSpinsCollected,
        phase: 'START',
    };

    if (picToWildsCollected.length > 0) {
        freeSpins.picToWilds = picToWildsCollected;
    }

    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

    return {
        playSummary: {
            playAmount: 0,
            winAmount: 0,
            roundComplete,
        },
        gameResponse: {
            coinAmount: gameRequest.coinAmount,
            playAmount,
            winAmount: 0,
            cumulativeWinAmount: lastGameState.cumulativeWinAmount,

            reelStripPositions: lastGameState.reelStripPositions,
            slotWindow: lastGameState.slotWindow,
            lineWins: [],
            scatterWin: undefined,
            winType: 'FREE_SPIN_NO_WIN',
            picks: pickSpinResults,
            freeSpins: isPickFeatureCompleted ? freeSpins : undefined,
        },
        gameState: {
            ...lastGameState,
            picks: isPickFeatureCompleted ? undefined : { ...pickSpinResults, pickFeaturesAwarded },
            freeSpins: isPickFeatureCompleted ? freeSpins : undefined,
        },
    };
}

export type PickSpinsInputs = {
    gameRequest: GameRequest;
    gameState: GameState;
};
