export const modelDefinition = {
  modelId: "vgw051",
  gameIds: ["goblinGrand"],
  coinType1Values: [200, 1000, 2000, 5000, 10000, 50000],
  coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000],
  coinSizeMultiplier: 20,
  reelsLayout: [4, 4, 4, 4, 4, 4],
  topReelLayout: [4],
  wildSymbol: "WILD",
  scatterSymbol: "SCAT",
  coinSymbol: "COIN",
  countToTriggerFreeSpins: 3,
  // The initial free spin amount is the value of free spins awarded when >= countToTriggerFreeSpins scatters are in the slot window
  initialFreeSpinsAmount: 10,
  // The amount of free spins for each scatter after reaching the countToTriggerFreeSpins amount
  additionalFreeSpinsAmount: 5,

  winTable: [
    {
      symbol: "PIC1",
      multipliers: [1000, 500, 200, 100, 40],
    },
    {
      symbol: "PIC2",
      multipliers: [200, 100, 40, 20],
    },
    {
      symbol: "PIC3",
      multipliers: [50, 25, 15, 5],
    },
    {
      symbol: "PIC4",
      multipliers: [40, 20, 10, 5],
    },
    {
      symbol: "PIC5",
      multipliers: [30, 15, 8, 4],
    },
    {
      symbol: "PIC6",
      multipliers: [20, 10, 6, 3],
    },
    {
      symbol: "S",
      multipliers: [10, 6, 4, 2],
    },
    {
      symbol: "H",
      multipliers: [10, 6, 4, 2],
    },
    {
      symbol: "D",
      multipliers: [10, 6, 4, 2],
    },
    {
      symbol: "C",
      multipliers: [10, 6, 4, 2],
    },
    {
      symbol: "SCAT",
      multipliers: [120, 100, 80, 60],
    },
  ],

  // This is a cascading ways game, so there are zero lines.
  playLines: [],

  // prettier-ignore
  reels: [
        ['D', 'C', 'PIC6', 'PIC6', 'D', 'SCAT', 'H', 'H', 'PIC5', 'PIC5', 'S', 'S', 'PIC6', 'PIC6', 'C', 'C', 'COIN', 'COIN', 'S', 'S', 'PIC6', 'PIC2', 'PIC2', 'C', 'PIC4', 'PIC4', 'PIC6', 'C', 'C', 'PIC1', 'H', 'H', 'H', 'PIC1', 'PIC6', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'H', 'H', 'H', 'PIC2', 'PIC2', 'D', 'D', 'PIC5', 'PIC1', 'PIC6', 'PIC6', 'PIC6', 'D', 'D', 'D', 'PIC2', 'PIC6', 'PIC6', 'PIC6', 'S', 'PIC3', 'PIC3', 'C', 'C', 'COIN', 'COIN', 'H', 'PIC4', 'PIC4', 'PIC6', 'PIC6', 'S', 'C', 'C', 'PIC6', 'PIC6', 'PIC6', 'PIC3', 'PIC3', 'H', 'PIC2', 'PIC2', 'PIC2', 'H', 'D', 'D', 'D', 'PIC3', 'PIC3', 'C', 'C', 'C', 'H', 'D', 'D', 'PIC5', 'PIC1', 'H', 'PIC3', 'PIC3', 'PIC3', 'H', 'H', 'S', 'S', 'PIC3', 'PIC3', 'D', 'D', 'PIC6', 'PIC6', 'PIC6', 'C', 'C', 'PIC3', 'PIC3', 'PIC3', 'H', 'H', 'H', 'PIC3', 'PIC3', 'C', 'C', 'PIC6', 'PIC6', 'PIC6', 'C', 'C', 'H', 'H', 'H', 'D', 'D', 'PIC3', 'PIC3', 'C', 'C', 'C', 'PIC6', 'PIC6', 'PIC2', 'H'],
        ['D', 'H', 'H', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'C', 'C', 'S', 'S', 'PIC4', 'C', 'COIN', 'COIN', 'D', 'D', 'PIC4', 'S', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'PIC6', 'H', 'H', 'H', 'PIC3', 'PIC3', 'C', 'C', 'PIC6', 'PIC6', 'PIC2', 'D', 'D', 'PIC4', 'PIC2', 'PIC2', 'C', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'D', 'D', 'D', 'COIN', 'PIC6', 'PIC6', 'S', 'S', 'S', 'S', 'PIC5', 'PIC5', 'PIC5', 'S', 'S', 'S', 'S', 'PIC4', 'PIC4', 'C', 'C', 'D', 'D', 'S', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'S', 'S', 'S', 'S', 'PIC1', 'D', 'D', 'PIC6', 'C', 'C', 'D', 'D', 'D', 'COIN', 'COIN', 'C', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'D', 'D', 'H', 'H', 'SCAT', 'D', 'C', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'C', 'C', 'S', 'S', 'S', 'PIC1', 'PIC6', 'PIC6', 'D', 'PIC2', 'PIC5', 'PIC5', 'PIC5', 'C', 'C', 'PIC1', 'PIC5', 'PIC5', 'PIC5', 'C', 'PIC3', 'PIC3', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'S', 'S', 'S', 'PIC6', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'C', 'H', 'PIC6', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'D', 'D', 'D', 'PIC5', 'PIC5', 'PIC5', 'PIC2', 'S', 'S', 'S', 'S', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'S', 'S', 'COIN', 'D', 'C', 'H', 'PIC4', 'PIC4', 'S', 'SCAT', 'C', 'C', 'H', 'H', 'H', 'PIC2', 'PIC2', 'C', 'S', 'S', 'S', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'S', 'S', 'S', 'PIC1', 'S', 'PIC4', 'PIC4', 'D', 'D', 'D', 'COIN', 'PIC6', 'PIC6', 'D', 'D', 'PIC4', 'PIC4', 'WILD', 'S', 'S', 'PIC2', 'PIC5', 'PIC5', 'PIC5', 'PIC5', 'C', 'C', 'SCAT', 'PIC4', 'S', 'S', 'PIC3', 'PIC3'],
        ['C', 'C', 'H', 'H', 'PIC3', 'PIC6', 'PIC6', 'PIC6', 'PIC1', 'D', 'H', 'PIC3', 'PIC6', 'PIC6', 'PIC1', 'S', 'S', 'S', 'PIC1', 'D', 'D', 'C', 'SCAT', 'S', 'PIC2', 'C', 'C', 'C', 'PIC4', 'PIC1', 'D', 'D', 'COIN', 'COIN', 'H', 'H', 'PIC4', 'PIC6', 'PIC6', 'D', 'PIC3', 'C', 'C', 'PIC2', 'D', 'D', 'D', 'COIN', 'S', 'S', 'PIC1', 'C', 'C', 'PIC5', 'PIC5', 'PIC5', 'PIC3', 'H', 'H', 'SCAT', 'PIC4', 'H', 'H', 'H', 'PIC4', 'C', 'PIC3', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'PIC5', 'D', 'D', 'S', 'S', 'PIC3', 'D', 'D', 'D', 'C', 'C', 'WILD', 'H', 'PIC4', 'PIC3', 'D', 'D', 'D', 'PIC3', 'PIC3', 'PIC5', 'PIC5', 'PIC5', 'COIN', 'COIN', 'COIN', 'PIC4', 'C', 'D', 'PIC3', 'PIC4', 'SCAT', 'D', 'D', 'PIC1', 'PIC6', 'PIC6', 'PIC3', 'C', 'PIC4', 'H', 'PIC3', 'S', 'PIC4', 'PIC4', 'D', 'D', 'D', 'PIC3', 'PIC6', 'PIC6', 'PIC6', 'S', 'S', 'PIC4', 'PIC4', 'PIC3', 'H', 'H', 'H', 'PIC4', 'S', 'COIN', 'COIN', 'PIC5', 'PIC5', 'PIC5', 'PIC2', 'PIC2', 'PIC4', 'PIC3', 'H', 'H', 'PIC6', 'PIC6', 'PIC6', 'C', 'PIC4', 'S', 'S', 'PIC2', 'PIC6', 'PIC6', 'PIC3', 'C', 'S', 'PIC5', 'PIC5', 'PIC2', 'C', 'C', 'C', 'PIC6', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'S', 'S', 'COIN', 'COIN', 'COIN', 'PIC5', 'PIC5', 'S', 'S', 'PIC3', 'H', 'PIC2', 'PIC2', 'S', 'S', 'S', 'PIC3', 'PIC6', 'PIC6', 'H', 'H', 'H', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'D', 'PIC4', 'H', 'H', 'H', 'COIN'],
        ['PIC6', 'S', 'S', 'H', 'PIC1', 'PIC1', 'PIC5', 'PIC5', 'PIC4', 'D', 'D', 'D', 'PIC1', 'H', 'H', 'PIC6', 'PIC6', 'PIC6', 'COIN', 'COIN', 'S', 'PIC5', 'PIC5', 'PIC1', 'PIC1', 'D', 'PIC6', 'PIC5', 'PIC5', 'PIC5', 'PIC4', 'H', 'H', 'S', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'D', 'SCAT', 'C', 'C', 'PIC1', 'S', 'S', 'S', 'PIC6', 'C', 'C', 'PIC1', 'PIC6', 'PIC6', 'D', 'PIC3', 'H', 'H', 'H', 'PIC4', 'PIC4', 'PIC2', 'C', 'C', 'C', 'H', 'H', 'PIC6', 'PIC6', 'WILD', 'D', 'D', 'D', 'COIN', 'COIN', 'COIN', 'PIC4', 'PIC4', 'SCAT', 'D', 'D', 'S', 'PIC6', 'PIC6', 'PIC6', 'C', 'PIC4', 'PIC4', 'S', 'S', 'PIC5', 'PIC5', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC1', 'PIC1', 'PIC5', 'PIC5', 'C', 'S', 'S', 'PIC2', 'PIC2', 'D', 'D', 'S', 'COIN', 'COIN', 'PIC6', 'H', 'H', 'H', 'PIC3', 'PIC3', 'S', 'PIC5', 'PIC5', 'PIC5', 'H', 'C', 'PIC4', 'PIC4', 'PIC4', 'D', 'D', 'D', 'SCAT', 'C', 'C', 'PIC4', 'PIC1', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'S', 'S', 'S', 'H', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'C', 'D', 'D', 'PIC6', 'S', 'S', 'S', 'PIC6', 'COIN', 'COIN', 'PIC5', 'PIC5', 'PIC2', 'C', 'C', 'PIC1', 'PIC6', 'PIC6', 'PIC6', 'C', 'C', 'PIC2', 'PIC2', 'H', 'H', 'S', 'S', 'PIC6', 'PIC4', 'PIC1', 'C', 'C', 'C', 'D', 'D', 'PIC6', 'COIN', 'COIN', 'COIN', 'D', 'PIC4', 'PIC4', 'PIC4', 'H', 'H', 'H', 'PIC5', 'PIC5', 'PIC6', 'PIC3', 'H', 'H', 'PIC4', 'S', 'S', 'S', 'H', 'PIC3', 'D', 'PIC2', 'PIC4'],
        ['C', 'C', 'PIC5', 'PIC5', 'PIC5', 'PIC1', 'H', 'PIC6', 'PIC6', 'WILD', 'D', 'D', 'D', 'PIC4', 'PIC4', 'S', 'S', 'S', 'COIN', 'C', 'C', 'C', 'D', 'D', 'PIC2', 'PIC2', 'S', 'S', 'S', 'C', 'C', 'PIC5', 'H', 'H', 'PIC1', 'PIC5', 'S', 'S', 'PIC4', 'PIC4', 'D', 'D', 'D', 'S', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'H', 'C', 'PIC3', 'PIC3', 'PIC3', 'S', 'S', 'PIC4', 'PIC4', 'PIC6', 'PIC6', 'D', 'D', 'D', 'H', 'H', 'H', 'PIC2', 'D', 'S', 'SCAT', 'C', 'PIC5', 'PIC5', 'PIC5', 'PIC2', 'D', 'D', 'COIN', 'COIN', 'C', 'C', 'C', 'PIC5', 'PIC5', 'PIC1', 'PIC1', 'H', 'H', 'H', 'PIC4', 'PIC4', 'PIC4', 'PIC3', 'D', 'D', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC4', 'H', 'PIC3', 'PIC3', 'S', 'PIC1', 'C', 'D', 'PIC6', 'PIC6', 'PIC6', 'H', 'H', 'C', 'COIN', 'D', 'D', 'S', 'S', 'S', 'PIC2', 'PIC2', 'PIC6', 'S', 'S', 'S', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'S', 'S', 'S', 'PIC6', 'PIC6', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC6', 'PIC6', 'PIC6', 'PIC2', 'S', 'PIC4', 'PIC4', 'PIC4', 'COIN', 'COIN', 'PIC5', 'PIC5', 'PIC3', 'H', 'H', 'H', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'C', 'C', 'C', 'PIC3', 'PIC3', 'PIC3', 'PIC4', 'PIC4', 'PIC2', 'PIC2', 'PIC6', 'PIC1', 'S', 'S', 'H', 'H', 'H', 'D', 'PIC5', 'PIC5', 'H', 'H', 'PIC2', 'PIC2', 'C', 'C', 'PIC6', 'PIC6', 'S', 'S', 'H', 'COIN', 'COIN', 'D', 'D', 'D', 'H', 'H', 'PIC2', 'C', 'C', 'SCAT', 'D', 'PIC1', 'PIC1', 'PIC4'],
        ['S', 'S', 'H', 'H', 'PIC3', 'PIC3', 'PIC4', 'PIC4', 'PIC6', 'PIC6', 'PIC6', 'H', 'H', 'PIC5', 'PIC5', 'PIC5', 'C', 'D', 'D', 'D', 'C', 'C', 'COIN', 'S', 'H', 'H', 'PIC1', 'PIC1', 'PIC1', 'S', 'S', 'PIC5', 'PIC5', 'PIC5', 'S', 'S', 'S', 'SCAT', 'C', 'C', 'D', 'D', 'H', 'H', 'H', 'PIC3', 'PIC3', 'PIC3', 'S', 'S', 'S', 'PIC4', 'PIC4', 'H', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'H', 'H', 'PIC2', 'PIC2', 'S', 'S', 'PIC6', 'PIC6', 'D', 'SCAT', 'C', 'C', 'PIC2', 'PIC2', 'PIC2', 'D', 'D', 'C', 'C', 'COIN', 'S', 'PIC1', 'PIC1', 'C', 'C', 'C', 'PIC2', 'PIC2', 'PIC2', 'PIC6', 'PIC6', 'H', 'S', 'S', 'PIC3', 'PIC3', 'PIC3', 'H', 'H', 'H', 'PIC1', 'C', 'PIC4', 'PIC4', 'PIC4', 'D', 'PIC6', 'PIC6', 'PIC6', 'D', 'D', 'PIC1', 'PIC5', 'PIC5', 'D', 'D', 'D', 'PIC1', 'PIC1', 'C', 'C', 'C', 'PIC3', 'PIC3', 'D', 'D', 'COIN', 'COIN', 'H', 'S', 'PIC4', 'PIC4', 'PIC4'],
    ],

  // prettier-ignore
  topReel: ['PIC5', 'PIC6', 'PIC4', 'S', 'H', 'D', 'WILD', 'C', 'H', 'PIC5', 'D', 'PIC1', 'H', 'S', 'PIC3', 'PIC4', 'H', 'PIC5', 'SCAT', 'S', 'C', 'PIC4', 'D', 'H', 'S', 'PIC4', 'PIC4', 'C', 'H', 'PIC2', 'S', 'D', 'PIC3', 'C', 'PIC6', 'H', 'H', 'D', 'PIC6', 'PIC6', 'PIC3', 'D', 'C', 'S', 'PIC6', 'PIC1', 'C', 'S', 'PIC3', 'PIC5', 'C', 'D', 'D', 'S', 'PIC4', 'C', 'H', 'D', 'S', 'C', 'PIC4', 'PIC3', 'PIC3', 'C', 'S', 'S', 'H', 'C', 'PIC4', 'D', 'PIC5', 'PIC5', 'H', 'S', 'PIC6', 'D', 'C', 'S', 'PIC3', 'PIC5', 'PIC6', 'C', 'D', 'PIC4', 'PIC3', 'S', 'H', 'COIN', 'D', 'S', 'C', 'C', 'PIC5', 'WILD', 'D', 'PIC6', 'S', 'H', 'PIC3', 'D', 'PIC2', 'PIC4', 'PIC6', 'H', 'PIC5', 'D', 'C', 'PIC3', 'PIC6', 'PIC2', 'D', 'C', 'S', 'H', 'PIC6', 'D', 'PIC5', 'H', 'C', 'S', 'PIC2', 'H', 'PIC3', 'C', 'PIC4', 'H', 'PIC2', 'C', 'PIC5', 'PIC3', 'S', 'PIC6', 'COIN', 'PIC5', 'D', 'S', 'H', 'PIC1', 'D', 'PIC4', 'H', 'S', 'D', 'C', 'H'],

  multiplierValueWeights: {
    baseGame: [
      { outcome: 2, weight: 30 },
      { outcome: 3, weight: 40 },
      { outcome: 4, weight: 26 },
      { outcome: 5, weight: 14 },
      { outcome: 7, weight: 8 },
      { outcome: 10, weight: 5 },
    ],
    freeSpins: [
      { outcome: 2, weight: 30 },
      { outcome: 3, weight: 40 },
      { outcome: 4, weight: 26 },
      { outcome: 5, weight: 14 },
      { outcome: 7, weight: 8 },
      { outcome: 10, weight: 5 },
    ],
  },

  coinPrizeWeights: {
    duringSpinOrCascade: {
      20: 306_600,
      30: 131_400,
      40: 61_320,
      50: 43_800,
      75: 26_280,
      100: 17_520,
      150: 8_760,
      200: 6_570,
      300: 5_694,
      MINI: 4_380,
      MINOR: 876,
      MAJOR: "COIN_WEIGHT" as const,
      MULTIPLIER: 0,
    },
    duringHoldAndSpin: {
      20: 306_600,
      30: 131_400,
      40: 61_320,
      50: 43_800,
      75: 26_280,
      100: 17_520,
      150: 8_760,
      200: 6_570,
      300: 5_694,
      MINI: 4_380,
      MINOR: 876,
      MAJOR: "COIN_WEIGHT" as const,
      MULTIPLIER: 39_420,
    },
  },
  countToTriggerGrandJackpot: 28,
  countToTriggerHoldAndSpin: 6,
  holdAndSpinRespinCount: 3,
  multiplierCoinValues: [2, 3, 4, 5, 7, 10],

  coinType1_min_bet: 200,
  coinType1_max_bet: 50_000,
  coinType1_spin_max_bet: 1_000_000,
  coinType4_min_bet: 1,
  coinType4_max_bet: 1000,
  coinType4_spin_max_bet: 20_000,
};
