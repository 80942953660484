export const modelDefinition = {
  modelId: "vgw097",
  gameIds: ["drumFestival"],
  reelsLayout: [3, 3, 3, 3, 3],

  // prettier-ignore
  reels: [
      ['Q', 'PIC1', 'T', 'Q', 'T', 'A', 'A', 'PIC2', 'Q', 'A', 'SCAT', 'PIC4', 'A', 'PIC3', 'PIC3', 'J', 'Q', 'WILD', 'K', 'PIC4', 'K', 'K', 'PIC3', 'Q', 'J', 'PIC1', 'Q', 'WILD', 'A', 'A', 'Q', 'J', 'PIC3', 'SCAT', 'A', 'PIC4', 'K', 'Q', 'K', 'PIC2', 'T', 'K', 'PIC1', 'J', 'PIC2', 'A', 'A', 'PIC3', 'K', 'T', 'PIC4', 'PIC4', 'T', 'K', 'PIC3', 'J', 'A', 'PIC4', 'J', 'A', 'PIC4', 'T', 'K', 'Q', 'PIC2', 'A', 'PIC3', 'Q'],
      ['PIC2', 'J', 'BONUS', 'PIC1', 'K', 'K', 'WILD', 'J', 'J', 'SCAT', 'SCAT', 'Q', 'PIC4', 'K', 'Q', 'PIC2', 'PIC2', 'Q', 'PIC3', 'BONUS', 'T', 'PIC4', 'Q', 'PIC1', 'PIC4', 'J', 'A', 'A', 'K', 'PIC3', 'PIC3', 'A', 'K', 'PIC2', 'T', 'BONUS', 'K', 'PIC1', 'K', 'WILD', 'T', 'T', 'SCAT', 'SCAT', 'PIC1', 'K', 'PIC4', 'T', 'PIC2', 'PIC4', 'T', 'PIC3', 'BONUS', 'J', 'PIC3', 'Q', 'Q', 'PIC2', 'J', 'A', 'A', 'J', 'Q', 'J', 'Q'],
      ['BONUS', 'K', 'PIC1', 'T', 'BONUS', 'K', 'K', 'Q', 'SCAT', 'SCAT', 'SCAT', 'Q', 'PIC1', 'PIC3', 'PIC4', 'SCAT', 'PIC4', 'A', 'J', 'PIC4', 'K', 'A', 'PIC2', 'PIC1', 'T', 'WILD', 'PIC4', 'PIC4', 'J', 'PIC3', 'K', 'A', 'BONUS', 'A', 'PIC2', 'PIC3', 'PIC3', 'SCAT', 'T', 'T', 'BONUS', 'K', 'PIC3', 'Q', 'SCAT', 'SCAT', 'SCAT', 'Q', 'PIC1', 'PIC4', 'T', 'Q', 'SCAT', 'A', 'A', 'PIC2', 'PIC1', 'Q', 'WILD', 'PIC4', 'J', 'J', 'PIC3', 'K', 'A', 'BONUS', 'A', 'PIC2'],
      ['PIC1', 'K', 'SCAT', 'PIC4', 'K', 'WILD', 'PIC2', 'K', 'J', 'PIC3', 'BONUS', 'A', 'PIC4', 'PIC4', 'J', 'Q', 'WILD', 'A', 'PIC3', 'SCAT', 'PIC2', 'A', 'BONUS', 'T', 'Q', 'WILD', 'K', 'A', 'PIC4', 'BONUS', 'PIC3', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC1', 'PIC1', 'Q', 'PIC3', 'Q', 'T', 'SCAT', 'SCAT', 'Q', 'PIC4', 'PIC4', 'T', 'Q', 'K', 'SCAT', 'PIC3', 'A', 'PIC2', 'A', 'BONUS', 'T', 'Q', 'WILD', 'K', 'Q', 'A', 'J'],
      ['PIC3', 'T', 'PIC2', 'K', 'SCAT', 'PIC1', 'Q', 'PIC4', 'Q', 'PIC1', 'PIC3', 'T', 'PIC2', 'T', 'A', 'PIC3', 'SCAT', 'PIC1', 'A', 'PIC2', 'PIC3', 'T', 'PIC4', 'WILD', 'PIC2', 'PIC3', 'A', 'A', 'PIC2', 'Q', 'K', 'PIC1', 'PIC4', 'J', 'A', 'WILD', 'K', 'K', 'PIC1', 'Q', 'PIC4', 'Q', 'K', 'SCAT', 'J', 'A', 'WILD', 'K', 'PIC3', 'PIC4', 'A', 'PIC2', 'PIC4']
  ],
};
