import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import selectCherryMultiplier from "./components/selectCherryMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        selectCherryMultiplier: selectCherryMultiplier
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public isOpen = false;
    public reelSetBaseNumber = 0;
    public reelSetBaseNumberFS = 0;
    public reelSetFeatureNumber = 0;
    public cherriesNumber = 0;
    public cherriesSelectedMultipliers = new Array(8).fill(0);
    public isOpenBase = false;
    public isOpenFSTrigger = false;
    public isOpenFS = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        if (step.json.name == "Bonus Trigger") {
            this.isOpen = true;
            this.isOpenBase = false;
            this.isOpenFSTrigger = false;
            this.isOpenFS = false;
        }
        if (step.json.name == "Base RS1" || step.json.name == "Base RS2") {
            this.isOpenBase = true;
            this.isOpenFSTrigger = false;
            this.isOpenFS = false;
        }
        if (step.json.name == "FS Trigger RS1" || step.json.name == "FS Trigger RS2") {
            this.isOpenFSTrigger = true;
            this.isOpenBase = false;
            this.isOpenFS = false;
        }
        if (step.json.name.match(/^FS Step RS*/)) {
            this.isOpenFSTrigger = false;
            this.isOpenBase = false;
            this.isOpenFS = true;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        this.reelSetBaseNumber = this.step.json.reelSetBaseNumber ? this.step.json.reelSetBaseNumber : 0;
        this.reelSetBaseNumberFS = this.step.json.reelSetBaseNumberFS ? this.step.json.reelSetBaseNumberFS : 0;
        this.reelSetFeatureNumber = this.step.json.reelSetFeatureNumber ? this.step.json.reelSetFeatureNumber: 0;

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        this.isOpenBase = false;
        this.isOpenFSTrigger = false;
        this.isOpenFS = false;
        trace("reels mounted");
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addBaseStep() {
        let reelSetBaseNumber = this.reelSetBaseNumber ? this.reelSetBaseNumber : 1;
        const state = State.state;
        let stepTitle = "Base RS" + (++reelSetBaseNumber);
        bus.$emit(events.ADD_STEP, state.getFeatureStep(stepTitle, reelSetBaseNumber));
    }

    public addFeatureTriggerStep() {
        let reelSetBaseNumberFS = this.reelSetBaseNumberFS ? this.reelSetBaseNumberFS : 1;
        const state = State.state;
        let stepTitleFS = "FS Trigger RS" + (++reelSetBaseNumberFS);
        bus.$emit(events.ADD_STEP, state.getFeatureStep(stepTitleFS, reelSetBaseNumberFS) );
    }

    public addBonusTriggerStep() {
        const state = State.state;
        this.isOpenBase = false;
        this.isOpenFSTrigger = false;
        this.isOpenFS = false;
        bus.$emit(events.ADD_STEP, state.getBonusTriggerFromBaseGame() );
    }
    public addFreeSpinStep() {
        let reelSetFeatureNumber = this.reelSetFeatureNumber ? this.reelSetFeatureNumber : 1;
        const state = State.state;
        let reelNo = parseInt(reelSetFeatureNumber.toString()) + 3;
        let stepFS = "FS Step RS" + reelNo;
        bus.$emit(events.ADD_STEP, state.getFeatureStep(stepFS, this.reelSetFeatureNumber) );
    }
    public viewBaseReelsetNumber() {
        this.isOpenBase = true;
        this.isOpenFSTrigger = false;
        this.isOpenFS = false;
        this.isOpen = false;
    }

    public viewFSTriggerReelsetNumber() {
        this.isOpenBase = false;
        this.isOpenFSTrigger = true;
        this.isOpenFS = false;
        this.isOpen = false;
    }

    public viewFSReelsetNumber() {
        this.isOpenBase = false;
        this.isOpenFSTrigger = false;
        this.isOpenFS = true;
        this.isOpen = false;
    }


    public updateBonusTriggerSelectors() {
        if (this.step.json.reelSetBaseNumber != null) {
            this.reelSetBaseNumber = this.step.json.reelSetBaseNumber;
        } else {
            this.reelSetBaseNumber = 0;
        }

        if (this.step.json.reelSetFeatureNumber != null) {
            this.reelSetFeatureNumber = this.step.json.reelSetFeatureNumber;
        } else {
            this.reelSetFeatureNumber = 0;
        }
    }

    public updateBonusGameCherriesNumber(cherriesNumber) {
        this.step.json.cherriesNumber = cherriesNumber;

        // Reinitialize the cherries multipliers selectors.
        this.step.json.cherriesSelectedMultipliers = new Array(parseInt(cherriesNumber)).fill(0);
        this.cherriesSelectedMultipliers = this.step.json.cherriesSelectedMultipliers;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusGameCherriesMultipliers(event) {
        if (this.step.json.cherriesSelectedMultipliers == null) {
            this.step.json.cherriesSelectedMultipliers = new Array(this.step.json.cherriesNumber).fill(0);
        }

        this.step.json.cherriesSelectedMultipliers[event.target.id - 1] = parseInt(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateSamureelBonusTriggerSelectors() {
        if (this.step.json.cherriesNumber != null) {
            this.cherriesNumber = this.step.json.cherriesNumber;
        } else {
            this.cherriesNumber = 0;
        }

        if (this.step.json.cherriesSelectedMultipliers != null) {
            this.cherriesSelectedMultipliers = this.step.json.cherriesSelectedMultipliers;
        } else {
            this.cherriesSelectedMultipliers = new Array(this.cherriesNumber).fill(0);
        }
    }
}
