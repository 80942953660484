/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Base RS1");
            step.reelsType = "reelset1";
            step.reelSetBaseNumber = 0;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reelset1":
                    return State.state.getFSreels(0);
                    break;
                case "reelset2":
                    return State.state.getFSreels(1);
                    break;
                case "reelset3":
                    return State.state.getFSreels(2);
                    break;
                case "reelset4":
                    return State.state.getFSreels(3);
                    break;
                case "reelset5":
                    return State.state.getFSreels(4);
                    break;
                case "reelset6":
                    return State.state.getFSreels(5);
                    break;
                case "reelset7":
                    return State.state.getFSreels(6);
                    break;
                case "reelset8":
                    return State.state.getFSreels(7);
                    break;
                case "reelset9":
                    return State.state.getFSreels(8);
                    break;
                case "reelset10":
                    return State.state.getFSreels(9);
                    break;
                default:
                    return State.state.getFSreels(0);
            }
        },

        // example on creating custom indexes
        getBaseStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reelset1";
            return step;
        },
        getBonusTriggerFromBaseGame:() => State.state.createStep([48, 126, 6, 0, 0, 0], true, 0, "", "Bonus Trigger"),

        getFeatureStep: (stepType, reelsetNo=0) => {
            let step;
            switch(stepType) {
                case "Base RS1":
                    step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", stepType);
                    step.reelsType = "reelset1";
                    step.reelSetBaseNumber = 0;
                    break;
                case "Base RS2":
                    step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", stepType);
                    step.reelsType = "reelset2";
                    step.reelSetBaseNumber = 1;
                    break;
                case "FS Trigger RS1":
                    step = State.state.createStep([177, 166, 86, 85, 95, 179], false, 0, "", stepType);
                    step.reelsType = "reelset1";
                    step.reelSetBaseNumberFS = 0;
                    break;
                case "FS Trigger RS2":
                    step = State.state.createStep([188, 165, 98, 97, 94, 189], false, 0, "", stepType);
                    step.reelsType = "reelset2";
                    step.reelSetBaseNumberFS = 1;
                    break;
                case 'FS Step RS3':
                case 'FS Step RS4':
                case 'FS Step RS5':
                case 'FS Step RS6':
                case 'FS Step RS7':
                case 'FS Step RS8':
                case 'FS Step RS9':
                case 'FS Step RS10':
                    step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", stepType);
                    step.reelsType = "reelset"+ (parseInt(reelsetNo.toString()) + 3).toString();
                    step.reelSetFeatureNumber = parseInt(reelsetNo.toString());
                    break;
                default:
                    step.reelsType = reelsetNo > 0 ? ("reelset" + reelsetNo.toString()) : "reelset1";
            }

            return step;
        } ,
        getFSreels: (reelset)=> {
            let i;
            const state = State.state;
            const bands = state.getModelDefinition().reelSetsConfiguration[reelset];
            const reelsetResult = new Array();
            for(i = 0; i < bands.length; i++) {
                reelsetResult[i] = state.getModelDefinition().reels[bands[i]-1];
            }
            return reelsetResult;

        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = 6;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 5);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
