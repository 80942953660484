export const GOLD = 1;
export const SWEEPS = 4;

export type CoinType = 1 | 4;

export function getFullCoinName(coinTypeId: number): "GOLD" | "SWEEPS" {
  assertCoinType(coinTypeId);
  switch (coinTypeId) {
    case GOLD:
      return "GOLD";
    case SWEEPS:
      return "SWEEPS";
    default:
      (function exhaustivenessCheck(c: never) {
        throw new Error(`Unhandled coin type ${c}`);
      })(coinTypeId);
  }
}

export function assertCoinType(
  coinTypeId: number,
): asserts coinTypeId is CoinType {
  if (coinTypeId === GOLD || coinTypeId === SWEEPS) return;
  throw new Error(
    `${JSON.stringify(coinTypeId)} is not a valid coin type. Expected 1 or 4.`,
  );
}

export function isSweeps(coinTypeId: number): coinTypeId is typeof SWEEPS {
  return coinTypeId === SWEEPS;
}

export function isGold(coinTypeId: number): coinTypeId is typeof SWEEPS {
  return coinTypeId === SWEEPS;
}

export type JackpotType = "GRAND" | "MAJOR" | "MINOR" | "MINI" | "COMMUNITY";
export const jackpotTypes: Readonly<JackpotType[]> = [
  "GRAND",
  "MAJOR",
  "MINOR",
  "MINI",
  "COMMUNITY",
];

export function assertJackpotType(value: string): asserts value is JackpotType {
  if (jackpotTypes.includes(value as any)) return;
  throw new Error(`${JSON.stringify(value)} is not a valid jackpot type type.`);
}

export type FreeSpinPhase = "START" | "IN_PROGRESS" | "RETRIGGER" | "END";

export type WinType =
  | "NO_WIN"
  | "NORMAL_WIN"
  | "FREE_SPIN_NO_WIN"
  | "FREE_SPIN_WIN"
  | "SCATTER_WIN"
  | "SCATTER_WIN_NO_FREE_SPIN"
  | "BONUS_WIN"
  | "BONUS_GAME_WIN"
  | "FREE_SPIN_JACKPOT_WIN"
  | "JACKPOT_WIN";

export interface BaseModelDefinition {
  reelsLayout: number[];
  winLevels: Array<{
    name: string;
    threshold?: number;
  }>;
  currency_symbol: string;
  // ...more game-specific props...
  coinType1Values: number[];
  coinType4Values: number[];
  reels: string[][];
  featureReels?: string[][];
  freeSpinCount?: number;
  freeSpinTrigger: string;
  countToTriggerFreeSpin: number;
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
  playLines?: number[][];

  // TODO: make non-optional and number-only after fixing all usage sites
  coinType1_min_bet?: number | string;
  coinType1_max_bet?: number | string;
  coinType1_spin_max_bet?: number | string;
  coinType4_min_bet?: number | string;
  coinType4_max_bet?: number | string;
  coinType4_spin_max_bet?: number | string;
}

export type Cell = [row: number, col: number];
