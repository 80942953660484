import Vue from "vue";
import Component from "vue-class-component";
import * as casino from "./../../../helpers/casino";
import {trace, TODO} from "./../../../helpers/helpers";
import State from "../../../state/state";
import bus from "../../../common/bus";
import events from "../../../common/events";

@Component({
    // define reactive variables
    data: () => {
        return {
            message: "",
        };
    },
})
export default class Popup extends Vue {
    public message = "";
    private promise: Promise<any>;
    private showPopup = false;
    private resolved = false;
    private rejected = false;
    private inputText = "";
    private resolve = () => {};
    private reject = () => {};
    constructor() {
        super();
        this.promise = new Promise((res, rej) => {});
        bus.$on(events.SHOW_INPUT_POPUP, (callback, text, defaultValue="") => {
            callback(this.show(text, defaultValue));
        });
    }
    public show(text: string = "", defaultValue: string = "") { 
        this.showPopup = true;
        this.message = text;
        this.inputText = defaultValue;
        this.$nextTick(() => this.$refs.theInput && this.$refs.theInput["focus"]() );
        
        this.promise = new Promise((resolve, reject) => { 
            this.resolve = () => {
                resolve(this.inputText);
            };
            this.reject = () => {
                resolve(false);
            };
        }); 
        return this.promise;
    }
    public close() {
        this.showPopup = false;
    }
    public doOK() {
        trace("OK!");
        this.close();
        this.resolve();
    }
    public doCancel() {
        trace("Cancel!");
        this.close();
        this.reject();
    }
}
