import Vue from "vue"; 
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../../../../../helpers/casino";
import {trace, TODO, rowsFromIndexes, wrapIndex, range} from "./../../../../../helpers/helpers";
import State from "../../../../../state/state";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";
  
@Component({
    data: () => {
        return {
            text: '',
        };
    },
})
export default class NumberBox extends Vue {
    @Prop() public step: any;
    @Prop() public numberArrayName?: string;
    private data: number[] = [];
    private text = "";
    private canUpdate = false;
    private updateTimeout;

    constructor() {
        super();
    }

    public mounted() {
        bus.$on("step-updated", (step) => this.stepChanged(step) );
    }

    public beforeDestroy() { 
    }

    @Watch("step")
    public stepChanged(step) { 
        if (!step || !step.json) { return; }
        this.canUpdate = false;
        this.data = step.json[this.numberArrayName ? this.numberArrayName : ""];
        this.text = String(this.data);

        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }
    @Watch("text")
    public textChanged(val, oldval) {
        if (!this.canUpdate) { return; }

        if (!Array.isArray(Array(val.split(',').map(Number)))) {
            this.text = oldval.split(',').map(Number);
        }

        this.updateStep();
        this.updateDB();
    }
    public updateStep() {
        if (!this.step) {
            return;
        }
        const state = State.state;
        this.data = this.text.split(',').map(Number);
    } 
    public updateDB() { 
        if (!this.step) {  return; }

        this.step.json[this.numberArrayName ? this.numberArrayName : ""] = this.data;
        clearTimeout(this.updateTimeout);

        this.updateTimeout = setTimeout(() => {
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }, 500);
    }
}
