/* tslint:disable:variable-name */
import State from '../../../state';
import init from './config/init';
import { rowsFromIndexes } from '../../../../helpers/helpers';

export function setStateParams() {
    return {
        variations: [''],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Reels');
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => {
            return State.state.getModelDefinition().reels;
        },
        getFeatureReelStrips: () => {
            return State.state.getModelDefinition().freeSpinReels;
        },
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([24, 19, 14, 0, 0], false, 0, '', 'Free Spin trigger');
            return step;
        },

        getFeatureStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Free Spin step');
            step.prizeIndices = [];
            for (let i = 0; i < 30; i++) step.prizeIndices.push(-1);
            return step;
        },

        getClearingStep: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, '', 'Clear');
            step.gameState = 'clear';
            return step;
        },

        prepareStepForDB: step => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.isFeature);

            return step;
        }
    };
}
