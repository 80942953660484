var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        symbol: true,
        scatter: _vm.isScatter,
        bonus: _vm.isBonus,
        winCell: _vm.isWinCell,
        colour: _vm.colour,
      },
      style: { "grid-column": _vm.colIndex + 1, "grid-row": _vm.rowIndex + 1 },
    },
    [_c("p", { staticClass: "current-name" }, [_vm._v(_vm._s(_vm.symbolName))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }