var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sm-left" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "a",
        {
          attrs: { href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.doAddScenario()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-plus-circle fa-2x",
            staticStyle: {
              "margin-top": "7px",
              "margin-left": "5px",
              color: "#00c700",
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "items" },
      _vm._l(_vm.scenarios, function (scenario) {
        return _c(
          "div",
          {
            class: [_vm.selectedScenario == scenario ? "item-active" : "item"],
          },
          [
            _c(
              "table",
              { attrs: { width: "100%", cellpadding: "0", cellspacing: "0" } },
              [
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "trash" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteScenario(scenario)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-trash-alt",
                            staticStyle: { color: "#a7a7a7" },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { width: "100%" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "in",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openScenario(scenario)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !scenario.edit,
                                expression: "!scenario.edit",
                              },
                            ],
                            on: {
                              dblclick: function ($event) {
                                return _vm.setEditMode(scenario)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.trimName(scenario.name)))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scenario.edit,
                              expression: "scenario.edit",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newScenarioName,
                              expression: "newScenarioName",
                            },
                          ],
                          domProps: { value: _vm.newScenarioName },
                          on: {
                            blur: function ($event) {
                              return _vm.checkNameChange(scenario)
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.checkNameChange(scenario)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.newScenarioName = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }