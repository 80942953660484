import { mathModel } from "../../../../v3";
import { Choices } from "../../choices";

const filterSlotWindow = mathModel.filterSlotWindow;

export function stealRoyals(choices: Choices, slotWindow: string[][]) {
  const stolenRoyals = choices.chooseStolenRoyals();
  const replacementSymbols: { cell: mathModel.Cell; symbol: string }[] = [];
  filterSlotWindow(slotWindow, (symbol) =>
    stolenRoyals.includes(symbol)
  ).forEach((cell, index) => {
    const symbol = choices.chooseReplacementSymbol(stolenRoyals, index);
    replacementSymbols.push({ symbol, cell });
  });
  return { replacementSymbols, stolenRoyals };
}
