var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("div", { staticClass: "sm-game-spec-content" }, [
        _c("div", { staticClass: "flex-center menu" }, [
          _c(
            "div",
            {
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add Clear Step")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddBaseSpinStep(),
                  expression: "canAddBaseSpinStep()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addBaseStep()
                },
              },
            },
            [_vm._v("Add Base Step")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddBaseSpinStep(),
                  expression: "canAddBaseSpinStep()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addBaseSnowBlastStep()
                },
              },
            },
            [_vm._v("Add Base - SB Step")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddBaseSpinStep(),
                  expression: "canAddBaseSpinStep()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addFreespinTriggerFromBaseStep()
                },
              },
            },
            [_vm._v("Add Base - Free Spin Trigger")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddWheelSpin(),
                  expression: "canAddWheelSpin()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addWheelSpinStep()
                },
              },
            },
            [_vm._v("Add Wheel Spin")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddJackpotSpin(),
                  expression: "canAddJackpotSpin()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addJackpotSpinStep()
                },
              },
            },
            [_vm._v("Add Jackpot Spin")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddFreeSpinOrRetrigger(),
                  expression: "canAddFreeSpinOrRetrigger()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addFreeSpinStep()
                },
              },
            },
            [_vm._v("Add Free Spin")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canAddFreeSpinOrRetrigger(),
                  expression: "canAddFreeSpinOrRetrigger()",
                },
              ],
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.addFreeSpinReTriggerStep()
                },
              },
            },
            [_vm._v("Add Free Spin Retrigger")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.getWheelSpinCount() >= 0 || _vm.getFreeSpinCount() >= 0,
                expression:
                  "getWheelSpinCount() >= 0 || getFreeSpinCount() >= 0 ",
              },
            ],
            staticClass: "flex-center sub-title-menu",
          },
          [
            _c("div", { staticClass: "label" }, [
              _vm._v(
                "Wheel Spins Remaining: " + _vm._s(_vm.getWheelSpinCount())
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "label" }, [
              _vm._v("Free Spins Remaining: " + _vm._s(_vm.getFreeSpinCount())),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.canAddWild()
          ? _c("div", { staticClass: "note" }, [
              _vm._v(
                "\n        Note: Click any symbol to replace it with a WILD (select multiplier in the dropdown)\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.shouldSlotWindowVisible(),
                expression: "shouldSlotWindowVisible()",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c("reel", { attrs: { content: this, index: 0, step: _vm.step } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: this, index: 1, step: _vm.step } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: this, index: 2, step: _vm.step } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: this, index: 3, step: _vm.step } }),
            _vm._v(" "),
            _c("reel", { attrs: { content: this, index: 4, step: _vm.step } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowingWheelOptions(),
                expression: "isShowingWheelOptions()",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c("div", [
              _c("div", { staticClass: "label" }, [_vm._v("Select Prize:")]),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    domProps: { value: _vm.getWheelPrize() },
                    on: {
                      input: function ($event) {
                        return _vm.setWheelPrize($event.target.value)
                      },
                    },
                  },
                  [
                    _vm._l(_vm.getWheelPrizes(), function (opt) {
                      return [
                        _c("option", { domProps: { value: opt } }, [
                          _vm._v(_vm._s(opt)),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.shouldShowPrizeValueSection()
              ? _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Select value:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-style" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.wheelPrizeValue,
                            expression: "wheelPrizeValue",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.wheelPrizeValue = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        [_c("option", { domProps: { value: "0" } })],
                        _vm._v(" "),
                        _vm._l(_vm.getPrizeList(), function (opt) {
                          return [
                            _c("option", { domProps: { value: opt } }, [
                              _vm._v(_vm._s(opt)),
                            ]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowingJackpotOptions(),
                expression: "isShowingJackpotOptions()",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c("div", [
              _c("div", { staticClass: "label" }, [_vm._v("Select Jackpot:")]),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    domProps: { value: _vm.getJackpotType() },
                    on: {
                      input: function ($event) {
                        return _vm.setJackpotType($event.target.value)
                      },
                    },
                  },
                  [
                    _vm._l(_vm.getJackpotTypes(), function (opt) {
                      return [
                        _c("option", { domProps: { value: opt } }, [
                          _vm._v(_vm._s(opt)),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }