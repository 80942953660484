var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreespinSelectStep()
              },
            },
          },
          [_vm._v("Feature Select")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra5Step()
              },
            },
          },
          [_vm._v("FS Extra 5 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra15Step()
              },
            },
          },
          [_vm._v("FS Extra 15 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra30Step()
              },
            },
          },
          [_vm._v("FS Extra 30 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinTrigger()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStep()
              },
            },
          },
          [_vm._v("Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addMixAndMatchStep()
              },
            },
          },
          [_vm._v("Mix and Match Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addDummyStep()
              },
            },
          },
          [_vm._v("Dummy step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.isRespin &&
                !_vm.isMixAndMatchStep &&
                !_vm.isFreespinSelectStep,
              expression:
                "!isRespin && !isMixAndMatchStep && !isFreespinSelectStep",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.isRespin &&
                !_vm.isMixAndMatchStep &&
                !_vm.isMixAndMatchStep &&
                !_vm.isFeature &&
                !_vm.isFreespinSelectStep,
              expression:
                "!isRespin && !isMixAndMatchStep && !isMixAndMatchStep && !isFeature && !isFreespinSelectStep",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [_vm._v("Mix and Match trigger:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mixAndMatchTrigger,
                  expression: "mixAndMatchTrigger",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "mixAndMatchTrigger", id: "mixAndMatchTrigger" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.mixAndMatchTrigger = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeMixAndMatchTrigger,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "false", selected: "" } }, [
                _vm._v("false"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "true" } }, [_vm._v("true")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMixAndMatchStep && !_vm.isFreespinSelectStep,
              expression: "!isMixAndMatchStep && !isFreespinSelectStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value,
                  attrs: {
                    index: value,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value + 5,
                  attrs: {
                    index: value + 5,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value + 5 + 5,
                  attrs: {
                    index: value + 5 + 5,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin || _vm.isRespinTrigger,
              expression: "isRespin || isRespinTrigger",
            },
          ],
          staticClass: "flex-center menu",
        },
        [
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "button pointer",
              on: {
                click: function ($event) {
                  return _vm.resetRespinSymbols()
                },
              },
            },
            [_vm._v("Reset")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isMixAndMatchStep && !_vm.isFreespinSelectStep,
              expression: "isMixAndMatchStep && !isFreespinSelectStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "selected-scatters" }, [
            _c("label", [_vm._v(" Mix and Match selection steps: ")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-center sub-title-menu" }, [
              _c("div", { staticClass: "flex-center menu sm-label" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.updateMixAndMatchPrizeCounter(true)
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.updateMixAndMatchPrizeCounter(false)
                      },
                    },
                  },
                  [_vm._v("Delete")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-center" },
              _vm._l(_vm.mixAndMatchPrizes.length, function (n) {
                return _c("mixAndMatchPrizes", {
                  key: n,
                  attrs: { index: n, mixAndMatchPrizes: _vm.mixAndMatchPrizes },
                })
              }),
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.isRespin &&
                !_vm.isMixAndMatchStep &&
                !_vm.isMixAndMatchStep &&
                !_vm.isFeature &&
                !_vm.isFreespinSelectStep,
              expression:
                "!isRespin && !isMixAndMatchStep && !isMixAndMatchStep && !isFeature && !isFreespinSelectStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c("br"),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("meta Stage Counter (4 stage with 3 steps):")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.metaStageCntr,
                  expression: "metaStageCntr",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "metaStageCntr", id: "metaStageCntr" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.metaStageCntr = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeMetaStageCntr,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "-1", selected: "" } }, [
                _vm._v("leave"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "11" } }, [_vm._v("11")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFreespinSelectStep,
              expression: "isFreespinSelectStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("Set these only for mystery freespin selection")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFreespinSelectStep,
              expression: "isFreespinSelectStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("Mystery count (num of extra spins):")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.mysteryCount,
                  expression: "mysteryCount",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "mysteryCount", id: "mysteryCount" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.mysteryCount = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeMysteryCount,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "15", selected: "" } }, [
                _vm._v("15"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("Mystery reels (num of extra coins):")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.mysteryReelSet,
                  expression: "mysteryReelSet",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "mysteryReelSet", id: "mysteryReelSet" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.mysteryReelSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeMysteryReelSet,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "extra5", selected: "" } }, [
                _vm._v("extra5"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "extra15" } }, [
                _vm._v("extra15"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "extra30" } }, [
                _vm._v("extra30"),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }