/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.reelsType = "reels";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,

        getFSNumberOfReels: () => State.state.getModelDefinition().reelsLayoutFreeSpin.length,
        getFSReelThreeStrips: () => State.state.getModelDefinition().freeSpinsSetThree,
        getFSReelFourStrips: () => State.state.getModelDefinition().freeSpinsSetFour,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "freeSpinsSetThree":
                    return State.state.getModelDefinition().freeSpinsSetThree;
                    break;
                case "freeSpinsSetFour":
                    return State.state.getModelDefinition().freeSpinsSetFour;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getBonusTriggerFromBaseGame:() => State.state.createStep([0, 26, 13, 12, 0], true, 0, "", "Bonus Trigger"),
        getFeatureTriggerFromBaseGameStep: function() {
            const step = State.state.createStep([12, 23, 24, 25, 13], false, 0, "", "5 FS Symbols");
            step.reelsType = "reels";
            return step;
        },
        getFeatureStep: () => State.state.createStep([0, 26, 13, 12, 0], true, 0, "", "Bonus game"),
        getFSThreeStep: function() {
            const step = State.state.createStep([0, 0, 0, 0], false, 0, "", "Three Reels");
            step.reelsType = "freeSpinsSetThree";
            return step;
        },
        getFSFourStep: function() {
            const step = State.state.createStep([0, 0, 0, 0], false, 0, "", "Four Reels");
            step.reelsType = "freeSpinsSetFour";
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = (step.reelsType == "freeSpinsSetThree" || step.reelsType == "freeSpinsSetFour") ? 4: 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 3);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
