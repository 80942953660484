export function sortFeaturesByOrderGiven<T>(features: T[], order: T[]): T[] {
    const orderMap = new Map(order.map((item, index) => [item, index]));

    return features.sort((a, b) => {
        const indexA = orderMap.get(a);
        const indexB = orderMap.get(b);

        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
    });
}
