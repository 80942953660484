import { ScenarioSchema, createChoicesFromScenario, createRandomChoices } from './choices';
import {
    GameRequestSchema,
    GameResponseSchema,
    GameStateSchema,
    InitResponseSchema,
    init,
    play,
} from './operations';
import { modelDefinition } from './model-definition';

export type { GameRequest, GameResponse, GameState } from './operations';

export const model = {
    play,
    init,
    definition: modelDefinition,
    choices: {
        createRandomChoices,
        createChoicesFromScenario,
    },
    schemas: {
        initResponseSchema: InitResponseSchema,
        gameRequestSchema: GameRequestSchema,
        gameResponseSchema: GameResponseSchema,
        gameStateSchema: GameStateSchema,
        scenarioSchema: ScenarioSchema,
    },
};
