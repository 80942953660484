// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content .flex-center[data-v-1cdddbac] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .menu[data-v-1cdddbac] {
    margin: 5px 0 5px 0;
}
.centred[data-v-1cdddbac] {
    display: flex;
    justify-content: center;
}
.label[data-v-1cdddbac] {
    color: #a0a0a0;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw073/content.vue.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,cAAc;AAClB","sourcesContent":[".sm-game-spec-content .flex-center[data-v-1cdddbac] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .menu[data-v-1cdddbac] {\n    margin: 5px 0 5px 0;\n}\n.centred[data-v-1cdddbac] {\n    display: flex;\n    justify-content: center;\n}\n.label[data-v-1cdddbac] {\n    color: #a0a0a0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
