// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stolen-royals-selector[data-v-f3e62cbc] {
    margin-top: 15px;
    background: #707070; 
    padding: 10px;
    border-radius: 5px;
}
.picks-type[data-v-f3e62cbc] {
    background-color: #d0d0d0; 
    border: 1px solid #bbb;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.picks-type-text[data-v-f3e62cbc] {
    color: #28282B;
    font-size: 18px; 
    font-weight: bold;
    padding-right: 20px;
}
.stolen-royals-invalid-text[data-v-f3e62cbc] {
    font-size: 22px; 
    font-weight: bold;
    color: transparent; 
    background-image: linear-gradient(to right, red, rgb(154, 95, 255)); 
    -webkit-background-clip: text; 
    background-clip: text;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/reel-spin-feature-controls/stolen-royals-controls/stolen-royals-controls.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;AACzC;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mEAAmE;IACnE,6BAA6B;IAC7B,qBAAqB;AACzB","sourcesContent":[".stolen-royals-selector[data-v-f3e62cbc] {\n    margin-top: 15px;\n    background: #707070; \n    padding: 10px;\n    border-radius: 5px;\n}\n.picks-type[data-v-f3e62cbc] {\n    background-color: #d0d0d0; \n    border: 1px solid #bbb;\n    padding: 15px;\n    margin-bottom: 10px;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n.picks-type-text[data-v-f3e62cbc] {\n    color: #28282B;\n    font-size: 18px; \n    font-weight: bold;\n    padding-right: 20px;\n}\n.stolen-royals-invalid-text[data-v-f3e62cbc] {\n    font-size: 22px; \n    font-weight: bold;\n    color: transparent; \n    background-image: linear-gradient(to right, red, rgb(154, 95, 255)); \n    -webkit-background-clip: text; \n    background-clip: text;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
