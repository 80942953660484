import {
  CoinType,
  MathModelError,
  createCoinSizeValidator,
} from "../../../v3/math-model";
import { modelDefinition } from "../config";
import { GameState } from "../game-state";

export function ensureSpinInputsAreValid(inputs: {
  coinTypeId: CoinType;
  coinSize: number;
  gameState?: GameState;
}): void {
  const { coinTypeId, coinSize, gameState } = inputs;

  if (!isValidCoinSize(coinTypeId, coinSize)) {
    throw new MathModelError("Invalid coin size", { coinTypeId, coinSize });
  }

  // During feature games (fireshot or free spins), ensure coin size remains the same as from the triggering spin.
  if (gameState !== undefined && coinSize !== gameState.coinSize) {
    throw new MathModelError(
      `Coin size must match value ${gameState.coinSize} from triggering spin`,
      {
        coinSize,
      }
    );
  }
}

const isValidCoinSize = createCoinSizeValidator(modelDefinition);
