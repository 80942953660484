var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateWheelWin } },
      [
        _c("option", { attrs: { value: "WHEEL_DUMMY" } }, [
          _vm._v("Dummy step for collect"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "WHEEL_WIN" } }, [_vm._v("Win")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "WHEEL_NO_WIN" } }, [_vm._v("No win")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "FREE_SPIN" } }, [
          _vm._v("Freespin wheel: FREE_SPIN"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "JACKPOT" } }, [
          _vm._v("Freespin wheel: JACKPOT"),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MULTIPLIER" } }, [
          _vm._v("Freespin wheel: MULTIPLIER"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }