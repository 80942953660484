import { SpinInputs } from "../spin-inputs";
import { SpinOutputs } from "../spin-outputs";
import { GameState } from "../../game-state";
import { modelDefinition } from "../../config";
import { getWinType } from "../get-win-type";
import { SpinOutcome } from "../spin-outcome";
import { BoardGamePhase, generateBoardGame } from ".";

export function boardGame(inputs: SpinInputs): SpinOutputs {
  const { gameState, coinSize, choices } = inputs;
  if (gameState?.boardGamePhase === undefined) {
    throw new Error("Missing state");
  }

  const totalPlayAmount = coinSize * coinSizeMultiplier;

  let boardGamePhase: BoardGamePhase = gameState.boardGamePhase;

  let boardGameCount = gameState.boardGameCount;
  let boardGameState =
    gameState.boardGameState !== undefined
      ? gameState.boardGameState.slice(0)
      : undefined;
  let boardGameTotalCount: number | undefined = undefined;

  if (boardGameCount === undefined && boardGameState === undefined) {
    const generatedBoardGame = generateBoardGame(
      board,
      choices,
      boardGameLastPinkPosition
    );
    boardGameCount = generatedBoardGame.boardGameCount;
    boardGameState = generatedBoardGame.boardGameState;
    boardGameTotalCount = generatedBoardGame.boardGameCount;
  }

  if (boardGameState === undefined) {
    throw new Error("Missing state");
  }

  const gameStep = boardGameState.shift();

  if (gameStep === undefined) {
    throw new Error("Missing state");
  }

  if (boardGameCount === undefined) {
    throw new Error("Missing state");
  }

  const isJackpotTrigger = gameStep.step.position === rainbowPosition;
  let canDoJackpotWheel: boolean | undefined = undefined;
  if (isJackpotTrigger) {
    boardGameCount = 0;
    canDoJackpotWheel = true;
  } else {
    boardGameCount--;
    boardGameCount += gameStep.step.extraPicks ?? 0;
  }
  const multiplier = gameStep.step.multiplier ?? 1;
  boardGamePhase = boardGameCount === 0 ? "END" : "IN_PROGRESS";
  const boardGameStep = gameStep.step;
  const boardGameCard = gameStep.card;
  const { cumulativeWinAmount: oldCumulativeWinAmount } = gameState;

  // Compute win type and amount
  const totalWinAmount = gameStep.prize * coinSize * multiplier;
  const boardGamePrize = gameStep.prize * coinSize;
  const cumulativeWinAmount = oldCumulativeWinAmount + totalWinAmount;
  const winType = getWinType({ totalWinAmount, isBoardGameSpin: true });

  // Get the slot window from game state
  const reelStripPositions = gameState.reelStripPositions;
  const slotWindow = gameState.slotWindow;
  const freeSpinCount: number | undefined = gameState.freeSpinCount;
  const freeSpinPhase: GameState["freeSpinPhase"] | undefined =
    gameState.freeSpinPhase;
  const spinOutcome: SpinOutcome = {
    coinSize,
    totalPlayAmount,
    reelStripPositions,
    slotWindow,
    totalWinAmount,
    winType,
    winLines: [],
    lineWins: [],
    winCells: [],
    freeSpinCount,
    freeSpinPhase,
    cumulativeWinAmount,
    boardGamePhase,
    boardGameCount,
    boardGameStep,
    boardGameCard,
    canDoJackpotWheel,
    boardGamePrize,
    boardGameTotalCount,
  };

  const isFreeSpinsContinuing = (freeSpinPhase ?? "END") !== "END";
  const isBoardGameContinuing =
    boardGamePhase !== "END" || canDoJackpotWheel === true;
  if (!isBoardGameContinuing && !isFreeSpinsContinuing) return { spinOutcome };
  return {
    spinOutcome,
    gameState: {
      slotWindow,
      reelStripPositions,
      coinSize,
      freeSpinCount,
      freeSpinPhase,
      cumulativeWinAmount,
      boardGamePhase: !isBoardGameContinuing ? undefined : boardGamePhase,
      boardGameCount: !isBoardGameContinuing ? undefined : boardGameCount,
      boardGameState: !isBoardGameContinuing ? undefined : boardGameState,
      boardGameStep: !isBoardGameContinuing ? undefined : boardGameStep,
      boardGamePrize: !isBoardGameContinuing ? undefined : boardGamePrize,
      canDoJackpotWheel: !isBoardGameContinuing ? undefined : canDoJackpotWheel,
    },
  };
}

const {
  coinSizeMultiplier,
  rainbowPosition,
  board,
  boardGameLastPinkPosition,
} = modelDefinition;
