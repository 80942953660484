import type { Choices } from '../../../choices/index';
import type { Cell, GameState } from '../../../shared/index';
import { type GameResponse } from '../game-response';
import type { SpinInputs } from '../inputs';
import type { SpinOutcome } from '../outcome';

export function pickGoldCase(spinInputs: SpinInputs, _: Choices): SpinOutcome {
    const { gameRequest, gameState } = spinInputs;
    const { coinAmount, goldCasePick } = gameRequest;
    if(gameState === undefined){throw new Error()}
    if(goldCasePick === undefined){throw new Error()}
    if(gameState.caseChase === undefined){throw new Error()}
    if(gameState.caseChase.goldCasePicks === undefined){throw new Error()}
    if(gameState.caseChase.goldCasePrizes === undefined){throw new Error()}


    const prizesBefore = gameState.caseChase.allPrizes;
    const prizesAfter = [...prizesBefore.map((casePrize) => ({ ...casePrize }))];
    const pickedGoldCase = gameState.caseChase.goldCasePrizes[gameState.caseChase.goldCasePicks.length];
    const self = prizesAfter.find(({ cell }) => isSameCell(cell, goldCasePick));
    if( self === undefined){throw new Error()}

    switch (pickedGoldCase.feature) {
        case 'COLLECT': {
            self.gold = 'COLLECT';
            self.prize = pickedGoldCase.prize;
            self.value = prizesAfter.reduce((total, { prize }) => total + (prize ?? 0), 0) * coinAmount;
            break;
        }
        case 'MULTIPLYx2':
        case 'MULTIPLYx3':
        case 'MULTIPLYx4':
        case 'MULTIPLYx5': {
            const multiplier = Number(pickedGoldCase.feature.slice(-1));
            self.gold = pickedGoldCase.feature;
            self.prize = pickedGoldCase.prize;
            self.value = pickedGoldCase.prize * coinAmount;
            for (const p of prizesAfter) {
                if (p === self || p.gold === 'PICK') continue;
                p.value = (p.value ?? 0) * multiplier;
            }
            break;
        }
        case 'BOOST': {
            const boostValue = pickedGoldCase.prize * coinAmount;
            self.gold = 'BOOST';
            self.prize = pickedGoldCase.prize;
            self.value = boostValue;
            for (const p of prizesAfter) {
                if (p === self || p.gold === 'PICK') continue;
                p.value = (p.value ?? 0) + boostValue;
            }
            break;
        }
    }

    const gameResponse: GameResponse = {
        reelStripPositions: gameState.reelStripPositions,
        slotWindow: gameState.slotWindow,
        coinAmount,
        playAmount: 0,
        winAmount: 0,
        lineWins: [],
        winType: 'FREE_SPIN_NO_WIN',
        cumulativeWinAmount: gameState.cumulativeWinAmount,
        freeSpinPhase: gameState.freeSpinPhase,
        freeSpinCount: gameState.freeSpinCount,
        goldCaseCells: [],
        caseChase: gameState.caseChase,
        goldCasePickResult: { goldCase: self, prizesBefore, prizesAfter },
    };
    const newGameState: GameState = {
        reelStripPositions: gameState.reelStripPositions,
        slotWindow: gameState.slotWindow,
        coinAmount,
        cumulativeWinAmount: gameState.cumulativeWinAmount,
        freeSpinPhase: gameState.freeSpinPhase,
        freeSpinCount: gameState.freeSpinCount,
        caseChase: {
            phase: gameState.caseChase.phase,
            respins: gameState.caseChase.respins,
            newPrizes: [self],
            allPrizes: prizesAfter,
            hardPosition: gameState.caseChase.hardPosition,
            mediumPosition: gameState.caseChase.mediumPosition,
            goldCasePrizes: gameState.caseChase.goldCasePrizes,
            goldCasePicks: [...gameState.caseChase.goldCasePicks, goldCasePick],
        },
    };

    return {
        gameResponse,
        gameState: newGameState,
        playSummary: { playAmount: 0, winAmount: 0, roundComplete: false },
    };
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}