import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import WheelResult from "./components/wheelResult.vue";

@Component({
	components: {
		reel: Reel,
		wheelResult: WheelResult,
	},
})
export default class Content extends Vue {
	@Prop() public step: any;
	public isFeature = false;
	public isFeatureChecked = false;
	public show = false;
	public reelStripPositions = [];
	public reel = "";
	public isOpen = false;

	public baseReelHeightOne = 7;
	public baseReelHeightTwo = 6;
	public baseReelHeightThree = 6;
	public baseReelHeightFour = 6;
	public baseReelHeightFive = 6;
	public baseReelHeightSix = 7;

	public dynamicSymbol = "";
	public wheelResults = new Array();

	constructor() {
		super();
	}

	@Watch("step")
	public stepWatch(step) {
		if (!step) {
			this.show = false;
			return;
		}

		this.isOpen = false;
		this.show = true;
		this.isFeature = step.json.isFeature ? step.json.isFeature : false;
		this.dynamicSymbol = step.json.dynamicSymbol;
	}

	public mounted() {
		this.isOpen = false;
		trace("reels mounted");
	}

	public addBaseStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getBaseStep());
	}

	public updateTopReelReelStripPosition(value) {
		this.step.json.topReelReelStripPosition = value;

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateBaseReelHeight(index, value) {
		switch (index) {
			case 0:
				this.step.json.baseReelHeightOne = value;
				break;
			case 1:
				this.step.json.baseReelHeightTwo = value;
				break;
			case 2:
				this.step.json.baseReelHeightThree = value;
				break;
			case 3:
				this.step.json.baseReelHeightFour = value;
				break;
			case 4:
				this.step.json.baseReelHeightFive = value;
				break;
			case 5:
				this.step.json.baseReelHeightSix = value;
				break;
		}

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateBaseReelsHeights() {
		if (this.step.json.baseReelHeightOne != null) {
			this.baseReelHeightOne = this.step.json.baseReelHeightOne;
		}

		if (this.step.json.baseReelHeightTwo != null) {
			this.baseReelHeightTwo = this.step.json.baseReelHeightTwo;
		}

		if (this.step.json.baseReelHeightThree != null) {
			this.baseReelHeightThree = this.step.json.baseReelHeightThree;
		}

		if (this.step.json.baseReelHeightFour != null) {
			this.baseReelHeightFour = this.step.json.baseReelHeightFour;
		}

		if (this.step.json.baseReelHeightFive != null) {
			this.baseReelHeightFive = this.step.json.baseReelHeightFive;
		}

		if (this.step.json.baseReelHeightSix != null) {
			this.baseReelHeightSix = this.step.json.baseReelHeightSix;
		}
	}

	public updateWheelResults() {
		if (this.step.json.wheelResults != null) {
			this.wheelResults = this.step.json.wheelResults;
		} else {
			this.wheelResults = new Array();
		}
	}

	public addFeatureTriggerStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getFeatureTriggerStep());
	}

	public addFeatureStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getFeatureStep());
	}

	public onChangeDynamicSymbol() {
		this.step.json.dynamicSymbol = this.dynamicSymbol;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateWheelResult(index, event) {
		this.step.json.wheelResults[index] = event.target.value;
		this.wheelResults[index] = event.target.value;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public shouldDisplay(optionIndex: number, wheelIndex: number): boolean {
		const results: string[] = this.step.json.wheelResults;
		let actualWheelIndex = 0;
		if (wheelIndex > 0) {
			for (let i = 0; i < wheelIndex - 1; i++) {
				if (i < results.length) {
					const next = results[i].indexOf("r");
					if (next >= 0) {
						actualWheelIndex++;
					} else {
						const prev = results[i].indexOf("l");
						if (prev >= 0) {
							actualWheelIndex--;
						} else return false;
					}
				}
			}
		}
		return optionIndex === actualWheelIndex;
	}

	public updatewheelResultCounter(direction) {
		const wheelResultsNum = this.wheelResults.length;
		if (direction) {
			this.step.json.wheelResults[wheelResultsNum] = "";
			this.wheelResults[wheelResultsNum] = "";
		} else {
			if (wheelResultsNum > 0) {
				this.wheelResults.pop();
				this.step.json.wheelResults = [];
				this.wheelResults.forEach((element) => {
					this.step.json.wheelResults.push(element);
				});
			} else {
				alert("no more to delete");
			}
		}
		this.$forceUpdate();
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public addClearingStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getClearingStep());
	}
}
