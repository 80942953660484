import { Choices } from './choices';
import { ScenarioStep } from './scenario-step';

export function createChoicesFromScenarioStep(scenarioStep: ScenarioStep): Choices {
    return {
        chooseReelStripPositions() {
            return scenarioStep.reelStripPositions ?? [0, 0, 0, 0, 0];
        },
    };
}
