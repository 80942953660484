export default {
  "success": true,
  "messages": [],
  "modelDefinition": {
    "modelId": "dragonsden",
    "scatterSymbol": "FS",
    "wildSymbol": "WI",
    "freeSpinTrigger": "FS",
    "spinCountToBonus": 115,
    "minLineCount": 40,
    "countToTriggerFreeSpin": 2,
    "freeSpinCount": 10,
    "winTable": [
      {
        "symbol": "N4",
        "multipliers": [50, 25, 10]
      }, {
        "symbol": "N3",
        "multipliers": [50, 25, 10]
      }, {
        "symbol": "N2",
        "multipliers": [50, 25, 10]
      }, {
        "symbol": "N1",
        "multipliers": [50, 25, 10]
      }, {
        "symbol": "J3",
        "multipliers": [150, 40, 10, 5]
      }, {
        "symbol": "J2",
        "multipliers": [250, 50, 15, 5]
      }, {
        "symbol": "J1",
        "multipliers": [500, 100, 25, 10, 5]
      }, {
        "symbol": "FS",
        "multipliers": [0, 0, 0, 0, 1]
      }
    ],
    "reelsLayout": [3, 4, 4, 4, 4, 3],


    "playLines": [
      [1, 1, 1, 1, 1, 1], // 1
      [0, 0, 0, 0, 0, 0], // 2
      [2, 2, 2, 2, 2, 2], // 3
      [2, 3, 3, 3, 3, 2], // 4
      [2, 0, 1, 2, 3, 1], // 5
      [1, 3, 2, 1, 0, 2], // 6
      [0, 0, 1, 1, 2, 2], // 7
      [2, 2, 1, 1, 0, 0], // 8
      [2, 2, 0, 0, 2, 2], // 9
      [0, 0, 2, 2, 0, 0], // 10
      [1, 2, 1, 2, 1, 2], // 11
      [2, 1, 2, 1, 2, 0], // 12
      [0, 3, 1, 1, 3, 0], // 13
      [2, 0, 2, 0, 2, 0], // 14
      [1, 2, 0, 2, 0, 1], // 15
      [1, 1, 3, 3, 1, 1], // 16
      [2, 3, 1, 1, 3, 2], // 17
      [0, 0, 3, 3, 0, 0], // 18
      [1, 0, 2, 0, 2, 0], // 19
      [0, 2, 0, 2, 0, 2], // 20
      [2, 1, 0, 1, 1, 2], // 21
      [1, 0, 1, 0, 2, 1], // 22
      [0, 3, 2, 1, 0, 0], // 23
      [2, 0, 3, 0, 3, 1], // 24
      [0, 3, 0, 3, 0, 2], // 25
      [1, 0, 2, 1, 0, 1], // 26
      [2, 3, 1, 2, 3, 1], // 27
      [0, 2, 1, 0, 3, 2], // 28
      [1, 0, 3, 3, 0, 1], // 29
      [0, 1, 2, 2, 1, 0], // 30
      [1, 3, 1, 1, 3, 1], // 31
      [2, 1, 2, 3, 2, 1], // 32
      [0, 1, 3, 3, 1, 0], // 33
      [2, 3, 0, 0, 3, 2], // 34
      [1, 2, 3, 2, 1, 0], // 35
      [0, 1, 2, 3, 0, 1], // 36
      [1, 0, 3, 2, 1, 0], // 37
      [2, 3, 0, 1, 2, 0], // 38
      [1, 2, 3, 0, 1, 2], // 39
      [2, 1, 0, 3, 2, 1]  // 40
    ],

    "bonusMultipliers": [
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 25, 0, 0],
      [1, 1, 1, 2, 2, 3, 3, 3, 4, 4, 0, 0],
      [1, 1, 1, 2, 2, 3, 3, 3, 5, 9, 0, 0],
      [1, 1, 1, 2, 2, 2, 3, 3, 8, 10, 0, 0],
      [1, 1, 2, 2, 2, 3, 3, 5, 6, 12, 0, 0],
      [1, 1, 2, 2, 2, 2, 3, 4, 4, 5, 0, 0]
    ],


    "coinType1Values": [250, 2500, 10000, 50000, 100000],
    "coinType4Values": [1, 2, 5, 10, 50],
    "coinType1_min_bet": "250",
    "coinType1_max_bet": "100000",
    "coinType1_spin_max_bet": "3000000",
    "coinType4_min_bet": "1",
    "coinType4_max_bet": "50",
    "coinType4_spin_max_bet": "1500",
    "reels": [
      ["N1", "N4", "J3", "N3", "N1", "N4", "J1", "N2", "N4", "J2", "N3", "N1", "N2", "N1", "FS", "N2", "J3", "N3", "J1", "N4", "N2", "J2", "N4", "N3", "N2"],
      ["N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "WI", "WI", "WI", "WI", "N1", "N2", "N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "WI", "J2", "N3", "N4", "N1", "N2", "N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "WI", "J2", "N3", "N4", "N1", "N2", "N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "J1", "J1", "J1", "J1", "N1", "N2", "N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "J2", "J2", "J2", "J2", "N1", "N2", "N3", "N4", "J1", "N2", "N1", "N4", "J3", "N3", "N1", "J2", "N3", "N1", "N3", "N2", "J3", "J3", "J3", "J3", "N1", "N2"],
      ["N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "WI", "WI", "WI", "WI", "J2", "N4", "N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "WI", "N2", "J1", "N3", "J2", "N4", "N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "WI", "N2", "J1", "N3", "J2", "N4", "N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "J1", "J1", "J1", "J1", "J2", "N4", "N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "J2", "J2", "J2", "J2", "J2", "N4", "N2", "N1", "N4", "N3", "J1", "N4", "J3", "N2", "N1", "N4", "J3", "N2", "N1", "N4", "J3", "J3", "J3", "J3", "J2", "N4"],
      ["N1", "N3", "N1", "N2", "N4", "J3", "N4", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "WI", "WI", "WI", "WI", "N4", "J1", "N1", "N4", "J1", "N2", "N4", "J3", "N4", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "WI", "N4", "N1", "N2", "N4", "N1", "N1", "N4", "N1", "N2", "N4", "J3", "N3", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "WI", "N4", "N1", "N2", "N4", "N1", "N1", "N3", "J1", "N2", "N4", "J3", "N3", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "J1", "J1", "J1", "J1", "N4", "J1", "N1", "N3", "J1", "N2", "N4", "J3", "N3", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "J2", "J2", "J2", "J2", "N4", "J1", "N1", "N3", "J1", "N2", "N4", "J3", "N3", "J2", "N4", "N2", "N1", "N4", "N2", "J3", "J3", "J3", "J3", "J3", "N4", "J1"],
      ["N3", "N1", "N2", "J2", "N1", "N3", "J1", "N2", "N1", "J3", "N4", "N2", "N1", "N3", "WI", "WI", "WI", "WI", "N3", "N4", "N1", "N1", "N2", "J2", "N1", "N1", "J1", "N2", "N3", "J3", "N4", "N2", "N1", "N3", "WI", "N1", "N4", "J2", "N1", "N4", "N3", "N1", "N2", "J2", "N1", "N3", "J1", "N2", "N3", "J3", "N4", "N2", "N1", "N3", "WI", "N1", "N4", "J2", "N3", "N4", "N3", "N1", "N2", "J2", "N1", "N3", "J1", "N2", "N3", "J3", "N4", "N2", "N1", "N3", "J1", "J1", "J1", "J1", "N3", "N4", "N3", "N1", "N2", "J2", "N1", "N3", "J1", "N2", "N3", "J3", "N4", "N2", "N1", "N3", "J2", "J2", "J2", "J2", "N3", "N4", "N3", "N1", "N2", "J2", "N1", "N3", "J1", "N2", "N3", "J3", "N4", "N2", "N1", "N3", "J3", "J3", "J3", "J3", "N3", "N4"],
      ["N2", "N1", "N4", "N2", "J1", "N4", "N2", "N1", "N3", "J2", "N2", "N4", "N3", "N1", "N2", "J3", "N3", "J1", "N4", "J2", "N3", "N1", "FS", "J3", "N4"]
    ],

    "baseGameLockedWeights": [20, 1],
    "featureGameLockedWeights": [1, 1]
  }
}
