import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import type { Choices } from './choices';

export const ScenarioSchema = Type.Object({
    reelStripPositions: Type.Optional(Type.Array(Type.Integer())),

    isSuperStarAwarded: Type.Optional(Type.Boolean()),
    superStarMultiplier: Type.Optional(Type.Integer()),
});
export type Scenario = Static<typeof ScenarioSchema>;

export function createChoicesFromScenario(scenario: Scenario): Choices {
    return {
        chooseReelStripPositions() {
            return scenario.reelStripPositions ?? [0, 0, 0, 0, 0];
        },
        chooseSuperStarMultiplier() {
            return {
                isSuperStarAwarded: scenario.isSuperStarAwarded ?? false,
                superStarMultiplier: scenario.isSuperStarAwarded
                    ? scenario.superStarMultiplier
                        ? scenario.superStarMultiplier
                        : 1
                    : undefined,
            };
        },
    };
}
