var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center top-reel-selector-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-center top-reel-selector top-reel-selector-position",
          },
          [
            _c("label", { attrs: { for: "topReelReelStripPosition" } }, [
              _vm._v(" Position "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.topReelReelStripPosition,
                    expression: "topReelReelStripPosition",
                  },
                ],
                attrs: { id: "topReelReelStripPosition" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.topReelReelStripPosition = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateTopReelReelStripPosition(
                        _vm.topReelReelStripPosition
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0", selected: "selected" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(" "),
                _vm._l(67, function (n) {
                  return _c("option", [_vm._v(_vm._s(n) + " ")])
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        [_c("topreel", { attrs: { index: 0, step: _vm.step } })],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c("div", { staticClass: "flex-center scatters-selector" }, [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Number of Scatter prizes: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scattersNumber,
                  expression: "scattersNumber",
                },
              ],
              attrs: { id: "scatters_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.scattersNumber = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBonusGameScattersNumber(_vm.scattersNumber)
                  },
                ],
              },
            },
            _vm._l(28, function (n) {
              return _c("option", [_vm._v(_vm._s(n) + " ")])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "selected-scatters" },
          _vm._l(parseInt(_vm.scattersNumber), function (n) {
            return _c("selectScatterMultiplier", {
              key: n,
              attrs: {
                index: n,
                scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center gamble-selector-wrapper" }, [
        _c("div", { staticClass: "flex-center gamble-selector" }, [
          _c("label", { attrs: { for: "gamble_value" } }, [
            _vm._v("Double Cross Feature outcome: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dcfOutcomeSelected,
                  expression: "dcfOutcomeSelected",
                },
              ],
              attrs: { id: "gamble_value" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.dcfOutcomeSelected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateGamble(_vm.dcfOutcomeSelected)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [
                _vm._v("Win chances as designed"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("Always win")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("Always lose")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3" } }, [_vm._v("x0")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("x0.5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("x1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("x1.5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("x2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("x3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "9" } }, [_vm._v("x4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "10" } }, [_vm._v("x5")]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Top Reel: ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels: ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }