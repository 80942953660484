/* tslint:disable:variable-name */
import State from "../../../state";
import { rowsFromIndexes } from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],

        // Must implement
        createDefaultStep: (order = 0, variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.wildMermaidsFeature = false;
            step.mysterySymbol = "HV1";

            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        // Get the Base Game Step
        getBaseStep: (isWildMermaid: boolean, mysterySymbol: string) => {
            const step = State.state.createDefaultStep();
            step.wildMermaidsFeature = isWildMermaid;
            step.mysterySymbol = mysterySymbol;

            return step;
        },

        // Gets the Frequent Wild Free Game Step
        getFrequentWildFGStep: (isWildMermaid: boolean, mysterySymbol: string) => {
            const step = State.state.createStep([46, 33, 35, 52, 8], false, 0, "", "Frequent Wild FG");
            step.wildMermaidsFeature = isWildMermaid;
            step.mysterySymbol = mysterySymbol;

            return step;
        },

        // Gets the Golden Mermaid Free Game Step
        getGoldenMermaidFGStep: (isWildMermaid: boolean, mysterySymbol: string) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Golden Mermaid FG");
            step.wildMermaidsFeature = isWildMermaid;
            step.mysterySymbol = mysterySymbol;

            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = step.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
            const noOfReels = 5;
            const noOfRows = 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        },
    };
}
