// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol[data-v-06f27776] {
    flex-direction: column;
    border-radius: 10px;
    background: #707070;
    box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;
}
.current-name[data-v-06f27776] {
    margin: 0;
    padding: 0;
}
.original-name[data-v-06f27776] {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    color: #dddddd;
}
.scatter[data-v-06f27776] {
    border: 1px solid red;
}
.wildScat[data-v-06f27776] {
    border: 1px solid greenyellow;
}
.winCell[data-v-06f27776] {
    border: 3px solid blue
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw099/slot-window/reel-symbol/reel-symbol.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,kEAAkE;AACtE;AACA;IACI,SAAS;IACT,UAAU;AACd;AACA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,6BAA6B;AACjC;AACA;IACI;AACJ","sourcesContent":[".symbol[data-v-06f27776] {\n    flex-direction: column;\n    border-radius: 10px;\n    background: #707070;\n    box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;\n}\n.current-name[data-v-06f27776] {\n    margin: 0;\n    padding: 0;\n}\n.original-name[data-v-06f27776] {\n    margin: 0;\n    padding: 0;\n    font-size: 0.8em;\n    color: #dddddd;\n}\n.scatter[data-v-06f27776] {\n    border: 1px solid red;\n}\n.wildScat[data-v-06f27776] {\n    border: 1px solid greenyellow;\n}\n.winCell[data-v-06f27776] {\n    border: 3px solid blue\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
