/* tslint:disable:variable-name */
import State from "../../../state";
import {
    trace,
    TODO,
    wrapIndex,
    range,
    randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["94"],

        createStep: (
            reelStripPositions,
            isFeature = false,
            order = 0,
            variation = "",
            name = "Base"
        ) => {
            const step: any = State.state.addExtraDataToStep(
                createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name)
            );
            // step.field0 = JSON.stringify([]);
            return step;
        },
        // Must implement
        createDefaultStep: (order = 0, variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () =>
            State.state.standalone
                ? init.modelDefinition
                : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        // example on creating custom indexes
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => {
            const step = State.state.createStep([26, 26, 0, 26, 0], false, order, "", "F-Trigger");
            step.field0 = "wheel";
            return step;
        },
        getFeatureStep: (order = 0) =>
            State.state.createStep([6, 4, 7, 0, 9], true, order, "", "F-Step"),
        addPickStep: (order = 0) => {
            const step = State.state.createStep([25, 0, 0, 0, 0], false, order, "", "Pick Step");
            step.isFeature = true,
            step.field0 = "wheel";
            step.field1 = ["1", "1", "1"];
            return step;
        },
        getFeatureReTriggerStep: (order = 0) => {
            const step = State.state.createStep([4, 4, 0, 0, 8], false, order, "", "F-Retrigger");
            return step;
        },
    };
}
