/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes, trace } from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.case = 0;
            step.factor = false;
            step.internal_name = "Base";
            return step;
        },
        getBaseStep: (stepTitle, caseNumber, factor) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", stepTitle);
            step.case = Number(caseNumber);
            step.internal_name = stepTitle;
            step.factor = factor;
            return step;
        },
        getFreeGameStep: (stepTitle, caseNumber, factor) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", stepTitle);
            step.case = Number(caseNumber);
            step.internal_name = stepTitle;
            step.factor = factor;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        /***
         * Returns modified array according to the range of various cases of the game.
         * @param key
         */
        getRangesFromKey: (key) => {
            const keyArr = key.split(":");
            for (const index in keyArr) {
                if (~keyArr[index].search("-")) {
                    keyArr[index] = keyArr[index].split("-");
                }
            }
            return keyArr;
        },

        /***
         * Function finds whether key is in an applicable range or not.
         * @param min Minimum value of range
         * @param max Maximum value of range
         * @param key Key to be checked.
         */
        inRange: (min, max, key) => {
            min = Number(min);
            max = Number(max);
            key = Number(key);
            return key >= min && key <= max;
        },

        /***
         * A wrapper to the inRange function to make it compatible with the current singularRangeArr length.
         * @param singularRangeArr
         * @param rangeNumber
         */
        isInSingleArrayRange: (singularRangeArr, rangeNumber) => {
            return singularRangeArr.length > 1 ? State.state.inRange(singularRangeArr[0], singularRangeArr[1], rangeNumber) :
                State.state.inRange(singularRangeArr[0], singularRangeArr[0], rangeNumber);
        },

        /***
         * Handles the entire rangeArr as per the union types to find the specific range of cases.
         * @param rangeArr
         * @param rangeNumber
         */
        isInRange: (rangeArr, rangeNumber) => {
            if (rangeArr[0].constructor === Array) {
                for (const item of rangeArr) {
                    if (State.state.isInSingleArrayRange(item, rangeNumber)) {
                        return true;
                    }
                }
                return false;
            }
            return State.state.isInSingleArrayRange(rangeArr, rangeNumber);
        },

        /**
         * Get reel strips according to the cases present in the game.
         */
        getReelStrips: (caseNumber) => {
            trace(State.state.getModelDefinition());

            if (caseNumber.toString() === "0") {
                return State.state.getModelDefinition().reels;
            }

            const featureReels = State.state.getModelDefinition().caseReels;

            for (const key in featureReels) {
                if (!featureReels.hasOwnProperty(key)) {
                    continue;
                }

                const caseRange = State.state.getRangesFromKey(key);

                if (State.state.isInRange(caseRange, caseNumber.toString())) {
                    if (caseRange.toString() == '1' || caseRange.toString() == '8,11') {
                        let reel = featureReels[key]["pre"].map(arr => arr.slice());
                        let postReel = featureReels[key]["post"];

                        for (let i = 0; i < reel.length; i++) {
                            for (let j = 0; j < reel[i].length; j++) {
                                if (reel[i][j] != postReel[i][j]) {
                                    reel[i][j] = `${reel[i][j]} (${postReel[i][j]})`;
                                }
                            }
                        }

                        return reel;
                    }
                    else {
                        return featureReels[key]["pre"];
                    }
                }
            }
        },
        prepareStepForDB: (step) => {
            if (step.internal_name == "FreeGame" && step.case.toString() === "0") {
                step.case = 2;
            }

            const state = State.state;
            const reelStrips = state.getReelStrips(step.case);
            const noOfReels = 5;
            const noOfRows = 3;

            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;

            return step;
        }
    };
}
