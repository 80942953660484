var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c("div", { attrs: { align: "center" } }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.down()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-up",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.up()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-down",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.p0top) + " "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMultiplier(0),
                  expression: "showMultiplier(0)",
                },
              ],
              staticClass: "vgw035-multiplier",
            },
            [_vm._v("[x" + _vm._s(_vm.multipliers[0]) + "]")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.p1top) + " "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMultiplier(1),
                  expression: "showMultiplier(1)",
                },
              ],
              staticClass: "vgw035-multiplier",
            },
            [_vm._v("[x" + _vm._s(_vm.multipliers[1]) + "]")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reels, function (r, i) {
          return _c("div", { staticClass: "symbol on" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(r)),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMultiplier(i + 2),
                      expression: "showMultiplier(i+2)",
                    },
                  ],
                  staticClass: "vgw035-multiplier",
                },
                [_vm._v("[x" + _vm._s(_vm.multipliers[i + 2]) + "]")]
              ),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.p0bottom) + " "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMultiplier(5),
                  expression: "showMultiplier(5)",
                },
              ],
              staticClass: "vgw035-multiplier",
            },
            [_vm._v("[x" + _vm._s(_vm.multipliers[5]) + "]")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.p1bottom) + " "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMultiplier(6),
                  expression: "showMultiplier(6)",
                },
              ],
              staticClass: "vgw035-multiplier",
            },
            [_vm._v("[x" + _vm._s(_vm.multipliers[6]) + "]")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }