var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "candy-cane-selector" }, [
    _c("h2", [_vm._v("Candy Cane Control Panel:")]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "candy-cane-picks" } },
        [_vm._v("Number of Candy Canes:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.numCandyCanes,
              expression: "numCandyCanes",
            },
          ],
          attrs: { id: "candy-cane-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.numCandyCanes = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { domProps: { value: 3 } }, [_vm._v("3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 4 } }, [_vm._v("4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 5 } }, [_vm._v("5")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "candy-cane-picks" } },
        [_vm._v("Select Replacement Symbol:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.candyCaneReplacementSymbol,
              expression: "step.candyCaneReplacementSymbol",
            },
          ],
          attrs: { id: "candy-cane-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "candyCaneReplacementSymbol",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: "WILD" } }, [_vm._v("WILD")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "PIC1" } }, [_vm._v("PIC1")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "PIC2" } }, [_vm._v("PIC2")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "PIC3" } }, [_vm._v("PIC3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "PIC4" } }, [_vm._v("PIC4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "PIC5" } }, [_vm._v("PIC5")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "A" } }, [_vm._v("A")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "K" } }, [_vm._v("K")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "Q" } }, [_vm._v("Q")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "J" } }, [_vm._v("J")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "10" } }, [_vm._v("10")]),
          _vm._v(" "),
          _c("option", { domProps: { value: "9" } }, [_vm._v("9")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.step.candyCanePlacements, function (candyCane, index) {
        return index < _vm.numCandyCanes
          ? _c("card", { key: index, attrs: { details: candyCane } })
          : _vm._e()
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }