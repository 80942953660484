import { filterSlotWindow } from "@vgw/gdk-math-model-tools";
import type {
  CharacterWildsWithPositions,
  ReelSpinFeatures,
} from "../../reel-spin-features-response";

export function updateSlotWindowSymbolsWithFeatures(args: {
  reelSpinFeatures?: ReelSpinFeatures;
  slotWindowAfterFeaturesApplied: string[][];
  slotWindowBeforeAnyReelSpinFeaturesApplied: string[][];
}): { reelSpinFeatures?: ReelSpinFeatures; slotWindow: string[][] } {
  const {
    reelSpinFeatures,
    slotWindowBeforeAnyReelSpinFeaturesApplied,
    slotWindowAfterFeaturesApplied,
  } = args;

  const positionsBySymbol: Record<string, [number, number][]> = [
    "PIC1",
    "PIC2",
    "PIC3",
  ].reduce(
    (mapping: Record<string, [number, number][]>, characterWildSymbol) => {
      mapping[characterWildSymbol] = filterSlotWindow(
        slotWindowBeforeAnyReelSpinFeaturesApplied,
        (symbol) => symbol === characterWildSymbol
      );
      return mapping;
    },
    {}
  );

  if (reelSpinFeatures?.beforeReelsStop && !reelSpinFeatures?.afterReelsStop) {
    // update main slotWindow
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: reelSpinFeatures?.beforeReelsStop.characterWilds,
      positionsBySymbol,
    });
  } else if (
    reelSpinFeatures?.afterReelsStop &&
    !reelSpinFeatures?.beforeReelsStop
  ) {
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: reelSpinFeatures?.afterReelsStop?.characterWilds,
      positionsBySymbol,
    });
    updateSlotWindowWithCharacterWilds({
      slotWindow:
        reelSpinFeatures?.afterReelsStop?.intermediateResults?.slotWindow,
      characterWildSymbols: reelSpinFeatures?.afterReelsStop?.characterWilds,
      positionsBySymbol,
    });
  } else if (
    reelSpinFeatures?.beforeReelsStop &&
    reelSpinFeatures?.afterReelsStop
  ) {
    const beforePics =
      reelSpinFeatures?.beforeReelsStop?.characterWilds?.pics ?? [];
    const afterPics =
      reelSpinFeatures?.afterReelsStop?.characterWilds?.pics ?? [];
    const characterWildsCombinedOfBeforeAndAfterReelStop: CharacterWildsWithPositions =
      {
        pics: [...beforePics, ...afterPics],
        positionToWilds: [
          ...(reelSpinFeatures?.beforeReelsStop?.characterWilds
            ?.positionToWilds ?? []),
          ...(reelSpinFeatures?.afterReelsStop?.characterWilds
            ?.positionToWilds ?? []),
        ],
      };

    // update intermittent slot window with character wilds triggered in before reel stop
    updateSlotWindowWithCharacterWilds({
      slotWindow:
        reelSpinFeatures?.afterReelsStop?.intermediateResults?.slotWindow,
      characterWildSymbols: reelSpinFeatures?.beforeReelsStop?.characterWilds,
      positionsBySymbol,
    });

    // update the main slot window with character wilds triggered in both before and after reel stop
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: characterWildsCombinedOfBeforeAndAfterReelStop,
      positionsBySymbol,
    });
  }

  return {
    reelSpinFeatures: reelSpinFeatures,
    slotWindow: slotWindowAfterFeaturesApplied,
  };
}

function updateSlotWindowWithCharacterWilds(args: {
  slotWindow: string[][];
  positionsBySymbol: Record<string, [number, number][]>;
  characterWildSymbols?: CharacterWildsWithPositions;
}) {
  const { slotWindow, characterWildSymbols } = args;
  for (const symbolPosition of characterWildSymbols?.positionToWilds ?? []) {
    slotWindow[symbolPosition.reelIndex][
      symbolPosition.rowIndex
    ] = `${symbolPosition.symbol}_W`;
  }
}
