// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-type[data-v-6fe169f6] {
  color: #28282B;
  font-size: 18px; 
  font-weight: bold;
  padding-right: 20px;
}
.card-colour[data-v-6fe169f6] {
  color: #28282B;
  font-size: 18px; 
  font-weight: bold; 
  padding-right: 20px;
}
.jake-is-smart-man[data-v-6fe169f6]{
  background-color: #d0d0d0; 
  border: 1px solid #bbb;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/reel-spin-feature-controls/candy-cane-controls/card/card.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,qCAAqC;AACvC","sourcesContent":[".card-type[data-v-6fe169f6] {\n  color: #28282B;\n  font-size: 18px; \n  font-weight: bold;\n  padding-right: 20px;\n}\n.card-colour[data-v-6fe169f6] {\n  color: #28282B;\n  font-size: 18px; \n  font-weight: bold; \n  padding-right: 20px;\n}\n.jake-is-smart-man[data-v-6fe169f6]{\n  background-color: #d0d0d0; \n  border: 1px solid #bbb;\n  padding: 15px;\n  margin-bottom: 10px;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
