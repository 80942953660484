import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import { trace, TODO, range } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public cases = [];
    public caseNumber = 0;
    public reelsNo = 5;
    public factor = "false";
    public isBonusAllowed = true;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.caseNumber = step.json.case;
        this.setFactor(step.json.factor);

        this.isBonusAllowed = step.json.internal_name != "FreeGame";
    }
    public mounted() {
        trace("reels mounted");
        this.cases = range(15);
    }

    /**
     * Gets the Base Game Step.
     */
    public getBaseStep() {
        const state = State.state;
        const stepTitle = "Base";
        bus.$emit(events.ADD_STEP, state.getBaseStep(stepTitle, this.caseNumber, this.factor == "true"));
    }

    /**
     * Gets the Free Game Step.
     */
    public getFreeGameStep() {
        const state = State.state;
        const stepTitle = "FreeGame";
        bus.$emit(events.ADD_STEP, state.getFreeGameStep(stepTitle, this.caseNumber, this.factor == "true"));
    }

    /**
     * Gets the Bonus Step.
     */
    public setBonusReelStrip() {
        this.step.json.reelStripPositions = [34, 35, 15, 0, 0];
        this.step.json.case = 0;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Called whenever the user updates the case number.
     */
    public onCaseChange() {
        trace(this.caseNumber);
        this.step.json.case = Number(this.caseNumber);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Called whenever user updates the factor value.
     */
    public onUpdateFactor() {
        this.step.json.factor = this.factor == "true";
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Set the factor value by converting from boolean to string.
     * @param value Value to be set in boolean.
     */
    public setFactor(value: boolean) {
        this.factor = String(value);
    }
}