/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],

    "modelDefinition" : {
        "modelId": "tf123",

        "scatterSymbol": "scat",
        "wildSymbol": "wild",
    
        "freeSpinTrigger": "scat",
        "dynamiteSymbol": "pica",
        "countToTriggerFreeSpin": 3,
    
        "metaStageSteps":5,
        "metaStageMax":2,
    
        "reelsLayout": [3, 3, 3, 3, 3],
        "maxFreeSpinReTriggerCount": 9,
    
        "reels": [
            ["q","10","scat","q","10","pica","q","10","pica","q","10","pica","q","10","pice","a","j","picd","q","pice","10","picb","9","picc","a","picd","q","k","pice"],
            ["picc","j","9","picb","j","k","pica","j","k","pice","q","9","scat","j","9","picb","a","9","picc","a","k","picd","a","10","picb","a","j"],
            ["a","picc","j","wild","10","picb","k","wild","k","picb","q","wild","k","9","scat","10","k","pica","pice","k","pica","9","a","pica","q","picb","picc","9","pica","picd","j"],
            ["picb","9","wild","wild","wild","9","wild","wild","wild","10","wild","wild","k","wild","10","wild","k","j","scat","k","j","pica","pice","10","picd","k","picb","picc","q","picd","a","picb","k","picd","picc","a"],
            ["j","wild","wild","wild","j","wild","wild","wild","j","wild","wild","10","wild","wild","10","wild","9","wild","q","9","scat","q","9","pica","k","a","picb","k","a","picc","k","a","picc","picd","10","a","pice"]
        ],
    
        "featureReels": [
            ["q","10","scat","q","10","pica","q","10","pica","q","10","pica","q","scat","10","pice","a","scat","j","picd","q","pice","10","picb","9","picc","a","picd","q","scat","k","pice"],
            ["picc","j","scat","9","picb","j","k","pica","j","k","pice","q","9","scat","j","9","picb","a","scat","9","picc","a","k","picd","a","scat","10","picb","a","j"],
            ["a","picc","j","wild","10","picb","k","wild","wild","k","wild","wild","picb","q","wild","k","9","scat","10","k","pica","pice","k","pica","9","scat","a","pica","q","picb","scat","picc","9","pica","picd","j","scat"],
            ["wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild"],
            ["wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild"],
        ],
    },
    
}
