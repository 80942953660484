export const modelDefinition = {
    modelId: "dealOrNoDeal",
    gameIds: ["dealOrNoDeal"],
    reelsLayout: [3, 3, 3, 3, 3],

    // prettier-ignore
    reels: [
            ["PIC3", "T", "PIC4", "K", "SCAT", "N", "PIC4", "K", "PIC3", "A", "PIC1", "PIC1", "PIC1", "J", "N", "A", "J", "T", "PIC3", "PIC3", "J", "PIC1", "PIC1", "A", "J", "N", "K", "J", "PIC4", "K", "N", "A", "T", "PIC4", "J", "PIC3", "T", "A", "SCAT", "PIC4", "PIC1", "T", "PIC2", "PIC3", "PIC4", "T", "PIC1", "Q", "PIC4", "A", "PIC1", "PIC3", "A", "T", "CASE", "CASE", "PIC3", "J", "T", "PIC3", "A", "PIC4", "PIC2", "T", "PIC4", "Q", "CASE", "CASE", "T", "PIC2", "PIC4", "Q", "SCAT", "N", "PIC2", "T", "PIC3", "A", "PIC1", "PIC1", "Q", "N", "K", "PIC4", "N", "Q", "PIC4", "K", "CASE", "CASE", "PIC3", "A", "K", "PIC2", "Q", "N", "PIC4", "Q", "N", "PIC3", "PIC4", "T", "N", "J", "Q"],
            ["T", "PIC3", "PIC2", "K", "N", "A", "PIC4", "N", "A", "K", "WILD", "WILD", "WILD", "K", "PIC2", "A", "N", "PIC2", "A", "K", "PIC2", "J", "A", "Q", "PIC3", "K", "A", "WILD", "WILD", "WILD", "PIC2", "J", "PIC4", "PIC2", "K", "PIC1", "Q", "N", "SCAT", "Q", "PIC3", "J", "PIC4", "PIC3", "A", "PIC1", "N", "K", "Q", "PIC2", "PIC3", "A", "PIC2", "K", "CASE", "CASE", "CASE", "K", "N", "PIC2", "K", "WILD", "WILD", "WILD", "K", "PIC3", "T", "PIC2", "Q", "PIC3", "J", "N", "SCAT", "J", "N", "K", "J", "PIC1", "PIC1", "PIC1", "J", "N", "PIC3", "PIC2", "K", "Q", "PIC2", "N", "CASE", "CASE", "CASE", "Q", "PIC1", "PIC4", "K", "WILD", "WILD", "WILD", "Q", "J", "PIC1", "PIC4", "K", "PIC1", "PIC4"],
            ["A", "T", "PIC2", "PIC4", "SCAT", "Q", "PIC4", "A", "PIC3", "N", "WILD", "WILD", "WILD", "Q", "PIC2", "N", "A", "PIC2", "T", "PIC3", "J", "Q", "PIC4", "K", "Q", "PIC3", "PIC4", "PIC2", "WILD", "WILD", "WILD", "K", "PIC3", "Q", "PIC1", "PIC3", "PIC4", "T", "SCAT", "A", "PIC4", "K", "A", "N", "PIC1", "PIC1", "PIC1", "J", "K", "PIC2", "PIC3", "K", "T", "J", "CASE", "CASE", "CASE", "J", "PIC2", "Q", "T", "PIC2", "WILD", "WILD", "WILD", "K", "PIC2", "T", "PIC1", "Q", "T", "K", "SCAT", "T", "PIC3", "J", "PIC1", "PIC2", "K", "PIC3", "PIC1", "J", "A", "PIC2", "WILD", "WILD", "WILD", "J", "A", "Q", "PIC4", "CASE", "CASE", "CASE", "PIC2", "K", "PIC3", "A", "PIC1", "PIC3", "T", "N", "J", "PIC3", "N" ],
            ["N", "PIC4", "PIC3", "N", "SCAT", "PIC3", "J", "PIC4", "PIC3", "A", "PIC1", "PIC1", "PIC1", "K", "N", "Q", "A", "PIC2", "PIC2", "J", "N", "PIC4", "PIC3", "Q", "PIC4", "A", "K", "PIC2", "J", "WILD", "WILD", "WILD", "T", "PIC4", "PIC2", "K", "T", "N", "SCAT", "PIC3", "PIC1", "PIC4", "SCAT", "PIC3", "K", "PIC1", "PIC1", "K", "T", "PIC1", "PIC2", "PIC3", "PIC3", "J", "CASE", "CASE", "CASE", "PIC3", "T", "A", "Q", "PIC2", "J", "J", "T", "PIC3", "WILD", "WILD", "WILD", "PIC1", "PIC2", "N", "SCAT", "K", "PIC3", "PIC4", "PIC2", "A", "Q", "WILD", "WILD", "WILD", "N", "PIC3", "Q", "PIC2", "PIC2", "J", "CASE", "CASE", "CASE", "PIC3", "PIC4", "A", "Q", "PIC2", "K", "WILD", "WILD", "WILD", "N", "PIC3", "Q", "PIC1", "A",],
            ["K", "PIC4", "A", "SCAT", "PIC4", "A", "Q", "J", "PIC4", "A", "J", "K", "T", "Q", "A", "PIC2", "PIC2", "T", "PIC3", "Q", "PIC4", "PIC3", "J", "PIC4", "Q", "PIC1", "PIC1", "PIC1", "A", "K", "PIC4", "T", "PIC2", "PIC2", "J", "PIC4", "T", "J", "SCAT", "PIC3", "PIC1", "Q", "PIC4", "K", "Q", "PIC4", "K", "PIC1", "J", "PIC2", "PIC2", "N", "A", "PIC2", "CASE", "CASE", "CASE", "PIC3", "PIC2", "N", "K", "PIC1", "PIC1", "K", "SCAT", "J", "PIC3", "PIC4", "PIC1", "PIC3", "T", "J", "SCAT", "PIC3", "PIC4", "PIC2", "PIC3", "N", "PIC1", "PIC3", "K", "N", "A", "PIC2", "PIC2", "K", "A", "PIC3", "CASE", "CASE", "CASE", "Q", "PIC3", "A", "PIC1", "PIC2", "N", "K", "PIC3", "PIC3", "PIC1", "A", "PIC2", "Q", "PIC1"]
    ],
  };