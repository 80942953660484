/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf121",

        reelsLayout: [3, 3, 3, 3, 3],
        scatterSymbol: "S",
        freeSpinTrigger: "S",
        wildSymbol: "W",

        coinSizeMultiplier: 20,
        totalSymbolNum: 15,
        bonusSymbol: "F",

        // prettier-ignore
        reels: [
            ['O','O','D','B','D','B','Y','S','B','N','C','N','N','O','Q','A','N','Q','Z','C','C','B','N','Z','O','B','Z','Q','Q','C','D','S','P','P','C','C','B','C','B','D','B','B','A','D','N','Q','A','O','N','P','Q','B','D','C','O','B','D','P','O','B','B','O','A','O','O','P','D','Y','Z','S','P','C','B','O','B','S','O','C','D','Q','D','S','Q','O','O','Q','N','Q','P','O','D','B','S','Z','N','Q','Q','O','D','N','D','O','N','Q','D','D','C','B','P','N','B','A','C','D','B','D','A','B','D','A','D','B','Y','C','B','N','O','D','Q','D','B','B','D','C','A','Q','S','P','Y','A','A','B','C','B','O','A','B','B','D','N','S','C','A','Q','D','A','A','O','O','B','P','A','Q','Q','O','O','D','S','Q','A','P','A','O','D','A','P','B','Q','Y','A','A','O','D','P','Z','Q','C','N','P','D','O','Q','D','A','C','B','B','B','A','D'],
            ['N','O','A','A','Y','D','C','D','A','D','Q','Q','C','B','P','W','B','B','Y','C','D','C','D','P','D','C','D','C','Y','P','O','A','W','Y','A','O','Q','P','A','Q','N','Y','P','B','W','B','C','C','D','Q','D','C','A','N','B','P','D','B','W','D','C','Q','C','A','Q','O','D','Y','N','B','B','N','C','Y','A','A','Q','A','A','A','C','C','D','D','A','Q','C','D','Y','A','Y','B','Z','Y','N','D','P','C','Y','P','C','N','P','Q','Y','W','O','B','C','C','C','A','D','B','O','A','A','B','O','A','Y','D','W','A','A','Q','B','C','O','W','P','P','N','Y','N','O','W','B','Q','N','P','Q','D','D','O','B','Q','P','D','N','A','Q','C','N','D','C','A','P','A','C','Z','W','N','B','Y','C','Q','P','B','P','A','C','Q','P','C','Q','C','Z','A','Y','P','D','C','D','A','D','O','A','D','P','Q','W','A','D','P','N','D','Q','D','A'],
            ['D','P','B','S','Q','D','N','Q','D','O','D','D','A','S','B','D','N','N','Q','D','N','B','P','S','P','P','Y','B','B','C','C','C','D','A','A','C','S','Q','C','C','C','Y','C','D','Q','D','A','S','B','B','Y','O','S','O','Y','Q','O','S','B','A','C','A','P','C','O','D','B','A','A','P','A','B','N','S','P','B','N','D','S','P','A','D','S','P','D','C','Q','A','W','C','N','P','D','A','C','Y','B','A','Q','O','D','Q','Q','C','C','D','P','A','A','Q','N','D','O','C','O','B','N','B','A','A','D','A','P','Q','B','A','P','S','P','A','C','S','A','C','C','C','A','Z','P','D','P','O','D','D','B','B','Q','Q','C','C','D','B','N','B','N','W','C','D','W','B','C','B','C','A','P','D','Q','Z','N','O','A','O','C','Y','C','C','D','C','N','B','Q','D','Q','Y','P','S','A','O','A','N','S','N','N','N','B','P','O','A','B','Y'],
            ['W','O','Y','N','O','A','W','A','B','D','Y','C','O','B','B','W','Y','C','D','Q','C','A','Q','A','Y','B','C','Y','Y','A','B','D','Q','Q','O','Q','B','W','A','A','C','Z','A','Z','A','C','Z','Z','N','B','Z','N','D','Z','Q','B','A','A','Z','P','D','Q','Q','N','Q','A','D','O','Z','Q','Z','D','C','Q','A','P','A','W','P','C','A','B','Y','O','B','A','D','A','Z','C','Q','C','Z','N','A','Q','O','C','D','N','C','Z','Y','Z','N','B','Z','D','Z','B','Z','Y','Q','B','N','B','C','D','A','N','P','B','C','Y','P','Q','Z','N','C','B','A','Z','B','Q','Y','Y','A','A','Q','B','P','Q','C','P','B','C','Z','O','C','A','P','C','N','A','C','Q','B','B','Q','A','C','C','C','Y','O','A','Q','N','B','D','A','Z','A','N','Y','N','D','Z','C','N','C','C','A','Q','B','W','A','D','N','N','N','P','P','W','C','N','N','P','A','Z'],
            ['A','Z','Q','D','D','O','N','C','O','N','A','N','Z','D','Q','D','D','C','N','A','B','O','P','Y','P','P','Z','Q','Q','B','A','C','A','Z','N','O','A','Q','Z','Y','D','Q','A','C','W','A','B','A','A','Q','Y','C','A','D','W','Y','A','B','Z','B','B','D','D','A','N','C','C','Y','S','P','A','W','D','D','D','A','S','Q','D','B','W','Z','N','B','S','P','D','A','O','A','C','Y','B','D','B','O','N','D','A','A','O','Z','Z','C','S','D','A','Y','P','A','Y','Z','B','Y','A','W','O','A','A','S','O','B','B','B','B','A','P','B','B','P','O','O','Z','N','Z','Z','P','D','Z','O','B','B','P','C','B','Z','N','N','A','N','A','S','Q','Q','D','Y','W','Y','O','P','Q','Y','N','C','O','B','S','C','C','S','A','D','P','S','O','D','O','D','Y','Q','D','A','Y','P','S','N','O','C','P','Q','Y','C','Y','A','Q','A','S','N','Z','C'],
        ],

        // prettier-ignore
        reelsFreespin: [
            ['Y','B','C','B','P','Q','C','B','Q','N','A','D','N','Q','Y','C','B','B','D','Z','C','C','D','P','B','D','D','B','C','Y','A','B','B','B','B','C','C','Q','C','B','Q','A','Q','B','A','A','B','A','D','Z','C','B','Q','C','Z','D','D','B','D','B','A','B','A','C','Z','B','B','B','B','B','Z','A','C','C','Q','A','A','A','D','B','B','A','B','C','C','B','N','B','D','B','B','C','D','B','P','A','Z','B','C','B','Z','C','A','A','P','A','B','P','A','C','B','C','A','Z','B','A','P','P','D','B','A','B','B','A','B','C','B','D','B','B','D','B','Y','D','P','B','Q','A','C','Q','B','D','B','N','Z','N','D','A','D','Q','C','Z','Z','P','N','Z','Z','B','C','D','B','D','Z','Q','B','Z','P','D','A','A','A','A','P','B','Z','Z','B','A','Z','B','A','B','Z','Q','B','B','C','P','B','D','P','B','B','B','A','B','C','A','Z','C'],
            ['D','C','B','Y','B','Y','B','Q','D','N','P','Y','P','A','Y','O','C','Z','B','Z','Y','Z','Z','D','A','Z','N','Z','C','C','Y','O','D','C','Y','P','N','N','C','C','Y','P','A','D','C','D','N','C','O','C','Y','Q','D','C','D','Y','D','Y','Y','A','Q','O','A','P','P','N','N','P','A','P','P','Y','B','O','C','C','B','C','B','B','Y','Q','Y','A','Q','N','A','B','N','Z','N','P','C','N','A','A','O','N','N','C','B','A','B','B','N','B','C','Y','Y','D','Z','C','A','Q','C','A','B','O','C','A','Y','Y','D','A','P','P','C','C','C','Y','C','Q','Y','B','D','C','Y','C','B','Y','B','Y','P','C','C','C','P','C','D','C','Q','O','C','O','C','A','A','P','N','Y','Q','B','Y','Z','O','N','N','Q','O','Z','P','D','Q','A','Y','C','O','P','P','D','Z','Y','D','D','N','P','P','O','B','Y','Y','B','P','Y','O','N','A','D','Y','D'],
            ['B','C','C','A','Z','A','D','B','A','A','D','C','N','B','N','N','D','Z','P','A','Z','D','N','A','N','N','N','N','Z','N','N','Y','C','N','P','D','N','N','F','A','A','B','D','D','Y','D','N','N','C','N','O','C','C','C','C','F','Z','C','F','Q','N','C','Q','D','A','N','O','B','O','B','A','Z','A','O','C','N','C','N','D','O','A','D','N','N','N','N','A','Q','O','N','D','N','Z','P','N','N','A','P','Z','A','B','Z','P','A','C','A','O','D','Q','Q','N','F','C','A','W','D','Z','W','D','N','O','C','D','Z','N','Y','D','F','Z','Y','W','O','D','C','W','N','A','O','N','Z','Z','F','Q','A','Z','F','A','Z','Z','O','Z','N','C','D','Z','D','D','Y','O','A','D','A','O','D','N','N','D','N','C','O','B','C','A','Z','C','O','N','D','P','N','A','A','A','A','O','C','Z','P','N','D','D','B','Y','C','P','C','D','Z','N','O'],
            ['N','N','N','P','N','B','P','B','Y','B','B','N','N','C','O','D','P','B','A','P','A','B','A','Q','A','A','A','N','B','A','Q','N','B','B','A','Z','A','Z','N','O','O','B','P','P','A','N','B','A','B','D','N','A','N','Y','O','O','O','P','Q','Z','B','Q','N','O','B','Y','Z','C','A','A','O','Y','O','B','A','B','P','A','D','B','P','N','A','O','N','B','O','N','A','P','B','B','N','B','C','P','B','B','A','B','N','Q','A','Z','Q','C','Q','O','D','Q','W','O','C','C','B','O','N','O','B','P','P','A','P','O','N','P','C','Q','P','Y','P','Q','Q','Q','A','C','P','C','Y','O','O','A','O','Q','N','P','Q','B','B','B','Y','Z','N','O','C','C','N','N','Z','Y','N','D','C','A','B','W','B','A','C','O','B','N','O','P','A','P','O','B','Y','A','A','O','A','B','B','D','Y','B','O','O','Q','O','Y','N','Z','A','C','Z','A','N'],
            ['B','O','B','D','D','Z','B','Q','O','O','Q','A','C','D','D','D','Z','O','C','C','B','D','Z','Y','Q','Q','Q','Q','N','C','A','B','B','B','Q','N','P','P','A','C','Y','Q','D','P','Q','P','Q','Q','P','Z','Q','O','D','B','Y','Q','Q','Q','B','C','P','C','D','B','B','B','P','A','Q','O','O','D','B','B','O','Q','O','B','Y','C','B','Y','B','Q','B','B','D','O','B','N','D','D','D','D','D','D','C','A','Z','D','O','O','Y','D','B','O','B','Q','C','C','A','P','D','C','B','B','O','O','C','B','P','P','Q','D','B','D','N','N','D','Q','O','Y','B','C','Y','B','Q','Z','D','P','Z','D','D','P','D','Q','Z','D','C','Q','O','B','P','D','Q','O','Q','D','N','C','Z','B','O','Q','D','C','Q','N','Y','B','P','O','Y','B','A','O','Y','N','D','C','B','N','A','O','O','Q','O','N','P','Z','D','B','D','N','O','N','B','Q','D','B'],
        ],
    },
};
