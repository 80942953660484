/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["93"],

        // Must implement
        createDefaultStep: (order = 0, variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: (order = 0) => {
            const step = State.state.createDefaultStep();
            step.name = "Step";
            return step;
        },
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([0, 22, 0, 45, 0], false, order, "", "F-Trigger") ,
        getFeatureStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0], true, order, "", "F-Step") ,
    };
}
