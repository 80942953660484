var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.expandingWild[_vm.index - 1],
            expression: "expandingWild[index - 1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.expandingWild,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updateExpandingWild(_vm.index - 1)
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "false" } }, [_vm._v("false")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "true" } }, [_vm._v("true")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }