/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => State.state.createStep([0, 16, 17, 0, 0], false, 0, "", "F-Trigger") ,
        getFeatureStep: () => State.state.createStep([9, 4, 7, 0, 21], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([0, 16, 17, 0, 0], true, 0, "", "F-Step") ,
    };
}
