import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";
import ExpandingSymbol from "./components/expandingSymbol.vue";

@Component({
    components: {
        reel: Reel,
        selectScatterMultiplier: SelectScatterMultiplier,
        expandingSymbol: ExpandingSymbol,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isRespin = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public reelsNo = 5;

    public scattersSelectedMultipliers = new Array(15).fill("X");
    public reelOption = 0;
    public expandingSymbols = new Array();

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ?? false;
        this.isRespin = step.json.isRespin ?? false;
        this.reelOption = step.json.reelOption ?? 0;
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
    }

    public addRespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStep());
    }

    public addFreespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinStep());
    }

    public onChangeReelOption() {
        this.step.json.reelOption = this.reelOption;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public resetRespinSymbols() {
        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateRespinScattersMultipliers(event) {
        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }
        this.step.json.scattersSelectedMultipliers[event.target.id - 1] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.scattersSelectedMultipliers == undefined && this.step.json.respinScatterPrizes) {
            this.step.json.scattersSelectedMultipliers = [];

            const reelsNum = State.state.getModelDefinition().reelsLayout.length;
            const symbolsPerReelNum = State.state.getModelDefinition().reelsLayout[0];
            for (let i = 0; i < symbolsPerReelNum; i++) {
                for (let j = 0; j < reelsNum; j++) {
                    this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[j][i]);
                }
            }
        }

        const noWinSymbol = State.state.getModelDefinition().respinNoWinSymbol;
        if (this.step.json.scattersSelectedMultipliers != null) {
            this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
        } else {
            const totalSymbolNum = State.state.getModelDefinition().totalSymbolNum;
            this.scattersSelectedMultipliers = new Array(totalSymbolNum).fill(noWinSymbol);
        }
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateExpandingSymbolCounter(direction) {
        const expandingSymbolsNum = this.expandingSymbols.length;
        if (direction) {
            if (this.step.json.expandingSymbols === undefined) {
                this.step.json.expandingSymbols = [];
            }
            this.step.json.expandingSymbols[expandingSymbolsNum] = "";
            this.expandingSymbols[expandingSymbolsNum] = "";
        } else {
            if (expandingSymbolsNum > 0) {
                this.expandingSymbols.pop();
                this.step.json.expandingSymbols = [];
                this.expandingSymbols.forEach((element) => {
                    this.step.json.expandingSymbols.push(element);
                });
            } else {
                alert("no more to delete");
            }
        }
        this.$forceUpdate();
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateExpandingSymbol(index, event) {
        this.step.json.expandingSymbols[index] = event.target.value;
        this.expandingSymbols[index] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateExpandingSymbols() {
        if (this.step.json.expandingSymbols != null) {
            this.expandingSymbols = this.step.json.expandingSymbols;
        } else {
            this.expandingSymbols = new Array();
        }
    }
}
