export const config = {
  modelId: 'vgw064',
  gameIds: ['reallyReelyRich'],
  scatterSymbol: 'SCAT',
  wildSymbol: 'WILD',
  coinSymbol: 'COIN',
  reelsLayout: [3, 4, 3, 4, 3],
  betMultiplier: 20,
  freeSpinWinMultiplier: 2,

  // prettier-ignore
  reels: [
      ['CLUB', 'DIAMOND', 'PIC4', 'SPADE', 'PIC3', 'HEART', 'HEART', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'PIC5', 'SPADE', 'SPADE', 'PIC2', 'HEART', 'CLUB', 'PIC4', 'DIAMOND', 'CLUB', 'PIC5', 'HEART', 'SPADE', 'PIC4', 'CLUB', 'PIC1', 'HEART', 'SPADE', 'PIC4', 'CLUB', 'DIAMOND', 'PIC3', 'CLUB', 'SPADE', 'PIC5', 'CLUB', 'HEART', 'SCAT', 'CLUB', 'DIAMOND', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'SPADE', 'PIC5', 'CLUB', 'COIN', 'COIN', 'COIN', 'SPADE', 'PIC2', 'HEART', 'HEART', 'PIC3', 'DIAMOND', 'CLUB', 'PIC5', 'SPADE', 'DIAMOND', 'PIC2', 'SPADE', 'SCAT', 'HEART', 'CLUB', 'PIC1', 'SPADE', 'HEART', 'PIC2', 'DIAMOND', 'PIC3', 'HEART', 'PIC4', 'SPADE', 'CLUB', 'PIC3', 'HEART', 'DIAMOND', 'PIC2', 'PIC2', 'SPADE', 'PIC4', 'HEART', 'COIN', 'COIN', 'DIAMOND', 'PIC3', 'SPADE', 'PIC5', 'HEART'],
      ['HEART', 'CLUB', 'PIC3', 'SPADE', 'PIC5', 'HEART', 'HEART', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'SPADE', 'WILD', 'WILD', 'WILD', 'WILD', 'DIAMOND', 'SPADE', 'PIC4', 'HEART', 'SPADE', 'PIC5', 'HEART', 'CLUB', 'PIC2', 'HEART', 'SPADE', 'WILD', 'WILD', 'DIAMOND', 'PIC3', 'SPADE', 'PIC2', 'CLUB', 'PIC5', 'SPADE', 'PIC3', 'CLUB', 'PIC4', 'HEART', 'HEART', 'SCAT', 'CLUB', 'DIAMOND', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'SPADE', 'PIC5', 'CLUB', 'HEART', 'COIN', 'COIN', 'COIN', 'SPADE', 'SPADE', 'PIC2', 'HEART', 'HEART', 'CLUB', 'PIC4', 'DIAMOND', 'DIAMOND', 'WILD', 'HEART', 'HEART', 'PIC2', 'SPADE', 'PIC4'],
      ['CLUB', 'CLUB', 'PIC4', 'SPADE', 'PIC3', 'HEART', 'WILD', 'WILD', 'WILD', 'WILD', 'DIAMOND', 'PIC5', 'SPADE', 'DIAMOND', 'WILD', 'CLUB', 'PIC4', 'DIAMOND', 'PIC5', 'PIC5', 'CLUB', 'PIC1', 'SPADE', 'HEART', 'PIC5', 'SPADE', 'DIAMOND', 'PIC3', 'PIC3', 'SPADE', 'SCAT', 'CLUB', 'PIC4', 'HEART', 'CLUB', 'PIC3', 'HEART', 'SCAT', 'CLUB', 'DIAMOND', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'HEART', 'PIC5', 'CLUB', 'COIN', 'COIN', 'COIN', 'SPADE', 'PIC2', 'HEART', 'SPADE', 'WILD', 'CLUB', 'WILD', 'DIAMOND', 'DIAMOND', 'PIC5', 'PIC2', 'SPADE', 'SCAT', 'HEART', 'CLUB', 'PIC1', 'SPADE', 'DIAMOND', 'PIC4', 'PIC4', 'HEART', 'PIC3', 'CLUB', 'WILD', 'DIAMOND', 'PIC2', 'SPADE', 'PIC4', 'HEART', 'PIC2', 'PIC2', 'DIAMOND', 'WILD', 'HEART', 'COIN', 'COIN', 'PIC3', 'SPADE', 'PIC5', 'HEART'],
      ['CLUB', 'WILD', 'SPADE', 'PIC4', 'CLUB', 'WILD', 'HEART', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'SPADE', 'WILD', 'WILD', 'WILD', 'WILD', 'DIAMOND', 'PIC2', 'SPADE', 'SPADE', 'PIC5', 'PIC5', 'PIC5', 'CLUB', 'CLUB', 'COIN', 'COIN', 'COIN', 'DIAMOND', 'PIC3', 'PIC3', 'PIC3', 'SPADE', 'PIC2', 'CLUB', 'CLUB', 'PIC4', 'PIC4', 'PIC4', 'HEART', 'HEART', 'SCAT', 'CLUB', 'DIAMOND', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'SPADE', 'PIC5', 'CLUB', 'WILD', 'WILD', 'WILD', 'WILD', 'SPADE', 'HEART', 'PIC3', 'DIAMOND', 'SCAT', 'HEART', 'DIAMOND', 'PIC5', 'HEART', 'HEART', 'PIC2', 'PIC2', 'PIC2', 'SPADE', 'PIC4'],
      ['CLUB', 'CLUB', 'PIC4', 'SPADE', 'PIC3', 'HEART', 'WILD', 'WILD', 'WILD', 'WILD', 'DIAMOND', 'PIC5', 'SPADE', 'SPADE', 'WILD', 'CLUB', 'PIC4', 'DIAMOND', 'PIC5', 'PIC5', 'PIC5', 'CLUB', 'PIC1', 'SPADE', 'SPADE', 'DIAMOND', 'DIAMOND', 'PIC3', 'PIC3', 'PIC3', 'SPADE', 'SCAT', 'CLUB', 'CLUB', 'PIC4', 'HEART', 'HEART', 'SCAT', 'CLUB', 'DIAMOND', 'PIC1', 'PIC1', 'PIC1', 'DIAMOND', 'HEART', 'PIC5', 'CLUB', 'COIN', 'COIN', 'COIN', 'SPADE', 'PIC2', 'HEART', 'HEART', 'WILD', 'WILD', 'WILD', 'DIAMOND', 'DIAMOND', 'PIC5', 'PIC2', 'SPADE', 'SCAT', 'HEART', 'CLUB', 'PIC1', 'SPADE', 'DIAMOND', 'PIC4', 'PIC4', 'PIC4', 'HEART', 'HEART', 'PIC3', 'CLUB', 'WILD', 'DIAMOND', 'DIAMOND', 'PIC2', 'PIC2', 'PIC2', 'SPADE', 'PIC4', 'HEART', 'COIN', 'COIN', 'COIN', 'SPADE', 'PIC5', 'HEART']
  ],

  coinType1Values: [100, 500, 1000, 5000, 10000, 50000, 100000],
  coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000],

  coinType1_min_bet: 100,
  coinType1_max_bet: 100000,
  coinType1_spin_max_bet: 2000000,

  coinType4_min_bet: 1,
  coinType4_max_bet: 1000,
  coinType4_spin_max_bet: 20000,
  freeSpinTrigger: 'SCAT',
  freeSpinsAwarded: [30, 20, 15],
  countToTriggerFireshot: 6,
  fireshotRespinCount: 3,
  countToTriggerGrand: 17,

  winTable: [
      {
          symbol: 'PIC1',
          multipliers: [250, 100, 50, 10],
      },
      {
          symbol: 'PIC2',
          multipliers: [200, 80, 30],
      },
      {
          symbol: 'PIC3',
          multipliers: [150, 60, 30],
      },
      {
          symbol: 'PIC4',
          multipliers: [100, 50, 20],
      },
      {
          symbol: 'PIC5',
          multipliers: [100, 50, 20],
      },
      {
          symbol: 'HEART',
          multipliers: [20, 10, 5],
      },
      {
          symbol: 'DIAMOND',
          multipliers: [20, 10, 5],
      },
      {
          symbol: 'CLUB',
          multipliers: [20, 10, 5],
      },
      {
          symbol: 'SPADE',
          multipliers: [20, 10, 5],
      },
      {
          symbol: 'SCAT',
          multipliers: [100, 25, 5],
      },
  ],
  playLines: [],
  coinPrizeWeights: {
    initial: {
        1: 92_000,
        2: 61_000,
        3: 28_000,
        4: 17_000,
        5: 11_000,
        10: 8_000,
        15: 5_200,
        20: 4_000,
        MINI: 5_200,
        MINOR: 1_400,
        MAJOR: 'COIN_WEIGHT' as const,
    },
    respin: {
        1: 200_000,
        2: 130_000,
        3: 61_000,
        4: 41_000,
        5: 25_000,
        10: 16_000,
        15: 10_000,
        20: 6_000,
        MINI: 8_000,
        MINOR: 1_500,
        MAJOR: 'COIN_WEIGHT' as const,
    },
  },  
};
