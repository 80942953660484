import {createDefaultSlotStep} from "./../../../../helpers/helpers";
import State from '../../../state';
import {config} from './config/init';

export function setStateParams() {
    return {
        variations: [],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = '', name= 'Base') => {
            return State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
        },

        // Must implement
        createDefaultStep: (order = 0, variation = '') => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),

        // unique per model
        getModelDefinition: () => State.state.standalone ? config : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTrigger: (order = 0) => State.state.createStep([32, 21, 34, 8, 13], false, order, '', 'F-Trigger'),
    };
}
