export default {
    "success": true,
    "messages": [],
    "gold_balance": 6025950000,
    "sweeps_balance": 413064023,
    "sweeps_deposit_balance": 200001245,
    "sweeps_promo_balance": 6527948,
    "sweeps_win_balance": 206534830,
    "sweeps_available_balance": 206534830,
    "serverTime": "2020-09-02 09:46:45",
    "modelId": "ingenuity033",
    "gameSessionId": 55,
    "reelStripPositions": [
    5,
    38,
    23,
    19,
    8
],
    "slotWindow": [
    [
        "Queen",
        "HV3",
        "MV1"
    ],
    [
        "HV3",
        "MV2",
        "Jack"
    ],
    [
        "HV3",
        "MV2",
        "King"
    ],
    [
        "MV2",
        "Ace",
        "Wild"
    ],
    [
        "Ace",
        "Queen",
        "Wild"
    ]
],
    "modelDefinition": {
    "reelsLayout": [
        3,
        3,
        3,
        3,
        3
    ],
        "winLevels": [
        {
            "name": "normal1",
            "threshold": 5
        },
        {
            "name": "normal2",
            "threshold": 10
        },
        {
            "name": "normal3",
            "threshold": 15
        },
        {
            "name": "bigwin",
            "threshold": 30
        },
        {
            "name": "megawin",
            "threshold": 70
        },
        {
            "name": "epicwin"
        }
    ],
        "currency_symbol": "$",
        "coinType1Values": [],
        "coinType4Values": [],
        "reels": [
        [
            "Ace",
            "Queen",
            "MV1",
            "MV2",
            "Jack",
            "Queen",
            "HV3",
            "MV1",
            "Ace",
            "King",
            "HV2",
            "HV2",
            "HV2",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "King",
            "Jack",
            "HV3",
            "Ace",
            "Wild",
            "Queen",
            "Jack",
            "MV1",
            "Ace",
            "HV1",
            "HV1",
            "HV1",
            "King",
            "MV1",
            "MV2",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "Ace",
            "HV3",
            "MV1",
            "Ten",
            "Queen",
            "HV2",
            "HV2",
            "HV2",
            "Queen",
            "King",
            "MV2"
        ],
        [
            "Ace",
            "King",
            "HV3",
            "MV2",
            "Jack",
            "Queen",
            "MV1",
            "MV2",
            "Ace",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "King",
            "HV3",
            "Jack",
            "Ace",
            "Wild",
            "Queen",
            "HV3",
            "MV2",
            "Jack",
            "Ace",
            "HV1",
            "HV1",
            "HV1",
            "King",
            "Queen",
            "MV1",
            "Jack",
            "Ten",
            "Bonus",
            "King",
            "Ace",
            "HV3",
            "MV2",
            "Jack",
            "King",
            "HV2",
            "HV2",
            "HV2",
            "Ten",
            "HV3",
            "King",
            "MV2",
            "MV1"
        ],
        [
            "Jack",
            "Queen",
            "MV2",
            "HV3",
            "Jack",
            "Queen",
            "MV1",
            "HV3",
            "Ace",
            "King",
            "HV2",
            "HV2",
            "HV2",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "King",
            "HV3",
            "MV2",
            "Ace",
            "Wild",
            "Queen",
            "HV3",
            "MV2",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Ace",
            "Ten",
            "MV2",
            "Jack",
            "King",
            "Wild",
            "Queen",
            "Ace",
            "HV3",
            "MV1",
            "Ten",
            "Queen",
            "HV2",
            "HV2",
            "HV2",
            "Ace",
            "Queen",
            "MV2",
            "MV1"
        ],
        [
            "Ace",
            "King",
            "HV3",
            "MV2",
            "Jack",
            "Queen",
            "MV1",
            "HV3",
            "Ace",
            "King",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "King",
            "HV3",
            "MV2",
            "Ace",
            "Wild",
            "Queen",
            "HV3",
            "MV2",
            "Jack",
            "Ace",
            "HV1",
            "HV1",
            "HV1",
            "King",
            "Queen",
            "MV1",
            "MV2",
            "Jack",
            "Ten",
            "Wild",
            "King",
            "Ace",
            "HV3",
            "Queen",
            "Jack",
            "King",
            "HV2",
            "HV2",
            "HV2",
            "Ten",
            "King",
            "MV2",
            "Jack",
            "MV1"
        ],
        [
            "King",
            "HV3",
            "Queen",
            "MV2",
            "Jack",
            "Queen",
            "MV1",
            "HV3",
            "Ace",
            "Queen",
            "Wild",
            "HV1",
            "HV1",
            "HV1",
            "Jack",
            "Ten",
            "Bonus",
            "Queen",
            "King",
            "MV1",
            "MV2",
            "Ace",
            "Wild",
            "Queen",
            "HV3",
            "MV2",
            "Jack",
            "Ace",
            "HV1",
            "HV1",
            "HV1",
            "King",
            "Jack",
            "MV1",
            "Queen",
            "Ten",
            "Bonus",
            "King",
            "Ace",
            "MV1",
            "Jack",
            "Queen",
            "HV2",
            "HV2",
            "HV2",
            "Ten",
            "Ace",
            "MV1"
        ]
    ],
        "freeSpinTrigger": "Bonus",
        "countToTriggerFreeSpin": 3,
        "scatterSymbol": "Bonus",
        "wildSymbol": "Wild",
        "freeSpinCounts": [
        {
            "freeSpins": 20,
            "count": 5
        },
        {
            "freeSpins": 15,
            "count": 4
        },
        {
            "freeSpins": 10,
            "count": 3
        }
    ],
        "modelId": "ingenuity033",
        "winTable": [
        {
            "symbol": "HV1",
            "multipliers": [
                500,
                80,
                30
            ]
        },
        {
            "symbol": "HV2",
            "multipliers": [
                300,
                60,
                25
            ]
        },
        {
            "symbol": "HV3",
            "multipliers": [
                150,
                50,
                20
            ]
        },
        {
            "symbol": "MV1",
            "multipliers": [
                120,
                35,
                15
            ]
        },
        {
            "symbol": "MV2",
            "multipliers": [
                120,
                35,
                15
            ]
        },
        {
            "symbol": "Wild",
            "multipliers": [
                800,
                120,
                40
            ]
        },
        {
            "symbol": "Ace",
            "multipliers": [
                75,
                30,
                10
            ]
        },
        {
            "symbol": "King",
            "multipliers": [
                75,
                30,
                10
            ]
        },
        {
            "symbol": "Queen",
            "multipliers": [
                50,
                15,
                5
            ]
        },
        {
            "symbol": "Jack",
            "multipliers": [
                50,
                15,
                5
            ]
        },
        {
            "symbol": "Ten",
            "multipliers": [
                50,
                15,
                5
            ]
        },
        {
            "symbol": "Bonus",
            "multipliers": [
                5,
                2,
                1
            ]
        }
    ],
        "playLines": [
        [
            1,
            1,
            1,
            1,
            1
        ],
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            2,
            2,
            2,
            2,
            2
        ],
        [
            0,
            1,
            2,
            1,
            0
        ],
        [
            2,
            1,
            0,
            1,
            2
        ],
        [
            1,
            0,
            1,
            2,
            1
        ],
        [
            2,
            1,
            2,
            1,
            2
        ],
        [
            0,
            0,
            1,
            2,
            2
        ],
        [
            2,
            2,
            1,
            0,
            0
        ],
        [
            0,
            1,
            1,
            1,
            0
        ],
        [
            2,
            1,
            1,
            1,
            2
        ],
        [
            1,
            1,
            0,
            1,
            1
        ],
        [
            1,
            1,
            2,
            1,
            1
        ],
        [
            1,
            0,
            1,
            0,
            1
        ],
        [
            1,
            2,
            1,
            2,
            1
        ],
        [
            1,
            0,
            0,
            0,
            1
        ],
        [
            1,
            2,
            2,
            2,
            1
        ],
        [
            1,
            0,
            2,
            1,
            0
        ],
        [
            1,
            2,
            0,
            1,
            2
        ],
        [
            0,
            2,
            2,
            2,
            0
        ],
        [
            2,
            0,
            0,
            0,
            2
        ],
        [
            0,
            2,
            1,
            2,
            0
        ],
        [
            2,
            0,
            1,
            0,
            2
        ],
        [
            0,
            2,
            0,
            2,
            0
        ],
        [
            2,
            0,
            2,
            0,
            2
        ],
        [
            0,
            0,
            2,
            0,
            0
        ],
        [
            2,
            2,
            0,
            2,
            2
        ],
        [
            0,
            0,
            1,
            0,
            0
        ],
        [
            2,
            2,
            1,
            2,
            2
        ],
        [
            1,
            2,
            1,
            0,
            1
        ],
        [
            4,
            4,
            4,
            4,
            4
        ],
        [
            3,
            3,
            3,
            3,
            3
        ],
        [
            5,
            5,
            5,
            5,
            5
        ],
        [
            3,
            4,
            5,
            4,
            3
        ],
        [
            5,
            4,
            3,
            4,
            5
        ],
        [
            4,
            5,
            5,
            5,
            4
        ],
        [
            4,
            3,
            3,
            3,
            4
        ],
        [
            5,
            5,
            4,
            5,
            5
        ],
        [
            3,
            3,
            4,
            3,
            3
        ],
        [
            4,
            4,
            5,
            4,
            4
        ],
        [
            4,
            4,
            3,
            4,
            4
        ],
        [
            3,
            5,
            3,
            5,
            3
        ],
        [
            5,
            3,
            5,
            3,
            5
        ],
        [
            3,
            4,
            3,
            4,
            3
        ],
        [
            5,
            4,
            5,
            4,
            5
        ],
        [
            4,
            3,
            4,
            3,
            4
        ],
        [
            4,
            5,
            4,
            5,
            4
        ],
        [
            3,
            4,
            4,
            4,
            3
        ],
        [
            5,
            4,
            4,
            4,
            5
        ],
        [
            3,
            5,
            5,
            5,
            3
        ],
        [
            5,
            3,
            3,
            3,
            5
        ],
        [
            5,
            3,
            4,
            3,
            5
        ],
        [
            3,
            5,
            4,
            5,
            3
        ],
        [
            4,
            3,
            5,
            3,
            4
        ],
        [
            4,
            5,
            3,
            5,
            4
        ],
        [
            3,
            3,
            5,
            3,
            5
        ],
        [
            5,
            5,
            3,
            5,
            4
        ],
        [
            3,
            3,
            4,
            5,
            5
        ],
        [
            5,
            5,
            4,
            3,
            3
        ],
        [
            4,
            5,
            4,
            3,
            4
        ]
    ],
        "caseReels": {
        "1": {
            "pre": [
                [
                    "Ace",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Jack",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "Ace",
                    "HV3",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Queen",
                    "King",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "Queen",
                    "Jack",
                    "MV2",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "Queen",
                    "Jack",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV2",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "HV3",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "Jack",
                    "Queen",
                    "MV2",
                    "HV3",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Ten",
                    "King",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "Queen",
                    "Ace",
                    "HV3",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ace",
                    "Queen",
                    "MV2",
                    "Ten",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "Queen",
                    "Jack",
                    "MV2",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "King",
                    "Ace",
                    "HV3",
                    "Queen",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "King",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "King",
                    "HV3",
                    "Queen",
                    "MV2",
                    "Jack",
                    "Queen",
                    "HV3",
                    "MV1",
                    "Ace",
                    "Queen",
                    "Wild",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV2",
                    "Ten",
                    "Queen",
                    "MV1",
                    "King",
                    "Ace",
                    "HV3",
                    "Jack",
                    "MV1",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "Ace",
                    "MV1"
                ]
            ],
                "post": [
                [
                    "Ace",
                    "Queen",
                    "Wild",
                    "MV2",
                    "Wild",
                    "Queen",
                    "MV1",
                    "Jack",
                    "Wild",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Wild",
                    "Wild",
                    "Wild",
                    "HV3",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "Ace",
                    "HV3",
                    "Wild",
                    "Wild",
                    "Wild",
                    "HV2",
                    "HV2",
                    "Queen",
                    "King",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "Queen",
                    "Jack",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Wild",
                    "Wild",
                    "Wild",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "Wild",
                    "Jack",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV2",
                    "Wild",
                    "King",
                    "HV2",
                    "HV2",
                    "Wild",
                    "Wild",
                    "Wild",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "Wild",
                    "Wild",
                    "Wild",
                    "HV3",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Wild",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Wild",
                    "King",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "Queen",
                    "Ace",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ace",
                    "Queen",
                    "MV2",
                    "Ten",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "Queen",
                    "Jack",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "Wild",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Wild",
                    "Wild",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "King",
                    "Ace",
                    "HV3",
                    "Wild",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "Wild",
                    "Wild",
                    "Wild",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "Wild",
                    "Wild",
                    "Queen",
                    "MV2",
                    "Jack",
                    "Queen",
                    "Wild",
                    "MV1",
                    "Ace",
                    "Queen",
                    "Wild",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "HV3",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV2",
                    "Ten",
                    "Queen",
                    "MV1",
                    "King",
                    "Ace",
                    "HV3",
                    "Jack",
                    "Wild",
                    "Wild",
                    "Wild",
                    "HV2",
                    "HV2",
                    "Wild",
                    "Ace",
                    "Wild"
                ]
            ]
        },
        "8-11": {
            "pre": [
                [
                    "Ace",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Queen",
                    "HV3",
                    "MV1",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "Jack",
                    "HV3",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV1",
                    "Queen",
                    "Jack",
                    "MV1",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "Ace",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Queen",
                    "King",
                    "MV2"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Jack",
                    "Ace",
                    "MV2",
                    "King",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV2",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "HV3",
                    "King",
                    "MV2",
                    "MV1"
                ],
                [
                    "Jack",
                    "Queen",
                    "MV2",
                    "HV3",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV2",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Ace",
                    "Ten",
                    "MV2",
                    "Jack",
                    "King",
                    "MV1",
                    "Ten",
                    "HV3",
                    "Queen",
                    "Ace",
                    "HV3",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ace",
                    "Queen",
                    "MV2",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Ace",
                    "MV2",
                    "Jack",
                    "King",
                    "Ace",
                    "HV3",
                    "Queen",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "King",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "King",
                    "HV3",
                    "Queen",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "Queen",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "King",
                    "MV1",
                    "Jack",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Jack",
                    "MV1",
                    "Queen",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV1",
                    "Jack",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "Ace",
                    "MV1"
                ]
            ],
                "post": [
                [
                    "Ace",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Queen",
                    "HV3",
                    "MV1",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "Jack",
                    "HV3",
                    "Ace",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "Jack",
                    "MV1",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "Ace",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Queen",
                    "King",
                    "MV2"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Jack",
                    "Ace",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV2",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "HV3",
                    "King",
                    "MV2",
                    "MV1"
                ],
                [
                    "Jack",
                    "Queen",
                    "MV2",
                    "HV3",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV2",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Ace",
                    "Ten",
                    "MV2",
                    "Jack",
                    "King",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "Ace",
                    "HV3",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ace",
                    "Queen",
                    "MV2",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Ace",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "Wild",
                    "Wild",
                    "King",
                    "Ace",
                    "HV3",
                    "Queen",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "King",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "King",
                    "HV3",
                    "Queen",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "Queen",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Wild",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Jack",
                    "MV1",
                    "Queen",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV1",
                    "Jack",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "Ace",
                    "MV1"
                ]
            ]
        },
        "2-7:12-14": {
            "pre": [
                [
                    "Ace",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Queen",
                    "HV3",
                    "MV1",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "Jack",
                    "HV3",
                    "Ace",
                    "Wild",
                    "Queen",
                    "Jack",
                    "MV1",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "MV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "Ace",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Queen",
                    "King",
                    "MV2"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Jack",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "Jack",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV2",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "HV3",
                    "King",
                    "MV2",
                    "MV1"
                ],
                [
                    "Jack",
                    "Queen",
                    "MV2",
                    "HV3",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Jack",
                    "Ten",
                    "HV3",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Ace",
                    "Ten",
                    "MV2",
                    "Jack",
                    "King",
                    "Wild",
                    "Queen",
                    "Ace",
                    "HV3",
                    "MV1",
                    "Ten",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ace",
                    "Queen",
                    "MV2",
                    "MV1"
                ],
                [
                    "Ace",
                    "King",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "HV3",
                    "Ace",
                    "King",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV2",
                    "Queen",
                    "King",
                    "HV3",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Queen",
                    "MV1",
                    "MV2",
                    "Jack",
                    "Ten",
                    "Wild",
                    "King",
                    "Ace",
                    "HV3",
                    "Queen",
                    "Jack",
                    "King",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "King",
                    "MV2",
                    "Jack",
                    "MV1"
                ],
                [
                    "King",
                    "HV3",
                    "Queen",
                    "MV2",
                    "Jack",
                    "Queen",
                    "MV1",
                    "Ace",
                    "Queen",
                    "Wild",
                    "HV1",
                    "HV1",
                    "HV1",
                    "Jack",
                    "Ten",
                    "MV1",
                    "Queen",
                    "King",
                    "MV2",
                    "Ace",
                    "Wild",
                    "Queen",
                    "HV3",
                    "MV2",
                    "Jack",
                    "Ace",
                    "HV1",
                    "HV1",
                    "HV1",
                    "King",
                    "Jack",
                    "MV1",
                    "Queen",
                    "Ten",
                    "HV3",
                    "King",
                    "Ace",
                    "MV1",
                    "Jack",
                    "Queen",
                    "HV2",
                    "HV2",
                    "HV2",
                    "Ten",
                    "Ace",
                    "MV1"
                ]
            ]
        }
    },
    "features": {
        "pathOfGod": false,
            "heavenAscension": false,
            "mightyGod": false
    },
    "caseSelector": {
        "baseGame": [
            {
                "case": 7,
                "weight": 6
            },
            {
                "case": 0,
                "weight": 4244
            },
            {
                "case": 2,
                "weight": 6
            },
            {
                "case": 3,
                "weight": 6
            },
            {
                "case": 4,
                "weight": 6
            },
            {
                "case": 5,
                "weight": 6
            },
            {
                "case": 6,
                "weight": 6
            },
            {
                "case": 1,
                "weight": 84
            },
            {
                "case": 8,
                "weight": 7
            },
            {
                "case": 9,
                "weight": 13
            },
            {
                "case": 10,
                "weight": 13
            },
            {
                "case": 11,
                "weight": 13
            },
            {
                "case": 12,
                "weight": 6
            },
            {
                "case": 13,
                "weight": 6
            },
            {
                "case": 14,
                "weight": 6
            }
        ],
            "freeGame": [
            {
                "case": 0,
                "weight": 1949
            },
            {
                "case": 1,
                "weight": 300
            },
            {
                "case": 2,
                "weight": 30
            },
            {
                "case": 3,
                "weight": 30
            },
            {
                "case": 4,
                "weight": 30
            },
            {
                "case": 5,
                "weight": 30
            },
            {
                "case": 6,
                "weight": 30
            },
            {
                "case": 7,
                "weight": 30
            },
            {
                "case": 8,
                "weight": 35
            },
            {
                "case": 9,
                "weight": 70
            },
            {
                "case": 10,
                "weight": 70
            },
            {
                "case": 11,
                "weight": 70
            },
            {
                "case": 12,
                "weight": 70
            },
            {
                "case": 13,
                "weight": 70
            },
            {
                "case": 14,
                "weight": 90
            }
        ]
    },
    "caseState": [
        {
            "case": [
                1
            ],
            "keys": [
                "pathOfGod"
            ],
            "boolArr": [
                true
            ]
        },
        {
            "case": [
                2,
                7
            ],
            "keys": [
                "heavenAscension"
            ],
            "boolArr": [
                true
            ]
        },
        {
            "case": [
                8
            ],
            "keys": [
                "pathOfGod",
                "heavenAscension"
            ],
            "boolArr": [
                true,
                true
            ]
        },
        {
            "case": [
                9,
                11
            ],
            "keys": [
                "pathOfGod",
                "heavenAscension",
                "mightyGod"
            ],
            "boolArr": [
                true,
                true,
                true
            ]
        },
        {
            "case": [
                12,
                14
            ],
            "keys": [
                "heavenAscension",
                "mightyGod"
            ],
            "boolArr": [
                true,
                true
            ]
        }
    ],
        "reelsIndexSelector": {
        "2": [
            2,
            4
        ],
            "3": [
            1,
            5
        ],
            "4": [
            1,
            2
        ],
            "5": [
            2,
            3
        ],
            "6": [
            3,
            4
        ],
            "7": [
            1,
            3
        ],
            "8": [
            4,
            5
        ],
            "9": [
            1
        ],
            "10": [
            2
        ],
            "11": [
            3
        ],
            "12": [
            3,
            4
        ],
            "13": [
            1,
            5
        ],
            "14": [
            4,
            5
        ]
    },
    "retriggerSelector": [
        {
            "case": 0,
            "weight": 8
        },
        {
            "case": 1,
            "weight": 2
        }
    ]
}
}