import Vue from 'vue';
import Component from 'vue-class-component';
import Content from '../content.vue';

@Component({
    props: ['index', 'prizeIndices']
})
export default class SelectScatterMultiplier extends Vue {
    constructor() {
        super();
    }

    public updatePopPrizeIndices() {
        (<Content>this.$parent).updatePopPrizeIndices(event);
    }
}
