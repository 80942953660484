import { SpinInputs } from "../spin-inputs";
import { SpinOutputs } from "../spin-outputs";
import { GameState } from "../../game-state";
import { modelDefinition } from "../../config";
import { getWinType } from "../get-win-type";
import { SpinOutcome } from "../spin-outcome";
import { computeJackpots } from "./compute-jackpots";
import { BoardGamePhase } from "./board-game-phase";

export function jackpotWheel(inputs: SpinInputs): SpinOutputs {
  const { gameState, coinSize, choices, coinTypeId } = inputs;

  if (
    gameState?.boardGamePhase === undefined ||
    gameState?.boardGameCount === undefined
  ) {
    throw new Error("Missing state");
  }
  const totalPlayAmount = coinSize * coinSizeMultiplier;

  const boardGamePhase: BoardGamePhase = gameState.boardGamePhase;
  const boardGameCount: number = gameState.boardGameCount;

  const table = choices.chooseJackpotTable(coinSize, coinTypeId);
  const jackpotType = choices.chooseJackpotType(table);

  const { jackpotWinCounts, jackpotWinAmounts, jackpotWinAmount } =
    computeJackpots(coinSize, jackpotType);

  const { cumulativeWinAmount: oldCumulativeWinAmount } = gameState;

  // Compute win type and amount
  const totalWinAmount = jackpotWinAmount ?? 0;
  const cumulativeWinAmount = oldCumulativeWinAmount + totalWinAmount;

  const winType = getWinType({
    totalWinAmount,
    isBoardGameSpin: true,
    isJackpotTrigger: true,
  });

  // Get the slot window from game state
  const reelStripPositions = gameState.reelStripPositions;
  const slotWindow = gameState.slotWindow;

  const freeSpinCount: number | undefined = gameState.freeSpinCount;
  const freeSpinPhase: GameState["freeSpinPhase"] | undefined =
    gameState.freeSpinPhase;

  const spinOutcome: SpinOutcome = {
    coinSize,
    totalPlayAmount,
    reelStripPositions,
    slotWindow,
    totalWinAmount,
    winType,
    winLines: [],
    lineWins: [],
    winCells: [],
    freeSpinCount,
    freeSpinPhase,
    cumulativeWinAmount,
    boardGamePhase,
    boardGameCount,
    jackpotWinCounts,
    jackpotWinAmounts,
    jackpotWinAmount,
  };

  const isFreeSpinsContinuing = (freeSpinPhase ?? "END") !== "END";
  if (!isFreeSpinsContinuing) return { spinOutcome };

  return {
    spinOutcome,
    gameState: {
      slotWindow,
      reelStripPositions,
      coinSize,
      freeSpinCount,
      freeSpinPhase,
      cumulativeWinAmount,
    },
  };
}

const { coinSizeMultiplier } = modelDefinition;
