export default {
    "success": true,
    "messages": [],
    "gold_balance": 6025950000,
    "sweeps_balance": 413058686,
    "sweeps_deposit_balance": 200001395,
    "sweeps_promo_balance": 6527948,
    "sweeps_win_balance": 206529343,
    "sweeps_available_balance": 206529343,
    "serverTime": "2020-12-21 11:56:05",
    "modelId": "ingenuity034",
    "gameSessionId": 1,
    "reelStripPositions": [
      51,
      66,
      8,
      12,
      3
    ],
    "slotWindow": [
      [
        "J",
        "J",
        "J",
        "K"
      ],
      [
        "10",
        "J",
        "A",
        "A"
      ],
      [
        "PIC2",
        "PIC2",
        "PIC2",
        "Q"
      ],
      [
        "PIC2",
        "PIC2",
        "10",
        "Q"
      ],
      [
        "PIC2",
        "PIC2",
        "PIC2",
        "PIC2"
      ]
    ],
    "modelDefinition": {
      "reelsLayout": [
        4,
        4,
        4,
        4,
        4
      ],
      "winLevels": [
        {
          "name": "normal1",
          "threshold": 5
        },
        {
          "name": "normal2",
          "threshold": 10
        },
        {
          "name": "normal3",
          "threshold": 15
        },
        {
          "name": "bigwin",
          "threshold": 30
        },
        {
          "name": "megawin",
          "threshold": 70
        },
        {
          "name": "epicwin"
        }
      ],
      "currency_symbol": "$",
      "coinType1Values": [
        250,
        2500,
        10000,
        50000,
        100000
      ],
      "coinType4Values": [
        1,
        2,
        5,
        10,
        50,
        100,
        200,
        500
      ],
      "reels": [
        [
          "10",
          "A",
          "Q",
          "A",
          "J",
          "SCAT",
          "Q",
          "A",
          "K",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "Q",
          "K",
          "A",
          "A",
          "A",
          "10",
          "Q",
          "K",
          "J",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "10",
          "J",
          "K",
          "Q",
          "SCAT",
          "10",
          "Q",
          "K",
          "J",
          "A",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "A",
          "K",
          "10",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "J",
          "SCAT",
          "Q",
          "10",
          "J",
          "J",
          "J",
          "K",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "A",
          "Q",
          "K",
          "K",
          "K",
          "J",
          "10",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "10",
          "A",
          "J",
          "SCAT",
          "10",
          "10",
          "10",
          "A",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "J",
          "K",
          "Q",
          "Q",
          "Q",
          "J",
          "10"
        ],
        [
          "A",
          "A",
          "A",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "10",
          "Q",
          "WILD",
          "A",
          "K",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "10",
          "J",
          "Q",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "10",
          "K",
          "K",
          "Q",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "A",
          "10",
          "WILD",
          "J",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "Q",
          "WILD",
          "A",
          "J",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "A",
          "10",
          "WILD",
          "K",
          "Q",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "K",
          "Q",
          "J",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "Q",
          "10",
          "10",
          "10",
          "J"
        ],
        [
          "K",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "A",
          "J",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "Q",
          "Q",
          "J",
          "SCAT",
          "A",
          "J",
          "WILD",
          "K",
          "Q",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "K",
          "Q",
          "WILD",
          "10",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "A",
          "A",
          "SCAT",
          "K",
          "J",
          "WILD",
          "10",
          "A",
          "Q",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "K",
          "WILD",
          "J",
          "10",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "10",
          "Q",
          "SCAT",
          "J",
          "J",
          "10",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "K",
          "10",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "10"
        ],
        [
          "A",
          "A",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "J",
          "K",
          "K",
          "A",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "10",
          "Q",
          "Q",
          "J",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "K",
          "WILD",
          "10",
          "A",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "A",
          "WILD",
          "K",
          "J",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "10",
          "WILD",
          "A",
          "J",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "A",
          "Q",
          "WILD",
          "J",
          "J",
          "K",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "A",
          "10",
          "10",
          "Q"
        ],
        [
          "K",
          "WILD",
          "J",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "A",
          "SCAT",
          "J",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "Q",
          "WILD",
          "10",
          "10",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "A",
          "A",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "10",
          "WILD",
          "J",
          "J",
          "SCAT",
          "K",
          "10",
          "WILD",
          "Q",
          "Q",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "K",
          "K",
          "SCAT",
          "10",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "Q"
        ]
      ],
      "freeSpinTrigger": "SCAT",
      "countToTriggerFreeSpin": 3,
      "scatterSymbol": "SCAT",
      "wildSymbol": "WILD",
      "modelId": "ingenuity034",
      "featureName": "stackBonus",
      "baseLength": 5,
      "freeSpinCount": 10,
      "stackedColumn": 0,
      "stackedKey": [
        "PIC1",
        "PIC2",
        "PIC3",
        "PIC4"
      ],
      "winTable": [
        {
          "symbol": "PIC1",
          "multipliers": [
            100,
            75,
            25
          ]
        },
        {
          "symbol": "PIC2",
          "multipliers": [
            75,
            50,
            20
          ]
        },
        {
          "symbol": "PIC3",
          "multipliers": [
            50,
            30,
            15
          ]
        },
        {
          "symbol": "PIC4",
          "multipliers": [
            40,
            20,
            10
          ]
        },
        {
          "symbol": "A",
          "multipliers": [
            30,
            15,
            7
          ]
        },
        {
          "symbol": "K",
          "multipliers": [
            30,
            15,
            7
          ]
        },
        {
          "symbol": "Q",
          "multipliers": [
            25,
            10,
            5
          ]
        },
        {
          "symbol": "J",
          "multipliers": [
            25,
            10,
            5
          ]
        },
        {
          "symbol": "10",
          "multipliers": [
            25,
            10,
            5
          ]
        }
      ],
      "playLines": [
        [
          1,
          1,
          1,
          1,
          1
        ],
        [
          2,
          2,
          2,
          2,
          2
        ],
        [
          0,
          0,
          0,
          0,
          0
        ],
        [
          3,
          3,
          3,
          3,
          3
        ],
        [
          0,
          0,
          1,
          1,
          1
        ],
        [
          1,
          1,
          0,
          0,
          0
        ],
        [
          0,
          0,
          0,
          1,
          1
        ],
        [
          1,
          1,
          1,
          0,
          0
        ],
        [
          1,
          1,
          2,
          2,
          2
        ],
        [
          2,
          2,
          1,
          1,
          1
        ],
        [
          1,
          1,
          1,
          2,
          2
        ],
        [
          2,
          2,
          2,
          1,
          1
        ],
        [
          2,
          2,
          3,
          3,
          3
        ],
        [
          3,
          3,
          2,
          2,
          2
        ],
        [
          2,
          2,
          2,
          3,
          3
        ],
        [
          3,
          3,
          3,
          2,
          2
        ],
        [
          0,
          0,
          1,
          2,
          2
        ],
        [
          2,
          2,
          1,
          0,
          0
        ],
        [
          1,
          1,
          2,
          3,
          3
        ],
        [
          3,
          3,
          2,
          1,
          1
        ],
        [
          0,
          1,
          2,
          1,
          2
        ],
        [
          2,
          1,
          0,
          1,
          0
        ],
        [
          0,
          1,
          0,
          1,
          2
        ],
        [
          2,
          1,
          2,
          1,
          0
        ],
        [
          1,
          2,
          3,
          2,
          3
        ],
        [
          3,
          2,
          1,
          2,
          1
        ],
        [
          1,
          2,
          1,
          2,
          3
        ],
        [
          3,
          2,
          3,
          2,
          1
        ],
        [
          0,
          1,
          1,
          1,
          0
        ],
        [
          1,
          0,
          0,
          0,
          1
        ],
        [
          1,
          2,
          2,
          2,
          1
        ],
        [
          2,
          1,
          1,
          1,
          2
        ],
        [
          2,
          3,
          3,
          3,
          2
        ],
        [
          3,
          2,
          2,
          2,
          3
        ],
        [
          0,
          1,
          0,
          1,
          0
        ],
        [
          1,
          0,
          1,
          0,
          1
        ],
        [
          1,
          2,
          1,
          2,
          1
        ],
        [
          2,
          1,
          2,
          1,
          2
        ],
        [
          2,
          3,
          2,
          3,
          2
        ],
        [
          3,
          2,
          3,
          2,
          3
        ]
      ],
      "countToReTriggerFreeSpin": 3,
      "noOfReTriggerSpins": 10,
      "featureReels": [
        [
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "SCAT",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "SCAT",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "SCAT",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "SCAT",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2"
        ],
        [
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "WILD",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "WILD",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "WILD",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "WILD",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "WILD",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3"
        ],
        [
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "WILD",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "SCAT",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "SCAT",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "WILD",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "SCAT",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "SCAT",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4"
        ],
        [
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "WILD",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "WILD",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4"
        ],
        [
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "SCAT",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "WILD",
          "SCAT",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "SCAT",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "WILD",
          "PIC2",
          "PIC2",
          "PIC2",
          "PIC2",
          "SCAT",
          "WILD",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC4",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC3",
          "PIC1",
          "PIC1",
          "PIC1",
          "PIC1",
          "WILD",
          "SCAT"
        ]
      ],
      "coinType1_min_bet": 250,
      "coinType1_max_bet": 100000,
      "coinType1_spin_max_bet": 4000000,
      "coinType4_min_bet": 1,
      "coinType4_max_bet": 500,
      "coinType4_spin_max_bet": 20000
    }
  }