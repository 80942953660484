/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf126",

        reelsLayout: [3, 3, 3],
        scatterSymbol: "S",
        freeSpinTrigger: "S",
        wildSymbol: "WD",

        coinSizeMultiplier: 10,

        // prettier-ignore
        reels: [
            ['L1','L4','H2','L3','L4','H2','L1','L1','L1','L3','H3','L2','L4','L4','L3','L1','H1','L3','L3','L4','L2','H3','L4','L4','L3','L2','H2','H2','H2','L1','L3','H3','L2','L4','H1','H1','L3','H3','L2','L4','H3','H3','H3','L1','L3','L2','H1','H1','H1','L1','L4','L4','H3','L2','L4','L3','L3','L3','H3','L1','L4','L2','H2','L3','L4','H1','L1','L4','H3','L2','L3','L1','L1','H2','L4','L2','H3','H3','L4','L3','L3','L2','L1','H3','L3','L4','L2','L2','L2','H1','L3','H3','L4','L4','L4','L2','H3','L3','L4','H2','L1','L3','H2','L1','L1','L4','L3','H3','L2','L2','H1','L4','L1','H2','L2','L2','L4','L3','L3','H2','L4','L2','L2','L1','H2','L1','L3','L2'],
            ['L1','L4','H2','L3','L4','H2','L1','L1','L1','L3','L4','H2','L3','H3','L2','WD','WD','WD','L4','L4','L1','H1','L2','L4','L4','L3','L2','H2','H2','H2','L1','L3','H3','L2','L4','L3','H3','L2','L4','H3','H3','H3','L1','L3','L2','H1','H1','H1','L1','L4','L4','H3','L2','L3','L3','L3','H3','L1','L4','L2','H2','L3','L4','H1','L1','L4','H3','L2','L3','WD','WD','WD','L1','L1','H2','L4','L2','H3','H3','L4','L3','L3','L2','L1','H3','L4','L3','L2','L2','L2','H1','L3','H3','L4','L4','L4','L2','H3','L3','H2','L4','L1','L3','H2','L1','L1','L4','L3','H3','L4','L2','L2','H1','L4','L1','H2','L2','L2','L4','L3','L3','H2','L1','H2','L4','L1','L3','L2'],
            ['L1','L4','H2','L3','L4','H2','L1','L1','L1','L3','H3','L2','L4','L4','L3','L1','H1','L3','L3','L4','L2','H3','L4','L4','L3','L2','H2','H2','H2','L1','L3','H3','L2','L4','H1','H1','L3','L2','H3','L4','H3','H3','H3','L1','L3','L2','H1','H1','H1','L1','L4','L4','H3','L2','L4','L3','L3','L3','H3','L1','L4','L2','H2','L3','L4','H1','L1','L4','H3','L2','L3','L1','L1','H2','L4','L2','H3','H3','L4','L3','L3','L2','L1','H3','L3','L4','L2','L2','L2','H1','L3','H3','L4','L4','L4','L2','H3','L3','L4','H2','L1','L3','H2','L1','L1','L4','L3','H3','L2','L2','H1','L4','L1','H2','L2','L2','L4','L3','L3','H2','L4','L2','L2','L1','H2','L1','L3','L2'],
        ],

        // prettier-ignore
        reelsFreespin: {
            set1: [
                ['L1','H3','L4','H2','L3','L1','L4','L3','H2','L4','L1','L3','H3','L4','L3','H1','L4','L2','L3','L4','L2','H3','L4','L2','L3','H2','L1','L3','H3','L2','L4','L3','H3','L2','L4','H3','L3','L4','L2','H1','L4','H2','L3','L4','H3','L3','L4','L1','H3','L2','L4','L1','L2','H2','L4','L1','H3','L4','L3','L2','L4','L1','L2','L4','H1','L3','L1','L4','L3','H2','L2','L1','H3','L4','L3','H2','L2','L1','L3','H3','L2','L4','H1','L3','L4','L2','H3','L4','L1','H2','L3','H3','L2','L4','L1','L3','H2','L2','L1','L4','L3','L2','L1','L4','L3','H3','L2','H1','L4','L3','L1','L2','H1','L3','L2','L1','H1','L3','L4','L2','L3','L4','L1','H2','L4','L3','L2'],
                ['L1','H3','L3','WD','WD','WD','L1','H2','L4','WD','WD','WD','L1','L3','H2','WD','WD','WD','L4','L2','L3','WD','WD','WD','L4','L2','H3','L4','L2','WD','WD','WD','L4','H3','L2','L4','L3','H3','L2','L4','H3','WD','WD','WD','L3','H1','L4','WD','WD','WD','H3','L3','L4','L1','WD','WD','WD','L1','L2','H2','L4','L1','WD','WD','WD','L3','H1','L4','L1','L3','L4','H2','WD','WD','WD','L2','L1','H3','L4','L3','H2','L2','L3','L1','WD','WD','WD','L4','H1','L3','L4','L2','H3','L4','L1','L3','WD','WD','WD','L3','H2','L2','L1','L4','WD','WD','WD','L3','H3','L2','H1','L4','WD','WD','WD','L2','L1','L3','L4','L2','L3','WD','WD','WD','L4','L3','L2'],
                ['L1','H3','L4','H2','L3','L1','L4','L3','H2','L4','L1','L3','H3','L4','L3','H1','L4','L2','L3','L4','L2','H3','L4','L2','L3','H2','L1','L3','H3','L2','L4','L3','H3','L2','L4','H3','L3','L4','L2','H1','L4','H2','L3','L4','H3','L3','L4','L1','H3','L2','L4','L1','L2','H2','L4','L1','H3','L4','L3','L2','L4','L1','L2','L4','H1','L3','L1','L4','L3','H2','L2','L1','H3','L4','L3','H2','L2','L1','L3','H3','L2','L4','H1','L3','L4','L2','H3','L4','L1','H2','L3','H3','L2','L4','L1','L3','H2','L2','L1','L4','L3','L2','L1','L4','L3','H3','L2','H1','L4','L3','L1','L2','H1','L3','L2','L1','H1','L3','L4','L2','L3','L4','L1','H2','L4','L3','L2'],
            ], 
            set2: [
                ['L1','H3','L4','H2','L3','L1','L4','L3','H2','L4','L1','L3','H3','L4','L3','H1','L4','L2','L3','L4','L2','H3','L4','L2','L3','H2','L1','L3','H3','L2','L4','L3','H3','L2','L4','H3','L3','L4','L2','H1','L4','H2','L3','L4','H3','L3','L4','L1','H3','L2','L4','L1','L2','H2','L4','L1','H3','L4','L3','L2','L4','L1','L2','L4','H1','L3','L1','L4','L3','H2','L2','L1','H3','L4','L3','H2','L2','L1','L3','H3','L2','L4','H1','L3','L4','L2','H3','L4','L1','H2','L3','H3','L2','L4','L1','L3','H2','L2','L1','L4','L3','L2','L1','L4','L3','H3','L2','H1','L4','L3','L1','L2','H1','L3','L2','L1','H1','L3','L4','L2','L3','L4','L1','H2','L4','L3','L2'],
                ['L1','H3','L3','L4','H2','L3','L1','H2','L4','WD','WD','WD','L1','L3','H3','L4','L3','H1','H3','L2','L3','L1','H3','H1','L4','L2','H3','L4','L2','L3','H2','L1','WD','WD','WD','L2','L3','H3','L2','L4','H3','WD','WD','WD','L3','H1','L4','H2','L3','L4','H3','L3','L4','L1','L2','H2','L4','L1','L2','H2','L4','L1','H3','L4','L2','L3','H1','L4','L1','L3','L4','H2','WD','WD','WD','L2','L1','H3','L4','L3','H2','L2','L3','L1','L4','H3','L2','L4','H1','L3','L4','L2','H3','L4','L1','L3','L2','L4','L1','L3','H2','L2','L1','L4','L2','L1','L4','L3','H3','L2','H1','L4','L2','H1','L3','L2','L1','L3','L4','L2','L3','L4','L1','H2','L4','L3','L2'],
                ['L1','H3','L4','H2','L3','L1','L4','L3','H2','L4','L1','L3','H3','L4','L3','H1','L4','L2','L3','L4','L2','H3','L4','L2','L3','H2','L1','L3','H3','L2','L4','L3','H3','L2','L4','H3','L3','L4','L2','H1','L4','H2','L3','L4','H3','L3','L4','L1','H3','L2','L4','L1','L2','H2','L4','L1','H3','L4','L3','L2','L4','L1','L2','L4','H1','L3','L1','L4','L3','H2','L2','L1','H3','L4','L3','H2','L2','L1','L3','H3','L2','L4','H1','L3','L4','L2','H3','L4','L1','H2','L3','H3','L2','L4','L1','L3','H2','L2','L1','L4','L3','L2','L1','L4','L3','H3','L2','H1','L4','L3','L1','L2','H1','L3','L2','L1','H1','L3','L4','L2','L3','L4','L1','H2','L4','L3','L2'],
            ],
        },
    },
};
