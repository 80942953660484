import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

export const CharacterPic = Type.Union([Type.Literal('PIC1'), Type.Literal('PIC2'), Type.Literal('PIC3')]);
export const CharacterWilds = Type.Array(CharacterPic, {
    minItems: 1,
    maxItems: 3,
});

export type CharacterWilds = Static<typeof CharacterWilds>;

export type CharacterPic = Static<typeof CharacterPic>;
