var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-steps" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add clearing step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addStep()
                },
              },
            },
            [_vm._v("Add step")]
          ),
          _vm._v(" "),
          !_vm.spinOutcome.gameResponse.genieBonusPhase ||
          _vm.spinOutcome.gameResponse.genieBonusPhase === "END"
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addGenieBonusTriggerStep()
                    },
                  },
                },
                [_vm._v("Trigger genie bonus")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        !_vm.spinOutcome.gameResponse.genieBonusPhase ||
        _vm.spinOutcome.gameResponse.genieBonusPhase === "START"
          ? _c(
              "div",
              { staticClass: "reel-position-selectors" },
              _vm._l(_vm.scenario.reelStripPositions, function (positionData) {
                return _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: positionData.value,
                      expression: "positionData.value",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: { type: "number" },
                  domProps: { value: positionData.value },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          positionData,
                          "value",
                          _vm._n($event.target.value)
                        )
                      },
                      () => {
                        if (positionData.value < 0) {
                          positionData.value = positionData.max
                        }
                        if (positionData.value > positionData.max) {
                          positionData.value = 0
                        }
                      },
                    ],
                    wheel: function ($event) {
                      $event.preventDefault()
                      return _vm.onScroll($event, positionData)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                })
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("label", [_vm._v("Mystery Symbol")]),
        _vm._v(" "),
        _c("div", [
          _c(
            "select",
            {
              domProps: { value: _vm.scenario.mysterySymbol },
              on: {
                change: function ($event) {
                  return _vm.handleMysterySymbolSelect($event.target.value)
                },
              },
            },
            _vm._l(_vm.getMysterySymbols(), function (mysterySymbol) {
              return _c(
                "option",
                { key: mysterySymbol, domProps: { value: mysterySymbol } },
                [_vm._v("\n          " + _vm._s(mysterySymbol) + "\n        ")]
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.shouldTriggerOptionsDisplayed()
        ? _c("div", [
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Genie Bonus Lamp colours")]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "genie-bonus-lamp-options" },
                _vm._l(_vm.scenario.genieBonusLamps, function (feature) {
                  return _c("div", { staticClass: "row" }, [
                    _c("label", [_vm._v("LAMP at ")]),
                    _c("span", [
                      _vm._v(
                        "[ " +
                          _vm._s(feature.position[0]) +
                          ", " +
                          _vm._s(feature.position[1]) +
                          " ]"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Colour ")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: feature.colour,
                            expression: "feature.colour",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                feature,
                                "colour",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.handleGenieBonusColourUpdate(
                                feature,
                                $event.target.value
                              )
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "G" } }, [
                          _vm._v("GREEN"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "P", selected: "" } }, [
                          _vm._v("PURPLE"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "R", selected: "" } }, [
                          _vm._v("RED"),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.scenario.genieBonusLamps &&
              _vm.scenario.genieBonusLamps.length > 0
                ? _c(
                    "div",
                    { staticClass: "triggering colours" },
                    [
                      _c("h3", { staticClass: "heading" }, [
                        _vm._v("Triggering Colours:"),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        new Set(
                          _vm.scenario.genieBonusLamps.map((l) => l.colour)
                        ),
                        function (colour) {
                          return _c("div", [
                            _c("input", {
                              staticClass: "triggering-colour-check-box",
                              attrs: { id: colour, type: "checkbox" },
                              domProps: {
                                checked:
                                  _vm.scenario.triggeringColours?.includes(
                                    colour
                                  ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleTriggeringColourSelect(
                                    $event.target.checked,
                                    colour
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: colour } }, [
                              _vm._v(
                                _vm._s(
                                  colour === "G"
                                    ? "GREEN"
                                    : colour === "P"
                                    ? "PURPLE"
                                    : "RED"
                                ) + " "
                              ),
                            ]),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldCoinPrizeSelectionDisplayed()
        ? _c("div", { staticStyle: { width: "100%" } }, [
            _c("h3", { staticClass: "heading" }, [_vm._v("Coin Prizes:")]),
            _vm._v(" "),
            _vm.isGenieBonusTrigger() && _vm.getNonFeatureCoinCount() < 6
              ? _c("p", [
                  _c("b", [_vm._v(_vm._s(5 - _vm.getNonFeatureCoinCount()))]),
                  _vm._v(
                    " more coins to be added to trigger the Genie Bonus feature"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.spinOutcome.gameResponse.genieBonusPhase &&
            _vm.spinOutcome.gameResponse.genieBonusPhase !== "START"
              ? _c("p", [
                  _c("b", [_vm._v(_vm._s(5 - _vm.getCurrentFeatureCount()))]),
                  _vm._v(" more features can be added."),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "feature-grid-container" },
              _vm._l(
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                function (reelIndex) {
                  return _c(
                    "div",
                    {
                      staticClass: "feature-grid-item",
                      class: {
                        "green-coin": _vm.getFeatureColour(reelIndex) === "G",
                        "purple-coin": _vm.getFeatureColour(reelIndex) === "P",
                        "red-coin": _vm.getFeatureColour(reelIndex) === "R",
                      },
                    },
                    [
                      _c("div", { staticClass: "coin-row" }, [
                        _c(
                          "select",
                          {
                            attrs: { disabled: !_vm.isEditable(reelIndex) },
                            on: {
                              change: function ($event) {
                                return _vm.handleCoinPrizeSelected(
                                  reelIndex,
                                  $event.target.value
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "-1" } }, [
                              _vm._v("-"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.getApplicableCoinPrizes(reelIndex),
                              function (prize) {
                                return _c(
                                  "option",
                                  {
                                    key: "coin-prize-" + prize,
                                    domProps: {
                                      value: prize,
                                      selected: _vm.isCoinPrizeSelected(
                                        reelIndex,
                                        prize
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(prize) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "coin-row" },
                        [
                          _vm.getFeatureColour(reelIndex) === "G"
                            ? _c("multi-select", {
                                attrs: {
                                  options: _vm.getGreenPrizes(),
                                  "selected-values":
                                    _vm.getGreenPrizeForCell(reelIndex),
                                  "is-editable": _vm.isPrizeEditable(reelIndex),
                                },
                                on: {
                                  "selection-changed": function ($event) {
                                    return _vm.handleGreenPrizeSelection(
                                      $event,
                                      reelIndex
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getFeatureColour(reelIndex) === "P"
                            ? _c(
                                "select",
                                {
                                  attrs: {
                                    disabled: !_vm.isPrizeEditable(reelIndex),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlePurpleCoinPrizeSelected(
                                        reelIndex,
                                        $event.target.value
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.getPurplePrizes(), function (prize) {
                                  return _c(
                                    "option",
                                    {
                                      key: "purple-prize-" + prize,
                                      domProps: {
                                        value: prize,
                                        selected: _vm.isPurpleCoinPrizeSelected(
                                          reelIndex,
                                          prize
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " \n            " +
                                          _vm._s(prize) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("slot-window", {
        attrs: { spinOutcome: _vm.spinOutcome, scenario: _vm.scenario },
        on: { "selection-changed": _vm.handleGreenPrizeSelection },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }