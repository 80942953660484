import { modelDefinition } from '../../../model-definition';

type SlotWindow = string[][];

type CharacterPic = 'PIC1' | 'PIC2' | 'PIC3';
type PositionToWild = {
    rowIndex: number;
    reelIndex: number;
    symbol: CharacterPic;
};

export type CharacterWilds = {
    pics: CharacterPic[];
    positionToWilds: PositionToWild[];
};

export type CharacterWildsFeatureResult = {
    slotWindow: SlotWindow;
    characterWilds: CharacterWilds;
};

export function playCharacterWildsFeature({
    slotWindow,
    characterWilds,
}: {
    slotWindow: SlotWindow;
    characterWilds?: CharacterPic[];
}): CharacterWildsFeatureResult {
    const validPics: CharacterPic[] = [];
    const positionToWilds: PositionToWild[] = [];
    const newSlotWindow = slotWindow.map((column) => [...column]);
    characterWilds = characterWilds ?? [];
    for (const [reelIndex, reel] of newSlotWindow.entries()) {
        for (const [rowIndex, symbol] of reel.entries()) {
            if (!isSymbolInValidCharacters(characterWilds, symbol)) continue;

            if (!isSymbolInValidCharacters(validPics, symbol)) validPics.push(symbol);
            reel[rowIndex] = modelDefinition.wildSymbol;
            positionToWilds.push({ rowIndex, reelIndex, symbol });
        }
    }

    return {
        characterWilds: {
            pics: validPics,
            positionToWilds,
        },
        slotWindow: newSlotWindow,
    };
}

function isSymbolInValidCharacters(characterWilds: CharacterPic[], symbol: string): symbol is CharacterPic {
    return characterWilds.includes(symbol as CharacterPic);
}
