/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],

    "modelDefinition" : {

        "modelId": "tf103",
        "version": "1.4",

        "scatterSymbol": "scat",
        "wildSymbol": "wild",
    
        "freeSpinTrigger": "scat",
        "dynamiteSymbol": "pic1",
        "countToTriggerFreeSpin": 3,
    
        "freeSpinCount3": 10,
        "freeSpinCount4": 15,
        "freeSpinCount5": 25,
        "alreadyFreeSpinCount": 5,
    
        "metaStageSteps":5,
        "metaStageMax":2,
    
        "reelsLayout": [3, 3, 3, 3, 3],
        "maxFreeSpinReTriggerCount": 9,
    
        "winTable": [
            {
                "symbol": "pic1",
                "multipliers": [250, 100, 20, 5]
            },
            {
                "symbol": "pic2",
                "multipliers": [125, 50, 10]
            },
            {
                "symbol": "pic3",
                "multipliers": [125, 50, 10]
            },
            {
                "symbol": "pic4",
                "multipliers": [125, 50, 10]
            },
            {
                "symbol": "pic5",
                "multipliers": [100, 50, 10]
            },
            {
                "symbol": "a",
                "multipliers": [100, 50, 10]
            },
            {
                "symbol": "k",
                "multipliers": [50, 20, 5]
            },
            {
                "symbol": "q",
                "multipliers": [50, 20, 5]
            },
            {
                "symbol": "j",
                "multipliers": [50, 20, 5]
            },
            {
                "symbol": "10",
                "multipliers": [50, 20, 5]
            },
            {
                "symbol": "9",
                "multipliers": [50, 20, 5, 2]
            },
            {
                "symbol": "scat",
                "multipliers": [200, 20, 2]
            },
            {
                "symbol": "wild",
                "multipliers": []
            }
        ],
    
        "jackpotPool": [
            {"winAmount":1000,      "name":"mini",      "wild":true,        "symbols":["9", "10", "j", "q", "k", "a"]                },
            {"winAmount":2500,      "name":"minor",     "wild":true,        "symbols":["pic1", "pic2", "pic3", "pic4", "pic5"]       },
            {"winAmount":25000,     "name":"major",     "wild":false,       "symbols":["9", "10", "j", "q", "k", "a"]                },
            {"winAmount":100000,    "name":"grand",     "wild":false,       "symbols":["pic1", "pic2", "pic3", "pic4", "pic5"]       }
        ],
    
        "playLines":[
            [1,1,1,1,1],
            [0,0,0,0,0],
            [2,2,2,2,2],
            [0,1,2,1,0],
            [2,1,0,1,2],
            [1,0,0,0,1],
            [1,2,2,2,1],
            [0,0,1,2,2],
            [2,2,1,0,0],
            [2,1,1,1,2],
            [0,1,1,1,0],
            [0,1,0,1,0],
            [2,1,2,1,2],
            [1,0,1,0,1],
            [1,2,1,2,1],
            [0,2,2,2,0],
            [2,0,0,0,2],
            [0,2,0,2,0],
            [2,2,2,1,0],
            [2,1,1,1,0],    //20
        ],
    
        "reels": [
            ["q","10","scat","q","10","pic1","q","10","pic1","q","10","pic1","q","10","pic5","a","j","pic4","q","pic5","10","pic2","9","pic3","a","pic4","q","k","pic5"],
            ["pic3","j","9","pic2","j","k","pic1","j","k","pic5","q","9","scat","j","9","pic2","a","9","pic3","a","k","pic4","a","10","pic2","a","j"],
            ["a","pic3","j","wild","10","pic2","k","wild","k","pic2","q","wild","k","9","scat","10","k","pic1","pic5","k","pic1","9","a","pic1","q","pic2","pic3","9","pic1","pic4","j"],
            ["pic2","9","wild","wild","wild","9","wild","wild","wild","10","wild","wild","k","wild","10","wild","k","j","scat","k","j","pic1","pic5","10","pic4","k","pic2","pic3","q","pic4","a","pic2","k","pic4","pic3","a"],
            ["j","wild","wild","wild","j","wild","wild","wild","j","wild","wild","10","wild","wild","10","wild","9","wild","q","9","scat","q","9","pic1","k","a","pic2","k","a","pic3","k","a","pic3","pic4","10","a","pic5"]
        ],
    
        "featureReels": [
            ["q","10","scat","q","10","pic1","q","10","pic1","q","10","pic1","q","scat","10","pic5","a","scat","j","pic4","q","pic5","10","pic2","9","pic3","a","pic4","q","scat","k","pic5"],
            ["pic3","j","scat","9","pic2","j","k","pic1","j","k","pic5","q","9","scat","j","9","pic2","a","scat","9","pic3","a","k","pic4","a","scat","10","pic2","a","j"],
            ["a","pic3","j","wild","10","pic2","k","wild","wild","k","wild","wild","pic2","q","wild","k","9","scat","10","k","pic1","pic5","k","pic1","9","scat","a","pic1","q","pic2","scat","pic3","9","pic1","pic4","j","scat"],
            ["wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild"],
            ["wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild","wild"],
        ],
    
        "winLevels": [
            { "name": "normal1", "threshold": 5 },
            { "name": "normal2", "threshold": 15 },
            { "name": "normal3", "threshold": 20 },
            { "name": "bigwin", "threshold": 35 },
            { "name": "megawin", "threshold": 70 },
            { "name": "epicwin" }
        ],
    
        "freespinProbTable": [
            {
                "option": 10,
                "weight": 93
            },
            {
                "option": 15,
                "weight": 5
            },
            {
                "option": 25,
                "weight": 2
            },
        ],
    
        "--coinType1Values": [250, 2500, 10000, 50000, 100000],
        "--coinType4Values": [1, 2, 5, 10, 15, 20, 30, 50, 250],
    
        "coinType1Values": [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500],
        "coinType4Values": [1, 2, 3, 4, 5, 6, 7, 13, 14, 15],
    
        "coinType1_min_bet": 250,
        "coinType1_max_bet": 100000,
        "coinType1_spin_max_bet": 4000000,
    
        "coinType4_min_bet": 1,
        "coinType4_max_bet": 250,
        "coinType4_spin_max_bet": 10000,
    
    
        // ----------   Server-only properties:   ----------
        "example": {
        }
    
    },
    
}
