// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vgw089__outer[data-v-f7ca1e80] {
  /* Revert all styles to avoid anything leaking into the component. */
  all: revert;
}
.vgw089__outer[data-v-f7ca1e80] {
  color: white;
  padding: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw089/content.vue.css"],"names":[],"mappings":"AAAA;EACE,oEAAoE;EACpE,WAAW;AACb;AACA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".vgw089__outer[data-v-f7ca1e80] {\n  /* Revert all styles to avoid anything leaking into the component. */\n  all: revert;\n}\n.vgw089__outer[data-v-f7ca1e80] {\n  color: white;\n  padding: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
