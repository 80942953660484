import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {
        };
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public baseBonuses = ["Random","NoBonus","GretelWildBonus","HanselWildBonus"];
    public substitutionBaseBonus = {bonus: "Random"};

    public showFeatureBonusSelector = false; 
    public showBaseBonusSelector = false; 

    public featureBonuses = ["Random","NoBonus","JumboBonus"];
    public substitutionFeatureBonus = {featureBonus: "Random"};

    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        if(this.isFeature){
            this.showFeatureBonusSelector = true;
            this.showBaseBonusSelector = false; 
        }else {
            this.showBaseBonusSelector = true;
            this.showFeatureBonusSelector = false
        }

        this.substitutionBaseBonus = {bonus: step.json.substitutionBaseBonus};
        this.substitutionFeatureBonus = {featureBonus: step.json.substitutionFeatureBonus};
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }

    public doChangeBaseBonus() {
        trace(this.substitutionBaseBonus.bonus);
        this.step.json.substitutionFeatureBonus = "";
        this.step.json.substitutionBaseBonus = this.substitutionBaseBonus.bonus;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public doChangeFeatureBonus() {
        trace(this.substitutionFeatureBonus.featureBonus);
        this.step.json.substitutionBaseBonus = "";
        this.step.json.substitutionFeatureBonus = this.substitutionFeatureBonus.featureBonus;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
