import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
	props: ["index", "wheelResults"],
})
export default class WheelResult extends Vue {
	constructor() {
		super();
	}
	updateWheelResult(index) {
		(<Content>this.$parent).updateWheelResult(index, event);
	}

	shouldDisplay(optionIndex: number, wheelIndex: number): boolean {
		return (<Content>this.$parent).shouldDisplay(optionIndex, wheelIndex);
	}
}
