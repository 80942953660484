import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, wrapIndex, rowsFromIndexes, getReelWindowFromIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./../reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        
        console.log(step.json);
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        // this.step.json.field0 = String(this.shiftStack);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    } 
    public doChangePattern() {
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }
    public addMinorJackpotStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMinorJackpotStep());
    }
    public addMajorJackpotStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMajorJackpotStep());
    }
}

const getSymbolStack = (reelStripPositions, isFeature) => {
    const state = State.state;
    const reels = (isFeature ? state.getFeatureReelStrips() : state.getReelStrips())[0];
    const index = reelStripPositions[0];
    const window = getReelWindowFromIndex(reels, index, state.getNumberOfRows());
    const first = window[0];
    const b = first.indexOf("PIC") > -1 && window[1] === first && window[2] === first && window[3] === first;

    return b ? first : null;
}