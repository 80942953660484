import bus from "../../../../common/bus";
import events from "../../../../common/events";
import {
  createFreeSpinStep,
  createSpinStep,
  originatorId,
  StepJson,
} from "../../../../state/models/slots/vgw099";
import Vue, { PropType } from "vue";
import { spin } from "./vgw099/math-model/spin/";
import { createChoicesFromScenarioStep } from "./vgw099/math-model/choices/";
import { SlotWindow } from "./slot-window";
import { GameState } from "./vgw099/math-model/config/";

export default Vue.component("Content", {
  props: {
    step: Object as PropType<{ test_scenario_step_id: number }>,
  },
  components: {
    "slot-window": SlotWindow,
  },
  data: function () {
    return {
      scenario: {
        reelStripPositions: [
          { value: 5 },
          { value: 5 },
          { value: 5 },
          { value: 5 },
          { value: 5 },
        ],
      },
      allSteps: [] as Step[],
    };
  },
  computed: {
    spinOutcome: function () {
      const spinOutput = spin({
        coinTypeId: 4,
        coinSize: 1,
        choices: createChoicesFromScenarioStep({
          reelStripPositions: this.scenario.reelStripPositions.map(
            ({ value }) => value
          ),
        }),
        gameState: this.currentStep?.gameState,
      });

      return spinOutput.spinOutcome;
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
  },
  methods: {
    addSpinStep: function () {
      bus.$emit(events.ADD_STEP, createSpinStep());
    },

    addFreeSpinStep: function () {
      bus.$emit(events.ADD_STEP, createFreeSpinStep());
    },

    // ----- Scenario persistence methods -----
    reloadStepsFromScenario: function (scenario: Scenario) {
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }

        this.allSteps[this.currentStepIndex].json = convertToStepJson(
          newScenario,
          this.allSteps[this.currentStepIndex].json
        );
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) =>
      this.reloadStepsFromScenario(scenario)
    );

    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) =>
      this.reloadStepsFromScenario(scenario)
    );
  },
});

type Scenario = { steps: Step[] } | undefined;

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

/**
 * Representation of the scenario used internally to the view.
 *
 * This is set up to allow for reactive binding to the UI
 */
interface InternalScenario {
  reelStripPositions: { value: number }[];
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  if (steps[0].gameState) {
    modified = true;
    steps[0].gameState = undefined;
  }

  for (const step of steps) {
    if (!compareGameStates(gameState, step.gameState)) {
      modified = true;
      step.gameState = gameState;
    }

    const spinOutput = spin({
      coinTypeId: 4,
      coinSize: 1,
      choices: createChoicesFromScenarioStep({
        ...step.json,
      }),
      gameState,
    });

    gameState = spinOutput.gameState;
  }

  return modified;
}

function convertToStepJson(
  scenario: InternalScenario,
  previousJson: StepJson
): StepJson {
  return {
    ...previousJson,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
  };
}

function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
  };
}

function compareGameStates(a?: GameState, b?: GameState) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}
