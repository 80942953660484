import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
	components: {
		reel: Reel,
	},
})
export default class Content extends Vue {
	@Prop() public step: any;
	public isFeature = false;
	public isFeatureChecked = false;
	public show = false;
	public reelStripPositions = [];
	public reelsNo = 4;
	public reel = "";
	public isOpen = false;

	public scattersNumber = 0;
	public scattersSelectedMultipliers = new Array(8).fill(0);
	public skulls = 0;
	public forceFS = false;
	public reelType = 0;
	public reelId = 0;

	public dcfOutcomeSelected = 0;

	public maxScatters = "0";

	public baseReelHeightOne = 7;
	public baseReelHeightTwo = 6;
	public baseReelHeightThree = 6;
	public baseReelHeightFour = 6;
	public baseReelHeightFive = 6;
	public baseReelHeightSix = 7;

	constructor() {
		super();
	}

	@Watch("step")
	public stepWatch(step) {
		if (!step) {
			this.show = false;
			return;
		}

		this.isOpen = false;
		this.show = true;
		this.isFeature = step.json.isFeature ? step.json.isFeature : false;

		if (this.reelsNo !== this.step.json.reelStripPositions.length) {
			this.reelsNo = this.step.json.reelStripPositions.length;
			bus.$emit(events.UPDATE_STEPS_DB, this.step);
		}
	}

	public mounted() {
		this.isOpen = false;
		trace("reels mounted");
	}

	public addBaseStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getBaseStep());
	}

	public addFeatureTriggerStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
	}

	public updateReelType(reelType) {
		this.step.json.reelType = parseInt(reelType);
		this.reelType = reelType;

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateReelId(reelId) {
		this.step.json.reelId = parseInt(reelId);
		this.reelId = reelId;
		if (this.reelType == 0) {
			this.step.json.reelId = this.reelId = 0;
		}
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateSkullNumber(skullNumber) {
		this.step.json.skulls = parseInt(skullNumber);
		this.skulls = this.step.json.skulls;

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}
	
	public updateForceFS(forceFS) {
		this.step.json.forceFS = forceFS;
		this.forceFS = this.step.json.forceFS;

		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateSettings() {
		if (this.step.json.forceFS) {
			this.forceFS = this.step.json.forceFS;
		} else {
			this.forceFS = false;
		}
		if (this.step.json.skulls) {
			this.skulls = this.step.json.skulls;
		} else {
			this.skulls = 0;
		}
		if (this.step.json.reelType) {
			this.reelType = this.step.json.reelType;
		} else {
			this.reelType = 0;
			this.reelId = 0;
		}
		if (this.step.json.reelId) {
			this.reelId = this.step.json.reelId;
		} else {
			this.reelId = 0;
		}
		if (this.reelType == 0) {
			this.step.json.reelId = this.reelId = 0;
		}
	}
	
	public getReelTypeStr() {
		return ["nowin","default","freeSpins","freeSpins_1","freeSpins_2","freeSpins_3"][this.reelType];
	}
}
