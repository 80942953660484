// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-22a74bf0] {
}
.sm-game-spec-content .flex[data-v-22a74bf0] {
  display: flex;
}
.sm-game-spec-content .flex-center[data-v-22a74bf0] {
  display: flex;
  justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-22a74bf0] {
  margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-22a74bf0] {
  font-size: 10px;
  color: gray;
}
.sm-game-spec-content .menu[data-v-22a74bf0] {
  margin: 5px 0 5px 0;
}
.sm-game-spec-content .btn-toggle-is-feature[data-v-22a74bf0] {
  background-color: #024802;
}
.text[data-v-22a74bf0] {
  color: #ccc;
  font-size: 14px;
}
.title[data-v-22a74bf0] {
  color: #ccc;
  text-align: center;
  font-size: 16px;
  margin-bottom: -0.5rem;
  font-weight: bold;
}
.box[data-v-22a74bf0] {
  margin: -0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/ingenuity036/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".sm-game-spec-content[data-v-22a74bf0] {\n}\n.sm-game-spec-content .flex[data-v-22a74bf0] {\n  display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-22a74bf0] {\n  display: flex;\n  justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-22a74bf0] {\n  margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-22a74bf0] {\n  font-size: 10px;\n  color: gray;\n}\n.sm-game-spec-content .menu[data-v-22a74bf0] {\n  margin: 5px 0 5px 0;\n}\n.sm-game-spec-content .btn-toggle-is-feature[data-v-22a74bf0] {\n  background-color: #024802;\n}\n.text[data-v-22a74bf0] {\n  color: #ccc;\n  font-size: 14px;\n}\n.title[data-v-22a74bf0] {\n  color: #ccc;\n  text-align: center;\n  font-size: 16px;\n  margin-bottom: -0.5rem;\n  font-weight: bold;\n}\n.box[data-v-22a74bf0] {\n  margin: -0.5rem 0.5rem 0.5rem 0.5rem;\n  text-align: center;\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
