var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.BASE)
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.BASE_RESPIN)
              },
            },
          },
          [_vm._v("Base Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.FS_TRIGGER)
              },
            },
          },
          [_vm._v("FS Trigger Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.PICK)
              },
            },
          },
          [_vm._v("Pick Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.FREE_SPIN)
              },
            },
          },
          [_vm._v("FS Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStep(_vm.StepType.FREE_SPIN_RESPIN)
              },
            },
          },
          [_vm._v("FS Respin Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex menu" }, [
        _vm.StepType.FS_TRIGGER !== _vm.stepType
          ? _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
              _c("label", { attrs: { for: "dragon_bonus" } }, [
                _vm._v("Dragon bonus: "),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dragonBonus,
                      expression: "dragonBonus",
                    },
                  ],
                  attrs: { id: "dragon_bonus" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.dragonBonus = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.update({ dragonBonus: _vm.dragonBonus })
                      },
                    ],
                  },
                },
                _vm._l(
                  _vm.getStepDragonBonusOptions(),
                  function (value, index) {
                    return _c("option", { key: index }, [
                      _vm._v(_vm._s(value) + "\n                "),
                    ])
                  }
                ),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: {
              index: 0,
              step: _vm.step,
              reelStrip: _vm.reelSetId,
              dragonBonus: _vm.dragonBonus,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 1,
              step: _vm.step,
              reelStrip: _vm.reelSetId,
              dragonBonus: _vm.dragonBonus,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 2,
              step: _vm.step,
              reelStrip: _vm.reelSetId,
              dragonBonus: _vm.dragonBonus,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 3,
              step: _vm.step,
              reelStrip: _vm.reelSetId,
              dragonBonus: _vm.dragonBonus,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 4,
              step: _vm.step,
              reelStrip: _vm.reelSetId,
              dragonBonus: _vm.dragonBonus,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }