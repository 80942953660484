import Vue from "vue"; 
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../../../../../helpers/casino";
import {trace, TODO, rowsFromIndexes, getReelWindowFromIndex, wrapIndex, getAssetsPath} from "./../../../../../helpers/helpers";
import State from "../../../../../state/state";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events"; 
  
@Component({
    data: () => {
        return {
            reelIndex: 0,
        };
    },
})
export default class Reel extends Vue {
    @Prop({ type: Number, default: 0}) public index;
    @Prop() public step: any;
    @Prop({ type: Boolean, default: false}) public isFeature;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
    private reelIndex = 0;
    private text = "0";
    private p0top = "";
    private p1top = "";
    private p0bottom = "";
    private p1bottom = "";
    private reels = [];
    private nRows = 3;
    private show = false;
    private canUpdate = false; 
    private updateTimeout; 
    constructor() {
        super();
        const state = State.state;
        const md = state.getModelDefinition();
        const reelstrips = md["reels"];
        this.nRows = md.reelsLayout[this.index];
    }
    @Watch("step")
    public stepChanged(step) { 
        if (!step || !step.json) { return; }
        this.canUpdate = false;
        this.reelIndex = step.json.reelStripPositions[this.index];
        this.text = String(this.reelIndex);
        
        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }
    @Watch("text")
    public textChanged(val, oldval) { 
        if (!this.canUpdate) { return; }
        if (!Number.isInteger(Number(val))) { 
            // TODO try and find the next index that starts with the text written
            this.text = oldval; 
        } 
        const rs = this.getReels();
        if (Number(this.text) >= rs.length) {
            this.text = String(wrapIndex(Number(this.text), rs));
        }

        this.updateStep();
        this.updateDB();
    }
    @Watch("isFeature") 
    public isFeatureChanged(val) { 
        this.updateStep();
    }   
    public mounted() {
        this.$refs.reel && this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);

        bus.$on("tbg-change-reel", (dir) => {
            if (dir > 0) this.goUpFeatureReelsAsGroup();
            if (dir < 0) this.goDownFeatureReelsAsGroup();
        });
    }
    public beforeDestroy() {
        this.$refs.reel && this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
        bus.$off("tbg-change-reel");
    }
    private getReels() {
        const state = State.state;
        const a = this.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
        return a[this.index];
    }
    public up() {
        if ((this.index === 1 || this.index === 2 || this.index === 3) && this.isFeature) {
            bus.$emit("tbg-change-reel", 1);
            return;
        }
        this.text = String(wrapIndex(this.reelIndex + 1, this.getReels())); 
    }
    public down() {
        if ((this.index === 1 || this.index === 2 || this.index === 3) && this.isFeature) {
            bus.$emit("tbg-change-reel", -1);
            return;
        }
        this.text = String(wrapIndex(this.reelIndex - 1, this.getReels())); 
    }
    public goUpFeatureReelsAsGroup() { 
        if (!(this.index === 1 || this.index === 2 || this.index === 3)) return;
        this.text = String(wrapIndex(this.reelIndex + 1, this.getReels())); 
    }
    public goDownFeatureReelsAsGroup() { 
        if (!(this.index === 1 || this.index === 2 || this.index === 3)) return;
        this.text = String(wrapIndex(this.reelIndex - 1, this.getReels())); 
    }
    public updateStep() {
        if (!this.step) {
            this.show = false;
            return;
        }
        const state = State.state;
        const rs = this.getReels();
        this.reelIndex = Number(this.text);

        this.p0top = rs[wrapIndex(this.reelIndex - 2, rs)];
        this.p1top = rs[wrapIndex(this.reelIndex - 1, rs)]; 
        this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.nRows);

        this.p0bottom = rs[wrapIndex(this.reelIndex + this.nRows, rs)];
        this.p1bottom = rs[wrapIndex(this.reelIndex + this.nRows + 1, rs)];
        this.show = true;
    }
    public updateDB() { 
        if (!this.step) { return; }
        this.step.json.reelStripPositions[this.index] = this.reelIndex;
        bus.$emit("tbg-update-step-from-reel", this.step); 
    }
    public scrollFunction(e) { 
        if (e.deltaY < 0) {
            this.down();
        } else if (e.deltaY > 0) {
            this.up();
        } 
        e.preventDefault();
    }
}
