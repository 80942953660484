import { render, staticRenderFns } from "./reel-symbol.vue?vue&type=template&id=12172275&scoped=true"
import script from "./reel-symbol.vue.ts?vue&type=script&lang=ts&external"
export * from "./reel-symbol.vue.ts?vue&type=script&lang=ts&external"
import style0 from "./reel-symbol.css?vue&type=style&index=0&id=12172275&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12172275",
  null
  
)

export default component.exports