import State from '../../../state';
import {modelDefinition} from './model-definition';
import { createClearingStep } from './steps';

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => createClearingStep(),
        getLayout: () => State.state.getModelDefinition().reelsLayout,

        // // unique per model
        getModelDefinition: () => modelDefinition,
        getNumberOfReels: () => 5,
        getNumberOfRows: () => 3,
        getReelStrips: () => [['PIC1'],['PIC2'],['PIC3'],['PIC4'],['PIC5']],
    };
}
