export default {
  success: true,
  messages: [],
  modelDefinition: {
    betMultiplier: 50,
    modelId: "vgw048",
    reelsLayout: [4, 4, 4, 4, 4],
    scatterSymbol: "SCAT",
    wildSymbol: "WILD",
    freeSpinMultiplier: 0,
    freeSpinCount: 5,
    winTable: [{
      symbol: "P1",
      multipliers: [400, 40, 15, 3],
    }, {
      symbol: "P2",
      multipliers: [300, 30, 12, 2],
    }, {
      symbol: "P3",
      multipliers: [250, 25, 10],
    }, {
      symbol: "P4",
      multipliers: [150, 20, 8],
    }, {
      symbol: "P5",
      multipliers: [150, 20, 8],
    }, {
      symbol: "A",
      multipliers: [120, 15, 6],
    }, {
      symbol: "K",
      multipliers: [120, 15, 6],
    }, {
      symbol: "Q",
      multipliers: [100, 10, 4],
    }, {
      symbol: "J",
      multipliers: [100, 10, 4],
    }, {
      symbol: "10",
      multipliers: [100, 10, 4],
    }],
    playLines: [[1, 1, 1, 1, 1], [2, 2, 2, 2, 2], [0, 0, 0, 0, 0], [3, 3, 3, 3, 3], [0, 0, 1, 1, 1], [1, 1, 0, 0, 0], [0, 0, 0, 1, 1], [1, 1, 1, 0, 0], [1, 1, 2, 2, 2], [2, 2, 1, 1, 1], [1, 1, 1, 2, 2], [2, 2, 2, 1, 1], [2, 2, 3, 3, 3], [3, 3, 2, 2, 2], [2, 2, 2, 3, 3], [3, 3, 3, 2, 2], [0, 0, 1, 2, 2], [2, 2, 1, 0, 0], [1, 1, 2, 3, 3], [3, 3, 2, 1, 1], [0, 1, 2, 1, 2], [2, 1, 0, 1, 0], [0, 1, 0, 1, 2], [2, 1, 2, 1, 0], [1, 2, 3, 2, 3], [3, 2, 1, 2, 1], [1, 2, 1, 2, 3], [3, 2, 3, 2, 1], [0, 1, 1, 1, 0], [1, 0, 0, 0, 1], [1, 2, 2, 2, 1], [2, 1, 1, 1, 2], [2, 3, 3, 3, 2], [3, 2, 2, 2, 3], [0, 1, 0, 1, 0], [1, 0, 1, 0, 1], [1, 2, 1, 2, 1], [2, 1, 2, 1, 2], [2, 3, 2, 3, 2], [3, 2, 3, 2, 3], [0, 1, 0, 2, 0], [2, 1, 2, 0, 2], [1, 2, 1, 3, 1], [3, 2, 3, 1, 3], [0, 1, 0, 2, 1], [2, 1, 2, 0, 1], [1, 2, 1, 3, 2], [3, 2, 3, 1, 2], [0, 3, 2, 0, 2], [3, 0, 1, 3, 1]],
    reels: [ [ "A", "P1", "P2", "J", "K", "P3", "P4", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "J", "K", "J", "K", "A", "J", "10", "10", "A", "J", "P3", "P2", "10", "K", "Q", "A", "P5", "10", "P2", "P4", "K", "J", "Q", "10", "P5", "J", "P4", "K", "P5", "Q", "J", "A", "10", "P4", "J", "P2", "Q", "P3", "K", "P5", "SCAT", "Q", "10", "J", "P1", "K", "Q", "A", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "J", "K", "P3", "P4", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "P5", "10", "A", "J", "P5", "Q", "10", "Q", "J", "P3", "P2", "10", "10", "Q", "J", "K", "P5", "J", "P4", "K", "Q", "10", "J", "P3", "P4", "A", "P5", "10", "SCAT", "J", "Q", "A", "K", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "SCAT", "P5", "P1", "J", "P2", "A", "Q", "10", "K", "P5", "SCAT", "J", "10", "A", "Q", "10", "Q", "P4", "J", "P2", "P4", "K", "J", "A", "P3", "P5", "Q", "10", "P4", "P1", "J", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "P2", "P3", "P3", "P4", "P4", "P5", "P5", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "A", "P5", "K", "SCAT", "J", "Q", "A", "K", "P2", "10", "Q", "J", "K", "Q", "P1", "10", "A", "J", "P3", "P2", "10", "Q", "K", "P4", "P5", "J", "10", "P5", "J", "P4", "K", "P5", "10", "A", "J", "P5", "P1", "10", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "P2", "P3", "P3", "P4", "P4", "P5", "P5", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "10", "K", "J", "Q", "10", "A", "P1", "P4", "Q", "10", "K", "J", "SCAT", "Q", "P5", "P1", "10", "A", "Q", "P3", "P1", "P4", "10", "K", "J", "P4", "J", "K", "10", "P5", "Q", "J", "K", "10", "P2", "J", "A", "Q", "P4", "P5", "J", "10", "P3", "A", "COIN", "COIN", "COIN", "COIN" ] ],
    featureReels: [ [ "A", "P1", "P2", "J", "K", "P3", "P4", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "J", "K", "J", "K", "A", "J", "10", "10", "A", "J", "P3", "P2", "10", "K", "Q", "A", "P5", "10", "P2", "P4", "K", "J", "Q", "10", "P5", "J", "P4", "K", "P5", "Q", "J", "A", "10", "P4", "J", "P2", "Q", "P3", "K", "P5", "SCAT", "Q", "10", "J", "P1", "K", "Q", "A", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "J", "K", "P3", "P4", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "P5", "10", "A", "J", "P5", "Q", "10", "Q", "J", "P3", "P2", "10", "10", "Q", "J", "K", "P5", "J", "P4", "K", "Q", "10", "J", "P3", "P4", "A", "P5", "10", "SCAT", "J", "Q", "A", "K", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "SCAT", "P5", "P1", "J", "P2", "A", "Q", "10", "K", "P5", "SCAT", "J", "10", "A", "Q", "10", "Q", "P4", "J", "P2", "P4", "K", "J", "A", "P3", "P5", "Q", "10", "P4", "P1", "J", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "P2", "P3", "P3", "P4", "P4", "P5", "P5", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "A", "P5", "K", "SCAT", "J", "Q", "A", "K", "P2", "10", "Q", "J", "K", "Q", "P1", "10", "A", "J", "P3", "P2", "10", "Q", "K", "P4", "P5", "J", "10", "P5", "J", "P4", "K", "P5", "10", "A", "J", "P5", "P1", "10", "COIN", "COIN", "COIN", "COIN" ], [ "A", "P1", "P2", "P2", "P3", "P3", "P4", "P4", "P5", "P5", "P3", "P4", "P5", "WILD", "P3", "P4", "P5", "10", "K", "J", "Q", "10", "A", "P1", "P4", "Q", "10", "K", "J", "SCAT", "Q", "P5", "P1", "10", "A", "Q", "P3", "P1", "P4", "10", "K", "J", "P4", "J", "K", "10", "P5", "Q", "J", "K", "10", "P2", "J", "A", "Q", "P4", "P5", "J", "10", "P3", "A", "COIN", "COIN", "COIN", "COIN" ] ],
    defaultSelectLine: 50,
    coinType1Values: [250, 1250, 5000, 25000, 50000],
    coinType4Values: [1, 2, 5, 10, 30],
    coinType1_min_bet: 250,
    coinType1_max_bet: 50000,
    coinType1_spin_max_bet: 2500000,
    coinType4_min_bet: 1,
    coinType4_max_bet: 30,
    coinType4_spin_max_bet: 1500,
    winLevels: [{
      name: "normal1",
      threshold: 5,
    }, {
      name: "normal2",
      threshold: 15,
    }, {
      name: "normal3",
      threshold: 25,
    }, {
      name: "bigwin",
      threshold: 50,
    }, {
      name: "megawin",
      threshold: 150,
    }, {
      name: "epicwin",
      threshold: 2147483647,
    }],
    freeSpinTrigger: "SCAT",
    countToTriggerFreeSpin: 3,
    coinSymbol: "COIN",
    coinCountToTriggerFeature: 8,
    jackpotFeatureLoopCount: 3,
    initialJackpotAmount: {
      SWEEPS: {
        MAJOR: 50000,
        GRAND: 25000000,
      },
      GOLD: {
        MAJOR: 50000,
        GRAND: 25000000,
      },
    },
    coinPricesSweeps: {
      jackpotTypes: ["MAJOR", "MINOR", "MINI"],
      prices: [0, 100, 50, 0, 0, 20, 10, 5, 4, 3, 2, 1],
      fireShotCoinTypes: ["JACKPOT", "JACKPOT", "JACKPOT", "COLLECT", "UPGRADE"],
      weights: {
        0: {
          1: [500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          2: [1000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          5: [2500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          10: [5000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          30: [15000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        },
        1: {
          1: [500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          2: [1000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          5: [2500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          10: [5000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          30: [15000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        },
      },
    },
    coinPricesGold: {
      jackpotTypes: ["MAJOR", "MINOR", "MINI"],
      prices: [0, 100, 50, 0, 0, 20, 10, 5, 4, 3, 2, 1],
      fireShotCoinTypes: ["JACKPOT", "JACKPOT", "JACKPOT", "COLLECT", "UPGRADE"],
      weights: {
        0: {
          250: [500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          1250: [1000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          5000: [2500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          25000: [5000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
          50000: [15000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        },
        1: {
          250: [500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          1250: [1000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          5000: [2500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          25000: [5000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
          50000: [15000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        },
      },
    },
    maxBetMultiplier: 50,
    currency_symbol: "$",
  },
  modelId: "vgw048",
  gameSessionId: 2,
  reelStripPositions: [54, 30, 6, 51, 62],
  slotWindow: [["SCAT", "Q", "10", "J"], ["P5", "J", "P4", "K"], ["P3", "P4", "P5", "SCAT"], ["J", "P5", "P1", "10"], ["COIN", "COIN", "COIN", "A"]],
  gold_balance: 2000000,
  sweeps_balance: 10000000,
  sweeps_deposit_balance: 10000000,
  sweeps_promo_balance: 0,
  sweeps_win_balance: 0,
  sweeps_available_balance: 0,
  symbolInfo: [[null, null, null, null], [null, null, null, null], [null, null, null, null], [null, null, null, null], [{
    price: 50,
    emptySpotNumber: 0,
    enabled: false,
  }, {
    price: 100,
    emptySpotNumber: 0,
    enabled: false,
  }, {
    price: 50,
    emptySpotNumber: 0,
    enabled: false,
  }, null]],
  grandJackpotPool: 25000000,
  majorJackpotPool: 50000,
  serverTime: "2020-03-22 21:08:00",
};
