import Vue, { PropType } from "vue";
import { SpinOutcome } from "../math-model";
import { ReelSymbol } from "./reel-symbol";
import { InternalStep } from "../../vgw089/step-controls";
export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<SpinOutcome>,
    step: Object as PropType<InternalStep>,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    // New Slot Window Check
    slotWindow: function () {
      return this.spinOutcome?.newSlotWindow ?? this.spinOutcome.slotWindow;
    },
    // Free Spins Check
    isFreeSpins: function () {
      if (this.spinOutcome.freeSpinPhase) {
        return true;
      } else {
        return false;
      }
    },
    // Board Game Check
    isBoardGame: function () {
      if (this.spinOutcome.boardGamePhase) {
        return true;
      } else {
        return false;
      }
    },
    // Board Game First Step Check
    isBoardTrigger: function () {
      if (this.spinOutcome?.boardGamePhase === "IN_PROGRESS") {
        return true;
      } else {
        return false;
      }
    },
    // Board Game Jackpot Trigger
    isJackpotTriggered: function () {
      if (this.spinOutcome?.winType === "JACKPOT_WIN") {
        return true;
      } else {
        return false;
      }
    },
    isBoardGamePhaseEnd: function () {
      if (this.spinOutcome?.boardGamePhase === "END") {
        return true;
      } else {
        return false;
      }
    },
    // Wild Path Check
    isWildPath: function () {
      if (this.spinOutcome.wildPath) {
        return true;
      } else {
        return false;
      }
    },
    // Multiplier Check
    isMultiplier: function () {
      if (this.spinOutcome.multiplier) {
        return true;
      } else {
        return false;
      }
    },
    // Stolen Royals Check
    isStolenRoyals: function () {
      if (this.spinOutcome.stolenRoyals) {
        return true;
      } else {
        return false;
      }
    },
    // Nutt Prize Check
    isNuttPrize: function () {
      if (this.spinOutcome.nuttPrize) {
        return true;
      } else {
        return false;
      }
    },
    // Candy Cane Check
    isCandyCane: function () {
      if (this.spinOutcome.candyCanes) {
        return true;
      } else {
        return false;
      }
    },
    spinType: function () {
      if (
        this.spinOutcome.boardGameCount &&
        this.spinOutcome.boardGamePhase !== "START"
      ) {
        return "BOARD_GAME";
      }
      if (
        this.spinOutcome.freeSpinPhase &&
        this.spinOutcome.freeSpinPhase !== "START"
      ) {
        return "FREE_SPINS";
      }
      return "BASE";
    },
  },
  methods: {
    isNuttCell: function (rowIndex: number, colIndex: number): boolean {
      return (
        this.spinOutcome.nuttCells?.find(
          (cell) => cell[0] === rowIndex && cell[1] === colIndex
        ) !== undefined
      );
    },
    stolenRoyalIndex: function (
      rowIndex: number,
      colIndex: number
    ): number | undefined {
      const index = this.spinOutcome.stolenRoyalsReplacementSymbols?.findIndex(
        ({ cell }) => cell[0] === rowIndex && cell[1] === colIndex
      );

      if (index === -1) {
        return undefined;
      }

      return index;
    },
    isCandyCaneCell: function (rowIndex: number, colIndex: number): boolean {
      return !!this.spinOutcome.candyCanes?.some((candyCane) =>
        candyCane.candyCaneCells.some(
          (cell) => cell[0] === rowIndex && cell[1] === colIndex
        )
      );
    },
    isWildPathCell: function (rowIndex: number, colIndex: number): boolean {
      return (
        this.spinOutcome.wildPath?.find(
          (cell) => cell[0] === rowIndex && cell[1] === colIndex
        ) !== undefined
      );
    },
  },
});
