var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("Base")
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FSTrigger")
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("FreeSpinStep")
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addNewStep("EFFreeSpinStep")
              },
            },
          },
          [_vm._v("EF FreeSpin Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mystOption,
              expression: "mystOption",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "select-style" }, [
            _c("label", { attrs: { for: "bg_number" } }, [
              _vm._v("Myst symbol: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mystSymbolChanging,
                    expression: "mystSymbolChanging",
                  },
                ],
                staticStyle: {
                  color: "#00c700",
                  "font-weight": "bold",
                  "font-size": "small",
                },
                attrs: { id: "bg_number" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.mystSymbolChanging = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "H1" } }, [_vm._v("H1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "H2" } }, [_vm._v("H2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "H3" } }, [_vm._v("H3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "M1" } }, [_vm._v("M1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "M2" } }, [_vm._v("M2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L1" } }, [_vm._v("L1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L2" } }, [_vm._v("L2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L3" } }, [_vm._v("L3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L4" } }, [_vm._v("L4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L5" } }, [_vm._v("L5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "L6" } }, [_vm._v("L6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Wild" } }, [_vm._v("Wild")]),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fsShow,
                        expression: "fsShow",
                      },
                    ],
                    attrs: { value: "FS" },
                  },
                  [_vm._v("FS")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "select-style" }, [
            _c("label", { attrs: { for: "cc_number" } }, [
              _vm._v("Column changing: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.columnChanging,
                    expression: "columnChanging",
                  },
                ],
                staticStyle: {
                  color: "#00c700",
                  "font-weight": "bold",
                  "font-size": "small",
                },
                attrs: { id: "cc_number" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.columnChanging = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("None")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,3" } }, [_vm._v("2,3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,4" } }, [_vm._v("2,4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,5" } }, [_vm._v("2,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3,4" } }, [_vm._v("3,4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3,5" } }, [_vm._v("3,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4,5" } }, [_vm._v("4,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,3,4" } }, [_vm._v("2,3,4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,3,5" } }, [_vm._v("2,3,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,4,5" } }, [_vm._v("2,4,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3,4,5" } }, [_vm._v("3,4,5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2,3,4,5" } }, [
                  _vm._v("2,3,4,5"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mystOption,
                  expression: "mystOption",
                },
              ],
              staticClass: "elemOK pointer",
              on: {
                click: function ($event) {
                  return _vm.updateBaseStep(
                    _vm.mystSymbolChanging,
                    _vm.columnChanging
                  )
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }