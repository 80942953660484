import {modelDefinition, WildPic} from '../../../../../state/models/slots/vgw096';

const { pic1Symbol, pic2Symbol, reels, reelsLayout } = modelDefinition;

/**
 * Returns a list of PIC1/PIC2 stacks that are fully visible in the slot window. A stack here means two vertically-
 * adjacent PIC1s or PIC2s. The return value lists each stack by the position of its upper symbol as a [row, col] cell.
 * These PIC1/PIC2 stacks are the candidates for the WILD PIC feature of free spins (not all candidates become WILD PICs).
 */
export function findWildPics(reelStripPositions: number[]): WildPic[] {
    const result: WildPic[] = [];
    for (let col = 0; col < reels.length; ++col) {
        const reel = reels[col];
        for (let row = 0; row < reelsLayout[col] - 1; ++row) {
            const sym = reel[(reelStripPositions[col] + row) % reel.length];
            if (sym !== pic1Symbol && sym !== pic2Symbol) continue;
            const sym2 = reel[(reelStripPositions[col] + row + 1) % reel.length];
            if (sym2!== sym) continue;
            result.push({ pos: [row, col], type: sym });
            row += 1;
        }
    }
    return result;
}
