import type { Choices } from '../../../choices/choices';
import type { CasePrizesByCell, Cell } from '../../../shared/index';

export function selectCasePrizes(
    caseCells: Cell[],
    choices: Choices,
    coinAmount: number,
    oldPrizes: CasePrizesByCell,
): CasePrizesByCell {
    const casePrizes: CasePrizesByCell = [];
    const unavailablePrizes = oldPrizes.map((cp) => cp.prize ?? 0);

    for (const cell of caseCells) {
        const prize = choices.chooseCasePrize({ cell, unavailablePrizes });
        const value = coinAmount * prize;
        casePrizes.push({ cell, prize, value });
        unavailablePrizes.push(prize);
    }
    return casePrizes;
}
