import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public isActive = false;
    public bonusOption = false;
    public internal_name = "";
    public shipNo = 3;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isActive = false;
        this.show = true;
        this.bonusOption = false;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.shipNo = step.json.shipNo ? step.json.shipNo : 3;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if (step.json.internal_name == "Bonus Trigger") {
            this.bonusOption = true;
        }
    }

    public mounted() {
        this.reelsNo = 5;
        this.isActive = false;
        this.bonusOption = false;
        trace("reels mounted");
    }

    public addNewStep(typeStep) {
        const state = State.state;
        this.reelsNo = 5;
        switch(typeStep){
            case 'Base':
                bus.$emit(events.ADD_STEP, state.setBaseStep());
                break;
            case 'FSTrigger':
                bus.$emit(events.ADD_STEP, state.setFSTriggerStep());
                break;
            case 'FreeSpinStep':
                bus.$emit(events.ADD_STEP, state.addFSStep('FreeSpin Step'));
                break;
            case 'BonusTrigger':
                this.isActive = true;
                this.shipNo = 3;
                bus.$emit(events.ADD_STEP, state.addBonusTrigger());
                break;
            default:
                bus.$emit(events.ADD_STEP, state.getBaseStep());
        }
    }

    public updateStep(shipNo) {
        this.reelsNo = 5;
        this.step.json.shipNo = shipNo;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        this.isActive = true;
    }
}
