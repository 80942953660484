import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { type Cell, filterSlotWindow } from '@vgw/gdk-math-model-tools';
import { CellSchema, GoldCaseFeatureSchema } from '../shared/index';
import type { Choices } from './choices';

export const ScenarioSchema = Type.Object({
    reelStripPositions: Type.Optional(Type.Array(Type.Integer())),
    isOfferAwarded: Type.Optional(Type.Boolean()),
    bankerOfferWinAmount: Type.Optional(Type.Number()),
    isBankerMultiplierOfferAwarded: Type.Optional(Type.Boolean()),
    bankerMultiplierAmount: Type.Optional(Type.Number()),
    isBankerStacksOfferAwarded: Type.Optional(Type.Boolean()),
    bankerStacksSymbol: Type.Optional(Type.Union([Type.Literal('WILD'), Type.Literal('CASE')])),
    bankerStacksReels: Type.Optional(Type.Array(Type.Integer())),
    bankerCase: Type.Optional(Type.Object({ cell: CellSchema, symbol: Type.String() })),
    bankerScat: Type.Optional(Type.Object({ cell: CellSchema, symbol: Type.String() })),
    casePrizes: Type.Optional(Type.Array(Type.Array(Type.Union([Type.Number(), Type.Literal(0), Type.Null()])))),
    goldCases: Type.Optional(Type.Array(Type.Object({ cell: CellSchema, feature: GoldCaseFeatureSchema }))),
});

export type Scenario = Static<typeof ScenarioSchema>;

export function createChoicesFromScenario(scenario: Scenario): Choices {
    return {
        chooseReelStripPositions() {
            return scenario.reelStripPositions ?? [17, 36, 10, 13, 17];
        },
        chooseIfBankerOfferIsAwarded() {
            return scenario.isOfferAwarded ?? false;
        },
        chooseBankerOfferWinAmount(_, playAmount) {
            return (scenario.bankerOfferWinAmount ?? 5) * playAmount;
        },
        chooseIfBankerStacksIsAwarded() {
            return scenario.isBankerStacksOfferAwarded ?? false;
        },
        chooseBankerStacksSymbol() {
            return scenario.bankerStacksSymbol ?? 'WILD';
        },
        chooseIfBankerMultiplierIsAwarded() {
            return scenario.isBankerMultiplierOfferAwarded ?? false;
        },
        chooseBankerMultiplierAmount() {
            return scenario.bankerMultiplierAmount ?? 2;
        },
        chooseBankerStacksReels() {
            return scenario.bankerStacksReels ?? [1, 2, 3];
        },
        chooseIfBankerScatIsAwarded() {
            return scenario.bankerScat !== undefined;
        },
        chooseBankerScatCell() {
            return scenario.bankerScat?.cell ?? [0, 0];
        },
        chooseBankerScatSymbol() {
            return scenario.bankerScat?.symbol ?? 'A';
        },
        chooseIfBankerCaseIsAwarded() {
            return scenario.bankerCase !== undefined;
        },
        chooseBankerCaseCell() {
            return scenario.bankerCase?.cell ?? [0, 0];
        },
        chooseBankerCaseSymbol() {
            return scenario.bankerCase?.symbol ?? 'A';
        },
        chooseIfCaseBecomesGoldCase(cell) {
            function isCellInList(targetCell: Cell, list: Cell[]): boolean {
                return list.find((elem) => elem[0] === targetCell[0] && elem[1] === targetCell[1]) !== undefined;
            }

            return isCellInList(cell, scenario.goldCases?.map((gc) => gc.cell) ?? []);
        },
        chooseCasePrize(info: { cell: Cell; unavailablePrizes: number[] }) {
            const [row, col] = info.cell;
            return scenario.casePrizes?.[col]?.[row] ?? 5;
        },
        chooseHardAndMediumPositions(slotWindow) {
            // Always choose first available non-case as HARD, second available non-case (if any) as MEDIUM
            const candidateCells = filterSlotWindow(slotWindow, (symbol) => symbol !== 'CASE');
            const hardPosition = candidateCells[0];
            const mediumPosition = candidateCells.length > 1 ? candidateCells[1] : undefined;
            return { hardPosition, mediumPosition };
        },
        chooseIfCellBecomesCaseOnRespin({ cell }) {
            // Turn into CASE if _any_ non-falsy prize is specified at that cell position in the scenario
            const [row, col] = cell;
            const becomesCase = Boolean(scenario.casePrizes?.[col]?.[row]);
            return becomesCase;
        },
        chooseGoldCaseFeature(goldCaseIndex) {
            const feature = scenario.goldCases?.[goldCaseIndex]?.feature;
            if (!feature || feature === 'COLLECT') return 'COLLECT';
            if (feature === 'BOOST') return 'BOOST';
            return 'MULTIPLY';
        },
        chooseGoldCaseMultiplier(goldCaseIndex) {
            const feature = scenario.goldCases?.[goldCaseIndex]?.feature;
            const multiplier = feature?.startsWith('MULTIPLYx') ? (Number(feature.slice(-1)) as 2 | 3 | 4 | 5) : 2;
            return multiplier;
        },
        chooseIfGoldCaseFeatureIsKept() {
            return true;
        },
    };
}
