var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "jake-is-smart-man" }, [
    _c("p", [
      _c("label", { staticClass: "card-type", attrs: { for: "card-type" } }, [
        _vm._v("Select Reel:"),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.details.reel,
              expression: "details.reel",
            },
          ],
          attrs: { id: "card-type" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.details,
                "reel",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: 0 } }, [_vm._v("1")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 1 } }, [_vm._v("2")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 2 } }, [_vm._v("3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 3 } }, [_vm._v("4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 4 } }, [_vm._v("5")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _c(
        "label",
        { staticClass: "card-type", attrs: { for: "card-position" } },
        [_vm._v("Select Reel Strip Position:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.details.reelPosition,
              expression: "details.reelPosition",
            },
          ],
          attrs: { id: "card-position" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.details,
                "reelPosition",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: 0 } }, [_vm._v("1")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 1 } }, [_vm._v("2")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 2 } }, [_vm._v("3")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 3 } }, [_vm._v("4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 4 } }, [_vm._v("5")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _c(
        "label",
        { staticClass: "card-type", attrs: { for: "card-candy-cane-type" } },
        [_vm._v("Select Candy Cane Type:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.details.candyCaneType,
              expression: "details.candyCaneType",
            },
          ],
          attrs: { id: "card-candy-cane-type" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.details,
                "candyCaneType",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: "TOP_LEFT" } }, [
            _vm._v("TOP_LEFT"),
          ]),
          _vm._v(" "),
          _c("option", { domProps: { value: "TOP_RIGHT" } }, [
            _vm._v("TOP_RIGHT"),
          ]),
          _vm._v(" "),
          _c("option", { domProps: { value: "BOTTOM_LEFT" } }, [
            _vm._v("BOTTOM_LEFT"),
          ]),
          _vm._v(" "),
          _c("option", { domProps: { value: "BOTTOM_RIGHT" } }, [
            _vm._v("BOTTOM_RIGHT"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }