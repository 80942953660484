import Vue, {PropType} from 'vue';
import { ChestPrize, modelDefinition } from '../../../../state/models/slots/vgw096';
import type Content from './content.vue';

export default Vue.component('Chests', {
    props: {
        content: {
            type: Object as PropType<InstanceType<typeof Content>>,
        },
        chests: { type: Array as PropType<ChestPrize[]> },
    },

    data: () => ({
        standardPrizesText: [] as string[],
    }),

    computed: {
        chestTypeOptions() {
            return [
                { label: 'Type A', value: 'A' },
                { label: 'Type B', value: 'B' },
                { label: 'Type C', value: 'C' },
            ]
        },
        standardPrizeOptions(): number[] {
            return modelDefinition.chests.standardPrizeWeights.A.map(it => it.outcome);
        },
        isStandardPrizesTextValid(): boolean[] {
            return this.standardPrizesText.map(value => {
                const prizes = parseStandardPrizesText(value);
                const isValid = !!prizes;
                return isValid;
            });
        },
        specialPrizeOptions() {
            return [
                { label: '(none)', value: undefined },
                { label: 'MINI', value: 'MINI' },
                { label: 'MINOR', value: 'MINOR' },
                { label: 'COLLECT', value: 'COLLECT' },
                { label: 'BOOST x1', value: 'BOOSTx1' },
                { label: 'BOOST x2', value: 'BOOSTx2' },
                { label: 'BOOST x3', value: 'BOOSTx3' },
                { label: 'BOOST x5', value: 'BOOSTx5' },
                { label: 'BOOST x10', value: 'BOOSTx10' },
            ];
        },
    },

    methods: {
        getChestType(index: number): ChestPrize['type'] {
            return this.chests[index].type;
        },
        setChestType(index: number, value: ChestPrize['type']): void {
            this.chests[index].type = value;
            this.content.saveChangesToStep();
        },
        getStandardPrizes(index: number): string {
            return this.standardPrizesText[index];
        },
        setStandardPrizes(index: number, value: string): void {
            this.standardPrizesText.splice(index, 1, value);
            const prizes = parseStandardPrizesText(value);
            if (prizes) {
                this.chests[index].standard = prizes;
                this.content.saveChangesToStep();
            }
        },
        getSpecialPrize(index: number): ChestPrize['special'] {
            return this.chests[index].special;
        },
        setSpecialPrize(index: number, value: ChestPrize['special']): void {
            this.chests[index].special = value || undefined;
            this.content.saveChangesToStep();
        },
    },

    watch: {
        chests(newValue: ChestPrize[]) {
            this.standardPrizesText = newValue.map(cp => cp.standard.join(', '));
        },
    },
});

function parseStandardPrizesText(value: string): number[] | undefined {
    const prizes = value.split(/ *, */).map(p => Number(p));
    const isValid = prizes.every(p => modelDefinition.chests.standardPrizeWeights.A.find(it => it.outcome === p));
    return isValid ? prizes : undefined;
}
