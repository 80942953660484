var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { margin: "0.5rem" } }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("Jackpot frame 1:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.jackpotFrame1,
                expression: "jackpotFrame1",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "jackpotFrame1", id: "jackpotFrame1" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.jackpotFrame1 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangejackpotFrame1,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "true", selected: "" } }, [
              _vm._v("true"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "false" } }, [_vm._v("false")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { margin: "0.5rem" } }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("Jackpot frame 2:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.jackpotFrame2,
                expression: "jackpotFrame2",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "jackpotFrame2", id: "jackpotFrame2" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.jackpotFrame2 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangejackpotFrame2,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "true", selected: "" } }, [
              _vm._v("true"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "false" } }, [_vm._v("false")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { margin: "0.5rem" } }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("Jackpot frame 3:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.jackpotFrame3,
                expression: "jackpotFrame3",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "jackpotFrame3", id: "jackpotFrame3" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.jackpotFrame3 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangejackpotFrame3,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "true", selected: "" } }, [
              _vm._v("true"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "false" } }, [_vm._v("false")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { margin: "0.5rem" } }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("pic1 Freespin Win:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dynamiteFreespinWin,
                expression: "dynamiteFreespinWin",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "dynamiteFreespinWin", id: "dynamiteFreespinWin" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.dynamiteFreespinWin = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeDynamiteFreespinWin,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "0", selected: "" } }, [
              _vm._v("0"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("meta Stage:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.metaStage,
                expression: "metaStage",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "metaStage", id: "metaStage" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.metaStage = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeMetaStage,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "-1", selected: "" } }, [
              _vm._v("leave"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("meta Stage Counter:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.metaStageCntr,
                expression: "metaStageCntr",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "metaStageCntr", id: "metaStageCntr" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.metaStageCntr = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeMetaStageCntr,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "-1", selected: "" } }, [
              _vm._v("leave"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "11" } }, [_vm._v("11")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "13" } }, [_vm._v("13")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "14" } }, [_vm._v("14")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "16" } }, [_vm._v("16")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "17" } }, [_vm._v("17")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "18" } }, [_vm._v("18")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "19" } }, [_vm._v("19")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }