var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isBankerAllowed("offer") || _vm.isInBankerOfferStep,
                expression: "!isBankerAllowed('offer') || isInBankerOfferStep",
              },
            ],
            staticClass: "custom-steps",
          },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCaseNotTrigger || _vm.isCaseEndPhase,
                    expression: "isCaseNotTrigger || isCaseEndPhase",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.addClearingStep()
                  },
                },
              },
              [_vm._v("Add clearing step")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCaseNotTrigger || _vm.isCaseEndPhase,
                    expression: "isCaseNotTrigger || isCaseEndPhase",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.addStep()
                  },
                },
              },
              [_vm._v("Add step")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCaseNotTrigger || _vm.isCaseEndPhase,
                    expression: "isCaseNotTrigger || isCaseEndPhase",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.addFreeSpinStep()
                  },
                },
              },
              [_vm._v("Trigger Free Spins")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCaseNotTrigger || _vm.isCaseEndPhase,
                    expression: "isCaseNotTrigger || isCaseEndPhase",
                  },
                ],
                on: { click: _vm.addJackpot },
              },
              [_vm._v("Trigger GRAND Jackpot")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCaseNotTrigger || _vm.isCaseEndPhase,
                    expression: "isCaseNotTrigger || isCaseEndPhase",
                  },
                ],
                on: { click: _vm.addCaseChaseTrigger },
              },
              [_vm._v("Add Case Chase trigger")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCaseNotTrigger && !_vm.isCaseEndPhase,
                    expression: "!isCaseNotTrigger && !isCaseEndPhase",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.addCasePrize()
                  },
                },
              },
              [_vm._v("Add Case Chase step")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isBankerAllowed("offer") && !_vm.isInBankerOfferStep,
                expression: "isBankerAllowed('offer') && !isInBankerOfferStep",
              },
            ],
            staticClass: "custom-steps",
          },
          [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.addBankerOffer()
                  },
                },
              },
              [_vm._v("Add Banker Offer step")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
      _vm._v(" "),
      _c("div", { staticClass: "banker-feature-selector" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCasePrizeActive && !_vm.isInBankerOfferStep,
                expression: "!isCasePrizeActive && !isInBankerOfferStep",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.getBankerButtonState("multiplier"),
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBankerFeature("multiplier")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s("Banker Multiplier")),
                _c("br"),
                _vm._v(
                  " [ " +
                    _vm._s(_vm.scenario.bankerMultiplierAmount) +
                    "x ]\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.getBankerButtonState("stack"),
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBankerFeature("stack")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s("Banker Stacks")),
                _c("br"),
                _vm._v(
                  " [ " + _vm._s(_vm.scenario.bankerStacksSymbol) + " ]\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.getBankerButtonState("scatter"),
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBankerFeature("scatter")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s("Banker Scatter")),
                _c("br"),
                _vm._v(
                  " [ " +
                    _vm._s(_vm.scenario.bankerScat.symbol) +
                    " ] [ " +
                    _vm._s(_vm.scenario.bankerScat.cell[0]) +
                    ", " +
                    _vm._s(_vm.scenario.bankerScat.cell[1]) +
                    " ]\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.getBankerButtonState("case"),
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBankerFeature("case")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s("Banker Case")),
                _c("br"),
                _vm._v(
                  " [ " +
                    _vm._s(_vm.scenario.bankerCase.symbol) +
                    " ] [ " +
                    _vm._s(_vm.scenario.bankerCase.cell[0]) +
                    ", " +
                    _vm._s(_vm.scenario.bankerCase.cell[1]) +
                    " ]\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.getBankerButtonState("offer"),
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleBankerFeature("offer")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s("Banker Offer")),
                _c("br"),
                _vm._v(
                  " [ " +
                    _vm._s(_vm.scenario.bankerOfferWinAmount) +
                    "x ]\n      "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCasePrizeActive && !_vm.isInBankerOfferStep,
                expression: "!isCasePrizeActive && !isInBankerOfferStep",
              },
            ],
            staticClass: "flex-center",
          },
          [
            _c(
              "div",
              {
                class: [
                  "button",
                  "step-button",
                  "pointer",
                  _vm.isCaseNotTrigger
                    ? "btn-toggle-is-disabled"
                    : "btn-toggle-is-active",
                  _vm.isCoinPrizesSelected ? "btn-toggle-is-selected" : "",
                ],
                on: {
                  click: function ($event) {
                    return _vm.toggleCoinPrizes()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s("Coin prizes") + "\n      ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCasePrizeActive,
                expression: "!isCasePrizeActive",
              },
            ],
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBankerMultiplierSelected,
                    expression: "isBankerMultiplierSelected",
                  },
                ],
                staticClass: "banker-multiplier-controls",
              },
              [
                _vm._v("\n        Prerequisites to trigger  \n        "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scenario.bankerMultiplierAmount,
                        expression: "scenario.bankerMultiplierAmount",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.scenario,
                          "bankerMultiplierAmount",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 0 } }, [
                      _vm._v("Banker Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 2 } }, [
                      _vm._v("2x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 3 } }, [
                      _vm._v("3x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 4 } }, [
                      _vm._v("4x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 5 } }, [
                      _vm._v("5x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 6 } }, [
                      _vm._v("6x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 7 } }, [
                      _vm._v("7x Multiplier"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: 10 } }, [
                      _vm._v("10x Multiplier"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBankerStacksSelected,
                    expression: "isBankerStacksSelected",
                  },
                ],
                staticClass: "banker-stacks-controls",
              },
              [
                _vm._v("\n        Banker Stacks Type: \n        "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scenario.bankerStacksSymbol,
                        expression: "scenario.bankerStacksSymbol",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.scenario,
                          "bankerStacksSymbol",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "NONE" } }, [
                      _vm._v("None"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "WILD" } }, [
                      _vm._v("WILD Stacks"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "CASE" } }, [
                      _vm._v("CASE Stacks"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "reel-selectors" },
                  [
                    _c("label", [_vm._v("Stack Reels:")]),
                    _vm._v(" "),
                    _vm._l(5, function (_, index) {
                      return _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.scenario.bankerStacksReels,
                            expression: "scenario.bankerStacksReels",
                          },
                        ],
                        key: index,
                        attrs: {
                          type: "checkbox",
                          disabled: index === 0 || index === 4,
                        },
                        domProps: {
                          value: index,
                          checked: Array.isArray(_vm.scenario.bankerStacksReels)
                            ? _vm._i(_vm.scenario.bankerStacksReels, index) > -1
                            : _vm.scenario.bankerStacksReels,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.scenario.bankerStacksReels,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = index,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.scenario,
                                    "bankerStacksReels",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.scenario,
                                    "bankerStacksReels",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.scenario, "bankerStacksReels", $$c)
                            }
                          },
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBankerScatterSelected,
                    expression: "isBankerScatterSelected",
                  },
                ],
                staticClass: "banker-scat-controls",
              },
              [
                _c("div", { staticClass: "scat-symbol-selector" }, [
                  _vm._v("\n          Prerequisites to trigger :\n          "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("label", [_vm._v("Replace Scatter with symbol :")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenario.bankerScat.symbol,
                          expression: "scenario.bankerScat.symbol",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.scenario.bankerScat,
                            "symbol",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "NONE" } }, [
                        _vm._v("NONE"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "T" } }, [_vm._v("10")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "N" } }, [_vm._v("9")]),
                    ]
                  ),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        `on row ${_vm.scenario.bankerScat.cell[0]} and reel ${_vm.scenario.bankerScat.cell[1]}.`
                      ) +
                      "\n          "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n          Symbol selection restrictions on game server :\n          "
                  ),
                  _vm._m(2),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBankerCaseSelected,
                    expression: "isBankerCaseSelected",
                  },
                ],
                staticClass: "banker-case-controls",
              },
              [
                _c("div", { staticClass: "case-symbol-selector" }, [
                  _vm._v("\n          Prerequisites to trigger :\n          "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("label", [_vm._v("Replace Case with symbol :")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenario.bankerCase.symbol,
                          expression: "scenario.bankerCase.symbol",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.scenario.bankerCase,
                            "symbol",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "NONE" } }, [
                        _vm._v("NONE"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "T" } }, [_vm._v("10")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "N" } }, [_vm._v("9")]),
                    ]
                  ),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        `on row ${_vm.scenario.bankerCase.cell[0]} and reel ${_vm.scenario.bankerCase.cell[1]}.`
                      ) +
                      "\n          "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n          Position selection restrictions on game server :\n          "
                  ),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n          Symbol selection restrictions on game server :\n          "
                  ),
                  _vm._m(5),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBankerOfferSelected || _vm.isInBankerOfferStep,
                    expression: "isBankerOfferSelected || isInBankerOfferStep",
                  },
                ],
                staticClass: "banker-offer-controls",
              },
              [
                !_vm.isInBankerOfferStep
                  ? _c("div", { staticClass: "offer-amount-selector" }, [
                      _vm._v(
                        "\n          Prerequisites to trigger :\n          "
                      ),
                      _vm._m(6),
                      _vm._v(" "),
                      _c("label", [_vm._v("Offer amount multiplier :")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.scenario.bankerOfferWinAmount,
                              expression: "scenario.bankerOfferWinAmount",
                              modifiers: { number: true },
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.scenario,
                                "bankerOfferWinAmount",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: 0 } }, [
                            _vm._v("0"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 5 } }, [
                            _vm._v("5"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 10 } }, [
                            _vm._v("10"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 15 } }, [
                            _vm._v("15"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 20 } }, [
                            _vm._v("20"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 25 } }, [
                            _vm._v("25"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: 50 } }, [
                            _vm._v("50"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInBankerOfferStep
                  ? _c("div", [
                      _c("label", [
                        _vm._v(
                          "Banker offer amount: " +
                            _vm._s(
                              _vm.currentStep?.gameState?.bankerOfferAmount ?? 0
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [
                            "button",
                            "step-button",
                            "pointer",
                            _vm.scenario.hasAcceptedBankerOffer
                              ? "btn-toggle-is-active"
                              : "",
                          ],
                          on: { click: _vm.acceptOffer },
                        },
                        [_vm._v("ACCEPT")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [
                            "button",
                            "step-button",
                            "pointer",
                            _vm.scenario.hasAcceptedBankerOffer
                              ? ""
                              : "btn-toggle-is-active",
                          ],
                          on: { click: _vm.declineOffer },
                        },
                        [_vm._v("DECLINE")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.isCaseNotTrigger &&
                  (_vm.isCasePrizeActive || _vm.isCoinPrizesSelected),
                expression:
                  "!isCaseNotTrigger && (isCasePrizeActive || isCoinPrizesSelected)",
              },
            ],
            staticClass: "case-prizes-controls",
          },
          [
            _c(
              "div",
              { staticClass: "slot-window-cases card" },
              [
                _vm._l(_vm.scenario.casePrizes, function (reel, reelIndex) {
                  return [
                    _vm._l(reel, function (prize, rowIndex) {
                      return [
                        _c(
                          "div",
                          {
                            key: `${reelIndex}-${rowIndex}`,
                            class: {
                              "case-symbol": true,
                              "case-with-prize":
                                !_vm.isGoldCaseSelected(reelIndex, rowIndex) &&
                                prize !== null,
                              "case-with-gold": _vm.isGoldCaseSelected(
                                reelIndex,
                                rowIndex
                              ),
                            },
                            style: {
                              "grid-column": reelIndex + 1,
                              "grid-row": rowIndex + 1,
                            },
                          },
                          [
                            _vm.isCaseDisabled(reelIndex, rowIndex)
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getCasePickCaseWord(
                                          reelIndex,
                                          rowIndex
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isCaseDisabled(reelIndex, rowIndex) &&
                            !_vm.isGoldCaseSelected(reelIndex, rowIndex)
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value:
                                          _vm.scenario.casePrizes[reelIndex][
                                            rowIndex
                                          ],
                                        expression:
                                          "scenario.casePrizes[reelIndex][rowIndex]",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    attrs: {
                                      disabled: _vm.isCaseDisabled(
                                        reelIndex,
                                        rowIndex
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return _vm._n(val)
                                            })
                                        _vm.$set(
                                          _vm.scenario.casePrizes[reelIndex],
                                          rowIndex,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("None")]
                                    ),
                                    _vm._v(" "),
                                    _vm.canSelectGrandCase(reelIndex, rowIndex)
                                      ? _c(
                                          "option",
                                          { domProps: { value: _vm.GRAND_ID } },
                                          [_vm._v("GRAND")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.availableCasePrizes,
                                      function (prize) {
                                        return [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.isCasePrizeSelected(
                                                    prize
                                                  ),
                                              },
                                              domProps: { value: prize },
                                            },
                                            [_vm._v(_vm._s(prize))]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.isGoldPickStep &&
                                      (!_vm.isCaseDisabled(
                                        reelIndex,
                                        rowIndex
                                      ) ||
                                        _vm.isGoldCasePicked(
                                          reelIndex,
                                          rowIndex
                                        )),
                                    expression:
                                      "!isGoldPickStep && (!isCaseDisabled(reelIndex,rowIndex) || isGoldCasePicked(reelIndex,rowIndex))",
                                  },
                                ],
                                class: [
                                  "button",
                                  "step-button",
                                  "pointer",
                                  _vm.isGoldCasePicked(reelIndex, rowIndex)
                                    ? "btn-toggle-is-gold-disabled"
                                    : _vm.isGoldCaseSelected(
                                        reelIndex,
                                        rowIndex
                                      )
                                    ? "btn-toggle-is-gold"
                                    : _vm.isCaseDisabled(reelIndex, rowIndex)
                                    ? "btn-toggle-is-disabled"
                                    : "",
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleGoldCase(
                                      reelIndex,
                                      rowIndex
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.isGoldCasePicked(reelIndex, rowIndex)
                                        ? _vm.getGoldPickCaseWord(
                                            reelIndex,
                                            rowIndex
                                          )
                                        : _vm.isGoldCaseSelected(
                                            reelIndex,
                                            rowIndex
                                          )
                                        ? "GOLD"
                                        : "NOT GOLD"
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.isGoldPickStep &&
                                      (!_vm.isCaseDisabled(
                                        reelIndex,
                                        rowIndex
                                      ) ||
                                        _vm.isGoldCasePicked(
                                          reelIndex,
                                          rowIndex
                                        ) ||
                                        _vm.isGoldCasePickable(
                                          reelIndex,
                                          rowIndex
                                        )),
                                    expression:
                                      "isGoldPickStep && (!isCaseDisabled(reelIndex,rowIndex) || isGoldCasePicked(reelIndex,rowIndex) || isGoldCasePickable(reelIndex,rowIndex))",
                                  },
                                ],
                                class: [
                                  "button",
                                  "step-button",
                                  "pointer",
                                  _vm.isGoldCasePicked(reelIndex, rowIndex)
                                    ? "btn-toggle-is-gold-disabled"
                                    : _vm.isGoldCasePickable(
                                        reelIndex,
                                        rowIndex
                                      )
                                    ? "btn-toggle-is-gold"
                                    : _vm.isCaseDisabled(reelIndex, rowIndex)
                                    ? "btn-toggle-is-disabled"
                                    : "",
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.pickGoldCase(reelIndex, rowIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.getGoldPickCaseWord(
                                        reelIndex,
                                        rowIndex
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(_vm.scenario.goldCases, function (goldCase, goldCaseIndex) {
              return [
                _vm._v(
                  "\n          Gold case " +
                    _vm._s(goldCaseIndex + 1) +
                    " :\n          "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: goldCase.feature,
                        expression: "goldCase.feature",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          goldCase,
                          "feature",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "COLLECT" } }, [
                      _vm._v("collect"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MULTIPLYx2" } }, [
                      _vm._v("x2"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MULTIPLYx3" } }, [
                      _vm._v("x3"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MULTIPLYx4" } }, [
                      _vm._v("x4"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "MULTIPLYx5" } }, [
                      _vm._v("x5"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "BOOST" } }, [
                      _vm._v("boost"),
                    ]),
                  ]
                ),
                _vm._v("\n            prize :\n          "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value:
                          _vm.scenario.casePrizes[goldCase.cell[1]][
                            goldCase.cell[0]
                          ],
                        expression:
                          "scenario.casePrizes[goldCase.cell[1]][goldCase.cell[0]]",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      disabled: _vm.isCaseDisabled(
                        goldCase.cell[1],
                        goldCase.cell[0]
                      ),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.scenario.casePrizes[goldCase.cell[1]],
                          goldCase.cell[0],
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("None"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableCasePrizes, function (prize) {
                      return [
                        _c(
                          "option",
                          {
                            attrs: { disabled: _vm.isCasePrizeSelected(prize) },
                            domProps: { value: prize },
                          },
                          [_vm._v(_vm._s(prize))]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("br"),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isCasePrizeActive,
              expression: "!isCasePrizeActive",
            },
          ],
          staticClass: "reel-position-selectors",
        },
        [
          _c("label", [_vm._v("Reel strips:")]),
          _vm._v(" "),
          _vm._l(_vm.scenario.reelStripPositions, function (position) {
            return _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: position.value,
                  expression: "position.value",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: position.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(position, "value", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("slot-window", {
        attrs: {
          spinOutcome: _vm.isBankerActive("offer")
            ? _vm.spinOutcomePostBankerOffer
            : _vm.spinOutcome,
          goldCases: _vm.scenario.goldCases,
          scenario: _vm.scenario,
          availableBankerCasePositions: _vm.availableBankerCasePositions(
            _vm.spinOutcome
          ),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("< 3 SCAT symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("< 6 CASE symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("win > 0")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("exactly 3 SCAT symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("< 6 CASE symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("only SCAT pay, no other win")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("Selected symbol does not generate a win.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Royals on the same reel as the replaced SCATTER symbol are excluded from selection."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("exactly 6 CASE symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("no Prize Stacks with CASE")]),
      _vm._v(" "),
      _c("li", [_vm._v("win amount is 0")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("Top and bottom positions only on reel with 3x CASE")]),
      _vm._v(" "),
      _c("li", [_vm._v("Middle position only on reel with 2x CASE")]),
      _vm._v(" "),
      _c("li", [_vm._v("Not available on reel with 1x CASE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v("Royals from the 1st reel are excluded from selection."),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Royals on same the reel as the replaced CASE symbol are excluded from selection."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [_vm._v("Base game only")]),
      _vm._v(" "),
      _c("li", [_vm._v("No other features triggered")]),
      _vm._v(" "),
      _c("li", [_vm._v("< 3 SCAT symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("< 6 CASE symbols")]),
      _vm._v(" "),
      _c("li", [_vm._v("win amount > 0")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }