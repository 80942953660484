import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";

@Component({
    components: {
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isOpen = false;
    public wheelSpinValue = "0";
    public diceBonusOne = "0";
    public diceBonusTwo = "0";
    public diceBonusThree = "0";
    public diceBonusFour = "";

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;

        if (this.wheelSpinValue != this.step.json.wheelSpinValue) {
            this.wheelSpinValue = this.step.json.wheelSpinValue;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if (this.diceBonusOne != this.step.json.diceBonusOne) {
            this.diceBonusOne = this.step.json.diceBonusOne;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if (this.diceBonusTwo != this.step.json.diceBonusTwo) {
            this.diceBonusTwo = this.step.json.diceBonusTwo;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if (this.diceBonusThree != this.step.json.diceBonusThree) {
            this.diceBonusThree = this.step.json.diceBonusThree;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if (this.diceBonusFour != this.step.json.diceBonusFour) {
            this.diceBonusFour = this.step.json.diceBonusFour;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public updateWheelSpinValue(wheelSpinValue) {
        if (wheelSpinValue == "") {
            wheelSpinValue = "0";
        }

        this.step.json.wheelSpinValue = wheelSpinValue;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDiceBonusOne(diceBonusOne) {
        if (diceBonusOne == "") {
            diceBonusOne = "0";
        }

        this.step.json.diceBonusOne = diceBonusOne;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDiceBonusTwo(diceBonusTwo) {
        if (diceBonusTwo == "") {
            diceBonusTwo = "0";
        }

        this.step.json.diceBonusTwo = diceBonusTwo;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDiceBonusThree(diceBonusThree) {
        if (diceBonusThree == "") {
            diceBonusThree = "0";
        }

        this.step.json.diceBonusThree = diceBonusThree;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDiceBonusFour(diceBonusFour) {
        if (diceBonusFour == "") {
            diceBonusFour = "0";
        }

        this.step.json.diceBonusFour = diceBonusFour;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
