var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            staticClass: "flex-center fs-selector sm-label",
            on: {
              change: function ($event) {
                return _vm.updateSpell(_vm.isSpell)
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFsStep,
                    expression: "(!isFsStep)",
                  },
                ],
                staticClass: "select-style",
              },
              [
                _c("label", { attrs: { for: "isSpell" } }, [_vm._v("Spell: ")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isSpell,
                        expression: "isSpell",
                      },
                    ],
                    staticStyle: {
                      color: "#00c700",
                      "font-weight": "bold",
                      "font-size": "small",
                      "padding-left": "50px",
                    },
                    attrs: { id: "isSpell" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.isSpell = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("Random")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("isSpell"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("noSpell"),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSpellSymbol,
                  expression: "(isSpellSymbol)",
                },
              ],
              staticClass: "select-style",
            },
            [
              _c("label", { attrs: { for: "bg_number" } }, [
                _vm._v("Spell symbol: "),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.spellSymbol,
                      expression: "spellSymbol",
                    },
                  ],
                  staticStyle: {
                    color: "#00c700",
                    "font-weight": "bold",
                    "font-size": "small",
                    "padding-left": "50px",
                  },
                  attrs: { id: "bg_number" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.spellSymbol = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "Random" } }, [
                    _vm._v("Random"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "HV1" } }, [_vm._v("HV1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "HV2" } }, [_vm._v("HV2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "HV3" } }, [_vm._v("HV3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "MV1" } }, [_vm._v("MV1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "MV2" } }, [_vm._v("MV2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "MV3" } }, [_vm._v("MV3")]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-center fs-selector sm-space" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "elemOK pointer",
              class: [_vm.isSpell ? "blue" : "elemOK"],
              attrs: { id: "ok" },
              on: {
                click: function ($event) {
                  return _vm.updateStep(_vm.isSpell, _vm.spellSymbol)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }