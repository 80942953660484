var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
      on: {
        click: function ($event) {
          return _vm.selectCell({ row: -1, col: -1 })
        },
      },
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFreeSpinTriggerStep,
                expression: "canAddFreeSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFireshotTriggerStep,
                expression: "canAddFireshotTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFireshotTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Fireshot")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " + _vm._s(_vm.fireshotRespinsRemaining)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Coins Collected: " + _vm._s(_vm.fireshotCoinsCollected))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReelStripPositions,
              expression: "showReelStripPositions",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", { attrs: { content: _vm.content, index: 0 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: _vm.content, index: 1 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: _vm.content, index: 2 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: _vm.content, index: 3 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: _vm.content, index: 4 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: _vm.content, index: 5 } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chests.length > 0,
              expression: "chests.length > 0",
            },
          ],
        },
        [_c("Chests", { attrs: { content: _vm.content, chests: _vm.chests } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }