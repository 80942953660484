import type { Choices } from './choices';
import { modelDefinition } from '../model-definition';
import { createWeightedOutcomeSelector } from '@vgw/gdk-math-model-tools';
import type { JackpotType, PickFeatureAward } from '../operations/shared';

export function createRandomChoices(options: {
    /**
     * Callback used to generate a random number between `min` and `max` inclusive, with uniform probability across
     * numbers in the range. The `chu-rng` library provides a suitable implementation.
     */
    getRandomFromRange: (range: { min: number; max: number }) => number;
}): Choices {
    const fullReelWildsFeatureReelsSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.fullReelSpinFeatureReelWeights,
        getRandomFromRange: options.getRandomFromRange,
    });
    const singleWildsSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.singleWildsFeatureWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    const baseReelSpinFeatureSetSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.reelSpinFeatureSetWeights.base,
        getRandomFromRange: options.getRandomFromRange,
    });

    const freeSpinReelSpinFeatureSetSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.reelSpinFeatureSetWeights.freeSpin,
        getRandomFromRange: options.getRandomFromRange,
    });

    const characterWildsFeatureReelsSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.characterWildFeatureReelWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    const numberOfMultipliersSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.numberOfMultipliersWeights,
        getRandomFromRange: options.getRandomFromRange,
    });
    const multiplierSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.multiplierWeights,
        getRandomFromRange: options.getRandomFromRange,
    });
    const deliverySequenceSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.deliverySequenceWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    const freeSpinsSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.freeSpinAwardWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    function createCoinWeightBasedOutcomeSelector(args: {
        weightedOutcomes: { weight: 'COIN_WEIGHT' | number; outcome: 'MINI' | 'MINOR' | 'PROG' }[];
        coinWeight: number;
        getRandomFromRange: (range: { min: number; max: number }) => number;
    }) {
        const { weightedOutcomes, coinWeight } = args;
        return createWeightedOutcomeSelector({
            weightedOutcomes: weightedOutcomes.map((weightedOutcome) => {
                const weight = weightedOutcome.weight !== 'COIN_WEIGHT' ? weightedOutcome.weight : coinWeight;
                return {
                    ...weightedOutcome,
                    weight,
                };
            }),
            getRandomFromRange: options.getRandomFromRange,
        });
    }

    const progressiveJackpotSelector = createWeightedOutcomeSelector({
        weightedOutcomes: modelDefinition.progressiveJackpotWeights,
        getRandomFromRange: options.getRandomFromRange,
    });

    return {
        chooseReelStripPositions() {
            return modelDefinition.reels.map((reel) => options.getRandomFromRange({ min: 0, max: reel.length - 1 }));
        },
        chooseReelSpinFeatureSet(isFreeSpin) {
            const selector = isFreeSpin ? freeSpinReelSpinFeatureSetSelector : baseReelSpinFeatureSetSelector;
            return selector();
        },
        chooseFullReelWildsFeatureReels() {
            const numberOfFullReelWildsReels = fullReelWildsFeatureReelsSelector();
            const availableReels = [0, 1, 2, 3, 4];
            const selectedReels:number[] = [];

            for (let counter = 0; counter < numberOfFullReelWildsReels; counter++) {
                const selectedReelIndex = options.getRandomFromRange({ min: 0, max: availableReels.length - 1 });
                selectedReels.push(...availableReels.splice(selectedReelIndex, 1));
            }
            return selectedReels;
        },
        chooseWhetherReelSpinFeaturesApplied(chance: number, outOf: number): boolean {
            return options.getRandomFromRange({ min: 1, max: outOf }) <= chance;
        },
        chooseCharacterWilds(): ('PIC1' | 'PIC2' | 'PIC3')[] {
            return characterWildsFeatureReelsSelector() as ('PIC1' | 'PIC2' | 'PIC3')[];
        },
        chooseSingleWilds() {
            const numberOfSingleWilds = singleWildsSelector();
            const availableCellPositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
            const selectedWilds: [number, number][] = [];

            for (let counter = 0; counter < numberOfSingleWilds; counter++) {
                const selectedCellPositionIndex = options.getRandomFromRange({
                    min: 0,
                    max: availableCellPositions.length - 1,
                });

                const selectedCellPosition = availableCellPositions.splice(selectedCellPositionIndex, 1)[0];

                const numberOfSymbolsPerReel = modelDefinition.reelsLayout[0];

                const selectedReelIndex = Math.floor(selectedCellPosition / numberOfSymbolsPerReel);
                const selectedRowIndex = selectedCellPosition % numberOfSymbolsPerReel;
                selectedWilds.push([selectedRowIndex, selectedReelIndex]);
            }

            return selectedWilds;
        },
        chooseMultiplierCells(): { cell: [number, number]; multiplier: number }[] {
            const numberOfMultipliers = numberOfMultipliersSelector();
            const availableCellPositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

            const selectedMultiplierCells: { cell: [number, number]; multiplier: number }[] = [];

            for (let counter = 0; counter < numberOfMultipliers; counter++) {
                const selectedCellPositionIndex = options.getRandomFromRange({
                    min: 0,
                    max: availableCellPositions.length - 1,
                });
                const selectedCellPosition = availableCellPositions.splice(selectedCellPositionIndex, 1)[0];
                const numberOfSymbolsPerReel = modelDefinition.reelsLayout[0];

                const selectedReelIndex = Math.floor(selectedCellPosition / numberOfSymbolsPerReel);
                const selectedRowIndex = selectedCellPosition % numberOfSymbolsPerReel;
                selectedMultiplierCells.push({
                    cell: [selectedRowIndex, selectedReelIndex],
                    multiplier: multiplierSelector(),
                });
            }

            return selectedMultiplierCells;
        },
        chooseReelSpinDeliverySequence() {
            return deliverySequenceSelector();
        },
        chooseFeatureDeliveryOrder() {
            let features: ('FRW' | 'CW' | 'SW' | 'M')[] = ['FRW', 'CW', 'SW', 'M'];
            features = features.sort(() => Math.random() - 0.5);
            return features;
        },
        chooseWhetherRemainingFeatureDeliveredBeforeReelsStop() {
            return options.getRandomFromRange({ min: 1, max: 100 }) <= 50;
        },
        chooseFreeSpins() {
            return freeSpinsSelector();
        },
        choosePickAwards() {
            const selectedFeatures: PickFeatureAward[] = [];
            const pickFeatureAwardPool = [...modelDefinition.pickFeatureAwardPool];

            for (let i = 0; i < 9; i++) {
                const selectedFeatureIndex = options.getRandomFromRange({
                    min: 0,
                    max: pickFeatureAwardPool.length - 1,
                });
                const selectedFeature = pickFeatureAwardPool[selectedFeatureIndex];

                selectedFeatures.push(selectedFeature);
                pickFeatureAwardPool.splice(selectedFeatureIndex, 1);

                if (selectedFeature === 'START') break;
            }
            return selectedFeatures;
        },
        shouldIncludeNonWinningMultipliers() {
            return options.getRandomFromRange({ min: 0, max: 1 }) === 1;
        },
        chooseJackpotTriggered({ coinAmount, coinType }) {
            const multiplier = coinType === 'GC' ? 0.01 : 1;
            const coinWeight = coinAmount * multiplier;
            const chance = modelDefinition.jackpotWeightConstants.chance + coinWeight;
            const outOf = modelDefinition.jackpotWeightConstants.outOf;
            return options.getRandomFromRange({ min: 1, max: outOf }) <= chance;
        },
        chooseJackpot({ coinAmount, coinType }) {
            const multiplier = coinType === 'GC' ? 0.01 : 1;
            const coinWeight = coinAmount * multiplier;

            const jackpotTypeSelector = createCoinWeightBasedOutcomeSelector({
                weightedOutcomes: modelDefinition.jackpotTypeWeights,
                getRandomFromRange: options.getRandomFromRange,
                coinWeight,
            });

            let jackpotType: JackpotType | 'PROG' = jackpotTypeSelector();

            if (jackpotType === 'PROG') {
                jackpotType = progressiveJackpotSelector();
            }

            return jackpotType;
        },
    };
}
