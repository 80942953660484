/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {
	return {
		variations: [""],
		getModelDefinition: () =>
			State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
		createDefaultStep: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels");
			step.reelsType = "reels";
			step.rows = State.state.getModelDefinition().reels;
			step.wheelResults = [];
			step.dynamicSymbol = "";
			return step;
		},
		getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
		getRowsNo: () => State.state.getModelDefinition().getLayout(),
		getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
		getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
		getReelStrips: (type) => {
			switch (type) {
				case "reels":
					return State.state.getModelDefinition().reels;
					break;
				default:
					return State.state.getModelDefinition().reels;
			}
		},
		getBaseStep: () => State.state.createDefaultStep(),
		getFeatureTriggerStep: (order = 0) => {
			const step = State.state.createStep([18, 21, 14, 10, 19], false, order, "", "F-Trigger");
			step.reelsType = "reels";
			step.wheelResults = [];
			step.dynamicSymbol = "";
			return step;
		},
		getFeatureStep: () => {
			const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "F-Step");
			step.reelsType = "reels";
			step.wheelResults = [];
			step.dynamicSymbol = "";
			return step;
		},
		prepareStepForDB: (step) => {
			const state = State.state;
			const reelStrips = state.getReelStrips(step.reelsType);
			const noOfReels = 5;
			step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 4);
			step.randoms = step.reelStripPositions;
			return step;
		},
		getClearingStep: function() {
			const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Clear");
			step.wheelResults = [];
			step.dynamicSymbol = "";
			step.gameState = "clear";
			return step;
		},
	};
}
