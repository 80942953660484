/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "vgw056",
        "wildSymbol": "WILD",
        "freeSpinTrigger": "SC",

        "countToTriggerFreeSpin" : 3,
        "freeSpinCount": 10,

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 40 ],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "5000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "50",
        "coinType4_spin_max_bet": "2000",

        "reelsLayout": [3, 3, 3, 3, 3],

        "reels": [
          [ "TE", "PIC4", "K", "PIC3", "PIC5", "PIC4", "Q", "PIC2", "K", "SC", "A", "PIC1", "Q", "PIC2", "WILD", "WILD", "WILD", "PIC1", "PIC5", "Q", "PIC2", "J", "PIC5", "PIC3", "TE", "J", "PIC4", "A", "PIC1", "Q", "TE", "PIC2", "K", "SC", "TE", "PIC1", "A", "K", "PIC3", "A", "PIC5", "PIC4", "A", "Q", "PIC1", "A", "K", "PIC3", "TE", "SC", "PIC2", "J", "K", "PIC2", "A", "PIC4", "K", "J", "PIC1", "Q", "K", "SC", "PIC5", "A", "PIC2", "Q", "PIC3", "J", "PIC2", "PIC5", "PIC4", "TE", "PIC3", "Q", "PIC1", "PIC5", "SC", "K", "PIC2", "J", "PIC4", "PIC5", "PIC3", "TE", "PIC4", "A", "TE", "PIC2", "A", "PIC3", "Q", "WILD", "WILD", "WILD", "PIC2", "TE", "A", "PIC1", "Q", "PIC2", "J", "A", "PIC4", "TE", "Q", "PIC3", "J", "TE", "PIC3", "A", "K", "SC", "A", "PIC2", "K", "PIC3", "A", "PIC1", "Q", "J", "PIC2", "A", "SC", "PIC4", "Q", "PIC5", "PIC1", "K" ],
          [ "Q", "PIC3", "A", "PIC2", "J", "TE", "Q", "PIC1", "TE", "PIC3", "PIC5", "PIC4", "K", "PIC2", "A", "WILD", "WILD", "WILD", "PIC3", "PIC5", "A", "PIC4", "TE", "PIC2", "K", "Q", "PIC4", "PIC5", "TE", "PIC4", "K", "PIC2", "Q", "J", "A", "PIC5", "PIC1", "Q", "A", "PIC3", "K", "PIC2", "Q", "PIC4", "K", "J", "PIC2", "TE", "PIC4", "K", "PIC1", "PIC5", "PIC3", "TE", "PIC5", "PIC4", "K", "PIC1", "A", "PIC2", "PIC5", "PIC3", "A", "J", "K", "PIC4", "Q", "PIC2", "TE", "PIC5", "PIC1", "A", "PIC4", "Q", "PIC2", "A", "PIC3", "PIC5", "PIC4", "K", "PIC4", "A", "PIC2", "Q", "PIC3", "K", "A", "PIC4", "K", "PIC5", "PIC2", "TE", "J", "PIC4", "PIC5", "PIC3", "TE", "PIC4", "A", "PIC3", "K", "PIC1", "Q", "PIC5", "PIC2", "K", "PIC4", "A", "TE", "PIC5", "PIC3", "A", "PIC4", "A", "WILD", "WILD", "WILD", "PIC2", "Q", "A", "PIC4", "Q", "A" ],
          [ "PIC3", "K", "SC", "J", "PIC5", "PIC4", "Q", "PIC3", "TE", "K", "PIC2", "A", "K", "PIC1", "A", "PIC4", "Q", "SC", "TE", "K", "J", "PIC1", "PIC5", "WILD", "WILD", "WILD", "PIC5", "A", "PIC4", "TE", "PIC5", "PIC1", "K", "PIC5", "PIC2", "PIC5", "J", "PIC1", "SC", "TE", "PIC3", "K", "PIC1", "PIC5", "A", "PIC2", "Q", "K", "PIC2", "SC", "PIC1", "J", "PIC1", "A", "TE", "PIC2", "PIC5", "PIC3", "Q", "J", "SC", "A", "PIC4", "J", "PIC1", "A", "TE", "J", "PIC5", "PIC4", "Q", "PIC3", "PIC5", "K", "PIC3", "J", "PIC5", "PIC3", "K", "PIC2", "SC", "Q", "PIC2", "A", "J", "PIC4", "Q", "PIC3", "TE", "PIC4", "A", "PIC3", "Q", "TE", "A", "PIC2", "K", "PIC3", "A", "WILD", "WILD", "WILD", "Q", "PIC1", "A", "PIC4", "Q", "J", "A", "PIC4", "A", "PIC1", "Q", "PIC1", "A", "PIC2", "K", "SC", "A", "PIC2", "Q", "TE", "PIC4", "J" ],
          [ "PIC3", "PIC5", "A", "PIC3", "A", "PIC2", "TE", "PIC4", "J", "Q", "PIC1", "PIC5", "PIC3", "TE", "PIC4", "K", "PIC5", "PIC2", "J", "WILD", "WILD", "WILD", "Q", "A", "PIC1", "Q", "PIC4", "TE", "J", "PIC1", "PIC5", "K", "PIC2", "TE", "PIC3", "A", "PIC4", "PIC5", "Q", "K", "A", "PIC4", "K", "PIC2", "A", "J", "PIC4", "TE", "Q", "PIC2", "J", "K", "PIC4", "PIC5", "PIC3", "TE", "PIC5", "PIC4", "PIC5", "PIC3", "J", "Q", "PIC2", "K", "PIC1", "TE", "Q", "K", "TE", "J", "PIC2", "PIC5", "K", "PIC1", "Q", "PIC3", "A", "WILD", "WILD", "WILD", "PIC2", "PIC5", "K", "A", "PIC1", "TE", "Q", "PIC3", "TE", "PIC4", "PIC5", "J", "PIC2", "K", "PIC3", "Q", "PIC2", "A", "Q", "A", "PIC1", "PIC5", "TE", "PIC2", "K", "A", "PIC4", "J", "PIC3", "Q", "J", "PIC5", "A", "PIC3", "Q", "PIC4", "A", "PIC2", "K", "PIC3", "A", "PIC1", "Q", "PIC2", "J" ],
          [ "A", "PIC1", "K", "J", "PIC4", "TE", "Q", "PIC3", "SC", "TE", "PIC4", "J", "A", "PIC4", "K", "PIC1", "PIC5", "PIC2", "J", "PIC5", "PIC1", "PIC4", "K", "Q", "PIC3", "Q", "PIC1", "A", "PIC2", "PIC5", "SC", "J", "PIC5", "PIC1", "J", "PIC4", "SC", "PIC2", "K", "J", "PIC3", "Q", "PIC2", "K", "PIC2", "Q", "PIC3", "PIC4", "A", "WILD", "WILD", "WILD", "Q", "PIC2", "PIC5", "PIC1", "J", "SC", "Q", "J", "TE", "PIC3", "Q", "A", "PIC2", "PIC5", "PIC4", "J", "PIC2", "PIC5", "PIC4", "K", "PIC5", "SC", "Q", "PIC1", "A", "PIC3", "K", "PIC2", "A", "PIC1", "PIC2", "PIC4", "PIC5", "K", "PIC1", "Q", "PIC3", "A", "PIC4", "Q", "J", "PIC2", "Q", "A", "PIC4", "J", "PIC2", "PIC5", "K", "PIC4", "TE", "SC", "A", "PIC3", "J", "PIC4", "A", "PIC3", "Q", "K", "PIC2", "A", "K", "PIC3", "A", "PIC5", "PIC1", "TE", "PIC2" ]
        ],
        "featureReels": [
          [ "TE", "PIC4", "K", "PIC3", "PIC5", "PIC4", "SC", "PIC2", "K", "PIC5", "A", "PIC1", "Q", "PIC2", "WILD", "WILD", "WILD", "PIC1", "PIC5", "Q", "PIC2", "J", "PIC5", "PIC3", "TE", "J", "PIC4", "A", "PIC1", "SC", "TE", "PIC2", "K", "PIC3", "TE", "PIC1", "A", "K", "PIC3", "A", "PIC5", "PIC4", "A", "Q", "PIC1", "A", "K", "PIC3", "TE", "Q", "SC", "J", "K", "PIC2", "A", "PIC4", "K", "J", "PIC1", "Q", "K", "PIC2", "PIC5", "A", "PIC2", "Q", "PIC3", "J", "PIC4", "PIC5", "PIC4", "TE", "PIC3", "Q", "PIC1", "PIC5", "SC", "K", "PIC2", "J", "PIC4", "PIC5", "PIC3", "TE", "PIC4", "A", "TE", "PIC2", "A", "PIC3", "Q", "WILD", "WILD", "WILD", "PIC2", "TE", "A", "PIC1", "Q", "PIC2", "J", "A", "PIC4", "TE", "Q", "PIC3", "J", "TE", "PIC3", "A", "K", "PIC1", "A", "SC", "K", "PIC3", "A", "PIC1", "Q", "J", "PIC2", "A", "PIC3", "PIC4", "Q", "PIC5", "PIC1", "K" ],
          [ "Q", "PIC3", "A", "PIC2", "J", "TE", "Q", "PIC1", "TE", "PIC3", "PIC5", "PIC4", "K", "PIC2", "A", "PIC2", "PIC3", "PIC4", "PIC1", "PIC5", "A", "PIC4", "TE", "PIC2", "K", "Q", "PIC4", "PIC5", "TE", "PIC4", "K", "PIC2", "Q", "J", "A", "PIC5", "PIC1", "Q", "A", "PIC3", "K", "PIC2", "Q", "PIC4", "K", "J", "PIC2", "TE", "PIC4", "K", "PIC1", "PIC5", "PIC3", "TE", "PIC5", "PIC4", "K", "PIC1", "A", "PIC2", "PIC5", "PIC3", "A", "J", "K", "PIC4", "Q", "PIC2", "TE", "PIC5", "PIC1", "A", "PIC4", "Q", "PIC2", "A", "PIC3", "PIC5", "PIC4", "K", "PIC4", "A", "PIC2", "Q", "PIC3", "K", "A", "PIC4", "K", "PIC5", "PIC2", "TE", "J", "PIC4", "PIC5", "PIC3", "TE", "PIC4", "A", "PIC3", "K", "PIC1", "Q", "PIC5", "PIC2", "K", "PIC4", "A", "TE", "PIC5", "PIC3", "A", "PIC4", "A", "WILD", "WILD", "WILD", "PIC2", "Q", "A", "PIC4", "Q", "A" ],
          [ "PIC3", "K", "PIC4", "J", "PIC5", "PIC4", "SC", "PIC1", "TE", "K", "WILD", "WILD", "WILD", "PIC1", "A", "PIC4", "Q", "PIC2", "TE", "K", "J", "PIC1", "PIC5", "A", "PIC2", "PIC2", "PIC5", "SC", "PIC4", "TE", "PIC5", "PIC1", "K", "PIC5", "PIC2", "PIC5", "J", "PIC1", "PIC2", "TE", "PIC3", "K", "PIC1", "PIC5", "A", "PIC2", "Q", "K", "PIC2", "TE", "PIC4", "J", "PIC1", "A", "SC", "PIC2", "PIC5", "PIC3", "Q", "J", "PIC1", "A", "PIC4", "J", "PIC1", "A", "TE", "J", "PIC5", "PIC4", "SC", "PIC3", "PIC5", "K", "PIC3", "J", "PIC5", "PIC3", "K", "PIC2", "PIC1", "Q", "PIC2", "A", "J", "PIC4", "Q", "PIC3", "SC", "PIC4", "A", "PIC3", "Q", "TE", "A", "PIC2", "K", "PIC3", "A", "WILD", "WILD", "WILD", "Q", "PIC1", "A", "PIC4", "Q", "J", "A", "PIC4", "A", "PIC1", "Q", "PIC5", "A", "PIC2", "K", "PIC4", "A", "PIC2", "Q", "TE", "PIC4", "J" ],
          [ "PIC3", "PIC5", "A", "PIC3", "A", "PIC2", "TE", "PIC4", "J", "Q", "PIC1", "PIC5", "PIC3", "TE", "PIC4", "K", "PIC5", "PIC2", "J", "WILD", "WILD", "WILD", "Q", "A", "PIC1", "Q", "PIC4", "TE", "J", "PIC1", "PIC5", "K", "PIC2", "TE", "PIC3", "A", "PIC4", "PIC5", "Q", "K", "A", "PIC4", "K", "PIC2", "A", "J", "PIC4", "TE", "Q", "PIC2", "J", "K", "PIC4", "PIC5", "PIC3", "TE", "PIC5", "PIC4", "PIC5", "PIC3", "J", "Q", "PIC2", "K", "PIC1", "TE", "Q", "K", "TE", "J", "PIC2", "PIC5", "K", "PIC1", "Q", "PIC3", "A", "WILD", "WILD", "WILD", "PIC2", "PIC5", "K", "A", "PIC1", "TE", "Q", "PIC3", "TE", "PIC4", "PIC5", "J", "PIC2", "K", "PIC3", "Q", "PIC2", "A", "Q", "A", "PIC1", "PIC5", "TE", "PIC2", "K", "A", "PIC4", "J", "PIC3", "Q", "J", "PIC5", "A", "PIC3", "Q", "PIC4", "A", "PIC2", "K", "PIC3", "A", "PIC1", "Q", "PIC2", "J" ],
          [ "A", "PIC1", "K", "J", "PIC4", "TE", "Q", "PIC3", "PIC2", "TE", "PIC4", "J", "SC", "PIC4", "K", "PIC1", "PIC5", "PIC2", "J", "PIC5", "WILD", "WILD", "WILD", "Q", "PIC3", "Q", "PIC1", "A", "PIC2", "PIC5", "PIC1", "J", "PIC5", "PIC1", "J", "PIC4", "TE", "PIC2", "K", "J", "PIC3", "SC", "PIC2", "K", "PIC2", "Q", "PIC3", "PIC4", "A", "PIC2", "PIC3", "J", "Q", "PIC2", "PIC5", "PIC1", "J", "PIC2", "Q", "J", "TE", "PIC3", "SC", "A", "PIC2", "PIC5", "PIC4", "J", "PIC2", "PIC5", "PIC4", "K", "PIC5", "PIC2", "Q", "PIC1", "A", "PIC3", "K", "PIC2", "A", "WILD", "WILD", "WILD", "PIC5", "K", "PIC1", "Q", "PIC3", "A", "PIC4", "Q", "J", "PIC2", "Q", "SC", "PIC4", "J", "PIC2", "PIC5", "K", "PIC4", "TE", "PIC2", "A", "PIC3", "J", "PIC4", "A", "PIC3", "Q", "K", "PIC2", "A", "K", "PIC3", "A", "PIC5", "PIC1", "TE", "PIC2" ]
        ],

        "winTable": [
          {
            "symbol": "WILD",
            "multipliers": [300, 150, 75]
          }, {
            "symbol": "PIC1",
            "multipliers": [200, 100, 50]
          }, {
            "symbol": "PIC2",
            "multipliers": [100, 75, 30]
          }, {
            "symbol": "PIC3",
            "multipliers": [75, 50, 25]
          }, {
            "symbol": "PIC4",
            "multipliers": [60, 25, 15]
          },{
            "symbol": "PIC5",
            "multipliers": [50, 20, 10]
          }, {
            "symbol": "A",
            "multipliers": [45, 15, 8]
          }, {
            "symbol": "K",
            "multipliers": [45, 15, 8]
          }, {
            "symbol": "Q",
            "multipliers": [40, 10, 5]
          }, {
            "symbol": "J",
            "multipliers": [40, 10, 5]
          }, {
            "symbol": "TE",
            "multipliers": [40, 10, 5]
          }, {
            "symbol": "SC",
            "multipliers": [0, 0, 0]
          }
        ],

        "playLines" : [
            [1, 1, 1, 1, 1], // 1
            [0, 0, 0, 0, 0], // 2
            [2, 2, 2, 2, 2], // 3
            [0, 1, 2, 1, 0], // 4
            [2, 1, 0, 1, 2], // 5
            [0, 0, 1, 2, 2], // 6
            [2, 2, 1, 0, 0], // 7
            [1, 0, 1, 2, 1], // 8
            [1, 2, 1, 0, 1], // 9
            [1, 0, 0, 1, 0], // 10
            [1, 2, 2, 1, 2], // 11
            [0, 1, 0, 0, 1], // 12
            [2, 1, 2, 2, 1], // 13
            [0, 2, 0, 2, 0], // 14
            [2, 0, 2, 0, 2], // 15
            [1, 0, 2, 0, 1], // 16
            [1, 2, 0, 2, 1], // 17
            [0, 1, 1, 1, 0], // 18
            [2, 1, 1, 1, 2], // 19
            [0, 2, 2, 2, 0], // 20
            [2, 0, 0, 0, 2], // 21
            [0, 1, 2, 2, 2], // 22
            [2, 1, 0, 0, 0], // 23
            [2, 0, 1, 0, 2], // 24
            [0, 2, 1, 2, 0], // 25
            [0, 0, 2, 0, 0], // 26
            [2, 2, 0, 2, 2], // 27
            [0, 0, 2, 2, 2], // 28
            [2, 2, 0, 0, 0], // 29
            [2, 2, 2, 2, 1], // 30
            [0, 0, 0, 0, 1], // 31
            [0, 0, 0, 0, 2], // 32
            [0, 2, 2, 2, 2], // 33
            [1, 1, 1, 1, 0], // 34
            [1, 1, 1, 1, 2], // 35
            [1, 1, 1, 0, 2], // 36
            [1, 1, 1, 2, 0], // 37
            [0, 0, 0, 2, 1], // 38
            [2, 2, 2, 0, 1], // 39
            [2, 2, 2, 1, 0], // 40
            [0, 0, 0, 1, 2], // 41
            [2, 2, 2, 0, 2], // 42
            [0, 0, 0, 2, 0], // 43
            [1, 1, 0, 2, 1], // 44
            [1, 1, 2, 0, 1], // 45
            [1, 2, 2, 0, 2], // 46
            [0, 2, 2, 0, 0], // 47
            [2, 0, 2, 2, 1], // 48
            [0, 2, 0, 0, 1], // 49
            [2, 2, 1, 2, 1],  // 50
        ],
    },
}
