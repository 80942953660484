import {createDefaultSlotStep} from "./../../../../helpers/helpers";
import State from '../../../state';
import {modelDefinition} from './config/init';

export function setStateParams() {
    return {
        variations: [],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = '', name= 'Base') => {
            const step = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.gameState = isFeature ? 'continue' : 'clear';
            return step;

        },

        // Must implement
        createDefaultStep: (order = 0, variation = '') => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout,

        // unique per model
        getModelDefinition: () => modelDefinition, 
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,
        getFeatureReelStrips: () => modelDefinition.featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFreeSpinTriggerFromBaseGame: (order = 0) => State.state.createStep([24, 13, 2, 27, 32], false, order, '', 'F-Trigger'),
        getFreeSpinStep: (order = 0) => State.state.createStep([54, 49, 105, 76, 125], true, order, '', 'F-Spin'),
        getClearingStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0, 0], false, order, '', 'Clear'),
    };
}
