import Vue from "vue"; 
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../helpers/casino";
import * as device from "./../helpers/device";
import {trace, TODO, getAssetsPath} from "./../helpers/helpers";
import State from "../state/state";
import bus from "./../common/bus";
import LocalStorage from "./../common/localstorage";
import events from "./../common/events";
import SocketIO from "./../socket/socket";

import ListOfScenarios from "./list_of_scenarios/list_of_scenarios.vue"; 
import ListOfSteps from "./list_of_steps/list_of_steps.vue"; 
import GameContent from "./game_content/game_content.vue"; 
import Popup from "./popup/popup.vue"; 
import InputPopup from "./popup/inputpopup/inputpopup.vue"; 

@Component({
    components: {
        "sm-popup": Popup,
        "sm-input-popup": InputPopup,
        "sm-game-content": GameContent,
        "sm-list-steps": ListOfSteps,
        "sm-list-scenarios": ListOfScenarios,
    },
})
export default class ScenarioManager extends Vue { 
    public smImage: string = `${getAssetsPath()}/images/btn_trigger.png`;
    public showCover: boolean = false;
    public gameId = "";
    public userId = 1;
    public standalone = false;
    public sendCookies = false;
    public gameIds = [];
    public showGameContent = device.isDesktop() || device.isTablet();
    public environments: any = [];
    public environment = "";
    constructor() {
        super();
        bus.$on(events.CLOSE_SCENARIO, () => this.close());
        bus.$on(events.SHOW_SCENARIO, () => this.SMShow());
    }
    public mounted() {
        this.loadEnvironments();

        this.$nextTick(() => {
            const state = State.state;
            this.gameId = state.getGameId();
            this.userId = state.getUserId(); 
            this.standalone = state.standalone;
            this.gameIds = state.getValidGameIds();
            trace(this.gameIds);
            
            // set model specific params on the state
            const socket = new SocketIO( () => {
                State.state.initModel();
            });
            State.state = { socket };
        });
    }
    public SMShow(): void { 
        const state = State.state;
        this.showCover = !this.showCover;
        casino.keyboard(!this.showCover);
        if (this.showCover) {
            state.slotState.setSMVisible(true);
            state.pauseGame();
        } else {
            this.close();
        }
    }
    @Watch("gameId") public gameIdChanged(val) {
        trace("Changing gameId to: " + val + " standalone: " + State.state.standalone);
        if (State.state.loaded) { 
            this.refreshWithParams();
            return ;
        }
        State.state = { loaded: true, }
        State.state.changeGameId(val);
    } 
    @Watch("userId") public userIdChanged(val) {
        if (val === "") { return; }
        State.state = { userId: val }; 
    }
    private refreshWithParams() {
        const userId = State.state.userId;
        const gameId = State.state.gameId;
        const url = `${getAssetsPath()}/?userId=${userId}&gameId=${this.gameId}&env=${this.environment}`;
        window.location.href = url;
    }
    public loadEnvironments() {
        this.environments.push(`localhost`);
        this.environments.push(`chumba`);
        const env = LocalStorage.getItem("env");
        this.environment = env ? env : this.environments[0];
        const sendCookies = LocalStorage.getItem("sendCookies");
        this.sendCookies = sendCookies ? true : false;
    }
    @Watch("sendCookies") public sendCookiesChanged(val) {
        if (val) {
            LocalStorage.setItem("sendCookies", "1");
        } else {
            LocalStorage.removeItem("sendCookies");
        }
        State.state = { sendCookies: val }; 
        console.log(val);
    }
    @Watch("environment") public environmentChanged(val) {
        LocalStorage.setItem("env", val);
        State.state = { environment: val }; 
        if (State.state.loaded) { 
            this.refreshWithParams();
        }
    }
    public close() {
        const state = State.state;
        this.showCover = false;
        casino.keyboard(true);
        state.slotState.setSMVisible(false);
        state.resumeGame();
    }
}
