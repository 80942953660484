import { mathModel } from "../../../v3";
import { modelDefinition } from "../config";
import { generateSlotWindow } from "../generate-slot-window";
import { getWinCells } from "./get-win-cells";
import { getWinType } from "./get-win-type";
import { SpinInputs } from "./spin-inputs";
import { SpinOutputs } from "./spin-outputs";
import { GameState } from "../game-state";
import { SpinOutcome } from "./spin-outcome";
import { isTriggeringBoardGame } from "./is-triggering-board-game";
import { performReelSpinFeatures } from "./reel-spin-features";

export function freeSpins(inputs: SpinInputs): SpinOutputs {
  const { gameState } = inputs;
  const { coinSize, choices, coinTypeId } = inputs;

  if (
    gameState?.freeSpinCount === undefined ||
    gameState?.freeSpinPhase === undefined
  ) {
    throw new Error("Missing state");
  }

  let freeSpinCount: number | undefined = gameState?.freeSpinCount;
  let freeSpinRetriggerCount: number | undefined;

  const { cumulativeWinAmount: oldCumulativeWinAmount } = gameState;

  // Update free spin state
  let freeSpinPhase: GameState["freeSpinPhase"] = "IN_PROGRESS";
  freeSpinCount -= 1;

  // Generate the slot window
  const totalPlayAmount = coinSize * coinSizeMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions();
  const slotWindow = generateSlotWindow(reelStripPositions);

  // Reel Spin Features
  const {
    reelSpinFeatures,
    multiplier,
    newSlotWindow,
    stolenRoyals,
    stolenRoyalsReplacementSymbols,
    nuttCells,
    nuttPrize,
    wildPath,
    wildPathId,
    candyCanes,
    candyCaneReplacement,
  } = performReelSpinFeatures(
    false,
    coinSize,
    coinTypeId,
    choices,
    totalPlayAmount,
    slotWindow,
    checkLineWins,
    checkScatterWin
  );
  const actualSlowWindow = newSlotWindow ?? slotWindow;

  // Evaluate line wins
  const wins = checkLineWins(actualSlowWindow);
  const winLines = wins.map((win) => win.playLineIndex + win.length / 10);
  const lineWins = wins.map(
    (win) => win.multiplier * coinSize * (multiplier ?? 1)
  );
  const winCells = wins.map((win) =>
    getWinCells(win.playLineIndex, win.length)
  );

  // Evaluate scatter win
  const scatterWin = checkScatterWin(actualSlowWindow);
  const isFreeSpinReTrigger = scatterWin !== undefined;
  if (isFreeSpinReTrigger) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount * (multiplier ?? 1));
    winCells.push(scatterWin.cells);
    const tableIndex =
      modelDefinition.reelsLayout.length - scatterWin.cells.length;
    freeSpinPhase = "RETRIGGER";
    const newFreeSpins = modelDefinition.freeSpinRetriggerCount[tableIndex];
    freeSpinCount += newFreeSpins;
    freeSpinRetriggerCount = newFreeSpins;
  }

  // Compute win type and amount
  const nuttPrizeWinAmount = (nuttPrize ?? 0) * (nuttCells ?? []).length;
  const totalWinAmount =
    lineWins.reduce((sum, amt) => sum + amt, 0) + nuttPrizeWinAmount;
  const cumulativeWinAmount = oldCumulativeWinAmount + totalWinAmount;
  const winType = getWinType({
    totalWinAmount,
    isFreeSpinTrigger: isFreeSpinReTrigger,
    isFreeSpin: true,
  });

  const isBoardGameTrigger = isTriggeringBoardGame(slotWindow);

  const boardGamePhase = isBoardGameTrigger ? "START" : undefined;

  if (freeSpinCount <= 0) {
    freeSpinPhase = "END";
  }
  const spinOutcome: SpinOutcome = {
    coinSize,
    totalPlayAmount,
    reelStripPositions,
    slotWindow,
    totalWinAmount,
    winType,
    winLines,
    lineWins,
    winCells,
    freeSpinCount,
    freeSpinPhase,
    cumulativeWinAmount,
    boardGamePhase,
    freeSpinRetriggerCount,
    reelSpinFeatures,
    multiplier,
    newSlotWindow,
    stolenRoyals,
    stolenRoyalsReplacementSymbols,
    nuttCells,
    nuttPrize,
    wildPath,
    candyCanes,
    candyCaneReplacement,
    wildPathId,
  };

  if (freeSpinPhase === "END" && !isBoardGameTrigger) return { spinOutcome };
  return {
    spinOutcome,
    gameState: {
      freeSpinCount,
      freeSpinPhase,
      cumulativeWinAmount,
      slotWindow,
      reelStripPositions,
      coinSize,
      boardGamePhase,
    },
  };
}

const { coinSizeMultiplier, scatterSymbol, winTable } = modelDefinition;
const checkLineWins = mathModel.createLineWinChecker(modelDefinition);

/** Checks the slot window for a scatter win, returning the win cells and multiplier if found, or undefined otherwise. */
function checkScatterWin(slotWindow: string[][]) {
  const cells = mathModel.filterSlotWindow(
    slotWindow,
    (sym) => sym === scatterSymbol
  );
  const tableIndex = slotWindow.length - cells.length;
  const multiplier =
    tableIndex < scatterWinMultipliers.length
      ? scatterWinMultipliers[tableIndex]
      : 0;
  return multiplier ? { cells, multiplier } : undefined;
}
const scatterWinMultipliers =
  winTable.find((entry) => entry.symbol === scatterSymbol)?.multipliers ?? [];
