import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { CharacterWilds, JackpotPhase, PickFeature, ReelSpinFeatures } from '../shared';

export const GameResponseSchema = Type.Object({
    coinAmount: Type.Integer(),
    playAmount: Type.Integer(),
    winAmount: Type.Integer(),
    cumulativeWinAmount: Type.Optional(Type.Number()),
    winCells: Type.Optional(Type.Array(Type.Array(Type.Tuple([Type.Number(), Type.Number()])))),
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),

    lineWins: Type.Array(
        Type.Object({
            playLineIndex: Type.Integer(),
            symbol: Type.String(),
            length: Type.Number(),
            multiplier: Type.Number(),
            winAmount: Type.Number(),
        }),
    ),
    scatterWin: Type.Optional(
        Type.Object({
            cells: Type.Array(Type.Tuple([Type.Integer(), Type.Integer()])),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
        }),
    ),

    freeSpins: Type.Optional(
        Type.Object({
            remaining: Type.Number(),
            phase: Type.Union([
                Type.Literal('START'),
                Type.Literal('IN_PROGRESS'),
                Type.Literal('RETRIGGER'),
                Type.Literal('END'),
            ]),
            picToWilds: Type.Optional(CharacterWilds),
        }),
    ),
    picks: Type.Optional(
        Type.Object({
            userPicks: Type.Optional(Type.Array(PickFeature)),
            currentPick: Type.Optional(PickFeature),
            phase: Type.Union([Type.Literal('START'), Type.Literal('IN_PROGRESS'), Type.Literal('END')]),
        }),
    ),
    winType: Type.Union([
        Type.Literal('NO_WIN'),
        Type.Literal('NORMAL_WIN'),
        Type.Literal('FREE_SPIN_NO_WIN'),
        Type.Literal('FREE_SPIN_WIN'),
        Type.Literal('SCATTER_WIN'),
        Type.Literal('JACKPOT_WIN'),
    ]),
    reelSpinFeatures: Type.Optional(ReelSpinFeatures),
    jackpotPhase: Type.Optional(JackpotPhase),
    jackpotWinCounts: Type.Optional(
        Type.Object({
            MINOR: Type.Optional(Type.Integer()),
            MINI: Type.Optional(Type.Integer()),
            GRAND: Type.Optional(Type.Integer()),
            MAJOR: Type.Optional(Type.Integer()),
        }),
    ),
    jackpotWinAmounts: Type.Optional(
        Type.Object({
            MINOR: Type.Optional(Type.Integer()),
            MINI: Type.Optional(Type.Integer()),
        }),
    ),
});
export type GameResponse = Static<typeof GameResponseSchema>;
