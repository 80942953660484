export const modelDefinition = {
    modelId: 'vgw099',
    gameIds: ['freaky13'],
    coinType1Values: [100, 1000, 5000, 10000, 20000, 100000, 400000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 4000],
    coinAmountMultiplier: 13,
    reelsLayout: [3, 3, 3, 3, 3],
    scatterSymbol: 'SCAT',
    wildSymbol: 'WILD_SCAT',
    freeSpinsTriggerCount: 13,
    freeSpinsRetriggerCount: 13,

    winTable: [
        {
            symbol: 'SCAT',
            multipliers: [100, 10, 2],
        },
        {
            symbol: 'WILD_SCAT',
            multipliers: [2000, 200, 50, 10],
        },
        {
            symbol: 'PIC1',
            multipliers: [2000, 200, 50, 10],
        },
        {
            symbol: 'PIC2',
            multipliers: [1000, 150, 30],
        },
        {
            symbol: 'PIC3',
            multipliers: [500, 100, 20],
        },
        {
            symbol: 'PIC4',
            multipliers: [300, 50, 10],
        },
        {
            symbol: 'PIC5',
            multipliers: [200, 40, 5],
        },
        {
            symbol: 'PIC6',
            multipliers: [100, 25, 5],
        },
    ],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [1, 1, 0, 1, 1], // 10
        [1, 1, 2, 1, 1], // 11
        [0, 0, 1, 2, 2], // 12
        [2, 2, 1, 0, 0], // 13
    ],

    // prettier-ignore
    reels:[
        ["PIC4", "PIC5", "PIC3", "PIC4", "PIC6", "PIC1", "PIC3", "PIC5", "PIC2", "PIC6", "PIC4", "PIC2", "PIC5", "PIC4", "PIC6", "PIC3", "PIC4", "PIC1", "PIC5", "PIC6", "PIC2", "PIC5", "PIC4", "PIC6", "PIC5", "PIC3", "PIC4", "PIC1", "PIC2", "PIC5", "PIC6", "PIC3", "SCAT", "PIC4", "PIC2", "PIC6", "PIC5", "PIC3", "PIC6"],
        ["PIC3", "PIC1", "PIC5", "PIC4", "PIC6", "PIC5", "PIC3", "PIC2", "PIC4", "PIC1", "PIC5", "PIC6", "PIC2", "PIC4", "PIC6", "PIC5", "PIC3", "PIC6", "PIC4", "PIC5", "PIC1", "PIC2", "SCAT", "PIC4", "PIC5", "PIC2", "PIC3", "PIC6", "PIC5", "PIC4", "PIC6", "PIC5", "PIC3", "PIC1", "PIC6", "PIC2", "PIC3", "PIC6", "PIC4"],
        ["PIC3", "PIC5", "PIC6", "PIC4", "PIC3", "PIC6", "PIC5", "PIC2", "PIC3", "PIC4", "PIC5", "PIC3", "PIC2", "PIC6", "PIC1", "PIC5", "PIC6", "PIC4", "PIC3", "PIC1", "PIC6", "PIC4", "PIC2", "PIC6", "PIC1", "PIC5", "PIC4", "PIC6", "PIC1", "PIC5", "PIC4", "PIC6", "PIC2", "PIC5", "PIC6", "SCAT", "PIC1", "PIC3", "PIC4", "PIC2"],
        ["PIC5", "PIC2", "PIC4", "PIC1", "PIC5", "PIC3", "PIC1", "PIC5", "PIC3", "SCAT", "PIC6", "PIC2", "PIC4", "PIC3", "PIC1", "PIC6", "PIC5", "PIC3", "PIC6", "PIC2", "PIC1", "PIC6", "PIC4", "PIC3", "PIC6", "PIC5", "PIC4", "PIC2", "PIC5", "PIC6", "PIC4", "PIC5", "PIC6", "PIC2", "PIC4", "PIC3", "PIC6", "PIC1", "PIC4", "PIC6"],
        ["PIC6", "PIC5", "PIC3", "PIC2", "PIC5", "PIC4", "PIC1", "PIC5", "PIC6", "PIC3", "PIC4", "PIC2", "PIC5", "PIC4", "PIC6", "SCAT", "PIC2", "PIC6", "PIC3", "PIC1", "PIC4", "PIC6", "PIC5", "PIC1"],
    ],

    winLevels: [
        { name: 'normal1', threshold: 2 },
        { name: 'normal2', threshold: 5 },
        { name: 'normal3', threshold: 20 },
        { name: 'bigwin', threshold: 35 },
        { name: 'megawin', threshold: 70 },
        { name: 'epicwin' },
    ],
};
