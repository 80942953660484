// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer > div[data-v-14ec4fed] {
  margin-block-end: 2em;
}
.general-controls[data-v-14ec4fed] {
  display: flex;
  gap: 1em;
}
.reel-position-selectors[data-v-14ec4fed] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;

  margin-bottom: 1em;
}
.reel-position-selectors > input[data-v-14ec4fed] {
  width: 8ch;
  padding: 0.25em;

  text-align: center;
  border-radius: 0.5em;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw089/step-controls/step-controls.vue.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,QAAQ;AACV;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,qBAAqB;;EAErB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,eAAe;;EAEf,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".outer > div[data-v-14ec4fed] {\n  margin-block-end: 2em;\n}\n.general-controls[data-v-14ec4fed] {\n  display: flex;\n  gap: 1em;\n}\n.reel-position-selectors[data-v-14ec4fed] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  justify-items: center;\n\n  margin-bottom: 1em;\n}\n.reel-position-selectors > input[data-v-14ec4fed] {\n  width: 8ch;\n  padding: 0.25em;\n\n  text-align: center;\n  border-radius: 0.5em;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
