/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 3]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: (n) => State.state.getModelDefinition().fsFeatureReels[n],

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureReTriggerStep: (featureReelsToUse) => {
            const step = State.state.createStep([92, 92, 92], true, 0, "", "F-Retrig");
            step.featureReelsToUse = featureReelsToUse;
            return step;
        },
        getFeatureStep: (featureReelsToUse) => {
            const step = State.state.createStep([0, 0, 3], true, 0, "", "F-Step");
            step.featureReelsToUse = featureReelsToUse;
            return step;
        },
        getFeatureTriggerFromBaseGame: () => State.state.createStep([11, 11, 21], false, 0, "", "F-Trig"),
        getWildsGalore: () => State.state.createStep([6, 6, 6], false, 0, "", "Wilds"),

        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips(where.featureReelsToUse) : State.state.getReelStrips();
            return reels;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReels(step);
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            return step;
        },
    };
}
