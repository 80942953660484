var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center top-reel-selector-wrapper" }, [
        _c("h3", [_vm._v("Top Reel: ")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center top-reel-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.topReelFirstSymbol,
                  expression: "topReelFirstSymbol",
                },
              ],
              attrs: { id: "topReelFirstSymbol" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.topReelFirstSymbol = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateTopReelSymbol(1, _vm.topReelFirstSymbol)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "NONE", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "S" } }, [_vm._v("S")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "E" } }, [_vm._v("E")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "T" } }, [_vm._v("T")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center top-reel-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.topReelSecondSymbol,
                  expression: "topReelSecondSymbol",
                },
              ],
              attrs: { id: "topReelSecondSymbol" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.topReelSecondSymbol = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateTopReelSymbol(2, _vm.topReelSecondSymbol)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "NONE", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "S" } }, [_vm._v("S")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "E" } }, [_vm._v("E")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "T" } }, [_vm._v("T")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center top-reel-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.topReelThirdSymbol,
                  expression: "topReelThirdSymbol",
                },
              ],
              attrs: { id: "topReelThirdSymbol" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.topReelThirdSymbol = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateTopReelSymbol(3, _vm.topReelThirdSymbol)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "NONE", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "S" } }, [_vm._v("S")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "E" } }, [_vm._v("E")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "T" } }, [_vm._v("T")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center top-reel-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.topReelForthSymbol,
                  expression: "topReelForthSymbol",
                },
              ],
              attrs: { id: "topReelForthSymbol" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.topReelForthSymbol = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateTopReelSymbol(4, _vm.topReelForthSymbol)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "NONE", selected: "selected" } }, [
                _vm._v("Random"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Z" } }, [_vm._v("Z")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "S" } }, [_vm._v("S")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "B" } }, [_vm._v("B")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "C" } }, [_vm._v("C")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "D" } }, [_vm._v("D")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "E" } }, [_vm._v("E")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "T" } }, [_vm._v("T")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "N" } }, [_vm._v("N")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex-center top-reel-selector top-reel-selector-position",
          },
          [
            _c("label", { attrs: { for: "topReelReelStripPosition" } }, [
              _vm._v(" Position "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.topReelReelStripPosition,
                    expression: "topReelReelStripPosition",
                  },
                ],
                attrs: { id: "topReelReelStripPosition" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.topReelReelStripPosition = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateTopReelReelStripPosition(
                        _vm.topReelReelStripPosition
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0", selected: "selected" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(" "),
                _vm._l(67, function (n) {
                  return _c("option", [_vm._v(_vm._s(n) + " ")])
                }),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex-center top-reel-selector top-reel-selector-max-scatters",
          },
          [
            _c("label", { attrs: { for: "maxScatters" } }, [
              _vm._v(" Max Scatters "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.maxScatters,
                    expression: "maxScatters",
                  },
                ],
                attrs: { id: "maxScatters" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.maxScatters = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updatedMaxScatters(_vm.maxScatters)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0", selected: "selected" } }, [
                  _vm._v("No"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center base-height-selector-wrapper" }, [
        _c("h3", [_vm._v("Base reels heights: ")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightOne,
                  expression: "baseReelHeightOne",
                },
              ],
              attrs: { id: "baseReelHeightOne" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightOne = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(0, _vm.baseReelHeightOne)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "7", selected: "selected" } }, [
                _vm._v("7"),
              ]),
              _vm._v(" "),
              _vm._l(7, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightTwo,
                  expression: "baseReelHeightTwo",
                },
              ],
              attrs: { id: "baseReelHeightTwo" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightTwo = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(1, _vm.baseReelHeightTwo)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "6", selected: "selected" } }, [
                _vm._v("6"),
              ]),
              _vm._v(" "),
              _vm._l(6, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightThree,
                  expression: "baseReelHeightThree",
                },
              ],
              attrs: { id: "baseReelHeightThree" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightThree = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(2, _vm.baseReelHeightThree)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "6", selected: "selected" } }, [
                _vm._v("6"),
              ]),
              _vm._v(" "),
              _vm._l(6, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightFour,
                  expression: "baseReelHeightFour",
                },
              ],
              attrs: { id: "baseReelHeightFour" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightFour = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(3, _vm.baseReelHeightFour)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "6", selected: "selected" } }, [
                _vm._v("6"),
              ]),
              _vm._v(" "),
              _vm._l(6, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightFive,
                  expression: "baseReelHeightFive",
                },
              ],
              attrs: { id: "baseReelHeightFive" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightFive = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(4, _vm.baseReelHeightFive)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "6", selected: "selected" } }, [
                _vm._v("6"),
              ]),
              _vm._v(" "),
              _vm._l(6, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightSix,
                  expression: "baseReelHeightSix",
                },
              ],
              attrs: { id: "baseReelHeightSix" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightSix = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(5, _vm.baseReelHeightSix)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "7", selected: "selected" } }, [
                _vm._v("7"),
              ]),
              _vm._v(" "),
              _vm._l(7, function (n) {
                return _c("option", [_vm._v(_vm._s(n) + " ")])
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c("div", { staticClass: "flex-center scatters-selector" }, [
          _c("label", { attrs: { for: "scatters_number" } }, [
            _vm._v("Number of Scatter prizes: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scattersNumber,
                  expression: "scattersNumber",
                },
              ],
              attrs: { id: "scatters_number" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.scattersNumber = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBonusGameScattersNumber(_vm.scattersNumber)
                  },
                ],
              },
            },
            _vm._l(46, function (n) {
              return _c("option", [_vm._v(_vm._s(n) + " ")])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "selected-scatters" },
          _vm._l(parseInt(_vm.scattersNumber), function (n) {
            return _c("selectScatterMultiplier", {
              key: n,
              attrs: {
                index: n,
                scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
              },
            })
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }