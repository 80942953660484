import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 5;
    public reel = "";
    public isOpen = false;
    public reelTwoWildMultiplier = "-1";
    public reelThreeWildMultiplier = "-1";
    public reelFourWildMultiplier = "-1";

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if (this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep(reelSetID) {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep(reelSetID));
    }

    public addFSTriggerRS8() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinStepRS8());
    }

    public updateWildMultiplier(index, value) {
        switch (index) {
            case 2:
                this.step.json.reelTwoWildMultiplier = value;
                break;
            case 3:
                this.step.json.reelThreeWildMultiplier = value;
                break;
            case 4:
                this.step.json.reelFourWildMultiplier = value;
                break;
        }

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateWildsReelSelector() {
        if (this.step.json.reelTwoWildMultiplier != null) {
            this.reelTwoWildMultiplier = this.step.json.reelTwoWildMultiplier;
        } else {
            this.reelTwoWildMultiplier = "-1";
        }

        if (this.step.json.reelThreeWildMultiplier != null) {
            this.reelThreeWildMultiplier = this.step.json.reelThreeWildMultiplier;
        } else {
            this.reelThreeWildMultiplier = "-1";
        }

        if (this.step.json.reelFourWildMultiplier != null) {
            this.reelFourWildMultiplier = this.step.json.reelFourWildMultiplier;
        } else {
            this.reelFourWildMultiplier = "-1";
        }
    }
}