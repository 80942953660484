var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu newMenu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("FS Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("FS Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sub-title-menu newMenu spaceNewMenu" }, [
        _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "sub-title-menu",
            },
            [
              _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
                _c("div", { staticClass: "select-style newSelect" }, [
                  _c("label", { attrs: { for: "reel_number" } }, [
                    _vm._v("extra reels: "),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.extraReels,
                        expression: "extraReels",
                      },
                    ],
                    staticClass: "text-input reel-text reel-index newInput",
                    attrs: { type: "number", min: "0", id: "reel_number" },
                    domProps: { value: _vm.extraReels },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.extraReels = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wheel0Index,
                        expression: "wheel0Index",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.wheel0Index = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeWheel0Index,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1", selected: "" } }, [
                      _vm._v("random"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "12" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "13" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "14" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "15" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "16" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "17" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "19" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "20" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "22" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "23" } }, [_vm._v("s1")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wheel1Index,
                        expression: "wheel1Index",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.wheel1Index = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeWheel1Index,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1", selected: "" } }, [
                      _vm._v("random"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("p8")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("s3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "12" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "13" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "14" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "15" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "16" } }, [_vm._v("p5")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "17" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "19" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "20" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "22" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "23" } }, [_vm._v("p4")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wheel2Index,
                        expression: "wheel2Index",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.wheel2Index = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeWheel2Index,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1", selected: "" } }, [
                      _vm._v("random"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("s3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("m2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("m3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("m2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "12" } }, [_vm._v("p5")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "13" } }, [_vm._v("p8")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "14" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "15" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "16" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "17" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "19" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "20" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "22" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "23" } }, [_vm._v("p5")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wheel3Index,
                        expression: "wheel3Index",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.wheel3Index = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeWheel3Index,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1", selected: "" } }, [
                      _vm._v("random"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("p15")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("s4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("s3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("s2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [_vm._v("p5")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "12" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "13" } }, [_vm._v("p8")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "14" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "15" } }, [_vm._v("p2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "16" } }, [_vm._v("m2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "17" } }, [_vm._v("p3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "19" } }, [_vm._v("p4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "20" } }, [_vm._v("m1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21" } }, [_vm._v("p5")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "22" } }, [_vm._v("s1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "23" } }, [_vm._v("p8")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wheel4Index,
                        expression: "wheel4Index",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFSTrigger,
                        expression: "isFSTrigger",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.wheel4Index = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeWheel4Index,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1", selected: "" } }, [
                      _vm._v("random"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("p1000")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("p100")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("p100")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "11" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "12" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "13" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "14" } }, [_vm._v("p100")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "15" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "16" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "17" } }, [_vm._v("p100")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "18" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "19" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "20" } }, [_vm._v("p100")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "21" } }, [_vm._v("p10")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "22" } }, [_vm._v("p25")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "23" } }, [_vm._v("p10")]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFSTrigger,
                  expression: "!isFSTrigger",
                },
              ],
              staticClass: "elemOK pointer",
              class: [_vm.isActive ? "blue" : "elemOK"],
              attrs: { id: "ok" },
              on: {
                click: function ($event) {
                  return _vm.updateStep(_vm.levelNo, _vm.extraReels)
                },
              },
            },
            [_vm._v("OK")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFSTrigger,
                  expression: "isFSTrigger",
                },
              ],
              staticClass: "elemOK pointer",
              class: [_vm.isActive ? "blueTrigger" : "elemOKTrigger"],
              attrs: { id: "okTrigger" },
              on: {
                click: function ($event) {
                  return _vm.updateStep(_vm.levelNo, _vm.extraReels)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.extraReels > 3 ? "flex" : "flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }