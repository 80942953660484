/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {

        "modelId": "tf105",
        "version": "1.1",

        "betMultiplier" : 25,
        "reelsLayout" : [ 4, 4, 4 ],
        "scatterSymbol" : "S",
        "wildSymbol" : "Z",
    
        "defaultFreeSpinCount": 8,
        "alreadyFreeSpinCount": 4,
        "countToTriggerFreeSpin" : 5,
        "countSymbolsWin" : 5,
        "jackpotMultiplier" : 888,
        "countJackpotWin" : 13,
        "winCapMulti": -1,
    
        "playLines": [],
    
        "winTable" : [ {
          "symbol" : "S",
          "multipliers" : [ 5 ]
        }, {
          "symbol" : "B",
          "multipliers" : [ 5 ]
        }, {
          "symbol" : "C",
          "multipliers" : [ 4 ]
        }, {
          "symbol" : "D",
          "multipliers" : [ 3 ]
        }, {
          "symbol" : "E",
          "multipliers" : [ 3 ]
        }, {
          "symbol" : "A",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "K",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "Q",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "J",
          "multipliers" : [ 2 ]
        }, {
          "symbol" : "T",
          "multipliers" : [ 1 ]
        }, {
          "symbol" : "N",
          "multipliers" : [ 1 ]
        } ],
    
        "reels" : [ 
          ["Q","Q","T","T","S","S","Q","Q","A","A","Q","Q","T","T","A","A","K","K","B","B","K","K","N","N","J","J","A","A","T","T","E","E","N","N","D","D","N","N","T","T","A","A","C","C","T","T","N","N","D","D","N","N","C","C","E","E","C","C","J","J","Q","Q","K","K","N","N","T","T","S","S","E","E","J","J","N","N","Q","Q","K","K","B","B","S","S","Q","Q","N","N","J","J","S","S","B","B","K","K","J","J","A","A","B","B","J","J","T","T","K","K","A","A","T","T","D","D","C","C","D","D","E","E"],
          ["A","A","J","J","S","S","Q","Q","N","N","K","K","N","N","B","B","D","D","K","K","B","B","E","E","N","N","T","T","D","D","B","B","J","J","D","D","J","J","A","A","Q","Q","T","T","D","D","Q","Q","T","T","J","J","K","K","T","T","E","E","T","T","C","C","B","B","Q","Q","J","J","B","B","Q","Q","Z","Z","K","K","N","N","T","T","C","C","N","N","T","T","N","N","E","E","J","J","B","B","C","C","Z","Z","E","E","N","N","A","A","B","B","E","E","T","T","C","C","J","J","T","T","Z","Z","D","D","K","K","A","A","S","S","K","K","N","N","K","K","J","J","N","N","Q","Q","C","C","E","E","Z","Z","J","J","A","A","N","N","A","A","J","J","K","K","T","T","K","K","J","J","D","D","J","J","C","C","N","N","S","S","Q","Q","Z","Z","A","A","Q","Q","N","N","Z","Z","B","B","K","K","A","A","D","D","Q","Q","C","C","T","T","D","D","A","A","N","N","Z","Z","E","E","T","T","A","A","K","K","C","C","A","A","Z","Z","Q","Q","N","N","Q","Q","A","A","N","N","T","T","Z","Z","Q","Q","T","T","K","K","T","T","N","N","T","T","N","N","T","T","E","E"],
          ["Q","Q","B","B","N","N","K","K","Z","Z","C","C","Q","Q","D","D","A","A","J","J","Q","Q","T","T","N","N","A","A","J","J","S","S","J","J","Z","Z","E","E","B","B","T","T","D","D","Z","Z","T","T","B","B","Z","Z","T","T","C","C","Z","Z","J","J","N","N","T","T","C","C","E","E","A","A","D","D","K","K","Q","Q","T","T","A","A","S","S","Z","Z","K","K","N","N","T","T","D","D","A","A","J","J","B","B","K","K","E","E","N","N","Q","Q","C","C","T","T","K","K","N","N","Z","Z","E","E","B","B","N","N","S","S","N","N"],
          ["J","J","Z","Z","E","E","A","A","D","D","Z","Z","N","N","C","C","K","K","T","T","K","K","T","T","J","J","E","E","T","T","N","N","Z","Z","A","A","D","D","Q","Q","N","N","B","B","Q","Q","C","C","J","J","S","S","K","K","B","B","Z","Z","Q","Q","J","J","C","C","D","D","Q","Q","A","A","N","N","K","K","T","T","Q","Q","Z","Z","S","S","Z","Z","K","K","E","E","A","A","N","N","T","T","A","A","B","B","D","D","N","N","E","E","B","B","T","T","S","S","J","J","T","T","N","N","T","T","B","B","N","N","C","C"],
          ["K","K","N","N","Q","Q","E","E","T","T","B","B","Q","Q","S","S","C","C","A","A","T","T","Z","Z","N","N","A","A","E","E","K","K","D","D","B","B","T","T","A","A","T","T","N","N","Z","Z","C","C","N","N","Q","Q","Z","Z","T","T","K","K","J","J","T","T","J","J","S","S","K","K","N","N","J","J","C","C","T","T","J","J","E","E","N","N","D","D","N","N","A","A","D","D","Q","Q","B","B","N","N","B","B","T","T","J","J","C","C","E","E","Z","Z","B","B","D","D","Q","Q","K","K","A","A"]
        ],
    
        "reels_fs" : [ 
          ["D","D","N","N","B","B","S","S","C","C","J","J","T","T","K","K","T","T","N","N","T","T","S","S","T","T","N","N","Q","Q","A","A","K","K","E","E","Q","Q","D","D","B","B","E","E","T","T","K","K","E","E","C","C","T","T","A","A","N","N","A","A","T","T","A","A","Q","Q","J","J","K","K","C","C","N","N","T","T","Q","Q","A","A","N","N","J","J","C","C","B","B","D","D","J","J","B","B","N","N","J","J","K","K","N","N","Q","Q","D","D","E","E"],
          ["T","T","Q","Q","C","C","Z","Z","A","A","T","T","Z","Z","J","J","D","D","N","N","Q","Q","J","J","B","B","K","K","E","E","Z","Z","T","T","K","K","N","N","A","A","J","J","K","K","N","N","C","C","S","S","Q","Q","N","N","T","T","Q","Q","B","B","Z","Z","T","T","N","N","A","A","S","S","C","C","K","K","E","E","N","N","A","A","Z","Z","B","B","Z","Z","Q","Q","Z","Z","T","T","D","D","J","J","Z","Z","D","D","K","K","T","T","A","A","E","E","N","N","Z","Z","J","J","E","E","D","D","C","C","T","T","Z","Z","N","N","B","B","Z","Z"],
          ["J","J","T","T","D","D","Q","Q","K","K","E","E","A","A","E","E","N","N","Z","Z","N","N","T","T","Q","Q","K","K","Z","Z","T","T","J","J","S","S","A","A","Z","Z","T","T","C","C","A","A","T","T","D","D","Z","Z","A","A","B","B","T","T","N","N","E","E","K","K","N","N","Z","Z","N","N","D","D","A","A","Z","Z","Q","Q","T","T","J","J","B","B","E","E","Q","Q","T","T","K","K","N","N","Z","Z","B","B","Z","Z","N","N","Q","Q","J","J","D","D","C","C","Z","Z","J","J","C","C","S","S","Z","Z","C","C","B","B","N","N","K","K"],
          ["B","B","A","A","C","C","S","S","T","T","K","K","T","T","Z","Z","B","B","C","C","N","N","Q","Q","E","E","J","J","C","C","Z","Z","Q","Q","D","D","N","N","T","T","Z","Z","K","K","T","T","D","D","J","J","Q","Q","A","A","N","N","T","T","Z","Z","T","T","J","J","E","E","A","A","D","D","N","N","A","A","K","K","C","C","Q","Q","N","N","Z","Z","K","K","N","N","Z","Z","N","N","S","S","Z","Z","A","A","Z","Z","T","T","Q","Q","D","D","B","B","K","K","B","B","Z","Z","E","E","N","N","J","J","T","T","E","E","Z","Z","J","J","Z","Z"],
          ["E","E","A","A","E","E","D","D","C","C","D","D","Z","Z","T","T","E","E","C","C","K","K","N","N","E","E","N","N","T","T","Q","Q","C","C","J","J","Q","Q","B","B","J","J","N","N","J","J","N","N","B","B","N","N","Z","Z","A","A","N","N","A","A","B","B","D","D","K","K","D","D","S","S","T","T","A","A","Z","Z","K","K","Z","Z","T","T","C","C","A","A","J","J","T","T","Z","Z","Q","Q","S","S","Z","Z","T","T","Q","Q","N","N","K","K","Z","Z","T","T","J","J","N","N","B","B","K","K","T","T","Z","Z","Q","Q"],
          ["T","T","Q","Q","A","A","S","S","T","T","D","D","T","T","D","D","A","A","J","J","E","E","B","B","C","C","Z","Z","A","A","T","T","K","K","N","N","K","K","Z","Z","N","N","J","J","K","K","A","A","N","N","J","J","D","D","S","S","N","N","Q","Q","Z","Z","J","J","Z","Z","C","C","N","N","K","K","Q","Q","T","T","C","C","Q","Q","J","J","B","B","E","E","B","B","D","D","T","T","E","E","N","N","Q","Q","K","K","B","B","Z","Z","N","N","Z","Z","A","A","N","N","T","T","C","C","T","T","Z","Z","E","E"],
        ],
    
        "wheels" : [
          [
          {"prize": 4 },
          {"multi": 1 },
          {"spins": 1 },
    
          {"prize": 2 },
          {"multi": 1 },
          {"spins": 2 },
    
          {"prize": 2 },
          {"multi": 1 },
          {"spins": 1 },
    
          {"prize": 3 },
          {"multi": 1 },
          {"spins": 1 },
    
          {"prize": 4 },
          {"spins": 2 },
          {"spins": 1 },
    
          {"prize": 2 },
          {"multi": 1 },
          {"spins": 1 },
    
          {"prize": 2 },
          {"multi": 1 },
          {"spins": 1 },
    
          {"prize": 3 },
          {"multi": 1 },
          {"spins": 1 },
        ],    // wheels[0]
        [
          {"prize": 8 },
          {"multi": 1 },
          {"prize": 2 },
    
          {"spins": 1 },
          {"multi": 1 },
          {"prize": 3 },
    
          {"spins": 3 },
          {"prize": 2 },
          {"spins": 2 },
    
          {"multi": 1 },
          {"prize": 3 },
          {"spins": 1 },
    
          {"multi": 1 },
          {"prize": 4 },
          {"spins": 1 },
    
          {"multi": 1 },
          {"prize": 5 },
          {"spins": 1 },
    
          {"multi": 1 },
          {"prize": 2 },
          {"spins": 1 },
    
          {"multi": 1 },
          {"prize": 3 },
          {"prize": 4 },
        ],    // wheels[1]
        [
          {"prize": 10 },
          {"prize": 2 },
          {"multi": 1 },
    
          {"prize": 3 },
          {"spins": 3 },
          {"multi": 2 },
    
          {"spins": 2 },
          {"multi": 3 },
          {"spins": 1 },
    
          {"multi": 2 },
          {"prize": 4 },
          {"spins": 1 },
    
          {"prize": 5 },
          {"prize": 8 },
          {"multi": 1 },
    
          {"prize": 2 },
          {"spins": 2 },
          {"multi": 1 },
    
          {"prize": 3 },
          {"spins": 1 },
          {"multi": 1 },
    
          {"prize": 4 },
          {"spins": 1 },
          {"prize": 5 },
        ],    // wheels[2]
        [
          {"prize": 15 },
          {"prize": 2 },
          {"spins": 2 },
    
          {"prize": 3 },
          {"spins": 4 },
          {"prize": 2 },
    
          {"spins": 3 },
          {"prize": 3 },
          {"multi": 1 },
    
          {"prize": 4 },
          {"spins": 2 },
          {"prize": 5 },
    
          {"multi": 1 },
          {"prize": 8 },
          {"prize": 10 },
    
          {"prize": 2 },
          {"multi": 2 },
          {"prize": 3 },
    
          {"spins": 1 },
          {"prize": 4 },
          {"multi": 1 },
    
          {"prize": 5 },
          {"spins": 1 },
          {"prize": 8 },
        ],    // wheels[3]
        [
          {"prize": 1000 },
          {"prize": 10 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 100 },
          {"prize": 25 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 10 },
          {"prize": 100 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 100 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 100 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 100 },
          {"prize": 10 },
          {"prize": 25 },
          {"prize": 10 },
        ]    // wheels[4]
        ],
    
        "bonusMulti": [
          { "name": "Grand", "multi":	1000 },
          { "name": "Major", "multi": 100 },
          { "name": "Minor","multi": 25 },
          { "name": "Mini","multi": 10 },
        ],
    
        "coinType1Values" : [ 500, 2500, 5000, 7500, 12500, 25000, 75000, 125000, 250000 ],
        "coinType4Values" : [ 1, 5, 10, 15, 25, 50, 150, 250, 500 ],
        "coinType1_min_bet" : 500,
        "coinType1_max_bet" : 250000,
        "coinType1_spin_max_bet" : 7500000,
        "coinType4_min_bet" : 1,
        "coinType4_max_bet" : 500,
        "coinType4_spin_max_bet" : 15000,
    
        "winLevels" : [ {
          "name" : "normal1",
          "threshold" : 5
        }, {
          "name" : "normal2",
          "threshold" : 15
        }, {
          "name" : "normal3",
          "threshold" : 25
        }, {
          "name" : "bigwin",
          "threshold" : 50
        }, {
          "name" : "megawin",
          "threshold" : 150
        }, {
          "name" : "epicwin",
          "threshold" : 2147483647
        } ],
    }
}
