/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["95"],

        // Must implement
        createDefaultStep: (order = 0, variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getGoldReelsBase:() => State.state.getModelDefinition().goldReelsBase,
        getGoldReelsFeature:() => State.state.getModelDefinition().goldReelsFeature,
        // example on creating custom indexes
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([9, 4, 17, 8, 1], false, order, "", "F-Trigger") ,
        getFeatureStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0], true, order, "", "F-Step") ,
        getFeatureReTriggerStep: (order = 0) =>  State.state.createStep([9, 4, 17, 8, 29], true, order, "", "F-ReTrigger") ,
        getMiniBaseJackpot: (order = 0) => State.state.createStep([97, 106, 0, 0, 0], false, order, "", "Mini") ,
        getGrandBaseJackpot: (order = 0) => State.state.createStep([97, 106, 100, 57, 46], false, order, "", "Grand") ,
        getMiniFeatureJackpot: (order = 0) => State.state.createStep([97, 15, 0, 0, 0], false, order, "", "Mini Feature") ,
        getGrandFeatureJackpot: (order = 0) => State.state.createStep([97, 15, 100, 57, 46], false, order, "", "Grand Feature") ,

    };
}
