/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"modelDefinition": {
		"modelId": "vgw030",
		"reelsLayout": [
			3,
			3,
			3,
			3,
			3
		],
		"scatterSymbol": "SCAT",
		"wildSymbol": "WILD",
		"freeSpinMultiplier": 0,
		"freeSpinCount": 0,
		"winTable": [{
				"symbol": "pic1",
				"multipliers": [
					800,
					80,
					18
				]
			},
			{
				"symbol": "pic2",
				"multipliers": [
					500,
					60,
					16
				]
			},
			{
				"symbol": "pic3",
				"multipliers": [
					500,
					60,
					16
				]
			},
			{
				"symbol": "pic4",
				"multipliers": [
					300,
					50,
					12
				]
			},
			{
				"symbol": "pic5",
				"multipliers": [
					300,
					50,
					12
				]
			},
			{
				"symbol": "Heart",
				"multipliers": [
					120,
					30,
					8
				]
			},
			{
				"symbol": "Diamond",
				"multipliers": [
					120,
					30,
					8
				]
			},
			{
				"symbol": "Spade",
				"multipliers": [
					120,
					30,
					8
				]
			},
			{
				"symbol": "Club",
				"multipliers": [
					120,
					30,
					8
				]
			},
			{
				"symbol": "SCAT",
				"multipliers": [
					200,
					10,
					4
				]
			},
			{
				"symbol": "WILD",
				"multipliers": [
					4000,
					100,
					20
				]
			}
		],
		"playLines": [
			[
				1,
				1,
				1,
				1,
				1
			],
			[
				0,
				0,
				0,
				0,
				0
			],
			[
				2,
				2,
				2,
				2,
				2
			],
			[
				0,
				1,
				2,
				1,
				0
			],
			[
				2,
				1,
				0,
				1,
				2
			],
			[
				0,
				0,
				1,
				2,
				1
			],
			[
				2,
				1,
				2,
				1,
				1
			],
			[
				1,
				0,
				1,
				2,
				2
			],
			[
				1,
				2,
				1,
				0,
				0
			],
			[
				0,
				1,
				0,
				1,
				1
			],
			[
				2,
				2,
				1,
				0,
				1
			],
			[
				1,
				1,
				0,
				1,
				2
			],
			[
				1,
				1,
				2,
				1,
				0
			],
			[
				0,
				1,
				1,
				1,
				2
			],
			[
				2,
				1,
				1,
				1,
				0
			],
			[
				0,
				2,
				1,
				2,
				0
			],
			[
				2,
				0,
				1,
				0,
				2
			],
			[
				1,
				2,
				2,
				2,
				1
			],
			[
				1,
				0,
				0,
				0,
				1
			],
			[
				1,
				1,
				2,
				2,
				1
			]
		],
		
		"reels" : [
			["pic2", "Spade", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "WILD", "Heart", "Diamond", "pic2", "Club", "pic1", "Spade", "Diamond", "pic2", "Spade", "pic1", "pic1", "pic1", "pic2", "Spade", "SCAT", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "Club", "Diamond", "pic1", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "pic5"],
			["pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "Diamond", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "SCAT", "Heart", "Spade", "pic3", "Diamond", "WILD", "Club", "pic1", "pic1", "pic1", "pic5", "pic4"],
			["Spade", "Club", "pic4", "Heart", "pic2", "Heart", "pic3", "Spade", "pic1", "Heart", "pic1", "Heart", "pic1", "Spade", "Diamond", "SCAT", "Spade", "Heart", "pic1", "pic1", "pic1", "WILD", "pic3", "Spade", "Club", "Heart", "pic3", "Spade", "Diamond", "Heart", "pic5"],
			["Diamond", "Heart", "pic2", "Heart", "Club", "WILD", "pic1", "pic2", "Club", "pic4", "pic2", "Spade", "SCAT", "Diamond", "Club", "pic4", "pic1", "pic1", "pic1", "pic2", "Club", "pic4", "pic2", "pic5", "Club", "pic3", "pic5"],
			["SCAT", "pic4", "Diamond", "pic2", "Heart", "Spade", "pic2", "Diamond", "pic3", "pic3", "Heart", "pic2", "Club", "Diamond", "pic2", "pic1", "Club", "pic4", "Diamond", "pic2", "pic4", "Club", "pic3", "Heart", "pic4", "Heart", "pic3", "Heart", "WILD", "Spade", "pic3", "pic2", "pic1", "pic1", "pic1", "pic5"]
		],

		"featureReels" : [
			["pic2", "Spade", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "WILD", "Heart", "Diamond", "pic2", "Club", "pic1", "Spade", "Diamond", "pic2", "Spade", "pic1", "pic1", "pic1", "pic2", "Spade", "SCAT", "Club", "pic3", "Heart", "pic4", "Heart", "Diamond", "Club", "Diamond", "pic1", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "pic5"],
			["pic2", "Diamond", "Club", "pic4", "Diamond", "Club", "pic4", "Diamond", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "SCAT", "Heart", "Spade", "pic3", "Diamond", "WILD", "Club", "pic1", "pic1", "pic1", "pic5", "pic4"],
			["Spade", "Club", "pic4", "Heart", "pic2", "Heart", "pic3", "Spade", "pic1", "Heart", "pic1", "Heart", "pic1", "Spade", "Diamond", "SCAT", "Spade", "Heart", "pic1", "pic1", "pic1", "WILD", "pic3", "Spade", "Club", "Heart", "pic3", "Spade", "Diamond", "Heart", "pic5"],
			["Diamond", "Heart", "pic2", "Heart", "Club", "WILD", "pic1", "pic2", "Club", "pic4", "pic2", "Spade", "SCAT", "Diamond", "Club", "pic4", "pic1", "pic1", "pic1", "pic2", "Club", "pic4", "pic2", "Heart", "Club", "pic3", "pic5"],
			["SCAT", "pic4", "Diamond", "pic2", "Heart", "Spade", "pic2", "Diamond", "pic3", "pic3", "Heart", "pic2", "Club", "Diamond", "pic2", "pic1", "Club", "pic4", "Diamond", "pic2", "pic4", "Club", "pic3", "Heart", "pic4", "Heart", "pic3", "Heart", "WILD", "Spade", "pic3", "pic2", "pic1", "pic1", "pic1", "pic5"]
		],
		
		"defaultSelectLine": 20,
		"coinType1Values": [
			250,
			2500,
			10000,
			50000,
			100000
		],
		"coinType4Values": [
			1,
			2,
			5,
			10,
			50
		],
		"coinType1_min_bet": 250,
		"coinType1_max_bet": 100000,
		"coinType1_spin_max_bet": 2500000,
		"coinType4_min_bet": 1,
		"coinType4_max_bet": 50,
		"coinType4_spin_max_bet": 1250,
		"winLevels": [{
				"name": "normal1",
				"threshold": 5
			},
			{
				"name": "normal2",
				"threshold": 15
			},
			{
				"name": "normal3",
				"threshold": 25
			},
			{
				"name": "bigwin",
				"threshold": 50
			},
			{
				"name": "megawin",
				"threshold": 150
			},
			{
				"name": "epicwin",
				"threshold": 2147483647
			}
		],
		"freeSpinTrigger": "SCAT",
		"countToTriggerFreeSpin": 3,
		"wheelOptions": {
			"freeGames": [
				8,
				10,
				15
			],
			"freeGamesWeights": [
				1,
				2,
				1
			],
			"ups": [
				1,
				2,
				3,
				4
			],
			"upsWeights": [
				2,
				3,
				3,
				5
			],
			"multipliers": [
				1,
				2,
				3,
				4,
				5
			],
			"multipliersWeights": [
				3,
				7,
				3,
				1,
				1
			]
		},
		"credits": 25,
		"currency_symbol": "$"
	},
	"modelId": "vgw030",
	"gameSessionId": 95,
	"reelStripPositions": [
		26,
		13,
		13,
		14,
		33
	],
	"slotWindow": [
		[
			"Heart",
			"pic4",
			"Heart"
		],
		[
			"Heart",
			"SCAT",
			"Heart"
		],
		[
			"Spade",
			"Diamond",
			"SCAT"
		],
		[
			"Club",
			"pic4",
			"pic1"
		],
		[
			"pic1",
			"pic1",
			"pic5"
		]
	],
	"gold_balance": 10000000,
	"sweeps_balance": 10000000,
	"sweeps_deposit_balance": 10000000,
	"sweeps_promo_balance": 0,
	"sweeps_win_balance": 0,
	"sweeps_available_balance": 0,
	"serverTime": "2018-11-29 11:14:04"
}
