import type { Choices } from "../../../../choices";
import { modelDefinition } from "../../../../model-definition";
import type { Position } from "../../../shared";

export function computeGreenFeaturePrizes(options: { choices: Choices; coinAmount: number; position: Position }): {
    coinPrizes: Array<{
        type: "COIN" | "JACKPOT_MINI" | "JACKPOT_MINOR";
        prize: number;
    }>;
    totalValue: number;
} {
    const { choices, coinAmount, position } = options;
    const numberOfCoinPrizes: number = choices.genie.chooseNumberOfGreenFeatureCoinPrizes({ position });
    const coinPrizes: Array<{
        type: "COIN" | "JACKPOT_MINI" | "JACKPOT_MINOR";
        prize: number;
    }> = [];
    for (let i = 0; i < numberOfCoinPrizes; i++) {
        const prize = choices.genie.chooseGreenFeatureCoinPrize({
            existingCoinPrizes: coinPrizes.map((a) => a.prize),
            position,
            prizeIndex: i,
            coinAmount,
        });
        coinPrizes.push({
            type: "COIN",
            prize: prize * coinAmount,
        });
    }

    const fixedJackpot: "JACKPOT_MINI" | "JACKPOT_MINOR" | undefined = choices.genie.chooseGreenFeatureFixedJackpot({
        position,
    });

    if (fixedJackpot) {
        const fixedJackpotKey = fixedJackpot === "JACKPOT_MINI" ? "MINI" : "MINOR";
        coinPrizes.push({
            type: fixedJackpot,
            prize: modelDefinition.jackpots.fixedJackpots[fixedJackpotKey] * coinAmount,
        });
    }

    const totalValue = coinPrizes.reduce((total, coinPrize) => {
        return total + coinPrize.prize;
    }, 0);

    coinPrizes.sort((a, b) => {
        return a.prize - b.prize;
    });
    return {
        coinPrizes,
        totalValue,
    };
}
