export interface StepJson {
    name: string;
    originatorId: number;
    gameState: 'clear' | 'continue';
    reelStripPositions: number[];
    coinPrizes?: CoinPrize[][];
    freeSpinPrizes?: FreeSpinPrize[];
}

export type CoinPrize = number | 'MINI' | 'MINOR' | 'MAJOR' | 'UPGRADE' | 'COLLECT';

export type FreeSpinPrize = number | 'MINI' | 'MINOR' | 'MAJOR';

export function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        originatorId,
        gameState: 'clear',
        reelStripPositions: [0, 0, 0, 0, 0],
    };
}

export function createSpinStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [14, 14, 14, 14, 14],
    };
}

export function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [15, 32, 1, 29, 24],
    };
}

export function createFireshotTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [16, 34, 18, 3, 22],
    };
}

export const originatorId = new Date().getTime();
