import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Vue from "vue";
import { model, GameState } from "./model";
import { createChoicesFromScenario } from "./model/choices";
import {
  StepJson,
  originatorId,
} from "../../../../state/models/slots/star-catch-jackpots";
import { SlotWindow } from "./slot-window";
import { modelDefinition } from "./model/model-definition";

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
  },
  data: function (): {
    scenario: InternalScenario;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        reelStripPositions: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
        ],
        isSuperStarAwarded: false,
        superStarMultiplier: 1,
      },
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      console.log("computed spin outcome");
      const spinOutput = model.play(
        {
          coinType: "SC",
          gameRequest: { coinAmount: 1 },
          gameState: this.currentStep?.gameState,
        },
        createChoicesFromScenario({
          ...this.scenario,
          reelStripPositions: this.scenario.reelStripPositions.map(
            ({ value }) => value
          ),
        })
      );
      console.log("spinOutput: ", spinOutput);

      return spinOutput;
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    superStarMultiplierList(): number[] {
      return [
        ...new Set([
          ...modelDefinition.superStarMultiplierWeights.map((w) => w.outcome),
          1,
        ]),
      ].sort();
    },
  },
  methods: {
    addClearingStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "clear",
        reelStripPositions: [3, 5, 0, 0, 3],
      });
    },
    addStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [3, 5, 0, 0, 3],
      });
    },
    addFreeSpinTriggerStep() {
      bus.$emit(events.ADD_STEP, {
        name: "free spin",
        originatorId,
        reelStripPositions: [0, 0, 3, 4, 15],
      });
    },
    addScatterWinStep() {
      bus.$emit(events.ADD_STEP, {
        name: "scatter win",
        originatorId,
        reelStripPositions: [44, 40, 22, 23, 27],
      });
    },
    addSuperStarSpinStep() {
      bus.$emit(events.ADD_STEP, {
        name: "scatter win",
        originatorId,
        reelStripPositions: [44, 40, 22, 23, 27],
        isSuperStarAwarded: true,
        superStarMultiplier: 5,
      });
    },
    addJackpotTriggerStep() {
      bus.$emit(events.ADD_STEP, {
        name: "jackpot win",
        originatorId,
        reelStripPositions: [44, 40, 22, 23, 26],
        isSuperStarAwarded: true,
      });
    },
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    handleSuperStarSelected() {
      this.scenario.superStarMultiplier =
        this.scenario.superStarMultiplier ?? 1;

      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }
        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertToStepJson(newScenario),
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
  };
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  return {
    gameState: scenario.gameState,
    superStarMultiplier: scenario.superStarMultiplier,
    isSuperStarAwarded: scenario.isSuperStarAwarded,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
  };
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;

  for (const step of steps) {
    if (step.json.gameState === "clear") gameState = undefined;

    step.gameState = gameState;

    const spinOutput = model.play(
      {
        coinType: "SC",
        gameRequest: { coinAmount: 1 },
        gameState,
      },
      createChoicesFromScenario({
        ...step.json,
      })
    );

    gameState = spinOutput.gameState;
  }

  return modified;
}

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

interface InternalScenario {
  reelStripPositions: { value: number }[];
  isSuperStarAwarded?: boolean;
  superStarMultiplier?: number;
  gameState?: "continue" | "clear";
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;
