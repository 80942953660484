import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import type { Choices } from "./choices";
import { modelDefinition } from "../model-definition/index";
import type { FeatureColour, Position } from "../operations/shared/index";
import {
  FeatureColoursSchema,
  PositionSchema,
} from "../operations/shared/index";

const GreenFeatureScenarioSchema = Type.Object({
  type: Type.Literal("FEATURE"),
  colour: Type.Literal("G"),
  position: PositionSchema,
  prizes: Type.Array(
    Type.Union([
      Type.Object({
        type: Type.Literal("COIN"),
        prize: Type.Integer(),
      }),
      Type.Object({
        type: Type.Union([
          Type.Literal("JACKPOT_MINI"),
          Type.Literal("JACKPOT_MINOR"),
        ]),
      }),
    ])
  ),
});
const PurpleFeatureScenarioSchema = Type.Object({
  type: Type.Literal("FEATURE"),
  colour: Type.Literal("P"),
  position: PositionSchema,
  prize: Type.Union([
    Type.Integer(),
    Type.Literal("JACKPOT_MINI"),
    Type.Literal("JACKPOT_MINOR"),
    Type.Literal("JACKPOT_MAJOR"),
  ]),
});
const RedFeatureScenarioSchema = Type.Object({
  type: Type.Literal("FEATURE"),
  colour: Type.Literal("R"),
  position: PositionSchema,
});
export const FeatureSchema = Type.Union([
  GreenFeatureScenarioSchema,
  PurpleFeatureScenarioSchema,
  RedFeatureScenarioSchema,
]);

export const CoinPrizeSchema = Type.Optional(
  Type.Union([
    Type.Object({
      type: Type.Union([Type.Literal("COIN")]),
      prize: Type.Integer(),
      position: PositionSchema,
    }),
    Type.Object({
      type: Type.Union([
        Type.Literal("JACKPOT_MINI"),
        Type.Literal("JACKPOT_MINOR"),
      ]),
      position: PositionSchema,
    }),
    Type.Object({
      type: Type.Literal("JACKPOT_MAJOR"),
      position: PositionSchema,
    }),
    FeatureSchema,
  ])
);

export const CoinPrizesScenario = Type.Array(CoinPrizeSchema);
export type CoinPrizesScenario = Static<typeof CoinPrizesScenario>;

export const ScenarioSchema = Type.Object({
  reelStripPositions: Type.Optional(Type.Array(Type.Integer())),
  mysterySymbol: Type.Optional(Type.String()),
  genieBonusLamps: Type.Optional(
    Type.Array(
      Type.Object({ colour: FeatureColoursSchema, position: PositionSchema })
    )
  ),
  coinPrizes: Type.Optional(CoinPrizesScenario),
});
export type Scenario = Static<typeof ScenarioSchema>;

export const OpenScenarioSchema = Type.Object({
  reelStripPositions: Type.Optional(Type.Array(Type.Integer())),
  mysterySymbol: Type.Optional(Type.String()),
  genieBonusLamps: Type.Optional(
    Type.Union([
      Type.Array(
        Type.Array(Type.Union([FeatureColoursSchema, Type.Literal("")]))
      ),
      Type.Array(
        Type.Object({
          colour: FeatureColoursSchema,
          position: PositionSchema,
        })
      ),
    ])
  ),
  coinPrizes: Type.Optional(CoinPrizesScenario),
});
export type OpenScenario = Static<typeof OpenScenarioSchema>;

export function createChoicesFromScenario(scenario: Scenario): Choices {
  const findPosition = (position: Position, arrayPositions: Position[]) => {
    return arrayPositions.find(
      (arrayPosition) =>
        arrayPosition[0] === position[0] && arrayPosition[1] === position[1]
    );
  };
  return {
    base: {
      chooseMysterySymbol() {
        return (
          scenario.mysterySymbol ??
          modelDefinition.mysterySymbolWeights[
            modelDefinition.mysterySymbolWeights.length - 1
          ].outcome
        );
      },
      chooseReelStripPositions() {
        return scenario.reelStripPositions ?? [34, 6, 28, 53, 57];
      },
    },
    genie: {
      chooseTriggeringCoinPrize(coinPrizeOptions: {
        position: [number, number];
      }): number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" {
        const { position } = coinPrizeOptions;
        const nonFeaturePrizes =
          scenario.coinPrizes?.filter(
            (unfilteredPrize) => unfilteredPrize.type !== "FEATURE"
          ) ?? [];
        const coinPrize = nonFeaturePrizes.find(
          (nonFeature) =>
            nonFeature.position[0] === position[0] &&
            nonFeature.position[1] === position[1]
        );

        if (coinPrize === undefined) {
          throw Error(`Invalid amount of triggering coin prizes than expected`);
        }
        if (coinPrize.type === "FEATURE") {
          throw Error(`No feature allowed during trigger`);
        }

        return coinPrize.type === "COIN" ? coinPrize.prize : coinPrize.type;
      },
      chooseRespinCoinPrize(coinPrizeOptions: {
        coinWeight: number;
        position: Position;
        existingFeatureCount: number;
      }):
        | number
        | "JACKPOT_MINI"
        | "JACKPOT_MINOR"
        | "JACKPOT_MAJOR"
        | "FEATURE" {
        const { position, existingFeatureCount } = coinPrizeOptions;

        const coinPrize = scenario.coinPrizes?.find(
          (feature) =>
            feature.position[0] === position[0] &&
            feature.position[1] === position[1]
        );

        if (coinPrize === undefined) {
          throw Error(`Invalid amount of respin coin prizes than expected`);
        }

        if (
          coinPrize.type === "FEATURE" &&
          existingFeatureCount === modelDefinition.maxRespinFeatures
        ) {
          throw Error(`Features cannot exceed 5`);
        }

        return coinPrize.type === "COIN" ? coinPrize.prize : coinPrize.type;
      },
      chooseDoesCoinLand(_, position: Position): boolean {
        if (scenario.coinPrizes === undefined) {
          return false;
        }

        return (
          findPosition(
            position,
            scenario.coinPrizes.map((prize) => prize.position)
          ) !== undefined
        );
      },
      choosePurpleTriggeringCoinPrize(coinPrizeOptions: {
        position: [number, number];
      }): number | "JACKPOT_MINI" | "JACKPOT_MINOR" | "JACKPOT_MAJOR" {
        const { position } = coinPrizeOptions;
        const prizes = scenario.coinPrizes ?? [];

        const coinPrize = prizes
          .filter((prize) => prize.type === "FEATURE" && prize.colour === "P")
          .find(
            (feature) =>
              feature.position[0] === position[0] &&
              feature.position[1] === position[1]
          );

        if (coinPrize === undefined) {
          throw Error(
            `Invalid amount of triggering purple coin prizes than expected`
          );
        }

        // @ts-ignore // Scenario manager is not handling type narrowing correctly.
        return coinPrize.prize;
      },
      chooseHardPosition() {
        return [0, 0];
      },
      chooseRespinFeatureColour(
        validColours: FeatureColour[],
        position: Position
      ): FeatureColour {
        const featureCoinPrizes = scenario.coinPrizes?.filter(
          (prize) => prize.type === "FEATURE"
        );
        if (!featureCoinPrizes) {
          throw Error(
            `Expected FEATURE coin prizes during RESPIN, but found none`
          );
        }

        const feature = featureCoinPrizes.find(
          (prize) =>
            prize.position[0] === position[0] &&
            prize.position[1] === position[1]
        );
        if (!feature || feature.type !== "FEATURE") {
          throw Error(
            `Cannot find FEATURE at position [${position[0]}, ${position[1]}]`
          );
        }

        if (!validColours.includes(feature.colour)) {
          throw Error(
            `FEATURE colour ${feature.colour} is not one of the valid colours: ${validColours.join(",")}`
          );
        }

        return feature.colour;
      },
      chooseFeatureColour(triggerCount: number): FeatureColour[] {
        if (triggerCount === 0) return [];
        const featuresInCoinPrizes =
          scenario.coinPrizes
            ?.filter((prize) => prize.type === "FEATURE")
            .map((prize) => prize.position) ?? [];
        return (
          scenario.genieBonusLamps
            ?.filter((lamp) =>
              findPosition(lamp.position, featuresInCoinPrizes)
            )
            .map((prize) => prize.colour) ?? []
        );
      },
      chooseTriggerCount(scatters: Position[]): number {
        const lamps = scenario.genieBonusLamps ?? [];

        const nonFeatureCoinPrizes =
          scenario.coinPrizes?.filter((prize) => prize.type !== "FEATURE") ??
          [];
        if (nonFeatureCoinPrizes.length < 5) return 0;

        if (scatters.length === 0 || lamps.length === 0) {
          return 0;
        }

        const nonMatchingPositions = scatters.filter(
          (scatterPosition) =>
            !findPosition(
              scatterPosition,
              lamps.map((lamp) => lamp.position)
            )
        );

        if (nonMatchingPositions.length > 0) {
          throw Error(
            `Genie lamp positions [${nonMatchingPositions.join("], [")}] does not match scatter positions`
          );
        }

        const triggers = scenario.coinPrizes?.filter(
          (prize) =>
            findPosition(
              prize.position,
              lamps.map((lamp) => lamp.position)
            ) !== undefined
        );
        return triggers?.length ?? 0;
      },
      chooseRandomColorPosition(params: {
        array: unknown[];
        position: Position;
        isFeature: boolean;
      }): number {
        const { array, position } = params;

        const scatProperties = scenario.genieBonusLamps?.find(
          (scatColor) =>
            scatColor.position[0] === position[0] &&
            scatColor.position[1] === position[1]
        );

        if (!scatProperties) {
          throw Error(
            `Scat color for position [${position[0]}, ${position[1]}] not provided`
          );
        }

        const indexOfColor = array.indexOf(scatProperties.colour);

        if (indexOfColor === -1) {
          throw Error(
            `Scat color for provided for position [${position[0]}, ${position[1]}] is not matching trigger colors`
          );
        }

        return indexOfColor;
      },
      chooseRandomPosition(array: Position[]): number {
        const nonFeatures = scenario.coinPrizes?.filter(
          (item) =>
            item.type !== "FEATURE" && findPosition(item.position, array)
        );
        const nonFeatureAtPosition = nonFeatures?.[0];

        if (nonFeatureAtPosition === undefined) {
          throw Error(`Not enough coinPrizes provided`);
        }

        return array.findIndex(
          (item) =>
            item[0] === nonFeatureAtPosition.position[0] &&
            item[1] === nonFeatureAtPosition.position[1]
        );
      },
      chooseRandomScatterPosition(params: {
        array: Position[];
        triggeringColour: FeatureColour;
      }): number {
        const { array, triggeringColour } = params;

        const allScattersPositionsWithTriggeringColour =
          scenario.genieBonusLamps?.filter(
            (scatColor) => scatColor.colour === triggeringColour
          );

        if (!allScattersPositionsWithTriggeringColour) {
          throw Error(
            `Scat colour ${triggeringColour} is not defined in "triggeringColors"`
          );
        }

        const scatterPositionWithTriggeringColourIndex: number | undefined =
          array.findIndex((availableScatPosition) => {
            return (
              allScattersPositionsWithTriggeringColour.find(
                (scatterPositionFromScenario) => {
                  return (
                    scatterPositionFromScenario.position[0] ===
                      availableScatPosition[0] &&
                    scatterPositionFromScenario.position[1] ===
                      availableScatPosition[1]
                  );
                }
              ) !== undefined
            );
          });

        if (scatterPositionWithTriggeringColourIndex === -1) {
          throw Error(
            `Scat position not provided for colour ${triggeringColour} `
          );
        }

        return scatterPositionWithTriggeringColourIndex;
      },
      chooseNumberOfGreenFeatureCoinPrizes({ position }) {
        const feature = scenario.coinPrizes?.find(
          (coinPrize) =>
            coinPrize.type === "FEATURE" && coinPrize.colour === "G"
        );

        if (!feature) {
          throw Error(
            `Green feature not defined at position [${position[0]}, ${position[1]}]`
          );
        }

        if (feature.type === "FEATURE" && feature.colour === "G") {
          const prizesExcludingFixedJackpots = feature.prizes.filter(
            (prize) => prize.type === "COIN"
          );

          if (prizesExcludingFixedJackpots.length === 0) {
            throw Error("Green Feature prizes not defined");
          }
          return prizesExcludingFixedJackpots.length;
        } else {
          return 0;
        }
      },
      chooseGreenFeatureCoinPrize({
        position,
        existingCoinPrizes,
        prizeIndex,
        coinAmount,
      }) {
        const features = scenario.coinPrizes?.filter(
          (coinPrize) =>
            coinPrize.type === "FEATURE" && coinPrize.colour === "G"
        );

        const featureAtPosition = features?.find(
          (feature) =>
            feature.position[0] === position[0] &&
            feature.position[1] === position[1]
        );

        if (!featureAtPosition) {
          throw Error(
            `Green feature not defined at position [${position[0]}, ${position[1]}]`
          );
        }

        if (
          featureAtPosition.type === "FEATURE" &&
          featureAtPosition.colour === "G"
        ) {
          const prizesExcludingFixedJackpots = featureAtPosition.prizes.filter(
            (prize) => prize.type === "COIN"
          );
          if (prizesExcludingFixedJackpots.length === 0) {
            throw Error("Green Feature prizes not defined");
          }

          const prizeAtIndex = prizesExcludingFixedJackpots[prizeIndex];

          const prizeAmount =
            prizeAtIndex.type === "COIN" ? prizeAtIndex.prize * coinAmount : 0;
          if (existingCoinPrizes.includes(prizeAmount)) {
            throw Error(
              `Coin prize ${prizeAmount} has already been choosen as one of the Green feature prizes`
            );
          }

          return prizeAmount;
        } else {
          throw Error(`Green feature coin muust be a FEATURE`);
        }
      },
      chooseGreenFeatureFixedJackpot(options: {
        position: Position;
      }): "JACKPOT_MINI" | "JACKPOT_MINOR" | undefined {
        const { position } = options;
        const features = scenario.coinPrizes?.filter(
          (coinPrize) =>
            coinPrize.type === "FEATURE" && coinPrize.colour === "G"
        );

        const featureAtPosition = features?.find(
          (feature) =>
            feature.position[0] === position[0] &&
            feature.position[1] === position[1]
        );

        if (!featureAtPosition) {
          throw Error(
            `Green feature not defined at position [${position[0]}, ${position[1]}]`
          );
        }

        if (
          featureAtPosition.type === "FEATURE" &&
          featureAtPosition.colour === "G"
        ) {
          const fixedJackpots: Array<{
            type: "JACKPOT_MINI" | "JACKPOT_MINOR";
          }> = [];
          featureAtPosition.prizes.forEach((prize) => {
            if (prize.type !== "COIN") {
              fixedJackpots.push(prize);
            }
          });

          if (fixedJackpots.length > 1) {
            throw Error(
              `Green feature prizes can only include one fix jackpot at position [${position[0]}, ${position[1]}]`
            );
          }

          if (fixedJackpots.length === 1) {
            return fixedJackpots[0].type;
          }
        } else {
          return;
        }
      },
    },
  };
}
