var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "board-game-selector" }, [
    _c("p", { staticClass: "picks-type" }, [
      _c(
        "label",
        { staticClass: "picks-type-text", attrs: { for: "board-game-picks" } },
        [_vm._v("Picks:")]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.step.boardGamePicks,
              expression: "step.boardGamePicks",
            },
          ],
          attrs: { id: "board-game-picks" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.step,
                "boardGamePicks",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { domProps: { value: 4 } }, [_vm._v("4")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 5 } }, [_vm._v("5")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 6 } }, [_vm._v("6")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 7 } }, [_vm._v("7")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.step.boardGameSteps, function (boardStep, index) {
        return _c("card", { key: index, attrs: { details: boardStep } })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }