import Vue from "vue";
import Component from "vue-class-component";
import * as casino from "./../../helpers/casino";
import {trace, TODO, randRange, compareValues, setOrder, getAssetsPath} from "./../../helpers/helpers";
import State from "../../state/state";
import bus from "../../common/bus";
import events from "../../common/events";

@Component({
    // define reactive variables
    data: () => {
        return {
            list: [],
            selectedScenario: {},
        };
    },
})
export default class ListOfScenarios extends Vue {
    public btnUpload: string = `${getAssetsPath()}/images/btn_upload.png`;  
    public btnAddScenario: string = `${getAssetsPath()}/images/btn_new_step.png`;  
    public btnTrash: string = `${getAssetsPath()}/images/btn_trash_white.png`;  
    public btnSelect: string = `${getAssetsPath()}/images/btn_arrow_right.png`;  
    public selectedScenario: any = {};
    public scenarios: any = [];
    private newScenarioName = "";

    constructor() {
        super();
        this.selectedScenario = this.scenarios[0];

        bus.$on(events.GET_SCENARIOS, (scenarios) => this.getScenarios(scenarios));
        bus.$on(events.UPDATE_SCENARIOS, (scenarios) => this.updateScenarios(scenarios));
        bus.$on(events.UPDATE_STEPS, (scenario) => this.updateSteps(scenario));
        bus.$on(events.RECREATE_SCENARIO, (steps) => this.recreateScenario(steps));
    }
    public getScenarios(scenarios: any) { 
        this.scenarios = this.setScenarios(scenarios); 
        const scenario = (this.scenarios.length === 0) ? null : this.scenarios[0]; 
        this.openScenario(scenario); 
    }
    private setScenarios(scenarios: any) {
        return scenarios.map(scenario => {
            scenario.steps = this.sortScenario(scenario);
            return scenario;
        });
    }
    private sortScenario(scenario) {
        const a: any = [];
        let c = 0;
        while (c < scenario.steps.length) {
            const step = scenario.steps.find(st => st.order_number === c);
            a.push(step);
            c++;
        } 
        return a;
    }
    public async updateScenarios(scenarios: any) { 
        const previousScenarioId = this.selectedScenario ? this.selectedScenario.test_scenario_id : null;
        
        // trace("UPDATING SCENARIOS: ");
        // trace(scenarios);

        // update the current ones to remove editing (before reassigning)
        this.scenarios.forEach((it) => it.edit = false); 
        this.scenarios = this.setScenarios(scenarios); 

        let updatedScenario = this.scenarios.find(it => previousScenarioId != null && it.test_scenario_id === previousScenarioId);

        if (this.scenarios.length === 0) { 
            
        } else if (this.scenarios.length === 1) {
            updatedScenario = this.scenarios[0];
        }
        if (!updatedScenario && this.scenarios.length > 0) {
            let p: any = null;
            bus.$emit(events.SHOW_POPUP, (promise) => p = promise, "The scenario you where working on was deleted :(", false, false, true, false, true);
            await p; 
            updatedScenario = this.scenarios[0] || undefined;
        } 

        this.openScenario(updatedScenario);
    }
    public updateSteps(scenario: any) {
        trace("Updating steps: ");
        // trace(scenario);
        const s = this.scenarios.find(it => it.test_scenario_id === scenario.test_scenario_id);
        if (!s) { return; }

        s.steps = this.sortScenario(scenario);

        bus.$emit(events.REFRESH_SCENARIO, this.selectedScenario);
    }
    public doUpload() {
        TODO("do Upload action");
    }
    public doAddScenario() {
        State.state.addScenario();
    }
    public trimName(name): string {
        let s = name;
        if (name.length > 35) {
            s = name.substr(0, 35);
        }
        return s;
    }
    public editScenario(scenario) {
        trace(scenario);
    }
    public async deleteScenario(scenario) {
        trace(scenario); 
        const state = State.state;
        const i = this.scenarios.indexOf(scenario);
        
        const msg = `Delete scenario "${scenario.name}"?`;
        let p: any = null;
        bus.$emit(events.SHOW_POPUP, (promise) => p = promise, msg, true, true, false, false, true);
        const res = await p; 
        if (res) {
            state.deleteScenario(scenario.test_scenario_id);
            this.scenarios.splice(i, 1);
            this.openScenario(this.scenarios[0]);
        }
    }
    public openScenario(scenario) {
        this.selectedScenario = scenario;
        bus.$emit(events.CHANGE_SCENARIO, scenario);
    }
    public setEditMode(scenario) {
        this.scenarios.forEach((it) => it.edit = false);
        this.newScenarioName = scenario.name;
        scenario.edit = true;
    }
    // triggered when moving steps around
    public recreateScenario(steps) { 
        setOrder(this.selectedScenario.steps);
        State.state.recreateScenario(this.selectedScenario.steps );
    }
    public checkNameChange(scenario) {
        const state = State.state;
        if (this.newScenarioName === "" || this.newScenarioName === scenario.name) {
            scenario.edit = false; 
            return;
        }
        scenario.name = this.newScenarioName;
        scenario.edit = false; 

        State.state.editScenario(scenario.test_scenario_id, this.newScenarioName);
        this.$emit("update");
    }
}
