import { modelDefinition } from "./math-model";
import { GameState } from "./math-model/game-state";
import { SpinOutcome } from "./math-model/spin";

export type SpinType = "BASE" | "WILD_FURY" | "FREE_SPINS";

export function spinTypeFromGameState(gameState?: GameState): SpinType {
  if (!gameState) {
    return "BASE";
  }

  if (gameState.wildFuryPhase) {
    return "WILD_FURY";
  }

  return "FREE_SPINS";
}

export function spinTypeFromSpinOutcome(spinOutcome: SpinOutcome): SpinType {
  if (spinOutcome.wildFuryPhase) {
    return "WILD_FURY";
  }

  if (spinOutcome.freeSpinPhase && spinOutcome.freeSpinPhase !== "START") {
    return "FREE_SPINS";
  }

  return "BASE";
}

export function getReels(spinType: SpinType): string[][] {
  if (spinType === "BASE") {
    return modelDefinition.reels;
  }

  if (spinType === "WILD_FURY") {
    return modelDefinition.wildFury.reels;
  }

  return modelDefinition.freeSpins.reels;
}

export function wrapPosition(
  position: number,
  spinType: SpinType,
  reelIndex: number,
): number {
  const reels = getReels(spinType);
  const reel = reels[reelIndex];

  return (position + reel.length) % reel.length;
}
