export function orientatePath({
    currentPath,
    numberOfReels,
    numberOfSymbols,
    flipX,
    flipY,
    swapAxis,
}: {
    currentPath: number[][];
    numberOfReels: number;
    numberOfSymbols: number;
    flipX: boolean;
    flipY: boolean;
    swapAxis: boolean;
}): number[][] {
    const path = currentPath.map((col) => [...col]);
    return path.map((cell) => {
        if (flipX) {
            const currentX = cell[1];
            cell[1] = numberOfReels - 1 - currentX;
        }
        if (flipY) {
            const currentY = cell[0];
            cell[0] = numberOfSymbols - 1 - currentY;
        }
        if (swapAxis) {
            [cell[0], cell[1]] = [cell[1], cell[0]];
        }
        return cell;
    });
}
