import type { PlayArguments } from "@vgw/gdk-math-model-server";
import { createValueValidator } from "@vgw/gdk-math-model-tools";

import type { GameState } from "../shared";
import type { GameRequest } from "./game-request";
import { modelDefinition } from "../../model-definition";

export type SpinInputs = PlayArguments<GameRequest, GameState>;

export function assertSpinInputsValid({
  coinType,
  gameRequest,
  gameState,
}: SpinInputs): void {
  // Ensure a valid coin amount has been sent
  if (!isValidCoinAmount(coinType, gameRequest.coinAmount)) {
    throw new Error("Invalid coin amount seleceted");
  }

  // Ensure play amount does not change during a round
  if (gameState && gameState.coinAmount !== gameRequest.coinAmount) {
    throw new Error("Coin amount changed during a round");
  }

  if (gameState?.picks && !gameRequest.pickId) {
    throw new Error("Pick id is equired");
  }
}

const isValidCoinAmount = createValueValidator(
  modelDefinition.validCoinAmounts
);
