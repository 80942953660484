var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reels, function (row, i) {
          return _c(
            "div",
            { key: i, staticClass: "symbol on" },
            [
              _c("SymbolPickable", {
                attrs: {
                  symPicksReel: _vm.symPicks,
                  index: i,
                  value: _vm.symPicks[i],
                  symbolInfo: _vm.symbolInfoReel,
                  isJackpotLocked: _vm.isJackpotContainer,
                  isPreviousCoin: _vm.isPreviousCoin(i),
                  isCascade: _vm.isCascade,
                  isLocked: _vm.isLocked,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }