import Vue from "vue";
import SM from "./components/sm.vue"; 
import * as casino from "./helpers/casino";
import { randString, trace, TODO } from "./helpers/helpers";
import State from "./state/state";
import bus from "./common/bus";
import events from "./common/events";

export default class SM2 {
    public component;
    private div;
    private parentDiv;
    private divName;
    constructor(slot, parentDiv = "#vue_scenario_manager") {
        State.state = {
            slot,
            slotState: slot.state,
            main: this,
            userId: casino.getUserId(), 
            gameId: slot.state.gameId,
        };
        // create the html div container that will hold the VUE window
        this.parentDiv = parentDiv;
        this.divName = randString(30, "abcdefghijklmnopqrstuvwxyz"); 
        const $ = window["$"];
        this.div = $(`<div class='${this.divName}'></div>`);
        $(this.parentDiv).append(this.div);
        this.component = new Vue({
            el: "." + this.divName, 
            render: h => h(SM),
        });
        bus.$on(events.DESTROY_SCENARIO_MANAGER, () => this.destroy());
    }
    public show() {
        bus.$emit(events.SHOW_SCENARIO);
    }
    public setStandalone(gameId) {
        State.state = { standalone: true };
        State.state = { gameId };
    }
    public destroy() {
        if (!this.component) { return; }
        const $ = window["$"];
        State.state.disconnectSocket();
        this.component.$destroy(true);
        bus.$destroy();
        this.component = null;
        $(this.parentDiv).empty();
        
    }
} 

window["chumba"].scenarioManager = SM2;
