var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c("label", { attrs: { for: _vm.index } }, [
      _vm._v(" Prize #" + _vm._s(_vm.index) + ": "),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.scattersSelectedMultipliers[_vm.index - 1],
            expression: "scattersSelectedMultipliers[index - 1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.scattersSelectedMultipliers,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updateRespinScatterMulti()
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "NoScatter" } }, [_vm._v("NoScatter")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "Random" } }, [_vm._v("Random")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "Bunch" } }, [_vm._v("Bunch")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "Mike" } }, [_vm._v("Mike")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1000" } }, [_vm._v("1000")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MGB1" } }, [_vm._v("MGB1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MGB2" } }, [_vm._v("MGB2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MGB3" } }, [_vm._v("MGB3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MGB4" } }, [_vm._v("MGB4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MGB5" } }, [_vm._v("MGB5")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }