import { filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/index';
import type { GameResponse } from '../game-response';
import type { WinInfo } from '../create-wins-evaluator';

export function evaluateBankerCaseFeature(
    slotWindow: string[][],
    { winAmount }: WinInfo,
    choices: Choices,
    isFreeSpin: boolean,
    bankerStacks: GameResponse['bankerStacks'] | undefined,
): GameResponse['bankerCase'] {
    // Determine if banker CASE is awarded.
    const caseCount = slotWindow.flat().filter((symbol) => symbol === 'CASE').length;
    const canAward = caseCount === 6 && winAmount === 0 && bankerStacks === undefined;
    if (!canAward) return;

    const isBankerCaseAwarded = choices.chooseIfBankerCaseIsAwarded(isFreeSpin);
    if (!isBankerCaseAwarded) return;

    // Determine which CASE symbol in the slot window to replace.
    const candidateCells = filterSlotWindow(slotWindow, (symbol, [row, col]) => {
        // Limit to CASE symbols that appear on reels 2-5. Reel 1 is excluded to avoid creating new line wins.
        if (symbol !== 'CASE' || col === 0) return false;

        // For stacks of 3 CASEs, top/bottom CASEs may be replaced, but never the middle one (as CASE-X-CASE would never occur naturally).
        const isStackOfThree = slotWindow[col][0] === 'CASE' && slotWindow[col][2] === 'CASE';
        if (isStackOfThree) return row !== 1;

        // For stacks of 2 CASES, the middle CASE may be replaced but never the top/bottom one (as X-CASE-X would never occur naturally).
        const isStackOfTwo = slotWindow[col][1] === 'CASE';
        if (isStackOfTwo) return row === 1;

        // Otherwise the column has only one CASE. It may be replaced.
        return true;
    });
    const cell = choices.chooseBankerCaseCell(candidateCells);

    // Determine the replacement symbol.
    const candidateSymbols = ['A', 'K', 'Q', 'J', 'T', 'N'].filter((symbol) => {
        // Never choose a symbol that appears in the first column of the slot window, to ensure no new line wins are created.
        if (slotWindow[0].includes(symbol)) return false;

        // Never choose a symbol that appears in the slot window column where the replacement is being made,
        // since that would create stack of two suit symbols, which would never occur naturally.
        const [_, col] = cell;
        return !slotWindow[col].includes(symbol);
    });
    const symbol = choices.chooseBankerCaseSymbol(candidateSymbols);
    return { cell, symbol };
}
