import Vue, { PropType } from "vue";
import { getReels } from "../../math-model-helpers";
import { Wild } from "../../math-model/wild";

export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    spinType: String as PropType<"WILD_FURY" | "FREE_SPINS" | "BASE">,
    wilds: Array as PropType<Array<Wild> | undefined>,
    newWilds: Array as PropType<Array<Wild> | undefined>,
  },
  computed: {
    originalSymbol: function (): string {
      const reel = getReels(this.spinType)[this.colIndex];

      return reel[(this.position + this.rowIndex) % reel.length];
    },
    isWild: function (): boolean {
      return this.symbolName === "WILD";
    },
    newWildIndex: function (): number | undefined {
      if (!this.newWilds) {
        return undefined;
      }

      const index = this.newWilds.findIndex(
        isThisCell(this.rowIndex, this.colIndex),
      );

      if (index === -1) {
        return undefined;
      }

      return index;
    },
    isNewWild: function (): boolean {
      return this.newWildIndex !== undefined;
    },
    isScatter: function (): boolean {
      return this.originalSymbol === "SCAT";
    },
    wildModifier: {
      get: function (): undefined | number | string {
        if (!this.wilds) {
          return undefined;
        }

        const wildInfo = this.wilds.find(
          isThisCell(this.rowIndex, this.colIndex),
        );

        if (!wildInfo) {
          return undefined;
        }

        if (wildInfo.jackpotType) {
          return wildInfo.jackpotType;
        }

        return wildInfo.multiplier;
      },
      set: function (modifier): void {
        this.$emit("modifier-changed", {
          modifier,
          newWildIndex: this.newWildIndex,
        });
      },
    },
    wildModifierDisplay: function (): string {
      if (typeof this.wildModifier === "string") {
        return this.wildModifier;
      }

      if (typeof this.wildModifier === "number") {
        return `x${this.wildModifier}`;
      }

      return "";
    },
  },
  methods: {
    onReelScroll: function (e: WheelEvent, reelIndex: number) {
      this.$emit("reel-scroll", reelIndex, e.deltaY);
    },
  },
});

function isThisCell(
  rowIndex: number,
  colIndex: number,
): (wild: Wild) => boolean {
  return (wild) => wild.cell[0] === rowIndex && wild.cell[1] === colIndex;
}
