var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("\n      Feature Trigger\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("\n      Feature Step\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("\n      Clearing Step\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(7, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [_vm._v("Symbol update source:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.upgradeSource,
                expression: "upgradeSource",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "upgradeSource", id: "upgradeSource" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.upgradeSource = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeUpgradeSource,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "None", selected: "" } }, [
              _vm._v("None"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lp1" } }, [_vm._v("lp1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lp2" } }, [_vm._v("lp2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lp3" } }, [_vm._v("lp3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lp4" } }, [_vm._v("lp4")]),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text" }, [_vm._v("Symbol update target:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.upgradeTarget,
                expression: "upgradeTarget",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "upgradeTarget", id: "upgradeTarget" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.upgradeTarget = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeUpgradeTarget,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "None", selected: "" } }, [
              _vm._v("None"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hp1" } }, [_vm._v("hp1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hp2" } }, [_vm._v("hp2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hp3" } }, [_vm._v("hp3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "wild" } }, [_vm._v("wild")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [
          _vm._v("Remove block (in next spin):"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.removeBlockIndex,
                expression: "removeBlockIndex",
                modifiers: { number: true },
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "removeBlockIndex", id: "removeBlockIndex" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return _vm._n(val)
                    })
                  _vm.removeBlockIndex = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeRemoveBlockIndex,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "-1", selected: "" } }, [
              _vm._v("None"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "9" } }, [_vm._v("10")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "8" } }, [_vm._v("9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "7" } }, [_vm._v("8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "6" } }, [_vm._v("7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "5" } }, [_vm._v("6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c("div", { staticClass: "flex-center menu sm-label" }, [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.addMegaReelSet(true)
                },
              },
            },
            [_vm._v("\n        Add Mega\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.addMegaReelSet(false)
                },
              },
            },
            [_vm._v("\n        Remove Mega\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shouldDisplayMegaReelSet(0),
              expression: "shouldDisplayMegaReelSet(0)",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(7, function (reelIndex) {
          return _c("mega-reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, megIndex: 0, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shouldDisplayMegaReelSet(1),
              expression: "shouldDisplayMegaReelSet(1)",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(7, function (reelIndex) {
          return _c("mega-reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, megIndex: 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shouldDisplayMegaReelSet(2),
              expression: "shouldDisplayMegaReelSet(2)",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(7, function (reelIndex) {
          return _c("mega-reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, megIndex: 2, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isFeature
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplayMegaReelSet(3),
                  expression: "shouldDisplayMegaReelSet(3)",
                },
              ],
              class: ["flex-center"],
            },
            _vm._l(7, function (reelIndex) {
              return _c("mega-reel", {
                key: reelIndex,
                attrs: { index: reelIndex - 1, megIndex: 3, step: _vm.step },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }