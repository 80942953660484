var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c(
        "div",
        { class: _vm.canUpdate ? "" : "hide", attrs: { align: "center" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-up",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", { staticClass: "respin" }, [
            _c(
              "div",
              {
                class: [
                  "symbol",
                  "on",
                  sym,
                  _vm.content.isCoin({ row, col: _vm.index })
                    ? "COIN"
                    : "NONCOIN",
                  _vm.content.canSetCoinPrize({ row, col: _vm.index })
                    ? "unlocked"
                    : "locked",
                ],
              },
              [
                !_vm.content.isCoin({ row, col: _vm.index })
                  ? _c(
                      "div",
                      { staticClass: "text" },
                      [
                        !_vm.content.canSetOrUnsetCoin({ row, col: _vm.index })
                          ? [_c("div", [_vm._v(_vm._s(sym))])]
                          : [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.content.setCoinPrize({
                                        row,
                                        col: _vm.index,
                                        value: "20",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            (\n                            "
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-2x fa-mouse-pointer",
                                    staticStyle: { "font-size": "85%" },
                                  }),
                                  _vm._v(
                                    " \n                            click for coin)\n                        "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    )
                  : !_vm.content.canSetCoinPrize({ row, col: _vm.index })
                  ? _c("div", { staticClass: "text locked" }, [
                      _vm._v(
                        "\n                    🟡 " +
                          _vm._s(
                            _vm.content.getCoinPrize({ row, col: _vm.index })
                          ) +
                          "\n                "
                      ),
                    ])
                  : _c("div", { staticClass: "text" }, [
                      _c(
                        "select",
                        {
                          domProps: {
                            value: _vm.content.getCoinPrize({
                              row,
                              col: _vm.index,
                            }),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.content.setCoinPrize({
                                row,
                                col: _vm.index,
                                value: $event.target.value,
                              })
                            },
                          },
                        },
                        [
                          _vm._l(_vm.content.coinPrizeLevels, function (opt) {
                            return [
                              _c("option", { domProps: { value: opt.value } }, [
                                _vm._v(_vm._s(opt.label)),
                              ]),
                            ]
                          }),
                          _vm._v(" "),
                          _vm.content.canSetOrUnsetCoin({ row, col: _vm.index })
                            ? [
                                _c("option", { domProps: { value: "0" } }, [
                                  _vm._v("❌ Remove"),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-down",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }