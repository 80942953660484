var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeGameStep()
              },
            },
          },
          [_vm._v("Free Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeStep == "BaseGame",
              expression: "activeStep == 'BaseGame'",
            },
          ],
        },
        [
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c("p", { staticClass: "text" }, [_vm._v("Reel Type:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reelID,
                      expression: "reelID",
                    },
                  ],
                  staticStyle: { margin: "0.5rem" },
                  attrs: { name: "reelID", id: "reelID" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.reelID = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeReelID,
                    ],
                  },
                },
                _vm._l(_vm.baseGameReelsID, function (reel, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: reel.id } },
                    [_vm._v("\n          " + _vm._s(reel.value) + "\n        ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
                [_vm._v("Reel Weight:")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reelIndex,
                      expression: "reelIndex",
                    },
                  ],
                  staticStyle: { margin: "0.5rem" },
                  attrs: { name: "reelIndex", id: "reelIndex" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.reelIndex = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeReelIndex,
                    ],
                  },
                },
                _vm._l(
                  _vm.baseReelsWeights[_vm.reelID],
                  function (reel, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(reel + " (Type " + (index + 1) + ")") +
                            "\n        "
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "text",
                    staticStyle: { "margin-left": "1rem" },
                  },
                  [_vm._v("Dynamite Wild(Fuse):")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isDynamiteWildActive,
                        expression: "isDynamiteWildActive",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "dynamiteWild", id: "dynamiteWild" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.isDynamiteWildActive = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onChangeActiveDynamiteWild,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledToggleFreeSpin,
                      expression: "isEnabledToggleFreeSpin",
                    },
                  ],
                  staticStyle: { "margin-left": "0.5rem" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text",
                      staticStyle: { "margin-left": "1rem" },
                    },
                    [_vm._v("Activate Free Spin:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.activateFreeSpin,
                          expression: "activateFreeSpin",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFreeSpin",
                        id: "activateFreeSpin",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.activateFreeSpin = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeActivateFreeSpin,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledDynamiteFuse,
                      expression: "isEnabledDynamiteFuse",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("Dynamite Fuse Reels:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dynamiteCount,
                          expression: "dynamiteCount",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: { name: "dynamiteCount", id: "dynamiteCount" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.dynamiteCount = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeDynamiteFuse,
                        ],
                      },
                    },
                    _vm._l(_vm.dynamiteCounts, function (dynamiteCount, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: dynamiteCount } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(dynamiteCount) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledFreeSpin,
                      expression: "isEnabledFreeSpin",
                    },
                  ],
                  staticStyle: { "margin-left": "1rem" },
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("Free Spin Reels:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.freeSpinCount,
                          expression: "freeSpinCount",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: { name: "freeSpinCount", id: "freeSpinCount" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.freeSpinCount = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeFreeSpinCount,
                        ],
                      },
                    },
                    _vm._l(_vm.freeSpinCounts, function (freeSpinCount, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: freeSpinCount } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(freeSpinCount) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabled.Feature1,
                      expression: "isEnabled.Feature1",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "text" }, [_vm._v("Play Feature 1:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isActive.Feature1,
                          expression: "isActive.Feature1",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFeature1",
                        id: "activateFeature1",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.isActive,
                              "Feature1",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.onChangeActiveFeature,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabled.Feature2,
                      expression: "isEnabled.Feature2",
                    },
                  ],
                  staticStyle: { "margin-left": "2rem" },
                },
                [
                  _c("p", { staticClass: "text" }, [_vm._v("Play Feature 2:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isActive.Feature2,
                          expression: "isActive.Feature2",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFeature2",
                        id: "activateFeature2",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.isActive,
                              "Feature2",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.onChangeActiveFeature,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabled.Feature3,
                      expression: "isEnabled.Feature3",
                    },
                  ],
                  staticStyle: { "margin-left": "2rem" },
                },
                [
                  _c("p", { staticClass: "text" }, [_vm._v("Play Feature 3:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isActive.Feature3,
                          expression: "isActive.Feature3",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFeature3",
                        id: "activateFeature3",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.isActive,
                              "Feature3",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.onChangeActiveFeature,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabled.Feature4,
                      expression: "isEnabled.Feature4",
                    },
                  ],
                  staticStyle: { "margin-left": "2rem" },
                },
                [
                  _c("p", { staticClass: "text" }, [_vm._v("Play Feature 4:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isActive.Feature4,
                          expression: "isActive.Feature4",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFeature4",
                        id: "activateFeature4",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.isActive,
                              "Feature4",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.onChangeActiveFeature,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeStep == "FreeGame",
              expression: "activeStep == 'FreeGame'",
            },
          ],
        },
        [
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c(
                "p",
                { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
                [_vm._v("Reel Type:")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reelID,
                      expression: "reelID",
                    },
                  ],
                  staticStyle: { margin: "0.5rem" },
                  attrs: { name: "reelID", id: "reelID" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.reelID = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeReelID,
                    ],
                  },
                },
                _vm._l(_vm.freeGameReelsID, function (reel, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: reel.id } },
                    [_vm._v("\n          " + _vm._s(reel.value) + "\n        ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
                [_vm._v("Reel Weight:")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reelIndex,
                      expression: "reelIndex",
                    },
                  ],
                  staticStyle: { margin: "0.5rem" },
                  attrs: { name: "reelIndex", id: "reelIndex" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.reelIndex = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeReelIndex,
                    ],
                  },
                },
                _vm._l(
                  _vm.freeReelsWeights[_vm.reelID],
                  function (reel, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(reel + " (Type " + (index + 1) + ")") +
                            "\n        "
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c("div", [
                _c("p", { staticClass: "text" }, [
                  _vm._v("Dynamite Wild(Fuse):"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isDynamiteWildActive,
                        expression: "isDynamiteWildActive",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "dynamiteWild", id: "dynamiteWild" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.isDynamiteWildActive = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onChangeActiveDynamiteWild,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledToggleFreeSpin,
                      expression: "isEnabledToggleFreeSpin",
                    },
                  ],
                  staticStyle: { "margin-left": "0.5rem" },
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("Activate Free Spin:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.activateFreeSpin,
                          expression: "activateFreeSpin",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: {
                        name: "activateFreeSpin",
                        id: "activateFreeSpin",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.activateFreeSpin = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeActivateFreeSpin,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("True"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("False"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: ["flex-center"], staticStyle: { margin: "0.5rem" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledDynamiteFuse,
                      expression: "isEnabledDynamiteFuse",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("Dynamite Fuse Reels:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dynamiteCount,
                          expression: "dynamiteCount",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: { name: "dynamiteCount", id: "dynamiteCount" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.dynamiteCount = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeDynamiteFuse,
                        ],
                      },
                    },
                    _vm._l(_vm.dynamiteCounts, function (dynamiteCount, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: dynamiteCount } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(dynamiteCount) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnabledFreeSpin,
                      expression: "isEnabledFreeSpin",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v("Free Spin Reels:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.freeSpinCount,
                          expression: "freeSpinCount",
                        },
                      ],
                      staticStyle: { margin: "0.5rem" },
                      attrs: { name: "freeSpinCount", id: "freeSpinCount" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.freeSpinCount = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onChangeFreeSpinCount,
                        ],
                      },
                    },
                    _vm._l(_vm.freeSpinCounts, function (freeSpinCount, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: freeSpinCount } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(freeSpinCount) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.enableFreeGameFeatures,
                  expression: "enableFreeGameFeatures",
                },
              ],
              class: ["flex-center"],
              staticStyle: { margin: "0.5rem" },
            },
            [
              _c("div", [
                _c("p", { staticClass: "text" }, [_vm._v("Play Feature 1:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isActive.Feature1,
                        expression: "isActive.Feature1",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "activateFeature1", id: "activateFeature1" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.isActive,
                            "Feature1",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onChangeActiveFeature,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "2rem" } }, [
                _c("p", { staticClass: "text" }, [_vm._v("Play Feature 2:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isActive.Feature2,
                        expression: "isActive.Feature2",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "activateFeature2", id: "activateFeature2" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.isActive,
                            "Feature2",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onChangeActiveFeature,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "2rem" } }, [
                _c("p", { staticClass: "text" }, [_vm._v("Play Feature 3:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isActive.Feature3,
                        expression: "isActive.Feature3",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "activateFeature3", id: "activateFeature3" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.isActive,
                            "Feature3",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onChangeActiveFeature,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "2rem" } }, [
                _c("p", { staticClass: "text" }, [_vm._v("Play Feature 4:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isActive.Feature4,
                        expression: "isActive.Feature4",
                      },
                    ],
                    staticStyle: { margin: "0.5rem" },
                    attrs: { name: "activateFeature4", id: "activateFeature4" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.isActive,
                            "Feature4",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onChangeActiveFeature,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("True"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("False"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }