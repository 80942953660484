// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol[data-v-229c29ac] {
  flex-direction: column;
  border-radius: 10px;
  background: #707070;
  box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;
}
.current-name[data-v-229c29ac] {
  margin: 0;
  padding: 0;
}
.original-name[data-v-229c29ac] {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  color: #dddddd;
}
.bonus[data-v-229c29ac] {
  border: 1px solid greenyellow;
}
.winCell[data-v-229c29ac] {
  border: 3px solid blue;
}
.G[data-v-229c29ac] {
  border: 1px solid darkolivegreen;
  background-color: green;
}
.P[data-v-229c29ac] {
  border: 1px solid purple;
  background-color: purple;
}
.R[data-v-229c29ac] {
  border: 1px solid indianred;
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/questOfThe3Lamps/slot-window/reel-symbol/reel-symbol.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,kEAAkE;AACpE;AACA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,gCAAgC;EAChC,uBAAuB;AACzB;AACA;EACE,wBAAwB;EACxB,wBAAwB;AAC1B;AACA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB","sourcesContent":[".symbol[data-v-229c29ac] {\n  flex-direction: column;\n  border-radius: 10px;\n  background: #707070;\n  box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;\n}\n.current-name[data-v-229c29ac] {\n  margin: 0;\n  padding: 0;\n}\n.original-name[data-v-229c29ac] {\n  margin: 0;\n  padding: 0;\n  font-size: 0.8em;\n  color: #dddddd;\n}\n.bonus[data-v-229c29ac] {\n  border: 1px solid greenyellow;\n}\n.winCell[data-v-229c29ac] {\n  border: 3px solid blue;\n}\n.G[data-v-229c29ac] {\n  border: 1px solid darkolivegreen;\n  background-color: green;\n}\n.P[data-v-229c29ac] {\n  border: 1px solid purple;\n  background-color: purple;\n}\n.R[data-v-229c29ac] {\n  border: 1px solid indianred;\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
