import { WinCellsForSymbol } from "./win-summerisers";
import { modelDefinition } from "../../../../../state/models/slots/vgw051";

export function createVgw051CascadeStepGenerator(modelDefinition: {
  reels: string[][];
  reelsLayout: number[];
}) {
  const { reelsLayout, reels } = modelDefinition;

  return function cascadeStep(
    oldSlotWindow: string[][],
    oldReelStripPositions: number[],
    collapsingCells: number[][]
  ) {
    const slotWindow = oldSlotWindow.map((column) => [...column]);
    const reelStripPositions = new Array<number>();

    // Removes the collapsing cells from the slot window, this assumes the cells come in order from 0 -> N, so it is reversed to go from N -> 0
    collapsingCells.reverse().forEach((cell) => {
      slotWindow[cell[1]].splice(cell[0], 1);
    });

    // Loops through each reel on the slot window, seeing if a collapse has happened, and if it has, unshift a new symbol to the front of the reel
    slotWindow.forEach((slotWindowReel, index) => {
      const slotWindowReelHeight = reelsLayout[index];
      let position = oldReelStripPositions[index];
      const numberOfNewSymbolsNeeded =
        slotWindowReelHeight - slotWindowReel.length;

      // When there is no cascade, the reel will not change
      if (numberOfNewSymbolsNeeded === 0) {
        reelStripPositions.push(position);
        return;
      }
      const reelStrip = reels[index];
      for (let i = 0; i < numberOfNewSymbolsNeeded; i++) {
        position = position - 1 < 0 ? reelStrip.length - 1 : position - 1;
        const symbol = reelStrip[position];
        slotWindowReel.unshift(symbol);
      }
      reelStripPositions.push(position);
    });
    return { slotWindow, reelStripPositions };
  };
}

export function createVgw051TopReelCascadeStepGenerator(modelDefinition: {
  topReel: string[];
  topReelLayout: number[];
}) {
  const { topReel, topReelLayout } = modelDefinition;
  return function topReelCascadeStep(
    oldTopReelSlotWindow: string[],
    oldTopReelStripPosition: number,
    collapsingCells: number[][]
  ) {
    const topReelSlotWindow = [...oldTopReelSlotWindow];

    // Removes the collapsing cells from the slot window, this assumes the cells come in order from 0 -> N, so it is reversed to go from N -> 0
    collapsingCells.reverse().forEach((cell) => {
      topReelSlotWindow.splice(cell[0], 1);
    });

    // Checks if a collapse has happened and if it has, push a new symbol to the end of the reel
    const topReelHeight = topReelLayout[0];
    let topReelStripPosition = oldTopReelStripPosition;
    const numberOfNewSymbolsNeeded = topReelHeight - topReelSlotWindow.length;

    // When there is no cascade, the reel will not change
    if (numberOfNewSymbolsNeeded === 0)
      return { topReelStripPosition, topReelSlotWindow };
    for (let i = 0; i < numberOfNewSymbolsNeeded; i++) {
      // The new symbol that needs to be added can be found by adding the reel height to the current position
      const newSymbolIndex =
        (topReelStripPosition + i + topReelHeight) % topReel.length;
      const symbol = topReel[newSymbolIndex];
      topReelSlotWindow.push(symbol);
    }

    // The top reel strip position is incremented by how many collapses occurred
    topReelStripPosition =
      (topReelStripPosition + numberOfNewSymbolsNeeded) % topReel.length;
    return { topReelStripPosition, topReelSlotWindow };
  };
}

export function createVgw051CollapsingCellsSeparator() {
  return function separateCollapsingCells(
    collapsingCellsBySymbol: WinCellsForSymbol[]
  ) {
    const slotWindowCollapsingCells = new Array<Array<number>>();
    const topReelSlotWindowCollapsingCells = new Array<Array<number>>();
    collapsingCellsBySymbol.forEach((symbolForCells) => {
      symbolForCells.cells.forEach((cell) => {
        // Check if the cell is already exists, useful for wild symbols
        const exists =
          topReelSlotWindowCollapsingCells.some((collapsingCell) =>
            areCellsEqual(collapsingCell, cell)
          ) ||
          slotWindowCollapsingCells.some((collapsingCell) =>
            areCellsEqual(collapsingCell, cell)
          );
        if (exists) return;

        // Add it to the top reel if applicable, otherwise the standard slot window
        if (cell[1] === 6) topReelSlotWindowCollapsingCells.push(cell);
        else slotWindowCollapsingCells.push(cell);
      });
    });
    return {
      slotWindowCollapsingCells: slotWindowCollapsingCells.sort(compareCells),
      topReelSlotWindowCollapsingCells: topReelSlotWindowCollapsingCells.sort(
        compareCells
      ),
    };
  };
}
/** Checks cells for ascending order */
function compareCells(cellOne: number[], CellTwo: number[]) {
  if (cellOne[1] !== CellTwo[1]) return cellOne[1] - CellTwo[1];
  return cellOne[0] - CellTwo[0];
}

export function areCellsEqual(cellOne: number[], CellTwo: number[]) {
  return cellOne[0] === CellTwo[0] && cellOne[1] === CellTwo[1];
}

const slotWindowCascadeStep = createVgw051CascadeStepGenerator(modelDefinition);
const topReelSlotWindowCascadeStep = createVgw051TopReelCascadeStepGenerator(
  modelDefinition
);
const collapsingCellsSeparator = createVgw051CollapsingCellsSeparator();

export function generateVgw051ReelStripPositionsAndSlotWindowsFromCascade(
  oldSlotWindow: string[][],
  oldReelStripPositions: number[],
  oldTopReelSlotWindow: string[],
  oldTopReelStripPosition: number,
  collapsingCellsBySymbol: WinCellsForSymbol[]
) {
  // Gather collapsing cells for the base slot window, and the top reel
  const {
    slotWindowCollapsingCells,
    topReelSlotWindowCollapsingCells,
  } = collapsingCellsSeparator(collapsingCellsBySymbol);

  // Generate the slot windows and reel strips from cascade steps
  const { reelStripPositions, slotWindow } = slotWindowCascadeStep(
    oldSlotWindow,
    oldReelStripPositions,
    slotWindowCollapsingCells
  );
  const {
    topReelStripPosition,
    topReelSlotWindow,
  } = topReelSlotWindowCascadeStep(
    oldTopReelSlotWindow,
    oldTopReelStripPosition,
    topReelSlotWindowCollapsingCells
  );

  return {
    reelStripPositions,
    slotWindow,
    topReelStripPosition,
    topReelSlotWindow,
  };
}
