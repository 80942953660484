var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Slot Window):"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "slotWindow", staticClass: "slot-window card" },
      [
        _vm._l(
          _vm.spinOutcome.gameResponse.slotWindow,
          function (reel, colIndex) {
            return _vm._l(reel, function (symbolName, rowIndex) {
              return _c(
                "reel-symbol",
                _vm._g(
                  {
                    key: `${colIndex} ${rowIndex}`,
                    attrs: {
                      position:
                        _vm.spinOutcome.gameResponse.reelStripPositions[
                          colIndex
                        ],
                      colIndex: colIndex,
                      rowIndex: rowIndex,
                      symbolName: symbolName,
                      spinType: _vm.spinType,
                      "is-win-cell": _vm.isWinCell(rowIndex, colIndex),
                    },
                  },
                  _vm.$listeners
                )
              )
            })
          }
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Text Output):"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("p", [
        _c("b", [_vm._v("Spin Type:")]),
        _vm._v(" " + _vm._s(_vm.spinType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Type:")]),
        _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.winType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Amount:")]),
        _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse.winAmount)),
      ]),
    ]),
    _vm._v(" "),
    _vm.spinOutcome.gameResponse.reelSpinFeatures
      ? _c("h3", { staticClass: "heading" }, [
          _vm._v("Reel Spin Features (Text Output):"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.spinOutcome.gameResponse.reelSpinFeatures
      ? _c("div", { staticClass: "reel-spin-features-grid-container" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("span", [_vm._v("Full Reel Wilds")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.beforeReelsStop?.fullReelWilds?.join(
                  ", "
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.afterReelsStop?.fullReelWilds?.join(
                  ", "
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("Single Wilds")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.beforeReelsStop?.singleWilds?.join(
                  ", "
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.afterReelsStop?.singleWilds?.join(
                  ", "
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("Multipliers")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.beforeReelsStop?.multiplierCells
                  ?.map((m) => `${m.multiplier}(${m.cell})`)
                  .join(", ")
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.afterReelsStop?.multiplierCells
                  ?.map((m) => `${m.multiplier}(${m.cell})`)
                  .join(", ")
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("Character Wilds")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.beforeReelsStop?.characterWilds?.pics.join(
                  ", "
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.spinOutcome.gameResponse.reelSpinFeatures?.afterReelsStop?.characterWilds?.pics.join(
                  ", "
                )
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isFreeSpins
      ? _c("div", { staticClass: "card-generic-info" }, [
          _c("p", [
            _c("b", [_vm._v("Free Spin Phase:")]),
            _vm._v(
              " " + _vm._s(_vm.spinOutcome.gameResponse?.freeSpins?.phase)
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Free Spin Count:")]),
            _vm._v(
              "  " + _vm._s(_vm.spinOutcome.gameResponse?.freeSpins?.remaining)
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Pic to wilds:")]),
            _vm._v(
              "  " +
                _vm._s(
                  _vm.spinOutcome.gameResponse?.freeSpins?.picToWilds?.join(
                    ", "
                  )
                )
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isPickSpin
      ? _c("div", { staticClass: "card-generic-info" }, [
          _c("p", [
            _c("b", [_vm._v("Pick Phase:")]),
            _vm._v(" " + _vm._s(_vm.spinOutcome.gameResponse?.picks?.phase)),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Pick Feature:")]),
            _vm._v(
              "  " +
                _vm._s(_vm.spinOutcome.gameResponse?.picks?.currentPick?.type)
            ),
          ]),
          _vm._v(" "),
          _vm.spinOutcome.gameResponse?.picks?.currentPick?.type ===
          "FREE_SPINS"
            ? _c("p", [
                _c("b", [_vm._v("Free spins awarded:")]),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.spinOutcome.gameResponse?.picks?.currentPick
                        ?.freeSpinsAwarded
                    )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.spinOutcome.gameResponse?.picks?.currentPick?.type ===
          "PIC_TO_WILD"
            ? _c("p", [
                _c("b", [_vm._v("Pic to Wild awarded:")]),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.spinOutcome.gameResponse?.picks?.currentPick
                        ?.picToWildAwarded
                    )
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("details", [
        _c("summary", [_vm._v("Full Spin Outcome:")]),
        _vm._v(" "),
        _c("p", [_c("pre", [_vm._v(_vm._s(_vm.spinOutcome.gameResponse))])]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("strong", [_vm._v("Feature")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("strong", [_vm._v("Before Reels Stop")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("strong", [_vm._v("After Reels Stop")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }