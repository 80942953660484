import { modelDefinition } from '../../model-definition';
import type { GameState, JackpotPhase } from '../shared';
import type { GameRequest } from './game-request';
import type { SpinOutcome } from './outcome';
import type { Choices } from '../../choices/choices';
import type { GameResponse } from './game-response';

export function spinJackpot(
    { gameRequest, coinType, gameState: lastGameState }: JackpotSpinInputs,
    choices: Choices,
): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;
    const { reelStripPositions, slotWindow } = lastGameState;

    const { cumulativeWinAmount: previousCumulativeWinAmount } = lastGameState;

    const jackpotPhase: JackpotPhase = 'END';
    const jackpotAwarded = choices.chooseJackpot({ coinAmount, coinType });

    const roundComplete = true;

    const jackpotWinCounts = ['GRAND', 'MAJOR'].includes(jackpotAwarded) ? { [jackpotAwarded]: 1 } : undefined;

    const jackpotWinAmounts: GameResponse['jackpotWinCounts'] =
        jackpotAwarded === 'MINOR' || jackpotAwarded === 'MINI'
            ? { [jackpotAwarded]: modelDefinition.fixedJackpotWinMultiplier[jackpotAwarded] * playAmount }
            : undefined;

    const winAmount = (jackpotWinAmounts?.MINI ?? 0) + (jackpotWinAmounts?.MINOR ?? 0);

    const cumulativeWinAmount = previousCumulativeWinAmount + winAmount;

    return {
        playSummary: {
            playAmount: 0,
            winAmount,
            roundComplete,
            jackpotWinCounts,
        },
        gameResponse: {
            coinAmount,
            playAmount,
            winAmount,
            cumulativeWinAmount,

            reelStripPositions,
            slotWindow,

            lineWins: [],
            scatterWin: undefined,
            winType: 'JACKPOT_WIN',

            jackpotPhase,
            jackpotWinCounts,
            jackpotWinAmounts,
        },
        gameState: undefined,
    };
}

export type JackpotSpinInputs = {
    gameRequest: GameRequest;
    gameState: GameState;
    coinType: 'SC' | 'GC';
};
