import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        console.log(step.json);
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    } 
    public doChangePattern() {
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }
    public addGrandJackpotTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getGrandJackpotTriggerFromBaseGame());
    }
    public addMajorJackpotTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMajorJackpotTriggerFromBaseGame());
    }
    public addMinorJackpotTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMinorJackpotTriggerFromBaseGame());
    }
    public addMiniJackpotTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMiniJackpotTriggerFromBaseGame());
    }
    public addJackpotFreeSpinTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getJackpotFreeSpinTriggerStep());
    }
    public addFreeSpinTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinTriggerFromBaseGame());
    }
    public addFreeSpinReTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinReTriggerStep());
    }
    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }
}
