import { CoinType, GOLD, SWEEPS } from "./data-types";

/**
 * Returns a function that checks whether the given coin type/size are valid for the given model definition.
 */
export function createCoinSizeValidator(modelDefinition: {
  coinType1Values: number[];
  coinType4Values: number[];
}) {
  return function isValidCoinSize(
    coinTypeId: CoinType,
    coinSize: number,
  ): boolean {
    if (coinTypeId === GOLD)
      return modelDefinition.coinType1Values.includes(coinSize);
    if (coinTypeId === SWEEPS)
      return modelDefinition.coinType4Values.includes(coinSize);
    return false;
  };
}
