/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf006_fishandchips",
        "scatterSymbol": "FS1",
        "bonusSymbol": "SC1",
        "wildSymbol": "WI",
        "freeSpinTrigger": "FS1",
        "countToTriggerBonusGame": 3,
        "countToTriggerFreeSpin": 3,
        "freeSpinCount": 12,
        "wildSymbols": [
            "WI1",
            "WI2",
            "WI3",
            "WI4"
        ],
        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [20, 8, 5]
            }, {
                "symbol": "LV2",
                "multipliers": [20, 8, 5]
            }, {
                "symbol": "LV3",
                "multipliers": [25, 10, 6]
            }, {
                "symbol": "LV4",
                "multipliers": [25, 10, 6]
            }, {
                "symbol": "HV1",
                "multipliers": [50, 20, 10]
            }, {
                "symbol": "HV2",
                "multipliers": [75, 30, 15]
            }, {
                "symbol": "HV3",
                "multipliers": [100, 40, 20]
            }, {
                "symbol": "HV4",
                "multipliers": [175, 60, 30]
            }
        ],

        "featureWinTable": [
            {
                "symbol": "LV1",
                "multipliers": [20, 8]
            }, {
                "symbol": "LV2",
                "multipliers": [20, 8]
            }, {
                "symbol": "LV3",
                "multipliers": [25, 10]
            }, {
                "symbol": "LV4",
                "multipliers": [25, 10]
            }, {
                "symbol": "HV1",
                "multipliers": [50, 20]
            }, {
                "symbol": "HV2",
                "multipliers": [75, 30]
            }, {
                "symbol": "HV3",
                "multipliers": [100, 40]
            }, {
                "symbol": "HV4",
                "multipliers": [175, 60]
            }
        ],
        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"2000000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"1000",

        "reelsLayout": [3, 3, 3, 3, 3],
        "reelsLayoutFreeSpin": [4, 4, 4, 4],

        "reels": [
            ["LV1", "LV2", "LV1", "HV2", "LV3", "LV3", "HV2", "LV4", "HV3", "LV1", "FS1", "HV1", "LV1", "LV3", "LV2", "HV1", "LV1", "HV3", "LV3", "LV4", "HV1", "HV2", "LV1", "LV1", "HV3", "HV2", "LV3", "LV2", "HV3", "LV3", "LV4", "HV2", "LV4", "HV1", "LV4", "HV2", "LV2", "FS1", "HV3", "LV2", "LV3", "LV1", "LV2", "HV1", "HV2", "LV3", "HV3", "HV2", "HV4", "LV3"],
            ["LV2", "HV1", "LV3", "LV2", "WI1", "LV4", "LV2", "HV3", "HV3", "LV4", "HV4", "LV3", "LV4", "LV1", "LV2", "LV4", "HV1", "HV4", "LV4", "HV4", "HV1", "LV2", "LV3", "HV3", "LV4", "HV1", "LV1", "LV2", "HV1", "LV1", "WI1", "LV1", "HV1", "HV1", "LV2", "LV2", "HV2", "LV3", "HV2", "LV2", "LV4", "LV4", "LV1", "HV1", "LV3", "LV1", "LV2", "HV3", "LV3", "HV2"],
            ["LV3", "HV2", "LV2", "HV2", "LV3", "LV4", "LV4", "HV3", "LV3", "LV4", "HV2", "HV4", "LV2", "HV3", "LV4", "LV1", "HV1", "LV2", "LV4", "WI1", "LV3", "LV1", "LV1", "FS1", "HV1", "LV3", "LV1", "LV2", "LV2", "HV3", "LV4", "LV2", "HV1", "LV1", "HV1", "HV2", "LV2", "HV3", "HV3", "LV3", "LV3", "FS1", "LV1", "HV2", "LV2", "LV2", "HV4", "LV3", "LV1", "HV1"],
            ["HV2", "LV3", "LV1", "LV4", "HV1", "HV1", "LV1", "LV3", "WI2", "LV2", "LV1", "HV4", "LV2", "HV2", "HV2", "LV4", "LV4", "LV1", "HV2", "HV4", "LV2", "HV3", "LV3", "LV4", "HV1", "LV1", "HV2", "LV3", "LV2", "LV4", "LV4", "HV3", "LV4", "LV4", "LV3", "HV2", "LV3", "HV4", "LV3", "HV1", "LV1", "HV4", "LV1", "HV1", "LV1", "LV2", "HV1", "LV2", "HV3", "HV3"],
            ["HV1", "LV2", "HV1", "HV3", "LV2", "LV4", "HV2", "FS1", "HV2", "LV2", "LV3", "LV3", "HV3", "HV1", "LV1", "LV3", "LV4", "LV1", "FS1", "HV2", "LV2", "LV4", "LV2", "HV2", "LV1", "LV4", "HV1", "LV1", "HV3", "LV2", "WI3", "LV1", "LV4", "FS1", "HV3", "LV1", "LV3", "HV2", "LV4", "HV4", "LV3", "LV4", "HV1", "LV3", "FS1", "LV1", "HV2", "LV3", "HV1", "LV2"]
        ],
        "reelsSetTwo": [
            ["LV1", "LV2", "LV1", "HV2", "LV3", "LV3", "HV2", "LV4", "HV3", "LV1", "FS1", "HV1", "LV1", "LV3", "LV2", "HV1", "LV1", "HV3", "LV3", "LV4", "HV1", "HV2", "LV1", "LV1", "HV3", "HV2", "LV3", "LV2", "HV3", "LV3", "LV4", "HV2", "LV4", "HV1", "LV4", "HV2", "LV2", "FS1", "HV3", "LV2", "LV3", "LV1", "LV2", "HV1", "HV2", "LV3", "HV3", "HV2", "HV4", "LV3"],
            ["LV2", "HV1", "LV3", "LV2", "WI1", "LV4", "LV2", "WI1", "HV3", "LV4", "WI1", "LV3", "LV4", "WI1", "LV2", "LV4", "WI1", "HV4", "LV4", "HV4", "HV1", "LV2", "LV3", "WI1", "LV4", "HV1", "LV1", "LV2", "HV1", "LV1", "WI1", "LV1", "HV1", "HV1", "LV2", "LV2", "HV2", "LV3", "WI1", "LV2", "LV4", "LV4", "LV1", "HV1", "LV3", "WI1", "LV2", "HV3", "LV3", "HV2"],
            ["LV3", "HV2", "WI1", "HV2", "LV3", "LV4", "LV4", "HV3", "LV3", "WI1", "HV2", "HV4", "LV2", "HV3", "WI1", "LV1", "HV1", "LV2", "LV4", "WI1", "LV3", "LV1", "LV1", "FS1", "HV1", "LV3", "LV1", "LV2", "WI1", "HV3", "LV4", "LV2", "WI1", "LV1", "HV1", "HV2", "LV2", "WI1", "HV3", "LV3", "LV3", "FS1", "LV1", "HV2", "WI1", "LV2", "HV4", "WI1", "LV1", "HV1"],
            ["HV2", "LV3", "LV1", "LV4", "HV1", "HV1", "LV1", "LV3", "WI2", "LV2", "LV1", "HV4", "LV2", "HV2", "HV2", "LV4", "LV4", "WI2", "HV2", "HV4", "LV2", "HV3", "LV3", "LV4", "HV1", "LV1", "HV2", "LV3", "LV2", "WI2", "LV4", "HV3", "LV4", "LV4", "LV3", "HV2", "LV3", "HV4", "LV3", "HV1", "LV1", "HV4", "LV1", "HV1", "LV1", "WI2", "HV1", "LV2", "HV3", "HV3"],
            ["HV1", "LV2", "HV1", "HV3", "WI3", "LV4", "HV2", "FS1", "HV2", "LV2", "LV3", "LV3", "HV3", "HV1", "LV1", "LV3", "LV4", "LV1", "FS1", "HV2", "LV2", "LV4", "LV2", "HV2", "LV1", "LV4", "HV1", "LV1", "HV3", "LV2", "WI3", "LV1", "LV4", "FS1", "HV3", "LV1", "LV3", "HV2", "LV4", "HV4", "LV3", "LV4", "HV1", "LV3", "FS1", "LV1", "HV2", "LV3", "HV1", "LV2"]
        ],
        "reelsSetThree": [
            ["LV1", "LV2", "LV1", "HV2", "LV3", "LV3", "HV2", "LV4", "HV3", "SC1", "HV3", "HV1", "LV1", "LV3", "LV2", "HV4", "LV1", "HV3", "LV3", "LV4", "HV1", "HV2", "LV1", "HV1", "LV1", "HV4", "HV2", "LV3", "LV2", "HV3", "LV3", "LV4", "HV2", "LV4", "HV1", "LV4", "HV2", "LV2", "SC1", "HV3", "LV2", "LV3", "LV1", "LV2", "HV1", "HV2", "LV3", "HV3", "HV3", "HV2", "HV4", "LV3"],
            ["LV2", "HV1", "LV3", "LV2", "WI4", "LV4", "LV2", "HV3", "HV3", "LV4", "HV4", "LV3", "SC1", "LV1", "LV2", "LV4", "WI4", "HV4", "LV4", "HV4", "HV1", "LV2", "HV3", "LV3", "HV3", "LV4", "HV1", "LV1", "HV2", "LV2", "HV1", "LV1", "HV4", "LV1", "WI4", "HV1", "LV2", "LV2", "HV2", "LV3", "SC1", "LV2", "LV4", "HV2", "LV1", "HV1", "LV3", "LV1", "LV2", "HV3", "LV3", "HV2"],
            ["LV3", "HV2", "LV2", "HV2", "LV3", "WI4", "LV4", "HV3", "LV3", "SC1", "LV4", "HV2", "HV4", "LV2", "HV3", "LV1", "LV1", "HV1", "LV2", "LV4", "HV4", "LV3", "LV1", "LV1", "HV4", "HV1", "LV3", "LV1", "WI4", "LV2", "HV3", "LV4", "LV2", "HV1", "LV1", "HV1", "HV2", "LV2", "SC1", "HV3", "LV3", "LV3", "HV3", "LV2", "HV2", "WI4", "LV3", "HV4", "LV4", "LV1", "HV1"],
            ["HV2", "LV3", "LV1", "LV1", "WI4", "HV1", "LV1", "LV3", "SC1", "LV2", "LV2", "HV4", "LV2", "HV2", "HV2", "LV4", "LV4", "HV1", "LV1", "HV2", "HV4", "LV2", "HV3", "LV3", "WI4", "HV1", "LV1", "HV2", "LV3", "LV2", "LV4", "LV4", "HV3", "SC1", "LV4", "LV3", "HV2", "LV3", "HV4", "LV3", "WI4", "LV1", "HV4", "LV1", "HV1", "LV4", "LV2", "HV1", "LV2", "HV3", "HV3"]
        ],

        "cascadeReelsSetsWeights": [ 2278, 7722 ],

        "tackleBoxesTypeChances": [ 1000, 9000 ],

        "tackleBoxesFreeSpins": [
            {
                "weight": 13,
                "spins": 2
            },
            {
                "weight": 13,
                "spins": 3
            },
            {
                "weight": 13,
                "spins": 4
            },
            {
                "weight": 13,
                "spins": 5
            },
            {
                "weight": 13,
                "spins": 6
            },
            {
                "weight": 13,
                "spins": 8
            },
            {
                "weight": 12,
                "spins": 10
            },
            {
                "weight": 10,
                "spins": 12
            }
        ],

        "tackleBoxesMultipliers": [
            {
                "weight": 11,
                "multiplier": 2
            },
            {
                "weight": 11,
                "multiplier": 3
            },
            {
                "weight": 13,
                "multiplier": 4
            },
            {
                "weight": 13,
                "multiplier": 5
            },
            {
                "weight": 13,
                "multiplier": 8
            },
            {
                "weight": 13,
                "multiplier": 10
            },
            {
                "weight": 13,
                "multiplier": 15
            },
            {
                "weight": 13,
                "multiplier": 25
            }
        ],

        "playLines": [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null ]


    }

    ,
}
