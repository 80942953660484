/* in order to support standalone, please insert the init response from the server here */
export default {
	success: true,
	messages: [],

	modelDefinition: {
		version: "1.0.0",
		modelId: "tf112",
		reelsLayout: [3, 3, 3, 3, 3],

		scatterSymbol: "SC1",
		wildSymbol: "WI1",
		wildSymbolStampede: "WI2",
		respinNoWinSymbol: "BLK",

		coinBonusCoinSymbol: "CO1",
		coinBonusAddSymbol: "AD1",

		totalSymbolNum: 15,
		countToTriggerRespin: 3,
		respinsCount: 3,

		winTable: [
			{ symbol: "HV4", multipliers: [500, 100, 20] },
			{ symbol: "HV3", multipliers: [400, 80, 15] },
			{ symbol: "HV2", multipliers: [300, 60, 12] },
			{ symbol: "HV1", multipliers: [250, 50, 10] },

			{ symbol: "LV4", multipliers: [200, 40, 8] },
			{ symbol: "LV3", multipliers: [150, 30, 7] },
			{ symbol: "LV2", multipliers: [120, 25, 6] },
			{ symbol: "LV1", multipliers: [100, 20, 5] },

			{ symbol: "WI1", multipliers: [500, 100, 20] },
			{ symbol: "WI2", multipliers: [500, 100, 20] },
			{ symbol: "SC1", multipliers: [] },
		],

		playLines: [
			[1, 1, 1, 1, 1],
			[0, 0, 0, 0, 0],
			[2, 2, 2, 2, 2],
			[0, 1, 2, 1, 0],
			[2, 1, 0, 1, 2],

			[0, 0, 1, 0, 0],
			[0, 1, 0, 1, 0],
			[0, 1, 1, 1, 0],
			[1, 0, 0, 0, 1],
			[1, 0, 1, 0, 1],

			[1, 1, 0, 1, 1],
			[1, 1, 2, 1, 1],
			[1, 2, 1, 2, 1],
			[1, 2, 2, 2, 1],
			[2, 1, 1, 1, 2],

			[2, 1, 2, 1, 2],
			[2, 2, 1, 2, 2],
			[0, 0, 2, 0, 0],
			[0, 2, 0, 2, 0],
			[0, 2, 2, 2, 0],
		],

		// prettier-ignore
		allPaylines: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],

		reels: [],

		// prettier-ignore
		reelSets: [
			[
				['HV4','LV2','LV4','LV1','HV4','HV3','SC1','HV1','HV2','HV4','LV1','LV2','HV3','LV4','HV4','LV1','LV2','HV3','LV1','HV1','HV4','HV2','LV4','LV2','HV1','LV1','HV4','LV2','HV1','LV1','LV2','LV4','HV3','LV1','SC1','LV4','LV1','HV1','LV2','LV3','LV4','LV1','HV1','LV4','LV2','HV4','HV1','LV1','LV2','LV4','HV3','HV1','LV2','LV1','HV1','LV2','LV4','HV3','LV1','LV2','HV1','LV4','WI1','LV2','LV4','HV1','LV2','LV1','LV4','HV4','LV3','LV1','LV4','HV1','LV2','HV2','HV1','LV1','HV3','LV4','LV1','HV2','LV2','LV1','HV3','LV4','LV2','LV1','HV4','LV4','LV1','HV1','LV3','HV2','WI1','LV2','LV4','HV4','LV2','LV4','LV1','LV2','LV4','HV2'],
				['LV4','LV1','HV2','HV3','LV1','HV2','LV3','LV4','LV2','HV1','LV1','SC1','LV3','LV1','HV4','LV4','LV1','LV2','LV3','LV1','HV1','LV3','HV2','LV4','LV1','LV3','HV3','LV4','LV1','LV3','HV2','LV4','LV1','LV3','HV4','LV4','LV3','HV2','HV1','LV3','LV4','WI1','LV1','LV4','HV2','LV1','LV4','HV1','LV1','HV4','LV3','LV1','HV1','LV3','HV3','SC1','LV2','LV1','LV3','HV3','LV1','LV3','HV4','LV4','HV2','HV3','LV1','LV3','LV4','HV2','HV4','WI1','LV4','LV3','HV1','LV4','HV2','LV1','LV4','LV3','HV3','SC1','LV1','HV2','HV4','LV4','HV2','LV3','HV3','LV1','LV4','LV3','HV2','LV1','LV3','LV4','HV3','LV3'],
				['LV2','LV4','LV3','LV1','HV1','LV3','LV4','LV2','WI1','HV1','HV2','HV4','HV1','LV3','HV3','HV1','LV2','SC1','HV1','HV3','LV3','LV2','HV2','LV3','HV1','HV4','HV2','SC1','LV3','LV1','LV2','HV2','LV3','HV3','HV1','LV2','HV2','LV3','HV1','HV4','LV1','LV3','HV2','HV1','LV3','LV2','HV4','LV3','LV2','WI1','HV1','LV2','HV2','LV1','HV3','LV3','LV2','HV2','HV1','HV4','HV2','HV3','LV2','HV1','SC1','LV2','LV4','HV1','LV3','LV2','LV4','LV3','HV2','LV2','LV3','HV2','HV1','HV3','LV2','LV3','HV3','LV2','HV1','LV3','WI1','LV2','HV4','LV3','HV1','HV2','HV4','LV2','HV3','HV1'],
				['HV1','HV2','HV3','WI1','LV2','LV3','HV1','LV2','LV4','LV3','LV2','HV2','HV1','HV4','LV4','LV2','LV3','HV2','HV3','LV1','LV4','LV2','LV1','HV1','LV4','LV2','LV3','LV4','LV2','LV1','HV1','HV3','LV3','SC1','HV1','LV2','LV3','HV1','LV4','LV3','HV3','HV2','LV1','HV1','LV2','LV1','HV2','LV4','LV1','LV2','HV2','LV4','LV1','HV2','HV3','HV4','LV1','LV3','HV2','LV4','LV2','SC1','LV1','LV2','HV1','HV2','LV3','LV2','LV1','HV4','LV4','LV2','LV3','LV1','HV1','LV2','LV3','LV4','HV4','LV1','LV4','WI1','LV1','LV4','LV3','HV2','LV4','HV1','LV1','LV4','LV3','LV2','LV1','SC1','HV3','HV1','LV3','HV3','LV4','LV1','LV3','HV1','HV3','LV3','HV1','LV1','LV2','LV3'],
				['HV2','LV1','LV2','LV4','HV3','LV1','LV4','HV4','LV3','LV4','HV2','LV1','LV3','LV4','SC1','HV1','LV4','HV2','LV1','LV3','LV4','LV2','HV2','LV1','LV3','HV4','LV2','HV3','LV3','HV2','LV1','LV4','LV3','LV2','LV1','HV3','LV2','HV4','LV4','HV1','LV1','LV3','LV4','HV3','HV1','LV4','LV1','LV3','HV1','LV4','LV1','HV1','LV2','HV2','SC1','HV1','LV2','HV3','HV4','HV1','LV3','HV4','LV4','LV3','LV2','HV3','LV1','HV2','HV1','LV4','LV2','HV1','LV1','LV2','LV4','HV2','LV3','LV1','HV2','LV4','LV1','LV2','HV1','SC1','LV3','HV2','LV1','HV1','HV2','LV3','HV1','LV2','HV2','HV1','HV4','LV2','LV3','LV4','LV1','LV3','HV1','LV2','LV4','LV3','LV2','HV3','LV3','HV4'],
			],
			[
				['HV4','LV2','LV4','LV1','HV4','HV3','LV4','HV1','HV2','HV4','LV1','LV2','HV3','LV4','HV4','LV1','LV2','HV3','LV1','HV1','HV4','HV2','LV4','LV2','HV1','LV1','HV4','LV2','HV1','LV1','LV2','LV4','HV3','LV1','LV3','LV4','LV1','HV1','LV2','LV3','LV4','LV1','HV1','LV4','LV2','HV4','HV1','LV1','LV2','LV4','HV3','HV1','LV2','LV1','HV1','LV2','LV4','HV3','LV1','LV2','HV1','LV4','WI1','LV2','LV4','HV1','LV2','LV1','LV4','HV4','LV3','LV1','LV4','HV1','LV2','HV2','HV1','LV1','HV3','LV4','LV1','HV2','LV2','LV1','HV3','LV4','LV2','LV1','HV4','LV4','LV1','HV1','LV3','HV2','WI1','LV2','LV4','HV4','LV2','LV4','LV1','LV2','LV4','HV2'],
				['LV4','LV1','HV2','HV3','LV1','HV2','LV3','LV4','LV2','HV1','LV1','LV2','LV3','LV1','HV4','LV4','LV1','LV2','LV3','LV1','HV1','LV3','HV2','LV4','LV1','LV3','HV3','LV4','LV1','LV3','HV2','LV4','LV1','LV3','HV4','LV4','LV3','HV2','HV1','LV3','LV4','WI1','LV1','LV4','HV2','LV1','LV4','HV1','LV1','HV4','LV3','LV1','HV1','LV3','HV3','LV4','LV2','LV1','LV3','HV3','LV1','LV3','HV4','LV4','HV2','HV3','LV1','LV3','LV4','HV2','HV4','WI1','LV4','LV3','HV1','LV4','HV2','LV1','LV4','LV3','HV3','LV4','LV1','HV2','HV4','LV4','HV2','LV3','HV3','LV1','LV4','LV3','HV2','LV1','LV3','LV4','HV3','LV3'],
				['LV2','LV4','LV3','LV1','HV1','LV3','LV4','LV2','LV3','HV1','HV2','HV4','HV1','LV3','HV3','HV1','LV2','LV4','HV1','HV3','LV3','LV2','HV2','LV3','HV1','HV4','HV2','LV2','LV3','LV1','LV2','HV2','LV3','HV3','HV1','LV2','HV2','LV3','HV1','HV4','LV1','LV3','HV2','HV1','LV3','LV2','HV4','LV3','LV2','WI1','HV1','LV2','HV2','LV1','HV3','LV3','LV2','HV2','HV1','HV4','HV2','HV3','LV2','HV1','LV1','LV2','LV4','HV1','LV3','LV2','LV4','LV3','HV2','LV2','LV3','HV2','HV1','HV3','LV2','LV3','HV3','LV2','HV1','LV3','WI1','LV2','HV4','LV3','HV1','HV2','HV4','LV2','HV3','HV1'],
				['HV1','HV2','HV3','LV1','LV2','LV3','HV1','LV2','LV4','LV3','LV2','HV2','HV1','HV4','LV4','LV2','LV3','HV2','HV3','LV1','LV4','LV2','LV1','HV1','LV4','LV2','LV3','LV4','LV2','LV1','HV1','HV3','LV3','LV4','HV1','LV2','LV3','HV1','LV4','LV3','HV3','HV2','LV1','HV1','LV2','LV1','HV2','LV4','LV1','LV2','HV2','LV4','LV1','HV2','HV3','HV4','LV1','LV3','HV2','LV4','LV2','LV3','LV1','LV2','HV1','HV2','LV3','LV2','LV1','HV4','LV4','LV2','LV3','LV1','HV1','LV2','LV3','LV4','HV4','LV1','LV4','WI1','LV1','LV4','LV3','HV2','LV4','HV1','LV1','LV4','LV3','LV2','LV1','LV4','HV3','HV1','LV3','HV3','LV4','LV1','LV3','HV1','HV3','LV3','HV1','LV1','LV2','LV3'],
				['HV2','LV1','LV2','LV4','HV3','LV1','LV4','HV4','LV3','LV4','HV2','LV1','LV3','LV4','HV3','HV1','LV4','HV2','LV1','WI2','LV4','LV2','HV2','LV1','LV3','HV4','LV2','HV3','LV3','HV2','LV1','LV4','LV3','LV2','LV1','HV3','LV2','HV4','LV4','HV1','LV1','LV3','LV4','HV3','HV1','LV4','LV2','LV3','HV1','LV4','LV1','HV1','LV2','HV2','LV3','HV1','LV2','HV3','HV4','HV1','LV3','HV4','LV4','LV3','LV2','HV3','LV1','HV2','HV1','LV4','LV2','HV1','LV1','LV2','LV4','HV2','LV3','LV1','HV2','LV4','LV1','LV2','HV1','LV1','LV3','HV2','LV1','HV1','HV2','LV3','HV1','LV2','HV2','HV1','HV4','LV2','LV3','WI2','LV1','LV3','HV1','LV2','LV4','LV3','LV2','HV3','LV3','HV4'],
			],
		],

		coinBonusGame: {
			// prettier-ignore
			bands: [
				['CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK'],
				['CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'AD1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'CO1', 'BLK', 'CO1', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK', 'BLK']
			],

			bandForNumberOfCoins: [
				{ min: 0, max: 4 },
				{ min: 5, max: 9 },
				{ min: 10, max: 14 },
				{ min: 15, max: 19 },
				{ min: 20, max: 24 },
				{ min: 25, max: 29 },
				{ min: 30, max: 34 },
				{ min: 35, max: 39 },
				{ min: 40, max: 45 },
			],

			coinInfo: [
				{
					level: "bronze",
					value: [5, 10, 15, 20, 25, 30, 40, 50],
					count: [5, 6, 4, 3, 2, 2, 1, 1],
					weight: [3000, 3000, 1500, 1000, 500, 500, 250, 250],
				},
				{
					level: "silver",
					value: [60, 80, 100, 120, 140, 160, 180, 200],
					count: [5, 6, 4, 3, 2, 2, 1, 1],
					weight: [3000, 3000, 1500, 1000, 500, 500, 250, 250],
				},
				{
					level: "gold",
					value: [250, 300, 350, 400, 450, 500, 750, 1000],
					count: [5, 6, 4, 3, 2, 2, 1, 1],
					weight: [3000, 3000, 1500, 1000, 500, 500, 250, 250],
				},
			],
		},
	},
};
