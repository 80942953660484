import { render, staticRenderFns } from "./card.vue?vue&type=template&id=09ffa6c9&scoped=true"
import script from "./card.vue.ts?vue&type=script&lang=ts&external"
export * from "./card.vue.ts?vue&type=script&lang=ts&external"
import style0 from "./card.css?vue&type=style&index=0&id=09ffa6c9&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ffa6c9",
  null
  
)

export default component.exports