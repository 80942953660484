/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base cascade RS1");
            step.reelsType = "reels";
            step.internal_name = "Base cascade RS1";
            step.cascadeReels = 1;
            return step;
        },
        getBaseStep: function(stepTitle, cascadeReels) {
            const rsTitle = "Base cascade RS" + cascadeReels;
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", rsTitle);
            step.reelsType = "reels";
            step.internal_name = rsTitle;
            step.cascadeReels = cascadeReels;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "reelsSetTwo":
                    return State.state.getModelDefinition().reelsSetTwo;
                    break;
                case "reelsSetThree":
                    return State.state.getModelDefinition().reelsSetThree;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getFSTriggerStep (stepTitle) {
            const step = State.state.createStep([10, 0, 23, 0, 7], false, 0, "", stepTitle);
            step.reelsType = "reels";
            return step;
        },

        getFSStep (stepTitle) {
            const step = State.state.createStep([3, 0, 12, 0], false, 0, "", stepTitle);
            step.reelsType = "reelsSetThree";
            return step;
        },

        getFSTBStep (stepTitle) {
            const step = State.state.createStep([9, 12, 9, 8], false, 0, "", stepTitle);
            step.reelsType = "reelsSetThree";
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = (step.reelsType === "reels") ? 5 : 4;
            const noOfRows = (step.reelsType === "reels") ? 3 : 4;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
