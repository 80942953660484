import Vue from "vue";
import { Prop, Component, Watch, Emit } from "vue-property-decorator";
import * as casino from "../../../../../helpers/casino";
import {
  trace,
  TODO,
  rowsFromIndexes,
  getReelWindowFromIndex,
  wrapIndex,
  getAssetsPath,
} from "../../../../../helpers/helpers";
import State from "../../../../../state/state";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";
import Content from "../content.vue";

@Component({
  data: () => {
    return {
      reelIndex: 0,
    };
  },
})
export default class Reel extends Vue {
  @Prop({ type: Number, default: 0 }) public index;
  @Prop() public step: any;
  @Prop({ type: Boolean, default: false }) public isFeature;
  public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
  public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
  private reelIndex = 0;
  private text = "0";
  private p0top = "";
  private p1top = "";
  private p0bottom = "";
  private p1bottom = "";
  private reels = [];
  private nRows = 3;
  private show = false;
  private canUpdate = false;
  private updateTimeout;
  private featureReelsToUse = 4;
  constructor() {
    super();
    const state = State.state;
    const md = state.getModelDefinition();
    const reelstrips = md["reels"];
    this.nRows = md.reelsLayout[this.index];
  }

  @Watch("step")
  public stepChanged(step) {
    if (!step || !step.json) {
      return;
    }
    this.canUpdate = false;
    this.reelIndex = step.json.reelStripPositions[this.index];
    this.text = String(this.reelIndex);

    this.$nextTick(() => {
      this.canUpdate = true;
      this.updateStep();
    });
  }
  @Watch("text")
  public textChanged(val, oldval) {
    const n = Number(val);
    if (!this.canUpdate) {
      return;
    }
    if (!Number.isInteger(Number(val))) {
      this.text = oldval;
    }
    const rs = this.getReels();
    if (n >= rs.length) {
      this.text = String(wrapIndex(n, rs));
    } else if (n < 0) {
      this.reelIndex = 0;
      this.text = String(this.reelIndex);
    }

    this.updateStep();
    this.updateDB();
  }
  @Watch("isFeature")
  public isFeatureChanged(val) {
    this.updateStep();
  }
  public mounted() {
    this.$refs.reel && this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
  }
  public beforeDestroy() {
    this.$refs.reel && this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
  }
  private getReels() {
    const state = State.state;
    const a = state.getReelStrips(this.step.json.reelsType);
    return a[this.index];
  }
  public up() {
    const rs = this.getReels();
    this.text = String(wrapIndex(this.reelIndex + 1, rs));
  }
  public down() {
    const rs = this.getReels();
    this.text = String(wrapIndex(this.reelIndex - 1, rs));
  }
  public updateStep() {
    this.featureReelsToUse = this.step.json.featureReelsToUse;
    if (!this.step) {
      this.show = false;
      return;
    }
    const state = State.state;
    const rs = this.getReels();
    this.reelIndex = Number(this.text);

    this.p0top = rs[wrapIndex(this.reelIndex - 2, rs)];
    this.p1top = rs[wrapIndex(this.reelIndex - 1, rs)];
    this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.nRows);

    this.p0bottom = rs[wrapIndex(this.reelIndex + this.nRows, rs)];
    this.p1bottom = rs[wrapIndex(this.reelIndex + this.nRows + 1, rs)];
    this.show = true;

    (<Content>this.$parent).updateBaseReelsHeights();
  }
  public updateDB() {
    if (!this.step) {
      return;
    }
    this.step.json.reelStripPositions[this.index] = this.reelIndex;
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }, 500);
  }
  public scrollFunction(e) {
    if (e.deltaY < 0) {
      this.down();
    } else if (e.deltaY > 0) {
      this.up();
    }
    e.preventDefault();
  }
}
