/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.reelsType = "reels";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                case "featureReels":
                    return State.state.getModelDefinition().featureReels;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getBonusTrigger: function() {
            const step = State.state.createStep([22, 27, 1, 10, 4], false, 0, "", "Bonus Trigger");
            step.reelsType = "reels";
            return step;
        },
        getFSTrigger: function() {
            const step = State.state.createStep([6, 0, 40, 0, 0], false, 0, "", "FS Trigger");
            step.reelsType = "reels";
            return step;
        },
        getFeatureStep: function() {
            const step = State.state.createStep([0, 0, 0, 0], false, 0, "", "Feature Step");
            step.reelsType = "featureReels";
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = (step.reelsType == "featureReels") ? 4: 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, state.getNumberOfRows());
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}