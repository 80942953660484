var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sm-steps" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scenario == null,
            expression: "scenario == null",
          },
        ],
        staticClass: "no-steps",
      },
      [_vm._v(" ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex-center buttons" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scenario != null,
              expression: "scenario != null",
            },
          ],
          attrs: { href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.playScenario()
            },
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPlay,
                  expression: "showPlay",
                },
              ],
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-play-circle",
                staticStyle: { "margin-top": "7px", color: "#00c700" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPulse,
              expression: "showPulse",
            },
          ],
        },
        [
          _c("i", {
            staticClass: "fas fa-2x fa-spinner fa-pulse",
            staticStyle: { "margin-top": "7px", color: "#969696" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCheck,
              expression: "showCheck",
            },
          ],
          staticClass: "flex",
        },
        [
          _c("i", {
            staticClass: "fas fa-2x fa-check-square",
            staticStyle: { "margin-top": "7px", color: "#00c700" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "play-now" }, [_vm._v("Play Now!")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scenario != null,
            expression: "scenario != null",
          },
        ],
        staticClass: "print-json button pointer",
      },
      [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.exportScenario()
              },
            },
          },
          [_vm._v("Export JSON")]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.isMobile
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scenario != null,
                expression: "scenario != null",
              },
            ],
            ref: "divSteps",
            staticClass: "steps",
          },
          [
            _c(
              "draggable",
              {
                attrs: { list: _vm.steps, move: _vm.onMoveCallback },
                on: { start: _vm.startDrag, end: _vm.endDrag },
              },
              [
                _c(
                  "transition-group",
                  _vm._l(_vm.steps, function (step, index) {
                    return _c(
                      "div",
                      {
                        key: step.test_scenario_step_id,
                        class: [
                          _vm.selectedStep == step ? "item-active" : "",
                          "item",
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clone",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cloneStep(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-copy" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "rename",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.renameStep(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-edit" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "trash",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteStep(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-trash-alt" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "inner-item",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openStep(step)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  step.json && step.json.name
                                    ? step.json.name
                                    : step.test_scenario_step_id
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }