import Vue from 'vue';
import { Prop, Component, Watch, Emit } from 'vue-property-decorator';
import {
    getReelWindowFromIndex,
    wrapIndex,
    getAssetsPath,
} from '../../../../../helpers/helpers';
import State from '../../../../../state/state';
import bus from '../../../../../common/bus';
import events from '../../../../../common/events';
import Content from '../content.vue';

@Component({
    data: () => {
        return {
            reelIndex: 0,
        };
    },
})
export default class Reel extends Vue {
    @Prop({ type: Number, default: 0 }) public index;
    @Prop() public step: any;
    @Prop({ type: Number, default: 0 }) public reelSetIndex; // 0->white 1->red 2->blue 3->red_blue
    @Prop({ type: Number, default: -1 }) public scatters;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
    private reelIndex = 0;
    private text = '0';
    private p0top = '';
    private p1top = '';
    private p0bottom = '';
    private p1bottom = '';
    private reels = [];
    private nRows = 3;
    private show = false;
    private canUpdate = false;
    private updateTimeout;

    constructor() {
        super();
        const state = State.state;
        const md = state.getModelDefinition();

        if (this.reelSetIndex === 2 || this.reelSetIndex === 3) {
            this.nRows = md.blueReelsLayout[this.index];
        } else {
            this.nRows = md.reelsLayout[this.index];
        }

        this.show = true;
    }

    @Watch('step')
    public stepChanged(step) {
        if (!step || !step.json) {
            return;
        }
        this.canUpdate = false;
        this.reelIndex = step.json.reelStripPositions !== undefined ? step.json.reelStripPositions[this.index] : 0;
        this.text = String(this.reelIndex);
        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }

    @Watch('reelSetIndex')
    public reelSetIndexChanged(val: number) {
        this.reelSetIndex = Number(val);
        
        const state = State.state;
        const md = state.getModelDefinition();

        if (this.reelSetIndex === 2 || this.reelSetIndex === 3) {
            this.nRows = md.blueReelsLayout[this.index];
        } else {
            this.nRows = md.reelsLayout[this.index];
        }

        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }

    @Watch('scatters')
    public scattersChanged(val: number) {
        this.scatters = Number(val);

        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }

    @Watch('text')
    public textChanged(val, oldval) {
        const n = Number(val);
        if (!this.canUpdate) {
            return;
        }
        if (!Number.isInteger(Number(val))) {
            this.text = oldval;
        }
        const rs = this.getReels();
        if (n >= rs.length) {
            this.text = String(wrapIndex(n, rs));
        } else if (n < 0) {
            this.reelIndex = 0;
            this.text = String(this.reelIndex);
        }

        this.updateStep();
        this.updateDB();
    }
    public mounted() {
        this.$refs.reel && this.$refs.reel['addEventListener']('wheel', this.scrollFunction);
    }
    public beforeDestroy() {
        this.$refs.reel && this.$refs.reel['removeEventListener']('wheel', this.scrollFunction);
    }
    private getReels() {
        const state = State.state;

        const a = state.getReelStrips(this.reelSetIndex);
        
        if (this.reelSetIndex === 1 || this.reelSetIndex === 3) {
            const subReelIndex = this.getSubReelSetIndexByScattersNum(this.scatters);
            return a[subReelIndex][this.index];
        }

        return a[this.index];
    }

    private getSubReelSetIndexByScattersNum(scatters: number) {
        let index: number;

        switch (scatters) {
            case 0:
            case 2:
                index = 0;
                break;
            case 4:
                index = 1;
                break;
            case 6:
                index = 2;
                break;
            case 8:
                index = 3;
                break;
            case 10:
                index = 4;
                break;
            default:
                index = -1;
                break;
        }

        return index;
    }

    public up() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex + 1, rs));
    }
    public down() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex - 1, rs));
    }
    public updateStep() {
        if (!this.step) {
            this.show = false;
            return;
        }
        const state = State.state;
        const rs = this.getReels();
        this.reelIndex = Number(this.text);

        this.p0top = rs[wrapIndex(this.reelIndex - 2, rs)];
        this.p1top = rs[wrapIndex(this.reelIndex - 1, rs)];
        this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.nRows);
        this.p0bottom = rs[wrapIndex(this.reelIndex + this.nRows, rs)];
        this.p1bottom = rs[wrapIndex(this.reelIndex + this.nRows + 1, rs)];

        (<Content>this.$parent).onUpdateStep();
    }
    public updateDB() {
        if (!this.step) {
            return;
        }
        this.step.json.reelStripPositions[this.index] = this.reelIndex;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }, 500);
    }
    public scrollFunction(e) {
        if (e.deltaY < 0) {
            this.down();
        } else if (e.deltaY > 0) {
            this.up();
        }
        e.preventDefault();
    }
}
