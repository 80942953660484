import Vue from "vue";
import { Prop, Component, Watch, Emit } from "vue-property-decorator";
import * as casino from "./../../../../../helpers/casino";
import {
    trace,
    TODO,
    rowsFromIndexes,
    getReelWindowFromIndex,
    wrapIndex,
    getAssetsPath,
} from "./../../../../../helpers/helpers";
import State from "../../../../../state/state";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";
import { IVGW065Step } from "../content.vue";

// If the array is empty, return {kind: 'failure', reason: 'array is empty'}.
// If the index is outside of the array's bounds, return {kind: 'failure', reason: 'no element at that index'}.
// Otherwise, return {kind: 'success', value: /* element at index i */}.

@Component({
    data: () => {
        return {
            reelIndex: 0,
        };
    },
})
export default class Reel extends Vue {
    @Prop({ type: Number, default: 0 }) public index;
    @Prop() public step: any;
    @Prop({ type: Boolean, default: false }) public isFeature;
    @Prop({ type: Function, default: () => {} }) public hasChanged;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
    private reelIndex = 0;
    private text = "0";
    private p0top = "";
    private p1top = "";
    private p0bottom = "";
    private p1bottom = "";
    private reels = [];
    private nRows = 3;
    private show = false;
    private canUpdate = false;

    constructor() {
        super();
        const state = State.state;
        const md = state.getModelDefinition();
        this.nRows = md.reelsLayout[this.index];
    }

    @Watch("step")
    public stepChanged(step: IVGW065Step) {
        if (!step || !step.json) {
            return;
        }
        this.canUpdate = false;
        this.reelIndex = step.json.reelStripPositions[this.index];
        this.text = String(this.reelIndex);

        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }

    @Watch("text")
    public textChanged(val, oldval) {
        const n = Number(val);
        if (!this.canUpdate) {
            return;
        }
        if (!Number.isInteger(n)) {
            this.text = oldval;
        }
        const rs = this.getReels();
        if (n >= rs.length) {
            this.text = String(wrapIndex(n, rs));
        } else if (n < 0) {
            this.reelIndex = 0;
            this.text = String(this.reelIndex);
        }

        this.updateDB();
    }

    @Watch("isFeature")
    public isFeatureChanged(val) {
        this.updateStep();
    }
    
    public mounted() {
        this.$refs.reel && this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
    }
    public beforeDestroy() {
        this.$refs.reel && this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
    }

    private getReels() {
        const state = State.state;
        const reels = this.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
        return reels[this.index];
    }

    public up() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex + 1, rs));
    }

    public down() {
        const rs = this.getReels();
        this.text = String(wrapIndex(this.reelIndex - 1, rs));
    }

    public updateStep() {
        if (!this.step) {
            this.show = false;
            return;
        }
        const state = State.state;
        const rs = this.getReels();
        this.reelIndex = Number(this.text);

        this.p0top = rs[wrapIndex(this.reelIndex - 2, rs)];
        this.p1top = rs[wrapIndex(this.reelIndex - 1, rs)];
        this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.nRows);

        this.p0bottom = rs[wrapIndex(this.reelIndex + this.nRows, rs)];
        this.p1bottom = rs[wrapIndex(this.reelIndex + this.nRows + 1, rs)];
        this.show = true;
    }

    public updateDB() {
        if (!this.step) {
            return;
        }

        this.updateStep();
        const step: any = this.step;
        const reelStripPositions = [...step.json.reelStripPositions];
        reelStripPositions[this.index] = this.reelIndex;

        this.hasChanged(reelStripPositions);
    }

    public scrollFunction(e) {
        if (e.deltaY < 0) {
            this.down();
        } else if (e.deltaY > 0) {
            this.up();
        }
        e.preventDefault();
    }

    // Custom Functions
    public doChangePattern() {
        this.updateDB();
    }
}
