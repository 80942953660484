var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add new clearing step")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v("Add new step")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Trigger Free Spins")]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.isBoardGame
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addBoardGameStep()
                    },
                  },
                },
                [_vm._v("Add Board Game Step")]
              )
            : _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addBoardGameStep()
                    },
                  },
                },
                [_vm._v("Trigger Board Game")]
              ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isBoardGameFirstStep
        ? _c(
            "div",
            [_c("board-game-controls", { attrs: { step: _vm.scenario } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reel-position-selectors" },
          _vm._l(_vm.scenario.reelStripPositions, function (position) {
            return _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: position.value,
                  expression: "position.value",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: position.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(position, "value", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            })
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("p", { staticClass: "reel-spin-feature-selector" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.featureSetId,
                    expression: "scenario.featureSetId",
                  },
                ],
                attrs: { id: "reel-spin_feature-picks" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.scenario,
                      "featureSetId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("NO FEATURE"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 0 } }, [_vm._v("WILD_PATH")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 1 } }, [
                  _vm._v("CANDY_CANES"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 2 } }, [
                  _vm._v("NUTT_PRIZE"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 3 } }, [
                  _vm._v("STEAL_ROYALS"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 4 } }, [
                  _vm._v("MULTIPLIER"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 5 } }, [
                  _vm._v("STEAL_ROYALS & WILD_PATH"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 6 } }, [
                  _vm._v("STEAL_ROYALS  & CANDY_CANES"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 7 } }, [
                  _vm._v("\tSTEAL_ROYALS  & NUTT_PRIZE"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 8 } }, [
                  _vm._v("\tMULTIPLIER & WILD_PATH"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 9 } }, [
                  _vm._v("MULTIPLIER & CANDY_CANES "),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 10 } }, [
                  _vm._v("MULTIPLIER & NUTT_PRIZE "),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 11 } }, [
                  _vm._v("STEAL_ROYALS & MULTIPLIER "),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 12 } }, [
                  _vm._v("STEAL_ROYALS & MULTIPLIER & WILD_PATH "),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 13 } }, [
                  _vm._v("STEAL_ROYALS & MULTIPLIER & CANDY_CANES "),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: 14 } }, [
                  _vm._v("STEAL_ROYALS & MULTIPLIER & NUTT_PRIZE "),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("reel-spin-feature-controls", {
            attrs: {
              step: _vm.scenario,
              reelSpinFeatureId: _vm.scenario.featureSetId,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("slot-window", {
        attrs: { spinOutcome: _vm.spinOutcome, step: _vm.scenario },
        on: { "stolen-royal-changed": _vm.onStolenRoyalChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "label",
      {
        staticClass: "reel-spin-feature-label",
        attrs: { for: "reel-spin-feature-picks" },
      },
      [_c("h3", [_vm._v("Select Reel Spin Feature: ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }