import Vue from 'vue';
import { PropType } from 'vue';
import { getReelWindowFromIndex, wrapIndex } from '../../../../helpers/helpers';
import { modelDefinition } from '../../../../state/models/slots/vgw096';
import type Content from './content.vue';

export default Vue.component('Reel', {
    props: {
        content: {
            type: Object as PropType<InstanceType<typeof Content>>,
        },
        index: {
            type: Number,
        },
    },

    data() {
        return {
            isLoading: false,
            reelIndex: 0,
        };
    },

    computed: {
        show(): boolean {
            return !!this.content.currentStep;
        },
        col(): number {
            return this.index;
        },
        reel(): string[] {
            return this.content.reels[this.index];
        },
        windowHeight(): number {
            return modelDefinition.reelsLayout[this.index];
        },
        symbolsAboveWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex - 2, 2);
        },
        symbolsBelowWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex + this.windowHeight, 2);
        },
        reelWindow(): string[] {
            return getReelWindowFromIndex(this.reel, this.reelIndex, this.windowHeight);
        },
        canUpdate(): boolean {
            return this.content.canChangeReelStripPositions;
        },
        isInFireshot(): boolean {
            return this.content.isFireshotRespin || this.content.fireshotRespinsRemaining;
        },
    },

    methods: {
        up() {
            ++this.reelIndex;
            this.updateStep();
        },
        down() {
            --this.reelIndex;
            this.updateStep();
        },
        handleWheelEvent(e) {
            if (!this.canUpdate) return;
            if (e.deltaY > 0) this.up();
            if (e.deltaY < 0) this.down();
            e.preventDefault();
        },
        updateStep() {
            if (this.isLoading || !this.content.currentStep) return;
            this.content.currentStep.json.reelStripPositions[this.index] = this.reelIndex;
            this.content.notifyReelStripPositionsChanged();
        },
    },

    watch: {
        'content.currentStep'(step) {
            this.isLoading = true;
            this.reelIndex = step?.json?.reelStripPositions?.[this.index] ?? 0;
            setTimeout(() => this.isLoading = false);
        },
        reelIndex(val) {
            const indexInRange = wrapIndex(val, this.reel);
            if (indexInRange !== val) this.reelIndex = indexInRange;
            this.updateStep();
        },
    },

    mounted() {
        this.$refs.reel && this.$refs.reel['addEventListener']('wheel', this.handleWheelEvent);
    },

    beforeDestroy() {
        this.$refs.reel && this.$refs.reel['removeEventListener']('wheel', this.handleWheelEvent);
    },
});
