export interface StackPICSymbol {
  cell: [number, number];
  isGold: boolean;
}

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  addedWilds?: Array<[number, number]>;
  stackPicSymbolCells?: StackPICSymbol[];
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [0, 18, 0, 18, 0, 18],
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [60, 60, 60, 60, 60, 60],
  };
}

export function createFreeSpinTriggerStep(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [38, 58, 8, 49, 56, 28],
  };
};

export function createPIC1StackReSpinTrigger(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [52, 58, 8, 49, 56, 28],
  };
};

export function createPIC2StackReSpinTrigger(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [13, 58, 8, 49, 56, 28],
  };
};

export function createPIC3StackReSpinTrigger(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [27, 58, 8, 49, 56, 28],
  };
};

export function createPIC4StackReSpinTrigger(): StepJson {
  return {
      name: 'Step',
      originatorId,
      gameState: 'continue',
      reelStripPositions: [199, 58, 8, 49, 56, 28],
  };
};


export const originatorId = new Date().getTime();
