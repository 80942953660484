// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
	"success" : true,
	"messages" : [ ],
	"modelDefinition" : {
	  "modelId" : "vgw054",
	  "reelsLayout" : [ 3, 3, 3, 3, 3 ],
	  "scatterSymbol" : "Badge",
	  "wildSymbol" : "wild",
	  "freeSpinMultiplier" : 0,
	  "freeSpinCount" : 5,
	  "winTable" : [ {
		"symbol" : "Pic1",
		"multipliers" : [ 300, 50, 10 ]
	  }, {
		"symbol" : "Pic2",
		"multipliers" : [ 250, 30, 8 ]
	  }, {
		"symbol" : "Pic3",
		"multipliers" : [ 250, 30, 8 ]
	  }, {
		"symbol" : "Pic4",
		"multipliers" : [ 125, 25, 5 ]
	  }, {
		"symbol" : "Pic5",
		"multipliers" : [ 125, 25, 5 ]
	  }, {
		"symbol" : "Pic6",
		"multipliers" : [ 125, 25, 5 ]
	  }, {
		"symbol" : "Pic7",
		"multipliers" : [ 75, 10, 3 ]
	  }, {
		"symbol" : "Pic8",
		"multipliers" : [ 75, 10, 3 ]
	  }, {
		"symbol" : "Pic9",
		"multipliers" : [ 75, 10, 3 ]
	  }, {
		"symbol" : "Pic10",
		"multipliers" : [ 75, 10, 3 ]
	  }, {
		"symbol" : "wild",
		"multipliers" : [ 300, 50, 10 ]
	  }, {
		"symbol" : "Badge",
		"multipliers" : [ ]
	  } ],
	  "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ], [ 0, 0, 2, 0, 0 ], [ 2, 2, 0, 2, 2 ], [ 0, 0, 2, 2, 2 ], [ 2, 2, 0, 0, 0 ], [ 2, 2, 2, 2, 1 ], [ 0, 0, 0, 0, 1 ], [ 0, 0, 0, 0, 2 ], [ 0, 2, 2, 2, 2 ], [ 1, 1, 1, 1, 0 ], [ 1, 1, 1, 1, 2 ], [ 1, 1, 1, 0, 2 ], [ 1, 1, 1, 2, 0 ], [ 0, 0, 0, 2, 1 ], [ 2, 2, 2, 0, 1 ], [ 2, 2, 2, 1, 0 ], [ 0, 0, 0, 1, 2 ], [ 2, 2, 2, 0, 2 ], [ 0, 0, 0, 2, 0 ], [ 1, 1, 0, 2, 1 ], [ 1, 1, 2, 0, 1 ], [ 1, 2, 2, 0, 2 ], [ 0, 2, 2, 0, 0 ], [ 2, 0, 2, 2, 1 ], [ 0, 2, 0, 0, 1 ], [ 2, 2, 1, 2, 1 ] ],
	  "reels" : [ [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic5", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic1", "Pic8", "Pic2", "Pic5", "wild" ], [ "Pic1", "Pic1", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic8", "Pic3", "Pic9", "Pic6", "Pic4", "Pic1", "Pic9", "Pic6", "Pic4", "Pic8", "Pic2", "wild" ], [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic1", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic5", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic1", "Pic8", "Pic2", "Pic5", "wild" ], [ "Pic1", "Pic1", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic8", "Pic3", "Pic9", "Pic6", "Pic4", "Pic10", "Pic9", "Pic6", "Pic4", "Pic8", "wild" ], [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic9", "Pic6", "Pic4", "Pic5", "Badge", "Badge", "Badge", "Pic1", "Pic5", "wild" ] ],
	  "featureReels" : [ [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic5", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic1", "Pic8", "Pic2", "Pic5", "wild" ], [ "Pic1", "Pic1", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic8", "Pic3", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic4", "Pic8", "Pic2", "wild" ], [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic1", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic5", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic1", "Pic8", "Pic2", "Pic5", "wild" ], [ "Pic1", "Pic1", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic8", "Pic3", "Pic9", "Pic6", "Pic4", "Badge", "Badge", "Badge", "Pic4", "Pic8", "wild" ], [ "wild", "wild", "Pic2", "Pic10", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic3", "Pic9", "Pic4", "Pic10", "Pic1", "Pic5", "Pic2", "Pic3", "Pic4", "Pic8", "Pic7", "Pic2", "Pic10", "Pic3", "Pic9", "Pic6", "Pic4", "Pic5", "Badge", "Badge", "Badge", "Pic1", "Pic5", "wild" ] ],
	  "defaultSelectLine" : 50,
	  "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
	  "coinType4Values" : [ 1, 2, 5, 10, 15, 20, 30, 50, 100 ],
	  "coinType1_min_bet" : 250,
	  "coinType1_max_bet" : 100000,
	  "coinType1_spin_max_bet" : 5000000,
	  "coinType4_min_bet" : 1,
	  "coinType4_max_bet" : 50,
	  "coinType4_spin_max_bet" : 5000,
	  "winLevels" : [ {
		"name" : "normal1",
		"threshold" : 5
	  }, {
		"name" : "normal2",
		"threshold" : 15
	  }, {
		"name" : "normal3",
		"threshold" : 20
	  }, {
		"name" : "bigwin",
		"threshold" : 35
	  }, {
		"name" : "megawin",
		"threshold" : 70
	  }, {
		"name" : "epicwin",
		"threshold" : 2147483647
	  } ],
	  "freeSpinTrigger" : "Badge",
	  "countToTriggerFreeSpin" : 2147483647,
	  "fortuneCartPrizes" : [ "1", "2", "3", "4", "big wheel", "free spins" ],
	  "whichBigWheel" : [ "bigWheelJackpots", "bigWheelPrizes" ],
	  "bigWheelJackpots" : [ "mini", "minor", "grand" ],
	  "bigWheelPrizes" : [ "4", "5", "10", "15", "25", "30", "50" ],
	  "sweepsMiniInitialJackpotValue" : 1000,
	  "sweepsMinorInitialJackpotValue" : 5000,
	  "sweepsGrandInitialJackpotValue" : 30000000,
	  "goldMiniInitialJackpotValue" : 100000,
	  "goldMinorInitialJackpotValue" : 500000,
	  "goldGrandInitialJackpotValue" : 3000000000,
	  "currency_symbol" : "$"
	},
	"modelId" : "vgw054",
	"gameSessionId" : 238,
	"reelStripPositions" : [ 3, 2, 27, 31, 17 ],
	"slotWindow" : [ [ "Pic10", "Pic3", "Pic4" ], [ "Pic2", "Pic10", "Pic3" ], [ "Badge", "Badge", "Badge" ], [ "wild", "Pic1", "Pic1" ], [ "Pic4", "Pic8", "Pic7" ] ],
	"gold_balance" : 2013035000,
	"sweeps_balance" : 206530723,
	"sweeps_deposit_balance" : 199989245,
	"sweeps_promo_balance" : 0,
	"sweeps_win_balance" : 6541478,
	"sweeps_available_balance" : 6541478,
	"miniJackpotPool" : 1000,
	"grandJackpotPool" : 30000000,
	"serverTime" : "2020-02-20 18:47:52",
	"minorJackpotPool" : 5000
  };
