// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export const modelDefinition = {
    modelId: "vgw077",

    scatterSymbol: "SCAT",
    wildSymbol: "WILD",
    coinSymbol: "COIN",
    freeSpinTrigger: "SCAT",
    countToTriggerFreeSpin: 3,
    reelsLayout: [4, 4, 4, 4, 4, 4],
    defaultSelectLine: "",
    betMultiplier: 20,
    countToTriggerFireshot: 8,
    fireshotRespinCount: 3,

    winTable: [
        {
            symbol: "PIC1",
            multipliers: [100, 60, 40, 20, 8]
        },
        {
            symbol: "PIC2",
            multipliers: [80, 40, 30, 8]
        },
        {
            symbol: "PIC3",
            multipliers: [80, 40, 30, 8]
        },
        {
            symbol: "PIC4",
            multipliers: [60, 30, 20, 6]
        },
        {
            symbol: "PIC5",
            multipliers: [60, 30, 20, 6]
        },
        {
            symbol: "HEART",
            multipliers: [20, 10, 4, 2]
        },
        {
            symbol: "DIAMOND",
            multipliers: [20, 10, 4, 2]
        },
        {
            symbol: "CLUB",
            multipliers: [20, 10, 4, 2]
        },
        {
            symbol: "SPADE",
            multipliers: [20, 10, 4, 2]
        },
        {
            symbol: "SCAT",
            multipliers: [1000, 100, 10, 2]
        },
    ],

    // This is a ways game, so there are zero lines.
    playLines: [],

    reels: [
        ["HEART","PIC2","CLUB","CLUB","CLUB","SCAT","SPADE","CLUB","CLUB","PIC1","HEART","CLUB","PIC3","CLUB","COIN","COIN","COIN","HEART","HEART","PIC4","CLUB","CLUB","SPADE","PIC3","DIAMOND","PIC5","CLUB","CLUB","PIC2","SPADE","PIC5","HEART","HEART","PIC3","CLUB","PIC1","PIC1","PIC1","PIC1","SPADE","PIC3","CLUB","CLUB","PIC1","SPADE","CLUB","HEART","HEART","PIC2","CLUB","CLUB","PIC4","SPADE","PIC5","HEART","HEART","PIC1","PIC1","CLUB","CLUB","DIAMOND","PIC4","HEART","HEART","HEART","PIC5","CLUB","CLUB","SCAT","CLUB","PIC4","PIC5","CLUB","CLUB","PIC2","CLUB","PIC3","HEART","HEART","PIC1","SPADE","PIC1","CLUB","CLUB","PIC4","PIC5","HEART","HEART","COIN","COIN","COIN","COIN","HEART","PIC2","PIC2","CLUB","PIC3","DIAMOND","PIC4","SPADE","HEART","HEART","PIC1","CLUB","CLUB","PIC2","SPADE","PIC5","DIAMOND","CLUB","PIC4","SPADE","SPADE","HEART","PIC1","CLUB","CLUB","PIC2","SPADE","SPADE","PIC5","CLUB","CLUB","CLUB","PIC4","CLUB","CLUB","CLUB","PIC3","PIC3","CLUB","PIC5","CLUB","CLUB","HEART","PIC3","CLUB","CLUB","PIC2","SPADE","CLUB","PIC4"],
        ["DIAMOND","PIC3","HEART","HEART","SCAT","DIAMOND","DIAMOND","DIAMOND","PIC1","HEART","PIC4","SPADE","PIC1","DIAMOND","DIAMOND","PIC5","HEART","HEART","HEART","COIN","COIN","COIN","DIAMOND","DIAMOND","PIC1","CLUB","SPADE","SPADE","PIC1","DIAMOND","DIAMOND","DIAMOND","PIC4","HEART","HEART","PIC2","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC2","SPADE","PIC5","HEART","PIC1","DIAMOND","DIAMOND","PIC3","SPADE","PIC2","DIAMOND","DIAMOND","PIC4","HEART","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","HEART","PIC5","DIAMOND","DIAMOND","WILD","CLUB","PIC3","DIAMOND","DIAMOND","PIC4","SPADE","SCAT","HEART","PIC5","DIAMOND","DIAMOND","PIC4","SPADE","PIC3","DIAMOND","DIAMOND","PIC1","DIAMOND","PIC1","DIAMOND","PIC2","HEART","PIC4","DIAMOND","COIN","COIN","COIN","COIN","DIAMOND","PIC2","CLUB","PIC3","PIC3","SPADE","PIC2","DIAMOND","PIC4","PIC5","SPADE","SPADE","PIC1","DIAMOND","PIC1","DIAMOND","PIC3","HEART","HEART","PIC4","SPADE","PIC5","PIC5","DIAMOND","PIC1","PIC1","SPADE","PIC5","DIAMOND","DIAMOND","PIC5","SPADE","SPADE","SPADE","PIC2","PIC2","HEART","HEART","HEART","PIC2","DIAMOND","PIC4","HEART","HEART","PIC1","DIAMOND","PIC2","SPADE","PIC3","DIAMOND","DIAMOND","PIC5","HEART"],
        ["HEART","PIC1","SPADE","PIC1","HEART","PIC4","SPADE","DIAMOND","HEART","PIC2","PIC2","SPADE","COIN","COIN","COIN","SPADE","PIC1","HEART","HEART","PIC4","SPADE","PIC1","PIC1","PIC1","SPADE","SPADE","SPADE","PIC2","PIC2","HEART","HEART","WILD","SPADE","SPADE","PIC5","HEART","HEART","PIC2","CLUB","PIC1","HEART","PIC4","SPADE","SPADE","SPADE","PIC3","DIAMOND","PIC1","PIC1","SPADE","SPADE","PIC4","HEART","HEART","COIN","COIN","COIN","SPADE","SPADE","SPADE","PIC1","CLUB","PIC5","SPADE","PIC3","SPADE","PIC1","PIC1","PIC1","PIC1","HEART","PIC2","SPADE","SPADE","WILD","DIAMOND","PIC4","SPADE","SPADE","PIC1","HEART","PIC5","SPADE","SPADE","SPADE","PIC5","HEART","HEART","PIC4","SPADE","COIN","COIN","COIN","COIN","HEART","PIC3","SPADE","SPADE","PIC1","HEART","PIC4","SPADE","SCAT","HEART","HEART","HEART","PIC3","PIC3","SPADE","SPADE","SPADE","PIC4","PIC5","CLUB","PIC3","PIC3","HEART","PIC2","SPADE","PIC1","HEART","PIC5","SPADE","SPADE","SCAT","CLUB","PIC2","PIC2","SPADE","PIC3","DIAMOND","PIC5","SPADE","PIC4","PIC4","HEART","PIC1","PIC1","SPADE","SPADE","PIC5","PIC5","HEART","PIC1","CLUB","PIC5","DIAMOND","PIC3","PIC3"],
        ["CLUB","CLUB","WILD","PIC5","CLUB","PIC2","HEART","HEART","PIC4","CLUB","CLUB","COIN","COIN","COIN","HEART","HEART","PIC3","CLUB","CLUB","PIC1","HEART","PIC5","CLUB","CLUB","SPADE","SPADE","PIC2","CLUB","CLUB","PIC5","HEART","HEART","PIC3","CLUB","CLUB","COIN","COIN","COIN","CLUB","CLUB","CLUB","PIC4","SCAT","SPADE","SPADE","PIC2","CLUB","HEART","PIC5","CLUB","PIC3","PIC3","PIC3","DIAMOND","PIC5","SPADE","SPADE","PIC1","CLUB","PIC1","DIAMOND","PIC5","PIC5","CLUB","PIC4","SPADE","PIC2","PIC3","CLUB","PIC1","SPADE","PIC1","CLUB","CLUB","PIC4","PIC5","SPADE","CLUB","PIC2","PIC2","PIC2","CLUB","CLUB","COIN","COIN","COIN","COIN","HEART","HEART","PIC5","PIC4","CLUB","HEART","PIC2","SPADE","PIC3","CLUB","CLUB","PIC4","PIC4","CLUB","SCAT","SPADE","SPADE","PIC2","CLUB","PIC4","HEART","WILD","SPADE","PIC5","HEART","DIAMOND","PIC3","SPADE","PIC1","CLUB","PIC4","SPADE","PIC5","PIC5","CLUB","SPADE","PIC3","CLUB","CLUB","PIC1","HEART","PIC5","CLUB","PIC2","SPADE","SPADE","PIC1","PIC1","PIC1","PIC1","CLUB","HEART","PIC2","CLUB","CLUB","PIC1","DIAMOND","PIC3"],
        ["HEART","PIC1","DIAMOND","PIC1","HEART","PIC5","DIAMOND","SPADE","SPADE","PIC3","PIC3","PIC3","DIAMOND","DIAMOND","COIN","COIN","COIN","DIAMOND","PIC5","PIC5","PIC5","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC2","PIC2","HEART","WILD","DIAMOND","DIAMOND","PIC4","PIC4","SPADE","SCAT","DIAMOND","PIC3","HEART","HEART","SPADE","PIC4","PIC4","CLUB","PIC2","DIAMOND","PIC1","PIC1","SPADE","SPADE","PIC5","PIC5","DIAMOND","DIAMOND","PIC3","HEART","PIC2","DIAMOND","DIAMOND","PIC1","SPADE","SPADE","HEART","PIC3","DIAMOND","PIC1","PIC1","PIC1","PIC1","HEART","PIC2","DIAMOND","WILD","CLUB","PIC5","DIAMOND","DIAMOND","DIAMOND","PIC2","HEART","PIC1","DIAMOND","PIC1","HEART","PIC5","DIAMOND","PIC3","HEART","PIC1","PIC1","PIC1","PIC1","SPADE","PIC4","PIC4","DIAMOND","COIN","COIN","COIN","COIN","HEART","PIC3","DIAMOND","DIAMOND","PIC1","HEART","PIC5","DIAMOND","SCAT","DIAMOND","PIC1","SPADE","SPADE","SPADE","PIC3","HEART","PIC5","PIC5","SPADE","PIC2","PIC2","HEART","HEART","PIC3","DIAMOND","PIC1","SPADE","PIC5","HEART","DIAMOND","DIAMOND","SCAT","SPADE","SPADE","PIC2","PIC2","HEART","CLUB","PIC1","SPADE","PIC4","DIAMOND","DIAMOND","PIC5","HEART","HEART","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","HEART","HEART","PIC2"],
        ["HEART","PIC4","CLUB","PIC1","DIAMOND","DIAMOND","PIC2","SPADE","COIN","COIN","COIN","COIN","DIAMOND","PIC5","HEART","HEART","PIC3","CLUB","SPADE","PIC1","HEART","HEART","SCAT","SPADE","PIC5","CLUB","CLUB","PIC2","PIC2","SPADE","PIC1","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","HEART","PIC5","CLUB","PIC1","SPADE","SPADE","PIC2","HEART","CLUB","PIC4","PIC4","DIAMOND","PIC1","PIC1","SPADE","PIC5","HEART","HEART","SPADE","PIC2","HEART","PIC2","SPADE","PIC3","DIAMOND","SCAT","CLUB","PIC2","HEART","SPADE","PIC1","PIC1","PIC1","PIC1","SPADE","PIC2","PIC3","HEART","HEART","PIC1","PIC1","PIC1","CLUB","PIC5","PIC5","HEART","DIAMOND","COIN","COIN","COIN","COIN","CLUB","PIC3","PIC4","HEART","SPADE","PIC1","DIAMOND","PIC5","PIC5","HEART","CLUB","PIC1","DIAMOND","PIC1","SPADE","PIC3","PIC3","HEART","PIC4","SPADE","PIC5","PIC5","HEART","PIC1","PIC1","CLUB","SPADE","SCAT","PIC4","HEART","SPADE","PIC1","DIAMOND","PIC2"]
    ],

    // Feature reels are dynamic for this math model, so `config.featureReels` should not be used. It has been set to
    // an empty array here, so that if math model code and/or helpers inadvertently access this prop, there should be
    // obvious errors in processing and/or outcome.
    featureReels: [],

    winLevels: [
        { name: "normal1", threshold: 5 },
        { name: "normal2", threshold: 15 },
        { name: "normal3", threshold: 20 },
        { name: "bigwin", threshold: 35 },
        { name: "megawin", threshold: 70 },
        { name: "epicwin" }
    ],

    coinType1Values: [100, 500, 1000, 5000, 10000, 50000],
    coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250, 500],

    coinType1_min_bet: 100,
    coinType1_max_bet: 50000,
    coinType1_spin_max_bet: 1000000,

    coinType4_min_bet: 1,
    coinType4_max_bet: 500,
    coinType4_spin_max_bet: 10000,

    freeSpins: {
        /** Wheel 1 gives the number of free spins awarded. */
        wheel1: [25, 8, 10, 15, 8, 5, 20, 10, 8, 15, 10, 5],

        /** Wheel 2 gives the number of PIC1s added to the reels. */
        wheel2: [100, 30, 40, 60, 30, 20, 80, 40, 30, 60, 40, 20],

        /** Positions on reels where PICs are substituted. `reelIndexes` values are 0-based (PAR sheet is 1-based). */
        pic1Substitutions: [
            {
                pic1sAdded: 20,
                reelIndexes: [
                    [23,51,61,71],
                    [79,115,135],
                    [81,121,129,145],
                    [48,95,119],
                    [34,55,144],
                    [60,95,106],
                ]
            },
            {
                pic1sAdded: 30,
                reelIndexes: [
                    [23,51,61,71,85],
                    [1,72,79,115,135],
                    [41,81,121,129,145],
                    [48,64,75,95,119],
                    [34,55,104,128,144],
                    [56,60,81,95,106],
                ]
            },
            {
                pic1sAdded: 40,
                reelIndexes: [
                    [1,23,25,51,61,71,85],
                    [1,32,72,79,115,135,144],
                    [41,81,106,121,129,141,145],
                    [48,64,75,95,119,123],
                    [34,55,104,128,134,144],
                    [16,43,56,60,81,95,106],
                ]
            },
            {
                pic1sAdded: 60,
                reelIndexes: [
                    [1,21,23,25,30,51,61,70,71,85,107],
                    [1,32,53,72,79,99,100,115,126,135,144],
                    [27,28,37,41,81,106,107,121,129,141,145],
                    [28,47,48,54,64,75,95,119,123],
                    [7,33,34,55,104,121,128,134,144],
                    [16,24,25,43,56,60,81,95,106],
                ]
            },
            {
                pic1sAdded: 80,
                reelIndexes: [
                    [1,21,23,25,28,30,48,51,61,65,70,71,85,107],
                    [1,31,32,46,53,64,72,79,99,100,115,126,135,144],
                    [7,27,28,34,37,41,62,81,106,107,121,129,134,141,145],
                    [21,28,32,47,48,54,64,75,95,117,119,123],
                    [5,7,33,34,43,55,59,104,115,121,128,134,144],
                    [16,24,25,36,43,56,60,81,95,103,104,106],
                ]
            },
            {
                pic1sAdded: 100,
                reelIndexes: [
                    [1,10,21,23,25,28,30,48,51,61,65,70,71,85,98,103,107],
                    [1,31,32,33,46,47,53,64,72,79,99,100,115,126,127,135,144],
                    [7,27,28,34,37,41,61,62,81,82,106,107,120,121,129,134,141,145],
                    [20,21,28,31,32,47,48,54,58,64,75,90,95,104,117,119,123],
                    [2,5,7,33,34,43,55,59,79,104,114,115,121,128,134,144,147],
                    [16,24,25,36,43,56,60,73,81,95,103,104,106,117],
                ]
            },
        ],
    },

    fireshot: {
        /** Weights used each spin for choosing whether COINs in the slot window will be NORMAL_COIN or SPECIAL_COIN. */
        coinModeWeights: {
            NORMAL_COIN: 7,
            SPECIAL_COIN: 1,
        },

        /** Weights used during fireshot respins to possibly turn non-coin symbols in the slot window into COINs. */
        extraCoinWeights: {
            NORMAL_COIN: {
                easy: {
                    chance: 1,
                    outOf: 15,
                },
                hard: {
                    chance: 'COIN_WEIGHT' as const,
                    outOf: 160_000,
                },
            },
            SPECIAL_COIN: {
                easy: {
                    chance: 1,
                    outOf: 10,
                },
                hard: {
                    chance: 'COIN_WEIGHT' as const,
                    outOf: 67_000,
                },
            },
        },

        /** Weights used to assign prizes to each COIN symbol that appears in the slot window. */
        prizeWeights: {
            NORMAL_COIN: {
                initial: {
                    20: 243750,
                    30: 90000,
                    40: 45000,
                    60: 32050,
                    100: 22500,
                    200: 7500,
                    300: 3750,
                    MINI: 4700,
                    MINOR: 750,
                    MAJOR: 'COIN_WEIGHT' as const,
                },
                respin: {
                    20: 750000,
                    30: 283500,
                    40: 135000,
                    60: 81000,
                    100: 66750,
                    200: 21600,
                    300: 5400,
                    MINI: 6000,
                    MINOR: 750,
                    MAJOR: 'COIN_WEIGHT' as const,
                },
            },
            SPECIAL_COIN: {
                initial: {
                    20: 14400,
                    30: 14400,
                    40: 14400,
                    60: 12000,
                    100: 9000,
                    200: 6400,
                    300: 4300,
                    MINI: 4300,
                    MINOR: 800,
                    MAJOR: 'COIN_WEIGHT' as const,
                },
                respin: {
                    20: 48000,
                    30: 48000,
                    40: 48000,
                    60: 37400,
                    100: 22000,
                    200: 16000,
                    300: 6400,
                    MINI: 13000,
                    MINOR: 1200,
                    MAJOR: 'COIN_WEIGHT' as const,
                },
            },
        },
    },

    jackpots: {
        fixedMultipliers: {
            MINI: 500,
            MINOR: 2000,
        },
        seedValue: {
            MAJOR: {
                GOLD: 20_000_000,
                SWEEPS: 200_000,
            },
            GRAND: {
                GOLD: 200_000_000,
                SWEEPS: 2_000_000,
            },
        },
        percentToJackpotPool: {
            MAJOR: 0.5,
            GRAND: 0.5,
        },
    },
};
