var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _c("h2", [_vm._v("Scenario Result")]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "slotWindow", staticClass: "slot-window card" },
      [
        _vm._l(_vm.spinOutcome.slotWindow, function (reel, colIndex) {
          return _vm._l(reel, function (symbolName, rowIndex) {
            return _c(
              "reel-symbol",
              _vm._g(
                {
                  key: `${colIndex} ${rowIndex}`,
                  attrs: {
                    position: _vm.spinOutcome.reelStripPositions[colIndex],
                    colIndex: colIndex,
                    rowIndex: rowIndex,
                    symbolName: symbolName,
                    spinType: _vm.spinType,
                    newWilds: _vm.spinOutcome.newWilds,
                    wilds: _vm.spinOutcome.wilds,
                  },
                },
                _vm.$listeners
              )
            )
          })
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card generic-info" }, [
      _c("p", [_vm._v("Spin Type: " + _vm._s(_vm.spinType))]),
      _vm._v(" "),
      _c("p", [_vm._v("Win Type: " + _vm._s(_vm.spinOutcome.winType))]),
    ]),
    _vm._v(" "),
    _vm.isFreeSpin
      ? _c("div", { staticClass: "card generic-info" }, [
          _c("p", [
            _vm._v("Free Spin Phase: " + _vm._s(_vm.spinOutcome.freeSpinPhase)),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Free Spin Count: " + _vm._s(_vm.spinOutcome.freeSpinCount)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isWildFurySpin
      ? _c("div", { staticClass: "card generic-info" }, [
          _c("p", [
            _vm._v("Wild Fury Phase: " + _vm._s(_vm.spinOutcome.wildFuryPhase)),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Scatter Win Count: " +
                _vm._s(_vm.spinOutcome.wildFuryScatterWins)
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }