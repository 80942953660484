/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.levelNo = 1;
            step.reelsType = "reels1";
            step.hammerNo = [0, 0, 0];
            step.internal_name = "Base";
            return step;
        },
        getBaseStep: function(buster, reels) {
            let stepReelsPos = buster ? [0, 4, 57, 46, 0] : [0, 0, 0, 0, 0];
            const name = buster ? "Base buster" : "Base";
            const step = State.state.createStep(stepReelsPos, false, 0, "", name);
            step.reelsType = reels;
            step.levelNo = 1;
            step.hammerNo = [0, 0, 0];
            step.internal_name = name;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels1":
                    return State.state.getModelDefinition().reels1;
                    break;
                case "reels2":
                    return State.state.getModelDefinition().reels2;
                    break;
                case "reels3":
                    return State.state.getModelDefinition().reels3;
                    break;
                case "reelsFeature1":
                    return State.state.getModelDefinition().reelsFeature1;
                    break;
                case "reelsFeature2":
                    return State.state.getModelDefinition().reelsFeature2;
                    break;
                case "reelsFeature3":
                    return State.state.getModelDefinition().reelsFeature3;
                    break;
                default:
                    return State.state.getModelDefinition().reels1;
            }
        },

        // example on creating custom indexes
        getFSTriggerStep (stepTitle, reelsType) {
            const step = State.state.createStep([23, 17, 52, 31, 17], false, 0, "", stepTitle);
            step.reelsType = reelsType;
            step.internal_name = "FS Trigger";
            step.levelNo = 1;
            step.hammerNo = [0, 0, 0];
            return step;
        },
        getFSStep (stepTitle, reelsType) {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", stepTitle);
            step.internal_name = stepTitle;
            step.reelsType = reelsType;
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType, step.bands);
            const noOfReels = 5;
            const noOfRows = step.internal_name == "FS Step"|| step.internal_name == "FS Step Buster" ? 8 : 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
