import Vue from 'vue';
import Component from 'vue-class-component';
import Content from '../content.vue';

@Component({
    props: ['index', 'mixAndMatchPrizes'],
})
export default class MixAndMatchPrizes extends Vue {
    constructor() {
        super();
    }
    updateMixAndMatchPrize(index) {
        (<Content>this.$parent).updateMixAndMatchPrize(index, event);
    }
}
