// tslint:disable: object-literal-key-quotes trailing-comma whitespace no-consecutive-blank-lines
export const config = {
    modelId: "vgw076",

    scatterSymbol: "SCAT",
    scatterCountToTriggerFeature: 3,
    reelsLayout: [3, 3, 3, 3, 3],
    defaultSelectLine: "9",

    winTable: [
        {
            symbol: "PIC1",
            multipliers: [2000, 200, 50, 10]
        },
        {
            symbol: "PIC2",
            multipliers: [1000, 150, 30]
        },
        {
            symbol: "PIC3",
            multipliers: [500, 100, 20]
        },
        {
            symbol: "PIC4",
            multipliers: [300, 50, 10]
        },
        {
            symbol: "PIC5",
            multipliers: [200, 40, 5]
        },
        {
            symbol: "PIC6",
            multipliers: [100, 25, 5]
        },
    ],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
    ],

    reels: [
        ['PIC4','PIC5','PIC3','PIC4','PIC6','PIC1','PIC3','PIC6','PIC5','PIC2','PIC6','PIC4','PIC2','PIC5','PIC4','PIC6','PIC3','PIC4','PIC1','PIC5','PIC6','PIC2','PIC5','PIC4','PIC6','PIC5','PIC3','PIC4','PIC1','PIC2','PIC5','PIC6','PIC3','SCAT','PIC4','PIC2','PIC6','PIC5','PIC3','PIC6'],
        ['PIC3','PIC1','PIC5','PIC4','PIC6','PIC5','PIC3','PIC1','PIC2','PIC4','PIC1','PIC5','PIC6','PIC2','PIC4','PIC6','PIC5','PIC3','PIC6','PIC4','PIC5','PIC1','PIC2','SCAT','PIC4','PIC5','PIC2','PIC3','PIC6','PIC5','PIC4','PIC6','PIC5','PIC3','PIC1','PIC6','PIC2','PIC3','PIC6','PIC4'],
        ['PIC3','PIC5','PIC6','PIC4','PIC3','PIC6','PIC5','PIC2','PIC3','PIC4','PIC5','PIC3','PIC2','PIC6','PIC1','PIC5','PIC6','PIC4','PIC3','PIC1','PIC6','PIC4','PIC2','PIC6','PIC1','PIC5','PIC4','PIC6','PIC1','PIC5','PIC4','PIC6','PIC2','PIC5','PIC6','SCAT','PIC1','PIC3','PIC4','PIC2'],
        ['PIC5','PIC2','PIC4','PIC1','PIC5','PIC3','PIC1','PIC5','PIC3','SCAT','PIC6','PIC2','PIC4','PIC3','PIC1','PIC6','PIC5','PIC3','PIC6','PIC2','PIC1','PIC6','PIC4','PIC3','PIC6','PIC5','PIC4','PIC2','PIC5','PIC6','PIC4','PIC5','PIC6','PIC2','PIC4','PIC3','PIC6','PIC1','PIC4','PIC6'],
        ['PIC6','PIC5','PIC3','PIC2','PIC5','PIC4','PIC1','PIC5','PIC6','PIC3','PIC4','PIC2','PIC5','PIC4','PIC6','SCAT','PIC2','PIC6','PIC3','PIC1','PIC4','PIC6','PIC5','PIC1'],
    ],

    winLevels: [
        { name: "normal1", threshold: 5 },
        { name: "normal2", threshold: 15 },
        { name: "normal3", threshold: 20 },
        { name: "bigwin", threshold: 35 },
        { name: "megawin", threshold: 70 },
        { name: "epicwin" }
    ],

    coinType1Values: [200, 1000, 5000, 10_000, 20_000, 100_000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000],

    coinType1_min_bet: 200,
    coinType1_max_bet: 100_000,
    coinType1_spin_max_bet: 900_000,

    coinType4_min_bet: 1,
    coinType4_max_bet: 2000,
    coinType4_spin_max_bet: 18_000,

    prizeWeights: {
        10: 1000,
        20: 1000,
        30: 1000,
        50: 1000,
        100: 700,
        250: 200,
        500: 70,
        1000: 20,
        2000: 6,
        5000: 3,
        10000: 1,
    },
};
