import { mathModel } from "../../../v3";
import { modelDefinition } from "../config";

/** Checks the slot window for a scatter win, returning the win cells and multiplier if found, or undefined otherwise. */
export function checkScatterWin(slotWindow: string[][]) {
  const cells = mathModel.filterSlotWindow(
    slotWindow,
    (sym) => sym === scatterSymbol
  );
  const tableIndex = slotWindow.length - cells.length;
  const multiplier =
    tableIndex < scatterWinMultipliers.length
      ? scatterWinMultipliers[tableIndex]
      : 0;
  return multiplier ? { cells, multiplier } : undefined;
}

const { scatterSymbol, winTable } = modelDefinition;
const scatterWinMultipliers =
  winTable.find((entry) => entry.symbol === scatterSymbol)?.multipliers ?? [];
