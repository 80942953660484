/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["94"],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = "", name = "Base") => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.field0 = 3;
            step.field1 = "PIC2,,";
            return step;
        },
        // Must implement
        createDefaultStep: (order = 0, variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        // example on creating custom indexes
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([27, 0, 19, 28, 0], false, order, "", "F-Trigger") ,
        getFeatureStep: (order = 0) => State.state.createStep([6, 4, 7, 54, 9], true, order, "", "F-Step") ,
        getFeatureReTriggerStep: (order = 0) => State.state.createStep([27, 0, 19, 28, 0], true, order, "", "F-Retrigger") ,
    };
}
