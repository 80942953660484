// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-content {
  background-color: #2a2a2a;
  height: calc(100vh - 100px);
  text-align: left;
  overflow: auto;
  font-family: Arial, Helvetica, sans-serif;
}
/* .sm-game-content .sm-in-content {
    /* padding: 0 10px 0 10px; */
/* } 
*/
.sm-game-content .sm-phaser-parent {
  width: 400px;
  height: 300px;
  background-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/game_content.vue.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,gBAAgB;EAChB,cAAc;EACd,yCAAyC;AAC3C;AACA;gCACgC;AAChC;CACC;AACD;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".sm-game-content {\n  background-color: #2a2a2a;\n  height: calc(100vh - 100px);\n  text-align: left;\n  overflow: auto;\n  font-family: Arial, Helvetica, sans-serif;\n}\n/* .sm-game-content .sm-in-content {\n    /* padding: 0 10px 0 10px; */\n/* } \n*/\n.sm-game-content .sm-phaser-parent {\n  width: 400px;\n  height: 300px;\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
