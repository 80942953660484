/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "vgw050",
        "wildSymbols": ["2x", "3x", "5x", "8x"],
        "freeSpinTrigger": "SCAT",

        "countToTriggerFreeSpin" : 3,

        "coinType1Values": [ 1250, 12500, 50000, 250000, 500000, 750000],
        "coinType4Values": [ 5, 15, 30, 100, 300, 600],

        "coinType1_min_bet": "1250",
        "coinType1_max_bet": "750000",
        "coinType1_spin_max_bet": "3750000",

        "coinType4_min_bet": "5",
        "coinType4_max_bet": "600",
        "coinType4_spin_max_bet": "3000",

        "reelsLayout": [3, 3, 3],

        "reels": [
            ["blank", "7bar", "blank", "scat", "blank", "7bar", "blank", "red7", "blank", "2x",   "blank", "scat", "blank", "3bar", "blank", "2bar", "blank", "3bar", "blank", "scat", "blank", "1bar"],
            ["blank", "3bar", "blank", "scat", "blank", "5x",   "3x",    "8x",   "2x",    "5x",   "blank", "scat", "blank", "red7", "blank", "2bar", "blank", "7bar", "blank", "scat", "blank", "1bar"],
            ["blank", "3bar", "blank", "scat", "blank", "7bar", "blank", "1bar", "blank", "scat", "blank", "2bar", "blank", "7bar", "blank", "red7", "blank", "7bar", "blank", "2x",   "blank", "7bar"],
        ],
        "featureReels": [
          ["blank", "7bar", "blank", "scat", "blank", "7bar", "blank", "red7", "blank", "2x",   "blank", "scat", "blank", "3bar", "blank", "2bar", "blank", "3bar", "blank", "scat", "blank", "1bar"],
          ["blank", "3bar", "blank", "scat", "blank", "5x",   "3x",    "8x",   "2x",    "5x",   "blank", "scat", "blank", "red7", "blank", "2bar", "blank", "7bar", "blank", "scat", "blank", "1bar"],
          ["blank", "3bar", "blank", "scat", "blank", "7bar", "blank", "1bar", "blank", "scat", "blank", "2bar", "blank", "7bar", "blank", "red7", "blank", "7bar", "blank", "2x",   "blank", "7bar"],
        ],

        "winTable": [
          {
            "symbol": "blank",
          }, {
            "symbol": "8x",
          }, {
            "symbol": "5x",
          }, {
            "symbol": "3x",
          }, {
            "symbol": "2x",
          },{
            "symbol": "7bar",
          }, {
            "symbol": "3bar",
          }, {
            "symbol": "2bar",
          }, {
            "symbol": "1bar",
          }, {
            "symbol": "scat",
          }
        ],

        "playLines" : [
            [1, 1, 1], // 1
            [0, 0, 0], // 2
            [2, 2, 2], // 3
            [2, 1, 0], // 4
            [0, 1, 2], // 5
            [1, 0, 1], // 6
            [1, 2, 1], // 7
            [2, 1, 2], // 8
            [0, 1, 0], // 9
        ],
    },
}
