/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes, trace } from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Base Game");
            step.internalName = "BaseGame";

            return State.state.addBaseGameDefaults(step, false);
        },
        getBaseStep: (isTreasureReel = false) => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", isTreasureReel ? "BG Treasure Reel" : "Base Game");
            step.internalName = isTreasureReel ? "BGTreasureReel" : "BaseGame";

            return State.state.addBaseGameDefaults(step, isTreasureReel);
        },
        addBaseGameDefaults: (step, isTreasureReel) => {
            step.isTreasureReel = isTreasureReel;
            step.reelSet = 1;

            // Respin Trigger Defaults
            step.monkeyPlacementFactor = false;
            step.monkeyReelPosition = 0;
            step.monkeyRowPosition = 0;
            step.respinReelSet = 1;

            // Freespin Trigger Defaults
            step.scatterPlacementFactor = false;
            step.scatterReelsPosition = [0, 1, 2];
            step.scatterRowPosition = [0, 1, 2];

            // Map Feature Settings
            step.mapSymbolFactor = false;
            step.mapSymbolCount = 1;
            step.mapSymbolReel = [0];
            step.mapSymbolRowPosition = [1];

            if (isTreasureReel) {
                step.reelsType = "reelsselection_6";
                step.fatSymbolId = [18, 19, 20, 21];
                step.fatSymbolReels = [0, 1, 4, 5];
            }
            else {
                step.reelsType = "reelsselection";
            }

            return step;
        },
        getFreeGameStep: (isTreasureReel = false) => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], true, 0, "", isTreasureReel ? "FG Treasure Reel" : "Free Game");
            step.internalName = isTreasureReel ? "FGTreasureReel" : "FreeGame";

            return State.state.addFreeGameDefaults(step, isTreasureReel);
        },
        addFreeGameDefaults: (step, isTreasureReel) => {
            step.isTreasureReel = isTreasureReel;
            step.reelSet = 1;

            // Free Game Defaults
            step.ghostyCaptainPlacementFactor = false;
            step.ghostlyCaptainReelsPosition = [0, 1, 2];
            step.ghostlyRowPosition = [0, 1, 2];

            // Freespin Trigger Defaults
            step.scatterPlacementFactor = false;
            step.scatterReelsPosition = [0, 1, 2];
            step.scatterRowPosition = [0, 1, 2];

            // Map Feature Settings
            step.mapSymbolFactor = false;
            step.mapSymbolCount = 1;
            step.mapSymbolReel = [0];
            step.mapSymbolRowPosition = [1];

            if (isTreasureReel) {
                step.reelsType = "reelsselection_6";
                step.fatSymbolId = [18, 19, 20, 21];
                step.fatSymbolReels = [0, 1, 4, 5];
            }
            else {
                step.reelsType = "reelsselection";
            }

            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        /***
         * Returns reels on the basis of game state type.
         * @param type
         */
        getReelStrips: (type, reelSet) => {
            return State.state.getModelDefinition()[type][reelSet].reel;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType, step.reelSet - 1);
            const noOfReels = step.isTreasureReel ? 6 : 5;
            const noOfRows = 3;

            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;

            return step;
        },
    };
}
