import Vue, { PropType } from "vue";
import { ScenarioStep } from "../math-model/choices";
import { MultiplierControls } from "./multiplier-controls";
import { NuttPrizeControls } from "./nutt-prize-controls";
import { CandyCaneControls } from "./candy-cane-controls";
import { StolenRoyalsControls } from "./stolen-royals-controls";
import { WildPathControls } from "./wild-path-controls";

export default Vue.component("ReelSpinFeatureControls", {
  components: {
    "multiplier-controls": MultiplierControls,
    "nutt-prize-controls": NuttPrizeControls,
    "candy-cane-controls": CandyCaneControls,
    "stolen-royals-controls": StolenRoyalsControls,
    "wild-path-controls": WildPathControls,
  },
  props: {
    step: Object as PropType<ScenarioStep>,
    reelSpinFeatureId: Number,
  },
});
