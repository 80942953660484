import { mathModel } from "../../../../v3";
import { modelDefinition } from "../../config";

type Cell = mathModel.Cell;

export function generateCandyCaneCells(
  shape: "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "TOP_RIGHT",
  midPoint: number,
  reel: number
): Cell[] {
  const cells: Cell[] = [];
  const startingPoint = midPoint - 2;
  const endingPoint = midPoint + 2;

  // Generate trunk
  for (let i = startingPoint; i <= endingPoint; i++) {
    if (i >= 0 && i <= 4) cells.push([i, reel]);
  }

  const shapeValues = modelDefinition.shapeMap[shape];
  const footReel = shapeValues.isLeft ? reel - 1 : reel + 1;
  if (
    (shapeValues.isLeft && footReel < 0) ||
    (!shapeValues.isLeft && footReel > 4)
  ) {
    return cells;
  }

  // Generate foot
  const footCellOneY = shapeValues.isTop ? midPoint - 1 : midPoint + 1;
  if (
    (shapeValues.isTop && footCellOneY < 0) ||
    (!shapeValues.isTop && footCellOneY > 4)
  ) {
    return cells;
  }
  cells.push([footCellOneY, footReel]);
  const footCellTwoY = shapeValues.isTop ? midPoint - 2 : midPoint + 2;
  if (
    (shapeValues.isTop && footCellTwoY < 0) ||
    (!shapeValues.isTop && footCellTwoY > 4)
  ) {
    return cells;
  }
  cells.push([footCellTwoY, footReel]);

  return cells;
}
