/* in order to support standalone, please insert the init response from the server here */
export default {
  success: true,
  messages: [],

  modelDefinition: {
    version: "1.0.0",
    modelId: "tf114",
    reelsLayout: [7, 7, 7, 7, 7, 7, 7],

    playLines: [],

    // prettier-ignore
    allPaylines: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49],

    // prettier-ignore
    reels : [
        ['lp2','lp1','hp1','lp3','hp1','hp1','lp2','lp2','wild','hp2','hp2','lp3','lp2','lp3','lp1','lp1','lp4','lp2','lp4','hp2','scatter','lp1','lp1','wild','lp3','hp1','hp1','lp2','lp3','wild','lp2','lp4','hp3','lp3','hp2','hp1','wild','lp1','lp1','hp2','hp1','wild','lp1','lp4','lp4','lp4','hp2','hp2','hp3','lp2','hp3','lp1','scatter','lp3','lp3','lp2','lp2','lp3','lp3','hp2','hp2','wild','lp3','hp2','hp1','hp1','hp3','lp2','lp1','lp4','hp2','lp3','hp2','lp1','hp2','hp1','lp2','hp1','hp2','lp3','lp3','hp3','hp3','hp3','hp1','lp4','scatter','lp1','lp2','hp1','lp3','lp4','lp2','hp1','hp3','hp2','hp1','lp4','hp1','lp1','hp1','lp3','lp3','hp2','lp4','lp3','lp3','lp1','lp2','lp1','lp2','hp1','lp2','lp2','lp1','scatter','lp2','lp2','lp4','lp4','lp4','hp1','hp1','hp1','hp3','lp2','lp2','lp1','lp4','lp4','hp1','hp3','scatter','lp2','lp2','lp4','hp1','lp4','lp2','lp2','lp4','lp1','lp1','lp2','wild','lp4','lp4','hp1','wild','lp3','lp4','hp1','hp1','wild','hp2','hp2','lp3','lp3','hp2','lp4','lp2','lp3','lp3','scatter','hp2','lp4','hp3','hp1','lp2','lp2','lp4','hp2','lp1','lp1','lp2','lp2','scatter','lp1','lp1','lp4','lp4','hp1','hp1','hp1','hp2','hp2','lp4','lp4','hp3','lp2','hp1','lp2','lp3','lp3','scatter','lp1','lp2','lp1','hp1','hp2','lp4','hp2','hp1','hp3','hp3','lp3','wild','lp1','lp1','hp3','lp3','lp3','lp4','hp2','lp3','lp1','lp1','lp3','scatter','lp1','lp2','lp3','lp3','lp1','lp1','hp2','hp1','lp4','lp4','wild','hp3','hp3','lp1','lp1','lp4','lp3','lp1','lp1','hp1','hp1','lp2','hp3','lp4','lp4','lp3','scatter','hp2','lp2','lp4','lp1','wild','hp3','lp4','hp2','lp2','lp3'],
        ['lp2','lp1','hp3','lp2','hp1','hp3','lp1','hp1','lp4','lp2','lp1','hp1','lp4','lp3','lp2','wild','lp3','lp1','hp2','hp3','lp3','lp2','hp1','scatter','lp1','hp2','hp1','lp2','hp1','lp1','lp1','lp3','hp2','lp1','lp4','lp2','lp3','hp2','hp2','lp1','lp2','wild','lp1','lp3','lp3','lp1','hp3','lp3','lp1','lp4','hp1','scatter','lp2','lp2','lp1','lp4','hp1','lp2','lp4','lp4','hp1','lp3','wild','lp4','lp3','lp1','lp2','lp4','hp2','hp1','lp2','wild','hp2','hp2','hp1','hp1','lp4','hp2','lp3','lp3','hp1','hp1','scatter','lp4','hp2','hp1','hp1','lp4','hp2','hp1','hp1','lp3','hp2','lp4','lp4','hp3','scatter','hp1','lp2','lp3','lp1','lp4','lp2','lp3','hp1','hp3','lp2','hp3','lp4','wild','hp3','hp3','lp3','hp1','hp1','lp3','hp1','hp1','hp1','hp2','lp4','scatter','lp4','lp4','lp3','hp2','lp4','wild','lp3','lp4','lp2','lp1','hp3','hp1','lp2','lp1','lp3','hp3','hp2','lp3','wild','hp1','lp2','lp1','hp1','lp4','lp4','scatter','lp4','hp1','wild','hp2','lp3','lp3','lp2','hp2','hp2','lp2','lp2','wild','lp1','lp3','lp3','lp4','lp2','lp1','lp1','lp4','lp3','lp3','lp2','lp1','hp2','lp1','hp1','lp1','lp1','hp1','scatter','lp2','hp3','hp2','hp2','hp2','lp1','hp3','hp3','lp1','lp1','lp2','hp1','hp2','lp4','lp1','lp1','hp3','lp2','lp4','hp3','hp2','wild','lp4','lp3','lp1','hp3','lp2','lp2','hp1','wild','lp1','lp4','lp1','lp4','lp4','lp4','hp2','lp2','lp2','lp3','hp1','hp1','lp3','lp2','lp3','scatter','lp2','lp4','lp4','lp1','lp2','lp4','lp3','wild','lp2','lp2','lp1','lp4','hp1','lp1','hp2','hp2','hp3','lp2','lp3','lp3','lp4','lp3','lp3','hp2','lp2','lp1','hp3','hp2','lp1','lp2','lp1'],
        ['hp3','lp2','lp2','hp2','wild','lp4','lp2','lp4','lp4','lp3','lp1','lp4','hp2','lp4','lp2','hp2','hp1','lp1','lp4','lp4','scatter','hp2','hp1','lp1','hp1','lp3','lp1','lp2','hp1','lp1','lp4','lp3','lp3','hp3','lp2','lp3','hp2','hp1','lp1','lp1','lp3','lp4','hp1','scatter','hp2','hp2','lp1','hp2','lp1','wild','hp1','hp1','hp3','lp2','lp1','lp1','lp3','lp2','hp1','hp2','lp2','lp2','lp3','lp2','wild','hp1','lp4','lp3','hp3','hp1','lp3','hp2','lp4','scatter','hp3','hp3','lp2','lp2','lp4','lp4','hp2','hp2','lp1','hp1','lp1','wild','lp4','hp1','lp1','hp1','lp3','hp3','lp3','lp3','hp3','hp3','lp3','lp3','hp3','lp3','wild','lp4','lp2','lp3','lp2','lp2','scatter','lp1','lp2','lp2','lp1','lp1','lp4','lp2','wild','lp4','lp2','lp4','lp1','lp2','lp2','lp1','lp1','hp2','wild','hp1','lp3','lp4','scatter','lp4','lp3','hp2','lp2','lp2','hp3','lp4','hp2','hp1','hp2','lp2','hp1','hp2','lp2','lp2','lp3','hp3','lp3','lp4','lp4','wild','lp3','lp4','scatter','lp4','lp1','lp1','lp4','hp3','hp3','hp2','hp2','hp1','lp4','lp2','lp3','lp3','lp4','hp3','lp3','lp2','lp1','lp3','lp3','hp1','lp2','lp1','lp1','hp1','hp1','hp2','lp2','lp2','lp1','lp1','lp2','hp1','lp3','wild','hp1','lp4','hp2','lp3','hp2','lp4','wild','lp1','hp2','lp3','lp3','lp1','lp1','wild','lp3','hp1','hp3','lp1','lp1','hp1','hp2','lp2','lp2','hp1','hp1','lp1','hp3','hp1','lp2','hp3','scatter','lp1','lp3','lp3','lp4','lp2','hp1','lp4','lp3','lp3','lp4','lp3','lp1','hp2','hp2','lp4','lp4','hp2','hp3','lp1','lp1','lp3','hp1','hp1','lp4','hp1','lp1','hp1','hp2','hp1','hp2','hp1','lp2','wild','lp1','lp4','lp4','hp1'],
        ['lp2','lp4','wild','lp1','lp3','lp3','hp2','lp2','lp4','hp1','lp2','lp2','lp4','hp3','hp1','lp2','lp1','wild','lp4','hp1','hp1','lp4','lp4','lp3','scatter','lp4','lp1','hp1','hp2','lp4','lp4','lp1','lp1','hp3','hp3','lp4','lp1','lp1','lp3','lp1','lp4','lp3','lp1','lp1','lp1','hp2','hp2','hp3','hp1','lp1','lp3','hp1','hp1','lp2','hp3','hp3','hp2','lp3','lp2','hp1','wild','lp1','lp2','wild','hp1','lp4','lp2','lp3','hp1','hp1','hp1','hp2','scatter','lp3','hp2','lp1','lp3','hp3','hp2','hp2','lp1','lp3','lp1','lp1','lp2','lp4','hp2','lp4','lp1','lp1','lp3','hp2','lp1','lp1','lp2','lp3','lp4','lp2','hp1','hp1','lp2','lp4','lp1','hp2','wild','lp4','lp2','hp2','lp2','hp3','hp1','wild','lp2','hp1','lp1','lp2','scatter','lp3','hp1','lp3','lp1','hp2','hp3','hp2','wild','lp2','lp4','lp2','hp1','lp2','lp3','wild','hp2','lp2','lp2','lp4','lp1','hp1','hp3','hp1','hp1','lp3','lp3','lp4','lp4','scatter','lp1','lp1','lp2','lp3','lp3','lp1','lp1','lp2','hp3','hp1','lp3','lp2','lp4','lp4','lp4','hp2','hp1','hp2','hp1','lp3','lp3','scatter','hp1','hp2','hp2','hp1','hp2','hp1','lp2','wild','lp3','lp2','hp1','lp4','lp4','hp3','lp3','hp3','lp4','wild','lp1','lp3','lp4','hp1','lp4','wild','lp1','hp1','hp2','lp3','hp2','lp2','lp4','hp1','scatter','lp1','lp1','lp3','hp3','hp3','lp4','lp4','lp1','hp3','hp2','lp4','lp2','lp2','lp4','lp3','lp3','lp2','lp2','hp2','lp3','lp2','hp2','lp3','hp1','lp3','hp3','lp2','hp3','lp1','lp2','hp1','lp2','scatter','lp4','lp2','lp3','hp1','hp2','lp1','lp2','lp3','wild','lp4','lp3','lp4','lp1','hp2','lp1','lp4','lp3','hp1','lp3','hp2','lp1','hp3'],
        ['hp3','hp3','lp3','hp2','wild','lp1','hp1','lp3','lp1','hp1','lp2','lp3','lp4','hp3','hp1','lp3','lp4','lp2','lp3','lp1','lp4','lp4','lp4','lp3','lp1','lp2','lp3','lp1','lp3','lp1','lp3','wild','hp2','hp1','wild','hp2','lp3','lp4','lp2','hp3','hp1','lp1','lp2','scatter','lp2','hp2','lp3','hp1','lp3','hp1','lp4','wild','hp2','hp2','hp1','lp1','lp3','hp2','lp1','lp1','hp1','lp4','hp2','lp2','hp1','lp4','hp1','hp2','lp3','lp3','lp4','lp3','hp1','hp2','lp1','lp4','lp2','hp1','lp1','hp2','hp2','wild','lp1','lp2','hp3','lp1','lp1','lp3','lp4','lp1','lp1','hp2','hp1','wild','lp1','lp2','hp2','lp4','lp4','hp2','hp3','lp4','hp1','lp4','lp1','lp4','hp2','lp1','lp2','lp2','wild','lp3','lp2','lp1','lp1','lp3','scatter','lp2','lp4','hp3','lp4','lp4','lp2','hp1','hp1','lp4','lp3','lp3','hp3','lp3','lp2','lp1','lp1','lp2','hp3','hp1','hp1','hp1','hp3','lp2','lp2','lp3','lp4','lp4','hp2','hp3','lp2','hp1','lp4','wild','hp1','lp4','hp3','lp2','scatter','hp3','lp2','hp1','hp3','lp1','wild','lp2','hp2','hp2','lp2','lp4','lp3','lp3','lp2','lp4','lp1','lp4','hp3','hp3','hp3','lp1','lp2','lp1','lp2','lp3','lp3','hp1','lp2','lp4','wild','lp2','lp1','scatter','lp3','hp1','hp2','lp2','hp1','lp1','lp1','lp4','hp1','hp1','lp1','lp1','lp3','hp1','lp1','wild','lp3','hp2','lp1','hp1','lp3','lp2','hp2','lp3','hp2','lp2','hp2','lp1','wild','lp4','lp2','lp3','hp1','hp1','lp1','lp3','lp4','hp2','scatter','hp3','hp1','lp2','hp2','lp1','lp2','lp4','hp3','lp4','lp1','hp2','lp4','lp2','lp3','lp2','lp4','hp1','lp2','lp2','lp4','lp4','scatter','hp2','lp1','lp3','hp1','hp1','lp3','hp2'],
        ['lp3','hp2','lp2','lp1','hp1','hp1','wild','lp2','hp2','hp2','hp2','hp1','lp3','lp1','lp1','hp1','lp3','lp4','lp2','lp4','lp1','lp2','lp2','hp3','lp4','lp4','wild','lp2','hp1','hp2','hp1','lp2','hp2','hp2','lp4','hp3','hp3','hp3','hp2','lp2','lp2','lp2','lp1','wild','lp3','lp4','hp2','hp1','lp3','lp3','lp3','lp2','lp4','lp2','scatter','hp3','hp1','hp1','hp1','hp2','lp2','lp4','lp3','lp4','hp2','lp3','lp1','lp1','lp1','lp3','lp1','hp3','lp1','lp1','lp3','lp3','lp1','lp1','lp2','lp3','lp1','lp4','lp2','lp2','lp1','hp3','lp3','lp3','lp2','lp4','lp4','hp1','lp3','lp4','hp1','hp1','lp4','lp3','lp3','lp2','lp1','hp3','lp2','lp1','hp2','hp2','hp3','lp1','lp2','lp2','scatter','lp1','lp4','hp3','lp1','lp3','lp3','lp1','hp1','hp1','hp1','lp1','lp4','lp2','hp1','wild','lp3','hp2','lp2','lp3','lp3','hp3','lp2','lp2','scatter','lp4','lp2','lp1','lp1','hp1','lp1','hp1','lp3','wild','lp4','lp2','lp4','lp1','hp2','hp1','lp4','hp1','hp3','lp3','lp2','lp3','hp1','lp3','hp1','wild','lp1','lp4','lp4','lp2','hp1','hp1','lp1','hp2','hp3','scatter','lp4','lp4','lp1','lp1','hp2','hp1','hp3','lp3','lp3','hp2','wild','hp3','hp2','lp4','lp1','lp2','lp1','lp2','wild','hp2','hp2','lp1','lp1','lp3','hp2','lp4','lp2','lp2','lp1','lp4','lp4','hp2','hp1','scatter','lp3','lp4','hp1','wild','hp3','lp4','lp1','hp2','lp3','hp3','hp2','wild','hp3','lp4','hp1','lp2','lp2','scatter','lp1','lp3','lp2','lp1','lp4','wild','hp2','hp1','lp3','hp1','lp4','lp3','lp2','lp3','lp4','hp3','wild','lp1','hp1','lp4','scatter','hp2','hp1','lp4','lp1','hp1','hp2','hp1','lp2','lp3','hp1','lp3','hp2','lp4'],
        ['lp2','lp4','lp3','lp1','lp3','lp1','lp4','lp3','lp1','hp2','hp2','hp1','hp1','hp1','lp4','hp2','lp4','lp4','hp3','scatter','lp1','hp2','wild','hp1','lp4','lp4','hp2','hp3','lp3','lp3','hp2','lp2','hp3','hp3','lp2','lp2','lp1','lp1','lp4','lp1','lp4','lp4','lp3','lp1','hp3','hp2','hp2','wild','lp3','lp1','hp1','lp4','wild','lp2','lp2','hp3','hp1','lp1','lp4','hp1','lp1','hp3','lp4','lp4','hp1','lp4','lp3','lp2','wild','lp4','hp1','hp1','wild','lp1','hp2','lp4','lp4','lp2','hp2','lp1','lp1','lp2','lp2','lp4','lp4','lp1','lp1','lp2','lp2','lp4','lp4','hp2','lp1','lp1','lp2','hp3','lp2','lp1','hp1','lp3','lp3','wild','hp1','lp3','lp1','lp2','lp2','hp3','lp1','lp1','hp2','lp4','scatter','hp1','lp2','hp1','hp1','hp1','lp3','hp1','lp4','hp2','lp4','lp3','lp3','lp4','lp1','hp1','lp4','wild','lp3','lp1','lp1','lp2','lp3','lp2','lp1','hp3','wild','lp2','lp2','lp1','hp1','lp3','lp4','lp2','hp1','hp3','scatter','hp2','hp2','hp1','hp3','hp1','wild','lp3','hp3','hp1','hp2','lp4','lp2','hp3','lp2','hp1','wild','hp3','lp2','lp2','lp4','hp1','hp2','hp3','lp3','lp2','hp2','lp2','lp1','hp3','lp3','lp3','lp2','lp3','hp1','lp3','lp2','lp1','lp3','hp2','hp2','hp2','hp1','hp1','lp3','hp1','scatter','lp4','lp2','hp1','lp3','wild','hp2','hp3','lp1','lp3','lp2','lp2','lp3','lp2','lp2','hp2','hp2','hp2','lp2','lp3','scatter','hp2','hp2','lp3','lp3','hp3','hp1','wild','lp1','lp4','lp4','lp3','lp2','lp3','lp2','hp2','hp1','lp4','lp3','lp1','lp1','lp4','lp4','hp1','lp4','lp1','lp2','lp3','scatter','hp2','lp1','lp1','lp3','lp1','hp1','lp3','lp3','hp1','lp2','hp1','lp1','lp1'],
    ],

    // prettier-ignore
    featureReels: [
        ['lp1','lp4','hp1','lp1','hp2','lp2','lp3','lp1','lp3','lp1','hp2','lp3','lp2','hp2','lp2','lp4','hp1','wild','hp2','lp4','lp4','hp2','hp1','hp3','hp3','lp1','lp4','hp2','lp2','hp1','lp2','lp1','lp3','lp2','lp4','lp2','lp4','lp4','lp2','lp2','lp3','hp2','lp4','hp1','wild','lp4','lp1','lp4','hp3','lp2','lp4','lp2','lp3','hp1','lp1','lp2','lp1','hp1','lp2','lp2','lp4','hp1','lp3','lp2','lp1','lp4','lp3','lp3','lp2','hp1','hp1','lp1','lp1','lp4','lp3','lp3','lp3','lp2','lp3','wild','hp1','hp1','lp1','lp4','lp2','lp3','hp1','hp1','lp3','hp1','lp2','hp2','hp2','hp2','lp4','lp1','lp1','lp3','lp3','lp3','lp2','hp1','lp4','lp4','lp2','hp1','lp4','lp2','lp4','lp3','lp1','lp4','lp2','lp4','lp1','hp3','hp3','hp2','hp2','hp3','hp3','lp2','lp3','lp2','lp1','lp2','hp3','hp3','lp1','lp3','lp2','lp4','lp1','hp1','hp1','lp4','lp2','lp2','lp3','lp1','lp2','lp3','lp4','lp3','lp3','lp4','lp2','lp4','lp1','lp1','lp3','lp4','lp3','lp3','lp1','lp1','lp3','hp2','hp2','lp1','lp4','lp2','wild','lp3','hp3','lp3','lp2','lp4','lp4','hp3','lp3','hp2','hp3','hp3','lp1','wild','lp3','hp2','hp2','lp1','lp4','lp2','lp2','hp3','lp2','lp2','hp3','hp3','hp1','lp1','lp1','hp2','hp2','wild','hp1','hp1','lp2','lp4','lp3','lp2','lp1','lp4','lp4','hp3','hp3','lp3','lp3','lp1','lp2','hp1','hp1','lp4','lp4','lp4','hp1','lp1','hp3','hp3','wild','lp3','lp3','hp2','lp2','lp1','lp1','hp2','lp1','hp1','hp1','hp2','hp2','hp1','lp1','lp1','hp2','hp2','lp4','lp3','hp3','hp3','lp4','lp2','hp1','hp1','lp3','lp1','lp1','lp4','lp3','hp2','lp3','lp1','lp2','lp2','lp3','hp1'],
        ['lp3','hp2','lp2','lp3','lp3','hp1','lp3','lp4','lp1','lp2','lp4','hp2','lp4','lp3','lp4','hp3','hp2','hp1','lp3','wild','hp1','lp1','lp4','lp2','lp2','lp4','lp2','lp4','lp3','lp2','hp2','lp2','lp4','lp4','lp2','lp1','lp1','lp3','lp4','lp1','hp1','hp2','hp2','lp3','hp3','lp3','lp1','lp3','lp4','hp1','hp3','lp2','lp2','lp4','hp1','lp4','lp4','hp1','lp1','lp1','hp3','lp2','lp2','lp3','wild','lp4','lp4','hp2','hp2','lp3','hp3','hp3','lp2','lp3','lp2','lp2','lp3','lp1','lp2','lp2','lp1','hp2','lp4','lp3','lp2','wild','hp2','lp2','hp1','lp2','hp2','lp1','lp1','lp1','hp2','hp2','lp3','hp1','lp1','lp1','lp2','lp4','lp2','lp4','hp1','lp4','lp3','hp1','hp2','lp4','lp4','hp3','hp3','hp2','hp2','hp3','hp3','lp2','lp2','lp2','hp1','hp1','lp1','lp3','lp4','lp2','lp1','lp1','lp4','lp2','lp1','lp1','lp1','lp2','lp3','lp2','lp3','lp2','lp3','lp2','hp2','hp2','hp1','lp4','lp3','hp1','lp2','hp1','hp1','lp1','lp4','lp3','lp1','lp2','hp1','lp3','lp4','wild','lp2','lp2','lp3','lp3','hp3','hp3','lp3','lp3','hp2','hp2','lp1','lp4','lp2','lp3','lp1','lp1','lp4','hp2','lp3','lp4','hp3','hp3','lp2','hp2','hp2','lp3','lp3','lp4','lp4','hp1','hp1','lp3','lp4','hp1','lp1','hp1','hp2','lp2','lp2','lp2','hp1','hp1','lp1','lp1','lp1','hp1','lp1','lp1','wild','lp4','lp4','hp1','lp4','lp4','hp3','lp2','lp2','lp4','lp4','lp3','hp3','hp3','hp1','lp3','lp1','lp1','hp1','hp2','lp3','lp4','hp1','lp3','lp4','lp4','lp1','wild','lp3','lp3','lp1','lp3','lp1','lp1','lp3','lp1','lp3','lp3','hp2','hp1','lp1','lp1','lp3','lp1','hp2','hp3','hp3','hp1','lp2','lp1'],
        ['wild','hp2','hp1','hp1','lp3','lp1','lp1','lp4','lp2','lp3','lp4','lp2','hp1','lp3','hp3','hp2','lp2','wild','hp2','lp3','lp1','lp1','hp1','hp2','hp2','hp3','lp2','hp1','lp3','lp1','lp1','lp2','lp4','hp2','lp4','lp4','lp3','lp3','lp2','lp4','hp1','lp3','hp3','hp2','lp4','hp1','lp3','hp2','hp1','lp2','lp3','lp4','lp4','hp1','lp4','hp2','lp3','hp3','hp1','hp1','lp2','hp1','hp1','lp2','hp1','lp3','lp1','lp1','lp3','wild','lp4','hp3','hp3','wild','lp2','hp1','lp4','hp2','hp2','hp1','hp1','lp3','lp2','lp4','lp4','lp2','lp2','lp2','lp2','lp1','lp1','lp1','hp2','hp2','lp1','lp4','lp2','lp1','hp3','wild','lp1','lp4','lp3','lp4','lp4','lp1','lp1','lp2','lp2','hp2','hp2','hp1','hp1','lp1','hp3','hp3','lp1','lp1','hp2','lp3','lp4','lp3','lp1','lp2','lp1','hp1','wild','lp2','lp1','lp2','lp2','lp1','lp3','lp4','lp2','hp3','hp3','lp4','lp3','lp2','lp2','lp3','lp4','lp4','hp1','lp4','lp3','lp3','lp1','lp3','lp1','hp2','lp4','lp2','lp1','hp2','lp3','hp2','lp4','lp1','lp1','lp2','lp2','lp3','hp2','lp2','lp4','lp3','lp1','hp1','lp4','hp1','hp1','lp3','lp3','lp2','hp1','hp1','lp3','lp3','lp2','lp1','lp4','lp4','lp2','lp2','lp2','lp3','lp4','hp1','lp1','lp1','lp2','lp4','lp1','lp1','lp1','lp2','lp2','hp1','lp2','lp3','hp2','lp4','lp3','lp1','lp3','lp3','lp4','lp2','hp1','lp1','lp1','lp4','lp1','lp4','lp4','lp2','lp4','hp3','hp3','hp2','lp3','lp3','lp4','lp2','wild','hp2','hp2','hp3','hp3','lp3','lp3','lp3','lp4','hp2','lp2','lp2','hp2','lp3','lp4','hp1','lp1','hp3','hp3','lp1','lp4','hp3','hp3','lp1','lp3','lp1','lp3','lp1','hp1','lp2'],
        ['hp2','lp1','lp3','lp2','hp1','lp1','lp2','lp4','lp3','hp1','lp4','lp1','hp3','hp3','lp2','lp3','lp3','wild','hp1','lp4','lp3','lp3','hp2','lp2','lp3','lp3','lp4','lp3','lp1','lp1','lp4','lp4','lp2','lp2','wild','hp2','hp3','hp3','lp3','lp1','lp1','hp1','lp3','lp3','hp1','lp4','lp2','lp1','lp1','hp1','lp2','lp2','hp2','lp1','lp2','lp2','hp2','wild','lp2','lp1','hp1','hp2','lp1','hp1','lp1','lp4','hp2','lp4','lp1','hp3','lp3','lp4','lp3','lp3','hp3','lp1','lp1','lp2','hp2','hp2','lp1','lp4','hp3','hp3','hp1','lp4','lp1','lp2','lp2','hp1','hp1','lp4','hp1','hp2','hp2','hp3','lp3','hp2','hp1','lp1','lp4','lp3','lp4','lp1','lp1','lp3','hp2','lp1','lp3','lp1','lp4','lp2','lp2','lp1','lp3','lp3','lp4','lp4','lp2','hp1','lp1','lp3','wild','hp2','hp3','hp3','lp4','lp2','lp2','lp4','lp4','lp3','lp1','lp3','lp1','lp1','hp3','hp3','hp1','lp2','lp2','lp1','lp2','hp2','hp1','hp1','lp2','lp3','lp2','hp1','hp1','lp3','lp3','lp3','lp3','lp1','lp4','hp2','lp4','lp2','lp4','lp3','lp3','lp4','lp2','lp2','lp4','lp1','hp3','hp3','hp2','hp2','lp3','lp1','lp1','lp1','lp4','lp4','hp1','hp2','hp2','lp2','lp2','lp1','lp2','lp3','lp1','lp2','lp2','lp3','lp1','lp1','lp4','lp4','hp1','hp1','lp2','lp3','wild','hp3','lp2','hp1','lp3','hp1','hp2','lp4','lp3','hp1','lp1','lp1','lp4','lp4','lp4','lp4','hp1','lp3','lp4','hp1','lp4','lp3','lp2','hp3','lp3','lp2','lp4','hp2','hp1','lp1','hp2','lp2','lp4','lp1','lp2','lp2','lp3','hp2','hp3','hp1','lp3','hp3','lp3','lp4','hp1','lp2','lp3','lp1','hp1','hp2','lp3','hp2','lp3','wild','lp4','hp2','lp2','lp2'],
        ['lp2','lp3','lp1','hp2','lp4','lp4','lp2','hp2','hp1','lp1','lp3','lp1','lp4','hp1','lp3','hp1','wild','lp3','lp2','lp3','lp2','hp2','lp1','lp1','lp3','lp1','lp3','lp3','hp2','hp2','lp3','lp1','lp1','lp2','lp3','hp3','hp3','lp2','lp1','lp4','hp1','lp4','hp1','lp2','wild','lp3','lp3','lp2','lp2','hp1','lp4','lp2','hp2','hp1','lp1','hp3','hp3','lp3','hp2','hp2','lp2','lp2','lp4','lp2','lp4','lp4','lp2','lp4','hp3','hp3','lp3','lp3','lp3','lp1','lp1','lp3','lp3','lp4','lp2','lp1','hp1','hp1','lp1','lp3','lp1','lp2','lp4','hp1','hp1','lp2','hp2','hp2','hp1','hp1','lp4','lp4','hp3','hp3','hp1','lp1','lp1','hp1','lp2','lp2','wild','hp2','hp3','lp3','lp4','lp4','lp3','lp3','hp1','hp1','lp1','lp3','lp1','lp1','lp2','lp2','hp1','hp3','hp3','lp1','hp1','hp1','lp1','lp3','lp1','lp3','lp3','hp2','wild','hp1','lp4','hp2','hp2','lp2','hp3','hp3','lp3','lp1','hp2','hp2','lp4','lp4','lp2','lp2','lp1','lp1','lp1','lp3','lp4','hp1','lp3','lp2','lp4','lp3','lp1','lp1','lp3','lp2','lp1','hp1','hp1','lp4','lp3','lp1','lp2','lp3','lp1','hp1','lp3','lp3','lp1','lp3','lp3','lp4','lp4','hp1','hp3','wild','hp2','lp2','lp4','lp4','lp2','lp2','hp3','hp3','lp4','lp2','lp2','lp3','lp3','lp4','lp2','lp2','lp3','lp3','lp1','lp1','lp1','lp2','lp2','lp4','lp3','hp2','hp1','lp1','hp2','hp3','wild','hp1','hp2','hp2','lp1','lp1','hp2','hp1','lp2','lp1','lp4','lp4','lp2','lp4','hp2','lp3','lp4','lp4','lp2','lp4','lp4','lp1','lp2','hp2','lp2','lp2','lp4','lp2','lp4','lp3','lp1','lp1','hp2','hp2','lp3','lp4','hp3','lp4','lp4','lp3','lp2','hp3','lp3','lp4'],
        ['lp4','lp3','hp1','lp2','lp4','lp2','hp2','lp4','lp1','lp4','lp3','lp3','lp4','lp2','lp2','hp2','wild','hp3','lp3','hp2','lp2','lp1','hp1','hp1','lp3','lp2','hp3','hp1','lp1','hp3','hp2','hp2','lp3','lp3','hp1','lp2','hp2','lp4','hp3','hp2','lp4','hp2','hp1','lp1','lp1','lp2','lp1','hp2','lp4','lp1','hp2','hp2','hp1','lp2','lp3','hp1','lp4','hp1','wild','lp4','lp4','lp4','lp2','lp1','lp1','lp3','lp1','lp3','lp3','hp1','lp1','lp3','lp2','lp3','hp1','lp3','lp1','lp1','lp3','wild','hp2','lp1','lp4','hp3','hp3','lp4','lp3','lp4','lp4','lp4','hp1','hp2','lp4','lp1','hp2','lp4','hp1','hp1','lp1','hp1','lp1','lp1','lp2','hp2','hp3','hp3','hp2','hp1','lp1','lp4','lp4','lp3','lp3','lp2','hp2','hp1','lp2','lp2','lp1','lp3','lp3','wild','hp3','hp3','lp3','lp3','lp1','hp2','lp2','lp4','hp1','lp4','hp2','lp2','hp1','hp1','lp2','hp2','hp2','lp4','lp2','lp4','lp2','hp1','hp1','lp3','wild','lp1','lp3','lp2','lp2','lp3','lp4','lp4','lp2','lp3','lp3','lp1','lp1','lp2','lp3','hp3','wild','hp1','lp3','lp2','lp3','hp1','lp1','lp1','lp4','hp1','hp1','lp4','lp2','lp3','lp2','lp2','lp2','lp4','lp4','lp4','lp3','lp3','lp1','lp1','lp3','lp2','lp2','lp1','lp1','lp1','hp3','hp3','lp3','lp2','lp4','lp4','hp1','lp2','lp2','lp3','hp3','hp3','lp2','lp3','lp1','hp2','lp4','lp3','lp3','lp2','lp2','lp4','hp3','hp2','lp4','hp1','lp1','lp1','lp2','hp3','hp2','lp3','wild','hp1','lp2','lp1','lp4','lp4','lp2','lp1','lp3','lp2','lp4','lp3','hp1','hp1','lp2','hp3','hp3','lp1','lp3','lp1','lp1','lp1','lp4','lp3','lp1','lp3','lp4','lp1','hp2','lp1','lp1','hp2'],
        ['lp4','hp1','lp3','hp3','lp4','lp3','lp3','lp1','lp2','lp3','lp1','lp1','lp1','lp2','lp3','lp1','lp3','lp2','lp2','hp2','hp2','hp1','lp1','lp1','hp1','lp2','hp2','hp2','lp4','lp4','lp4','hp2','lp2','lp1','lp2','lp3','lp3','hp2','lp3','lp4','lp4','lp2','hp3','lp4','hp1','hp2','wild','hp3','lp1','lp1','lp1','hp1','wild','lp1','lp2','lp3','lp3','lp2','hp1','lp4','lp4','hp2','lp1','lp4','lp3','lp3','hp1','hp3','lp1','hp2','lp4','lp1','hp2','hp1','lp4','lp3','lp4','hp2','hp2','lp1','lp4','lp2','lp1','lp4','lp2','lp2','hp2','lp4','lp1','lp3','lp3','lp3','hp3','hp3','lp4','lp2','hp1','hp1','lp4','hp3','hp3','lp4','lp4','lp1','hp1','hp1','lp4','lp1','hp2','hp2','lp2','lp4','lp4','lp3','lp3','hp1','lp1','lp1','lp4','hp2','hp2','lp1','hp3','hp3','lp3','lp4','lp1','lp2','lp2','lp1','lp3','lp3','lp4','lp2','lp1','lp4','lp2','lp4','hp1','lp3','lp1','lp4','lp2','lp2','hp1','hp1','hp3','hp3','lp4','lp2','lp2','lp2','wild','hp3','hp3','hp1','lp4','lp3','lp3','hp1','hp1','lp2','hp2','lp1','lp4','lp3','hp1','hp1','lp2','lp2','lp1','lp1','hp2','lp2','lp2','hp3','hp1','lp1','lp1','lp3','lp2','lp3','hp2','wild','hp3','hp1','lp1','hp3','lp2','lp1','lp3','hp1','lp4','lp4','lp3','hp2','hp2','lp2','hp1','lp2','lp2','lp2','lp3','lp3','lp3','hp1','lp3','lp3','hp2','lp1','lp2','hp2','lp3','lp3','lp2','wild','hp3','lp4','lp1','hp1','hp1','lp3','lp2','lp3','lp1','lp2','hp2','lp3','lp3','lp2','lp3','lp1','lp1','lp2','hp2','lp4','lp4','lp4','lp3','lp3','hp1','lp1','lp1','lp4','lp2','lp4','lp4','lp3','lp1','lp1','hp1','lp2','hp2','wild','lp2','hp3'],
    ],

    mega: {
      emptySymbol: ".",

      triggerCascades: [2, 5, 9],
      initialTriggerCascadeForFreespin: [2, 5, 9, 16],
      visibleCascadeMax: 10,

      levels: [
        {
          wildMulti: 1,
          symbol: "wild",
          reelSet: [
            "..*...............*.........*...*...........*..........*.......*",
            "......*...*...........*..............*..........*...*.....*.....",
            "..............*..................*.......*....*...*....*......*.",
            ".....*...............*....*.........................*.......*...",
            "..............................................*.........*.......",
            "..*........*............*........*....*............*........*...",
            ".......*........*...........*..*...*......*.....*....*...*.....*",
          ],
        },
        {
          wildMulti: 2,
          symbol: "wild2x",
          reelSet: [
            ".............aa............bb.....cc.....dd.................ee..",
            ".............aa............bb.....cc.....dd..........ii.....ee..",
            "ff....gg............hh.........................jj....ii.........",
            "ff....gg............hh........kk...............jj...............",
            "..........ll..................kk....mm..........................",
            "...nn.....ll.....oo.....pp..........mm.....qq.....rr.....ss.....",
            "...nn............oo.....pp.................qq.....rr.....ss.....",
          ],
        },
        {
          wildMulti: 3,
          symbol: "wild3x",
          reelSet: [
            "..............aaa.................bbb......................ccc..",
            "..ddd.........aaa.................bbb..........fff.........ccc..",
            "..ddd.........aaa..........eee....bbb..........fff.........ccc..",
            "..ddd......................eee..........iii....fff..............",
            "........ggg.........hhh....eee..........iii..........jjj........",
            "........ggg.........hhh.................iii..........jjj........",
            "........ggg.........hhh..............................jjj........",
          ],
        },
      ],

      featureLevels: [
        {
          wildMulti: 1,
          symbol: "wild",
          reelSet: [
            "..*.....*.........*......*..*...*...........*..........*.......*",
            "......*...*...........*.......*......*..........*...*.....*.....",
            ".*............*.........*........*.......*...*................*.",
            ".....*..*..................*..*.....*......*...*......*....*....",
            "*..............*.....*..................*.........*.....*.......",
            "..*........*.......*....*........*....*......*..............*...",
            ".......*........*.....*.....*..*...*......*.....*....*.........*",
          ],
        },
        {
          wildMulti: 2,
          symbol: "wild2x",
          reelSet: [
            "...aa....bb.............cc............ee....ff......gg....hh....",
            "...aa....bb.............cc...dd.......ee....ff......gg....hh....",
            ".............ii..............dd...kk............mm............oo",
            "......pp.....ii......jj...........kk.....ll.....mm.....nn.....oo",
            ".qq...pp.............jj...ss.............ll............nn.......",
            ".qq.............rr........ss...tt....uu......vv....ww......xx...",
            "................rr.............tt....uu......vv....ww......xx...",
          ],
        },
        {
          wildMulti: 3,
          symbol: "wild3x",
          reelSet: [
            "..............aaa.....bbb.................ccc.......ddd.........",
            "..............aaa.....bbb........ggg......ccc.......ddd......eee",
            "........fff...aaa.....bbb........ggg......ccc.......ddd......eee",
            "........fff................jjj...ggg...........lll...........eee",
            "..hhh...fff.......iii......jjj........kkk......lll......mmm.....",
            "..hhh.............iii......jjj........kkk......lll......mmm.....",
            "..hhh.............iii.................kkk...............mmm.....",
          ],
        },
        {
          wildMulti: 4,
          symbol: "wild4x",
          reelSet: [
            "...................................aaaa.........bbbb............",
            "..........cccc..dddd...............aaaa.........bbbb........hhhh",
            "..........cccc..dddd..eeee.........aaaa..gggg...bbbb........hhhh",
            "...iiii...cccc..dddd..eeee..ffff...aaaa..gggg...bbbb..jjjj..hhhh",
            "...iiii...cccc..dddd..eeee..ffff.........gggg.........jjjj..hhhh",
            "...iiii...............eeee..ffff.........gggg.........jjjj......",
            "...iiii.....................ffff......................jjjj......",
          ],
        },
      ],
    },
  },
};
