import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 5;
    public reel = "";
    public isOpen = false;
    public forcedJackpot = "no";

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }

    public addFSTrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFSTrigger() );
    }

    public addFSStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFSStep() );
    }

    public updateJackpotForceOption(forcedJackpot) {
        this.step.json.forcedJackpot = forcedJackpot;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}