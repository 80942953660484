/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([11, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureReTriggerStep: () => {
            const step = State.state.createStep([39, 0, 16, 0, 39], true, 0, "", "F-Retrig");
            return step;
        },
        getFeatureStep: () => {
            const step = State.state.createStep([8, 0, 0, 0, 0], true, 0, "", "F-Step");
            return step;
        },
        getFeatureTrigger: () => State.state.createStep([13, 0, 6, 0, 27], false, 0, "", "F-Trig"),
        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips() : State.state.getReelStrips();
            return reels;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReels(step);
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            return step;
        },

        addExtraDataToStep: (step) => {
            return step;
        }
    };
}
