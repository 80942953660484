import Vue from "vue";
import Component from "vue-class-component";
import ReelVgw088 from "./reel.vue";
import { Prop, Watch } from "vue-property-decorator";
import {
  Vgw088StepJson,
  getFreeSpinTriggerFromBaseGame,
  getSnowBlastTrigger,
  getWheelSpinStep,
  getFreeSpinStep,
  JackpotType,
  getFreeSpinTriggerFromFreeSpinGame,
  getJackpotSpinStep,
  createClearingStep,
  getBaseStep
} from "../../../../state/models/slots/vgw088/steps";
import { getReelWindowFromIndex, trace } from "./../../../../helpers/helpers";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import State from "../../../../state/state";
import { modelDefinition } from "../../../../state/models/slots/vgw088/model-definition";
import { SnowBlastLevel, numberOfWildsBySnowBlastLevel, snowBlastLevelUpgradeBySpin, wheelPrize } from "../../../../state/models/slots/vgw088/constant";

const originatorId = new Date().getTime();

@Component({
  components: {
    reel: ReelVgw088,
  },
  data: () => {
    return {};
  },
})
export default class Content extends Vue {
  @Prop() public step: Vgw088Step | undefined;
  public show = false;
  private allSteps: Vgw088Step[] = [];
  @Prop() private wheelPrizeValue: string | undefined;

  @Watch("wheelPrizeValue")
  public wheelPrizeValueStep(wheelPrizeValue) {
    const currentWheelPrizeValue = this.getPrizeValue();
    if (wheelPrizeValue && wheelPrizeValue !== currentWheelPrizeValue) {
      this.setPrizeValue(wheelPrizeValue);
    }
  }

  @Watch("step")
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }
    this.show = true;
    this.wheelPrizeValue = this.getPrizeValue();
  }

  public mounted() {
    trace("reels mounted");
    bus.$on(events.UPDATE_STEPS, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
  }

  reloadStepsFromScenario(scenario?: {
    steps: Array<{ test_scenario_step_id: number; json: Vgw088StepJson }>;
  }) {
    const newSteps: Vgw088Step[] = [];
    for (const incomingStep of scenario?.steps ?? []) {
      const existingStep = this.allSteps.find(
        (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
      );
      const step =
        existingStep && incomingStep.json.originatorId === originatorId
          ? existingStep
          : incomingStep;
      newSteps.push(step);
    }

    // Traverse all steps, ensuring the state transitions are all valid and correct.
    if (newSteps.length > 0) {
      const isModified = this.updateStepsInPlace(newSteps);

      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    }
  }

  currentStep(): Vgw088Step | undefined {
    if (!this.step || !this.allSteps) return;
    return this.allSteps.find(
      (s) =>
        this.step && s.test_scenario_step_id === this.step.test_scenario_step_id
    );
  }

  finalStep(): Vgw088Step | undefined {
    return this.allSteps[this.allSteps.length - 1];
  }

  canAddBaseSpinStep(): boolean {
    return !this.finalStep()?.freeSpinCount && !this.finalStep()?.wheelSpinCount;
  }

  canAddWheelSpin(): boolean {
    const step = this.finalStep();
    return !!this.getWheelSpinRemaining() && step?.json.wheelSelection?.type !== "JACKPOT";
  }

  canAddJackpotSpin(): boolean {
    return this.finalStep()?.json.wheelSelection?.type === "JACKPOT"
  }

  canAddFreeSpinOrRetrigger(): boolean {
    return !this.getWheelSpinRemaining() && !!this.getFreeSpinRemaining() && this.finalStep()?.json.wheelSelection?.type !== "JACKPOT";
  }

  public addFreespinTriggerFromBaseStep() {
    const state = State.state;

    bus.$emit(events.ADD_STEP, getFreeSpinTriggerFromBaseGame());
  }

  public addBaseStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, getBaseStep());
  }

  public addBaseSnowBlastStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, getSnowBlastTrigger());
  }

  public addWheelSpinStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, getWheelSpinStep());
  }

  public addJackpotSpinStep() {
    bus.$emit(events.ADD_STEP, getJackpotSpinStep());
  }

  public addClearingStep() {
    bus.$emit(events.ADD_STEP, createClearingStep());
  }

  public addFreeSpinStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, getFreeSpinStep());
  }
  
  public addFreeSpinReTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, getFreeSpinTriggerFromFreeSpinGame());
  }

  public getWheelSpinRemaining(): number | undefined {
    const finalStep = this.finalStep();
    if (!finalStep) return 0;

    return finalStep.wheelSpinCount;
  }

  public getFreeSpinRemaining(): number | undefined {
    const finalStep = this.finalStep();
    if (!finalStep) return 0;

    return finalStep.freeSpinCount || 0;
  }

  public getWheelSpinCount(): number | undefined {
    const currentStep = this.currentStep();
    if (!currentStep) return 0;

    return currentStep.wheelSpinCount;
  }

  public getFreeSpinCount(): number | undefined {
    const currentStep = this.currentStep();
    if (!currentStep) return 0;

    return currentStep.freeSpinCount;
  }

  public getReelType() {
    const currentStep = this.currentStep();
    if (!currentStep) return;

    if (currentStep.wheelSpinCount === undefined && currentStep.freeSpinCount && currentStep.freeSpinCount > 0) {
      return 'FREE_SPIN';
    } else if (currentStep.json.addedWilds && currentStep.json.addedWilds.length >= 0) {
      return 'BASE_SB';
    } else if (currentStep.wheelSpinCount === undefined || currentStep.wheelSpinCount === 5) {
      return 'BASE';
    }
  }

  updateStepsInPlace(steps: Vgw088Step[]): boolean {
    let name = "Spin";
    let isAnyStepModified = false;
    let freeSpinCount = 0;
    let wheelSpinCount: number | undefined = undefined;
    let isFreeSpin = false;
    let wheelSpinIndex: number | undefined;
    let snowBlastLevel: SnowBlastLevel | undefined;
    let jackpotSpin = false;

    for (const step of steps) {
      step.isJackpotSpin = jackpotSpin;
      step.reelStripPositions = step.json.reelStripPositions;
      if (step.json.gameState === "clear") {
        name = step.json.name;
        freeSpinCount = 0;
        wheelSpinCount = undefined;
      } else {

        name = "Spin";
      }

      isFreeSpin = freeSpinCount > 0;

      if (wheelSpinCount === undefined) {
        let slotWindow: string[][] = getSlotWindow({
          isFreeSpin,
          reelStripPositions: step.json.reelStripPositions
        });

        const scatterSymbols = findCells(
          modelDefinition.scatterSymbol,
          slotWindow
        );

        const freeSpinsAwarded = scatterSymbols.length >= 3 ? modelDefinition.freeSpinsAwarded : 0;

        const isFreeSpinTrigger = freeSpinsAwarded > 0;
        if (isFreeSpin) {
          freeSpinCount -= 1;
          name = "Free spin";
        }

        if (isFreeSpinTrigger) {
          name = isFreeSpin ? "F-Retrigger" : "F-Trigger";
          freeSpinCount += freeSpinsAwarded;
          if (!isFreeSpin) {
            wheelSpinCount = 5;
            snowBlastLevel = 'COOL';
          }
        }

        if (!isFreeSpin) {
          snowBlastLevel = undefined;
        }
      } else {
        wheelSpinCount -=jackpotSpin ? 0 :  1;
        if(wheelSpinIndex !== undefined)
        {
          wheelSpinIndex += !jackpotSpin ? 1 : 0;
        }
        else
        {
          wheelSpinIndex = 0;
        }

        if (wheelSpinCount === -1) {
          wheelSpinCount = undefined;
          wheelSpinIndex = undefined;
          freeSpinCount -= 1;
        } else if (step.json.wheelSelection?.type === 'START') {
          wheelSpinCount = 0;

        } else if (step.json.wheelSelection?.type === 'FREE_SPINS') {
          freeSpinCount += step.json.wheelSelection?.value;
        } else if (step.json.wheelSelection?.type === 'SNOW_BLAST_LEVEL_UPGRADE') {
          snowBlastLevel = snowBlastLevelUpgradeBySpin[wheelSpinIndex];
        } else if(jackpotSpin) {
          jackpotSpin = false;
        } else if (step.json.wheelSelection?.type === "JACKPOT") {
          jackpotSpin = true;
        }
      }

      Object.assign(step, {
        freeSpinCount,
        wheelSpinCount,
        wheelSpinIndex,
        snowBlastLevel
      });
      const json = { ...step.json, name: step.json.name };
      if (JSON.stringify(json) !== JSON.stringify(step.json)) {
        isAnyStepModified = true;
        step.json = json;
        json.originatorId = originatorId;
      }
    }

    return isAnyStepModified;
  }

  canAddWild(): boolean {
    const currentStep = this.currentStep();
    if (!currentStep) return false;

    const reelType = this.getReelType();
    return reelType === 'BASE_SB' || reelType === 'FREE_SPIN';
  }

  shouldSlotWindowVisible(): boolean {
    const currentStep = this.currentStep();
    if (!currentStep) return false;

    const reelType = this.getReelType();
    return reelType === 'BASE' || reelType === 'BASE_SB' || reelType === 'FREE_SPIN';
  }

  isShowingWheelOptions(): boolean {
    const currentStep = this.currentStep();
    return !this.shouldSlotWindowVisible() && !currentStep?.isJackpotSpin;
  }

  isShowingJackpotOptions(): boolean {
    const currentStep = this.currentStep();
    return currentStep?.isJackpotSpin === true;
  }

  isAddedWild({ row,
    col }: {
      row: number;
      col: number;
    }): boolean {
    const currentStep = this.currentStep();
    if (!currentStep) return false;

    return (currentStep.json.addedWilds?.[col]?.[row] || 0) > 0;
  }

  getWildMultiplier({ row,
    col }: {
      row: number;
      col: number;
    }): number {
    const currentStep = this.currentStep();
    if (!currentStep) return 0;

    return (currentStep.json.addedWilds?.[col]?.[row] || 0);
  }

  addWild({
    row,
    col,
    multiplier
  }: {
    row: number;
    col: number;
    multiplier: string;
  }) {

    const currentStep = this.currentStep();
    if (!currentStep) return;

    const reelType = this.getReelType();

    if (!(reelType === 'BASE_SB' || reelType === 'FREE_SPIN')) return;

    if (multiplier !== '0' 
      && !this.isAddedWild({ row, col }) 
      && !isWildLimitUnderThreshold(reelType, currentStep?.snowBlastLevel, currentStep?.json.addedWilds)
    ) return;
    const addedWildsArray = generateNewAddedWildsArray(row, col, Number(multiplier), currentStep.json.addedWilds);
    currentStep.json.addedWilds = addedWildsArray;
    this.saveChangesToStep();

  }

  getWheelPrizes(): typeof WheelSelectionKind {
    return WheelSelectionKind;
  }

  getWheelPrize(): WheelSelectionKind | undefined {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection?.type) return;

    return currentStep.json.wheelSelection.type;
  }

  getJackpotTypes(): typeof JackpotTypes {
    return JackpotTypes;
  }

  getJackpotType(): JackpotType | undefined {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.jackpotType) return;

    return currentStep.json.jackpotType;
  }

  getPrizeList(): number[] | JackpotType[] | undefined {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection || currentStep.wheelSpinCount === undefined) return [];
    if (currentStep.json.wheelSelection.type === 'START') return undefined;

    const currentWheelSpinIndex = currentStep.wheelSpinIndex || 0;
    if (currentStep.json.wheelSelection.type !== 'JACKPOT') {
      return wheelPrize[currentStep.json.wheelSelection.type][currentWheelSpinIndex];
    } else {
      return wheelPrize[currentStep.json.wheelSelection.type];
    }

  }

  setWheelPrize(prize: WheelSelectionKind): void {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection || currentStep.wheelSpinCount === undefined) return;

    currentStep.json.wheelSelection.type = prize;

    if (currentStep.json.wheelSelection.type !== 'START') {
      const prizeList = this.getPrizeList();
      if (!prizeList) return

      if (
        currentStep.json.wheelSelection.type === 'COIN_PRIZE'
        || currentStep.json.wheelSelection.type === 'FREE_SPINS'
      ) {
        currentStep.json.wheelSelection.value = Number(prizeList[0]);
      } else if (currentStep.json.wheelSelection.type === 'SNOW_BLAST_LEVEL_UPGRADE') {
        currentStep.json.wheelSelection.value = Number(prizeList[Math.floor(Math.random() * prizeList.length)]);
      }
    }

    this.saveChangesToStep();
  }

  setJackpotType(prize: JackpotType): void {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.jackpotType) return;

    currentStep.json.jackpotType = prize;
    this.saveChangesToStep();
  }

  getPrizeValue(): string | undefined {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection || currentStep.wheelSpinCount === undefined) return;
    if (currentStep.json.wheelSelection.type === 'START' || currentStep.json.wheelSelection.type === 'JACKPOT') return undefined;

    return currentStep.json.wheelSelection.value.toString() || undefined;
  }

  setPrizeValue(value: number | JackpotType,) {
    // TODO: extract most of this code to a private function, so it can be used by setWheelPrize function
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection || currentStep.wheelSpinCount === undefined) return;

    if (
      currentStep.json.wheelSelection.type === 'COIN_PRIZE'
      || currentStep.json.wheelSelection.type === 'FREE_SPINS'
    ) {
      currentStep.json.wheelSelection.value = Number(value);
    }

    this.saveChangesToStep();

  }

  shouldShowPrizeValueSection() {
    const currentStep = this.currentStep();
    if (!currentStep || !currentStep.json.wheelSelection || currentStep.wheelSpinCount === undefined) return;

    return currentStep.json.wheelSelection.type === 'FREE_SPINS'
      || currentStep.json.wheelSelection.type === 'COIN_PRIZE';
  }

  saveChangesToStep(): void {
    // Traverse all steps, ensuring the state transitions are all valid and correct.
    this.updateStepsInPlace(this.allSteps);

    // Marking all the steps as having originated here, and persist them.
    this.allSteps.forEach((step) => (step.json.originatorId = originatorId));
    bus.$emit(events.EDIT_STEPS, this.allSteps);
  }
}

export const WheelSelectionKind = ['FREE_SPINS', 'COIN_PRIZE', 'SNOW_BLAST_LEVEL_UPGRADE', 'START', 'JACKPOT'] as const;
export const JackpotTypes = ["GRAND", "MAJOR", "MINOR", "MINI"] as const;
export type WheelSelectionKind = 'FREE_SPINS' | 'COIN_PRIZE' | 'SNOW_BLAST_LEVEL_UPGRADE' | 'START' | 'JACKPOT';
export interface Vgw088Step {
  reelStripPositions?: number[];
  test_scenario_step_id: number;
  json: Vgw088StepJson;
  freeSpinCount?: number;
  wheelSpinCount?: number;
  wheelSpinIndex?: number;
  snowBlastLevel?: SnowBlastLevel;
  isJackpotSpin?: boolean;
}

function isWildLimitUnderThreshold(reelType: string, snowBlastLevel: SnowBlastLevel | undefined, addedWilds: number[][] | undefined): boolean {
  const numberOfWilds = addedWilds?.reduce((sum, currentValue) => {
    return sum + currentValue.filter((m) => m > 0).length;
  }, 0) || 0;

  const BASE_MAX_WILDS = 10;

  if (reelType === 'BASE_SB') {
    return numberOfWilds < BASE_MAX_WILDS;
  } else if (reelType === 'FREE_SPIN') {
    if (!snowBlastLevel) return false;
    return numberOfWilds < numberOfWildsBySnowBlastLevel[snowBlastLevel]
  }

  return false;
}

function generateNewAddedWildsArray(row: number, col: number, multiplier: number, currentAddedWilds: number[][] | undefined) {
  return [0, 1, 2, 3, 4].map(reelColumn => [0, 1, 2, 3].map(reelRow => {
    if (reelRow === row && reelColumn === col) {
      return multiplier;
    }
    return currentAddedWilds?.[reelColumn]?.[reelRow] || 0;
  }));
}

function getSlotWindow({
  isFreeSpin,
  reelStripPositions,
}: {
  isFreeSpin: boolean;
  reelStripPositions: number[];
}): string[][] {
  const reelsLayout = modelDefinition.reelsLayout;

  const reels = isFreeSpin
    ? modelDefinition.freeSpinReels
    : modelDefinition.reels;
  return reels.map((_r, col) =>
    getReelWindowFromIndex(
      reels[col],
      reelStripPositions[col],
      reelsLayout[col]
    )
  );
}

function findCells(
  symbol: string,
  slotWindow: string[][]
): Array<[number, number]> {
  const cells: Array<[number, number]> = [];

  for (let col = 0; col < slotWindow.length; col++) {
    for (let row = 0; row < slotWindow[col].length; row++) {
      if (slotWindow[col][row] === symbol) {
        cells.push([row, col]);
      }
    }
  }
  return cells;
}