export const modelDefinition = {
  modelId: "vgw089",
  gameIds: ["roaringStrike"],
  coinType1Values: [500, 1000, 2500, 5000, 10000, 50000],
  coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2000],
  coinSizeMultiplier: 20,
  reelsLayout: [3, 3, 3, 3, 3],
  wildSymbol: "WILD",
  scatterSymbol: "SCAT",
  mysterySymbol: "M",
  becomesWildSymbol: "PIC1",

  freeSpinTriggerCount: 8,
  freeSpinRetriggerCount: 4,

  coinType1_spin_max_bet: 50_000 * 20,
  coinType4_spin_max_bet: 2_000 * 20,

  winTable: [
    {
      // NB: SCAT wins are multiplied by the total play amount. The others below are multiplied by coin size.
      symbol: "SCAT",
      multipliers: [100, 10, 2],
    },
    {
      symbol: "PIC1",
      multipliers: [600, 80, 40, 5],
    },
    {
      symbol: "PIC2",
      multipliers: [400, 60, 20, 4],
    },
    {
      symbol: "PIC3",
      multipliers: [100, 50, 20, 2],
    },
    {
      symbol: "PIC4",
      multipliers: [100, 40, 12, 2],
    },
    {
      symbol: "PIC5",
      multipliers: [80, 30, 12, 2],
    },
    {
      symbol: "PIC6",
      multipliers: [80, 30, 10, 2],
    },
    {
      symbol: "A",
      multipliers: [40, 12, 10],
    },
    {
      symbol: "K",
      multipliers: [30, 10, 8],
    },
    {
      symbol: "Q",
      multipliers: [30, 10, 5],
    },
    {
      symbol: "J",
      multipliers: [20, 10, 5],
    },
    {
      symbol: "T",
      multipliers: [20, 10, 5],
    },
  ],

  playLines: [
    [0, 0, 0, 0, 0], // 1
    [1, 1, 1, 1, 1], // 2
    [2, 2, 2, 2, 2], // 3
    [0, 1, 2, 1, 0], // 4
    [2, 1, 0, 1, 2], // 5
    [1, 0, 0, 0, 1], // 6
    [2, 1, 1, 1, 2], // 7
    [1, 2, 2, 2, 1], // 8
    [0, 1, 1, 1, 0], // 9
    [0, 0, 1, 2, 2], // 10
    [2, 2, 1, 0, 0], // 11
    [1, 1, 0, 1, 1], // 12
    [1, 1, 2, 1, 1], // 13
    [1, 0, 1, 0, 1], // 14
    [2, 1, 2, 1, 2], // 15
    [0, 1, 0, 1, 0], // 16
    [1, 2, 1, 2, 1], // 17
    [0, 2, 2, 2, 0], // 18
    [2, 0, 0, 0, 2], // 19
    [0, 2, 0, 2, 0], // 20
  ],

  // prettier-ignore
  reels: [
        ['M', 'M', 'M', 'M', 'PIC4', 'PIC2', 'PIC4', 'PIC2', 'A', 'Q', 'PIC1', 'PIC1', 'SCAT', 'J', 'PIC5', 'Q', 'A', 'A', 'SCAT', 'Q', 'M', 'M', 'PIC3', 'K', 'PIC3', 'PIC6', 'PIC6', 'A', 'A', 'K', 'M', 'M', 'PIC6', 'PIC6', 'M', 'PIC4', 'T', 'PIC4', 'PIC4', 'J', 'M', 'M', 'M', 'J', 'M', 'PIC5', 'PIC5', 'K', 'Q', 'A', 'Q', 'A', 'M', 'M', 'K', 'PIC2', 'PIC2', 'K', 'PIC1', 'PIC1', 'PIC5', 'PIC5', 'PIC5', 'PIC3', 'J', 'K', 'T', 'Q', 'SCAT', 'A', 'K', 'J', 'PIC3', 'PIC3', 'K', 'PIC4', 'PIC4', 'PIC5', 'PIC6', 'PIC6', 'PIC5', 'J', 'J', 'Q'],
        ['M', 'M', 'M', 'M', 'PIC1', 'K', 'K', 'PIC1', 'PIC1', 'Q', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'PIC5', 'PIC2', 'M', 'M', 'PIC6', 'PIC6', 'PIC6', 'J', 'SCAT', 'J', 'J', 'Q', 'M', 'M', 'M', 'T', 'PIC5', 'PIC5', 'PIC1', 'PIC1', 'PIC1', 'K', 'A', 'A', 'A', 'M', 'M', 'PIC4', 'PIC4', 'PIC5', 'PIC4', 'PIC5', 'K', 'PIC3', 'PIC3', 'PIC4', 'J', 'SCAT', 'M', 'T', 'J', 'PIC4', 'J', 'Q', 'T', 'Q', 'T', 'Q', 'Q'],
        ['M', 'M', 'M', 'M', 'A', 'PIC1', 'Q', 'PIC1', 'K', 'K', 'PIC2', 'K', 'A', 'A', 'T', 'PIC3', 'PIC3', 'T', 'PIC6', 'PIC4', 'K', 'PIC4', 'M', 'PIC5', 'M', 'PIC5', 'J', 'J', 'PIC5', 'Q', 'PIC6', 'T', 'PIC6', 'PIC2', 'M', 'M', 'M', 'K', 'PIC1', 'Q', 'SCAT', 'K', 'PIC2', 'J', 'A', 'J', 'A', 'Q', 'PIC3', 'PIC3', 'T', 'PIC4', 'T', 'SCAT', 'PIC4', 'PIC5', 'M', 'M', 'M', 'J', 'J', 'PIC5', 'Q', 'PIC6', 'PIC6', 'PIC2', 'PIC5'],
        ['M', 'M', 'M', 'M', 'PIC5', 'A', 'PIC1', 'PIC1', 'A', 'PIC6', 'K', 'PIC2', 'PIC1', 'PIC5', 'J', 'PIC2', 'PIC2', 'Q', 'PIC6', 'SCAT', 'J', 'PIC3', 'PIC3', 'J', 'M', 'A', 'M', 'Q', 'PIC4', 'SCAT', 'PIC6', 'T', 'K', 'J', 'J', 'T', 'T', 'M', 'M', 'A', 'PIC6', 'T', 'A', 'PIC1', 'PIC1', 'M', 'M', 'K', 'PIC2', 'Q', 'PIC5', 'PIC5', 'K', 'PIC2', 'Q', 'PIC6', 'PIC6', 'K', 'PIC3', 'PIC3', 'J', 'M', 'M', 'PIC4', 'Q', 'PIC4', 'M', 'PIC6', 'M', 'K', 'J', 'A', 'A'],
        ['M', 'M', 'M', 'M', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', 'K', 'K', 'PIC5', 'PIC2', 'Q', 'M', 'Q', 'M', 'Q', 'PIC2', 'Q', 'T', 'T', 'PIC4', 'PIC1', 'A', 'PIC3', 'PIC5', 'T', 'PIC2', 'J', 'PIC6', 'T', 'PIC2', 'PIC3', 'A', 'PIC5', 'PIC5', 'J', 'T', 'PIC4', 'Q', 'PIC4', 'PIC6', 'M', 'M', 'SCAT', 'K', 'T', 'K', 'M', 'M', 'M', 'M', 'A', 'PIC1', 'PIC1', 'PIC1', 'J', 'K', 'K', 'PIC5', 'PIC5', 'Q', 'PIC1', 'A', 'PIC6', 'A', 'PIC3', 'PIC3', 'T', 'T', 'PIC4', 'A', 'A', 'PIC4', 'PIC5', 'SCAT', 'PIC2', 'J', 'PIC6', 'T', 'M', 'M', 'PIC6', 'PIC5', 'PIC5', 'J', 'Q', 'PIC4', 'J', 'PIC4', 'J', 'PIC6', 'PIC6', 'SCAT', 'K', 'T', 'K', 'J', 'J'],
    ],

  freeSpins: {
    reelsLayout: [4, 4, 4, 4, 4],

    playLines: [
      [0, 0, 0, 0, 0], // 1
      [1, 1, 1, 1, 1], // 2
      [2, 2, 2, 2, 2], // 3
      [3, 3, 3, 3, 3], // 4
      [1, 2, 3, 2, 1], // 5
      [0, 1, 2, 1, 0], // 6
      [3, 2, 1, 2, 3], // 7
      [2, 1, 0, 1, 2], // 8
      [3, 2, 2, 2, 3], // 9
      [2, 1, 1, 1, 2], // 10
      [1, 0, 0, 0, 1], // 11
      [2, 3, 3, 3, 2], // 12
      [1, 2, 2, 2, 1], // 13
      [0, 1, 1, 1, 0], // 14
      [1, 1, 2, 3, 3], // 15
      [3, 3, 2, 1, 1], // 16
      [2, 2, 1, 2, 2], // 17
      [1, 1, 0, 1, 1], // 18
      [2, 2, 3, 2, 2], // 19
      [0, 0, 1, 0, 0], // 20
      [3, 2, 3, 2, 3], // 21
      [2, 1, 2, 1, 2], // 22
      [1, 0, 1, 0, 1], // 23
      [2, 3, 2, 3, 2], // 24
      [1, 2, 1, 2, 1], // 25
      [0, 1, 0, 1, 0], // 26
      [1, 3, 3, 3, 1], // 27
      [0, 2, 2, 2, 0], // 28
      [3, 1, 1, 1, 3], // 29
      [1, 3, 1, 3, 1], // 30
    ],

    // prettier-ignore
    reels: [
            ['M', 'M', 'Q', 'PIC4', 'PIC5', 'Q', 'SCAT', 'A', 'A', 'PIC4', 'PIC1', 'PIC1', 'SCAT', 'PIC6', 'J', 'PIC2', 'A', 'PIC2', 'T', 'PIC6', 'A', 'PIC6', 'J', 'PIC5', 'J', 'Q', 'J', 'PIC4', 'J', 'PIC4', 'PIC3', 'PIC3', 'J', 'PIC6', 'PIC6', 'PIC4', 'PIC4', 'T', 'J', 'J', 'A', 'J', 'A', 'M', 'M', 'M', 'M', 'PIC5', 'T', 'A', 'A', 'PIC4', 'PIC1', 'PIC4', 'J', 'K', 'J', 'PIC2', 'A', 'PIC2', 'Q', 'T', 'A', 'PIC3', 'J', 'T', 'PIC6', 'Q', 'J', 'K', 'PIC5', 'J', 'PIC3', 'PIC3', 'PIC5', 'PIC6', 'PIC6', 'T', 'PIC4', 'PIC4', 'T', 'J', 'PIC6', 'M', 'M', 'Q', 'T', 'PIC5', 'Q', 'SCAT', 'A', 'A', 'T', 'PIC1', 'PIC1', 'SCAT', 'PIC6', 'J', 'PIC2', 'A', 'PIC2', 'T', 'PIC6', 'A', 'T', 'J', 'PIC5', 'J', 'Q', 'J', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC3', 'J', 'PIC6', 'PIC6', 'T', 'T', 'T', 'J', 'J', 'A', 'J', 'A', 'Q', 'T', 'M', 'M', 'PIC5', 'T', 'A', 'A', 'T', 'PIC1', 'T', 'J', 'K', 'J', 'PIC2', 'A', 'PIC2', 'Q', 'T', 'A', 'PIC3', 'J', 'T', 'M', 'M', 'J', 'K', 'PIC5', 'J', 'PIC3', 'PIC3', 'PIC5', 'PIC6', 'PIC6', 'T', 'PIC4', 'PIC4', 'T', 'J', 'PIC6'],
            ['A', 'K', 'K', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'PIC5', 'PIC6', 'PIC3', 'A', 'A', 'PIC1', 'PIC4', 'PIC1', 'J', 'A', 'PIC5', 'PIC6', 'PIC3', 'PIC3', 'T', 'SCAT', 'J', 'Q', 'A', 'Q', 'J', 'T', 'SCAT', 'J', 'K', 'PIC5', 'T', 'PIC6', 'PIC5', 'T', 'PIC6', 'T', 'M', 'M', 'M', 'M', 'T', 'J', 'PIC3', 'J', 'PIC3', 'T', 'PIC5', 'PIC1', 'A', 'K', 'K', 'A', 'PIC2', 'PIC2', 'PIC5', 'K', 'Q', 'A', 'M', 'M', 'PIC4', 'Q', 'J', 'A', 'PIC5', 'PIC6', 'PIC3', 'PIC3', 'T', 'K', 'Q', 'Q', 'A', 'T', 'PIC5', 'T', 'J', 'J', 'PIC5', 'PIC5', 'T', 'PIC6', 'J', 'PIC1', 'PIC6', 'T', 'J', 'T', 'J', 'PIC5', 'T', 'J', 'PIC3', 'J', 'A', 'K', 'K', 'A', 'PIC1', 'PIC1', 'J', 'J', 'PIC2', 'M', 'M', 'PIC6', 'PIC3', 'A', 'A', 'PIC1', 'PIC4', 'PIC1', 'J', 'A', 'PIC5', 'PIC6', 'PIC3', 'PIC3', 'T', 'SCAT', 'J', 'Q', 'A', 'Q', 'J', 'T', 'SCAT', 'J', 'K', 'PIC5', 'T', 'PIC6', 'PIC5', 'T', 'PIC6', 'T', 'J', 'T', 'J', 'PIC5', 'T', 'J', 'PIC3', 'J', 'PIC3', 'T', 'PIC5', 'PIC1', 'A', 'K', 'K', 'A', 'PIC2', 'PIC2', 'PIC5', 'K', 'Q', 'M', 'M', 'M', 'PIC4', 'Q', 'J', 'A', 'PIC5', 'PIC6', 'PIC3', 'PIC3', 'T', 'K', 'Q', 'Q', 'A', 'T', 'PIC5', 'T', 'J', 'J', 'PIC5', 'PIC5', 'T', 'PIC6', 'J', 'PIC1', 'PIC6', 'T', 'J', 'T', 'J', 'PIC5', 'T', 'J', 'PIC3', 'J', 'A', 'K', 'K'],
            ['T', 'K', 'T', 'Q', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'J', 'K', 'PIC5', 'Q', 'T', 'Q', 'PIC4', 'PIC5', 'J', 'A', 'A', 'T', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC1', 'PIC5', 'PIC5', 'Q', 'A', 'J', 'J', 'Q', 'PIC5', 'A', 'A', 'Q', 'PIC3', 'SCAT', 'A', 'PIC4', 'Q', 'M', 'M', 'M', 'M', 'PIC4', 'SCAT', 'J', 'K', 'PIC1', 'T', 'PIC4', 'K', 'T', 'T', 'T', 'K', 'J', 'T', 'PIC5', 'K', 'K', 'A', 'M', 'M', 'J', 'A', 'A', 'PIC1', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC6', 'PIC5', 'PIC5', 'Q', 'A', 'J', 'J', 'T', 'T', 'PIC2', 'PIC2', 'Q', 'PIC3', 'Q', 'A', 'PIC6', 'PIC6', 'PIC3', 'PIC5', 'J', 'Q', 'PIC1', 'A', 'J', 'K', 'T', 'K', 'T', 'Q', 'PIC1', 'PIC1', 'T', 'T', 'J', 'M', 'M', 'Q', 'T', 'Q', 'PIC4', 'PIC5', 'J', 'A', 'A', 'T', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC1', 'PIC5', 'PIC5', 'Q', 'A', 'J', 'J', 'Q', 'PIC5', 'A', 'A', 'Q', 'PIC3', 'SCAT', 'A', 'PIC4', 'Q', 'PIC3', 'PIC5', 'J', 'Q', 'PIC4', 'SCAT', 'J', 'K', 'PIC1', 'T', 'PIC4', 'K', 'T', 'T', 'T', 'K', 'J', 'T', 'PIC5', 'K', 'K', 'M', 'M', 'M', 'J', 'A', 'A', 'PIC1', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC6', 'PIC5', 'PIC5', 'Q', 'A', 'J', 'J', 'T', 'T', 'PIC2', 'PIC2', 'Q', 'PIC3', 'Q', 'A', 'PIC6', 'PIC6', 'PIC3', 'PIC5', 'J', 'Q', 'PIC1', 'A', 'J', 'K', 'T', 'K', 'T'],
            ['Q', 'T', 'Q', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC3', 'PIC5', 'J', 'A', 'PIC3', 'PIC4', 'K', 'Q', 'J', 'K', 'PIC5', 'J', 'PIC5', 'A', 'Q', 'Q', 'T', 'PIC6', 'J', 'PIC1', 'A', 'PIC5', 'T', 'T', 'SCAT', 'A', 'PIC5', 'J', 'T', 'A', 'SCAT', 'Q', 'K', 'J', 'M', 'M', 'M', 'M', 'PIC5', 'PIC4', 'Q', 'PIC1', 'PIC1', 'J', 'J', 'T', 'Q', 'PIC4', 'Q', 'A', 'PIC3', 'J', 'PIC4', 'PIC4', 'PIC3', 'PIC2', 'M', 'M', 'J', 'K', 'PIC5', 'J', 'PIC5', 'K', 'PIC4', 'PIC4', 'T', 'PIC1', 'J', 'T', 'PIC2', 'PIC5', 'T', 'T', 'K', 'A', 'PIC5', 'J', 'T', 'A', 'T', 'Q', 'K', 'PIC2', 'PIC5', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'A', 'Q', 'PIC6', 'Q', 'T', 'Q', 'A', 'PIC1', 'PIC1', 'K', 'A', 'PIC3', 'M', 'M', 'A', 'PIC3', 'PIC4', 'K', 'Q', 'J', 'K', 'PIC5', 'J', 'PIC5', 'A', 'Q', 'Q', 'T', 'PIC6', 'J', 'PIC1', 'A', 'PIC5', 'T', 'T', 'SCAT', 'A', 'PIC5', 'J', 'T', 'A', 'SCAT', 'Q', 'K', 'J', 'PIC5', 'K', 'PIC4', 'PIC5', 'PIC5', 'PIC4', 'Q', 'PIC1', 'PIC1', 'J', 'J', 'T', 'Q', 'PIC4', 'Q', 'A', 'PIC3', 'J', 'PIC4', 'PIC4', 'PIC3', 'M', 'M', 'M', 'J', 'K', 'PIC5', 'J', 'PIC5', 'K', 'PIC4', 'PIC4', 'T', 'PIC1', 'J', 'T', 'PIC2', 'PIC5', 'T', 'T', 'K', 'A', 'PIC5', 'J', 'T', 'A', 'T', 'Q', 'K', 'PIC2', 'PIC5', 'PIC6', 'PIC6', 'PIC5', 'PIC5', 'A', 'Q', 'PIC6', 'Q', 'T', 'Q'],
            ['M', 'M', 'PIC2', 'T', 'A', 'PIC1', 'PIC1', 'J', 'PIC5', 'M', 'PIC5', 'K', 'K', 'Q', 'PIC1', 'Q', 'Q', 'Q', 'M', 'M', 'Q', 'T', 'PIC4', 'A', 'A', 'PIC3', 'K', 'T', 'Q', 'J', 'PIC6', 'T', 'Q', 'PIC3', 'A', 'K', 'K', 'M', 'M', 'PIC4', 'T', 'PIC4', 'PIC6', 'M', 'M', 'Q', 'PIC5', 'T', 'PIC5', 'M', 'M', 'J', 'T', 'A', 'PIC6', 'A', 'M', 'J', 'PIC5', 'PIC5', 'K', 'K', 'Q', 'J', 'A', 'PIC6', 'A', 'PIC3', 'PIC3', 'T', 'T', 'PIC4', 'A', 'A', 'PIC4', 'K', 'SCAT', 'PIC2', 'J', 'PIC6', 'T', 'PIC2', 'PIC5', 'PIC6', 'K', 'K', 'J', 'T', 'PIC4', 'J', 'PIC4', 'J', 'PIC6', 'PIC6', 'SCAT', 'PIC5', 'T', 'PIC5', 'J', 'J', 'M', 'M', 'M', 'M', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'PIC5', 'K', 'K', 'Q', 'PIC1', 'Q', 'Q', 'Q', 'PIC2', 'T', 'Q', 'T', 'PIC4', 'A', 'A', 'PIC3', 'K', 'T', 'Q', 'J', 'PIC6', 'T', 'Q', 'PIC3', 'A', 'K', 'K', 'M', 'M', 'PIC4', 'T', 'PIC4', 'PIC6', 'M', 'M', 'SCAT', 'PIC5', 'T', 'PIC5', 'M', 'M', 'J', 'T', 'A', 'PIC6', 'A', 'M', 'J', 'PIC5', 'PIC5', 'K', 'K', 'Q', 'J', 'A', 'PIC6', 'A', 'PIC3', 'PIC3', 'T', 'T', 'PIC4', 'A', 'A', 'PIC4', 'K', 'SCAT', 'PIC2', 'J', 'PIC6', 'T', 'PIC2', 'PIC5', 'PIC6', 'K', 'K', 'J', 'T', 'PIC4', 'J', 'PIC4', 'J', 'PIC6', 'PIC6', 'SCAT', 'PIC5', 'T', 'PIC5', 'J', 'J'],
        ],
  },

  wildFury: {
    reelsLayout: [4, 4, 4, 4, 4],

    playLines: [
      [0, 0, 0, 0, 0], // 1
      [1, 1, 1, 1, 1], // 2
      [2, 2, 2, 2, 2], // 3
      [3, 3, 3, 3, 3], // 4
      [1, 2, 3, 2, 1], // 5
      [0, 1, 2, 1, 0], // 6
      [3, 2, 1, 2, 3], // 7
      [2, 1, 0, 1, 2], // 8
      [3, 2, 2, 2, 3], // 9
      [2, 1, 1, 1, 2], // 10
      [1, 0, 0, 0, 1], // 11
      [2, 3, 3, 3, 2], // 12
      [1, 2, 2, 2, 1], // 13
      [0, 1, 1, 1, 0], // 14
      [1, 1, 2, 3, 3], // 15
      [3, 3, 2, 1, 1], // 16
      [2, 2, 1, 2, 2], // 17
      [1, 1, 0, 1, 1], // 18
      [2, 2, 3, 2, 2], // 19
      [0, 0, 1, 0, 0], // 20
      [3, 2, 3, 2, 3], // 21
      [2, 1, 2, 1, 2], // 22
      [1, 0, 1, 0, 1], // 23
      [2, 3, 2, 3, 2], // 24
      [1, 2, 1, 2, 1], // 25
      [0, 1, 0, 1, 0], // 26
      [1, 3, 3, 3, 1], // 27
      [0, 2, 2, 2, 0], // 28
      [3, 1, 1, 1, 3], // 29
      [1, 3, 1, 3, 1], // 30
    ],

    // prettier-ignore
    reels: [
            ['M', 'M', 'Q', 'T', 'K', 'Q', 'SCAT', 'A', 'A', 'PIC4', 'PIC1', 'PIC1', 'SCAT', 'PIC5', 'PIC5', 'PIC4', 'A', 'PIC2', 'Q', 'PIC6', 'J', 'PIC4', 'A', 'K', 'J', 'Q', 'J', 'T', 'PIC5', 'PIC6', 'PIC3', 'PIC3', 'T', 'PIC6', 'PIC6', 'M', 'M', 'T', 'J', 'J', 'PIC4', 'K', 'PIC4', 'M', 'M', 'K', 'T', 'J', 'Q', 'A', 'A', 'Q', 'PIC1', 'PIC1', 'K', 'PIC5', 'K', 'Q', 'A', 'PIC2', 'Q', 'PIC6', 'A', 'PIC3', 'J', 'K', 'PIC4', 'Q', 'J', 'PIC5', 'K', 'J', 'PIC3', 'PIC3', 'K', 'PIC6', 'PIC6', 'T', 'PIC4', 'PIC4', 'SCAT', 'J', 'PIC4', 'T'],
            ['A', 'K', 'A', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'K', 'PIC3', 'Q', 'PIC1', 'A', 'Q', 'PIC3', 'Q', 'J', 'A', 'M', 'M', 'M', 'M', 'SCAT', 'PIC6', 'Q', 'Q', 'A', 'K', 'K', 'SCAT', 'PIC3', 'T', 'PIC5', 'K', 'T', 'PIC6', 'J', 'K', 'PIC6', 'T', 'J', 'T', 'J', 'K', 'T', 'J', 'PIC3', 'J', 'PIC1', 'Q', 'K', 'A', 'PIC1', 'PIC5', 'PIC5', 'A', 'PIC2', 'PIC2', 'K', 'Q', 'T', 'A', 'A', 'Q', 'PIC4', 'Q', 'J', 'A', 'K', 'PIC6', 'PIC3', 'PIC3', 'T', 'PIC5', 'Q', 'Q', 'A', 'T', 'K', 'T', 'PIC4', 'J', 'K', 'K', 'T', 'J', 'PIC1', 'PIC1', 'PIC6', 'T', 'J', 'T', 'PIC6', 'K', 'T', 'J', 'PIC3', 'J', 'A', 'PIC5', 'PIC5', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'K', 'PIC3', 'Q', 'PIC1', 'A', 'Q', 'PIC4', 'Q', 'J', 'A', 'M', 'M', 'M', 'M', 'SCAT', 'PIC6', 'Q', 'Q', 'A', 'K', 'K', 'SCAT', 'PIC4', 'M', 'M', 'K', 'T', 'PIC6', 'J', 'K', 'PIC6', 'T', 'J', 'T', 'J', 'K', 'T', 'J', 'PIC3', 'J', 'PIC1', 'Q', 'K', 'A', 'PIC1', 'PIC5', 'PIC5', 'A', 'PIC2', 'PIC2', 'K', 'Q', 'PIC5', 'A', 'A', 'Q', 'PIC4', 'Q', 'J', 'A', 'K', 'PIC6', 'PIC3', 'PIC3', 'T', 'PIC5', 'Q', 'Q', 'A', 'T', 'K', 'T', 'PIC4', 'J', 'K', 'K', 'T', 'J', 'PIC1', 'PIC1', 'PIC6', 'T', 'J', 'T', 'PIC6', 'K', 'T', 'J', 'PIC3', 'J'],
            ['T', 'PIC5', 'T', 'PIC4', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'J', 'PIC2', 'K', 'Q', 'PIC1', 'Q', 'PIC6', 'K', 'J', 'PIC6', 'A', 'T', 'PIC4', 'M', 'M', 'M', 'T', 'PIC1', 'K', 'K', 'Q', 'T', 'J', 'J', 'T', 'PIC4', 'PIC4', 'SCAT', 'Q', 'PIC3', 'Q', 'SCAT', 'PIC6', 'PIC6', 'PIC3', 'K', 'J', 'Q', 'PIC4', 'T', 'J', 'PIC5', 'PIC1', 'T', 'PIC4', 'J', 'T', 'PIC5', 'T', 'PIC5', 'J', 'PIC2', 'K', 'Q', 'PIC5', 'Q', 'PIC6', 'K', 'J', 'A', 'A', 'PIC1', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC6', 'K', 'K', 'Q', 'A', 'J', 'J', 'T', 'PIC2', 'PIC2', 'PIC2', 'Q', 'PIC3', 'Q', 'M', 'M', 'PIC6', 'PIC3', 'K', 'J', 'Q', 'PIC1', 'A', 'J', 'PIC5', 'T', 'PIC5', 'T', 'PIC5', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'J', 'PIC2', 'K', 'Q', 'PIC1', 'Q', 'PIC6', 'K', 'J', 'A', 'A', 'T', 'M', 'M', 'M', 'M', 'T', 'PIC1', 'K', 'K', 'Q', 'A', 'J', 'J', 'T', 'J', 'M', 'SCAT', 'Q', 'PIC3', 'Q', 'SCAT', 'PIC6', 'PIC6', 'PIC3', 'K', 'J', 'Q', 'PIC4', 'A', 'J', 'PIC5', 'PIC1', 'T', 'PIC4', 'PIC5', 'T', 'PIC5', 'T', 'PIC5', 'J', 'PIC2', 'K', 'Q', 'PIC5', 'Q', 'PIC6', 'K', 'J', 'A', 'A', 'PIC1', 'T', 'PIC4', 'J', 'PIC4', 'T', 'PIC6', 'K', 'K', 'Q', 'A', 'J', 'J', 'T', 'PIC2', 'PIC2', 'PIC2', 'Q', 'PIC3', 'Q', 'A', 'PIC6', 'PIC6', 'PIC3', 'K', 'J', 'Q', 'PIC1', 'A', 'J', 'PIC5'],
            ['Q', 'PIC4', 'Q', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC3', 'K', 'J', 'A', 'PIC3', 'SCAT', 'PIC2', 'PIC1', 'J', 'PIC5', 'K', 'J', 'M', 'M', 'M', 'M', 'T', 'PIC1', 'J', 'PIC1', 'PIC2', 'K', 'T', 'T', 'PIC5', 'A', 'K', 'J', 'T', 'A', 'SCAT', 'PIC5', 'PIC5', 'Q', 'SCAT', 'A', 'K', 'A', 'Q', 'K', 'A', 'PIC6', 'PIC4', 'J', 'J', 'T', 'Q', 'PIC6', 'Q', 'A', 'PIC3', 'J', 'PIC1', 'A', 'PIC3', 'PIC4', 'PIC2', 'Q', 'J', 'PIC5', 'K', 'J', 'K', 'T', 'A', 'PIC4', 'T', 'PIC1', 'J', 'T', 'PIC2', 'K', 'T', 'T', 'PIC5', 'A', 'K', 'J', 'T', 'A', 'T', 'Q', 'PIC5', 'PIC2', 'K', 'PIC6', 'PIC6', 'K', 'K', 'A', 'Q', 'PIC6', 'Q', 'PIC6', 'Q', 'A', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC3', 'K', 'J', 'A', 'PIC3', 'PIC4', 'PIC2', 'PIC1', 'J', 'PIC5', 'K', 'J', 'M', 'M', 'M', 'M', 'T', 'PIC1', 'J', 'PIC1', 'PIC2', 'K', 'T', 'T', 'PIC5', 'A', 'K', 'J', 'T', 'A', 'T', 'Q', 'M', 'M', 'SCAT', 'PIC6', 'PIC6', 'K', 'K', 'A', 'Q', 'PIC6', 'PIC4', 'J', 'J', 'T', 'Q', 'PIC6', 'Q', 'A', 'PIC3', 'J', 'PIC1', 'A', 'PIC3', 'PIC4', 'PIC2', 'Q', 'J', 'PIC5', 'K', 'J', 'K', 'PIC6', 'A', 'PIC4', 'T', 'PIC1', 'J', 'T', 'PIC2', 'K', 'T', 'T', 'PIC5', 'A', 'K', 'J', 'T', 'A', 'T', 'Q', 'PIC5', 'PIC2', 'K', 'PIC6', 'PIC6', 'K', 'K', 'A', 'Q', 'PIC6'],
            ['M', 'M', 'M', 'M', 'A', 'PIC2', 'Q', 'Q', 'PIC5', 'K', 'K', 'Q', 'J', 'PIC5', 'K', 'Q', 'Q', 'T', 'PIC2', 'Q', 'T', 'T', 'PIC4', 'A', 'A', 'PIC3', 'PIC5', 'Q', 'K', 'J', 'PIC6', 'Q', 'PIC2', 'PIC3', 'A', 'PIC5', 'PIC5', 'J', 'T', 'PIC4', 'T', 'PIC4', 'PIC6', 'M', 'M', 'SCAT', 'K', 'T', 'K', 'M', 'M', 'M', 'PIC6', 'A', 'PIC1', 'PIC1', 'PIC5', 'J', 'K', 'K', 'PIC5', 'PIC5', 'Q', 'Q', 'A', 'PIC6', 'A', 'PIC3', 'PIC3', 'T', 'T', 'PIC4', 'A', 'A', 'PIC4', 'PIC5', 'SCAT', 'K', 'J', 'PIC6', 'T', 'PIC2', 'K', 'PIC6', 'PIC5', 'PIC5', 'J', 'T', 'PIC4', 'J', 'PIC4', 'J', 'PIC6', 'PIC6', 'SCAT', 'K', 'T', 'K', 'J', 'J'],
        ],
  },

  winLevels: [
    { name: "normal1", threshold: 2 },
    { name: "normal2", threshold: 5 },
    { name: "normal3", threshold: 20 },
    { name: "bigwin", threshold: 35 },
    { name: "megawin", threshold: 70 },
    { name: "epicwin" },
  ],

  mysterySymbolWeights: {
    BASE: [
      { outcome: "PIC1", weight: 1 },
      { outcome: "PIC2", weight: 3 },
      { outcome: "PIC3", weight: 4 },
      { outcome: "PIC4", weight: 6 },
      { outcome: "PIC5", weight: 6 },
      { outcome: "PIC6", weight: 6 },
      { outcome: "A", weight: 6 },
      { outcome: "K", weight: 5 },
      { outcome: "Q", weight: 5 },
      { outcome: "J", weight: 5 },
      { outcome: "T", weight: 4 },
    ],
    FREE_SPINS: [
      { outcome: "PIC2", weight: 2 },
      { outcome: "PIC3", weight: 4 },
      { outcome: "PIC4", weight: 4 },
      { outcome: "PIC5", weight: 4 },
      { outcome: "PIC6", weight: 5 },
      { outcome: "A", weight: 5 },
      { outcome: "K", weight: 5 },
      { outcome: "Q", weight: 5 },
      { outcome: "J", weight: 5 },
      { outcome: "T", weight: 5 },
    ],
    WILD_FURY: [
      { outcome: "PIC2", weight: 1 },
      { outcome: "PIC3", weight: 2 },
      { outcome: "PIC4", weight: 2 },
      { outcome: "PIC5", weight: 3 },
      { outcome: "PIC6", weight: 4 },
      { outcome: "A", weight: 6 },
      { outcome: "K", weight: 7 },
      { outcome: "Q", weight: 6 },
      { outcome: "J", weight: 6 },
      { outcome: "T", weight: 7 },
    ],
  },

  wildFuryTriggerWeights: [
    { outcome: true, weight: 1 },
    { outcome: false, weight: 36 },
  ],

  wildMultiplierWeights: {
    FREE_SPINS: [
      { outcome: 1, weight: 9 },
      { outcome: 2, weight: 1 },
    ],
    WILD_FURY: [
      { outcome: 1, weight: 6 },
      { outcome: 2, weight: 1 },
    ],
  } as const,

  jackpots: {
    fixedMultipliers: {
      MINI: 20,
      MINOR: 100,
    },
    seedValues: {
      MAJOR: {
        GOLD: 10_000_000,
        SWEEPS: 500_000,
      },
      GRAND: {
        GOLD: 1_000_000_000,
        SWEEPS: 50_000_000,
      },
    },
    percentToJackpotPool: {
      MAJOR: 0.5,
      GRAND: 0.25,
    },
  } as const,

  jackpotFeatureWeights: {
    FREE_SPINS: [
      { outcome: true, weight: 1 },
      { outcome: false, weight: 91 },
    ],
    WILD_FURY: [
      { outcome: true, weight: 1 },
      { outcome: false, weight: 86 },
    ],
  } as const,

  jackpotTypeWeights: {
    SWEEPS: [
      { outcome: "GRAND", multiplier: 1, constant: 0 },
      { outcome: "MAJOR", multiplier: 195, constant: 0 },
      { outcome: "MINOR", multiplier: 49, constant: 5329017 },
      { outcome: "MINI", multiplier: -245, constant: 21316510 },
    ],
    GOLD: [
      { outcome: "GRAND", multiplier: 1, constant: 0 },
      { outcome: "MAJOR", multiplier: 195, constant: 0 },
      { outcome: "MINOR", multiplier: 49, constant: 106580340 },
      { outcome: "MINI", multiplier: -245, constant: 426330200 },
    ],
  } as const,
};
