import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { CharacterWilds } from './character-wilds';
import { PickFeature, PickFeatureAward } from './pick-feature';
import { JackpotPhase } from './jackpots';
import { ReelSpinFeatures } from './reel-spin-features-response';

export const GameStateSchema = Type.Object({
    coinAmount: Type.Integer(),

    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),

    cumulativeWinAmount: Type.Number(),

    freeSpins: Type.Optional(
        Type.Object({
            remaining: Type.Number(),
            phase: Type.Union([
                Type.Literal('START'),
                Type.Literal('IN_PROGRESS'),
                Type.Literal('RETRIGGER'),
                Type.Literal('END'),
            ]),
            picToWilds: Type.Optional(CharacterWilds),
        }),
    ),
    picks: Type.Optional(
        Type.Object({
            phase: Type.Union([Type.Literal('START'), Type.Literal('IN_PROGRESS'), Type.Literal('END')]),
            userPicks: Type.Optional(Type.Array(PickFeature)),
            pickFeaturesAwarded: Type.Optional(Type.Array(PickFeatureAward)),
        }),
    ),
    jackpotPhase: Type.Optional(JackpotPhase),
    reelSpinFeatures: Type.Optional(ReelSpinFeatures),
});
export type GameState = Static<typeof GameStateSchema>;
