import Vue, { PropType } from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import {
  getAssetsPath,
  getReelWindowFromIndex,
  wrapIndex,
} from "../../../../helpers/helpers";
import State from "../../../../state/state";
import { Vgw082Step } from "./content.vue";
import type Content from './content.vue';
@Component({
  data: () => {
    return {
      reelIndex: 0,
    };
  },
})
export default class ReelVgw082 extends Vue {
  @Prop({ type: Number, default: 0 }) public index;
  @Prop() public step: Vgw082Step | undefined;
  @Prop({ type: Boolean, default: false }) public isFeature;
  @Prop({ type: Number, default: 3 }) public height;
  @Prop({type: Object as PropType<InstanceType<typeof Content>>}) public content;
  public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
  public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
  private reelIndex = 0;
  private text = "0";
  private reels = [];
  
  constructor() {
    super();
    const state = State.state;
  }

  @Watch("step")
  public stepChanged(step: Vgw082Step) {
    if (!step || !step.json) {
      return;
    }
    this.reelIndex = step.json.reelStripPositions[this.index];
    this.text = String(this.reelIndex);
  }

  @Watch("text")
  public textChanged(val, oldval) {
    const n = Number(val);

    if (!Number.isInteger(n)) {
      this.text = oldval;
    }
    const rs = this.getReels();
    if (n >= rs.length) {
      this.text = String(wrapIndex(n, rs));
    } else if (n < 0) {
      this.reelIndex = 0;
      this.text = String(this.reelIndex);
    }
    this.updateStep();
  }

  @Watch("isFeature")
  public isFeatureChanged() {
    this.updateStep();
  }

  @Watch("height")
  public heightChanged() {
    this.updateStep();
  }

  public mounted() {
    this.$refs.reel &&
      this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
  }

  public beforeDestroy() {
    this.$refs.reel &&
      this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
  }

  private getReels() {
    const state = State.state;
    const a = this.isFeature
      ? state.getFeatureReelStrips()
      : state.getReelStrips();
    return a[this.index];
  }

  public up() {
    const rs = this.getReels();
    this.text = String(wrapIndex(this.reelIndex + 1, rs));
  }

  public down() {
    const rs = this.getReels();
    this.text = String(wrapIndex(this.reelIndex - 1, rs));
  }

  public updateStep() {
    if (!this.step) {
      return;
    }
    const rs = this.getReels();
    this.reelIndex = Number(this.text);

    this.reels = getReelWindowFromIndex(rs, this.reelIndex, this.height);
    this.step.json.reelStripPositions[this.index] = this.reelIndex;

    if (!this.content.currentStep()) return;
    this.content.currentStep().json.reelStripPositions[this.index] = this.reelIndex;

    this.content.saveChangesToStep();
  }

  symbolsBelowWindow() {
    const reel = this.getReels();    

    const reelCells = [0, 1].map(r => reel[wrapIndex(this.reelIndex + this.height + r, reel)]);
    return reelCells;
  }
    
  public symbolsAboveWindow() {
    if (!this.step) {
      return;
    }    
    const reel = this.getReels();

    const maxHeight = this.step.json.reelsExpansionHeight ?? 3;
    const aboveReelHeight = 2 + (maxHeight - this.height);            
    const reelCells = getReelWindowFromIndex(
      reel,
      this.reelIndex - aboveReelHeight,
      aboveReelHeight
    );
    return reelCells;
  }

  public reelWindow() {
    const reel = this.getReels();   
    const reelCells = getReelWindowFromIndex(reel, this.reelIndex, this.height);
    return reelCells;
  }

  public scrollFunction(e) {
    if (e.deltaY < 0) {
      this.down();
    } else if (e.deltaY > 0) {
      this.up();
    }
    e.preventDefault();
  }

  public isCoin(sym: string) {
    return State.state.isCoin(sym);
  }
}
