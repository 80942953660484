var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-steps" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add new clearing step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addSpinStep()
                },
              },
            },
            [_vm._v("Add new step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addFreeSpinTriggerStep()
                },
              },
            },
            [_vm._v("Trigger Free Spins")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addScatterWinStep()
                },
              },
            },
            [_vm._v("Trigger Scatter win")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addSuperDrumSpinStep()
                },
              },
            },
            [_vm._v("Add Super Drum Spin")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addJackpotTriggerStep()
                },
              },
            },
            [_vm._v("Add Jackpot Trigger")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isWheelSpin
        ? _c("div", [
            _c("h2", { staticClass: "heading" }, [_vm._v("Pick Options:")]),
            _vm._v(" "),
            _c("div", { staticClass: "pick-option-selectors" }, [
              _c("label", [_vm._v("Pick Options:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.pickOption,
                      expression: "scenario.pickOption",
                    },
                  ],
                  attrs: { id: "wheel-spin_pick" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "pickOption",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: "bothWheels" } }, [
                    _vm._v("BOTH_WHEELS"),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: "freeSpinWheel" } }, [
                    _vm._v("FREE_SPIN_WHEEL"),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: "multiplierWheel" } }, [
                    _vm._v("MULTIPLIER_WHEEL"),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        !_vm.isWheelSpin
          ? _c(
              "div",
              { staticClass: "reel-position-selectors" },
              [
                _c("label", [_vm._v("Reel strips:")]),
                _vm._v(" "),
                _vm._l(_vm.scenario.reelStripPositions, function (position) {
                  return _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: position.value,
                        expression: "position.value",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: { type: "number" },
                    domProps: { value: position.value },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(position, "value", _vm._n($event.target.value))
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  })
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isWheelSpin
          ? _c("div", { staticClass: "free-spin-selectors" }, [
              _c("label", { attrs: { for: "freeSpinMultiplier" } }, [
                _vm._v("Free Spin Multiplier:"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.freeSpinsMultiplier,
                      expression: "scenario.freeSpinsMultiplier",
                    },
                  ],
                  attrs: { id: "freeSpinMultiplier" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "freeSpinsMultiplier",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.freeSpinMultiplierList, function (multiplier) {
                  return _c(
                    "option",
                    {
                      key: "freespin-multiplier-" + multiplier,
                      domProps: { value: multiplier },
                    },
                    [_vm._v("\n          " + _vm._s(multiplier) + "\n        ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("label", { attrs: { for: "freeSpinAward" } }, [
                _vm._v("Free Spin Awared:"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.freeSpinsAwarded,
                      expression: "scenario.freeSpinsAwarded",
                    },
                  ],
                  attrs: { id: "freeSpinAward" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "freeSpinsAwarded",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.freeSpinAwardList, function (freeSpinsAwarded) {
                  return _c(
                    "option",
                    {
                      key: "freespin-award-" + freeSpinsAwarded,
                      domProps: { value: freeSpinsAwarded },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(freeSpinsAwarded) + "\n        "
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isWheelSpin
          ? _c("div", { staticClass: "super-drum-selectors" }, [
              _c("label", { attrs: { for: "superCheckbox" } }, [
                _vm._v("Super Drum:"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.superDrum.isSuperDrumAwarded,
                    expression: "scenario.superDrum.isSuperDrumAwarded",
                  },
                ],
                attrs: { id: "superCheckbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.scenario.superDrum.isSuperDrumAwarded
                  )
                    ? _vm._i(_vm.scenario.superDrum.isSuperDrumAwarded, null) >
                      -1
                    : _vm.scenario.superDrum.isSuperDrumAwarded,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.scenario.superDrum.isSuperDrumAwarded,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.scenario.superDrum,
                              "isSuperDrumAwarded",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.scenario.superDrum,
                              "isSuperDrumAwarded",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.scenario.superDrum,
                          "isSuperDrumAwarded",
                          $$c
                        )
                      }
                    },
                    _vm.handleSuperDrumSelected,
                  ],
                },
              }),
              _vm._v(" "),
              _vm.scenario.superDrum.isSuperDrumAwarded
                ? _c("label", { attrs: { for: "superDrumMultiplierSelect" } }, [
                    _vm._v("Super Drum Multiplier"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.scenario.superDrum.isSuperDrumAwarded
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenario.superDrum.multiplier,
                          expression: "scenario.superDrum.multiplier",
                        },
                      ],
                      attrs: { id: "superDrumMultiplierSelect" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.scenario.superDrum,
                            "multiplier",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(
                      _vm.superDrumMultiplierList,
                      function (superDrumMultiplier) {
                        return _c(
                          "option",
                          {
                            key: "superdrum-" + superDrumMultiplier,
                            domProps: { value: superDrumMultiplier },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(superDrumMultiplier) +
                                "\n        "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("slot-window", { attrs: { spinOutcome: _vm.spinOutcome } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }