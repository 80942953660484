/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.reelsType = "reels";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                case "reelsSetOne":
                    return State.state.getModelDefinition().reels;
                case "reelsSetTwo":
                    return State.state.getModelDefinition().reelsSetTwo;
                case "reelsSetThree":
                    return State.state.getModelDefinition().featureReels;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getBaseStep: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels Set 1");
            step.reelsType = "reelsSetOne";
            step.reelSetID = '1';
            return step;
        },
        getReelsSetOne: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels Set 1");
            step.reelsType = "reelsSetOne";
            step.reelSetID = '1';
            return step;
        },
        getReelsSetTwo: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels Set 2");
            step.reelsType = "reelsSetTwo";
            step.reelSetID = '2';
            return step;
        },
        getReelsSetThree: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels Set 3");
            step.reelsType = "reelsSetThree";
            step.reelSetID = '3';
            return step;
        },
        getFeatureTriggerReelSetOne: function() {
            const step = State.state.createStep([17, 6, 13, 3, 7], false, 0, "", "FS Trigger - RS1");
            step.reelsType = "reelsSetOne";
            step.reelSetID = '1';
            return step;
        },
        getFeatureTriggerReelSetTwo: function() {
            const step = State.state.createStep([17, 6, 13, 3, 7], false, 0, "", "FS Trigger - RS2");
            step.reelsType = "reelsSetTwo";
            step.reelSetID = '2';
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, state.getNumberOfRows());
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}