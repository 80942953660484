import bus from "../../../../common/bus";
import events from "../../../../common/events";
import {
  createBoardGameStep,
  createClearingStep,
  createFreeSpinStep,
  createSpinStep,
  originatorId,
  StepJson,
} from "../../../../state/models/slots/vgw092";
import Vue, { PropType } from "vue";
import { spin } from "./math-model";
import { Card, createChoicesFromScenarioStep } from "./math-model/choices";
import { SlotWindow } from "./slot-window";
import { BoardGameControls } from "./board-game-controls";
import { ReelSpinFeatureControls } from "./reel-spin-feature-controls";
import { GameState } from "./math-model/game-state";
import { Colour } from "./math-model/spin";

export default Vue.component("Content", {
  props: {
    step: Object as PropType<{ test_scenario_step_id: number }>,
  },
  components: {
    "slot-window": SlotWindow,
    "board-game-controls": BoardGameControls,
    "reel-spin-feature-controls": ReelSpinFeatureControls,
  },
  data: function (): {
    scenario: InternalScenario;
    reelSpinFeatureId?: number;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        reelStripPositions: [
          { value: 5 },
          { value: 5 },
          { value: 5 },
          { value: 5 },
          { value: 5 },
        ],
        replacementSymbols: [],
      },
      reelSpinFeatureId: undefined,
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      const spinOutput = spin({
        coinTypeId: 4,
        coinSize: 1,
        selectedLines: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
          37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
        ],
        choices: createChoicesFromScenarioStep({
          ...convertToStepJson(this.scenario),
        }),
        gameState: this.currentStep?.gameState,
      });
      return spinOutput.spinOutcome;
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    // Free Spins Check
    isFreeSpins: function () {
      if (this.spinOutcome.freeSpinPhase) {
        return true;
      } else {
        return false;
      }
    },
    // Board Game Check
    isBoardGame: function () {
      if (this.spinOutcome.boardGamePhase) {
        return true;
      } else {
        return false;
      }
    },
    // Board Game First Step Check
    isBoardGameFirstStep: function () {
      if (this.currentStep?.gameState?.boardGamePhase === "START") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    addSpinStep: function () {
      bus.$emit(events.ADD_STEP, createSpinStep());
    },

    addClearingStep: function () {
      bus.$emit(events.ADD_STEP, createClearingStep());
    },

    addFreeSpinStep: function () {
      bus.$emit(events.ADD_STEP, createFreeSpinStep());
    },

    addBoardGameStep: function () {
      bus.$emit(events.ADD_STEP, createBoardGameStep());
    },

    onStolenRoyalChanged: function (payload: {
      royal: string;
      stolenRoyalIndex: number;
    }) {
      this.scenario.replacementSymbols[payload.stolenRoyalIndex].value =
        payload.royal;
    },

    // ----- Scenario persistence methods -----
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }

        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertToStepJson(newScenario),
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });

    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

/**
 * Representation of the scenario used internally to the view.
 *
 * This is set up to allow for reactive binding to the UI
 */
interface InternalScenario {
  reelStripPositions: { value: number }[];
  boardGamePicks?: number;
  boardGameSteps?: Array<{
    card: Card;
    prize: number;
    colour: Colour;
  }>;
  jackpotType?: "GRAND" | "MAJOR" | "MINOR" | "MINI";
  featureSetId?: number;
  multiplier?: number;
  nuttSymbol?:
    | "PIC1"
    | "PIC2"
    | "PIC3"
    | "PIC4"
    | "PIC5"
    | "A"
    | "K"
    | "Q"
    | "J"
    | "10"
    | "9";
  nuttPrize?: number;
  replacementSymbols: Array<{ value: string }>;
  candyCaneReplacementSymbol?: string;
  candyCanePlacements?: Array<{
    reel: number;
    reelPosition: number;
    candyCaneType: "TOP_LEFT" | "TOP_RIGHT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT";
  }>;
  stolenRoyals?: Array<string>;
  wildPathFlipX?: boolean;
  wildPathFlipY?: boolean;
  wildPathSwapAxis?: boolean;
  wildPathId?: "ID_1" | "ID_2" | "ID_3" | "ID_4" | "ID_5" | "ID_6";
  wildPathStartingPosition?: number;
  wildPathLength?: number;
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;

  for (const step of steps) {
    step.gameState = gameState;

    const spinOutput = spin({
      coinTypeId: 4,
      coinSize: 1,
      selectedLines: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
      ],

      choices: createChoicesFromScenarioStep({
        ...step.json,
      }),
      gameState,
    });

    gameState = spinOutput.gameState;
  }

  return modified;
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  return {
    boardGamePicks: scenario.boardGamePicks,
    boardGameSteps: scenario.boardGameSteps,
    jackpotType: scenario.jackpotType,
    featureSetId: scenario.featureSetId,
    multiplier: scenario.multiplier,
    nuttSymbol: scenario.nuttSymbol,
    nuttPrize: scenario.nuttPrize,
    replacementSymbols: scenario.replacementSymbols.map(({ value }) => value),
    candyCaneReplacementSymbol: scenario.candyCaneReplacementSymbol,
    candyCanePlacements: scenario.candyCanePlacements,
    stolenRoyals: scenario.stolenRoyals,
    wildPathFlipY: scenario.wildPathFlipY,
    wildPathFlipX: scenario.wildPathFlipX,
    wildPathSwapAxis: scenario.wildPathSwapAxis,
    wildPathId: scenario.wildPathId,
    wildPathStartingPosition: scenario.wildPathStartingPosition,
    wildPathLength: scenario.wildPathLength,

    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
  };
}

function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
    replacementSymbols: (step.json.replacementSymbols ?? []).map((value) => ({
      value,
    })),
  };
}

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}
