/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.extraReels = 0;
            step.levelNo = -1;
            step.reelsLayout = [3,3,3];
            step.reelIds = [0,1,2];
            return step;
        },
        getBaseStep: function(stepReelsPos) {
            const name = "Base";
            const step = State.state.createStep([0, 0, 0], false, 0, "", name);
            step.reelsType = "reels";
            step.internal_name = name;
            step.levelNo = -1;
            step.extraReels = 0;
            step.reelsLayout = [3,3,3];
            step.reelIds = [0,1,2];
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],

        getNewModelDefinition: () => State.state.getModelDefinition(),

        getReelStrips: () => State.state.getModelDefinition().reels, 
        getFeatureReelStrips: () => State.state.getModelDefinition().reels_fs,

        // example on creating custom indexes
        getFSTriggerStep (stepTitle, reelsType) {
            const step = State.state.createStep([140, 127, 0], false, 0, "", stepTitle);
            step.reelsType = reelsType;
            step.reelIds = [0,1,2];
            step.levelNo = -1;
            step.isGreen = 2;
            step.internal_name = "FS Trigger";
            return step;
        },
        getFSStep (stepTitle, reelsType) {
            const step = State.state.createStep([0, 0, 0], true, 0, "", stepTitle);
            step.internal_name = stepTitle;
            step.reelIds = [0,1,2];
            step.levelNo = -1;
            step.reelsType = reelsType;
            step.isFeature = true;
            return step;
        },

        prepareStepForDB: (step) => {

            console.log( "prepareStepForDB tf104" );

            const state = State.state;

            let reelstrip_pos_list = step.reelStripPositions;
            let reel_id_list = step.reelIds;

            const old_reelsstrip_pos = JSON.parse(JSON.stringify(reelstrip_pos_list));
            //const old_reel_id_list = JSON.parse(JSON.stringify(reel_id_list));

            const reels_num = step.extraReels + 3;
            const pos_len = reelstrip_pos_list.length;

            if ( reels_num < old_reelsstrip_pos.length )
            {
                console.log( "remove reel " + reels_num );
                reelstrip_pos_list = reelstrip_pos_list.slice(0, reels_num);
                reel_id_list = reel_id_list.slice(0, reels_num);
            }
            else
            {
                const newLength = reels_num - pos_len;
                for(let i=0; i < newLength; i++)
                {
                    reelstrip_pos_list[reelstrip_pos_list.length] = 0;
                    reel_id_list[reel_id_list.length] = 3;
                }
            }

            step.reelStripPositions = reelstrip_pos_list;
            step.reelIds = reel_id_list;

            state.getNewModelDefinition().reelStripPositions = step.reelStripPositions;
            state.getNewModelDefinition().reelIds = step.reelIds;



            //step.rows = rowsFromIndexes(reels, reelstrip_pos_list, step.reelStripPositions.length, 3);
            //step.randoms = reelstrip_pos_list;

            return step;

        }
    };
}
