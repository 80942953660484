var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content vgw060",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Default")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Free Spin")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusTriggerStep()
              },
            },
          },
          [_vm._v("Jackpot Game")]
        ),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "flex-center",
        attrs: { id: "substitute-wrapper" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reel-container", class: ["flex-center"] },
        [
          _vm.cascadeDetected
            ? _c("div", { staticClass: "cascade-notice" }, [
                _vm._v("CASCADE ⌖"),
                _c("br"),
                _vm._v("DETECTED"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("reel", {
            staticClass: "top-reel",
            attrs: {
              index: 6,
              step: _vm.step,
              symbolInfoIndex: 0,
              symbolInfoReel: _vm.symbolInfos[0][6],
              isJackpotGame: _vm.isJackpotGame,
              isLocked: _vm.hasCascadesSaved || _vm.isJackpotGameDefined,
            },
          }),
          _vm._v(" "),
          _vm._l(6, function (reelIndex) {
            return _c("reel", {
              key: reelIndex + 5000,
              attrs: {
                index: reelIndex - 1,
                step: _vm.step,
                symbolInfoIndex: 0,
                symbolInfoReel: _vm.symbolInfos[0][reelIndex - 1],
                isJackpotGame: _vm.isJackpotGame,
                isLocked: _vm.hasCascadesSaved || _vm.isJackpotGameDefined,
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.cascadeDetected
        ? [
            _vm.cascadeInfos && _vm.cascadeInfos.length > 0
              ? _vm._l(_vm.cascadeInfos, function (cascadeInfoSet, ciIndex) {
                  return _c("div", { key: ciIndex + 1000 }, [
                    _c(
                      "div",
                      { staticClass: "reel-container flex-center" },
                      [
                        _vm.canCascade(cascadeInfoSet)
                          ? _c("div", { staticClass: "cascade-notice" }, [
                              _vm._v("CASCADE ⌖"),
                              _c("br"),
                              _vm._v("DETECTED"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "coz-im-special button pointer cascade-button",
                            class: {
                              "coz-im-special-final":
                                _vm.showCascadeTrashButton(ciIndex),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateDB()
                              },
                            },
                          },
                          [_vm._v("Cascade Round " + _vm._s(ciIndex + 1))]
                        ),
                        _vm._v(" "),
                        _vm.showCascadeTrashButton(ciIndex)
                          ? _c(
                              "div",
                              {
                                staticClass: "button pointer trash-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFinalCascadeStep()
                                  },
                                },
                              },
                              [_vm._v("x")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("reel", {
                          staticClass: "top-reel",
                          attrs: {
                            index: 6,
                            step: _vm.step,
                            isCascade: true,
                            symbolInfoIndex: ciIndex,
                            symbolInfoReel: _vm.cascadeSymbolInfos[ciIndex][6],
                            isLocked:
                              ciIndex != _vm.cascadeInfos.length - 1 ||
                              _vm.isJackpotGameDefined,
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(6, function (reelIndex) {
                          return _c("reel", {
                            key: reelIndex + 2000,
                            attrs: {
                              isCascade: true,
                              index: reelIndex - 1,
                              step: _vm.step,
                              symbolInfoIndex: ciIndex,
                              symbolInfoReel:
                                _vm.cascadeSymbolInfos[ciIndex][reelIndex - 1],
                              isLocked:
                                ciIndex != _vm.cascadeInfos.length - 1 ||
                                _vm.isJackpotGameDefined,
                            },
                          })
                        }),
                      ],
                      2
                    ),
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isJackpotGameDefined && _vm.canCascadeAgain
              ? [
                  _c(
                    "div",
                    { staticClass: "reel-container", class: ["flex-center"] },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "button cascade-button pointer",
                          on: {
                            click: function ($event) {
                              return _vm.addCascadeStep()
                            },
                          },
                        },
                        [_vm._v("Add Cascade Step")]
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isJackpotGame
        ? [
            _vm.symbolInfos && _vm.symbolInfos.length > 1
              ? _vm._l(_vm.symbolInfos, function (symbolInfoSet, siIndex) {
                  return _c("div", { key: siIndex + 4000 }, [
                    siIndex > 0
                      ? _c(
                          "div",
                          { staticClass: "reel-container flex-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "coz-im-special button pointer jackpot-button",
                                class: {
                                  "coz-im-special-final":
                                    _vm.showJackpotTrashButton(siIndex),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateDB()
                                  },
                                },
                              },
                              [_vm._v("Jackpot Round " + _vm._s(siIndex))]
                            ),
                            _vm._v(" "),
                            _vm.showJackpotTrashButton(siIndex)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "button pointer trash-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFinalJackpotStep()
                                      },
                                    },
                                  },
                                  [_vm._v("x")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("reel", {
                              staticClass: "top-reel",
                              attrs: {
                                index: 6,
                                step: _vm.step,
                                isJackpotContainer: true,
                                symbolInfoIndex: siIndex,
                                symbolInfoReel: symbolInfoSet[6],
                                isLocked: siIndex != _vm.symbolInfos.length - 1,
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(6, function (reelIndex) {
                              return _c("reel", {
                                key: reelIndex + 3000,
                                attrs: {
                                  index: reelIndex - 1,
                                  step: _vm.step,
                                  isJackpotContainer: true,
                                  symbolInfoIndex: siIndex,
                                  symbolInfoReel: symbolInfoSet[reelIndex - 1],
                                  isLocked:
                                    siIndex != _vm.symbolInfos.length - 1,
                                },
                              })
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.canCascadeAgain && _vm.isLastCascadeValid
              ? _c(
                  "div",
                  { staticClass: "reel-container", class: ["flex-center"] },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "button jackpot-button pointer",
                        on: {
                          click: function ($event) {
                            return _vm.addJackpotStep()
                          },
                        },
                      },
                      [_vm._v("Add Jackpot Step")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }