/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init93";

export function setStateParams() {
    return {
        variations: ["93"], 
        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0]);
            step.winBonusGame = false;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[1],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => State.state.createStep([13, 0, 27, 0, 0, 20], false, 0, "", "F-Trigger") ,
        getFeatureStep: () => State.state.createStep([0, 0, 0, 0, 0, 0], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([13, 0, 27, 0, 0, 20], true, 0, "", "F-Step") ,
        prepareStepForDB: (step) => {
            // same steps as state.prepareStepForDB
            const state = State.state;
            const reelStrips = step.isFeature ? state.getFeatureReelStrips() : state.getReelStrips();
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());

            step.rows[3][0] = "";
            step.rows[3][5] = "";
            return step;
        },
    };
}
