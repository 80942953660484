import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public wildOptionChanging = "Normal";
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public isOpen = false;
    public wildOption = false;
    public baseOption = false;
    public coinFeatureOption = false;
    public fsShow = false;
    public internal_name = "";
    public coinsFeature = 50;
    public coinDefaultShow = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.wildOption = false;
        this.baseOption = false;
        this.fsShow = false;
        this.coinDefaultShow = false;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        if(step.json.internal_name == 'Base' || step.json.internal_name == 'FS Trigger') {
            this.baseOption = true;
            this.wildOption = false;
            this.fsShow = true;
            this.coinDefaultShow = false;
            this.coinFeatureOption = false;
        } else if(step.json.internal_name == 'FreeSpin Step') {
            this.wildOption = true;
            this.baseOption = false;
            this.fsShow = true;
            this.coinDefaultShow = false;
            this.coinFeatureOption = false;
        } else if (step.json.internal_name == 'Coins Feature') {
            this.coinDefaultShow = true;
            this.coinFeatureOption = true;
        } else {
            this.wildOption = false;
            this.baseOption = false;
            this.fsShow = false;
            this.coinDefaultShow = false;
            this.coinFeatureOption = false;
        }

        this.wildOptionChanging = this.step.json.wildOptionChanging ? this.step.json.wildOptionChanging : "Normal";
        this.coinsFeature = this.step.json.coinsFeature ? this.step.json.coinsFeature : 50;
    }

    public mounted() {
        this.reelsNo = 5;
        this.isOpen = false;
        this.wildOption = false;
        this.baseOption = false;
        trace("reels mounted");
    }

    public addNewStep(typeStep) {
        const state = State.state;
        this.reelsNo = 5;
        switch(typeStep){
            case 'Base':
                bus.$emit(events.ADD_STEP, state.setBaseStep());
                break;
            case 'BaseCoins':
                bus.$emit(events.ADD_STEP, state.setCoinsFeatureStep());
                break;
            case 'FSTrigger':
                bus.$emit(events.ADD_STEP, state.setFSTriggerStep());
                break;
            case 'FreeSpinStep':
                bus.$emit(events.ADD_STEP, state.addFSStep(this.wildOptionChanging));
                break;
            default:
                bus.$emit(events.ADD_STEP, state.getBaseStep());
        }
    }

    public updateBaseStep(wildOptionChanging) {
        this.reelsNo = 5;
        this.step.json.wildOptionChanging = wildOptionChanging;
        switch(wildOptionChanging) {
            case "2x":
            case "3x":
            case "4x":
            case "Wild1":
            case "Wild2":
            case "Wild3":
                this.step.json.reelsType = "reels";
                break;
            case "Random":
                this.step.json.reelsType = "reelsRandomWilds";
                break;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateFSStep(wildOptionChanging) {
        this.reelsNo = 5;
        this.step.json.wildOptionChanging = wildOptionChanging;
        switch(wildOptionChanging) {
            case "2x":
            case "3x":
            case "4x":
                this.step.json.reelsType = "reelsFS";
                break;
            case "Random":
                this.step.json.reelsType = "reelsFSRandomWilds";
                break;
            case "Wild1":
            case "Wild2":
            case "Wild3":
                this.step.json.reelsType = "reelsWildReelFeature";
                break;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }


    public updateCoinsStep(coinsFeature) {
        this.reelsNo = 5;
        this.internal_name = "typeStep";
        this.step.json.coinsFeature = coinsFeature;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

}
