var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "reel", staticClass: "sm-reel-vgw048" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.substep === 0,
              expression: "substep === 0",
            },
          ],
          attrs: { align: "center" },
        },
        [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "text-input reel-text reel-index",
              attrs: { type: "number" },
              domProps: { value: _vm.text },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.text = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-center",
              staticStyle: { "font-size": "12px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.down()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-2x fa-chevron-up",
                    staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.up()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-2x fa-chevron-down",
                    staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.substep === 0,
              expression: "substep === 0",
            },
          ],
          staticClass: "symbol dim",
        },
        [_c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p0top))])]
      ),
      _vm._v(" "),
      _vm._l(_vm.window, function (w, i) {
        return _c(
          "div",
          {
            key: w.id,
            class: [
              "symbol",
              "on",
              _vm.isJackpot && _vm.isCoin(i) ? "jackpot" : "",
              _vm.isCoinLocked(i) ? "locked" : "",
            ],
          },
          [
            _vm.substep === 0 || w === "Coin"
              ? _c(
                  "div",
                  {
                    class: [
                      _vm.isCoin(i) && _vm.isJackpot ? "" : "pad-text",
                      "text",
                    ],
                  },
                  [_vm._v(_vm._s(w))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.substep > 0 && !_vm.isCoinLocked(i)
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "toggle",
                      on: {
                        click: function ($event) {
                          return _vm.toggleACoinOnJackpotFeature(i)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "pointer" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.isToggleOn(i)
                              ? _vm.toggleOn
                              : _vm.toggleOff,
                            width: "35",
                            height: "35",
                          },
                        }),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.itHasInfo(i) && _vm.isJackpot
              ? _c("div", [
                  _c("div", { attrs: { align: "center" } }, [
                    _c("div", { staticClass: "select-style" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedCoinsInfo[i],
                              expression: "selectedCoinsInfo[i]",
                            },
                          ],
                          attrs: { disabled: _vm.isCoinLocked(i) },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.selectedCoinsInfo,
                                  i,
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.onChangeCoinSelect(i)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.coinsInfo, function (coinInfo) {
                          return _c(
                            "option",
                            { key: _vm.getCoinText(coinInfo) },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.getCoinText(coinInfo)) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.substep === 0,
              expression: "substep === 0",
            },
          ],
          staticClass: "symbol dim",
        },
        [_c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p0bottom))])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }