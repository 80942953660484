var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Coin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStampedeTriggerStep()
              },
            },
          },
          [_vm._v("Stampede Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [_vm._v("reelset:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reelSetIndex,
                expression: "reelSetIndex",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "reelSetIndex", id: "reelSetIndex" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.reelSetIndex = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeReelSet,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "0", selected: "" } }, [
              _vm._v("0"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c(
          "div",
          { staticClass: "selected-scatters" },
          [
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value,
                attrs: {
                  index: value,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 5,
                attrs: {
                  index: value + 5,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(5, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 10,
                attrs: {
                  index: value + 10,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFullRandom()
              },
            },
          },
          [_vm._v("Random")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFullNoScatter()
              },
            },
          },
          [_vm._v("NoScatter")]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }