import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch, Inject} from "vue-property-decorator";
import * as casino from "../../../../helpers/casino";
import {trace, TODO, range, getReelWindowFromIndex} from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reelvgw028: Reel,
    }, 
    data: function() {
        return {
            isJackpot: false,
        }
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isJackpot = false;
    public isFeature = false;
    public isSweeps = true;
    public isFeatureChecked = false;
    public show = false;
    public symbolInfos: any = [];
    private featureReelsToUse = 6;
    constructor() {
        super();

    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        trace("vgw041 > Content > step updating");
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.isSweeps = step.json.isSweeps || step.json.isSweeps == null ? true : false;
        this.symbolInfos = step.json.symbolInfos;
        this.featureReelsToUse = step.json.featureReelsToUse || 6;
        this.$nextTick(() => bus.$emit("step-updated", step));
    }
    public mounted() {
        bus.$on("step-updated", (step) => {  
            const wasJackpot = this.isJackpot;
            this.isJackpot = State.state.isJackpot(this.step);
            if (wasJackpot && !this.isJackpot) {
                this.step.json.symbolInfos = [];
                this.step.json.symbolInfos.push(State.state.getEmptySymbolInfo());
                this.updateSymbolInfosAndSave();

            } else if (!wasJackpot && this.isJackpot && this.step.json.symbolInfos.length === 1) {
                this.createBaseJackpotTrigger();
                this.updateSymbolInfosAndSave();
            } 
        });
    }
    public beforeDestroy() {
        bus.$off("step-updated");
    }
    public updateSymbolInfosAndSave() {
        const state = State.state;
        this.symbolInfos = this.step.json.symbolInfos;
        state.organizeSymbolInfos(this.step.json.symbolInfos);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public createBaseJackpotTrigger() {
        State.state.enableCoinsOnStep(this.step);
    }
    public addBaseStep() {
        const state = State.state; 
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addWithCoins() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getWithCoinsStep() );
    }
    public addJackpotTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getJackpotTriggerStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep(this.featureReelsToUse) );
    }
    public addFeatureTriggerStep() {
        const state = State.state; 
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerStep() );
    }
    public addFeatureReTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep() );
    }
    public addJackpotStep() {
        const state = State.state;
        this.step.json.symbolInfos.push(state.getEmptySymbolInfo());
        this.updateSymbolInfosAndSave();
    }
    public doChangeFeatureReelsTouse() { 
        this.checkChangesInStep();
    }
    public toggleFeature() {
        this.step.json.isFeature = !this.step.json.isFeature;
        this.checkChangesInStep();
    }   
    public toggleSweeps() {
        this.step.json.isSweeps = !this.step.json.isSweeps;
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        const state = State.state;
        this.step.json.featureReelsToUse = this.featureReelsToUse; 
        
        const coins = state.countSymbolOnStep(this.step, "Coin");

        // cleanup
        while(this.step.json.symbolInfos.length > 1) { this.step.json.symbolInfos.pop(); } 

        // add again if on coin
        if (coins >= 4) { this.createBaseJackpotTrigger(); }
        this.updateSymbolInfosAndSave();
    }

}
