import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public reelSetCascadeNumber = 1;
    public isOpenBase = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpenBase = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        let stepTitle = "Base";
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getBaseStep(stepTitle, parseInt(this.reelSetCascadeNumber.toString())));
    }


    public viewBaseReelsetNumber() {
        this.isOpenBase = true;
    }

    public viewFSTriggerReelsetNumber() {
        this.isOpenBase = false;
        const state = State.state;
        let stepTitle = "FS Trigger";
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep(stepTitle));
    }

    public viewFSReelsetNumber() {
        this.isOpenBase = false;
        const state = State.state;
        let stepTitle = "FS Step";
        this.reelsNo = 4;
        bus.$emit(events.ADD_STEP, state.getFSStep(stepTitle));
    }

    public viewFSTBReelsetNumber() {
        this.isOpenBase = false;
        const state = State.state;
        let stepTitle = "Tackle Box Step";
        this.reelsNo = 4;
        bus.$emit(events.ADD_STEP, state.getFSTBStep(stepTitle));
    }

}
