import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import { PositionSchema } from "./common";
import { CoinPrizeSchema, FeatureColoursSchema, GenieBonusPhaseSchema } from "./genie-bonus";

export const GameStateSchema = Type.Object({
    coinAmount: Type.Integer(),
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),
    cumulativeWinAmount: Type.Number(),
    mysterySymbol: Type.String(),

    genieBonusPhase: GenieBonusPhaseSchema,
    genieBonusCount: Type.Integer(),
    genieBonusColours: Type.Array(FeatureColoursSchema),
    coinPrizes: Type.Array(CoinPrizeSchema),
    hardPosition: PositionSchema,
});
export type GameState = Static<typeof GameStateSchema>;
