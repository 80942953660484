import { mathModel } from "../../../v3";
import { modelDefinition } from "../config";

const { bonusSymbol, countToTriggerBoardGame } = modelDefinition;

/** Checks if the slot window has the required amount of bonus cells needed to trigger the board game */
export function isTriggeringBoardGame(slotWindow: string[][]): boolean {
  const bonusCells = mathModel.filterSlotWindow(
    slotWindow,
    (sym) => sym === bonusSymbol
  );
  return bonusCells.length >= countToTriggerBoardGame;
}
