var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFreeSpinTriggerStep,
                expression: "canAddFreeSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFireshotTriggerStep,
                expression: "canAddFireshotTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFireshotTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Fireshot")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddGrandTriggerStep,
                expression: "canAddGrandTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addGrandTriggerStep()
              },
            },
          },
          [_vm._v("Trigger GRAND")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.index + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " + _vm._s(_vm.fireshotRespinsRemaining)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Coin Count: " +
                  _vm._s(_vm.stepInfo && _vm.stepInfo.cumulativeCoinCount)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWheelIndexes,
                  expression: "showWheelIndexes",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Wheel 1")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWheelIndexes,
                  expression: "showWheelIndexes",
                },
              ],
              staticClass: "select-style",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.wheel1Index,
                      expression: "wheel1Index",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.wheel1Index = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: undefined } }, [
                    _vm._v("random"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.wheel1Options, function (opt) {
                    return [
                      _c("option", { domProps: { value: opt.value } }, [
                        _vm._v(_vm._s(opt.label)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWheelIndexes,
                  expression: "showWheelIndexes",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Wheel 2")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWheelIndexes,
                  expression: "showWheelIndexes",
                },
              ],
              staticClass: "select-style",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.wheel2Index,
                      expression: "wheel2Index",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.wheel2Index = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: undefined } }, [
                    _vm._v("random"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.wheel2Options, function (opt) {
                    return [
                      _c("option", { domProps: { value: opt.value } }, [
                        _vm._v(_vm._s(opt.label)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCoinMode,
                  expression: "showCoinMode",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Coin Mode")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCoinMode,
                  expression: "showCoinMode",
                },
              ],
              staticClass: "select-style",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.coinMode,
                      expression: "coinMode",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.coinMode = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: undefined } }, [
                    _vm._v("random"),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: "NORMAL_COIN" } }, [
                    _vm._v("Normal Coin 🟡"),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: "SPECIAL_COIN" } }, [
                    _vm._v("Special Coin 💎"),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", { attrs: { content: this, index: 0 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 1 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 2 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 3 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 4 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 5 } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }