import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public activeStep = "BaseGame";

    public reelID = "_noWin";
    public reelIndex = "0";

    public baseGameReelsID = [
        { id: "_noWin", value: "No Win" },
        { id: "default", value: "Default" },
        { id: "default_1", value: "Feature 1 Active" },
        { id: "default_2", value: "Feature 2 Active" },
        { id: "default_3", value: "Feature 3 Active" },
        { id: "default_4", value: "Feature 4 Active" },
    ]

    public freeGameReelsID = [
        { id: "freeSpins", value: "Free Spins" },
        { id: "freeSpins_no_feature", value: "Free Spins No Feature" },
    ]

    public baseReelsWeights = {
        _noWin: [100],
        default: [21, 10, 10, 10, 10, 10, 9, 8, 7, 5],
        default_1: [27, 9.5, 9.5, 9.5, 9.5, 9, 8, 7, 6, 5],
        default_2: [21, 10, 10, 10, 10, 10, 9, 8, 7, 5],
        default_3: [63, 5, 5, 5, 5, 5, 4.5, 3.5, 2.5, 1.5],
        default_4: [50.2, 6.3, 6.3, 6.3, 6.3, 6.2, 5.6, 4.8, 4.5, 3.5],
    }

    public freeReelsWeights = {
        freeSpins: [50.2, 6.3, 6.3, 6.3, 6.3, 6.2, 5.6, 4.8, 4.5, 3.5],
        freeSpins_no_feature: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
    }

    public isActive = {
        Feature1: "false",
        Feature2: "false",
        Feature3: "false",
        Feature4: "false"
    }

    public isEnabled = {
        Feature1: false,
        Feature2: false,
        Feature3: false,
        Feature4: false,
    }
    public enableFreeGameFeatures = true;
    public activateFreeSpin = "false";
    public isEnabledFreeSpin = false;
    public isEnabledDynamiteFuse = false;
    public isEnabledToggleFreeSpin = true;
    public freeSpinCount = [0];
    public freeSpinCounts = [
        [0],
        [1],
        [2],
        [3],
        [4],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [1, 2],
        [1, 3],
        [1, 4],
        [2, 3],
        [2, 4],
        [3, 4],
        [0, 1, 2],
        [0, 1, 3],
        [0, 1, 4],
        [0, 2, 3],
        [0, 2, 4],
        [0, 3, 4],
        [1, 2, 3],
        [1, 2, 4],
        [1, 3, 4],
        [2, 3, 4],
        [0, 1, 2, 3],
        [0, 1, 2, 4],
        [0, 1, 3, 4],
        [0, 2, 3, 4],
        [1, 2, 3, 4],
        [0, 1, 2, 3, 4]
    ];
    public isSeniorFeatureActive = "false";
    public isDynamiteWildActive = "false";
    public dynamiteCount = [0];
    public dynamiteCounts = [
        [0],
        [1],
        [2],
        [3],
        [4],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [1, 2],
        [1, 3],
        [1, 4],
        [2, 3],
        [2, 4],
        [3, 4],
        [0, 1, 2],
        [0, 1, 3],
        [0, 1, 4],
        [0, 2, 3],
        [0, 2, 4],
        [0, 3, 4],
        [1, 2, 3],
        [1, 2, 4],
        [1, 3, 4],
        [2, 3, 4]
    ]
    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;

        this.updateLocalVariables();

        bus.$emit("update-feature");
    }

    private updateLocalVariables() {
        this.activeStep = this.step.json.internalName;
        this.reelID = this.step.json.reelID;
        this.reelIndex = this.step.json.reelIndex;

        if (this.activeStep == "FreeGame") {
            this.enableFreeGameFeatures = this.step.json.enableFreeGameFeatures;
        }
        else {
            this.step.json.isDynamiteWildActive = this.getBooleanFromString(this.isDynamiteWildActive);
            this.step.json.activateFreeSpin = this.getBooleanFromString(this.activateFreeSpin);
        }
        for (let i = 1; i <= 4; i++) {
            this.isActive["Feature" + i] = this.step.json["activateFeature" + i].toString();
        }
    }

    public mounted() {
        trace("reels mounted");
    }

    /**
     * Adds a new base game step.
     */
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep('_noWin', 0));
    }

    /**
     * Adds a new free game step.
     */
    public addFreeGameStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeGameStep("freeSpins", 0));
    }

    /**
     * Called whenever user updates the reel id in select box.
     */
    public onChangeReelID() {
        this.step.json.reelID = this.reelID;
        this.reelIndex = "0";
        this.step.json.reelIndex = parseInt(this.reelIndex);

        if (this.activeStep == "FreeGame") {
            this.checkEnableFeatureFG();
        }
        else {
            this.checkEnableFeature();
        }

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public checkEnableFeatureFG() {
        if (this.reelID == "freeSpins") {
            this.enableFreeGameFeatures = true;
            for (let i = 1; i <= 4; i++) {
                this.step.json["activateFeature" + i] = this.getBooleanFromString(this.isActive["Feature" + i]);
            }
        }
        else {
            this.enableFreeGameFeatures = false;
            for (let i = 1; i <= 4; i++) {
                this.step.json["activateFeature" + i] = false;
            }
        }

        this.step.json.enableFreeGameFeatures = this.enableFreeGameFeatures;
    }

    /**
     * Enable or disable features according to the reel config.
     */
    public checkEnableFeature() {
        const reelSelected = this.reelID.split('_')[1] || '0';

        // Disable all features in model except those in reels;
        for (let i = 1; i <= 4; i++) {
            if (i <= parseInt(reelSelected)) {
                this.isEnabled["Feature" + i] = true;
                this.step.json["activateFeature" + i] = this.getBooleanFromString(this.isActive["Feature" + i]);
            }
            else {
                this.isEnabled["Feature" + i] = false;
                this.step.json["activateFeature" + i] = false;
            }
        }

        if (reelSelected == '0') {
            for (let i = 1; i <= 4; i++) {
                this.step.json["activateFeature" + i] = false;
            }
        }
    }

    /**
     * Called whenever user updates the reel index in select box.
     */
    public onChangeReelIndex() {
        this.step.json.reelID = this.reelID;
        this.step.json.reelIndex = parseInt(this.reelIndex);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Called whenever user updates the Activate Free Spin Status.
     */
    public onChangeActivateFreeSpin() {
        this.step.json.activateFreeSpin = this.getBooleanFromString(this.activateFreeSpin);
        if (this.activateFreeSpin === "true") {
            this.isEnabledFreeSpin = true;
            this.step.json.freeSpinCount = this.freeSpinCount;
        }
        else {
            this.isEnabledFreeSpin = false;
            this.step.json.freeSpinCount = undefined;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Called whenever user updates the Dynamite Fuse Count status.
     */
    public onChangeDynamiteFuse() {
        this.step.json.dynamiteCount = this.dynamiteCount;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Called whenever an feature active state is changed.
     */
    public onChangeActiveFeature() {
        for (let i = 1; i <= 4; i++) {
            if ((this.isEnabled["Feature" + i] && this.activeStep == "BaseGame") || (this.enableFreeGameFeatures && this.activeStep == "FreeGame")) {
                this.step.json["activateFeature" + i] = this.getBooleanFromString(this.isActive["Feature" + i]);
            }
        }

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeActiveDynamiteWild() {
        this.step.json.isDynamiteWildActive = this.getBooleanFromString(this.isDynamiteWildActive);
        if (this.isDynamiteWildActive === "true") {
            this.isEnabledDynamiteFuse = true;
            this.step.json.dynamiteCount = this.dynamiteCount;
            this.isEnabledToggleFreeSpin = false;
            this.isEnabledFreeSpin = false;
            this.activateFreeSpin = "false";
        }
        else {
            this.isEnabledDynamiteFuse = false;
            this.step.json.dynamiteCount = undefined;
            this.isEnabledToggleFreeSpin = true;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeFreeSpinCount() {
        this.step.json.freeSpinCount = this.freeSpinCount;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    private getBooleanFromString(str: string): boolean {
        return str.toLowerCase() == "true";
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }
}
