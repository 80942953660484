import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, wrapIndex, rowsFromIndexes, getReelWindowFromIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./../reels/reel.vue";

const baseGameTable = {
    PIC1_patterns:  [11111,11110,11101,11100,11011,11010,11001,11000,10111,10110,10101,10100,10011,10010,10001],
    PIC1_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 75, 75, 80],
    PIC2_patterns:  [22222, 22220, 22202, 22200, 22022, 22020, 22002, 22000, 20222, 20220, 20202, 20200, 20022, 20020, 20002],
    PIC2_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70],
    PIC3_patterns:  [33333, 33330, 33303, 33300, 33033, 33030, 33003, 33000, 30333, 30330, 30303, 30300, 30033, 30030, 30003],
    PIC3_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70],
    PIC4_patterns:  [44444, 44440, 44404, 44400, 44044, 44040, 44004, 44000, 40444, 40440, 40404, 40400, 40044, 40040, 40004],
    PIC4_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70]
};
const featureGameTable = {
    PIC1_patterns:  [11111, 11110, 11101, 11100, 11011, 11010, 11001, 11000, 10111, 10110, 10101, 10100, 10011, 10010, 10001],
    PIC1_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 75, 75, 80],
    PIC2_patterns:  [22222, 22220, 22202, 22200, 22022, 22020, 22002, 22000, 20222, 20220, 20202, 20200, 20022, 20020, 20002],
    PIC2_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70],
    PIC3_patterns:  [33333, 33330, 33303, 33300, 33033, 33030, 33003, 33000, 30333, 30330, 30303, 30300, 30033, 30030, 30003],
    PIC3_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70],
    PIC4_patterns:  [44444, 44440, 44404, 44400, 44044, 44040, 44004, 44000, 40444, 40440, 40404, 40400, 40044, 40040, 40004],
    PIC4_weights:   [1, 2, 5, 5, 25, 30, 50, 50, 10, 25, 50, 50, 70, 70, 70]
};

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public shiftStack = baseGameTable.PIC1_patterns[0];
    public symbolStack = false;
    public show = false;
    public listItems = baseGameTable.PIC1_patterns;
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.shiftStack = step.json.field0;
        this.symbolStack = getSymbolStack(step.json.reelStripPositions, this.isFeature);
        
        if (this.symbolStack) {
            this.listItems = baseGameTable[`${this.symbolStack}_patterns`]; // CHANGE THIS
            this.shiftStack = this.shiftStack == 11111 ? this.listItems[0] : this.shiftStack;
        }
        console.log(step.json);
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        this.step.json.field0 = String(this.shiftStack);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    } 
    public doChangePattern() {
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }
}

const getSymbolStack = (reelStripPositions, isFeature) => {
    const state = State.state;
    const reels = (isFeature ? state.getFeatureReelStrips() : state.getReelStrips())[0];
    const index = reelStripPositions[0];
    const window = getReelWindowFromIndex(reels, index, state.getNumberOfRows());
    const first = window[0];
    const b = first.indexOf("PIC") > -1 && window[1] === first && window[2] === first && window[3] === first;

    return b ? first : null;
}