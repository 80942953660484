// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-928bf500] {
}
.sm-game-spec-content .flex[data-v-928bf500] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-928bf500] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-928bf500] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-928bf500] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-928bf500] {
    margin: 5px 0 5px 0;
}
.sm-game-spec-content .button[data-v-928bf500] {
    padding: 2px;
}
.sm-game-spec-content .text-message[data-v-928bf500] {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0a0a0;
    font-size: 12px;
}
.sm-game-spec-content .optionsContainer[data-v-928bf500] {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333333;
    color: #a0a0a0;
    font-size: 12px;
    height: 60px;
}
.sm-game-spec-content .options[data-v-928bf500] {
    text-align: center;
    line-height: 20px;
    margin: 0 10px 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw034/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".sm-game-spec-content[data-v-928bf500] {\n}\n.sm-game-spec-content .flex[data-v-928bf500] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-928bf500] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-928bf500] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-928bf500] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-928bf500] {\n    margin: 5px 0 5px 0;\n}\n.sm-game-spec-content .button[data-v-928bf500] {\n    padding: 2px;\n}\n.sm-game-spec-content .text-message[data-v-928bf500] {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #a0a0a0;\n    font-size: 12px;\n}\n.sm-game-spec-content .optionsContainer[data-v-928bf500] {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #333333;\n    color: #a0a0a0;\n    font-size: 12px;\n    height: 60px;\n}\n.sm-game-spec-content .options[data-v-928bf500] {\n    text-align: center;\n    line-height: 20px;\n    margin: 0 10px 0 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
