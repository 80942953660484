/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf008_reelberryblast",
        "wildSymbol": "WI1",
        "freeSpinTrigger": "FS1",

        "countToTriggerFreeSpin": 3,
        "freeSpinCount": 10,

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 5, 10, 15, 25 ],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "1500000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "25",
        "coinType4_spin_max_bet": "1000",

        "reels": [
            [ "LV3", "HV5", "LV1", "HV1", "LV4", "WI1", "LV3", "HV1", "HV3", "LV1", "HV4", "LV2", "LV1", "LV3", "HV5", "HV1", "HV3", "LV1", "FS1", "HV3", "LV3", "LV1", "HV5", "HV1", "LV3", "HV5", "LV4", "HV3", "LV1", "HV1", "LV3", "HV5", "HV1", "LV1", "WI1", "HV3", "HV2", "LV3", "LV2", "LV1", "HV1", "HV3", "LV1", "HV2", "HV4", "LV1" ],
            [ "LV2", "HV4", "HV2", "LV2", "HV4", "LV4", "HV3", "FS1", "HV2", "LV4", "HV1", "HV5", "LV1", "HV2", "LV4", "LV2", "HV4", "LV4", "WI1", "LV3", "LV2", "HV2", "HV4", "LV4", "LV2", "HV1", "HV4", "HV2", "LV2", "HV4", "FS1", "HV2", "LV2", "HV4", "LV4", "LV1", "HV2", "HV4", "LV4", "HV5", "HV2", "LV2", "LV4", "WI1", "LV2", "LV4", "LV3", "HV2" ],
            [ "HV2", "LV3", "LV1", "WI1", "LV2", "LV3", "HV5", "LV2", "HV4", "LV1", "LV3", "HV2", "LV4", "LV1", "FS1", "HV5", "LV2", "LV1", "LV4", "HV4", "HV1", "LV1", "LV4", "HV5", "HV1", "LV1", "LV4", "LV3", "WI1", "LV2", "HV1", "HV5", "LV1", "LV3", "HV1", "HV3", "HV5", "LV2", "LV1", "HV3", "HV1", "FS1", "LV2", "LV3", "HV3", "LV1", "HV1", "HV3", "HV5", "LV2", "LV1", "HV3", "LV3", "HV1", "HV3", "LV2", "LV3", "HV3", "HV2", "HV4", "HV3", "HV5", "LV1", "HV3", "HV1", "LV1", "HV3", "HV1", "LV3", "HV3", "HV1", "HV2", "HV3", "LV3", "WI1", "HV1", "LV2", "LV1", "HV5", "HV3", "LV3", "HV4", "HV3", "HV5", "LV2", "HV1", "LV3", "HV5", "LV1", "HV1", "LV3", "LV2", "FS1", "HV1", "LV3", "HV2", "LV2", "LV4" ],
            [ "LV4", "LV2", "HV3", "LV1", "FS1", "LV3", "HV2", "LV4", "LV1", "HV4", "LV3", "LV1", "HV2", "LV4", "LV2", "LV3", "HV5", "LV2", "LV4", "LV1", "HV1", "WI1", "LV3", "LV1", "HV3", "LV3", "LV2", "LV4", "HV4", "HV3", "LV1", "LV2", "LV4", "LV3", "HV3", "LV1", "LV3", "FS1", "LV2", "LV4", "HV2", "LV3", "HV1", "HV2", "LV1", "HV4", "LV2", "HV3" ],
            [ "LV1", "HV2", "LV2", "LV4", "LV1", "HV1", "LV3", "HV4", "FS1", "LV4", "HV3", "LV1", "LV2", "LV3", "HV2", "LV1", "LV3", "LV4", "HV1", "LV1", "LV3", "LV2", "HV1", "LV4", "HV2", "HV3", "HV1", "LV2", "HV3", "LV3", "HV5", "LV4", "HV1", "LV1", "WI1", "LV3", "LV4", "HV4", "LV2", "HV1", "LV4", "HV2", "HV3", "LV3", "LV4", "HV3", "HV1", "LV2", "LV1", "HV3", "LV4", "LV3", "HV2", "HV3", "LV2" ]
        ],

        "reelsSetTwo": [
            [ "LV3", "HV5", "LV1", "HV1", "LV4", "HV3", "LV3", "HV1", "HV3", "LV1", "HV4", "LV2", "LV1", "LV3", "HV5", "HV1", "HV3", "LV1", "FS1", "HV3", "LV3", "LV1", "HV5", "HV1", "LV3", "HV5", "LV4", "HV3", "LV1", "HV1", "LV3", "HV5", "HV1", "LV1", "LV4", "HV3", "HV2", "LV3", "LV2", "LV1", "HV1", "HV3", "LV1", "HV2", "HV4", "LV1" ],
            [ "LV2", "HV4", "HV2", "LV2", "HV4", "LV4", "HV3", "FS1", "HV2", "LV4", "HV1", "HV5", "LV1", "HV2", "LV4", "LV2", "HV4", "LV4", "HV3", "LV3", "LV2", "HV2", "HV4", "LV4", "LV2", "HV1", "HV4", "HV2", "LV2", "HV4", "FS1", "HV2", "LV2", "HV4", "LV4", "LV1", "HV2", "HV4", "LV4", "HV5", "HV2", "LV2", "LV4", "HV4", "LV2", "LV4", "LV3", "HV2" ],
            [ "HV2", "LV3", "LV1", "HV1", "LV2", "LV3", "HV5", "LV2", "HV4", "LV1", "LV3", "HV2", "LV4", "LV1", "FS1", "HV5", "LV2", "LV1", "LV4", "HV4", "HV1", "LV1", "LV4", "HV5", "HV1", "LV1", "LV4", "LV3", "WI1", "LV2", "HV1", "HV5", "LV1", "LV3", "HV1", "HV3", "HV5", "LV2", "LV1", "HV3", "HV1", "FS1", "LV2", "LV3", "HV3", "LV1", "HV1", "HV3", "HV5", "LV2", "LV1", "HV3", "LV3", "HV1", "HV3", "LV2", "LV3", "HV3", "HV2", "HV4", "HV3", "HV5", "LV1", "HV3", "HV1", "LV1", "HV3", "HV1", "LV3", "HV3", "HV1", "HV2", "HV3", "LV3", "LV4", "HV1", "LV2", "LV1", "HV5", "HV3", "LV3", "HV4", "HV3", "HV5", "LV2", "HV1", "LV3", "HV5", "LV1", "HV1", "LV3", "LV2", "FS1", "HV1", "LV3", "HV2", "LV2", "LV4" ],
            [ "LV4", "LV2", "HV3", "LV1", "FS1", "LV3", "HV2", "LV4", "LV1", "HV4", "LV3", "LV1", "HV2", "LV4", "LV2", "LV3", "HV5", "LV2", "LV4", "LV1", "HV1", "HV2", "LV3", "LV1", "HV3", "LV3", "LV2", "LV4", "HV4", "HV3", "LV1", "LV2", "LV4", "LV3", "HV3", "LV1", "LV3", "FS1", "LV2", "LV4", "HV2", "LV3", "HV1", "HV2", "LV1", "HV4", "LV2", "HV3" ],
            [ "LV1", "HV2", "LV2", "LV4", "LV1", "HV1", "LV3", "HV4", "FS1", "LV4", "HV3", "LV1", "LV2", "LV3", "HV2", "LV1", "LV3", "LV4", "HV1", "LV1", "LV3", "LV2", "HV1", "LV4", "HV2", "HV3", "HV1", "LV2", "HV3", "LV3", "HV5", "LV4", "HV1", "LV1", "LV2", "LV3", "LV4", "HV4", "LV2", "HV1", "LV4", "HV2", "HV3", "LV3", "LV4", "HV3", "HV1", "LV2", "LV1", "HV3", "LV4", "LV3", "HV2", "HV3", "LV2" ]
        ],

        "featureReels": [
            [ "LV1", "HV2", "HV1", "HV3", "LV3", "HV5", "WI1", "HV4", "LV1", "LV2", "LV3", "LV1", "LV2", "LV4", "HV1", "LV1", "HV3", "LV3", "WI1", "LV1", "LV3", "HV4", "LV4", "HV5", "HV2", "WI1", "LV4", "HV3", "HV5", "LV1", "LV2", "HV1", "HV4", "FS1", "LV2", "LV1", "LV4", "HV3", "LV2", "HV5", "HV4", "WI1", "LV3", "LV4", "HV1" ],
            [ "LV3", "HV5", "LV2", "HV3", "LV1", "LV2", "HV5", "LV3", "HV2", "WI1", "HV4", "LV2", "LV4", "LV3", "HV3", "LV1", "HV2", "LV2", "HV5", "HV1", "LV1", "FS1", "LV2", "HV4", "LV1", "HV3", "HV2", "HV5", "LV1", "HV2", "LV2", "LV4", "LV3", "WI1", "HV1", "LV4", "HV2", "HV4", "LV4", "LV3", "LV1", "HV4", "LV3", "HV3", "HV1" ],
            [ "HV1", "LV1", "LV3", "WI1", "LV1", "HV1", "HV5", "LV3", "HV2", "LV1", "LV2", "HV5", "HV4", "WI1", "HV2", "LV4", "HV1", "LV3", "HV3", "FS1", "HV1", "LV2", "HV2", "HV3", "LV2", "LV3", "LV4", "HV1", "WI1", "LV3", "LV2", "HV4", "LV1", "HV3", "LV3", "LV4", "HV2", "LV2", "HV5", "FS1", "LV4", "HV5", "LV1", "HV4", "HV2" ],
            [ "HV5", "WI1", "LV2", "HV2", "LV4", "HV4", "HV1", "LV1", "HV5", "LV2", "LV3", "LV1", "HV3", "HV2", "FS1", "HV5", "LV1", "LV4", "LV3", "HV3", "HV1", "HV4", "HV2", "LV1", "LV4", "LV2", "HV5", "HV4", "HV2", "LV4", "LV1", "FS1", "LV3", "HV3", "LV2", "HV4", "LV4", "HV2", "HV1", "HV5", "HV4", "LV3", "LV4", "HV4", "LV1" ],
            [ "HV3", "LV4", "HV1", "FS1", "HV2", "LV2", "HV4", "HV2", "HV3", "LV1", "HV5", "HV1", "LV4", "LV1", "HV3", "HV1", "LV1", "FS1", "HV5", "HV3", "LV1", "HV4", "LV2", "LV1", "HV2", "LV2", "HV1", "LV3", "HV3", "WI1", "LV3", "LV4", "HV5", "HV4", "LV4", "HV5", "HV1", "LV3", "HV2", "LV1", "LV2", "HV3", "WI1", "HV4", "HV5" ]
        ],

        "baseGameWeights": [5134, 4866],

        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [10, 5, 3]
            }, {
                "symbol": "LV2",
                "multipliers": [10, 5, 3]
            }, {
                "symbol": "LV3",
                "multipliers": [12, 6, 4]
            }, {
                "symbol": "LV4",
                "multipliers": [12, 6, 4]
            }, {
                "symbol": "HV1",
                "multipliers": [40, 15, 10]
            }, {
                "symbol": "HV2",
                "multipliers": [60, 25, 15]
            }, {
                "symbol": "HV3",
                "multipliers": [80, 30, 20]
            }, {
                "symbol": "HV4",
                "multipliers": [100, 40, 25]
            }, {
                "symbol": "HV5",
                "multipliers": [500, 100, 50]
            }, {
                "symbol": "WI1",
                "multipliers": [500, 100, 50]
            }
        ],

        "playLines": [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],

            [0, 1, 0, 1, 0],
            [0, 1, 1, 1, 0],
            [1, 0, 0, 0, 1],
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],

            [2, 1, 2, 1, 2],
            [1, 2, 2, 2, 1],
            [2, 1, 1, 1, 2],
            [1, 0, 1, 2, 1],
            [1, 2, 1, 0, 1],

            [0, 0, 1, 2, 2],
            [2, 2, 1, 0, 0],
            [2, 0, 0, 0, 2],
            [0, 2, 2, 2, 0],
            [0, 2, 0, 2, 0],

            [2, 0, 2, 0, 2],
            [2, 0, 1, 0, 2],
            [0, 2, 1, 2, 0],
            [1, 0, 2, 0, 1],
            [1, 2, 0, 2, 1]
        ],

        "reelsLayout": [3, 3, 3, 3, 3],

        "minimumReSpinFSCount": 3,

        "freeSpinCountArray": [ 0, 0, 0, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 75, 100 ]

    },
}
