import Vue, { PropType } from "vue";
import { SpinOutcome } from "../model/operations/play";
import { ReelSymbol } from "./reel-symbol";
import { modelDefinition } from "../model/model-definition";

export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<
      SpinOutcome & { winCells: [number, number][] }
    >,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    isFreeSpins: function (): boolean {
      return this.spinOutcome.gameResponse.freeSpins?.phase !== undefined;
    },
    isPickSpin: function (): boolean {
      const picks = this.spinOutcome.gameResponse.picks;
      if (!picks) return false;

      return picks.phase !== "START";
    },
    spinType: function () {
      const gameResponse = this.spinOutcome.gameResponse;
      if (gameResponse.jackpotPhase && gameResponse.jackpotPhase !== "START") {
        return "JACKPOT_SPIN";
      } else if (gameResponse.picks && gameResponse.picks.phase !== "START") {
        return "PICK_SPIN";
      } else if (
        gameResponse.freeSpins?.phase &&
        gameResponse.freeSpins.phase !== "START"
      ) {
        return "FREE_SPINS";
      }
      return "BASE";
    },
  },
  methods: {
    isWinCell: function (rowIndex: number, colIndex: number): boolean {
      return this.spinOutcome.winCells[colIndex]?.[rowIndex] === 1;
    },
  },
});
