var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "fs-selector sm-label cardSpace" }, [
    _c("div", [
      _c("label", { attrs: { for: _vm.index } }, [
        _vm._v("#" + _vm._s(_vm.index) + ": "),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.expandingSymbols[_vm.index - 1],
              expression: "expandingSymbols[index - 1]",
            },
          ],
          attrs: { id: _vm.index },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.expandingSymbols,
                  _vm.index - 1,
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              function ($event) {
                return _vm.updateExpandingSymbol(_vm.index - 1)
              },
            ],
          },
        },
        [
          _c("option", { attrs: { value: "H1" } }, [_vm._v("H1")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "H2" } }, [_vm._v("H2")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "H3" } }, [_vm._v("H3")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "H4" } }, [_vm._v("H4")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "L1" } }, [_vm._v("L1")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "L2" } }, [_vm._v("L2")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "L3" } }, [_vm._v("L3")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "L4" } }, [_vm._v("L4")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "L5" } }, [_vm._v("L5")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }