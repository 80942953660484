var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBigWinStep()
              },
            },
          },
          [_vm._v("BigWin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFeatureTrigger,
              expression: "!isFeatureTrigger",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c(
            "div",
            {
              class: [
                "button",
                "step-button",
                "pointer",
                _vm.isFeature ? "btn-toggle-is-feature" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.toggleFeature()
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature,
              expression: "isFeature",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "small", attrs: { align: "center" } }, [
            _c("div", { staticClass: "small", attrs: { align: "center" } }, [
              _vm._v("Ups:"),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.upsCount2,
                      expression: "upsCount2",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.upsCount2 = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.doChangeUpsDuringFeatureStep()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeatureTrigger && !_vm.isFeature,
              expression: "isFeatureTrigger && !isFeature",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", [
            _c("div", { staticClass: "small" }, [_vm._v("FreeSpinCount:")]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.freeSpinCount,
                      expression: "freeSpinCount",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.freeSpinCount = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.doChangeFreeSpinCount()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "small" }, [_vm._v("Ups:")]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.upsCount,
                      expression: "upsCount",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.upsCount = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.doChangeFreeSpinCount()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "small" }, [_vm._v("Multipliers:")]),
            _vm._v(" "),
            _c("div", { staticClass: "select-style" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.multiplierCount,
                      expression: "multiplierCount",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.multiplierCount = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.doChangeFreeSpinCount()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(Number(_vm.upsCount2), function (up) {
        return _c("div", { key: "up" + up }, [
          up > 0 && Number(_vm.upsCount) > 1
            ? _c("div", { staticClass: "flex-center upsep" }, [
                _vm._v("\n            Up: " + _vm._s(up) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center" },
            _vm._l(_vm.reelsNo, function (reelIndex) {
              return _c(
                "div",
                { key: "reel-" + up + "r" + reelIndex },
                [
                  _c("reel", {
                    attrs: {
                      index: reelIndex - 1,
                      upindex: up - 1,
                      step: _vm.step,
                      isFeature: _vm.isFeature,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mid" }, [
      _vm._v(
        "\n                Manually set the ups depending on the value you set on the trigger \n                "
      ),
      _c("br"),
      _vm._v("(it must be the same amount)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }