/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf102",
        "reels": [],
        "reelsLayout": []
    }
};

