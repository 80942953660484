/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId" : "tf104",

    "betMultiplier" : 30,
    "reelsLayout" : [ 3, 3, 3 ],
    "scatterSymbol" : "S",
    "wildSymbol" : "Z",
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 10,

    "playLines": [],

    "winTable" : [ {
        "symbol" : "S",
        "multipliers" : [ 6 ]
    }, {
        "symbol" : "B",
        "multipliers" : [ 8 ]
    }, {
        "symbol" : "C",
        "multipliers" : [ 5 ]
    }, {
        "symbol" : "D",
        "multipliers" : [ 4 ]
    }, {
        "symbol" : "E",
        "multipliers" : [ 4 ]
    }, {
        "symbol" : "A",
        "multipliers" : [ 3 ]
    }, {
        "symbol" : "K",
        "multipliers" : [ 3 ]
    }, {
        "symbol" : "Q",
        "multipliers" : [ 2 ]
    }, {
        "symbol" : "J",
        "multipliers" : [ 2 ]
    }, {
        "symbol" : "T",
        "multipliers" : [ 1 ]
    }, {
        "symbol" : "N",
        "multipliers" : [ 1 ]
    } ],

    "reels" : [ 
    ["N","N","D","D","K","K","J","J","T","T","C","C","T","T","D","D","Q","Q","A","A","C","C","J","J","J","A","A","N","N","N","D","D","D","J","J","E","E","N","N","Q","Q","C","C","J","J","J","E","E","K","K","A","A","D","D","Q","Q","J","J","E","E","N","N","Q","Q","K","K","N","N","C","C","Q","Q","Q","D","D","T","T","T","A","A","Q","Q","Q","E","E","A","A","C","C","T","T","D","D","A","A","T","T","T","C","C","J","J","T","T","T","J","J","K","K","B","B","A","A","E","E","K","K","D","D","A","A","K","K","K","B","B","Q","Q","E","E","N","N","T","T","B","B","N","N","E","E","S","S","S","K","K","K","N","N","N"],
    ["C","C","K","K","D","D","J","J","A","A","T","T","T","S","S","E","E","J","J","C","C","A","A","E","E","Q","Q","Z","Z","J","J","D","D","A","A","B","B","S","S","Q","Q","Z","Z","N","N","E","E","E","T","T","K","K","Q","Q","Z","Z","J","J","Q","Q","K","K","J","J","Z","Z","Q","Q","T","T","Z","Z","N","N","K","K","B","B","T","T","N","N","Q","Q","Z","Z","K","K","D","D","D","T","T","T","Q","Q","D","D","D","J","J","Z","Z","T","T","A","A","Z","Z","N","N","A","A","C","C","N","N","Z","Z","K","K","J","J","T","T","Z","Z","S","S","A","A","E","E","C","C","K","K","B","B","C","C","T","T","T","A","A","Z","Z","N","N","C","C","E","E","Z","A","A","A","K","K","Z","Z","Q","Q","N","N","Z","Z","T","T","S","S","S","Z","Z","J","J","N","N","N","B","D","D","J","J","Z","Z","N","N"],
    ["J","J","N","N","N","E","E","A","A","D","D","J","J","T","T","C","C","J","J","J","N","N","K","K","T","T","T","E","E","A","A","J","J","A","A","K","K","K","N","D","D","J","J","J","Q","Q","Q","B","B","B","Q","Q","T","T","N","N","Q","Q","D","D","T","T","Z","Z","N","N","D","D","N","N","Z","Z","S","S","S","S","Z","Z","T","T","D","D","K","K","B","B","T","T","K","K","Z","Z","N","N","C","C","C","T","T","Z","Z","J","J","Q","Q","Z","B","K","K","Q","Q","Z","Z","N","N","E","E","Z","Q","Q","K","K","A","A","A","Q","Q","Z","Z","T","T","E","E","K","K","K","Z","A","A","A","T","T","Z","E","E","J","J","A","A","Z","Z","N","N","J","J","C","C","N","N","Z","Z","A","A","T","E","E","C","C","D","D","Q","Q","Z","Z","A","A","C","C","Q","Q","D","D","B","T","T","T","Q","Q","Z","Z","K","K","N","N"],

    ["A","A","N","N","E","E","A","A","C","C","N","N","J","J","N","N","K","K","J","J","Z","Z","K","K","A","A","Z","J","J","A","A","Q","Q","Z","J","J","K","K","Z","T","T","D","D","Q","Q","B","B","J","J","S","S","D","D","N","N","B","B","T","T","J","J","N","N","Z","Z","T","T","S","S","S","S","Z","N","N","T","T","Q","Q","B","B","T","T","N","N","C","C","T","T","K","K","N","N","J","J","Q","Q","B","B","K","K","Q","Q","Z","Z","N","N","A","A","Z","Z","K","K","A","A","E","E","Q","Q","Z","Z","S","S","Z","T","T","E","E","T","T","Z","Z","Q","Q","E","E","T","T","Z","Z","A","A","J","J","Q","Q","Z","J","J","C","C","D","D","T","T","C","C","E","E","N","N","K","K","C","C","D","D","K","K","Z","Z","Q","Q","D","D"],
    ["E","E","E","C","C","C","J","J","J","J","N","N","N","Z","Z","Z","K","K","A","A","A","J","J","J","K","K","A","A","A","J","J","B","B","B","S","S","S","S","N","N","N","B","B","T","T","J","J","N","N","N","N","T","T","S","S","S","S","N","N","T","T","T","Q","Q","Q","T","T","N","N","C","C","T","T","T","K","K","K","K","N","N","N","J","J","J","Q","Q","B","B","K","K","K","Q","Q","Q","N","N","N","N","A","A","Z","Z","Z","K","K","A","A","E","E","Q","Q","Z","Z","Z","Z","T","T","T","T","E","E","Q","Q","Q","Q","T","T","Z","Z","A","A","J","J","Z","Z","D","D","N","N","N","J","J","J","J","C","C","D","D","T","T","T","C","C","E","E","K","K","K","D","D","D","A","A","Z","Z","Q","Q","D","D"],
    ["A","A","N","N","E","E","A","A","C","C","N","N","J","J","N","N","K","K","J","J","K","K","A","A","J","J","A","A","Q","Q","J","J","K","K","T","T","D","D","Q","Q","B","B","J","J","S","S","D","D","N","N","B","B","T","T","J","J","N","N","Z","Z","T","T","S","S","S","S","N","N","T","T","Q","Q","B","B","T","T","N","N","C","C","T","T","K","K","N","N","J","J","Q","Q","B","B","K","K","Q","Q","Z","Z","N","N","A","A","K","K","A","A","E","E","Q","Q","Z","Z","S","S","T","T","E","E","T","T","Q","Q","E","E","T","T","Z","Z","A","A","J","J","Q","Q","J","J","C","C","D","D","T","T","C","C","E","E","N","N","K","K","C","C","D","D","K","K","Q","Q","D","D"],
    ["Z","A","N","J","T","C","N","J","A","K","Z","E","N","J","A","Q","Z","K","N","J","T","C","D","Q","B","J","S","T","N","B","D","Q","A","K","N","Z","T","S","S","N","T","Q","B","A","N","C","T","B","Q","J","K","Q","Z","N","A","K","T","Z","E","Q","A","S","Z","T","E","J","Q","E","T","Z","A","J","Q","N","Z","K","D","J","T","C","E","N","K","D","T","C","K","Q","J","D"],
    ],

    "reels_fs" : [ 
    ["N","N","D","D","K","K","J","J","T","T","C","C","T","T","D","D","Q","Q","A","A","C","C","J","J","J","A","A","N","N","N","D","D","D","J","J","E","E","N","N","Q","Q","S","S","C","C","J","J","J","E","E","K","K","A","A","D","D","Q","Q","J","J","E","E","N","N","Q","Q","K","K","E","E","C","C","Q","Q","Q","D","D","T","T","T","A","A","Q","Q","Q","E","E","A","A","C","C","T","T","D","D","A","A","T","T","T","C","C","J","J","T","T","J","J","K","K","B","B","A","A","E","E","K","K","D","D","D","A","A","K","K","K","B","B","Q","Q","E","E","N","N","T","T","B","B","N","N","E","E","S","S","S","S","K","K","K","N","N","N"],
    ["C","C","K","K","D","D","Z","Z","A","A","T","T","Z","Z","E","E","J","J","C","C","A","A","E","E","Z","Z","Q","Q","J","J","D","D","A","A","B","B","S","S","Q","Q","Z","Z","N","N","E","E","E","S","S","S","S","Z","Z","K","K","Q","Q","Z","Z","J","J","Q","Q","K","K","J","J","Z","Z","Z","Z","T","T","T","Q","Q","Z","Z","N","N","N","B","B","K","K","Z","Z","Q","Q","K","K","Z","Z","D","D","T","T","T","Z","Z","D","D","Q","Q","J","J","Z","Z","T","T","A","A","Z","Z","N","N","A","A","C","C","N","N","Z","Z","Z","K","K","J","J","A","A","T","T","Z","S","S","Z","Z","E","E","C","C","K","K","B","B","A","A","C","C","T","T","T","Z","Z","N","N","C","C","E","E","Z","Z","K","K","A","A","A","Z","Z","Q","Q","N","N","Z","Z","T","T","S","S","Z","Z","J","J","N","N","N","D","D","J","J","Z","Z","N","N"],
    ["J","J","N","N","Z","E","E","A","A","Z","Z","J","J","T","T","Z","Z","N","N","K","K","J","J","Z","Z","T","T","E","E","A","A","Z","Z","J","J","K","K","K","D","D","D","J","J","Z","Z","Q","Q","Q","B","B","B","Q","Q","S","S","S","N","N","Z","Z","D","D","T","T","Z","Z","N","N","D","D","N","N","Z","Z","S","S","S","S","Z","Z","Z","T","T","D","D","K","K","B","B","T","T","Z","Z","Z","N","N","C","C","C","K","K","Z","Z","J","J","Q","Q","B","B","K","K","Q","Q","Z","Z","N","N","E","Z","E","K","K","Z","Z","A","A","A","Q","Q","Z","Z","T","T","T","Q","Q","Z","Z","T","T","E","E","K","K","Z","Z","E","E","J","J","J","A","A","Z","Z","J","J","C","C","N","N","Z","Z","A","A","E","E","E","C","C","D","D","Q","Q","Z","Z","A","A","C","C","A","A","Z","Z","D","D","B","T","T","T","Z","Z","Q","Q","N","N","Z","Z"],

    ["A","A","Z","Z","N","N","E","E","A","A","C","C","N","N","J","J","Z","Z","N","N","K","K","J","J","Z","Z","K","K","A","A","Z","Z","J","J","A","A","Q","Q","Z","J","J","K","K","Z","T","T","D","D","Q","Q","B","B","J","J","S","S","D","D","N","N","B","B","T","T","D","D","N","N","Z","Z","T","T","S","S","S","S","Z","N","N","T","T","Q","Q","B","B","T","T","N","N","C","C","T","T","K","K","Z","Z","J","J","Q","Q","B","B","K","K","Q","Q","Z","Z","N","N","E","E","Z","Z","K","K","A","A","E","E","Q","Q","Z","Z","S","S","T","T","Z","E","E","T","T","Z","Z","Q","Q","T","T","Z","Z","E","E","J","J","A","A","Z","J","J","C","C","D","D","T","T","C","C","E","E","N","N","K","K","C","C","D","D","K","K","Z","Z","Q","Q","D","D"],
    ["E","E","E","C","C","C","J","J","J","J","N","N","N","Z","Z","Z","K","K","A","A","A","J","J","J","K","K","A","A","A","J","J","B","B","B","S","S","S","S","N","N","N","B","B","T","T","J","J","N","N","N","N","T","T","S","S","S","S","N","N","T","T","T","Q","Q","Q","T","T","N","N","C","C","T","T","T","K","K","K","K","N","N","N","J","J","J","Q","Q","B","B","K","K","K","Q","Q","Q","N","N","N","N","A","A","Z","Z","Z","K","K","A","A","E","E","Q","Q","Z","Z","Z","Z","T","T","T","T","E","E","Q","Q","Q","Q","T","T","Z","Z","A","A","J","J","Z","Z","D","D","N","N","N","J","J","J","J","C","C","D","D","T","T","T","C","C","E","E","K","K","K","D","D","D","A","A","Z","Z","Q","Q","D","D"],
    ["A","A","N","N","E","E","A","A","C","C","N","N","J","J","Z","Z","N","N","K","K","J","J","K","K","A","A","J","J","A","A","Q","Q","J","J","K","K","T","T","D","D","Q","Q","B","B","J","J","S","S","D","D","N","N","B","B","T","T","D","D","N","N","Z","Z","T","T","S","S","S","S","N","N","T","T","Q","Q","B","B","T","T","N","N","C","C","T","T","K","K","J","J","Q","Q","B","B","K","K","Q","Q","Z","Z","N","N","E","E","K","K","A","A","E","E","Q","Q","Z","Z","S","S","T","T","E","E","T","T","Q","Q","T","T","E","E","J","J","A","A","J","J","C","C","D","D","T","T","C","C","E","E","N","N","K","K","C","C","D","D","K","K","Z","Z","Q","Q","D","D"],
    ["Z","A","N","J","T","C","N","J","A","K","Z","E","N","J","A","Q","Z","K","N","J","T","C","D","Q","B","J","S","T","N","B","D","Q","A","K","N","Z","T","S","S","N","T","Q","B","A","N","C","T","B","Q","J","K","Q","Z","N","A","K","T","Z","E","Q","A","S","Z","T","E","J","Q","E","T","Z","A","J","Q","N","Z","K","D","J","T","C","E","N","K","D","T","Z","K","Q","J","D"],
    ],

    "reelProb": [
        {"reels": [3,4  ], "table": [{ "option": 0,"weight": 65 }, {"option": 1,"weight": 15}, {"option": 2,"weight": 15}, {"option": 3,"weight": 5 }]},
        {"reels": [5    ], "table": [{ "option": 0,"weight": 65 }, {"option": 1,"weight": 10}, {"option": 2,"weight": 15}, {"option": 3,"weight": 10}]},
        {"reels": [6    ], "table": [{ "option": 0,"weight": 60 }, {"option": 1,"weight": 15}, {"option": 2,"weight": 15}, {"option": 3,"weight": 10}]},
        {"reels": [7    ], "table": [{ "option": 0,"weight": 50 }, {"option": 1,"weight": 20}, {"option": 2,"weight": 20}, {"option": 3,"weight": 10}]},
        {"reels": [8,9  ], "table": [{ "option": 0,"weight": 35 }, {"option": 1,"weight": 20}, {"option": 2,"weight": 35}, {"option": 3,"weight": 10}]},
        {"reels": [10,11], "table": [{ "option": 0,"weight": 30 }, {"option": 1,"weight": 25}, {"option": 2,"weight": 35}, {"option": 3,"weight": 10}]},
        {"reels": [12,13], "table": [{ "option": 0,"weight": 25 }, {"option": 1,"weight": 15}, {"option": 2,"weight": 50}, {"option": 3,"weight": 10}]},
        {"reels": [14   ], "table": [{ "option": 0,"weight": 15 }, {"option": 1,"weight": 15}, {"option": 2,"weight": 35}, {"option": 3,"weight": 35}]},
    ],

    "freespinProb": [
        {"table": [{ "level": 1,"weight": 25 }, {"level": 2,"weight": 25}, {"level": 3,"weight": 25}, {"level": 4,"weight": 25 }]},
        {"table": [{ "count": 8,"weight": 33.3333333 }, {"count": 10,"weight": 50}, {"count": 12,"weight": 0.166666667 }]},
    ],

    "coinType1Values" : [ 100, 500, 2000, 5000, 20000, 50000, 500000 ],
    "coinType4Values" : [ 1, 5, 15, 25, 50, 150, 250, 500 ],
    "coinType1_min_bet" : 100,
    "coinType1_max_bet" : 500000,
    "coinType1_spin_max_bet" : 10000000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 500,
    "coinType4_spin_max_bet" : 10000,
    "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
    }, {
        "name" : "normal2",
        "threshold" : 15
    }, {
        "name" : "normal3",
        "threshold" : 25
    }, {
        "name" : "bigwin",
        "threshold" : 50
    }, {
        "name" : "megawin",
        "threshold" : 150
    }, {
        "name" : "epicwin",
        "threshold" : 2147483647
    } ],

    "countToTriggerFreeSpin" : 5,
    "countReelWin" : 3,
    "jackpotMultiplier" : 888,
    "countJackpotWin" : 12,
    


















        "reelsFS1": [
            ["N", "N", "N", "N", "A", "A", "J", "J", "J", "J", "Q", "Q", "Q", "Q", "C", "C", "J", "J", "J", "J", "K", "K", "D", "D", "J", "J", "N", "N", "E", "E", "E", "E", "A", "A", "N", "N", "T", "T", "T", "T", "Q", "Q", "N", "N", "N", "N", "C", "C", "C", "C", "S", "S", "S", "S", "D", "D", "B", "B", "C", "C", "N", "N", "N", "N", "E", "E", "N", "N", "N", "N", "J", "J", "S", "S", "T", "T", "D", "D", "D", "D", "T", "T", "A", "A", "A", "A", "B", "B", "B", "B", "Q", "Q", "K", "K", "A", "A", "Q", "Q", "T", "T", "E", "E", "J", "J", "T", "T", "T", "T", "E", "E", "E", "E", "D", "D", "D", "D", "K", "K", "K", "K", "Q", "Q", "C", "C", "C", "C", "A", "A", "A", "A", "N", "N", "K", "K", "T", "T", "K", "K", "A", "A", "N", "N", "Q", "Q", "Q", "Q", "J", "J", "K", "K", "K", "K"],
            ["N", "N", "N", "N", "A", "A", "A", "A", "Z", "Z", "N", "N", "A", "A", "T", "T", "T", "T", "D", "D", "N", "N", "Z", "Z", "Z", "Z", "K", "K", "E", "E", "E", "E", "K", "K", "B", "B", "B", "B", "T", "T", "T", "T", "C", "C", "T", "T", "B", "B", "A", "A", "S", "S", "Z", "Z", "Z", "Z", "K", "K", "J", "J", "J", "J", "Z", "Z", "Z", "Z", "N", "N", "A", "A", "Z", "Z", "Z", "Z", "T", "T", "K", "K", "J", "J", "E", "E", "J", "J", "Q", "Q", "Z", "Z", "Q", "Q", "Z", "Z", "C", "C", "C", "C", "Z", "Z", "D", "D", "D", "D", "C", "C", "Q", "Q", "Q", "Q", "Z", "Z", "E", "E", "Z", "Z", "E", "E", "E", "E", "Q", "Q", "Q", "Q", "D", "D", "A", "A", "A", "A", "N", "N", "N", "N", "D", "D", "D", "D", "Z", "Z", "N", "N", "N", "N", "S", "S", "S", "S", "K", "K", "K", "K", "C", "C", "C", "C", "J", "J", "Q", "Q", "N", "N", "N", "N", "J", "J", "J", "J", "N", "N", "T", "T", "Q", "Q", "K", "K", "K", "K", "Z", "Z", "J", "J", "A", "A", "Z", "Z", "Z", "Z", "T", "T"],
            ["B", "B", "N", "N", "Q", "Q", "Q", "Q", "B", "B", "B", "B", "N", "N", "N", "N", "A", "A", "Z", "Z", "K", "K", "T", "T", "Z", "Z", "Q", "Q", "Q", "Q", "N", "N", "C", "C", "C", "C", "K", "K", "N", "N", "T", "T", "T", "T", "Z", "Z", "Z", "Z", "J", "J", "J", "J", "S", "S", "S", "S", "B", "B", "Z", "Z", "Z", "Z", "J", "J", "A", "A", "T", "T", "T", "T", "Z", "Z", "Q", "Q", "Q", "Q", "J", "J", "Q", "Q", "J", "J", "A", "A", "A", "A", "J", "J", "J", "J", "K", "K", "Z", "Z", "Z", "Z", "A", "A", "A", "A", "Z", "Z", "T", "T", "Z", "Z", "Z", "Z", "C", "C", "E", "E", "E", "E", "T", "T", "Z", "Z", "D", "D", "D", "D", "Z", "Z", "K", "K", "Q", "Q", "D", "D", "N", "N", "K", "K", "T", "T", "Q", "Q", "N", "N", "K", "K", "Z", "Z", "T", "T", "T", "T", "N", "N", "N", "N", "J", "J", "B", "B", "B", "B", "S", "S", "C", "C", "K", "K", "K", "K", "N", "N", "C", "C", "Q", "Q", "Q", "Q", "T", "T", "Q", "Q", "D", "D", "J", "J", "E", "E", "E", "E", "Z", "Z", "D", "D", "D", "D", "C", "C", "C", "C", "A", "A", "A", "A", "K", "K", "N", "N", "N", "N", "C", "C", "K", "K", "K", "K", "A", "A", "N", "N", "T", "T", "D", "D", "T", "T", "D", "D", "D", "D", "J", "J", "J", "J", "E", "E", "E", "E", "J", "J", "J", "J", "Z", "Z", "T", "T", "K", "K", "K", "K", "Q", "Q", "B", "B", "Z", "Z", "E", "E", "K", "K", "K", "K", "C", "C", "C", "C", "A", "A", "Z", "Z", "Z", "Z", "B", "B", "B", "B", "E", "E", "A", "A", "Z", "Z", "Z", "Z", "A", "A", "T", "T", "T", "T", "Q", "Q", "K", "K", "Z", "Z", "E", "E", "N", "N", "N", "N", "E", "E", "N", "N", "A", "A", "Q", "Q", "D", "D", "N", "N", "J", "J", "N", "N", "N", "N", "T", "T", "A", "A", "A", "A", "Z", "Z", "D", "D", "D", "D", "N", "N", "N", "N", "C", "C", "C", "C", "E", "E", "E", "E", "Z", "Z", "Z", "Z", "J", "J", "Z", "Z", "A", "A", "Q", "Q", "Z", "Z", "Z", "Z", "N", "N", "N", "N", "Z", "Z", "N", "N", "N", "N", "Z", "Z", "Z", "Z", "J", "J", "Z", "Z", "N", "N"]
        ],
        "reelsFS2": [
            ["K", "K", "B", "B", "A", "A", "D", "D", "D", "D", "C", "C", "C", "C", "N", "N", "N", "N", "Q", "Q", "N", "N", "N", "N", "K", "K", "K", "K", "Q", "Q", "S", "S", "S", "S", "K", "K", "T", "T", "T", "T", "E", "E", "E", "E", "C", "C", "J", "J", "J", "J", "N", "N", "D", "D", "D", "D", "N", "N", "T", "T", "E", "E", "E", "E", "T", "T", "J", "J", "T", "T", "E", "E", "N", "N", "N", "N", "K", "K", "K", "K", "A", "A", "N", "N", "J", "J", "B", "B", "B", "B", "S", "S", "K", "K", "A", "A", "K", "K", "D", "D", "J", "J", "N", "N", "N", "N", "A", "A", "J", "J", "A", "A", "A", "A", "T", "T", "Q", "Q", "Q", "Q", "C", "C", "J", "J", "J", "J", "E", "E", "Q", "Q", "D", "D", "A", "A", "A", "A", "T", "T", "T", "T", "C", "C", "C", "C", "Q", "Q", "Q", "Q", "N", "N", "Q", "Q"],
            ["D", "D", "T", "T", "E", "E", "K", "K", "Z", "Z", "Q", "Q", "K", "K", "K", "K", "Z", "Z", "A", "A", "A", "A", "T", "T", "A", "A", "Z", "Z", "D", "D", "D", "D", "E", "E", "A", "A", "A", "A", "C", "C", "Q", "Q", "A", "A", "T", "T", "T", "T", "Z", "Z", "E", "E", "E", "E", "Q", "Q", "Q", "Q", "T", "T", "J", "J", "Q", "Q", "Q", "Q", "K", "K", "N", "N", "N", "N", "B", "B", "T", "T", "Z", "Z", "A", "A", "N", "N", "E", "E", "E", "E", "A", "A", "N", "N", "N", "N", "D", "D", "D", "D", "Z", "Z", "J", "J", "K", "K", "Z", "Z", "Z", "Z", "J", "J", "J", "J", "Z", "Z", "Z", "Z", "C", "C", "Q", "Q", "Z", "Z", "Z", "Z", "N", "N", "Z", "Z", "Z", "Z", "T", "T", "T", "T", "J", "J", "D", "D", "K", "K", "Z", "Z", "Z", "Z", "J", "J", "Z", "Z", "Z", "Z", "J", "J", "Z", "Z", "B", "B", "B", "B", "Z", "Z", "K", "K", "K", "K", "S", "S", "S", "S", "N", "N", "N", "N", "C", "C", "C", "C", "N", "N", "N", "N", "C", "C", "C", "C", "N", "N", "Z", "Z", "Z", "Z", "S", "S", "Z", "Z", "J", "J", "J", "J", "Z", "Z", "N", "N"],
            ["Z", "Z", "T", "T", "T", "T", "B", "B", "B", "B", "N", "N", "N", "N", "B", "B", "E", "E", "J", "J", "T", "T", "Z", "Z", "Z", "Z", "D", "D", "A", "A", "E", "E", "E", "E", "K", "K", "A", "A", "Q", "Q", "K", "K", "Z", "Z", "Z", "Z", "Q", "Q", "C", "C", "C", "C", "D", "D", "D", "D", "N", "N", "Q", "Q", "D", "D", "D", "D", "A", "A", "N", "N", "E", "E", "Z", "Z", "Z", "Z", "N", "N", "N", "N", "T", "T", "T", "T", "J", "J", "T", "T", "J", "J", "J", "J", "Z", "Z", "Z", "Z", "C", "C", "N", "N", "Z", "Z", "Z", "Z", "K", "K", "K", "K", "E", "E", "E", "E", "C", "C", "C", "C", "J", "J", "J", "J", "E", "E", "E", "E", "N", "N", "A", "A", "J", "J", "N", "N", "N", "N", "A", "A", "A", "A", "D", "D", "A", "A", "A", "A", "T", "T", "D", "D", "D", "D", "Z", "Z", "N", "N", "N", "N", "D", "D", "N", "N", "N", "N", "Z", "Z", "Z", "Z", "N", "N", "B", "B", "B", "B", "J", "J", "K", "K", "Z", "Z", "T", "T", "T", "T", "Z", "Z", "T", "T", "T", "T", "C", "C", "C", "C", "Z", "Z", "Z", "Z", "Q", "Q", "N", "N", "S", "S", "T", "T", "N", "N", "N", "N", "A", "A", "Z", "Z", "T", "T", "B", "B", "B", "B", "J", "J", "K", "K", "K", "K", "N", "N", "K", "K", "Q", "Q", "Z", "Z", "Z", "Z", "K", "K", "J", "J", "E", "E", "K", "K", "Z", "Z", "B", "B", "A", "A", "A", "A", "S", "S", "S", "S", "J", "J", "T", "T", "Q", "Q", "Q", "Q", "B", "B", "E", "E", "J", "J", "J", "J", "Z", "Z", "N", "N", "N", "N", "Q", "Q", "Q", "Q", "Z", "Z", "Q", "Q", "A", "A", "T", "T", "T", "T", "K", "K", "K", "K", "J", "J", "D", "D", "D", "D", "Z", "Z", "E", "E", "E", "E", "A", "A", "N", "N", "N", "N", "K", "K", "Q", "Q", "Q", "Q", "C", "C", "C", "C", "T", "T", "C", "C", "Q", "Q", "Q", "Q", "D", "D", "C", "C", "C", "C", "Z", "Z", "J", "J", "J", "J", "Z", "Z", "C", "C", "Z", "Z", "K", "K", "K", "K", "T", "T", "Z", "Z", "Z", "Z", "A", "A", "A", "A", "Z", "Z", "Z", "Z", "A", "A", "Q", "Q", "K", "K", "Z", "Z", "Z", "Z", "N", "N", "Q", "Q", "N", "N", "N", "N"]
        ],
        "reelsFS3": [
            ["J", "J", "T", "T", "K", "K", "K", "K", "A", "A", "Q", "Q", "D", "D", "T", "T", "T", "T", "N", "N", "E", "E", "D", "D", "D", "D", "A", "A", "Q", "Q", "Q", "Q", "N", "N", "N", "N", "E", "E", "E", "E", "A", "A", "A", "A", "T", "T", "Q", "Q", "Q", "Q", "A", "A", "E", "E", "E", "E", "K", "K", "B", "B", "B", "B", "N", "N", "Q", "Q", "C", "C", "J", "J", "C", "C", "C", "C", "K", "K", "T", "T", "K", "K", "C", "C", "C", "C", "N", "N", "D", "D", "D", "D", "K", "K", "S", "S", "S", "S", "J", "J", "Q", "Q", "J", "J", "E", "E", "T", "T", "T", "T", "B", "B", "K", "K", "K", "K", "Q", "Q", "N", "N", "N", "N", "T", "T", "A", "A", "A", "A", "N", "N", "N", "N", "C", "C", "D", "D", "N", "N", "N", "N", "A", "A", "N", "N", "J", "J", "J", "J", "S", "S", "J", "J", "J", "J"],
            ["K", "K", "D", "D", "T", "T", "Z", "Z", "D", "D", "D", "D", "S", "S", "S", "S", "Z", "Z", "Z", "Z", "C", "C", "C", "C", "A", "A", "E", "E", "E", "E", "T", "T", "C", "C", "J", "J", "B", "B", "Q", "Q", "Q", "Q", "N", "N", "N", "N", "E", "E", "Q", "Q", "E", "E", "E", "E", "N", "N", "Q", "Q", "Q", "Q", "C", "C", "C", "C", "J", "J", "Z", "Z", "Z", "Z", "S", "S", "Z", "Z", "A", "A", "A", "A", "T", "T", "K", "K", "Q", "Q", "Z", "Z", "Z", "Z", "A", "A", "Z", "Z", "K", "K", "K", "K", "T", "T", "E", "E", "Z", "Z", "Q", "Q", "A", "A", "A", "A", "N", "N", "Z", "Z", "Z", "Z", "N", "N", "J", "J", "J", "J", "K", "K", "N", "N", "Z", "Z", "K", "K", "K", "K", "Q", "Q", "Z", "Z", "J", "J", "N", "N", "N", "N", "A", "A", "J", "J", "D", "D", "D", "D", "B", "B", "B", "B", "Z", "Z", "B", "B", "B", "B", "Z", "Z", "Z", "Z", "T", "T", "T", "T", "Z", "Z", "Z", "Z", "D", "D", "B", "B", "A", "A", "C", "C", "K", "K", "T", "T", "T", "T", "Z", "Z", "N", "N", "N", "N", "J", "J", "J", "J", "N", "N", "N", "N", "Z", "Z"],
            ["K", "K", "N", "N", "J", "J", "K", "K", "K", "K", "Z", "Z", "Q", "Q", "Q", "Q", "J", "J", "J", "J", "Z", "Z", "Q", "Q", "B", "B", "Z", "Z", "K", "K", "K", "K", "N", "N", "A", "A", "Q", "Q", "Z", "Z", "D", "D", "N", "N", "Z", "Z", "Z", "Z", "K", "K", "D", "D", "D", "D", "K", "K", "Q", "Q", "Q", "Q", "Z", "Z", "N", "N", "N", "N", "D", "D", "D", "D", "N", "N", "N", "N", "T", "T", "Z", "Z", "Z", "Z", "T", "T", "A", "A", "A", "A", "C", "C", "Z", "Z", "E", "E", "J", "J", "D", "D", "D", "D", "E", "E", "E", "E", "J", "J", "B", "B", "B", "B", "E", "E", "C", "C", "Z", "Z", "N", "N", "S", "S", "S", "S", "Z", "Z", "T", "T", "T", "T", "E", "E", "E", "E", "J", "J", "Z", "Z", "Z", "Z", "S", "S", "Z", "Z", "Z", "Z", "T", "T", "N", "N", "D", "D", "J", "J", "Z", "Z", "A", "A", "A", "A", "Q", "Q", "B", "B", "D", "D", "N", "N", "N", "N", "T", "T", "T", "T", "A", "A", "C", "C", "C", "C", "K", "K", "K", "K", "N", "N", "N", "N", "Q", "Q", "E", "E", "D", "D", "D", "D", "N", "N", "N", "N", "Z", "Z", "J", "J", "A", "A", "Z", "Z", "Z", "Z", "K", "K", "Z", "Z", "Z", "Z", "T", "T", "Q", "Q", "Q", "Q", "Z", "Z", "N", "N", "T", "T", "N", "N", "N", "N", "K", "K", "Z", "Z", "Z", "Z", "K", "K", "T", "T", "E", "E", "T", "T", "T", "T", "C", "C", "J", "J", "J", "J", "Z", "Z", "Z", "Z", "D", "D", "A", "A", "J", "J", "J", "J", "B", "B", "B", "B", "C", "C", "C", "C", "T", "T", "N", "N", "N", "N", "C", "C", "J", "J", "N", "N", "Z", "Z", "A", "A", "Z", "Z", "K", "K", "Z", "Z", "Z", "Z", "N", "N", "N", "N", "T", "T", "C", "C", "C", "C", "Z", "Z", "Z", "Z", "T", "T", "T", "T", "Z", "Z", "C", "C", "C", "C", "Q", "Q", "N", "N", "J", "J", "A", "A", "Q", "Q", "Q", "Q", "K", "K", "Q", "Q", "E", "E", "E", "E", "A", "A", "K", "K", "K", "K", "E", "E", "E", "E", "J", "J", "J", "J", "N", "N", "A", "A", "A", "A", "Q", "Q", "A", "A", "A", "A", "Q", "Q", "B", "B", "B", "B", "Z", "Z", "A", "A", "Z", "Z", "Z", "Z"]
        ],
        "reelsFS4": [
            ["J", "J", "N", "N", "N", "N", "B", "B", "D", "D", "A", "A", "A", "A", "S", "S", "C", "C", "C", "C", "E", "E", "J", "J", "T", "T", "J", "J", "J", "J", "Q", "Q", "C", "C", "K", "K", "A", "A", "J", "J", "N", "N", "N", "N", "T", "T", "T", "T", "C", "C", "C", "C", "N", "N", "D", "D", "Q", "Q", "Q", "Q", "C", "C", "K", "K", "A", "A", "T", "T", "E", "E", "E", "E", "K", "K", "K", "K", "D", "D", "D", "D", "Q", "Q", "K", "K", "D", "D", "D", "D", "Q", "Q", "A", "A", "A", "A", "N", "N", "A", "A", "J", "J", "J", "J", "T", "T", "N", "N", "K", "K", "K", "K", "E", "E", "T", "T", "Q", "Q", "J", "J", "N", "N", "N", "N", "T", "T", "T", "T", "E", "E", "E", "E", "S", "S", "S", "S", "N", "N", "N", "N", "Q", "Q", "Q", "Q", "N", "N", "A", "A", "K", "K", "B", "B", "B", "B"],
            ["Q" ,"Q" ,"N" ,"N" ,"J" ,"J" ,"C" ,"C" ,"T" ,"T" ,"J" ,"J" ,"J" ,"J" ,"T" ,"T" ,"T" ,"T" ,"Q" ,"Q" ,"Q" ,"Q" ,"N" ,"N" ,"Z" ,"Z" ,"N" ,"N" ,"N" ,"N" ,"J" ,"J" ,"J" ,"J" ,"S" ,"S" ,"T" ,"T" ,"T" ,"T" ,"B" ,"B" ,"B" ,"B" ,"C" ,"C" ,"C" ,"C" ,"K" ,"K" ,"Q" ,"Q" ,"T" ,"T" ,"K" ,"K" ,"N" ,"N" ,"N" ,"N" ,"A" ,"A" ,"E" ,"E" ,"K" ,"K" ,"K" ,"K" ,"Z" ,"Z" ,"Z" ,"Z" ,"J" ,"J" ,"E" ,"E" ,"E" ,"E" ,"D" ,"D" ,"Z" ,"Z" ,"Z" ,"Z" ,"T" ,"T" ,"Z" ,"Z" ,"Q" ,"Q" ,"A" ,"A" ,"D" ,"D" ,"D" ,"D" ,"N" ,"N" ,"Z" ,"Z" ,"A" ,"A" ,"A" ,"A" ,"T" ,"T" ,"Z" ,"Z" ,"A" ,"A" ,"S" ,"S" ,"S" ,"S" ,"Q" ,"Q" ,"J" ,"J" ,"A" ,"A" ,"Z" ,"Z" ,"K" ,"K" ,"Q" ,"Q" ,"Q" ,"Q" ,"K" ,"K" ,"K" ,"K" ,"Z" ,"Z" ,"N" ,"N" ,"B" ,"B" ,"B" ,"B" ,"A" ,"A" ,"A" ,"A" ,"Z" ,"Z" ,"Z" ,"Z" ,"N" ,"N" ,"N" ,"N" ,"J" ,"J" ,"C" ,"C" ,"C" ,"C" ,"Z" ,"Z" ,"Z" ,"Z" ,"D" ,"D" ,"Z" ,"Z" ,"D" ,"D" ,"D" ,"D" ,"N" ,"N" ,"N" ,"N" ,"B" ,"B" ,"Z" ,"Z" ,"Z" ,"Z" ,"K" ,"K" ,"E" ,"E" ,"C" ,"C" ,"B" ,"B" ,"E" ,"E" ,"E" ,"E" ,"Z" ,"Z" ,"Z" ,"Z"],
            ["Z", "Z", "Z", "Z", "K", "K", "K", "K", "Z", "Z", "N", "N", "N", "N", "Z", "Z", "Z", "Z", "T", "T", "N", "N", "N", "N", "A", "A", "C", "C", "C", "C", "J", "J", "Z", "Z", "C", "C", "J", "J", "Z", "Z", "Z", "Z", "C", "C", "C", "C", "K", "K", "Q", "Q", "D", "D", "D", "D", "Z", "Z", "Z", "Z", "N", "N", "N", "N", "K", "K", "N", "N", "E", "E", "E", "E", "T", "T", "T", "T", "Q", "Q", "Q", "Q", "N", "N", "N", "N", "E", "E", "E", "E", "N", "N", "N", "N", "C", "C", "C", "C", "Z", "Z", "B", "B", "B", "B", "N", "N", "K", "K", "K", "K", "T", "T", "T", "T", "A", "A", "A", "A", "Z", "Z", "Z", "Z", "T", "T", "N", "N", "D", "D", "D", "D", "Z", "Z", "A", "A", "C", "C", "K", "K", "E", "E", "E", "E", "K", "K", "Z", "Z", "J", "J", "J", "J", "A", "A", "Z", "Z", "Z", "Z", "S", "S", "S", "S", "B", "B", "B", "B", "J", "J", "N", "N", "A", "A", "B", "B", "J", "J", "J", "J", "Z", "Z", "Z", "Z", "J", "J", "Z", "Z", "Z", "Z", "K", "K", "B", "B", "Z", "Z", "Z", "Z", "A", "A", "K", "K", "Z", "Z", "Z", "Z", "B", "B", "D", "D", "D", "D", "Q", "Q", "C", "C", "Z", "Z", "C", "C", "N", "N", "N", "N", "J", "J", "Z", "Z", "Q", "Q", "K", "K", "Z", "Z", "J", "J", "S", "S", "Z", "Z", "Z", "Z", "D", "D", "T", "T", "Z", "Z", "A", "A", "A", "A", "N", "N", "Z", "Z", "Z", "Z", "T", "T", "K", "K", "N", "N", "J", "J", "Q", "Q", "T", "T", "N", "N", "N", "N", "A", "A", "A", "A", "Q", "Q", "Q", "Q", "Z", "Z", "A", "A", "A", "A", "C", "C", "C", "C", "T", "T", "N", "N", "N", "N", "D", "D", "E", "E", "A", "A", "T", "T", "J", "J", "J", "J", "Z", "Z", "E", "E", "Q", "Q", "Q", "Q", "Z", "Z", "Z", "Z", "Q", "Q", "Z", "Z", "Q", "Q", "J", "J", "T", "T", "T", "T", "Q", "Q", "E", "E", "Q", "Q", "Q", "Q", "E", "E", "D", "D", "N", "N", "T", "T", "T", "T", "A", "A", "K", "K", "K", "K", "Q", "Q", "T", "T", "A", "A", "Z", "Z", "D", "D", "J", "J", "J", "J", "N", "N", "D", "D", "D", "D", "E", "E", "E", "E", "K", "K", "K", "K"]
        ],
    }
}
