import { SpinInputs } from "./spin-inputs";
import { modelDefinition } from "../config";
import { getWinCells } from "./get-win-cells";
import { getWinType } from "./get-win-type";
import { ensureSpinInputsAreValid } from "./ensure-spin-inputs-are-valid";
import { Choices, generateSlotWindow } from "../choices";
import { GameState } from "../game-state";
import { SpinOutputs } from "./spin-outputs";
import {
  LineWin,
  createLineWinChecker,
} from "../../../v3/math-model/create-line-win-checker";
import { FreeSpinPhase, filterSlotWindow } from "../../../v3/math-model";

export function spin(inputs: SpinInputs): SpinOutputs {
  const { coinSize, coinTypeId, choices, gameState } = inputs;

  ensureSpinInputsAreValid(inputs);

  const totalPlayAmount = coinSize * coinAmountMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions();
  const slotWindow = generateSlotWindow(reelStripPositions);

  const wins = checkLineWins(slotWindow);
  const winLines = wins.map((win) => win.playLineIndex + win.length / 10);

  const bonusSymbolWins = checkBonusSymbolWin(slotWindow);
  const { isFreeSpin, isFreeSpinTrigger, freeSpinPhase, freeSpinCount, freeSpinsAwarded, freeSpinsMultiplier } =
      updateFreeSpinState({
          choices,
          isFreeSpinTrigger: bonusSymbolWins !== undefined,
          gameState,
      });

  if (gameState?.freeSpinsMultiplier) {
      updateLineWinMultiplier({ slotWindow, lineWins: wins, freeSpinsMultiplier: gameState?.freeSpinsMultiplier });
  }
  const lineWins = wins.map((win) => win.multiplier * coinSize);

  const winCells = wins.map((win) => getWinCells(win.playLineIndex, win.length));

  const scatterWin = checkScatterWin(slotWindow);
  const isScatterWin = scatterWin !== undefined;

  const numberOfScatters = filterSlotWindow(slotWindow, (sym) => sym === scatterSymbol).length;
  const { isSuperDrumAwarded, multiplier: superDrumMultiplier } = choices.chooseSuperDrumMultiplier(
      numberOfScatters,
      coinTypeId,
      coinSize
  );

  if (isScatterWin) {
      lineWins.push(scatterWin.multiplier * totalPlayAmount * (superDrumMultiplier ?? 1));
      winCells.push(scatterWin.cells);
  }

  if (bonusSymbolWins) {
      lineWins.push(bonusSymbolWins.multiplier * totalPlayAmount);
      winCells.push(bonusSymbolWins.cells);
  }

  const totalWinAmount = lineWins.reduce((sum, amt) => sum + amt, 0);

  const isGrandTriggered =
      isSuperDrumAwarded && numberOfScatters === modelDefinition.scatterSymbolsToTriggerProgressiveJackpots;

  const winType = getWinType({
      totalWinAmount,
      isScatterWin,
      isFreeSpinTrigger,
      isFreeSpin,
      isJackpotTrigger: isGrandTriggered,
  });

  // Compute the cumuluative amount.
  const cumulativeWinAmount = isFreeSpin
      ? (gameState?.cumulativeWinAmount ?? 0) + totalWinAmount
      : isFreeSpinTrigger
      ? totalWinAmount
      : undefined;

  const jackpotWinAmounts = isGrandTriggered ? { GRAND: 0 } : undefined;
  const jackpotWinCounts = isGrandTriggered ? { GRAND: 1 } : undefined;
  const isPick = false as const;

  const spinOutcome = {
      coinSize,
      totalPlayAmount,
      reelStripPositions,
      slotWindow,
      winType,
      totalWinAmount,
      winLines,
      lineWins,
      winCells,
      isSuperDrumAwarded,
      superDrumMultiplier: isSuperDrumAwarded ? superDrumMultiplier : undefined,
      cumulativeWinAmount,
      freeSpinPhase,
      freeSpinCount,
      freeSpinsAwarded,
      freeSpinsMultiplier,
      jackpotWinAmounts,
      jackpotWinCounts,
      isPick,
  };

  // If the next spin will still be a base game spin, just return the spinOutcome with no game state.
  const isNextSpinABaseSpin = !freeSpinPhase || freeSpinPhase === 'END';
  if (isNextSpinABaseSpin)
      return {
          spinOutcome,
          jackpotWinCounts,
      };

  return {
      spinOutcome,
      gameState: {
          coinSize,
          reelStripPositions,
          cumulativeWinAmount: cumulativeWinAmount ?? 0,
          freeSpinPhase,
          freeSpinCount,
          freeSpinsAwarded,
          freeSpinsMultiplier,
      },
      jackpotWinCounts,
  };
}

const { coinAmountMultiplier, scatterSymbol, winTable, bonusSymbol } = modelDefinition;
const checkLineWins = createLineWinChecker({
  ...modelDefinition,
  winTable: winTable.filter((record) => record.symbol !== bonusSymbol),
});

function checkScatterWin(slotWindow: string[][]) {
  const cells = filterSlotWindow(slotWindow, (sym) => sym === scatterSymbol);
  const tableIndex = scatterWinMultipliers.length + 2 - cells.length; // TODO: confirm this logic with rtp
  const multiplier = tableIndex < scatterWinMultipliers.length ? scatterWinMultipliers[tableIndex] : 0;
  return multiplier ? { cells, multiplier } : undefined;
}

function checkBonusSymbolWin(slotWindow: string[][]) {
  const cells = filterSlotWindow(slotWindow, (sym) => sym === bonusSymbol);
  const multiplier =
      cells.length === modelDefinition.bonusSymbolsToTriggerFreeSpins ? bonusWinMultipliers[0] : undefined;
  return multiplier ? { cells, multiplier } : undefined;
}

const scatterWinMultipliers = winTable.find((entry) => entry.symbol === scatterSymbol)?.multipliers ?? [];
const bonusWinMultipliers = winTable.find((entry) => entry.symbol === bonusSymbol)?.multipliers ?? [];

function updateFreeSpinState(info: { choices: Choices; isFreeSpinTrigger: boolean; gameState?: GameState }): {
  isFreeSpin: boolean;
  isFreeSpinTrigger: boolean;
  freeSpinPhase: FreeSpinPhase | undefined;
  freeSpinCount: number | undefined;
  freeSpinsAwarded: number | undefined;
  freeSpinsMultiplier: number | undefined;
  freeSpinsRetriggerCount: number | undefined;
} {
  const { isFreeSpinTrigger, gameState } = info;
  const isFreeSpin = gameState?.freeSpinPhase !== undefined;
  let freeSpinPhase: FreeSpinPhase | undefined;
  let freeSpinCount: number | undefined;

  const freeSpinsAwarded: number | undefined = gameState?.freeSpinsAwarded;
  let freeSpinsRetriggerCount: number | undefined;
  const freeSpinsMultiplier: number | undefined = gameState?.freeSpinsMultiplier;

  if (isFreeSpinTrigger && !isFreeSpin) {
      freeSpinPhase = 'START';
  } else if (isFreeSpinTrigger && isFreeSpin) {
      // free spins is retriggered from a free spin.
      freeSpinsRetriggerCount = freeSpinsAwarded;
      freeSpinCount = (gameState.freeSpinCount ?? 0) - 1 + (gameState?.freeSpinsAwarded ?? 0);
      freeSpinPhase = 'RETRIGGER';
  } else if (isFreeSpin) {
      // free spins continues from a free spin.
      freeSpinCount = (gameState.freeSpinCount ?? 0) - 1;
      freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';
  }

  return {
      isFreeSpin,
      isFreeSpinTrigger,
      freeSpinPhase,
      freeSpinCount,
      freeSpinsAwarded,
      freeSpinsMultiplier,
      freeSpinsRetriggerCount,
  };
}

function updateLineWinMultiplier(info: { lineWins: LineWin[]; freeSpinsMultiplier: number; slotWindow: string[][] }) {
  const { lineWins, freeSpinsMultiplier, slotWindow } = info;

  lineWins.forEach((win) => {
      const playLineIndex = win.playLineIndex;
      const playLine = modelDefinition.playLines[playLineIndex];

      for (let colIndex = 0; colIndex < win.length; colIndex++) {
          const rowIndex = playLine[colIndex];
          const slotWindowSymbol = slotWindow[colIndex][rowIndex];
          if (slotWindowSymbol === modelDefinition.wildSymbol) {
              win.multiplier = win.multiplier * freeSpinsMultiplier;
              break;
          }
      }
  });
}
