import Vue, { PropType } from "vue";

export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    spinType: String as PropType<"FREE_SPINS" | "BASE" | "BOARD_GAME">,
    isNuttCell: Boolean,
    isCandyCaneCell: Boolean,
    stolenRoyalIndex: Number,
    isWildPathCell: Boolean,
    replacementSymbols: Array<{
      symbol: string;
      cell: [number, number];
    }>,
  },
  computed: {
    isStolenRoyal: function (): boolean {
      return this.stolenRoyalIndex !== undefined;
    },
    stolenRoyal: {
      get: function (): undefined | string {
        return this.replacementSymbols[this.stolenRoyalIndex]?.symbol;
      },
      set: function (royal: string): void {
        this.$emit("stolen-royal-changed", {
          royal,
          stolenRoyalIndex: this.stolenRoyalIndex,
        });
      },
    },
  },
});
