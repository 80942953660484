import { modelDefinition } from '../../../model-definition';

type SlotWindow = string[][];

export type MultiplierCell = {
    cell: [number, number];
    multiplier: number;
};

export type MultiplierFeatureResult = {
    multiplierCells: MultiplierCell[];
};

export function playMultiplierFeature({
    slotWindow,
    multiplierCells,
}: {
    slotWindow: SlotWindow;
    multiplierCells?: MultiplierCell[];
}): MultiplierFeatureResult {
    multiplierCells = multiplierCells ?? [];

    const validMultiplierCells: MultiplierCell[] = [];

    const newSlotWindow = slotWindow.map((column) => [...column]);
    for (const multiplierCell of multiplierCells) {
        const cell = multiplierCell.cell;
        const symbol = newSlotWindow[cell[1]][cell[0]];
        if (symbol === modelDefinition.scatterSymbol) continue;

        validMultiplierCells.push(multiplierCell);
    }

    return {
        multiplierCells: validMultiplierCells,
    };
}
