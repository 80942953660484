import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, wrapIndex, rowsFromIndexes, getReelWindowFromIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import { IStep } from "../../../../interface/step";

export interface IVGW054Step extends IStep {
    json: {
        name: string;
        reelStripPositions: number[];
        isFeature: boolean;
        variation: string;
        field0?: string;
        randoms: number[];
        rows: string[][];
    };
}
@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: IVGW054Step | any;
    public privateStep;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    private reelsWheelFeatures: string[];
    constructor() {
        super();
        this.reelsWheelFeatures = [];
    }
    @Watch("step") 
    public stepWatch(step: IVGW054Step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.reelsWheelFeatures = JSON.parse(this.step.json.field0 || "[]");
        
        console.log(JSON.stringify(step.json));
    }
    public mounted() { 
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        // this.step.json.field0 = String(this.shiftStack);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    } 
    public doChangePattern() {
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }
}
