/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"modelDefinition": {
		"modelId": "tf106",
		"noSymbol": "0",
		"wildSymbol": "10",
		"skullTotem": "11",
		"scatterSymbol": "12",
		"reelsLayout": [7, 7, 7, 7, 7],
		"betMultiplier": 20,
		
		"winTable": [{
			"symbol": "12",
			"multipliers": []
		}, {
			"symbol": "11",
			"multipliers": []
		}, {
			"symbol": "10",
			"multipliers": []
		}, {
			"symbol": "9",
			"multipliers": [300, 100, 40]
		}, {
			"symbol": "8",
			"multipliers": [150, 50, 20]
		}, {
			"symbol": "7",
			"multipliers": [120, 40, 16]
		}, {
			"symbol": "6",
			"multipliers": [90, 30, 12]
		}, {
			"symbol": "5",
			"multipliers": [60, 20, 8]
		}, {
			"symbol": "4",
			"multipliers": [30, 10, 4]
		}, {
			"symbol": "3",
			"multipliers": [30, 10, 4]
		}, {
			"symbol": "2",
			"multipliers": [30, 10, 4]
		}, {
			"symbol": "1",
			"multipliers": [30, 10, 4]
		}],
		
		"coinType1Values": [250, 2500, 5000, 10000, 50000, 100000],
		"coinType4Values": [1, 2, 5, 10, 20, 50, 100, 200, 500],
		"coinType1_min_bet": 500,
		"coinType1_max_bet": 250000,
		"coinType1_spin_max_bet": 7500000,
		"coinType4_min_bet": 1,
		"coinType4_max_bet": 500,
		"coinType4_spin_max_bet": 15000,
		
		"reels": [
			["4", "3", "7", "9", "4", "4", "6", "2", "4", "2", "4", "6", "7", "7", "6", "3", "6", "2", "2", "9", "4", "7", "2", "2", "4", "2", "6", "3", "3", "6", "2", "9", "9", "4", "4", "6", "3", "2", "6", "4", "7", "6", "3", "3", "6", "2", "7", "7", "4", "9", "3", "3", "7", "2", "3", "4", "6", "4", "2", "3", "2", "4", "9", "3", "3", "7", "3", "4", "4", "7", "2", "3", "2", "3", "6", "2", "4", "2", "3", "4", "6", "4", "2", "7", "7", "3", "3", "9", "9", "4", "3", "6", "2", "3", "2", "6", "4", "4", "2", "3", "7", "3", "3", "4", "9", "3", "2", "4", "6", "2", "3", "7", "3", "4", "7", "7", "2", "4", "3", "6", "3", "6", "2", "4", "9", "3", "7", "4", "2", "2", "2", "4", "2", "6", "7", "2", "7", "2", "4", "4", "2", "2", "4", "4", "9", "2", "7", "4", "3", "3", "2", "7", "3", "4", "4", "3", "6", "4", "4", "7", "3", "9", "2", "6", "4", "4", "2", "2", "4", "2", "6", "7", "2", "7", "2", "4", "4", "2", "2", "4", "4", "9", "2", "7", "4", "3", "3", "2", "7", "3", "4", "4", "3", "6", "4", "4", "7", "3", "9", "2", "6", "4", "4", "7", "2", "2", "4", "2", "6", "3", "3", "6", "2", "9", "9", "4", "4", "6", "3", "2", "6", "4", "7", "6", "3", "3", "6", "2", "7", "7", "4", "9", "3", "3", "7", "2", "3", "4", "6", "4", "2"],
			["2", "8", "4", "2", "7", "2", "2", "1", "1", "5", "2", "4", "7", "1", "8", "1", "7", "5", "4", "2", "1", "4", "2", "5", "1", "1", "4", "2", "7", "7", "4", "5", "2", "1", "1", "4", "2", "4", "4", "8", "5", "2", "2", "4", "1", "5", "5", "7", "2", "1", "8", "1", "1", "4", "4", "2", "5", "1", "2", "5", "5", "4", "5", "2", "4", "4", "1", "7", "5", "2", "1", "2", "5", "1", "2", "2", "8", "8", "4", "2", "5", "1", "1", "2", "1", "7", "7", "1", "5", "2", "7", "2", "4", "5", "4", "8", "5", "5", "4", "4", "2", "2", "1", "5", "4", "8", "2", "7", "4", "5", "2", "2", "1", "1", "8", "8", "5", "4", "2", "7", "5", "5", "4", "2", "1", "2", "7", "8", "2", "2", "7", "1", "1", "5", "4", "4", "4", "2", "5", "2", "5", "1", "1", "8", "2", "1", "7", "2", "2", "4", "8", "8", "4", "5", "1", "1", "4", "7", "1", "4", "1", "5", "1", "4", "7", "1", "1", "2", "7", "7", "5", "5", "8", "2", "4", "4", "8", "5", "2", "2", "4", "1", "5", "5", "7", "2", "1", "8", "1", "1", "4", "4", "2", "5", "1", "2", "5", "5", "4", "5", "2", "4", "4", "1", "7", "5", "2", "1", "2", "5", "1", "2", "2", "8", "8", "4", "2", "5", "2", "8", "4", "2", "7", "2", "2", "1", "1", "5", "2", "4", "7", "1", "8", "1", "7", "5", "4", "2", "1", "4", "7"],
			["5", "8", "1", "1", "9", "9", "3", "8", "1", "5", "5", "3", "1", "1", "8", "3", "6", "3", "9", "5", "3", "1", "6", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "1", "3", "3", "1", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "3", "9", "6", "6", "6", "1", "5", "3", "5", "5", "8", "6", "9", "3", "3", "6", "1", "6", "3", "6", "6", "1", "6", "8", "9", "1", "5", "3", "5", "3", "5", "1", "8", "3", "3", "1", "1", "8", "5", "1", "6", "6", "8", "5", "3", "9", "1", "5", "5", "3", "1", "5", "3", "1", "1", "5", "1", "3", "8", "3", "6", "1", "9", "9", "3", "5", "5", "1", "8", "3", "3", "8", "8", "6", "1", "6", "5", "9", "8", "1", "1", "5", "5", "3", "5", "1", "3", "1", "5", "9", "3", "8", "1", "3", "3", "6", "6", "5", "8", "1", "1", "9", "9", "3", "8", "1", "5", "5", "3", "1", "1", "8", "3", "6", "3", "9", "5", "3", "1", "6", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "1", "3", "3", "1", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "3", "9", "6", "6", "6", "1", "5"],
			["6", "8", "1", "4", "9", "3", "3", "5", "1", "4", "7", "2", "4", "3", "5", "2", "1", "1", "6", "6", "3", "3", "2", "6", "1", "4", "2", "5", "8", "8", "1", "2", "5", "3", "9", "4", "1", "8", "5", "2", "4", "4", "6", "5", "1", "2", "7", "7", "3", "7", "1", "1", "9", "9", "4", "6", "8", "2", "2", "7", "3", "4", "2", "5", "3", "1", "1", "6", "3", "3", "8", "4", "1", "9", "2", "5", "3", "1", "5", "4", "6", "6", "4", "2", "8", "6", "2", "3", "5", "5", "4", "9", "2", "2", "7", "1", "4", "2", "6", "3", "2", "8", "6", "3", "1", "1", "7", "2", "5", "4", "3", "8", "1", "1", "4", "8", "2", "5", "6", "3", "1", "8", "2", "5", "3", "4", "5", "3", "3", "9", "4", "6", "8", "1", "2", "2", "5", "1", "3", "7", "2", "4", "1", "1", "5", "6", "1", "8", "4", "1", "5", "5", "5", "2", "9", "9", "4", "7", "3", "6", "6", "1", "5", "4", "4", "5", "7", "3", "1", "2", "4", "7", "7", "6", "3", "3", "1", "8", "4", "3", "3", "7", "3", "2", "6", "2", "1", "9", "6", "1", "3", "5", "6", "4", "3", "8", "2", "2", "2", "7", "4", "4", "2", "6", "6", "2", "2", "9", "3", "3", "5", "3", "2", "4", "5", "1", "1", "8", "8", "1", "2", "4", "6", "6", "3", "1", "2", "7", "7", "3", "3", "4", "5", "3", "7", "4", "1", "6", "9", "2", "1"],
			["7", "7", "3", "2", "9", "9", "4", "5", "6", "6", "1", "4", "4", "7", "3", "1", "1", "6", "2", "3", "8", "5", "1", "3", "4", "8", "5", "7", "3", "5", "3", "1", "8", "2", "9", "1", "1", "7", "2", "7", "7", "4", "4", "8", "8", "1", "3", "5", "7", "7", "5", "3", "6", "2", "2", "9", "1", "7", "4", "2", "6", "1", "2", "7", "7", "5", "4", "4", "3", "8", "5", "4", "3", "3", "6", "6", "4", "7", "5", "8", "1", "1", "6", "3", "2", "6", "5", "5", "3", "3", "7", "1", "8", "6", "4", "9", "1", "2", "7", "7", "3", "1", "6", "6", "7", "6", "2", "2", "6", "2", "5", "8", "8", "3", "3", "9", "3", "5", "1", "4", "6", "6", "2", "1", "5", "1", "9", "9", "2", "4", "6", "2", "3", "5", "2", "4", "7", "3", "8", "7", "1", "3", "6", "2", "4", "4", "5", "3", "3", "8", "8", "2", "4", "7", "4", "9", "3", "5", "4", "4", "1", "6", "6", "3", "8", "5", "4", "3", "3", "7", "5", "4", "2", "6", "2", "2", "8", "3", "3", "5", "7", "1", "5", "2", "9", "4", "4", "8", "6", "1", "3", "8", "7", "7", "1", "3", "6", "2", "3", "5", "4", "4", "1", "6", "8", "8", "4", "9", "1", "1", "6", "5", "3", "8", "5", "1", "5", "7", "4", "3", "1", "5", "2", "2", "2", "1", "6", "3", "8", "1", "4", "2", "5", "3", "9", "2", "4", "3", "7", "5", "2"]
		],
		
		"reelset": {
			"nowin": [
				{
					"weight": 100,
					"id": 0,
					"reels": [
						["4", "3", "7", "9", "4", "4", "6", "2", "4", "2", "4", "6", "7", "7", "6", "3", "6", "2", "2", "9", "4", "7", "2", "2", "4", "2", "6", "3", "3", "6", "2", "9", "9", "4", "4", "6", "3", "2", "6", "4", "7", "6", "3", "3", "6", "2", "7", "7", "4", "9", "3", "3", "7", "2", "3", "4", "6", "4", "2", "3", "2", "4", "9", "3", "3", "7", "3", "4", "4", "7", "2", "3", "2", "3", "6", "2", "4", "2", "3", "4", "6", "4", "2", "7", "7", "3", "3", "9", "9", "4", "3", "6", "2", "3", "2", "6", "4", "4", "2", "3", "7", "3", "3", "4", "9", "3", "2", "4", "6", "2", "3", "7", "3", "4", "7", "7", "2", "4", "3", "6", "3", "6", "2", "4", "9", "3", "7", "4", "2", "2", "2", "4", "2", "6", "7", "2", "7", "2", "4", "4", "2", "2", "4", "4", "9", "2", "7", "4", "3", "3", "2", "7", "3", "4", "4", "3", "6", "4", "4", "7", "3", "9", "2", "6", "4", "4", "2", "2", "4", "2", "6", "7", "2", "7", "2", "4", "4", "2", "2", "4", "4", "9", "2", "7", "4", "3", "3", "2", "7", "3", "4", "4", "3", "6", "4", "4", "7", "3", "9", "2", "6", "4", "4", "7", "2", "2", "4", "2", "6", "3", "3", "6", "2", "9", "9", "4", "4", "6", "3", "2", "6", "4", "7", "6", "3", "3", "6", "2", "7", "7", "4", "9", "3", "3", "7", "2", "3", "4", "6", "4", "2"],
						["2", "8", "4", "2", "7", "2", "2", "1", "1", "5", "2", "4", "7", "1", "8", "1", "7", "5", "4", "2", "1", "4", "2", "5", "1", "1", "4", "2", "7", "7", "4", "5", "2", "1", "1", "4", "2", "4", "4", "8", "5", "2", "2", "4", "1", "5", "5", "7", "2", "1", "8", "1", "1", "4", "4", "2", "5", "1", "2", "5", "5", "4", "5", "2", "4", "4", "1", "7", "5", "2", "1", "2", "5", "1", "2", "2", "8", "8", "4", "2", "5", "1", "1", "2", "1", "7", "7", "1", "5", "2", "7", "2", "4", "5", "4", "8", "5", "5", "4", "4", "2", "2", "1", "5", "4", "8", "2", "7", "4", "5", "2", "2", "1", "1", "8", "8", "5", "4", "2", "7", "5", "5", "4", "2", "1", "2", "7", "8", "2", "2", "7", "1", "1", "5", "4", "4", "4", "2", "5", "2", "5", "1", "1", "8", "2", "1", "7", "2", "2", "4", "8", "8", "4", "5", "1", "1", "4", "7", "1", "4", "1", "5", "1", "4", "7", "1", "1", "2", "7", "7", "5", "5", "8", "2", "4", "4", "8", "5", "2", "2", "4", "1", "5", "5", "7", "2", "1", "8", "1", "1", "4", "4", "2", "5", "1", "2", "5", "5", "4", "5", "2", "4", "4", "1", "7", "5", "2", "1", "2", "5", "1", "2", "2", "8", "8", "4", "2", "5", "2", "8", "4", "2", "7", "2", "2", "1", "1", "5", "2", "4", "7", "1", "8", "1", "7", "5", "4", "2", "1", "4", "7"],
						["5", "8", "1", "1", "9", "9", "3", "8", "1", "5", "5", "3", "1", "1", "8", "3", "6", "3", "9", "5", "3", "1", "6", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "1", "3", "3", "1", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "3", "9", "6", "6", "6", "1", "5", "3", "5", "5", "8", "6", "9", "3", "3", "6", "1", "6", "3", "6", "6", "1", "6", "8", "9", "1", "5", "3", "5", "3", "5", "1", "8", "3", "3", "1", "1", "8", "5", "1", "6", "6", "8", "5", "3", "9", "1", "5", "5", "3", "1", "5", "3", "1", "1", "5", "1", "3", "8", "3", "6", "1", "9", "9", "3", "5", "5", "1", "8", "3", "3", "8", "8", "6", "1", "6", "5", "9", "8", "1", "1", "5", "5", "3", "5", "1", "3", "1", "5", "9", "3", "8", "1", "3", "3", "6", "6", "5", "8", "1", "1", "9", "9", "3", "8", "1", "5", "5", "3", "1", "1", "8", "3", "6", "3", "9", "5", "3", "1", "6", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "1", "3", "3", "1", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "6", "1", "1", "8", "3", "1", "1", "5", "3", "5", "3", "9", "3", "8", "5", "3", "6", "5", "1", "8", "6", "1", "1", "6", "8", "3", "3", "3", "9", "6", "6", "6", "1", "5"],
						["6", "8", "1", "4", "9", "3", "3", "5", "1", "4", "7", "2", "4", "3", "5", "2", "1", "1", "6", "6", "3", "3", "2", "6", "1", "4", "2", "5", "8", "8", "1", "2", "5", "3", "9", "4", "1", "8", "5", "2", "4", "4", "6", "5", "1", "2", "7", "7", "3", "7", "1", "1", "9", "9", "4", "6", "8", "2", "2", "7", "3", "4", "2", "5", "3", "1", "1", "6", "3", "3", "8", "4", "1", "9", "2", "5", "3", "1", "5", "4", "6", "6", "4", "2", "8", "6", "2", "3", "5", "5", "4", "9", "2", "2", "7", "1", "4", "2", "6", "3", "2", "8", "6", "3", "1", "1", "7", "2", "5", "4", "3", "8", "1", "1", "4", "8", "2", "5", "6", "3", "1", "8", "2", "5", "3", "4", "5", "3", "3", "9", "4", "6", "8", "1", "2", "2", "5", "1", "3", "7", "2", "4", "1", "1", "5", "6", "1", "8", "4", "1", "5", "5", "5", "2", "9", "9", "4", "7", "3", "6", "6", "1", "5", "4", "4", "5", "7", "3", "1", "2", "4", "7", "7", "6", "3", "3", "1", "8", "4", "3", "3", "7", "3", "2", "6", "2", "1", "9", "6", "1", "3", "5", "6", "4", "3", "8", "2", "2", "2", "7", "4", "4", "2", "6", "6", "2", "2", "9", "3", "3", "5", "3", "2", "4", "5", "1", "1", "8", "8", "1", "2", "4", "6", "6", "3", "1", "2", "7", "7", "3", "3", "4", "5", "3", "7", "4", "1", "6", "9", "2", "1"],
						["7", "7", "3", "2", "9", "9", "4", "5", "6", "6", "1", "4", "4", "7", "3", "1", "1", "6", "2", "3", "8", "5", "1", "3", "4", "8", "5", "7", "3", "5", "3", "1", "8", "2", "9", "1", "1", "7", "2", "7", "7", "4", "4", "8", "8", "1", "3", "5", "7", "7", "5", "3", "6", "2", "2", "9", "1", "7", "4", "2", "6", "1", "2", "7", "7", "5", "4", "4", "3", "8", "5", "4", "3", "3", "6", "6", "4", "7", "5", "8", "1", "1", "6", "3", "2", "6", "5", "5", "3", "3", "7", "1", "8", "6", "4", "9", "1", "2", "7", "7", "3", "1", "6", "6", "7", "6", "2", "2", "6", "2", "5", "8", "8", "3", "3", "9", "3", "5", "1", "4", "6", "6", "2", "1", "5", "1", "9", "9", "2", "4", "6", "2", "3", "5", "2", "4", "7", "3", "8", "7", "1", "3", "6", "2", "4", "4", "5", "3", "3", "8", "8", "2", "4", "7", "4", "9", "3", "5", "4", "4", "1", "6", "6", "3", "8", "5", "4", "3", "3", "7", "5", "4", "2", "6", "2", "2", "8", "3", "3", "5", "7", "1", "5", "2", "9", "4", "4", "8", "6", "1", "3", "8", "7", "7", "1", "3", "6", "2", "3", "5", "4", "4", "1", "6", "8", "8", "4", "9", "1", "1", "6", "5", "3", "8", "5", "1", "5", "7", "4", "3", "1", "5", "2", "2", "2", "1", "6", "3", "8", "1", "4", "2", "5", "3", "9", "2", "4", "3", "7", "5", "2"]
					]
				}
			],
	
	
			"default": [
				{
					// No Win
					"weight": 10,
					"id": 0,
					"reels": [
						["8", "4", "7", "3", "3", "8", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "4", "8", "3", "4", "7", "4", "7", "3"],
						["8", "2", "2", "6", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["7", "2", "4", "6", "6", "2", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "4", "2", "7", "2", "4", "6", "6", "4"],
						["8", "2", "3", "7", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["6", "2", "2", "7", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6"]
					],
				},
				{
					"weight": 4,
					"id": 1,
					"reels": [
						["7", "3", "6", "2", "2", "7", "6", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "2", "2", "7", "3", "6", "7", "3", "6"],
						["7", "2", "5", "1", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2"],
						["6", "3", "3", "5", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6"],
						["3", "5", "5", "2", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3"],
						["5", "2", "7", "2", "2", "3", "5", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "2", "3", "5", "2", "7", "6", "1"]
					]
				},
				{
					"weight": 4,
					"id": 2,
					"reels": [
						["4", "3", "7", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3"],
						["8", "2", "2", "6", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["7", "4", "4", "6", "6", "2", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "4", "2", "7", "2", "4", "7", "6", "2"],
						["8", "2", "3", "7", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["6", "4", "3", "7", "7", "2", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "7", "3", "2", "3", "4", "8", "6"]
					]
				},
				// Win
				{
					"weight": 12,
					"id": 3,
					"reels": [
						["2", "5", "1", "1", "1", "1", "5", "5", "3", "3", "1", "3", "2", "5", "1", "1", "3", "3", "1", "3", "5", "5", "3", "5", "1", "1", "5", "5", "1", "3", "5", "1", "1", "1", "5", "3", "3", "1", "1", "5", "2", "2", "1", "5", "2", "5", "1", "5", "3", "3", "1", "5", "3", "1", "5", "2", "1", "2", "2", "3"],
						["1", "6", "6", "6", "1", "6", "1", "1", "3", "6", "1", "6", "3", "1", "1", "6", "3", "1", "3", "3", "1", "6", "1", "1", "3", "6", "6", "1", "1", "1", "3", "6", "6", "1", "1", "3", "6", "1", "3", "3", "1", "3", "6", "6", "1", "3", "3", "1", "1", "3", "3", "6", "3", "3", "1", "3", "3", "6", "1", "3"],
						["2", "1", "5", "5", "1", "6", "5", "2", "5", "2", "6", "5", "2", "1", "6", "6", "2", "1", "1", "2", "5", "6", "6", "5", "2", "2", "1", "1", "5", "5", "1", "5", "5", "6", "6", "1", "1", "6", "5", "6", "6", "1", "5", "2", "6", "1", "1", "2", "6", "6", "1", "1", "2", "6", "2", "1", "5", "2", "2", "5"],
						["6", "6", "3", "5", "5", "5", "2", "2", "6", "6", "1", "3", "3", "6", "2", "6", "2", "3", "6", "5", "2", "1", "2", "6", "2", "3", "5", "5", "3", "5", "3", "2", "3", "5", "3", "3", "2", "1", "3", "3", "2", "5", "2", "6", "2", "1", "1", "3", "3", "2", "6", "1", "2", "5", "5", "5", "3", "6", "2", "2"],
						["6", "2", "5", "1", "2", "6", "2", "6", "2", "3", "6", "3", "6", "3", "2", "2", "6", "3", "3", "2", "3", "1", "3", "6", "6", "2", "5", "2", "6", "2", "3", "2", "1", "5", "5", "3", "2", "3", "3", "3", "5", "6", "2", "2", "5", "3", "5", "5", "3", "3", "5", "5", "5", "3", "3", "6", "2", "2", "5", "6"]
					]
				},
				{
					"weight": 12,
					"id": 4,
					"reels": [
						["1", "5", "2", "2", "2", "2", "5", "5", "3", "3", "2", "3", "1", "5", "2", "2", "3", "3", "2", "3", "5", "5", "3", "5", "2", "2", "5", "5", "2", "3", "5", "2", "2", "2", "5", "3", "3", "2", "2", "5", "1", "1", "2", "5", "1", "5", "2", "5", "3", "3", "2", "5", "3", "2", "5", "1", "2", "1", "1", "3"],
						["2", "7", "7", "7", "2", "7", "2", "2", "3", "7", "2", "7", "3", "2", "2", "7", "3", "2", "3", "3", "2", "7", "2", "2", "3", "7", "7", "2", "2", "2", "3", "7", "7", "2", "2", "3", "7", "2", "3", "3", "2", "3", "7", "7", "2", "3", "3", "2", "2", "3", "3", "7", "3", "3", "2", "3", "3", "7", "2", "3"],
						["1", "2", "5", "5", "2", "7", "5", "1", "5", "1", "7", "5", "1", "2", "7", "7", "1", "2", "2", "1", "5", "7", "7", "5", "1", "1", "2", "2", "5", "5", "2", "5", "5", "7", "7", "2", "2", "7", "5", "7", "7", "2", "5", "1", "7", "2", "2", "1", "7", "7", "2", "2", "1", "7", "1", "2", "5", "1", "1", "5"],
						["7", "7", "3", "5", "5", "5", "1", "1", "7", "7", "2", "3", "3", "7", "1", "7", "1", "3", "7", "5", "1", "2", "1", "7", "1", "3", "5", "5", "3", "5", "3", "1", "3", "5", "3", "3", "1", "2", "3", "3", "1", "5", "1", "7", "1", "2", "3", "3", "2", "2", "1", "7", "1", "5", "5", "5", "3", "7", "1", "1"],
						["7", "1", "5", "2", "1", "7", "1", "7", "1", "3", "7", "3", "7", "3", "1", "1", "7", "3", "3", "1", "3", "2", "3", "7", "7", "1", "5", "1", "7", "1", "3", "1", "2", "5", "5", "3", "1", "3", "3", "3", "5", "7", "1", "1", "5", "3", "5", "5", "3", "3", "5", "5", "5", "3", "3", "7", "1", "1", "5", "7"]
					]
				},
				{
					"weight": 12,
					"id": 5,
					"reels": [
						["4", "5", "3", "3", "3", "3", "5", "5", "2", "2", "3", "2", "4", "5", "3", "3", "2", "2", "3", "2", "5", "5", "2", "5", "3", "3", "5", "5", "3", "2", "5", "3", "3", "3", "5", "2", "2", "3", "3", "5", "4", "4", "3", "5", "4", "5", "3", "5", "2", "2", "3", "5", "2", "3", "5", "4", "3", "4", "4", "2"],
						["3", "8", "8", "8", "3", "8", "3", "3", "2", "8", "3", "8", "2", "3", "3", "8", "2", "3", "2", "2", "3", "8", "3", "3", "2", "8", "8", "3", "3", "3", "2", "8", "8", "3", "3", "2", "8", "3", "2", "2", "3", "2", "8", "8", "3", "2", "2", "3", "3", "2", "2", "8", "2", "2", "3", "2", "2", "8", "3", "2"],
						["4", "3", "5", "5", "3", "8", "5", "4", "5", "4", "8", "5", "4", "3", "8", "8", "4", "3", "3", "4", "5", "8", "8", "5", "4", "4", "3", "3", "5", "5", "3", "5", "5", "8", "8", "3", "3", "8", "5", "8", "8", "3", "5", "4", "8", "3", "3", "4", "8", "8", "3", "3", "4", "8", "4", "3", "5", "4", "4", "5"],
						["8", "8", "2", "5", "5", "5", "4", "4", "8", "8", "3", "2", "2", "8", "4", "8", "4", "2", "8", "5", "4", "3", "4", "8", "4", "2", "5", "5", "2", "5", "2", "4", "2", "5", "2", "2", "4", "3", "2", "2", "4", "5", "4", "8", "4", "3", "2", "2", "3", "3", "4", "8", "4", "5", "5", "5", "2", "8", "4", "4"],
						["8", "4", "5", "3", "4", "8", "4", "8", "4", "2", "8", "2", "8", "2", "4", "4", "8", "2", "2", "4", "2", "3", "2", "8", "8", "4", "5", "4", "8", "4", "2", "4", "3", "5", "5", "2", "4", "2", "2", "2", "5", "8", "4", "4", "5", "2", "5", "5", "2", "2", "5", "5", "5", "2", "2", "8", "4", "4", "5", "8"]
					]
				},
				{
					"weight": 12,
					"id": 6,
					"reels": [
						["1", "6", "4", "4", "4", "4", "6", "6", "2", "2", "4", "2", "1", "6", "4", "4", "2", "2", "4", "2", "6", "6", "2", "6", "4", "4", "6", "6", "4", "2", "6", "4", "4", "4", "6", "2", "2", "4", "4", "6", "1", "1", "4", "6", "1", "6", "4", "6", "2", "2", "4", "6", "2", "4", "6", "1", "4", "1", "1", "2"],
						["4", "9", "9", "9", "4", "9", "4", "4", "2", "9", "4", "9", "2", "4", "4", "9", "2", "4", "2", "2", "4", "9", "4", "4", "2", "9", "9", "4", "4", "4", "2", "9", "9", "4", "4", "2", "9", "4", "2", "2", "4", "2", "9", "9", "4", "2", "2", "4", "4", "2", "2", "9", "2", "2", "4", "2", "2", "9", "4", "2"],
						["1", "4", "6", "6", "4", "9", "6", "1", "6", "1", "9", "6", "1", "4", "9", "9", "1", "4", "4", "1", "6", "9", "9", "6", "1", "1", "4", "4", "6", "6", "4", "6", "6", "9", "9", "4", "4", "9", "6", "9", "9", "4", "6", "1", "9", "4", "4", "1", "9", "9", "4", "4", "1", "9", "1", "4", "6", "1", "1", "6"],
						["9", "9", "2", "6", "6", "6", "1", "1", "9", "9", "4", "2", "2", "9", "1", "9", "1", "2", "9", "6", "1", "4", "1", "9", "1", "2", "6", "6", "2", "6", "2", "1", "2", "6", "2", "2", "1", "4", "2", "2", "1", "6", "1", "9", "1", "4", "2", "2", "4", "4", "1", "9", "1", "6", "6", "6", "2", "9", "1", "1"],
						["9", "1", "6", "4", "1", "9", "1", "9", "1", "2", "9", "2", "9", "2", "1", "1", "9", "2", "2", "1", "2", "4", "2", "9", "9", "1", "6", "1", "9", "1", "2", "1", "4", "6", "6", "2", "1", "2", "2", "2", "6", "9", "1", "1", "6", "2", "6", "6", "2", "2", "6", "6", "6", "2", "2", "9", "1", "1", "6", "9"]
					]
				},
				{
					"weight": 10.1,
					"id": 7,
					"reels": [
						["1", "3", "5", "3", "5", "3", "4", "4", "5", "4", "4", "4", "5", "5", "4", "4", "5", "4", "3", "3", "5", "4", "3", "5", "3", "3", "5", "5", "4", "3", "3", "5", "4", "4", "5", "5", "3", "1", "1", "5", "3", "5", "1", "3", "5", "3", "5", "5", "4", "4", "5", "3", "4", "5", "3", "1", "5", "1", "1", "4"],
						["5", "7", "7", "7", "5", "7", "5", "5", "4", "7", "5", "7", "5", "4", "5", "7", "4", "5", "4", "4", "5", "5", "7", "7", "5", "7", "7", "5", "5", "7", "5", "7", "7", "5", "5", "4", "7", "5", "4", "4", "5", "4", "7", "7", "5", "4", "4", "5", "4", "4", "5", "5", "4", "4", "7", "5", "4", "4", "7", "4"],
						["1", "5", "3", "3", "5", "7", "3", "1", "3", "1", "7", "3", "1", "5", "7", "7", "1", "5", "5", "1", "3", "7", "7", "3", "1", "1", "5", "5", "3", "3", "5", "3", "3", "7", "7", "5", "7", "3", "5", "7", "7", "5", "3", "1", "7", "5", "5", "1", "7", "7", "5", "5", "1", "7", "1", "5", "3", "1", "1", "3"],
						["7", "7", "4", "3", "3", "3", "1", "1", "7", "7", "5", "4", "4", "7", "1", "7", "1", "4", "7", "3", "1", "5", "1", "7", "1", "4", "3", "3", "4", "3", "4", "1", "4", "3", "4", "4", "1", "5", "4", "4", "1", "3", "1", "7", "1", "5", "4", "4", "5", "1", "7", "5", "1", "3", "3", "3", "4", "7", "1", "1"],
						["7", "1", "3", "5", "1", "7", "1", "7", "1", "4", "7", "4", "7", "4", "1", "1", "7", "4", "4", "1", "4", "5", "4", "7", "7", "1", "3", "1", "7", "1", "4", "1", "5", "3", "3", "4", "1", "4", "4", "4", "3", "7", "1", "1", "3", "4", "3", "3", "4", "4", "3", "3", "3", "4", "4", "7", "1", "1", "3", "7"]
					]
				},
				{
					"weight": 9.4,
					"id": 8,
					"reels": [
						["4", "3", "6", "3", "6", "3", "2", "2", "6", "2", "2", "2", "6", "6", "2", "2", "6", "2", "3", "3", "6", "2", "3", "6", "3", "3", "6", "6", "2", "3", "3", "6", "2", "2", "6", "6", "3", "4", "4", "6", "3", "6", "4", "3", "6", "3", "6", "6", "2", "2", "6", "3", "2", "6", "3", "4", "6", "4", "4", "2"],
						["6", "5", "5", "5", "6", "5", "6", "6", "2", "5", "6", "5", "2", "2", "6", "5", "2", "2", "2", "2", "6", "6", "5", "5", "6", "5", "5", "6", "6", "5", "6", "5", "5", "6", "6", "2", "5", "6", "2", "2", "6", "2", "5", "5", "6", "2", "2", "6", "2", "2", "6", "2", "2", "5", "6", "6", "2", "2", "5", "2"],
						["4", "6", "3", "3", "6", "5", "3", "4", "3", "4", "5", "3", "4", "6", "5", "5", "4", "6", "6", "4", "3", "5", "5", "3", "4", "4", "6", "6", "3", "3", "6", "3", "3", "5", "5", "6", "5", "3", "6", "5", "5", "4", "3", "4", "5", "6", "6", "4", "5", "5", "6", "6", "4", "5", "4", "6", "3", "4", "4", "3"],
						["5", "5", "2", "3", "3", "3", "4", "4", "5", "5", "6", "2", "2", "5", "4", "5", "4", "2", "5", "3", "4", "6", "4", "5", "4", "2", "3", "3", "2", "3", "2", "4", "2", "3", "2", "2", "4", "6", "2", "2", "4", "3", "4", "5", "4", "6", "2", "2", "4", "4", "5", "6", "4", "3", "3", "3", "2", "5", "4", "4"],
						["5", "4", "3", "6", "4", "5", "4", "5", "4", "2", "5", "2", "5", "2", "4", "4", "5", "2", "2", "4", "2", "6", "2", "5", "5", "4", "3", "4", "5", "4", "2", "4", "6", "3", "3", "2", "4", "2", "2", "2", "3", "5", "4", "4", "3", "2", "3", "3", "2", "2", "3", "3", "3", "2", "2", "5", "4", "4", "3", "5"]
					]
				},
				{
					"weight": 3.6,
					"id": 9,
					"reels": [
						["4", "1", "7", "1", "7", "1", "2", "2", "7", "2", "2", "2", "7", "7", "2", "2", "7", "2", "1", "1", "7", "2", "1", "7", "1", "1", "7", "2", "1", "7", "1", "7", "2", "2", "7", "7", "1", "4", "4", "7", "1", "7", "4", "1", "7", "1", "7", "7", "2", "2", "7", "1", "2", "7", "1", "4", "7", "4", "4", "2"],
						["7", "8", "8", "8", "7", "8", "7", "7", "2", "8", "7", "8", "7", "2", "7", "8", "2", "2", "2", "2", "7", "7", "8", "8", "7", "8", "8", "7", "7", "8", "7", "8", "8", "7", "7", "2", "8", "7", "2", "2", "7", "2", "8", "8", "7", "2", "2", "7", "2", "2", "7", "2", "2", "8", "7", "7", "2", "2", "8", "2"],
						["4", "7", "1", "1", "7", "8", "1", "4", "1", "4", "8", "1", "4", "7", "8", "8", "4", "7", "7", "4", "1", "8", "8", "1", "4", "4", "7", "7", "1", "1", "7", "1", "1", "8", "8", "7", "8", "1", "7", "8", "8", "4", "1", "4", "8", "7", "7", "4", "8", "8", "7", "7", "4", "8", "4", "7", "1", "4", "4", "1"],
						["8", "8", "2", "1", "1", "1", "4", "4", "8", "8", "7", "2", "2", "8", "4", "8", "4", "2", "8", "1", "4", "7", "4", "8", "4", "2", "1", "1", "2", "1", "2", "4", "2", "1", "2", "2", "4", "7", "2", "2", "4", "1", "4", "8", "4", "7", "2", "2", "4", "4", "8", "7", "4", "1", "1", "1", "2", "8", "4", "4"],
						["8", "4", "1", "8", "4", "8", "4", "8", "4", "2", "8", "2", "8", "2", "4", "4", "8", "2", "2", "4", "2", "8", "2", "8", "8", "4", "1", "4", "8", "4", "2", "4", "8", "1", "1", "2", "4", "2", "2", "2", "1", "8", "4", "4", "1", "2", "1", "1", "2", "2", "1", "1", "1", "2", "2", "8", "4", "4", "1", "8"]
					]
				},
				{
					"weight": 3,
					"id": 10,
					"reels": [
						["4", "1", "7", "1", "7", "1", "2", "2", "7", "2", "2", "2", "7", "2", "7", "2", "7", "2", "1", "1", "7", "2", "1", "7", "1", "1", "7", "2", "1", "7", "1", "7", "2", "2", "7", "7", "1", "4", "4", "7", "1", "7", "4", "1", "7", "1", "7", "7", "2", "2", "7", "1", "2", "7", "1", "4", "7", "4", "4", "2"],
						["7", "8", "8", "8", "7", "8", "7", "7", "2", "8", "7", "8", "7", "2", "7", "8", "2", "2", "2", "2", "7", "8", "7", "8", "7", "8", "8", "7", "7", "8", "7", "8", "8", "7", "7", "2", "8", "7", "2", "2", "7", "2", "8", "8", "7", "2", "2", "7", "2", "2", "7", "2", "2", "8", "7", "2", "2", "8", "7", "2"],
						["4", "7", "1", "1", "7", "8", "1", "4", "1", "4", "8", "1", "4", "7", "8", "8", "4", "7", "7", "4", "1", "8", "8", "1", "4", "4", "7", "1", "7", "1", "7", "1", "1", "8", "8", "7", "8", "1", "7", "8", "8", "4", "1", "4", "8", "7", "7", "4", "8", "8", "7", "7", "4", "8", "4", "7", "1", "4", "4", "1"],
						["8", "8", "2", "1", "1", "1", "4", "4", "8", "8", "7", "2", "2", "8", "4", "8", "4", "2", "8", "1", "4", "7", "4", "8", "4", "2", "1", "1", "2", "1", "2", "4", "2", "1", "2", "2", "4", "7", "2", "2", "4", "1", "4", "8", "4", "7", "2", "2", "4", "4", "8", "7", "4", "1", "1", "1", "2", "8", "4", "4"],
						["8", "4", "1", "7", "4", "8", "4", "8", "4", "2", "8", "2", "8", "2", "4", "4", "8", "2", "2", "4", "2", "7", "2", "8", "8", "4", "1", "4", "8", "4", "2", "4", "7", "1", "1", "2", "4", "2", "2", "2", "1", "8", "4", "4", "1", "2", "1", "1", "2", "2", "1", "1", "1", "2", "2", "8", "4", "4", "1", "8"]
					]
				},
				{
					"weight": 3.1,
					"id": 11,
					"reels": [
						["3", "1", "8", "1", "8", "1", "2", "2", "8", "2", "2", "2", "8", "8", "2", "2", "8", "2", "1", "1", "8", "2", "1", "8", "1", "1", "8", "2", "1", "8", "1", "8", "2", "2", "8", "8", "1", "3", "3", "8", "1", "8", "3", "1", "8", "1", "8", "8", "2", "2", "8", "1", "2", "8", "1", "3", "8", "3", "3", "2"],
						["8", "7", "7", "7", "8", "7", "8", "8", "2", "7", "8", "7", "8", "2", "8", "7", "2", "2", "2", "2", "8", "8", "7", "7", "8", "7", "7", "8", "8", "7", "8", "7", "7", "8", "8", "2", "7", "8", "2", "2", "8", "2", "7", "7", "8", "2", "2", "8", "2", "2", "8", "2", "2", "7", "8", "2", "2", "7", "8", "2"],
						["3", "8", "1", "1", "8", "7", "1", "3", "1", "3", "7", "1", "3", "8", "7", "7", "3", "8", "8", "3", "1", "7", "7", "1", "3", "3", "8", "8", "1", "1", "8", "1", "1", "7", "7", "8", "7", "1", "8", "7", "7", "3", "1", "3", "7", "8", "8", "3", "7", "7", "8", "8", "3", "7", "3", "8", "1", "3", "3", "1"],
						["7", "7", "2", "1", "1", "1", "3", "3", "7", "7", "8", "2", "2", "7", "3", "7", "3", "2", "7", "1", "3", "8", "3", "7", "3", "2", "1", "1", "2", "1", "2", "3", "2", "1", "2", "2", "3", "8", "2", "2", "3", "1", "3", "7", "3", "8", "2", "2", "3", "3", "7", "8", "3", "1", "1", "1", "2", "7", "3", "3"],
						["7", "3", "1", "7", "3", "7", "3", "7", "3", "2", "7", "2", "7", "2", "3", "3", "7", "2", "2", "3", "2", "7", "2", "7", "7", "3", "1", "3", "7", "3", "2", "3", "7", "1", "1", "2", "3", "2", "2", "2", "1", "7", "3", "3", "1", "2", "1", "1", "2", "2", "1", "1", "1", "2", "2", "7", "3", "3", "1", "7"]
					]
				},
				{
					"weight": 2,
					"id": 12,
					"reels": [
						["3", "1", "8", "1", "8", "1", "2", "2", "8", "2", "2", "2", "8", "2", "8", "2", "8", "2", "1", "1", "8", "2", "1", "8", "1", "1", "8", "2", "1", "8", "1", "8", "2", "2", "8", "8", "1", "3", "3", "8", "1", "8", "3", "1", "8", "1", "8", "8", "2", "2", "8", "1", "2", "8", "1", "3", "8", "3", "3", "2"],
						["8", "7", "7", "7", "8", "7", "8", "8", "2", "7", "8", "7", "8", "2", "8", "7", "2", "2", "2", "2", "8", "7", "8", "7", "8", "7", "7", "8", "8", "7", "8", "7", "7", "8", "8", "2", "7", "8", "2", "2", "8", "2", "7", "7", "8", "2", "2", "8", "2", "2", "8", "2", "2", "7", "8", "2", "2", "7", "8", "2"],
						["3", "8", "1", "1", "8", "7", "1", "3", "1", "3", "7", "1", "3", "8", "7", "7", "3", "8", "8", "3", "1", "7", "7", "1", "3", "3", "8", "1", "8", "1", "8", "1", "1", "7", "7", "8", "7", "1", "8", "7", "7", "3", "1", "3", "7", "8", "8", "3", "7", "7", "8", "8", "3", "7", "3", "8", "1", "3", "3", "1"],
						["7", "7", "2", "1", "1", "1", "3", "3", "7", "7", "8", "2", "2", "7", "3", "7", "3", "2", "7", "1", "3", "8", "3", "7", "3", "2", "1", "1", "2", "1", "2", "3", "2", "1", "2", "2", "3", "8", "2", "2", "3", "1", "3", "7", "3", "8", "2", "2", "3", "3", "7", "8", "3", "1", "1", "1", "2", "7", "3", "3"],
						["7", "3", "1", "8", "3", "7", "3", "7", "3", "2", "7", "2", "7", "2", "3", "3", "7", "2", "2", "3", "2", "8", "2", "7", "7", "3", "1", "3", "7", "3", "2", "3", "8", "1", "1", "2", "3", "2", "2", "2", "1", "7", "3", "3", "1", "2", "1", "1", "2", "2", "1", "1", "1", "2", "2", "7", "3", "3", "1", "7"]
					]
				},
				{
					"weight": 1.8,
					"id": 13,
					"reels": [
						["3", "1", "9", "1", "9", "1", "4", "4", "9", "4", "4", "4", "9", "9", "4", "4", "9", "4", "1", "1", "9", "4", "1", "9", "1", "1", "9", "4", "1", "9", "1", "9", "4", "4", "9", "9", "1", "3", "3", "9", "1", "9", "3", "1", "9", "1", "9", "9", "4", "4", "9", "1", "4", "9", "1", "3", "9", "3", "3", "4"],
						["9", "6", "6", "6", "9", "6", "9", "9", "4", "6", "9", "6", "9", "4", "9", "9", "6", "9", "4", "4", "9", "9", "6", "6", "9", "6", "6", "9", "9", "6", "9", "6", "6", "9", "9", "4", "6", "9", "4", "4", "9", "4", "6", "6", "9", "4", "4", "9", "4", "4", "9", "4", "4", "6", "9", "4", "4", "6", "9", "4"],
						["3", "9", "1", "1", "9", "6", "1", "3", "1", "3", "6", "1", "3", "9", "6", "6", "3", "9", "9", "3", "1", "6", "6", "1", "3", "3", "9", "9", "1", "1", "9", "1", "1", "6", "6", "9", "6", "1", "9", "6", "6", "3", "1", "3", "6", "9", "9", "3", "6", "6", "9", "9", "3", "6", "3", "9", "1", "3", "3", "1"],
						["6", "6", "4", "1", "1", "1", "3", "3", "6", "6", "9", "4", "4", "6", "3", "6", "3", "4", "6", "1", "3", "9", "3", "6", "3", "4", "1", "1", "4", "1", "4", "3", "4", "1", "4", "4", "3", "9", "4", "4", "3", "1", "3", "6", "3", "9", "4", "4", "3", "3", "6", "9", "3", "1", "1", "1", "4", "6", "3", "3"],
						["6", "3", "1", "6", "3", "6", "3", "6", "3", "4", "6", "4", "6", "4", "3", "3", "6", "4", "4", "3", "4", "6", "4", "6", "6", "3", "1", "3", "6", "3", "4", "3", "6", "1", "1", "4", "3", "4", "4", "4", "1", "6", "3", "3", "1", "4", "1", "1", "4", "4", "1", "1", "1", "4", "4", "6", "3", "3", "1", "6"]
					]
				},
				{
					"weight": 1,
					"id": 14,
					"reels": [
						["3", "1", "9", "1", "9", "1", "4", "4", "9", "4", "4", "4", "9", "4", "9", "4", "9", "4", "1", "1", "9", "4", "1", "9", "1", "1", "9", "4", "1", "9", "1", "9", "4", "4", "9", "9", "1", "3", "3", "9", "1", "9", "3", "1", "9", "1", "9", "9", "4", "4", "9", "1", "4", "9", "1", "3", "9", "3", "3", "4"],
						["9", "6", "6", "6", "9", "6", "9", "9", "4", "6", "9", "6", "9", "4", "9", "6", "4", "4", "4", "4", "9", "6", "9", "6", "9", "6", "6", "9", "9", "6", "9", "6", "6", "9", "9", "4", "6", "9", "4", "4", "9", "4", "6", "6", "9", "4", "4", "9", "4", "4", "9", "4", "4", "6", "9", "4", "4", "6", "9", "4"],
						["3", "9", "1", "1", "9", "6", "1", "3", "1", "3", "6", "1", "3", "9", "6", "6", "3", "9", "9", "3", "1", "6", "6", "1", "3", "3", "9", "1", "9", "1", "9", "1", "1", "6", "6", "9", "6", "1", "9", "6", "6", "3", "1", "3", "6", "9", "9", "3", "6", "6", "9", "9", "3", "6", "3", "9", "1", "3", "3", "1"],
						["6", "6", "4", "1", "1", "1", "3", "3", "6", "6", "9", "4", "4", "6", "3", "6", "3", "4", "6", "1", "3", "9", "3", "6", "3", "4", "1", "1", "4", "1", "4", "3", "4", "1", "4", "4", "3", "9", "4", "4", "3", "1", "3", "6", "3", "9", "4", "4", "3", "3", "6", "9", "3", "1", "1", "1", "4", "6", "3", "3"],
						["6", "3", "1", "9", "3", "6", "3", "6", "3", "4", "6", "4", "6", "4", "3", "3", "6", "4", "4", "3", "4", "9", "4", "6", "6", "3", "1", "3", "6", "3", "4", "3", "9", "1", "1", "4", "3", "4", "4", "4", "1", "6", "3", "3", "1", "4", "1", "1", "4", "4", "1", "1", "1", "4", "4", "6", "3", "3", "1", "6"]
					]
				},
			],
	
	
			"freeSpins": [
				{
					"weight": 27,
					"id": 0,
					"reels": [
						["2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2", "2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2"],
						["1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2"],
						["5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6"],
						["2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3"],
						["2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7", "2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7"]
					]
				},
				{
					"weight": 27,
					"id": 1,
					"reels": [
						["3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3"],
						["6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7", "6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7"],
						["7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6"]
					]
				},
				{
					"weight": 26,
					"id": 2,
					"reels": [
						["1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4", "1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4"],
						["3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4", "3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4"],
						["3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7", "3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7"],
						["9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3", "9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3"],
						["1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1", "1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1"]
					]
				},
				// Win
				{
					"weight": 2,
					"id": 3,
					"reels": [
						["6", "5", "6", "2", "6", "2", "2", "1", "5", "1", "5", "6", "5", "1", "2", "1", "1", "5", "1", "2", "1", "6", "6", "2", "5", "2", "6", "1", "5", "6", "5", "2", "2", "5", "6", "5", "1", "5", "2", "1", "1", "6", "6", "1", "5", "2", "2", "6", "6", "6", "5", "6", "1", "1", "5", "2", "2", "2", "6", "5", "5", "1", "2", "1"],
						["2", "1", "5", "1", "1", "2", "5", "6", "5", "2", "6", "6", "2", "6", "5", "1", "2", "1", "2", "5", "2", "6", "5", "5", "1", "1", "5", "6", "6", "2", "6", "1", "1", "2", "1", "5", "5", "6", "1", "6", "6", "1", "2", "6", "2", "5", "5", "2", "6", "5", "2", "1", "2", "5", "2", "6", "5", "6", "1", "6", "1", "5", "1", "2"],
						["6", "5", "1", "2", "6", "2", "1", "2", "1", "6", "5", "1", "5", "6", "2", "5", "6", "2", "5", "5", "1", "6", "6", "2", "1", "2", "5", "2", "1", "6", "5", "1", "6", "6", "1", "5", "2", "2", "5", "2", "1", "6", "5", "2", "1", "1", "6", "5", "5", "1", "6", "2", "5", "1", "1", "2", "6", "5", "6", "5", "6", "2", "2", "1"],
						["6", "1", "5", "2", "1", "1", "1", "2", "2", "6", "5", "2", "5", "6", "6", "5", "5", "6", "5", "1", "1", "2", "1", "6", "6", "5", "2", "2", "5", "1", "2", "6", "5", "6", "5", "1", "2", "2", "5", "2", "1", "2", "1", "1", "6", "6", "5", "6", "1", "2", "1", "5", "2", "2", "1", "2", "1", "6", "6", "5", "5", "6", "6", "5"],
						["1", "6", "2", "1", "2", "5", "2", "6", "5", "1", "5", "6", "5", "2", "6", "1", "2", "2", "6", "1", "6", "1", "2", "5", "5", "2", "6", "1", "5", "5", "1", "6", "2", "1", "5", "2", "6", "6", "1", "1", "2", "1", "6", "5", "2", "6", "5", "5", "5", "2", "1", "2", "6", "1", "5", "5", "2", "2", "1", "6", "6", "6", "5", "1"]
					]
				},
				{
					"weight": 2,
					"id": 4,
					"reels": [
						["3", "7", "1", "7", "1", "1", "5", "7", "7", "3", "3", "1", "3", "5", "5", "5", "3", "1", "1", "5", "1", "7", "5", "7", "5", "1", "3", "5", "3", "7", "7", "3", "1", "1", "3", "5", "7", "3", "3", "7", "3", "7", "7", "1", "5", "1", "5", "5", "7", "1", "3", "3", "7", "5", "5", "5", "7", "7", "1", "1", "5", "3", "1", "3"],
						["5", "7", "5", "5", "3", "5", "1", "3", "3", "1", "7", "7", "1", "1", "3", "7", "5", "3", "3", "1", "7", "5", "3", "5", "5", "7", "3", "7", "1", "7", "1", "1", "5", "1", "3", "7", "5", "5", "1", "3", "7", "7", "3", "3", "1", "1", "7", "5", "1", "1", "3", "5", "3", "3", "7", "5", "7", "5", "5", "1", "7", "7", "1", "3"],
						["3", "1", "7", "5", "5", "3", "5", "7", "1", "1", "7", "7", "3", "3", "5", "1", "1", "3", "1", "5", "5", "7", "3", "3", "5", "7", "1", "7", "7", "5", "3", "1", "1", "7", "1", "1", "7", "3", "7", "5", "5", "3", "5", "1", "3", "7", "5", "3", "1", "5", "5", "3", "3", "7", "5", "3", "7", "7", "1", "5", "1", "1", "7", "3"],
						["7", "5", "3", "5", "1", "1", "5", "7", "3", "1", "7", "3", "3", "7", "1", "5", "5", "3", "3", "1", "7", "1", "1", "3", "7", "7", "5", "5", "7", "3", "5", "1", "5", "5", "7", "3", "1", "3", "5", "1", "7", "1", "1", "3", "7", "7", "3", "5", "7", "1", "5", "1", "5", "5", "3", "7", "3", "7", "3", "3", "1", "5", "1", "7"],
						["3", "1", "7", "3", "7", "5", "5", "5", "1", "7", "5", "1", "1", "3", "3", "7", "5", "5", "3", "3", "3", "7", "1", "1", "1", "7", "5", "7", "3", "7", "5", "1", "7", "7", "1", "7", "3", "5", "5", "7", "3", "1", "5", "5", "3", "1", "3", "1", "5", "7", "3", "1", "1", "5", "3", "3", "1", "7", "7", "1", "5", "5", "7", "3"]
					]
				},
				{
					"weight": 2,
					"id": 5,
					"reels": [
						["5", "5", "8", "1", "4", "4", "5", "8", "8", "4", "1", "1", "4", "5", "1", "8", "5", "1", "8", "8", "4", "4", "1", "8", "4", "8", "4", "5", "5", "5", "1", "1", "5", "1", "4", "5", "4", "1", "8", "8", "8", "4", "5", "4", "5", "1", "1", "4", "8", "1", "5", "5", "8", "1", "8", "4", "5", "1", "8", "1", "5", "4", "4", "4"],
						["4", "5", "1", "8", "4", "8", "1", "4", "4", "1", "8", "1", "5", "8", "5", "5", "1", "4", "1", "8", "1", "8", "1", "5", "4", "4", "4", "5", "8", "5", "5", "4", "8", "5", "1", "8", "4", "1", "4", "1", "4", "5", "8", "8", "5", "5", "4", "1", "8", "4", "5", "8", "1", "4", "4", "8", "5", "5", "1", "8", "1", "1", "4", "5"],
						["4", "1", "8", "8", "4", "5", "1", "4", "5", "5", "5", "8", "1", "8", "1", "4", "1", "1", "8", "4", "8", "4", "4", "5", "5", "5", "8", "8", "4", "1", "5", "1", "4", "1", "1", "5", "5", "5", "4", "4", "8", "5", "8", "1", "4", "1", "8", "4", "1", "1", "1", "8", "4", "1", "5", "5", "8", "8", "5", "5", "4", "4", "8", "4"],
						["8", "8", "4", "5", "5", "1", "8", "8", "5", "1", "1", "4", "4", "1", "5", "4", "1", "8", "5", "1", "4", "1", "4", "8", "5", "5", "8", "8", "5", "4", "1", "4", "5", "8", "4", "1", "8", "1", "4", "8", "5", "4", "4", "1", "8", "1", "5", "5", "1", "4", "4", "5", "8", "4", "8", "8", "1", "1", "4", "5", "5", "5", "4", "1"],
						["8", "8", "4", "4", "1", "4", "5", "8", "4", "1", "5", "1", "1", "8", "5", "5", "1", "8", "1", "1", "1", "5", "4", "8", "4", "8", "4", "5", "5", "5", "8", "4", "4", "4", "1", "8", "5", "5", "4", "5", "1", "8", "8", "4", "1", "4", "1", "5", "8", "5", "1", "5", "4", "8", "1", "8", "5", "4", "1", "1", "5", "4", "4", "8"]
					]
				},
				{
					"weight": 2,
					"id": 6,
					"reels": [
						["9", "3", "2", "2", "9", "5", "3", "5", "5", "3", "9", "2", "3", "9", "2", "5", "3", "5", "2", "2", "3", "3", "5", "5", "9", "9", "5", "3", "9", "5", "2", "2", "2", "5", "3", "3", "9", "9", "5", "2", "9", "3", "9", "5", "5", "3", "2", "2", "9", "9", "3", "5", "3", "5", "9", "3", "2", "5", "3", "5", "9", "2", "2", "2"],
						["5", "5", "9", "3", "9", "5", "3", "5", "3", "5", "3", "2", "2", "9", "2", "2", "9", "3", "3", "5", "2", "2", "5", "3", "2", "5", "9", "9", "2", "5", "9", "3", "5", "5", "3", "9", "5", "9", "2", "5", "3", "2", "3", "9", "3", "2", "2", "9", "5", "5", "9", "5", "2", "5", "2", "3", "3", "9", "2", "9", "9", "3", "3", "2"],
						["9", "9", "2", "2", "5", "9", "2", "3", "3", "3", "9", "2", "3", "5", "5", "5", "9", "9", "2", "5", "2", "9", "2", "2", "5", "3", "5", "3", "3", "5", "9", "3", "9", "2", "2", "9", "5", "3", "2", "3", "5", "9", "5", "2", "3", "9", "3", "5", "5", "5", "3", "3", "3", "5", "2", "9", "5", "3", "2", "2", "9", "9", "5", "2"],
						["5", "9", "3", "2", "2", "9", "3", "9", "5", "5", "2", "9", "5", "3", "2", "3", "9", "5", "5", "3", "9", "2", "2", "3", "5", "2", "3", "5", "9", "3", "2", "5", "3", "3", "2", "5", "3", "5", "9", "9", "9", "9", "3", "5", "2", "5", "2", "2", "9", "2", "2", "9", "3", "5", "3", "3", "5", "5", "5", "2", "2", "9", "3", "9"],
						["2", "5", "3", "3", "5", "2", "9", "9", "5", "2", "5", "3", "9", "2", "9", "3", "5", "5", "2", "3", "3", "5", "9", "9", "2", "5", "3", "3", "9", "5", "2", "2", "5", "2", "9", "2", "9", "5", "5", "3", "9", "9", "2", "5", "3", "2", "3", "3", "9", "3", "9", "2", "3", "2", "3", "5", "5", "2", "3", "2", "5", "5", "9", "9"]
					]
				},
				{
					"weight": 2,
					"id": 7,
					"reels": [
						["7", "2", "2", "4", "7", "6", "4", "4", "7", "6", "2", "2", "4", "6", "7", "6", "6", "7", "4", "4", "2", "7", "4", "6", "7", "2", "7", "6", "2", "2", "4", "6", "4", "7", "6", "4", "6", "7", "4", "2", "4", "6", "2", "2", "6", "6", "2", "7", "2", "2", "6", "4", "7", "7", "2", "2", "7", "4", "6", "6", "7", "4", "6", "4"],
						["6", "2", "2", "6", "2", "7", "4", "7", "6", "4", "2", "6", "4", "7", "7", "4", "6", "6", "2", "4", "7", "4", "6", "4", "6", "6", "2", "2", "2", "7", "7", "4", "7", "2", "6", "6", "2", "4", "7", "2", "6", "4", "2", "4", "4", "7", "7", "6", "7", "6", "7", "6", "2", "4", "4", "2", "4", "2", "2", "7", "6", "4", "7", "6"],
						["4", "6", "4", "4", "7", "6", "2", "4", "6", "6", "7", "2", "7", "7", "2", "2", "6", "4", "6", "2", "2", "4", "7", "6", "6", "7", "4", "7", "7", "2", "4", "2", "4", "4", "2", "7", "7", "6", "6", "7", "2", "6", "6", "6", "4", "2", "4", "2", "2", "6", "7", "7", "2", "7", "4", "2", "6", "6", "4", "2", "6", "4", "7", "4"],
						["6", "7", "7", "2", "4", "4", "6", "2", "2", "4", "7", "7", "4", "6", "6", "2", "4", "2", "6", "2", "7", "4", "6", "2", "7", "6", "6", "7", "4", "2", "4", "7", "6", "6", "4", "7", "7", "4", "6", "2", "4", "2", "2", "7", "4", "2", "6", "7", "6", "6", "6", "4", "2", "7", "2", "2", "6", "2", "7", "4", "6", "4", "4", "7"],
						["2", "6", "4", "6", "2", "4", "6", "7", "7", "4", "7", "7", "6", "4", "2", "2", "7", "4", "4", "6", "6", "6", "4", "2", "2", "4", "2", "7", "6", "6", "2", "7", "4", "7", "6", "2", "6", "7", "4", "6", "7", "4", "2", "7", "2", "4", "6", "2", "2", "6", "7", "7", "6", "7", "2", "4", "4", "2", "2", "4", "6", "4", "6", "7"]
					]
				},
				{
					"weight": 2,
					"id": 8,
					"reels": [
						["4", "3", "4", "8", "3", "4", "6", "4", "8", "3", "6", "8", "6", "8", "3", "6", "8", "3", "6", "4", "8", "8", "8", "4", "3", "4", "6", "4", "6", "3", "6", "3", "4", "6", "8", "3", "3", "6", "8", "4", "6", "3", "6", "4", "8", "4", "3", "8", "8", "3", "8", "8", "3", "6", "6", "4", "4", "4", "4", "3", "3", "6", "8", "6"],
						["3", "8", "3", "6", "8", "3", "6", "6", "4", "8", "4", "4", "3", "4", "8", "6", "8", "3", "8", "4", "8", "3", "8", "6", "4", "6", "4", "6", "6", "3", "4", "3", "3", "8", "6", "6", "4", "3", "8", "4", "4", "3", "8", "3", "4", "8", "6", "6", "4", "4", "3", "6", "8", "8", "3", "3", "6", "8", "4", "8", "3", "6", "4", "6"],
						["8", "4", "8", "6", "8", "3", "8", "4", "4", "3", "6", "3", "4", "3", "6", "6", "6", "4", "8", "3", "6", "4", "3", "3", "4", "4", "6", "3", "8", "8", "8", "6", "4", "8", "4", "8", "6", "3", "4", "3", "6", "8", "4", "8", "6", "6", "3", "3", "8", "6", "3", "4", "3", "6", "4", "8", "3", "8", "3", "6", "6", "4", "4", "8"],
						["4", "3", "8", "4", "8", "6", "4", "3", "6", "6", "6", "8", "4", "3", "8", "3", "3", "8", "4", "8", "4", "3", "4", "6", "6", "6", "4", "3", "8", "6", "3", "8", "3", "6", "3", "4", "6", "8", "8", "6", "4", "8", "3", "6", "4", "3", "4", "8", "3", "4", "6", "6", "8", "8", "3", "8", "6", "4", "3", "8", "3", "6", "4", "4"],
						["4", "6", "6", "3", "3", "8", "4", "8", "8", "3", "8", "4", "6", "4", "3", "6", "8", "4", "6", "3", "6", "3", "4", "8", "4", "4", "3", "8", "6", "3", "8", "6", "6", "8", "3", "8", "6", "8", "6", "3", "4", "6", "4", "8", "3", "3", "4", "4", "3", "3", "4", "4", "6", "4", "8", "3", "6", "6", "4", "6", "3", "8", "8", "8"]
					]
				},
				{
					"weight": 2,
					"id": 9,
					"reels": [
						["6", "2", "1", "1", "9", "2", "9", "1", "6", "6", "2", "9", "2", "9", "1", "6", "9", "9", "2", "6", "2", "1", "6", "9", "9", "6", "6", "2", "1", "1", "1", "2", "9", "6", "2", "2", "6", "2", "6", "9", "1", "1", "9", "9", "6", "1", "2", "1", "6", "1", "2", "2", "9", "1", "6", "6", "1", "1", "9", "9", "9", "2", "2", "6"],
						["1", "9", "6", "2", "6", "9", "1", "6", "2", "1", "9", "1", "9", "2", "6", "2", "6", "1", "1", "6", "1", "2", "6", "2", "2", "9", "9", "2", "9", "1", "6", "9", "2", "1", "6", "1", "1", "2", "9", "6", "2", "9", "6", "9", "1", "9", "2", "6", "9", "2", "9", "2", "2", "1", "9", "9", "6", "2", "6", "1", "6", "6", "1", "1"],
						["6", "2", "9", "9", "9", "2", "6", "6", "2", "6", "1", "9", "1", "1", "1", "2", "6", "6", "2", "9", "6", "9", "6", "2", "1", "1", "1", "9", "2", "2", "1", "9", "2", "2", "6", "1", "6", "1", "6", "9", "2", "6", "9", "9", "2", "9", "1", "1", "2", "9", "1", "6", "2", "9", "6", "6", "9", "9", "2", "6", "1", "1", "2", "1"],
						["1", "1", "6", "9", "6", "6", "2", "2", "6", "1", "9", "9", "2", "2", "1", "9", "2", "9", "6", "1", "1", "9", "2", "2", "6", "9", "1", "9", "2", "6", "1", "6", "2", "1", "6", "1", "2", "9", "1", "6", "1", "9", "2", "6", "6", "9", "9", "2", "9", "6", "6", "9", "2", "6", "2", "1", "9", "1", "2", "6", "9", "2", "1", "1"],
						["6", "2", "2", "6", "9", "2", "6", "1", "1", "2", "1", "9", "9", "6", "1", "9", "2", "2", "1", "1", "9", "9", "9", "2", "6", "6", "9", "6", "1", "2", "1", "6", "1", "9", "9", "2", "6", "2", "9", "6", "1", "6", "1", "2", "2", "1", "6", "9", "6", "1", "2", "1", "2", "2", "6", "6", "1", "2", "6", "9", "1", "9", "9", "9"]
					]
				},
				{
					"weight": 2,
					"id": 10,
					"reels": [
						["2", "3", "3", "8", "2", "8", "3", "7", "8", "8", "2", "7", "7", "2", "3", "7", "2", "3", "3", "3", "8", "3", "2", "7", "7", "7", "8", "7", "8", "2", "8", "2", "3", "2", "7", "7", "2", "7", "7", "3", "8", "8", "8", "2", "3", "8", "2", "3", "3", "2", "2", "3", "8", "2", "8", "8", "7", "7", "7", "3", "7", "3", "2", "8"],
						["7", "8", "2", "3", "7", "7", "3", "8", "8", "8", "3", "7", "2", "2", "2", "3", "2", "8", "3", "3", "8", "7", "2", "7", "3", "2", "8", "7", "3", "7", "2", "8", "3", "7", "3", "2", "3", "7", "8", "7", "2", "8", "8", "2", "8", "7", "2", "3", "8", "7", "7", "3", "7", "8", "3", "3", "8", "2", "2", "8", "3", "7", "2", "2"],
						["7", "3", "7", "2", "3", "8", "8", "3", "8", "2", "2", "3", "7", "8", "7", "2", "3", "8", "2", "3", "7", "8", "8", "3", "8", "2", "7", "7", "3", "2", "7", "2", "7", "2", "2", "7", "7", "8", "3", "2", "3", "3", "2", "7", "3", "8", "8", "8", "7", "8", "2", "2", "7", "3", "8", "7", "2", "8", "2", "8", "3", "3", "3", "7"],
						["7", "2", "3", "3", "2", "2", "7", "7", "7", "3", "3", "8", "8", "8", "2", "8", "8", "7", "3", "3", "8", "2", "2", "7", "8", "2", "7", "7", "3", "8", "2", "3", "3", "8", "3", "2", "7", "3", "8", "7", "2", "2", "7", "3", "8", "2", "8", "7", "3", "2", "7", "8", "3", "7", "3", "7", "2", "7", "2", "3", "2", "8", "8", "8"],
						["7", "8", "8", "8", "2", "7", "7", "2", "7", "2", "3", "3", "8", "3", "3", "2", "2", "8", "7", "8", "2", "7", "2", "8", "7", "3", "2", "7", "3", "8", "3", "3", "2", "7", "2", "3", "2", "2", "7", "8", "8", "7", "3", "3", "8", "8", "7", "3", "8", "3", "8", "7", "7", "3", "2", "2", "7", "2", "8", "3", "3", "2", "7", "8"]
					]
				},
				{
					"weight": 2,
					"id": 11,
					"reels": [
						["4", "4", "4", "7", "9", "7", "3", "3", "7", "9", "3", "9", "4", "7", "3", "9", "3", "9", "7", "3", "4", "3", "4", "7", "4", "9", "9", "7", "7", "9", "3", "4", "3", "9", "9", "7", "7", "3", "3", "7", "9", "9", "4", "4", "4", "4", "7", "3", "7", "9", "4", "9", "7", "7", "7", "4", "3", "9", "9", "4", "3", "3", "4", "3"],
						["7", "9", "7", "3", "4", "9", "4", "7", "4", "7", "9", "4", "3", "3", "9", "3", "4", "7", "9", "3", "9", "3", "3", "7", "7", "4", "4", "9", "9", "7", "4", "3", "4", "3", "9", "3", "3", "9", "7", "3", "4", "4", "7", "4", "9", "7", "7", "9", "7", "9", "3", "3", "4", "3", "3", "7", "4", "9", "7", "7", "9", "9", "4", "4"],
						["3", "4", "7", "4", "3", "4", "9", "7", "9", "7", "7", "3", "9", "3", "9", "4", "4", "3", "4", "3", "4", "9", "9", "9", "3", "4", "9", "7", "7", "7", "3", "7", "9", "9", "4", "3", "4", "4", "3", "7", "3", "4", "7", "7", "9", "9", "7", "3", "7", "3", "9", "7", "9", "3", "7", "3", "4", "4", "4", "7", "9", "9", "4", "3"],
						["7", "9", "3", "9", "4", "4", "7", "3", "9", "7", "3", "7", "4", "4", "9", "3", "9", "4", "3", "4", "9", "3", "7", "3", "7", "9", "4", "7", "7", "9", "4", "3", "4", "4", "7", "7", "9", "4", "9", "3", "9", "7", "9", "3", "3", "7", "4", "3", "3", "9", "3", "7", "3", "4", "9", "7", "7", "3", "7", "9", "4", "4", "9", "4"],
						["3", "7", "9", "7", "3", "3", "4", "9", "4", "4", "7", "3", "9", "9", "7", "4", "9", "3", "9", "4", "9", "3", "3", "3", "7", "7", "4", "7", "4", "7", "4", "9", "4", "9", "3", "3", "9", "4", "4", "7", "7", "7", "4", "3", "7", "9", "3", "9", "3", "4", "9", "3", "4", "3", "4", "9", "7", "7", "9", "9", "4", "3", "7", "7"]
					]
				},
				{
					"weight": 2,
					"id": 12,
					"reels": [
						["1", "4", "9", "4", "1", "4", "9", "9", "1", "9", "8", "4", "8", "8", "8", "1", "1", "8", "4", "1", "8", "8", "9", "9", "1", "9", "9", "4", "4", "1", "8", "4", "1", "9", "9", "4", "8", "1", "4", "4", "8", "4", "8", "1", "9", "8", "1", "9", "8", "1", "4", "9", "1", "8", "1", "8", "9", "4", "4", "9", "9", "8", "1", "4"],
						["1", "9", "9", "4", "8", "4", "8", "4", "9", "4", "1", "9", "1", "8", "1", "8", "1", "1", "8", "9", "4", "4", "4", "8", "8", "9", "9", "4", "8", "9", "1", "1", "4", "4", "1", "8", "1", "8", "9", "9", "1", "9", "9", "8", "8", "1", "4", "4", "8", "1", "9", "4", "8", "4", "4", "1", "9", "9", "1", "4", "9", "1", "8", "8"],
						["9", "1", "1", "4", "8", "8", "4", "8", "4", "9", "1", "9", "1", "9", "8", "4", "4", "9", "8", "4", "8", "1", "1", "9", "8", "9", "4", "1", "1", "8", "9", "4", "1", "1", "4", "8", "8", "8", "4", "9", "1", "8", "4", "1", "9", "9", "4", "1", "4", "8", "9", "1", "9", "9", "9", "4", "8", "1", "4", "9", "1", "8", "4", "8"],
						["1", "4", "4", "9", "1", "8", "9", "9", "4", "8", "1", "1", "8", "8", "9", "4", "9", "4", "1", "4", "1", "9", "1", "4", "9", "8", "1", "9", "8", "8", "8", "4", "9", "1", "1", "8", "9", "4", "8", "9", "1", "9", "8", "8", "4", "1", "4", "4", "8", "8", "1", "1", "8", "4", "8", "4", "1", "9", "9", "9", "4", "9", "1", "4"],
						["1", "8", "4", "1", "9", "8", "4", "1", "9", "8", "9", "9", "1", "4", "4", "8", "4", "9", "1", "9", "8", "8", "4", "1", "9", "9", "4", "8", "1", "4", "1", "8", "1", "8", "4", "8", "9", "9", "1", "9", "9", "4", "8", "1", "4", "4", "8", "1", "1", "4", "4", "1", "1", "8", "9", "4", "9", "8", "8", "9", "4", "9", "1", "8"]
					]
				}
			],
	
	
			"freeSpins_1": [
				{
					"weight": 24,
					"id": 0,
					"reels": [
						["2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2", "2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2"],
						["1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2"],
						["5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6"],
						["2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3"],
						["2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7", "2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7"]
					]
				},
				{
					"weight": 24,
					"id": 1,
					"reels": [
						["3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3"],
						["6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7", "6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7"],
						["7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6"]
					]
				},
				{
					"weight": 22,
					"id": 2,
					"reels": [
						["1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4", "1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4"],
						["3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4", "3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4"],
						["3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7", "3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7"],
						["9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3", "9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3"],
						["1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1", "1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1"]
					]
				},
				// Win
				{
					"weight": 3,
					"id": 3,
					"reels": [
						["6", "5", "6", "2", "6", "2", "2", "1", "5", "1", "5", "6", "5", "1", "2", "1", "1", "5", "1", "2", "1", "6", "6", "2", "5", "2", "6", "1", "5", "6", "5", "2", "2", "5", "6", "5", "1", "5", "2", "1", "1", "6", "6", "1", "5", "2", "2", "6", "6", "6", "5", "6", "1", "1", "5", "2", "2", "2", "6", "5", "5", "1", "2", "1"],
						["2", "1", "5", "1", "1", "2", "5", "6", "5", "2", "6", "6", "2", "6", "5", "1", "2", "1", "2", "5", "2", "6", "5", "5", "1", "1", "5", "6", "6", "2", "6", "1", "1", "2", "1", "5", "5", "6", "1", "6", "6", "1", "2", "6", "2", "5", "5", "2", "6", "5", "2", "1", "2", "5", "2", "6", "5", "6", "1", "6", "1", "5", "1", "2"],
						["6", "5", "1", "2", "6", "2", "1", "2", "1", "6", "5", "1", "5", "6", "2", "5", "6", "2", "5", "5", "1", "6", "6", "2", "1", "2", "5", "2", "1", "6", "5", "1", "6", "6", "1", "5", "2", "2", "5", "2", "1", "6", "5", "2", "1", "1", "6", "5", "5", "1", "6", "2", "5", "1", "1", "2", "6", "5", "6", "5", "6", "2", "2", "1"],
						["6", "1", "5", "2", "1", "1", "1", "2", "2", "6", "5", "2", "5", "6", "6", "5", "5", "6", "5", "1", "1", "2", "1", "6", "6", "5", "2", "2", "5", "1", "2", "6", "5", "6", "5", "1", "2", "2", "5", "2", "1", "2", "1", "1", "6", "6", "5", "6", "1", "2", "1", "5", "2", "2", "1", "2", "1", "6", "6", "5", "5", "6", "6", "5"],
						["1", "6", "2", "1", "2", "5", "2", "6", "5", "1", "5", "6", "5", "2", "6", "1", "2", "2", "6", "1", "6", "1", "2", "5", "5", "2", "6", "1", "5", "5", "1", "6", "2", "1", "5", "2", "6", "6", "1", "1", "2", "1", "6", "5", "2", "6", "5", "5", "5", "2", "1", "2", "6", "1", "5", "5", "2", "2", "1", "6", "6", "6", "5", "1"]
					]
				},
				{
					"weight": 3,
					"id": 4,
					"reels": [
						["3", "7", "1", "7", "1", "1", "5", "7", "7", "3", "3", "1", "3", "5", "5", "5", "3", "1", "1", "5", "1", "7", "5", "7", "5", "1", "3", "5", "3", "7", "7", "3", "1", "1", "3", "5", "7", "3", "3", "7", "3", "7", "7", "1", "5", "1", "5", "5", "7", "1", "3", "3", "7", "5", "5", "5", "7", "7", "1", "1", "5", "3", "1", "3"],
						["5", "7", "5", "5", "3", "5", "1", "3", "3", "1", "7", "7", "1", "1", "3", "7", "5", "3", "3", "1", "7", "5", "3", "5", "5", "7", "3", "7", "1", "7", "1", "1", "5", "1", "3", "7", "5", "5", "1", "3", "7", "7", "3", "3", "1", "1", "7", "5", "1", "1", "3", "5", "3", "3", "7", "5", "7", "5", "5", "1", "7", "7", "1", "3"],
						["3", "1", "7", "5", "5", "3", "5", "7", "1", "1", "7", "7", "3", "3", "5", "1", "1", "3", "1", "5", "5", "7", "3", "3", "5", "7", "1", "7", "7", "5", "3", "1", "1", "7", "1", "1", "7", "3", "7", "5", "5", "3", "5", "1", "3", "7", "5", "3", "1", "5", "5", "3", "3", "7", "5", "3", "7", "7", "1", "5", "1", "1", "7", "3"],
						["7", "5", "3", "5", "1", "1", "5", "7", "3", "1", "7", "3", "3", "7", "1", "5", "5", "3", "3", "1", "7", "1", "1", "3", "7", "7", "5", "5", "7", "3", "5", "1", "5", "5", "7", "3", "1", "3", "5", "1", "7", "1", "1", "3", "7", "7", "3", "5", "7", "1", "5", "1", "5", "5", "3", "7", "3", "7", "3", "3", "1", "5", "1", "7"],
						["3", "1", "7", "3", "7", "5", "5", "5", "1", "7", "5", "1", "1", "3", "3", "7", "5", "5", "3", "3", "3", "7", "1", "1", "1", "7", "5", "7", "3", "7", "5", "1", "7", "7", "1", "7", "3", "5", "5", "7", "3", "1", "5", "5", "3", "1", "3", "1", "5", "7", "3", "1", "1", "5", "3", "3", "1", "7", "7", "1", "5", "5", "7", "3"]
					]
				},
				{
					"weight": 3,
					"id": 5,
					"reels": [
						["5", "5", "8", "1", "4", "4", "5", "8", "8", "4", "1", "1", "4", "5", "1", "8", "5", "1", "8", "8", "4", "4", "1", "8", "4", "8", "4", "5", "5", "5", "1", "1", "5", "1", "4", "5", "4", "1", "8", "8", "8", "4", "5", "4", "5", "1", "1", "4", "8", "1", "5", "5", "8", "1", "8", "4", "5", "1", "8", "1", "5", "4", "4", "4"],
						["4", "5", "1", "8", "4", "8", "1", "4", "4", "1", "8", "1", "5", "8", "5", "5", "1", "4", "1", "8", "1", "8", "1", "5", "4", "4", "4", "5", "8", "5", "5", "4", "8", "5", "1", "8", "4", "1", "4", "1", "4", "5", "8", "8", "5", "5", "4", "1", "8", "4", "5", "8", "1", "4", "4", "8", "5", "5", "1", "8", "1", "1", "4", "5"],
						["4", "1", "8", "8", "4", "5", "1", "4", "5", "5", "5", "8", "1", "8", "1", "4", "1", "1", "8", "4", "8", "4", "4", "5", "5", "5", "8", "8", "4", "1", "5", "1", "4", "1", "1", "5", "5", "5", "4", "4", "8", "5", "8", "1", "4", "1", "8", "4", "1", "1", "1", "8", "4", "1", "5", "5", "8", "8", "5", "5", "4", "4", "8", "4"],
						["8", "8", "4", "5", "5", "1", "8", "8", "5", "1", "1", "4", "4", "1", "5", "4", "1", "8", "5", "1", "4", "1", "4", "8", "5", "5", "8", "8", "5", "4", "1", "4", "5", "8", "4", "1", "8", "1", "4", "8", "5", "4", "4", "1", "8", "1", "5", "5", "1", "4", "4", "5", "8", "4", "8", "8", "1", "1", "4", "5", "5", "5", "4", "1"],
						["8", "8", "4", "4", "1", "4", "5", "8", "4", "1", "5", "1", "1", "8", "5", "5", "1", "8", "1", "1", "1", "5", "4", "8", "4", "8", "4", "5", "5", "5", "8", "4", "4", "4", "1", "8", "5", "5", "4", "5", "1", "8", "8", "4", "1", "4", "1", "5", "8", "5", "1", "5", "4", "8", "1", "8", "5", "4", "1", "1", "5", "4", "4", "8"]
					]
				},
				{
					"weight": 3,
					"id": 6,
					"reels": [
						["9", "3", "2", "2", "9", "5", "3", "5", "5", "3", "9", "2", "3", "9", "2", "5", "3", "5", "2", "2", "3", "3", "5", "5", "9", "9", "5", "3", "9", "5", "2", "2", "2", "5", "3", "3", "9", "9", "5", "2", "9", "3", "9", "5", "5", "3", "2", "2", "9", "9", "3", "5", "3", "5", "9", "3", "2", "5", "3", "5", "9", "2", "2", "2"],
						["5", "5", "9", "3", "9", "5", "3", "5", "3", "5", "3", "2", "2", "9", "2", "2", "9", "3", "3", "5", "2", "2", "5", "3", "2", "5", "9", "9", "2", "5", "9", "3", "5", "5", "3", "9", "5", "9", "2", "5", "3", "2", "3", "9", "3", "2", "2", "9", "5", "5", "9", "5", "2", "5", "2", "3", "3", "9", "2", "9", "9", "3", "3", "2"],
						["9", "9", "2", "2", "5", "9", "2", "3", "3", "3", "9", "2", "3", "5", "5", "5", "9", "9", "2", "5", "2", "9", "2", "2", "5", "3", "5", "3", "3", "5", "9", "3", "9", "2", "2", "9", "5", "3", "2", "3", "5", "9", "5", "2", "3", "9", "3", "5", "5", "5", "3", "3", "3", "5", "2", "9", "5", "3", "2", "2", "9", "9", "5", "2"],
						["5", "9", "3", "2", "2", "9", "3", "9", "5", "5", "2", "9", "5", "3", "2", "3", "9", "5", "5", "3", "9", "2", "2", "3", "5", "2", "3", "5", "9", "3", "2", "5", "3", "3", "2", "5", "3", "5", "9", "9", "9", "9", "3", "5", "2", "5", "2", "2", "9", "2", "2", "9", "3", "5", "3", "3", "5", "5", "5", "2", "2", "9", "3", "9"],
						["2", "5", "3", "3", "5", "2", "9", "9", "5", "2", "5", "3", "9", "2", "9", "3", "5", "5", "2", "3", "3", "5", "9", "9", "2", "5", "3", "3", "9", "5", "2", "2", "5", "2", "9", "2", "9", "5", "5", "3", "9", "9", "2", "5", "3", "2", "3", "3", "9", "3", "9", "2", "3", "2", "3", "5", "5", "2", "3", "2", "5", "5", "9", "9"]
					]
				},
				{
					"weight": 3,
					"id": 7,
					"reels": [
						["7", "2", "2", "4", "7", "6", "4", "4", "7", "6", "2", "2", "4", "6", "7", "6", "6", "7", "4", "4", "2", "7", "4", "6", "7", "2", "7", "6", "2", "2", "4", "6", "4", "7", "6", "4", "6", "7", "4", "2", "4", "6", "2", "2", "6", "6", "2", "7", "2", "2", "6", "4", "7", "7", "2", "2", "7", "4", "6", "6", "7", "4", "6", "4"],
						["6", "2", "2", "6", "2", "7", "4", "7", "6", "4", "2", "6", "4", "7", "7", "4", "6", "6", "2", "4", "7", "4", "6", "4", "6", "6", "2", "2", "2", "7", "7", "4", "7", "2", "6", "6", "2", "4", "7", "2", "6", "4", "2", "4", "4", "7", "7", "6", "7", "6", "7", "6", "2", "4", "4", "2", "4", "2", "2", "7", "6", "4", "7", "6"],
						["4", "6", "4", "4", "7", "6", "2", "4", "6", "6", "7", "2", "7", "7", "2", "2", "6", "4", "6", "2", "2", "4", "7", "6", "6", "7", "4", "7", "7", "2", "4", "2", "4", "4", "2", "7", "7", "6", "6", "7", "2", "6", "6", "6", "4", "2", "4", "2", "2", "6", "7", "7", "2", "7", "4", "2", "6", "6", "4", "2", "6", "4", "7", "4"],
						["6", "7", "7", "2", "4", "4", "6", "2", "2", "4", "7", "7", "4", "6", "6", "2", "4", "2", "6", "2", "7", "4", "6", "2", "7", "6", "6", "7", "4", "2", "4", "7", "6", "6", "4", "7", "7", "4", "6", "2", "4", "2", "2", "7", "4", "2", "6", "7", "6", "6", "6", "4", "2", "7", "2", "2", "6", "2", "7", "4", "6", "4", "4", "7"],
						["2", "6", "4", "6", "2", "4", "6", "7", "7", "4", "7", "7", "6", "4", "2", "2", "7", "4", "4", "6", "6", "6", "4", "2", "2", "4", "2", "7", "6", "6", "2", "7", "4", "7", "6", "2", "6", "7", "4", "6", "7", "4", "2", "7", "2", "4", "6", "2", "2", "6", "7", "7", "6", "7", "2", "4", "4", "2", "2", "4", "6", "4", "6", "7"]
					]
				},
				{
					"weight": 3,
					"id": 8,
					"reels": [
						["4", "3", "4", "8", "3", "4", "6", "4", "8", "3", "6", "8", "6", "8", "3", "6", "8", "3", "6", "4", "8", "8", "8", "4", "3", "4", "6", "4", "6", "3", "6", "3", "4", "6", "8", "3", "3", "6", "8", "4", "6", "3", "6", "4", "8", "4", "3", "8", "8", "3", "8", "8", "3", "6", "6", "4", "4", "4", "4", "3", "3", "6", "8", "6"],
						["3", "8", "3", "6", "8", "3", "6", "6", "4", "8", "4", "4", "3", "4", "8", "6", "8", "3", "8", "4", "8", "3", "8", "6", "4", "6", "4", "6", "6", "3", "4", "3", "3", "8", "6", "6", "4", "3", "8", "4", "4", "3", "8", "3", "4", "8", "6", "6", "4", "4", "3", "6", "8", "8", "3", "3", "6", "8", "4", "8", "3", "6", "4", "6"],
						["8", "4", "8", "6", "8", "3", "8", "4", "4", "3", "6", "3", "4", "3", "6", "6", "6", "4", "8", "3", "6", "4", "3", "3", "4", "4", "6", "3", "8", "8", "8", "6", "4", "8", "4", "8", "6", "3", "4", "3", "6", "8", "4", "8", "6", "6", "3", "3", "8", "6", "3", "4", "3", "6", "4", "8", "3", "8", "3", "6", "6", "4", "4", "8"],
						["4", "3", "8", "4", "8", "6", "4", "3", "6", "6", "6", "8", "4", "3", "8", "3", "3", "8", "4", "8", "4", "3", "4", "6", "6", "6", "4", "3", "8", "6", "3", "8", "3", "6", "3", "4", "6", "8", "8", "6", "4", "8", "3", "6", "4", "3", "4", "8", "3", "4", "6", "6", "8", "8", "3", "8", "6", "4", "3", "8", "3", "6", "4", "4"],
						["4", "6", "6", "3", "3", "8", "4", "8", "8", "3", "8", "4", "6", "4", "3", "6", "8", "4", "6", "3", "6", "3", "4", "8", "4", "4", "3", "8", "6", "3", "8", "6", "6", "8", "3", "8", "6", "8", "6", "3", "4", "6", "4", "8", "3", "3", "4", "4", "3", "3", "4", "4", "6", "4", "8", "3", "6", "6", "4", "6", "3", "8", "8", "8"]
					]
				},
				{
					"weight": 3,
					"id": 9,
					"reels": [
						["6", "2", "1", "1", "9", "2", "9", "1", "6", "6", "2", "9", "2", "9", "1", "6", "9", "9", "2", "6", "2", "1", "6", "9", "9", "6", "6", "2", "1", "1", "1", "2", "9", "6", "2", "2", "6", "2", "6", "9", "1", "1", "9", "9", "6", "1", "2", "1", "6", "1", "2", "2", "9", "1", "6", "6", "1", "1", "9", "9", "9", "2", "2", "6"],
						["1", "9", "6", "2", "6", "9", "1", "6", "2", "1", "9", "1", "9", "2", "6", "2", "6", "1", "1", "6", "1", "2", "6", "2", "2", "9", "9", "2", "9", "1", "6", "9", "2", "1", "6", "1", "1", "2", "9", "6", "2", "9", "6", "9", "1", "9", "2", "6", "9", "2", "9", "2", "2", "1", "9", "9", "6", "2", "6", "1", "6", "6", "1", "1"],
						["6", "2", "9", "9", "9", "2", "6", "6", "2", "6", "1", "9", "1", "1", "1", "2", "6", "6", "2", "9", "6", "9", "6", "2", "1", "1", "1", "9", "2", "2", "1", "9", "2", "2", "6", "1", "6", "1", "6", "9", "2", "6", "9", "9", "2", "9", "1", "1", "2", "9", "1", "6", "2", "9", "6", "6", "9", "9", "2", "6", "1", "1", "2", "1"],
						["1", "1", "6", "9", "6", "6", "2", "2", "6", "1", "9", "9", "2", "2", "1", "9", "2", "9", "6", "1", "1", "9", "2", "2", "6", "9", "1", "9", "2", "6", "1", "6", "2", "1", "6", "1", "2", "9", "1", "6", "1", "9", "2", "6", "6", "9", "9", "2", "9", "6", "6", "9", "2", "6", "2", "1", "9", "1", "2", "6", "9", "2", "1", "1"],
						["6", "2", "2", "6", "9", "2", "6", "1", "1", "2", "1", "9", "9", "6", "1", "9", "2", "2", "1", "1", "9", "9", "9", "2", "6", "6", "9", "6", "1", "2", "1", "6", "1", "9", "9", "2", "6", "2", "9", "6", "1", "6", "1", "2", "2", "1", "6", "9", "6", "1", "2", "1", "2", "2", "6", "6", "1", "2", "6", "9", "1", "9", "9", "9"]
					]
				},
				{
					"weight": 3,
					"id": 10,
					"reels": [
						["2", "3", "3", "8", "2", "8", "3", "7", "8", "8", "2", "7", "7", "2", "3", "7", "2", "3", "3", "3", "8", "3", "2", "7", "7", "7", "8", "7", "8", "2", "8", "2", "3", "2", "7", "7", "2", "7", "7", "3", "8", "8", "8", "2", "3", "8", "2", "3", "3", "2", "2", "3", "8", "2", "8", "8", "7", "7", "7", "3", "7", "3", "2", "8"],
						["7", "8", "2", "3", "7", "7", "3", "8", "8", "8", "3", "7", "2", "2", "2", "3", "2", "8", "3", "3", "8", "7", "2", "7", "3", "2", "8", "7", "3", "7", "2", "8", "3", "7", "3", "2", "3", "7", "8", "7", "2", "8", "8", "2", "8", "7", "2", "3", "8", "7", "7", "3", "7", "8", "3", "3", "8", "2", "2", "8", "3", "7", "2", "2"],
						["7", "3", "7", "2", "3", "8", "8", "3", "8", "2", "2", "3", "7", "8", "7", "2", "3", "8", "2", "3", "7", "8", "8", "3", "8", "2", "7", "7", "3", "2", "7", "2", "7", "2", "2", "7", "7", "8", "3", "2", "3", "3", "2", "7", "3", "8", "8", "8", "7", "8", "2", "2", "7", "3", "8", "7", "2", "8", "2", "8", "3", "3", "3", "7"],
						["7", "2", "3", "3", "2", "2", "7", "7", "7", "3", "3", "8", "8", "8", "2", "8", "8", "7", "3", "3", "8", "2", "2", "7", "8", "2", "7", "7", "3", "8", "2", "3", "3", "8", "3", "2", "7", "3", "8", "7", "2", "2", "7", "3", "8", "2", "8", "7", "3", "2", "7", "8", "3", "7", "3", "7", "2", "7", "2", "3", "2", "8", "8", "8"],
						["7", "8", "8", "8", "2", "7", "7", "2", "7", "2", "3", "3", "8", "3", "3", "2", "2", "8", "7", "8", "2", "7", "2", "8", "7", "3", "2", "7", "3", "8", "3", "3", "2", "7", "2", "3", "2", "2", "7", "8", "8", "7", "3", "3", "8", "8", "7", "3", "8", "3", "8", "7", "7", "3", "2", "2", "7", "2", "8", "3", "3", "2", "7", "8"]
					]
				},
				{
					"weight": 3,
					"id": 11,
					"reels": [
						["4", "4", "4", "7", "9", "7", "3", "3", "7", "9", "3", "9", "4", "7", "3", "9", "3", "9", "7", "3", "4", "3", "4", "7", "4", "9", "9", "7", "7", "9", "3", "4", "3", "9", "9", "7", "7", "3", "3", "7", "9", "9", "4", "4", "4", "4", "7", "3", "7", "9", "4", "9", "7", "7", "7", "4", "3", "9", "9", "4", "3", "3", "4", "3"],
						["7", "9", "7", "3", "4", "9", "4", "7", "4", "7", "9", "4", "3", "3", "9", "3", "4", "7", "9", "3", "9", "3", "3", "7", "7", "4", "4", "9", "9", "7", "4", "3", "4", "3", "9", "3", "3", "9", "7", "3", "4", "4", "7", "4", "9", "7", "7", "9", "7", "9", "3", "3", "4", "3", "3", "7", "4", "9", "7", "7", "9", "9", "4", "4"],
						["3", "4", "7", "4", "3", "4", "9", "7", "9", "7", "7", "3", "9", "3", "9", "4", "4", "3", "4", "3", "4", "9", "9", "9", "3", "4", "9", "7", "7", "7", "3", "7", "9", "9", "4", "3", "4", "4", "3", "7", "3", "4", "7", "7", "9", "9", "7", "3", "7", "3", "9", "7", "9", "3", "7", "3", "4", "4", "4", "7", "9", "9", "4", "3"],
						["7", "9", "3", "9", "4", "4", "7", "3", "9", "7", "3", "7", "4", "4", "9", "3", "9", "4", "3", "4", "9", "3", "7", "3", "7", "9", "4", "7", "7", "9", "4", "3", "4", "4", "7", "7", "9", "4", "9", "3", "9", "7", "9", "3", "3", "7", "4", "3", "3", "9", "3", "7", "3", "4", "9", "7", "7", "3", "7", "9", "4", "4", "9", "4"],
						["3", "7", "9", "7", "3", "3", "4", "9", "4", "4", "7", "3", "9", "9", "7", "4", "9", "3", "9", "4", "9", "3", "3", "3", "7", "7", "4", "7", "4", "7", "4", "9", "4", "9", "3", "3", "9", "4", "4", "7", "7", "7", "4", "3", "7", "9", "3", "9", "3", "4", "9", "3", "4", "3", "4", "9", "7", "7", "9", "9", "4", "3", "7", "7"]
					]
				},
				{
					"weight": 3,
					"id": 12,
					"reels": [
						["1", "4", "9", "4", "1", "4", "9", "9", "1", "9", "8", "4", "8", "8", "8", "1", "1", "8", "4", "1", "8", "8", "9", "9", "1", "9", "9", "4", "4", "1", "8", "4", "1", "9", "9", "4", "8", "1", "4", "4", "8", "4", "8", "1", "9", "8", "1", "9", "8", "1", "4", "9", "1", "8", "1", "8", "9", "4", "4", "9", "9", "8", "1", "4"],
						["1", "9", "9", "4", "8", "4", "8", "4", "9", "4", "1", "9", "1", "8", "1", "8", "1", "1", "8", "9", "4", "4", "4", "8", "8", "9", "9", "4", "8", "9", "1", "1", "4", "4", "1", "8", "1", "8", "9", "9", "1", "9", "9", "8", "8", "1", "4", "4", "8", "1", "9", "4", "8", "4", "4", "1", "9", "9", "1", "4", "9", "1", "8", "8"],
						["9", "1", "1", "4", "8", "8", "4", "8", "4", "9", "1", "9", "1", "9", "8", "4", "4", "9", "8", "4", "8", "1", "1", "9", "8", "9", "4", "1", "1", "8", "9", "4", "1", "1", "4", "8", "8", "8", "4", "9", "1", "8", "4", "1", "9", "9", "4", "1", "4", "8", "9", "1", "9", "9", "9", "4", "8", "1", "4", "9", "1", "8", "4", "8"],
						["1", "4", "4", "9", "1", "8", "9", "9", "4", "8", "1", "1", "8", "8", "9", "4", "9", "4", "1", "4", "1", "9", "1", "4", "9", "8", "1", "9", "8", "8", "8", "4", "9", "1", "1", "8", "9", "4", "8", "9", "1", "9", "8", "8", "4", "1", "4", "4", "8", "8", "1", "1", "8", "4", "8", "4", "1", "9", "9", "9", "4", "9", "1", "4"],
						["1", "8", "4", "1", "9", "8", "4", "1", "9", "8", "9", "9", "1", "4", "4", "8", "4", "9", "1", "9", "8", "8", "4", "1", "9", "9", "4", "8", "1", "4", "1", "8", "1", "8", "4", "8", "9", "9", "1", "9", "9", "4", "8", "1", "4", "4", "8", "1", "1", "4", "4", "1", "1", "8", "9", "4", "9", "8", "8", "9", "4", "9", "1", "8"]
					]
				}
			],
	
	
			"freeSpins_2": [
				{
					"weight": 20,
					"id": 0,
					"reels": [
						["2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2", "2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2"],
						["1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2"],
						["5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6"],
						["2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3"],
						["2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7", "2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7"]
					]
				},
				{
					"weight": 20,
					"id": 1,
					"reels": [
						["3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3"],
						["6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7", "6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7"],
						["7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6"]
					]
				},
				{
					"weight": 20,
					"id": 2,
					"reels": [
						["1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4", "1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4"],
						["3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4", "3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4"],
						["3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7", "3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7"],
						["9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3", "9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3"],
						["1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1", "1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1"]
					]
				},
				// Win
				{
					"weight": 4,
					"id": 3,
					"reels": [
						["6", "5", "6", "2", "6", "2", "2", "1", "5", "1", "5", "6", "5", "1", "2", "1", "1", "5", "1", "2", "1", "6", "6", "2", "5", "2", "6", "1", "5", "6", "5", "2", "2", "5", "6", "5", "1", "5", "2", "1", "1", "6", "6", "1", "5", "2", "2", "6", "6", "6", "5", "6", "1", "1", "5", "2", "2", "2", "6", "5", "5", "1", "2", "1"],
						["2", "1", "5", "1", "1", "2", "5", "6", "5", "2", "6", "6", "2", "6", "5", "1", "2", "1", "2", "5", "2", "6", "5", "5", "1", "1", "5", "6", "6", "2", "6", "1", "1", "2", "1", "5", "5", "6", "1", "6", "6", "1", "2", "6", "2", "5", "5", "2", "6", "5", "2", "1", "2", "5", "2", "6", "5", "6", "1", "6", "1", "5", "1", "2"],
						["6", "5", "1", "2", "6", "2", "1", "2", "1", "6", "5", "1", "5", "6", "2", "5", "6", "2", "5", "5", "1", "6", "6", "2", "1", "2", "5", "2", "1", "6", "5", "1", "6", "6", "1", "5", "2", "2", "5", "2", "1", "6", "5", "2", "1", "1", "6", "5", "5", "1", "6", "2", "5", "1", "1", "2", "6", "5", "6", "5", "6", "2", "2", "1"],
						["6", "1", "5", "2", "1", "1", "1", "2", "2", "6", "5", "2", "5", "6", "6", "5", "5", "6", "5", "1", "1", "2", "1", "6", "6", "5", "2", "2", "5", "1", "2", "6", "5", "6", "5", "1", "2", "2", "5", "2", "1", "2", "1", "1", "6", "6", "5", "6", "1", "2", "1", "5", "2", "2", "1", "2", "1", "6", "6", "5", "5", "6", "6", "5"],
						["1", "6", "2", "1", "2", "5", "2", "6", "5", "1", "5", "6", "5", "2", "6", "1", "2", "2", "6", "1", "6", "1", "2", "5", "5", "2", "6", "1", "5", "5", "1", "6", "2", "1", "5", "2", "6", "6", "1", "1", "2", "1", "6", "5", "2", "6", "5", "5", "5", "2", "1", "2", "6", "1", "5", "5", "2", "2", "1", "6", "6", "6", "5", "1"]
					]
				},
				{
					"weight": 4,
					"id": 4,
					"reels": [
						["3", "7", "1", "7", "1", "1", "5", "7", "7", "3", "3", "1", "3", "5", "5", "5", "3", "1", "1", "5", "1", "7", "5", "7", "5", "1", "3", "5", "3", "7", "7", "3", "1", "1", "3", "5", "7", "3", "3", "7", "3", "7", "7", "1", "5", "1", "5", "5", "7", "1", "3", "3", "7", "5", "5", "5", "7", "7", "1", "1", "5", "3", "1", "3"],
						["5", "7", "5", "5", "3", "5", "1", "3", "3", "1", "7", "7", "1", "1", "3", "7", "5", "3", "3", "1", "7", "5", "3", "5", "5", "7", "3", "7", "1", "7", "1", "1", "5", "1", "3", "7", "5", "5", "1", "3", "7", "7", "3", "3", "1", "1", "7", "5", "1", "1", "3", "5", "3", "3", "7", "5", "7", "5", "5", "1", "7", "7", "1", "3"],
						["3", "1", "7", "5", "5", "3", "5", "7", "1", "1", "7", "7", "3", "3", "5", "1", "1", "3", "1", "5", "5", "7", "3", "3", "5", "7", "1", "7", "7", "5", "3", "1", "1", "7", "1", "1", "7", "3", "7", "5", "5", "3", "5", "1", "3", "7", "5", "3", "1", "5", "5", "3", "3", "7", "5", "3", "7", "7", "1", "5", "1", "1", "7", "3"],
						["7", "5", "3", "5", "1", "1", "5", "7", "3", "1", "7", "3", "3", "7", "1", "5", "5", "3", "3", "1", "7", "1", "1", "3", "7", "7", "5", "5", "7", "3", "5", "1", "5", "5", "7", "3", "1", "3", "5", "1", "7", "1", "1", "3", "7", "7", "3", "5", "7", "1", "5", "1", "5", "5", "3", "7", "3", "7", "3", "3", "1", "5", "1", "7"],
						["3", "1", "7", "3", "7", "5", "5", "5", "1", "7", "5", "1", "1", "3", "3", "7", "5", "5", "3", "3", "3", "7", "1", "1", "1", "7", "5", "7", "3", "7", "5", "1", "7", "7", "1", "7", "3", "5", "5", "7", "3", "1", "5", "5", "3", "1", "3", "1", "5", "7", "3", "1", "1", "5", "3", "3", "1", "7", "7", "1", "5", "5", "7", "3"]
					]
				},
				{
					"weight": 4,
					"id": 5,
					"reels": [
						["5", "5", "8", "1", "4", "4", "5", "8", "8", "4", "1", "1", "4", "5", "1", "8", "5", "1", "8", "8", "4", "4", "1", "8", "4", "8", "4", "5", "5", "5", "1", "1", "5", "1", "4", "5", "4", "1", "8", "8", "8", "4", "5", "4", "5", "1", "1", "4", "8", "1", "5", "5", "8", "1", "8", "4", "5", "1", "8", "1", "5", "4", "4", "4"],
						["4", "5", "1", "8", "4", "8", "1", "4", "4", "1", "8", "1", "5", "8", "5", "5", "1", "4", "1", "8", "1", "8", "1", "5", "4", "4", "4", "5", "8", "5", "5", "4", "8", "5", "1", "8", "4", "1", "4", "1", "4", "5", "8", "8", "5", "5", "4", "1", "8", "4", "5", "8", "1", "4", "4", "8", "5", "5", "1", "8", "1", "1", "4", "5"],
						["4", "1", "8", "8", "4", "5", "1", "4", "5", "5", "5", "8", "1", "8", "1", "4", "1", "1", "8", "4", "8", "4", "4", "5", "5", "5", "8", "8", "4", "1", "5", "1", "4", "1", "1", "5", "5", "5", "4", "4", "8", "5", "8", "1", "4", "1", "8", "4", "1", "1", "1", "8", "4", "1", "5", "5", "8", "8", "5", "5", "4", "4", "8", "4"],
						["8", "8", "4", "5", "5", "1", "8", "8", "5", "1", "1", "4", "4", "1", "5", "4", "1", "8", "5", "1", "4", "1", "4", "8", "5", "5", "8", "8", "5", "4", "1", "4", "5", "8", "4", "1", "8", "1", "4", "8", "5", "4", "4", "1", "8", "1", "5", "5", "1", "4", "4", "5", "8", "4", "8", "8", "1", "1", "4", "5", "5", "5", "4", "1"],
						["8", "8", "4", "4", "1", "4", "5", "8", "4", "1", "5", "1", "1", "8", "5", "5", "1", "8", "1", "1", "1", "5", "4", "8", "4", "8", "4", "5", "5", "5", "8", "4", "4", "4", "1", "8", "5", "5", "4", "5", "1", "8", "8", "4", "1", "4", "1", "5", "8", "5", "1", "5", "4", "8", "1", "8", "5", "4", "1", "1", "5", "4", "4", "8"]
					]
				},
				{
					"weight": 4,
					"id": 6,
					"reels": [
						["9", "3", "2", "2", "9", "5", "3", "5", "5", "3", "9", "2", "3", "9", "2", "5", "3", "5", "2", "2", "3", "3", "5", "5", "9", "9", "5", "3", "9", "5", "2", "2", "2", "5", "3", "3", "9", "9", "5", "2", "9", "3", "9", "5", "5", "3", "2", "2", "9", "9", "3", "5", "3", "5", "9", "3", "2", "5", "3", "5", "9", "2", "2", "2"],
						["5", "5", "9", "3", "9", "5", "3", "5", "3", "5", "3", "2", "2", "9", "2", "2", "9", "3", "3", "5", "2", "2", "5", "3", "2", "5", "9", "9", "2", "5", "9", "3", "5", "5", "3", "9", "5", "9", "2", "5", "3", "2", "3", "9", "3", "2", "2", "9", "5", "5", "9", "5", "2", "5", "2", "3", "3", "9", "2", "9", "9", "3", "3", "2"],
						["9", "9", "2", "2", "5", "9", "2", "3", "3", "3", "9", "2", "3", "5", "5", "5", "9", "9", "2", "5", "2", "9", "2", "2", "5", "3", "5", "3", "3", "5", "9", "3", "9", "2", "2", "9", "5", "3", "2", "3", "5", "9", "5", "2", "3", "9", "3", "5", "5", "5", "3", "3", "3", "5", "2", "9", "5", "3", "2", "2", "9", "9", "5", "2"],
						["5", "9", "3", "2", "2", "9", "3", "9", "5", "5", "2", "9", "5", "3", "2", "3", "9", "5", "5", "3", "9", "2", "2", "3", "5", "2", "3", "5", "9", "3", "2", "5", "3", "3", "2", "5", "3", "5", "9", "9", "9", "9", "3", "5", "2", "5", "2", "2", "9", "2", "2", "9", "3", "5", "3", "3", "5", "5", "5", "2", "2", "9", "3", "9"],
						["2", "5", "3", "3", "5", "2", "9", "9", "5", "2", "5", "3", "9", "2", "9", "3", "5", "5", "2", "3", "3", "5", "9", "9", "2", "5", "3", "3", "9", "5", "2", "2", "5", "2", "9", "2", "9", "5", "5", "3", "9", "9", "2", "5", "3", "2", "3", "3", "9", "3", "9", "2", "3", "2", "3", "5", "5", "2", "3", "2", "5", "5", "9", "9"]
					]
				},
				{
					"weight": 4,
					"id": 7,
					"reels": [
						["7", "2", "2", "4", "7", "6", "4", "4", "7", "6", "2", "2", "4", "6", "7", "6", "6", "7", "4", "4", "2", "7", "4", "6", "7", "2", "7", "6", "2", "2", "4", "6", "4", "7", "6", "4", "6", "7", "4", "2", "4", "6", "2", "2", "6", "6", "2", "7", "2", "2", "6", "4", "7", "7", "2", "2", "7", "4", "6", "6", "7", "4", "6", "4"],
						["6", "2", "2", "6", "2", "7", "4", "7", "6", "4", "2", "6", "4", "7", "7", "4", "6", "6", "2", "4", "7", "4", "6", "4", "6", "6", "2", "2", "2", "7", "7", "4", "7", "2", "6", "6", "2", "4", "7", "2", "6", "4", "2", "4", "4", "7", "7", "6", "7", "6", "7", "6", "2", "4", "4", "2", "4", "2", "2", "7", "6", "4", "7", "6"],
						["4", "6", "4", "4", "7", "6", "2", "4", "6", "6", "7", "2", "7", "7", "2", "2", "6", "4", "6", "2", "2", "4", "7", "6", "6", "7", "4", "7", "7", "2", "4", "2", "4", "4", "2", "7", "7", "6", "6", "7", "2", "6", "6", "6", "4", "2", "4", "2", "2", "6", "7", "7", "2", "7", "4", "2", "6", "6", "4", "2", "6", "4", "7", "4"],
						["6", "7", "7", "2", "4", "4", "6", "2", "2", "4", "7", "7", "4", "6", "6", "2", "4", "2", "6", "2", "7", "4", "6", "2", "7", "6", "6", "7", "4", "2", "4", "7", "6", "6", "4", "7", "7", "4", "6", "2", "4", "2", "2", "7", "4", "2", "6", "7", "6", "6", "6", "4", "2", "7", "2", "2", "6", "2", "7", "4", "6", "4", "4", "7"],
						["2", "6", "4", "6", "2", "4", "6", "7", "7", "4", "7", "7", "6", "4", "2", "2", "7", "4", "4", "6", "6", "6", "4", "2", "2", "4", "2", "7", "6", "6", "2", "7", "4", "7", "6", "2", "6", "7", "4", "6", "7", "4", "2", "7", "2", "4", "6", "2", "2", "6", "7", "7", "6", "7", "2", "4", "4", "2", "2", "4", "6", "4", "6", "7"]
					]
				},
				{
					"weight": 4,
					"id": 8,
					"reels": [
						["4", "3", "4", "8", "3", "4", "6", "4", "8", "3", "6", "8", "6", "8", "3", "6", "8", "3", "6", "4", "8", "8", "8", "4", "3", "4", "6", "4", "6", "3", "6", "3", "4", "6", "8", "3", "3", "6", "8", "4", "6", "3", "6", "4", "8", "4", "3", "8", "8", "3", "8", "8", "3", "6", "6", "4", "4", "4", "4", "3", "3", "6", "8", "6"],
						["3", "8", "3", "6", "8", "3", "6", "6", "4", "8", "4", "4", "3", "4", "8", "6", "8", "3", "8", "4", "8", "3", "8", "6", "4", "6", "4", "6", "6", "3", "4", "3", "3", "8", "6", "6", "4", "3", "8", "4", "4", "3", "8", "3", "4", "8", "6", "6", "4", "4", "3", "6", "8", "8", "3", "3", "6", "8", "4", "8", "3", "6", "4", "6"],
						["8", "4", "8", "6", "8", "3", "8", "4", "4", "3", "6", "3", "4", "3", "6", "6", "6", "4", "8", "3", "6", "4", "3", "3", "4", "4", "6", "3", "8", "8", "8", "6", "4", "8", "4", "8", "6", "3", "4", "3", "6", "8", "4", "8", "6", "6", "3", "3", "8", "6", "3", "4", "3", "6", "4", "8", "3", "8", "3", "6", "6", "4", "4", "8"],
						["4", "3", "8", "4", "8", "6", "4", "3", "6", "6", "6", "8", "4", "3", "8", "3", "3", "8", "4", "8", "4", "3", "4", "6", "6", "6", "4", "3", "8", "6", "3", "8", "3", "6", "3", "4", "6", "8", "8", "6", "4", "8", "3", "6", "4", "3", "4", "8", "3", "4", "6", "6", "8", "8", "3", "8", "6", "4", "3", "8", "3", "6", "4", "4"],
						["4", "6", "6", "3", "3", "8", "4", "8", "8", "3", "8", "4", "6", "4", "3", "6", "8", "4", "6", "3", "6", "3", "4", "8", "4", "4", "3", "8", "6", "3", "8", "6", "6", "8", "3", "8", "6", "8", "6", "3", "4", "6", "4", "8", "3", "3", "4", "4", "3", "3", "4", "4", "6", "4", "8", "3", "6", "6", "4", "6", "3", "8", "8", "8"]
					]
				},
				{
					"weight": 4,
					"id": 9,
					"reels": [
						["6", "2", "1", "1", "9", "2", "9", "1", "6", "6", "2", "9", "2", "9", "1", "6", "9", "9", "2", "6", "2", "1", "6", "9", "9", "6", "6", "2", "1", "1", "1", "2", "9", "6", "2", "2", "6", "2", "6", "9", "1", "1", "9", "9", "6", "1", "2", "1", "6", "1", "2", "2", "9", "1", "6", "6", "1", "1", "9", "9", "9", "2", "2", "6"],
						["1", "9", "6", "2", "6", "9", "1", "6", "2", "1", "9", "1", "9", "2", "6", "2", "6", "1", "1", "6", "1", "2", "6", "2", "2", "9", "9", "2", "9", "1", "6", "9", "2", "1", "6", "1", "1", "2", "9", "6", "2", "9", "6", "9", "1", "9", "2", "6", "9", "2", "9", "2", "2", "1", "9", "9", "6", "2", "6", "1", "6", "6", "1", "1"],
						["6", "2", "9", "9", "9", "2", "6", "6", "2", "6", "1", "9", "1", "1", "1", "2", "6", "6", "2", "9", "6", "9", "6", "2", "1", "1", "1", "9", "2", "2", "1", "9", "2", "2", "6", "1", "6", "1", "6", "9", "2", "6", "9", "9", "2", "9", "1", "1", "2", "9", "1", "6", "2", "9", "6", "6", "9", "9", "2", "6", "1", "1", "2", "1"],
						["1", "1", "6", "9", "6", "6", "2", "2", "6", "1", "9", "9", "2", "2", "1", "9", "2", "9", "6", "1", "1", "9", "2", "2", "6", "9", "1", "9", "2", "6", "1", "6", "2", "1", "6", "1", "2", "9", "1", "6", "1", "9", "2", "6", "6", "9", "9", "2", "9", "6", "6", "9", "2", "6", "2", "1", "9", "1", "2", "6", "9", "2", "1", "1"],
						["6", "2", "2", "6", "9", "2", "6", "1", "1", "2", "1", "9", "9", "6", "1", "9", "2", "2", "1", "1", "9", "9", "9", "2", "6", "6", "9", "6", "1", "2", "1", "6", "1", "9", "9", "2", "6", "2", "9", "6", "1", "6", "1", "2", "2", "1", "6", "9", "6", "1", "2", "1", "2", "2", "6", "6", "1", "2", "6", "9", "1", "9", "9", "9"]
					]
				},
				{
					"weight": 4,
					"id": 10,
					"reels": [
						["2", "3", "3", "8", "2", "8", "3", "7", "8", "8", "2", "7", "7", "2", "3", "7", "2", "3", "3", "3", "8", "3", "2", "7", "7", "7", "8", "7", "8", "2", "8", "2", "3", "2", "7", "7", "2", "7", "7", "3", "8", "8", "8", "2", "3", "8", "2", "3", "3", "2", "2", "3", "8", "2", "8", "8", "7", "7", "7", "3", "7", "3", "2", "8"],
						["7", "8", "2", "3", "7", "7", "3", "8", "8", "8", "3", "7", "2", "2", "2", "3", "2", "8", "3", "3", "8", "7", "2", "7", "3", "2", "8", "7", "3", "7", "2", "8", "3", "7", "3", "2", "3", "7", "8", "7", "2", "8", "8", "2", "8", "7", "2", "3", "8", "7", "7", "3", "7", "8", "3", "3", "8", "2", "2", "8", "3", "7", "2", "2"],
						["7", "3", "7", "2", "3", "8", "8", "3", "8", "2", "2", "3", "7", "8", "7", "2", "3", "8", "2", "3", "7", "8", "8", "3", "8", "2", "7", "7", "3", "2", "7", "2", "7", "2", "2", "7", "7", "8", "3", "2", "3", "3", "2", "7", "3", "8", "8", "8", "7", "8", "2", "2", "7", "3", "8", "7", "2", "8", "2", "8", "3", "3", "3", "7"],
						["7", "2", "3", "3", "2", "2", "7", "7", "7", "3", "3", "8", "8", "8", "2", "8", "8", "7", "3", "3", "8", "2", "2", "7", "8", "2", "7", "7", "3", "8", "2", "3", "3", "8", "3", "2", "7", "3", "8", "7", "2", "2", "7", "3", "8", "2", "8", "7", "3", "2", "7", "8", "3", "7", "3", "7", "2", "7", "2", "3", "2", "8", "8", "8"],
						["7", "8", "8", "8", "2", "7", "7", "2", "7", "2", "3", "3", "8", "3", "3", "2", "2", "8", "7", "8", "2", "7", "2", "8", "7", "3", "2", "7", "3", "8", "3", "3", "2", "7", "2", "3", "2", "2", "7", "8", "8", "7", "3", "3", "8", "8", "7", "3", "8", "3", "8", "7", "7", "3", "2", "2", "7", "2", "8", "3", "3", "2", "7", "8"]
					]
				},
				{
					"weight": 4,
					"id": 11,
					"reels": [
						["4", "4", "4", "7", "9", "7", "3", "3", "7", "9", "3", "9", "4", "7", "3", "9", "3", "9", "7", "3", "4", "3", "4", "7", "4", "9", "9", "7", "7", "9", "3", "4", "3", "9", "9", "7", "7", "3", "3", "7", "9", "9", "4", "4", "4", "4", "7", "3", "7", "9", "4", "9", "7", "7", "7", "4", "3", "9", "9", "4", "3", "3", "4", "3"],
						["7", "9", "7", "3", "4", "9", "4", "7", "4", "7", "9", "4", "3", "3", "9", "3", "4", "7", "9", "3", "9", "3", "3", "7", "7", "4", "4", "9", "9", "7", "4", "3", "4", "3", "9", "3", "3", "9", "7", "3", "4", "4", "7", "4", "9", "7", "7", "9", "7", "9", "3", "3", "4", "3", "3", "7", "4", "9", "7", "7", "9", "9", "4", "4"],
						["3", "4", "7", "4", "3", "4", "9", "7", "9", "7", "7", "3", "9", "3", "9", "4", "4", "3", "4", "3", "4", "9", "9", "9", "3", "4", "9", "7", "7", "7", "3", "7", "9", "9", "4", "3", "4", "4", "3", "7", "3", "4", "7", "7", "9", "9", "7", "3", "7", "3", "9", "7", "9", "3", "7", "3", "4", "4", "4", "7", "9", "9", "4", "3"],
						["7", "9", "3", "9", "4", "4", "7", "3", "9", "7", "3", "7", "4", "4", "9", "3", "9", "4", "3", "4", "9", "3", "7", "3", "7", "9", "4", "7", "7", "9", "4", "3", "4", "4", "7", "7", "9", "4", "9", "3", "9", "7", "9", "3", "3", "7", "4", "3", "3", "9", "3", "7", "3", "4", "9", "7", "7", "3", "7", "9", "4", "4", "9", "4"],
						["3", "7", "9", "7", "3", "3", "4", "9", "4", "4", "7", "3", "9", "9", "7", "4", "9", "3", "9", "4", "9", "3", "3", "3", "7", "7", "4", "7", "4", "7", "4", "9", "4", "9", "3", "3", "9", "4", "4", "7", "7", "7", "4", "3", "7", "9", "3", "9", "3", "4", "9", "3", "4", "3", "4", "9", "7", "7", "9", "9", "4", "3", "7", "7"]
					]
				},
				{
					"weight": 4,
					"id": 12,
					"reels": [
						["1", "4", "9", "4", "1", "4", "9", "9", "1", "9", "8", "4", "8", "8", "8", "1", "1", "8", "4", "1", "8", "8", "9", "9", "1", "9", "9", "4", "4", "1", "8", "4", "1", "9", "9", "4", "8", "1", "4", "4", "8", "4", "8", "1", "9", "8", "1", "9", "8", "1", "4", "9", "1", "8", "1", "8", "9", "4", "4", "9", "9", "8", "1", "4"],
						["1", "9", "9", "4", "8", "4", "8", "4", "9", "4", "1", "9", "1", "8", "1", "8", "1", "1", "8", "9", "4", "4", "4", "8", "8", "9", "9", "4", "8", "9", "1", "1", "4", "4", "1", "8", "1", "8", "9", "9", "1", "9", "9", "8", "8", "1", "4", "4", "8", "1", "9", "4", "8", "4", "4", "1", "9", "9", "1", "4", "9", "1", "8", "8"],
						["9", "1", "1", "4", "8", "8", "4", "8", "4", "9", "1", "9", "1", "9", "8", "4", "4", "9", "8", "4", "8", "1", "1", "9", "8", "9", "4", "1", "1", "8", "9", "4", "1", "1", "4", "8", "8", "8", "4", "9", "1", "8", "4", "1", "9", "9", "4", "1", "4", "8", "9", "1", "9", "9", "9", "4", "8", "1", "4", "9", "1", "8", "4", "8"],
						["1", "4", "4", "9", "1", "8", "9", "9", "4", "8", "1", "1", "8", "8", "9", "4", "9", "4", "1", "4", "1", "9", "1", "4", "9", "8", "1", "9", "8", "8", "8", "4", "9", "1", "1", "8", "9", "4", "8", "9", "1", "9", "8", "8", "4", "1", "4", "4", "8", "8", "1", "1", "8", "4", "8", "4", "1", "9", "9", "9", "4", "9", "1", "4"],
						["1", "8", "4", "1", "9", "8", "4", "1", "9", "8", "9", "9", "1", "4", "4", "8", "4", "9", "1", "9", "8", "8", "4", "1", "9", "9", "4", "8", "1", "4", "1", "8", "1", "8", "4", "8", "9", "9", "1", "9", "9", "4", "8", "1", "4", "4", "8", "1", "1", "4", "4", "1", "1", "8", "9", "4", "9", "8", "8", "9", "4", "9", "1", "8"]
					]
				}
			],
	
	
			"freeSpins_3": [
				// no win
				{
					"weight": 16,
					"id": 0,
					"reels": [
						["2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2", "2", "7", "6", "3", "2", "3", "7", "6", "3", "2", "6", "7", "6", "3", "7", "2", "2", "7", "3", "6", "7", "3", "6", "2", "7", "6", "2", "3", "2", "3", "6", "7", "7", "3", "6", "2"],
						["1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2", "1", "7", "2", "5", "2", "1", "7", "5", "2", "1", "5", "7", "7", "5", "2", "1", "1", "5", "7", "2", "5", "7", "2", "1", "2", "7", "1", "5", "7", "5", "2", "1", "1", "5", "7", "2"],
						["5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6", "5", "1", "3", "6", "5", "6", "1", "3", "1", "6", "5", "3", "5", "1", "6", "3", "5", "3", "6", "1", "1", "5", "3", "6", "6", "1", "5", "3", "5", "1", "3", "6", "1", "5", "3", "6"],
						["2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3", "2", "3", "5", "7", "6", "1", "3", "5", "2", "6", "7", "1", "5", "1", "6", "7", "3", "2", "7", "6", "2", "5", "3", "1", "7", "2", "6", "1", "3", "5", "7", "6", "1", "2", "5", "3"],
						["2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7", "2", "3", "5", "7", "6", "1", "1", "2", "5", "3", "6", "7", "2", "5", "1", "3", "6", "7", "6", "3", "5", "7", "1", "2", "3", "5", "2", "7", "6", "1", "5", "2", "1", "6", "3", "7"]
					]
				},
				{
					"weight": 16,
					"id": 1,
					"reels": [
						["3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3", "3", "8", "4", "7", "8", "3", "7", "4", "3", "7", "8", "4", "8", "3", "4", "7", "4", "7", "3", "8", "4", "3", "8", "7", "4", "7", "8", "3", "3", "7", "8", "4", "7", "8", "4", "3"],
						["6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8", "6", "3", "8", "2", "8", "2", "3", "6", "3", "2", "8", "6", "6", "8", "3", "2", "2", "6", "3", "8", "6", "8", "3", "2", "8", "6", "3", "2", "8", "3", "2", "6", "3", "6", "2", "8"],
						["6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7", "6", "2", "7", "4", "7", "2", "6", "4", "6", "4", "7", "2", "4", "6", "7", "2", "2", "7", "6", "4", "6", "4", "2", "7", "2", "4", "7", "6", "7", "2", "4", "6", "6", "4", "2", "7"],
						["7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8", "7", "4", "6", "2", "8", "3", "2", "8", "7", "6", "4", "3", "6", "4", "7", "2", "3", "8", "3", "2", "7", "8", "4", "6", "3", "2", "6", "8", "4", "7", "6", "4", "7", "3", "2", "8"],
						["7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6", "7", "2", "3", "6", "8", "4", "8", "2", "6", "4", "3", "7", "4", "6", "3", "2", "7", "8", "8", "2", "6", "4", "7", "3", "2", "3", "4", "8", "6", "7", "7", "8", "4", "3", "2", "6"]
					]
				},
				{
					"weight": 18,
					"id": 2,
					"reels": [
						["1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4", "1", "9", "8", "4", "9", "1", "4", "8", "4", "1", "9", "8", "1", "8", "9", "4", "4", "1", "9", "8", "9", "4", "1", "8", "8", "1", "9", "4", "4", "9", "1", "8", "1", "8", "9", "4"],
						["3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4", "3", "9", "4", "7", "9", "3", "4", "7", "7", "4", "9", "3", "4", "7", "9", "3", "7", "4", "3", "9", "7", "9", "3", "4", "3", "7", "4", "9", "9", "3", "7", "4", "3", "7", "9", "4"],
						["3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7", "3", "8", "7", "1", "8", "7", "1", "3", "1", "8", "7", "3", "7", "3", "1", "8", "7", "3", "8", "1", "1", "8", "7", "3", "1", "8", "7", "3", "3", "8", "1", "7", "3", "8", "1", "7"],
						["9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3", "9", "1", "8", "4", "7", "3", "4", "1", "7", "3", "8", "9", "7", "9", "4", "8", "3", "1", "3", "4", "1", "9", "7", "8", "9", "1", "8", "4", "3", "7", "8", "9", "4", "1", "7", "3"],
						["1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1", "1", "8", "9", "4", "3", "7", "4", "7", "9", "8", "3", "1", "8", "1", "7", "4", "3", "9", "4", "9", "8", "7", "3", "1", "3", "1", "8", "4", "7", "9", "3", "9", "4", "8", "7", "1"]
					]
				},
				// Win
				{
					"weight": 5,
					"id": 3,
					"reels": [
						["6", "5", "6", "2", "6", "2", "2", "1", "5", "1", "5", "6", "5", "1", "2", "1", "1", "5", "1", "2", "1", "6", "6", "2", "5", "2", "6", "1", "5", "6", "5", "2", "2", "5", "6", "5", "1", "5", "2", "1", "1", "6", "6", "1", "5", "2", "2", "6", "6", "6", "5", "6", "1", "1", "5", "2", "2", "2", "6", "5", "5", "1", "2", "1"],
						["2", "1", "5", "1", "1", "2", "5", "6", "5", "2", "6", "6", "2", "6", "5", "1", "2", "1", "2", "5", "2", "6", "5", "5", "1", "1", "5", "6", "6", "2", "6", "1", "1", "2", "1", "5", "5", "6", "1", "6", "6", "1", "2", "6", "2", "5", "5", "2", "6", "5", "2", "1", "2", "5", "2", "6", "5", "6", "1", "6", "1", "5", "1", "2"],
						["6", "5", "1", "2", "6", "2", "1", "2", "1", "6", "5", "1", "5", "6", "2", "5", "6", "2", "5", "5", "1", "6", "6", "2", "1", "2", "5", "2", "1", "6", "5", "1", "6", "6", "1", "5", "2", "2", "5", "2", "1", "6", "5", "2", "1", "1", "6", "5", "5", "1", "6", "2", "5", "1", "1", "2", "6", "5", "6", "5", "6", "2", "2", "1"],
						["6", "1", "5", "2", "1", "1", "1", "2", "2", "6", "5", "2", "5", "6", "6", "5", "5", "6", "5", "1", "1", "2", "1", "6", "6", "5", "2", "2", "5", "1", "2", "6", "5", "6", "5", "1", "2", "2", "5", "2", "1", "2", "1", "1", "6", "6", "5", "6", "1", "2", "1", "5", "2", "2", "1", "2", "1", "6", "6", "5", "5", "6", "6", "5"],
						["1", "6", "2", "1", "2", "5", "2", "6", "5", "1", "5", "6", "5", "2", "6", "1", "2", "2", "6", "1", "6", "1", "2", "5", "5", "2", "6", "1", "5", "5", "1", "6", "2", "1", "5", "2", "6", "6", "1", "1", "2", "1", "6", "5", "2", "6", "5", "5", "5", "2", "1", "2", "6", "1", "5", "5", "2", "2", "1", "6", "6", "6", "5", "1"]
					]
				},
				{
					"weight": 5,
					"id": 4,
					"reels": [
						["3", "7", "1", "7", "1", "1", "5", "7", "7", "3", "3", "1", "3", "5", "5", "5", "3", "1", "1", "5", "1", "7", "5", "7", "5", "1", "3", "5", "3", "7", "7", "3", "1", "1", "3", "5", "7", "3", "3", "7", "3", "7", "7", "1", "5", "1", "5", "5", "7", "1", "3", "3", "7", "5", "5", "5", "7", "7", "1", "1", "5", "3", "1", "3"],
						["5", "7", "5", "5", "3", "5", "1", "3", "3", "1", "7", "7", "1", "1", "3", "7", "5", "3", "3", "1", "7", "5", "3", "5", "5", "7", "3", "7", "1", "7", "1", "1", "5", "1", "3", "7", "5", "5", "1", "3", "7", "7", "3", "3", "1", "1", "7", "5", "1", "1", "3", "5", "3", "3", "7", "5", "7", "5", "5", "1", "7", "7", "1", "3"],
						["3", "1", "7", "5", "5", "3", "5", "7", "1", "1", "7", "7", "3", "3", "5", "1", "1", "3", "1", "5", "5", "7", "3", "3", "5", "7", "1", "7", "7", "5", "3", "1", "1", "7", "1", "1", "7", "3", "7", "5", "5", "3", "5", "1", "3", "7", "5", "3", "1", "5", "5", "3", "3", "7", "5", "3", "7", "7", "1", "5", "1", "1", "7", "3"],
						["7", "5", "3", "5", "1", "1", "5", "7", "3", "1", "7", "3", "3", "7", "1", "5", "5", "3", "3", "1", "7", "1", "1", "3", "7", "7", "5", "5", "7", "3", "5", "1", "5", "5", "7", "3", "1", "3", "5", "1", "7", "1", "1", "3", "7", "7", "3", "5", "7", "1", "5", "1", "5", "5", "3", "7", "3", "7", "3", "3", "1", "5", "1", "7"],
						["3", "1", "7", "3", "7", "5", "5", "5", "1", "7", "5", "1", "1", "3", "3", "7", "5", "5", "3", "3", "3", "7", "1", "1", "1", "7", "5", "7", "3", "7", "5", "1", "7", "7", "1", "7", "3", "5", "5", "7", "3", "1", "5", "5", "3", "1", "3", "1", "5", "7", "3", "1", "1", "5", "3", "3", "1", "7", "7", "1", "5", "5", "7", "3"]
					]
				},
				{
					"weight": 5,
					"id": 5,
					"reels": [
						["5", "5", "8", "1", "4", "4", "5", "8", "8", "4", "1", "1", "4", "5", "1", "8", "5", "1", "8", "8", "4", "4", "1", "8", "4", "8", "4", "5", "5", "5", "1", "1", "5", "1", "4", "5", "4", "1", "8", "8", "8", "4", "5", "4", "5", "1", "1", "4", "8", "1", "5", "5", "8", "1", "8", "4", "5", "1", "8", "1", "5", "4", "4", "4"],
						["4", "5", "1", "8", "4", "8", "1", "4", "4", "1", "8", "1", "5", "8", "5", "5", "1", "4", "1", "8", "1", "8", "1", "5", "4", "4", "4", "5", "8", "5", "5", "4", "8", "5", "1", "8", "4", "1", "4", "1", "4", "5", "8", "8", "5", "5", "4", "1", "8", "4", "5", "8", "1", "4", "4", "8", "5", "5", "1", "8", "1", "1", "4", "5"],
						["4", "1", "8", "8", "4", "5", "1", "4", "5", "5", "5", "8", "1", "8", "1", "4", "1", "1", "8", "4", "8", "4", "4", "5", "5", "5", "8", "8", "4", "1", "5", "1", "4", "1", "1", "5", "5", "5", "4", "4", "8", "5", "8", "1", "4", "1", "8", "4", "1", "1", "1", "8", "4", "1", "5", "5", "8", "8", "5", "5", "4", "4", "8", "4"],
						["8", "8", "4", "5", "5", "1", "8", "8", "5", "1", "1", "4", "4", "1", "5", "4", "1", "8", "5", "1", "4", "1", "4", "8", "5", "5", "8", "8", "5", "4", "1", "4", "5", "8", "4", "1", "8", "1", "4", "8", "5", "4", "4", "1", "8", "1", "5", "5", "1", "4", "4", "5", "8", "4", "8", "8", "1", "1", "4", "5", "5", "5", "4", "1"],
						["8", "8", "4", "4", "1", "4", "5", "8", "4", "1", "5", "1", "1", "8", "5", "5", "1", "8", "1", "1", "1", "5", "4", "8", "4", "8", "4", "5", "5", "5", "8", "4", "4", "4", "1", "8", "5", "5", "4", "5", "1", "8", "8", "4", "1", "4", "1", "5", "8", "5", "1", "5", "4", "8", "1", "8", "5", "4", "1", "1", "5", "4", "4", "8"]
					]
				},
				{
					"weight": 5,
					"id": 6,
					"reels": [
						["9", "3", "2", "2", "9", "5", "3", "5", "5", "3", "9", "2", "3", "9", "2", "5", "3", "5", "2", "2", "3", "3", "5", "5", "9", "9", "5", "3", "9", "5", "2", "2", "2", "5", "3", "3", "9", "9", "5", "2", "9", "3", "9", "5", "5", "3", "2", "2", "9", "9", "3", "5", "3", "5", "9", "3", "2", "5", "3", "5", "9", "2", "2", "2"],
						["5", "5", "9", "3", "9", "5", "3", "5", "3", "5", "3", "2", "2", "9", "2", "2", "9", "3", "3", "5", "2", "2", "5", "3", "2", "5", "9", "9", "2", "5", "9", "3", "5", "5", "3", "9", "5", "9", "2", "5", "3", "2", "3", "9", "3", "2", "2", "9", "5", "5", "9", "5", "2", "5", "2", "3", "3", "9", "2", "9", "9", "3", "3", "2"],
						["9", "9", "2", "2", "5", "9", "2", "3", "3", "3", "9", "2", "3", "5", "5", "5", "9", "9", "2", "5", "2", "9", "2", "2", "5", "3", "5", "3", "3", "5", "9", "3", "9", "2", "2", "9", "5", "3", "2", "3", "5", "9", "5", "2", "3", "9", "3", "5", "5", "5", "3", "3", "3", "5", "2", "9", "5", "3", "2", "2", "9", "9", "5", "2"],
						["5", "9", "3", "2", "2", "9", "3", "9", "5", "5", "2", "9", "5", "3", "2", "3", "9", "5", "5", "3", "9", "2", "2", "3", "5", "2", "3", "5", "9", "3", "2", "5", "3", "3", "2", "5", "3", "5", "9", "9", "9", "9", "3", "5", "2", "5", "2", "2", "9", "2", "2", "9", "3", "5", "3", "3", "5", "5", "5", "2", "2", "9", "3", "9"],
						["2", "5", "3", "3", "5", "2", "9", "9", "5", "2", "5", "3", "9", "2", "9", "3", "5", "5", "2", "3", "3", "5", "9", "9", "2", "5", "3", "3", "9", "5", "2", "2", "5", "2", "9", "2", "9", "5", "5", "3", "9", "9", "2", "5", "3", "2", "3", "3", "9", "3", "9", "2", "3", "2", "3", "5", "5", "2", "3", "2", "5", "5", "9", "9"]
					]
				},
				{
					"weight": 5,
					"id": 7,
					"reels": [
						["7", "2", "2", "4", "7", "6", "4", "4", "7", "6", "2", "2", "4", "6", "7", "6", "6", "7", "4", "4", "2", "7", "4", "6", "7", "2", "7", "6", "2", "2", "4", "6", "4", "7", "6", "4", "6", "7", "4", "2", "4", "6", "2", "2", "6", "6", "2", "7", "2", "2", "6", "4", "7", "7", "2", "2", "7", "4", "6", "6", "7", "4", "6", "4"],
						["6", "2", "2", "6", "2", "7", "4", "7", "6", "4", "2", "6", "4", "7", "7", "4", "6", "6", "2", "4", "7", "4", "6", "4", "6", "6", "2", "2", "2", "7", "7", "4", "7", "2", "6", "6", "2", "4", "7", "2", "6", "4", "2", "4", "4", "7", "7", "6", "7", "6", "7", "6", "2", "4", "4", "2", "4", "2", "2", "7", "6", "4", "7", "6"],
						["4", "6", "4", "4", "7", "6", "2", "4", "6", "6", "7", "2", "7", "7", "2", "2", "6", "4", "6", "2", "2", "4", "7", "6", "6", "7", "4", "7", "7", "2", "4", "2", "4", "4", "2", "7", "7", "6", "6", "7", "2", "6", "6", "6", "4", "2", "4", "2", "2", "6", "7", "7", "2", "7", "4", "2", "6", "6", "4", "2", "6", "4", "7", "4"],
						["6", "7", "7", "2", "4", "4", "6", "2", "2", "4", "7", "7", "4", "6", "6", "2", "4", "2", "6", "2", "7", "4", "6", "2", "7", "6", "6", "7", "4", "2", "4", "7", "6", "6", "4", "7", "7", "4", "6", "2", "4", "2", "2", "7", "4", "2", "6", "7", "6", "6", "6", "4", "2", "7", "2", "2", "6", "2", "7", "4", "6", "4", "4", "7"],
						["2", "6", "4", "6", "2", "4", "6", "7", "7", "4", "7", "7", "6", "4", "2", "2", "7", "4", "4", "6", "6", "6", "4", "2", "2", "4", "2", "7", "6", "6", "2", "7", "4", "7", "6", "2", "6", "7", "4", "6", "7", "4", "2", "7", "2", "4", "6", "2", "2", "6", "7", "7", "6", "7", "2", "4", "4", "2", "2", "4", "6", "4", "6", "7"]
					]
				},
				{
					"weight": 5,
					"id": 8,
					"reels": [
						["4", "3", "4", "8", "3", "4", "6", "4", "8", "3", "6", "8", "6", "8", "3", "6", "8", "3", "6", "4", "8", "8", "8", "4", "3", "4", "6", "4", "6", "3", "6", "3", "4", "6", "8", "3", "3", "6", "8", "4", "6", "3", "6", "4", "8", "4", "3", "8", "8", "3", "8", "8", "3", "6", "6", "4", "4", "4", "4", "3", "3", "6", "8", "6"],
						["3", "8", "3", "6", "8", "3", "6", "6", "4", "8", "4", "4", "3", "4", "8", "6", "8", "3", "8", "4", "8", "3", "8", "6", "4", "6", "4", "6", "6", "3", "4", "3", "3", "8", "6", "6", "4", "3", "8", "4", "4", "3", "8", "3", "4", "8", "6", "6", "4", "4", "3", "6", "8", "8", "3", "3", "6", "8", "4", "8", "3", "6", "4", "6"],
						["8", "4", "8", "6", "8", "3", "8", "4", "4", "3", "6", "3", "4", "3", "6", "6", "6", "4", "8", "3", "6", "4", "3", "3", "4", "4", "6", "3", "8", "8", "8", "6", "4", "8", "4", "8", "6", "3", "4", "3", "6", "8", "4", "8", "6", "6", "3", "3", "8", "6", "3", "4", "3", "6", "4", "8", "3", "8", "3", "6", "6", "4", "4", "8"],
						["4", "3", "8", "4", "8", "6", "4", "3", "6", "6", "6", "8", "4", "3", "8", "3", "3", "8", "4", "8", "4", "3", "4", "6", "6", "6", "4", "3", "8", "6", "3", "8", "3", "6", "3", "4", "6", "8", "8", "6", "4", "8", "3", "6", "4", "3", "4", "8", "3", "4", "6", "6", "8", "8", "3", "8", "6", "4", "3", "8", "3", "6", "4", "4"],
						["4", "6", "6", "3", "3", "8", "4", "8", "8", "3", "8", "4", "6", "4", "3", "6", "8", "4", "6", "3", "6", "3", "4", "8", "4", "4", "3", "8", "6", "3", "8", "6", "6", "8", "3", "8", "6", "8", "6", "3", "4", "6", "4", "8", "3", "3", "4", "4", "3", "3", "4", "4", "6", "4", "8", "3", "6", "6", "4", "6", "3", "8", "8", "8"]
					]
				},
				{
					"weight": 5,
					"id": 9,
					"reels": [
						["6", "2", "1", "1", "9", "2", "9", "1", "6", "6", "2", "9", "2", "9", "1", "6", "9", "9", "2", "6", "2", "1", "6", "9", "9", "6", "6", "2", "1", "1", "1", "2", "9", "6", "2", "2", "6", "2", "6", "9", "1", "1", "9", "9", "6", "1", "2", "1", "6", "1", "2", "2", "9", "1", "6", "6", "1", "1", "9", "9", "9", "2", "2", "6"],
						["1", "9", "6", "2", "6", "9", "1", "6", "2", "1", "9", "1", "9", "2", "6", "2", "6", "1", "1", "6", "1", "2", "6", "2", "2", "9", "9", "2", "9", "1", "6", "9", "2", "1", "6", "1", "1", "2", "9", "6", "2", "9", "6", "9", "1", "9", "2", "6", "9", "2", "9", "2", "2", "1", "9", "9", "6", "2", "6", "1", "6", "6", "1", "1"],
						["6", "2", "9", "9", "9", "2", "6", "6", "2", "6", "1", "9", "1", "1", "1", "2", "6", "6", "2", "9", "6", "9", "6", "2", "1", "1", "1", "9", "2", "2", "1", "9", "2", "2", "6", "1", "6", "1", "6", "9", "2", "6", "9", "9", "2", "9", "1", "1", "2", "9", "1", "6", "2", "9", "6", "6", "9", "9", "2", "6", "1", "1", "2", "1"],
						["1", "1", "6", "9", "6", "6", "2", "2", "6", "1", "9", "9", "2", "2", "1", "9", "2", "9", "6", "1", "1", "9", "2", "2", "6", "9", "1", "9", "2", "6", "1", "6", "2", "1", "6", "1", "2", "9", "1", "6", "1", "9", "2", "6", "6", "9", "9", "2", "9", "6", "6", "9", "2", "6", "2", "1", "9", "1", "2", "6", "9", "2", "1", "1"],
						["6", "2", "2", "6", "9", "2", "6", "1", "1", "2", "1", "9", "9", "6", "1", "9", "2", "2", "1", "1", "9", "9", "9", "2", "6", "6", "9", "6", "1", "2", "1", "6", "1", "9", "9", "2", "6", "2", "9", "6", "1", "6", "1", "2", "2", "1", "6", "9", "6", "1", "2", "1", "2", "2", "6", "6", "1", "2", "6", "9", "1", "9", "9", "9"]
					]
				},
				{
					"weight": 5,
					"id": 10,
					"reels": [
						["2", "3", "3", "8", "2", "8", "3", "7", "8", "8", "2", "7", "7", "2", "3", "7", "2", "3", "3", "3", "8", "3", "2", "7", "7", "7", "8", "7", "8", "2", "8", "2", "3", "2", "7", "7", "2", "7", "7", "3", "8", "8", "8", "2", "3", "8", "2", "3", "3", "2", "2", "3", "8", "2", "8", "8", "7", "7", "7", "3", "7", "3", "2", "8"],
						["7", "8", "2", "3", "7", "7", "3", "8", "8", "8", "3", "7", "2", "2", "2", "3", "2", "8", "3", "3", "8", "7", "2", "7", "3", "2", "8", "7", "3", "7", "2", "8", "3", "7", "3", "2", "3", "7", "8", "7", "2", "8", "8", "2", "8", "7", "2", "3", "8", "7", "7", "3", "7", "8", "3", "3", "8", "2", "2", "8", "3", "7", "2", "2"],
						["7", "3", "7", "2", "3", "8", "8", "3", "8", "2", "2", "3", "7", "8", "7", "2", "3", "8", "2", "3", "7", "8", "8", "3", "8", "2", "7", "7", "3", "2", "7", "2", "7", "2", "2", "7", "7", "8", "3", "2", "3", "3", "2", "7", "3", "8", "8", "8", "7", "8", "2", "2", "7", "3", "8", "7", "2", "8", "2", "8", "3", "3", "3", "7"],
						["7", "2", "3", "3", "2", "2", "7", "7", "7", "3", "3", "8", "8", "8", "2", "8", "8", "7", "3", "3", "8", "2", "2", "7", "8", "2", "7", "7", "3", "8", "2", "3", "3", "8", "3", "2", "7", "3", "8", "7", "2", "2", "7", "3", "8", "2", "8", "7", "3", "2", "7", "8", "3", "7", "3", "7", "2", "7", "2", "3", "2", "8", "8", "8"],
						["7", "8", "8", "8", "2", "7", "7", "2", "7", "2", "3", "3", "8", "3", "3", "2", "2", "8", "7", "8", "2", "7", "2", "8", "7", "3", "2", "7", "3", "8", "3", "3", "2", "7", "2", "3", "2", "2", "7", "8", "8", "7", "3", "3", "8", "8", "7", "3", "8", "3", "8", "7", "7", "3", "2", "2", "7", "2", "8", "3", "3", "2", "7", "8"]
					]
				},
				{
					"weight": 5,
					"id": 11,
					"reels": [
						["4", "4", "4", "7", "9", "7", "3", "3", "7", "9", "3", "9", "4", "7", "3", "9", "3", "9", "7", "3", "4", "3", "4", "7", "4", "9", "9", "7", "7", "9", "3", "4", "3", "9", "9", "7", "7", "3", "3", "7", "9", "9", "4", "4", "4", "4", "7", "3", "7", "9", "4", "9", "7", "7", "7", "4", "3", "9", "9", "4", "3", "3", "4", "3"],
						["7", "9", "7", "3", "4", "9", "4", "7", "4", "7", "9", "4", "3", "3", "9", "3", "4", "7", "9", "3", "9", "3", "3", "7", "7", "4", "4", "9", "9", "7", "4", "3", "4", "3", "9", "3", "3", "9", "7", "3", "4", "4", "7", "4", "9", "7", "7", "9", "7", "9", "3", "3", "4", "3", "3", "7", "4", "9", "7", "7", "9", "9", "4", "4"],
						["3", "4", "7", "4", "3", "4", "9", "7", "9", "7", "7", "3", "9", "3", "9", "4", "4", "3", "4", "3", "4", "9", "9", "9", "3", "4", "9", "7", "7", "7", "3", "7", "9", "9", "4", "3", "4", "4", "3", "7", "3", "4", "7", "7", "9", "9", "7", "3", "7", "3", "9", "7", "9", "3", "7", "3", "4", "4", "4", "7", "9", "9", "4", "3"],
						["7", "9", "3", "9", "4", "4", "7", "3", "9", "7", "3", "7", "4", "4", "9", "3", "9", "4", "3", "4", "9", "3", "7", "3", "7", "9", "4", "7", "7", "9", "4", "3", "4", "4", "7", "7", "9", "4", "9", "3", "9", "7", "9", "3", "3", "7", "4", "3", "3", "9", "3", "7", "3", "4", "9", "7", "7", "3", "7", "9", "4", "4", "9", "4"],
						["3", "7", "9", "7", "3", "3", "4", "9", "4", "4", "7", "3", "9", "9", "7", "4", "9", "3", "9", "4", "9", "3", "3", "3", "7", "7", "4", "7", "4", "7", "4", "9", "4", "9", "3", "3", "9", "4", "4", "7", "7", "7", "4", "3", "7", "9", "3", "9", "3", "4", "9", "3", "4", "3", "4", "9", "7", "7", "9", "9", "4", "3", "7", "7"]
					]
				},
				{
					"weight": 5,
					"id": 12,
					"reels": [
						["1", "4", "9", "4", "1", "4", "9", "9", "1", "9", "8", "4", "8", "8", "8", "1", "1", "8", "4", "1", "8", "8", "9", "9", "1", "9", "9", "4", "4", "1", "8", "4", "1", "9", "9", "4", "8", "1", "4", "4", "8", "4", "8", "1", "9", "8", "1", "9", "8", "1", "4", "9", "1", "8", "1", "8", "9", "4", "4", "9", "9", "8", "1", "4"],
						["1", "9", "9", "4", "8", "4", "8", "4", "9", "4", "1", "9", "1", "8", "1", "8", "1", "1", "8", "9", "4", "4", "4", "8", "8", "9", "9", "4", "8", "9", "1", "1", "4", "4", "1", "8", "1", "8", "9", "9", "1", "9", "9", "8", "8", "1", "4", "4", "8", "1", "9", "4", "8", "4", "4", "1", "9", "9", "1", "4", "9", "1", "8", "8"],
						["9", "1", "1", "4", "8", "8", "4", "8", "4", "9", "1", "9", "1", "9", "8", "4", "4", "9", "8", "4", "8", "1", "1", "9", "8", "9", "4", "1", "1", "8", "9", "4", "1", "1", "4", "8", "8", "8", "4", "9", "1", "8", "4", "1", "9", "9", "4", "1", "4", "8", "9", "1", "9", "9", "9", "4", "8", "1", "4", "9", "1", "8", "4", "8"],
						["1", "4", "4", "9", "1", "8", "9", "9", "4", "8", "1", "1", "8", "8", "9", "4", "9", "4", "1", "4", "1", "9", "1", "4", "9", "8", "1", "9", "8", "8", "8", "4", "9", "1", "1", "8", "9", "4", "8", "9", "1", "9", "8", "8", "4", "1", "4", "4", "8", "8", "1", "1", "8", "4", "8", "4", "1", "9", "9", "9", "4", "9", "1", "4"],
						["1", "8", "4", "1", "9", "8", "4", "1", "9", "8", "9", "9", "1", "4", "4", "8", "4", "9", "1", "9", "8", "8", "4", "1", "9", "9", "4", "8", "1", "4", "1", "8", "1", "8", "4", "8", "9", "9", "1", "9", "9", "4", "8", "1", "4", "4", "8", "1", "1", "4", "4", "1", "1", "8", "9", "4", "9", "8", "8", "9", "4", "9", "1", "8"]
					]
				}
			]
		}		
	},
}
