export class LocalStorage {
    private available = false;
    constructor() {

    }
    isLocalStorageAvailable() {
        if (!this.available) {
            try {
                var uid = new Date;
                window.localStorage.setItem(`${uid}`, `${uid}`);
                var fail = window.localStorage.getItem(`${uid}`) != `${uid}`;
                window.localStorage.removeItem(`${uid}`);

                this.available = true;

            } catch (exception) {
                this.available = false;
            }
        }

        return this.available;
    }
    public clear() {
        if(this.isLocalStorageAvailable())
            window.localStorage.clear();
    }
    public setItem(key, val) {
        if(this.isLocalStorageAvailable() && key!=null && val!=null){
            window.localStorage.setItem(key, val);
        }
    }
    public removeItem(key) {
        if(this.isLocalStorageAvailable()){
            window.localStorage.removeItem(key);
        }
    }
    public getItem(key) { 
        if(this.isLocalStorageAvailable()){
            return window.localStorage.getItem(key);
        }
        return null;
    }
    public storage(key) {
        if(this.isLocalStorageAvailable()){
            return window.localStorage;
        }
    }
};

const ls = new LocalStorage();
export default ls;