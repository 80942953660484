/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { createDefaultSlotStep, rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],

        createStep: (reelStripPositions, isFeature = false, order = 0, variation = "", name = "Base") => {
            return createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name);
        },
        // Must implement
        createDefaultStep: () =>  State.state.createStep([0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getReelStrips: () => State.state.getModelDefinition().reels,

        getBaseStep: (order = 0) => State.state.createDefaultStep(),

        getTrigger8FG: (order = 0) => {
            const step = State.state.createStep([2, 2, 2], false, order, "", "Bonus 8 FG Trigger");
            step.field0 = "6";
            step.field1 = "6";

            return step;
        },
        
        getMiniJackpotStep: (order = 0) => {
            const step = State.state.createStep([2, 2, 2], false, order, "", "Bonus 2x Mini");
            step.field0 = "0";
            step.field1 = "3";

            return step;
        },
        
        getFeatureStep: (order = 0) => {
            const step = State.state.createStep([0, 0, 0], true, order, "", "F-Step");
            step.reelsType = "reelsFeature";

            return step;
        },
    };
}
