import { mathModel } from "../../v3";
import { modelDefinition } from "./config";

const generateBaseSlotWindow =
  mathModel.createSlotWindowGenerator(modelDefinition);

export function generateSlotWindow(reelStripPositions: number[]) {
  const slotWindow = generateBaseSlotWindow(reelStripPositions);

  return slotWindow;
}
