import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import {getReelWindowFromIndex} from "../../../../helpers/helpers";
import Reel from './reel.vue';
import {
    createClearingStep,
    createSpinStep,
    createFreeSpinTriggerStep,
    modelDefinition,
    ReelIndexesOfFramedSymbols,
    StepJson,
    originatorId,
} from '../../../../state/models/slots/vgw080';


export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel
    },

    data: () => ({
        allSteps: [] as Step[],
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        labelForAddSpinStep(): string {
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin'
            return 'Add Base Spin';
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '';
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        isClearingStep(): boolean {
            return this.stepName === 'Clear';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'F-Trigger') return 'Free Spin Trigger';
            if (this.stepName === 'F-Retrigger') return 'Free Spin Retrigger';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },

        // ----- Base game state -----
        reels(): string[][] {
            return modelDefinition.reels;
        },
        canChangeReelStripPositions(): boolean {
            return !this.isClearingStep;
        },
        isFreeSpin(): boolean {
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        reelIndexesOfFramedSymbols(): ReelIndexesOfFramedSymbols {
                return this.currentStep?.json?.reelIndexesOfFramedSymbols || {reel1: [], reel5: []};
        },
        reelStripPositions(): number[] {
            return this.currentStep?.json?.reelStripPositions || [];
        },
        jackpotTriggers() {
            return modelDefinition.jackpotTriggers;
        }
    },

    methods: {
        // Step adding functions
        addClearingStep() {
            bus.$emit(events.ADD_STEP, createClearingStep());
        },
        addSpinStep() {
            bus.$emit(events.ADD_STEP, createSpinStep());
        },
        addFreeSpinTriggerStep() {
            bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep());
        },
        toggleSymbolFrame(reelIndex: number, slotWindIndex: number) {
            if(!this.currentStep) return;

            const reelNumber = reelIndex + 1;
            const reelKey = `reel${reelNumber}`;
            const reelStrip = this.reels[reelIndex];
            
            const reelStripIndex = (this.reelStripPositions[reelIndex] + slotWindIndex) % reelStrip.length;
            const framedSymbolsForReel = this.reelIndexesOfFramedSymbols[reelKey] || [];

            if(this.isSymbolFramed(reelIndex, slotWindIndex)) {
                const framedSymbolsWithoutIndex = framedSymbolsForReel.filter((symbolReelIndex) => symbolReelIndex !== reelStripIndex);
                this.currentStep.json.reelIndexesOfFramedSymbols[reelKey] = framedSymbolsWithoutIndex;
            } else {
                this.currentStep.json.reelIndexesOfFramedSymbols[reelKey].push(reelStripIndex);
            }

            this.saveChangesToStep();
        },
        isSymbolFramed(reelIndex: number, slotWindIndex: number){
            const reelNumber = reelIndex + 1;
            const reelKey = `reel${reelNumber}`;
            const reelStrip = this.reels[reelIndex];
            
            const reelStripIndex = (this.reelStripPositions[reelIndex] + slotWindIndex) % reelStrip.length;
            const framedIndexes = this.reelIndexesOfFramedSymbols[reelKey];
            
            return framedIndexes?.includes(reelStripIndex);
        },
        canSymbolBeFramed(reelIndex: number, symbol: string){
            const edgeReelIndexes = [0,4];
            const isEdgeReel = edgeReelIndexes.includes(reelIndex);
            if(!isEdgeReel || this.isClearingStep) return false;

            const triggeringSymbols = Object.keys(this.jackpotTriggers);
            return triggeringSymbols.includes(symbol);
        },

        // ----- Scenario persistence methods -----
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: {steps: Array<{test_scenario_step_id: number, json: StepJson}>}) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }

            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;

            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    isNextStepPick?: boolean;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
}

function updateStepsInPlace(steps: Step[]): boolean {
    let name = '';
    let isFreeSpin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;
    
    let isAnyStepModified = false;
    for(const step of steps) {
        // Handle clearing step by clearing all state
        if (step.json.name === 'Clear') {
            name = step.json.name;
            freeSpinPhase = undefined;
            freeSpinCount = 0;
        } else {
            const isFreeSpin = freeSpinCount > 0;
            const scatCells = findCells(step.json.reelStripPositions, modelDefinition.scatterSymbol);
            const freeSpinsAwarded = getFreeSpinsAwarded(scatCells.length);
            const isFreeSpinTrigger = freeSpinsAwarded > 0;

            name = 'Spin'
            if(isFreeSpin) {
                name = 'Free Spin'
                freeSpinCount -= 1;
                freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';
            }
            if (isFreeSpinTrigger) {
                name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                freeSpinCount += freeSpinsAwarded;
                freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
            }
        }

        Object.assign(step, {
            name, isFreeSpin, 
            freeSpinPhase, freeSpinCount
        })

        const json = {...step.json, name};
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }

    return isAnyStepModified;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = modelDefinition.reels;
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function getFreeSpinsAwarded(scatCount: number): number {
    return modelDefinition.freeSpinsAwarded[modelDefinition.reelsLayout.length - scatCount] ?? 0;
}