/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import { rowsFromIndexes } from "../../../../helpers/helpers";

export function setStateParams() {
  return {
    variations: [""],
    getModelDefinition: () =>
      State.state.standalone
        ? init.modelDefinition
        : State.state.slot.state.getModelDefinition(),
    createDefaultStep: () => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0],
        false,
        0,
        "",
        "Reels"
      );
      step.reelsType = "reels";
      step.rows = State.state.getModelDefinition().reels;
      step.reelSetIndex = 0;

      return step;
    },
    getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
    getRowsNo: () => State.state.getModelDefinition().getLayout(),
    getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
    getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
    getReelStrips: (index) => {
      return State.state.getModelDefinition().reelSets[index];
    },
    getBaseStep: () => State.state.createDefaultStep(),
    getFeatureTriggerStep: (order = 0) => {
      const step = State.state.createStep(
        [5, 54, 15, 0, 0],
        false,
        order,
        "",
        "Coin Bonus Trigger"
      );
      step.reelsType = "reels";
      step.reelSetIndex = 0;
      return step;
    },
    getStampedeTriggerStep: (order = 0) => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 18],
        false,
        order,
        "",
        "Stampede Bonus Trigger"
      );
      step.reelsType = "reels";
      step.reelSetIndex = 1;
      return step;
    },
    getFeatureStep: () => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0],
        true,
        0,
        "",
        "F-Step"
      );
      step.reelsType = "reels";
      step.reelSetIndex = 0;

			step.scattersSelectedMultipliers = [];
			for (let i = 0; i < State.state.getModelDefinition().totalSymbolNum; i++)
				step.scattersSelectedMultipliers.push("Random");

      return step;
    },
    prepareStepForDB: (step) => {
      const state = State.state;
      const reelStrips = state.getReelStrips(step.reelSetIndex);
      const noOfReels = 5;
      step.rows = rowsFromIndexes(
        reelStrips,
        step.reelStripPositions,
        noOfReels,
        3
      );
      step.randoms = step.reelStripPositions;
      return step;
    },
    getClearingStep: function() {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0],
        false,
        0,
        "",
        "Clear"
      );
      step.reelSetIndex = 0;
      step.gameState = "clear";
      return step;
    },
  };
}
