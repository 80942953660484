var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Free Spin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFillerStep()
              },
            },
          },
          [_vm._v("Filler step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center menu",
        },
        [
          _c("div", { staticClass: "button-with-drop-down" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedWin,
                    expression: "selectedWin",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedWin = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("Normal Win"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.normalWins, function (scenario) {
                  return [
                    _c("option", { domProps: { value: scenario } }, [
                      _vm._v(_vm._s(scenario.description)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "button-with-drop-down" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedWin,
                    expression: "selectedWin",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedWin = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("BIG Win"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.bigWins, function (scenario) {
                  return [
                    _c("option", { domProps: { value: scenario } }, [
                      _vm._v(_vm._s(scenario.description)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "button-with-drop-down" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedWin,
                    expression: "selectedWin",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedWin = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("MEGA Win"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.megaWins, function (scenario) {
                  return [
                    _c("option", { domProps: { value: scenario } }, [
                      _vm._v(_vm._s(scenario.description)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "button-with-drop-down" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedWin,
                    expression: "selectedWin",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedWin = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("EPIC Win"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.epicWins, function (scenario) {
                  return [
                    _c("option", { domProps: { value: scenario } }, [
                      _vm._v(_vm._s(scenario.description)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showJackpotCountdownNumber,
              expression: "showJackpotCountdownNumber",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Jackpot Countdown Number"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jackpotCountdownNumber,
                    expression: "jackpotCountdownNumber",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.jackpotCountdownNumber = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: undefined } }, [
                  _vm._v("auto"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.jackpotCountdownNumbers, function (n) {
                  return [
                    _c("option", { domProps: { value: n } }, [
                      _vm._v(_vm._s(n)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReels,
              expression: "showReels",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", {
            attrs: {
              index: 0,
              step: _vm.showReels ? _vm.step : _vm.dummyStep,
              isFeature: _vm.isFeature,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 1,
              step: _vm.showReels ? _vm.step : _vm.dummyStep,
              isFeature: _vm.isFeature,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 2,
              step: _vm.showReels ? _vm.step : _vm.dummyStep,
              isFeature: _vm.isFeature,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 3,
              step: _vm.showReels ? _vm.step : _vm.dummyStep,
              isFeature: _vm.isFeature,
            },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: {
              index: 4,
              step: _vm.showReels ? _vm.step : _vm.dummyStep,
              isFeature: _vm.isFeature,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }