import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import TopReel from "./reels/topreel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        topreel: TopReel,
        selectScatterMultiplier: SelectScatterMultiplier,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public show = false;
    public reelStripPositions = [];
    public reel = "";
    public isOpen = false;
    public topReelFirstSymbol = "NONE";
    public topReelSecondSymbol = "NONE";
    public topReelThirdSymbol = "NONE";
    public topReelForthSymbol = "NONE";
    public topReelReelStripPosition = "0";
    public reelsNo = 6;
    public scattersNumber = 28;
    public scattersSelectedMultipliers = new Array(28).fill(0);

    public baseReelHeightOne = 7;
    public baseReelHeightTwo = 6;
    public baseReelHeightThree = 6;
    public baseReelHeightFour = 6;
    public baseReelHeightFive = 6;
    public baseReelHeightSix = 7;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }

    public addRespinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getRespinStep() );
    }

    public updateTopReelReelStripPosition(value) {
        this.step.json.topReelReelStripPosition = value;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBaseReelHeight(index, value) {
        switch (index) {
            case 0:
                this.step.json.baseReelHeightOne = value;
                break;
            case 1:
                this.step.json.baseReelHeightTwo = value;
                break;
            case 2:
                this.step.json.baseReelHeightThree = value;
                break;
            case 3:
                this.step.json.baseReelHeightFour = value;
                break;
            case 4:
                this.step.json.baseReelHeightFive = value;
                break;
            case 5:
                this.step.json.baseReelHeightSix = value;
                break;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateTopReelSymbol(index, value) {
        switch (index) {
            case 1:
                this.step.json.topReelFirstSymbol = value;
                break;
            case 2:
                this.step.json.topReelSecondSymbol = value;
                break;
            case 3:
                this.step.json.topReelThirdSymbol = value;
                break;
            case 4:
                this.step.json.topReelForthSymbol = value;
                break;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBaseReelsHeights() {
        if (this.step.json.baseReelHeightOne != null) {
            this.baseReelHeightOne = this.step.json.baseReelHeightOne;
        }

        if (this.step.json.baseReelHeightTwo != null) {
            this.baseReelHeightTwo = this.step.json.baseReelHeightTwo;
        }

        if (this.step.json.baseReelHeightThree != null) {
            this.baseReelHeightThree = this.step.json.baseReelHeightThree;
        }

        if (this.step.json.baseReelHeightFour != null) {
            this.baseReelHeightFour = this.step.json.baseReelHeightFour;
        }

        if (this.step.json.baseReelHeightFive != null) {
            this.baseReelHeightFive = this.step.json.baseReelHeightFive;
        }

        if (this.step.json.baseReelHeightSix != null) {
            this.baseReelHeightSix = this.step.json.baseReelHeightSix;
        }
    }

    public updateTopReelSymbols() {
        if (this.step.json.topReelFirstSymbol != null) {
            this.topReelFirstSymbol = this.step.json.topReelFirstSymbol;
        } else {
            this.topReelFirstSymbol = "0";
        }

        if (this.step.json.topReelSecondSymbol != null) {
            this.topReelSecondSymbol = this.step.json.topReelSecondSymbol;
        } else {
            this.topReelSecondSymbol = "0";
        }

        if (this.step.json.topReelThirdSymbol != null) {
            this.topReelThirdSymbol = this.step.json.topReelThirdSymbol;
        } else {
            this.topReelThirdSymbol = "0";
        }

        if (this.step.json.topReelForthSymbol != null) {
            this.topReelForthSymbol = this.step.json.topReelForthSymbol;
        } else {
            this.topReelForthSymbol = "0";
        }
    }

    public updateTopReelReelStripPositionFromJson() {
        if (this.step.json.topReelReelStripPosition != null) {
            this.topReelReelStripPosition = this.step.json.topReelReelStripPosition;
        } else {
            this.topReelReelStripPosition = "0";
        }
    }

    public addFullNoScatter() {
        this.step.json.scattersSelectedMultipliers = new Array(this.scattersNumber).fill(0);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateRespinScattersMultipliers(event) {
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(this.scattersNumber).fill(0);
        }
        this.step.json.scattersSelectedMultipliers[event.target.id - 1] = Number(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.scattersSelectedMultipliers == undefined && this.step.json.respinScatterPrizes) {
            this.step.json.scattersSelectedMultipliers = [];

            // top reel
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[1][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[2][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[3][0]);
            this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[4][0]);

            for (let i = 1; i < 5; i++) {
                for (let j = 0; j < 6; j++) {
                    this.step.json.scattersSelectedMultipliers.push(this.step.json.respinScatterPrizes[j][i]);
                }
            }
        }

        if (this.step.json.scattersSelectedMultipliers != null) {
            this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
        } else {
            this.scattersSelectedMultipliers = new Array(this.scattersNumber).fill(0);
        }
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }
}
