/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
            
        createStep: (reelStripPositions, isFeature = false, order=0, variation="",name="Base") => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.field0 = "";
            step.field1 = "1";
            step.field2 = "";
            step.field3 = `[
                [0,0,0,0,0],
                [0,0,0,0,0],
                [0,0,0,0,0],
                [0,0,0,0,0]
            ]`;
            return step;
        },
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model 
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: (reel = 0) => State.state.getModelDefinition().reelsLayout[reel],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getBigWinStep: () => State.state.createStep([6, 19, 16, 5, 10], false, 0, "", "Big Win") ,
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([22, 13, 14, 0, 0], false, 0, "", "F-Trigger");
            step.field0 = "8";
            step.field1 = "3"; 
            step.field2 = "4"; 
            return step;
        },
        getFeatureStep: () => State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([17, 14, 13, 12, 0], true, 0, "", "F-Step") ,
    };
}
