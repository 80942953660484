import type { MultiplierCell } from '../multipliers';
import type { WinInfo } from '../../create-wins-evaluator';
import type { Choices } from '../../../../choices';
import { applyReelSpinFeatures } from './apply-reel-spin-features';
import type {
    FullReelWilds,
    ReelSpinFeatureDelivery,
    ReelSpinFeatures,
    CharacterWildsWithPositions,
} from '../../../shared';
import { modelDefinition } from '../../../../model-definition';
import { filterSlotWindow, type LineWin } from '@vgw/gdk-math-model-tools';
import { sortFeaturesByOrderGiven } from './sort-array-in-given-order';

export function deliverReelSpinFeatureResults(args: {
    choices: Choices;
    playAmount: number;
    coinAmount: number;
    resultsBeforeFeatures: {
        winInfo: WinInfo;
        slotWindow: string[][];
        cumulativeWinAmount: number;
    };
    resultsAfterAllFeatures: {
        winInfo: WinInfo;
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
    featuresSelectedForSpin: string[];
}): ReelSpinFeatures | undefined {
    const deliverySequence = args.choices.chooseReelSpinDeliverySequence();
    const featureDeliveryOrder = args.choices.chooseFeatureDeliveryOrder();

    const winCells = getWinCells({ lineWins: args.resultsAfterAllFeatures.winInfo.lineWins });

    args.resultsAfterAllFeatures.multiplierCells = args.resultsAfterAllFeatures?.multiplierCells?.filter(
        (multiplierCell) => {
            const shouldIncludeNonWinningMultipliers = args.choices.shouldIncludeNonWinningMultipliers();
            return (
                winCells[multiplierCell.cell[1]][multiplierCell.cell[0]] === 1 ||
                (winCells[multiplierCell.cell[1]][multiplierCell.cell[0]] === 0 && shouldIncludeNonWinningMultipliers)
            );
        },
    );

    const numberOfWildsOnEachReel = countWildsOnFullReelWildsReel({
        slotWindow: args.resultsBeforeFeatures.slotWindow,
        fullReelWilds: args.resultsAfterAllFeatures.fullReelWilds,
    });

    const areAnyReelsContainMoreThanOneWild = Object.values(numberOfWildsOnEachReel).some((count) => count > 1);

    // Before features delivery
    let deliverAllFeaturesBeforeReelsStop = false;
    if (args.resultsAfterAllFeatures.winInfo.scatterWin !== undefined || deliverySequence === 'BEFORE') {
        deliverAllFeaturesBeforeReelsStop = true;
    } else if (deliverySequence === 'AFTER' && areAnyReelsContainMoreThanOneWild) {
        deliverAllFeaturesBeforeReelsStop = true;
    } else if (deliverySequence === 'BOTH') {
        const numberOfFeatures = countNumberOfFeatures(args.resultsAfterAllFeatures);
        if (args.resultsAfterAllFeatures.winInfo.lineWinAmount <= 5 * args.playAmount || numberOfFeatures < 5)
            deliverAllFeaturesBeforeReelsStop = true;
    }

    const featuresDelivery = deliverApplicableFeatureByOrder({
        featureDeliveryOrder,
        choices: args.choices,
        coinAmount: args.coinAmount,
        playAmount: args.playAmount,
        slotWindow: args.resultsBeforeFeatures.slotWindow,
        listOfFeaturesToApply: args.featuresSelectedForSpin as ('FRW' | 'CW' | 'SW' | 'M')[],
        featureDetail: args.resultsAfterAllFeatures,
    });

    if (deliverAllFeaturesBeforeReelsStop || deliverySequence === 'AFTER') {
        const { slotWindow: _, winInfo: __, ...featureDeliveryProperties } = featuresDelivery;

        if (deliverAllFeaturesBeforeReelsStop) {
            return {
                beforeReelsStop: { ...featureDeliveryProperties },
            };
        } else {
            const featuresByWinningStatus = getFeaturesByWiningStatus({
                featuresDelivery,
                winCells,
                slotWindowBeforeFeaturesApplied: args.resultsBeforeFeatures.slotWindow,
            });
            const winningFeatures = removeNonWinningFeatures({ featuresByWinningStatus, featureDeliveryOrder });
            return {
                afterReelsStop: {
                    ...winningFeatures,
                    intermediateResults: {
                        slotWindow: args.resultsBeforeFeatures.slotWindow,
                        lineWins: args.resultsBeforeFeatures.winInfo.lineWins,
                        scatterWin: args.resultsBeforeFeatures.winInfo.scatterWin,
                        winAmount: args.resultsBeforeFeatures.winInfo.winAmount,
                        winCells: args.resultsBeforeFeatures.winInfo.winCells,
                        cumulativeWinAmount:
                            args.resultsBeforeFeatures.cumulativeWinAmount > 0
                                ? args.resultsBeforeFeatures.cumulativeWinAmount +
                                  args.resultsBeforeFeatures.winInfo.winAmount
                                : undefined,
                    },
                },
            };
        }
    } else if (deliverySequence === 'BOTH') {
        const featuresAllocatedToBeforeReelsStop: {
            fullReelWilds: number[];
            characterWilds: CharacterWildsWithPositions;
            singleWilds: [number, number][];
            multiplierCells: MultiplierCell[];
        } = {
            fullReelWilds: [],
            singleWilds: [],
            characterWilds: { pics: [], positionToWilds: [] },
            multiplierCells: [],
        };

        // add full reel wild reel with more than one wild to before
        const fullReelWilds = [...(args.resultsAfterAllFeatures.fullReelWilds ?? [])];
        const reelsWithMoreThanOneWilds = fullReelWilds.filter((reelIndex) => numberOfWildsOnEachReel[reelIndex] > 1);
        const validFullReelWilds = fullReelWilds.filter((reelIndex) => !reelsWithMoreThanOneWilds.includes(reelIndex));

        featuresAllocatedToBeforeReelsStop.fullReelWilds.push(...reelsWithMoreThanOneWilds);

        // add non winning features to BEFORE reels stop
        const featuresByWinningStatus = getFeaturesByWiningStatus({
            featuresDelivery: {
                fullReelWilds: validFullReelWilds,
                characterWilds: args.resultsAfterAllFeatures.characterWilds,
                singleWilds: args.resultsAfterAllFeatures.singleWilds,
                multiplierCells: args.resultsAfterAllFeatures.multiplierCells,
                slotWindow: args.resultsBeforeFeatures.slotWindow,
            },
            winCells,
            slotWindowBeforeFeaturesApplied: args.resultsBeforeFeatures.slotWindow,
        });

        featuresAllocatedToBeforeReelsStop.fullReelWilds.push(
            ...(featuresByWinningStatus.nonWinning.fullReelWilds ?? []),
        );
        featuresAllocatedToBeforeReelsStop.characterWilds.pics.push(
            ...(featuresByWinningStatus.nonWinning.characterWilds?.pics ?? []),
        );
        featuresAllocatedToBeforeReelsStop.characterWilds.positionToWilds.push(
            ...(featuresByWinningStatus.nonWinning.characterWilds?.positionToWilds ?? []),
        );
        featuresAllocatedToBeforeReelsStop.singleWilds.push(...(featuresByWinningStatus.nonWinning.singleWilds ?? []));
        featuresAllocatedToBeforeReelsStop.multiplierCells.push(
            ...(featuresByWinningStatus.nonWinning.multiplierCells ?? []),
        );

        // allocate remaining features
        const remainingFeatures = combineRemainingFeatures({
            featuresAllocatedToBeforeReelsStop,
            allFeatures: args.resultsAfterAllFeatures,
        });

        const featuresAllocatedToAfterReelsStop: {
            fullReelWilds: number[];
            characterWilds: CharacterWildsWithPositions;
            singleWilds: [number, number][];
            multiplierCells: MultiplierCell[];
        } = {
            fullReelWilds: [],
            singleWilds: [],
            characterWilds: { pics: [], positionToWilds: [] },
            multiplierCells: [],
        };

        for (const [_, feature] of remainingFeatures.entries()) {
            const allocateBefore = args.choices.chooseWhetherRemainingFeatureDeliveredBeforeReelsStop();
            const featuresAllocateBeforeOrAfter = allocateBefore
                ? featuresAllocatedToBeforeReelsStop
                : featuresAllocatedToAfterReelsStop;

            if (feature.type === 'FRW') featuresAllocateBeforeOrAfter.fullReelWilds.push(feature.reel);
            else if (feature.type === 'SW') featuresAllocateBeforeOrAfter.singleWilds.push(feature.cell);
            else if (feature.type === 'CW') {
                featuresAllocateBeforeOrAfter.characterWilds.pics.push(feature.symbol);
                featuresAllocateBeforeOrAfter.characterWilds.positionToWilds.push(...feature.positionToWild);
            } else if (feature.type === 'M') featuresAllocateBeforeOrAfter.multiplierCells.push(feature.multiplierCell);
        }

        const featureDeliveryBeforeReelsStop = deliverApplicableFeatureByOrder({
            featureDeliveryOrder,
            choices: args.choices,
            coinAmount: args.coinAmount,
            playAmount: args.playAmount,
            slotWindow: args.resultsBeforeFeatures.slotWindow,
            listOfFeaturesToApply: args.featuresSelectedForSpin as ('FRW' | 'CW' | 'SW' | 'M')[],
            featureDetail: featuresAllocatedToBeforeReelsStop,
        });

        const featureDeliveryAfterReelsStop = deliverApplicableFeatureByOrder({
            featureDeliveryOrder,
            choices: args.choices,
            coinAmount: args.coinAmount,
            playAmount: args.playAmount,
            slotWindow: args.resultsBeforeFeatures.slotWindow,
            listOfFeaturesToApply: args.featuresSelectedForSpin as ('FRW' | 'CW' | 'SW' | 'M')[],
            featureDetail: featuresAllocatedToAfterReelsStop,
        });

        if (
            featureDeliveryAfterReelsStop.winInfo.lineWinAmount > featureDeliveryBeforeReelsStop.winInfo.lineWinAmount
        ) {
            const { slotWindow: _, winInfo: __, ...remainingAfterReelsStopProperties } = featureDeliveryAfterReelsStop;
            const {
                slotWindow: ___,
                winInfo: ____,
                ...remainingBeforeReelsStopProperties
            } = featureDeliveryBeforeReelsStop;
            return {
                afterReelsStop: {
                    ...remainingAfterReelsStopProperties,
                    intermediateResults: {
                        slotWindow: featureDeliveryBeforeReelsStop.slotWindow,
                        lineWins: featureDeliveryBeforeReelsStop.winInfo.lineWins,
                        scatterWin: featureDeliveryBeforeReelsStop.winInfo.scatterWin,
                        winAmount: featureDeliveryBeforeReelsStop.winInfo.winAmount,
                        cumulativeWinAmount:
                            args.resultsBeforeFeatures.cumulativeWinAmount > 0
                                ? args.resultsBeforeFeatures.cumulativeWinAmount +
                                  featureDeliveryBeforeReelsStop.winInfo.winAmount
                                : undefined,
                        winCells: featureDeliveryBeforeReelsStop.winInfo.winCells,
                    },
                },
                beforeReelsStop: remainingBeforeReelsStopProperties,
            };
        } else {
            const { slotWindow: _, winInfo: __, ...remainingProperties } = featuresDelivery;
            return {
                beforeReelsStop: remainingProperties,
            };
        }
    }
}

export function combineRemainingFeatures({
    featuresAllocatedToBeforeReelsStop,
    allFeatures,
}: {
    featuresAllocatedToBeforeReelsStop: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
    allFeatures: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
}): FeaturesNotAllocated[] {
    const combinedFeaturesNotAllocated: FeaturesNotAllocated[] = [];

    if (allFeatures.fullReelWilds) {
        combinedFeaturesNotAllocated.push(
            ...(allFeatures.fullReelWilds ?? [])
                .filter((reelIndex) => !(featuresAllocatedToBeforeReelsStop.fullReelWilds ?? []).includes(reelIndex))
                .map((reelIndex) => {
                    return { type: 'FRW' as const, reel: reelIndex };
                }),
        );
    }
    if (allFeatures.singleWilds) {
        combinedFeaturesNotAllocated.push(
            ...(allFeatures.singleWilds ?? [])
                .filter((singleWild) => !(featuresAllocatedToBeforeReelsStop.singleWilds ?? []).includes(singleWild))
                .map((cell) => {
                    return { type: 'SW' as const, cell };
                }),
        );
    }
    if (allFeatures.multiplierCells) {
        combinedFeaturesNotAllocated.push(
            ...(allFeatures.multiplierCells ?? [])
                .filter(
                    (multiplierCell) =>
                        !(featuresAllocatedToBeforeReelsStop.multiplierCells ?? []).includes(multiplierCell),
                )
                .map((multiplierCell) => {
                    return { type: 'M' as const, multiplierCell };
                }),
        );
    }
    if (allFeatures.characterWilds) {
        const charaterWildSymbolsNotAllocatedBeforeReelsStop = (allFeatures.characterWilds.pics ?? []).filter(
            (characterWild) => !(featuresAllocatedToBeforeReelsStop.characterWilds?.pics ?? []).includes(characterWild),
        );

        for (const symbol of charaterWildSymbolsNotAllocatedBeforeReelsStop) {
            combinedFeaturesNotAllocated.push({
                type: 'CW',
                symbol,
                positionToWild: (featuresAllocatedToBeforeReelsStop.characterWilds?.positionToWilds ?? []).filter(
                    (position) => charaterWildSymbolsNotAllocatedBeforeReelsStop.includes(position.symbol),
                ),
            });
        }
    }
    return combinedFeaturesNotAllocated;
}

type FeaturesNotAllocated =
    | { type: 'FRW'; reel: number }
    | {
          type: 'CW';
          symbol: 'PIC1' | 'PIC2' | 'PIC3';
          positionToWild: { symbol: 'PIC1' | 'PIC2' | 'PIC3'; rowIndex: number; reelIndex: number }[];
      }
    | { type: 'SW'; cell: [number, number] }
    | { type: 'M'; multiplierCell: MultiplierCell };

function removeNonWinningFeatures({
    featuresByWinningStatus,
    featureDeliveryOrder,
}: {
    features?: ReelSpinFeatureDelivery;
    featuresByWinningStatus: FeaturesByWinningStatus;
    featureDeliveryOrder: ('FRW' | 'CW' | 'SW' | 'M')[];
}): {
    fullReelWilds?: number[];
    characterWilds?: CharacterWildsWithPositions;
    singleWilds?: [number, number][];
    multiplierCells?: MultiplierCell[];
    featuresInOrder: ('FRW' | 'CW' | 'SW' | 'M')[];
} {
    const applicableFeatures = getFeaturesApplied({
        fullReelWilds: featuresByWinningStatus.winning.fullReelWilds,
        singleWilds: featuresByWinningStatus.winning.singleWilds,
        multiplierCells: featuresByWinningStatus.winning.multiplierCells,
        characterWilds: featuresByWinningStatus.winning.characterWilds,
        featureDeliveryOrder,
    });
    return {
        fullReelWilds: applicableFeatures.FRW ? featuresByWinningStatus.winning.fullReelWilds : undefined,
        singleWilds: applicableFeatures.SW ? featuresByWinningStatus.winning.singleWilds : undefined,
        multiplierCells: applicableFeatures.M ? featuresByWinningStatus.winning.multiplierCells : undefined,
        characterWilds: applicableFeatures.CW ? featuresByWinningStatus.winning.characterWilds : undefined,

        featuresInOrder: applicableFeatures.features,
    };
}

type FeaturesByWinningStatus = {
    winning: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
    nonWinning: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
};

function getFeaturesByWiningStatus({
    featuresDelivery,
    winCells,
    slotWindowBeforeFeaturesApplied,
}: {
    featuresDelivery?: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    } & { slotWindow: string[][] };
    winCells: number[][];
    slotWindowBeforeFeaturesApplied: string[][];
}): FeaturesByWinningStatus {
    const winningCharacterWilds = featuresDelivery?.characterWilds?.pics.filter((characterWildSymbol) => {
        const characterSymbolPositions = filterSlotWindow(
            slotWindowBeforeFeaturesApplied,
            (symbol) => symbol === characterWildSymbol,
        );
        return characterSymbolPositions.some((position) => winCells[position[1]][position[0]] === 1);
    });
    const winningCharacterWildPositions = featuresDelivery?.characterWilds?.positionToWilds.filter((position) =>
        winningCharacterWilds?.includes(position.symbol),
    );

    const nonWinningCharacterWilds = featuresDelivery?.characterWilds?.pics.filter(
        (characterWildSymbol) => !winningCharacterWilds?.includes(characterWildSymbol),
    );

    const nonWinningCharacterWildPositions = featuresDelivery?.characterWilds?.positionToWilds.filter((position) =>
        nonWinningCharacterWilds?.includes(position.symbol),
    );

    return {
        winning: {
            fullReelWilds: featuresDelivery?.fullReelWilds?.filter((reelIndex) =>
                winCells[reelIndex].some((cellValue) => cellValue === 1),
            ),
            singleWilds: featuresDelivery?.singleWilds?.filter(
                (singleWildCell) => winCells[singleWildCell[1]][singleWildCell[0]] === 1,
            ),
            multiplierCells: featuresDelivery?.multiplierCells?.filter(
                (multiplierCell) => winCells[multiplierCell.cell[1]][multiplierCell.cell[0]] === 1,
            ),
            characterWilds: {
                pics: winningCharacterWilds ?? [],
                positionToWilds: winningCharacterWildPositions ?? [],
            },
        },
        nonWinning: {
            fullReelWilds: featuresDelivery?.fullReelWilds?.filter((reelIndex) =>
                winCells[reelIndex].every((cellValue) => cellValue === 0),
            ),
            singleWilds: featuresDelivery?.singleWilds?.filter(
                (singleWildCell) => winCells[singleWildCell[1]][singleWildCell[0]] === 0,
            ),
            multiplierCells: featuresDelivery?.multiplierCells?.filter(
                (multiplierCell) => winCells[multiplierCell.cell[1]][multiplierCell.cell[0]] === 0,
            ),
            characterWilds: {
                pics: nonWinningCharacterWilds ?? [],
                positionToWilds: nonWinningCharacterWildPositions ?? [],
            },
        },
    };
}

function deliverApplicableFeatureByOrder({
    featureDeliveryOrder,
    playAmount,
    coinAmount,
    slotWindow,
    listOfFeaturesToApply,
    featureDetail,
}: {
    listOfFeaturesToApply: ('FRW' | 'CW' | 'SW' | 'M')[];
    featureDeliveryOrder: ('FRW' | 'CW' | 'SW' | 'M')[];
    choices: Choices;
    coinAmount: number;
    playAmount: number;
    slotWindow: string[][];
    featureDetail: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
}): ReelSpinFeatureDelivery & {
    slotWindow: string[][];
    winInfo: WinInfo;
} {
    const featuresSorted = sortFeaturesByOrderGiven(listOfFeaturesToApply, featureDeliveryOrder);

    const featureResultsAfterReApply = applyReelSpinFeatures({
        features: featuresSorted,
        playAmount,
        coinAmount,
        newSlotWindow: slotWindow.map((reel) => [...reel]),
        featuresAwarded: { ...featureDetail, characterWilds: featureDetail.characterWilds?.pics },
    });

    const { featureResults } = featureResultsAfterReApply;

    const appliedFeatures = getFeaturesApplied({
        fullReelWilds: featureResults.fullReelWilds,
        singleWilds: featureResults.singleWilds,
        characterWilds: featureResults.characterWilds,
        multiplierCells: featureResults.multiplierCells,
        featureDeliveryOrder,
    });

    return {
        fullReelWilds: appliedFeatures.FRW ? featureResults.fullReelWilds : undefined,
        singleWilds: appliedFeatures.SW ? featureResults.singleWilds : undefined,
        characterWilds: appliedFeatures.CW ? featureResults.characterWilds : undefined,
        multiplierCells: appliedFeatures.M ? featureResults.multiplierCells : undefined,
        featuresInOrder: appliedFeatures.features,
        slotWindow: featureResultsAfterReApply.newSlotWindow,
        winInfo: featureResultsAfterReApply.winInfoAfterFeature,
    };
}

function countNumberOfFeatures(args: {
    fullReelWilds?: FullReelWilds;
    characterWilds?: CharacterWildsWithPositions;
    singleWilds?: [number, number][];
    multiplierCells?: MultiplierCell[];
}) {
    const featureSuccessCount = [
        args.fullReelWilds?.length ?? 0,
        args.characterWilds?.pics.length ?? 0,
        args.singleWilds?.length ?? 0,
        args.multiplierCells?.length ?? 0,
    ];
    return featureSuccessCount.reduce((total, count) => total + count);
}

function getFeaturesApplied({
    fullReelWilds,
    characterWilds,
    singleWilds,
    multiplierCells,
    featureDeliveryOrder,
}: {
    fullReelWilds?: FullReelWilds;
    characterWilds?: CharacterWildsWithPositions;
    singleWilds?: [number, number][];
    multiplierCells?: MultiplierCell[];
    featureDeliveryOrder: ('FRW' | 'CW' | 'SW' | 'M')[];
}): {
    FRW: boolean;
    CW: boolean;
    SW: boolean;
    M: boolean;
    features: ('FRW' | 'CW' | 'SW' | 'M')[];
} {
    const featuresApplied = {
        FRW: (fullReelWilds ?? []).length > 0,
        CW: (characterWilds?.pics ?? []).length > 0,
        SW: (singleWilds ?? []).length > 0,
        M: (multiplierCells ?? []).length > 0,
    };

    const features: FeatureType[] = [];

    if (featuresApplied.FRW) features.push('FRW');
    if (featuresApplied.CW) features.push('CW');
    if (featuresApplied.SW) features.push('SW');
    if (featuresApplied.M) features.push('M');

    return {
        ...featuresApplied,
        features: sortFeaturesByOrderGiven<'FRW' | 'CW' | 'SW' | 'M'>(features, featureDeliveryOrder),
    };
}

type FeatureType = 'FRW' | 'CW' | 'SW' | 'M';
function countWildsOnFullReelWildsReel(args: { slotWindow: string[][]; fullReelWilds: number[] | undefined }) {
    return (args.fullReelWilds ?? []).reduce((wildCountByReelIndex: Record<string, number>, reelIndex) => {
        wildCountByReelIndex[reelIndex] = args.slotWindow[reelIndex].filter(
            (symbol) => symbol === modelDefinition.wildSymbol,
        ).length;
        return wildCountByReelIndex;
    }, {});
}

function getWinCells({ lineWins }: { lineWins: LineWin[] }) {
    const cells: [number, number][] = [];
    for (const lineWin of lineWins) {
        const playLineIndex = lineWin.playLineIndex;
        const playLine = modelDefinition.playLines[playLineIndex];

        for (const [reelIndex, rowIndex] of playLine.entries()) {
            cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
            if (reelIndex < lineWin.length) {
                cells[reelIndex][rowIndex] = 1;
            }
        }
    }
    return cells;
}
