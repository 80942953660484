import { mathModel } from "../../../v3";
import { JackpotWinCounts } from "./compute-jackpot-win-counts";

type WinType = mathModel.WinType;

/** Returns the most appropriate win type for the given spin outcome details. */
export function getWinType(info: {
  totalWinAmount: number;
  isFreeSpinTrigger?: boolean;
  isFreeSpins?: boolean;
  isWildFury?: boolean;
  jackpotWinCounts?: JackpotWinCounts;
}): WinType {
  const isJackpotWin = info.jackpotWinCounts
    ? info.jackpotWinCounts.MINI > 0 ||
      info.jackpotWinCounts.MINOR > 0 ||
      info.jackpotWinCounts.MAJOR > 0 ||
      info.jackpotWinCounts.GRAND > 0
    : false;

  if (isJackpotWin) return "JACKPOT_WIN";
  else if (info.isFreeSpinTrigger) return "SCATTER_WIN";
  else if (info.isFreeSpins)
    return info.totalWinAmount > 0 ? "FREE_SPIN_WIN" : "FREE_SPIN_NO_WIN";
  else if (info.isWildFury)
    return info.totalWinAmount > 0 ? "BONUS_WIN" : "NO_WIN";
  return info.totalWinAmount > 0 ? "NORMAL_WIN" : "NO_WIN";
}
