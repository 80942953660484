export interface StepJson {
    name: string;
    originatorId: number;
    gameState: 'clear' | 'continue';
    reelStripPositions: number[];
    stackSymbolReplacement: string;
    reelSet?: FreeSpinSet;
    multiplierSet?: FreeSpinSet;
    freeSpinMultiplier?: number;
    coinMode: CoinMode;
    coinPrizes?: CoinPrize[][];
}

export type FreeSpinSet = 'set1' | 'set2' | 'set3' | 'set4' | 'set5' | 'set6';
export type CoinMode = 'NORMAL_COIN' | 'DIAMOND_COIN';
export type CoinPrize = number | 'MINI' | 'MINOR' | 'MAJOR';

export function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        originatorId,
        gameState: 'clear',
        reelStripPositions: [90, 12, 12, 0, 1],
        stackSymbolReplacement: 'PIC1',
        coinMode: 'NORMAL_COIN',
    };
}

export function createSpinStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [60, 60, 60, 60, 60],
        stackSymbolReplacement: 'PIC1',
        coinMode: 'NORMAL_COIN',
    };
}

export function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [15, 32, 38, 29, 25],
        stackSymbolReplacement: 'PIC1',
        coinMode: 'NORMAL_COIN',
    };
}

export function createFireshotTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [268, 14, 96, 12, 48],
        stackSymbolReplacement: 'PIC1',
        coinMode: 'NORMAL_COIN',
    };
}

export const originatorId = new Date().getTime();
