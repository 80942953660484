import Vue from "vue";
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../../../../../helpers/casino";
import {trace, TODO, rowsFromIndexes, getReelWindowFromIndex, wrapIndex, getAssetsPath} from "./../../../../../helpers/helpers";
import State from "./../../../../../state/state";
import bus from "./../../../../../common/bus";
import events from "./../../../../../common/events";
import SymbolPickable from "../components/SymbolPickable.vue";
import { SymbolInfo } from "src/state/models/slots/vgw060/state";
import Content from "../content.vue";

@Component({
    components: {
        SymbolPickable: SymbolPickable,
    },
    data: () => {
        return {
            reelIndex: 0,
            symPicks: [],
            // symbolInfoReel: [],
        };
    },
})
export default class Reel extends Vue {
    @Prop({ type: Number, default: 0}) public index;
    @Prop({ type: Number, default: 0}) public symbolInfoIndex;
    @Prop() public symbolInfoReel;//: SymbolInfo[] = []; //vue error after forced initialisation
    @Prop() public step: any;
    @Prop({ type: Number, default: 0}) public substep;
    @Prop({ type: Boolean, default: false}) public isJackpotContainer;
    @Prop({ type: Boolean, default: false}) public isJackpotLocked;
    @Prop({ type: Boolean, default: false}) public isJackpotGame;
    @Prop({ type: Boolean, default: false}) public isCascade;
    @Prop({ type: Boolean, default: false}) public isLocked;
    public btnUp: string = `${getAssetsPath()}/images/reel-step-up.png`;
    public btnDown: string = `${getAssetsPath()}/images/reel-step-down.png`;
    private reelIndex = 0;
    private text = "0";
    private p0top = "";
    private p1top = "";
    private p0bottom = "";
    private p1bottom = "";
    private reels : string[][] = [];
    private nRows;
    private nReels;
    private show = false;
    private canUpdate = false;
    private updateTimeout;

    public isPreviousCoin(rowNo : number) : boolean {
        //TODO: wip need to consider cascading coins
        const jackpotCoinExistsPreviously = () : boolean => this.step.json.symbolInfos[this.symbolInfoIndex - 1][this.index][rowNo] != null;
        const baseCoinExistsPreviously = () : boolean => this.step.json.symbolInfos != null && this.step.json.symbolInfos[0] != null && this.step.json.symbolInfos[0][this.index][rowNo] != null;
        const previousCascadeCoinExists = () : boolean => this.step.json.cascadeSymbolInfos[this.symbolInfoIndex - 1][this.index][rowNo] != null;
        const lastReelDataSymbolInfo : string[][] = State.state.getLastReelData(this.step).symbolInfo;
        const lastCoinExists = () : boolean => lastReelDataSymbolInfo != null && lastReelDataSymbolInfo[this.index][rowNo] != null;

        if (!this.isCascade) {
            if (this.symbolInfoIndex > 0) {
                if (this.symbolInfoIndex > 1) {
                    //jackpot rounds after first
                    return jackpotCoinExistsPreviously();
                } else {
                    //first jackpot round
                    return lastCoinExists();
                }
            }
        } else {
            // if (this.symbolInfoIndex > 0) {
            //     //cascade round after first
            //     return previousCascadeCoinExists();
            // } else {
            //     //first cascade round 
            //     return baseCoinExistsPreviously();
            // }
        }
        
        return false;
    }

    constructor() {
        super();
        const state = State.state;
        this.nRows = state.getNumberOfRows();
        this.nReels = state.getNumberOfReels();
    }

    @Watch("step")
    public stepChanged(step) {
        if (!step || !step.json) { return; }
        this.canUpdate = false;
        this.reelIndex = step.json.reelStripPositions[this.index];

        this.$nextTick(() => {
            this.canUpdate = true;
            this.updateStep();
        });
    }

    public mounted() {
        // this.$refs.reel["addEventListener"]("wheel", this.scrollFunction);
    }
    public beforeDestroy() {
        // this.$refs.reel["removeEventListener"]("wheel", this.scrollFunction);
    }
    private getReels() {
        return ["lol"];
    }
    public updateStep() {
        if (!this.step) {
            this.show = false;
            return;
        }

        this.reels = this.getStepReel();
        this.show = true;

        this.updateSymPicks();
        this.updateSymInfo();
    }

    private getStepReel() {
        const cascadeInfos : string[][][] = this.step.json.cascadeInfos;
        
        if (this.isCascade) {
            return cascadeInfos[this.symbolInfoIndex][this.index];
        } else if (this.isJackpotContainer && (<Content>this.$parent).hasCascadesSaved) {
            return (<Content>this.$parent).getLastCascade()[this.index];
        } else {
            return this.step.json.rows[this.index];
        }
    }
    public updateSymPicks() {
        this.$data.symPicks = [];
        for (let i = 0; i < this.getStepReel().length; i++) {
            this.$data.symPicks.push(this.getStepReel()[i]);
        }
    }

    private getStepSymbolInfo() {
        if (this.isCascade) {
            return this.step.json.cascadeSymbolInfos[this.symbolInfoIndex][this.index];
        } else {
            return this.step.json.symbolInfos[this.symbolInfoIndex][this.index];
        }
    }
    public updateSymInfo() {
        this.$data.symbolInfoReel = [];
        for (let i = 0; i < this.getStepReel().length; i++) {
            this.$data.symbolInfoReel.push(this.getStepSymbolInfo()[i]);
        }
    }

    public updateDB() {
        if (!this.step) { return; }

        if (this.isCascade) {
            this.step.json.cascadeInfos[this.symbolInfoIndex][this.index] = this.$data.symPicks;
        } else {
            if (!this.isJackpotContainer)
                this.step.json.rows[this.index] = this.$data.symPicks;
            
            Vue.set(this.step.json.symbolInfos[this.symbolInfoIndex], this.index, this.symbolInfoReel);
        }
        
        // //Update future jackpot game coins too - temporarily disabled
        // if (this.symbolInfoIndex < this.step.json.symbolInfos.length - 1) {
        //     for (let futureIndex = this.symbolInfoIndex + 1; futureIndex < this.step.json.symbolInfos.length; futureIndex++) {
        //         const reel = this.step.json.symbolInfos[futureIndex][this.index];
        //         for (let row = 0; row < reel.length; row++) {
        //             reel[row] = this.symbolInfoReel[row];
        //         }
        //     }
        // }

        (<Content>this.$parent).updateCascadeStatus();

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }, 500);
    }
}
