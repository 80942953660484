var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c("div", { staticStyle: { height: "50px" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFortuneCart === true,
                expression: "isFortuneCart === true",
              },
            ],
            staticClass: "flex-center sub-title-menu",
          },
          [
            _c("div", { staticClass: "small", attrs: { align: "center" } }, [
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fortuneCart,
                        expression: "fortuneCart",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.fortuneCart = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.doChangePattern()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.fortuneCartItems, function (item) {
                    return _c(
                      "option",
                      { key: "fc-" + item, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isBigWheel === true,
                expression: "isBigWheel === true",
              },
            ],
            staticClass: "flex-center sub-title-menu",
          },
          [
            _c("div", { staticClass: "small", attrs: { align: "center" } }, [
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bigWheel,
                        expression: "bigWheel",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.bigWheel = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.doChangePattern()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.bigWheelPrizes, function (item) {
                    return _c(
                      "option",
                      { key: "bw-" + item, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { align: "center" } }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.down()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-up",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.up()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-2x fa-chevron-down",
                  staticStyle: { margin: "5px", color: "rgb(0, 199, 0)" },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p1top))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reels, function (r, ri) {
          return _c("div", { key: ri, staticClass: "symbol on" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(r))]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "symbol dim" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.p0bottom))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }