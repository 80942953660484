var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewJumboSymbols()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Base 3 bones")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewJumboFSSymbols()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerFSStep()
              },
            },
          },
          [_vm._v("FS 2 bones")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboSymbols,
                expression: "showJumboSymbols",
              },
            ],
            staticClass: "flex-center fs-selector sm-label",
          },
          [
            _c(
              "label",
              { staticClass: "jumboSymbol", attrs: { for: "symbol_base" } },
              [_vm._v("Base Jumbo Symbol: ")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jumboSymbolSet,
                    expression: "jumboSymbolSet",
                  },
                ],
                attrs: { id: "symbol_base" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.jumboSymbolSet = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.viewJumboInfo()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("Select Option"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v("No jumbo symbol"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "LV1" } }, [_vm._v("LV1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "LV2" } }, [_vm._v("LV2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "LV3" } }, [_vm._v("LV3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "LV4" } }, [_vm._v("LV4")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "LV5" } }, [_vm._v("LV5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV1" } }, [_vm._v("HV1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV2" } }, [_vm._v("HV2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV3" } }, [_vm._v("HV3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV4" } }, [_vm._v("HV4")]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboFSSymbols,
                expression: "showJumboFSSymbols",
              },
            ],
            staticClass: "flex-center fs-selector sm-label",
          },
          [
            _c(
              "label",
              {
                staticClass: "jumboSymbol",
                attrs: { for: "symbol_free_spin" },
              },
              [_vm._v("FS Jumbo Symbol: ")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jumboSymbolFSSet,
                    expression: "jumboSymbolFSSet",
                  },
                ],
                attrs: { id: "symbol_free_spin" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.jumboSymbolFSSet = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.viewJumboFSInfo()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("Select Option"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v("No jumbo symbol"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV5" } }, [_vm._v("HV5")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV6" } }, [_vm._v("HV6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV7" } }, [_vm._v("HV7")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "HV8" } }, [_vm._v("HV8")]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboInfo,
                expression: "showJumboInfo",
              },
            ],
            staticClass: "flex-center fs-selector sm-label jumbo",
            on: {
              change: function ($event) {
                return _vm.setColumnSelect()
              },
            },
          },
          [
            _c("label", { attrs: { for: "jumbo_dimension" } }, [
              _vm._v("Jumbo dimensions: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jumboDimensionSet,
                    expression: "jumboDimensionSet",
                  },
                ],
                attrs: { id: "jumbo_dimension" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.jumboDimensionSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("2x2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("3x3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("4x4")]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboInfo,
                expression: "showJumboInfo",
              },
            ],
            staticClass: "flex-center fs-selector sm-label jumbo",
          },
          [
            _c("label", { attrs: { for: "jumbo_column" } }, [
              _vm._v("Jumbo column: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jumboColumnSet,
                    expression: "jumboColumnSet",
                  },
                ],
                attrs: { id: "jumbo_column" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.jumboColumnSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.$data.columns, function (column, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: column.id } },
                  [_vm._v(_vm._s(column.name) + "\n                ")]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboInfo,
                expression: "showJumboInfo",
              },
            ],
            staticClass: "flex-center fs-selector sm-label jumbo",
          },
          [
            _c("label", { attrs: { for: "jumbo_position" } }, [
              _vm._v("Jumbo position: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.jumboPositionSet,
                    expression: "jumboPositionSet",
                  },
                ],
                attrs: { id: "jumbo_position" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.jumboPositionSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.$data.positions, function (position, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: position.id } },
                  [_vm._v(_vm._s(position.name) + "\n                ")]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showJumboInfo,
                expression: "showJumboInfo",
              },
            ],
            staticClass: "jumboOK pointer",
            on: {
              click: function ($event) {
                return _vm.setJumboSymbols()
              },
            },
          },
          [_vm._v("OK")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }