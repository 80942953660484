/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.reelsType = "reels";
            step.internal_name = "Base";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "reelsBaseCascade":
                    return State.state.getModelDefinition().reelsBaseCascade;
                    break;
                case "featureReels":
                    return State.state.getModelDefinition().featureReels;
                    break;
                case "reelsFeatureCascade":
                    return State.state.getModelDefinition().reelsFeatureCascade;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getBasePRStep () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.internal_name = "Base";
            return step;
        },

        getFSPRStep () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "FS Step");
            step.internal_name = "FS Step";
            step.reelsType = "featureReels";
            return step;
        },

        getJumboSymbolSet(reel, internal_name, jumboSymbolSet, jumboDimensionSet, jumboPositionSet, jumboColumnSet) {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", internal_name);
            step.reelsType = reel;
            step.internal_name = internal_name;
            if(internal_name == "Base") {
                step.jumboSymbolSet = jumboSymbolSet.toString();
            } else {
                step.jumboSymbolFSSet = jumboSymbolSet.toString();
            }
            step.jumboPositionSet = parseInt(jumboPositionSet);
            step.jumboColumnSet = parseInt(jumboColumnSet);
            step.jumboDimensionSet = parseInt(jumboDimensionSet) + 2;
            return step;
        },

        getFeatureTriggerFromBaseGameStep: function() {
            const step = State.state.createStep([56, 55, 56, 55, 56], false, 0, "", "Base 3 Bones");
            step.reelsType = "reels";
            return step;
        },

        getFeatureTriggerFromFSGameStep: function() {
            const step = State.state.createStep([12, 55, 55, 55, 55], false, 0, "", "FS 2 Bones");
            step.reelsType = "featureReels";
            return step;
        },
        getFeatureStep: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Feature Step");
            step.reelsType = "featureReels";
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 4);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
