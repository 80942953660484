import { JackpotType } from "./steps"

interface WheelPrize {
    FREE_SPINS: number[][]
    COIN_PRIZE: number[][],
    SNOW_BLAST_LEVEL_UPGRADE: number[][],
    JACKPOT: JackpotType[]
}

export const wheelPrize: WheelPrize = {
    FREE_SPINS: [
        [ 2, 3, 4 ],
        [ 3, 4, 5 ],
        [ 4, 5, 6 ],
        [ 5, 6, 7 ],
        [ 6, 7, 8 ],
    ],
    COIN_PRIZE: [
        [ 1, 2, 4 ],
        [ 2, 3, 5 ],
        [ 3, 4, 6 ],
        [ 4, 5, 7 ],
        [ 5, 6, 8 ],
    ],
    SNOW_BLAST_LEVEL_UPGRADE: [
        [ 3, 7, 10 ],
        [ 3, 7, 10 ],
        [ 3, 7, 10 ],
        [ 3, 7, 10 ],
        [ 3, 7, 10 ],
    ],
    JACKPOT: ['GRAND', 'MAJOR', 'MINI', 'MINOR']
}

export type SnowBlastLevel = 'COOL' | 'CHILL' | 'ICY' | 'EXTRA_ICY' | 'FROST' |'BLIZZARD';
export const snowBlastLevelUpgradeBySpin: SnowBlastLevel[] = [
    'CHILL', 'ICY', 'EXTRA_ICY', 'FROST', 'BLIZZARD'
]

export const numberOfWildsBySnowBlastLevel: {[key in SnowBlastLevel]: number } = {
    COOL: 4,
    CHILL: 6,
    ICY: 8,
    EXTRA_ICY: 10,
    FROST: 12,
    BLIZZARD: 15,
}