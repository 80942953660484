import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from '../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import {getReelWindowFromIndex} from '../../../../helpers/helpers';
import Reel from './reel.vue';
import {
  CoinPrize,
  originatorId,
  StepJson,
  modelDefinition,
  createFireshotTriggerStep,
  createSpinStep,
  createClearingStep,
  createFreeSpinTriggerStep
} from '../../../../state/models/slots/vgw064';

@Component({
  components: {
    reel: Reel,
  },
  data: () => {
    return {};
  },
})
export default class Content extends Vue {
  @Prop() public step: any;
  public show = false;
  private allSteps: Step[] = [];

  constructor() {
    super();
  }
  @Watch('step')
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }
    this.show = true;
  }
  public mounted() {
    trace('reels mounted');
    bus.$on(events.UPDATE_STEPS, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
  }

  saveChangesToStep(): void {
    // Traverse all steps, ensuring the state transitions are all valid and correct.
    this.updateStepsInPlace(this.allSteps);

    // Marking all the steps as having originated here, and persist them.
    this.allSteps.forEach(step => step.json.originatorId = originatorId);
    bus.$emit(events.EDIT_STEPS, this.allSteps);
  }

  reloadStepsFromScenario(scenario?: {
    steps: Array<{ test_scenario_step_id: number; json: StepJson }>;
  }) {
    const newSteps: Step[] = [];
    for (const incomingStep of scenario?.steps ?? []) {
      const existingStep = this.allSteps.find(
        (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
      );
      const step =
        existingStep && incomingStep.json.originatorId === originatorId
          ? existingStep
          : incomingStep;
      newSteps.push(step);
    }
    
    // Traverse all steps, ensuring the state transitions are all valid and correct.
    const isModified = this.updateStepsInPlace(newSteps);
    this.allSteps = newSteps;

    // If any of the steps needed modifying, persist the changes.
    if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);    
  }

  updateStepsInPlace(steps: Step[]) {
    let name = '';
    let isFireshotRespin = false;
    let fireshotPhase: Step['fireshotPhase'];
    let fireshotCount = 0;

    let coinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map((index) => index % 2 === 0 ? [0, 0, 0,] : [0, 0,0,0]);
    let cumulativeCoinPrizes: CoinPrize[][] = [0, 1, 2, 3, 4].map((index) => index % 2 === 0 ? [0, 0, 0,] : [0, 0, 0, 0]);
    let cumulativeCoinCount = 0;

    let isFreeSpin = false;
    let freeSpinCount = 0;
    let freeSpinPhase: Step['freeSpinPhase'];

    let isAnyStepModified = false;
    
    for (const step of steps) {
      if (step.json.name === 'Clear') {
        name = step.json.name;
        isFireshotRespin = false;
        fireshotPhase = undefined;
        fireshotCount = 0;
        isFreeSpin = false;
        freeSpinPhase = undefined;
        freeSpinCount = 0;
        coinPrizes = [];
        cumulativeCoinPrizes = [0, 1, 2, 3, 4].map((index) => index % 2 === 0 ? [0, 0, 0,] : [0, 0, 0, 0]);
        cumulativeCoinCount = 0;
      } else {
        isFireshotRespin = fireshotCount > 0;
        isFreeSpin = freeSpinCount > 0;
        const coinCells = findCells(step.json.reelStripPositions, modelDefinition.coinSymbol);
        const isFireshotTrigger = coinCells.length >= modelDefinition.countToTriggerFireshot;

        const scatterSymbols = findCells(step.json.reelStripPositions, modelDefinition.scatterSymbol);
        const freeSpinsAwarded = modelDefinition.freeSpinsAwarded[modelDefinition.reelsLayout.length - scatterSymbols.length];
        const isFreeSpinTrigger = freeSpinsAwarded > 0;

        if (isFireshotRespin) {
          const prevCoinCount = cumulativeCoinCount;
          coinPrizes = step.json.coinPrizes ?? [0, 1, 2, 3, 4].map(index => index % 2 === 0 ? [0, 0, 0] : [0, 0, 0, 0]);
          coinPrizes = [0, 1, 2, 3, 4].map(col => (col % 2 === 0 ? [0, 1, 2] : [0, 1, 2, 3, 4]).map(row => cumulativeCoinPrizes?.[col]?.[row] ? 0 : (coinPrizes?.[col]?.[row]) || 0));

          cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => (col % 2 === 0 ? [0, 1, 2] : [0, 1, 2, 3, 4]).map(row => cumulativeCoinPrizes?.[col]?.[row] || coinPrizes?.[col]?.[row]) || 0);
          cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);
          name = 'Respin';
          fireshotCount = cumulativeCoinCount === modelDefinition.countToTriggerGrand
          ? 0
          : cumulativeCoinCount > prevCoinCount
              ? modelDefinition.fireshotRespinCount
              : fireshotCount - 1;
          fireshotPhase = fireshotCount > 0 ? 'IN_PROGRESS' : 'END';
        } else {
          name = 'Spin';

          if (isFreeSpin) {
            name = 'Free spin';
            freeSpinCount -= 1;
            freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END'
          } else {
            freeSpinPhase = undefined;
          }

          if (isFreeSpinTrigger) {
            name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
            freeSpinCount += freeSpinsAwarded;
            freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
          }

          coinPrizes = [0, 1, 2, 3, 4].map((index) => index % 2 === 0 ? [0, 0, 0,] : [0, 0, 0, 0]);
          for (const [row, col] of coinCells) coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
          cumulativeCoinPrizes = [0, 1, 2, 3, 4].map(col => (col % 2 === 0 ? [0, 1, 2] : [0, 1, 2, 3, 4]).map(row => coinPrizes?.[col]?.[row] || 0));
          cumulativeCoinCount = cumulativeCoinPrizes.reduce((n, cps) => n + cps.filter(cp => !!cp).length, 0);

          if (isFireshotTrigger) {
              name = cumulativeCoinCount < modelDefinition.countToTriggerGrand ? 'C-Trigger' : 'J-Trigger';
              fireshotCount = modelDefinition.fireshotRespinCount;
              fireshotPhase = 'START';
          }
          else {
              fireshotPhase = undefined;
          }
        }
      }

      Object.assign(step, {
        fireshotPhase, fireshotCount,
        freeSpinCount, freeSpinPhase,
        cumulativeCoinPrizes, cumulativeCoinCount
      });

      const json = {...step.json, name, coinPrizes};
      if (JSON.stringify(json) !== JSON.stringify(step.json)) {
        isAnyStepModified = true;
        step.json = json;
        json.originatorId = originatorId;
      }
    } 
    return isAnyStepModified;
  }

  getCurrentStepIndex(): number | undefined {
    if (!this.step || !this.allSteps) return;
    return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
  }

  public isFireshotTrigger(): boolean {
    return this.currentStep()?.fireshotPhase === 'START';
  }

  public isFireshotRespin(): boolean {
    const fireshotPhase = this.currentStep()?.fireshotPhase;
    return fireshotPhase === 'IN_PROGRESS' || fireshotPhase === 'END';
  }

  public getFireshotRespinsRemaining(): number {
    return this.currentStep()?.fireshotCount ?? 0;
  }

  public getFireshotCoinsCollected(): number {
    return this.currentStep()?.cumulativeCoinCount ?? 0;
  }

  public getCoinPrizeLevels(): Array<{value: unknown, label: string}> {
    const keys = Object.keys(modelDefinition.coinPrizeWeights.initial);
    return keys.map(k => ({
        value: isNaN(Number(k)) ? k : Number(k),
        label: `🟡 ${k}`,
    }));
  }

  // ----- Free spin state -----
  public isFreeSpin(): boolean {
    if (this.isFireshotRespin()) return false;
    const freeSpinPhase = this.currentStep()?.freeSpinPhase;
    return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
  }

  public getFreeSpinsRemaining(): number {
    return this.currentStep()?.freeSpinCount ?? 0;
  }  

  public canSetOrUnsetCoin({row, col}: {row: number, col: number}): boolean {
    const isFireshotRespin = this.isFireshotRespin();
    if (!isFireshotRespin) return false;
    const prevStepInfo = this.allSteps[(this.getCurrentStepIndex() ?? 0) - 1];
    return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] === 0;
  }

  public canSetCoinPrize({row, col}: {row: number, col: number}): boolean {
    const isFireshotTrigger = this.isFireshotTrigger();
    if (isFireshotTrigger) {
        return this.currentStep()?.cumulativeCoinPrizes?.[col]?.[row] !== 0;
    }
    else {
        const prevStepInfo = this.allSteps[(this.getCurrentStepIndex() ?? 0) - 1];
        return prevStepInfo?.cumulativeCoinPrizes?.[col]?.[row] === 0;
    }
  }

  public getCoinPrize({row, col}: {row: number, col: number}): string {
    return (this.currentStep()?.cumulativeCoinPrizes?.[col]?.[row] ?? 0).toString();
  }

  setCoinPrize({row, col, value}: {row: number, col: number, value: string}) {
    if (!this.currentStep) return;
    const cp = toCoinPrize(value);
    const currentCoinPrizes = this.currentStep()?.json.coinPrizes;
    const newCoinPrices = [0, 1, 2, 3, 4].map(c => {
      return (c % 2 === 0 ? [0, 1, 2] : [0, 1, 2, 3]).map(r => {
        return c === col && r === row ? cp : currentCoinPrizes?.[c]?.[r] ?? 0;
      });
    });
    (this.currentStep() as Step).json.coinPrizes = newCoinPrices;
    this.saveChangesToStep();
  }

  public addFreeSpinTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep());
  }
  public addClearingStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, createClearingStep());
  }

  public addSpinStep() {
    bus.$emit(events.ADD_STEP, createSpinStep())
  }

  public addFireshotTriggerStep() { bus.$emit(events.ADD_STEP, createFireshotTriggerStep())}

  finalStep(): Step | undefined {
    return this.allSteps[this.allSteps.length - 1];
  }

  canAddFireshotTriggerStep(): boolean {
    return !this.finalStep()?.fireshotCount;
  }

  labelForAddSpinStep(): string {
    if (this.finalStep()?.fireshotCount) return 'Add Respin';
    if (this.finalStep()?.freeSpinCount) return 'Add Free Spin';
    return 'Add Base Spin';
  }

  public isCoin({ row, col }: { row: number; col: number }) {
    const isCoin = this.currentStep()?.cumulativeCoinPrizes?.[col]?.[row];
    return !!isCoin;
  }

  currentStep(): Step | undefined {
    if (!this.step || !this.allSteps) return;
    return this.allSteps.find(
      (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
    );
  }
}

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';  
  freeSpinCount?: number;
  fireshotPhase?: 'START' | 'IN_PROGRESS' | 'END';
  fireshotCount?: number;
  cumulativeCoinPrizes?: CoinPrize[][];
  cumulativeCoinCount?: number;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
  const reels = modelDefinition.reels;
  const cells: Array<[number, number]> = [];
  for (let col = 0; col < reels.length; ++col) {
      const windowHeight = modelDefinition.reelsLayout[col];
      const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
      for (let row = 0; row < windowHeight; ++row) {
          if (reelWindow[row] === symbol) {
              cells.push([row, col]);
          }
      }
  }
  return cells;
}

function toCoinPrize(value: string): CoinPrize {
  const n = Number(value);
  return isNaN(n) ? value : n as any;
}