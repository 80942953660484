// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
// tslint:disable: object-literal-key-quotes trailing-comma whitespace no-consecutive-blank-lines
export const modelDefinition = {
    modelId: "vgw070",

    scatterSymbol: "SCATTER",
    wildSymbol: "WILD",
    jackpotSymbol: "JACKPOT",
    countToTriggerJackpot: 3,
    freeSpinTrigger: "SCATTER",
    countToTriggerFreeSpin: 3,
    freeSpinCount: 10,
    reelsLayout: [3, 3, 3, 3, 3, 3],
    defaultSelectLine: "25",

    winTable: [
        {
            symbol: "WILD",
            multipliers: [2500, 1000, 200, 100]
        },
        {
            symbol: "PIC1",
            multipliers: [2000, 500, 150, 50]
        },
        {
            symbol: "PIC2",
            multipliers: [1500, 500, 100, 40]
        },
        {
            symbol: "PIC3",
            multipliers: [1200, 400, 80, 30]
        },
        {
            symbol: "PIC4",
            multipliers: [1000, 300, 70, 20]
        },
        {
            symbol: "PIC5",
            multipliers: [800, 250, 60, 15]
        },
        {
            symbol: "PIC6",
            multipliers: [500, 200, 50, 10]
        },
        {
            symbol: "H",
            multipliers: [250, 100, 20, 5]
        },
        {
            symbol: "D",
            multipliers: [250, 100, 20, 5]
        },
        {
            symbol: "C",
            multipliers: [250, 100, 20, 5]
        },
        {
            symbol: "S",
            multipliers: [250, 100, 20, 5]
        },
        {
            symbol: "SCATTER",
            multipliers: [500, 50, 10, 2]
        },
        {
            symbol: "JACKPOT",
            multipliers: [1000, 100, 20, 5]
        }
    ],

    playLines: [
        [1, 1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2, 2], // 3
        [0, 1, 2, 2, 1, 0], // 4
        [2, 1, 0, 0, 1, 2], // 5
        [0, 0, 1, 1, 2, 2], // 6
        [2, 2, 1, 1, 0, 0], // 7
        [1, 0, 1, 0, 1, 0], // 8
        [1, 2, 1, 2, 1, 2], // 9
        [1, 0, 0, 0, 0, 1], // 10
        [1, 2, 2, 2, 2, 1], // 11
        [0, 1, 0, 1, 0, 1], // 12
        [2, 1, 2, 1, 2, 1], // 13
        [0, 1, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 1, 2], // 15
        [1, 1, 0, 0, 1, 1], // 16
        [1, 1, 2, 2, 1, 1], // 17
        [0, 1, 2, 1, 0, 1], // 18
        [2, 1, 0, 1, 2, 1], // 19
        [0, 2, 2, 2, 2, 0], // 20
        [2, 0, 0, 0, 0, 2], // 21
        [0, 0, 2, 2, 0, 0], // 22
        [2, 2, 0, 0, 2, 2], // 23
        [0, 2, 0, 2, 0, 2], // 24
        [2, 0, 2, 0, 2, 0]  // 25
    ],

    reels: [
        ["H","PIC1","PIC6","S","PIC3","H","PIC6","S","PIC5","PIC6","H","PIC5","S","PIC3","PIC3","H","PIC6","D","PIC5","S","PIC4","PIC4","PIC4","H","PIC3","D","PIC5","PIC2","H","PIC6","C","PIC4","H","PIC3","D","PIC5","PIC6","H","PIC1","D","PIC3","H","PIC4","S","PIC6","H","S","PIC5","H","PIC6","S","PIC5","H","SCATTER","C","PIC6","PIC5","H","PIC4","C","PIC6","PIC6","PIC6","S","PIC3","H","PIC5","S","PIC3","C","H","PIC4","PIC2","S","PIC5","PIC5","H","PIC1","S","PIC6","PIC3","H","PIC5","S","PIC2","H","JACKPOT","S","PIC4","H","PIC6","S","PIC4","PIC5","H","PIC2","PIC2","S","PIC3","PIC6","C","PIC4","H","SCATTER","S","PIC6","PIC4","S","PIC3","H","WILD","S","PIC4","PIC5","S","PIC3","H","PIC6","S","PIC4"],
        ["S","PIC3","D","WILD","C","PIC5","PIC4","H","PIC3","C","PIC6","PIC6","S","PIC4","C","PIC2","H","PIC5","C","SCATTER","D","PIC6","PIC3","S","PIC4","PIC4","C","PIC5","WILD","D","PIC3","PIC3","PIC3","H","PIC6","C","PIC4","D","PIC1","S","PIC4","H","PIC6","PIC3","S","PIC1","H","PIC6","D","PIC1","PIC1","S","PIC3","H","PIC5","PIC5","PIC5","D","WILD","C","PIC3","PIC4","C","PIC6","D","SCATTER","C","PIC6","PIC1","H","PIC2","S","PIC4","PIC1","H","PIC6","PIC2","C","PIC5","H","PIC1","PIC5","D","PIC6","S","JACKPOT","D","PIC4","H","PIC5","C","PIC6","D","PIC5","PIC4","H","PIC2","S","PIC5","C","PIC4","D","PIC5","PIC3","C","PIC6","PIC4","S","PIC5","D","PIC3","PIC6","H","PIC5","D","PIC6","C","PIC5","D","PIC2"],
        ["H","SCATTER","D","PIC4","C","PIC2","WILD","S","PIC3","H","PIC5","PIC4","C","PIC5","H","PIC4","PIC6","C","PIC1","PIC5","S","WILD","PIC3","H","PIC6","PIC1","D","PIC5","H","JACKPOT","D","PIC3","H","PIC2","PIC6","S","PIC4","D","WILD","C","PIC6","S","PIC3","PIC3","PIC3","H","PIC5","PIC5","PIC5","C","PIC4","PIC6","S","PIC4","H","PIC6","PIC6","PIC6","D","PIC3","H","SCATTER","D","PIC2","S","PIC6","C","PIC1","PIC1","PIC1","H","WILD","S","SCATTER","C","PIC4","H","PIC3","S","PIC6","PIC2","C","WILD","PIC4","D","PIC5","PIC1","H","PIC6","D","JACKPOT","H","PIC3","PIC5","S","PIC6","PIC5","H","WILD","S","PIC5","C","PIC4","PIC4","PIC4","H","PIC6","C","PIC5","H","PIC2","PIC4","C","PIC3","WILD","D","PIC6","PIC5","S","PIC3"],
        ["D","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","C","PIC3","PIC3","S","PIC5","PIC5","PIC5","D","PIC4","PIC4","C","PIC3","PIC3","PIC3","S","SCATTER","D","PIC1","PIC1","PIC1","H","JACKPOT","S","PIC5","PIC5","PIC5","H","PIC3","PIC3","PIC3","D","PIC4","PIC4","PIC4","C","PIC6","PIC6","PIC6","H","PIC2","PIC2","C","PIC6","PIC6","PIC6","D","PIC2","PIC2","H","PIC5","PIC5","PIC5","H","SCATTER","S","PIC1","PIC1","PIC1","D","JACKPOT","C","PIC5","PIC5","PIC5","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","S","PIC5","PIC5","PIC5","H","PIC3","PIC3","PIC3","C","WILD","H","PIC2","PIC2","D","PIC3","PIC3","PIC3","H","WILD","D","PIC4","PIC4","PIC4","S","SCATTER","C","PIC2","PIC2","PIC2"],
        ["D","SCATTER","C","PIC3","PIC3","PIC3","H","JACKPOT","D","PIC4","PIC4","H","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","C","PIC5","PIC5","PIC5","S","PIC2","PIC2","PIC2","H","PIC3","PIC3","C","JACKPOT","H","PIC2","PIC2","PIC2","C","SCATTER","D","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","C","PIC5","PIC5","PIC5","PIC1","PIC1","PIC1","S","PIC5","PIC5","PIC5","PIC3","PIC3","PIC3","WILD","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","SCATTER","H","PIC5","PIC5","PIC5","D","PIC6","PIC6","PIC6","S","JACKPOT","D","PIC5","PIC5","PIC5","C","WILD","S","PIC3","PIC3","PIC3","C","SCATTER","PIC2","PIC2","PIC2","D","PIC1","PIC1","PIC1","S","PIC6","PIC6","PIC6","PIC4","PIC4","PIC4","PIC3","PIC3","PIC3","D","PIC5","PIC5","PIC5","H","PIC1","PIC1","PIC1"],
        ["C","PIC4","PIC4","PIC4","S","D","WILD","C","S","SCATTER","H","PIC5","PIC5","PIC5","S","JACKPOT","D","PIC3","PIC3","PIC3","H","C","PIC1","PIC1","PIC1","S","SCATTER","D","PIC6","PIC6","PIC6","H","S","PIC2","PIC2","PIC2","S","C","PIC4","PIC4","PIC4","D","PIC2","PIC2","PIC2","C","H","PIC1","PIC1","PIC1","D","PIC3","PIC3","PIC3","D","S","SCATTER","H","PIC5","PIC5","PIC5","C","JACKPOT","H","PIC5","PIC5","PIC5","C","PIC6","PIC6","PIC6","H","JACKPOT","D","PIC2","PIC2","PIC2","S","PIC3","PIC3","PIC3","C","PIC1","PIC1","PIC1","H","PIC4","PIC4","PIC4","C","PIC2","PIC2","D","PIC1","PIC1","S","PIC6","PIC6","PIC6","H","SCATTER","D"]
    ],
    featureReels: [
        ["H","PIC1","PIC6","S","PIC3","H","PIC6","S","PIC5","PIC6","H","PIC5","S","PIC3","PIC3","H","PIC6","D","PIC5","S","PIC4","PIC4","PIC4","H","PIC3","D","PIC5","PIC2","H","PIC6","C","PIC4","H","PIC3","D","PIC5","PIC6","H","PIC1","D","PIC3","H","PIC4","S","PIC6","H","S","PIC5","H","PIC6","S","PIC5","H","SCATTER","C","PIC6","PIC5","H","PIC4","C","PIC6","PIC6","PIC6","S","PIC3","H","PIC5","S","PIC3","C","H","PIC4","PIC2","S","PIC5","PIC5","H","PIC1","S","PIC6","PIC3","H","PIC5","S","PIC2","H","JACKPOT","S","PIC4","H","PIC6","S","PIC4","PIC5","H","PIC2","PIC2","S","PIC3","PIC6","C","PIC4","H","SCATTER","S","PIC6","PIC4","S","PIC3","H","WILD","S","PIC4","PIC5","S","PIC3","H","PIC6","S","PIC4"],
        ["S","PIC3","D","WILD","C","PIC5","PIC4","H","WILD","C","PIC6","PIC6","S","PIC4","C","PIC2","H","PIC5","C","SCATTER","D","PIC6","PIC3","S","PIC4","PIC4","C","PIC5","WILD","D","PIC3","PIC3","PIC3","H","PIC6","C","PIC4","D","PIC1","S","PIC4","H","PIC6","PIC3","S","WILD","H","PIC6","D","PIC1","PIC1","S","PIC3","H","PIC5","PIC5","PIC5","D","WILD","C","PIC3","PIC4","C","PIC6","D","SCATTER","C","PIC6","PIC1","H","WILD","S","PIC4","PIC1","H","PIC6","PIC2","C","PIC5","H","PIC1","PIC5","D","PIC6","S","JACKPOT","D","PIC4","H","WILD","C","PIC6","D","PIC5","PIC4","H","PIC2","S","PIC5","C","PIC4","D","PIC5","PIC3","C","PIC6","PIC4","S","PIC5","D","PIC3","PIC6","H","PIC5","D","PIC6","C","PIC5","D","PIC2"],
        ["H","SCATTER","D","PIC4","C","PIC2","WILD","S","PIC3","H","PIC5","PIC4","C","WILD","H","PIC4","PIC6","C","WILD","PIC5","S","WILD","PIC3","H","PIC6","PIC1","D","PIC5","H","JACKPOT","D","PIC3","H","WILD","PIC6","S","PIC4","D","WILD","C","PIC6","S","PIC3","PIC3","PIC3","H","PIC5","PIC5","PIC5","C","PIC4","WILD","S","PIC4","H","PIC6","PIC6","PIC6","D","PIC3","H","SCATTER","D","PIC2","S","WILD","C","PIC1","PIC1","PIC1","H","WILD","S","SCATTER","C","PIC4","H","PIC3","S","PIC6","PIC2","C","WILD","PIC4","D","PIC5","PIC1","H","PIC6","D","JACKPOT","H","PIC3","PIC5","S","PIC6","PIC5","H","WILD","S","PIC5","C","PIC4","PIC4","PIC4","H","PIC6","C","WILD","H","PIC2","PIC4","C","PIC3","WILD","D","PIC6","PIC5","S","PIC3"],
        ["D","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","C","PIC3","PIC3","S","PIC5","PIC5","PIC5","D","PIC4","PIC4","C","PIC3","PIC3","PIC3","S","SCATTER","D","PIC1","PIC1","PIC1","H","JACKPOT","S","PIC5","PIC5","PIC5","H","PIC3","PIC3","PIC3","D","PIC4","PIC4","PIC4","C","PIC6","PIC6","PIC6","H","PIC2","PIC2","C","PIC6","PIC6","PIC6","D","PIC2","PIC2","H","PIC5","PIC5","PIC5","H","SCATTER","S","PIC1","PIC1","PIC1","D","JACKPOT","C","PIC5","PIC5","PIC5","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","S","PIC5","PIC5","PIC5","H","PIC3","PIC3","PIC3","C","WILD","H","PIC2","PIC2","D","PIC3","PIC3","PIC3","H","WILD","D","PIC4","PIC4","PIC4","S","SCATTER","C","PIC2","PIC2","PIC2"],
        ["D","SCATTER","C","PIC3","PIC3","PIC3","H","JACKPOT","D","PIC4","PIC4","H","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","C","PIC5","PIC5","PIC5","S","PIC2","PIC2","PIC2","H","PIC3","PIC3","C","JACKPOT","H","PIC2","PIC2","PIC2","C","SCATTER","D","PIC6","PIC6","PIC6","S","PIC4","PIC4","PIC4","C","PIC5","PIC5","PIC5","PIC1","PIC1","PIC1","S","PIC5","PIC5","PIC5","PIC3","PIC3","PIC3","WILD","PIC4","PIC4","PIC4","H","PIC6","PIC6","PIC6","SCATTER","H","PIC5","PIC5","PIC5","D","PIC6","PIC6","PIC6","S","JACKPOT","D","PIC5","PIC5","PIC5","C","WILD","S","PIC3","PIC3","PIC3","C","SCATTER","PIC2","PIC2","PIC2","D","PIC1","PIC1","PIC1","S","PIC6","PIC6","PIC6","PIC4","PIC4","PIC4","PIC3","PIC3","PIC3","D","PIC5","PIC5","PIC5","H","PIC1","PIC1","PIC1"],
        ["C","PIC4","PIC4","PIC4","S","D","WILD","C","S","SCATTER","H","PIC5","PIC5","PIC5","S","JACKPOT","D","PIC3","PIC3","PIC3","H","C","PIC1","PIC1","PIC1","S","SCATTER","D","PIC6","PIC6","PIC6","H","S","PIC2","PIC2","PIC2","S","C","PIC4","PIC4","PIC4","D","PIC2","PIC2","PIC2","C","H","PIC1","PIC1","PIC1","D","PIC3","PIC3","PIC3","D","S","SCATTER","H","PIC5","PIC5","PIC5","C","JACKPOT","H","PIC5","PIC5","PIC5","C","PIC6","PIC6","PIC6","H","JACKPOT","D","PIC2","PIC2","PIC2","S","PIC3","PIC3","PIC3","C","PIC1","PIC1","PIC1","H","PIC4","PIC4","PIC4","C","PIC2","PIC2","D","PIC1","PIC1","S","PIC6","PIC6","PIC6","H","SCATTER","D"]
    ],

    winLevels: [
        { "name": "normal1", "threshold": 5 },
        { "name": "normal2", "threshold": 15 },
        { "name": "normal3", "threshold": 20 },
        { "name": "bigwin", "threshold": 35 },
        { "name": "megawin", "threshold": 70 },
        { "name": "epicwin" }
    ],
    
    wildMultiplierWeights: {
        baseGame: [
            { multiplier: 1, weight: 120 },
            { multiplier: 2, weight: 21 },
            { multiplier: 3, weight: 6 },
            { multiplier: 4, weight: 2 },
            { multiplier: 5, weight: 1 },
        ],
        freeSpins: [
            { multiplier: 1, weight: 0 },
            { multiplier: 2, weight: 20 },
            { multiplier: 3, weight: 6 },
            { multiplier: 4, weight: 2 },
            { multiplier: 5, weight: 1 },
        ],
    },

    jackpotPrizeWeights: {
        sweeps: [
            { prize: "GRAND", weight: 1, multiplier: 1 },
            { prize: "MAJOR", weight: 15, multiplier: 1 },
            { prize: "MINOR", weight: 698154, multiplier: 4 },
            { prize: "MINI", weight: 2094462, multiplier: 20 }
        ],
        gold: [
            { prize: "GRAND", weight: 1, multiplier: 1 },
            { prize: "MAJOR", weight: 15, multiplier: 1 },
            { prize: "MINOR", weight: 69815400, multiplier: 4 },
            { prize: "MINI", weight: 209446200, multiplier: 20 }
        ]
    },

    jackpots: {
        fixedMultipliers: {
            MINI: 20,
            MINOR: 100,
        },
        seedValue: {
            MAJOR: {
                GOLD: 20_000_000,
                SWEEPS: 200_000,
            },
            GRAND: {
                GOLD: 200_000_000,
                SWEEPS: 2_000_000,
            },
        },
        percentToJackpotPool: {
            MAJOR: 0.5,
            GRAND: 0.5,
        },
    },

    wildMultiplierLimit: 100, 

    coinType1Values: [200, 1000, 2000, 10000, 40000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400],

    coinType1_min_bet: 200,
    coinType1_max_bet: 40000,
    coinType1_spin_max_bet: 1000000,

    coinType4_min_bet: 1,
    coinType4_max_bet: 400,
    coinType4_spin_max_bet: 10000,

    // ----------   Server-only properties:   ----------    
};
