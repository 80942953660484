/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "modelId" : "vladimirfarm",
      "gameId" : null,
      "reelsLayout" : [ 3, 3, 3, 3, 3 ],
      "scatterSymbol" : "SCAT",
      "wildSymbol" : "WILD",
      "bonusSymbol" : null,
      "freeSpinTrigger" : "SCAT",
      "countToTriggerFreeSpin" : 3,
      "freeSpinMultiplier" : 2,
      "freeSpinCount" : 10,
      "winTable": [
        {
            "symbol": "Cucumber",
            "multipliers": [750, 100, 25, 2]
        },
        {
            "symbol": "Cabbage",
            "multipliers": [750, 100, 25, 2]
        },
        {
            "symbol": "Corn",
            "multipliers": [250, 50, 10]
        },
        {
            "symbol": "Potato",
            "multipliers": [250, 50, 10]
        },
        {
            "symbol": "Tomato",
            "multipliers": [250, 50, 10]
        },
        {
            "symbol": "Ace",
            "multipliers": [100, 25, 5]
        },
        {
            "symbol": "King",
            "multipliers": [100, 25, 5]
        },
        {
            "symbol": "Queen",
            "multipliers": [100, 25, 5]
        },
        {
            "symbol": "Jack",
            "multipliers": [100, 25, 5]
        },
        { 
            "symbol": "Wild",
            "multipliers": [10000, 1000, 100, 10]
        },
        { 
            "symbol": "Scatter",
            "multipliers": [100, 25, 5]
        }
      ],
      "featureWinTable" : null,
      "playLines": [
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 0],
        [2, 2, 2, 2, 2],
        [0, 1, 2, 1, 0],
        [2, 1, 0, 1, 2],
        [0, 0, 1, 2, 2],
        [2, 2, 1, 0, 0],
        [1, 0, 1, 2, 1],
        [1, 2, 1, 0, 1],
        [1, 0, 0, 1, 0], 
        [1, 2, 2, 1, 2], 
        [0, 1, 0, 0, 1], 
        [2, 1, 2, 2, 1], 
        [0, 2, 0, 2, 0], 
        [2, 0, 2, 0, 2], 
        [1, 0, 2, 0, 1], 
        [1, 2, 0, 2, 1], 
        [0, 1, 1, 1, 0], 
        [2, 1, 1, 1, 2], 
        [0, 2, 2, 2, 0] 
      ],
      "reels": [
        ["Tomato","Jack","Cucumber","King","Potato","Jack","Wild","King","Corn","Jack","Bonus","King","Cabbage","Queen","Cucumber","King","Potato","Jack","Wild","King","Corn","Queen","Bonus","Ace","Tomato","Queen","Cucumber","King","Potato","Jack","Scatter","Ace","Potato","Queen","Cabbage","Jack","Cucumber","King","Bonus","Ace"],
        ["Queen","Potato","Ace","Cabbage","Queen","Corn","Ace","Cucumber","Queen","Tomato","Ace","Wild","Jack","Corn","King","Cabbage","Queen","Tomato","Ace","Scatter","Jack","Corn","King","Cabbage","Queen","Tomato","Ace","Wild","Jack","Potato","Ace","Scatter","Queen","Corn","Ace","Cabbage","Jack","Cucumber","King","Tomato"],
        ["Corn","Jack","Tomato","Ace","Potato","Queen","Bonus","King","Tomato","Jack","Cucumber","Ace","Potato","Queen","Bonus","King","Corn","Jack","Wild","Ace","Tomato","Queen","Bonus","King","Potato","Jack","Scatter","Ace","Corn","Queen","Cabbage","King","Tomato","Jack","Corn","Ace","Potato","Queen","Bonus","King"],
        ["Tomato","King","Cabbage","Queen","Potato","Cucumber","Corn","Ace","Tomato","Wild","Queen","Cabbage","Cucumber","King","Corn","Cabbage","Ace","Potato","Jack","Cucumber","Queen","Tomato","Cabbage","Ace","Potato","Cucumber","King","Corn","Jack","Scatter","Queen","Tomato","Cabbage","King","Potato","Cucumber","Ace","Corn","Jack","Wild"],
        ["Tomato","Ace","Cabbage","Potato","King","Cucumber","Queen","Corn","Wild","Jack","Tomato","Ace","Scatter","Potato","King","Bonus","Queen","Corn","Cabbage","Jack","Tomato","Ace","Cucumber","Potato","King","Wild","Queen","Corn","Scatter","Jack","Tomato","Ace","Cabbage","Potato","King","Cucumber","Queen","Corn","Bonus","Jack"]
     ],
     "featureReels": [
      ["Tomato","Jack","Cucumber","King","Potato","Jack","Wild","King","Corn","Jack","Bonus","King","Cabbage","Queen","Cucumber","King","Potato","Jack","Wild","King","Corn","Queen","Bonus","Ace","Tomato","Queen","Cucumber","King","Potato","Jack","Scatter","Ace","Potato","Queen","Cabbage","Jack","Cucumber","King","Bonus","Ace"],
      ["Queen","Potato","Ace","Cabbage","Queen","Corn","Ace","Cucumber","Queen","Tomato","Ace","Wild","Jack","Corn","King","Cabbage","Queen","Tomato","Ace","Scatter","Jack","Corn","King","Cabbage","Queen","Tomato","Ace","Wild","Jack","Potato","Ace","Scatter","Queen","Corn","Ace","Cabbage","Jack","Cucumber","King","Tomato"],
      ["Corn","Jack","Tomato","Ace","Potato","Queen","Bonus","King","Tomato","Jack","Cucumber","Ace","Potato","Queen","Bonus","King","Corn","Jack","Wild","Ace","Tomato","Queen","Bonus","King","Potato","Jack","Scatter","Ace","Corn","Queen","Cabbage","King","Tomato","Jack","Corn","Ace","Potato","Queen","Bonus","King"],
      ["Tomato","King","Cabbage","Queen","Potato","Cucumber","Corn","Ace","Tomato","Wild","Queen","Cabbage","Cucumber","King","Corn","Cabbage","Ace","Potato","Jack","Cucumber","Queen","Tomato","Cabbage","Ace","Potato","Cucumber","King","Corn","Jack","Scatter","Queen","Tomato","Cabbage","King","Potato","Cucumber","Ace","Corn","Jack","Wild"],
      ["Tomato","Ace","Cabbage","Potato","King","Cucumber","Queen","Corn","Wild","Jack","Tomato","Ace","Scatter","Potato","King","Bonus","Queen","Corn","Cabbage","Jack","Tomato","Ace","Cucumber","Potato","King","Wild","Queen","Corn","Scatter","Jack","Tomato","Ace","Cabbage","Potato","King","Cucumber","Queen","Corn","Bonus","Jack"]
      ],
     "featureReelsStops" : null,
      "defaultSelectLine" : 20,
      "coinType1Values": [250, 2500, 10000, 50000, 100000 ],
      "coinType4Values": [1, 2, 5, 10, 50],
      "coinType1_min_bet" : 250,
      "coinType1_max_bet": 100000,
      "coinType1_spin_max_bet": 2000000,
      "coinType4_min_bet": 1,
      "coinType4_max_bet" : 50,
      "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
      }, {
        "name" : "normal2",
        "threshold" : 15
      }, {
        "name" : "normal3",
        "threshold" : 25
      }, {
        "name" : "bigwin",
        "threshold" : 50
      }, {
        "name" : "megawin",
        "threshold" : 150
      }, {
        "name" : "epicwin",
        "threshold" : 2147483647
      } ],
      "coinType4_spin_max_bet" : 1000,
      "flavor" : "93",
      "currency_symbol" : "$",
      "normal_count" : "2"
    },
    "modelId" : "vladimirfarm",
    "gameSessionId" : 1,
    "reelStripPositions" : [ 18, 2, 22, 1, 29 ],
    "slotWindow" : [ [ "Tomato", "Jack", "Cucumber" ], [ "Queen", "Potato", "Ace" ], [ "Corn", "Jack", "Tomato" ], [ "Tomato", "King", "Cabbage" ], [ "Tomato", "Ace", "Cabbage" ] ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 206529343,
    "sweeps_deposit_balance" : 200001395,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 6527948,
    "sweeps_available_balance" : 6527948,
    "serverTime" : "2018-02-21 13:09:43"
};
