import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    private featureReelsToUse = 6;
    public featureWildReelNumber = 1;
    public baseWildReelNumber = 1;
    public isOpenBaseWild = false;
    public isOpenFeatureWild = false;
    constructor() {
        super();
    }
    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpenBaseWild = false;
        this.isOpenFeatureWild = false;
        if (step.json.name == "WR") {
            this.isOpenBaseWild = true;
        }
        if (step.json.name == "WR-F") {
            this.isOpenFeatureWild = true;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.featureReelsToUse = step.json.featureReelsToUse || 6;
        this.featureWildReelNumber = this.step.json.featureWildReelNumber ? this.step.json.featureWildReelNumber+1 : 1;
        this.baseWildReelNumber = this.step.json.baseWildReelNumber ? this.step.json.baseWildReelNumber+1 : 1;
        this.$nextTick(() => bus.$emit("step-updated", step));
    }
    public mounted() {
        this.isOpenBaseWild = false;
        this.isOpenFeatureWild = false;
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        this.checkChangesInStep();
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep(this.featureReelsToUse) );
    }

    public viewBaseReelsetNumber() {
        this.isOpenBaseWild = true;
        this.isOpenFeatureWild = false;
    }

    public viewFeatureReelsetNumber() {
        this.isOpenBaseWild = false;
        this.isOpenFeatureWild = true;
    }

    public addWildReelsBaseStep(){
        const state = State.state;
        let baseWildReelNumber = this.baseWildReelNumber -1;
        bus.$emit(events.ADD_STEP, state.getWildReelStep("wildReels_base", baseWildReelNumber, true) );
    }

    public addWildReelsFeatureStep(){
        const state = State.state;
        let featureWildReelNumber = this.featureWildReelNumber -1;
        bus.$emit(events.ADD_STEP, state.getWildReelStep("wildReels_feature", featureWildReelNumber,false) );
    }

    public addCriminalBaseStep(){
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getCriminalReelStep("criminal_base", true) );
    }

    public addCriminalFeatureStep(){
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getCriminalReelStep("criminal_feature", false) );
    }

    public addFlyingWildBaseStep(){
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFlyingWildReelStep("flyingWildReels_base", true) );
    }

    public addFlyingWildFeatureStep(){
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFlyingWildReelStep("flyingWildReels_feature", false) );
    }

    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }

    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep(this.featureReelsToUse) );
    }
    public addWildsGalore() {
        bus.$emit(events.ADD_STEP, State.state.getWildsGalore() );
    }
    public doChangeFeatureReelsToUse() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        if(this.isFeature) {
            this.step.json.featureReelsToUse = this.featureReelsToUse;
        }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
