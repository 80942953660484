export const originatorId = new Date().getTime();

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  fullReelWilds?: (0 | 1 | 2 | 3 | 4)[];
  singleWilds?: [number, number][];
  multiplierCells?: {
    cell: [number, number];
    multiplier: number;
  }[];
  characterWilds?: ("PIC1" | "PIC2" | "PIC3")[];
  reelSpinDeliverySequence?: "BEFORE" | "AFTER" | "BOTH";
  deliverRemainingFeaturesBeforeReelsStop?: boolean;
  reelSpinFeatureDeliveryOrder?: ("FRW" | "CW" | "SW" | "M")[];
  shouldReelSpinFeaturesApplied: boolean;
  reelSpinFeatureSetId: number;
  pickFeatureAwarded?: ("FREE_SPINS" | "PIC1" | "PIC2" | "PIC3" | "START")[];
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [3, 5, 0, 0, 3],
    shouldReelSpinFeaturesApplied: true,
    reelSpinFeatureSetId: 15,
  };
}
