/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import {rtp90, rtp93} from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => {
            return State.state.standalone ? rtp93.modelDefinition : State.state.slot.state.getModelDefinition()
        }, 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => 5,
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => State.state.createStep([0, 146, 80, 137, 0], false, 0, "", "F-Trigger") ,
        getFeatureStep: () => State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "F-Step") ,
        getFeatureReTriggerStep: () => State.state.createStep([0, 146, 80, 137, 0], true, 0, "", "F-Step") ,
    };
}
