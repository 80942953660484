const coinSizeMultiplier = 50;

export const modelDefinition = {
    modelId: 'vgw067',
    gameIds: ['theGoldenCheese'],
    coinType1Values: [200, 1000, 2000, 5000, 10000, 20000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400],
    coinSizeMultiplier,
    reelsLayout: [4, 4, 4, 4, 4],
    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',

    coinType1_spin_max_bet: 20000 * coinSizeMultiplier,
    coinType4_spin_max_bet: 400 * coinSizeMultiplier,

    winTable: [
        {
            symbol: 'SCAT',
            multipliers: [100, 10, 2],
        },
        {
            symbol: 'WILD',
            multipliers: [1000, 200, 50],
        },
        {
            symbol: 'PIC1',
            multipliers: [750, 150, 30],
        },
        {
            symbol: 'PIC2',
            multipliers: [500, 100, 20],
        },
        {
            symbol: 'PIC3',
            multipliers: [500, 100, 20],
        },
        {
            symbol: 'PIC4',
            multipliers: [250, 50, 10],
        },
        {
            symbol: 'PIC5',
            multipliers: [250, 50, 10],
        },
        {
            symbol: 'PIC6',
            multipliers: [250, 50, 10],
        },
        {
            symbol: 'HEART',
            multipliers: [100, 20, 5],
        },
        {
            symbol: 'DIAMOND',
            multipliers: [100, 20, 5],
        },
        {
            symbol: 'CLUB',
            multipliers: [100, 20, 5],
        },
        {
            symbol: 'SPADE',
            multipliers: [100, 20, 5],
        },
    ],

    playLines: [
        [0, 0, 0, 0, 0], //1
        [1, 1, 1, 1, 1], //2
        [2, 2, 2, 2, 2], //3
        [3, 3, 3, 3, 3], //4
        [0, 1, 0, 1, 0], //5
        [1, 2, 1, 2, 1], //6
        [2, 3, 2, 3, 2], //7
        [1, 0, 1, 0, 1], //8
        [2, 1, 2, 1, 2], //9
        [3, 2, 3, 2, 3], //10
        [0, 1, 2, 1, 0], //11
        [1, 2, 3, 2, 1], //12
        [2, 1, 0, 1, 2], //13
        [3, 2, 1, 2, 3], //14
        [0, 0, 1, 0, 0], //15
        [1, 1, 2, 1, 1], //16
        [2, 2, 3, 2, 2], //17
        [1, 1, 0, 1, 1], //18
        [2, 2, 1, 2, 2], //19
        [3, 3, 2, 3, 3], //20
        [0, 1, 1, 1, 0], //21
        [1, 2, 2, 2, 1], //22
        [2, 3, 3, 3, 2], //23
        [1, 0, 0, 0, 1], //24
        [2, 1, 1, 1, 2], //25
        [3, 2, 2, 2, 3], //26
        [0, 2, 0, 2, 0], //27
        [1, 3, 1, 3, 1], //28
        [2, 0, 2, 0, 2], //29
        [3, 1, 3, 1, 3], //30
        [0, 0, 2, 0, 0], //31
        [1, 1, 3, 1, 1], //32
        [2, 2, 0, 2, 2], //33
        [3, 3, 1, 3, 3], //34
        [0, 2, 2, 2, 0], //35
        [1, 3, 3, 3, 1], //36
        [2, 0, 0, 0, 2], //37
        [3, 1, 1, 1, 3], //38
        [0, 2, 1, 2, 0], //39
        [1, 3, 2, 3, 1], //40
        [2, 0, 1, 0, 2], //41
        [3, 1, 2, 1, 3], //42
        [1, 0, 1, 2, 1], //43
        [2, 1, 2, 3, 2], //44
        [1, 2, 1, 0, 1], //45
        [2, 3, 2, 1, 2], //46
        [0, 0, 1, 2, 2], //47
        [1, 1, 2, 3, 3], //48
        [2, 2, 1, 0, 0], //49
        [3, 3, 2, 1, 1], //50
    ],

    // prettier-ignore
    reels: [
        ['PIC5', 'CLUB', 'CLUB', 'PIC6', 'PIC5', 'HEART', 'SPADE', 'DIAMOND', 'PIC1', 'CLUB', 'HEART', 'PIC4', 'CLUB', 'PIC1', 'CLUB', 'PIC3', 'SPADE', 'PIC4', 'PIC6', 'PIC2', 'PIC6', 'DIAMOND', 'CLUB', 'PIC2', 'PIC5', 'PIC4', 'HEART', 'SPADE', 'PIC3', 'PIC4', 'CLUB', 'SPADE', 'SCAT', 'DIAMOND', 'SPADE', 'SPADE', 'WILD', 'WILD', 'HEART', 'DIAMOND', 'PIC5', 'DIAMOND', 'PIC6', 'HEART', 'DIAMOND', 'HEART', 'PIC3', 'DIAMOND', 'HEART', 'SPADE', 'PIC2', 'CLUB'],
        ['HEART', 'HEART', 'PIC2', 'SPADE', 'SCAT', 'CLUB', 'DIAMOND', 'DIAMOND', 'PIC3', 'SPADE', 'SPADE', 'PIC6', 'HEART', 'PIC3', 'SPADE', 'PIC5', 'HEART', 'PIC2', 'SPADE', 'CLUB', 'PIC6', 'PIC5', 'SPADE', 'CLUB', 'PIC3', 'CLUB', 'WILD', 'WILD', 'DIAMOND', 'PIC6', 'PIC2', 'SPADE', 'PIC4', 'DIAMOND', 'PIC3', 'CLUB', 'PIC5', 'PIC4', 'SPADE', 'PIC1', 'DIAMOND', 'PIC4', 'PIC4', 'DIAMOND', 'PIC6', 'PIC5', 'HEART', 'SCAT', 'PIC2', 'HEART', 'PIC5', 'PIC4', 'PIC6', 'CLUB', 'DIAMOND', 'PIC3', 'PIC3', 'HEART', 'PIC4', 'PIC1', 'PIC1', 'PIC4', 'SPADE', 'PIC5', 'PIC6', 'CLUB', 'PIC6', 'PIC3', 'CLUB', 'PIC2', 'PIC5', 'DIAMOND', 'PIC4', 'HEART', 'PIC2', 'DIAMOND', 'PIC1', 'SPADE', 'CLUB', 'PIC2', 'HEART', 'WILD', 'WILD', 'WILD', 'HEART', 'PIC5', 'CLUB', 'CLUB', 'PIC1', 'DIAMOND', 'PIC6'],
        ['SPADE', 'PIC5', 'SPADE', 'CLUB', 'PIC1', 'CLUB', 'SPADE', 'PIC6', 'SPADE', 'SPADE', 'SCAT', 'DIAMOND', 'CLUB', 'PIC4', 'CLUB', 'PIC5', 'PIC6', 'DIAMOND', 'PIC6', 'CLUB', 'PIC2', 'PIC2', 'PIC6', 'HEART', 'PIC3', 'PIC6', 'DIAMOND', 'SPADE', 'WILD', 'WILD', 'WILD', 'HEART', 'SPADE', 'PIC5', 'PIC5', 'DIAMOND', 'PIC1', 'PIC5', 'PIC2', 'PIC5', 'CLUB', 'PIC3', 'PIC6', 'DIAMOND', 'WILD', 'WILD', 'WILD', 'CLUB', 'PIC4', 'PIC4', 'PIC2', 'SPADE', 'PIC3', 'HEART', 'PIC3', 'SPADE', 'PIC5', 'PIC2', 'DIAMOND', 'CLUB', 'CLUB', 'PIC5', 'SCAT', 'CLUB', 'PIC1', 'PIC4', 'DIAMOND', 'PIC4', 'CLUB', 'PIC3', 'DIAMOND', 'PIC4', 'HEART', 'PIC4', 'PIC3', 'PIC3', 'HEART', 'PIC1', 'PIC4', 'PIC6', 'SPADE', 'HEART', 'PIC2', 'PIC2', 'HEART', 'HEART', 'HEART', 'PIC1', 'DIAMOND', 'DIAMOND', 'PIC6'],
        ['PIC6', 'HEART', 'DIAMOND', 'PIC6', 'PIC4', 'HEART', 'SCAT', 'SPADE', 'PIC4', 'PIC5', 'HEART', 'PIC2', 'HEART', 'PIC5', 'PIC3', 'SPADE', 'SCAT', 'CLUB', 'HEART', 'PIC6', 'DIAMOND', 'PIC4', 'PIC2', 'CLUB', 'SPADE', 'CLUB', 'PIC3', 'DIAMOND', 'PIC5', 'CLUB', 'CLUB', 'PIC2', 'HEART', 'WILD', 'WILD', 'WILD', 'PIC5', 'DIAMOND', 'PIC4', 'PIC1', 'PIC5', 'PIC5', 'CLUB', 'PIC6', 'PIC4', 'HEART', 'PIC3', 'CLUB', 'DIAMOND', 'PIC1', 'CLUB', 'DIAMOND', 'SPADE', 'PIC5', 'PIC1', 'CLUB', 'PIC6', 'PIC2', 'SPADE', 'DIAMOND', 'PIC3', 'CLUB', 'PIC2', 'PIC4', 'PIC6', 'CLUB', 'PIC3', 'CLUB', 'PIC6', 'SPADE', 'PIC5', 'HEART', 'CLUB', 'PIC2', 'DIAMOND', 'SPADE', 'PIC4', 'PIC1', 'CLUB', 'CLUB', 'PIC6', 'PIC2', 'CLUB', 'PIC4', 'PIC3', 'PIC3', 'CLUB', 'WILD', 'WILD', 'SPADE', 'PIC1'],
        ['DIAMOND', 'PIC6', 'PIC4', 'CLUB', 'PIC5', 'SPADE', 'PIC3', 'PIC4', 'CLUB', 'CLUB', 'PIC3', 'DIAMOND', 'PIC4', 'HEART', 'PIC2', 'CLUB', 'DIAMOND', 'PIC3', 'CLUB', 'PIC1', 'HEART', 'PIC5', 'SPADE', 'WILD', 'WILD', 'CLUB', 'PIC6', 'SPADE', 'DIAMOND', 'SCAT', 'HEART', 'PIC6', 'CLUB', 'CLUB', 'PIC2', 'SPADE', 'PIC5', 'PIC1', 'HEART', 'PIC2']
    ],

    winLevels: [
        { name: 'normal1', threshold: 2 },
        { name: 'normal2', threshold: 5 },
        { name: 'normal3', threshold: 20 },
        { name: 'bigwin', threshold: 35 },
        { name: 'megawin', threshold: 70 },
        { name: 'epicwin' },
    ],

    freeSpinFeatures: {
        20: {
            multiplier: 1,
            featureName: 'FREESPINS_20',
        },
        12: {
            multiplier: 2,
            featureName: 'FREESPINS_12',
        },
        6: {
            multiplier: 5,
            featureName: 'FREESPINS_6',
        },
    } as const,

    countToTriggerHoldAndSpin: 6,
    holdAndSpinRespinCount: 3,
    countToTriggerGrand: 20,

    /** Weights used during hold-and-spin respins to possibly turn non-cheese symbols in the slot window into cheeses. */
    extraCheeseWeights: {
        chance: 1,
        outOf: 13,
    },

    cheeseBlockPrizeWeights: {
        '1x1': {
            BASE: [
                { outcome: 50, weight: 8 },
                { outcome: 60, weight: 4 },
                { outcome: 70, weight: 3 },
                { outcome: 80, weight: 3 },
                { outcome: 90, weight: 2 },
                { outcome: 100, weight: 2 },
                { outcome: 125, weight: 1 },
                { outcome: 150, weight: 1 },
            ],
            FREESPINS_20: [
                { outcome: 50, weight: 8 },
                { outcome: 60, weight: 4 },
                { outcome: 70, weight: 3 },
                { outcome: 80, weight: 3 },
                { outcome: 90, weight: 2 },
                { outcome: 100, weight: 2 },
                { outcome: 125, weight: 1 },
                { outcome: 150, weight: 1 },
            ],
            FREESPINS_12: [
                { outcome: 50, weight: 1 },
                { outcome: 60, weight: 1 },
                { outcome: 70, weight: 1 },
                { outcome: 80, weight: 1 },
                { outcome: 90, weight: 1 },
                { outcome: 100, weight: 1 },
                { outcome: 125, weight: 1 },
                { outcome: 150, weight: 1 },
            ],
            FREESPINS_6: [
                { outcome: 50, weight: 2 },
                { outcome: 60, weight: 2 },
                { outcome: 70, weight: 2 },
                { outcome: 80, weight: 2 },
                { outcome: 90, weight: 2 },
                { outcome: 100, weight: 2 },
                { outcome: 125, weight: 1 },
                { outcome: 150, weight: 1 },
            ],
        },
        '2x2': {
            BASE: [
                { outcome: 300, weight: 2 },
                { outcome: 400, weight: 2 },
                { outcome: 500, weight: 2 },
                { outcome: 600, weight: 2 },
                { outcome: 750, weight: 1 },
                { outcome: 'MINI' as const, weight: 1 },
            ],
            FREESPINS_20: [
                { outcome: 300, weight: 2 },
                { outcome: 400, weight: 2 },
                { outcome: 500, weight: 2 },
                { outcome: 600, weight: 2 },
                { outcome: 750, weight: 1 },
                { outcome: 'MINI' as const, weight: 1 },
            ],
            FREESPINS_12: [
                { outcome: 300, weight: 1 },
                { outcome: 400, weight: 1 },
                { outcome: 500, weight: 2 },
                { outcome: 600, weight: 2 },
                { outcome: 750, weight: 2 },
                { outcome: 'MINI' as const, weight: 3 },
            ],
            FREESPINS_6: [
                { outcome: 300, weight: 1 },
                { outcome: 400, weight: 2 },
                { outcome: 500, weight: 2 },
                { outcome: 600, weight: 2 },
                { outcome: 750, weight: 1 },
                { outcome: 'MINI', weight: 1 } as const,
            ],
        },
        '3x3': {
            BASE: [
                { outcome: 1000, weight: 3 },
                { outcome: 1200, weight: 2 },
                { outcome: 1500, weight: 2 },
                { outcome: 1750, weight: 1 },
                { outcome: 2000, weight: 1 },
                { outcome: 'MINOR' as const, weight: 1 },
            ],
            FREESPINS_20: [
                { outcome: 1000, weight: 3 },
                { outcome: 1200, weight: 2 },
                { outcome: 1500, weight: 2 },
                { outcome: 1750, weight: 1 },
                { outcome: 2000, weight: 1 },
                { outcome: 'MINOR' as const, weight: 1 },
            ],
            FREESPINS_12: [
                { outcome: 1000, weight: 2 },
                { outcome: 1200, weight: 2 },
                { outcome: 1500, weight: 2 },
                { outcome: 1750, weight: 2 },
                { outcome: 2000, weight: 2 },
                { outcome: 'MINOR' as const, weight: 5 },
            ],
            FREESPINS_6: [
                { outcome: 1000, weight: 3 },
                { outcome: 1200, weight: 2 },
                { outcome: 1500, weight: 2 },
                { outcome: 1750, weight: 1 },
                { outcome: 2000, weight: 1 },
                { outcome: 'MINOR' as const, weight: 1 },
            ],
        },
        '4x4': {
            BASE: [
                { outcome: 2500, weight: 1 },
                { outcome: 3000, weight: 1 },
                { outcome: 3500, weight: 1 },
                { outcome: 4000, weight: 1 },
                { outcome: 5000, weight: 1 },
                { outcome: 6000, weight: 1 },
                { outcome: 7500, weight: 1 },
            ],
            FREESPINS_20: [
                { outcome: 2500, weight: 1 },
                { outcome: 3000, weight: 1 },
                { outcome: 3500, weight: 1 },
                { outcome: 4000, weight: 1 },
                { outcome: 5000, weight: 1 },
                { outcome: 6000, weight: 1 },
                { outcome: 7500, weight: 1 },
            ],
            FREESPINS_12: [
                { outcome: 2500, weight: 1 },
                { outcome: 3000, weight: 1 },
                { outcome: 3500, weight: 1 },
                { outcome: 4000, weight: 1 },
                { outcome: 5000, weight: 1 },
                { outcome: 6000, weight: 1 },
                { outcome: 7500, weight: 1 },
            ],
            FREESPINS_6: [
                { outcome: 2500, weight: 1 },
                { outcome: 3000, weight: 1 },
                { outcome: 3500, weight: 1 },
                { outcome: 4000, weight: 1 },
                { outcome: 5000, weight: 1 },
                { outcome: 6000, weight: 1 },
                { outcome: 7500, weight: 1 },
            ],
        },
        '4x5': {
            BASE: [
                { outcome: 7500, weight: 4 },
                { outcome: 8000, weight: 4 },
                { outcome: 9000, weight: 2 },
                { outcome: 10000, weight: 3 },
                { outcome: 15000, weight: 2 },
                { outcome: 20000, weight: 1 },
            ],
            FREESPINS_20: [
                { outcome: 7500, weight: 4 },
                { outcome: 8000, weight: 4 },
                { outcome: 9000, weight: 2 },
                { outcome: 10000, weight: 3 },
                { outcome: 15000, weight: 2 },
                { outcome: 20000, weight: 1 },
            ],
            FREESPINS_12: [
                { outcome: 7500, weight: 4 },
                { outcome: 8000, weight: 4 },
                { outcome: 9000, weight: 2 },
                { outcome: 10000, weight: 3 },
                { outcome: 15000, weight: 2 },
                { outcome: 20000, weight: 1 },
            ],
            FREESPINS_6: [
                { outcome: 7500, weight: 4 },
                { outcome: 8000, weight: 4 },
                { outcome: 9000, weight: 2 },
                { outcome: 10000, weight: 3 },
                { outcome: 15000, weight: 2 },
                { outcome: 20000, weight: 1 },
            ],
        },
    },

    cheeseBlockExtraPrizeWeightMultiplierByCurrency: {
        SWEEPS: 1,
        GOLD: 1 / 50,
    },

    cheeseBlockExtraPrizeWeights: {
        '4x4': {
            BASE: [
                { outcome: 'MAJOR', multiplier: 2, constant: 0 },
                { outcome: 7500, multiplier: 3, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -5, constant: 2200 },
            ] as const,
            FREESPINS_20: [
                { outcome: 'MAJOR', multiplier: 2, constant: 0 },
                { outcome: 7500, multiplier: 3, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -5, constant: 8026 },
            ] as const,
            FREESPINS_12: [
                { outcome: 'MAJOR', multiplier: 2, constant: 0 },
                { outcome: 7500, multiplier: 3, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -5, constant: 4569 },
            ] as const,
            FREESPINS_6: [
                { outcome: 'MAJOR', multiplier: 2, constant: 0 },
                { outcome: 7500, multiplier: 3, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -5, constant: 2200 },
            ] as const,
        },
        '4x5': {
            BASE: [
                { outcome: 'GRAND', multiplier: 1, constant: 0 },
                { outcome: 20000, multiplier: 1, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -2, constant: 6000 },
            ] as const,
            FREESPINS_20: [
                { outcome: 'GRAND', multiplier: 1, constant: 0 },
                { outcome: 20000, multiplier: 1, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -2, constant: 6000 },
            ] as const,
            FREESPINS_12: [
                { outcome: 'GRAND', multiplier: 1, constant: 0 },
                { outcome: 20000, multiplier: 1, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -2, constant: 3416 },
            ] as const,
            FREESPINS_6: [
                { outcome: 'GRAND', multiplier: 1, constant: 0 },
                { outcome: 20000, multiplier: 1, constant: 0 },
                { outcome: 'NORMAL_PICK', multiplier: -2, constant: 1645 },
            ] as const,
        },
    },
    jackpots: {
        fixedMultipliers: {
            MINI: 1000,
            MINOR: 5000,
        },
        seedValues: {
            MAJOR: {
                GOLD: 100_000_000,
                SWEEPS: 1_000_000,
            },
            GRAND: {
                GOLD: 1_000_000_000,
                SWEEPS: 10_000_000,
            },
        },
        percentToJackpotPool: {
            MAJOR: 0.4,
            GRAND: 0.4,
        },
    },
};
