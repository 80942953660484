import Vue, { PropType } from "vue";
import { SpinOutcome } from "../math-model/spin";
import { ReelSymbol } from "./reel-symbol";

export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<SpinOutcome>,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    isFreeSpins: function (): boolean {
      return this.spinOutcome.freeSpinPhase !== undefined;
    },
    spinType: function () {
      if (this.spinOutcome.isPick) {
        return "WHEEL_SPIN";
      } else if (
        this.spinOutcome.freeSpinPhase &&
        this.spinOutcome.freeSpinPhase !== "START"
      ) {
        return "FREE_SPINS";
      }
      return "BASE";
    },
  },
  methods: {
    isWinCell: function (rowIndex: number, colIndex: number): boolean {
      if (this.spinOutcome.winCells) {
        for (const cellsArray of this.spinOutcome.winCells) {
          for (const cell of cellsArray) {
            if (cell[0] === rowIndex && cell[1] === colIndex) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
});
