var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isWheelTriggered,
                expression: "!isWheelTriggered",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isWheelTriggered,
                expression: "!isWheelTriggered",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addWheelStepTrigger()
              },
            },
          },
          [_vm._v("Wheel Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isWheelTriggered,
                expression: "isWheelTriggered",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addWheelStep()
              },
            },
          },
          [_vm._v("Wheel Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWheelTriggered && !_vm.isWheel,
              expression: "isWheelTriggered && !isWheel",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWheel,
              expression: "isWheel",
            },
          ],
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "iswheel" }, [
            _c("div", { staticClass: "iswheel" }, [
              _c("div", [_vm._v("Index: ")]),
              _vm._v(" "),
              _c("div", { staticClass: "select-style" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elementIndex,
                        expression: "elementIndex",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.elementIndex = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.doChangeElementIndex,
                      ],
                    },
                  },
                  _vm._l(_vm.wheelElementsIndexes, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isWheel,
              expression: "!isWheel",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "iswheel" }, [
      _c("div", { staticClass: "sub-title-menu" }, [
        _c("div", { attrs: { align: "center" } }, [
          _vm._v(
            "\n                   Wheel is Triggered, make sure you add a `Wheel Step` ^\n                    "
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "sub-title-menu" }, [
      _c("div", { attrs: { align: "center" } }, [
        _vm._v(
          "\n                Make sure the step before this one is triggering the wheel!\n                "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }