import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "../../../../helpers/casino";
import {trace, TODO, range} from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./../reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
    data: () => {
        return {
        };
    },
})
export default class Content extends Vue {  
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    
    public nRows = State.state.getModelDefinition().reelsLayout[0];

    constructor() {
        super();
    }
    
    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        
        this.show = true;

        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.$nextTick(() => bus.$emit("step-updated", step));
    }
    
    public mounted() {
        trace("reels mounted");
    }
    
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        this.checkChangesInStep();
    }
    
    public addBaseStep() {
        bus.$emit(events.ADD_STEP, State.state.getBaseStep() );
    }
    
    public addFeatureStep() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureStep() );
    }
    
    public addFeatureTriggerStep() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureTrigger() );
    }
    
    public addFeatureRetrigger() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureReTriggerStep() );
    }
    
    public checkChangesInStep() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
