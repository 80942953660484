import { modelDefinition } from "../config";
import { Choices } from "./choices";
import { ScenarioStep } from "./scenario-step";

export function createChoicesFromScenarioStep(scenarioStep: ScenarioStep): Choices {
  return {
      chooseReelStripPositions() {
          return scenarioStep.reelStripPositions ?? [0, 0, 0, 0, 0];
      },
      chooseSuperDrumMultiplier() {
          return {
              isSuperDrumAwarded: scenarioStep.superDrum?.isSuperDrumAwarded ?? false,
              multiplier: scenarioStep.superDrum?.isSuperDrumAwarded
                  ? scenarioStep.superDrum.multiplier
                      ? scenarioStep.superDrum.multiplier
                      : 1
                  : undefined,
          };
      },
      chooseFreeSpinsAwarded() {
          return scenarioStep?.freeSpinsAwarded ?? modelDefinition.freeSpinsWheel[0];
      },
      chooseFreeSpinsMultiplier() {
          return scenarioStep?.freeSpinsMultiplier ?? modelDefinition.freeSpinsMultiplierWheel[0];
      },
  };
}
