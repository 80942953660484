import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./../reels/reel.vue";
import {modelDefinition} from "../../../../state/models/slots/vgw075/config/init";


@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {
            burgerReelPosition: undefined as number | undefined,
            isJackpotFriesVisible: undefined as boolean | undefined,
        };
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isFeature = false;
    public picxReplacement = modelDefinition.picxSymbol;
    public burgerReelPosition = undefined as number | undefined;
    public isJackpotFriesVisible = undefined as boolean | undefined;

    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.picxReplacement = step.json.picxReplacement;
        this.burgerReelPosition = step.json.burgerReelPosition;
        this.isJackpotFriesVisible = step.json.isJackpotFriesVisible;
        console.log(step.json);
    }
    public mounted() { 
        trace("reels mounted");
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }
    public updatePicxReplacementSymbol() {
        this.step.json.picxReplacement = this.picxReplacement;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinTriggerFromBaseGame());
    }
    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }
    public addFreeSpinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreeSpinStep());
    }
    public addBurgerReelPosition() {
        this.step.json.burgerReelPosition = this.burgerReelPosition;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addIsJackpotFriesVisible() {
        this.step.json.isJackpotFriesVisible = this.isJackpotFriesVisible
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
