var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStep()
              },
            },
          },
          [_vm._v("Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: ["button", "step-button", "pointer", ""],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center max-scatter-selector" }, [
        _c(
          "div",
          {
            class: ["button", "step-button", "pointer", ""],
            on: {
              click: function ($event) {
                return _vm.toggleMaxScatterOnRetrigger()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s("Respin Trigger Max Scatters: " + _vm.maxScatterExtra) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center top-reel-selector-wrapper" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-center top-reel-selector top-reel-selector-position",
          },
          [
            _c("label", { attrs: { for: "topReelReelStripPosition" } }, [
              _vm._v(" Position "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.topReelReelStripPosition,
                    expression: "topReelReelStripPosition",
                  },
                ],
                attrs: { id: "topReelReelStripPosition" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.topReelReelStripPosition = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateTopReelReelStripPosition(
                        _vm.topReelReelStripPosition
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0", selected: "selected" } }, [
                  _vm._v("0"),
                ]),
                _vm._v(" "),
                _vm._l(67, function (n) {
                  return _c("option", [_vm._v(_vm._s(n) + " ")])
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        [_c("topreel", { attrs: { index: 0, step: _vm.step } })],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [
          _vm._v("Feature level (for trigger step):"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.featureLevel,
                expression: "featureLevel",
                modifiers: { number: true },
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "featureLevel", id: "featureLevel" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return _vm._n(val)
                    })
                  _vm.featureLevel = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeFeatureLevel,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "0", selected: "" } }, [
              _vm._v("0"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticClass: "text" }, [
          _vm._v("Pick result (compass minigame):"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.wheelPickResult,
                expression: "wheelPickResult",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "wheelPickResult", id: "wheelPickResult" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.wheelPickResult = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeWheelPickResult,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "false", selected: "" } }, [
              _vm._v("false"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "true" } }, [_vm._v("true")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c(
          "div",
          { staticClass: "selected-scatters" },
          [
            _vm._l(4, function (value) {
              return _c("selectScatterMultiplier", {
                key: value,
                attrs: {
                  index: value,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 4,
                attrs: {
                  index: value + 4,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 4 + 6,
                attrs: {
                  index: value + 4 + 6,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 4 + 12,
                attrs: {
                  index: value + 4 + 12,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 4 + 18,
                attrs: {
                  index: value + 4 + 18,
                  scattersSelectedMultipliers: _vm.scattersSelectedMultipliers,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.resetRespinSymbols()
              },
            },
          },
          [_vm._v("Reset")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Top Reel:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }