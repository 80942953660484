var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c(
        "div",
        { class: _vm.canUpdate ? "" : "hide", attrs: { align: "center" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-up",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", { staticClass: "respin" }, [
            _c(
              "div",
              {
                class: [
                  "symbol",
                  "on",
                  _vm.content.isCellGoldenStackSymbolThisStep(_vm.index, row)
                    ? "goldenStackedSymbolThisStep"
                    : _vm.content.isStackPICSymbolThisStep(_vm.index, row)
                    ? "stackedSymbolThisStep"
                    : "",
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _vm.content.isStackReSpin
                      ? [
                          _c("div", { staticClass: "stackReSpinCell" }, [
                            _vm.content.isStackedPICSymbol(_vm.index, row)
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.content.isStackPICSymbolThisStep(
                                        _vm.index,
                                        row
                                      )
                                        ? "clickable"
                                        : "",
                                      _vm.content.isCellGoldenStackSymbol(
                                        _vm.index,
                                        row
                                      )
                                        ? "goldenStackedSymbol"
                                        : "",
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.content.toggleStackPICSymbol(
                                          _vm.index,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.content.stackedSymbol))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "BLANK clickable",
                                    on: {
                                      click: function ($event) {
                                        return _vm.content.toggleStackPICSymbol(
                                          _vm.index,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "click for " +
                                        _vm._s(_vm.content.stackedSymbol)
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            _vm.content.isStackPICSymbolThisStep(
                              _vm.index,
                              row
                            ) &&
                            (_vm.content.isCellGoldenStackSymbol(
                              _vm.index,
                              row
                            ) ||
                              _vm.content.canAddMoreGoldenStackSymbols)
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      "goldenSymbolToggle",
                                      !_vm.content.isCellGoldenStackSymbol(
                                        _vm.index,
                                        row
                                      )
                                        ? "inactive"
                                        : "",
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.content.toggleGoldCell(
                                          _vm.index,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("⭐️")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      : _vm.content.isClearingStep ||
                        !_vm.content.canCellHaveAddedWild(_vm.index, sym)
                      ? [
                          _c("div", { class: [sym] }, [
                            _c("span", [_vm._v(_vm._s(sym))]),
                          ]),
                        ]
                      : !_vm.content.isCellAddedWild(_vm.index, row)
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "clickable",
                              attrs: { title: "Click to add WILD" },
                              on: {
                                click: function ($event) {
                                  _vm.content.canCellHaveAddedWild(sym) &&
                                    _vm.content.toggleAddedWildSymbol(
                                      _vm.index,
                                      row
                                    )
                                },
                              },
                            },
                            [
                              _c("span", { class: [sym] }, [
                                _vm._v(" " + _vm._s(sym) + " "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: ["alternateOption", "WILD"] },
                                [_vm._v("WILD")]
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "clickable",
                              attrs: { title: "Click to remove WILD" },
                              on: {
                                click: function ($event) {
                                  return _vm.content.toggleAddedWildSymbol(
                                    _vm.index,
                                    row
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { class: ["WILD"] }, [_vm._v("WILD")]),
                              _vm._v(" "),
                              _c("span", { class: ["alternateOption", sym] }, [
                                _vm._v(_vm._s(sym)),
                              ]),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-down",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }