/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf009_outerspaceinvaders",
        "scatterSymbol": "SC",
        "scatterSymbols": ["SC", "FS"],
        "bonusSymbol": "SC",
        "wildSymbol": "WI",
        "freeSpinTrigger": "FS",
        "countToTriggerFreeSpin": 3,
        "countToTriggerBonusGame": 3,
        "freeSpinCountArray": [8, 12, 20],

        "winTable": [
            {
                "symbol": "LV1",
                "multipliers": [25, 15, 5]
            },
            {
                "symbol": "LV2",
                "multipliers": [25, 15, 5]
            },
            {
                "symbol": "LV3",
                "multipliers": [25, 15, 5]
            },
            {
                "symbol": "LV4",
                "multipliers": [40, 25, 15]
            },
            {
                "symbol": "LV5",
                "multipliers": [40, 25, 15]
            },
            {
                "symbol": "HV1",
                "multipliers": [100, 80, 40]
            },
            {
                "symbol": "HV2",
                "multipliers": [200, 100, 50]
            },
            {
                "symbol": "HV3",
                "multipliers": [250, 125, 60]
            },
            {
                "symbol": "HV4",
                "multipliers": [500, 150, 80]
            },
            {
                "symbol": "HV5",
                "multipliers": [5000, 500, 100]
            }
        ],

        "winTableFS": [
            {
                "symbol": "HV4",
                "multipliers": [500, 250]
            },
            {
                "symbol": "HV3",
                "multipliers": [250, 100]
            },
            {
                "symbol": "HV2",
                "multipliers": [200, 50]
            },
            {
                "symbol": "HV1",
                "multipliers": [100, 20]
            },
            {
                "symbol": "LV5",
                "multipliers": [60, 10]
            },
            {
                "symbol": "LV4",
                "multipliers": [50, 8]
            },
            {
                "symbol": "LV3",
                "multipliers": [40, 7]
            },
            {
                "symbol": "LV2",
                "multipliers": [30, 6]
            },
            {
                "symbol": "LV1",
                "multipliers": [20, 5]
            }
        ],


        "reelsLayout": [3, 3, 3, 3, 3],
        "reelsLayoutFreeSpin": [3, 3, 3, 3],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "2500000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "50",
        "coinType4_spin_max_bet": "1250",
        "betMultiplier" : 20,
        "reels": [
            ["HV1", "LV2", "LV3", "HV1", "LV3", "LV1", "HV5", "LV1", "LV3", "SC", "LV2", "LV5", "HV4", "LV2", "LV1", "HV4", "LV3", "HV1", "LV1", "LV5", "FS", "LV1", "LV2", "HV1", "LV4", "LV2", "HV2", "LV4", "HV4", "LV1", "LV4", "HV3", "LV1", "HV3", "LV4", "HV2", "LV3", "LV5", "HV2", "LV4", "HV1", "LV5", "HV5", "LV4", "FS", "LV2", "HV3", "LV4", "LV5", "SC", "LV3", "HV2", "LV1"],
            ["HV3", "LV2", "LV4", "LV5", "HV3", "LV1", "LV5", "LV3", "LV5", "HV3", "LV1", "LV4", "LV3", "FS", "LV4", "LV1", "HV5", "LV2", "LV5", "LV3", "HV2", "LV1", "LV2", "LV3", "HV2", "LV5", "LV1", "WI", "LV5", "LV1", "LV3", "LV1", "HV2", "LV3", "LV5", "SC", "LV2", "LV1", "HV3", "LV4", "LV2", "HV1", "LV5", "LV1", "HV5", "LV2", "HV4", "LV1", "LV3", "HV2", "LV2", "HV4", "LV4", "LV1", "LV3", "HV4", "LV5", "LV2", "LV2", "HV2", "LV4", "LV1", "HV1", "LV2", "HV1", "LV1", "LV4", "HV1", "LV5", "LV1", "FS", "LV4", "LV5", "HV1", "LV4", "LV5", "SC", "LV4", "LV3", "LV5", "HV1", "LV4", "LV2", "LV3", "HV2", "LV3", "LV2", "SC", "LV3"],
            ["LV1", "LV2", "FS", "LV1", "LV2", "HV1", "LV3", "SC", "LV2", "HV3", "LV4", "LV2", "LV4", "HV3", "LV5", "LV2", "HV3", "LV1", "LV2", "LV5", "HV4", "LV1", "HV4", "LV5", "LV2", "HV4", "LV2", "HV5", "LV3", "HV3", "LV3", "LV5", "HV1", "LV1", "LV2", "LV1", "HV1", "LV4", "LV2", "HV2", "LV5", "LV3", "HV1", "LV5", "LV3", "HV2", "LV2", "LV3", "LV1", "HV5", "LV3", "HV2", "LV2", "LV3", "LV5", "HV2", "LV5", "LV1", "HV1", "LV1", "FS", "LV2", "LV3", "LV4", "HV1", "LV1", "LV3", "WI", "LV1", "LV3", "LV1", "LV3", "SC", "LV1", "LV3", "LV5", "HV2", "LV5", "LV1", "LV5", "HV2", "LV4", "LV1", "LV5", "SC", "LV3", "LV1", "LV4"],
            ["LV5", "HV4", "LV3", "LV5", "HV4", "LV4", "LV5", "HV4", "LV4", "LV3", "HV1", "LV3", "LV4", "HV2", "LV1", "LV4", "HV2", "LV4", "HV1", "LV5", "SC", "LV3", "HV5", "LV1", "FS", "LV4", "LV2", "HV2", "LV1", "LV2", "SC", "LV3", "HV1", "LV4", "LV2", "HV1", "FS", "LV3", "LV4", "HV3", "LV5", "LV2", "HV3", "LV2", "LV1", "WI", "LV2", "LV1", "HV1", "LV4", "HV2", "LV2", "SC", "LV2", "LV4", "HV3", "LV1"],
            ["HV2", "LV1", "LV2", "FS", "LV4", "HV3", "LV1", "SC", "LV5", "HV4", "LV3", "LV1", "HV5", "LV1", "LV2", "HV4", "LV2", "SC", "LV5", "HV1", "LV5", "FS", "LV4", "LV3", "HV3", "LV4", "HV3", "LV5", "LV3", "HV2", "LV4", "SC", "LV3", "LV1", "HV4", "LV2", "HV2", "LV3", "LV5", "HV2", "LV5", "LV2", "HV1", "LV1", "FS", "LV4", "HV1", "LV4", "HV1", "LV1", "LV4", "HV2", "LV2", "HV1", "LV3"]
        ],

        "featureReels": [
            ["LV4", "LV2", "LV3", "LV2", "HV1", "LV1", "LV5", "HV1", "LV5", "HV4", "LV3", "HV3", "LV5", "LV4", "LV2", "HV2", "LV1", "LV4", "HV1", "RA1", "LV2", "LV1", "LV3", "HV2", "LV4", "HV3", "LV3", "LV2", "LV3", "LV2", "HV1", "LV1", "LV5", "HV1", "HV4", "LV3", "HV3", "LV5", "LV1", "RA3", "LV4", "LV2", "HV2", "LV1", "LV4", "HV1", "RA2", "LV2", "LV1", "LV3", "HV2", "LV4", "HV3", "LV3"],
            ["LV3", "LV2", "LV1", "LV4", "LV2", "LV1", "HV2", "LV3", "LV4", "HV4", "LV5", "RA3", "HV3", "LV4", "HV1", "LV5", "LV2", "HV1", "LV3", "LV5", "LV4", "LV2", "HV2", "LV3", "LV5", "LV1", "HV3", "LV3", "RA2", "LV2", "LV1", "LV4", "LV1", "HV2", "LV3", "LV4", "HV4", "LV5", "RA1", "HV3", "LV4", "HV1", "LV5", "LV2", "HV1", "LV1", "LV3", "LV4", "LV2", "HV2", "LV3", "LV5", "LV1", "HV3"],
            ["LV3", "LV1", "LV5", "HV1", "RA1", "HV4", "LV2", "LV3", "LV4", "LV1", "HV3", "LV4", "HV2", "LV3", "LV2", "HV1", "LV1", "LV5", "HV1", "LV1", "HV3", "LV4", "HV2", "LV3", "LV4", "LV2", "HV4", "LV3", "LV1", "LV5", "HV1", "RA2", "HV4", "LV2", "LV4", "LV1", "HV3", "LV4", "HV2", "LV3", "LV2", "HV1", "LV1", "LV2", "LV5", "HV1", "LV1", "HV3", "LV4", "HV2", "LV3", "RA3", "LV4", "LV2", "HV4"],
            ["LV5", "HV4", "HV1", "LV2", "LV3", "HV3", "LV2", "HV1", "HV2", "LV3", "LV4", "RA3", "LV1", "LV3", "HV2", "LV4", "HV3", "LV3", "LV2", "HV1", "LV1", "HV4", "LV5", "LV2", "HV2", "LV1", "LV4", "LV5", "HV4", "RA2", "HV1", "LV2", "LV1", "HV3", "LV2", "HV1", "HV2", "LV3", "LV4", "RA1", "LV1", "LV3", "HV2", "LV4", "HV3", "LV3", "LV2", "HV1", "LV1", "HV4", "LV5", "LV2", "HV2", "LV1"]
        ],

        "playLines": [
            [1, 1, 1, 1, 1], //1
            [0, 0, 0, 0, 0], //2
            [2, 2, 2, 2, 2], //3
            [0, 1, 2, 1, 0], //4
            [2, 1, 0, 1, 2], //5
            [0, 0, 1, 0, 0], //6
            [0, 1, 0, 1, 0], //7
            [0, 1, 1, 1, 0], //8
            [1, 0, 0, 0, 1], //9
            [1, 0, 1, 0, 1], //10
            [1, 1, 0, 1, 1], //11
            [1, 1, 2, 1, 1], //12
            [1, 2, 1, 2, 1], //13
            [1, 2, 2, 2, 1], //14
            [2, 1, 1, 1, 2], //15
            [2, 1, 2, 1, 2], //16
            [2, 2, 1, 2, 2], //17
            [0, 2, 0, 2, 0], //18
            [2, 0, 2, 0, 2], //19
            [0, 0, 2, 0, 0], //20
            [2, 2, 0, 2, 2], //21
            [2, 0, 0, 0, 2], //22
            [0, 2, 2, 2, 0], //23
            [1, 2, 0, 2, 1], //24
            [1, 0, 2, 0, 1]  //25
        ],

        "playLineFS": [
            [1, 1, 1, 1], //1
            [0, 0, 0, 0], //2
            [2, 2, 2, 2], //3
            [0, 1, 2, 1], //4
            [2, 1, 0, 1], //5
            [0, 0, 1, 0], //6
            [0, 1, 0, 1], //7
            [0, 1, 1, 1], //8
            [1, 0, 0, 0], //9
            [1, 0, 1, 0], //10
            [1, 1, 0, 1], //11
            [1, 1, 2, 1], //12
            [1, 2, 1, 2], //13
            [1, 2, 2, 2], //14
            [2, 1, 1, 1], //15
            [2, 1, 2, 1], //16
            [2, 2, 1, 2], //17
            [0, 2, 0, 2], //18
            [2, 0, 2, 0], //19
            [0, 0, 2, 0], //20
            [2, 2, 0, 2], //21
            [2, 0, 0, 0], //22
            [0, 2, 2, 2], //23
            [1, 2, 0, 2], //24
            [1, 0, 2, 0]  //25
        ],

        "winSpecialSymbols": {
        "RA1": 1,
            "RA2": 2,
            "RA3": 3
        },

        "zerkHealth": 10,
        "scatterInvaders": [
            [{"ship": 0, "increment": 1, "payTable": 1, "weight": 0},
                {"ship": 1, "increment": 1, "payTable": 2, "weight": 0},
                {"ship": 2, "increment": 1, "payTable": 3, "weight": 0},
                {"ship": 3, "increment": 2, "payTable": 5, "weight": 21},
                {"ship": 4, "increment": 2, "payTable": 7, "weight": 77},
                {"ship": 5, "increment": 2, "payTable": 9, "weight": 141},
                {"ship": 6, "increment": 3, "payTable": 12, "weight": 155},
                {"ship": 7, "increment": 3, "payTable": 15, "weight": 156},
                {"ship": 8, "increment": 3, "payTable": 18, "weight": 155},
                {"ship": 9, "increment": 4, "payTable": 22, "weight": 120},
                {"ship": 10, "increment": 4, "payTable": 26, "weight": 80},
                {"ship": 11, "increment": 4, "payTable": 30, "weight": 40},
                {"ship": 12, "increment": 5, "payTable": 35, "weight": 20},
                {"ship": 13, "increment": 8, "payTable": 43, "weight": 10},
                {"ship": 14, "increment": 10, "payTable": 53, "weight": 5},
                {"ship": 15, "increment": 12, "payTable": 65, "weight": 5},
                {"ship": 16, "increment": 15, "payTable": 80, "weight": 5},
                {"ship": 17, "increment": 20, "payTable": 100, "weight": 10}
            ],
            [
                {"ship": 0, "increment": 2, "payTable": 2, "weight": 0},
                {"ship": 1, "increment": 2, "payTable": 4, "weight": 0},
                {"ship": 2, "increment": 3, "payTable": 7, "weight": 0},
                {"ship": 3, "increment": 4, "payTable": 11, "weight": 20},
                {"ship": 4, "increment": 4, "payTable": 15, "weight": 100},
                {"ship": 5, "increment": 5, "payTable": 20, "weight": 130},
                {"ship": 6, "increment": 5, "payTable": 25, "weight": 150},
                {"ship": 7, "increment": 5, "payTable": 30, "weight": 150},
                {"ship": 8, "increment": 5, "payTable": 35, "weight": 150},
                {"ship": 9, "increment": 5, "payTable": 40, "weight": 120},
                {"ship": 10, "increment": 5, "payTable": 45, "weight": 80},
                {"ship": 11, "increment": 10, "payTable": 55, "weight": 40},
                {"ship": 12, "increment": 10, "payTable": 65, "weight": 20},
                {"ship": 13, "increment": 10, "payTable": 75, "weight": 10},
                {"ship": 14, "increment": 15, "payTable": 90, "weight": 5},
                {"ship": 15, "increment": 15, "payTable": 105, "weight": 5},
                {"ship": 16, "increment": 20, "payTable": 125, "weight": 5},
                {"ship": 17, "increment": 25, "payTable": 150, "weight": 15}
            ],
            [
                {"ship":0, "increment": 3, "payTable": 3, "weight": 0},
                {"ship":1, "increment": 3, "payTable": 6, "weight": 0},
                {"ship":2, "increment": 4, "payTable": 10, "weight": 0},
                {"ship":3, "increment": 5, "payTable": 15, "weight": 20},
                {"ship":4, "increment": 5, "payTable": 20, "weight": 20},
                {"ship":5, "increment": 5, "payTable": 25, "weight": 20},
                {"ship":6, "increment": 5, "payTable": 30, "weight": 50},
                {"ship":7, "increment": 5, "payTable": 35, "weight": 75},
                {"ship":8, "increment": 5, "payTable": 40, "weight": 100},
                {"ship":9, "increment": 10, "payTable": 50, "weight": 145},
                {"ship":10, "increment": 10, "payTable": 60, "weight": 160},
                {"ship":11, "increment": 10, "payTable": 70, "weight": 145},
                {"ship":12, "increment": 15, "payTable": 85, "weight": 120},
                {"ship":13, "increment": 20, "payTable": 105, "weight": 95},
                {"ship":14, "increment": 25, "payTable": 130, "weight": 10},
                {"ship":15, "increment": 30, "payTable": 160, "weight": 10},
                {"ship":16, "increment": 40, "payTable": 200, "weight": 10},
                {"ship":17, "increment": 50, "payTable": 250, "weight": 20}
            ]
        ]
    },
}
