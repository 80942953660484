// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-20998d6d] {
}
.sm-game-spec-content .flex[data-v-20998d6d] {
  display: flex;
}
.sm-game-spec-content .flex-center[data-v-20998d6d] {
  display: flex;
  justify-content: center;
}
.vgw075-selector-wrapper[data-v-20998d6d] {
  position: relative;
  flex-direction: row;
  align-items: center;
  top: 3px;
  padding: 15px 0;
  margin: 10px;
  margin-bottom: 15px;
  border: 1px solid #444;
  font-size: 0.85em;
  color: #999;
}
.vgw075-selector-wrapper select[data-v-20998d6d] {
  background-color: #383838;
  height: 29px;
  overflow: hidden;
  color: #888;
  font-size: 1em;
  border: 1px solid #00c700;
  border-radius: 5px;
  top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw075/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[".sm-game-spec-content[data-v-20998d6d] {\n}\n.sm-game-spec-content .flex[data-v-20998d6d] {\n  display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-20998d6d] {\n  display: flex;\n  justify-content: center;\n}\n.vgw075-selector-wrapper[data-v-20998d6d] {\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  top: 3px;\n  padding: 15px 0;\n  margin: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #444;\n  font-size: 0.85em;\n  color: #999;\n}\n.vgw075-selector-wrapper select[data-v-20998d6d] {\n  background-color: #383838;\n  height: 29px;\n  overflow: hidden;\n  color: #888;\n  font-size: 1em;\n  border: 1px solid #00c700;\n  border-radius: 5px;\n  top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
