/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "modelId" : "vgw018",
      "gameId" : null,
      "reelsLayout" : [ 3, 3, 3, 3, 3 ],
      "scatterSymbol" : "SCAT",
      "wildSymbol" : "WILD",
      "bonusSymbol" : null,
      "freeSpinTrigger" : "SCAT",
      "countToTriggerFreeSpin" : 3,
      "freeSpinMultiplier" : 0,
      "freeSpinCount" : 10,
      "winTable" : [ {
        "symbol" : "PIC1",
        "multipliers" : [ 300, 100, 10 ]
      }, {
        "symbol" : "PIC2",
        "multipliers" : [ 200, 100, 10 ]
      }, {
        "symbol" : "PIC3",
        "multipliers" : [ 200, 100, 10 ]
      }, {
        "symbol" : "PIC4",
        "multipliers" : [ 200, 100, 5 ]
      }, {
        "symbol" : "PIC5",
        "multipliers" : [ 150, 50, 5 ]
      }, {
        "symbol" : "A",
        "multipliers" : [ 100, 20, 5 ]
      }, {
        "symbol" : "K",
        "multipliers" : [ 100, 20, 5 ]
      }, {
        "symbol" : "Q",
        "multipliers" : [ 100, 20, 5 ]
      }, {
        "symbol" : "J",
        "multipliers" : [ 50, 10, 5 ]
      }, {
        "symbol" : "10",
        "multipliers" : [ 50, 10, 5 ]
      }, {
        "symbol" : "9",
        "multipliers" : [ 50, 10, 5 ]
      }, {
        "symbol" : "SCAT",
        "multipliers" : [ 100, 20, 5, 2 ]
      }, {
        "symbol" : "WILD",
        "multipliers" : [ 1000, 500, 50, 5 ]
      } ],
      "featureWinTable" : null,
      "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ], [ 0, 0, 2, 0, 0 ], [ 2, 2, 0, 2, 2 ], [ 0, 0, 2, 2, 2 ], [ 2, 2, 0, 0, 0 ], [ 2, 2, 2, 2, 1 ], [ 0, 0, 0, 0, 1 ], [ 0, 0, 0, 0, 2 ], [ 0, 2, 2, 2, 2 ], [ 1, 1, 1, 1, 0 ], [ 1, 1, 1, 1, 2 ], [ 1, 1, 1, 0, 2 ], [ 1, 1, 1, 2, 0 ], [ 0, 0, 0, 2, 1 ], [ 2, 2, 2, 0, 1 ], [ 2, 2, 2, 1, 0 ], [ 0, 0, 0, 1, 2 ], [ 2, 2, 2, 0, 2 ], [ 0, 0, 0, 2, 0 ], [ 1, 1, 0, 2, 1 ], [ 1, 1, 2, 0, 1 ], [ 1, 2, 2, 0, 2 ], [ 0, 2, 2, 0, 0 ], [ 2, 0, 2, 2, 1 ], [ 0, 2, 0, 0, 1 ], [ 2, 2, 1, 2, 1 ] ],
      "reels" : [ [ "PIC2", "K", "PIC3", "J", "PIC4", "Q", "10", "PIC5", "K", "SCAT", "10", "PIC3", "9", "PIC1", "J", "PIC4", "K", "PIC1", "A", "PIC5", "Q", "PIC2", "A", "PIC1", "J", "10", "WILD", "J", "PIC5", "10" ], [ "PIC4", "Q", "PIC2", "K", "SCAT", "Q", "PIC5", "9", "PIC1", "Q", "9", "PIC1", "J", "10", "PIC3", "K", "10", "PIC1", "J", "K", "PIC3", "9", "WILD", "K", "PIC4", "9", "PIC5", "K", "PIC2", "A" ], [ "A", "PIC2", "J", "PIC1", "A", "PIC4", "J", "SCAT", "K", "PIC5", "9", "WILD", "A", "PIC2", "Q", "10", "PIC4", "9", "J", "PIC5", "A", "Q", "PIC3", "J", "10", "PIC1", "9", "Q", "WILD", "K" ], [ "9", "SCAT", "Q", "PIC5", "J", "PIC1", "PIC3", "K", "PIC4", "9", "WILD", "A", "10", "PIC3", "Q", "PIC4", "J", "PIC5", "A", "PIC4", "K", "PIC2", "10", "PIC1", "9", "WILD", "A", "PIC2", "J", "10" ], [ "9", "10", "K", "PIC2", "9", "A", "WILD", "Q", "10", "PIC3", "K", "PIC4", "9", "10", "PIC1", "9", "PIC2", "A", "PIC4", "Q", "9", "SCAT", "10", "K", "PIC5", "J", "10", "WILD", "Q", "J" ] ],
      "featureReels" : [ [ "PIC5", "PIC5", "PIC5", "PIC5", "WILD", "WILD", "WILD", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "PIC4", "PIC4", "SCAT", "PIC4", "PIC3", "PIC3", "PIC3", "PIC5", "PIC5", "PIC5", "PIC5" ], [ "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC4", "PIC4", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "PIC2", "WILD", "WILD", "WILD", "PIC5", "PIC5", "PIC5", "PIC2", "SCAT", "PIC2", "PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1" ], [ "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "WILD", "WILD", "WILD", "PIC4", "PIC4", "PIC4", "PIC4", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC4" ], [ "PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "PIC5", "WILD", "WILD", "WILD", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "SCAT", "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC3", "PIC2" ], [ "WILD", "WILD", "WILD" ] ],
      "featureReelsStops" : null,
      "defaultSelectLine" : 50,
      "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
      "coinType4Values" : [ 1, 2, 5, 10, 50 ],
      "coinType1_min_bet" : 250,
      "coinType1_max_bet" : 100000,
      "coinType1_spin_max_bet" : 5000000,
      "coinType4_min_bet" : 1,
      "coinType4_max_bet" : 50,
      "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
      }, {
        "name" : "normal2",
        "threshold" : 15
      }, {
        "name" : "normal3",
        "threshold" : 25
      }, {
        "name" : "bigwin",
        "threshold" : 50
      }, {
        "name" : "megawin",
        "threshold" : 150
      }, {
        "name" : "epicwin",
        "threshold" : 2147483647
      } ],
      "coinType4_spin_max_bet" : 2500,
      "flavor" : "93",
      "currency_symbol" : "$",
      "normal_count" : "2"
    },
    "modelId" : "vgw018",
    "gameSessionId" : 1,
    "reelStripPositions" : [ 18, 2, 22, 1, 29 ],
    "slotWindow" : [ [ "A", "PIC5", "Q" ], [ "PIC2", "K", "SCAT" ], [ "PIC3", "J", "10" ], [ "SCAT", "Q", "PIC5" ], [ "J", "9", "10" ] ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 206529343,
    "sweeps_deposit_balance" : 200001395,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 6527948,
    "sweeps_available_balance" : 6527948,
    "serverTime" : "2018-02-21 13:09:43"
};
