var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sub-title-menu",
          staticStyle: { "padding-top": "10px" },
        },
        [
          _c("div", [
            _c("div"),
            _vm._v(" "),
            _vm.showBaseBonusSelector
              ? _c("div", [
                  _c(
                    "span",
                    { staticStyle: { color: "#999", "padding-left": "10px" } },
                    [_vm._v("Base Bonus: ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "select-style",
                      staticStyle: {
                        display: "inline-block",
                        "vertical-align": "middle",
                      },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.substitutionBaseBonus,
                              expression: "substitutionBaseBonus",
                            },
                          ],
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.substitutionBaseBonus = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.doChangeBaseBonus()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.baseBonuses, function (bonus) {
                          return _c(
                            "option",
                            { key: bonus, domProps: { value: { bonus } } },
                            [_vm._v(_vm._s(bonus))]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "padding-top": "10px" } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.showFeatureBonusSelector
                ? _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#999", "padding-left": "10px" },
                      },
                      [_vm._v("Feature Bonus: ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select-style",
                        staticStyle: {
                          display: "inline-block",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.substitutionFeatureBonus,
                                expression: "substitutionFeatureBonus",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.substitutionFeatureBonus = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.doChangeFeatureBonus()
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.featureBonuses, function (featureBonus) {
                            return _c(
                              "option",
                              {
                                key: featureBonus,
                                domProps: { value: { featureBonus } },
                              },
                              [_vm._v(_vm._s(featureBonus))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }