import { baseModelDefinition } from "./base";

export const modelDefinition = {
  base: baseModelDefinition,

  maxRespinFeatures: 5,
  triggerCoinsCount: 5,
  coinAmountMultiplier: 20,
  validCoinAmounts: {
    SC: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2500],
    GC: [20, 40, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000],
  },

  // prettier-ignore
  reels: [
      ["PIC6", "PIC1", "Q", "M", "M", "M", "M", "M", "PIC5", "PIC6", "PIC3", "PIC5", "A", "PIC1", "PIC3", "J", "PIC5", "WILD", "J", "PIC2", "PIC5", "Q", "PIC2", "J", "PIC3", "PIC1", "Q", "A", "WILD", "Q", "A", "PIC3", "SCAT", "J", "PIC6", "A", "J", "Q", "K", "A", "J", "M", "M", "M", "M", "M", "A", "PIC3", "PIC6", "J", "PIC5", "PIC4", "SCAT", "PIC6", "PIC3", "J", "A", "PIC2", "J"],
      ["PIC6", "K", "WILD", "PIC6", "J", "K", "PIC4", "PIC1", "K", "PIC4", "PIC1", "M", "M", "M", "M", "M", "PIC6", "K", "PIC5", "A", "K", "PIC4", "J", "K", "PIC5", "PIC1", "M", "M", "M", "M", "M", "A", "K", "PIC2", "PIC6", "A", "K", "PIC6", "K", "WILD", "J", "Q", "PIC5", "SCAT", "J", "PIC5", "K", "Q", "PIC2", "PIC4", "Q", "PIC5", "SCAT", "K", "PIC3", "Q", "PIC2", "Q", "J"],
      ["SCAT", "Q", "PIC1", "K", "SCAT", "PIC5", "Q", "PIC5", "PIC3", "PIC6", "PIC5", "M", "M", "M", "M", "M", "PIC6", "A", "PIC2", "PIC6", "PIC1", "A", "WILD", "PIC4", "A", "Q", "PIC2", "A", "PIC6", "PIC5", "Q", "PIC2", "PIC5", "PIC3", "Q", "J", "Q", "PIC4", "PIC6", "J", "Q", "PIC6", "PIC6", "PIC4", "Q", "M", "M", "M", "M", "M", "PIC1", "A", "PIC2", "Q", "A", "WILD", "PIC6", "J", "J", "PIC3"],
      ["PIC1", "K", "PIC6", "PIC4", "K", "PIC6", "PIC2", "Q", "J", "SCAT", "PIC5", "J", "M", "M", "M", "M", "M", "A", "PIC3", "PIC6", "PIC2", "A", "PIC4", "J", "Q", "WILD", "K", "PIC6", "K", "PIC1", "PIC5", "PIC6", "PIC4", "M", "M", "M", "M", "M", "Q", "PIC5", "Q", "J", "PIC6", "SCAT", "J", "PIC4", "PIC4", "J", "PIC5", "K", "PIC1", "PIC3", "A", "PIC2", "A", "K", "J", "Q", "WILD", "J"]        ,
      ["PIC3", "J", "PIC2", "PIC6", "PIC5", "A", "PIC6", "PIC5", "Q", "PIC3", "J", "PIC2", "PIC3", "J", "K", "PIC3", "PIC6", "PIC1", "A", "A", "PIC4", "J", "PIC5", "A", "PIC1", "PIC3", "A", "M", "M", "M", "M", "M", "PIC5", "J", "A", "SCAT", "PIC6", "PIC2", "PIC1", "Q", "PIC4", "Q", "PIC6", "WILD", "J", "A", "Q", "M", "M", "M", "M", "M", "A", "SCAT", "J", "PIC4", "WILD", "PIC1", "PIC3", "PIC6"],
  ],

  winTable: [
    {
      symbol: "WILD",
      multipliers: [500, 200, 100],
    },
    {
      symbol: "PIC1",
      multipliers: [250, 100, 40],
    },
    {
      symbol: "PIC2",
      multipliers: [200, 80, 30],
    },
    {
      symbol: "PIC3",
      multipliers: [100, 60, 20],
    },
    {
      symbol: "PIC4",
      multipliers: [80, 40, 15],
    },
    {
      symbol: "PIC5",
      multipliers: [60, 30, 12],
    },
    {
      symbol: "PIC6",
      multipliers: [40, 20, 10],
    },
    {
      symbol: "A",
      multipliers: [20, 10, 4],
    },
    {
      symbol: "K",
      multipliers: [20, 10, 4],
    },
    {
      symbol: "Q",
      multipliers: [20, 10, 4],
    },
    {
      symbol: "J",
      multipliers: [20, 10, 4],
    },
  ],

  scatterWinTable: [
    {
      symbol: "SCAT",
      multipliers: [0],
    },
  ],

  wildSymbol: "WILD",
  scatterSymbol: "SCAT",
  mysterySymbol: "M",

  winLevels: [
    { name: "normal1", threshold: 2 },
    { name: "normal2", threshold: 5 },
    { name: "normal3", threshold: 20 },
    { name: "bigwin", threshold: 35 },
    { name: "megawin", threshold: 70 },
    { name: "epicwin" },
  ],

  mysterySymbolWeights: [
    { outcome: "WILD", weight: 1 },
    { outcome: "PIC1", weight: 2 },
    { outcome: "PIC2", weight: 5 },
    { outcome: "PIC3", weight: 10 },
    { outcome: "PIC4", weight: 30 },
    { outcome: "PIC5", weight: 5 },
    { outcome: "PIC6", weight: 12 },
    { outcome: "A", weight: 30 },
    { outcome: "K", weight: 35 },
    { outcome: "Q", weight: 35 },
    { outcome: "J", weight: 35 },
  ],

  genieBonusTriggerWeights: [
    [
      { outcome: 0, weight: 129 },
      { outcome: 1, weight: 1 },
    ],
    [
      { outcome: 0, weight: 88 },
      { outcome: 1, weight: 1 },
      { outcome: 2, weight: 1 },
    ],
    [
      { outcome: 1, weight: 29 },
      { outcome: 2, weight: 16 },
      { outcome: 3, weight: 7 },
    ],
    [
      { outcome: 1, weight: 10 },
      { outcome: 2, weight: 10 },
      { outcome: 3, weight: 10 },
    ],
    [
      { outcome: 1, weight: 2 },
      { outcome: 2, weight: 9 },
      { outcome: 3, weight: 6 },
    ],
  ],

  coinPrizes: {
    TRIGGER: [
      { outcome: 5, weight: 390000 },
      { outcome: 10, weight: 450000 },
      { outcome: 20, weight: 360000 },
      { outcome: 40, weight: 240000 },
      { outcome: 60, weight: 150000 },
      { outcome: 80, weight: 120000 },
      { outcome: 100, weight: 30000 },
      { outcome: 200, weight: 6000 },
      { outcome: 400, weight: 6000 },
      { outcome: "JACKPOT_MINI" as const, weight: 3000 },
      { outcome: "JACKPOT_MINOR" as const, weight: 1500 },
      { outcome: "FEATURE" as const, weight: 0 },
      { outcome: "JACKPOT_MAJOR" as const, weight: "COIN_WEIGHT" as const },
    ],
    RESPIN: [
      { outcome: 5, weight: 780000 },
      { outcome: 10, weight: 900000 },
      { outcome: 20, weight: 720000 },
      { outcome: 40, weight: 480000 },
      { outcome: 60, weight: 300000 },
      { outcome: 80, weight: 240000 },
      { outcome: 100, weight: 60000 },
      { outcome: 200, weight: 12000 },
      { outcome: 400, weight: 12000 },
      { outcome: "JACKPOT_MINI" as const, weight: 6000 },
      { outcome: "JACKPOT_MINOR" as const, weight: 3000 },
      { outcome: "FEATURE" as const, weight: 210000 },
      { outcome: "JACKPOT_MAJOR" as const, weight: "COIN_WEIGHT" as const },
    ],
    COIN_ONLY: [
      { outcome: 5, weight: 390000 },
      { outcome: 10, weight: 450000 },
      { outcome: 20, weight: 360000 },
      { outcome: 40, weight: 240000 },
      { outcome: 60, weight: 150000 },
      { outcome: 80, weight: 120000 },
      { outcome: 100, weight: 30000 },
      { outcome: 200, weight: 6000 },
      { outcome: 400, weight: 6000 },
      { outcome: "JACKPOT_MINI" as const, weight: 3000 },
      { outcome: "JACKPOT_MINOR" as const, weight: 1500 },
    ],
  },

  genieBonusLampWeights: [
    [
      {
        outcome: ["G"],
        weight: 1,
      },
      {
        outcome: ["P"],
        weight: 1,
      },
      {
        outcome: ["R"],
        weight: 1,
      },
    ],
    [
      {
        outcome: ["G", "P"],
        weight: 1,
      },
      {
        outcome: ["G", "R"],
        weight: 1,
      },
      {
        outcome: ["P", "R"],
        weight: 1,
      },
    ],
    [
      {
        outcome: ["G", "P", "R"],
        weight: 1,
      },
    ],
  ],

  coinWeights: {
    easy: {
      chance: 1,
      outOf: 12,
    },
    hard: {
      chance: "COIN_WEIGHT" as const,
      outOf: 9481832,
    },
  },

  jackpots: {
    fixedJackpots: {
      MINI: 500,
      MINOR: 2000,
    },
  },
  genieGreenFeatureNumberOfCoinPrizesWeights: [
    { outcome: 3, weight: 20 },
    { outcome: 4, weight: 20 },
    { outcome: 5, weight: 20 },
    { outcome: 6, weight: 10 },
    { outcome: 7, weight: 3 },
    { outcome: 8, weight: 2 },
    { outcome: 9, weight: 1 },
    { outcome: 10, weight: 1 },
  ],
  genieGreenFeatureCoinPrizeWeights: [
    { outcome: 5, weight: 5 },
    { outcome: 10, weight: 5 },
    { outcome: 20, weight: 10 },
    { outcome: 30, weight: 10 },
    { outcome: 40, weight: 10 },
    { outcome: 50, weight: 5 },
    { outcome: 60, weight: 5 },
    { outcome: 80, weight: 5 },
    { outcome: 100, weight: 5 },
    { outcome: 150, weight: 1 },
    { outcome: 200, weight: 1 },
    { outcome: 300, weight: 1 },
  ],
  genieGreenFeatureJackpotTriggerWeights: {
    chance: 1,
    outOf: 18,
  },
  genieGreenFeatureFixedJackpotWeights: [
    { outcome: "JACKPOT_MINI" as const, weight: 5 },
    { outcome: "JACKPOT_MINOR" as const, weight: 1 },
  ],
  purpleFeatureMultiplier: 2,
  numberOfCoinsToTriggerGrand: 15,
};
