/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
    "modelId" : "vgw024",
        "reelsLayout" : [ 4, 4, 4, 4, 4 ],
        "scatterSymbol" : "SCAT",
        "wildSymbol" : "WILD",
        "freeSpinMultiplier" : 0,
        "freeSpinCount" : 15,
        "winTable" : [ {
        "symbol" : "pic1",
        "multipliers" : [ 250, 100, 25 ]
    }, {
        "symbol" : "pic2",
        "multipliers" : [ 250, 100, 25 ]
    }, {
        "symbol" : "pic3",
        "multipliers" : [ 200, 50, 10 ]
    }, {
        "symbol" : "pic4",
        "multipliers" : [ 200, 50, 10 ]
    }, {
        "symbol" : "A",
        "multipliers" : [ 150, 30, 10 ]
    }, {
        "symbol" : "K",
        "multipliers" : [ 150, 30, 5 ]
    }, {
        "symbol" : "Q",
        "multipliers" : [ 150, 25, 5 ]
    }, {
        "symbol" : "j",
        "multipliers" : [ 100, 25, 5 ]
    }, {
        "symbol" : "10",
        "multipliers" : [ 100, 10, 5 ]
    }, {
        "symbol" : "9",
        "multipliers" : [ 100, 10, 5 ]
    }, {
        "symbol" : "SCAT",
        "multipliers" : [ 50, 20, 5 ]
    } ],
        "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 2, 2, 2, 2, 2 ], [ 0, 0, 0, 0, 0 ], [ 3, 3, 3, 3, 3 ], [ 0, 0, 1, 1, 1 ], [ 1, 1, 0, 0, 0 ], [ 0, 0, 0, 1, 1 ], [ 1, 1, 1, 0, 0 ], [ 1, 1, 2, 2, 2 ], [ 2, 2, 1, 1, 1 ], [ 1, 1, 1, 2, 2 ], [ 2, 2, 2, 1, 1 ], [ 2, 2, 3, 3, 3 ], [ 3, 3, 2, 2, 2 ], [ 2, 2, 2, 3, 3 ], [ 3, 3, 3, 2, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 1, 2, 3, 3 ], [ 3, 3, 2, 1, 1 ], [ 0, 1, 2, 1, 2 ], [ 2, 1, 0, 1, 0 ], [ 0, 1, 0, 1, 2 ], [ 2, 1, 2, 1, 0 ], [ 1, 2, 3, 2, 3 ], [ 3, 2, 1, 2, 1 ], [ 1, 2, 1, 2, 3 ], [ 3, 2, 3, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 1, 0, 0, 0, 1 ], [ 1, 2, 2, 2, 1 ], [ 2, 1, 1, 1, 2 ], [ 2, 3, 3, 3, 2 ], [ 3, 2, 2, 2, 3 ], [ 0, 1, 0, 1, 0 ], [ 1, 0, 1, 0, 1 ], [ 1, 2, 1, 2, 1 ], [ 2, 1, 2, 1, 2 ], [ 2, 3, 2, 3, 2 ], [ 3, 2, 3, 2, 3 ], [ 0, 1, 0, 2, 0 ], [ 2, 1, 2, 0, 2 ], [ 1, 2, 1, 3, 1 ], [ 3, 2, 3, 1, 3 ], [ 0, 1, 0, 2, 1 ], [ 2, 1, 2, 0, 1 ], [ 1, 2, 1, 3, 2 ], [ 3, 2, 3, 1, 2 ], [ 0, 3, 2, 0, 2 ], [ 3, 0, 1, 3, 1 ] ],
        "reels" : [ [ "9", "pic3", "Q", "pic4", "K", "pic2", "Q", "SCAT", "10", "pic3", "9", "pic1", "K", "Q", "pic1", "j", "pic2", "10", "pic3", "9", "pic4", "Q", "10", "pic2", "j", "pic3", "9", "pic4", "K", "9", "pic1", "pic2", "j", "pic3", "K", "pic4", "Q", "10", "pic2", "pic3", "Q", "pic4", "K", "pic2", "Q", "10", "pic3", "9", "pic1", "K", "pic1", "j", "pic2", "10", "pic3", "9", "pic4", "Q", "pic1", "pic1", "pic1", "pic1", "pic3", "9", "pic4", "K", "pic2", "A" ], [ "pic4", "K", "pic3", "9", "j", "pic1", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic3", "K", "pic4", "Q", "A", "pic3", "9", "pic4", "K", "Q", "pic1", "10", "K", "pic1", "10", "pic3", "K", "A", "pic1", "K", "pic3", "10", "pic4", "9", "WILD", "WILD", "WILD", "WILD" ], [ "9", "pic3", "10", "pic4", "K", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic4", "Q", "j", "pic4", "9", "pic1", "K", "pic2", "j", "pic3", "9", "pic4", "10", "9", "pic1", "j", "K", "A", "pic1", "9", "pic4", "Q", "pic2", "j", "pic4", "10", "pic3", "WILD", "WILD", "WILD", "WILD" ], [ "pic1", "Q", "pic4", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic3", "K", "j", "pic4", "K", "pic1", "9", "10", "pic2", "pic3", "K", "pic1", "j", "pic2", "Q", "9", "pic3", "Q", "10", "SCAT", "Q", "pic1", "10", "9", "pic2", "K", "Q", "pic3", "K", "Q", "pic3", "10", "A", "pic1", "10", "9", "pic2", "K", "pic3", "K", "Q", "pic3", "10", "A", "9", "WILD", "WILD", "WILD", "WILD", "j" ], [ "A", "Q", "pic3", "K", "pic4", "SCAT", "10", "j", "pic3", "K", "j", "pic4", "K", "pic1", "9", "10", "pic1", "9", "pic2", "Q", "j", "pic4", "Q", "pic4", "j", "pic2", "Q", "9", "pic1", "A", "Q", "pic3", "K", "Q", "pic3", "10", "9", "pic3", "A", "Q", "pic3", "K", "Q", "pic3", "10", "pic3", "WILD", "WILD", "WILD", "WILD" ] ],
        "featureReels" : [ [ "9", "pic3", "Q", "pic4", "K", "pic2", "Q", "SCAT", "10", "pic3", "9", "pic1", "K", "Q", "pic1", "j", "pic2", "10", "pic3", "9", "pic4", "Q", "10", "pic2", "j", "pic3", "9", "pic4", "K", "9", "pic1", "pic2", "j", "pic3", "K", "pic4", "Q", "10", "pic2", "pic3", "Q", "pic4", "K", "pic2", "Q", "10", "pic3", "9", "pic1", "K", "pic1", "j", "pic2", "10", "pic3", "9", "pic4", "Q", "pic1", "pic1", "pic1", "pic1", "pic3", "9", "pic4", "K", "pic2", "A" ], [ "pic4", "K", "pic3", "9", "j", "pic1", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic3", "K", "pic4", "Q", "A", "pic3", "9", "pic4", "K", "Q", "pic1", "10", "K", "pic1", "10", "pic3", "K", "A", "pic1", "K", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD" ], [ "9", "pic3", "10", "pic4", "K", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic4", "Q", "j", "pic4", "9", "pic1", "K", "pic2", "j", "pic3", "9", "pic4", "10", "9", "pic1", "j", "K", "A", "pic1", "9", "pic4", "Q", "pic2", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD" ], [ "pic1", "Q", "pic4", "Q", "SCAT", "10", "pic2", "j", "pic1", "9", "pic3", "K", "j", "pic4", "K", "pic1", "9", "10", "pic2", "pic3", "K", "pic1", "j", "pic2", "Q", "9", "pic3", "Q", "10", "SCAT", "Q", "pic1", "10", "9", "pic2", "K", "Q", "pic3", "K", "Q", "pic3", "10", "A", "pic1", "10", "9", "pic2", "K", "Q", "pic3", "K", "Q", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "j" ], [ "A", "Q", "pic3", "K", "pic4", "SCAT", "10", "j", "pic3", "K", "j", "pic4", "K", "pic1", "9", "10", "pic1", "9", "pic2", "Q", "j", "pic4", "Q", "pic4", "j", "pic2", "Q", "9", "pic1", "A", "Q", "pic3", "K", "Q", "pic3", "10", "9", "pic3", "A", "Q", "pic3", "K", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD", "WILD" ] ],
        "defaultSelectLine" : 0,
        "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values" : [ 1, 2, 5, 10, 50 ],
        "coinType1_min_bet" : 250,
        "coinType1_max_bet" : 100000,
        "coinType1_spin_max_bet" : 5000000,
        "coinType4_min_bet" : 1,
        "coinType4_max_bet" : 50,
        "coinType4_spin_max_bet" : 2500,
        "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
    }, {
        "name" : "normal2",
        "threshold" : 15
    }, {
        "name" : "normal3",
        "threshold" : 25
    }, {
        "name" : "bigwin",
        "threshold" : 50
    }, {
        "name" : "megawin",
        "threshold" : 150
    }, {
        "name" : "epicwin",
        "threshold" : 2147483647
    } ],
        "freeSpinTrigger" : "SCAT",
        "countToTriggerFreeSpin" : 3,
        "featureWildsToBeSubstituted" : {
        "1" : [ 34, 35, 36, 37 ],
            "2" : [ 36, 37, 38, 39 ],
            "3" : [ 53, 54, 55 ],
            "4" : [ 43, 44, 45, 46 ]
    },
    "flavor" : "94",
        "currency_symbol" : "$"
},
    "modelId" : "vgw024",
    "gameSessionId" : 38197,
    "reelStripPositions" : [ 50, 36, 35, 37, 35 ],
    "slotWindow" : [ [ "pic1", "j", "pic2", "10" ], [ "9", "WILD", "WILD", "WILD" ], [ "j", "pic4", "10", "pic3" ], [ "pic3", "K", "Q", "pic3" ], [ "10", "9", "pic3", "A" ] ],
    "gold_balance" : 2000000,
    "sweeps_balance" : 5248180,
    "sweeps_deposit_balance" : 0,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 5248180,
    "sweeps_available_balance" : 5248180,
    "serverTime" : "2018-08-31 07:52:59"
};
