import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import { PositionSchema } from "./common";

export const FeatureColoursSchema = Type.Union([Type.Literal("G"), Type.Literal("P"), Type.Literal("R")]);
export type FeatureColour = Static<typeof FeatureColoursSchema>;

export const GenieBonusLamp = Type.Object({
    position: Type.Tuple([Type.Integer(), Type.Integer()]),
    colour: FeatureColoursSchema,
});
export type GenieBonusLamp = Static<typeof GenieBonusLamp>;

export const GenieBonusLamps = Type.Array(GenieBonusLamp);
export type GenieBonusLamps = Static<typeof GenieBonusLamps>;

const GreenFeatureSchema = Type.Object({
    type: Type.Literal("FEATURE"),
    colour: Type.Literal("G"),
    value: Type.Integer(),
    position: PositionSchema,
    multiplierApplied: Type.Optional(Type.Integer()),
    prizes: Type.Array(
        Type.Union([
            Type.Object({
                type: Type.Union([Type.Literal("COIN")]),
                prize: Type.Integer(),
            }),
            Type.Object({
                type: Type.Union([Type.Literal("JACKPOT_MINI"), Type.Literal("JACKPOT_MINOR")]),
                prize: Type.Integer(),
            }),
        ]),
    ),
});
const PurpleFeatureSchema = Type.Object({
    type: Type.Literal("FEATURE"),
    colour: Type.Literal("P"),
    prize: Type.Union([
        Type.Integer(),
        Type.Literal("JACKPOT_MINI"),
        Type.Literal("JACKPOT_MINOR"),
        Type.Literal("JACKPOT_MAJOR"),
    ]),
    value: Type.Integer(),
    position: PositionSchema,
    multiplierApplied: Type.Optional(Type.Integer()),
});
const RedFeatureSchema = Type.Object({
    type: Type.Literal("FEATURE"),
    colour: Type.Literal("R"),
    value: Type.Integer(),
    position: PositionSchema,
    multiplierApplied: Type.Optional(Type.Integer()),
});
export const FeatureSchema = Type.Union([GreenFeatureSchema, PurpleFeatureSchema, RedFeatureSchema]);
export type Feature = Static<typeof FeatureSchema>;

export const CoinPrizeSchema = Type.Optional(
    Type.Union([
        Type.Object({
            type: Type.Union([Type.Literal("COIN"), Type.Literal("JACKPOT_MINI"), Type.Literal("JACKPOT_MINOR")]),
            value: Type.Integer(),
            position: PositionSchema,
            multiplierApplied: Type.Optional(Type.Integer()),
        }),
        Type.Object({
            type: Type.Literal("JACKPOT_MAJOR"),
            position: PositionSchema,
        }),
        FeatureSchema,
    ]),
);

export const CoinPrizesSchema = Type.Optional(Type.Array(CoinPrizeSchema));

export type CoinPrizes = Static<typeof CoinPrizesSchema>;

export type CoinPrize = Static<typeof CoinPrizeSchema>;

export const GenieBonusFeatureSchema = Type.Object({
    position: PositionSchema,
    colour: FeatureColoursSchema,
});
export type GenieBonusFeature = Static<typeof GenieBonusFeatureSchema>;

export const GenieBonusFeaturesSchema = Type.Array(GenieBonusFeatureSchema);
export type GenieBonusFeatures = Static<typeof GenieBonusFeaturesSchema>;

export const GenieBonusPhaseSchema = Type.Union([
    Type.Literal("START"),
    Type.Literal("IN_PROGRESS"),
    Type.Literal("END"),
]);

export const JackpotWinCounts = Type.Object({
    GRAND: Type.Integer(),
    MAJOR: Type.Integer(),
});
export type JackpotWinCounts = Static<typeof JackpotWinCounts>;

export type GenieBonusPhase = Static<typeof GenieBonusPhaseSchema>;
