/* in order to support standalone, please insert the init response from the server here */
export const base = {
  "modelId": "vgw047",
  "scatterSymbol": "SCAT",
  "wildSymbol": "WILD",
  "freeSpinTrigger": "SCAT",
  "countToTriggerFreeSpin": 3,
  "jackpotFeatureLoopCount": 3,
  "betMultiplier" :50,
  "coinSymbol": "COIN",
  "coinCountToTriggerFeature": 8,
  "revealSymbolDefined": "REVEAL",

  "reelsLayout": [4, 4, 4, 4, 4],
  "percentToJackpotPool": 1.0,

  "winTable": [
    {
      "symbol" : "P1",
      "multipliers" : [750, 150, 30, 10]
    },
    {
      "symbol" : "P2",
      "multipliers" : [500, 100, 25, 8]
    },
    {
      "symbol" : "P3",
      "multipliers" : [400, 80, 20]
    },
    {
      "symbol" : "P4",
      "multipliers" : [300, 75, 15]
    },
    {
      "symbol" : "P5",
      "multipliers" : [200, 60, 12]
    },
    {
      "symbol" : "A",
      "multipliers" : [150, 50, 10]
    },
    {
      "symbol" : "K",
      "multipliers" : [120, 40, 8]
    },
    {
      "symbol" : "Q",
      "multipliers" : [90, 30, 6]
    },
    {
      "symbol" : "J",
      "multipliers" : [60, 20, 4]
    },
    {
      "symbol" : "10",
      "multipliers" : [40, 15, 4]
    }
  ],

  "coinType1Values": [ 250, 1250, 5000, 25000, 50000],
  "coinType4Values": [ 1, 2, 5, 10, 30],

  "coinType1_min_bet": "250",
  "coinType1_max_bet": "50000",
  "coinType1_spin_max_bet": "2500000",

  "coinType4_min_bet": "1",
  "coinType4_max_bet": "30",
  "coinType4_spin_max_bet": "1500",

  "initialJackpotAmount":{
    "SWEEPS":{
      "MAJOR":50000,
      "GRAND":25000000
    },

    "GOLD":{
      "MAJOR":50000,
      "GRAND":25000000
    }
  },


  
  "coinPricesSweeps": {
    "jackpotTypes": ["MAJOR", "MINOR", "MINI"],
    "prices": [0, 100, 50, 0, 0, 20, 10, 5, 4, 3, 2, 1],
    "fireShotCoinTypes": ["JACKPOT", "JACKPOT", "JACKPOT", "COLLECT", "UPGRADE"],
    "weights": {
      "0": {
          //base Game
        "1":[500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "2":[1000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "5":[2500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "10":[5000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "30":[15000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000]
      },
      "1": {
        //feature Game
        "1":[500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "2":[1000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "5":[2500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "10":[5000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "30":[15000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000]
      }

    }
  },



  "coinPricesGold":{
    "jackpotTypes": ["MAJOR", "MINOR", "MINI"],
    "prices": [0, 100, 50, 0, 0, 20, 10, 5, 4, 3, 2, 1],
    "fireShotCoinTypes": ["JACKPOT", "JACKPOT", "JACKPOT", "COLLECT", "UPGRADE"],
    "weights": {
      "0": {
        //base Game
        "250":[500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "1250":[1000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "5000":[2500, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "25000":[15000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000],
        "50000":[25000, 200000, 400000, 0, 0, 50000, 50000, 100000, 1000000, 1500000, 4500000, 7900000]
      },
      "1": {
        //feature Game
        "250":[500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "1250":[1000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "5000":[2500, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "25000":[15000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000],
        "50000":[25000, 20000, 40000, 90000, 450000, 50000, 50000, 100000, 1000000, 1500000, 4500000, 9900000]
      }
    }
  },

  "coinChanceWeights": {
    "SWEEPS": [14, 14, 14, 14, 14, 11, 11, 11, 11, 11, 11, 10000000],
    "GOLD":  [14, 14, 14, 14, 14, 11, 11, 11, 11, 11, 11, 10000000]
  },


  "reels": [
    ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P2","P3","K","P4","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","K","Q","A","P5","10","P2","P4","K","J","Q","10","P5","J","P4","K","P5","Q","J","A","10","P4","J","P2","Q","P3","K","P5","SCAT","WILD","10","J","P1","K","Q","COIN","COIN","COIN","COIN"],
    ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P1","10","A","P5","10","A","J","P5","WILD","10","Q","J","P3","P2","10","10","Q","J","K","P5","J","P4","K","Q","10","J","P3","P4","A","P5","10","SCAT","J","Q","A","COIN","COIN","COIN","COIN"],
    ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","A","Q","10","K","P5","SCAT","J","10","A","Q","10","Q","P4","J","SCAT","10","P3","J","P5","A","K","Q","WILD","10","P2","P4","K","J","A","P3","P5","Q","10","P4","P1","COIN","COIN","COIN","COIN"],
    ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","J","Q","10","J","P3","P4","A","P5","K","SCAT","J","Q","A","K","P2","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","Q","K","P4","P5","J","10","P5","J","P4","K","P5","10","A","J","P5","WILD","10","COIN","COIN","COIN","COIN"],
    ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P5","A","Q","P2","P3","10","K","J","Q","10","A","P1","P4","Q","10","K","J","SCAT","Q","P5","P1","10","A","Q","P3","WILD","P4","10","K","J","P4","J","K","10","P5","Q","J","K","10","P2","J","A","Q","P4","P5","J","10","P3","A","COIN","COIN","COIN","COIN"]
  ],
  "fsFeatureReels":
  {
    "8":[
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P2","P3","K","P4","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","K","Q","A","P5","10","P2","P4","K","J","Q","10","P5","J","P4","K","P5","Q","J","A","10","P4","J","P2","Q","P3","K","P5","SCAT","WILD","10","J","P1","K","Q","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P1","10","A","P5","10","A","J","P5","WILD","10","Q","J","P3","P2","10","10","Q","J","K","P5","J","P4","K","Q","10","J","P3","P4","A","P5","10","SCAT","J","Q","A","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","A","Q","10","K","P5","SCAT","J","10","A","Q","10","Q","P4","J","SCAT","10","P3","J","P5","A","K","Q","WILD","10","P2","P4","K","J","A","P3","P5","Q","10","P4","P1","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","J","Q","10","J","P3","P4","A","P5","K","SCAT","J","Q","A","K","P2","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","Q","K","P4","P5","J","10","P5","J","P4","K","P5","10","A","J","P5","WILD","10","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P5","A","Q","P2","P3","10","K","J","Q","10","A","P1","P4","Q","10","K","J","SCAT","Q","P5","P1","10","A","Q","P3","WILD","P4","10","K","J","P4","J","K","10","P5","Q","J","K","10","P2","J","A","Q","P4","P5","J","10","P3","A","COIN","COIN","COIN","COIN"]
    ],

    "15":[
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P2","P3","K","P4","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","K","Q","A","P5","10","P2","P4","K","J","Q","10","P5","J","P4","K","P5","Q","J","A","10","P4","J","P2","Q","P3","K","P5","SCAT","WILD","10","J","P1","K","Q","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P1","10","A","P5","10","A","J","P5","WILD","10","Q","J","P3","P2","10","10","Q","J","K","P5","J","P4","K","Q","10","J","P3","P4","A","P5","10","SCAT","J","Q","A","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","A","Q","10","K","P5","SCAT","J","10","A","Q","10","Q","P4","J","SCAT","10","P3","J","P5","A","K","Q","WILD","10","P2","P4","K","J","A","P3","P5","Q","10","P4","P1","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","J","Q","10","J","P3","P4","A","P5","K","SCAT","J","Q","A","K","P2","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","Q","K","P4","P5","J","10","P5","J","P4","K","P5","10","A","J","P5","WILD","10","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P5","A","Q","P2","P3","10","K","J","Q","10","A","P1","P4","Q","10","K","J","SCAT","Q","P5","P1","10","A","Q","P3","WILD","P4","10","K","J","P4","J","K","10","P5","Q","J","K","10","P2","J","A","Q","P4","P5","J","10","P3","A","COIN","COIN","COIN","COIN"]
    ],

    "25":[
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P2","P3","K","P4","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","K","Q","A","P5","10","P2","P4","K","J","Q","10","P5","J","P4","K","P5","Q","J","A","10","P4","J","P2","Q","P3","K","P5","SCAT","WILD","10","J","P1","K","Q","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","P1","10","A","P5","10","A","J","P5","WILD","10","Q","J","P3","P2","10","10","Q","J","K","P5","J","P4","K","Q","10","J","P3","P4","A","P5","10","SCAT","J","Q","A","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","A","Q","10","K","P5","SCAT","J","10","A","Q","10","Q","P4","J","SCAT","10","P3","J","P5","A","K","Q","WILD","10","P2","P4","K","J","A","P3","P5","Q","10","P4","P1","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P1","K","A","J","Q","10","J","P3","P4","A","P5","K","SCAT","J","Q","A","K","P2","10","Q","J","K","Q","P1","10","A","J","P3","P2","10","Q","K","P4","P5","J","10","P5","J","P4","K","P5","10","A","J","P5","WILD","10","COIN","COIN","COIN","COIN"],
      ["REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","REVEAL","P5","A","Q","P2","P3","10","K","J","Q","10","A","P1","P4","Q","10","K","J","SCAT","Q","P5","P1","10","A","Q","P3","WILD","P4","10","K","J","P4","J","K","10","P5","Q","J","K","10","P2","J","A","Q","P4","P5","J","10","P3","A","COIN","COIN","COIN","COIN"]
    ]

  }
};

export const m93 = Object.assign({}, base, {
  "percentToJackpotPool": 0.5
});

export const m94 = Object.assign({}, base, {
  "percentToJackpotPool": 1
});

export const m95 = Object.assign({}, base, { 
});

