var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "Reel-feature-selector" }, [
    _c("h2", [_vm._v("Reel Feature Control Panel:")]),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 0
      ? _c("div", [_c("wild-path-controls", { attrs: { step: _vm.step } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 1
      ? _c("div", [_c("candy-cane-controls", { attrs: { step: _vm.step } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 2
      ? _c("div", [_c("nutt-prize-controls", { attrs: { step: _vm.step } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 3
      ? _c(
          "div",
          [_c("stolen-royals-controls", { attrs: { step: _vm.step } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 4
      ? _c("div", [_c("multiplier-controls", { attrs: { step: _vm.step } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 5
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("wild-path-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 6
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("candy-cane-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 7
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("nutt-prize-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 8
      ? _c(
          "div",
          [
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("wild-path-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 9
      ? _c(
          "div",
          [
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("candy-cane-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 10
      ? _c(
          "div",
          [
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("nutt-prize-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 11
      ? _c(
          "div",
          [
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 12
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("wild-path-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 13
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("candy-cane-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.reelSpinFeatureId === 14
      ? _c(
          "div",
          [
            _c("stolen-royals-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("multiplier-controls", { attrs: { step: _vm.step } }),
            _vm._v(" "),
            _c("nutt-prize-controls", { attrs: { step: _vm.step } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }