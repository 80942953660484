// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"modelDefinition": {
		"modelId": "vgw036",
		"reelsLayout": [4, 4, 4, 4, 4],
		"scatterSymbol": "SCAT",
		"wildSymbol": "WILD",
		"freeSpinMultiplier": 0,
		"freeSpinCount": 10,
		"winTable": [{
			"symbol": "WILD",
			"multipliers": [100, 75, 25]
		}, {
			"symbol": "PIC1",
			"multipliers": [100, 75, 25]
		}, {
			"symbol": "PIC2",
			"multipliers": [75, 50, 25]
		}, {
			"symbol": "PIC3",
			"multipliers": [50, 25, 15]
		}, {
			"symbol": "PIC4",
			"multipliers": [40, 20, 10]
		}, {
			"symbol": "A",
			"multipliers": [35, 15, 7]
		}, {
			"symbol": "K",
			"multipliers": [35, 15, 7]
		}, {
			"symbol": "Q",
			"multipliers": [25, 10, 5]
		}, {
			"symbol": "J",
			"multipliers": [25, 10, 5]
		}, {
			"symbol": "10",
			"multipliers": [25, 10, 5]
		}, {
			"symbol": "SCAT",
			"multipliers": []
		}],
		"playLines": [
			[1, 1, 1, 1, 1],
			[2, 2, 2, 2, 2],
			[0, 0, 0, 0, 0],
			[3, 3, 3, 3, 3],
			[1, 2, 3, 2, 1],
			[2, 1, 0, 1, 2],
			[0, 0, 1, 2, 3],
			[3, 3, 2, 1, 0],
			[1, 0, 0, 0, 1],
			[2, 3, 3, 3, 2],
			[0, 1, 2, 3, 3],
			[3, 2, 1, 0, 0],
			[1, 0, 1, 2, 1],
			[2, 3, 2, 1, 2],
			[0, 1, 0, 1, 0],
			[3, 2, 3, 2, 3],
			[1, 2, 1, 0, 1],
			[2, 1, 2, 3, 2],
			[0, 1, 1, 1, 0],
			[3, 2, 2, 2, 3],
			[1, 1, 2, 3, 3],
			[2, 2, 1, 0, 0],
			[1, 1, 0, 1, 1],
			[2, 2, 3, 2, 2],
			[1, 2, 2, 2, 3],
			[2, 1, 1, 1, 0],
			[0, 0, 1, 0, 0],
			[3, 3, 2, 3, 3],
			[0, 1, 2, 2, 3],
			[3, 2, 1, 1, 0],
			[0, 0, 0, 1, 2],
			[3, 3, 3, 2, 1],
			[1, 0, 0, 1, 2],
			[2, 3, 3, 2, 1],
			[0, 1, 1, 2, 3],
			[3, 2, 2, 1, 0],
			[1, 0, 1, 2, 3],
			[2, 3, 2, 1, 0],
			[0, 1, 2, 3, 2],
			[3, 2, 1, 0, 1]
		],
		"reels" : [
		  ["10", "A", "Q", "A", "J", "SCAT", "Q", "A", "K", "PIC2", "PIC2", "PIC2", "PIC2", "Q", "K", "A", "10", "Q", "K", "J", "PIC3", "PIC3", "PIC3", "PIC3", "10", "J", "K", "Q", "SCAT", "10", "Q", "K", "J", "A", "PIC2", "PIC2", "PIC2", "PIC2", "A", "K", "10", "PIC3", "PIC3", "PIC3", "PIC3", "J", "SCAT", "Q", "10", "J", "A", "PIC1", "PIC1", "PIC1", "PIC1", "K", "Q", "K", "J", "10", "PIC4", "PIC4", "PIC4", "PIC4", "10", "SCAT", "10", "J", "10"],
		  ["A", "A", "PIC1", "PIC1", "PIC1", "PIC1", "10", "Q", "A", "K", "PIC2", "PIC2", "PIC2", "PIC2", "K", "10", "J", "Q", "PIC1", "PIC1", "PIC1", "PIC1", "10", "Q", "K", "A", "PIC4", "PIC4", "PIC4", "PIC4", "A", "10", "WILD", "J", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "Q", "A", "J", "PIC4", "PIC4", "PIC4", "PIC4", "A", "10", "WILD", "K", "Q", "PIC2", "PIC2", "PIC2", "PIC2", "10", "K", "Q", "J", "PIC3", "PIC3", "PIC3", "PIC3", "Q", "10", "J", "10"],
		  ["K", "PIC3", "PIC3", "PIC3", "PIC3", "K", "Q", "A", "K", "J", "PIC2", "PIC2", "PIC2", "PIC2", "Q", "A", "J", "SCAT", "K", "J", "WILD", "K", "Q", "PIC4", "PIC4", "PIC4", "PIC4", "K", "Q", "10", "WILD", "PIC1", "PIC1", "PIC1", "PIC1", "A", "A", "SCAT", "K", "J", "WILD", "10", "A", "K", "Q", "PIC4", "PIC4", "PIC4", "PIC4", "K", "WILD", "J", "10", "PIC3", "PIC3", "PIC3", "PIC3", "K", "10", "Q", "SCAT", "J", "J", "10", "PIC4", "PIC4", "PIC4", "PIC4", "K", "10", "PIC1", "PIC1", "PIC1", "PIC1", "10"],
		  ["A", "A", "PIC3", "PIC3", "PIC3", "PIC3", "J", "Q", "K", "A", "PIC2", "PIC2", "PIC2", "PIC2", "10", "K", "J", "Q", "PIC1", "PIC1", "PIC1", "PIC1", "K", "WILD", "10", "A", "PIC4", "PIC4", "PIC4", "PIC4", "A", "WILD", "A", "J", "PIC1", "PIC1", "PIC1", "PIC1", "10", "WILD", "A", "J", "PIC4", "PIC4", "PIC4", "PIC4", "A", "10", "WILD", "K", "J", "Q", "PIC2", "PIC2", "PIC2", "PIC2", "K", "10", "Q", "J"],
		  ["K", "WILD", "A", "PIC2", "PIC2", "PIC2", "PIC2", "10", "K", "SCAT", "PIC1", "PIC1", "PIC1", "PIC1", "10", "J", "A", "PIC4", "PIC4", "PIC4", "PIC4", "Q", "WILD", "J", "10", "PIC3", "PIC3", "PIC3", "PIC3", "10", "A", "K", "J", "PIC1", "PIC1", "PIC1", "PIC1", "10", "WILD", "K", "J", "SCAT", "J", "K", "10", "WILD", "Q", "PIC4", "PIC4", "PIC4", "PIC4", "K", "Q", "K", "SCAT", "10", "PIC3", "PIC3", "PIC3", "PIC3", "Q", "K"]
		],
		"featureReels" : [
		  ["PIC3", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "SCAT", "PIC2", "PIC2", "PIC2", "PIC2", "SCAT", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "SCAT", "PIC3", "PIC3", "PIC3", "PIC3", "PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1", "PIC1", "SCAT", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC4", "SCAT", "PIC2", "PIC2", "PIC2", "PIC2"],
		  ["PIC2", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC4", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "WILD", "PIC3", "PIC3", "PIC3", "PIC3", "PIC2", "PIC2", "PIC2", "PIC2", "PIC4", "PIC4", "PIC4", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "PIC2", "PIC2", "PIC2"],
		  ["PIC1", "PIC3", "PIC3", "PIC3", "PIC3", "PIC4", "PIC4", "PIC4", "PIC4", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1", "PIC1", "SCAT", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1", "PIC1", "SCAT", "PIC2", "PIC2", "PIC2", "PIC2", "WILD", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC4", "PIC2", "PIC2", "PIC2", "PIC2", "PIC3", "PIC3", "PIC3", "PIC3", "SCAT", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "SCAT", "PIC4", "PIC4", "PIC4", "PIC4", "PIC1", "PIC1", "PIC1"],
		  ["PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC3", "PIC3", "PIC3", "PIC3", "PIC2", "PIC2", "PIC2", "PIC2", "PIC4", "PIC4", "PIC4", "PIC4", "WILD", "PIC1", "PIC1", "PIC1", "PIC1", "PIC3", "PIC3", "PIC3", "PIC3", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC4", "PIC4", "PIC4", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "PIC4", "PIC4", "PIC4", "PIC4"],
		  ["PIC2", "PIC2", "PIC2", "PIC2", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "PIC3", "PIC3", "PIC3", "PIC3", "SCAT", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "SCAT", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "PIC4", "PIC4", "PIC4", "SCAT", "PIC2", "PIC2", "PIC2", "PIC2", "PIC3", "PIC3", "PIC3", "PIC3", "WILD", "PIC2", "PIC2", "PIC2", "PIC2", "SCAT", "WILD", "PIC4", "PIC4", "PIC4", "PIC4", "PIC3", "PIC3", "PIC3", "PIC3", "PIC1", "PIC1", "PIC1", "PIC1", "WILD", "SCAT"]
		],
		"defaultSelectLine": 40,
		"coinType1Values": [250, 2500, 10000, 50000, 100000],
		"coinType4Values": [1, 2, 5, 10, 50],
		"coinType1_min_bet": 250,
		"coinType1_max_bet": 100000,
		"coinType1_spin_max_bet": 4000000,
		"coinType4_min_bet": 1,
		"coinType4_max_bet": 50,
		"coinType4_spin_max_bet": 2000,
		"winLevels": [{
			"name": "normal1",
			"threshold": 5
		}, {
			"name": "normal2",
			"threshold": 15
		}, {
			"name": "normal3",
			"threshold": 25
		}, {
			"name": "bigwin",
			"threshold": 50
		}, {
			"name": "megawin",
			"threshold": 150
		}, {
			"name": "epicwin",
			"threshold": 2147483647
		}],
		"freeSpinTrigger": "SCAT",
		"countToTriggerFreeSpin": 3,
		"maxFreeSpinReTriggerCount": 9,
		"currency_symbol": "$"
	},
	"modelId": "vgw036",
	"gameSessionId": 891,
	"reelStripPositions": [26, 64, 23, 42, 50],
	"slotWindow": [
		["K", "Q", "SCAT", "10"],
		["10", "J", "10", "A"],
		["PIC4", "PIC4", "PIC4", "PIC4"],
		["PIC4", "PIC4", "PIC4", "PIC4"],
		["PIC4", "K", "Q", "K"]
	],
	"gold_balance": 10000000,
	"sweeps_balance": 10000000,
	"sweeps_deposit_balance": 10000000,
	"sweeps_promo_balance": 0,
	"sweeps_win_balance": 0,
	"sweeps_available_balance": 0,
	"serverTime": "2019-03-13 18:09:53"
};
