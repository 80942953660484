import { Cell, WaysWin } from "./ways-win-checker";

export type WinCellsForSymbol = {
  symbol: string;
  cells: number[][];
};

export function createVgw051WinCellsBySymbolSummariser(modelDefinition: {
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
}) {
  const groupWinCellsBySymbol = createWaysWinSummariser(modelDefinition);

  // This holds a key for the cells that are a part of the top reel, and provides the new cell that it will be mapped to
  const topReelCellMap: Record<string, Cell> = {
    "0,1": [0, 6],
    "0,2": [1, 6],
    "0,3": [2, 6],
    "0,4": [3, 6],
  };

  return function winCellsBySymbolSummariser(wins: Array<WaysWin>) {
    const winCellsBySymbol = groupWinCellsBySymbol(wins);
    winCellsBySymbol.forEach((WinCellForSymbol) => {
      WinCellForSymbol.cells = WinCellForSymbol.cells
        .map((cell) => {
          // If the cell is in reel 1 or 6 (no top reel), stay the same
          if (cell[1] === 0 || cell[1] === 5) return cell;
          const key = `${cell[0]},${cell[1]}`;

          // If the cell is a part of the top reel return the mapped result,
          // otherwise return cell with a reduced row, to match the cell positions on reels 1 and 6
          const newCell = topReelCellMap[key] ?? [cell[0] - 1, cell[1]];
          return newCell;
        })
        .sort((cellOne, CellTwo) => {
          // Resort the cells array, as change the top reels can put it out of order
          if (cellOne[1] !== CellTwo[1]) return cellOne[1] - CellTwo[1];
          return cellOne[0] - CellTwo[0];
        });
    });
    return winCellsBySymbol;
  };
}

/**
 * Returns an abbreviated version of the given `waysWins` array, which has a single list of winning cells for each
 * winning symbol. The original symbol win cell patterns are not preserved in the abbreviated format.
 * NB: play outcomes for ways games tend to report wins using this abbreviated structure rather than listing every ways
 * win, since in some cases WaysWin arrays can be very large for slot windows with many winning ways.
 */
export function createWaysWinSummariser(options: {
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
}) {
  const symbolOrder = getSortedWinTableSymbols(options);

  /**
   * Groups ways wins by symbol and sorts them as follows:
   * (a) WILD symbol (if present in winTable)
   * (b) SCATTER symbol (if present in winTable)
   * (c) All other symbols listed in the winTable, in descending order of highest multiplier
   */
  return function groupWinCellsBySymbol(
    waysWins: WaysWin[]
  ): WinCellsForSymbol[] {
    if (waysWins.length === 0) return [];

    const cellsBySymbol = new Map<string, Map<number, Cell>>();
    for (const { symbol, cells } of waysWins) {
      let cellsForSymbol = cellsBySymbol.get(symbol);
      if (!cellsForSymbol)
        cellsBySymbol.set(symbol, (cellsForSymbol = new Map()));
      for (const [row, col] of cells) {
        cellsForSymbol.set(row * 1000 + col, [row, col]);
      }
    }

    const result: Array<{ symbol: string; cells: Cell[] }> = [];
    for (const symbol of symbolOrder) {
      const cellsForSymbol = cellsBySymbol.get(symbol);
      if (!cellsForSymbol) continue;
      const cells: Cell[] = [];
      cellsForSymbol.forEach((cell) => cells.push(cell));
      result.push({ symbol, cells });
    }
    return result;
  };
}

function getSortedWinTableSymbols(options: {
  scatterSymbol: string;
  wildSymbol: string;
  winTable?: Array<{
    symbol: string;
    multipliers: number[];
  }>;
}): string[] {
  const { winTable = [], wildSymbol, scatterSymbol } = options;

  // Sort the winTable (clone it first so the original is not modified).
  const sortedWinTable: Array<{
    symbol: string;
    multipliers: number[];
  }> = winTable.slice().sort((s1, s2) => {
    if (s1.symbol === s2.symbol) return 0;

    // WILD (if any) comes first.
    if (wildSymbol) {
      if (s1.symbol === wildSymbol) return -1;
      if (s2.symbol === wildSymbol) return 1;
    }

    // SCAT (if any) comes next.
    if (scatterSymbol) {
      if (s1.symbol === scatterSymbol) return -1;
      if (s2.symbol === scatterSymbol) return 1;
    }

    // Sort the rest by descending order of highest multiplier.
    const m1 = s1.multipliers[0] ?? 0;
    const m2 = s2.multipliers[0] ?? 0;
    return m2 - m1;
  });

  // Return just the symbol names from the sorted winTable.
  return sortedWinTable.map((s) => s.symbol);
}
