/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        getModelDefinition: () =>
            State.state.standalone
                ? init.modelDefinition
                : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep(
                [0, 0, 0, 0, 0],
                false,
                0,
                "",
                "Reels"
            );
            step.baseReelSet = "set1";
            step.rows = 5;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () =>
            State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: (baseReelSet: string) => {
            if (baseReelSet === "set1")
                return State.state.getModelDefinition().reels.set1;
            if (baseReelSet === "set2")
                return State.state.getModelDefinition().reels.set2;
            if (baseReelSet === "set3")
                return State.state.getModelDefinition().reels.set3;
        },
        getFeatureReelStrips: () =>
            State.state.getModelDefinition().reelsFreespin,

        getBaseStep: () => State.state.createDefaultStep(),
        getFreeSpinTriggerFromBaseGame: () => {
            const step = State.state.createStep(
                [159, 53, 41, 101, 208],
                false,
                0,
                "",
                "FS trigger"
            );
            step.typeOfBaseSpinStep = "normal";
            step.baseReelSet = "set2";
            return step;
        },
        getFreeSpinStep: () => {
            const step = State.state.createStep(
                [0, 0, 0, 0, 0],
                true,
                0,
                "",
                "FS step"
            );
            step.isFeature = true;
            return step;
        },
        getRespinTrigger: () => {
            const step = State.state.createStep(
                [174, 143, 155, 171, 214],
                false,
                0,
                "",
                "Respin Trigger"
            );
            step.typeOfBaseSpinStep = "normal";
            step.baseReelSet = "set1";
            return step;
        },
        getRespinStep: () => {
            const step = State.state.createStep(
                [0, 0, 0, 0, 0],
                true,
                0,
                "",
                "Respin step"
            );
            step.isRespin = true;
            return step;
        },
        getRespinAndFreeSpinTrigger: () => {
            const step = State.state.createStep(
                [46, 37, 170, 39, 147],
                false,
                0,
                "",
                "Respin + FS"
            );
            step.typeOfBaseSpinStep = "normal";
            step.baseReelSet = "set2";
            return step;
        },
        getClearingStep: function () {
            const step = State.state.createStep(
                [0, 0, 0, 0, 0],
                false,
                0,
                "",
                "Clear"
            );
            step.gameState = "clear";
            return step;
        },

        prepareStepForDB: (step) => {
            if (
                step.typeOfBaseSpinStep === "cascade" ||
                step.typeOfFreeSpinStep === "cascade" ||
                step.isRespin
            ) {
                step.reelStripPositions = undefined;
            }

            if (step.typeOfFreeSpinStep !== "select") {
                step.numberOfFreeSpins = undefined;
                step.initialMultiplier = undefined;
                step.increaseMultiplier = undefined;
            }

            const featureSymbols = step.featureSymbols;
            if (featureSymbols) {
                let symbolsAdaptedToSlotWindow: string[][] = [];
                for (
                    let i = 0;
                    i < State.state.getModelDefinition().reelsLayout.length;
                    i++
                ) {
                    symbolsAdaptedToSlotWindow = [
                        ...symbolsAdaptedToSlotWindow,
                        [
                            featureSymbols[i],
                            featureSymbols[i + 5],
                            featureSymbols[i + 10],
                            featureSymbols[i + 15],
                            featureSymbols[i + 20],
                        ],
                    ];
                }
                if (step.isRespin) {
                    step.respinSymbols = symbolsAdaptedToSlotWindow;
                } else {
                    step.freeSpinSymbols = symbolsAdaptedToSlotWindow;
                }
            }
            step.featureSymbols = undefined;

            step.wheelSpinResult = convertWheelSpinResult(step.wheelSpinResult);

            step.rows = undefined;
            step.randoms = undefined;
            return step;
        },
    };
}

function convertWheelSpinResult(value: string): string | number {
    return isNaN(Number(value)) ? value : Number(value);
}
