/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "modelId": "mauimagic",
      "scatterSymbol": "FS",
      "wildSymbol": "WW",
      "bonusSymbol": "BO",
      "freeSpinTrigger": "FS",
      "countToTriggerBonusGame": 3,
      "countToTriggerFreeSpin" : 3,
      "freeSpinCount": 5,
      "oneWildWinWeight": 35,
      "freeSpinMultipliers": [1, 2, 5], //3 SC -> x1  ; 5 SC -> x5
      "winTable": [
        {
          "symbol": "WW",
          "multipliers": [500, 300, 30]
        }, {
          "symbol": "AA",
          "multipliers": [300, 150, 20]
        },{
          "symbol": "BB",
          "multipliers": [200, 75, 15]
        },{
          "symbol": "CC",
          "multipliers": [100, 50, 12]
        }, {
          "symbol": "DD",
          "multipliers": [50, 20, 10]
        }, {
          "symbol": "EE",
          "multipliers": [20, 10, 5]
        }, {
          "symbol": "FF",
          "multipliers": [20, 10, 5]
        }, {
          "symbol": "GG",
          "multipliers": [20, 10, 5]
        }, {
          "symbol": "HH",
          "multipliers": [20, 10, 5]
        }
      ],
    
      "reelsLayout": [3, 3, 3, 3, 3],
      "playLines": [
    
        [1, 1, 1, 1, 1],  // 1
        [0, 0, 0, 0, 0],  // 2
        [2, 2, 2, 2, 2],  // 3
        [0, 1, 2, 1, 0],  // 4
        [2, 1, 0, 1, 2],  // 5
        [0, 0, 1, 2, 2],  // 6
        [2, 2, 1, 0, 0],  // 7
        [1, 0, 1, 2, 1],  // 8
        [1, 2, 1, 0, 1],  // 9
        [0, 1, 1, 1, 2],  // 10
        [2, 1, 1, 1, 0],  // 11
        [1, 0, 0, 1, 2],  // 12
        [1, 2, 2, 1, 0],  // 13
        [1, 1, 0, 1, 2],  // 14
        [1, 1, 2, 1, 0],  // 15
        [0, 0, 1, 2, 1],  // 16
        [2, 2, 1, 0, 1],  // 17
        [1, 0, 1, 2, 2],  // 18
        [1, 2, 1, 0, 0],  // 19
        [0, 0, 0, 1, 2],  // 20
        [2, 2, 2, 1, 0],  // 21
        [0, 1, 2, 2, 2],  // 22
        [2, 1, 0, 0, 0],  // 23
        [0, 1, 0, 1, 0],  // 24
        [2, 1, 2, 1, 2],  // 25
        [0, 1, 1, 1, 0],  // 26
        [2, 1, 1, 1, 2],  // 27
        [1, 0, 0, 0, 1],  // 28
        [1, 2, 2, 2, 1],  // 29
        [0, 1, 0, 1, 2]   // 30
      ],
    
      "bonus":{
        "multiplierRound": 2,
        "noLoseRound": 0,
        "rounds":[
          ["1", "2", "2", "2", "0", "5"],
          ["0", "0", "1", "2", "3", "6"],
          ["0", "0", "2", "2", "3", "4"],
          ["0", "0", "2", "3", "5", "10"],
          ["0", "0", "0", "5", "10", "15"],
          ["0", "0", "0", "10", "15", "25"]
        ]
      },
    
      "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
      "coinType4Values": [ 1, 2, 5, 10, 50],
    
      "coinType1_min_bet":"250",
      "coinType1_max_bet":"100000",
      "coinType1_spin_max_bet":"3000000",
    
      "coinType4_min_bet":"1",
      "coinType4_max_bet":"50",
      "coinType4_spin_max_bet":"1500",
    
      "reels": [
        ["EE", "DD", "DD", "HH", "BO", "BB", "BB", "WW", "FF", "GG", "AA", "HH", "CC", "EE", "CC", "HH", "EE", "AA", "EE", "CC", "AA", "FF", "FF", "DD", "DD", "BB", "BB", "FS", "GG", "HH", "CC", "DD", "BB", "HH", "FF", "FF", "EE", "HH", "AA", "CC", "CC", "BB", "WW", "HH", "AA", "HH", "BB", "AA", "DD", "AA", "BO", "EE", "FF", "GG", "BB", "FS", "BB", "BB", "EE", "DD", "AA", "HH", "BO", "DD", "CC", "AA", "HH", "FF", "EE", "CC", "BB", "DD", "AA", "AA", "BB", "EE", "FS", "EE", "DD", "CC", "AA", "AA", "GG", "GG", "CC", "FF", "DD", "FS", "GG", "FF", "BB", "GG", "FF", "CC", "HH", "EE", "BB", "AA", "BB", "FS", "FF"],
        ["DD", "FF", "HH", "BB", "BO", "HH", "GG", "WW", "BB", "CC", "AA", "CC", "AA", "EE", "HH", "BB", "DD", "AA", "CC", "BB", "FF", "BB", "CC", "AA", "AA", "GG", "FF", "FS", "CC", "EE", "GG", "FF", "BB", "DD", "CC", "CC", "CC", "EE", "AA", "HH", "FF", "AA", "AA", "EE", "CC", "CC", "FF", "HH", "FF", "EE", "FS", "BB", "DD", "EE", "FF", "FS", "AA", "EE", "DD", "AA", "FF", "BB", "BO", "GG", "AA", "HH", "BB", "DD", "BB", "FF", "WW", "HH", "CC", "GG", "GG", "DD", "GG", "BB", "FF", "GG", "GG", "BO", "EE", "BB", "GG", "EE", "HH", "FS", "CC", "BB", "GG", "WW", "CC", "BO", "DD", "DD", "AA", "FF", "HH", "AA", "EE"],
        ["HH", "CC", "DD", "HH", "BO", "CC", "DD", "WW", "GG", "BB", "EE", "HH", "DD", "CC", "DD", "HH", "AA", "EE", "BB", "DD", "EE", "AA", "BB", "FF", "CC", "CC", "CC", "FS", "BB", "HH", "CC", "EE", "GG", "BB", "BB", "GG", "HH", "HH", "FF", "FF", "DD", "WW", "AA", "AA", "GG", "AA", "FF", "DD", "EE", "GG", "BO", "AA", "GG", "GG", "EE", "FS", "CC", "GG", "AA", "DD", "EE", "BB", "BO", "EE", "DD", "FF", "AA", "BB", "HH", "CC", "WW", "CC", "GG", "DD", "EE", "BB", "WW", "BB", "DD", "FF", "EE", "BO", "BB", "HH", "CC", "AA", "DD", "FS", "HH", "AA", "EE", "WW", "AA", "BO", "HH", "HH", "FF", "EE", "CC", "WW", "AA"],
        ["EE", "AA", "BB", "EE", "BO", "AA", "BB", "WW", "FF", "GG", "HH", "FF", "BB", "DD", "AA", "FF", "EE", "WW", "DD", "HH", "AA", "GG", "FF", "FF", "AA", "CC", "GG", "WW", "GG", "FF", "BB", "BB", "FF", "EE", "FF", "FF", "DD", "FF", "AA", "CC", "AA", "AA", "WW", "EE", "DD", "WW", "BB", "CC", "AA", "FF", "WW", "HH", "EE", "DD", "AA", "FS", "BB", "GG", "EE", "AA", "BB", "BB", "FS", "BB", "HH", "HH", "EE", "DD", "EE", "CC", "WW", "HH", "GG", "BB", "BB", "EE", "WW", "GG", "CC", "HH", "HH", "WW", "EE", "GG", "HH", "DD", "CC", "FS", "GG", "GG", "CC", "WW", "DD", "HH", "DD", "DD", "AA", "CC", "HH", "WW", "EE"],
        ["DD", "FF", "HH", "DD", "BO", "HH", "GG", "WW", "BB", "CC", "EE", "CC", "EE", "AA", "HH", "AA", "BB", "WW", "CC", "GG", "AA", "AA", "BB", "DD", "FF", "GG", "AA", "WW", "BB", "DD", "HH", "FF", "CC", "AA", "CC", "BB", "DD", "CC", "GG", "FF", "EE", "EE", "WW", "DD", "HH", "WW", "EE", "HH", "EE", "BB", "WW", "GG", "BB", "BB", "FF", "BO", "FF", "DD", "AA", "GG", "FF", "BB", "BO", "HH", "EE", "FF", "DD", "BB", "CC", "HH", "WW", "GG", "CC", "EE", "HH", "CC", "WW", "BB", "EE", "GG", "FF", "WW", "BB", "DD", "GG", "AA", "HH", "FS", "CC", "AA", "EE", "WW", "AA", "EE", "AA", "CC", "GG", "FF", "GG", "WW", "AA"]
      ],
    
      "featureReels" : [
        ["EE", "FF", "CC", "HH", "EE", "BB", "HH", "EE", "BB", "FF", "EE", "CC", "EE", "GG", "AA", "FF", "HH", "DD", "GG", "EE", "CC", "FF", "GG", "BB", "GG", "HH", "DD", "HH", "FF", "CC", "GG", "FF", "DD", "HH", "GG", "WW", "EE", "FF", "DD", "HH", "EE", "CC", "EE", "HH", "DD", "FF", "EE", "BB", "EE", "GG", "CC", "FF", "HH", "DD", "GG", "EE", "AA", "FF", "GG", "CC", "GG", "HH", "DD", "HH", "FF", "BB", "GG", "FF", "AA", "HH", "GG", "DD", "HH", "GG", "CC", "HH", "FF", "DD", "GG", "HH", "BB", "GG", "HH", "DD", "HH", "GG", "FF", "HH"],
        ["FF", "GG", "DD", "HH", "FF", "BB", "GG", "FF", "BB", "GG", "FF", "DD", "FF", "HH", "BB", "GG", "FF", "CC", "HH", "FF", "DD", "GG", "HH", "AA", "HH", "FF", "DD", "HH", "GG", "DD", "HH", "GG", "DD", "EE", "HH", "BB", "FF", "GG", "CC", "EE", "FF", "DD", "FF", "EE", "CC", "GG", "FF", "AA", "FF", "HH", "DD", "GG", "EE", "CC", "HH", "FF", "BB", "GG", "HH", "DD", "HH", "GG", "CC", "EE", "GG", "AA", "HH", "GG", "BB", "EE", "HH", "CC", "EE", "HH", "DD", "EE", "GG", "CC", "HH", "EE", "AA", "HH", "EE", "CC", "EE", "HH", "GG", "EE"],
        ["GG", "HH", "CC", "EE", "GG", "BB", "HH", "GG", "BB", "HH", "GG", "CC", "GG", "EE", "AA", "HH", "GG", "DD", "EE", "GG", "CC", "HH", "EE", "BB", "EE", "GG", "DD", "EE", "HH", "CC", "EE", "HH", "DD", "FF", "EE", "AA", "GG", "HH", "DD", "FF", "GG", "CC", "GG", "FF", "DD", "HH", "GG", "BB", "GG", "EE", "CC", "HH", "FF", "DD", "EE", "GG", "AA", "HH", "FF", "CC", "EE", "HH", "DD", "FF", "HH", "BB", "FF", "HH", "AA", "FF", "EE", "DD", "FF", "HH", "CC", "FF", "HH", "DD", "GG", "FF", "BB", "HH", "FF", "DD", "FF", "GG", "HH", "FF"],
        ["HH", "EE", "DD", "FF", "HH", "BB", "EE", "HH", "BB", "EE", "HH", "DD", "HH", "FF", "BB", "EE", "HH", "CC", "FF", "HH", "DD", "GG", "FF", "AA", "FF", "HH", "DD", "FF", "GG", "DD", "FF", "EE", "DD", "GG", "FF", "BB", "HH", "EE", "CC", "GG", "HH", "DD", "HH", "GG", "CC", "FF", "HH", "AA", "HH", "FF", "DD", "HH", "GG", "CC", "FF", "HH", "BB", "FF", "GG", "DD", "FF", "HH", "CC", "GG", "EE", "AA", "GG", "EE", "BB", "GG", "FF", "CC", "GG", "EE", "DD", "GG", "EE", "CC", "HH", "GG", "AA", "EE", "GG", "CC", "GG", "HH", "EE", "GG"],
        ["EE", "FF", "CC", "GG", "EE", "BB", "FF", "GG", "BB", "FF", "GG", "CC", "EE", "GG", "AA", "FF", "EE", "DD", "GG", "FF", "CC", "HH", "GG", "BB", "GG", "FF", "DD", "GG", "HH", "CC", "GG", "FF", "DD", "HH", "GG", "AA", "EE", "FF", "DD", "HH", "EE", "CC", "EE", "HH", "DD", "GG", "HH", "BB", "EE", "GG", "CC", "EE", "HH", "DD", "GG", "HH", "AA", "GG", "HH", "CC", "GG", "EE", "DD", "HH", "FF", "BB", "HH", "FF", "AA", "HH", "GG", "DD", "HH", "FF", "CC", "HH", "FF", "DD", "EE", "HH", "BB", "FF", "HH", "DD", "HH", "EE", "FF", "HH"]
      ]
    
    },      
}
