var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v("Free Spin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Free Spin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clear Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isFeature ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center base-height-selector-wrapper" }, [
        _c("h3", [_vm._v("Reel heights:")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightTwo,
                  expression: "baseReelHeightTwo",
                },
              ],
              attrs: { id: "baseReelHeightTwo" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightTwo = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(1, _vm.baseReelHeightTwo)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "4", selected: "selected" } }, [
                _vm._v("4"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightThree,
                  expression: "baseReelHeightThree",
                },
              ],
              attrs: { id: "baseReelHeightThree" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightThree = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(2, _vm.baseReelHeightThree)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "4", selected: "selected" } }, [
                _vm._v("4"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightFour,
                  expression: "baseReelHeightFour",
                },
              ],
              attrs: { id: "baseReelHeightFour" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightFour = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(3, _vm.baseReelHeightFour)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "4", selected: "selected" } }, [
                _vm._v("4"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center base-height-selector" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelHeightFive,
                  expression: "baseReelHeightFive",
                },
              ],
              attrs: { id: "baseReelHeightFive" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelHeightFive = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateBaseReelHeight(4, _vm.baseReelHeightFive)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "4", selected: "selected" } }, [
                _vm._v("4"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c(
          "div",
          { staticClass: "selected-scatters" },
          [
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value,
                attrs: { index: value, prizeIndices: _vm.prizeIndices },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 6,
                attrs: { index: value + 6, prizeIndices: _vm.prizeIndices },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 6 + 6,
                attrs: { index: value + 6 + 6, prizeIndices: _vm.prizeIndices },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 6 + 12,
                attrs: {
                  index: value + 6 + 12,
                  prizeIndices: _vm.prizeIndices,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(6, function (value) {
              return _c("selectScatterMultiplier", {
                key: value + 6 + 18,
                attrs: {
                  index: value + 6 + 18,
                  prizeIndices: _vm.prizeIndices,
                },
              })
            }),
            _vm._v(" "),
            _c("br"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.resetAll()
              },
            },
          },
          [_vm._v("Reset all")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }