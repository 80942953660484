// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"modelDefinition": {
		"modelId": "vgw040",
		"reelsLayout": [3, 3, 3, 3, 3],
		"scatterSymbol": "SCAT",
		"wildSymbol": "Wild",
		"freeSpinMultiplier": 0,
		"freeSpinCount": 0,
		"winTable": [{
			"symbol": "PIC1",
			"multipliers": [1000, 200, 100, 4]
		}, {
			"symbol": "PIC2",
			"multipliers": [500, 150, 80, 2]
		}, {
			"symbol": "PIC3",
			"multipliers": [300, 100, 50]
		}, {
			"symbol": "PIC4",
			"multipliers": [300, 100, 50]
		}, {
			"symbol": "PIC5",
			"multipliers": [150, 80, 40]
		}, {
			"symbol": "PIC6",
			"multipliers": [125, 70, 30]
		}, {
			"symbol": "PIC7",
			"multipliers": [100, 50, 20]
		}, {
			"symbol": "PIC8",
			"multipliers": [100, 40, 10]
		}, {
			"symbol": "SCAT",
			"multipliers": [50, 10, 2]
		}, {
			"symbol": "Wild",
			"multipliers": [1000, 200, 100, 4]
		}],
		"playLines": [
			[1, 1, 1, 1, 1],
			[0, 0, 0, 0, 0],
			[2, 2, 2, 2, 2],
			[0, 1, 2, 1, 0],
			[2, 1, 0, 1, 2],
			[0, 0, 1, 2, 2],
			[2, 2, 1, 0, 0],
			[1, 0, 1, 2, 1],
			[1, 2, 1, 0, 1],
			[1, 0, 0, 1, 0],
			[1, 2, 2, 1, 2],
			[0, 1, 0, 0, 1],
			[2, 1, 2, 2, 1],
			[0, 2, 0, 2, 0],
			[2, 0, 2, 0, 2],
			[1, 0, 2, 0, 1],
			[1, 2, 0, 2, 1],
			[0, 1, 1, 1, 0],
			[2, 1, 1, 1, 2],
			[0, 2, 2, 2, 0],
			[2, 0, 0, 0, 2],
			[0, 1, 2, 2, 2],
			[2, 1, 0, 0, 0],
			[2, 0, 1, 0, 2],
			[0, 2, 1, 2, 0]
		],

		"reels" : [
			["PIC1", "PIC7", "PIC3", "PIC8", "PIC7", "PIC3", "PIC8", "PIC5", "PIC4", "PIC7", "PIC4", "PIC8", "PIC2", "PIC1", "PIC8", "PIC7", "PIC4", "PIC6", "PIC2", "PIC8", "PIC7", "PIC1", "PIC8", "PIC7", "PIC3", "PIC8", "PIC5", "PIC2", "SCAT", "PIC8", "PIC6"],
			["PIC2", "SCAT", "PIC8", "PIC3", "PIC6", "PIC8", "PIC7", "PIC3", "PIC2", "PIC6", "PIC1", "PIC8", "PIC2", "PIC7", "PIC6", "PIC4", "PIC7", "PIC1", "PIC2", "PIC6", "PIC6", "PIC4", "PIC8", "PIC6", "PIC1", "PIC5", "PIC4", "PIC6", "PIC7"],
			["PIC5", "PIC8", "PIC2", "PIC7", "PIC5", "PIC3", "PIC4", "PIC5", "PIC3", "PIC5", "PIC8", "PIC3", "PIC5", "PIC4", "PIC8", "PIC3", "PIC5", "PIC4", "PIC8", "PIC1", "SCAT", "PIC5", "PIC2", "PIC4", "PIC6", "PIC3", "PIC5", "PIC6", "PIC5", "PIC2", "PIC1"],
			["PIC1", "PIC8", "PIC3", "PIC2", "PIC8", "PIC3", "SCAT", "PIC5", "PIC4", "PIC7", "PIC2", "PIC4", "PIC7", "PIC2", "PIC3", "PIC6", "PIC4", "PIC5", "PIC8", "PIC6", "PIC5", "PIC4", "PIC7", "PIC2", "PIC5", "SCAT", "PIC7", "PIC1", "PIC6", "PIC3", "PIC8", "PIC4"],
			["PIC5", "PIC8", "PIC2", "PIC1", "PIC5", "PIC8", "PIC4", "PIC7", "PIC3", "PIC4", "PIC5", "PIC8", "PIC2", "PIC1", "PIC3", "SCAT", "PIC6", "PIC2", "PIC7", "PIC5", "PIC4", "PIC3", "PIC8", "PIC5", "PIC6", "PIC3", "PIC7", "PIC4", "PIC6", "PIC8", "PIC2", "PIC7"]
		],

		"featureReels" : [
			["PIC8", "PIC7", "PIC3", "PIC8", "PIC7", "PIC3", "PIC8", "PIC5", "PIC4", "PIC7", "PIC6", "PIC8", "PIC2", "PIC1", "PIC8", "PIC7", "PIC4", "PIC6", "PIC2", "PIC8", "PIC7", "PIC1", "PIC8", "PIC7", "PIC3", "PIC8", "PIC5", "PIC2", "SCAT", "PIC8", "PIC6"],
			["PIC2", "SCAT", "PIC8", "PIC3", "PIC6", "PIC8", "PIC7", "PIC3", "PIC4", "PIC6", "PIC1", "PIC8", "PIC2", "PIC7", "PIC6", "PIC4", "PIC7", "PIC1", "PIC4", "PIC6", "PIC6", "PIC4", "PIC8", "PIC6", "PIC1", "PIC5", "PIC4", "PIC6", "PIC7"],
			["PIC5", "PIC8", "PIC2", "PIC7", "PIC5", "PIC3", "PIC4", "PIC5", "PIC3", "PIC5", "PIC8", "PIC3", "PIC5", "PIC4", "PIC8", "PIC3", "PIC5", "PIC4", "PIC8", "PIC1", "SCAT", "PIC5", "PIC2", "PIC4", "PIC6", "PIC3", "PIC5", "PIC6", "PIC5", "PIC2", "PIC1"],
			["PIC1", "PIC8", "PIC3", "PIC2", "PIC8", "PIC3", "SCAT", "PIC5", "PIC4", "PIC7", "PIC2", "PIC4", "PIC7", "PIC2", "PIC3", "PIC6", "PIC4", "PIC5", "PIC8", "PIC6", "PIC5", "PIC4", "PIC7", "PIC2", "PIC5", "SCAT", "PIC7", "PIC1", "PIC6", "PIC3", "PIC8", "PIC4"],
			["PIC5", "PIC8", "PIC2", "PIC1", "PIC5", "PIC8", "PIC4", "PIC7", "PIC3", "PIC4", "PIC5", "PIC8", "PIC2", "PIC1", "PIC3", "SCAT", "PIC6", "PIC2", "PIC7", "PIC5", "PIC4", "PIC3", "PIC8", "PIC5", "PIC6", "PIC3", "PIC7", "PIC4", "PIC6", "PIC8", "PIC2", "PIC7"]
		],
		"defaultSelectLine": 25,
		"coinType1Values": [250, 2500, 10000, 50000, 100000],
		"coinType4Values": [1,2,4,10,20,100,400],
		"coinType1_min_bet": 250,
		"coinType1_max_bet": 100000,
		"coinType1_spin_max_bet": 2500000,
		"coinType4_min_bet": 1,
		"coinType4_max_bet": 400,
		"coinType4_spin_max_bet": 10000,
		"winLevels": [{
			"name": "normal1",
			"threshold": 5
		}, {
			"name": "normal2",
			"threshold": 15
		}, {
			"name": "normal3",
			"threshold": 25
		}, {
			"name": "bigwin",
			"threshold": 50
		}, {
			"name": "megawin",
			"threshold": 150
		}, {
			"name": "epicwin",
			"threshold": 2147483647
		}],
		"freeSpinTrigger": "SCAT",
		"countToTriggerFreeSpin": 3,
		"credits": 25,
		"freeSpinRetriggerCount": 5,
		"maxFreespinCap": 100,
		"fsOptions": [3, 4, 5, 6, 7, 8],
		"currency_symbol": "$"
	},
	"modelId": "vgw040",
	"gameSessionId": 9,
	"reelStripPositions": [2, 12, 25, 4, 4],
	"slotWindow": [
		["PIC3", "PIC8", "PIC7"],
		["PIC2", "PIC7", "PIC6"],
		["PIC3", "PIC5", "PIC6"],
		["PIC8", "PIC3", "SCAT"],
		["PIC5", "PIC8", "PIC4"]
	],
	"gold_balance": 10000000,
	"sweeps_balance": 10000000,
	"sweeps_deposit_balance": 10000000,
	"sweeps_promo_balance": 0,
	"sweeps_win_balance": 0,
	"sweeps_available_balance": 0,
	"serverTime": "2020-01-02 08:21:05"
};
