var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "fs-selector sm-label cardSpace" }, [
    _c("div", [
      _c("label", { attrs: { for: _vm.index } }, [
        _vm._v("Result #" + _vm._s(_vm.index) + ": "),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.wheelResults[_vm.index - 1],
              expression: "wheelResults[index - 1]",
            },
          ],
          attrs: { id: _vm.index },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.wheelResults,
                  _vm.index - 1,
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              function ($event) {
                return _vm.updateWheelResult(_vm.index - 1)
              },
            ],
          },
        },
        [
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "1r" },
            },
            [_vm._v("1 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "2l" },
            },
            [_vm._v("2 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "3r" },
            },
            [_vm._v("3 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "3r" },
            },
            [_vm._v("3 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "2l" },
            },
            [_vm._v("2 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(0, _vm.index),
                  expression: "shouldDisplay(0, index)",
                },
              ],
              attrs: { value: "1r" },
            },
            [_vm._v("1 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "2r" },
            },
            [_vm._v("2 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "3l" },
            },
            [_vm._v("3 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "4r" },
            },
            [_vm._v("4 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "4r" },
            },
            [_vm._v("4 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "3l" },
            },
            [_vm._v("3 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(1, _vm.index),
                  expression: "shouldDisplay(1, index)",
                },
              ],
              attrs: { value: "2r" },
            },
            [_vm._v("2 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "5r" },
            },
            [_vm._v("5 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "6l" },
            },
            [_vm._v("6 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "10r" },
            },
            [_vm._v("10 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "10r" },
            },
            [_vm._v("10 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "6l" },
            },
            [_vm._v("6 prev")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(2, _vm.index),
                  expression: "shouldDisplay(2, index)",
                },
              ],
              attrs: { value: "5r" },
            },
            [_vm._v("5 next")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "5" },
            },
            [_vm._v("5")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "6" },
            },
            [_vm._v("6")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "10" },
            },
            [_vm._v("10")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "5" },
            },
            [_vm._v("5")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "6" },
            },
            [_vm._v("6")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "20" },
            },
            [_vm._v("20")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "5" },
            },
            [_vm._v("5")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "6" },
            },
            [_vm._v("6")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "1000" },
            },
            [_vm._v("1000")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "5" },
            },
            [_vm._v("5")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "6" },
            },
            [_vm._v("6")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplay(3, _vm.index),
                  expression: "shouldDisplay(3, index)",
                },
              ],
              attrs: { value: "50" },
            },
            [_vm._v("50")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }