import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

function filterObjectKeys(predicate, obj) {
    return obj.reduce(
        (o, [key, value]) => (predicate(key, value) ? {...o, [key]: value} : o),
        {});
}

export enum DragonBonus {
    NONE = "NONE",
    PEARL_DRAGON = "PEARL_DRAGON",
    YELLOW_DRAGON = "YELLOW_DRAGON",
    BLACK_DRAGON = "BLACK_DRAGON",
    LONG_DRAGON = "LONG_DRAGON"
}

export enum ReelSetId {
    REEL_SET_1 = "REEL_SET_1",
    REEL_SET_2 = "REEL_SET_2",
    REEL_SET_3 = "REEL_SET_3",
    REEL_SET_4 = "REEL_SET_4"
}

export enum StepType {
    BASE = "BASE",
    BASE_RESPIN = "BASE_RESPIN",
    FS_TRIGGER = "FS_TRIGGER",
    PICK = "PICK",
    FREE_SPIN = "FREE_SPIN",
    FREE_SPIN_RESPIN = "FREE_SPIN_RESPIN"
}

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {
            DragonBonus: DragonBonus,
            ReelSetId: ReelSetId,
            StepType: StepType
        };
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public dragonBonus = DragonBonus.NONE;
    public dragonBonusOccurs = false;
    public reelSetId = ReelSetId.REEL_SET_1;
    public stepType = StepType.BASE;

    constructor(...args: any[]) {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.show = true;

        this.stepType = step.json.type ? step.json.type : StepType.BASE;

        if (step.json.dragonBonus) {
            this.dragonBonus = step.json.dragonBonus;

        } else if ([StepType.BASE_RESPIN, StepType.PICK, StepType.FREE_SPIN_RESPIN].includes(this.stepType)) {
            this.dragonBonus = DragonBonus.PEARL_DRAGON;

        } else {
            this.dragonBonus = DragonBonus.NONE;
        }

        if (step.json.dragonBonusOccurs) {
            this.dragonBonusOccurs = step.json.dragonBonusOccurs;

        } else if ([StepType.BASE_RESPIN, StepType.FREE_SPIN_RESPIN].includes(this.stepType)) {
            this.dragonBonusOccurs = true;

        } else {
            this.dragonBonusOccurs = false;
        }

        this.reelSetId = this.getReelSetId();
    }
    public mounted() {
        bus.$on("tbg-update-step-from-reel", step => this.update(step.json));
    }
    public addStep(type) {
        const step = State.state.createCustomStep(type);
        bus.$emit(events.ADD_STEP, step);
    }
    public update(params) {
        this.step.json.reelSetId = this.reelSetId;
        this.step.json.dragonBonus = this.dragonBonus;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public getStepDragonBonusOptions() {
        return filterObjectKeys(
            key => {
                if (this.stepType === StepType.FS_TRIGGER) {
                    return key === DragonBonus.NONE;
                } else if (this.stepType === StepType.PICK) {
                    return ![DragonBonus.NONE].includes(key);
                } else if ([StepType.BASE_RESPIN, StepType.FREE_SPIN_RESPIN].includes(this.stepType)) {
                    return [DragonBonus.PEARL_DRAGON, DragonBonus.BLACK_DRAGON].includes(key);
                } else {
                    return true;
                }
            },
            Object.entries(DragonBonus));
    }
    public getStepReelStrips() {
        return filterObjectKeys(
            key => key.match(this.stepType === StepType.FREE_SPIN ? `^FREE_SPIN` : `^BASE`),
            Object.entries(ReelSetId));
    }
    public getPickDragonBonus() {
        return DragonBonus.PEARL_DRAGON;
    }
    private getReelSetId() {
        if (this.stepType === StepType.BASE) {
            if (this.dragonBonus === DragonBonus.NONE) {
                return ReelSetId.REEL_SET_1;
            } else {
                return ReelSetId.REEL_SET_2;
            }
        } else if (this.stepType === StepType.BASE_RESPIN) {
            return ReelSetId.REEL_SET_2;

        } else if (this.stepType === StepType.FS_TRIGGER) {
            return ReelSetId.REEL_SET_1;

        } else if (this.stepType === StepType.PICK) {
            return ReelSetId.REEL_SET_1;

        } else if (this.stepType === StepType.FREE_SPIN) {
            if (this.dragonBonus === DragonBonus.LONG_DRAGON) {
                return ReelSetId.REEL_SET_4;
            } else {
                return ReelSetId.REEL_SET_3;
            }
        } else if (this.stepType === StepType.FREE_SPIN_RESPIN) {
            return ReelSetId.REEL_SET_4;

        } else {
            return ReelSetId.REEL_SET_1;
        }
    }
}
