import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./../reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;

    public isBonusTriggerStep = false;
    public isJackpotPickStep = false;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public onOffPatterns = [];
    public currentOnOffPattern = "";
    public currentSubstitutionPattern = "";
    public substitutionPatterns = [];
    public baseSubstitutionPatterns = [];
    public featureSubstitutionPatterns = [];
    public currentMultiplierPattern = "";
    public multiplierPatterns = [];
    public baseMultiplierPatterns = [];
    public featureMultiplierPatterns = [];

    public jackpotPicks : Object[] = [];
    public currentJackpotPick = 0;

    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.isBonusTriggerStep = step.json.isBonusTriggerStep;
        this.isJackpotPickStep = step.json.isJackpotPickStep;

        const patterns = this.isFeature? this.featureSubstitutionPatterns : this.baseSubstitutionPatterns;
        this.substitutionPatterns = patterns.filter((p, idx) => patterns.indexOf(p) === idx);

        const multipliers = this.isFeature? this.featureMultiplierPatterns : this.baseMultiplierPatterns;
        this.multiplierPatterns = multipliers.filter((m, idx) => multipliers.indexOf(m) === idx);

        this.currentOnOffPattern = step.json.field0;
        this.currentSubstitutionPattern = step.json.field1;
        this.currentMultiplierPattern = step.json.field2;
        this.currentJackpotPick = step.json.field3;
    }
    public mounted() {
        this.onOffPatterns = State.state.getModelDefinition().multiplierOnOffPatterns.map((it) => it.pattern.toString());
        this.baseSubstitutionPatterns = State.state.getModelDefinition().baseSelectiveStackingPatterns.map((it) => it.replacementSymbols.toString());
        this.featureSubstitutionPatterns = State.state.getModelDefinition().featureSelectiveStackingPatterns.map((it) => it.replacementSymbols.toString());
        this.baseMultiplierPatterns = State.state.getModelDefinition().baseSelectiveStackingPatterns.map((it) => it.multipliers.toString());
        this.featureMultiplierPatterns = State.state.getModelDefinition().featureSelectiveStackingPatterns.map((it) => it.multipliers.toString());
        this.jackpotPicks = this.createJackpotPicks();
    }
    private createJackpotPicks() {
        const jackpots : string[] = ["GRAND", "MAJOR", "MINOR", "MINI"];

        const jackpotCollectionPoints = State.state.getModelDefinition().jackpotCollectionPoints;
        const jackpotPicks = State.state.getModelDefinition().jackpotPickOptions.map((picks, index) => {
            const collectedPoints = [0,0,0,0];
            const jackpotsWon : string[] = [];
            for (let i=0; i<picks.length; i++){
                const pick = picks[i];

                for (let j=0; j<pick.length; j++) {
                    const jp = pick[j] - 1;
                    collectedPoints[jp] ++;
                }

                jackpots.forEach((jackpotType:string, idx:number) => {
                    if (collectedPoints[idx] >= jackpotCollectionPoints[idx]){
                        jackpotsWon.push(jackpotType);
                    }
                });

                if (jackpotsWon.length)
                    break;
            }

            return {jackpotWins: jackpotsWon, index: index.toString()};
        });

        return jackpotPicks;
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addBonusTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBonusTriggerStep());
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep() );
    }
    public addJackpotPickStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getJackpotPickStep());
    }
    public changeOnOffPattern() {
        this.step.json.field0 = this.currentOnOffPattern;
        this.step.json.field1 = this.currentSubstitutionPattern;
        this.step.json.field2 = this.currentMultiplierPattern;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public changeJackpotPick() {
        this.step.json.field3 = this.currentJackpotPick;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
