const events = {
    MODEL_CHANGED: "sm-model-changed",  
    SHOW_SCENARIO: "sm-show",  
    CLOSE_SCENARIO: "sm-close",  
    CHANGE_SCENARIO: "sm-change-scenario",  
    CHANGE_STEP: "sm-change-step",  
    SHOW_POPUP: "sm-show-popup",  
    SHOW_INPUT_POPUP: "sm-show-input-popup",  
    GET_SCENARIOS: "sm-get-scenarios",  
    UPDATE_SCENARIOS: "sm-update-scenarios",  
    UPDATE_STEPS: "sm-update-steps",  
    REFRESH_SCENARIOS: "sm-refresh-scenarios",  
    REFRESH_SCENARIO: "sm-refresh-scenario",  
    DESTROY_SCENARIO_MANAGER: "sm-destroy",

    UPDATE_STEPS_DB: "sm-update-step-db",  
    ADD_STEP: "sm-add-step",  
    ADD_STEPS: "sm-add-steps",
    EDIT_STEPS: "em-edit-steps",
    RECREATE_SCENARIO: "sm-recreate-scenario",  
    SCENARIO_STARTED: "sm-scenario-started",  
};

export default events;
