var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addTrigger8FG()
              },
            },
          },
          [_vm._v("Bonus 8FG")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addMiniJackpotStep()
              },
            },
          },
          [_vm._v("Bonus 2x Mini")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "dark-rule" }),
      _vm._v(" "),
      _c("h5", { staticClass: "bonus-text" }, [
        _vm._v("Bonus wheels are only active on bonus trigger"),
      ]),
      _vm._v(" "),
      _c("div", { class: ["flex-center"], staticStyle: { height: "50px" } }, [
        _c("div", { staticClass: "small" }, [
          _c("div", { staticClass: "small", attrs: { align: "center" } }, [
            _vm._v("Multiplier Wheel:"),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.field0,
                    expression: "field0",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.field0 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateMultiplierWheelStop()
                    },
                  ],
                },
              },
              _vm._l(_vm.multiplierWheelItems, function (item, index) {
                return _c(
                  "option",
                  { key: "mw-" + index, domProps: { value: index } },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "small" }, [
          _c("div", { staticClass: "small", attrs: { align: "center" } }, [
            _vm._v("Prize Wheel:"),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.field1,
                    expression: "field1",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.field1 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updatePrizeWheelStop()
                    },
                  ],
                },
              },
              _vm._l(_vm.prizeWheelItems, function (item, index) {
                return _c(
                  "option",
                  { key: "pw-" + index, domProps: { value: index } },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("hr", { staticClass: "dark-rule" }),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }