import Vue from "vue";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reel.vue";
import {
  CoinPrize,
  MultiplierPrize,
  StepJson,
  createClearingStep,
  createFreeSpinTriggerStep,
  createHoldAndSpinTriggerStep,
  createSpinStep,
  modelDefinition,
  originatorId,
} from "../../../../state/models/slots/vgw051";
import {
  Cell,
  WinCellsForSymbol,
  areCellsEqual,
  createVgw051ScatterWinChecker,
  createVgw051WaysWinChecker,
  createVgw051WinCellsBySymbolSummariser,
  filterSlotWindow,
  filterVgw051SlotWindow,
  generateVgw051ReelStripPositionsAndSlotWindowsFromCascade,
} from "./helpers";

export default Vue.component("Content", {
  props: ["step"],

  components: {
    reel: Reel,
  },

  data: () => ({
    allSteps: [] as Step[],
  }),

  computed: {
    // ----- General step state -----
    show(): boolean {
      return this.currentStep !== undefined;
    },
    canShowMultiplierSection(): boolean {
      const multiplierCellsLength =
        this.currentStep?.multiplierCoinsThisSpin?.length ?? 0;
      return multiplierCellsLength > 0;
    },
    currentStep(): Step | undefined {
      if (!this.step || !this.allSteps) return;
      return this.allSteps.find(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    currentStepIndex(): number | undefined {
      if (!this.step || !this.allSteps) return;
      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    previousStep(): Step | undefined {
      return this.allSteps[(this.currentStepIndex ?? 0) - 1];
    },
    finalStep(): Step | undefined {
      return this.allSteps[this.allSteps.length - 1];
    },
    stepName(): string {
      return this.currentStep?.json.name ?? "???";
    },
    stepDescription(): string {
      const freeSpinPhase = this.currentStep?.freeSpinPhase;
      if (this.isCascadeStep) return "Cascade Step";
      if (this.isCascadeTrigger) return "Cascade Start";
      if (freeSpinPhase === "START") return "Free Spin Trigger";
      if (freeSpinPhase === "RETRIGGER") return "Free Spin Retrigger";
      if (this.isHoldAndSpinTrigger) return "Hold and Spin Trigger";
      if (this.isHoldAndSpinRespin) return "Hold and Spin Re-spin";
      if (this.isFreeSpin) return "Free Spin";
      return "Base Spin";
    },
    isClearingStep(): boolean {
      return this.stepName === "Clear";
    },
    canAddClearingStep(): boolean {
      return true;
    },
    canAddSpinStep(): boolean {
      return true;
    },
    canAddFeatureTriggerStep(): boolean {
      const cascadePhase = this.finalStep?.cascadePhase;
      return cascadePhase === undefined || cascadePhase === "END";
    },
    labelForAddSpinStep(): string {
      if (this.finalStep?.holdAndSpinCount) return "Add Re-spin";
      if (
        this.finalStep?.cascadePhase === "START" ||
        this.finalStep?.cascadePhase === "IN_PROGRESS"
      )
        return "Add Cascade Step";
      if (this.finalStep?.freeSpinCount) return "Add Free Spin";
      return "Add Base Spin";
    },
    labelForAddFreeSpinTriggerStep(): string {
      return `${this.finalStep?.freeSpinCount ? "Ret" : "T"}rigger Free Spins`;
    },

    // ----- Base game state -----
    reels(): string[][] {
      return modelDefinition.reels;
    },
    topReel(): string[] {
      return modelDefinition.topReel;
    },
    showReelStripPositions(): boolean {
      return this.show;
    },
    canChangeReelStripPositions(): boolean {
      return !this.isCascadeStep;
    },

    // Cascade State
    isCascadeStep(): boolean {
      const cascadePhase = this.currentStep?.cascadePhase;
      return cascadePhase === "IN_PROGRESS" || cascadePhase === "END";
    },
    isCascadeTrigger(): boolean {
      const cascadePhase = this.currentStep?.cascadePhase;
      return cascadePhase === "START";
    },
    isCascading(): boolean {
      return (
        this.currentStep?.cascadePhase === "START" ||
        this.currentStep?.cascadePhase === "IN_PROGRESS"
      );
    },

    // ----- Free spin state -----
    isFreeSpin(): boolean {
      const freeSpinPhase = this.currentStep?.freeSpinPhase;
      return (
        freeSpinPhase === "IN_PROGRESS" ||
        freeSpinPhase === "RETRIGGER" ||
        freeSpinPhase === "END"
      );
    },
    freeSpinsRemaining(): number {
      return this.currentStep?.freeSpinCount ?? 0;
    },

    // Reel Feature
    showMultiplier(): boolean {
      return !this.isCascadeStep;
    },
    showShakyShaky(): boolean {
      return !this.isCascadeStep && !this.isHoldAndSpinRespin;
    },
    multipliers(): number[] {
      const multipliersValues = this.isFreeSpin
        ? modelDefinition.multiplierValueWeights.freeSpins
        : modelDefinition.multiplierValueWeights.baseGame;
      return multipliersValues.map((o) => o.outcome);
    },
    reelFeatureMultiplier: {
      get(): number | undefined {
        return this.currentStep?.json?.multiplier ?? undefined;
      },
      set(value: number) {
        if (!this.currentStep) return;
        const valid = this.multipliers.includes(value);
        this.currentStep.json.multiplier = valid ? value : undefined;
        this.saveChangesToStep();
      },
    },
    wildsOptions(): string[] {
      const options = ["no additional wilds ❌"];
      if (this.currentStep?.isAbleToUseAddedWildsOnInitialSpin)
        options.push("initial spin 🟧");
      if (this.currentStep?.isAbleToUseAddedWildsOnEndingSpinOrCascade)
        options.push("end of spin or cascade 🟧");
      return options;
    },
    addedWilds: {
      get(): string {
        return this.currentStep?.json?.initialSpinAddedWilds
          ? "initial spin 🟧"
          : this.currentStep?.json?.endOfSpinOrCascadeAddedWilds
          ? "end of spin or cascade 🟧"
          : "no additional wilds ❌";
      },
      set(value: string) {
        if (!this.currentStep) return;

        // If initial or end of spin already exist when swapping to the other, it will swap those wilds
        if (value === "end of spin or cascade 🟧") {
          this.currentStep.json.endOfSpinOrCascadeAddedWilds =
            this.currentStep.json.initialSpinAddedWilds ?? [];
          this.currentStep.json.initialSpinAddedWilds = undefined;
        } else if (value === "initial spin 🟧") {
          this.currentStep.json.initialSpinAddedWilds =
            this.currentStep.json.endOfSpinOrCascadeAddedWilds ?? [];
          this.currentStep.json.endOfSpinOrCascadeAddedWilds = undefined;
        } else {
          this.currentStep.json.initialSpinAddedWilds = undefined;
          this.currentStep.json.endOfSpinOrCascadeAddedWilds = undefined;
        }
        this.saveChangesToStep();
      },
    },
    // Hold and spin
    isHoldAndSpinTrigger(): boolean {
      return this.currentStep?.holdAndSpinPhase === "START";
    },
    isHoldAndSpinRespin(): boolean {
      const HoldAndSpinPhase = this.currentStep?.holdAndSpinPhase;
      return HoldAndSpinPhase === "IN_PROGRESS" || HoldAndSpinPhase === "END";
    },
    holdAndSpinRespinsRemaining(): number {
      return this.currentStep?.holdAndSpinCount ?? 0;
    },
    holdAndSpinCoinsCollected(): number {
      return this.currentStep?.cumulativeCoinCount ?? 0;
    },
    coinPrizeLevels(): Array<{ value: unknown; label: string }> {
      // Add check for if it is during hold and spin or not
      const keys = Object.keys(
        modelDefinition.coinPrizeWeights.duringSpinOrCascade
      );
      return keys
        .map((k) => ({
          value: isNaN(Number(k)) ? k : Number(k),
          label: `🟡 ${k}`,
        }))
        .filter((k) => k.value !== "MULTIPLIER" || this.isHoldAndSpinRespin);
    },
    multiplierCoins(): number[][] {
      return this.currentStep?.multiplierCoinsThisSpin ?? [];
    },
    shakyShaky: {
      get(): boolean {
        return !!this.currentStep?.json.shakyShaky ?? false;
      },
      set(newValue: boolean): void {
        if (!this.currentStep) return;
        this.currentStep.json.shakyShaky = newValue ? { bigWin: newValue, feature: newValue } : undefined;
        this.saveChangesToStep();
      },
    },
    multiplierCoin: {
      get(): number | undefined {
        return this.currentStep?.multiplierIndexThisSpin;
      },
      set(value: number) {
        if (!this.currentStep) return;
        this.currentStep.multiplierIndexThisSpin = value;
        this.saveChangesToStep();
      },
    },
    multiplierCoinValues() {
      return modelDefinition.multiplierCoinValues;
    },
    coinsThatCanBeMultiplied() {
      const prizes = this.currentStep?.cumulativeCoinPrizes ?? [];
      const validCells: number[][] = [];
      prizes.forEach((prizes, col) =>
        prizes.forEach((prize, row) => {
          if (prize !== "MAJOR" && prize !== "MULTIPLIER" && prize !== 0)
            validCells.push([col, row]);
        })
      );
      return validCells.sort((a, b) => compareCells(a, b));
    },
    canAddMultiplierCoin(): boolean {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return false;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return false;
      const appliedCells = multiplierPrize.appliedCells;
      if (appliedCells.length >= 7) return false;
      return true;
    },
  },

  methods: {
    // ----- Step methods -----
    addClearingStep: () => bus.$emit(events.ADD_STEP, createClearingStep()),
    addSpinStep: () => bus.$emit(events.ADD_STEP, createSpinStep()),
    addFreeSpinTriggerStep: () =>
      bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep()),
    addHoldAndSpinTriggerStep: () =>
      bus.$emit(events.ADD_STEP, createHoldAndSpinTriggerStep()),
    // ----- Cascade -----
    isCollapsing(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      col = isTopReel ? 6 : col;
      return (
        this.currentStep?.collapsingCellsBySymbol?.some((symbol) =>
          symbol.cells.some((cell) => {
            return row === cell[0] && col === cell[1];
          })
        ) ?? false
      );
    },
    // ----- Reel feature -----
    isCandidateCell(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      // If neither of these exist, then we are not in a state were we are adding wilds
      if (
        !this.currentStep?.json.initialSpinAddedWilds &&
        !this.currentStep?.json.endOfSpinOrCascadeAddedWilds
      )
        return false;

      // Checking for top reel, mainly to avoid adding wilds to it as only reels 2 - 6 get wilds
      col = isTopReel ? 6 : col;
      const isCandidateCell = this.currentStep?.candidateCells?.some(
        (cell) => row === cell[0] && col === cell[1]
      );
      return isCandidateCell ?? false;
    },
    isAnAddedWild({ row, col }: { row: number; col: number }): boolean {
      if (!this.currentStep) return false;
      const addedWilds =
        this.currentStep.json.initialSpinAddedWilds ??
        this.currentStep.json.endOfSpinOrCascadeAddedWilds;
      if (!addedWilds) return false;
      const hasBeenAdded = addedWilds.some(
        (cell) => row === cell[0] && col === cell[1]
      );
      return hasBeenAdded;
    },
    //Hold and spin
    isCoin(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      if (isTopReel) col = 6;
      return !!this.currentStep?.cumulativeCoinPrizes?.[col]?.[row];
    },
    canSetOrUnsetCoin(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      if (isTopReel) col = 6;
      if (
        this.currentStep?.cascadePhase !== "END" &&
        (this.isHoldAndSpinTrigger || this.isHoldAndSpinRespin)
      )
        return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
      return false;
    },
    canSetCoinPrize(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      if (isTopReel) col = 6;
      if (
        this.previousStep?.cascadePhase === "START" ||
        this.previousStep?.cascadePhase === "IN_PROGRESS"
      ) {
        return this.currentStep?.coinPrizesThisSpin?.[col]?.[row] !== 0;
      } else if (this.isHoldAndSpinTrigger) {
        return this.currentStep?.coinPrizesThisSpin?.[col]?.[row] !== 0;
      } else if (this.isHoldAndSpinRespin) {
        return this.previousStep?.cumulativeCoinPrizes?.[col]?.[row] === 0;
      } else {
        return true;
      }
    },
    getCoinPrize(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): string {
      if (isTopReel) col = 6;
      return (
        this.currentStep?.cumulativeCoinPrizes?.[col]?.[row] ?? 0
      ).toString();
    },
    setCoinPrize(
      {
        row,
        col,
        value,
      }: {
        row: number;
        col: number;
        value: string;
      },
      isTopReel: boolean
    ) {
      if (isTopReel) col = 6;
      if (!this.currentStep) return;
      const cp = toCoinPrize(value);
      this.currentStep.json.coinPrizes = [0, 1, 2, 3, 4, 5, 6].map((c) => {
        return [0, 1, 2, 3].map((r) => {
          return c === col && r === row
            ? cp
            : this.currentStep?.json.coinPrizes?.[c]?.[r] ?? 0;
        });
      });
      this.saveChangesToStep();
    },
    showMultiplierTab(index: number): boolean {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return false;

      const appliedCells = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ].appliedCells;
      if (!appliedCells) return false;
      return index + 1 <= appliedCells.length;
    },
    getMultiplierCoinMultipliers(index: number): number | undefined {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return undefined;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return undefined;
      let appliedCell = multiplierPrize.appliedCells[index];
      if (appliedCell === undefined) return undefined;
      const value = appliedCell.multiplier;
      return value;
    },
    setMultiplierCoinMultipliers(index: number, value: string) {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return;
      const appliedCell = multiplierPrize.appliedCells[index];
      appliedCell.multiplier = Number(value);
      this.saveChangesToStep();
    },
    getMultiplierCoinAppliedCell(index: number): number[] | undefined {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return undefined;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return undefined;
      let appliedCell = multiplierPrize.appliedCells[index];
      if (appliedCell === undefined) return undefined;
      const value = appliedCell.cell;
      return value;
    },
    setMultiplierCoinAppliedCell(index: number, value: string) {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return;
      const appliedCell = multiplierPrize.appliedCells[index];
      const splitArray = value.split(",");
      appliedCell.cell = [Number(splitArray[0]), Number(splitArray[1])];
      this.saveChangesToStep();
    },
    addMultiplierCoin() {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return;
      const appliedCells = multiplierPrize.appliedCells;
      if (appliedCells.length >= 7) return;
      const cell = this.coinsThatCanBeMultiplied[0];
      const base = { cell: cell, multiplier: 2 };
      appliedCells.push(base);
      this.saveChangesToStep();
    },
    removeMultiplierCoin(index: number) {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return;
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return;
      multiplierPrize.appliedCells.splice(index, 1);
      this.saveChangesToStep();
    },
    getAffectedMultiplierCoinClass(
      {
        row,
        col,
      }: {
        row: number;
        col: number;
      },
      isTopReel: boolean
    ): string {
      const multiplierIndex = this.currentStep?.multiplierIndexThisSpin;
      if (multiplierIndex === undefined) return "not-affected-multiplier";
      const multiplierPrize = this.currentStep?.json.reverseMultiplierPrizes?.[
        multiplierIndex
      ];
      if (multiplierPrize === undefined) return "not-affected-multiplier";
      const multiplierCell = multiplierPrize.multiplierCell;
      if (isTopReel) col = 6;
      return areCellsEqual([col, row], multiplierCell)
        ? "affected-multiplier"
        : "not-affected-multiplier";
    },
    toggleWild({ row, col }: { row: number; col: number }) {
      if (!this.currentStep) return;
      const isCandidateCell = this.currentStep?.candidateCells?.some(
        (cell) => row === cell[0] && col === cell[1]
      );
      if (!isCandidateCell || !this.currentStep) return;
      const addedWilds =
        this.currentStep.json.initialSpinAddedWilds ??
        this.currentStep.json.endOfSpinOrCascadeAddedWilds;
      if (!addedWilds) return;
      let index: number | undefined;
      for (let i = 0; i < addedWilds.length; i++) {
        const cell = addedWilds[i];
        const match = row === cell[0] && col === cell[1];
        if (match) {
          index = i;
          break;
        }
      }

      // Added wilds have a max of 7
      if (index !== undefined) addedWilds.splice(index, 1);
      else if (addedWilds.length < 7) addedWilds.push([row, col]);

      this.saveChangesToStep();
    },
    canBeClickedForCoin(
      { row, col }: { row: number; col: number },
      isTopReel: boolean
    ): boolean {
      const isCollapsing = this.isCollapsing({ row, col }, isTopReel);
      return !isCollapsing && !this.isHoldAndSpinTrigger;
    },
    // ----- Scenario persistence methods -----
    saveChangesToStep(): void {
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      updateStepsInPlace(this.allSteps);

      // Marking all the steps as having originated here, and persist them.
      this.allSteps.forEach((step) => (step.json.originatorId = originatorId));
      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    reloadStepsFromScenario(scenario?: {
      steps: Array<{ test_scenario_step_id: number; json: StepJson }>;
    }) {
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }

      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;

      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },

  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario) =>
      this.reloadStepsFromScenario(scenario)
    );

    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario) =>
      this.reloadStepsFromScenario(scenario)
    );
  },
});

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  cascadePhase?: "START" | "IN_PROGRESS" | "END";
  slotWindow?: string[][];
  topReelSlotWindow?: string[];
  collapsingCellsBySymbol?: WinCellsForSymbol[];
  freeSpinPhase?: "START" | "IN_PROGRESS" | "RETRIGGER" | "END";
  freeSpinCount?: number;
  isAbleToUseAddedWildsOnInitialSpin?: boolean;
  isAbleToUseAddedWildsOnEndingSpinOrCascade?: boolean;
  candidateCells?: number[][];
  holdAndSpinPhase?: "START" | "IN_PROGRESS" | "END";
  holdAndSpinCount?: number;
  cumulativeCoinPrizes?: CoinPrize[][];
  coinPrizesThisSpin?: CoinPrize[][];
  cumulativeCoinCount?: number;
  multiplierCoinsThisSpin?: number[][];
  multiplierIndexThisSpin?: number;
}

function generateSlotWindow(
  reels: string[][],
  height: number,
  reelStripPositions: number[]
) {
  const slotWindow = reels.map((reel, col) => {
    const rowsInColumn = height;
    const symbolsInColumn: string[] = [];
    for (let row = 0; row < rowsInColumn; ++row) {
      const position = (reelStripPositions[col] + row) % reel.length;
      symbolsInColumn[row] = reel[position];
    }
    return symbolsInColumn;
  });
  return slotWindow;
}

function updateStepsInPlace(steps: Step[]) {
  let name = "";
  let cascadePhase: Step["cascadePhase"] | undefined = undefined;
  let slotWindow: string[][] = [];
  let topReelSlotWindow: string[] = [];
  let collapsingCellsBySymbol: WinCellsForSymbol[] = [];
  let reelStripPositions: number[] = [];
  let topReelStripPosition = 0;
  let isFreeSpin = false;
  let freeSpinPhase: Step["freeSpinPhase"];
  let freeSpinCount = 0;
  let isAnyStepModified = false;
  let isAbleToUseAddedWildsOnInitialSpin = false;
  let isAbleToUseAddedWildsOnEndingSpinOrCascade = false;
  let candidateCells: number[][] = [];
  let holdAndSpinPhase: Step["holdAndSpinPhase"];
  let holdAndSpinCount = 0;
  let coinPrizes: CoinPrize[][] = new Array(7)
    .fill(0)
    .map(() => new Array(4).fill(0));
  let cumulativeCoinPrizes: CoinPrize[][] = new Array(7)
    .fill(0)
    .map(() => new Array(4).fill(0));
  let cumulativeCoinCount = 0;
  let multiplierCoins: number[][] = [];
  for (const step of steps) {
    let wins: WaysWin[];
    let multiplierCoinsThisSpin: number[][] = [];
    let coinPrizesThisSpin: CoinPrize[][] = new Array(7)
      .fill(0)
      .map(() => new Array(4).fill(0));
    let multiplierIndexThisSpin = step.multiplierIndexThisSpin;
    if (step.json.name === "Clear") {
      // Handle clearing steps by clearing all state
      name = step.json.name;
      cascadePhase = undefined;
      slotWindow = [];
      topReelSlotWindow = [];
      reelStripPositions = step.json.reelStripPositions;
      topReelStripPosition = step.json.topReelStripPosition;
      collapsingCellsBySymbol = [];
      isFreeSpin = false;
      freeSpinPhase = undefined;
      freeSpinCount = 0;
      isAbleToUseAddedWildsOnInitialSpin = false;
      isAbleToUseAddedWildsOnEndingSpinOrCascade = false;
      holdAndSpinCount = 0;
      holdAndSpinPhase = undefined;
      cumulativeCoinPrizes = new Array(7)
        .fill(0)
        .map(() => new Array(4).fill(0));
      cumulativeCoinCount = 0;
    } else {
      const isHoldAndSpinRespin = holdAndSpinCount > 0;
      if (isHoldAndSpinRespin) {
        cascadePhase = undefined;
        isAbleToUseAddedWildsOnInitialSpin = false;
        isAbleToUseAddedWildsOnEndingSpinOrCascade = false;
        const prevCoinCount = cumulativeCoinCount;
        coinPrizes =
          step.json.coinPrizes ??
          new Array(7).fill(0).map(() => new Array(4).fill(0));
        coinPrizes = [0, 1, 2, 3, 4, 5, 6].map((col) =>
          [0, 1, 2, 3].map((row) =>
            cumulativeCoinPrizes?.[col]?.[row]
              ? 0
              : coinPrizes?.[col]?.[row] || 0
          )
        );
        cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5, 6].map(
          (col) =>
            [0, 1, 2, 3].map(
              (row) =>
                cumulativeCoinPrizes?.[col]?.[row] || coinPrizes?.[col]?.[row]
            ) || 0
        );
        cumulativeCoinCount = cumulativeCoinPrizes.reduce(
          (n, cps) => n + cps.filter((cp) => !!cp).length,
          0
        );
        name = "Respin";
        holdAndSpinCount =
          cumulativeCoinCount === modelDefinition.countToTriggerGrandJackpot
            ? 0
            : cumulativeCoinCount > prevCoinCount
            ? modelDefinition.holdAndSpinRespinCount
            : holdAndSpinCount - 1;
        holdAndSpinPhase = holdAndSpinCount > 0 ? "IN_PROGRESS" : "END";
        cumulativeCoinPrizes.forEach((reel, col) =>
          reel.forEach((coinPrize, row) => {
            const newCell = [col, row];
            if (
              coinPrize === "MULTIPLIER" &&
              !multiplierCoins.some((cell) => areCellsEqual(cell, newCell))
            ) {
              multiplierCoins.push(newCell);
              multiplierCoinsThisSpin.push(newCell);
            }
          })
        );
        if (multiplierCoinsThisSpin.length > 0) {
          multiplierIndexThisSpin = multiplierIndexThisSpin ?? 0;
          if (step.json.reverseMultiplierPrizes === undefined)
            step.json.reverseMultiplierPrizes = [];
          multiplierCoinsThisSpin.forEach((cell) => {
            if (
              !step.json.reverseMultiplierPrizes?.some((prize) =>
                areCellsEqual(prize.multiplierCell, cell)
              )
            ) {
              const multiplierPrize: MultiplierPrize = {
                multiplierCell: cell,
                appliedCells: [],
              };
              step.json.reverseMultiplierPrizes?.push(multiplierPrize);
            }
          });
          step.json.reverseMultiplierPrizes = step.json.reverseMultiplierPrizes.filter(
            (prize) =>
              multiplierCoinsThisSpin.some((cell) =>
                areCellsEqual(cell, prize.multiplierCell)
              )
          );
        } else step.json.reverseMultiplierPrizes = undefined;
      } else {
        multiplierCoins = [];
        multiplierIndexThisSpin = undefined;
        const isCascade =
          cascadePhase === "IN_PROGRESS" || cascadePhase === "START";
        // Update state for cascade step
        if (isCascade) {
          const cascadeValues = generateVgw051ReelStripPositionsAndSlotWindowsFromCascade(
            slotWindow,
            reelStripPositions,
            topReelSlotWindow,
            topReelStripPosition,
            collapsingCellsBySymbol
          );
          slotWindow = cascadeValues.slotWindow;
          reelStripPositions = cascadeValues.reelStripPositions;
          topReelSlotWindow = cascadeValues.topReelSlotWindow;
          topReelStripPosition = cascadeValues.topReelStripPosition;
          isAbleToUseAddedWildsOnInitialSpin = false;
        }
        // Update state for base
        else {
          name = "Spin";
          reelStripPositions = step.json.reelStripPositions;
          topReelStripPosition = step.json.topReelStripPosition;
          isAbleToUseAddedWildsOnInitialSpin = true;
          slotWindow = generateSlotWindow(
            modelDefinition.reels,
            4,
            reelStripPositions
          );
          topReelSlotWindow = generateSlotWindow([modelDefinition.topReel], 4, [
            topReelStripPosition,
          ])[0];
        }
        // Check if any cascades have happened
        if (cascadePhase === "END") cascadePhase = undefined;
        isFreeSpin = freeSpinCount > 0;

        // Collect candidate cells and added wilds
        candidateCells = filterCandidateCellsToAddWilds(slotWindow);
        const addedWilds =
          step.json.initialSpinAddedWilds ??
          step.json.endOfSpinOrCascadeAddedWilds ??
          [];
        let wins = winChecker(slotWindow, topReelSlotWindow);
        const scatWins = scatterWinChecker(slotWindow, topReelSlotWindow);
        const coinCells = filterVgw051SlotWindow(
          topReelSlotWindow,
          slotWindow,
          (sym) => sym === modelDefinition.coinSymbol
        );
        let isNextStepCascade = wins.some(
          (waysWin) => waysWin.symbol !== modelDefinition.scatterSymbol
        );
        let isHoldAndSpinTrigger =
          coinCells.length >= modelDefinition.countToTriggerHoldAndSpin &&
          !isNextStepCascade;

        isAbleToUseAddedWildsOnEndingSpinOrCascade =
          wins.length + scatWins.length <= 0 ||
          (addedWilds.length > 0 && isCascade);

        if (isHoldAndSpinTrigger)
          isAbleToUseAddedWildsOnEndingSpinOrCascade = false;

        if (!isAbleToUseAddedWildsOnEndingSpinOrCascade)
          // Determine if we can add wilds, if not make sure the arrays are cleared, and if so apply them
          step.json.endOfSpinOrCascadeAddedWilds = undefined;
        if (!isAbleToUseAddedWildsOnInitialSpin)
          step.json.initialSpinAddedWilds = undefined;
        if (
          addedWilds.length > 0 &&
          (isAbleToUseAddedWildsOnInitialSpin ||
            isAbleToUseAddedWildsOnEndingSpinOrCascade)
        ) {
          addedWilds.forEach(([row, reel]) => {
            const sym = slotWindow[reel][row];
            if (
              sym !== scatterSymbol &&
              sym !== wildSymbol &&
              sym !== coinSymbol
            )
              slotWindow[reel][row] = wildSymbol;
          });

          // Check for cascades or wins following the added wilds
          wins = winChecker(slotWindow, topReelSlotWindow);
          isNextStepCascade = wins.some(
            (waysWin) => waysWin.symbol !== modelDefinition.scatterSymbol
          );
          if (isHoldAndSpinTrigger && isNextStepCascade)
            isHoldAndSpinTrigger = false;
        }
        const isCascadeInProgress = !!cascadePhase;
        cascadePhase = getCascadePhase(isNextStepCascade, isCascadeInProgress);
        const previousSpinCollapsingCells = [...collapsingCellsBySymbol];
        collapsingCellsBySymbol = [];
        if (cascadePhase !== undefined && cascadePhase !== "END") {
          const winCellsBySymbol = summeriser(wins);
          collapsingCellsBySymbol = winCellsBySymbol.filter(
            (winCell) => winCell.symbol !== modelDefinition.scatterSymbol
          );
        }

        // Check free spin values (triggering, in free spins, if we reduce free spin value)
        if (
          isFreeSpin &&
          (cascadePhase === undefined || cascadePhase === "START")
        ) {
          freeSpinCount -= 1;
          freeSpinPhase = freeSpinCount > 0 ? "IN_PROGRESS" : "END";
        } else if (!isFreeSpin) freeSpinPhase = undefined;
        const isFreeSpinTriggerOrRetrigger =
          scatWins.length >= modelDefinition.countToTriggerFreeSpins &&
          (cascadePhase === undefined || cascadePhase === "END");

        if (isFreeSpinTriggerOrRetrigger) {
          const additionalScatters =
            scatWins.length - modelDefinition.countToTriggerFreeSpins;
          freeSpinCount +=
            modelDefinition.initialFreeSpinsAmount +
            additionalScatters * modelDefinition.additionalFreeSpinsAmount;
          freeSpinPhase = isFreeSpin ? "RETRIGGER" : "START";
        }

        if (isHoldAndSpinTrigger) {
          holdAndSpinPhase = "START";
          holdAndSpinCount = modelDefinition.holdAndSpinRespinCount;
        } else {
          holdAndSpinPhase = undefined;
        }
        if (isCascade) {
          const newCoinPrizes: CoinPrize[][] = new Array(7)
            .fill(0)
            .map(() => new Array(4).fill(0));

          const collapsingCells: number[][] = [];
          previousSpinCollapsingCells.forEach((collapsingCell) =>
            collapsingCell.cells.forEach((cell) =>
              collapsingCells.push([cell[1], cell[0]])
            )
          );
          newCoinPrizes.forEach((prize, col) => {
            prize.forEach((_, row) => {
              const thisCell = [col, row];
              const filteredCells = collapsingCells.filter((cell) =>
                !isTopReel(thisCell)
                  ? isCellBelow(cell, thisCell)
                  : isCellAbove(cell, thisCell)
              );
              const uniqueCells: number[][] = [];
              filteredCells.forEach((cell) => {
                if (
                  !uniqueCells.some((uniqueCell) =>
                    areCellsEqual(cell, uniqueCell)
                  )
                )
                  uniqueCells.push(cell);
              });
              const affectedCellsCount = uniqueCells.length;
              const oldPrizeValue = cumulativeCoinPrizes[col][row];
              if (oldPrizeValue !== 0) {
                const newRow = !isTopReel(thisCell)
                  ? row + affectedCellsCount
                  : row - affectedCellsCount;
                newCoinPrizes[col][newRow] = cumulativeCoinPrizes[col][row];
              }
            });
          });
          coinPrizes = newCoinPrizes;
          for (const [row, col] of coinCells) {
            if (newCoinPrizes[col][row] === 0) {
              coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
              coinPrizesThisSpin[col][row] =
                step.json.coinPrizes?.[col]?.[row] || 20;
            }
          }
          cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5, 6].map((col) =>
            [0, 1, 2, 3].map((row) => coinPrizes?.[col]?.[row] || 0)
          );
          cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5, 6].map(
            (col) => [0, 1, 2, 3].map((row) => coinPrizes?.[col]?.[row]) || 0
          );
        } else {
          coinPrizes = [0, 1, 2, 3, 4, 5, 6].map((_) => [0, 0, 0, 0]);
          for (const [row, col] of coinCells)
            coinPrizes[col][row] = step.json.coinPrizes?.[col]?.[row] || 20;
          cumulativeCoinPrizes = [0, 1, 2, 3, 4, 5, 6].map((col) =>
            [0, 1, 2, 3].map((row) => coinPrizes?.[col]?.[row] || 0)
          );
          cumulativeCoinCount = cumulativeCoinPrizes.reduce(
            (n, cps) => n + cps.filter((cp) => !!cp).length,
            0
          );
          coinPrizesThisSpin = coinPrizes;
        }
      }
      // Update the name of the step
      if (freeSpinPhase === "IN_PROGRESS") name = "Free Spin";
      if (freeSpinPhase === "END") name = "F-End";
      if (cascadePhase === "START") name = "Cascade Start";
      if (cascadePhase === "IN_PROGRESS") name = "Cascade";
      if (cascadePhase === "END") name = "Cascade End";
      if (freeSpinPhase === "START") name = "F-Trigger";
      if (freeSpinPhase === "RETRIGGER") name = "F-Retrigger";
      if (holdAndSpinPhase === "START") name = "HS-Trigger";
      if (holdAndSpinPhase === "IN_PROGRESS") name = "HS-Respin";
      if (holdAndSpinPhase === "END") name = "HS-End";
    }

    step.json.reverseMultiplierPrizes = !!step.json.reverseMultiplierPrizes
      ? step.json.reverseMultiplierPrizes.sort((first, second) =>
          compareCells(first.multiplierCell, second.multiplierCell)
        )
      : undefined;

    const multiplierItems = !!step.json.reverseMultiplierPrizes
      ? [...step.json.reverseMultiplierPrizes]
      : undefined;
    step.json.multiplierPrizes = !!multiplierItems
      ? multiplierItems.map((value) => flipMultiplierPrizesCell(value))
      : undefined;

    // Save all the step details
    Object.assign(step, {
      cascadePhase,
      slotWindow,
      topReelSlotWindow,
      collapsingCellsBySymbol,
      freeSpinCount,
      freeSpinPhase,
      isAbleToUseAddedWildsOnInitialSpin,
      isAbleToUseAddedWildsOnEndingSpinOrCascade,
      candidateCells,
      holdAndSpinPhase,
      holdAndSpinCount,
      cumulativeCoinPrizes,
      coinPrizesThisSpin,
      cumulativeCoinCount,
      multiplierCoinsThisSpin,
      multiplierIndexThisSpin,
    });
    const json = {
      ...step.json,
      name,
      coinPrizes,
    };
    if (JSON.stringify(json) !== JSON.stringify(step.json)) {
      isAnyStepModified = true;
      step.json = json;
      json.originatorId = originatorId;
    }
  }
  return isAnyStepModified;
}

//TODO: Move this to another file
interface WaysWin {
  /** The symbol that has a winning pattern in the slot window. */
  symbol: string;

  /** The number of appearances of `symbol` in the winning pattern. */
  length: number;

  /** The cells making up the winning pattern. Each cell is in [row, col] format relative to the top-left of the slot window. */
  cells: Cell[];
}

const winChecker = createVgw051WaysWinChecker(modelDefinition);
const summeriser = createVgw051WinCellsBySymbolSummariser(modelDefinition);
const scatterWinChecker = createVgw051ScatterWinChecker(modelDefinition);

function getCascadePhase(
  isCascadeWin: boolean,
  isCascadeInProgress: boolean
): "START" | "IN_PROGRESS" | "END" | undefined {
  if (isCascadeWin && !isCascadeInProgress) {
    return "START";
  }
  if (isCascadeWin && isCascadeInProgress) {
    return "IN_PROGRESS";
  }
  if (!isCascadeWin && isCascadeInProgress) {
    return "END";
  }

  return undefined;
}

// Gathers the candidate cells (cells you can add wilds to)
const { coinSymbol, scatterSymbol, wildSymbol } = modelDefinition;
const isCellInFirstReel = (cell: Cell) => cell[1] === 0;

function filterCandidateCellsToAddWilds(slotWindow: string[][]): Cell[] {
  const candidateSymbols = filterSlotWindow(
    slotWindow,
    (sym, cell) =>
      sym !== scatterSymbol &&
      sym !== wildSymbol &&
      sym !== coinSymbol &&
      !isCellInFirstReel(cell)
  );
  return candidateSymbols;
}

function toCoinPrize(value: string): CoinPrize {
  const n = Number(value);
  return isNaN(n) ? value : (n as any);
}

/** Returns true if cellOnes row (0 index) is larger then cellTwos row position and they are in the same reel (1 index)*/
function isCellBelow(cellOne: number[], cellTwo: number[]) {
  return cellOne[1] > cellTwo[1] && cellOne[0] === cellTwo[0];
}

/** Returns true if cellOnes row (0 index) is smaller then cellTwos row position and they are in the same reel (1 index)*/
function isCellAbove(cellOne: number[], cellTwo: number[]) {
  return cellOne[1] < cellTwo[1] && cellOne[0] === cellTwo[0];
}

function isTopReel(cell: number[]) {
  return cell[0] === 6;
}

/** Checks cells for ascending order */
function compareCells(cellOne: number[], CellTwo: number[]) {
  if (cellOne[0] !== CellTwo[0]) return cellOne[0] - CellTwo[0];
  return cellOne[1] - CellTwo[1];
}

function flipMultiplierPrizesCell(prize: MultiplierPrize): MultiplierPrize {
  return {
    multiplierCell: prize.multiplierCell,
    appliedCells: prize.appliedCells.map((actualPrize) => {
      return {
        cell: [actualPrize.cell[1], actualPrize.cell[0]],
        multiplier: actualPrize.multiplier,
      };
    }),
  };
}
