import { createSlotWindowGenerator } from "../../../../v3/math-model";
import { modelDefinition } from "../config";

const generateBaseSlotWindow = createSlotWindowGenerator(modelDefinition);

export function generateSlotWindow(reelStripPositions: number[]) {
  const slotWindow = generateBaseSlotWindow(reelStripPositions);

  return slotWindow;
}
