var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "numberbox", staticClass: "sm-numberbox-flambingo" },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.text,
            expression: "text",
          },
        ],
        staticClass: "message",
        attrs: { placeholder: "Input numbers seprated by commas" },
        domProps: { value: _vm.text },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.text = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }