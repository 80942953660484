import { BoardStep, Colour } from ".";

export type Movement = "SINGLE" | "DOUBLE";

export type Board = ReadonlyArray<BoardStep>;

/**
 * Moves the current position of the player to the next position in a given board.
 * The board given from the model definition will have a RAINBOW option.
 * This means that when using that board, a step should always be found, as any colour can go to the RAINBOW position.
 * The asserts used are there for typing and to stop misuse of the function.
 */
export function advanceBoardPosition(
  board: Board,
  currentBoardPosition: number,
  colour: Colour,
  movement: Movement
): BoardStep {
  const steps = board.filter(
    (step) =>
      (colour === step.colour || step.colour === "RAINBOW") &&
      step.position > currentBoardPosition
  );
  const index = movement === "DOUBLE" && steps.length > 1 ? 1 : 0;
  const step = steps[index];
  const {
    colour: stepColour,
    position,
    multiplier,
    extraPicks,
    shortcut,
  } = step;
  return {
    colour: stepColour === "RAINBOW" ? colour : stepColour,
    position,
    multiplier,
    extraPicks,
    shortcut,
  };
}
