var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Slot Window): "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "slotWindow", staticClass: "slot-window card" },
      [
        _vm._l(_vm.slotWindow, function (reel, colIndex) {
          return _vm._l(reel, function (symbolName, rowIndex) {
            return _c(
              "reel-symbol",
              _vm._g(
                {
                  key: `${colIndex} ${rowIndex}`,
                  attrs: {
                    position: _vm.spinOutcome.reelStripPositions[colIndex],
                    colIndex: colIndex,
                    rowIndex: rowIndex,
                    symbolName: symbolName,
                    spinType: _vm.spinType,
                    isNuttCell: _vm.isNuttCell(rowIndex, colIndex),
                    isCandyCaneCell: _vm.isCandyCaneCell(rowIndex, colIndex),
                    stolenRoyalIndex: _vm.stolenRoyalIndex(rowIndex, colIndex),
                    isWildPathCell: _vm.isWildPathCell(rowIndex, colIndex),
                    replacementSymbols:
                      _vm.spinOutcome.stolenRoyalsReplacementSymbols,
                  },
                },
                _vm.$listeners
              )
            )
          })
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Text Output): "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("p", [
        _c("b", [_vm._v("Spin Type:")]),
        _vm._v(" " + _vm._s(_vm.spinType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Type:")]),
        _vm._v("  " + _vm._s(_vm.spinOutcome.winType)),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.isJackpotTriggered
      ? _c("div", { staticClass: "jackpot-type-selector" }, [
          _c("h2", { staticClass: "heading" }, [
            _vm._v("Jackpot Type Selector: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.step.jackpotType,
                  expression: "step.jackpotType",
                },
              ],
              staticClass: "card-generic-info",
              attrs: { id: "card-prize" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.step,
                    "jackpotType",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c(
                "option",
                { attrs: { default: "" }, domProps: { value: "GRAND" } },
                [_vm._v("GRAND")]
              ),
              _vm._v(" "),
              _c("option", { domProps: { value: "MAJOR" } }, [_vm._v("MAJOR")]),
              _vm._v(" "),
              _c("option", { domProps: { value: "MINOR" } }, [_vm._v("MINOR")]),
              _vm._v(" "),
              _c("option", { domProps: { value: "MINI" } }, [_vm._v("MINI")]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.isFreeSpins
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Free Spins Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Free Spin Phase:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.freeSpinPhase)),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Free Spin Count:")]),
              _vm._v("  " + _vm._s(_vm.spinOutcome.freeSpinCount)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBoardGame
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Board Game Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [
              _c("b", [_vm._v("Board Game Phase:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.boardGamePhase)),
            ]),
            _vm._v(" "),
            _vm.isBoardGame
              ? _c("div", [
                  _c("p", [
                    _c("b", [_vm._v("Board Game Count:")]),
                    _vm._v("  " + _vm._s(_vm.spinOutcome.boardGameCount)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Board Game Card:")]),
                    _vm._v("  " + _vm._s(_vm.spinOutcome.boardGameCard)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Board Game Step:")]),
                    _vm._v("  " + _vm._s(_vm.spinOutcome.boardGameStep)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Board Game Prize:")]),
                    _vm._v("  " + _vm._s(_vm.spinOutcome.boardGamePrize)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isJackpotTriggered
              ? _c("div", [
                  _c("p", [
                    _c("b", [_vm._v("Board Game Jackpot Win Amounts:")]),
                    _vm._v(_vm._s(_vm.spinOutcome.jackpotWinAmount)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Board Game Jackpot Win Amounts:")]),
                    _vm._v(_vm._s(_vm.spinOutcome.jackpotWinCounts)),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isWildPath
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Wild Path Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [_vm._v("Wild Path feature has been triggered")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Wild Path ID:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.wildPathId)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isMultiplier
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Multiplier Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [_vm._v("Multiplier feature has been triggered")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Multiplier Value:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.multiplier)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isStolenRoyals
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Stolen Royals Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [_vm._v("Stolen Royals has been triggered")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Stolen Royals Value:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.stolenRoyals)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isNuttPrize
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Nutt Prize Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [_vm._v("Nutt Prize has been triggered")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("Nutt Prize Symbol Selected:")]),
              _vm._v(" " + _vm._s(_vm.spinOutcome.nuttPrize)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isCandyCane
      ? _c("div", [
          _c("h2", { staticClass: "heading" }, [
            _vm._v(" Candy Cane Information: "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-generic-info" }, [
            _c("p", [_vm._v("Candy Cane has been triggered")]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.spinOutcome.candyCanePlacement))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }