import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, range} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import selectJackpotPickable from "./components/selectJackpotPickable.vue";

@Component({
    components: {
        reel: Reel,
        selectJackpotPickable: selectJackpotPickable
    },
    data: () => {
        return {
            substituteSymbol: ""
        };
    },
})
export default class Content extends Vue {  
    @Prop() public step: any;
    public isFeature = false;
    public isBonus = false;
    public isFeatureChecked = false;
    public show = false;
    public jackpotPickables;
    public jackpotPickablesIndexes;
    public isValidJackpotPickables = true;
    
    public nRows = State.state.getModelDefinition().reelsLayout[0];
    constructor() {
        super();
        this.jackpotPickables = new Array(12).fill(0);
        this.jackpotPickablesIndexes = range(this.jackpotPickables.length);
    }
    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;

        this.isBonus = step.json.isBonusAvailable ? step.json.isBonusAvailable : false;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.$nextTick(() => bus.$emit("step-updated", step));
        this.$data.substituteSymbol = step.json.substituteSymbol ? step.json.substituteSymbol : "";

        if (this.isBonus && step.json.jackpotPickables) {
            this.jackpotPickables = step.json.jackpotPickables;
        } else {
            this.jackpotPickables = new Array(12).fill(0)
        }

        this.isValidJackpotPickables = this.validateJackpotPickables();
    }

    private validateJackpotPickables() : boolean {
        let isValid : boolean = false;
        
        let cleanedPickables = this.jackpotPickables.filter((p) => p !== 0);

        if (cleanedPickables != null && cleanedPickables.length > 0) {
            let jackpots = {};
            
            cleanedPickables.forEach((p) => {
                jackpots[p] ? jackpots[p]++ : jackpots[p] = 1;
            });

            for (let type in jackpots) {
                if (jackpots[type] >= 3) {
                    isValid = true;
                    break;
                }
            }
        } else {
            isValid = true;
        }
        
        return isValid;
    }
    
    public mounted() {
        trace("reels mounted");
    }
    
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.step.json.isFeature = this.isFeature;
        this.checkChangesInStep();
    }
    
    public addBaseStep() {
        bus.$emit(events.ADD_STEP, State.state.getBaseStep() );
    }

    public addStacksStep() {
        bus.$emit(events.ADD_STEP, State.state.getStacksStep() );
    }

    public addMultiplierStep() {
        bus.$emit(events.ADD_STEP, State.state.getMultiplierStep() );
    }
    
    public addFeatureStep() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureStep() );
    }
    
    public addFeatureTriggerStep() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureTrigger() );
    }
    
    public addFeatureRetrigger() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureReTriggerStep() );
    }
    
    public addBonusTriggerStep() {
        bus.$emit(events.ADD_STEP, State.state.getBonusTriggerStep() );
    }
    
    public checkChangesInStep() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusJackpotPickable(event) {
        this.createJackpotPickablesSafe();
        this.step.json.jackpotPickables[event.target.id] = 
            event.target.value == 0 ? parseInt(event.target.value) : event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateSubstituteSymbol() {
        this.step.json.substituteSymbol = this.$data.substituteSymbol;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private createJackpotPickablesSafe() {
        if (this.step.json.jackpotPickables == null) {
            this.step.json.jackpotPickables = new Array(12).fill(0);
        }
    }
}
