import { mathModel } from "../../../v3";
import { Choices, SpinType } from "../choices";
import { modelDefinition } from "../config";
import { Wild } from "../wild";

export function updateSlotWindowWithWilds(
  spinType: Exclude<SpinType, "BASE">,
  coinTypeId: mathModel.CoinType,
  totalPlayAmount: number,
  choices: Choices,
  baseSlotWindow: string[][],
  existingWilds: Wild[]
) {
  let slotWindow = substituteWilds(baseSlotWindow, existingWilds);
  const newWilds = findNewWilds(
    spinType,
    coinTypeId,
    totalPlayAmount,
    slotWindow,
    choices
  );
  slotWindow = substituteWilds(slotWindow, newWilds);
  const wilds = existingWilds.concat(newWilds);

  return { slotWindow, wilds, newWilds };
}

const { becomesWildSymbol, wildSymbol } = modelDefinition;

function substituteWilds(slotWindow: string[][], wilds: Wild[]) {
  const newSlotWindow = slotWindow.map((reelWindow) => [...reelWindow]);

  for (const wild of wilds) {
    newSlotWindow[wild.cell[1]][wild.cell[0]] = wildSymbol;
  }

  return newSlotWindow;
}

function findNewWilds(
  spinType: Exclude<SpinType, "BASE">,
  coinTypeId: mathModel.CoinType,
  totalPlayAmount: number,
  slotWindow: string[][],
  choices: Choices
): Wild[] {
  const newWildCells = mathModel.filterSlotWindow(slotWindow, shouldBecomeWild);

  const multipliers = choices.chooseWildMultipliers(
    spinType,
    newWildCells.length
  );
  const jackpotsAdded = choices.chooseIfJackpotsAreAdded(spinType, multipliers);
  const jackpotTypes = choices.chooseJackpotTypes(
    coinTypeId,
    totalPlayAmount,
    jackpotsAdded
  );

  return newWildCells.map((cell, index) => {
    if (multipliers[index] === undefined) {
      throw new Error("Wild multiplier was not defined.");
    }

    return {
      cell,
      multiplier: multipliers[index],
      jackpotType: jackpotTypes[index],
    };
  });
}

function shouldBecomeWild(symbol: string, cell: mathModel.Cell): boolean {
  return symbol === becomesWildSymbol && cell[1] !== 0 && cell[1] !== 4;
}
