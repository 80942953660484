import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ['index', 'coinValue'],
})
export default class selectCoinMultiplier extends Vue {
    constructor() {
        super();
    }

    updateCoin() {
        console.log("called updateCoin");
        (<Content>this.$parent).updateBonusGameCoinMultipliers(event);
    }
}
