var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _vm.spinType !== "WHEEL_SPIN"
      ? _c("h2", { staticClass: "heading" }, [
          _vm._v("Scenario Result (Slot Window): "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.spinType !== "WHEEL_SPIN"
      ? _c(
          "div",
          { ref: "slotWindow", staticClass: "slot-window card" },
          [
            _vm._l(_vm.spinOutcome.slotWindow, function (reel, colIndex) {
              return _vm._l(reel, function (symbolName, rowIndex) {
                return _c(
                  "reel-symbol",
                  _vm._g(
                    {
                      key: `${colIndex} ${rowIndex}`,
                      attrs: {
                        position: _vm.spinOutcome.reelStripPositions[colIndex],
                        colIndex: colIndex,
                        rowIndex: rowIndex,
                        symbolName: symbolName,
                        spinType: _vm.spinType,
                        "is-win-cell": _vm.isWinCell(rowIndex, colIndex),
                      },
                    },
                    _vm.$listeners
                  )
                )
              })
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("h2", { staticClass: "heading" }, [
      _vm._v("Scenario Result (Text Output): "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-generic-info" }, [
      _c("p", [
        _c("b", [_vm._v("Spin Type:")]),
        _vm._v(" " + _vm._s(_vm.spinType)),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Win Type:")]),
        _vm._v("  " + _vm._s(_vm.spinOutcome.winType)),
      ]),
    ]),
    _vm._v(" "),
    _vm.isFreeSpins
      ? _c("div", { staticClass: "card-generic-info" }, [
          _c("p", [
            _c("b", [_vm._v("Free Spin Phase:")]),
            _vm._v(" " + _vm._s(_vm.spinOutcome.freeSpinPhase)),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Free Spin Count:")]),
            _vm._v("  " + _vm._s(_vm.spinOutcome.freeSpinCount)),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Free Spin Multiplier:")]),
            _vm._v("  " + _vm._s(_vm.spinOutcome.freeSpinsMultiplier)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.spinType !== "WHEEL_SPIN"
      ? _c("div", { staticClass: "card-generic-info" }, [
          _c("p", [
            _c("b", [_vm._v("Is Super Drum:")]),
            _vm._v(" " + _vm._s(_vm.spinOutcome.isSuperDrumAwarded)),
          ]),
          _vm._v(" "),
          _c("p", [
            _c(
              "b",
              {
                directives: [
                  {
                    name: "id",
                    rawName: "v-id",
                    value: _vm.spinOutcome.isSuperDrumAwarded,
                    expression: "spinOutcome.isSuperDrumAwarded",
                  },
                ],
              },
              [_vm._v("Super Drum Multiplier:")]
            ),
            _vm._v("  " + _vm._s(_vm.spinOutcome.superDrumMultiplier)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }