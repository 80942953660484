var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h3", [_vm._v("New Steps")]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.addSpinStep()
            },
          },
        },
        [_vm._v("Step")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.addSpinStep("clear")
            },
          },
        },
        [_vm._v("Clearing Step")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.addSpinStep("wild-fury")
            },
          },
        },
        [_vm._v("Clearing WF Trigger")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.addSpinStep("free-spin")
            },
          },
        },
        [_vm._v("Clearing FS Trigger")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }