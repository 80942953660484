import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import type { InitArguments, InitOutcome } from '@vgw/gdk-math-model-server';
import type { Choices } from '../../choices';
import type { GameState } from '../shared';
import { createSlotWindowGenerator } from '@vgw/gdk-math-model-tools';

import { modelDefinition } from '../../model-definition';

export function init({ coinType, gameState }: InitArguments<GameState>, choices: Choices): InitOutcome<InitResponse> {
    if (gameState) {
        return {
            initResponse: {
                reelStripPositions: gameState.reelStripPositions,
                slotWindow: gameState.slotWindow,
                cumulativeWinAmount: gameState?.cumulativeWinAmount,
                freeSpinPhase: gameState?.freeSpinPhase,
                freeSpinCount: gameState?.freeSpinCount,
                modelDefinition: {
                    validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                    reels: modelDefinition.reels,
                },
            },
        };
    }

    const reelStripPositions = choices.chooseReelStripPositions();
    const slotWindow = generateSlotWindow(reelStripPositions);

    return {
        initResponse: {
            reelStripPositions,
            slotWindow,
            modelDefinition: {
                validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                reels: modelDefinition.reels,
            },
        },
    };
}

export const InitResponseSchema = Type.Object({
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),
    cumulativeWinAmount: Type.Optional(Type.Integer()),
    freeSpinPhase: Type.Optional(
        Type.Union([
            Type.Literal('START'),
            Type.Literal('IN_PROGRESS'),
            Type.Literal('RETRIGGER'),
            Type.Literal('END'),
        ]),
    ),
    freeSpinCount: Type.Optional(Type.Integer()),
    modelDefinition: Type.Object({
        validCoinAmounts: Type.Array(Type.Number()),
        reels: Type.Array(Type.Array(Type.String())),
    }),
});
export type InitResponse = Static<typeof InitResponseSchema>;

const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
