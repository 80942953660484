import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

export const GameResponseSchema = Type.Object({
    coinAmount: Type.Number(),
    playAmount: Type.Integer(),
    winAmount: Type.Integer(),
    cumulativeWinAmount: Type.Optional(Type.Number()),

    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),

    waysWins: Type.Array(Type.Number()),
    winCellsBySymbol: Type.Array(
        Type.Object({
            cells: Type.Array(Type.Array(Type.Integer(), Type.Integer())),
            symbol: Type.String(),
        }),
    ),
    scatterWin: Type.Optional(
        Type.Object({
            cells: Type.Array(Type.Tuple([Type.Integer(), Type.Integer()])),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
            superStarMultiplier: Type.Optional(Type.Integer()),
        }),
    ),
    bonusWin: Type.Optional(
        Type.Object({
            cells: Type.Array(Type.Tuple([Type.Integer(), Type.Integer()])),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
        }),
    ),
    winType: Type.Union([
        Type.Literal('NO_WIN'),
        Type.Literal('NORMAL_WIN'),
        Type.Literal('FREE_SPIN_NO_WIN'),
        Type.Literal('FREE_SPIN_WIN'),
        Type.Literal('SCATTER_WIN'),
        Type.Literal('BONUS_WIN'),
        Type.Literal('JACKPOT_WIN'),
    ]),

    freeSpinPhase: Type.Optional(
        Type.Union([
            Type.Literal('START'),
            Type.Literal('IN_PROGRESS'),
            Type.Literal('RETRIGGER'),
            Type.Literal('END'),
        ]),
    ),
    freeSpinCount: Type.Optional(Type.Integer()),
    jackpotWinCounts: Type.Optional(
        Type.Object({
            GRAND: Type.Integer(),
        }),
    ),
    isSuperStarAwarded: Type.Boolean(),
});
export type GameResponse = Static<typeof GameResponseSchema>;
