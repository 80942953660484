import type { Choices } from '../../choices';
import type { SpinOutcome } from './outcome';
import type { SpinInputs } from './inputs';
import { assertSpinInputsValid } from './inputs';
import { spinFree } from './spin-free';
import { spin } from './spin';

export { GameRequestSchema, type GameRequest } from './game-request';
export { GameResponseSchema, type GameResponse } from './game-response';
export type { SpinOutcome, SpinInputs };

export function play({ coinType, gameRequest, gameState }: SpinInputs, choices: Choices): SpinOutcome {
    assertSpinInputsValid({ coinType, gameRequest, gameState });

    const isFreeSpins = gameState !== undefined;

    if (isFreeSpins) {
        return spinFree({ coinType, gameRequest, gameState }, choices);
    } else {
        return spin({ coinType, gameRequest }, choices);
    }
}
