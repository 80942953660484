import { createClearingStep } from "./steps";
import State from "../../../state";
import { modelDefinition } from "./model-definition";

export function setStateParams() {
  return {
    variations: [],

    // None Game Specific Logic:
    createDefaultStep: () => createClearingStep(),
    getLayout: () => State.state.getModelDefinition().reelsLayout,

    // Game Specific Logic (VGW092):
    getModelDefinition: () => modelDefinition,
    getNumberOfReels: () => 5,
    getNumberOfRows: () => 5,
    getReelStrips: () => [["PIC1"], ["PIC2"], ["PIC3"], ["PIC4"], ["PIC5"]],
  };
}
