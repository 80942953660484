import {
  FeatureColour,
  GenieBonusLamps,
} from "../../../../components/game_content/slots/questOfThe3Lamps/model/src/operations/shared";
import { CoinPrizesScenario } from "../../../../components/game_content/slots/questOfThe3Lamps/model/src/choices/create-choices-from-scenario";
import { modelDefinition } from "./model-definition";

export const originatorId = new Date().getTime();

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  mysterySymbol: string;
  genieBonusLamps?: GenieBonusLamps;
  coinPrizes?: CoinPrizesScenario;
  triggeringColours?: FeatureColour[];
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [11, 18, 40, 36, 18],
    mysterySymbol: "K",
  };
}
