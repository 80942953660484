import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ["index", "expandingSymbols"],
})
export default class ExpandingSymbol extends Vue {
    constructor() {
        super();
    }
    updateExpandingSymbol(index) {
        (<Content>this.$parent).updateExpandingSymbol(index, event);
    }
}
