var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c(
        "div",
        { class: _vm.canUpdate ? "" : "hide", attrs: { align: "center" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-up",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", [
            _c(
              "div",
              {
                class: [
                  "symbol",
                  "on",
                  sym,
                  _vm.content.isCheeseAt({ row, col: _vm.col })
                    ? "CHEESE"
                    : "NONDAIRY",
                ],
              },
              [
                !_vm.content.isCheeseAt({ row, col: _vm.col })
                  ? _c("div", { staticClass: "text" }, [
                      !_vm.content.canSetOrUnsetCheeseAt({ row, col: _vm.col })
                        ? _c("div", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(sym) +
                                "\n                    "
                            ),
                          ])
                        : _c(
                            "div",
                            {
                              staticStyle: { "margin-top": "4px" },
                              on: {
                                click: function ($event) {
                                  return _vm.content.setCheeseAt({
                                    row,
                                    col: _vm.col,
                                    value: 1,
                                  })
                                },
                              },
                            },
                            [_vm._m(0, true)]
                          ),
                    ])
                  : _c("div", { staticClass: "text" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "cb",
                            _vm.content.cheeseBlockClassAt({
                              row,
                              col: _vm.col,
                            }),
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.content.showPrizeAt({
                                    row,
                                    col: _vm.col,
                                  }),
                                  expression: "content.showPrizeAt({row, col})",
                                },
                              ],
                              staticClass: "select-style",
                            },
                            [
                              _c(
                                "select",
                                {
                                  domProps: {
                                    value: _vm.content.getPrizeAt({
                                      row,
                                      col: _vm.col,
                                    }),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.content.setPrizeAt({
                                        row,
                                        col: _vm.col,
                                        value: $event.target.value,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.content.prizeOptionsAt({
                                      row,
                                      col: _vm.col,
                                    }),
                                    function (opt) {
                                      return [
                                        _c(
                                          "option",
                                          { domProps: { value: opt } },
                                          [_vm._v("🎁 " + _vm._s(opt))]
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v("\n                    🧀\n                    "),
                      _vm.content.canSetOrUnsetCheeseAt({ row, col: _vm.col })
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.content.setCheeseAt({
                                      row,
                                      col: _vm.col,
                                      value: 0,
                                    })
                                  },
                                },
                              },
                              [_vm._m(1, true)]
                            ),
                          ])
                        : _vm._e(),
                    ]),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-down",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("( "),
      _c("i", { staticClass: "fas fa-2x fa-mouse-pointer" }),
      _vm._v(" click for cheese)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("( "),
      _c("i", { staticClass: "fas fa-2x fa-mouse-pointer" }),
      _vm._v(" remove)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }