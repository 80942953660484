// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol[data-v-6923d52d] {
    flex-direction: column;
    border-radius: 10px;
    background: #707070;
    box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;
}
.current-name[data-v-6923d52d] {
    margin: 0;
    padding: 0;
}
.original-name[data-v-6923d52d] {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    color: #dddddd;
}
.nuttCell[data-v-6923d52d]{
    border: 3px solid #C4A484;
}
.candyCaneCell[data-v-6923d52d]{
    background-color: #D6001C! important;
}
.candyCaneCell[data-v-6923d52d]:nth-child(odd) {
    background-color: white ! important;
}
.stolenRoyalsReplacementSymbols[data-v-6923d52d]{
    border: 3px solid rgb(169, 28, 169);
}
.isWildPathCell[data-v-6923d52d]{
    border: 3px solid greenyellow;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/slot-window/reel-symbol/reel-symbol.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,kEAAkE;AACtE;AACA;IACI,SAAS;IACT,UAAU;AACd;AACA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,oCAAoC;AACxC;AACA;IACI,mCAAmC;AACvC;AACA;IACI,mCAAmC;AACvC;AACA;IACI,6BAA6B;AACjC","sourcesContent":[".symbol[data-v-6923d52d] {\n    flex-direction: column;\n    border-radius: 10px;\n    background: #707070;\n    box-shadow: inset 5px 5px 6px #676767, inset -5px -5px 6px #797979;\n}\n.current-name[data-v-6923d52d] {\n    margin: 0;\n    padding: 0;\n}\n.original-name[data-v-6923d52d] {\n    margin: 0;\n    padding: 0;\n    font-size: 0.8em;\n    color: #dddddd;\n}\n.nuttCell[data-v-6923d52d]{\n    border: 3px solid #C4A484;\n}\n.candyCaneCell[data-v-6923d52d]{\n    background-color: #D6001C! important;\n}\n.candyCaneCell[data-v-6923d52d]:nth-child(odd) {\n    background-color: white ! important;\n}\n.stolenRoyalsReplacementSymbols[data-v-6923d52d]{\n    border: 3px solid rgb(169, 28, 169);\n}\n.isWildPathCell[data-v-6923d52d]{\n    border: 3px solid greenyellow;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
