/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId" : "vgw027",
        "reelsLayout" : [ 3, 3, 3, 3, 3 ],
        "scatterSymbol" : "SCAT",
        "wildSymbol" : "WILD",
        "freeSpinMultiplier" : 0,
        "freeSpinCount" : 15,
        "winTable" : [ {
            "symbol" : "pic1",
            "multipliers" : [ 250, 40, 10 ]
        }, {
            "symbol" : "pic2",
            "multipliers" : [ 250, 40, 10 ]
        }, {
            "symbol" : "pic3",
            "multipliers" : [ 150, 30, 8 ]
        }, {
            "symbol" : "pic4",
            "multipliers" : [ 150, 30, 8 ]
        }, {
            "symbol" : "pic5",
            "multipliers" : [ 125, 20, 6 ]
        }, {
            "symbol" : "pic6",
            "multipliers" : [ 125, 20, 6 ]
        }, {
            "symbol" : "Heart",
            "multipliers" : [ 60, 15, 5 ]
        }, {
            "symbol" : "Diamond",
            "multipliers" : [ 60, 15, 5 ]
        }, {
            "symbol" : "Spade",
            "multipliers" : [ 60, 15, 5 ]
        }, {
            "symbol" : "Club",
            "multipliers" : [ 60, 15, 5 ]
        }, {
            "symbol" : "SCAT",
            "multipliers" : [ 200, 10, 3 ]
        }, {
            "symbol" : "WILD",
            "multipliers" : [ 2000, 50, 10 ]
        } ],
        "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ] ],
        "reels" : [ [ "SCAT", "Heart", "pic4", "Diamond", "pic1", "Heart", "Spade", "pic5", "pic6", "pic2", "Diamond", "pic3", "pic1", "Heart", "pic2", "WILD", "Club", "camera", "camera", "camera", "camera", "pic1" ], [ "pic2", "Spade", "Club", "pic4", "Diamond", "Club", "pic4", "pic5", "pic6", "pic1", "pic3", "Club", "Diamond", "pic4", "pic2", "Heart", "SCAT", "Heart", "pic1", "pic3", "Diamond", "WILD", "Club", "pic1", "camera", "camera", "camera", "camera", "pic1" ], [ "Spade", "Club", "pic4", "Heart", "pic2", "Heart", "pic3", "pic5", "pic6", "Spade", "pic1", "Heart", "pic1", "Heart", "pic1", "Spade", "Diamond", "SCAT", "Spade", "WILD", "pic3", "Spade", "pic1", "pic3", "pic1", "camera", "camera", "camera", "camera", "camera", "pic1" ], [ "Diamond", "Heart", "pic1", "Heart", "Club", "WILD", "pic1", "pic5", "pic6", "pic2", "Club", "pic4", "pic2", "Spade", "SCAT", "Diamond", "Club", "pic4", "Spade", "WILD", "pic1", "pic2", "pic3", "pic1", "pic2", "pic3", "Diamond", "pic1", "camera", "camera", "camera", "camera", "pic1" ], [ "pic2", "Spade", "SCAT", "Club", "pic3", "Heart", "pic4", "pic5", "pic6", "Heart", "Diamond", "WILD", "Club", "Diamond", "WILD", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "Diamond", "pic1", "Spade", "Heart", "pic4", "Diamond", "pic1", "Heart", "pic2", "Diamond", "pic1", "Spade", "pic1", "camera", "camera", "camera", "camera", "pic1" ] ],
        "defaultSelectLine" : 25,
        "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values" : [ 1, 2, 5, 10, 50 ],
        "coinType1_min_bet" : 250,
        "coinType1_max_bet" : 100000,
        "coinType1_spin_max_bet" : 2500000,
        "coinType4_min_bet" : 1,
        "coinType4_max_bet" : 50,
        "coinType4_spin_max_bet" : 1250,
        "winLevels" : [ {
            "name" : "normal1",
            "threshold" : 5
        }, {
            "name" : "normal2",
            "threshold" : 15
        }, {
            "name" : "normal3",
            "threshold" : 25
        }, {
            "name" : "bigwin",
            "threshold" : 50
        }, {
            "name" : "megawin",
            "threshold" : 150
        }, {
            "name" : "epicwin",
            "threshold" : 2147483647
        } ],
        "freeSpinTrigger" : "SCAT",
        "countToTriggerFreeSpin" : 3,
        "blankSymbol" : "camera",
        "replacementSymbols" : [ {
            "symbol" : "pic1",
            "weight" : 11
        }, {
            "symbol" : "pic2",
            "weight" : 18
        }, {
            "symbol" : "pic4",
            "weight" : 21
        }, {
            "symbol" : "pic6",
            "weight" : 25
        }, {
            "symbol" : "pic3",
            "weight" : 29
        }, {
            "symbol" : "pic5",
            "weight" : 30
        } ],
        "scatterMinCountToDoublePrices" : 3,
        "currency_symbol" : "$",
        "normal_count" : "2"
    },
    "modelId" : "vgw027",
    "gameSessionId" : 1,
    "reelStripPositions" : [ 13, 23, 4, 21, 4 ],
    "slotWindow" : [ [ "Heart", "pic2", "WILD" ], [ "pic1", "camera", "camera" ], [ "pic2", "Heart", "pic3" ], [ "pic2", "pic3", "pic1" ], [ "pic3", "Heart", "pic4" ] ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 206529343,
    "sweeps_deposit_balance" : 200001395,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 6527948,
    "sweeps_available_balance" : 6527948,
    "serverTime" : "2018-09-27 23:49:25"
}
