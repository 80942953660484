export const modelDefinition = {
    // prettier-ignore
    reels: [
        ['PIC1', 'PIC1', 'PIC1', 'A', 'PIC5', 'J', 'SCAT', '10', 'PIC3', 'K', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC5', 'Q', 'PIC6', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'PIC4', 'PIC6', 'K', 'PIC1', 'PIC5', 'A', 'WILD', 'WILD', 'WILD', 'K', 'PIC2', 'Q', 'PIC3', 'PIC4', 'K', 'PIC5', 'PIC5', 'PIC5', 'J', 'WILD', 'A', 'PIC6', 'PIC4', '10', 'PIC3', 'PIC3', 'PIC3', 'A', 'PIC6', 'SCAT', '10', 'PIC5', 'PIC1', 'PIC2', 'K', 'PIC6', 'PIC6', 'PIC6', 'A', 'PIC4', '10', 'PIC1', 'A', 'PIC5', 'J', 'SCAT', '10', 'PIC3', 'K', 'PIC4', 'A', 'PIC1', 'PIC5', 'Q', 'PIC6', 'K', 'PIC2', 'A', 'PIC4', 'PIC6', 'PIC3', 'K', 'PIC1', 'A', 'PIC5', 'K', 'PIC2', 'Q', 'PIC3', 'PIC4', 'K', 'PIC5', 'J', 'WILD', 'A', 'PIC6', 'PIC4', '10', 'PIC3', 'A', 'PIC2', 'PIC6', '10', 'PIC5', 'PIC1', 'PIC2', 'K', 'PIC6', 'A', 'PIC4', '10'],
        ['PIC2', 'PIC2', 'PIC2', 'K', 'PIC6', '10', 'SCAT', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC5', 'PIC5', 'PIC5', 'A', 'PIC2', '10', 'PIC5', 'PIC4', 'A', 'PIC3', 'PIC3', 'PIC3', 'K', 'SCAT', 'PIC5', 'J', 'PIC6', 'K', 'WILD', 'WILD', 'WILD', 'Q', 'PIC3', '10', 'PIC1', 'PIC5', 'A', 'PIC6', 'PIC6', 'PIC6', '10', 'PIC4', 'A', 'PIC1', 'PIC1', 'PIC1', 'K', 'PIC6', 'WILD', 'J', 'PIC3', 'PIC4', 'A', 'PIC1', 'PIC6', 'Q', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC5', 'A', 'PIC2', 'K', 'PIC6', '10', 'SCAT', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC5', 'A', 'PIC2', 'PIC3', '10', 'PIC5', 'PIC4', 'A', 'PIC3', 'K', 'PIC5', 'J', 'PIC2', 'K', 'PIC6', 'Q', 'PIC3', '10', 'PIC5', 'A', 'PIC6', '10', 'PIC4', 'A', 'PIC1', 'K', 'PIC6', 'WILD', 'J', 'PIC3', 'PIC4', 'A', 'PIC1', 'PIC6', 'Q', 'PIC4', 'K', 'PIC5', 'A'],
        ['PIC3', 'PIC3', 'PIC3', 'A', 'PIC4', 'K', 'PIC5', 'A', 'PIC2', 'K', 'PIC6', 'PIC6', 'PIC6', '10', 'PIC3', 'J', 'PIC5', 'A', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC6', '10', 'PIC3', 'PIC4', 'Q', 'WILD', 'WILD', 'WILD', 'J', 'PIC1', 'A', 'PIC2', 'PIC6', 'K', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC5', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'WILD', '10', 'PIC1', 'PIC5', 'SCAT', 'K', 'PIC4', 'A', 'PIC5', 'PIC5', 'PIC5', '10', 'PIC6', 'K', 'PIC3', 'A', 'PIC4', 'K', 'SCAT', 'A', 'PIC2', 'K', 'PIC6', '10', 'PIC3', 'J', 'PIC5', 'A', 'PIC1', 'Q', 'PIC6', '10', 'PIC3', 'PIC4', 'Q', 'WILD', 'J', 'PIC1', 'A', 'PIC2', 'PIC6', 'K', 'PIC4', 'A', 'PIC5', 'K', 'PIC2', 'A', 'WILD', '10', 'PIC1', 'PIC5', 'SCAT', 'K', 'PIC4', 'A', 'PIC5', '10', 'PIC6', 'K'],
        ['PIC1', 'PIC1', 'PIC1', 'J', 'PIC5', 'K', 'SCAT', 'Q', 'PIC3', '10', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC1', '10', 'PIC6', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC4', 'A', 'PIC1', 'PIC5', 'J', 'WILD', 'WILD', 'WILD', '10', 'PIC2', 'K', 'PIC3', 'PIC4', '10', 'PIC5', 'PIC5', 'PIC5', 'K', 'WILD', 'J', 'PIC6', 'Q', 'PIC3', 'PIC3', 'PIC3', 'J', 'WILD', 'A', 'PIC2', 'PIC6', 'SCAT', 'Q', 'PIC5', '10', 'PIC6', 'PIC6', 'PIC6', 'A', 'PIC4', 'Q', 'PIC1', 'J', 'PIC5', 'K', 'SCAT', 'Q', 'PIC3', '10', 'PIC4', 'A', 'PIC1', '10', 'PIC6', 'K', 'PIC2', 'J', 'PIC4', 'A', 'PIC1', 'J', 'PIC5', '10', 'PIC2', 'K', 'PIC3', 'PIC4', '10', 'PIC5', 'K', 'WILD', 'J', 'PIC6', 'Q', 'PIC3', 'J', 'WILD', 'A', 'PIC2', 'PIC6', 'Q', 'PIC5', '10', 'PIC6', 'A', 'PIC4', 'Q'],
        ['PIC2', 'PIC2', 'PIC2', '10', 'PIC6', 'Q', 'SCAT', 'J', 'PIC1', 'A', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC2', 'A', 'PIC4', 'Q', 'PIC3', 'PIC3', 'PIC3', '10', 'PIC5', 'K', 'PIC2', 'PIC6', '10', 'WILD', 'WILD', 'WILD', 'A', 'PIC3', 'Q', 'PIC1', 'PIC5', 'A', 'PIC6', 'PIC6', 'PIC6', 'Q', 'WILD', '10', 'PIC4', 'J', 'PIC1', 'PIC1', 'PIC1', '10', 'WILD', 'K', 'PIC3', 'PIC4', 'SCAT', 'J', 'PIC6', 'A', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC5', 'J', 'PIC2', '10', 'PIC6', 'Q', 'SCAT', 'J', 'PIC1', 'A', 'PIC5', 'K', 'PIC2', 'A', 'PIC4', 'Q', 'PIC3', '10', 'PIC5', 'K', 'PIC2', '10', 'PIC6', 'A', 'PIC3', 'Q', 'PIC1', 'PIC5', 'A', 'PIC6', 'Q', 'WILD', '10', 'PIC4', 'J', 'PIC1', '10', 'WILD', 'K', 'PIC3', 'PIC4', 'SCAT', 'J', 'PIC6', 'A', 'PIC4', 'K', 'PIC5', 'J'],
    ],

    reelsLayout: [3, 3, 3, 3, 3],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [1, 0, 0, 0, 1], // 12
        [1, 2, 2, 2, 1], // 13
        [0, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 2], // 15
        [1, 1, 0, 1, 1], // 16
        [1, 1, 2, 1, 1], // 17
        [0, 2, 0, 2, 0], // 18
        [2, 0, 2, 0, 2], // 19
        [0, 2, 2, 2, 0], // 20
    ],

    coinAmountMultiplier: 20,
    validCoinAmounts: {
        SC: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2500],
        GC: [100, 500, 1000, 2500, 5000, 10000, 25000, 50000],
    },

    winTable: [
        {
            symbol: 'WILD',
            multipliers: [200, 50, 10],
        },
        {
            symbol: 'PIC1',
            multipliers: [100, 30, 10],
        },
        {
            symbol: 'PIC2',
            multipliers: [100, 30, 10],
        },
        {
            symbol: 'PIC3',
            multipliers: [100, 30, 10],
        },
        {
            symbol: 'PIC4',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'PIC5',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'PIC6',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'A',
            multipliers: [20, 5, 3],
        },
        {
            symbol: 'K',
            multipliers: [20, 5, 3],
        },
        {
            symbol: 'Q',
            multipliers: [20, 5, 3],
        },
        {
            symbol: 'J',
            multipliers: [20, 5, 3],
        },
        {
            symbol: '10',
            multipliers: [20, 5, 3],
        },
    ],

    scatterWinTable: [
        {
            symbol: 'SCAT',
            multipliers: [undefined, undefined, 2, 10, 100],
        },
    ],

    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',

    freeSpinRetriggerCount: 7,

    fullReelSpinFeatureReelWeights: [
        { outcome: 1, weight: 30 },
        { outcome: 2, weight: 30 },
        { outcome: 3, weight: 6 },
        { outcome: 4, weight: 1 },
    ],
    reelSpinFeatureSetWeights: {
        base: [
            { outcome: 0, weight: 9000 },
            { outcome: 1, weight: 160 },
            { outcome: 2, weight: 160 },
            { outcome: 3, weight: 160 },
            { outcome: 4, weight: 160 },
            { outcome: 5, weight: 60 },
            { outcome: 6, weight: 60 },
            { outcome: 7, weight: 60 },
            { outcome: 8, weight: 60 },
            { outcome: 9, weight: 60 },
            { outcome: 10, weight: 60 },
            { outcome: 11, weight: 24 },
            { outcome: 12, weight: 24 },
            { outcome: 13, weight: 24 },
            { outcome: 14, weight: 24 },
            { outcome: 15, weight: 5 },
        ],
        freeSpin: [
            { outcome: 0, weight: 1300 },
            { outcome: 1, weight: 160 },
            { outcome: 2, weight: 166 },
            { outcome: 3, weight: 160 },
            { outcome: 4, weight: 160 },
            { outcome: 5, weight: 50 },
            { outcome: 6, weight: 50 },
            { outcome: 7, weight: 50 },
            { outcome: 8, weight: 50 },
            { outcome: 9, weight: 50 },
            { outcome: 10, weight: 50 },
            { outcome: 11, weight: 5 },
            { outcome: 12, weight: 5 },
            { outcome: 13, weight: 5 },
            { outcome: 14, weight: 5 },
            { outcome: 15, weight: 1 },
        ],
    },
    reelSpinFeatureSets: [
        { featureId: 0, name: 'NO FEATURE', features: [] },
        { featureId: 1, name: 'FULL REEL WILDS (FRW)', features: ['FRW'] },
        { featureId: 2, name: 'SINGLE WILDS (SW)', features: ['SW'] },
        { featureId: 3, name: 'CHARACTER WILDS (CW)', features: ['CW'] },
        { featureId: 4, name: 'MULTIPLIERS (M)', features: ['M'] },
        { featureId: 5, name: 'FRW & SW', features: ['FRW', 'SW'] },
        { featureId: 6, name: 'FRW & CW', features: ['FRW', 'CW'] },
        { featureId: 7, name: 'FRW & M', features: ['FRW', 'M'] },
        { featureId: 8, name: 'SW & CW', features: ['CW', 'SW'] },
        { featureId: 9, name: 'SW & M', features: ['SW', 'M'] },
        { featureId: 10, name: 'CW & M', features: ['CW', 'M'] },
        { featureId: 11, name: 'FRW & SW & CW', features: ['FRW', 'CW', 'SW'] },
        { featureId: 12, name: 'FRW & SW & M', features: ['FRW', 'SW', 'M'] },
        { featureId: 13, name: 'FRW & CW & M', features: ['FRW', 'CW', 'M'] },
        { featureId: 14, name: 'SW & CW & M', features: ['CW', 'SW', 'M'] },
        { featureId: 15, name: 'FRW & SW & CW & M', features: ['FRW', 'CW', 'SW', 'M'] },
    ],

    reelSpinChanceInHundredWeights: {
        winMultiplierMaxRange: [0, 1, 2, 5, 10, 20, 30, 40, 60, 100, 200, 500, 1000, 2000],
        featureWeights: [
            [0, 50, 100, 100, 70, 50, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 50, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 50, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 50, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 10, 10, 10, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 100, 50, 20, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 100, 50, 20, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 100, 50, 20, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 100, 50, 20, 10, 10, 10],
            [0, 0, 100, 100, 80, 75, 100, 100, 100, 50, 50, 20, 10, 10],
        ],
    },

    characterWildFeatureReelWeights: [
        { outcome: ['PIC1'], weight: 1 },
        { outcome: ['PIC2'], weight: 1 },
        { outcome: ['PIC3'], weight: 1 },
        { outcome: ['PIC1', 'PIC2'], weight: 2 },
        { outcome: ['PIC1', 'PIC3'], weight: 2 },
        { outcome: ['PIC2', 'PIC3'], weight: 2 },
        { outcome: ['PIC1', 'PIC2', 'PIC3'], weight: 2 },
    ],
    singleWildsFeatureWeights: [
        { outcome: 3, weight: 16 },
        { outcome: 4, weight: 14 },
        { outcome: 5, weight: 11 },
        { outcome: 6, weight: 3 },
        { outcome: 7, weight: 1 },
    ],
    numberOfMultipliersWeights: [
        { outcome: 3, weight: 10 },
        { outcome: 4, weight: 3 },
        { outcome: 5, weight: 1 },
    ],
    multiplierWeights: [
        { outcome: 2, weight: 10 },
        { outcome: 3, weight: 6 },
        { outcome: 4, weight: 2 },
        { outcome: 5, weight: 1 },
    ],
    deliverySequenceWeights: [
        { outcome: 'BEFORE', weight: 5 } as const,
        { outcome: 'AFTER', weight: 5 } as const,
        { outcome: 'BOTH', weight: 10 } as const,
    ],
    freeSpinAwardWeights: [
        { outcome: 1, weight: 2 },
        { outcome: 2, weight: 3 },
        { outcome: 3, weight: 2 },
    ],
    pickFeatureAwardPool: [
        'FREE_SPINS',
        'FREE_SPINS',
        'FREE_SPINS',
        'FREE_SPINS',
        'FREE_SPINS',
        'PIC1',
        'PIC2',
        'PIC3',
        'START',
        'START',
    ] as const,
    fixedJackpotWinMultiplier: {
        MINOR: 250,
        MINI: 50,
    } as const,
    jackpotWeightConstants: {
        chance: 1578,
        outOf: 3427658,
    },
    jackpotTypeWeights: [
        { outcome: 'PROG' as const, weight: 'COIN_WEIGHT' as const },
        { outcome: 'MINOR' as const, weight: 210 },
        { outcome: 'MINI' as const, weight: 1368 },
    ],
    progressiveJackpotWeights: [
        { outcome: 'GRAND' as const, weight: 8 },
        { outcome: 'MAJOR' as const, weight: 237 },
    ],
};
