import { type Static, Type } from '@sinclair/typebox';
import { CaseChaseSchema, GoldCaseFeatureSchema } from './case-chase';
import { CellSchema } from './cell';

export const FreeSpinPhaseSchema = Type.Union([
    Type.Literal('START'),
    Type.Literal('IN_PROGRESS'),
    Type.Literal('RETRIGGER'),
    Type.Literal('END'),
]);

export type FreeSpinPhase = Static<typeof FreeSpinPhaseSchema>;

export const GameStateSchema = Type.Object({
    slotWindow: Type.Array(Type.Array(Type.String())),
    coinAmount: Type.Integer(),
    cumulativeWinAmount: Type.Integer(),
    freeSpinPhase: Type.Optional(FreeSpinPhaseSchema),
    freeSpinCount: Type.Optional(Type.Integer()),
    reelStripPositions: Type.Array(Type.Integer()),

    // Banker Offer:
    bankerOfferAmount: Type.Optional(Type.Integer()),
    hasAcceptedBankerOffer: Type.Optional(Type.Boolean()),

    // Case chase:
    caseChase: Type.Optional(
        Type.Intersect([
            CaseChaseSchema,
            Type.Object({
                hardPosition: CellSchema,
                mediumPosition: Type.Optional(CellSchema),
                goldCasePrizes: Type.Optional(
                    Type.Array(
                        Type.Object({
                            feature: GoldCaseFeatureSchema,
                            prize: Type.Integer(),
                        }),
                    ),
                ),
                goldCasePicks: Type.Optional(Type.Array(CellSchema)),
            }),
        ]),
    ),
});

export type GameState = Static<typeof GameStateSchema>;
