export const modelDefinition = {
  modelId: "ghostFaceLives",
  gameIds: ["ghostFaceLives"],
  reelsLayout: [3, 3, 3, 3, 3],

  // prettier-ignore
  reels: [
    ['PIC1', 'PIC1', 'PIC1', 'A', 'PIC5', 'J', 'SCAT', '10', 'PIC3', 'K', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC5', 'Q', 'PIC6', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'PIC4', 'PIC6', 'K', 'PIC1', 'PIC5', 'A', 'WILD', 'WILD', 'WILD', 'K', 'PIC2', 'Q', 'PIC3', 'PIC4', 'K', 'PIC5', 'PIC5', 'PIC5', 'J', 'WILD', 'A', 'PIC6', 'PIC4', '10', 'PIC3', 'PIC3', 'PIC3', 'A', 'PIC6', 'SCAT', '10', 'PIC5', 'PIC1', 'PIC2', 'K', 'PIC6', 'PIC6', 'PIC6', 'A', 'PIC4', '10', 'PIC1', 'A', 'PIC5', 'J', 'SCAT', '10', 'PIC3', 'K', 'PIC4', 'A', 'PIC1', 'PIC5', 'Q', 'PIC6', 'K', 'PIC2', 'A', 'PIC4', 'PIC6', 'PIC3', 'K', 'PIC1', 'A', 'PIC5', 'K', 'PIC2', 'Q', 'PIC3', 'PIC4', 'K', 'PIC5', 'J', 'WILD', 'A', 'PIC6', 'PIC4', '10', 'PIC3', 'A', 'PIC2', 'PIC6', '10', 'PIC5', 'PIC1', 'PIC2', 'K', 'PIC6', 'A', 'PIC4', '10'],
    ['PIC2', 'PIC2', 'PIC2', 'K', 'PIC6', '10', 'SCAT', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC5', 'PIC5', 'PIC5', 'A', 'PIC2', '10', 'PIC5', 'PIC4', 'A', 'PIC3', 'PIC3', 'PIC3', 'K', 'SCAT', 'PIC5', 'J', 'PIC6', 'K', 'WILD', 'WILD', 'WILD', 'Q', 'PIC3', '10', 'PIC1', 'PIC5', 'A', 'PIC6', 'PIC6', 'PIC6', '10', 'PIC4', 'A', 'PIC1', 'PIC1', 'PIC1', 'K', 'PIC6', 'WILD', 'J', 'PIC3', 'PIC4', 'A', 'PIC1', 'PIC6', 'Q', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC5', 'A', 'PIC2', 'K', 'PIC6', '10', 'SCAT', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC5', 'A', 'PIC2', 'PIC3', '10', 'PIC5', 'PIC4', 'A', 'PIC3', 'K', 'PIC5', 'J', 'PIC2', 'K', 'PIC6', 'Q', 'PIC3', '10', 'PIC5', 'A', 'PIC6', '10', 'PIC4', 'A', 'PIC1', 'K', 'PIC6', 'WILD', 'J', 'PIC3', 'PIC4', 'A', 'PIC1', 'PIC6', 'Q', 'PIC4', 'K', 'PIC5', 'A'],
    ['PIC3', 'PIC3', 'PIC3', 'A', 'PIC4', 'K', 'PIC5', 'A', 'PIC2', 'K', 'PIC6', 'PIC6', 'PIC6', '10', 'PIC3', 'J', 'PIC5', 'A', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC6', '10', 'PIC3', 'PIC4', 'Q', 'WILD', 'WILD', 'WILD', 'J', 'PIC1', 'A', 'PIC2', 'PIC6', 'K', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC5', 'K', 'PIC2', 'PIC2', 'PIC2', 'A', 'WILD', '10', 'PIC1', 'PIC5', 'SCAT', 'K', 'PIC4', 'A', 'PIC5', 'PIC5', 'PIC5', '10', 'PIC6', 'K', 'PIC3', 'A', 'PIC4', 'K', 'SCAT', 'A', 'PIC2', 'K', 'PIC6', '10', 'PIC3', 'J', 'PIC5', 'A', 'PIC1', 'Q', 'PIC6', '10', 'PIC3', 'PIC4', 'Q', 'WILD', 'J', 'PIC1', 'A', 'PIC2', 'PIC6', 'K', 'PIC4', 'A', 'PIC5', 'K', 'PIC2', 'A', 'WILD', '10', 'PIC1', 'PIC5', 'SCAT', 'K', 'PIC4', 'A', 'PIC5', '10', 'PIC6', 'K'],
    ['PIC1', 'PIC1', 'PIC1', 'J', 'PIC5', 'K', 'SCAT', 'Q', 'PIC3', '10', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC1', '10', 'PIC6', 'K', 'PIC2', 'PIC2', 'PIC2', 'J', 'PIC4', 'A', 'PIC1', 'PIC5', 'J', 'WILD', 'WILD', 'WILD', '10', 'PIC2', 'K', 'PIC3', 'PIC4', '10', 'PIC5', 'PIC5', 'PIC5', 'K', 'WILD', 'J', 'PIC6', 'Q', 'PIC3', 'PIC3', 'PIC3', 'J', 'WILD', 'A', 'PIC2', 'PIC6', 'SCAT', 'Q', 'PIC5', '10', 'PIC6', 'PIC6', 'PIC6', 'A', 'PIC4', 'Q', 'PIC1', 'J', 'PIC5', 'K', 'SCAT', 'Q', 'PIC3', '10', 'PIC4', 'A', 'PIC1', '10', 'PIC6', 'K', 'PIC2', 'J', 'PIC4', 'A', 'PIC1', 'J', 'PIC5', '10', 'PIC2', 'K', 'PIC3', 'PIC4', '10', 'PIC5', 'K', 'WILD', 'J', 'PIC6', 'Q', 'PIC3', 'J', 'WILD', 'A', 'PIC2', 'PIC6', 'Q', 'PIC5', '10', 'PIC6', 'A', 'PIC4', 'Q'],
    ['PIC2', 'PIC2', 'PIC2', '10', 'PIC6', 'Q', 'SCAT', 'J', 'PIC1', 'A', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC2', 'A', 'PIC4', 'Q', 'PIC3', 'PIC3', 'PIC3', '10', 'PIC5', 'K', 'PIC2', 'PIC6', '10', 'WILD', 'WILD', 'WILD', 'A', 'PIC3', 'Q', 'PIC1', 'PIC5', 'A', 'PIC6', 'PIC6', 'PIC6', 'Q', 'WILD', '10', 'PIC4', 'J', 'PIC1', 'PIC1', 'PIC1', '10', 'WILD', 'K', 'PIC3', 'PIC4', 'SCAT', 'J', 'PIC6', 'A', 'PIC4', 'PIC4', 'PIC4', 'K', 'PIC5', 'J', 'PIC2', '10', 'PIC6', 'Q', 'SCAT', 'J', 'PIC1', 'A', 'PIC5', 'K', 'PIC2', 'A', 'PIC4', 'Q', 'PIC3', '10', 'PIC5', 'K', 'PIC2', '10', 'PIC6', 'A', 'PIC3', 'Q', 'PIC1', 'PIC5', 'A', 'PIC6', 'Q', 'WILD', '10', 'PIC4', 'J', 'PIC1', '10', 'WILD', 'K', 'PIC3', 'PIC4', 'SCAT', 'J', 'PIC6', 'A', 'PIC4', 'K', 'PIC5', 'J'],
],
};
