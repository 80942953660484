import { mathModel } from "../../../v3";
import { modelDefinition } from "../config";
import { Wild } from "../wild";
import { createGetWinCells } from "./create-get-win-cells";

const { wildSymbol, scatterSymbol, winTable } = modelDefinition;
const getWinCells = createGetWinCells(modelDefinition.freeSpins.playLines);
const checkLineWins = mathModel.createLineWinChecker({
  wildSymbol,
  scatterSymbol,
  playLines: modelDefinition.freeSpins.playLines,
  winTable,
});

export function evaluateLineWinsWithWilds(
  slotWindow: string[][],
  coinSize: number,
  wilds: Wild[]
) {
  const wins = checkLineWins(slotWindow);

  const winLines: number[] = [];
  const lineWins: number[] = [];
  const winCells: [number, number][][] = [];

  for (const win of wins) {
    const lineSpecificWinCells = getWinCells(win.playLineIndex, win.length);

    const wildMultiplier = calculateWildMultiplier(lineSpecificWinCells, wilds);

    winLines.push(win.playLineIndex + win.length / 10);
    lineWins.push(win.multiplier * coinSize * wildMultiplier);
    winCells.push(lineSpecificWinCells);
  }

  return {
    winLines,
    lineWins,
    winCells,
  };
}

function calculateWildMultiplier(
  winCells: mathModel.Cell[],
  wilds: Wild[]
): number {
  let multiplier = 1;

  for (const winCell of winCells) {
    for (const wild of wilds) {
      if (isSameCell(winCell, wild.cell)) {
        multiplier *= wild.multiplier;
      }
    }
  }

  return multiplier;
}

function isSameCell(a: mathModel.Cell, b: mathModel.Cell): boolean {
  return a[0] === b[0] && a[1] === b[1];
}
