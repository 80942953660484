import { createSlotWindowGenerator } from '@vgw/gdk-math-model-tools';
import { modelDefinition } from '../../model-definition';
import type { GameState } from '../shared';
import { createWinsEvaluator } from './create-wins-evaluator';
import type { GameRequest } from './game-request';
import type { SpinOutcome } from './outcome';
import type { Choices } from '../../choices/choices';
import { getWinType } from './get-win-type';
import { evaluateWinsWithReelSpinFeatures } from './reel-spin-features';
import type { ReelSpinFeatures } from '../shared';

export function spinBase({ gameRequest, coinType }: BaseSpinInputs, choices: Choices): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

    // Generate Slot Window
    const reelStripPositions = choices.chooseReelStripPositions();
    let slotWindow = generateSlotWindow(reelStripPositions);

    // Evaluate line wins
    let winInfo = evaluateWins({ slotWindow, coinAmount, playAmount });

    const featureId = choices.chooseReelSpinFeatureSet(false);
    const winsInfoAfterFeaturesApplied = evaluateWinsWithReelSpinFeatures({
        featureId,
        slotWindow,
        lineWinTotalBeforeFeatures: winInfo.lineWinAmount,
        winInfo,
        coinAmount,
        playAmount,
        isFreeSpin: false,
        choices,
        cumulativeWinAmount: 0,
    });

    let reelSpinFeatures: ReelSpinFeatures | undefined;
    if (winsInfoAfterFeaturesApplied) {
        slotWindow = winsInfoAfterFeaturesApplied.slotWindowAfterFeature;
        winInfo = winsInfoAfterFeaturesApplied.winInfoAfterFeature;
        reelSpinFeatures = winsInfoAfterFeaturesApplied?.reelSpinFeatures;
    }

    const winAmount = winInfo.winAmount;

    const isScatterWin = winInfo.scatterWin !== undefined;
    const winType = getWinType({
        winAmount,
        isScatterWin: isScatterWin,
        isFreeSpin: false,
    });

    const isJackpotTrigger =
        featureId === 0 && !isScatterWin && choices.chooseJackpotTriggered({ coinAmount, coinType });

    const roundComplete = !isScatterWin && !isJackpotTrigger;

    const jackpotPhase = isJackpotTrigger ? 'START' : undefined;
    // Calculate new game state
    const gameState: GameState | undefined = roundComplete
        ? undefined
        : {
              reelStripPositions,
              coinAmount,
              slotWindow,
              cumulativeWinAmount: winAmount,
              jackpotPhase,
              reelSpinFeatures,
              picks: isScatterWin
                  ? {
                        phase: 'START',
                    }
                  : undefined,
          };

    return {
        playSummary: {
            playAmount,
            winAmount,
            roundComplete,
        },
        gameResponse: {
            coinAmount,
            playAmount,
            winAmount,
            cumulativeWinAmount: gameState?.cumulativeWinAmount,

            reelStripPositions,
            slotWindow,

            lineWins: winInfo.lineWins,
            winCells: winInfo.winCells,
            scatterWin: winInfo.scatterWin,
            winType,

            reelSpinFeatures,
            jackpotPhase,
            picks: isScatterWin
                ? {
                      phase: 'START',
                  }
                : undefined,
        },
        gameState,
    };
}

export type BaseSpinInputs = {
    gameRequest: GameRequest;
    coinType: 'SC' | 'GC';
};

const evaluateWins = createWinsEvaluator(modelDefinition);
const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
