/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.isSpell = 0;
            step.internal_name = "Base";
            return step;
        },
        getBaseStep: function() {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.reelsType = "reels";
            step.isSpell = 0;
            step.internal_name = "Base";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "freeSpinsReels":
                    return State.state.getModelDefinition().freeSpinsReels;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        // example on creating custom indexes
        getFSTriggerStep (stepTitle) {
            const step = State.state.createStep([16, 34, 34, 35, 16], false, 0, "", stepTitle);
            step.reelsType = "reels";
            step.isSpell = 0;
            step.internal_name = "FS Trigger";
            return step;
        },
        getFSStep (stepTitle) {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", stepTitle);
            step.internal_name = "FS Step";
            step.spellSymbol = "Random";
            step.isSpell = 0;
            step.reelsType = "freeSpinsReels";
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType, step.bands);
            const noOfReels = 5;
            const noOfRows = 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
