/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: ["94"],

        createStep: (reelStripPositions, isFeature = false, order=0, variation="",name="Base") => {
            const step: any = State.state.addExtraDataToStep(createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name));
            step.field0 = 11111;
            return step;
        },
        // Must implement
        createDefaultStep: (order = 0, variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().fsFeatureReels,
        //here custom .
        getStackSymbol:()=> State.state.getModelDefinition().stackSymbol,
        
        // example on creating custom indexes
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: (order = 0) => State.state.createStep([14, 0, 17, 0, 27], false, order, "", "F-Trigger") ,
        // getFeatureStep: (order = 0) => State.state.createStep([6, 4, 7, 54, 9], false, order, "", "F-Step") ,
        // getFeatureReTriggerStep: (order = 0) => State.state.createStep([14, 0, 17, 0, 27], false, order, "", "F-Retrigger") ,

        getFeatureReTriggerStep: (featureReelsToUse) => {
            const step = State.state.createStep([14, 0, 17, 0, 27], true,0, "", "F-Retrigger");//State.state.createStep([92, 92, 92], true, 0, "", "F-Retrig");
            step.featureReelsToUse = featureReelsToUse;
            return step;
        },
        getFeatureStep: (featureReelsToUse) => {//Fix feature reels to use
            const step = State.state.createStep([6, 4, 7, 54, 9], true, 0, "", "F-Step");
            step.featureReelsToUse = featureReelsToUse; 
            return step;
        },
        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips()[where.featureReelsToUse] : State.state.getReelStrips();
            return reels;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReels(step);
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            return step;
        },
    };
}
