import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";

@Component({
    components: {
        reel: Reel,
        selectScatterMultiplier: SelectScatterMultiplier,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 6;
    public reel = "";
    public isOpen = false;

    public scattersNumber = 0;
    public scattersSelectedMultipliers = new Array(8).fill(0);

    public maxScatters = "0";

    public baseReelHeightOne = 7;
    public baseReelHeightTwo = 6;
    public baseReelHeightThree = 6;
    public baseReelHeightFour = 6;
    public baseReelHeightFive = 6;
    public baseReelHeightSix = 7;

    public jackpotFrame1 = false;
    public jackpotFrame2 = false;
    public jackpotFrame3 = false;
    public dynamiteFreespinWin = 0;
    public metaStage = -1;
    public metaStageCntr = -1;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.jackpotFrame1 = step.json.jackpotFrame1;
        this.jackpotFrame2 = step.json.jackpotFrame2;
        this.jackpotFrame3 = step.json.jackpotFrame3;
        this.dynamiteFreespinWin = step.json.dynamiteFreespinWin;
        this.metaStage = step.json.metaStage;
        this.metaStageCntr = step.json.metaStageCntr;

        if (this.reelsNo !== this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public updatedMaxScatters(value) {
        this.step.json.maxScatters = value;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateTopReelReelStripPosition(value) {
        this.step.json.topReelReelStripPosition = value;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBaseReelHeight(index, value) {
        switch (index) {
            case 0:
                this.step.json.baseReelHeightOne = value;
                break;
            case 1:
                this.step.json.baseReelHeightTwo = value;
                break;
            case 2:
                this.step.json.baseReelHeightThree = value;
                break;
            case 3:
                this.step.json.baseReelHeightFour = value;
                break;
            case 4:
                this.step.json.baseReelHeightFive = value;
                break;
            case 5:
                this.step.json.baseReelHeightSix = value;
                break;
        }

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }


    public updateBaseReelsHeights() {
        if (this.step.json.baseReelHeightOne != null) {
            this.baseReelHeightOne = this.step.json.baseReelHeightOne;
        }

        if (this.step.json.baseReelHeightTwo != null) {
            this.baseReelHeightTwo = this.step.json.baseReelHeightTwo;
        }

        if (this.step.json.baseReelHeightThree != null) {
            this.baseReelHeightThree = this.step.json.baseReelHeightThree;
        }

        if (this.step.json.baseReelHeightFour != null) {
            this.baseReelHeightFour = this.step.json.baseReelHeightFour;
        }

        if (this.step.json.baseReelHeightFive != null) {
            this.baseReelHeightFive = this.step.json.baseReelHeightFive;
        }

        if (this.step.json.baseReelHeightSix != null) {
            this.baseReelHeightSix = this.step.json.baseReelHeightSix;
        }
    }

    public updatedMaxScattersToggle() {
        if (this.step.json.maxScatters != null) {
            this.maxScatters = this.step.json.maxScatters;
        } else {
            this.maxScatters = "0";
        }
    }

    public updateBonusGameScattersNumber(scattersNumber) {
        this.step.json.scattersNumber = scattersNumber;

        // Reinitialize the scatters multipliers selectors.
        this.step.json.scattersSelectedMultipliers = new Array(parseInt(scattersNumber)).fill(0);
        this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusGameScattersMultipliers(event) {
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(this.step.json.scattersNumber).fill(0);
        }

        this.step.json.scattersSelectedMultipliers[event.target.id - 1] = parseInt(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateBonusTriggerSelectors() {
        if (this.step.json.scattersNumber != null) {
            this.scattersNumber = this.step.json.scattersNumber;
        } else {
            this.scattersNumber = 0;
        }

        if (this.step.json.scattersSelectedMultipliers != null) {
            this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
        } else {
            this.scattersSelectedMultipliers = new Array(this.scattersNumber).fill(0);
        }
    }

    public addFeatureTriggerStep() {
        const state = State.state; 
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerStep() );
    }

    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }

    public onChangejackpotFrame1() {
        this.step.json.jackpotFrame1 = this.jackpotFrame1;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.jackpotFrame1 );
    }

    public onChangejackpotFrame2() {
        this.step.json.jackpotFrame2 = this.jackpotFrame2;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.jackpotFrame2 );
    }

    public onChangejackpotFrame3() {
        this.step.json.jackpotFrame3 = this.jackpotFrame3;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.jackpotFrame3 );
    }

    public onChangeDynamiteFreespinWin() {
        this.step.json.dynamiteFreespinWin = this.dynamiteFreespinWin;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.dynamiteFreespinWin );
    }

    public onChangeMetaStage() {
        this.step.json.metaStage = this.metaStage;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.metaStage );
    }

    public onChangeMetaStageCntr() {
        this.step.json.metaStageCntr = this.metaStageCntr;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        console.log( this.step.json.metaStageCntr );
    }

}
