/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "betMultiplier" : 50,
    "modelId" : "vgw057",
    "gameId" : null,
    "reelsLayout" : [ 4, 4, 4, 4, 4, 4 ],
    "scatterSymbol" : "SCAT",
    "wildSymbol" : "WILD",
    "bonusSymbol" : null,
    "freeSpinTrigger" : "SCAT",
    "countToTriggerFreeSpin" : 3,
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 12,
    "countToFreeSpinRetrigger" : [2, 3],
    "freeSpinRetriggerAmount" : [1, 12],
    "goldDragonSymbol": "PIC1",
    "substituteSymbols": ["PIC5","PIC4","PIC3","PIC2"],
    "substituteRequirements": [5, 10, 15, 30],

    "winTable": [
      {
        "symbol" : "SCAT",
        "multipliers" : [100, 25, 10, 5, 2]
      },
      {
        "symbol" : "PIC1",
        "multipliers" : [150, 45, 20, 8, 2]
      },
      {
        "symbol" : "PIC2",
        "multipliers" : [120, 35, 20, 8, 2]
      },
      {
        "symbol" : "PIC3",
        "multipliers" : [100, 30, 18, 8]
      },
      {
        "symbol" : "PIC4",
        "multipliers" : [90, 30, 18, 8]
      },
      {
        "symbol" : "PIC5",
        "multipliers" : [90, 30, 18, 6]
      },
      {
        "symbol" : "A",
        "multipliers" : [80, 25, 12, 6]
      },
      {
        "symbol" : "K",
        "multipliers" : [80, 25, 12, 6]
      },
      {
        "symbol" : "Q",
        "multipliers" : [60, 20, 10, 5]
      },
      {
        "symbol" : "J",
        "multipliers" : [60, 20, 10, 5]
      },
      {
        "symbol" : "10",
        "multipliers" : [60, 20, 10, 5]
      },
      {
        "symbol" : "9",
        "multipliers" : [60, 20, 10, 5]
      },
      {
        "symbol" : "WILD",
        "multipliers" : [0, 0, 0]
      }
    ],
    "featureWinTable" : null,
    "playLines" : [ ],
    "reels" : [
      ["A","PIC1","PIC2","PIC3","PIC1","J","A","PIC5","PIC1","10","PIC2","K","PIC1","9","PIC4","A","PIC2","Q","PIC4","A","10","PIC2","PIC3","K","SCAT","Q","PIC2","K","K","A","9","PIC4","K","PIC2","K","Q","9","Q","J","PIC5","SCAT" ],
      ["WILD","PIC1","PIC2","PIC3","PIC2","K","PIC3","10","PIC5","PIC1","J","PIC4","10","10","10","9","9","K","10","A","WILD","K","PIC1","PIC2","PIC2","PIC2","9","Q","PIC5","PIC5","PIC2","9","SCAT","K","Q","PIC2","PIC2","PIC2","PIC2","J","K","9","A","K","K","Q","K","K","K","10","9","PIC4","Q","K","Q","K","PIC2","K","10","9","Q","K","PIC4","Q","K","PIC3","K","10","9","Q","K","Q","K","PIC5","PIC4","K","10","9","SCAT","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","A","PIC1","PIC1","PIC1","PIC1","PIC3","PIC3","PIC3","PIC3","K","A","WILD","J","K","K","K","10","10","10","PIC4","J","Q","Q","Q","Q","PIC4","10","SCAT","10","PIC2","J","10","PIC3","9","PIC4","Q","PIC4","PIC4","PIC4","PIC3","9","PIC2","PIC4","J","Q","Q","9","A","Q","Q","K","K","Q","Q","10","9","PIC5","Q","Q","Q","K","PIC2","Q","10","9","Q","Q","PIC5","Q","Q","PIC3","Q","10","9","K","K","Q","Q","PIC5","PIC4","Q","10","9","PIC1","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","9","PIC2","J","A","SCAT","A","A","K","K","K","A","10","PIC1","PIC5","PIC5","PIC5","10","9","PIC2","10","9","PIC2","PIC2","PIC2","PIC2","9","PIC1","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","PIC1","9","PIC2","9","A","9","9","Q","9","PIC1","9","10","9","PIC5","Q","9","Q","9","PIC2","9","10","9","Q","9","PIC5","Q","9","PIC3","9","10","9","Q","9","Q","9","PIC5","PIC4","9","10","9","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","9","PIC2","J","A","SCAT","A","PIC3","PIC3","PIC3","PIC3","A","10","PIC1","PIC4","PIC4","PIC4","10","K","PIC2","10","K","PIC2","PIC2","PIC2","PIC2","K","K","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","K","K","PIC2","9","A","K","K","Q","K","K","K","10","9","Q","K","Q","K","PIC2","K","10","9","Q","K","PIC4","K","10","9","Q","PIC5"],
      ["PIC1","PIC1","PIC1","PIC5","PIC2","J","A","SCAT","A","PIC3","PIC3","PIC3","PIC3","A","10","PIC1","PIC4","PIC4","PIC4","10","K","PIC2","10","K","PIC2","PIC2","PIC2","PIC2","K","K","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","K","K","PIC2","9","A","K","K","Q","K","K","K","10","9","Q","K","Q","K","PIC2","K","10","9","Q","K","Q","K","PIC3","K","10","9","Q","K","Q","K","PIC5","PIC4","K","10","9","Q","PIC5"]
    ],
    "featureReels" : [
      ["A","PIC1","PIC2","PIC3","PIC1","J","A","PIC5","PIC1","10","PIC2","K","PIC1","9","PIC4","A","PIC2","Q","PIC4","A","10","PIC2","PIC3","K","SCAT","Q","PIC2","K","K","A","9","PIC4","K","PIC2","K","Q","9","Q","J","PIC5","SCAT" ],
      ["WILD","PIC1","PIC2","PIC3","PIC2","K","PIC3","10","PIC5","PIC1","J","PIC4","10","10","10","9","9","K","10","A","WILD","K","PIC1","PIC2","PIC2","PIC2","9","Q","PIC5","PIC5","PIC2","9","SCAT","K","Q","PIC2","PIC2","PIC2","PIC2","J","K","9","A","K","K","Q","K","K","K","10","9","PIC4","Q","K","Q","K","PIC2","K","10","9","Q","K","PIC4","Q","K","PIC3","K","10","9","Q","K","Q","K","PIC5","PIC4","K","10","9","SCAT","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","A","PIC1","PIC1","PIC1","PIC1","PIC3","PIC3","PIC3","PIC3","K","A","WILD","J","K","K","K","10","10","10","PIC4","J","Q","Q","Q","Q","PIC4","10","SCAT","10","PIC2","J","10","PIC3","9","PIC4","Q","PIC4","PIC4","PIC4","PIC3","9","PIC2","PIC4","J","Q","Q","9","A","Q","Q","K","K","Q","Q","10","9","PIC5","Q","Q","Q","K","PIC2","Q","10","9","Q","Q","PIC5","Q","Q","PIC3","Q","10","9","K","K","Q","Q","PIC5","PIC4","Q","10","9","PIC1","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","9","PIC2","J","A","SCAT","A","A","K","K","K","A","10","PIC1","PIC5","PIC5","PIC5","10","9","PIC2","10","9","PIC2","PIC2","PIC2","PIC2","9","PIC1","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","PIC1","9","PIC2","9","A","9","9","Q","9","PIC1","9","10","9","PIC5","Q","9","Q","9","PIC2","9","10","9","Q","9","PIC5","Q","9","PIC3","9","10","9","Q","9","Q","9","PIC5","PIC4","9","10","9","Q","PIC5"],
      ["WILD","PIC1","PIC2","PIC3","9","PIC2","J","A","SCAT","A","PIC3","PIC3","PIC3","PIC3","A","10","PIC1","PIC4","PIC4","PIC4","10","K","PIC2","10","K","PIC2","PIC2","PIC2","PIC2","K","K","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","K","K","PIC2","9","A","K","K","Q","K","K","K","10","9","Q","K","Q","K","PIC2","K","10","9","Q","K","PIC4","K","10","9","Q","PIC5"],
      ["PIC1","PIC1","PIC1","PIC5","PIC2","J","A","SCAT","A","PIC3","PIC3","PIC3","PIC3","A","10","PIC1","PIC4","PIC4","PIC4","10","K","PIC2","10","K","PIC2","PIC2","PIC2","PIC2","K","K","A","PIC4","PIC4","PIC4","PIC4","PIC3","J","PIC4","10","PIC3","J","9","K","K","PIC2","9","A","K","K","Q","K","K","K","10","9","Q","K","Q","K","PIC2","K","10","9","Q","K","Q","K","PIC3","K","10","9","Q","K","Q","K","PIC5","PIC4","K","10","9","Q","PIC5"]
    ],

    "featureReelsStops" : null,
    "defaultSelectLine" : 0,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values" : [ 1, 2, 5, 10, 50, 400 ],
    "coinType1_min_bet" : 250,
    "coinType1_max_bet" : 100000,
    "coinType1_spin_max_bet" : 5000000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 400,
    "coinType4_spin_max_bet": 20000,
    "winLevels" : [ {
      "name" : "normal1",
      "threshold" : 5
    }, {
      "name" : "normal2",
      "threshold" : 15
    }, {
      "name" : "normal3",
      "threshold" : 25
    }, {
      "name" : "bigwin",
      "threshold" : 50
    }, {
      "name" : "megawin",
      "threshold" : 150
    }, {
      "name" : "epicwin",
      "threshold" : 2147483647
    } ],

    "currency_symbol" : "$",
    "normal_count" : "2"
  },
  "modelId" : "vgw057",
  "gameSessionId" : 1,
  "reelStripPositions" : [ 82, 18, 45, 49, 114 ],
  "slotWindow" : [ [ "PIC2", "PIC1", "PIC4" ], [ "PIC4", "PIC3", "PIC1", "PIC5" ], [ "PIC2", "PIC4", "PIC1", "PIC5", "PIC1" ], [ "PIC1", "PIC4", "PIC1", "SCAT" ], [ "PIC2", "PIC1", "PIC1" ], [ "PIC2", "PIC1", "PIC1" ]],
  "gold_balance" : 2008650000,
  "sweeps_balance" : 206529343,
  "sweeps_deposit_balance" : 200001395,
  "sweeps_promo_balance" : 0,
  "sweeps_win_balance" : 6527948,
  "sweeps_available_balance" : 6527948,
  "serverTime" : "2018-05-02 20:02:46"
}


