var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c("div", [
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.featureSubstituteSymbol,
                    expression: "featureSubstituteSymbol",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.featureSubstituteSymbol = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.doChangeSelectedSymbol()
                    },
                  ],
                },
              },
              _vm._l(_vm.symbols, function (symbol) {
                return _c(
                  "option",
                  { key: symbol, domProps: { value: { symbol } } },
                  [_vm._v(_vm._s(symbol))]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }