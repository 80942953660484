import type { Choices } from "../../../../choices/choices";
import { modelDefinition } from "../../../../model-definition";
import type { WinInfo } from "../../create-wins-evaluator";
import { deliverReelSpinFeatureResults } from "./deliver-reel-spin-feature-result";
import { applyReelSpinFeatures } from "./apply-reel-spin-features";
import { validateFeatureResults } from "./validate-feature-results";
import type { CharacterWildsWithPositions } from "../../reel-spin-features-response";
import { type ReelSpinFeatures } from "../../reel-spin-features-response";
import { updateSlotWindowSymbolsWithFeatures } from "./update-slot-window-symbols-with-features";
import { sortFeaturesByOrderGiven } from "./sort-array-in-given-order";

export type EvaluateReelSpinFeatureArgs = {
  featureId: number;
  slotWindow: string[][];
  winInfo: WinInfo;
  lineWinTotalBeforeFeatures: number;
  choices: Choices;
  isFreeSpin: boolean;
  coinAmount: number;
  playAmount: number;
  cumulativeWinAmount: number;
};
export type EvaluateReelSpinFeatureResult = {
  slotWindowAfterFeature: string[][];
  winInfoAfterFeature: WinInfo;
  reelSpinFeatures?: ReelSpinFeatures;
  characterWilds?: CharacterWildsWithPositions;
};

export function evaluateWinsWithReelSpinFeatures(
  args: EvaluateReelSpinFeatureArgs
): EvaluateReelSpinFeatureResult | undefined {
  const {
    slotWindow,
    winInfo,
    choices,
    coinAmount,
    playAmount,
    lineWinTotalBeforeFeatures,
    cumulativeWinAmount,
    featureId,
  } = args;

  if (featureId === 0) return;

  const featureSet = modelDefinition.reelSpinFeatureSets[featureId];
  const featuresSelected = sortFeaturesByOrderGiven<string>(
    featureSet.features,
    ["FRW", "CW", "SW", "M"]
  );

  const featuresAwarded = {
    fullReelWilds: featuresSelected.includes("FRW")
      ? choices.chooseFullReelWildsFeatureReels()
      : undefined,
    characterWilds: featuresSelected.includes("CW")
      ? choices.chooseCharacterWilds()
      : undefined,
    singleWilds: featuresSelected.includes("SW")
      ? choices.chooseSingleWilds()
      : undefined,
    multiplierCells: featuresSelected.includes("M")
      ? choices.chooseMultiplierCells()
      : undefined,
  };

  const {
    winInfoAfterFeature,
    newSlotWindow,
    lineWinTotalAfterFeatures,
    featureResults,
  } = applyReelSpinFeatures({
    features: featuresSelected,
    newSlotWindow: slotWindow.map((col) => [...col]),
    coinAmount,
    playAmount,
    featuresAwarded,
  });

  if (
    validateFeatureResults({
      featureId,
      lineWinTotalAfterFeatures,
      playAmount,
      lineWinTotalBeforeFeatures,
      choices,
    })
  ) {
    const reelSpinFeatures: ReelSpinFeatures | undefined =
      deliverReelSpinFeatureResults({
        choices,
        playAmount,
        coinAmount,
        resultsAfterAllFeatures: {
          winInfo: winInfoAfterFeature,
          ...featureResults,
        },
        resultsBeforeFeatures: {
          winInfo,
          slotWindow,
          cumulativeWinAmount,
        },
        featuresSelectedForSpin: featuresSelected,
      });

    const {
      slotWindow: slotWindowAfterSymbolsUpdated,
      reelSpinFeatures: reelSpinFeaturesAfterSymbolsUpdated,
    } = updateSlotWindowSymbolsWithFeatures({
      featureResults,
      reelSpinFeatures: reelSpinFeatures,
      slotWindowAfterFeaturesApplied: newSlotWindow.map((reel) => [...reel]),
      slotWindowBeforeAnyReelSpinFeaturesApplied: slotWindow.map((reel) => [
        ...reel,
      ]),
    });

    return {
      winInfoAfterFeature,
      slotWindowAfterFeature: slotWindowAfterSymbolsUpdated,
      reelSpinFeatures: reelSpinFeaturesAfterSymbolsUpdated,
      characterWilds: featureResults.characterWilds,
    };
  }
}
