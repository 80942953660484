export const originatorId = new Date().getTime();

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[]; 
  isBankerMultiplierOfferAwarded?: boolean
  bankerMultiplierAmount?: number
  isBankerStacksOfferAwarded?: boolean
  bankerStacksSymbol?: 'WILD' | 'CASE'
  bankerStacksReels?: number[]
  bankerScat?: {cell:[number, number], symbol: string}
  bankerCase?: {cell:[number, number], symbol: string}
  isOfferAwarded?: boolean
  bankerOfferWinAmount?: number
  hasAcceptedBankerOffer?: boolean
  casePrizes?: (number | 0 | null)[][]
  goldCases?: {
    cell: [number, number];
    feature: 'COLLECT' | 'MULTIPLYx2' | 'MULTIPLYx3' | 'MULTIPLYx4' | 'MULTIPLYx5' | 'BOOST';
  }[];
  jackpot?: 'GRAND'
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [3, 5, 0, 0, 3],
  };
}