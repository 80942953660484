import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    getReelWindowFromIndex,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

/*
regular steps: 
```
{
    order: 1,
    reelStripPositions: [0, 0, 0, 0, 0],
    rows: [
        ["Coin", "Coin", "Heart", "Heart", "Heart"], 
        ["Coin", "Coin", "Heart", "Heart", "Heart"], 
        ["Coin", "Coin", "Heart", "Heart", "Heart"]
   ],
    isFeature: false,
    variation: "",
    symbolInfos: [
        [[{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [null, null, null], [null, null, null], [null, null, null]],
        [[{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [null, null, null], [null, null, null], [null, null, null]],
        [[{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [null, null, null], [null, null, null], [null, null, null]],
        [[{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [{"price":100, "jackpotType": "MAJOR", "enabled": true }, {"price":100, "jackpotType": "MAJOR", "enabled": true },  {"price":100, "jackpotType": "MAJOR", "enabled": true }], [null, null, null], [null, null, null], [null, null, null]]
   ]
} 
```
*/

export interface ICoinInfo {
    jackpotType: string;
    price: number;
    fireShotCoinType: string;
}

export function setStateParams() {
    return {
        variations: [""],
        createStep: (reelStripPositions, isFeature = false, order = 0, variation = "", name = "Base") => {
            const step: any = createDefaultSlotStep(reelStripPositions, isFeature, order, variation, name);
            step.symbolInfos = [];
            step.symbolInfos.push(State.state.getEmptySymbolInfo());
            return step;
        },
        getEmptySymbolInfo: () => [[null, null, null, null], [null, null, null, null], [null, null, null, null], [null, null, null, null], [null, null, null, null]], 
        createDefaultStep: (variation = "") => State.state.createStep([0, 0, 0, 0, 0]),
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getReelWindow: (reelIndex, step, reelStripPosition) => {
            return getReelWindowFromIndex(State.state.getReels(step)[reelIndex], reelStripPosition, 4);
        },
        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips(where.featureReelsToUse) : State.state.getReelStrips(); 
            return reels;
        },
        getCoinConfig: (coin = 4) => {
            const config = State.state.getModelDefinition()[`coinPrices${coin === 4 ? "Sweeps" : "Gold"}`];
            const {jackpotTypes, fireShotCoinTypes} = config;
            return config.prices.map((price, idx) => ({
                jackpotType: jackpotTypes.length > idx ? jackpotTypes[idx] : undefined,
                fireShotCoinType: fireShotCoinTypes.length > idx ? fireShotCoinTypes[idx] : "REGULAR",
                price,
            } as ICoinInfo)); 
        },
        isJackpot: (step) => State.state.countSymbolOnStep(step, "Coin") >= 8 ,
        organizeSymbolInfos: (symbolInfos: any = [], c = 0) => {
            while (c < symbolInfos.length) {
                const n = c + 1; 
                symbolInfos[c].map((row, ri) => { 
                    row.map((symbol, si) => {
                        const locked = symbol != null;
                        const value = symbol === null ? null : Object.assign({}, JSON.parse(JSON.stringify(symbol)), { locked });
                        if (symbol != null && n < symbolInfos.length) {
                            symbolInfos[n][ri][si] = value;
                        }
                    });
                });
                c++;
            }
            return symbolInfos;
        },
        // called only when the textfield is changed, only changes 1 reel
        resetAllSymbolInfosOnReel: (step, r = 0) => {
            const symbolInfos = step.json.symbolInfos;
            const reelStripPosition = step.json.reelStripPositions[r];
            const reelWindow = State.state.getReelWindow(r, step, reelStripPosition);
            symbolInfos.map((whichStep, stepIndex) => {
                whichStep[r].map((reel, ri) => {
                    const val = reelWindow[ri] === "COIN" ? State.state.getEnabledStep() : null;
                    if (val && stepIndex > 0) {
                        val.locked = true;
                    }
                    symbolInfos[stepIndex][r][ri] = val; 
                });
            });
        },
        lockJackpotSteps: (step, substep = 0) => {
            const symbolInfos = step.json.symbolInfos;
            let c = 1;
            while (c < symbolInfos.length) {
                symbolInfos[c].forEach((reel, reelIndex) => {
                    reel.forEach((item, ri) => { 
                        if (item) {   
                            item.locked = true;
                        }
                    });
                });
                c++;
            }
        },
        propagateInfoOnSymbolInfos: (step, substep, reelIndex, index, value) => { 
            const symbolInfos = step.json.symbolInfos; 
            let c = substep + 1;
            while (c < symbolInfos.length) {
                const nv = value ? JSON.parse(JSON.stringify(value)) : null;
                if (nv) { nv.locked = true; }
                symbolInfos[c][reelIndex][index] = nv;
                c++;
            }
        },
        propagateCoinInfoOnSymbolInfos: (step, substep, reelIndex, index, coinInfo) => { 
            const symbolInfos = step.json.symbolInfos; 
            let c = substep;
            while (c < symbolInfos.length) {
                const symbolInfo = symbolInfos[c][reelIndex][index];
                if (symbolInfo) {                     
                    symbolInfos[c][reelIndex][index] = {...symbolInfo, ...coinInfo}; 
                }
                c++;
            }
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReels(step);
            step.randoms = step.reelStripPositions;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            return step;
        },
        getCoinText: (coinConfig: ICoinInfo) => {
            if (coinConfig.fireShotCoinType === "JACKPOT") {
                return coinConfig.jackpotType;
            } else if (coinConfig.fireShotCoinType !== "REGULAR") {
                return coinConfig.fireShotCoinType;
            }
            return `${coinConfig.price}`;
        } ,

        // example on creating custom indexes
        getEnabledStep: () => ({enabled: true, price: 1, jackpotType: null, fireShotCoinType: "REGULAR"}),
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
        getWithCoinsStep: (order = 0) => State.state.createStep([62, 47, 3, 0, 33], false, order, "", "Base") ,
        getJackpotTriggerStep: (order = 0) => {
            const s = State.state;
            const step = s.createStep([62, 47, 3, 0, 33], false, order, "", "J-Trigger");
            step.symbolInfos[0] = [
                [s.getEnabledStep(), s.getEnabledStep(), null, null],
                [s.getEnabledStep(), s.getEnabledStep(), null, null],
                [s.getEnabledStep(), s.getEnabledStep(), null, null], 
                [s.getEnabledStep(), s.getEnabledStep(), null, null],
                [s.getEnabledStep(), s.getEnabledStep(), null, null],
           ];
            State.state.organizeSymbolInfos(step.symbolInfos); 
            return step;
        },
        getFeatureTriggerStep: (order = 0) => {
            return State.state.createStep([52, 40, 16, 40, 47], false, order, "", "F-Trigger"); 
        },
        getFeatureStep: (featureReelsToUse) => {
            const step = State.state.createStep([47, 25, 23, 31, 37], true, 0, "", "F-Step"); 
            step.featureReelsToUse = featureReelsToUse; 
            return step;
        },
        getReelWindowPerReel: (step) => { 
            const reelStrips = State.state.getReels(step);  
            return step.json.reelStripPositions.map((reelIndex, i) => {
                return [
                    reelStrips[i][wrapIndex(reelIndex,   reelStrips[i])],
                    reelStrips[i][wrapIndex(reelIndex + 1, reelStrips[i])],
                    reelStrips[i][wrapIndex(reelIndex + 2, reelStrips[i])],
                    reelStrips[i][wrapIndex(reelIndex + 3, reelStrips[i])],
               ];
            });
        },
        countSymbolOnStep: (step, symbol = "Coin") => { 
            let count = 0;
            State.state.getReelWindowPerReel(step).map((reel, r) => { reel.map((sym, i) => { count += (symbol.toLowerCase() === sym.toLowerCase()) ? 1 : 0; }); });
            return count;
        },
        enableCoinsOnStep: (step) => {
            const reelNum = step.featureReelsToUse || 8;
            const reels = State.state.getReelWindowPerReel(step);
            reels.map((reel, r) => {
                reel.map((symbol, i) => {
                    step.json.symbolInfos[0][r][i] = (symbol.toLowerCase() === "coin") ? State.state.getEnabledStep() : null;
                });
            });
            State.state.organizeSymbolInfos(step.json.symbolInfos);
        },
    };
}
