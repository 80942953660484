export const modelDefinition = {
    modelId: 'vgw088',
    gameIds: ['vivaFrostVegas'],
    reelsLayout: [4, 4, 4, 4, 4],
    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',
    freeSpinsAwarded: 7,
    wheelSpinCount: 5,


    // prettier-ignore
    reels: [
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'Q', 'K', 'WILD', 'PIC4', 'PIC4', 'A', 'A', 'A', 'Q', 'Q', 'K', 'WILD', 'K', 'A', 'PIC4', 'PIC4', 'PIC4', 'A', 'PIC2', 'PIC2', 'K', 'K', 'Q', 'Q', 'Q', 'Q', 'SCAT', 'PIC5', 'PIC5', 'K', 'PIC3', '10', '10', '10', 'PIC5', 'PIC5', 'PIC5', 'J', '10', 'K', 'K', 'PIC4', 'PIC4', 'A', 'J', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'Q', '10', 'WILD', 'A', 'A', 'PIC5', 'PIC5', 'PIC1', 'PIC4', 'PIC4', 'K', 'K', 'A', 'A', 'SCAT', 'PIC4', 'K', 'PIC3', 'PIC2', 'PIC2', 'PIC2', 'A', 'J', 'J', '10', '10', 'PIC5', 'A', 'A', 'PIC3', 'PIC3', 'Q', 'Q', 'Q', 'PIC5', 'PIC5', '10', '10', '10', 'A', 'K', 'PIC3', 'PIC3', 'PIC5', 'K', 'K', 'PIC1', 'PIC1', 'A', 'A', 'WILD', 'Q', 'Q', 'PIC4', 'PIC4', 'PIC1', 'PIC1', 'PIC4', 'Q', 'Q', 'J', 'WILD', 'PIC2', 'A', 'PIC4', 'PIC4', 'A', 'A', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'Q', 'PIC3', '10', '10', 'SCAT', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'PIC5', 'Q', 'Q', '10', '10', '10', 'Q', 'PIC5', 'K', 'K', 'PIC4', 'PIC4', 'A', 'K', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC5', 'WILD', 'A', 'A', 'J', 'PIC3', 'PIC4', 'PIC4', 'A', 'PIC5', 'J', 'SCAT', 'A', 'A', 'PIC4', 'PIC3', 'PIC3', '10', 'Q', 'PIC2', 'PIC2', 'Q', 'J', '10', '10', 'PIC2', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'PIC5', 'Q', 'Q', 'PIC2', 'PIC5', 'PIC5', 'A', 'A', 'A', '10', '10', 'PIC5', 'K', 'K', 'K' ],
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC4', 'K', 'K', 'SCAT', 'PIC5', 'PIC5', 'J', 'PIC4', 'PIC4', 'K', 'WILD', '10', '10', 'PIC3', 'PIC3', 'A', 'A', '10', 'Q', 'Q', 'WILD', 'PIC2', 'J', 'J', 'PIC4', 'PIC5', 'PIC5', 'A', 'PIC3', 'PIC3', 'J', 'J', 'K', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'K', 'K', 'J', '10', '10', '10', 'J', 'J', 'Q', 'PIC1', 'PIC1', 'PIC1', 'J', 'J', 'K', 'K', 'Q', 'Q', 'Q', 'Q', 'PIC4', 'PIC4', 'K', 'WILD', '10', '10', 'PIC5', 'PIC5', 'A', 'A', 'WILD', 'PIC2', 'PIC2', 'A', 'SCAT', 'J', 'J', 'A', 'PIC4', 'PIC4', 'Q', 'WILD', 'Q', 'Q', 'A', 'PIC5', 'K', 'Q', 'A', 'PIC5', '10', '10', 'PIC1', 'PIC1', 'J', 'J', '10', '10', 'A', 'K', 'K', 'PIC1', 'PIC1', 'J', 'K', 'K', 'SCAT', 'PIC5', 'PIC5', 'PIC5', 'K', 'PIC4', 'PIC4', 'WILD', 'J', 'J', 'PIC3', 'PIC5', 'A', 'A', 'J', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'J', 'J', 'PIC5', 'PIC5', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'J', 'J', 'PIC5', 'PIC5', 'PIC3', '10', 'K', 'K', 'K', '10', '10', 'PIC5', 'J', 'J', '10', 'PIC1', 'PIC1', 'PIC1', 'PIC3', 'PIC5', 'K', 'K', 'A', 'PIC5', 'Q', 'A', 'PIC4', 'PIC4', 'Q', 'WILD', '10', '10', 'Q', 'SCAT', 'A', 'A', 'WILD', 'Q', 'PIC2', 'A', 'K', 'J', 'J', 'J', 'A', 'PIC5', 'Q', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC5', 'K', 'A', 'J', 'PIC5', 'K', 'K', '10', '10', 'Q', 'Q', 'J', 'J', 'A',],
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'A', 'A', 'J', 'J', '10', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'PIC1', 'K', 'K', 'PIC4', 'SCAT', 'PIC5', 'K', 'Q', 'WILD', 'A', 'A', 'J', 'J', 'PIC4', 'WILD', 'Q', 'K', 'PIC3', 'PIC3', 'J', 'K', 'PIC4', 'Q', 'Q', 'Q', 'Q', 'K', 'PIC4', 'PIC5', 'SCAT', 'K', 'K', 'PIC3', 'PIC3', '10', '10', 'Q', 'J', 'J', 'PIC3', 'A', 'A', 'WILD', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'A', 'K', 'K', 'PIC4', 'SCAT', 'PIC5', 'Q', 'K', 'PIC2', 'PIC2', 'J', 'J', 'A', 'A', 'WILD', '10', '10', 'PIC3', 'K', 'PIC3', 'PIC3', 'A', 'A', 'PIC1', 'PIC1', '10', 'J', 'PIC4', 'PIC4', 'PIC5', '10', '10', '10', 'Q', 'K', 'K', 'PIC1', 'PIC1', '10', 'PIC5', 'A', 'A', 'K', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'PIC1', 'K', 'K', 'A', 'SCAT', 'PIC5', 'K', 'Q', 'WILD', 'PIC2', 'PIC2', 'J', 'J', '10', 'WILD', 'Q', 'K', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'PIC4', 'Q', 'Q', '10', '10', 'K', 'PIC4', 'PIC5', 'SCAT', 'K', '10', 'PIC3', 'J', 'K', '10', 'Q', 'J', 'J', 'K', 'A', 'A', 'WILD', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'K', 'K', 'PIC4', 'SCAT', 'PIC5', 'Q', 'J', 'A', 'K', 'Q', 'J', 'A', 'A', 'WILD', 'Q', 'J', 'PIC4', 'PIC3', 'PIC3', 'K', 'PIC2', 'PIC2', 'PIC2', 'PIC2', '10', '10', '10', 'K', 'PIC5', 'PIC3', '10', '10', 'Q', 'K', 'K',],
        ['10', 'PIC1', 'PIC1', 'PIC5', '10', '10', 'A', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', 'PIC2', 'PIC2', 'Q', 'A', 'SCAT', '10', 'PIC2', 'PIC2', 'K', 'K', 'SCAT', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'Q', '10', '10', 'PIC5', 'Q', 'J', 'J', 'Q', 'K', 'PIC5', 'PIC5', 'Q', 'Q', 'Q', 'J', '10', '10', 'PIC1', 'A', 'PIC2', 'PIC2', 'K', 'A', 'PIC5', 'PIC4', 'PIC4', 'SCAT', 'J', 'PIC5', 'PIC5', 'Q', 'Q', 'PIC4', 'PIC4', 'PIC1', 'Q', 'PIC5', '10', '10', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'Q', 'A', 'A', 'PIC3', 'PIC3', 'J', 'J', 'Q', 'PIC1', '10', 'Q', 'PIC5', 'J', 'J', 'Q', 'K', '10', '10', 'PIC5', 'PIC5', 'Q', 'J', 'PIC1', 'PIC1', 'PIC1', 'PIC5', '10', '10', 'A', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', 'SCAT', 'PIC1', 'Q', 'A', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'SCAT', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'Q', '10', 'PIC1', 'WILD', 'PIC5', 'J', 'J', 'Q', 'K', 'PIC5', 'PIC5', 'PIC2', 'PIC2', 'Q', 'J', 'PIC1', 'PIC1', 'PIC1', 'A', 'Q', 'Q', 'K', 'A', 'PIC5', 'PIC4', 'PIC4', 'SCAT', 'J', 'PIC5', 'PIC5', 'Q', 'Q', 'PIC4', 'PIC4', 'PIC1', 'Q', 'PIC5', '10', '10', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'Q', 'A', 'A', 'PIC3', 'PIC3', 'J', 'J', 'Q', 'PIC1', 'PIC1', 'WILD', 'PIC5', 'J', 'J', 'Q', 'K', '10', '10', 'PIC5', 'PIC5', 'Q', 'J',],
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC2', 'PIC5', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'Q', 'PIC5', 'PIC1', 'A', 'SCAT', 'PIC5', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'A', 'K', '10', 'J', 'J', 'PIC1', 'Q', 'PIC5', 'K', '10', '10', 'PIC1', 'K', '10', 'J', 'PIC1', 'PIC1', '10', '10', 'Q', 'PIC2', 'WILD', 'A', 'A', 'K', 'PIC4', 'PIC4', '10', 'Q', 'Q', 'J', 'J', 'A', 'SCAT', 'K', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC5', 'PIC5', 'A', 'A', 'K', '10', 'PIC2', 'PIC2', 'WILD', 'PIC5', 'PIC1', 'K', '10', '10', 'A', 'PIC5', 'PIC5', 'Q', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'Q', 'PIC2', 'PIC5', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'Q', 'PIC5', 'PIC1', 'A', 'SCAT', 'K', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'A', 'K', '10', 'J', 'J', 'PIC1', 'Q', 'PIC5', 'K', '10', '10', 'PIC1', 'K', '10', 'J', 'PIC1', 'PIC1', '10', '10', 'Q', 'PIC2', 'WILD', 'A', 'A', 'K', 'PIC4', 'PIC4', '10', 'Q', 'Q', 'J', 'J', 'A', 'SCAT', 'K', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC5', 'PIC5', 'A', 'A', 'K', '10', 'PIC2', 'PIC2', 'WILD', 'Q', 'PIC1', 'K', '10', '10', 'A', 'PIC5', 'PIC5', 'K',]
    ],

    // prettier-ignore
    snowBlastReels: [
            ['J', 'A', 'PIC1', 'Q', 'J', 'J', 'K', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'J', '10', '10', 'K', 'K', 'PIC4', 'A', 'A', '10', 'PIC2', 'PIC2', 'PIC2', 'Q', '10', '10', '10', 'Q', 'Q', 'Q', 'PIC3', 'PIC3', 'PIC5', 'Q', 'Q', '10', 'PIC5', 'PIC5', 'PIC5', 'Q', 'A', 'J', '10', 'PIC3', 'K', 'K', 'K', 'PIC1', 'PIC1', 'PIC1', 'K', 'Q', 'J', 'WILD', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'J', '10', 'A', 'A', 'K', 'PIC4', 'A', 'A', '10', '10', 'PIC2', 'A', 'Q', 'J', '10', '10', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'PIC5', 'Q', 'Q', '10', 'PIC5', 'PIC5', 'PIC3', 'A', 'A', '10', '10', 'Q', 'PIC3', 'A'],
            ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'K', 'K', 'K', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC1', 'J', 'J', 'J', 'PIC3', 'PIC5', 'Q', 'Q', 'J', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'J', 'J', 'PIC5', 'J', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'J', 'J', 'PIC5', 'PIC5', '10', 'PIC5', 'K', 'K', 'K', 'J', '10', '10', 'Q', 'J', 'J', '10', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'K', 'K', 'K', 'PIC5', 'A', 'Q', 'PIC4', 'PIC4', 'PIC5', 'WILD', '10', '10', 'Q', 'J', 'A', 'A', 'PIC3', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'J', 'J', 'PIC5', 'A', 'PIC5', 'A', 'Q', 'PIC3', 'J', 'A', 'PIC5', 'PIC5', 'A', 'PIC3', 'K', 'K', 'K', 'Q', 'A', '10', 'K', 'Q', 'Q'],
            ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'A', 'K', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC1', 'K', 'K', 'PIC4', 'K', 'PIC5', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC5', 'K', 'A', 'Q', 'J', 'K', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'J', 'Q', 'Q', '10', '10', 'J', 'PIC4', 'PIC5', '10', '10', '10', 'PIC3', 'K', 'K', 'K', 'Q', 'J', 'J', '10', 'A', 'A', 'K', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC2', 'K', 'K', 'PIC4', 'PIC3', 'PIC5', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'A', 'A', 'WILD', 'Q', 'J', '10', 'K', 'PIC3', 'PIC3', 'J', 'Q', 'Q', '10', '10', 'J', 'PIC4', 'PIC5', 'PIC5', '10', 'PIC1', 'J', '10', '10'],
            ['PIC1', 'PIC1', 'PIC1', 'PIC5', '10', '10', 'A', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', '10', 'PIC1', 'Q', 'A', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'Q', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'Q', '10', 'PIC1', 'Q', 'PIC5', 'J', 'J', 'Q', 'K', 'PIC5', 'PIC5', 'PIC2', 'PIC2', 'Q', 'J', 'PIC1', 'PIC1', 'PIC1', 'A', 'Q', 'Q', 'K', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', 'J', 'PIC1', 'Q', 'PIC5', '10', '10', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'Q', 'A', 'A', 'PIC3', 'PIC3', 'J', 'J', 'Q', 'PIC1', 'PIC1', 'WILD', 'PIC5', 'J', 'J', 'Q', 'K', '10', '10', 'PIC5', 'PIC5', 'K'],
            ['PIC1', 'PIC1', 'PIC1', 'PIC5', 'Q', 'PIC2', 'PIC5', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'Q', 'PIC5', 'Q', 'A', 'J', 'K', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'A', 'K', '10', 'J', 'J', 'Q', 'PIC5', 'PIC5', 'K', '10', '10', 'PIC5', 'K', '10', 'J', 'PIC1', 'PIC1', '10', '10', 'Q', 'PIC2', 'A', 'A', 'A', 'K', 'PIC4', 'PIC4', '10', '10', 'Q', 'Q', 'J', 'A', '10', 'K', 'K', 'Q', 'J', 'J', 'J', 'J', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC5', 'PIC5', 'A', 'A', 'PIC3', 'J', 'PIC2', 'PIC2', 'WILD', 'Q', 'PIC1', 'K', 'J', 'J', 'PIC3', 'K', '10']
        ],

    // prettier-ignore
    freeSpinReels: [
        ['K', 'K', 'K', 'K', 'Q', 'J', '10', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'J', 'Q', 'Q', 'Q', 'A', 'K', 'PIC5', 'A', 'A', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'Q', '10', '10', '10', 'Q', 'SCAT', 'J', 'J', 'J', 'PIC5', 'Q', 'Q', '10', 'PIC5', 'PIC5', 'PIC5', 'PIC3', 'A', 'J', '10', '10', 'K', 'K', 'K', 'PIC1', 'PIC1', 'PIC1', 'PIC1', 'Q', 'J', 'WILD', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'A', 'J', 'Q', 'A', 'A', 'K', 'SCAT', 'A', 'A', 'J', 'J', 'J', 'A', 'Q', 'J', '10', '10', 'Q', 'PIC3', 'PIC3', 'PIC3', 'PIC3', 'A', 'Q', 'Q', '10', 'PIC5', 'PIC5', 'PIC5', 'PIC3', 'A', '10', '10', '10', '10', 'PIC5'],
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'K', 'K', 'K', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC3', '10', '10', '10', 'PIC3', 'J', 'A', 'A', 'PIC3', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'J', 'J', 'PIC5', 'J', 'PIC5', 'SCAT', 'PIC3', 'PIC3', 'PIC3', 'J', 'PIC5', 'PIC5', '10', 'PIC3', 'K', 'K', 'K', 'J', '10', '10', 'Q', 'J', 'J', '10', 'Q', 'PIC1', 'PIC1', 'PIC1', 'PIC5', 'K', 'K', 'K', 'PIC5', 'A', 'Q', 'PIC4', 'PIC4', 'PIC3', 'WILD', '10', '10', 'Q', 'J', 'SCAT', 'A', 'PIC3', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'J', 'J', 'PIC5', 'A', 'PIC5', 'A', 'Q', 'PIC3', 'J', 'A', 'PIC5', 'PIC5', 'A', 'PIC3', 'K', 'K', 'K', 'A', 'Q', '10', 'Q', 'PIC3', 'A'],
        ['PIC1', 'PIC1', 'PIC1', 'PIC1', 'A', 'A', 'K', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC1', 'K', 'K', 'PIC4', 'K', 'PIC5', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC5', 'K', 'A', 'Q', 'J', 'K', 'SCAT', 'PIC3', 'PIC3', 'PIC3', 'J', 'Q', 'Q', '10', '10', 'J', 'PIC4', 'PIC5', '10', '10', '10', 'PIC3', 'K', 'K', 'K', 'Q', 'J', 'J', '10', 'A', 'A', 'K', 'J', 'J', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC2', 'K', 'K', 'PIC4', 'PIC3', 'SCAT', 'K', 'Q', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'A', 'A', 'WILD', 'Q', 'J', '10', 'K', 'PIC3', 'PIC3', 'J', 'Q', 'Q', '10', '10', 'J', 'PIC4', 'PIC5', 'PIC5', '10', 'PIC3', 'PIC5'],
        ['PIC1', 'PIC1', 'PIC1', 'PIC5', '10', '10', 'A', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', '10', 'PIC1', 'Q', 'A', 'PIC2', 'PIC2', 'PIC2', 'PIC2', 'K', 'K', 'Q', 'A', 'A', 'SCAT', 'PIC3', 'PIC3', 'PIC3', 'Q', '10', 'PIC1', 'Q', 'PIC5', 'J', 'J', 'Q', 'K', 'PIC5', 'PIC5', 'PIC2', 'PIC2', 'Q', 'J', 'PIC1', 'PIC1', 'PIC1', 'A', 'Q', 'Q', 'K', 'A', 'PIC5', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'PIC5', 'PIC5', 'Q', 'Q', 'J', 'J', 'SCAT', 'Q', 'PIC5', '10', '10', 'PIC2', 'PIC2', 'PIC5', 'PIC5', 'Q', 'A', 'A', 'PIC3', 'PIC3', 'J', 'J', 'Q', 'PIC1', 'PIC1', 'WILD', 'PIC5', 'J', 'J', 'Q', 'PIC1', '10', '10', 'PIC5', 'PIC5', 'PIC3'],
        ['PIC1', 'PIC1', 'PIC1', 'Q', 'Q', 'PIC5', 'PIC5', 'A', 'A', 'PIC4', 'PIC4', 'PIC4', 'PIC4', 'Q', 'Q', 'PIC5', '10', 'A', 'J', 'K', 'K', 'PIC3', 'PIC3', 'Q', 'PIC2', 'PIC2', 'K', 'K', 'K', 'A', 'A', 'SCAT', 'J', 'J', 'J', 'A', 'K', '10', 'J', 'J', 'Q', 'PIC5', 'PIC5', 'K', '10', '10', '10', 'K', '10', 'J', 'PIC5', 'PIC5', '10', '10', 'Q', 'Q', 'A', 'A', 'A', 'K', 'PIC4', 'PIC4', '10', '10', 'Q', 'Q', 'J', 'A', '10', 'SCAT', 'K', 'Q', 'J', 'J', 'J', 'J', 'K', 'K', 'K', 'A', 'A', 'PIC3', 'PIC5', 'PIC5', 'A', 'A', 'K', '10', 'PIC2', 'PIC2', 'WILD', 'Q', 'Q', 'K', 'K', 'PIC3']
    ],
    
};
