// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reel-feature-selector[data-v-e84997b4] {
    margin-top: 15px;
    background: #707070; 
    padding: 10px;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
  
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/reel-spin-feature-controls/reel-spin-feature-controls.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;AACzC","sourcesContent":[".Reel-feature-selector[data-v-e84997b4] {\n    margin-top: 15px;\n    background: #707070; \n    padding: 10px;\n    border-radius: 5px;\n    padding: 15px;\n    margin-bottom: 10px;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n  \n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
