export const originatorId = new Date().getTime();

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  superDrum: {
    isSuperDrumAwarded: boolean;
    multiplier: number;
  };
  freeSpinsAwarded?: number;
  freeSpinsMultiplier?: number;
  pickOption?: "multiplierWheel" | "freeSpinWheel" | "bothWheels";
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [36, 29, 2, 20, 1],
    superDrum: {
      isSuperDrumAwarded: false,
      multiplier: 1,
    },
  };
}

export function createSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [18, 47, 19, 7, 53],
    superDrum: {
      isSuperDrumAwarded: false,
      multiplier: 1,
    },
    pickOption: "freeSpinWheel",
  };
}
export function createFreeSpinTriggerStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [52, 18, 36, 0, 32],
    freeSpinsAwarded: 15,
    freeSpinsMultiplier: 2,
    superDrum: {
      isSuperDrumAwarded: false,
      multiplier: 1,
    },
  };
}
export function createScatterWinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [35, 10, 39, 10, 42],
    superDrum: {
      isSuperDrumAwarded: false,
      multiplier: 1,
    },
  };
}
export function createSuperDrumSpinStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [35, 10, 39, 10, 42],
    superDrum: {
      multiplier: 1,
      isSuperDrumAwarded: true,
    },
  };
}
export function createJackpotTriggerStep(): StepJson {
  return {
    name: "Step",
    originatorId,
    gameState: "continue",
    reelStripPositions: [10, 9, 8, 9, 11],
    superDrum: {
      multiplier: 1,
      isSuperDrumAwarded: true,
    },
  };
}
