var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "divGameContent", staticClass: "sm-game-content" }, [
    _c(
      "div",
      { staticClass: "sm-in-content" },
      [
        _c(_vm.gameComponent, {
          tag: "component",
          attrs: { step: _vm.selectedStep },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }