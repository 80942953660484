// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-window[data-v-208bff26] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5em;
  justify-items: center;
  background: #707070;
  padding: 1em;
  box-shadow: 0 4px 8px 0 #707070;
  padding: 1em;
  border-radius: 0.5em;
  background: #707070;
}
.slot-window > div[data-v-208bff26] {
  text-align: center;
  width: 100%;
  aspect-ratio: 1;
  background: #707070;
  color: lightgray;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;
  box-shadow: inset 0 0 10px #555555;
  font-size: 0.8em;
  max-height: 100px;
}
.card-generic-info[data-v-208bff26] {
  background-color: #404040;
  color: lightgray;
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 1em 0;
}
.heading[data-v-208bff26] {
  color: lightgray;
}
.reel-spin-features-grid-container[data-v-208bff26] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2px;
}
.reel-spin-features-grid-item[data-v-208bff26] {
  background-color: #707070;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/ghostFaceLives/slot-window/slot-window.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,UAAU;EACV,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,+BAA+B;EAC/B,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,gBAAgB;;EAEhB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,oBAAoB;EACpB,kCAAkC;EAClC,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,wCAAwC;EACxC,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,QAAQ;AACV;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".slot-window[data-v-208bff26] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 0.5em;\n  justify-items: center;\n  background: #707070;\n  padding: 1em;\n  box-shadow: 0 4px 8px 0 #707070;\n  padding: 1em;\n  border-radius: 0.5em;\n  background: #707070;\n}\n.slot-window > div[data-v-208bff26] {\n  text-align: center;\n  width: 100%;\n  aspect-ratio: 1;\n  background: #707070;\n  color: lightgray;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 0.5em;\n  box-shadow: inset 0 0 10px #555555;\n  font-size: 0.8em;\n  max-height: 100px;\n}\n.card-generic-info[data-v-208bff26] {\n  background-color: #404040;\n  color: lightgray;\n  padding: 0.5em;\n  border-radius: 0.5em;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  margin: 1em 0;\n}\n.heading[data-v-208bff26] {\n  color: lightgray;\n}\n.reel-spin-features-grid-container[data-v-208bff26] {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n  gap: 2px;\n}\n.reel-spin-features-grid-item[data-v-208bff26] {\n  background-color: #707070;\n  border: 1px solid #ccc;\n  padding: 10px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
