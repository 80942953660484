export const modelDefinition = {
  modelId: "vgw073",

  scatterSymbol: "SCAT",
  wildSymbol: "WILD",

  wildMultiplier: 2,

  countToTriggerFreeSpin: 3,

  reelsLayout: [3, 3, 3, 3, 3],

  /* As this is a ways game, there are no play-lines */
  playLines: [],

  winTable: [
    { symbol: "PIC1", multipliers: [200, 70, 15] },
    { symbol: "PIC2", multipliers: [150, 50, 12] },
    { symbol: "PIC3", multipliers: [125, 50, 12] },
    { symbol: "PIC4", multipliers: [100, 40, 10] },
    { symbol: "PIC5", multipliers: [100, 40, 10] },
    { symbol: "A", multipliers: [80, 25, 8] },
    { symbol: "K", multipliers: [80, 25, 8] },
    { symbol: "Q", multipliers: [60, 20, 6] },
    { symbol: "J", multipliers: [60, 20, 6] },
    { symbol: "10", multipliers: [50, 15, 5] },
    { symbol: "9", multipliers: [50, 15, 5] },
    { symbol: "SCAT", multipliers: [50, 20, 5] },
  ],

  // prettier-ignore
  reels: [
        ['PIC4', '10', '9', 'SCAT', 'A', 'K', 'PIC1', '10', 'Q', 'PIC5', 'J', 'J', 'PIC3', '10', '10', 'PIC5', '9', 'PIC1', 'K', 'K', 'PIC5', 'J', '10', 'PIC2', 'J', 'PIC3', 'K', 'Q', 'PIC5', 'K', 'J', 'PIC3', 'A',],
        ['PIC4', '10', 'Q', 'WILD', 'A', '10', 'PIC1', 'PIC1', 'K', 'PIC2', 'K', '9', 'PIC5', 'K', 'K', 'PIC3', 'J', 'Q', 'PIC1', '10', '10', 'SCAT', 'J', '9', 'PIC2', 'J', 'J', 'PIC3', 'PIC3', 'A', '10', 'PIC5', 'PIC5', 'A', '9', 'PIC1', 'A', 'Q', 'PIC3', '9', '9', 'PIC4', 'K', 'PIC5', 'J',],
        ['PIC5', '10', 'WILD', 'A', 'PIC3', 'K', 'PIC5', 'J', 'PIC1', 'PIC1', 'Q', '9', 'SCAT', 'J', 'K', 'PIC3', '9', 'PIC4', 'J', '10', 'PIC2', '9', 'PIC1', 'K', '10', 'WILD', 'A', 'K', 'PIC5', 'PIC5', 'J', 'PIC1', 'Q', '9', 'SCAT', 'J', 'K', 'PIC3', 'PIC3', '9', 'PIC4', 'J', '10', 'PIC2', '9', 'K',],
        ['PIC4', '10', 'Q', 'WILD', 'A', '10', 'PIC3', 'PIC3', 'J', 'A', 'SCAT', '10', 'PIC3', 'K', 'K', 'PIC5', 'J', 'K', 'PIC3', '10', 'PIC1', 'J', '9', 'PIC2', '10', 'A', 'PIC3', 'K', 'PIC5', 'PIC5', 'PIC5', 'A', 'A', 'PIC1', 'Q', 'J', 'PIC2', '9', 'Q', 'PIC3', '9', '9', 'PIC5', 'A', 'PIC3', 'Q', 'PIC5', '9', 'J', 'A', 'SCAT', '10', 'PIC3', 'K', 'K', 'PIC5', 'J', 'K', 'PIC4', '10', 'J', 'PIC1', 'PIC1', '10', 'Q', 'PIC5', 'A', '10', 'PIC3', 'PIC3', 'J', 'A', 'SCAT', '10', 'PIC3', 'K', 'K', 'PIC5', 'PIC5', 'J', 'K', 'PIC4', '10', 'PIC1', 'J', '9', 'PIC2', '10', 'A', 'PIC3', 'K', 'PIC5', 'PIC5', 'A', 'A', 'PIC1', 'Q', 'J', 'PIC2', '9', 'Q', 'PIC5', '9', '9', 'PIC3', 'A', '9', 'PIC5', 'J', 'PIC1', 'A', '10', 'PIC3', 'PIC3', 'PIC3', 'K', 'K', 'PIC5', 'J', 'K', 'PIC4', '10', 'PIC1', 'J',],
        ['PIC4', '10', '9', 'PIC5', 'A', 'K', 'PIC1', 'Q', 'SCAT', '10', 'PIC5', 'J', 'J', 'PIC3', 'PIC3', 'K', 'PIC1', '9', 'K', 'PIC5', '10', 'PIC2', 'J', 'PIC3', 'PIC4', '10', '9', 'PIC5', 'PIC5', 'A', 'K', 'PIC1', 'Q', 'SCAT', '10', 'PIC5', 'J', 'J', 'PIC3', 'K', 'PIC1', '9', 'PIC3', 'J', '10', 'PIC2', 'K', 'PIC3', 'J',],
    ],

  /* Allowed Coin Values */
  coinType1Values: [100, 500, 2_000, 5_000, 20_000],
  coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400],

  /* Gold Coin Value Restrictions */
  coinType1_min_bet: 100,
  coinType1_max_bet: 20_000,

  /* Sweeps Coin Value Restrictions */
  coinType4_min_bet: 1,
  coinType4_max_bet: 400,
};
