import {
  createSpinStep,
  createClearingStep,
  createWildFury,
  createFreeSpinTrigger,
} from "../../../../../state/models/slots/vgw089";
import Vue from "vue";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";

export default Vue.component("NewSteps", {
  methods: {
    addSpinStep: function (type?: string) {
      switch (type) {
        case "clear":
          bus.$emit(events.ADD_STEP, createClearingStep());
          break;
        case "wild-fury":
          bus.$emit(events.ADD_STEP, createWildFury());
          break;
        case "free-spin":
          bus.$emit(events.ADD_STEP, createFreeSpinTrigger());
          break;
        default:
          bus.$emit(events.ADD_STEP, createSpinStep());
          break;
      }
    },
  },
});
