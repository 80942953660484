import type { Cell } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/choices';
import type { CasePrizesByCell, GoldCaseFeature, PendingGoldCasePrize } from '../../../shared/index';

export function evaluateGoldCases(
    candidates: Cell[],
    choices: Choices,
    coinAmount: number,
    allPrizes: CasePrizesByCell,
): { cells: Cell[]; prizes: PendingGoldCasePrize[] } {
    const cells: Cell[] = [];
    const prizes: PendingGoldCasePrize[] = [];
    for (const cell of candidates) {
        // First check if the candidate CASE cell _might_ become a GOLD_CASE. If not, skip to the next candidate.
        if (!choices.chooseIfCaseBecomesGoldCase(cell)) continue;

        // Calculate the sum of all prizes, and the candidate cell's prize, for use below.
        const casePrize = allPrizes.find(({ cell: prizeCell }) => isSameCell(cell, prizeCell));
        if(casePrize?.value === undefined){throw new Error()}
        const sumOfCasePrizes = allPrizes.reduce((total, { value }) => total + (value ?? 0), 0);

        // Choose which feature to evaluate.
        const goldCaseIndex = prizes.length;
        const featureKind = choices.chooseGoldCaseFeature(goldCaseIndex);

        // Evaluate the chosen feature.
        let isGoldCaseKept: boolean;
        let feature: GoldCaseFeature;
        switch (featureKind) {
            case 'COLLECT': {
                const totalPrizesExclSelf = sumOfCasePrizes - casePrize.value;
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(featureKind, totalPrizesExclSelf / coinAmount);
                feature = 'COLLECT';
                break;
            }
            case 'MULTIPLY': {
                const totalPrizesExclSelf = sumOfCasePrizes - casePrize.value;
                const multiplier = choices.chooseGoldCaseMultiplier(goldCaseIndex, totalPrizesExclSelf / coinAmount);
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(
                    featureKind,
                    (totalPrizesExclSelf * (multiplier - 1)) / coinAmount,
                );
                feature = `MULTIPLYx${multiplier}`;
                break;
            }
            case 'BOOST': {
                const totalCaseAmount = (allPrizes.length - 1) * casePrize.value;
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(featureKind, totalCaseAmount / coinAmount);
                feature = 'BOOST';
                break;
            }
        }

        if (!isGoldCaseKept) continue;
        cells.push(cell);
        prizes.push({ feature, prize: casePrize.value });
    }

    return { cells, prizes };
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
