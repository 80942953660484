var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c("label", { attrs: { for: _vm.index } }, [
      _vm._v(" Prize #" + _vm._s(_vm.index) + ": "),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.scattersSelectedMultipliers[_vm.index - 1],
            expression: "scattersSelectedMultipliers[index-1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.scattersSelectedMultipliers,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updateScatter()
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "0" } }, [_vm._v("Random")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "20" } }, [_vm._v("1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "25" } }, [_vm._v("1.25")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "30" } }, [_vm._v("1.5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "35" } }, [_vm._v("1.75")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "40" } }, [_vm._v("2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "50" } }, [_vm._v("2.5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "60" } }, [_vm._v("3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "70" } }, [_vm._v("3.5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "80" } }, [_vm._v("4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "100" } }, [_vm._v("5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "120" } }, [_vm._v("6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "140" } }, [_vm._v("7")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "160" } }, [_vm._v("8")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "180" } }, [_vm._v("9")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "200" } }, [_vm._v("10")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "240" } }, [_vm._v("12")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "300" } }, [_vm._v("15")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "400" } }, [_vm._v("20")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "500" } }, [_vm._v("25")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "600" } }, [_vm._v("30")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1000" } }, [_vm._v("50")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1500" } }, [_vm._v("75")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2500" } }, [_vm._v("100")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "5000" } }, [_vm._v("250")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "40000" } }, [_vm._v("2000")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }