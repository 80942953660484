/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reels");
            step.rows = State.state.getModelDefinition().reels;
            step.expandingWild = new Array(5).fill(false);
            step.bonusWild = "";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reelsFreespin,
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([6, 0, 2, 0, 67], false, 0, "", "Feature trigger");
            step.expandingWild = new Array(5).fill(false);
            step.bonusWild = "";
            return step;
        },
        getRespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Feature step");
            step.expandingWild = new Array(5).fill(false);
            step.bonusWild = "";
            return step;
        },
        getFreespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Freespin step");
            step.isFeature = true;
            step.expandingWild = new Array(5).fill(false);
            step.bonusWild = "None";
            return step;
        },

        prepareStepForDB: (step) => {
            step.rows = undefined;
            step.randoms = undefined;
            return step;
        },

        addPickStep: (order = 0) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "Pick step");
            step.isPick = true;
            step.numOfFreeSpins = 12;
            return step;
        },

        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Clear");
            step.gameState = "clear";
            return step;
        },
    };
}
