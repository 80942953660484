var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content vgw028-game-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("No-Win Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addJackpotTriggerStep()
              },
            },
          },
          [_vm._v("Jackpot Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isJackpot,
                expression: "isJackpot",
              },
            ],
            staticClass: "button pointer green",
            on: {
              click: function ($event) {
                return _vm.addJackpotStep()
              },
            },
          },
          [_vm._v("Add jackpot step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("\n            Feature Step\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isFeature ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isSweeps ? "btn-toggle-is-sweeps" : "btn-toggle-is-gold",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleSweeps()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isSweeps ? "$weeps" : "Gold") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.symbolInfos, function (item, index) {
        return _c("div", { key: item.id }, [
          _c(
            "div",
            {
              class: ["flex-center", index % 2 == 0 ? "step-even" : "step-odd"],
            },
            _vm._l(3, function (reelIndex) {
              return _c("reelvgw028", {
                key: reelIndex,
                attrs: {
                  index: reelIndex - 1,
                  substep: index,
                  step: _vm.step,
                  isJackpot: _vm.isJackpot,
                  isFeature: _vm.isFeature,
                  isSweeps: _vm.isSweeps,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step.json.symbolInfos.length > 1,
                expression: "step.json.symbolInfos.length > 1",
              },
            ],
            staticClass: "line",
          }),
          _vm._v(" "),
          _c("br"),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }