
var ua = navigator.userAgent;

export function isChrome() { return ua.match(/chrome/i) !== null || ua.match(/crios/i) !== null; }
export function isFirefox() { return ua.match(/firefox/i) !== null || ua.match(/fxios/i) !== null; }
export function isSafari() { return ua.match(/safari/i) !== null && !isChrome() && !isFirefox(); }
export function isFBApp() { return ua.match(/fbav/i) !== null || ua.match(/fban/i) !== null; }
export function isMobile() { return isIOS() || isAndroid() }
export function isDesktop() { return !isMobile() && !isTablet(); }

export function isTablet() { return isIpad() || isAndroidTablet() }
export function isIpad() {return ua.match(/ipad/i) !== null }
export function isIOS(){ return ua.match(/iphone|ipad|ipod/i) !== null; }
export function isAndroid(){ return ua.match(/android/i) !== null; }
export function isAndroidTablet(){ 
    if (isKindleFireTablet()) return true;
    if (!isAndroid()) return false;
    return /Tablet/i.test(ua) || !( /Mobile/i.test(ua) )
}
export function isKindleFireTablet(){ return (/Silk/i.test(ua) && /KFTBW|KFMEWI|KFFOWI|KFSAW|KFASWI|KFARWI|KFAPW|KFTHW|KFSOWI|KFJW|KFTT|KFOT/i.test(ua) ) }
export function get_browser() {
    var tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
    }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR\/(\d+)/);
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
    }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
        name: M[0],
        version: M[1]
    };
}