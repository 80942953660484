import { baseSpin } from './base-spin';
import { ensureSpinInputsAreValid } from './ensure-spin-inputs-are-valid';
import { FreeSpinInputs, freeSpin } from './free-spin';
import { SpinInputs } from './spin-inputs';
import { SpinOutputs } from './spin-outputs';

export function spin(inputs: SpinInputs): SpinOutputs {
    ensureSpinInputsAreValid(inputs);

    if (isFreeSpins(inputs)) {
        return freeSpin(inputs);
    }

    return baseSpin(inputs);
}

function isFreeSpins(inputs: SpinInputs): inputs is FreeSpinInputs {
    return inputs.gameState !== undefined;
}
