import State from "../../../state";
import { modelDefinition } from "./config/model-definition";

export function setStateParams() {
  return {
    variations: ["96"],
    createDefaultStep: (order = 0, variation = "") =>
      State.state.createStep([0, 0, 0, 0, 0], false, order, "", "Base-spin"),
    getLayout: () => modelDefinition.reelsLayout.join("-"),

    // unique per model
    getModelDefinition: () => modelDefinition,
    getReelStrips: () => modelDefinition.reels,
    getFeatureReelStrips: () => modelDefinition.freeSpinReels,

    getExpandedReelsLayout: (height: number): number[] => {
      if (height === 3) {
        return modelDefinition.reelsLayout;
      }
      return modelDefinition.reelsLayoutByExpandedHeight[height];
    },

    getAvailableCoinPrizes: (reelIndex: number): string[] => {
      if (reelIndex >= 2) {
        const reel = `reel${reelIndex + 1}`;
        return Object.keys(modelDefinition.coinPrizesWeights.baseSpin[reel]);
      }
      return [];
    },
    getAvailableHotSpotMultipliers: (): number[] => {
      return modelDefinition.hotSpotsWinMultiplierWeights.map((m) => m.outcome);
    },

    isCoin: (symbolName: string): boolean => {
      return modelDefinition.coinSymbol === symbolName;
    },

    // example on creating custom indexes
    getBaseStep: (order = 0) => State.state.createDefaultStep(),
    getWinWinBigSpinFromBaseGame: (order = 0) =>
      State.state.createStep(
        [10, 53, 12, 4, 8],
        false,
        order,
        "",
        "Base-Win-Win-Big-Spin"
      ),
    getWinWinBigSpinFromFeatureGame: (order = 0) =>
      State.state.createStep(
        [42, 50, 10, 30, 23],
        true,
        order,
        "",
        "F-Win-Win-Big-Spin"
      ),
    getFreeSpinTriggerFromBaseGame: (order = 0) =>
      State.state.createStep(
        [106, 8, 77, 26, 21],
        false,
        order,
        "",
        "F-Trigger"
      ),
    getFeatureStep: (order = 0) =>
      State.state.createStep([6, 4, 7, 54, 9], true, order, "", "F-Step"),
    getFeatureReTriggerStep: (order = 0) =>
      State.state.createStep(
        [15, 34, 27, 26, 17],
        true,
        order,
        "",
        "F-Retrigger"
      ),
    getClearGameStateStep: (order = 0) => {
      const step = State.state.createStep(
        [0, 0, 0, 0, 0, 0],
        false,
        order,
        "",
        "Clear"
      );
      step.gameState = "clear";
      return step;
    },

    prepareStepForDB: (step) => step, // We override to do nothing, as we no longer need to set the 'rows' and 'random' props on the StepJson.
  };
}
