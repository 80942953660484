/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";


export function setStateParams() {

    return {
        variations: [""],
        reels: [0,0,0,0,0],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.internal_name = "Base";
            step.featureType = 0;
            step.reelsType = "reels";
            return step;
        },
        setBaseStep: function() {
            this.reels = [0, 0, 0, 0, 0];
            const step = State.state.createStep(this.reels, false, 0, "", "Base");
            step.reelsType = "reels";
            step.internal_name = "Base";
            step.featureType = 0;
            return step;
        },

        // 3 scatters will win FS trigger
        setFSTriggerStep () {
            const step = State.state.createStep([18,20, 19, 6, 40], false, 0, "", "FS Trigger");
            step.reelsType = "reels";
            step.internal_name = "FS Trigger";
            return step;
        },


        addFSStep () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "FS Step");
            step.featureType = 0;
            step.internal_name = "FS Step";
            step.reelsType = "reels";

            return step;
        },

        addBonusTrigger () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Bonus Trigger");
            step.featureType = 0;
            step.coinNumber = 1;
            step.coinValue = new Array(step.coinNumber).fill(0);

            step.internal_name = "Bonus Trigger";
            step.reelsType = "reels";

            return step;
        },

        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "reelsFS":
                    return State.state.getModelDefinition().reelsFS;
                    break;
                case "reelsRandomWilds":
                    return State.state.getModelDefinition().reelsRandomWilds;
                    break;
                case "reelsFSRandomWilds":
                    return State.state.getModelDefinition().reelsFSRandomWilds;
                    break;
                case "reelsWildReelFeature":
                    return State.state.getModelDefinition().reelsWildReelFeature;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType, step.bands);
            const noOfReels = 5;
            const noOfRows = 3;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, noOfRows);
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
