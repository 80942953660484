/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId" : "rp001_busterHammerCarnival",

        "reelsLayout": [5, 5, 5, 5, 5],
        "reelsLayoutFreeSpin": [8, 8, 8, 8, 8],

        "betMultiplier": 1,
        "scatterSymbol": "S",
        "wildSymbol": "Z",

        "freeSpinTrigger" : "S",
        "countToTriggerFreeSpin": 3,
        "freeSpinCount" : 10,

        "coinType1Values": [ 250, 5000, 25000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 20, 100],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "2000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "100",
        "coinType4_spin_max_bet": "2000",
        "winTable": [
            {
                "symbol" : "B",
                "multipliers" : [300, 125, 30]
            },
            {
                "symbol" : "C",
                "multipliers" : [200, 100, 20]
            },
            {
                "symbol" : "D",
                "multipliers" : [125, 75, 15]
            },
            {
                "symbol" : "E",
                "multipliers" : [125, 50, 15]
            },
            {
                "symbol" : "A",
                "multipliers" : [100, 30, 10]
            },
            {
                "symbol" : "K",
                "multipliers" : [100, 30, 10]
            },
            {
                "symbol" : "Q",
                "multipliers" : [75, 20, 5]
            },
            {
                "symbol" : "J",
                "multipliers" : [75, 20, 5]
            },
            {
                "symbol" : "T",
                "multipliers" : [50, 10, 3]
            },
            {
                "symbol" : "N",
                "multipliers" : [50, 10, 3]
            },
            {
                "symbol" : "S",
                "multipliers" : [50, 10, 2]
            }

        ],

        "reels1" : [
            ["K", "J", "E", "T", "Q", "E", "K", "T", "E", "D", "K", "E", "J", "T", "E", "J", "K", "E", "T", "E", "J", "T", "J", "S", "K", "T", "J", "E", "K", "J", "C", "K", "J", "Q", "E", "J", "K", "E", "T", "E", "A", "T", "K", "E", "N", "K", "T", "E", "T", "D", "B", "E"],
            ["N", "J", "C", "Q", "N", "C", "T", "E", "A", "N", "A", "Q", "N", "J", "Q", "A", "C", "S", "A", "C", "Q", "N", "B", "N", "A", "C", "B", "A", "N", "C", "Q", "Q", "K", "A", "C", "J", "D", "Q", "J", "N", "C", "A", "Q", "C", "A", "Q", "Q", "C", "D", "N", "A", "N", "C", "A", "B", "A", "C"],
            ["N", "T", "B", "T", "A", "B", "A", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "B", "A", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "E", "C", "A", "B", "A", "N", "K", "B", "K", "E", "E", "Q", "T", "S", "Q", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "J", "J", "E", "D", "D", "Q", "K"],
            ["N", "T", "J", "Q", "N", "C", "J", "Q", "N", "T", "J", "Q", "D", "A", "A", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "S", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "K", "B", "B", "B", "J", "A", "N", "N", "T", "D", "D", "D", "T", "T", "E", "Q", "D", "Q", "J", "N", "N", "C", "C", "T", "C", "T", "Q", "T", "T", "D", "N", "S", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "S", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "reelsFeature1" : [
            ["K", "J", "E", "T", "Q", "E", "K", "T", "E", "J", "K", "E", "J", "T", "E", "J", "K", "E", "T", "E", "J", "T", "J", "B", "K", "T", "J", "E", "K", "J", "C", "K", "J", "Q", "E", "J", "K", "E", "T", "E", "A", "T", "K", "E", "N", "K", "T", "E", "T", "D", "B", "E"],
            ["N", "J", "C", "Q", "N", "C", "T", "E", "A", "N", "B", "Q", "N", "A", "Q", "A", "C", "D", "A", "C", "Q", "N", "A", "N", "A", "C", "B", "A", "N", "C", "Q", "Q", "K", "A", "C", "J", "A", "Q", "J", "N", "C", "A", "Q", "C", "A", "Q", "Q", "C", "D", "N", "A", "N", "C", "A", "B", "J", "C"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "B", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "B", "A", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "E", "C", "A", "B", "A", "N", "K", "B", "K", "E", "E", "Q", "T", "C", "Q", "J", "J", "E", "D", "D", "Q", "K"],
            ["N", "T", "J", "Q", "N", "C", "J", "Q", "N", "T", "J", "Q", "D", "A", "A", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "K", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "K", "B", "B", "B", "J", "A", "N", "N", "T", "D", "D", "D", "T", "T", "E", "Q", "D", "Q", "J", "N", "N", "C", "C", "T", "C", "T", "Q", "T", "T", "D", "N", "K", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "E", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "reels2" : [
            ["K", "J", "E", "T", "Q", "E", "K", "T", "E", "D", "K", "E", "J", "T", "E", "J", "K", "E", "T", "C", "J", "T", "J", "S", "K", "T", "J", "E", "K", "J", "C", "K", "T", "Q", "E", "J", "K", "E", "T", "E", "A", "T", "K", "E", "N", "K", "J", "E", "T", "D", "B", "E"],
            ["N", "J", "C", "Q", "N", "C", "T", "E", "A", "N", "A", "Q", "N", "J", "Q", "A", "C", "S", "A", "C", "Q", "N", "B", "N", "A", "C", "B", "A", "N", "D", "Q", "Q", "K", "A", "C", "J", "D", "Q", "J", "N", "C", "A", "Q", "D", "A", "Q", "Q", "C", "D", "N", "A", "N", "D", "A", "B", "A", "C"],
            ["N", "T", "B", "T", "A", "B", "A", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "B", "A", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "E", "C", "A", "B", "A", "N", "K", "B", "K", "E", "E", "Q", "T", "S", "Q", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "J", "J", "E", "D", "D", "Q", "K"],
            ["N", "T", "J", "Q", "N", "C", "J", "Q", "N", "T", "J", "Q", "D", "A", "A", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "S", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "B", "B", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "A", "N", "N", "T", "D", "T", "T", "E", "D", "N", "N", "C", "C", "T", "C", "T", "T", "T", "D", "N", "S", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "S", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "reelsFeature2" : [
            ["K", "J", "E", "T", "Q", "E", "K", "T", "E", "J", "K", "E", "J", "T", "E", "J", "K", "E", "T", "E", "J", "T", "J", "B", "K", "T", "J", "E", "K", "J", "C", "K", "J", "Q", "E", "J", "K", "E", "T", "E", "A", "T", "K", "E", "N", "K", "T", "E", "T", "D", "B", "E"],
            ["N", "J", "C", "Q", "N", "C", "T", "E", "A", "N", "B", "Q", "N", "A", "Q", "A", "C", "D", "A", "C", "Q", "N", "A", "N", "A", "C", "B", "A", "N", "C", "Q", "Q", "K", "A", "C", "J", "A", "Q", "J", "N", "C", "A", "Q", "C", "A", "Q", "Q", "C", "D", "N", "A", "N", "C", "A", "B", "J", "C"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "B", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "B", "A", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "E", "C", "A", "B", "A", "N", "K", "B", "K", "E", "E", "Q", "T", "C", "Q", "J", "J", "E", "D", "D", "Q", "K"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "K", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "K", "B", "B", "B", "J", "A", "N", "N", "T", "D", "D", "D", "T", "T", "E", "Q", "D", "Q", "J", "N", "N", "C", "C", "T", "C", "T", "Q", "T", "T", "D", "N", "K", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "E", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "reels3" : [
            ["K", "J", "E", "T", "J", "C", "K", "T", "E", "K", "J", "T", "D", "K", "E", "J", "T", "E", "J", "K", "E", "T", "J", "E", "T", "J", "S", "K", "T", "J", "E", "K", "J", "C", "K", "T", "Q", "E", "J", "K", "B", "T", "E", "A", "T", "K", "E", "N", "K", "T", "D", "T", "Q", "E", "K", "J", "T", "B", "A", "J", "T", "E", "J", "K", "N", "E", "K", "J", "E"],
            ["N", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "Q", "N", "C", "T", "E", "A", "N", "A", "Q", "N", "J", "Q", "A", "C", "N", "Q", "J", "S", "N", "A", "C", "Q", "N", "E", "N", "A", "C", "N", "Q", "J", "B", "A", "N", "C", "Q", "N", "A", "C", "Q", "J", "N", "C", "A", "E", "C", "A", "N", "Q", "C", "A", "Q", "C", "N", "A", "C", "A", "N", "S", "C", "A", "Q", "J", "B", "A", "Q", "T", "C", "J", "Q", "A", "C"],
            ["N", "T", "B", "T", "A", "B", "A", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "B", "A", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "D", "C", "A", "B", "A", "N", "K", "B", "K", "D", "E", "Q", "T", "S", "Q", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "J", "E", "Q", "A", "D", "E", "A", "Q", "T", "E", "N", "T", "J", "D", "Q", "K", "E", "N", "Q", "J", "E", "N", "Q", "A", "E"],
            ["N", "T", "J", "Q", "N", "C", "J", "Q", "N", "T", "J", "Q", "D", "A", "A", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "S", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "B", "B", "D", "J", "K", "Q", "Z", "D", "J", "K", "Q", "A", "N", "N", "T", "D", "T", "T", "E", "D", "N", "N", "C", "C", "T", "C", "T", "T", "T", "D", "N", "S", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "S", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "reelsFeature3" : [
            ["K", "J", "E", "T", "Q", "E", "K", "T", "E", "J", "K", "E", "J", "T", "E", "J", "K", "E", "T", "E", "J", "T", "E", "K", "J", "T", "J", "J", "B", "K", "T", "J", "E", "K", "J", "C", "K", "J", "K", "E", "J", "K", "E", "T", "E", "A", "T", "K", "E", "N", "K", "T", "E", "T", "D", "K", "J", "B", "T", "E", "K", "T", "T", "E", "T", "K", "J", "E"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "A", "C", "D", "A", "C", "Q", "N", "D", "A", "N", "A", "C", "Q", "D", "N", "A", "C", "C", "C", "D", "A", "Q", "N", "C", "Q", "Q", "E", "Q", "C", "N", "A", "D", "Q", "A", "D", "N", "C", "A", "D", "D", "Q", "C", "A", "Q", "D", "Q", "C", "D", "N", "C", "A", "N", "C", "A", "B", "N", "C", "A", "Q", "N", "C", "A", "D", "Q", "N", "C", "A", "D", "N", "C", "A", "D", "Q", "N", "C", "D", "A", "D", "D"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "B", "J", "K", "J", "E", "T", "N", "E", "A", "K", "T", "D", "Q", "N", "J", "E", "A", "A", "K", "J", "T", "N", "A", "K", "J", "T", "B", "A", "A", "K", "J", "T", "N", "D", "K", "Q", "J", "T", "N", "N", "K", "Q", "B", "N", "J", "T", "T", "E", "C", "A", "B", "A", "N", "K", "B", "N", "K", "J", "J", "K", "E", "J", "T", "N", "E", "Q", "T", "C", "Q", "J", "J", "E", "Q", "T", "N", "D", "T", "Q", "K", "N", "K", "Q", "D"],
            ["N", "T", "A", "D", "J", "K", "Q", "Z", "N", "T", "A", "D", "J", "K", "Q", "B", "B", "B", "B", "B", "N", "N", "C", "C", "C", "D", "Q", "J", "J", "E", "J", "K", "D", "N", "C", "C", "A", "Q", "Q", "A", "T", "K", "B", "B", "B", "J", "A", "N", "N", "T", "D", "D", "D", "T", "T", "E", "Q", "D", "Q", "J", "N", "N", "C", "C", "T", "C", "T", "Q", "T", "T", "D", "N", "K", "J", "J", "C", "Q", "D", "D"],
            ["N", "Q", "B", "B", "B", "K", "N", "C", "N", "B", "B", "B", "K", "D", "A", "C", "N", "E", "K", "A", "Q", "C", "N", "E", "T", "K", "A", "C", "C", "N", "A", "A", "K", "C", "T", "N", "T", "J", "C", "C", "N", "T", "B", "B", "B", "C", "K", "N", "J", "A", "N", "C", "A", "D", "D"]
        ],

        "baseWildStriker1": {
            "0": [
                {"weight": 430, "wilds": [0, 4]},
                {"weight": 400, "wilds": [0, 3, 4]},
                {"weight": 100, "wilds": [0, 2, 3, 4]},
                {"weight": 70, "wilds": [0, 1, 2, 3, 4]}
            ],
            "1": [
                {"weight": 430, "wilds": [1, 4]},
                {"weight": 400, "wilds": [1, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 70, "wilds": [0, 1, 2, 3, 4]}
            ],
            "2": [
                {"weight": 430, "wilds": [2, 4]},
                {"weight": 400, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 70, "wilds": [0, 1, 2, 3, 4]}
            ],
            "3": [
                {"weight": 430, "wilds": [3, 4]},
                {"weight": 400, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 70, "wilds": [0, 1, 2, 3, 4]}
            ],
            "4": [
                {"weight": 300, "wilds": [4]},
                {"weight": 440, "wilds": [3, 4]},
                {"weight": 100, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 60, "wilds": [0, 1, 2, 3, 4]}
            ]
        },

        "featureWildStriker1": {
            "0": [
                {"weight": 500, "wilds": [0, 7]},
                {"weight": 300, "wilds": [0, 6, 7]},
                {"weight": 100, "wilds": [0, 5, 6, 7]},
                {"weight": 50, "wilds": [0, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "1": [
                {"weight": 500, "wilds": [1, 7]},
                {"weight": 300, "wilds": [1, 6, 7]},
                {"weight": 100, "wilds": [1, 5, 6, 7]},
                {"weight": 50, "wilds": [1, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "2": [
                {"weight": 500, "wilds": [2, 7]},
                {"weight": 300, "wilds": [2, 6, 7]},
                {"weight": 100, "wilds": [2, 5, 6, 7]},
                {"weight": 50, "wilds": [2, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "3": [
                {"weight": 500, "wilds": [3, 7]},
                {"weight": 300, "wilds": [3, 6, 7]},
                {"weight": 100, "wilds": [3, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "4": [
                {"weight": 500, "wilds": [4, 7]},
                {"weight": 300, "wilds": [4, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "5": [
                {"weight": 500, "wilds": [5, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "6": [
                {"weight": 500, "wilds": [6, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "7": [
                {"weight": 490, "wilds": [7]},
                {"weight": 300, "wilds": [6, 7]},
                {"weight": 100, "wilds": [5, 6, 7]},
                {"weight": 50, "wilds": [4, 5, 6, 7]},
                {"weight": 20, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ]
        },

        "baseWildStriker2": {
            "0": [
                {"weight": 400, "wilds": [0, 4]},
                {"weight": 300, "wilds": [0, 3, 4]},
                {"weight": 200, "wilds": [0, 2, 3, 4]},
                {"weight": 200, "wilds": [0, 1, 2, 3, 4]}
            ],
            "1": [
                {"weight": 400, "wilds": [1, 4]},
                {"weight": 300, "wilds": [1, 3, 4]},
                {"weight": 200, "wilds": [1, 2, 3, 4]},
                {"weight": 100, "wilds": [0, 1, 2, 3, 4]}
            ],
            "2": [
                {"weight": 400, "wilds": [2, 4]},
                {"weight": 300, "wilds": [2, 3, 4]},
                {"weight": 200, "wilds": [1, 2, 3, 4]},
                {"weight": 100, "wilds": [0, 1, 2, 3, 4]}
            ],
            "3": [
                {"weight": 400, "wilds": [3, 4]},
                {"weight": 300, "wilds": [2, 3, 4]},
                {"weight": 200, "wilds": [1, 2, 3, 4]},
                {"weight": 100, "wilds": [0, 1, 2, 3, 4]}
            ],
            "4": [
                {"weight": 250, "wilds": [4]},
                {"weight": 350, "wilds": [3, 4]},
                {"weight": 150, "wilds": [2, 3, 4]},
                {"weight": 150, "wilds": [1, 2, 3, 4]},
                {"weight": 100, "wilds": [0, 1, 2, 3, 4]}
            ]
        },

        "featureWildStriker2": {
            "0": [
                {"weight": 500, "wilds": [0, 7]},
                {"weight": 300, "wilds": [0, 6, 7]},
                {"weight": 100, "wilds": [0, 5, 6, 7]},
                {"weight": 50, "wilds": [0, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "1": [
                {"weight": 500, "wilds": [1, 7]},
                {"weight": 300, "wilds": [1, 6, 7]},
                {"weight": 100, "wilds": [1, 5, 6, 7]},
                {"weight": 50, "wilds": [1, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "2": [
                {"weight": 500, "wilds": [2, 7]},
                {"weight": 300, "wilds": [2, 6, 7]},
                {"weight": 100, "wilds": [2, 5, 6, 7]},
                {"weight": 50, "wilds": [2, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "3": [
                {"weight": 500, "wilds": [3, 7]},
                {"weight": 300, "wilds": [3, 6, 7]},
                {"weight": 100, "wilds": [3, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "4": [
                {"weight": 500, "wilds": [4, 7]},
                {"weight": 300, "wilds": [4, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "5": [
                {"weight": 500, "wilds": [5, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "6": [
                {"weight": 500, "wilds": [6, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "7": [
                {"weight": 490, "wilds": [7]},
                {"weight": 300, "wilds": [6, 7]},
                {"weight": 100, "wilds": [5, 6, 7]},
                {"weight": 50, "wilds": [4, 5, 6, 7]},
                {"weight": 20, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ]
        },


        "baseWildStriker3": {
            "0": [
                {"weight": 420, "wilds": [0, 4]},
                {"weight": 400, "wilds": [0, 3, 4]},
                {"weight": 100, "wilds": [0, 2, 3, 4]},
                {"weight": 80, "wilds": [0, 1, 2, 3, 4]}
            ],
            "1": [
                {"weight": 420, "wilds": [1, 4]},
                {"weight": 400, "wilds": [1, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 80, "wilds": [0, 1, 2, 3, 4]}
            ],
            "2": [
                {"weight": 420, "wilds": [2, 4]},
                {"weight": 400, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 80, "wilds": [0, 1, 2, 3, 4]}
            ],
            "3": [
                {"weight": 420, "wilds": [3, 4]},
                {"weight": 400, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 80, "wilds": [0, 1, 2, 3, 4]}
            ],
            "4": [
                {"weight": 350, "wilds": [4]},
                {"weight": 350, "wilds": [3, 4]},
                {"weight": 150, "wilds": [2, 3, 4]},
                {"weight": 100, "wilds": [1, 2, 3, 4]},
                {"weight": 50, "wilds": [0, 1, 2, 3, 4]}
            ]
        },

        "featureWildStriker3": {
            "0": [
                {"weight": 500, "wilds": [0, 7]},
                {"weight": 300, "wilds": [0, 6, 7]},
                {"weight": 100, "wilds": [0, 5, 6, 7]},
                {"weight": 50, "wilds": [0, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [0, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "1": [
                {"weight": 500, "wilds": [1, 7]},
                {"weight": 300, "wilds": [1, 6, 7]},
                {"weight": 100, "wilds": [1, 5, 6, 7]},
                {"weight": 50, "wilds": [1, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "2": [
                {"weight": 500, "wilds": [2, 7]},
                {"weight": 300, "wilds": [2, 6, 7]},
                {"weight": 100, "wilds": [2, 5, 6, 7]},
                {"weight": 50, "wilds": [2, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "3": [
                {"weight": 500, "wilds": [3, 7]},
                {"weight": 300, "wilds": [3, 6, 7]},
                {"weight": 100, "wilds": [3, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "4": [
                {"weight": 500, "wilds": [4, 7]},
                {"weight": 300, "wilds": [4, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "5": [
                {"weight": 500, "wilds": [5, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "6": [
                {"weight": 500, "wilds": [6, 7]},
                {"weight": 300, "wilds": [5, 6, 7]},
                {"weight": 100, "wilds": [4, 5, 6, 7]},
                {"weight": 50, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ],
            "7": [
                {"weight": 490, "wilds": [7]},
                {"weight": 300, "wilds": [6, 7]},
                {"weight": 100, "wilds": [5, 6, 7]},
                {"weight": 50, "wilds": [4, 5, 6, 7]},
                {"weight": 20, "wilds": [3, 4, 5, 6, 7]},
                {"weight": 20, "wilds": [2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [1, 2, 3, 4, 5, 6, 7]},
                {"weight": 10, "wilds": [0, 1, 2, 3, 4, 5, 6, 7]}
            ]
        }

    },
}
