import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import type { InitArguments, InitOutcome } from "@vgw/gdk-math-model-server";
import type { Choices } from "../../choices";
import { CoinPrizeSchema, GenieBonusPhaseSchema, type GameState } from "../shared";
import { modelDefinition } from "../../model-definition";
import type { GameResponse } from "../play";
import { play } from "../play";

export function init({ coinType, gameState }: InitArguments<GameState>, choices: Choices): InitOutcome<InitResponse> {
    if (gameState) {
        return {
            initResponse: {
                coinAmount: gameState.coinAmount,
                reelStripPositions: gameState.reelStripPositions,
                slotWindow: gameState.slotWindow,
                cumulativeWinAmount: gameState.cumulativeWinAmount,
                modelDefinition: {
                    validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                    reels: modelDefinition.base.reels,
                },
                genieBonusPhase: gameState.genieBonusPhase,
                genieBonusCount: gameState.genieBonusCount,
                coinPrizes: gameState.coinPrizes,
                mysterySymbol: gameState.mysterySymbol,
            },
        };
    }

    let spinWithNoWin: GameResponse | undefined;
    while (spinWithNoWin === undefined) {
        const { gameResponse } = play(
            { coinType: "SC", gameRequest: { coinAmount: modelDefinition.validCoinAmounts.SC[0] } },
            choices,
        );
        const { winType } = gameResponse;

        if (winType === "NO_WIN") {
            spinWithNoWin = gameResponse;
            break;
        }
    }

    const { reelStripPositions, slotWindow, mysterySymbol } = spinWithNoWin;

    return {
        initResponse: {
            reelStripPositions,
            slotWindow,
            modelDefinition: {
                validCoinAmounts: modelDefinition.validCoinAmounts[coinType],
                reels: modelDefinition.base.reels,
            },
            mysterySymbol,
        },
    };
}

export const InitResponseSchema = Type.Object({
    coinAmount: Type.Optional(Type.Integer()),
    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),
    modelDefinition: Type.Object({
        validCoinAmounts: Type.Array(Type.Number()),
        reels: Type.Array(Type.Array(Type.String())),
    }),
    mysterySymbol: Type.Optional(Type.String()),
    // Restore information
    cumulativeWinAmount: Type.Optional(Type.Number()),
    genieBonusPhase: Type.Optional(GenieBonusPhaseSchema),
    genieBonusCount: Type.Optional(Type.Integer()),
    coinPrizes: Type.Optional(Type.Array(CoinPrizeSchema)),
});
export type InitResponse = Static<typeof InitResponseSchema>;
