import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

export const JackpotPhase = Type.Union([Type.Literal('START'), Type.Literal('END')]);

export type JackpotPhase = Static<typeof JackpotPhase>;

export const JackpotType = Type.Union([
    Type.Literal('MINOR'),
    Type.Literal('MINI'),
    Type.Literal('GRAND'),
    Type.Literal('MAJOR'),
]);
export type JackpotType = Static<typeof JackpotType>;
