/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0]);
            step.handsIds = [0];
            step.internalName = "Base step";
            step.cardsNo = 1;
            step.rows = [
                ["Random", "Random", null],
                ["Random", "Random", "Random"]
            ];
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        getReelStrips: (type) => State.state.getModelDefinition().reels,

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getCardsStep (stepTitle) {
            const step = State.state.createStep([0,0,0,0,0], false, 0, "", "Add cards step");
            step.internalName = "Add cards step";
            step.rows = [["Random"]];
            return step;
        },
        prepareStepForDB: (step) => {
             delete step.reelStripPositions;
            delete step.reelsType;
            delete step.variation;
            delete step.isFeature;
            return step;
        }
    };
}
