import { filterSlotWindow } from "@vgw/gdk-math-model-tools";
import type {
  CharacterWildsWithPositions,
  ReelSpinFeatures,
} from "../../reel-spin-features-response";
import type { MultiplierCell } from '../multipliers';

export function updateSlotWindowSymbolsWithFeatures(args: {
  featureResults?: {
    fullReelWilds?: number[];
    characterWilds?: CharacterWildsWithPositions;
    singleWilds?: [number, number][];
    multiplierCells?: MultiplierCell[];
  };
  reelSpinFeatures?: ReelSpinFeatures;
  slotWindowAfterFeaturesApplied: string[][];
  slotWindowBeforeAnyReelSpinFeaturesApplied: string[][];
}): { reelSpinFeatures?: ReelSpinFeatures; slotWindow: string[][] } {
  const {
    featureResults,
    reelSpinFeatures,
    slotWindowBeforeAnyReelSpinFeaturesApplied,
    slotWindowAfterFeaturesApplied,
  } = args;

  const positionsBySymbol: Record<string, [number, number][]> = [
    "PIC1",
    "PIC2",
    "PIC3",
  ].reduce(
    (mapping: Record<string, [number, number][]>, characterWildSymbol) => {
      mapping[characterWildSymbol] = filterSlotWindow(
        slotWindowBeforeAnyReelSpinFeaturesApplied,
        (symbol) => symbol === characterWildSymbol
      );
      return mapping;
    },
    {}
  );

  if (reelSpinFeatures?.beforeReelsStop && !reelSpinFeatures?.afterReelsStop) {
    // update main slotWindow
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: reelSpinFeatures?.beforeReelsStop.characterWilds,
      positionsBySymbol,
    });
  } else if (
    reelSpinFeatures?.afterReelsStop &&
    !reelSpinFeatures?.beforeReelsStop
  ) {
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: reelSpinFeatures?.afterReelsStop?.characterWilds,
      positionsBySymbol,
    });
    // Check for any features that were filtered out
    if (
      reelSpinFeatures.afterReelsStop.fullReelWilds &&
      reelSpinFeatures.afterReelsStop.fullReelWilds.length !== featureResults?.fullReelWilds?.length
    ) {
      const filterOutReels = featureResults?.fullReelWilds?.filter((reelIndex) => {
          return (
              reelSpinFeatures.afterReelsStop?.fullReelWilds?.find((reelIndexAfter) => {
                  return reelIndex === reelIndexAfter;
              }) === undefined
          );
      });
      filterOutReels?.forEach((reelIndex) => {
          slotWindowAfterFeaturesApplied[reelIndex] = slotWindowBeforeAnyReelSpinFeaturesApplied[reelIndex];
      });
    }
    if (
      reelSpinFeatures.afterReelsStop.singleWilds &&
      reelSpinFeatures.afterReelsStop.singleWilds.length !== featureResults?.singleWilds?.length
    ) {
      const filterOutSingleWilds = featureResults?.singleWilds?.filter((wildPosition) => {
          return (
              reelSpinFeatures.afterReelsStop?.singleWilds?.find((wildPositionAfter) => {
                  return wildPosition[0] === wildPositionAfter[0] && wildPosition[1] === wildPositionAfter[1];
              }) === undefined
          );
      });
      filterOutSingleWilds?.forEach((wildPosition) => {
          slotWindowAfterFeaturesApplied[wildPosition[1]][wildPosition[0]] =
              slotWindowBeforeAnyReelSpinFeaturesApplied[wildPosition[1]][wildPosition[0]];
      });
    }
    if (
      reelSpinFeatures.afterReelsStop.characterWilds &&
      reelSpinFeatures.afterReelsStop.characterWilds.pics.length !== featureResults?.characterWilds?.pics.length
    ) {
      const filterOutCharacterWilds = featureResults?.characterWilds?.pics?.filter((picSymbol) => {
          return (
              reelSpinFeatures.afterReelsStop?.characterWilds?.pics.find((picSymbolAfter) => {
                  return picSymbol === picSymbolAfter;
              }) === undefined
          );
      });
      filterOutCharacterWilds?.forEach((picSymbol) => {
          const picWildPositionsForPic = featureResults?.characterWilds?.positionToWilds.filter(
              (picWildPosition) => {
                  return picWildPosition.symbol === picSymbol;
              },
          );
          picWildPositionsForPic?.forEach((picWildPosition) => {
              slotWindowAfterFeaturesApplied[picWildPosition.reelIndex][picWildPosition.rowIndex] =
                  slotWindowAfterFeaturesApplied[picWildPosition.reelIndex][picWildPosition.rowIndex] =
                      slotWindowBeforeAnyReelSpinFeaturesApplied[picWildPosition.reelIndex][
                          picWildPosition.rowIndex
                      ];
          });
      });
    }
  } else if (
    reelSpinFeatures?.beforeReelsStop &&
    reelSpinFeatures?.afterReelsStop
  ) {
    const beforePics =
      reelSpinFeatures?.beforeReelsStop?.characterWilds?.pics ?? [];
    const afterPics =
      reelSpinFeatures?.afterReelsStop?.characterWilds?.pics ?? [];
    const characterWildsCombinedOfBeforeAndAfterReelStop: CharacterWildsWithPositions =
      {
        pics: [...beforePics, ...afterPics],
        positionToWilds: [
          ...(reelSpinFeatures?.beforeReelsStop?.characterWilds
            ?.positionToWilds ?? []),
          ...(reelSpinFeatures?.afterReelsStop?.characterWilds
            ?.positionToWilds ?? []),
        ],
      };

    // update intermittent slot window with character wilds triggered in before reel stop
    updateSlotWindowWithCharacterWilds({
      slotWindow:
        reelSpinFeatures?.afterReelsStop?.intermediateResults?.slotWindow,
      characterWildSymbols: reelSpinFeatures?.beforeReelsStop?.characterWilds,
      positionsBySymbol,
    });

    // update the main slot window with character wilds triggered in both before and after reel stop
    updateSlotWindowWithCharacterWilds({
      slotWindow: slotWindowAfterFeaturesApplied,
      characterWildSymbols: characterWildsCombinedOfBeforeAndAfterReelStop,
      positionsBySymbol,
    });
  }

  return {
    reelSpinFeatures: reelSpinFeatures,
    slotWindow: slotWindowAfterFeaturesApplied,
  };
}

function updateSlotWindowWithCharacterWilds(args: {
  slotWindow: string[][];
  positionsBySymbol: Record<string, [number, number][]>;
  characterWildSymbols?: CharacterWildsWithPositions;
}) {
  const { slotWindow, characterWildSymbols } = args;
  for (const symbolPosition of characterWildSymbols?.positionToWilds ?? []) {
    slotWindow[symbolPosition.reelIndex][
      symbolPosition.rowIndex
    ] = `${symbolPosition.symbol}_W`;
  }
}
