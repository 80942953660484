/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange,
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export type SymbolInfo = null | {
    enabled : boolean,
    price : number,
    jackpotType : string | null,
}

function customCreateStep(stepName : string = "Default", isFreeSpin: boolean = false, ) {
    const emptyReels = [0,0,0,0,0];
    const step =  State.state.createStep(emptyReels, isFreeSpin, 0, "", stepName);
    
    step.rows = [];
    for (let reel = 0; reel < State.state.getNumberOfReels(); reel++) {
        step.rows[reel] = [];
        const reelLength = reel == State.state.getNumberOfReels() - 1 ? State.state.getNumberOfRowsTopRow() : State.state.getNumberOfRows();

        for (let row = 0; row < reelLength; row++) {
            step.rows[reel].push("  ");
        }
    }

    return step;
}

function updateReelRows(length : number, newRows : string[]) : string[] {
    const rows : string[] = [];

    for (let row = 0; row < State.state.getNumberOfRows(); row++) {
        if (newRows[row] && row < length) {
            rows.push(newRows[row]);
        } else {
            rows.push("  ");
        }
    }
    return rows;
}

function updateTopReelRows(rows : string[]) {
    return updateReelRows(State.state.getNumberOfRowsTopRow(), rows);
}

function updateBottomReelRows(rows : string[]) {
    return updateReelRows(State.state.getNumberOfRows(), rows);
}

type SymLastWinReel = {
    symbol: string,
    lastWinReelIndex: number,
}

export type ReelInfoSet = {
    reels : string[][];
    symbolInfo : SymbolInfo[][];
}

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => {
            const step = customCreateStep("Default");
            step.rows[0] = updateBottomReelRows(["P1", "P2"]);
            step.rows[1] = updateBottomReelRows(["P3", "P4", "HT"]);
            step.rows[2] = updateBottomReelRows(["P5", "P6", "WD", "P2"]);
            step.rows[3] = updateBottomReelRows(["SE", "CB", "HT", "P1", "P5"]);
            step.rows[4] = updateBottomReelRows(["SE", "CB", "HT", "DD", "P1", "SC"]);
            step.rows[5] = updateBottomReelRows(["SE", "CB", "HT", "DD", "P1", "P5", "DD"]);
            step.rows[6] = updateTopReelRows(["SE", "CB", "HT", "DD",]);
            return step;
        },
        getReplaceSymbol: () : string => "REPLACE",
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => 7,
        getNumberOfRows: () => 7,
        getNumberOfRowsTopRow: () => 4,
        getReelStrips: () => null,
        getFeatureReelStrips: () => null,

        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTrigger: () => {
            const step = customCreateStep("F-Trig");
            step.rows[0] = updateBottomReelRows(["P1", "SC"]);
            step.rows[1] = updateBottomReelRows(["P3", "P4", "HT"]);
            step.rows[2] = updateBottomReelRows(["P3", "P4", "HT"]);
            step.rows[3] = updateBottomReelRows(["P1", "SC"]);
            step.rows[4] = updateBottomReelRows(["P1", "P2"]);
            step.rows[5] = updateBottomReelRows(["P1", "P2"]);
            step.rows[6] = updateTopReelRows(["SE", "CB", "SC", "DD",]);
            return step;
        },
        getBonusTriggerStep: () => {
            const step = customCreateStep("B-Trig");
            step.rows[0] = updateBottomReelRows(["P1", "SC", "CO", "CO"]);
            step.rows[1] = updateBottomReelRows(["P3", "P4", "HT"]);
            step.rows[2] = updateBottomReelRows(["P3", "P4", "HT", "P3", "P3", "DD", "CO"]);
            step.rows[3] = updateBottomReelRows(["CO", "P1", "SC"]);
            step.rows[4] = updateBottomReelRows(["P1", "P2"]);
            step.rows[5] = updateBottomReelRows(["P1", "CO"]);
            step.rows[6] = updateTopReelRows(["SE", "CO", "P6", "DD",]);
            return step;
        },
        getReels: (step) => {
            const where = step.json ? step.json : step;
            const reels = where.isFeature ? State.state.getFeatureReelStrips() : State.state.getReelStrips();
            return reels;
        },
        prepareStepForDB: (step) => {
            step.randoms = step.reelStripPositions;
            return step;
        },

        addExtraDataToStep: (step) => {
            return step;
        },

        getEmptySymbolInfo: () : any[][] => {
            let emptyInfo : any[][] = [];

            for (let i = 0; i < 6; i++) {
                emptyInfo.push(new Array(7).fill(null));
            }

            emptyInfo.push(new Array(4).fill(null));

            return emptyInfo;
        },

        getEmptyCascadeInfo: () : any[][] => {
            let emptyInfo : any[][] = [];

            for (let i = 0; i < 6; i++) {
                emptyInfo.push(new Array(7).fill(null));
            }

            emptyInfo.push(new Array(4).fill(null));

            return emptyInfo;
        },
        getDefaultSymbolInfo: () : SymbolInfo => {
            return {
                jackpotType: null,
                price: 1,
                enabled: false,
            }
        },

        cleanupRowData: (step) : void => {
            let reelLengths : number[] = [];
            
            step.json.rows.forEach((reel : string[], reelIndex) => {
                reelLengths[reelIndex] = reel.filter((symName) => symName.trim().length > 0).length;
            });

            step.json.cascadeInfos.forEach((reel : string[], reelIndex) => {
                reelLengths[reelIndex] = reel.filter((symName) => symName.trim().length > 0).length;
            });

            step.json.symbolInfos.forEach((siSet : SymbolInfo[][]) => {
                for (let reelIndex = 0; reelIndex < siSet.length; reelIndex++ ) {
                    siSet[reelIndex] = siSet[reelIndex].slice(0, reelLengths[reelIndex]);
                }
            });
        },
        
        disableSymbolInfos: (sis: SymbolInfo[][]) => {
            State.state.enableSymbolInfos(sis, false);
        },

        enableSymbolInfos: (sis : SymbolInfo[][], enable : boolean = true) => {
            sis.flat().flat().forEach((si : SymbolInfo) => {
                if (si != null) {
                    si.enabled = enable;
                }
            });
        },

        generateNextCascadeInitial: (previousReels : string[][], previousSymbolInfo : SymbolInfo[][]) : ReelInfoSet => {
            const symWins : SymLastWinReel[] = State.state.getWinDetails(previousReels);
            const newReels : string[][] = [];
            const newSymbolInfo : SymbolInfo[][] = [];
            const hasSymbolInfo : boolean = previousSymbolInfo.length > 0;
            const newCascadeSet : ReelInfoSet = {reels: [], symbolInfo: []};

            const symbolsMatch = (symMain : string, symToCheck : string) : boolean => {
                return symMain.toLowerCase() === symToCheck.toLowerCase()
                    || symToCheck.toLowerCase() === "wd"
            }

            for (let i = 0; i < previousReels.length; i++) {
                newReels[i] = previousReels[i].slice();
                if (hasSymbolInfo) {
                    newSymbolInfo[i] = previousSymbolInfo[i].slice();
                }
            }
            
            symWins.filter(sw => sw.lastWinReelIndex >= 2).forEach((sw : SymLastWinReel) => {
                for (let reelIndex = 0; reelIndex <= sw.lastWinReelIndex; reelIndex++) {
                    const verticalSymbols = newReels[reelIndex];
    
                    if (reelIndex > 0 && reelIndex < 5) {
                        const topReelRow = reelIndex - 1;
                        const symName : string = newReels[6][topReelRow];

                        if (symbolsMatch(sw.symbol, symName.toLowerCase())) {
                            newReels[6][topReelRow] = State.state.getReplaceSymbol();
                        }
                    }
    
                    for (let row = 0; row < verticalSymbols.length; row++) {
                        const symName : string = verticalSymbols[row];

                        if (symbolsMatch(sw.symbol, symName.toLowerCase())) {
                            verticalSymbols[row] = State.state.getReplaceSymbol();
                        }
                    }
                }
            });

            newReels[6].reverse();
            if (hasSymbolInfo)
                newSymbolInfo[6].reverse();

            for (let reelIndex = 0; reelIndex < newReels.length; reelIndex++) {
                const spotToFill : number[] = [];

                for (let row = newReels[reelIndex].length - 1; row >= 0; row--) {
                    if (newReels[reelIndex][row] == null || newReels[reelIndex][row].trim() === "") continue;

                    if (newReels[reelIndex][row] === State.state.getReplaceSymbol()) {
                        //needs to be replaced
                        spotToFill.push(row);
                    } else {
                        //is a valid symbol
                        if (spotToFill.length > 0) {
                            //has spot to fill
                            const fillSpot : number = <number> spotToFill.shift();
                            newReels[reelIndex][fillSpot] = newReels[reelIndex][row];
                            newReels[reelIndex][row] = State.state.getReplaceSymbol();

                            if (hasSymbolInfo) {
                                const sourceRow = reelIndex === 6 ? row - 3 : row;
                                const destRow = reelIndex === 6 ? fillSpot - 3 : fillSpot;
                                newSymbolInfo[reelIndex][destRow] = newSymbolInfo[reelIndex][sourceRow];
                                newSymbolInfo[reelIndex][sourceRow] = null;
                            }

                            spotToFill.push(row);
                        }
                    }
                }
            }

            newReels[6].reverse();
            if (hasSymbolInfo)
                newSymbolInfo[6].reverse();

            newCascadeSet.reels = newReels;
            if (hasSymbolInfo)
                newCascadeSet.symbolInfo = newSymbolInfo;

            return newCascadeSet;
        },

        getWinDetails: (rows : string[][]) : SymLastWinReel[] => {
            const symWins : SymLastWinReel[] = [];
            const containsSym = (symbol : string) : boolean => {
                return symWins.filter(sw => sw.symbol === symbol).length > 0;
            }
            
            for (let reelIndex = 0; reelIndex < 6; reelIndex++) {
                const verticalSymbols = rows[reelIndex].slice();

                if (reelIndex > 0 && reelIndex < 5) {
                    let topReelRow = reelIndex - 1;
                    verticalSymbols.push(rows[6][topReelRow]);
                }

                for (let row = 0; row < verticalSymbols.length; row++) {
                    const symName : string = verticalSymbols[row];
                    
                    if (!["CO","SC","  ",State.state.getReplaceSymbol()].some(blacklist => blacklist === symName)) {
                        if (reelIndex === 0) {
                            if (containsSym(symName)) {
                                if (symWins.filter(sw => sw.symbol === symName).length === 1) {
                                    symWins.filter(sw => sw.symbol === symName)[0].lastWinReelIndex = reelIndex;
                                } else {
                                    trace("LOL BROKEN LOLOL");
                                }
                            } else {
                                symWins.push({symbol: symName, lastWinReelIndex: reelIndex});
                            }
                        } else {
                            if (containsSym(symName)) {
                                if (symWins.filter(sw => sw.symbol === symName).length === 1) {
                                    const slw : SymLastWinReel = symWins.filter(sw => sw.symbol === symName)[0];
                                    if (slw.lastWinReelIndex === reelIndex - 1) {
                                        slw.lastWinReelIndex = reelIndex;
                                    }
                                } else {
                                    trace("LOL BROKEN LOLOL2");
                                }
                            } else if (symName === "WD") {
                                symWins.forEach(sw => {
                                    if (sw.lastWinReelIndex === reelIndex - 1) {
                                        sw.lastWinReelIndex = reelIndex;
                                    }
                                });
                            }
                        }
                    }
                }
            }

            return symWins;

        },
        
        canCascade: (rows : string[][]) : boolean => {
            const symWins : SymLastWinReel[] = State.state.getWinDetails(rows);
            return symWins.filter(sw => sw.lastWinReelIndex >= 2).length > 0;
        },

        getLastReelData: (step) : ReelInfoSet => {
            let latestStepReels : string[][];
            let latestStepSymbolInfo : SymbolInfo[][];
            const stepCascades : string[][][] = step.json.cascadeInfos;
    
            if (stepCascades != null && stepCascades.length > 0) {
                latestStepReels = stepCascades[stepCascades.length - 1];
                latestStepSymbolInfo = step.json.cascadeSymbolInfos[stepCascades.length - 1];
            } else if (step.json.rows != null && step.json.symbolInfos != null) {
                latestStepReels = step.json.rows;
                latestStepSymbolInfo = step.json.symbolInfos[0];
            } else {
                latestStepSymbolInfo = latestStepReels = [];
            }
    
            const lastStepSet : ReelInfoSet = {reels: latestStepReels, symbolInfo: latestStepSymbolInfo};
    
            return lastStepSet;
        },
    };
}
