/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";
import {StepType} from "../../../../components/game_content/slots/pf003_monsoon/content.vue";

export function setStateParams() {
    return {
        variations: [""],
        // Must implement
        createDefaultStep: (variation = "") => {
            return State.state.createCustomStep(StepType.BASE);
        },
        createCustomStep: (type) => {
            const stepsMap = {
                [StepType.BASE]: {
                    name: "Base Step"
                },
                [StepType.BASE_RESPIN]: {
                    name: "Base Respin Step"
                },
                [StepType.FS_TRIGGER]: {
                    name: "FS Trigger Step",
                    reelStripPositions: [24, 37, 12, 0, 0]
                },
                [StepType.PICK]: {
                    name: "Pick Step"
                },
                [StepType.FREE_SPIN]: {
                    name: "FS Step"
                },
                [StepType.FREE_SPIN_RESPIN]: {
                    name: "FS Respin Step"
                }
            };
            return {
                ...State.state.createStep([0,0,0,0,0], false, 0, "", ""),
                ...(stepsMap[type] ? stepsMap[type] : {}),
                type: type
            };
        },
        getLayout: () =>{
            State.state.getModelDefinition().reelsLayout.join("-");
        } ,
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getReelStrips: (reelStrip) => {
            if (!State.state.getModelDefinition().extraReels[reelStrip]) {
                return State.state.getModelDefinition().reels;
            } else {
                return State.state.getModelDefinition().extraReels[reelStrip].reels;
            }
        },

        // example on creating custom indexes
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelSetId);
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, state.getNumberOfReels(), state.getNumberOfRows());
            step.randoms = step.reelStripPositions;
            return step;
        }
    };
}
