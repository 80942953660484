import { GameState, modelDefinition } from "../config";
import { getWinCells } from "./get-win-cells";
import { getWinType } from "./get-win-type";
import { SpinOutputs } from "./spin-outputs";
import { SpinOutcome } from "./spin-outcome";
import { Choices, generateSlotWindow } from "../choices";
import {
  CoinType,
  createLineWinChecker,
  filterSlotWindow,
} from "../../../../v3/math-model";
import { SlowBuffer } from "buffer";

export type FreeSpinInputs = {
  coinSize: number;
  choices: Choices;
  coinTypeId: CoinType;
  gameState: GameState;
};

export function freeSpin(inputs: FreeSpinInputs): SpinOutputs {
  const { coinSize, choices } = inputs;

  const { cumulativeWinAmount: oldCumulativeWinAmount } = inputs.gameState;

  // Generate the slot window
  const totalPlayAmount = coinSize * coinAmountMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions();
  const slotWindow = generateSlotWindow(reelStripPositions);

  const newSlotWindow = substituteScatters(slotWindow);

  // Evaluate line wins
  const wins = checkLineWins(newSlotWindow);
  const winLines = wins.map((win) => win.playLineIndex + win.length / 10);
  const lineWins = wins.map((win) => win.multiplier * coinSize);
  const winCells = wins.map((win) =>
    getWinCells(win.playLineIndex, win.length)
  );

  // Evaluate scatter win
  const scatterWin = checkScatterWin(newSlotWindow);
  if (scatterWin) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount);
    winCells.push(scatterWin.cells);
  }

  // Compute win type and amount
  const totalWinAmount = lineWins.reduce((sum, amt) => sum + amt, 0);
  const cumulativeWinAmount = oldCumulativeWinAmount + totalWinAmount;

  // Calculate new game state
  const isFreeSpinReTrigger = scatterWin !== undefined;
  let { freeSpinCount, freeSpinPhase } = inputs.gameState;
  freeSpinCount -= 1;
  freeSpinPhase = freeSpinCount > 0 ? "IN_PROGRESS" : "END";

  if (scatterWin) {
    freeSpinPhase = "RETRIGGER";
    freeSpinCount += modelDefinition.freeSpinsRetriggerCount;
  }

  const gameState =
    freeSpinPhase === "END"
      ? undefined
      : {
          coinSize,
          reelStripPositions,
          slotWindow: newSlotWindow,
          cumulativeWinAmount,
          freeSpinPhase,
          freeSpinCount,
        };

  const winType = getWinType({
    totalWinAmount,
    isFreeSpinTrigger: isFreeSpinReTrigger,
  });

  const spinOutcome: SpinOutcome = {
    coinSize,
    totalPlayAmount,
    reelStripPositions,
    slotWindow: newSlotWindow,
    totalWinAmount,
    winType,
    winLines,
    lineWins,
    winCells,
    freeSpinCount,
    freeSpinPhase,
    cumulativeWinAmount,
  };

  return {
    spinOutcome,
    gameState,
  };
}

const { coinAmountMultiplier, scatterSymbol, winTable } = modelDefinition;
const checkLineWins = createLineWinChecker(modelDefinition);

function checkScatterWin(slotWindow: string[][]) {
  const cells = filterSlotWindow(
    slotWindow,
    (sym) => sym === modelDefinition.wildSymbol
  );
  const tableIndex = slotWindow.length - cells.length;
  const multiplier =
    tableIndex < scatterWinMultipliers.length
      ? scatterWinMultipliers[tableIndex]
      : 0;
  return multiplier ? { cells, multiplier } : undefined;
}
const scatterWinMultipliers =
  winTable.find((entry) => entry.symbol === scatterSymbol)?.multipliers ?? [];

function substituteScatters(slotWindow: string[][]) {
  const newSlotWindow = structuredClone(slotWindow);
  for (let i = 0; i < newSlotWindow.length; i++) {
    const reel = newSlotWindow[i];
    for (let j = 0; j < reel.length; j++) {
      if (reel[j] === scatterSymbol) {
        reel[j] = modelDefinition.wildSymbol;
      }
    }
  }
  return newSlotWindow;
}
