/* tslint:disable:variable-name */
import Vue from "vue";
import {Prop, Component, Watch, Emit} from "vue-property-decorator";
import * as casino from "./../../helpers/casino";
import * as device from "./../../helpers/device";
import {trace, TODO, setOrder, createDefaultSlotStep, getAssetsPath} from "./../../helpers/helpers";
import State from "../../state/state";
import bus from "../../common/bus";
import events from "../../common/events";
import draggable from "vuedraggable"; 

@Component({
    components: {
        draggable,
    },
    // define reactive variables
    data: () => {
        return {
            scenario: null,
            selectedStep: null,
            steps: [],
        };
    },
})
export default class ListOfSteps extends Vue {  
    public btnAddStep: string = `${getAssetsPath()}/images/btn_new_step.png`;
    public btnTrash: string = `${getAssetsPath()}/images/btn_trash_white.png`;  
    public btnClone: string = `${getAssetsPath()}/images/btn_clone.png`;  
    public btnMove: string = `${getAssetsPath()}/images/btn_arrows_white.png`;  
    public btnPlayScenario: string = `${getAssetsPath()}/images/btn_play_scenario.png`;  

    public scenario: any = null;
    public selectedStep: any = null;
    public steps: any = [];
    public dragging = false;
    public showPlay = true;
    public showPulse = false;
    public showCheck = false;
    public isMobile = device.isDesktop() || device.isTablet();
    public updateTimeout: any = null;
    constructor() {
        super();

        bus.$on(events.CHANGE_SCENARIO, (scenario) => this.changeScenario(scenario) );
        bus.$on(events.REFRESH_SCENARIO, (scenario) => this.refreshScenario(scenario) );
        bus.$on(events.UPDATE_STEPS_DB, (step) => this.updateStepOnDB(step) );
        bus.$on(events.ADD_STEP, (step) => this.addStep(step) );
        bus.$on(events.ADD_STEPS, (steps) => this.addSteps(steps) );
        bus.$on(events.EDIT_STEPS, (scenarioSteps) => this.editSteps(scenarioSteps) );
    }
    public changeScenario(scenario) {
        this.dragging = false; 
        this.scenario = scenario;
        // trace(scenario)
        if (!scenario) {
            this.steps = [];
            this.selectedStep = null;
            this.triggerChange();
            return;
        }
        this.steps = scenario.steps; 
        this.addOrSelectFirst();
    }
    public async refreshScenario(scenario) {
        trace("Refreshing scenario");
        // trace(scenario);
        if (scenario.test_scenario_id !== this.scenario.test_scenario_id) { return; } 
        const previouslySelectedStepId = this.selectedStep ? this.selectedStep.test_scenario_step_id : null;
        this.steps = scenario.steps; 
        let selectedStep = this.steps.find(it => it.test_scenario_step_id === previouslySelectedStepId);
        
        if (this.steps.length === 0) {
            return;
        } else if (this.steps.length === 1) {
            selectedStep = this.steps[0];
        }
        
        if (selectedStep == null) {
            selectedStep = this.steps[0];

            let p: any = null;
            bus.$emit(events.SHOW_POPUP, (promise) => p = promise, "The step you where working on was deleted :(", false, false, true, false, true);
            await p; 
        }
        this.selectedStep = selectedStep;
        this.triggerChange();
    }
    private addOrSelectFirst() {
        if (this.steps.length === 0) {
            this.addStep();
        } else {
            this.selectedStep = this.steps[0];
            this.triggerChange();
        }
    }
    public async exportScenario() {
        const jsonScenario = JSON.stringify(this.steps.map((step) => step.json));
        const blob = new Blob([jsonScenario], { type: 'text/plain' });
        await navigator.clipboard.write(
            [new ClipboardItem({ [blob.type]: blob })]
        );
        alert("The selected scenario has been copied to your clipboard.")
    }

    public triggerChange() {
        bus.$emit(events.CHANGE_STEP, this.selectedStep);
        // if (this.selectedStep) { trace("order: " + this.selectedStep.json.order); }
        console.log('this.selectedStep', this.selectedStep);
    }
    public addStep(stepJson = null) {
        const state = State.state;
        stepJson = stepJson === null ? state.createDefaultStep() : stepJson;
        const order_number = this.steps ? this.steps.length : 0;
        state.addStep(this.scenario.test_scenario_id, stepJson, order_number);
    }
    public addSteps(steps) {
        const state = State.state;
        const order_number = this.steps ? this.steps.length : 0;
        state.addSteps(this.scenario.test_scenario_id, steps, order_number);
    }
    public editSteps(scenarioSteps) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            const test_scenario_id = this.scenario.test_scenario_id;
            const test_scenario_step_ids = scenarioSteps.map(s => s.test_scenario_step_id);
            const order_number = Math.min(0, ...scenarioSteps.map(s => s.order_number));
            const steps = scenarioSteps.map(s => s.json);
            trace("editSteps > " + test_scenario_step_ids.join(', '));
            State.state.editSteps(test_scenario_id, test_scenario_step_ids, steps, order_number);
        }, 350);
    }
    public async cloneStep(i) {
        const state = State.state;
        this.addStep(JSON.parse(JSON.stringify( this.steps[i].json ))); 
    }
    public async renameStep(i) {
        const step = this.steps[i];
        const name = step.json.name;
        const msg = "Rename step (max 20 chars)";
        let p: any = null;
        bus.$emit(events.SHOW_INPUT_POPUP, (promise) => p = promise, msg, name);

        const res = await p; 
        if (!res || res === "") { return; }
        step.json.name = res.slice(0, 20); 
        this.updateStepOnDB(step);
    }
    public async deleteStep(i) {
        const state = State.state;
        const msg = "Delete this step?";
        let p: any = null;
        bus.$emit(events.SHOW_POPUP, (promise) => p = promise, msg, true, true, false, false, true);
        const res = await p;
        if (res) {
            const test_scenario_id = this.scenario.test_scenario_id;
            const test_scenario_step_id = this.steps[i].test_scenario_step_id;
            this.steps.splice(i, 1);
            setOrder(this.steps);
            state.deleteStep(test_scenario_id, test_scenario_step_id, this.steps);
            this.addOrSelectFirst();
        }
    }
    public onMoveCallback(evt, originalEvent) {
        trace("moving...");
    }
    public startDrag(evt) {
        this.dragging = true; 
    }
    public async endDrag(evt) { 
        const state = State.state;
        this.dragging = false;
        bus.$emit(events.RECREATE_SCENARIO, this.steps);
    }
    public openStep(step) {
        if (this.dragging) {
            return;
        }
        this.selectedStep = step;
        this.triggerChange();
    }
    public playScenario() {
        this.showPlay = false;
        this.showPulse = true;
        this.showCheck = false;

        bus.$once(events.SCENARIO_STARTED, () => {
            setTimeout(() => {
                this.showPulse = false;
                this.showCheck = true;
                setTimeout(() => {
                    this.showCheck = false;
                    this.showPlay = true;
                }, 1000);
            }, 500);
        });
        
        State.state.startScenario(this.scenario.test_scenario_id);
    }
    public updateStepOnDB(step) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            trace("updateStepOnDB > " + step.test_scenario_step_id);
            const order_number = step.order_number;
            State.state.editStep(this.scenario.test_scenario_id, step.test_scenario_step_id, step.json, order_number);
        }, 350);
    }

    @Watch("steps", {deep: true}) 
    public stepsWatch(newVal, oldVal) {
        if (newVal.length > oldVal.length) this.selectedStep = newVal[newVal.length - 1];
        this.triggerChange();
    }
}
