/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"modelDefinition": {
        "modelId" : "vgw037",
        
        "scatterSymbol": "SCAT",
        "wildSymbol": "WILD",
        
        "freeSpinTrigger" : "SCAT",
        "countToTriggerFreeSpin" : 3,
        "freeSpinCount" : 10,
        "reelsLayout": [3, 3, 3, 3, 3],
        
        "winTable": [
            {
            "symbol":"WILD",
            "multipliers":[200, 75, 25]
            },
            {
            "symbol":"PIC1",
            "multipliers": [150,60,20]
            },
            {
            "symbol":"PIC2",
            "multipliers": [100,50,15]
            },
            {
            "symbol":"PIC3",
            "multipliers": [75, 40, 10]
            },
            {
            "symbol":"PIC4",
            "multipliers": [50, 20, 8]
            },
            {
            "symbol":"PIC5",
            "multipliers": [40, 15, 8]
            },
            {
            "symbol":"S",
            "multipliers": [30, 10, 5]
            },
            {
            "symbol":"H",
            "multipliers": [30, 10, 5]
            },
            {
            "symbol":"D",
            "multipliers": [30, 10, 5]
            },
            {
            "symbol":"C",
            "multipliers": [30,10,5]
            },
            {
            "symbol": "SCAT",
            "multipliers": [0,0,0]
            }
        ],
        
        "playLines" : [
            [1,1,1,1,1],
            [0,0,0,0,0],
            [2,2,2,2,2],
            [0,1,2,1,0],
            [2,1,0,1,2],
            [0,0,1,0,0],
            [2,2,1,2,2],
            [1,2,2,2,1],
            [1,0,0,0,1],
            [0,1,1,1,0],
            [2,1,1,1,2],
            [0,1,0,1,0],
            [2,1,2,1,2],
            [1,0,1,0,1],
            [1,2,1,2,1],
            [1,1,0,1,1],
            [1,1,2,1,1],
            [0,2,0,2,0],
            [2,0,2,0,2],
            [1,0,2,0,1],
            [1,2,0,2,1],
            [0,0,2,0,0],
            [2,2,0,2,2],
            [0,2,2,2,0],
            [2,0,0,0,2],
            [0,2,1,2,0],
            [2,0,1,0,2],
            [0,0,1,2,2],
            [2,2,1,0,0],
            [1,0,1,2,1],
            [1,2,1,0,1],
            [0,2,1,0,2],
            [2,0,1,2,0],
            [1,0,2,1,0],
            [1,2,0,1,2],
            [0,1,2,0,1],
            [2,1,0,2,1],
            [0,0,0,1,2],
            [2,2,2,1,0],
            [1,1,1,0,2],
            [1,1,1,2,0],
            [0,2,0,1,0],
            [2,0,2,1,2],
            [0,1,0,2,0],
            [2,1,2,0,2],
            [0,0,2,1,2],
            [2,2,0,1,0],
            [0,1,1,1,2],
            [2,1,1,1,0],
            [1,0,0,1,2]
        ],
        
        "reels" : [
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "C", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "PIC3", "PIC5", "S", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "PIC5", "C", "PIC3", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "D", "S", "PIC3", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "D", "C", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "PIC5", "D", "S", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "PIC4", "PIC5", "S", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "C", "H", "PIC4", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5","H","S","D"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "C", "S", "PIC4", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "D", "H", "S", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "D", "C", "S", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "PIC2", "PIC2", "PIC2", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "WILD", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "PIC4", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "PIC3", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
        ],
        
        "fsFeatureReels" : {
            "4520":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "C", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "PIC3", "PIC5", "S", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "PIC5", "C", "PIC3", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "D", "S", "PIC3", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "D", "C", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "PIC5", "D", "S", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "PIC4", "PIC5", "S", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "C", "H", "PIC4", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "PIC1", "PIC1", "PIC1", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5","H","S","D"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "C", "S", "PIC4", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "D", "H", "S", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "D", "C", "S", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "S", "PIC4", "PIC3", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "PIC2", "PIC2", "PIC2", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "WILD", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "PIC3", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "5018":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "PIC5", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "C", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "PIC3", "PIC5", "S", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "PIC5", "C", "PIC3", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "D", "S", "PIC3", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "D", "C", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "PIC5", "D", "S", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "PIC4", "PIC5", "S", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "C", "H", "PIC4", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5","H","S","D"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "C", "S", "PIC4", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "D", "H", "S", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "D", "C", "S", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "PIC2", "PIC2", "PIC2", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "WILD", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "PIC3", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "6015":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "C", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "PIC3", "PIC5", "S", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "PIC5", "C", "PIC3", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "WILD", "WILD", "WILD", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "D", "C", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "PIC5", "D", "S", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "PIC4", "PIC5", "S", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "WILD", "WILD", "WILD", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5","H","S","D"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "WILD", "WILD", "WILD", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "D", "H", "S", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "D", "C", "S", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "PIC2", "PIC2", "PIC2", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "WILD", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "PIC3", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "7512":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "WILD", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "WILD", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "PIC3", "PIC5", "S", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "PIC5", "C", "PIC3", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "WILD", "WILD", "WILD", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "WILD", "WILD", "WILD", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "WILD", "WILD", "WILD", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "PIC4", "PIC5", "S", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "WILD", "WILD", "WILD", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5", "H", "S", "D" ],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "WILD", "WILD", "WILD", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "D", "H", "S", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "WILD", "WILD", "WILD", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "WILD", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "WILD", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "9010":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "WILD", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "WILD", "WILD", "WILD", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "WILD", "WILD", "WILD", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "WILD", "WILD", "WILD", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "C", "PIC4", "D", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "C", "D", "PIC5", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "WILD", "WILD", "WILD", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "WILD", "WILD", "WILD", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "WILD", "WILD", "WILD", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "WILD", "WILD", "WILD", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5", "H", "S", "D"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "WILD", "WILD", "WILD", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "S", "C", "D", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "H", "D", "C", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "WILD", "WILD", "WILD", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "C", "PIC3", "H", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "PIC4", "PIC5", "D", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "H", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "WILD", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "1058":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "PIC5", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "WILD", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "C", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "C", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "WILD", "WILD", "WILD", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "PIC5", "H", "S", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "WILD", "WILD", "WILD", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "C", "H", "S", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "WILD", "WILD", "WILD", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "WILD", "WILD", "WILD", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "D", "PIC4", "S", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "WILD", "WILD", "WILD", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "S", "PIC5", "H", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "WILD", "WILD", "WILD", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "WILD", "WILD", "WILD", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "PIC4", "PIC5", "C", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "WILD", "WILD", "WILD", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "H", "S", "C", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5", "WILD", "WILD", "WILD"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "WILD", "WILD", "WILD", "H", "PIC5", "PIC3", "D", "C", "S", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "WILD", "WILD", "WILD", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "S", "H", "D", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "D", "H", "C", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "S", "D", "C", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "WILD", "WILD", "WILD", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "PIC4", "H", "S", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "WILD", "WILD", "WILD", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "WILD", "WILD", "WILD", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5","C","D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "PIC4", "PIC5", "WILD", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "WILD", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "PIC3", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "H", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "WILD", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "D", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "S", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5"]
            ],
            "1455":[
            ["C", "WILD", "H", "PIC5", "SCAT", "PIC2", "PIC2", "PIC2", "D", "C", "PIC1", "PIC1", "PIC1", "S", "SCAT", "S", "C", "PIC1", "PIC1", "PIC1", "H", "C", "SCAT", "S", "PIC4", "H", "PIC4", "C", "S", "WILD", "H", "PIC1", "PIC1", "PIC1", "C", "PIC2", "PIC2", "PIC2", "D", "PIC3", "D", "PIC5", "SCAT", "H", "PIC5", "PIC3", "WILD", "D", "PIC4", "D", "C", "SCAT", "D", "S", "PIC5", "PIC2", "PIC2", "PIC2", "H", "D", "SCAT", "D", "D", "S", "PIC3", "PIC5", "D", "WILD", "S", "PIC4", "PIC3", "C", "SCAT", "D", "S", "PIC5", "C", "SCAT", "PIC1", "PIC1", "PIC1", "D", "H", "PIC5", "PIC3", "WILD", "PIC3", "PIC2", "PIC2", "PIC2", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "PIC5", "PIC4", "D", "PIC3", "S", "S", "SCAT", "PIC4", "PIC4", "PIC4", "D", "S", "D", "PIC1", "PIC1", "PIC1", "S", "D", "SCAT", "PIC5", "PIC1", "PIC1", "PIC1", "D", "H", "WILD", "PIC5", "H", "PIC4", "PIC4", "PIC4", "SCAT", "H", "PIC5", "PIC2", "PIC2", "PIC2", "C", "S", "S", "SCAT", "PIC5", "C", "D", "PIC4", "PIC4", "PIC4", "H", "S", "H", "PIC1", "PIC1", "PIC1", "S", "H", "WILD", "S", "H", "PIC4", "PIC4", "PIC4", "PIC5", "H", "PIC3", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "S", "D", "PIC3", "PIC4", "C", "S", "PIC5", "WILD", "H", "PIC1", "PIC1", "PIC1", "PIC3", "S", "PIC5", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "H", "PIC2", "PIC2", "PIC2", "S", "PIC5", "PIC4", "PIC3", "D", "WILD", "PIC3", "C", "PIC3", "S", "C", "PIC1", "PIC1", "PIC1", "D", "PIC4", "PIC5", "H", "SCAT", "S", "PIC2", "C", "D", "PIC3", "WILD", "PIC4", "H", "WILD", "D", "PIC4", "PIC3", "D", "S", "PIC4", "D", "C", "PIC2", "PIC2", "PIC2", "D", "PIC5", "PIC1", "PIC1", "PIC1", "S", "PIC5", "PIC3", "H", "PIC1", "PIC1", "PIC1", "PIC5", "D", "PIC5", "PIC2", "PIC2", "PIC2", "H", "S", "PIC5", "PIC4", "S", "PIC3", "S", "D", "PIC1", "PIC1", "PIC1", "S", "PIC4", "H", "S", "WILD", "H", "PIC2", "PIC2", "PIC2", "H", "D", "PIC3", "PIC3", "SCAT", "S", "PIC2", "PIC2", "PIC2", "C", "D", "S", "PIC4", "PIC5", "SCAT"],
            ["PIC4", "PIC5", "WILD", "WILD", "WILD", "D", "PIC1", "PIC1", "PIC1", "PIC4", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "C", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "PIC3", "D", "S", "PIC4", "WILD", "WILD", "WILD", "D", "PIC4", "PIC5", "STACK", "STACK", "STACK", "PIC3", "S", "WILD", "WILD", "WILD", "H", "S", "PIC4", "C", "PIC1", "PIC1", "PIC1", "C", "PIC5", "C", "PIC4", "PIC5", "WILD", "WILD", "WILD", "S", "PIC3", "D", "WILD", "WILD", "WILD", "PIC4", "S", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "PIC5", "C", "WILD", "WILD", "WILD", "PIC4", "D", "C", "H", "S", "PIC5", "H", "PIC2", "PIC2", "PIC2", "S", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "S", "PIC3", "WILD", "WILD", "WILD", "PIC4", "PIC5", "C", "PIC2", "PIC2", "PIC2", "H", "PIC3", "WILD", "WILD", "WILD", "PIC5", "S", "D", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC4", "D", "S", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "PIC4", "D", "WILD", "WILD", "WILD", "D", "PIC3"],
            ["S", "PIC5", "PIC4", "H", "S", "H", "WILD", "WILD", "WILD", "C", "PIC4", "C", "PIC2", "PIC2", "PIC2", "D", "C", "SCAT", "PIC5", "D", "H", "STACK", "STACK", "STACK", "PIC3", "D", "C", "PIC1", "PIC1", "PIC1", "PIC5", "SCAT", "WILD", "WILD", "WILD", "PIC3", "PIC5", "S", "PIC5", "D", "SCAT", "C", "D", "PIC2", "PIC2", "PIC2", "PIC4", "C", "WILD", "WILD", "WILD", "PIC3", "C", "SCAT", "D", "WILD", "WILD", "WILD", "S", "SCAT", "PIC1", "PIC1", "PIC1", "D", "S", "WILD", "WILD", "WILD", "PIC3", "SCAT", "PIC5", "PIC3", "H", "PIC5", "PIC4", "PIC2", "PIC2", "PIC2", "PIC5", "H", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC4", "H", "PIC5", "C", "STACK", "STACK", "STACK", "PIC5", "WILD", "WILD", "WILD", "PIC3", "SCAT", "C", "D", "S", "SCAT", "C", "PIC1", "PIC1", "PIC1", "PIC5", "PIC2", "PIC2", "PIC2", "PIC5", "D", "PIC3", "C", "PIC4", "H", "PIC1", "PIC1", "PIC1", "H", "D", "WILD", "WILD", "WILD", "D", "SCAT", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "SCAT", "PIC5", "S", "PIC3", "PIC5", "WILD", "WILD", "WILD", "D", "D", "SCAT", "H", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "S", "D", "PIC1", "PIC1", "PIC1", "H", "S", "WILD", "WILD", "WILD", "D", "PIC4", "S", "D", "PIC4", "D", "PIC2", "PIC2", "PIC2", "C", "D", "STACK", "STACK", "STACK", "D", "H", "S", "PIC5", "WILD", "WILD", "WILD", "H", "C", "WILD", "WILD", "WILD", "SCAT", "D", "S", "PIC2", "PIC2", "PIC2", "D", "STACK", "STACK", "STACK", "C", "D", "PIC4", "S", "PIC1", "PIC1", "PIC1", "D", "SCAT", "PIC5", "D", "PIC4", "S", "PIC5", "WILD", "WILD", "WILD"],
            ["C", "PIC2", "PIC2", "PIC2", "PIC3", "PIC4", "STACK", "STACK", "STACK", "D", "PIC3", "PIC5", "WILD", "WILD", "WILD", "H", "PIC5", "PIC3", "WILD", "WILD", "WILD", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "D", "PIC5", "S", "PIC1", "PIC1", "PIC1", "C", "D", "STACK", "STACK", "STACK", "C", "PIC3", "PIC4", "WILD", "WILD", "WILD", "PIC5", "D", "S", "PIC2", "PIC2", "PIC2", "C", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "PIC4", "S", "PIC5", "S", "PIC4", "D", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "PIC1", "PIC1", "PIC1", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "PIC5", "WILD", "WILD", "WILD", "PIC5", "D", "STACK", "STACK", "STACK", "PIC5", "S", "C", "PIC5", "S", "PIC3", "H", "C", "PIC2", "PIC2", "PIC2", "S", "WILD", "WILD", "WILD", "PIC3", "C", "PIC5", "C", "H", "PIC4", "S", "PIC5", "PIC2", "PIC2", "PIC2", "D", "D", "C", "STACK", "STACK", "STACK", "PIC3", "WILD", "WILD", "WILD", "S", "D", "H", "PIC5", "PIC5", "C", "PIC3", "WILD", "WILD", "WILD", "PIC1", "PIC1", "PIC1", "H", "S", "C", "PIC4", "S", "H", "PIC2", "PIC2", "PIC2", "D", "PIC4", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "C", "H", "PIC4", "WILD", "WILD", "WILD", "PIC2", "PIC2", "PIC2", "PIC4", "S", "PIC5", "PIC4", "C", "H", "D", "WILD", "WILD", "WILD", "H", "PIC5", "C", "D", "PIC3", "C", "PIC3", "WILD", "WILD", "WILD", "PIC3", "S", "H", "PIC2", "PIC2", "PIC2", "D", "S", "WILD", "WILD", "WILD", "PIC4", "H", "STACK", "STACK", "STACK", "H", "D", "WILD", "WILD", "WILD", "D", "C", "PIC4", "H", "D", "PIC1", "PIC1", "PIC1", "H", "PIC3", "D", "C", "H", "PIC5", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "C", "PIC5", "PIC4", "WILD", "WILD", "WILD", "D", "PIC2", "PIC2", "PIC2", "H", "PIC3", "PIC5", "WILD", "WILD", "WILD", "S", "PIC5", "H", "PIC5", "C", "D"],
            ["H", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC4", "C", "H", "PIC4", "PIC5", "WILD", "C", "PIC3", "D", "C", "H", "STACK", "STACK", "STACK", "PIC4", "WILD", "D", "SCAT", "H", "PIC5", "PIC4", "S", "PIC5", "WILD", "C", "PIC1", "PIC1", "PIC1", "H", "PIC5", "PIC2", "PIC2", "PIC2", "S", "WILD", "PIC5", "PIC3", "C", "D", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "PIC2", "PIC2", "PIC2", "PIC3", "C", "PIC5", "SCAT", "PIC1", "PIC1", "PIC1", "D", "C", "PIC5", "SCAT", "D", "WILD", "PIC4", "PIC5", "D", "PIC2", "PIC2", "PIC2", "C", "PIC3", "C", "PIC5", "WILD", "C", "D", "H", "PIC3", "S", "PIC5", "C", "PIC5", "SCAT", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "S", "H", "PIC3", "PIC5", "S", "PIC3", "PIC5", "WILD", "C", "PIC4", "H", "C", "SCAT", "S", "H", "PIC1", "PIC1", "PIC1", "D", "H", "PIC4", "C", "D", "PIC5", "S", "SCAT", "PIC5", "WILD", "S", "C", "PIC3", "PIC5", "D", "PIC2", "PIC2", "PIC2", "PIC3", "PIC5", "D", "C", "SCAT", "C", "D", "WILD", "H", "S", "STACK", "STACK", "STACK", "PIC4", "D", "C", "S", "PIC3", "D", "PIC1", "PIC1", "PIC1", "C", "D", "PIC4", "S", "C", "PIC3", "H", "D", "SCAT", "S", "PIC5", "PIC2", "PIC2", "PIC2", "SCAT", "H", "C", "PIC5", "H", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "PIC5", "D", "PIC5", "SCAT", "D", "S", "PIC4", "S", "PIC3", "PIC5", "C", "PIC3", "WILD", "H", "PIC3", "D", "PIC2", "PIC2", "PIC2", "STACK", "STACK", "STACK", "SCAT", "C", "S", "PIC1", "PIC1", "PIC1", "PIC3", "C", "PIC5", "H", "PIC4", "D", "C", "PIC5", "C", "STACK", "STACK", "STACK", "STACK", "STACK", "STACK", "D", "SCAT", "H", "PIC5", "C", "PIC3", "S", "PIC5" ]
            ]
        },
        
        "featureMultiplier" : {
            "4520":20,
            "7512":12,
            "1455":5,
            "5018":18,
            "6015":15,
            "9010":10,
            "1058":8
        },
        
        "stack":{
            "baseStackSelector":[
            { "code":"PIC1", "weight":5 },
            { "code":"PIC2", "weight":7 },
            { "code":"PIC3", "weight":10 },
            { "code":"PIC4", "weight":10 },
            { "code":"PIC5", "weight":10 }
            ],
            "featureGame4520":[
            { "code":"PIC1", "weight":20 },
            { "code":"PIC2", "weight":20 },
            { "code":"PIC3", "weight":225 },
            { "code":"PIC4", "weight":250 },
            { "code":"PIC5", "weight":200 }
            ],
            "featureGame5018":[
            { "code":"PIC1", "weight":200 },
            { "code":"PIC2", "weight":240 },
            { "code":"PIC3", "weight":998 },
            { "code":"PIC4", "weight":1000 },
            { "code":"PIC5", "weight": 1000}
            ],
            "featureGame6015":[
            { "code":"PIC1", "weight": 200 },
            { "code":"PIC2", "weight": 217 },
            { "code":"PIC3", "weight": 994 },
            { "code":"PIC4", "weight": 1000 },
            { "code":"PIC5", "weight": 1000 }
            ],
            "featureGame7512":[
            { "code":"PIC1", "weight":200 },
            { "code":"PIC2", "weight":217 },
            { "code":"PIC3", "weight":698 },
            { "code":"PIC4", "weight":690 },
            { "code":"PIC5", "weight":690 }
            ],
            "featureGame9010": [
            { "code":"PIC1", "weight":250 },
            { "code":"PIC2", "weight":300 },
            { "code":"PIC3", "weight":997 },
            { "code":"PIC4", "weight":1000 },
            { "code":"PIC5", "weight":1000 }
            ],
            "featureGame1058":[
            { "code":"PIC1", "weight": 200 },
            { "code":"PIC2", "weight": 300 },
            { "code":"PIC3", "weight": 1049 },
            { "code":"PIC4", "weight": 1055 },
            { "code":"PIC5", "weight": 1055 }
            ],
            "featureGame1455":[
            { "code":"PIC1", "weight": 250 },
            { "code":"PIC2", "weight": 402 },
            { "code":"PIC3", "weight": 947 },
            { "code":"PIC4", "weight": 950 },
            { "code":"PIC5", "weight": 1000 }
            ]
        },
        
        "stackSymbol":{
            "stackSymbolSelector":[
            { "code":1, "symbol":"PIC1" },
            { "code":2, "symbol":"PIC2" },
            { "code":3, "symbol":"PIC3" },
            { "code":4, "symbol":"PIC4" },
            { "code":5, "symbol":"PIC5"}
            ]
        },
        
        "startFeatureMultiplier": 1,
        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],
        
        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "4000000",
        
        "coinType4_min_bet": "1",
        "coinType4_max_bet": "50",
        "coinType4_spin_max_bet": "2000"
    },
      
	"modelId": "vgw037",
	"gameSessionId": 891,
	"reelStripPositions": [0, 0, 0, 0, 0],
	"slotWindow": [
		["C","WILD","H"],
		["PIC4","PIC5","PIC3"],
		["S","PIC5","PIC4"],
		["C","PIC2","PIC2"],
		["H","PIC2","PIC2"]
	],
	"gold_balance": 10000000,
	"sweeps_balance": 10000000,
	"sweeps_deposit_balance": 10000000,
	"sweeps_promo_balance": 0,
	"sweeps_win_balance": 0,
	"sweeps_available_balance": 0,
	"serverTime": "2019-03-13 18:09:53"
};
