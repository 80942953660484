import { baseSpin } from "./base-spin";
import { ensureSpinInputsAreValid } from "./ensure-spin-inputs-are-valid";
import { freeSpin } from "./free-spin";
import { SpinInputs } from "./spin-inputs";
import { SpinOutputs } from "./spin-outputs";
import { wildFurySpin } from "./wild-fury-spin";

export function spin(inputs: SpinInputs): SpinOutputs {
  ensureSpinInputsAreValid(inputs);

  const isBaseSpin = inputs.gameState === undefined;
  const isWildFuryTriggered =
    isBaseSpin && inputs.choices.chooseWhetherWildFuryIsTriggered();
  const isWildFuryContinuing =
    inputs.gameState?.wildFuryPhase === "IN_PROGRESS";

  if (isWildFuryTriggered || isWildFuryContinuing) {
    return wildFurySpin(inputs);
  } else if (isBaseSpin) {
    return baseSpin(inputs);
  } else {
    // If we not in base or wild fury, then we must be in free spins.
    return freeSpin(inputs);
  }
}
