import Vue from 'vue';
import Component from 'vue-class-component';
import Content from '../content.vue';

@Component({
    props: ['index', 'diceResults']
})
export default class DiceResultSelector extends Vue {
    constructor() {
        super();
    }

    public setDiceResults() {
        (<Content>this.$parent).setDiceResults(event);
    }
}
