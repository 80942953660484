// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-0fc812b7] {
}
.sm-game-spec-content .flex[data-v-0fc812b7] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-0fc812b7] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-0fc812b7] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-0fc812b7] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-0fc812b7] {
    margin: 5px 0 5px 0;
}
.kb-bonus-type[data-v-0fc812b7] {
    background-color: #333;
}
.bonus-wheels[data-v-0fc812b7] {
    height: 75px;
}
.bonus-guide-text[data-v-0fc812b7] {
    color: gray;
    text-align: center;
    font-size: 13px;
}
.bonus-guide-text-2[data-v-0fc812b7] {
    color: gray;
    text-align: center;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
p.bonus-guide-text[data-v-0fc812b7] {
    color: yellow;
    margin: 5px 0;
}
.kb-margin-top[data-v-0fc812b7] {
    margin-top: 12px;
}
.btn-toggle-is-feature[data-v-0fc812b7] {
    background-color: #024802 !important;
}
.kb-you-must[data-v-0fc812b7] {
    color: salmon;
    font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw065/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,oCAAoC;AACxC;AACA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".sm-game-spec-content[data-v-0fc812b7] {\n}\n.sm-game-spec-content .flex[data-v-0fc812b7] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-0fc812b7] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-0fc812b7] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-0fc812b7] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-0fc812b7] {\n    margin: 5px 0 5px 0;\n}\n.kb-bonus-type[data-v-0fc812b7] {\n    background-color: #333;\n}\n.bonus-wheels[data-v-0fc812b7] {\n    height: 75px;\n}\n.bonus-guide-text[data-v-0fc812b7] {\n    color: gray;\n    text-align: center;\n    font-size: 13px;\n}\n.bonus-guide-text-2[data-v-0fc812b7] {\n    color: gray;\n    text-align: center;\n    font-size: 13px;\n    display: flex;\n    justify-content: center;\n}\np.bonus-guide-text[data-v-0fc812b7] {\n    color: yellow;\n    margin: 5px 0;\n}\n.kb-margin-top[data-v-0fc812b7] {\n    margin-top: 12px;\n}\n.btn-toggle-is-feature[data-v-0fc812b7] {\n    background-color: #024802 !important;\n}\n.kb-you-must[data-v-0fc812b7] {\n    color: salmon;\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
