import { LineWin } from "@vgw/gdk-math-model-tools";

export const getWinCells = ({
     lineWins,
     playLines,
 }: {
    lineWins: LineWin[];
    playLines: number[][];
}) => {
    const cells: [number, number][] = [];
    for (const lineWin of lineWins) {
        const playLineIndex = lineWin.playLineIndex;
        const playLine = playLines[playLineIndex];
        for (const [reelIndex, rowIndex] of playLine.entries()) {
            cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
            if (reelIndex < lineWin.length) {
                cells[reelIndex][rowIndex] = 1;
            }
        }
    }
    return cells;
}