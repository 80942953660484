import { mathModel } from "../../../../v3";

export function generateNewSlotWindow(
  oldSlotWindow: string[][],
  replacementSymbols: { cell: mathModel.Cell; symbol: string }[]
) {
  const newSlotWindow = oldSlotWindow.map((col) => [...col]);
  replacementSymbols.forEach(
    ({ cell, symbol }) => (newSlotWindow[cell[1]][cell[0]] = symbol)
  );
  return newSlotWindow;
}
