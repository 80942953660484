import type { Choices } from '../../../choices/choices';
import type { GameResponse } from '../game-response';

export function evaluateBankerStacksFeature(
    slotWindow: string[][],
    choices: Choices,
    isFreeSpin: boolean,
): { bankerStacks: GameResponse['bankerStacks']; updatedSlotWindow: string[][] } | undefined {
    // Determine whether banker Stacks is awarded
    const isBankerStacksOfferAwarded = choices.chooseIfBankerStacksIsAwarded(isFreeSpin);
    if (!isBankerStacksOfferAwarded) return;

    // Determine the stacked symbol and which reels to be changed
    const symbol = choices.chooseBankerStacksSymbol(isFreeSpin);
    const reels = choices.chooseBankerStacksReels(symbol);

    // Compute the new slot window
    const symbolStack = slotWindow[0].map(() => symbol);
    const updatedSlotWindow = slotWindow.map((reel, reelIndex) => (reels.includes(reelIndex) ? symbolStack : reel));
    return {
        bankerStacks: { symbol, reels, originalSlotWindow: slotWindow },
        updatedSlotWindow,
    };
}
