export const modelDefinition = {
    modelId: 'vgw078',

    wildSymbol: 'WILD',
    reelsLayout: [3, 3, 3, 3, 3],
    defaultSelectLine: '',
    betMultiplier: 25,

    cascadeLengthToTriggerFreeSpins: 5,
    baseFreeSpinsAwardedOnTrigger: 7,
    extraFreeSpinsAwardedOnTrigger: 2,
    cascadeLengthToRetriggerFreeSpins: 3,
    baseFreeSpinsAwardedOnRetrigger: 3,
    extraFreeSpinsAwardedOnRetrigger: 2,

    winTable: [
        {
            symbol: 'PIC1',
            multipliers: [500, 150, 50]
        },
        {
            symbol: 'PIC2',
            multipliers: [125, 50, 15]
        },
        {
            symbol: 'PIC3',
            multipliers: [100, 30, 12]
        },
        {
            symbol: 'PIC4',
            multipliers: [75, 25, 10]
        },
        {
            symbol: 'PIC5',
            multipliers: [50, 20, 8]
        },
        {
            symbol: 'A',
            multipliers: [30, 12, 5]
        },
        {
            symbol: 'K',
            multipliers: [25, 10, 4]
        },
        {
            symbol: 'Q',
            multipliers: [25, 10, 4]
        },
        {
            symbol: 'J',
            multipliers: [20, 8, 3]
        },
        {
            symbol: '10',
            multipliers: [20, 8, 3]
        },
    ],

    // This is a ways game, so there are zero lines.
    playLines: [],

    reels: [
        ['K','J','A','PIC4','10','PIC2','A','PIC3','J','J','A','PIC5','10','A','Q','10','A','PIC3','10','PIC5','Q','A','PIC1','J','J','A','PIC5','10','K','PIC4','J','Q','PIC5','A','PIC2','K','10','Q','PIC3','PIC3','K','J','PIC4','10','PIC2','J','A','A','PIC3','J','Q','PIC2','PIC5','10','Q','PIC1','A','PIC3','10','PIC5','J','A','PIC1','J','10','A','PIC5','10','K','PIC4','PIC4','J','Q','PIC5','A','PIC2','K','10','Q','PIC4'],
        ['PIC3','10','PIC4','Q','K','PIC2','J','J','K','Q','PIC1','K','10','10','PIC2','A','A','A','PIC1','10','J','J','J','K','PIC3','Q','PIC4','K','PIC5','J','WILD','Q','WILD','K','WILD','10','PIC2','Q','J','K','PIC5','Q','PIC3','10','PIC4','Q','K','PIC2','J','PIC5','K','Q','PIC1','K','PIC4','J','PIC5','10','10','10','PIC4','K','PIC2','Q','Q','Q','A','10','PIC5','J','PIC3','10','PIC4','Q','K','J','Q','PIC5','K','K','K','PIC1','10','PIC4','J','PIC5','10','A','PIC1','10','J','K','PIC3','A','PIC4','WILD','K','J','PIC5','Q','WILD','PIC3','K','PIC4','10','PIC2','Q','A','PIC5','J'],
        ['PIC4','Q','10','A','PIC4','K','PIC2','A','J','PIC3','Q','J','PIC5','Q','A','PIC1','10','A','PIC4','J','10','PIC5','A','PIC3','K','PIC4','J','PIC1','Q','PIC5','10','A','PIC2','WILD','Q','WILD','K','WILD','J','PIC5','10','J','PIC4','Q','10','A','A','A','PIC2','10','10','Q','PIC4','J','PIC5','A','PIC3','K','Q','PIC1','K','PIC5','10','Q','PIC2','K','K','K','Q','J','PIC5','10','PIC3','J','10','PIC4','K','PIC2','J','J','J','PIC3','Q','Q','Q','PIC1','A','10','K','PIC4','J','10','PIC5','A','PIC3','K','PIC4','J','PIC1','Q','PIC5','10','WILD','K','PIC4','WILD','Q','J','PIC5','10'],
        ['J','PIC5','PIC5','PIC5','Q','J','PIC3','10','Q','Q','Q','PIC3','PIC3','PIC3','A','PIC1','PIC1','PIC1','Q','10','10','10','PIC2','PIC2','PIC2','K','J','PIC5','Q','PIC4','A','PIC3','10','PIC5','J','A','WILD','J','WILD','K','WILD','J','J','PIC4','K','K','K','10','PIC4','PIC4','PIC4','K','A','A','A','PIC5','10','Q','PIC2','K','A','PIC4'],
        ['J','PIC4','PIC4','PIC4','10','K','PIC3','PIC3','PIC3','J','J','J','Q','PIC5','J','10','10','10','PIC4','K','K','K','PIC5','PIC5','PIC5','10','PIC4','K','PIC2','PIC2','PIC2','A','A','A','PIC1','PIC1','PIC1','Q','Q','Q','PIC4','A','PIC3','10','PIC5','K','K','K','Q','A','Q','PIC3','A','J','PIC5','10','J','PIC2','Q','A']
    ],
    featureReels: [
        ['K','J','A','PIC4','J','Q','A','PIC3','K','10','A','PIC5','10','A','Q','10','A','PIC3','10','PIC5','Q','K','PIC1','J','J','A','PIC5','10','K','PIC4','J','Q','PIC5','J','PIC2','K','10','Q','PIC3','PIC3','Q','J','PIC4','10','PIC2','J','Q','10','PIC3','J','Q','K','PIC5','10','Q','PIC1','A','PIC3','10','PIC5','J','A','PIC1','J','10','A','PIC5','10','K','PIC4','PIC4','J','Q','K','A','PIC2','K','10','Q','PIC4'],
        ['PIC3','10','PIC4','Q','K','PIC2','J','J','K','Q','PIC5','K','A','10','PIC2','A','10','K','PIC1','10','J','J','J','K','PIC3','Q','A','K','PIC5','J','WILD','Q','WILD','K','WILD','10','PIC2','Q','J','K','PIC5','Q','K','10','PIC4','Q','K','PIC2','J','PIC5','K','Q','PIC1','A','PIC4','J','PIC5','10','10','10','PIC4','A','PIC2','Q','Q','Q','A','10','PIC5','J','PIC3','10','PIC4','Q','A','J','Q','PIC5','J','10','K','PIC1','10','PIC4','J','PIC5','10','A','PIC1','10','J','K','PIC3','A','PIC4','WILD','K','J','PIC3','Q','10','K','A','10','PIC4','Q','A','PIC5','J'],
        ['PIC4','Q','10','A','J','Q','PIC5','A','J','PIC3','Q','J','PIC5','Q','A','PIC1','10','A','PIC4','J','10','PIC5','A','PIC3','K','10','J','PIC1','K','J','10','A','PIC2','WILD','Q','WILD','K','WILD','J','PIC5','10','J','PIC4','Q','10','A','A','A','PIC2','10','10','Q','PIC4','J','10','A','PIC3','K','Q','PIC4','K','PIC5','10','Q','PIC2','K','K','K','Q','J','PIC5','10','PIC3','J','10','PIC4','K','PIC2','J','J','J','PIC3','Q','Q','Q','PIC1','A','10','K','PIC4','J','10','PIC5','A','PIC3','K','Q','J','PIC1','Q','PIC5','10','WILD','K','PIC4','Q','J','PIC5','10'],
        ['J','PIC5','PIC5','PIC5','Q','J','PIC3','10','Q','Q','Q','PIC3','PIC3','PIC3','A','PIC1','PIC1','PIC1','Q','10','10','10','PIC2','PIC2','PIC2','K','J','PIC5','Q','PIC4','A','PIC3','10','PIC5','J','A','WILD','J','WILD','K','WILD','J','J','PIC4','K','K','K','10','PIC4','PIC4','PIC4','K','A','A','A','PIC5','10','Q','PIC2','K','A','PIC4','J','PIC5','PIC5','PIC5','Q','J','PIC3','10','Q','Q','Q','PIC3','PIC3','PIC3','A','PIC1','PIC1','PIC1','Q','10','10','10','PIC2','PIC2','PIC2','K','J','PIC5','Q','PIC4','A','PIC3','10','PIC5','J','J','J','PIC4','K','K','K','10','PIC4','PIC4','PIC4','K','A','A','A','PIC5','10','Q','PIC2','K','A','PIC4'],
        ['J','PIC4','PIC4','PIC4','10','K','PIC3','PIC3','PIC3','J','J','J','Q','PIC5','J','10','10','10','PIC4','K','K','K','PIC5','PIC5','PIC5','10','PIC4','K','PIC2','PIC2','PIC2','A','A','A','PIC1','PIC1','PIC1','Q','Q','Q','PIC4','A','PIC3','10','PIC5','K','K','K','Q','A','Q','PIC3','A','J','PIC5','10','J','PIC2','Q','A','J','PIC4','PIC4','PIC4','10','K','PIC3','PIC3','PIC3','J','K','J','Q','PIC5','J','10','10','10','PIC4','J','K','K','PIC5','PIC5','PIC5','10','PIC4','K','PIC2','PIC2','PIC2','A','A','K','PIC1','PIC1','PIC1','Q','Q','Q','PIC4','A','PIC3','10','PIC5','K','A','K','Q','A','Q','PIC3','A','J','PIC5','10','J','PIC2','Q','A'],
    ],

    winLevels: [
        { name: 'normal1', threshold: 5 },
        { name: 'normal2', threshold: 15 },
        { name: 'normal3', threshold: 20 },
        { name: 'bigwin', threshold: 35 },
        { name: 'megawin', threshold: 70 },
        { name: 'epicwin' }
    ],

    coinType1Values: [200, 1000, 2000, 5000, 10000, 20000, 40000, 80000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400, 800],

    coinType1_min_bet: 200,
    coinType1_max_bet: 80000,
    coinType1_spin_max_bet: 2_000_000,

    coinType4_min_bet: 1,
    coinType4_max_bet: 800,
    coinType4_spin_max_bet: 20_000,

    jackpots: {
        seedValue: {
            GOLD: 50_000_000,
            SWEEPS: 500_000,
        },
        percentToJackpotPool: {
            GOLD: 0.5,
            SWEEPS: 0.5,
        },
        countdownMinimumValues: {
            GOLD: {
                80_000: 10,
                40_000: 10,
                20_000: 10,
                10_000: 11,
                5_000: 15,
                2_000: 20,
                1_000: 24,
                200: 33,
            },
            SWEEPS: {
                800: 10,
                400: 10,
                200: 10,
                100: 11,
                50: 15,
                20: 20,
                10: 24,
                5: 28,
                2: 33,
                1: 36,
            },
        },
        countdownIncrementWeights: {
            GOLD: {
                80_000: [6, 7, 8, 9, 9, 9, 9, 9, 10, 9, 9],
                40_000: [3, 4, 5, 6, 8, 11, 15, 14, 19, 19, 18, 18, 18, 19, 19, 19],
                20_000: [1, 2, 2, 2, 3, 4, 5, 6, 9, 11, 13, 21, 19, 20, 20, 20, 20, 22, 18, 18, 22],
                10_000: [1, 1, 1, 1, 1, 1, 1, 3, 4, 5, 6, 9, 10, 12, 20, 19, 19, 20, 20, 20, 20, 20, 20, 20],
                5_000: [1, 1, 1, 1, 2, 2, 2, 3, 4, 5, 9, 10, 10, 14, 14, 18, 18, 19, 19, 20, 20, 20, 20, 20],
                2_000: [1, 1, 1, 1, 2, 2, 2, 3, 4, 8, 10, 10, 10, 10, 18, 17, 17, 19, 20, 20, 20, 20, 20, 20],
                1_000: [1, 1, 1, 1, 2, 2, 3, 4, 4, 9, 10, 10, 18, 10, 17, 17, 17, 19, 20, 20, 20, 20, 20, 20],
                200: [1, 1, 1, 1, 1, 1, 2, 5, 5, 10, 10, 10, 10, 15, 18, 17, 16, 15, 19, 20, 20, 20, 20, 20],
            },
            SWEEPS: {
                800: [6, 7, 8, 9, 9, 9, 9, 9, 10, 9, 9],
                400: [3, 4, 5, 6, 8, 11, 15, 14, 19, 19, 18, 18, 18, 19, 19, 19],
                200: [1, 2, 2, 2, 3, 4, 5, 6, 9, 11, 13, 21, 19, 20, 20, 20, 20, 22, 18, 18, 22],
                100: [1, 1, 1, 1, 1, 1, 1, 3, 4, 5, 6, 9, 10, 12, 20, 19, 19, 20, 20, 20, 20, 20, 20, 20],
                50: [1, 1, 1, 1, 2, 2, 2, 3, 4, 5, 9, 10, 10, 14, 14, 18, 18, 19, 19, 20, 20, 20, 20, 20],
                20: [1, 1, 1, 1, 2, 2, 2, 3, 4, 8, 10, 10, 10, 10, 18, 17, 17, 19, 20, 20, 20, 20, 20, 20],
                10: [1, 1, 1, 1, 2, 2, 3, 4, 4, 9, 10, 10, 18, 10, 17, 17, 17, 19, 20, 20, 20, 20, 20, 20],
                5: [1, 1, 1, 1, 2, 2, 3, 5, 5, 10, 10, 10, 13, 16, 17, 17, 16, 16, 20, 20, 20, 20, 20, 20],
                2: [1, 1, 1, 1, 1, 1, 2, 5, 5, 10, 10, 10, 10, 15, 18, 17, 16, 15, 19, 20, 20, 20, 20, 20],
                1: [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 5, 5, 5, 9, 10, 14, 12, 14, 19, 20, 20, 20, 20, 20],
            },
        },
    },
};
