import { render, staticRenderFns } from "./wheelPrize.vue?vue&type=template&id=02f8383e&scoped=true"
import script from "./wheelPrize.vue.ts?vue&type=script&lang=ts&external"
export * from "./wheelPrize.vue.ts?vue&type=script&lang=ts&external"
import style0 from "./wheelPrize.vue.css?vue&type=style&index=0&id=02f8383e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f8383e",
  null
  
)

export default component.exports