export const originatorId = new Date().getTime();

export interface StepJson {
  name: string;
  originatorId: number;
  gameState: "clear" | "continue";
  reelStripPositions: number[];
  isSuperStarAwarded?: boolean;
  superStarMultiplier?: number;
}

export function createClearingStep(): StepJson {
  return {
    name: "Clear",
    originatorId,
    gameState: "clear",
    reelStripPositions: [3, 5, 0, 0, 3],
  };
}
