/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "pf007_vikingodyssey",
        "scatterSymbol": "SC1",
        "scatterSymbols": ["SC1", "FS1"],
        "bonusSymbol": "SC1",
        "wildSymbol": "WI1",
        "wildSymbols": ["WI1", "FS1"],
        "freeSpinTrigger": "FS1",
        "shieldsMinReel": 1,
        "countToTriggerBonusGame": 3,
        "freeSpinStep": 3,
        "baseGameShieldsWeights": [
            {
                "0":[{"band": 1, "weight": 60}, {"band": 6, "weight": 40}],
                "1":[{"band": 2, "weight": 60}, {"band": 7, "weight": 40}],
                "2":[{"band": 3, "weight": 33}, {"band": 8, "weight": 67}],
                "3":[{"band": 4, "weight": 30}, {"band": 9, "weight": 70}],
                "4":[{"band": 5, "weight": 30}, {"band": 10, "weight": 70}]
            }, {
                "0":[{"band": 1, "weight": 55}, {"band": 6, "weight": 45}],
                "1":[{"band": 2, "weight": 55}, {"band": 7, "weight": 45}],
                "2":[{"band": 3, "weight": 70}, {"band": 8, "weight": 30}],
                "3":[{"band": 4, "weight": 55}, {"band": 9, "weight": 45}],
                "4":[{"band": 5, "weight": 20}, {"band": 10, "weight": 80}]
            }, {
                "0":[{"band": 1, "weight": 30}, {"band": 6, "weight": 70}],
                "1":[{"band": 2, "weight": 30}, {"band": 7, "weight": 70}],
                "2":[{"band": 3, "weight": 70}, {"band": 8, "weight": 30}],
                "3":[{"band": 4, "weight": 30}, {"band": 9, "weight": 70}],
                "4":[{"band": 5, "weight": 10}, {"band": 10, "weight": 90}]
            }, {
                "0":[{"band": 1, "weight": 20}, {"band": 6, "weight": 80}],
                "1":[{"band": 2, "weight": 20}, {"band": 7, "weight": 80}],
                "2":[{"band": 3, "weight": 20}, {"band": 8, "weight": 80}],
                "3":[{"band": 4, "weight": 20}, {"band": 9, "weight": 80}],
                "4":[{"band": 5, "weight": 10}, {"band": 10, "weight": 90}]
            }, {
                "0":[{"band": 1, "weight": 10}, {"band": 6, "weight": 90}],
                "1":[{"band": 2, "weight": 10}, {"band": 7, "weight": 90}],
                "2":[{"band": 3, "weight": 10}, {"band": 8, "weight": 90}],
                "3":[{"band": 4, "weight": 10}, {"band": 9, "weight": 90}],
                "4":[{"band": 5, "weight": 10}, {"band": 10, "weight": 90}]
            }, {
                "0":[{"band": 1, "weight": 0}, {"band": 6, "weight": 100}],
                "1":[{"band": 2, "weight": 0}, {"band": 7, "weight": 100}],
                "2":[{"band": 3, "weight": 0}, {"band": 8, "weight": 100}],
                "3":[{"band": 4, "weight": 0}, {"band": 9, "weight": 100}],
                "4":[{"band": 5, "weight": 0}, {"band": 10, "weight": 100}]
            }
        ],


        "winTable": [
            {
                "symbol": "WI1",
                "multipliers": [2500, 100, 25]
            },
            {
                "symbol": "HV4",
                "multipliers": [2500, 100, 25]
            },
            {
                "symbol": "HV3",
                "multipliers": [500, 80, 20]
            },
            {
                "symbol": "HV2",
                "multipliers": [300, 60, 15]
            },
            {
                "symbol": "HV1",
                "multipliers": [200, 50, 12]
            },
            {
                "symbol": "LV5",
                "multipliers": [100, 25, 8]
            },
            {
                "symbol": "LV4",
                "multipliers": [100, 25, 8]
            },
            {
                "symbol": "LV3",
                "multipliers": [60, 15, 5]
            },
            {
                "symbol": "LV2",
                "multipliers": [60, 15, 5]
            },
            {
                "symbol": "LV1",
                "multipliers": [60, 15, 5]
            }
        ],

        "reelsLayout": [3, 3, 3, 3, 3],
        "reelsLayoutFreeSpin": [4, 4, 4, 4, 4],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 50],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "2500000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "50",
        "coinType4_spin_max_bet": "1250",
        "betMultiplier" : 20,
        "reels": [
            ["LV1", "LV2", "HV1", "LV5", "LV3", "HV2", "LV1", "HV3", "LV3", "SC1", "LV5", "LV4", "HV2", "LV2", "LV1", "LV5", "FS1", "LV3", "LV4", "LV2", "HV3", "LV3", "LV5", "LV4", "HV1", "LV1", "LV3", "SC1", "LV5", "LV4", "HV2", "LV1", "LV4", "LV5", "LV2", "HV2", "LV4", "LV3", "SC1", "LV2", "LV1", "HV2", "LV3", "LV1", "HV2", "LV4", "LV5", "SC1", "LV2", "LV4", "HV4", "LV3", "LV4", "HV1", "HV4", "LV5", "LV3"],
            ["LV1", "LV2", "LV3", "LV4", "FS1", "LV2", "LV5", "LV4", "LV1", "HV2", "LV2", "LV5", "LV4", "LV2", "LV1", "HV1", "LV3", "HV3", "LV2", "LV1", "LV3", "LV2", "HV1", "LV1", "LV4", "LV2", "LV5", "HV1", "LV4", "LV2", "HV2", "LV1", "LV2", "HV4", "LV3", "LV5", "LV1", "LV2", "LV3", "LV1", "LV4", "LV2", "LV1", "LV3", "HV1"],
            ["LV1", "LV2", "LV5", "LV3", "LV1", "HV2", "LV2", "HV1", "LV3", "HV2", "LV1", "HV1", "LV5", "LV4", "LV1", "SC1", "LV5", "LV3", "HV2", "LV5", "HV1", "HV2", "LV1", "LV3", "FS1", "LV5", "LV2", "LV3", "HV3", "LV2", "LV3", "HV3", "LV2", "HV2", "SC1", "LV3", "HV1", "HV3", "LV3", "LV2", "HV4", "HV1", "LV1", "SC1", "LV4", "LV2", "HV3", "LV5", "LV1", "HV4", "LV2", "LV4", "SC1", "LV1", "HV3", "LV3", "HV1", "LV2", "LV4", "HV3", "HV1", "LV3", "LV5", "HV4", "LV3", "SC1", "LV5", "HV2", "HV1", "LV4", "LV5", "HV3", "LV2", "LV1", "HV2", "HV3", "LV1", "LV3", "SC1", "LV2", "LV1", "LV4", "LV3", "HV4"],
            ["LV1", "LV3", "LV5", "LV4", "HV4", "LV2", "LV3", "HV1", "HV3", "LV2", "LV5", "FS1", "LV3", "HV2", "LV1", "LV4", "LV5", "LV3", "HV2", "LV2", "LV4", "LV1", "LV5", "HV1", "LV3", "HV3", "LV2", "LV4", "HV2", "LV1", "LV3", "HV3", "LV2", "HV2", "LV1", "LV3", "HV4", "LV2", "LV3", "LV4", "HV2", "LV2", "LV1", "HV1", "LV5", "LV4", "HV4", "LV1", "LV5", "LV3", "LV4", "HV1", "LV5"],
            ["LV1", "HV2", "LV3", "HV1", "LV5", "SC1", "LV1", "HV3", "LV3", "LV4", "HV2", "LV5", "HV1", "LV3", "FS1", "LV1", "LV4", "HV2", "LV5", "HV4", "LV2", "SC1", "LV3", "HV2", "HV3", "LV2", "HV4", "LV4", "SC1", "LV5", "HV2", "LV2", "LV3", "HV1", "LV4", "LV1", "HV2", "LV2", "SC1", "LV4", "HV1", "LV1", "LV5", "HV3", "LV1", "HV1", "LV3", "LV5", "LV2", "SC1", "LV3", "LV4", "LV2", "HV1"],

            ["LV1", "LV2", "HV1", "LV5", "LV3", "HV2", "LV1", "HV3", "LV3", "SC1", "LV5", "LV4", "HV2", "LV2", "LV1", "LV5", "HV1", "LV3", "LV4", "LV2", "HV3", "LV3", "LV5", "LV4", "HV1", "LV1", "LV3", "SC1", "LV5", "LV4", "HV2", "LV1", "LV4", "LV5", "LV2", "HV2", "LV4", "LV3", "SC1", "LV2", "LV1", "HV2", "LV3", "LV1", "HV2", "LV4", "LV5", "SC1", "LV2", "LV4", "HV4", "LV3", "LV4", "HV1", "HV4", "LV5", "LV3"],
            ["LV1", "LV2", "LV3", "LV4", "HV2", "LV2", "LV5", "LV4", "LV1", "HV2", "LV2", "LV5", "LV4", "LV2", "LV1", "HV1", "LV3", "HV3", "LV2", "LV1", "LV3", "LV2", "HV1", "LV1", "LV4", "LV2", "LV5", "HV1", "LV4", "LV2", "HV2", "LV1", "LV2", "HV4", "LV3", "LV5", "LV1", "LV2", "LV3", "LV1", "LV4", "LV2", "LV1", "LV3", "HV1"],
            ["LV1", "LV2", "LV5", "LV3", "LV1", "HV2", "LV2", "HV1", "LV3", "HV2", "LV1", "HV1", "LV5", "LV4", "LV1", "SC1", "LV5", "LV3", "HV2", "LV5", "HV1", "HV2", "LV1", "LV3", "HV1", "LV5", "LV2", "LV3", "HV3", "LV2", "LV3", "HV3", "LV2", "HV2", "SC1", "LV3", "HV1", "HV3", "LV3", "LV2", "HV4", "HV1", "LV1", "SC1", "LV4", "LV2", "HV3", "LV5", "LV1", "HV4", "LV2", "LV4", "SC1", "LV1", "HV3", "LV3", "HV1", "LV2", "LV4", "HV3", "HV1", "LV3", "LV5", "HV4", "LV3", "SC1", "LV5", "HV2", "HV1", "LV4", "LV5", "HV3", "LV2", "LV1", "HV2", "HV3", "LV1", "LV3", "SC1", "LV2", "LV1", "LV4", "LV3", "HV4"],
            ["LV1", "LV3", "LV5", "LV4", "HV4", "LV2", "LV3", "HV1", "HV3", "LV2", "LV5", "HV1", "LV3", "HV2", "LV1", "LV4", "LV5", "LV3", "HV2", "LV2", "LV4", "LV1", "LV5", "HV1", "LV3", "HV3", "LV2", "LV4", "HV2", "LV1", "LV3", "HV3", "LV2", "HV2", "LV1", "LV3", "HV4", "LV2", "LV3", "LV4", "HV2", "LV2", "LV1", "HV1", "LV5", "LV4", "HV4", "LV1", "LV5", "LV3", "LV4", "HV1", "LV5"],
            ["LV1", "HV2", "LV3", "HV1", "LV5", "SC1", "LV1", "HV3", "LV3", "LV4", "HV2", "LV5", "HV1", "LV3", "HV2", "LV1", "LV4", "HV2", "LV5", "HV4", "LV2", "SC1", "LV3", "HV2", "HV3", "LV2", "HV4", "LV4", "SC1", "LV5", "HV2", "LV2", "LV3", "HV1", "LV4", "LV1", "HV2", "LV2", "SC1", "LV4", "HV1", "LV1", "LV5", "HV3", "LV1", "HV1", "LV3", "LV5", "LV2", "SC1", "LV3", "LV4", "LV2", "HV1"]
        ],

        "featureReels": [
            ["LV3", "HV3", "LV2", "LV5", "LV4", "HV4", "LV5", "LV1", "HV1", "LV2", "LV1", "LV2", "HV3", "LV3", "HV2", "LV5", "LV4", "HV1", "LV1", "HV2"],
            ["LV2", "LV3", "HV4", "LV4", "LV2", "HV4", "LV3", "HV3", "HV2", "LV1", "LV3", "LV5", "HV1", "LV3", "LV4", "LV5", "HV2", "LV1", "LV1", "LV2"],
            ["LV1", "LV2", "LV3", "HV3", "HV1", "LV1", "HV2", "LV2", "LV1", "LV3", "LV5", "LV2", "LV4", "HV3", "LV1", "HV4", "HV1", "LV3", "LV4", "LV5"],
            ["LV2", "LV3", "HV4", "HV1", "LV1", "HV4", "HV1", "LV4", "HV2", "HV1", "LV4", "LV3", "HV2", "HV3", "LV2", "LV5", "LV1", "LV5", "LV2", "LV3"],
            ["LV3", "LV1", "LV4", "HV3", "LV1", "HV4", "LV2", "HV1", "LV1", "LV5", "LV4", "LV1", "LV2", "HV2", "LV3", "LV5", "HV2", "LV2", "HV3", "LV5"]
        ],

        "playLines": [
            [1, 1, 1, 1, 1], // 1
            [0, 0, 0, 0, 0], // 2
            [2, 2, 2, 2, 2], // 3
            [0, 1, 2, 1, 0], // 4
            [2, 1, 0, 1, 2], // 5
            [0, 0, 1, 0, 0], // 6
            [0, 1, 0, 1, 0], // 7
            [0, 1, 1, 1, 0], // 8
            [1, 0, 0, 0, 1], // 9
            [1, 0, 1, 0, 1], // 10
            [1, 1, 0, 1, 1], // 11
            [1, 1, 2, 1, 1], // 12
            [1, 2, 1, 2, 1], // 13
            [1, 2, 2, 2, 1], // 14
            [2, 1, 1, 1, 2], // 15
            [2, 1, 2, 1, 2], // 16
            [2, 2, 1, 2, 2], // 17
            [0, 2, 0, 2, 0], // 18
            [2, 0, 2, 0, 2], // 19
            [0, 0, 2, 0, 0]  // 20
        ],

        "playLinesFreeSpin": [
            [1, 1, 1, 1, 1], // 1
            [0, 0, 0, 0, 0], // 2
            [2, 2, 2, 2, 2], // 3
            [0, 1, 2, 1, 0], // 4
            [2, 1, 0, 1, 2], // 5
            [0, 0, 1, 0, 0], // 6
            [0, 1, 0, 1, 0], // 7
            [0, 1, 1, 1, 0], // 8
            [1, 0, 0, 0, 1], // 9
            [1, 0, 1, 0, 1], // 10
            [1, 1, 0, 1, 1], // 11
            [1, 1, 2, 1, 1], // 12
            [1, 2, 1, 2, 1], // 13
            [1, 2, 2, 2, 1], // 14
            [2, 1, 1, 1, 2], // 15
            [2, 1, 2, 1, 2], // 16
            [2, 2, 1, 2, 2], // 17
            [0, 2, 0, 2, 0], // 18
            [2, 0, 2, 0, 2], // 19
            [0, 0, 2, 0, 0], // 20
            [2, 2, 0, 2, 2], // 21
            [0, 0, 2, 0, 0], // 22
            [0, 2, 2, 2, 0], // 23
            [3, 3, 3, 3, 3], // 24
            [1, 2, 3, 2, 1], // 25
            [3, 2, 1, 2, 3], // 26
            [2, 2, 3, 2, 2], // 27
            [2, 3, 2, 3, 2], // 28
            [2, 3, 3, 3, 2], // 29
            [3, 2, 2, 2, 3], // 30
            [3, 2, 3, 2, 3], // 31
            [3, 3, 2, 3, 3], // 32
            [1, 3, 1, 3, 1], // 33
            [3, 1, 3, 1, 3], // 34
            [1, 1, 3, 1, 1], // 35
            [3, 3, 1, 3, 3], // 36
            [3, 1, 1, 1, 3], // 37
            [1, 3, 3, 3, 1], // 38
            [0, 3, 0, 3, 0], // 39
            [3, 0, 3, 0, 3]  // 40
        ],

        "dynamicSymbols": [
            {"value": 0, "weight": 7213},
            {"value": 1, "weight": 1500},
            {"value": 2, "weight": 787},
            {"value": 3, "weight": 400},
            {"value": 4, "weight": 50},
            {"value": 5, "weight": 50}
        ],
        "bonusGameAwards":[
            {"index": 0, "value": 2, "weight": 1100},
            {"index": 1, "value": 3, "weight": 1000},
            {"index": 2, "value": 4, "weight": 1000},
            {"index": 3, "value": 5, "weight": 1100},
            {"index": 4, "value": 6, "weight": 1100},
            {"index": 5, "value": 8, "weight": 900},
            {"index": 6, "value": 10, "weight": 900},
            {"index": 7, "value": 12, "weight": 1000},
            {"index": 8, "value": 15, "weight": 900},
            {"index": 9, "value": 20, "weight": 600},
            {"index": 10, "value": 25, "weight": 100},
            {"index": 11, "value": 50, "weight": 100},
            {"index": 12, "value": 75, "weight": 100},
            {"index": 13, "value": 100, "weight": 100}
        ],

        "freeSpinWeights": [
            {"value": 0, "weight": 50},
            {"value": 1, "weight": 50}
        ],
        "maxFreeSpinCount": 24,
        "minFreeSpinCount": 12
    }

    ,
}
