var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-steps" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add clearing step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addStep()
                },
              },
            },
            [_vm._v("Add step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addFreeSpinStep()
                },
              },
            },
            [_vm._v("Trigger Free Spins")]
          ),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addJackpot } }, [
            _vm._v("Trigger GRAND Jackpot"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        _c("h2", { staticClass: "heading" }, [
          _vm._v("Banker Feature Toggles:"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banker-multiplier-controls" }, [
          _c("h3", [_vm._v("Banker Multiplier Controls:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scenario.bankerMultiplierAmount,
                  expression: "scenario.bankerMultiplierAmount",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.scenario,
                      "bankerMultiplierAmount",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  _vm.addBankerMultiplier,
                ],
              },
            },
            [
              _c("option", { domProps: { value: 0 } }, [
                _vm._v("Banker Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 2 } }, [
                _vm._v("2x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 3 } }, [
                _vm._v("3x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 4 } }, [
                _vm._v("4x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 5 } }, [
                _vm._v("5x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 6 } }, [
                _vm._v("6x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 7 } }, [
                _vm._v("7x Multiplier"),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: 10 } }, [
                _vm._v("10x Multiplier"),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banker-stacks-controls" }, [
          _c("h3", [_vm._v("Banker Stacks Controls:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scenario.bankerStacksSymbol,
                  expression: "scenario.bankerStacksSymbol",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.scenario,
                    "bankerStacksSymbol",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "WILD" } }, [
                _vm._v("WILD Stacks"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "CASE" } }, [
                _vm._v("CASE Stacks"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reel-selectors" },
            [
              _c("label", [_vm._v("Stack Reels:")]),
              _vm._v(" "),
              _vm._l(5, function (_, index) {
                return _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.bankerStacksReels,
                      expression: "scenario.bankerStacksReels",
                    },
                  ],
                  key: index,
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: index,
                    checked: Array.isArray(_vm.scenario.bankerStacksReels)
                      ? _vm._i(_vm.scenario.bankerStacksReels, index) > -1
                      : _vm.scenario.bankerStacksReels,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.scenario.bankerStacksReels,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = index,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.scenario,
                              "bankerStacksReels",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.scenario,
                              "bankerStacksReels",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.scenario, "bankerStacksReels", $$c)
                      }
                    },
                  },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addBankerStacks } }, [
            _vm._v("Trigger Banker Stacks"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banker-scat-controls" }, [
          _c("h3", [_vm._v("Banker Scatter Controls:")]),
          _vm._v(" "),
          _c("div", { staticClass: "scat-symbol-selector" }, [
            _c("label", [_vm._v("Symbol to Replace:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.bankerScat.symbol,
                    expression: "scenario.bankerScat.symbol",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.scenario.bankerScat,
                      "symbol",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scat-position-selector" }, [
            _c("label", [_vm._v("Position (Reel, Row):")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.bankerScat.cell[0],
                  expression: "scenario.bankerScat.cell[0]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "4" },
              domProps: { value: _vm.scenario.bankerScat.cell[0] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.bankerScat.cell,
                    0,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.bankerScat.cell[1],
                  expression: "scenario.bankerScat.cell[1]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "2" },
              domProps: { value: _vm.scenario.bankerScat.cell[1] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.bankerScat.cell,
                    1,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addBankerScat } }, [
            _vm._v("Trigger Banker Scatter"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banker-case-controls" }, [
          _c("h3", [_vm._v("Banker Case Controls:")]),
          _vm._v(" "),
          _c("div", { staticClass: "scat-symbol-selector" }, [
            _c("label", [_vm._v("Symbol to Replace:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.bankerCase.symbol,
                    expression: "scenario.bankerCase.symbol",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.scenario.bankerCase,
                      "symbol",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "A" } }, [_vm._v("A")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "K" } }, [_vm._v("K")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Q" } }, [_vm._v("Q")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "J" } }, [_vm._v("J")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "case-position-selector" }, [
            _c("label", [_vm._v("Position (Reel, Row):")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.bankerCase.cell[0],
                  expression: "scenario.bankerCase.cell[0]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "4" },
              domProps: { value: _vm.scenario.bankerCase.cell[0] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.bankerCase.cell,
                    0,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.bankerCase.cell[1],
                  expression: "scenario.bankerCase.cell[1]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "2" },
              domProps: { value: _vm.scenario.bankerCase.cell[1] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.bankerCase.cell,
                    1,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addBankerCase } }, [
            _vm._v("Trigger Banker Case"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banker-offer-controls" }, [
          _c("h3", [_vm._v("Banker Offer Controls:")]),
          _vm._v(" "),
          _c("div", { staticClass: "offer-amount-selector" }, [
            _c("label", [_vm._v("Offer Amount:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.scenario.bankerOfferWinAmount,
                    expression: "scenario.bankerOfferWinAmount",
                    modifiers: { number: true },
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.$set(
                      _vm.scenario,
                      "bankerOfferWinAmount",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { domProps: { value: 5 } }, [_vm._v("5")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 15 } }, [_vm._v("15")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 20 } }, [_vm._v("20")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 25 } }, [_vm._v("25")]),
                _vm._v(" "),
                _c("option", { domProps: { value: 50 } }, [_vm._v("50")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addBankerOffer } }, [
            _vm._v("Trigger Banker Offer"),
          ]),
          _vm._v(" "),
          _vm.currentStep?.json.isOfferAwarded
            ? _c("div", [
                _c("button", { on: { click: _vm.acceptOffer } }, [
                  _vm._v("Accept Offer"),
                ]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.declineOffer } }, [
                  _vm._v("Decline Offer"),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "case-prizes-controls" },
          [
            _c("h3", [_vm._v("Case Prizes Controls:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Note: Please only add prizes to CASE symbols on the slotWindow"
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.scenario.casePrizes, function (reel, reelIndex) {
              return _c(
                "div",
                { key: reelIndex, staticClass: "reel-prizes" },
                [
                  _c("h4", [
                    _vm._v("Reel " + _vm._s(reelIndex + 1) + " Prizes:"),
                  ]),
                  _vm._v(" "),
                  _vm._l(reel, function (prize, rowIndex) {
                    return _c(
                      "div",
                      {
                        key: `${reelIndex}-${rowIndex}`,
                        staticClass: "prize-input",
                      },
                      [
                        _c("label", [
                          _vm._v("Row " + _vm._s(rowIndex + 1) + ":"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value:
                                  _vm.scenario.casePrizes[reelIndex][rowIndex],
                                expression:
                                  "scenario.casePrizes[reelIndex][rowIndex]",
                                modifiers: { number: true },
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.scenario.casePrizes[reelIndex],
                                  rowIndex,
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: null } }, [
                              _vm._v("None"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 5 } }, [
                              _vm._v("5"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 10 } }, [
                              _vm._v("10"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 20 } }, [
                              _vm._v("20"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 30 } }, [
                              _vm._v("30"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 40 } }, [
                              _vm._v("40"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 50 } }, [
                              _vm._v("50"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 80 } }, [
                              _vm._v("80"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 100 } }, [
                              _vm._v("100"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 120 } }, [
                              _vm._v("120"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 150 } }, [
                              _vm._v("150"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 200 } }, [
                              _vm._v("200"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 400 } }, [
                              _vm._v("400"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 800 } }, [
                              _vm._v("800"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: 2000 } }, [
                              _vm._v("2000"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("button", { on: { click: _vm.addCasePrize } }, [
              _vm._v("Add Case Prizes"),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "case-prizes-controls" }, [
          _c("h3", [_vm._v("Gold Case Controls:")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        Note: Please only add Gold Cases to CASE symbols on the slotWindow\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gold-case-position" }, [
            _c("label", [_vm._v("Position (Reel, Row):")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.goldCases[0].cell[0],
                  expression: "scenario.goldCases[0].cell[0]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "4" },
              domProps: { value: _vm.scenario.goldCases[0].cell[0] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.goldCases[0].cell,
                    0,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.scenario.goldCases[0].cell[1],
                  expression: "scenario.goldCases[0].cell[1]",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", min: "0", max: "2" },
              domProps: { value: _vm.scenario.goldCases[0].cell[1] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.scenario.goldCases[0].cell,
                    1,
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gold-case-feature" }, [
            _c("label", [_vm._v("Feature:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenario.goldCases[0].feature,
                    expression: "scenario.goldCases[0].feature",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.scenario.goldCases[0],
                      "feature",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "COLLECT" } }, [
                  _vm._v("COLLECT"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "MULTIPLYx2" } }, [
                  _vm._v("MULTIPLY x2"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "MULTIPLYx3" } }, [
                  _vm._v("MULTIPLY x3"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "MULTIPLYx4" } }, [
                  _vm._v("MULTIPLY x4"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "MULTIPLYx5" } }, [
                  _vm._v("MULTIPLY x5"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "BOOST" } }, [_vm._v("BOOST")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.addGoldCase } }, [
            _vm._v("Add Gold Case"),
          ]),
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "heading" }, [_vm._v("Reel Strip Selector:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reel-position-selectors" },
          [
            _c("label", [_vm._v("Reel strips:")]),
            _vm._v(" "),
            _vm._l(_vm.scenario.reelStripPositions, function (position) {
              return _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: position.value,
                    expression: "position.value",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number" },
                domProps: { value: position.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(position, "value", _vm._n($event.target.value))
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              })
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("slot-window", { attrs: { spinOutcome: _vm.spinOutcome } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }