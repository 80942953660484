var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(0)
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(1)
              },
            },
          },
          [_vm._v("Base Step Buster")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(0)
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep(1)
              },
            },
          },
          [_vm._v("FreeSpin Step Buster")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: true,
                expression: "true",
              },
            ],
            staticClass: "sub-title-menu",
          },
          [
            _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
              _c("div", { staticClass: "select-style" }, [
                _c("label", { attrs: { for: "bg_number" } }, [
                  _vm._v("Antebet: "),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.levelNo,
                        expression: "levelNo",
                      },
                    ],
                    staticStyle: {
                      color: "#00c700",
                      "font-weight": "bold",
                      "font-size": "small",
                      "padding-left": "50px",
                    },
                    attrs: { id: "bg_number" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.levelNo = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("50 credits"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("75 credits"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [
                      _vm._v("100 credits"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBase && _vm.levelNo > 2,
                      expression: "isBase&&(levelNo>2)",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer1" } }, [
                    _vm._v("Gold hammer (reel 2): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.hammerNo[0],
                          expression: "hammerNo[0]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer1" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.hammerNo,
                            0,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFsStep && _vm.levelNo > 2,
                      expression: "isFsStep&&(levelNo>2)",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer_fs1" } }, [
                    _vm._v("Wild gold hammer (reel 2): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wildHammerNo[0],
                          expression: "wildHammerNo[0]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer_fs1" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.wildHammerNo,
                            0,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "5" } }, [_vm._v("6")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "6" } }, [_vm._v("7")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "7" } }, [_vm._v("8")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBase,
                      expression: "isBase",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer2" } }, [
                    _vm._v("Gold hammer (reel 3): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.hammerNo[1],
                          expression: "hammerNo[1]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer2" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.hammerNo,
                            1,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFsStep,
                      expression: "isFsStep",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer_fs2" } }, [
                    _vm._v("Wild gold hammer (reel 3): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wildHammerNo[1],
                          expression: "wildHammerNo[1]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer_fs2" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.wildHammerNo,
                            1,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "5" } }, [_vm._v("6")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "6" } }, [_vm._v("7")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "7" } }, [_vm._v("8")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBase && _vm.levelNo > 1,
                      expression: "isBase&&(levelNo>1)",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer3" } }, [
                    _vm._v("Gold hammer (reel 4): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.hammerNo[2],
                          expression: "hammerNo[2]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer3" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.hammerNo,
                            2,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFsStep && _vm.levelNo > 1,
                      expression: "isFsStep&&(levelNo>1)",
                    },
                  ],
                  staticClass: "select-style",
                },
                [
                  _c("label", { attrs: { for: "hammer_fs3" } }, [
                    _vm._v("Wild gold hammer (reel 4): "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wildHammerNo[2],
                          expression: "wildHammerNo[2]",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "hammer_fs3" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.wildHammerNo,
                            2,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("5")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "5" } }, [_vm._v("6")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "6" } }, [_vm._v("7")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "7" } }, [_vm._v("8")]),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center fs-selector sm-space" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isBase,
                expression: "isBase",
              },
            ],
            staticClass: "okBtn elemOK pointer",
            class: [_vm.isActive ? "blue" : "elemOK"],
            attrs: { id: "ok" },
            on: {
              click: function ($event) {
                return _vm.updateStep(_vm.levelNo, _vm.hammerNo)
              },
            },
          },
          [_vm._v("OK")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFsStep,
                expression: "isFsStep",
              },
            ],
            staticClass: "okBtn elemOK pointer",
            class: [_vm.isActive ? "blue" : "elemOK"],
            on: {
              click: function ($event) {
                return _vm.updateStep(_vm.levelNo, _vm.wildHammerNo)
              },
            },
          },
          [_vm._v("OK")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }