/* in order to support standalone, please insert the init response from the server here */
export const base = {
  "modelId": "vgw028",
  "scatterSymbol": "SC",
  "wildSymbol": "WILD",
  "freeSpinTrigger": "SC",
  "countToTriggerFreeSpin": 3,
  "jackpotFeatureLoopCount": 3,
  "betMultiplier" :50,
  "coinSymbol": "Coin",
  "coinCountToTriggerFeature": 6,
  "winTable": [
    {
      "symbol": "PIC1",
      "multipliers": [175, 50, 30, 3, 1]
    }, {
      "symbol": "PIC2",
      "multipliers": [100, 50, 20]
    }, {
      "symbol": "PIC3",
      "multipliers": [100, 50, 20]
    }, {
      "symbol": "PIC4",
      "multipliers": [75, 25, 15]
    }, {
      "symbol": "PIC5",
      "multipliers": [50, 20, 10]
    }, {
      "symbol": "Heart",
      "multipliers": [25, 10, 5]
    }, {
      "symbol": "Diamond",
      "multipliers": [25, 10, 5]
    }, {
      "symbol": "Spade",
      "multipliers": [25, 10, 5]
    }, {
      "symbol": "Club",
      "multipliers": [25, 10, 5]
    }, {
      "symbol": "SC",
      "multipliers": [100, 5, 2]
    }
  ],
  "reelsLayout": [3, 3, 3, 3, 3],
  "percentToJackpotPool": 1.5,

  "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
  "coinType4Values": [ 1, 2, 5, 10, 50],

  "coinType1_min_bet":"250",
  "coinType1_max_bet":"100000",
  "coinType1_spin_max_bet":"5000000",

  "coinType4_min_bet":"1",
  "coinType4_max_bet":"500",
  "coinType4_spin_max_bet":"2500",

  "initialJackpotAmount":{
    "SWEEPS":{
      "MAJOR":500000,
      "GRAND":10000000
    },

    "GOLD":{
      "MAJOR":500000000,
      "GRAND":20000000000
    }
  },

  "coinPricesSweeps": {
    "jackpotTypes": ["MAJOR", "MINOR", "MINI"],
    "prices": [0, 100, 20, 5, 4, 3, 2, 1],
    "weights": {
      "0": {
        "1": [50, 18000, 62000, 40000, 140000, 440000, 570000, 572450],
        "2": [100, 18000, 62000, 40000, 100000, 480000, 570000, 572400],
        "5": [250, 18000, 62000, 40000, 100000, 430000, 590000, 602250],
        "10":[500, 18000, 62000, 40000, 40000, 440000, 590000, 652000],
        "50":[2500, 18000, 62000, 40000, 40000, 140000, 690000, 850000]
      },

      "8" : {
        "1": [50, 18000, 62000, 40000, 140000, 440000, 570000, 572450],
        "2": [100, 18000, 62000, 40000, 100000, 480000, 570000, 572400],
        "5": [250, 18000, 62000, 40000, 100000, 430000, 590000, 602250],
        "10":[500, 18000, 62000, 40000, 40000, 440000, 590000, 652000],
        "50":[2500, 18000, 62000, 40000, 40000, 140000, 690000, 850000]
      },

      "16" : {
        "1": [50, 18000, 62000, 40000, 140000, 440000, 570000, 572450],
        "2": [100, 18000, 62000, 40000, 100000, 480000, 570000, 572400],
        "5": [250, 18000, 62000, 40000, 100000, 430000, 590000, 602250],
        "10":[500, 18000, 62000, 40000, 40000, 440000, 590000, 652000],
        "50":[2500, 18000, 62000, 40000, 40000, 140000, 690000, 850000]
      },

      "25" : {
        "1": [50, 18000, 62000, 40000, 140000, 440000, 570000, 572450],
        "2": [100, 18000, 62000, 40000, 100000, 480000, 570000, 572400],
        "5": [250, 18000, 62000, 40000, 100000, 430000, 590000, 602250],
        "10":[500, 18000, 62000, 40000, 40000, 440000, 590000, 652000],
        "50":[2500, 18000, 62000, 40000, 40000, 140000, 690000, 850000]
      }
    }
  },

  "coinPricesGold":{
    "jackpotTypes": ["MAJOR", "MINOR", "MINI"],
    "prices" : [0, 50, 10, 5, 4, 3, 2, 1],

    "weights":{
      "0": {
        "250":    [50, 18000, 62000, 30000, 100000, 290000, 440000, 772450],
        "2500":   [500, 18000, 62000, 30000, 100000, 390000, 440000, 672000],
        "10000":  [2000, 18000, 62000, 30000, 100000, 290000, 440000, 770500],
        "50000":  [10000, 18000, 62000, 30000, 100000, 90000, 440000, 962500],
        "100000": [20000, 18000, 62000, 30000, 100000, 90000, 340000, 1052500]
      },

      "8" : {
        "250":    [50, 18000, 62000, 30000, 100000, 290000, 440000, 772450],
        "2500":   [500, 18000, 62000, 30000, 100000, 390000, 440000, 672000],
        "10000":  [2000, 18000, 62000, 30000, 100000, 290000, 440000, 770500],
        "50000":  [10000, 18000, 62000, 30000, 100000, 90000, 440000, 962500],
        "100000": [20000, 18000, 62000, 30000, 100000, 90000, 340000, 1052500]
      },

      "16" : {
        "250":    [50, 18000, 62000, 30000, 100000, 290000, 440000, 772450],
        "2500":   [500, 18000, 62000, 30000, 100000, 390000, 440000, 672000],
        "10000":  [2000, 18000, 62000, 30000, 100000, 290000, 440000, 770500],
        "50000":  [10000, 18000, 62000, 30000, 100000, 90000, 440000, 962500],
        "100000": [20000, 18000, 62000, 30000, 100000, 90000, 340000, 1052500]
      },

      "25" : {
        "250":    [50, 18000, 62000, 30000, 100000, 290000, 440000, 772450],
        "2500":   [500, 18000, 62000, 30000, 100000, 390000, 440000, 672000],
        "10000":  [2000, 18000, 62000, 30000, 100000, 290000, 440000, 770500],
        "50000":  [10000, 18000, 62000, 30000, 100000, 90000, 440000, 962500],
        "100000": [20000, 18000, 62000, 30000, 100000, 90000, 340000, 1052500]
      }

    }
  },

  "coinChanceWeights": {
    "SWEEPS": [11, 11, 11, 11, 11, 11, 1000, 1000, 9000],
    "GOLD":  [11, 11, 11, 11, 11, 11, 1000, 1000, 10000000]
  },

  "reels": [
    ["PIC5", "Heart", "SC", "PIC4", "PIC4", "Heart", "PIC2", "PIC5", "PIC1", "PIC1", "PIC1", "PIC2", "Heart", "Club", "Diamond", "PIC4", "PIC5", "PIC2", "Heart", "PIC4", "PIC5", "PIC3", "Heart", "PIC1", "PIC2", "Heart", "PIC4", "PIC5", "PIC1", "PIC2", "PIC5", "Spade", "PIC2", "PIC3", "Heart", "Spade", "Heart", "PIC2", "Heart", "PIC3", "PIC5", "PIC1", "PIC4", "Coin", "Coin", "Coin", "Spade", "Spade"],
    ["Spade", "SC", "PIC2", "PIC5", "Spade", "PIC1", "PIC5", "PIC2", "Heart", "PIC3", "Spade", "Heart", "PIC1", "Diamond", "PIC4", "Spade", "PIC1", "Heart", "PIC4", "PIC1", "WILD", "PIC5", "Heart", "PIC4", "Heart", "PIC1", "PIC1", "PIC1", "PIC3", "PIC1", "PIC2", "Club", "SC", "Heart", "PIC1", "PIC2", "PIC4", "Heart", "PIC1", "Diamond", "PIC1", "Heart", "Coin", "Coin", "Coin"],
    ["PIC3", "Club", "PIC4", "Diamond", "SC", "Heart", "PIC4", "PIC5", "PIC1", "Heart", "WILD", "Heart", "PIC1", "PIC5", "Heart", "PIC3", "Heart", "WILD", "PIC1", "Diamond", "PIC4", "Club", "Heart", "PIC2", "Spade", "PIC4", "Diamond", "Heart", "PIC1", "Heart", "PIC4", "Spade", "Club", "Spade", "PIC2", "Diamond", "Spade", "PIC3", "Club", "PIC4", "Heart", "PIC3", "Diamond", "PIC3", "Club", "Coin", "Coin", "Coin", "Spade"],
    ["PIC5", "WILD", "PIC1", "PIC2", "Diamond", "WILD", "Heart", "PIC1", "Club", "PIC2", "Diamond", "WILD", "Club", "PIC5", "PIC3", "WILD", "Club", "SC", "PIC5", "WILD", "PIC1", "PIC4", "Club", "Spade", "PIC1", "Heart", "PIC2", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "PIC2", "Heart", "PIC1", "PIC5", "SC", "PIC4", "PIC4", "Club", "PIC3", "Diamond", "Coin", "Coin", "Coin"],
    ["PIC2", "Heart", "SC", "Spade", "Diamond", "Heart", "PIC1", "PIC2", "Heart", "PIC5", "PIC1", "PIC1", "PIC1", "Club", "PIC5", "PIC1", "PIC3", "Heart", "PIC3", "PIC4", "Heart", "Diamond", "PIC2", "PIC5", "PIC4", "Spade", "PIC1", "Heart", "Spade", "Heart", "PIC4", "PIC2", "Coin", "Coin", "Coin"]
  ],
  "fsFeatureReels":
  {
    "8":[
      ["PIC5", "Heart", "SC", "PIC4", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "Club", "Diamond", "PIC4", "PIC5", "PIC2", "PIC3", "PIC4", "PIC5", "PIC3", "PIC3", "PIC1", "PIC2", "PIC3", "PIC4", "PIC5", "PIC1", "PIC2", "PIC5", "Spade", "PIC2", "PIC3", "PIC1", "Spade", "PIC2", "PIC2", "Heart", "PIC3", "PIC5", "PIC1", "PIC4", "Coin", "Coin", "Coin", "Club", "Club"],
      ["Spade", "SC", "PIC2", "PIC5", "PIC1", "PIC1", "PIC1", "PIC2", "Heart", "PIC3", "Spade", "PIC1", "PIC1", "Diamond", "PIC4", "Spade", "PIC1", "PIC3", "PIC4", "PIC1", "WILD", "PIC5", "PIC3", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC2", "Club", "SC", "PIC3", "PIC1", "PIC2", "PIC4", "PIC2", "PIC1", "Diamond", "PIC1", "Heart", "Coin", "Coin", "Coin"],
      ["PIC3", "Club", "PIC4", "Diamond", "SC", "Heart", "PIC4", "PIC5", "PIC1", "Heart", "WILD", "PIC1", "PIC1", "PIC5", "Heart", "PIC3", "Heart", "WILD", "PIC1", "Diamond", "PIC4", "Club", "Heart", "PIC2", "Spade", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "PIC4", "Spade", "Club", "Spade", "PIC2", "Diamond", "Spade", "PIC3", "Club", "PIC4", "PIC2", "PIC3", "Diamond", "PIC3", "Club", "Coin", "Coin", "Coin", "Spade"],
      ["PIC5", "WILD", "PIC1", "PIC2", "Diamond", "WILD", "Heart", "PIC1", "Club", "PIC2", "Diamond", "WILD", "Club", "PIC5", "PIC3", "WILD", "Club", "SC", "PIC5", "WILD", "PIC1", "PIC4", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "PIC2", "Heart", "PIC1", "PIC5", "SC", "PIC4", "PIC4", "Club", "PIC3", "Diamond", "Coin", "Coin", "Coin"],
      ["PIC2", "Heart", "SC", "Spade", "Diamond", "Heart", "PIC1", "PIC2", "Heart", "PIC5", "PIC1", "PIC1", "PIC1", "Club", "PIC5", "PIC1", "PIC3", "Heart", "PIC3", "PIC4", "Heart", "Diamond", "PIC2", "PIC5", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "Heart", "PIC4", "PIC2", "Coin", "Coin", "Coin"]
    ],

    "16":[
      ["PIC5", "Heart", "SC", "PIC4", "PIC4", "Heart", "PIC2", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "Heart", "Club", "Diamond", "PIC4", "PIC5", "PIC2", "Heart", "PIC4", "PIC5", "PIC3", "Heart", "PIC1", "PIC2", "Heart", "PIC4", "PIC5", "PIC1", "PIC2", "PIC5", "Spade", "PIC2", "PIC3", "Heart", "Spade", "Heart", "PIC2", "Heart", "PIC3", "PIC5", "PIC1", "PIC4", "Coin", "Coin", "Coin", "PIC2", "PIC2"],
      ["Spade", "SC", "PIC2", "PIC5", "Spade", "PIC1", "PIC1", "PIC2", "Heart", "PIC3", "Spade", "Heart", "PIC1", "Diamond", "PIC4", "Spade", "PIC1", "Heart", "PIC4", "PIC1", "WILD", "PIC5", "Heart", "PIC4", "Heart", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC2", "Club", "SC", "Heart", "PIC1", "PIC2", "PIC4", "Heart", "PIC1", "Diamond", "PIC1", "Heart", "Coin", "Coin", "Coin"],
      ["PIC3", "Club", "PIC4", "Diamond", "SC", "Heart", "PIC4", "PIC5", "PIC1", "Heart", "WILD", "Heart", "PIC1", "PIC5", "Heart", "PIC3", "Heart", "WILD", "PIC1", "Diamond", "PIC4", "Club", "Heart", "PIC2", "Spade", "PIC4", "Diamond", "Heart", "PIC1", "PIC1", "PIC4", "Spade", "Club", "Spade", "PIC2", "Diamond", "Spade", "PIC3", "Club", "PIC4", "Heart", "PIC3", "Diamond", "PIC3", "Club", "Coin", "Coin", "Coin", "PIC2"],
      ["PIC5", "WILD", "PIC1", "PIC2", "Diamond", "WILD", "Heart", "PIC1", "Club", "PIC2", "Diamond", "WILD", "Club", "PIC5", "PIC3", "WILD", "Club", "SC", "PIC5", "WILD", "PIC1", "PIC4", "Club", "Spade", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "PIC2", "Heart", "PIC1", "PIC5", "SC", "PIC4", "PIC4", "Club", "PIC3", "Diamond", "Coin", "Coin", "Coin"],
      ["PIC2", "Heart", "SC", "Spade", "Diamond", "Heart", "PIC1", "PIC2", "Heart", "PIC5", "PIC1", "PIC1", "PIC1", "Club", "PIC5", "PIC1", "PIC3", "Heart", "PIC3", "PIC4", "Heart", "Diamond", "PIC2", "PIC5", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "Heart", "PIC4", "PIC2", "Coin", "Coin", "Coin"]
    ],

    "25":[
      ["PIC5", "Heart", "SC", "PIC4", "PIC4", "Heart", "PIC2", "PIC5", "PIC1", "PIC1", "PIC1", "PIC2", "Heart", "Club", "Diamond", "PIC4", "PIC5", "PIC2", "Heart", "PIC4", "PIC5", "PIC3", "Heart", "Club", "PIC2", "Heart", "PIC4", "PIC5", "PIC1", "PIC2", "PIC5", "Spade", "PIC2", "PIC3", "Heart", "Spade", "Heart", "PIC2", "Heart", "PIC3", "PIC5", "PIC1", "PIC4", "Coin", "Coin", "Coin", "Heart", "Heart"],
      ["Spade", "SC", "PIC2", "PIC5", "Spade", "PIC1", "PIC5", "PIC2", "Heart", "PIC3", "Spade", "Heart", "PIC1", "Diamond", "PIC4", "Spade", "PIC1", "Heart", "PIC4", "PIC1", "WILD", "PIC5", "Heart", "PIC4", "Heart", "PIC1", "PIC1", "PIC1", "PIC3", "PIC1", "PIC2", "Club", "SC", "Heart", "PIC1", "PIC2", "PIC4", "Heart", "Club", "Diamond", "PIC1", "Heart", "Coin", "Coin", "Coin"],
      ["PIC3", "Club", "PIC4", "Diamond", "SC", "Heart", "PIC4", "PIC5", "PIC1", "Heart", "WILD", "Heart", "Club", "PIC5", "Heart", "PIC3", "Heart", "WILD", "PIC1", "Diamond", "PIC4", "Club", "Heart", "PIC2", "Spade", "PIC4", "Diamond", "Heart", "PIC1", "Heart", "PIC4", "Spade", "Club", "Spade", "PIC2", "Diamond", "Spade", "PIC3", "Club", "PIC4", "Heart", "PIC3", "Diamond", "PIC3", "Club", "Coin", "Coin", "Coin", "Heart"],
      ["PIC5", "WILD", "PIC1", "PIC2", "Diamond", "WILD", "Heart", "PIC1", "Club", "PIC2", "Diamond", "WILD", "Club", "PIC5", "PIC3", "WILD", "Club", "SC", "PIC5", "WILD", "PIC1", "PIC4", "Club", "Spade", "Club", "Heart", "PIC2", "PIC1", "PIC1", "PIC1", "Heart", "Spade", "PIC2", "Heart", "PIC1", "PIC5", "SC", "PIC4", "PIC4", "Club", "PIC3", "Diamond", "Coin", "Coin", "Coin"],
      ["PIC2", "Heart", "SC", "Spade", "Diamond", "Heart", "PIC1", "PIC2", "Heart", "PIC5", "PIC1", "PIC1", "PIC1", "Club", "PIC5", "PIC1", "PIC3", "Heart", "PIC3", "PIC4", "Heart", "Diamond", "PIC2", "PIC5", "PIC4", "Spade", "PIC1", "Heart", "Spade", "Heart", "PIC4", "PIC2", "Coin", "Coin", "Coin"]
    ] 
  }
};

export const m93 = Object.assign({}, base, {
  "percentToJackpotPool": 0.5
});

export const m94 = Object.assign({}, base, {
  "percentToJackpotPool": 1
});

export const m95 = Object.assign({}, base, { 
});

