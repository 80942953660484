/* in order to support standalone, please insert the init response from the server here */
export default {
  "modelId": "vgw008",
  "gameSessionId": "254",
  "modelDefinition": {
    "reelsLayout": [
      3,
      3,
      3,
      3,
      3
    ],
    "winLevels": [
      {
        "name": "normal1",
        "threshold": 5
      },
      {
        "name": "normal2",
        "threshold": 15
      },
      {
        "name": "normal3",
        "threshold": 25
      },
      {
        "name": "bigwin",
        "threshold": 50
      },
      {
        "name": "megawin",
        "threshold": 150
      },
      {
        "name": "epicwin"
      }
    ],
    "currency_symbol": "$",
    "modelId": "vgw008",
    "scatterSymbol": "SCAT",
    "wildSymbol": "WILD",
    "freeSpinTrigger": "SCAT",
    "countToTriggerFreeSpin": 3,
    "freeSpinCount": 8,
    "freeSpinMultiplier": 2,
    "winTable": [
      {
        "symbol": "PIC1",
        "multipliers": [
          1500,
          400,
          100
        ]
      },
      {
        "symbol": "PIC2",
        "multipliers": [
          400,
          100,
          30
        ]
      },
      {
        "symbol": "PIC3",
        "multipliers": [
          250,
          50,
          25
        ]
      },
      {
        "symbol": "Spade",
        "multipliers": [
          150,
          40,
          20
        ]
      },
      {
        "symbol": "Heart",
        "multipliers": [
          100,
          30,
          15
        ]
      },
      {
        "symbol": "Diamond",
        "multipliers": [
          60,
          20,
          10
        ]
      },
      {
        "symbol": "Club",
        "multipliers": [
          50,
          15,
          8
        ]
      }
    ],
    "playLines": [
      [
        1,
        1,
        1,
        1,
        1
      ],
      [
        0,
        0,
        0,
        0,
        0
      ],
      [
        2,
        2,
        2,
        2,
        2
      ],
      [
        0,
        1,
        2,
        1,
        0
      ],
      [
        2,
        1,
        0,
        1,
        2
      ],
      [
        0,
        0,
        1,
        0,
        0
      ],
      [
        2,
        2,
        1,
        2,
        2
      ],
      [
        1,
        2,
        2,
        2,
        1
      ],
      [
        1,
        0,
        0,
        0,
        1
      ],
      [
        0,
        1,
        1,
        1,
        0
      ],
      [
        2,
        1,
        1,
        1,
        2
      ],
      [
        0,
        1,
        0,
        1,
        0
      ],
      [
        2,
        1,
        2,
        1,
        2
      ],
      [
        1,
        0,
        1,
        0,
        1
      ],
      [
        1,
        2,
        1,
        2,
        1
      ],
      [
        1,
        1,
        0,
        1,
        1
      ],
      [
        1,
        1,
        2,
        1,
        1
      ],
      [
        0,
        2,
        0,
        2,
        0
      ],
      [
        2,
        0,
        2,
        0,
        2
      ],
      [
        1,
        0,
        2,
        0,
        1
      ]
    ],
    "defaultSelectLine": "20",
    "reels": [
      [
        "SCAT",
        "Diamond",
        "Heart",
        "Spade",
        "Club",
        "Diamond",
        "PIC3",
        "PIC1",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "PIC2",
        "Spade",
        "Heart",
        "PIC2",
        "Diamond",
        "SCAT",
        "Spade",
        "Club",
        "PIC2",
        "Diamond",
        "PIC3",
        "Club",
        "PIC1",
        "Diamond",
        "Heart",
        "Spade",
        "PIC2",
        "SCAT",
        "PIC3",
        "Diamond",
        "Club",
        "Spade",
        "Diamond",
        "Heart",
        "Spade",
        "SCAT",
        "PIC3",
        "Diamond",
        "Club",
        "Heart",
        "Spade",
        "Diamond",
        "Heart",
        "PIC3",
        "SCAT",
        "Heart",
        "PIC2",
        "PIC3"
      ],
      [
        "PIC2",
        "Spade",
        "Club",
        "Heart",
        "Diamond",
        "Club",
        "PIC3",
        "Heart",
        "Club",
        "Spade",
        "SCAT",
        "Club",
        "PIC2",
        "Spade",
        "Club",
        "Diamond",
        "WILD",
        "Heart",
        "Club",
        "Diamond",
        "Spade",
        "PIC2",
        "Heart",
        "Diamond",
        "Club",
        "PIC3",
        "Spade",
        "SCAT",
        "Club",
        "Diamond",
        "PIC2",
        "Club",
        "Diamond",
        "WILD",
        "SCAT",
        "Heart",
        "Spade",
        "Club",
        "PIC1",
        "PIC3",
        "Heart",
        "Diamond",
        "Club",
        "Heart",
        "PIC1",
        "Spade",
        "Club",
        "Heart",
        "WILD",
        "Club",
        "SCAT",
        "PIC2",
        "Spade",
        "Club",
        "Diamond",
        "PIC3",
        "Heart",
        "Diamond",
        "Club",
        "PIC3",
        "SCAT",
        "Spade",
        "Club",
        "PIC1",
        "Spade",
        "Diamond",
        "Club",
        "Heart",
        "PIC1",
        "Spade",
        "Club",
        "Diamond"
      ],
      [
        "PIC2",
        "Club",
        "PIC3",
        "Diamond",
        "Heart",
        "Club",
        "Spade",
        "PIC3",
        "WILD",
        "Spade",
        "SCAT",
        "Diamond",
        "Spade",
        "Club",
        "Diamond",
        "PIC3",
        "PIC1",
        "Heart",
        "Diamond",
        "PIC2",
        "PIC1",
        "Diamond",
        "Heart",
        "Spade",
        "Diamond",
        "Heart",
        "PIC3",
        "PIC2",
        "Heart",
        "SCAT",
        "Diamond",
        "Heart",
        "Diamond",
        "PIC2",
        "Heart",
        "Spade",
        "SCAT",
        "Diamond",
        "Heart",
        "PIC3",
        "WILD",
        "Heart",
        "Spade",
        "PIC1",
        "Club",
        "Diamond",
        "PIC3",
        "Spade",
        "SCAT",
        "Heart",
        "PIC2",
        "Spade",
        "Club",
        "PIC3",
        "Diamond",
        "PIC1",
        "Club",
        "SCAT",
        "PIC3",
        "PIC2",
        "Spade",
        "Club",
        "PIC3",
        "Diamond",
        "Spade",
        "Club"
      ],
      [
        "Spade",
        "Club",
        "Heart",
        "Diamond",
        "Club",
        "PIC2",
        "Spade",
        "Club",
        "Spade",
        "Diamond",
        "Club",
        "Heart",
        "Diamond",
        "PIC1",
        "Spade",
        "Diamond",
        "Heart",
        "Club",
        "WILD",
        "Diamond",
        "Club",
        "Spade",
        "Heart",
        "Club",
        "Diamond",
        "Spade",
        "Club",
        "Heart",
        "PIC3",
        "Diamond",
        "Spade",
        "PIC1",
        "Heart",
        "Club",
        "Diamond",
        "WILD",
        "Club",
        "Heart",
        "Spade",
        "Club",
        "Diamond",
        "PIC3",
        "Club",
        "Heart",
        "Spade",
        "Club",
        "Heart",
        "Diamond",
        "Club",
        "PIC2"
      ],
      [
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "PIC1",
        "Club",
        "Heart",
        "PIC2",
        "Club",
        "Heart",
        "Diamond",
        "PIC3",
        "Club",
        "Heart",
        "Diamond",
        "PIC2",
        "Club",
        "Spade",
        "Diamond",
        "Club",
        "PIC2",
        "Diamond",
        "PIC1",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "PIC1",
        "Diamond",
        "Club",
        "Heart",
        "PIC3",
        "Club",
        "Diamond",
        "PIC1",
        "Heart",
        "Club",
        "Diamond",
        "PIC2",
        "Heart",
        "Club",
        "Diamond",
        "Spade"
      ]
    ],
    "featureReels": [
      [
        "SCAT",
        "Heart",
        "PIC2",
        "Spade",
        "Club",
        "PIC2",
        "Heart",
        "SCAT",
        "Spade",
        "PIC3",
        "PIC2",
        "Club",
        "PIC1",
        "PIC3",
        "Heart",
        "SCAT",
        "PIC1",
        "Spade",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "PIC2",
        "Club",
        "Diamond",
        "PIC3",
        "SCAT",
        "PIC2",
        "PIC3",
        "Heart",
        "PIC2",
        "Diamond",
        "Club",
        "PIC1",
        "PIC3",
        "SCAT",
        "Diamond",
        "PIC3",
        "Heart",
        "PIC1",
        "Spade",
        "Heart",
        "PIC2",
        "PIC3",
        "Heart",
        "SCAT",
        "PIC2",
        "Diamond",
        "PIC1",
        "Club",
        "Heart",
        "Spade",
        "PIC3"
      ],
      [
        "SCAT",
        "PIC3",
        "Diamond",
        "PIC1",
        "Spade",
        "PIC3",
        "Diamond",
        "SCAT",
        "PIC3",
        "PIC1",
        "PIC2",
        "Diamond",
        "Spade",
        "PIC3",
        "SCAT",
        "Diamond",
        "PIC1",
        "PIC3",
        "Diamond",
        "Spade",
        "PIC2",
        "PIC3",
        "SCAT",
        "PIC1",
        "Diamond",
        "PIC3",
        "Heart",
        "Diamond",
        "Club",
        "WILD",
        "Diamond",
        "Heart",
        "PIC2",
        "SCAT",
        "Spade",
        "PIC3",
        "Heart",
        "Diamond",
        "Club",
        "Spade",
        "PIC1",
        "PIC2",
        "Club",
        "Diamond",
        "SCAT",
        "PIC3",
        "Diamond",
        "PIC1",
        "PIC3",
        "Heart",
        "PIC1",
        "Diamond",
        "Heart",
        "Spade",
        "PIC2",
        "SCAT",
        "PIC3",
        "Club",
        "Diamond",
        "PIC2",
        "PIC1",
        "Heart",
        "Club",
        "Diamond",
        "WILD",
        "PIC3",
        "PIC2",
        "SCAT",
        "PIC1",
        "Diamond",
        "Spade",
        "PIC3",
        "Heart",
        "PIC2",
        "WILD",
        "PIC1",
        "Spade"
      ],
      [
        "SCAT",
        "Diamond",
        "Heart",
        "PIC2",
        "PIC1",
        "Heart",
        "Diamond",
        "Club",
        "Heart",
        "PIC1",
        "PIC2",
        "Diamond",
        "SCAT",
        "PIC1",
        "Heart",
        "PIC2",
        "Diamond",
        "Heart",
        "PIC1",
        "Diamond",
        "SCAT",
        "PIC3",
        "Club",
        "Spade",
        "PIC1",
        "Club",
        "PIC3",
        "Spade",
        "WILD",
        "Diamond",
        "PIC2",
        "SCAT",
        "PIC3",
        "Spade",
        "Club",
        "PIC1",
        "PIC3",
        "Diamond",
        "Heart",
        "Club",
        "PIC3",
        "SCAT",
        "Spade",
        "PIC3",
        "PIC1",
        "Club",
        "Spade",
        "PIC3",
        "WILD",
        "PIC2",
        "Diamond",
        "SCAT",
        "PIC3",
        "Spade",
        "Club",
        "PIC1",
        "Heart",
        "Diamond",
        "Club",
        "PIC1",
        "SCAT",
        "Diamond",
        "Heart",
        "Spade",
        "PIC1",
        "PIC3",
        "WILD",
        "Heart",
        "Spade"
      ],
      [
        "PIC3",
        "Diamond",
        "Club",
        "Spade",
        "Diamond",
        "PIC2",
        "PIC3",
        "Diamond",
        "Club",
        "Heart",
        "Diamond",
        "Club",
        "PIC3",
        "PIC1",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "WILD",
        "Spade",
        "Diamond",
        "PIC3",
        "Club",
        "Diamond",
        "Spade",
        "PIC3",
        "Diamond",
        "Club",
        "Spade",
        "Diamond",
        "PIC3",
        "PIC1",
        "Club",
        "Diamond",
        "Heart",
        "WILD",
        "Diamond",
        "Club",
        "PIC3",
        "Diamond",
        "Heart",
        "Spade",
        "Diamond",
        "Club",
        "PIC3",
        "Diamond",
        "Club",
        "Heart",
        "Diamond",
        "PIC2"
      ],
      [
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "PIC1",
        "Diamond",
        "Club",
        "Spade",
        "Diamond",
        "Club",
        "Heart",
        "Spade",
        "Diamond",
        "Club",
        "Heart",
        "PIC2",
        "Diamond",
        "PIC3",
        "Club",
        "Diamond",
        "Spade",
        "Heart",
        "PIC1",
        "Diamond",
        "Heart",
        "Club",
        "Diamond",
        "Heart",
        "Club",
        "PIC1",
        "Heart",
        "Diamond",
        "Club",
        "Spade",
        "Diamond",
        "Heart",
        "PIC1",
        "Club",
        "Diamond",
        "Heart",
        "PIC2",
        "Club",
        "Diamond",
        "Heart",
        "PIC3"
      ]
    ],
    "normal_count": "2",
    "coinType1Values": [
      250,
      2500,
      10000,
      50000,
      100000
    ],
    "coinType4Values": [
      1,
      2,
      5,
      10,
      50
    ],
    "coinType1_min_bet": "250",
    "coinType1_max_bet": "100000",
    "coinType1_spin_max_bet": "2000000",
    "coinType4_min_bet": "1",
    "coinType4_max_bet": "50",
    "coinType4_spin_max_bet": "1000"
  },
  "success": true,
  "messages": [],
  "gold_balance": 2008650000,
  "sweeps_balance": 206526616,
  "sweeps_deposit_balance": 199994915,
  "sweeps_promo_balance": 0,
  "sweeps_win_balance": 6531701,
  "sweeps_available_balance": 6531701,
  "serverTime": "2019-07-08 04:55:54",
  "reelStripPositions": [
    31,
    4,
    36,
    31,
    48
  ],
  "slotWindow": [
    [
      "PIC3",
      "Diamond",
      "Club"
    ],
    [
      "Diamond",
      "Club",
      "PIC3"
    ],
    [
      "SCAT",
      "Diamond",
      "Heart"
    ],
    [
      "PIC1",
      "Heart",
      "Club"
    ],
    [
      "Diamond",
      "Spade",
      "Club"
    ]
  ]
};
