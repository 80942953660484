/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: 'tf118',
        reelsLayout: [],
        coinType1Values: [25, 1000000],
        coinType4Values: [25, 10000],
        coinType1_min_bet: 100,
        coinType1_max_bet: 1000000,
        coinType1_spin_max_bet: 1000000,
        coinType4_min_bet: 100,
        coinType4_max_bet: 10000,
        coinType4_spin_max_bet: 10000,
        playLines: [],
        coinSizeMultiplier: 1,
        maxOddsFactor: 3
    }
};
