var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("div", { staticClass: "sm-game-spec-content" }, [
        _c("div", { staticClass: "flex-center menu" }, [
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.spinType,
                    expression: "spinType",
                    modifiers: { lazy: true },
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.spinType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddBaseSpinStep(),
                        expression: "canAddBaseSpinStep()",
                      },
                    ],
                    domProps: { value: "Base" },
                  },
                  [_vm._v("Base - Spin")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddBaseSpinStep(),
                        expression: "canAddBaseSpinStep()",
                      },
                    ],
                    domProps: { value: "BaseWWBS" },
                  },
                  [_vm._v("Base - Win Win Big Spin")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddBaseSpinStep(),
                        expression: "canAddBaseSpinStep()",
                      },
                    ],
                    domProps: { value: "BaseFreeSpinTrigger" },
                  },
                  [_vm._v("Base - Free Spin Trigger")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddFreeSpinStep(),
                        expression: "canAddFreeSpinStep()",
                      },
                    ],
                    domProps: { value: "Freespin" },
                  },
                  [_vm._v("Free game - Spin")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddFreeSpinStep(),
                        expression: "canAddFreeSpinStep()",
                      },
                    ],
                    domProps: { value: "FreeWWBS" },
                  },
                  [_vm._v("Free game - Win Win Big Spin")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddFreeSpinStep(),
                        expression: "canAddFreeSpinStep()",
                      },
                    ],
                    domProps: { value: "FreeRetrigger" },
                  },
                  [_vm._v("Free game - Retrigger")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.canAddFreeSpinStep(),
                        expression: "canAddFreeSpinStep()",
                      },
                    ],
                    domProps: { value: "ClearGameState" },
                  },
                  [_vm._v("Clear Game state")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "button pointer btn-add-step",
              on: {
                click: function ($event) {
                  return _vm.callStep()
                },
              },
            },
            [_vm._v("Add Step")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getFreeSpinsRemaining(),
                expression: "getFreeSpinsRemaining()",
              },
            ],
            staticClass: "flex-center sub-title-menu",
          },
          [
            _c("div", { staticClass: "label" }, [
              _vm._v(
                "Free Spins Remaining: " + _vm._s(_vm.getFreeSpinsRemaining())
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center sub-title-menu" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isWinWinBigSpinTrigger(),
                  expression: "isWinWinBigSpinTrigger()",
                },
              ],
              staticClass: "select-style",
            },
            [
              _c(
                "select",
                {
                  domProps: { value: _vm.getReelHeight() },
                  on: {
                    input: function ($event) {
                      return _vm.setReelHeight($event.target.value)
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 3 } }, [
                    _vm._v("Expands last 3 reels to 3 rows."),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 4 } }, [
                    _vm._v("Expands last 3 reels to 4 rows."),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 5 } }, [
                    _vm._v("Expands last 3 reels to 5 rows."),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 6 } }, [
                    _vm._v("Expands last 3 reels to 6 rows."),
                  ]),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center" },
          [
            _c("reel", {
              attrs: {
                content: this,
                index: 0,
                step: _vm.step,
                isFeature: _vm.isFeature,
                height: _vm.getReelHeights()[0],
              },
            }),
            _vm._v(" "),
            _c("reel", {
              attrs: {
                content: this,
                index: 1,
                step: _vm.step,
                isFeature: _vm.isFeature,
                height: _vm.getReelHeights()[1],
              },
            }),
            _vm._v(" "),
            _c("reel", {
              attrs: {
                content: this,
                index: 2,
                step: _vm.step,
                isFeature: _vm.isFeature,
                height: _vm.getReelHeights()[2],
              },
            }),
            _vm._v(" "),
            _c("reel", {
              attrs: {
                content: this,
                index: 3,
                step: _vm.step,
                isFeature: _vm.isFeature,
                height: _vm.getReelHeights()[3],
              },
            }),
            _vm._v(" "),
            _c("reel", {
              attrs: {
                content: this,
                index: 4,
                step: _vm.step,
                isFeature: _vm.isFeature,
                height: _vm.getReelHeights()[4],
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }