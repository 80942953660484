/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Reels");
            step.reelsType = "reels";
            step.rows = State.state.getModelDefinition().reels;
            step.jackpotFrame1 = false;
            step.jackpotFrame2 = false;
            step.jackpotFrame3 = false;
            step.dynamiteFreespinWin = 0;
            step.metaStage = -1;
            step.metaStageCntr = -1;

            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: (type) => {
            switch(type) {
                case "reels":
                    return State.state.getModelDefinition().reels;
                    break;
                case "featureReels": 
                    return State.state.getModelDefinition().featureReels;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerStep: (order = 0) => {
            const step = State.state.createStep([1, 13, 13, 20, 19], false, order, "", "F-Trigger");
            step.reelsType = "reels";
            return step;
        },
        getFeatureStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", "F-Step");
            step.reelsType = "featureReels";
            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelsType);
            const noOfReels = 5;
            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, 4);
            step.randoms = step.reelStripPositions;
            return step;
        }

    };
}
