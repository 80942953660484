var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFSTrigger()
              },
            },
          },
          [_vm._v("FS Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFSStep()
              },
            },
          },
          [_vm._v("FS Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center jackpot-selector-wrapper" }, [
        _c("div", { staticClass: "flex-center force-jackpot-selector" }, [
          _c("label", { attrs: { for: "forceJackpot" } }, [
            _vm._v("Jackpot: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forcedJackpot,
                  expression: "forcedJackpot",
                },
              ],
              attrs: { id: "forceJackpot" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.forcedJackpot = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateJackpotForceOption(_vm.forcedJackpot)
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "MINOR" } }, [_vm._v("Minor")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "MAJOR" } }, [_vm._v("Major")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }