export interface ReelIndexesOfFramedSymbols {
    reel1: number[];
    reel5: number[];
}

export interface StepJson {
    name: string;
    originatorId: number;
    gameState: 'clear' | 'continue';
    reelStripPositions: number[];
    reelIndexesOfFramedSymbols: ReelIndexesOfFramedSymbols
}

export const originatorId = new Date().getTime();

export function createSpinStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [0, 0, 0, 0, 0],
        reelIndexesOfFramedSymbols: {
            reel1: [],
            reel5: [],
        }
    };
}

export function createClearingStep(): StepJson {
    return {
        name: 'Clear',
        originatorId,
        gameState: 'clear',
        reelStripPositions: [0, 0, 0, 0, 0],
        reelIndexesOfFramedSymbols: {
            reel1: [],
            reel5: [],
        }
    };
}


export function createFreeSpinTriggerStep(): StepJson {
    return {
        name: 'Step',
        originatorId,
        gameState: 'continue',
        reelStripPositions: [31, 38, 8, 18, 56],
        reelIndexesOfFramedSymbols: {
            reel1: [],
            reel5: [],
        }
    };
};
