/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";

export function setStateParams() {

    return {
        variations: [""],

        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Base");
            step.internal_name = "Base";
            return step;
        },
        getBaseStep: (stepTitle,multiplierValue: number, reelsNo?: number[]) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", stepTitle);
            step.reels = reelsNo;
            step.internal_name = stepTitle;
            step.winvasionMultiplier = multiplierValue;
            return step;
        },
        getFreeGameStep: (stepTitle, multiplierValue: number, reelsNo?: number[]) => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, "", stepTitle);
            (reelsNo && reelsNo.length === 0) ? delete step.reels : step.reels = reelsNo
            multiplierValue === 1 ? delete step.winvasionMultiplier: step.winvasionMultiplier = multiplierValue;
            step.internal_name = stepTitle;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getFeatureReelStrips: () => State.state.getModelDefinition().featureReels,
        getReelStrips: () => State.state.getModelDefinition().reels,

        prepareStepForDB: (step) => {
            // temporary until chu-server accepts reelStripPositions only -----------------
            const state = State.state;
            const reelStrips = step.internal_name == "FreeGame" ? state.getFeatureReelStrips() : state.getReelStrips();
            step.randoms = step.reelStripPositions;
            step.rows = State.state.getRowsFromIndexes(step, reelStrips);
            return step;
        },


    };
}
