/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: "tf119",

        reelsLayout: [3, 3, 3, 3, 3],
        scatterSymbol: "WS",
        freeSpinTrigger: "WS",
        wildSymbol: "WS",
        respinNoWinSymbol: "X",
        coinSizeMultiplier: 10,
        totalSymbolNum: 15,

        reelLength: 49,

        // prettier-ignore
        reels: [
            ['L5','L3','H4','L2','L5','L4','L1','H3','L3','L5','L1','L4','H2','H3','L4','L2','L5','L4','H3','L1','L4','L5','H1','L4','H3','L1','L4','L2','L5','L4','L1','L3','L4','L5','H3','L4','WS','L5','L4','L1','H3','L4','H2','L1','L4','L5','H3','L4','L1'],
            ['L5','L3','H4','L2','L5','L4','L1','H3','L3','H2','L1','L4','H2','H3','L4','L2','L5','L4','H3','L1','L4','L5','H1','L4','H3','L1','L4','L2','L5','L4','L1','L3','L4','L5','H3','L4','WS','L5','L4','L1','H3','L4','H2','L1','L4','L5','H3','L4','L1'],
    
            ['L4','L3','L1','H3','H2','L5','H4','L4','L5','L2','H1','L5','H4','L1','L4','L3','L2','L5','L3','H4','L5','L2','L3','H3','H4','L2','L5','L3','H4','L5','L2','L3','L5','H2','L1','L5','H4','L3','L5','L2','H1','L5','L3','L2','L5','H4','L3','L5','L2'],
            ['L4','L3','L1','H3','H2','L5','H4','L4','L5','L2','H1','L5','H4','L1','L4','L3','L2','L5','WS','H4','L5','L2','L3','H3','H4','L2','L5','L3','H4','L5','L2','L3','L5','H2','L1','L5','H4','L3','WS','L2','H1','L5','L3','L2','L5','H4','L3','L5','L2'],
    
            ['L4','L5','L2','H4','H2','L3','H3','H1','L3','H2','L1','L3','WS','H3','L5','L4','L1','L3','H3','H2','L3','L1','L4','L3','L1','H3','L3','L4','L1','L3','H3','L4','L3','L2','H1','L3','L4','H3','L3','L1','L4','L3','L2','L1','H2','L4','H3','L3','L1'],
            ['L4','L5','L2','H4','H2','L3','H3','H1','L3','H2','L1','L3','WS','H3','L5','L4','L1','L3','H3','H2','L3','L1','L4','L3','L1','H3','L3','L4','L1','L3','H3','L4','L3','L2','H1','L3','WS','H3','L3','L1','L4','L3','L2','L1','H2','L4','H3','L3','L1'],
    
            ['L4','L3','L1','H3','L4','L5','H2','L2','L5','H1','H4','L5','L2','L3','L5','H4','H2','L3','L2','H4','L5','L2','L4','L5','H4','L4','L5','L1','H1','L5','L4','H4','L5','L4','L2','L5','H4','L4','L5','L2','L1','L5','H2','L2','L5','L4','H4','L5','L2'],
            ['L4','L3','L1','H3','WS','L5','H2','L2','L5','H1','H4','L5','L2','L3','L5','H4','H2','L3','L2','H4','L5','L2','L4','L5','H4','L4','L5','L1','H1','L5','L4','H4','L5','L4','L2','L5','H4','L4','L5','L2','L1','L5','H2','L2','L5','L4','H4','L5','L2'],
    
            ['L3','L4','L2','H3','H2','L3','L2','L5','L3','L1','H1','L3','WS','H3','L3','L1','H2','L5','L3','L2','L1','L5','L3','H3','L5','H4','L3','L5','L1','L4','L2','L5','L3','H4','L5','H3','L3','L5','L1','H4','L3','L5','L2','L1','L5','L3','H4','L5','L1'],
            ['L3','L4','L2','H3','H2','L3','L2','WS','L3','L1','H1','L3','L5','H3','L3','L1','H2','L5','L3','L2','L1','L5','L3','H3','L5','H4','L3','L5','WS','L4','L2','L5','L3','H4','L5','H3','L3','L5','L1','H4','L3','L5','L2','L1','L5','L3','H4','L5','L1'],
        ],

        // prettier-ignore
        reelsFreespin: [
            ['L5','L3','H4','L2','L5','L4','L1','H3','L3','L5','L1','L4','H2','H3','L4','L2','L5','L4','H3','L1','L4','L5','H1','L4','H3','L1','L4','L2','L5','L4','L1','L3','L4','L5','H3','L4','L1','L5','L4','L1','H3','L4','H2','L1','L4','L5','H3','L4','L1'],
            ['L5','L3','H4','L2','L5','L4','L1','H3','L3','L5','L1','L4','H2','H3','L4','L2','L5','L4','H3','L1','L4','L5','H1','L4','H3','L1','L4','L2','L5','L4','L1','L3','L4','L5','H3','L4','WS','L5','L4','L1','H3','L4','H2','L1','L4','L5','H3','L4','L1'],
            ['L5','L3','H4','L2','L5','L4','L1','H3','L3','L5','L1','L4','H2','H3','L4','L2','L5','L4','H3','L1','L4','L5','H1','L4','H3','L1','L4','L2','L5','L4','L1','L3','L4','L5','H3','L4','WS','L5','L4','L1','H3','L4','H2','L1','L4','L5','H3','L4','L1'],
    
            ['L4','L3','L1','H3','H2','L5','H4','L4','L5','L2','H1','L5','H4','L1','L4','L3','L2','L5','L3','H4','L5','L2','L3','H3','H4','L2','L5','L3','H4','L5','L2','L3','L5','H2','L1','L5','H4','L3','L5','L2','H1','L5','L3','L2','L5','H4','L3','L5','L2'],
            ['L4','L3','L1','H3','H2','L5','H4','L4','L5','L2','H1','L5','H4','L1','L4','L3','L2','L5','L3','H4','L5','L2','L3','H3','H4','L2','L5','L3','H4','L5','L2','L3','L5','H2','L1','L5','H4','L3','L5','L2','H1','L5','L3','L2','L5','H4','L3','L5','L2'],
            ['L4','L3','L1','H3','H2','L5','H4','L4','L5','L2','H1','L5','H4','L1','L4','L3','L2','L5','WS','H4','L5','L2','L3','H3','H4','L2','L5','L3','H4','L5','L2','WS','L5','H2','L1','L5','H4','L3','L5','L2','H1','L5','L3','L2','L5','H4','L3','L5','L2'],
    
            ['L4','L5','L2','H4','H2','L3','H3','H1','L3','H2','L1','L3','L4','H3','L5','L4','L1','L3','H3','H2','L3','L1','L4','L3','L1','H3','L3','L4','L1','L3','H3','L4','L3','L2','H1','L3','L4','H3','L3','L1','L4','L3','L2','L1','H2','L4','H3','L3','L1'],
            ['L4','L5','L2','H4','H2','L3','H3','H1','L3','H2','L1','L3','WS','H3','L5','L4','L1','L3','H3','H2','L3','L1','L4','L3','L1','H3','L3','L4','L1','L3','H3','L4','L3','L2','H1','L3','L4','H3','L3','L1','L4','L3','L2','L1','H2','L4','H3','L3','L1'],
            ['L4','L5','L2','H4','H2','L3','H3','H1','L3','H2','L1','L3','WS','H3','L5','L4','L1','L3','H3','H2','L3','L1','L4','L3','L1','H3','L3','L4','L1','L3','H3','WS','L3','L2','H1','L3','L4','H3','L3','L1','L4','L3','L2','L1','H2','L4','H3','L3','L1'],
    
            ['L4','L3','L1','H3','L4','L5','H2','L2','L5','H1','H4','L5','L2','L3','L5','H4','H2','L3','L2','H4','L5','L2','L4','L5','H4','L4','L5','L1','H1','L5','L4','H4','L5','L4','L2','L5','H4','L4','L5','L2','L1','L5','H2','L2','L5','L4','H4','L5','L2'],
            ['L4','L3','L1','H3','L4','L5','H2','L2','L5','H1','H4','L5','L2','L3','L5','H4','H2','L3','L2','H4','L5','L2','L4','L5','H4','L4','L5','L1','H1','L5','L4','H4','L5','L4','L2','L5','H4','L4','L5','L2','L1','L5','H2','L2','L5','L4','H4','L5','L2'],
            ['L4','L3','L1','H3','WS','L5','H2','L2','L5','H1','H4','L5','L2','L3','L5','H4','H2','L3','L2','H4','L5','L2','L4','L5','H4','WS','L5','L1','H1','L5','L4','H4','L5','WS','L2','L5','H4','L4','L5','L2','L1','L5','H2','L2','L5','L4','H4','L5','L2'],
    
            ['L3','L4','L2','H3','H2','L3','L2','L5','L3','L1','H1','L3','L5','H3','L3','L1','H2','L5','L3','L2','L1','L5','L3','H3','L5','H4','L3','L5','L1','L4','L2','L5','L3','H4','L5','H3','L3','L5','L1','H4','L3','L5','L2','L1','L5','L3','H4','L5','L1'],
            ['L3','L4','L2','H3','H2','L3','L2','L5','L3','L1','H1','L3','L5','H3','L3','L1','H2','L5','L3','L2','L1','L5','L3','H3','L5','H4','L3','L5','L1','L4','L2','L5','L3','H4','L5','H3','L3','L5','L1','H4','L3','L5','L2','L1','L5','L3','H4','L5','L1'],
            ['L3','L4','L2','H3','H2','L3','L2','WS','L3','L1','H1','L3','L5','H3','L3','L1','H2','L5','L3','L2','L1','L5','L3','H3','L5','H4','L3','L5','WS','L4','L2','L5','L3','H4','L5','H3','L3','L5','L1','H4','L3','L5','L2','L1','L5','L3','H4','L5','L1'],
        ],
    },
};
