import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Vue from "vue";
import { model, GameState } from "./model";
import { createChoicesFromScenario } from "./model/choices";
import {
  StepJson,
  originatorId,
} from "../../../../state/models/slots/deal-or-no-deal";
import { SlotWindow } from "./slot-window";
import { LineWin } from "@vgw/gdk-math-model-tools";

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
  },
  data: function (): {
    scenario: InternalScenario;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        reelStripPositions: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
        ],
        isBankerMultiplierOfferAwarded: false,
        bankerMultiplierAmount: 0,
        isBankerStacksOfferAwarded: false,
        bankerStacksSymbol: "WILD",
        bankerStacksReels: [],
        bankerScat: {
          cell: [0, 0],
          symbol: "A"
        },
        bankerCase: {
          cell: [0, 0],
          symbol: "A"
        },
        isOfferAwarded: false,
        bankerOfferWinAmount: 5,
        hasAcceptedBankerOffer: false,
        casePrizes: [[null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null]],
        goldCases: [{
          cell: [0, 0],
          feature: 'COLLECT' as const
        }],
      jackpot: 'GRAND'
      },
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      const spinOutput = model.play(
        {
          coinType: "SC",
          gameRequest: { coinAmount: 1, hasAcceptedBankerOffer: this.currentStep?.json.hasAcceptedBankerOffer },
          gameState: this.currentStep?.gameState,
        },
        createChoicesFromScenario({
          ...this.scenario,
          reelStripPositions: this.scenario.reelStripPositions.map(
            ({ value }) => value
          ),
        })
      );
      
      console.log('Spin output:', spinOutput)

      const spinOutcomeWithWinCells = {
        ...spinOutput,
        winCells: getWinCells({
          lineWins: spinOutput.gameResponse.lineWins,
          playLines: model.definition.playLines,
        }),
      };

      return spinOutcomeWithWinCells;
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
  },
  methods: {
    addClearingStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "clear",
        reelStripPositions: [0, 0, 0, 0, 0],
      });
    },
    addStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [3, 5, 0, 0, 3],
      });
    },
    addFreeSpinStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [72, 72, 72, 5, 5],
      });
    },
    addBankerMultiplier() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [0, 0, 0, 0, 0],
        isBankerMultiplierOfferAwarded: true,
        bankerMultiplierAmount: this.scenario.bankerMultiplierAmount
      });
    },
    addBankerStacks() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [0, 0, 0, 0, 0],
        isBankerStacksOfferAwarded: true,
        bankerStacksSymbol: this.scenario.bankerStacksSymbol,
        bankerStacksReels: this.scenario.bankerStacksReels
      });
    },
    addBankerScat() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [72, 72, 72, 0, 0],
        bankerScat: this.scenario.bankerScat
      });
    },
    addBankerCase() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [0, 54, 54, 0, 0],
        bankerCase: this.scenario.bankerCase
      });
    },
    addBankerOffer() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [27, 5, 21, 0, 0],
        isOfferAwarded: true,
        bankerOfferWinAmount: this.scenario.bankerOfferWinAmount,
        slotWindow: this.currentStep?.gameState?.slotWindow
      });
    },
    acceptOffer() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [27, 5, 21, 0, 0],
        isOfferAwarded: true,
        hasAcceptedBankerOffer: true,
        bankerOfferWinAmount: this.scenario.bankerOfferWinAmount,
        slotWindow: this.currentStep?.gameState?.slotWindow
      });
    },
    declineOffer() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [27, 5, 21, 0, 0],
        isOfferAwarded: true,
        hasAcceptedBankerOffer: false,
        bankerOfferWinAmount: this.scenario.bankerOfferWinAmount,
        slotWindow: this.currentStep?.gameState?.slotWindow
      });
    },
    addCasePrize() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [22, 54, 54, 46, 31],
        casePrizes: this.scenario.casePrizes,
        slotWindow: this.currentStep?.gameState?.slotWindow
      });
    },
    addGoldCase() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "continue",
        reelStripPositions: [22, 54, 54, 46, 31],
        goldCases: this.scenario.goldCases
      });
    },
    addJackpot() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        gameState: "continue",
        reelStripPositions: [54, 54, 54, 54, 54],
        jackpot: this.scenario.jackpot
      });
    },
  
    
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }
        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertToStepJson(newScenario),
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}
function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
    isBankerStacksOfferAwarded: step.json.isBankerStacksOfferAwarded ?? false,
    bankerStacksSymbol: step.json.bankerStacksSymbol ?? "WILD",
    bankerStacksReels: step.json.bankerStacksReels ?? [],
    isBankerMultiplierOfferAwarded: step.json.isBankerMultiplierOfferAwarded ?? false,
    bankerMultiplierAmount: step.json.bankerMultiplierAmount ?? 0,
    bankerScat:  step.json.bankerScat ?? { cell: [0, 0], symbol: 'A' },
    bankerCase:  step.json.bankerCase ?? { cell: [0, 0], symbol: 'A' },
    isOfferAwarded: step.json.isOfferAwarded ?? false,
    bankerOfferWinAmount: step.json.bankerOfferWinAmount ?? 5,
    hasAcceptedBankerOffer: step.json.hasAcceptedBankerOffer ?? false,
    casePrizes: step.json.casePrizes ?? [[null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null]],
    goldCases: step.json.goldCases ?? [{
      cell: [0, 0],
      feature: 'COLLECT' as const
    }],
    jackpot: step.json.jackpot ??  'GRAND'
    
  };
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  return {
    gameState: scenario.gameState,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
    isBankerMultiplierOfferAwarded: scenario.isBankerMultiplierOfferAwarded,
    bankerMultiplierAmount: scenario.bankerMultiplierAmount,
    isBankerStacksOfferAwarded: scenario.isBankerStacksOfferAwarded,
    bankerStacksSymbol: scenario.bankerStacksSymbol,
    bankerStacksReels: scenario.bankerStacksReels,
    bankerScat: scenario.bankerScat,
    bankerCase: scenario.bankerCase,
    isOfferAwarded: scenario.isOfferAwarded,
    bankerOfferWinAmount: scenario.bankerOfferWinAmount,
    casePrizes: scenario.casePrizes,
    goldCases: scenario.goldCases,
    jackpot: scenario.jackpot
  };
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;

  for (const step of steps) {
    if (step.json.gameState === "clear") gameState = undefined;

    step.gameState = gameState;
    const spinOutput = model.play(
      {
        coinType: "SC",
        gameRequest: { coinAmount: 1, hasAcceptedBankerOffer: step.json.hasAcceptedBankerOffer },
        gameState,
      },
      createChoicesFromScenario({
        ...step.json,
      })
    );

    gameState = spinOutput.gameState;
  }

  return modified;
}

function getWinCells({
  lineWins,
  playLines,
}: {
  lineWins: LineWin[];
  playLines: number[][];
}) {
  const cells: [number, number][] = [];
  for (const lineWin of lineWins) {
    const playLineIndex = lineWin.playLineIndex;
    const playLine = playLines[playLineIndex];

    for (const [reelIndex, rowIndex] of playLine.entries()) {
      cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
      if (reelIndex < lineWin.length) {
        cells[reelIndex][rowIndex] = 1;
      }
    }
  }
  return cells;
}

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

interface InternalScenario {
  reelStripPositions: { value: number }[];
  gameState?: "continue" | "clear";
  isBankerMultiplierOfferAwarded: boolean
  bankerMultiplierAmount: number
  isBankerStacksOfferAwarded: boolean
  bankerStacksSymbol: 'WILD' | 'CASE'
  bankerStacksReels: number[]
  bankerScat: {cell:[number, number], symbol: string}
  bankerCase: {cell:[number, number], symbol: string}
  isOfferAwarded: boolean,
  bankerOfferWinAmount: number
  hasAcceptedBankerOffer: boolean
  casePrizes: (number | 0 | null)[][]
  goldCases: {
    cell: [number, number];
    feature: 'COLLECT' | 'MULTIPLYx2' | 'MULTIPLYx3' | 'MULTIPLYx4' | 'MULTIPLYx5' | 'BOOST';
  }[];
  jackpot?: 'GRAND'
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;