var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonus5Prize } },
      [
        _c("option", { attrs: { value: "FREE_SPIN" } }, [_vm._v("Freespin")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "JACKPOT" } }, [_vm._v("Jackpot")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "LADDER1" } }, [_vm._v("LADDER1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "LADDER2" } }, [_vm._v("LADDER2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "LADDER3" } }, [_vm._v("LADDER3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "COLLECT" } }, [_vm._v("COLLECT")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }