import { createDefaultSlotStep } from "./../../../../helpers/helpers";
import State from "../../../state";
import { config } from "./config/init";

export function setStateParams() {
  return {
    variations: [],

    createStep: (
      reelStripPositions,
      isFeature = false,
      order = 0,
      variation = "",
      name = "Base"
    ) => {
      const step = State.state.addExtraDataToStep(
        createDefaultSlotStep(
          reelStripPositions,
          isFeature,
          order,
          variation,
          name
        )
      );
      step.gameState = isFeature ? "continue" : "clear";
      return step;
    },

    // Must implement
    createDefaultStep: (order = 0, variation = "") =>
      State.state.createStep([0, 0, 0, 0, 0]),
    getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),

    // unique per model
    getModelDefinition: () =>
      State.state.standalone
        ? config
        : State.state.slot.state.getModelDefinition(),
    getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
    getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
    getReelStrips: () => State.state.getModelDefinition().reels,
    // example on creating custom indexes
    getFeatureReelStrips: () => State.state.getModelDefinition().reels,
  };
}
