import { baseSpin } from './base-spin';
import { boardGame, jackpotWheel } from './board-game';
import { ensurePlayerInputsAreValid } from './ensure-spin-inputs-are-valid';
import { freeSpins } from './free-spin';
import { SpinInputs } from './spin-inputs';
import { SpinOutputs } from './spin-outputs';

export function spin(inputs: SpinInputs): SpinOutputs {
    ensurePlayerInputsAreValid(inputs);

    if (inputs.gameState?.canDoJackpotWheel !== undefined && inputs.gameState?.canDoJackpotWheel === true) {
        return jackpotWheel(inputs);
    }

    if (inputs.gameState?.boardGamePhase !== undefined) {
        return boardGame(inputs);
    }

    if (inputs.gameState?.freeSpinPhase !== undefined) {
        return freeSpins(inputs);
    }

    return baseSpin(inputs);
}
