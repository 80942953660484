import Vue from "vue";
import {Prop, Component, Watch} from "vue-property-decorator";
import bus from "../../../../../common/bus";
import events from "../../../../../common/events";
import {IVGW065Step} from "../content.vue";

@Component({
    data: () => {
        return {
            wheelPrize: "",
        };
    },
})
export default class WheelPrize extends Vue {
    @Prop({type: Number, default: 0}) public index;
    @Prop({type: String}) public initialValue;
    @Prop() public step: any;

    private canUpdate = false;
    public wheelPrize: number | string = "";

    constructor() {
        super();
        this.wheelPrize = this.initialValue;
    }

    @Watch("step")
    public stepChanged(step: IVGW065Step) {
        if (!step || !step.json) {
            return;
        }
        this.canUpdate = false;
        this.wheelPrize =
            (step.json.field1 && step.json.field1[this.index]) || this.initialValue || "";

        this.$nextTick(() => {
            this.canUpdate = true;
        });
    }

    @Watch("wheelPrize")
    public wheelPrizeChanged(val) {
        if (!this.step.json.field1) {
            // Prevent attempted initialization unless bonus wheel round
            return;
        }
        
        // Ensure jackpots cannot be duplicated
        if (val === 'minor') {
            this.blockDuplicateJackpots(val, 'minor');
            return
        };
        if (val === 'major') {
            this.blockDuplicateJackpots(val, 'major');
            return
        };
        
        if (this.index === 0 || this.isFilledToTheLeft(this.index)) {
            // ensure wheel prizes with a blank to the left are not saved
            this.wheelPrize = val;
            this.step.json.field1[this.index] = val;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        } else if (this.index > 0) {
            this.wheelPrize = "";
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }
    
    private isFilledToTheLeft(count: number) {
        const arr = this.step.json.field1.slice(0, count)
        if (count > arr.length) {
            return false;
        }
        return arr.every((e: any) => typeof e === 'string');
    }
    
    private blockDuplicateJackpots(val: string, jackpotType: string) {
        if (this.step.json.field1.includes(jackpotType)) {
            this.wheelPrize = this.step.json.field1[this.index] || "";
        } else {
            this.wheelPrize = val;
            this.step.json.field1[this.index] = val; 
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }
}
