/* tslint:disable:variable-name */
import State from "../../../state";
import {trace, TODO, wrapIndex, range, randRange, 
    rowsFromIndexes,
    createRandomSlotStep,
    createDefaultSlotStep,
} from "./../../../../helpers/helpers";
import init from "./config/init";

export function setStateParams() {
    return {
        variations: [""], 
        // Must implement
        createDefaultStep: (variation = "") => {
            const step = State.state.createStep([0, 0, 0, 0, 0]);
            step.substitutionSymbol="PIC1";
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        // unique per model
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(), 
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getFeatureReelStrips: () => State.state.getModelDefinition().reels,
        
        // example on creating custom indexes
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
            const step = State.state.createStep([67, 48, 27, 0, 0], false, 0, "", "F-Trigger");
            step.substitutionSymbol = "PIC1";
            return step;
        },
        getFeatureStep: () => {
            const step = State.state.createStep([9, 4, 7, 0, 21], true, 0, "", "F-Step");
            step.substitutionSymbol = "PIC1";
            return step;
        },
        getFeatureReTriggerStep: () => {
            const step = State.state.createStep([67, 48, 27, 0, 0], true, 0, "", "F-Step");
            step.substitutionSymbol = "PIC1";
            return step;  
        },
    };
}
