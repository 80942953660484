import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace, wrapIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";
  
@Component({
    components: {
        reel: Reel,
        selectScatterMultiplier: SelectScatterMultiplier,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 3;
    public reel = "";
    public reelsType = "";
    public isOpen = false;
    public isActive = false;
    public isFsStep = false;
    public internal_name = "";
    public isBase = false;
    public levelNo = -1;
    public extraReels = 0;
    public isFSTrigger = false;
    public reelIds = [];
    public scattersSelectedMultipliers = new Array(12).fill("Random");


    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.isOpen = false;
        this.show = true;
        this.isActive = false;
        this.levelNo = step.json.levelNo ? parseInt(step.json.levelNo) : -1;
        this.extraReels = step.json.extraReels ? step.json.extraReels : 0;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        if(step.json.internal_name == "Respin Step") {
            this.isFSTrigger = false;
            this.isFsStep = true;
            this.isBase = true;
        } else if(step.json.internal_name == "Base") {
            this.isFSTrigger = false;
            this.isFsStep = false;
            this.isBase = true;
        }else if(step.json.internal_name == "Respin Trigger") {
            this.isFSTrigger = true;
            this.isFsStep = false;
            this.isBase = true;
        }

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }

        this.updateScatterMultipliers();
    }

    @Watch("extraReels")
    public textChanged(val) {
        this.extraReels = Number(val);
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getBaseStep("reels"));
    }

    public addFreeSpinTrigger() {
        const state = State.state;
        this.isFsStep = false;
        this.reelsType = "reels";
        this.levelNo = -1;
        this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getRespinTriggerStep("Respin Trigger", this.reelsType));
    }

    public addFreeSpinStep() {
        const state = State.state;
        this.isFsStep = true;
        this.reelsType = "FS";
        //this.extraReels = 0;
        bus.$emit(events.ADD_STEP, state.getRespinStep("Respin step", this.reelsType));
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public updateStep(levelNo, extraReels) {
        this.step.json.levelNo = parseInt(levelNo);
        this.step.json.extraReels = parseInt(extraReels);
        this.refreshScatterMultipliersLength( this.getSymbolNum() );
        this.updateScatterMultipliers();
        this.isActive = true;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    private getSymbolNum()
    {
        const symbolNum = (3+this.extraReels) * 4;
        return symbolNum;
    }

    private refreshScatterMultipliersLength( scatterNum: number )
    {
        if ( this.step.json.scattersSelectedMultipliers.length < scatterNum )
        {
            for(;;)
            {
                this.step.json.scattersSelectedMultipliers.push( "Random" );
                if ( this.step.json.scattersSelectedMultipliers.length >= scatterNum )
                    break;
            }
        }
        else
        {
            if ( this.step.json.scattersSelectedMultipliers.length > scatterNum )
            {
                for(;;)
                {
                    this.step.json.scattersSelectedMultipliers.pop();
                    if ( this.step.json.scattersSelectedMultipliers.length <= scatterNum )
                        break;
                }
            }
        }
    }

    public updateRespinScattersMultipliers(event) {

        const scatterNum = this.getSymbolNum();
        if (this.step.json.scattersSelectedMultipliers == null) {
            this.step.json.scattersSelectedMultipliers = new Array(scatterNum).fill("Random");
        }

        this.refreshScatterMultipliersLength( scatterNum );
        this.step.json.scattersSelectedMultipliers[event.target.id] = event.target.value;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addFullRandom() {
        this.scattersSelectedMultipliers = [];
        this.step.json.scattersSelectedMultipliers = new Array(this.getSymbolNum()).fill("Random");
        this.updateScatterMultipliers();
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addFullNoScatter() {
        this.scattersSelectedMultipliers = [];
        this.step.json.scattersSelectedMultipliers = new Array(this.getSymbolNum()).fill("NoScatter");
        this.updateScatterMultipliers();
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateScatterMultipliers() {
        this.scattersSelectedMultipliers = new Array(this.getSymbolNum()).fill("Random");
        let update = false;
        if (this.step.json.scattersSelectedMultipliers != null) {
            for (let i=0;i<this.step.json.scattersSelectedMultipliers.length;i++){
                if ( this.scattersSelectedMultipliers[i] != this.step.json.scattersSelectedMultipliers[i] )
                    update = true;
                this.scattersSelectedMultipliers[i] = this.step.json.scattersSelectedMultipliers[i];
            }
        }
        return update;
    }


}
