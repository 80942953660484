var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonus8Prize } },
      [
        _c("option", { attrs: { value: "Mini" } }, [_vm._v("Mini")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "Major" } }, [_vm._v("Major")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "Mega" } }, [_vm._v("Mega")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }