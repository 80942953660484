var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreeGameStep()
              },
            },
          },
          [_vm._v("FreeGame")]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelCount, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c("p", { staticStyle: { "margin-left": "1rem" } }, [
          _vm._v("Reels Number"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reelsNo,
                expression: "reelsNo",
              },
            ],
            staticStyle: { margin: "0.2rem" },
            attrs: { name: "reelsNo", id: "reelNo" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.reelsNo = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onUpdateReels,
              ],
            },
          },
          _vm._l(_vm.reelArray, function (reel, index) {
            return _c("option", { key: index, domProps: { value: reel } }, [
              _vm._v(_vm._s(reel)),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-left": "1rem" } }, [
          _vm._v("Multiplier"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.multiplierValue,
                expression: "multiplierValue",
              },
            ],
            staticStyle: { margin: "0.2rem" },
            attrs: { name: "multiplier", id: "multiplier" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.multiplierValue = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onUpdateMultiplier,
              ],
            },
          },
          _vm._l(_vm.multiplierValueArray, function (multiplier) {
            return _c(
              "option",
              { key: multiplier, domProps: { value: multiplier } },
              [_vm._v(_vm._s(multiplier))]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }