/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf004_sandprincess",
        "wildSymbol": "WILD",
        "scatterSymbol": "Bonus",
        "countToTriggerBonusGame": 3,
        "freeSpinCount": 10,
        "winTable": [
            {
                "symbol": "WILD",
                "multipliers": [500, 100, 40]
            },
            {
                "symbol": "HV1",
                "multipliers": [500, 100, 40]
            }, {
                "symbol": "HV2",
                "multipliers": [300, 75, 30]
            }, {
                "symbol": "HV3",
                "multipliers": [150, 50, 20]
            }, {
                "symbol": "MV1",
                "multipliers": [150, 50, 20]
            }, {
                "symbol": "MV2",
                "multipliers": [100, 30, 15]
            }, {
                "symbol": "MV3",
                "multipliers": [100, 30, 15]
            }, {
                "symbol": "Ace",
                "multipliers": [50, 25, 10]
            }, {
                "symbol": "King",
                "multipliers": [50, 25, 10]
            }, {
                "symbol": "Queen",
                "multipliers": [40, 10, 5]
            }, {
                "symbol": "Jack",
                "multipliers": [40, 10, 5]
            }, {
                "symbol": "Bonus",
                "multipliers": [0, 0, 1]
            }
        ],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000, 200000],
        "coinType4Values": [ 1, 2, 5, 10, 50, 100],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"200000",
        "coinType1_spin_max_bet":"5000000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"100",
        "coinType4_spin_max_bet":"2500",

        "reelsLayout": [3, 3, 3, 3, 3],
        "reels": [
            [ "Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "Ace", "MV1", "MV3", "HV3", "HV3", "Ace", "Queen", "HV1", "HV1", "HV1", "King", "King", "MV2", "Jack", "MV3", "Queen", "Queen", "MV3", "Ace", "MV1", "MV2", "Ace", "Jack", "MV3", "Queen", "HV2", "HV2", "HV2", "Ace", "MV1", "Queen", "HV3", "HV3", "MV1", "MV3", "Ace", "King", "HV3", "HV3", "HV3", "Queen", "MV2", "Ace", "MV1"],
            [ "King", "HV2", "HV2", "HV2", "Queen", "MV2", "MV3", "King", "Bonus", "Ace", "HV3", "HV3", "MV1", "MV3", "Ace", "Ace", "Bonus", "MV3", "Queen", "Queen", "HV1", "HV1", "HV1", "Queen", "MV2", "MV3", "Jack", "Bonus", "King", "Queen", "MV2", "MV3", "MV1", "Queen", "Jack", "MV2", "HV2", "HV2", "HV2", "Ace", "Ace", "MV2", "HV3", "HV3", "King", "MV2", "Bonus", "Queen", "MV1", "HV3", "HV3", "King"],
            [ "Bonus", "Queen", "King", "MV1", "MV2", "Queen", "HV3", "HV3", "King", "MV3", "Ace", "HV2", "HV2", "Queen", "HV1", "HV1", "Ace", "MV3", "MV2", "Queen", "Bonus", "Ace", "King", "MV3", "WILD", "WILD", "WILD", "MV2", "MV1", "Queen", "Queen", "MV3", "HV3", "HV3", "Ace", "Jack", "Bonus", "Queen", "King", "MV1", "MV2", "Queen", "HV3", "HV3", "King", "MV3", "Ace", "HV2", "HV2", "Queen", "HV1", "HV1", "Ace", "MV3", "MV2", "Queen", "Bonus", "King", "Ace", "MV1", "WILD", "WILD", "WILD", "Queen", "HV3", "HV3", "MV3", "Queen", "MV2", "Ace", "MV3", "Jack"],
            [ "King", "HV2", "HV2", "HV2", "Jack", "MV1", "MV3", "Bonus", "Queen", "King", "HV3", "HV3", "Ace", "MV3", "MV1", "Queen", "King", "MV1", "MV2", "MV3", "Queen", "Queen", "Bonus", "Ace", "King", "MV3", "MV2", "HV1", "HV1", "HV1", "Queen", "MV2", "HV2", "HV2", "HV2", "Jack", "Bonus", "King", "Queen", "HV3", "HV3", "MV2", "MV1", "Queen", "HV3", "HV3", "Ace"],
            [ "Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "HV3", "HV3", "Jack", "MV1", "Ace", "MV3", "Queen", "King", "HV1", "HV1", "HV1", "Ace", "MV3", "Queen", "Queen", "MV1", "MV2", "King", "Ace", "MV3", "MV1", "Queen", "HV2", "HV2", "HV2", "Jack", "MV3", "Queen", "Ace", "HV3", "HV3", "King", "MV2", "MV1", "Ace", "Jack", "MV1", "HV3", "HV3", "Queen", "Ace", "MV2", "MV1", "Jack"]
        ],

        "reelsRandomWilds": [
            [ "WILD", "HV2", "HV2", "WILD", "King", "MV1", "WILD", "Ace", "WILD", "WILD", "MV3", "HV3", "WILD", "Ace", "Queen", "WILD", "HV1", "HV1", "WILD", "King", "WILD", "WILD", "MV3", "Queen", "WILD", "MV3", "Ace", "WILD", "MV2", "Ace", "WILD", "MV3", "Queen", "WILD", "HV2", "HV2", "WILD", "MV1", "Queen", "WILD", "HV3", "MV1", "WILD", "Ace", "King", "WILD", "HV3", "HV3", "WILD", "MV2", "Ace", "WILD"],
            [ "WILD", "HV2", "HV2", "WILD", "Queen", "MV2", "WILD", "King", "Bonus", "Ace", "HV3", "WILD", "WILD", "MV3", "Ace", "WILD", "Bonus", "MV3", "Queen", "Queen", "HV1", "WILD", "HV1", "WILD", "MV2", "MV3", "Jack", "Bonus", "King", "Queen", "MV2", "WILD", "MV1", "Queen", "WILD", "MV2", "HV2", "WILD", "HV2", "Ace", "Ace", "MV2", "HV3", "HV3", "King", "WILD", "Bonus", "Queen", "WILD", "HV3", "HV3", "King"],
            [ "Bonus", "Queen", "King", "MV1", "MV2", "Queen", "HV3", "HV3", "King", "MV3", "Ace", "HV2", "HV2", "Queen", "HV1", "HV1", "Ace", "MV3", "MV2", "Queen", "Bonus", "Ace", "King", "MV3", "WILD", "WILD", "WILD", "MV2", "MV1", "Queen", "Queen", "MV3", "HV3", "HV3", "Ace", "Jack", "Bonus", "Queen", "King", "MV1", "MV2", "Queen", "HV3", "HV3", "King", "MV3", "Ace", "HV2", "HV2", "Queen", "HV1", "HV1", "Ace", "MV3", "MV2", "Queen", "Bonus", "King", "Ace", "MV1", "WILD", "WILD", "WILD", "Queen", "HV3", "HV3", "MV3", "Queen", "MV2", "Ace", "MV3", "Jack"],
            [ "WILD", "HV2", "HV2", "WILD", "WILD", "MV1", "MV3", "Bonus", "Queen", "WILD", "HV3", "HV3", "Ace", "WILD", "MV1", "Queen", "King", "MV1", "WILD", "MV3", "Queen", "Queen", "Bonus", "Ace", "King", "WILD", "MV2", "HV1", "HV1", "WILD", "Queen", "WILD", "HV2", "HV2", "HV2", "Jack", "Bonus", "King", "WILD", "HV3", "WILD", "MV2", "WILD", "Queen", "HV3", "HV3", "WILD"],
            [ "WILD", "HV2", "WILD", "WILD", "King", "MV1", "WILD", "WILD", "HV3", "HV3", "WILD", "WILD", "Ace", "MV3", "WILD", "WILD", "WILD", "HV1", "WILD", "Ace", "WILD", "Queen", "Queen", "MV1", "WILD", "King", "Ace", "MV3", "WILD", "Queen", "HV2", "WILD", "HV2", "WILD", "MV3", "Queen", "Ace", "WILD", "HV3", "King", "MV2", "WILD", "Ace", "Jack", "MV1", "HV3", "WILD", "Queen", "Ace", "WILD", "MV1", "Jack"]
        ],

        "reelsFS": [
            [ "Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "Ace", "MV1", "MV3", "HV3", "HV3", "Ace", "Queen", "HV1", "HV1", "HV1", "King", "King", "MV2", "Jack", "MV3", "Queen", "Queen", "MV3", "Ace", "MV1", "MV2", "Ace", "Jack", "MV3", "Queen", "HV2", "HV2", "HV2", "Ace", "MV1", "Queen", "HV3", "HV3", "MV1", "MV3", "Ace", "King", "HV3", "HV3", "HV3", "Queen", "MV2", "Ace", "MV1"],
            [ "King","HV2","HV2","HV2","Queen","MV2","MV3","King","Ace","HV3","HV3","MV1","MV3","Ace","Ace","MV3","Queen","Queen","HV1","HV1","HV1","Queen","MV2","MV3","Jack","King","Queen","MV2","MV3","MV1","Queen","Jack","MV2","HV2","HV2","HV2","Ace","Ace","MV2","HV3","HV3","King","MV2","Queen","MV1","HV3","HV3","King"],
            [ "WILD", "WILD", "WILD", "MV2", "HV3", "Queen", "WILD", "WILD", "WILD", "Ace", "MV3", "King", "WILD", "WILD", "WILD", "Jack", "HV2", "Queen", "WILD", "WILD", "WILD", "King", "HV1", "Ace", "WILD", "WILD", "WILD", "Queen", "MV1", "Jack"],
            [ "King", "HV2", "HV2", "HV2", "Jack", "MV1", "MV3", "Queen", "King", "HV3", "HV3", "Ace", "MV3", "MV1", "Queen", "King", "MV1", "MV2", "MV3", "Queen", "Queen", "Ace", "King", "MV3", "MV2", "HV1", "HV1", "HV1", "Queen", "MV2", "HV2", "HV2", "HV2", "Jack", "King", "Queen", "HV3", "HV3", "MV2", "MV1", "Queen", "HV3", "HV3", "Ace", "MV2"],
            [ "Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "HV3", "HV3", "Jack", "MV1", "Ace", "MV3", "Queen", "King", "HV1", "HV1", "HV1", "Ace", "MV3", "Queen", "Queen", "MV1", "MV2", "King", "Ace", "MV3", "MV1", "Queen", "HV2", "HV2", "HV2", "Jack", "MV3", "Queen", "Ace", "HV3", "HV3", "King", "MV2", "MV1", "Ace", "Jack", "MV1", "HV3", "HV3", "Queen", "Ace", "MV2", "MV1", "Jack"]
        ],

        "reelsFSRandomWilds": [
            [ "Queen", "HV2", "HV2", "WILD", "King", "MV1", "MV3", "WILD", "Ace", "MV1", "WILD", "HV3", "HV3", "Ace", "Queen", "WILD", "HV1", "HV1", "King", "WILD", "MV2", "Jack", "MV3", "Queen", "WILD", "MV3", "Ace", "MV1", "MV2", "Ace", "WILD", "MV3", "Queen", "HV2", "HV2", "HV2", "Ace", "WILD", "Queen", "HV3", "HV3", "MV1", "MV3", "Ace", "King", "WILD", "HV3", "HV3", "Queen", "WILD", "Ace", "MV1"],
            [ "King", "WILD", "HV2", "HV2", "Queen", "MV2", "WILD", "King", "Ace", "HV3", "HV3", "MV1", "MV3", "Ace", "WILD", "MV3", "Queen", "Queen", "HV1", "HV1", "WILD", "Queen", "MV2", "MV3", "Jack", "King", "WILD", "MV2", "MV3", "MV1", "Queen", "WILD", "WILD", "HV2", "HV2", "HV2", "Ace", "Ace", "MV2", "HV3", "WILD", "WILD", "MV2", "Queen", "WILD", "HV3", "HV3", "King"],
            [ "WILD", "WILD", "WILD", "MV2", "WILD", "Queen", "WILD", "WILD", "WILD", "Ace", "WILD", "King", "WILD", "WILD", "WILD", "Jack", "WILD", "Queen", "WILD", "WILD", "WILD", "King", "WILD", "Ace", "WILD", "WILD", "WILD", "WILD", "MV1", "Jack"],
            [ "WILD", "HV2", "WILD", "HV2", "Jack", "MV1", "MV3", "WILD", "King", "HV3", "HV3", "Ace", "WILD", "MV1", "Queen", "King", "MV1", "WILD", "MV3", "Queen", "Queen", "WILD", "King", "MV3", "MV2", "WILD", "HV1", "HV1", "Queen", "MV2", "HV2", "HV2", "HV2", "WILD", "King", "Queen", "HV3", "HV3", "MV2", "WILD", "Queen", "HV3", "WILD", "Ace", "MV2"],
            [ "Queen", "HV2", "HV2", "HV2", "WILD", "MV1", "MV3", "Ace", "WILD", "HV3", "Jack", "MV1", "Ace", "MV3", "WILD", "King", "HV1", "HV1", "WILD", "Ace", "MV3", "Queen", "Queen", "MV1", "WILD", "King", "Ace", "MV3", "WILD", "Queen", "HV2", "HV2", "WILD", "Jack", "WILD", "Queen", "Ace", "HV3", "HV3", "King", "MV2", "MV1", "Ace", "WILD", "MV1", "HV3", "HV3", "Queen", "WILD", "MV2", "MV1", "Jack"]
        ],

        "reelsWildReelFeature": [
            ["Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "Ace", "MV1", "MV3", "HV3", "HV3", "Ace", "Queen", "HV1", "HV1", "HV1", "King", "King", "MV2", "Jack", "MV3", "Queen", "Queen", "MV3", "Ace", "MV1", "MV2", "Ace", "Jack", "MV3", "Queen", "HV2", "HV2", "HV2", "Ace", "MV1", "Queen", "HV3", "HV3", "MV1", "MV3", "Ace", "King", "HV3", "HV3", "HV3", "Queen", "MV2", "Ace", "MV1"],
            ["King", "HV2", "HV2", "HV2", "Queen", "MV2", "MV3", "King", "Ace", "HV3", "HV3", "MV1", "MV3", "Ace", "Ace", "MV3", "Queen", "Queen", "HV1", "HV1", "HV1", "Queen", "MV2", "MV3", "Jack", "King", "Queen", "MV2", "MV3", "MV1", "Queen", "Jack", "MV2", "HV2", "HV2", "HV2", "Ace", "Ace", "MV2", "HV3", "HV3", "King", "MV2", "Queen", "MV1", "HV3", "HV3", "King"],
            ["WILD", "WILD", "WILD", "MV2", "King", "HV3", "HV3", "Queen", "WILD", "WILD", "WILD", "Ace", "MV3", "Queen", "Queen", "MV1", "King", "WILD", "WILD", "WILD", "Jack", "HV2", "HV2", "Queen", "Queen", "MV3", "MV1", "Ace", "WILD", "WILD", "WILD", "King", "HV1", "HV1", "Ace", "Jack", "MV2", "MV3", "King", "WILD", "WILD", "WILD", "Queen", "HV3", "HV3", "MV1", "Jack", "MV2", "MV3", "Ace", "WILD", "WILD", "WILD", "MV2", "King", "HV3", "HV3", "Queen", "WILD", "WILD", "WILD", "Ace", "MV3", "Queen", "Queen", "MV1", "King", "WILD", "WILD", "WILD", "Jack", "HV2", "HV2", "Queen", "Queen", "MV3", "MV1", "Ace", "WILD", "WILD", "WILD", "King", "HV1", "HV1", "Ace", "Jack", "MV2", "MV3", "King", "WILD", "WILD", "WILD", "Queen", "HV3", "HV3", "MV1", "Jack", "MV2", "MV3", "Ace"],
            ["King", "HV2", "HV2", "HV2", "Jack", "MV1", "MV3", "Queen", "King", "HV3", "HV3", "Ace", "MV3", "MV1", "Queen", "King", "MV1", "MV2", "MV3", "Queen", "Queen", "Ace", "King", "MV3", "MV2", "HV1", "HV1", "HV1", "Queen", "MV2", "HV2", "HV2", "HV2", "Jack", "King", "Queen", "HV3", "HV3", "MV2", "MV1", "Queen", "HV3", "HV3", "Ace", "MV2"],
            ["Queen", "HV2", "HV2", "HV2", "King", "MV1", "MV3", "Ace", "HV3", "HV3", "Jack", "MV1", "Ace", "MV3", "Queen", "King", "HV1", "HV1", "HV1", "Ace", "MV3", "Queen", "Queen", "MV1", "MV2", "King", "Ace", "MV3", "MV1", "Queen", "HV2", "HV2", "HV2", "Jack", "MV3", "Queen", "Ace", "HV3", "HV3", "King", "MV2", "MV1", "Ace", "Jack", "MV1", "HV3", "HV3", "Queen", "Ace", "MV2", "MV1", "Jack"]
        ],

        "wildReelsFeaturesWeights": [
            {"value": "2x", "weight": 12},
            {"value": "3x", "weight": 14},
            {"value": "4x", "weight": 12},
            {"value": "Random", "weight": 14},
            {"value": "Wild1", "weight": 15},
            {"value": "Wild2", "weight": 15},
            {"value": "Wild4", "weight": 7},
            {"value": "Wild5", "weight": 7}
        ],

        "bonusCoinsAward": [
            {"value": 50, "weight": 2},
            {"value": 60, "weight": 3},
            {"value": 75, "weight": 3},
            {"value": 80, "weight": 4},
            {"value": 100, "weight": 4},
            {"value": 120, "weight": 3},
            {"value": 125, "weight": 2},
            {"value": 150, "weight": 2},
            {"value": 160, "weight": 3},
            {"value": 175, "weight": 4},
            {"value": 180, "weight": 4},
            {"value": 200, "weight": 4},
            {"value": 225, "weight": 2},
            {"value": 250, "weight": 2},
            {"value": 300, "weight": 2},
            {"value": 500, "weight": 1}
        ],

        "bonusFSFeature": [
            {
                "FS1" : [
                    {"value": "2x", "weight": 600},
                    {"value": "3x", "weight": 624},
                    {"value": "4x", "weight": 819}
                ]
            },
            {"FS2" : [
                    {"value": "Wild1", "weight": 280},
                    {"value": "Wild2", "weight": 200},
                    {"value": "Wild4", "weight": 546},
                    {"value": "Wild5", "weight": 379}
                ]
            },
            {"FS3" : [
                    {"value": "Normal", "weight": 13493},
                    {"value": "Random", "weight": 4012}
                ]
            },
            {"FS4" : [
                    {"value": "2x", "weight": 60},
                    {"value": "3x", "weight": 62},
                    {"value": "4x", "weight": 60},
                    {"value": "Random", "weight": 12},
                    {"value": "Wild1", "weight": 6},
                    {"value": "Wild2", "weight": 18},
                    {"value": "Wild4", "weight": 49},
                    {"value": "Wild5", "weight": 35}
                ]
            }
        ],


        "playLines": [
            [ 1,1,1,1,1 ],//1
            [ 0,0,0,0,0 ],//2
            [ 2,2,2,2,2 ],//3
            [ 0,1,2,1,0 ],//4
            [ 2,1,0,1,2 ],//5
            [ 0,0,1,0,0 ],//6
            [ 2,2,1,2,2 ],//7
            [ 1,2,2,2,1 ],//8
            [ 1,0,0,0,1 ],//9
            [ 0,1,1,1,0 ],//10
            [ 2,1,1,1,2 ],//11
            [ 0,1,0,1,0 ],//12
            [ 2,1,2,1,2 ],//13
            [ 1,0,1,0,1 ],//14
            [ 1,2,1,2,1 ],//15
            [ 1,1,0,1,1 ],//16
            [ 1,1,2,1,1 ],//17
            [ 0,2,0,2,0 ],//18
            [ 2,0,2,0,2 ],//19
            [ 1,0,2,0,1 ],//20
            [ 1,2,0,2,1 ],//21
            [ 0,0,2,0,0 ],//22
            [ 2,2,0,2,2 ],//23
            [ 0,2,2,2,0 ],//24
            [ 2,0,0,0,2 ],//25
            [ 0,2,1,2,0 ],//26
            [ 2,0,1,0,2 ],//27
            [ 1,1,1,1,2 ],//28
            [ 0,0,1,2,2 ],//29
            [ 2,2,1,0,0 ] //30
        ],
        "betMultiplier" : 30

    },
}
