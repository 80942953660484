export const modelDefinition = {
    modelId: 'vgw044',
    gameIds: ['fuQianQian'],
    coinType1Values: [100, 500, 1000, 5000, 10000, 40000],
    coinType4Values: [1, 2, 5, 10, 20, 50, 100, 200, 400],
    reelsLayout: [3, 3, 3, 3, 3],
    defaultSelectLine: '50',

    winTable: [
        {
            symbol: 'PIC1',
            multipliers: [1000, 100, 20],
        },
        {
            symbol: 'PIC2',
            multipliers: [500, 75, 15],
        },
        {
            symbol: 'PIC3',
            multipliers: [500, 75, 15],
        },
        {
            symbol: 'PIC4',
            multipliers: [250, 50, 10],
        },
        {
            symbol: 'PIC5',
            multipliers: [250, 50, 10],
        },
        {
            symbol: 'HEART',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'DIAMOND',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'CLUB',
            multipliers: [50, 15, 5],
        },
        {
            symbol: 'SPADE',
            multipliers: [50, 15, 5],
        },
    ],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [1, 0, 0, 0, 1], // 12
        [1, 2, 2, 2, 1], // 13
        [0, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 2], // 15
        [1, 1, 0, 1, 1], // 16
        [1, 1, 2, 1, 1], // 17
        [0, 2, 0, 2, 0], // 18
        [2, 0, 2, 0, 2], // 19
        [0, 2, 2, 2, 0], // 20
        [2, 0, 0, 0, 2], // 21
        [0, 0, 2, 0, 0], // 22
        [2, 2, 0, 2, 2], // 23
        [0, 2, 1, 2, 0], // 24
        [2, 0, 1, 0, 2], // 25
        [1, 0, 2, 0, 1], // 26
        [1, 2, 0, 2, 1], // 27
        [1, 0, 1, 2, 1], // 28
        [1, 2, 1, 0, 1], // 29
        [0, 0, 1, 0, 0], // 30
        [2, 2, 1, 2, 2], // 31
        [0, 1, 1, 1, 2], // 32
        [2, 1, 1, 1, 0], // 33
        [0, 0, 0, 1, 2], // 34
        [2, 2, 2, 1, 0], // 35
        [0, 1, 2, 2, 2], // 36
        [2, 1, 0, 0, 0], // 37
        [1, 1, 1, 1, 0], // 38
        [1, 1, 1, 1, 2], // 39
        [0, 0, 0, 0, 1], // 40
        [2, 2, 2, 2, 1], // 41
        [1, 1, 1, 0, 0], // 42
        [1, 1, 1, 2, 2], // 43
        [0, 0, 0, 1, 1], // 44
        [2, 2, 2, 1, 1], // 45
        [1, 1, 0, 0, 0], // 46
        [1, 1, 2, 2, 2], // 47
        [0, 0, 1, 1, 1], // 48
        [2, 2, 1, 1, 1], // 49
        [1, 0, 0, 1, 2], // 50
    ],

    reels: [
        ['HEART','SPADE','PIC3','PIC3','PIC3','HEART','DIAMOND','PIC4','CLUB','SPADE','PIC2','PIC2','PIC2','DIAMOND','SPADE','PIC5','HEART','COIN','COIN','SPADE','CLUB','PIC1','PIC1','PIC1','DIAMOND','PIC3','CLUB','HEART','PIC5','PIC5','PIC5','DIAMOND','CLUB','PIC4','PIC4','PIC4'],
        ['CLUB','PIC2','HEART','SPADE','PIC4','PIC4','PIC4','DIAMOND','SPADE','PIC5','CLUB','WILD','DIAMOND','PIC4','SPADE','PIC1','PIC1','PIC1','HEART','CLUB','PIC3','DIAMOND','PIC5','PIC5','PIC5','CLUB','SPADE','COIN','COIN','COIN','HEART','PIC3','SPADE','WILD','HEART','PIC2','PIC2','PIC2','CLUB','PIC4','DIAMOND','PIC5','HEART','PIC3','PIC3','PIC3','DIAMOND'],
        ['SPADE','WILD','CLUB','PIC1','PIC1','PIC1','DIAMOND','WILD','CLUB','PIC5','PIC5','PIC5','HEART','DIAMOND','PIC2','PIC2','PIC2','SPADE','HEART','COIN','COIN','COIN','CLUB','HEART','PIC3','PIC3','PIC3','DIAMOND','SPADE','PIC4','PIC4','PIC4'],
        ['PIC1','CLUB','WILD','HEART','DIAMOND','PIC1','PIC1','PIC1','SPADE','PIC5','CLUB','WILD','DIAMOND','PIC4','PIC4','PIC4','CLUB','PIC3','HEART','PIC4','DIAMOND','PIC2','SPADE','COIN','COIN','COIN','HEART','PIC2','PIC2','PIC2','SPADE','WILD','DIAMOND','PIC5','PIC5','PIC5','CLUB','WILD','HEART','PIC3','PIC3','PIC3','SPADE'],
        ['SPADE','HEART','PIC5','PIC5','PIC5','DIAMOND','CLUB','PIC2','PIC2','PIC2','HEART','SPADE','PIC3','CLUB','PIC4','DIAMOND','SPADE','PIC3','PIC3','PIC3','HEART','COIN','COIN','COIN','DIAMOND','CLUB','PIC1','PIC1','PIC1','DIAMOND','HEART','PIC5','SPADE','CLUB','PIC4','PIC4','PIC4'],
    ],

    coinType1_min_bet: 100,
    coinType1_max_bet: 40_000,
    coinType1_spin_max_bet: 2_000_000,
    coinType4_min_bet: 1,
    coinType4_max_bet: 400,
    coinType4_spin_max_bet: 20_000,

    wildSymbol: 'WILD',
    scatterSymbol: 'WILD',
    coinSymbol: 'COIN',
    freeSpinTrigger: 'WILD',
    countToTriggerFreeSpin: 3,
    freeSpinCount: 5,
    countToTriggerFireshot: 6,
    fireshotRespinCount: 3,
    countToTriggerGrand: 15,

    /** Weights used to assign prizes to each COIN symbol that appears in the slot window. */
    coinPrizeWeights: {
        initial: {
            1: 100_000,
            2: 28_500,
            3: 15_000,
            4: 9_900,
            5: 3_700,
            10: 2_800,
            20: 1_800,
            MINI: 4_000,
            MINOR: 800,
            MAJOR: 'COIN_WEIGHT' as const,
            UPGRADE: 0,
            COLLECT: 0,
        },
        respin: {
            1: 294_525,
            2: 76_000,
            3: 36_000,
            4: 16_000,
            5: 8_000,
            10: 2_700,
            20: 2_700,
            MINI: 2_700,
            MINOR: 500,
            MAJOR: 'COIN_WEIGHT' as const,
            UPGRADE: 50_000,
            COLLECT: 20_000,
        },
    },

    /** Weights used during fireshot respins to possibly turn non-coin symbols in the slot window into COINs. */
    extraCoinWeights: {
        easy: {
            chance: 1,
            outOf: 11,
        },
        hard: {
            chance: 'COIN_WEIGHT' as const,
            outOf: 136_000,
        },
    },

    /** Weights used to assign prizes to reels during free spins. */
    freeSpinPrizeWeights: {
        1: 14_000,
        2: 14_000,
        3: 14_000,
        4: 14_000,
        5: 14_000,
        10: 14_000,
        15: 7_000,
        20: 2_100,
        MINI: 1_050,
        MINOR: 350,
        MAJOR: 'COIN_WEIGHT' as const,
    },
};
