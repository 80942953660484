var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("\n            Add Step\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v("\n            Free Spin Trigger\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("\n            Free Spin Step\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinTrigger()
              },
            },
          },
          [_vm._v("\n            Respin Trigger\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStep()
              },
            },
          },
          [_vm._v("\n            Respin Step\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinAndFreeSpinTrigger()
              },
            },
          },
          [_vm._v("\n            Respin + FS Trigger\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("\n            Clearing Step\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFeature,
              expression: "!isFeature",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [
            _vm._v(
              "\n            Base Reel Set (must remain unchanged during cascade):\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.baseReelSet,
                  expression: "baseReelSet",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "baseReelSet", id: "baseReelSet" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.baseReelSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeBaseReelSet,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "set1" } }, [_vm._v("set1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "set2" } }, [_vm._v("set2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "set3" } }, [_vm._v("set3")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFeature,
              expression: "!isFeature",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [_vm._v("Type Of Base Spin Step:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.typeOfBaseSpinStep,
                  expression: "typeOfBaseSpinStep",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "typeOfBaseSpinStep", id: "typeOfBaseSpinStep" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.typeOfBaseSpinStep = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeBaseSpinStep,
                ],
              },
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v("Please select one"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "normal" } }, [
                _vm._v("Normal Base Step"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "cascade" } }, [
                _vm._v("Cascade Step (Dummy)"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature && !_vm.isRespin,
              expression: "isFeature && !isRespin",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [_vm._v("Type Of Free Spin Step:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.typeOfFreeSpinStep,
                  expression: "typeOfFreeSpinStep",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "typeOfFreeSpinStep", id: "typeOfFreeSpinStep" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.typeOfFreeSpinStep = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeFreeSpinStep,
                ],
              },
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v("Please select one"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "dummy" } }, [
                _vm._v("Type Selection (Dummy)"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "summary" } }, [
                _vm._v(
                  "\n                Summary For Defined Type (Dummy)\n            "
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "select" } }, [
                _vm._v(
                  "\n                Select Properties For Random Type\n            "
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "normal" } }, [
                _vm._v("FS Normal Step"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "cascade" } }, [
                _vm._v("FS Cascade Step"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin,
              expression: "isRespin",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [_vm._v("Type Of Respin Step:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.typeOfRespinStep,
                  expression: "typeOfRespinStep",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "typeOfRespinStep", id: "typeOfRespinStep" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.typeOfRespinStep = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeRespinStep,
                ],
              },
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v("Please select one"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "round_start" } }, [
                _vm._v("Round Start Step"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "dummy" } }, [
                _vm._v("Symbol Select (Dummy)"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "cascade" } }, [
                _vm._v("Cascade Step (Dummy)"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "wheel" } }, [
                _vm._v("Bonus Wheel Step"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center" }, [
        _c("h3", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.isFeature
                  ? _vm.isTypeOfFreeSpinStepSelected("normal")
                    ? "Free Spin Reels:"
                    : ""
                  : _vm.isTypeOfBaseSpinStepSelected("normal")
                  ? "Base Reels:"
                  : ""
              ) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (!_vm.isFeature &&
                  _vm.isTypeOfBaseSpinStepSelected("normal")) ||
                _vm.isTypeOfFreeSpinStepSelected("normal"),
              expression:
                "\n            (!isFeature && isTypeOfBaseSpinStepSelected('normal')) ||\n            isTypeOfFreeSpinStepSelected('normal')\n        ",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfFreeSpinStepSelected("select"),
              expression: "isTypeOfFreeSpinStepSelected('select')",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c("div", { staticClass: "selected-scatters" }, [
            _c("label", [_vm._v(" Number Of Free Spins: ")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.numberOfFreeSpins,
                    expression: "numberOfFreeSpins",
                    modifiers: { number: true },
                  },
                ],
                attrs: { name: "numberOfFreeSpins", id: "numberOfFreeSpins" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.numberOfFreeSpins = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.updateNumberOfFreeSpins,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "selected-scatters" }, [
            _c("label", [_vm._v(" Initial Global Multiplier: ")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.initialMultiplier,
                    expression: "initialMultiplier",
                    modifiers: { number: true },
                  },
                ],
                attrs: { name: "initialMultiplier", id: "initialMultiplier" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.initialMultiplier = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.updateInitialMultiplier,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "selected-scatters" }, [
            _c("label", [_vm._v(" Multiplier Increase: ")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.increaseMultiplier,
                    expression: "increaseMultiplier",
                    modifiers: { number: true },
                  },
                ],
                attrs: { name: "increaseMultiplier", id: "increaseMultiplier" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.increaseMultiplier = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.updateIncreaseMultiplier,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfRespinStepSelected("round_start"),
              expression: "isTypeOfRespinStepSelected('round_start')",
            },
          ],
          class: ["flex-center"],
        },
        [_c("h3", [_vm._v("Symbols For Initial Respin Slot Window:")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfRespinStepSelected("round_start"),
              expression: "isTypeOfRespinStepSelected('round_start')",
            },
          ],
        },
        [
          _c("p", { staticClass: "text", class: ["flex-center"] }, [
            _vm._v(
              "\n            Only the highest (max 3) symbols selected during the triggering\n            cascade process can be used.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text", class: ["flex-center"] }, [
            _vm._v(
              "\n            All positions should be specified.\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfRespinStepSelected("wheel"),
              expression: "isTypeOfRespinStepSelected('wheel')",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Wheel Win: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectWheelSpinResult", {
                on: {
                  input: function ($event) {
                    return _vm.updateWheelSpinResult(_vm.wheelSpinResult)
                  },
                },
                model: {
                  value: _vm.wheelSpinResult,
                  callback: function ($$v) {
                    _vm.wheelSpinResult = $$v
                  },
                  expression: "wheelSpinResult",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfFreeSpinStepSelected("cascade"),
              expression: "isTypeOfFreeSpinStepSelected('cascade')",
            },
          ],
          class: ["flex-center"],
        },
        [_c("h3", [_vm._v("Symbols For Cascading Free Spin Slot Window:")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTypeOfFreeSpinStepSelected("cascade"),
              expression: "isTypeOfFreeSpinStepSelected('cascade')",
            },
          ],
        },
        [
          _c("p", { staticClass: "text", class: ["flex-center"] }, [
            _vm._v(
              "\n            All of the available symbols can be used.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text", class: ["flex-center"] }, [
            _vm._v(
              "\n            Only the positions that will be empty after the cascade step\n            should be specified.\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.isTypeOfFreeSpinStepSelected("cascade") ||
                _vm.isTypeOfRespinStepSelected("round_start"),
              expression:
                "\n            isTypeOfFreeSpinStepSelected('cascade') ||\n            isTypeOfRespinStepSelected('round_start')\n        ",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _vm._l(5, function (value) {
                return _c("selectFeatureSymbols", {
                  key: value,
                  attrs: { index: value, featureSymbols: _vm.featureSymbols },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectFeatureSymbols", {
                  key: value + 5,
                  attrs: {
                    index: value + 5,
                    featureSymbols: _vm.featureSymbols,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectFeatureSymbols", {
                  key: value + 10,
                  attrs: {
                    index: value + 10,
                    featureSymbols: _vm.featureSymbols,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectFeatureSymbols", {
                  key: value + 15,
                  attrs: {
                    index: value + 15,
                    featureSymbols: _vm.featureSymbols,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectFeatureSymbols", {
                  key: value + 20,
                  attrs: {
                    index: value + 20,
                    featureSymbols: _vm.featureSymbols,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }