import { Type } from "@sinclair/typebox";
import type { Static } from "@sinclair/typebox";
import { CharacterPic } from "../shared";

export const FullReelWilds = Type.Array(Type.Integer(), {
  minItems: 1,
  maxItems: 4,
});
export type FullReelWilds = Static<typeof FullReelWilds>;

export const CharacterWilds = Type.Array(
  Type.Union([
    Type.Literal("PIC1"),
    Type.Literal("PIC2"),
    Type.Literal("PIC3"),
  ]),
  {
    minItems: 1,
    maxItems: 3,
  }
);
export const CharacterWildsWithPositions = Type.Object({
  pics: CharacterWilds,
  positionToWilds: Type.Array(
    Type.Object({
      rowIndex: Type.Integer(),
      reelIndex: Type.Integer(),
      symbol: CharacterPic,
    })
  ),
});
export type CharacterWildsWithPositions = Static<
  typeof CharacterWildsWithPositions
>;

export type CharacterWilds = Static<typeof CharacterWilds>;

export const singleWilds = Type.Array(
  Type.Tuple([
    Type.Integer({ minimum: 0, maximum: 2 }),
    Type.Integer({ minimum: 0, maximum: 4 }),
  ]),
  {
    minItems: 3,
    maxItems: 7,
  }
);

export const multiplierCells = Type.Array(
  Type.Object({
    cell: Type.Tuple([
      Type.Integer({ minimum: 0, maximum: 2 }),
      Type.Integer({ minimum: 0, maximum: 4 }),
    ]),
    multiplier: Type.Integer({ minimum: 2, maximum: 5 }),
  })
);

export const ReelSpinFeatureDelivery = Type.Object({
  fullReelWilds: Type.Optional(FullReelWilds),
  characterWilds: Type.Optional(CharacterWildsWithPositions),
  singleWilds: Type.Optional(singleWilds),
  multiplierCells: Type.Optional(multiplierCells),
  featuresInOrder: Type.Array(
    Type.Union([
      Type.Literal("FRW"),
      Type.Literal("CW"),
      Type.Literal("SW"),
      Type.Literal("M"),
    ])
  ),
});

export type ReelSpinFeatureDelivery = Static<typeof ReelSpinFeatureDelivery>;

export const ReelSpinFeatures = Type.Object({
  beforeReelsStop: Type.Optional(ReelSpinFeatureDelivery),
  afterReelsStop: Type.Optional(
    Type.Object({
      fullReelWilds: Type.Optional(FullReelWilds),
      characterWilds: Type.Optional(CharacterWildsWithPositions),
      singleWilds: Type.Optional(singleWilds),
      multiplierCells: Type.Optional(multiplierCells),
      featuresInOrder: Type.Array(
        Type.Union([
          Type.Literal("FRW"),
          Type.Literal("CW"),
          Type.Literal("SW"),
          Type.Literal("M"),
        ])
      ),
      intermediateResults: Type.Object({
        slotWindow: Type.Array(Type.Array(Type.String())),
        lineWins: Type.Array(
          Type.Object({
            playLineIndex: Type.Integer(),
            symbol: Type.String(),
            length: Type.Number(),
            multiplier: Type.Number(),
            winAmount: Type.Number(),
          })
        ),
        scatterWin: Type.Optional(
          Type.Object({
            cells: Type.Array(Type.Tuple([Type.Integer(), Type.Integer()])),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
          })
        ),
        winAmount: Type.Integer(),
        cumulativeWinAmount: Type.Optional(Type.Number()),
      }),
    })
  ),
});

export type ReelSpinFeatures = Static<typeof ReelSpinFeatures>;
