/* in order to support standalone, please insert the init response from the server here */
export default {
    success: true,
    messages: [],
    modelDefinition: {
        modelId: 'patriot-party',

        gameIds: ['patriot-party'],
        coinAmountMultiplier: 20,
        wildSymbol: 'WD',
        scatterSymbol: 'BO',

        freeSpinTriggerCounts: [8, 12, 15],
        freeSpinRetriggerCount: 2,

        sevens: ['SVN', 'DOU', 'TRI'],
        mixedSevenSymbol: 'MIX',

        playLines: [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],
    
            [1, 2, 2, 2, 1],
            [1, 0, 0, 0, 1],
            [2, 2, 1, 0, 0],
            [0, 0, 1, 2, 2],
            [2, 1, 1, 1, 0],
    
            [0, 1, 1, 1, 2],
            [1, 2, 1, 0, 1],
            [1, 0, 1, 2, 1],
            [0, 1, 0, 1, 0],
            [2, 1, 2, 1, 2],
    
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],
            [0, 2, 0, 2, 0],
            [2, 0, 2, 0, 2],
            [2, 0, 1, 0, 2],
        ],
        bluePlayLines: [
            [2, 2, 2, 2, 2], // 1
            [3, 3, 3, 3, 3],
            [4, 4, 4, 4, 4],
            [5, 5, 5, 5, 5],
            [0, 0, 0, 0, 0],
            [1, 1, 1, 1, 1],
            [0, 1, 2, 1, 0],
            [1, 2, 3, 2, 1],
            [2, 3, 4, 3, 2],
            [3, 4, 5, 4, 3],
            [5, 4, 3, 4, 5], // 11
            [4, 3, 2, 3, 4],
            [3, 2, 1, 2, 3],
            [2, 1, 0, 1, 2],
            [0, 1, 1, 1, 0],
            [1, 2, 2, 2, 1],
            [2, 3, 3, 3, 2],
            [3, 4, 4, 4, 3],
            [4, 5, 5, 5, 4],
            [1, 0, 0, 0, 1],
            [2, 1, 1, 1, 2], // 21
            [3, 2, 2, 2, 3],
            [4, 3, 3, 3, 4],
            [5, 4, 4, 4, 5],
            [0, 1, 1, 1, 2],
            [1, 2, 2, 2, 3],
            [2, 3, 3, 3, 4],
            [3, 4, 4, 4, 5],
            [5, 4, 4, 4, 3],
            [4, 3, 3, 3, 2],
            [3, 2, 2, 2, 1], // 31
            [2, 1, 1, 1, 0],
            [0, 1, 0, 1, 0],
            [1, 2, 1, 2, 1],
            [2, 3, 2, 3, 2],
            [3, 4, 3, 4, 3],
            [4, 5, 4, 5, 4],
            [5, 4, 5, 4, 5],
            [4, 3, 4, 3, 4],
            [3, 2, 3, 2, 3],
            [2, 1, 2, 1, 2], // 41
            [1, 0, 1, 0, 1],
            [0, 0, 1, 2, 2],
            [1, 1, 2, 3, 3],
            [2, 2, 3, 4, 4],
            [3, 3, 4, 5, 5],
            [5, 5, 4, 3, 3],
            [4, 4, 3, 2, 2],
            [3, 3, 2, 1, 1],
            [2, 2, 1, 0, 0],
            [0, 1, 2, 3, 4], // 51
            [1, 2, 3, 4, 5],
            [5, 4, 3, 2, 1],
            [4, 3, 2, 1, 0],
            [0, 0, 1, 1, 2],
            [1, 1, 2, 2, 3],
            [2, 2, 3, 3, 4],
            [3, 3, 4, 4, 5],
            [5, 5, 4, 4, 3],
            [4, 4, 3, 3, 2],
            [3, 3, 2, 2, 1], // 61
            [2, 2, 1, 1, 0],
            [1, 1, 0, 1, 1],
            [2, 2, 1, 2, 2],
            [3, 3, 2, 3, 3],
            [4, 4, 3, 4, 4],
            [5, 5, 4, 5, 5],
            [0, 0, 1, 0, 0],
            [1, 1, 2, 1, 1],
            [2, 2, 3, 2, 2],
            [3, 3, 4, 3, 3], // 71
            [4, 4, 5, 4, 4],
            [0, 2, 2, 2, 0],
            [1, 3, 3, 3, 1],
            [2, 4, 4, 4, 2],
            [3, 5, 5, 5, 3],
            [5, 3, 3, 3, 5],
            [4, 2, 2, 2, 4],
            [3, 1, 1, 1, 3],
            [2, 0, 0, 0, 2],
            [0, 2, 3, 2, 0], // 81
            [1, 3, 4, 3, 1],
            [2, 4, 5, 4, 2],
            [2, 4, 3, 4, 2],
            [5, 3, 2, 3, 5],
            [5, 3, 4, 3, 5],
            [4, 2, 1, 2, 4],
            [4, 2, 3, 2, 4],
            [3, 1, 0, 1, 3],
            [3, 1, 2, 1, 3],
            [0, 0, 1, 0, 1], // 91
            [1, 1, 2, 1, 2],
            [2, 2, 3, 2, 3],
            [3, 3, 4, 3, 4],
            [4, 4, 5, 4, 5],
            [5, 5, 4, 5, 4],
            [4, 4, 3, 4, 3],
            [3, 3, 2, 3, 2],
            [2, 2, 1, 2, 1],
            [1, 1, 0, 1, 0],
        ],

        reelsLayout: [3, 3, 3, 3, 3],
        blueReelsLayout: [6, 6, 6, 6, 6],

        // prettier-ignore
        reels: [
            ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD' ],
            ['PC', 'PD', 'WD', 'PA', 'PB', 'PD', 'PA', 'PB', 'TRI', 'PD', 'PD', 'SVN', 'PA', 'PB', 'PB', 'PA', 'PB', 'DOU', 'PD', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'PB', 'PB', 'PD', 'PD', 'PD', 'TRI', 'PD', 'PB', 'SVN', 'PB', 'PD', 'PD', 'BO', 'BO', 'PA', 'PB', 'PD', 'PA'],
            ['WD', 'PA', 'PB', 'SVN', 'PC', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PC', 'DOU', 'PC', 'PA', 'TRI', 'PC', 'BO', 'BO', 'BO', 'PC', 'PC', 'TRI', 'PC', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PC', 'PC', 'DOU', 'PA', 'PC', 'DOU', 'PC'],
            ['PC', 'TRI', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PC', 'DOU', 'PD', 'PC', 'PD', 'PC', 'TRI', 'PD', 'BO', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'DOU', 'PC', 'PD', 'DOU'],
            ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'SVN', 'PC', 'PD'],
        ],

        // prettier-ignore
        redReels: [
            [ //feature red, 2 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'BO', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red, 4 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'BO', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'BO', 'BO', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red, 6 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'BO', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'BO', 'BO', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'BO', 'BO', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red, 8 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'BO', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'BO', 'BO', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'BO', 'BO', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'BO', 'BO', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red, 10 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'BO', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'BO', 'BO', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'BO', 'BO', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'BO', 'BO', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ]
        ],
    
        // prettier-ignore
        blueReels: [
            ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
            ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
            ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
            ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
            ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
        ],
    
        // prettier-ignore
        redBlueReels: [
            [ //feature red & blue, 2 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'BO', 'BO', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red & blue, 4 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'BO', 'BO', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'BO', 'BO', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red & blue, 6 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'BO', 'BO', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red & blue, 8 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'BO', 'BO', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'BO', 'BO', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ],
            [ //feature red & blue, 10 extra bonus
                ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'BO', 'BO', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'BO', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
                ['PC', 'PD', 'WD', 'PA', 'PB', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'BO', 'BO', 'BO', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'PC', 'PA', 'PC', 'PD'],
                ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'PC', 'PB', 'PD', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'PD', 'PC', 'BO', 'BO', 'BO', 'BO', 'BO', 'BO'],
                ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'PC', 'PD', 'TRI', 'BO', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'BO', 'BO', 'PB', 'PC', 'DOU', 'PD', 'PB', 'PC', 'PD', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'BO', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB', 'PC', 'BO', 'BO', 'PD', 'DOU', 'PB'],
                ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'BO', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'],
            ]
        ],
    },
};
