// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* heading.css */
.heading[data-v-bafaa7dc]{
  color: lightgray;
}

/* slot-window.css */
.slot-window[data-v-bafaa7dc] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5em;
  justify-items: center;
  padding: 1em;
  border-radius: 0.5em;
  background: #707070; 
  padding: 1em;
  box-shadow: 0 4px 8px 0 #707070;
}
.slot-window > div[data-v-bafaa7dc] {
  text-align: center;
  width: 100%;
  aspect-ratio: 1;
  background: #707070; 
  color: lightgray;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;
  box-shadow: inset 0 0 10px #555555; 
  font-size: 0.8em;
}

/* .card.css */
.card-generic-info[data-v-bafaa7dc] {
  background-color: #404040; 
  color: lightgray; 
  padding: 0.5em; 
  border-radius: 0.5em; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  margin: 1em 0;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw092/slot-window/slot-window.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,aAAa;EACb,qCAAqC;EACrC,UAAU;EACV,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,+BAA+B;AACjC;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,gBAAgB;;EAEhB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,oBAAoB;EACpB,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA,cAAc;AACd;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,wCAAwC;EACxC,aAAa;AACf","sourcesContent":["/* heading.css */\n.heading[data-v-bafaa7dc]{\n  color: lightgray;\n}\n\n/* slot-window.css */\n.slot-window[data-v-bafaa7dc] {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 0.5em;\n  justify-items: center;\n  padding: 1em;\n  border-radius: 0.5em;\n  background: #707070; \n  padding: 1em;\n  box-shadow: 0 4px 8px 0 #707070;\n}\n.slot-window > div[data-v-bafaa7dc] {\n  text-align: center;\n  width: 100%;\n  aspect-ratio: 1;\n  background: #707070; \n  color: lightgray;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 0.5em;\n  box-shadow: inset 0 0 10px #555555; \n  font-size: 0.8em;\n}\n\n/* .card.css */\n.card-generic-info[data-v-bafaa7dc] {\n  background-color: #404040; \n  color: lightgray; \n  padding: 0.5em; \n  border-radius: 0.5em; \n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); \n  margin: 1em 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
