import State from '../../../state';
import {modelDefinition} from './model-definition';
import { createClearingStep } from './steps';

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => createClearingStep(),
        getLayout: () => State.state.getModelDefinition().reelsLayout,

        // // unique per model
        getModelDefinition: () => modelDefinition,
        getNumberOfReels: () => 5,
        getNumberOfRows: () => 4,
        getReelStrips: () => modelDefinition.reels,
        getBaseSnowBlastReelStrips: () => modelDefinition.snowBlastReels,
        getFeatureReelStrips: () => modelDefinition.freeSpinReels,
        getBaseStep: (order = 0) => State.state.createDefaultStep(),
    };
}
