import { modelDefinition } from "../../model-definition/index";
import type { GameRequest } from "./game-request";
import type { SpinOutcome } from "./outcome";
import type {
  CoinPrize,
  CoinPrizes,
  FeatureColour,
  GameState,
  GenieBonusFeatures,
  Position,
} from "../shared/index";
import type { Choices, GenieBonusSpinTypeChoices } from "../../choices/choices";
import { applyGenieFeatures } from "./genie-bonus/apply-genie-features";
import type { JackpotWinCounts } from "../shared/genie-bonus";
import { getWinType } from "./get-win-type";
import { GameResponse } from "./game-response";

export interface GenieSpinInputs {
  gameRequest: GameRequest;
  gameState: GameState;
  coinType: "SC" | "GC";
}

export function spinGenie(
  { gameState, coinType, gameRequest }: GenieSpinInputs,
  choices: Choices
): SpinOutcome {
  const {
    reelStripPositions,
    slotWindow,
    genieBonusCount: oldGenieBonusCount,
    genieBonusColours,
    hardPosition,
    coinPrizes,
    coinAmount,
    mysterySymbol,
    cumulativeWinAmount: oldCumulativeWinAmount,
  } = gameState;
  const playAmount =
    gameState.coinAmount * modelDefinition.coinAmountMultiplier;
  const coinWeight = coinAmount * (coinType === "GC" ? 0.05 : 1);
  const availablePositions = getAvailablePositions(coinPrizes);
  const { newCoinFeatureColour, newCoinPrizes } = getCoinPrizes({
    availablePositions,
    choices: choices.genie,
    coinAmount,
    coinWeight,
    existingFeatureColours: genieBonusColours,
    hardPosition,
    existingFeaturesCount: coinPrizes.filter(
      (prize) => prize.type === "FEATURE"
    ).length,
  });
  const oldCoinPrizes = [...coinPrizes];
  coinPrizes.push(...newCoinPrizes);

  applyGenieFeatures({
    genieBonusFeatureColours: newCoinFeatureColour.map((feature) => {
      return {
        position: feature.position,
        colour: feature.colour,
      };
    }),
    coinPrizes,
    choices,
    coinAmount,
    coinWeight,
  });

  const coinPrizesThisSpin = [...coinPrizes].filter(
    (prize) => !oldCoinPrizes.includes(prize)
  );

  let winAmount = 0;

  const isGrandTriggered =
    coinPrizes.length === modelDefinition.numberOfCoinsToTriggerGrand;
  const genieBonusCount = isGrandTriggered
    ? 0
    : coinPrizesThisSpin.length > 0
      ? 3
      : oldGenieBonusCount - 1;
  const genieBonusPhase = genieBonusCount > 0 ? "IN_PROGRESS" : "END";

  const isRoundComplete = genieBonusPhase === "END";

  let jackpotWinCounts: JackpotWinCounts | undefined = undefined;
  if (isRoundComplete) {
    jackpotWinCounts = {
      MAJOR: coinPrizes.filter((prize) => prize.type === "JACKPOT_MAJOR")
        .length,
      GRAND: isGrandTriggered ? 1 : 0,
    };
  }

  if (isRoundComplete) {
    const nonMajorCoinPrizes = coinPrizes.filter(
      (coinPrize) => coinPrize.type !== "JACKPOT_MAJOR"
    );
    winAmount += nonMajorCoinPrizes.reduce((total, coinPrize) => {
      return total + (coinPrize.type !== "JACKPOT_MAJOR" ? coinPrize.value : 0);
    }, 0);
  }

  const winType = getWinType({
    isGrandJackpotTrigger: isGrandTriggered,
    isGenieBonusSpin: true,
    winAmount,
  });

  const cumulativeWinAmount = oldCumulativeWinAmount + winAmount;

  const newGameState: GameState | undefined = !isRoundComplete
    ? {
        coinAmount,
        reelStripPositions,
        slotWindow,
        genieBonusPhase,
        genieBonusCount,
        cumulativeWinAmount,
        coinPrizes,
        genieBonusColours,
        hardPosition,
        mysterySymbol,
      }
    : undefined;

  const gameResponse: GameResponse = {
    playAmount,
    winAmount,
    cumulativeWinAmount,
    winType: winType,
    reelStripPositions,
    slotWindow,
    lineWins: [],
    genieBonusPhase,
    genieBonusCount,
    coinAmount,
    coinPrizes,
    coinPrizesThisSpin,
    jackpotWinCounts,
    mysterySymbol,
  };

  return {
    playSummary: {
      playAmount: 0,
      winAmount,
      roundComplete: isRoundComplete,
      jackpotWinCounts,
    },
    gameResponse: gameResponse,
    gameState: newGameState,
  };
}

const getAvailablePositions = (coinPrizes: CoinPrizes) => {
  const positions: Position[] = new Array(5)
    .fill(undefined)
    .map((_, index) => [
      [0, index] as [number, number],
      [1, index] as [number, number],
      [2, index] as [number, number],
    ])
    .flat();
  return positions.filter(
    (position) =>
      !coinPrizes.find(
        (prize) =>
          prize.position[0] === position[0] && prize.position[1] === position[1]
      )
  );
};

export const getCoinPrizes = ({
  availablePositions,
  coinWeight,
  coinAmount,
  choices,
  existingFeatureColours,
  hardPosition,
  existingFeaturesCount,
}: {
  availablePositions: Position[];
  choices: GenieBonusSpinTypeChoices;
  coinWeight: number;
  coinAmount: number;
  existingFeatureColours: FeatureColour[];
  existingFeaturesCount: number;
  hardPosition: Position;
}): { newCoinPrizes: CoinPrizes; newCoinFeatureColour: GenieBonusFeatures } => {
  const newCoinPrizes: CoinPrizes = [];
  const newCoinFeatureColour: GenieBonusFeatures = [];

  availablePositions.forEach((position) => {
    const existingFeatureCount =
      newCoinPrizes.filter((prize) => prize.type === "FEATURE").length +
      newCoinFeatureColour.length +
      existingFeaturesCount;

    const isHardPosition =
      position[0] === hardPosition[0] && position[1] === hardPosition[1];
    let coinWeights = modelDefinition.coinWeights.easy;

    if (isHardPosition) {
      coinWeights = {
        chance: coinWeight,
        outOf: modelDefinition.coinWeights.hard.outOf,
      };
    }

    if (!choices.chooseDoesCoinLand(coinWeights, position)) return;

    const coinPrizeType = choices.chooseRespinCoinPrize({
      coinWeight,
      position,
      existingFeatureCount,
    });
    let coinPrize: CoinPrize | undefined = undefined;

    if (typeof coinPrizeType === "number") {
      const winAmount = coinPrizeType * coinAmount;
      coinPrize = {
        type: "COIN",
        value: winAmount,
        position,
      };
      newCoinPrizes.push(coinPrize);
    } else if (
      coinPrizeType === "JACKPOT_MINI" ||
      coinPrizeType === "JACKPOT_MINOR"
    ) {
      const fixedJackpotKey =
        coinPrizeType === "JACKPOT_MINI" ? "MINI" : "MINOR";
      const winAmount =
        modelDefinition.jackpots.fixedJackpots[fixedJackpotKey] * coinAmount;
      coinPrize = {
        type: coinPrizeType,
        value: winAmount,
        position,
      };
      newCoinPrizes.push(coinPrize);
    } else if (coinPrizeType === "FEATURE") {
      const colour = choices.chooseRespinFeatureColour(
        existingFeatureColours,
        position
      );

      newCoinFeatureColour.push({
        position,
        colour,
      });
    } else {
      coinPrize = {
        type: coinPrizeType,
        position,
      };
      newCoinPrizes.push(coinPrize);
    }
  });
  return { newCoinPrizes, newCoinFeatureColour };
};
