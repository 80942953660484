import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import TopReel from "./reels/topreel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";
import WheelResult from "./components/wheelResult.vue";

@Component({
	components: {
		reel: Reel,
		topreel: TopReel,
		selectScatterMultiplier: SelectScatterMultiplier,
		wheelResult: WheelResult,
	},
})
export default class Content extends Vue {
	@Prop() public step: any;
	public isFeature = false;
	public show = false;
	public reelStripPositions = [];
	public reel = "";
	public isOpen = false;
	public topReelFirstSymbol = "NONE";
	public topReelSecondSymbol = "NONE";
	public topReelThirdSymbol = "NONE";
	public topReelForthSymbol = "NONE";
	public topReelReelStripPosition = "0";
	public reelsNo = 6;
	public scattersNumber = 28;
	public scattersSelectedMultipliers = new Array(28).fill("Random");
	public wheelResults = new Array();

	public maxScatterIndex = 0;
	public maxScatterValues = ["Random", "On", "Off"];

	constructor() {
		super();
	}

	@Watch("step")
	public stepWatch(step) {
		if (!step) {
			this.show = false;
			return;
		}

		this.isOpen = false;
		this.show = true;
		this.isFeature = step.json.isFeature ? step.json.isFeature : false;
	}

	public toggleMaxScatterOnRetrigger() {
		this.maxScatterIndex++;
		if (this.maxScatterIndex == this.maxScatterValues.length) {
			this.maxScatterIndex = 0;
		}

		this.step.json.maxScatter = this.maxScatterValues[this.maxScatterIndex];
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public toggleFeature() {
		this.isFeature = !this.isFeature;
		this.step.json.isFeature = this.isFeature;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public mounted() {
		this.isOpen = false;
		trace("reels mounted");
	}

	public addBaseStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getBaseStep());
	}

	public addFeatureTriggerStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame());
	}

	public addRespinStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getRespinStep());
	}

	public updateTopReelReelStripPosition(value) {
		this.step.json.topReelReelStripPosition = value;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateTopReelSymbol(index, value) {
		switch (index) {
			case 1:
				this.step.json.topReelFirstSymbol = value;
				break;
			case 2:
				this.step.json.topReelSecondSymbol = value;
				break;
			case 3:
				this.step.json.topReelThirdSymbol = value;
				break;
			case 4:
				this.step.json.topReelForthSymbol = value;
				break;
		}
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateTopReelSymbols() {
		if (this.step.json.topReelFirstSymbol != null) {
			this.topReelFirstSymbol = this.step.json.topReelFirstSymbol;
		} else {
			this.topReelFirstSymbol = "-1";
		}

		if (this.step.json.topReelSecondSymbol != null) {
			this.topReelSecondSymbol = this.step.json.topReelSecondSymbol;
		} else {
			this.topReelSecondSymbol = "-1";
		}

		if (this.step.json.topReelThirdSymbol != null) {
			this.topReelThirdSymbol = this.step.json.topReelThirdSymbol;
		} else {
			this.topReelThirdSymbol = "-1";
		}

		if (this.step.json.topReelForthSymbol != null) {
			this.topReelForthSymbol = this.step.json.topReelForthSymbol;
		} else {
			this.topReelForthSymbol = "-1";
		}
	}

	public updateTopReelReelStripPositionFromJson() {
		if (this.step.json.topReelReelStripPosition != null) {
			this.topReelReelStripPosition = this.step.json.topReelReelStripPosition;
		} else {
			this.topReelReelStripPosition = "0";
		}
	}

	public addFullRandom() {
		this.step.json.scattersSelectedMultipliers = new Array(this.scattersNumber).fill("Random");
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public addFullNoScatter() {
		this.step.json.scattersSelectedMultipliers = new Array(this.scattersNumber).fill("NoScatter");
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateRespinScattersMultipliers(event) {
		if (this.step.json.scattersSelectedMultipliers == null) {
			this.step.json.scattersSelectedMultipliers = new Array(this.scattersNumber).fill("Random");
		}
		this.step.json.scattersSelectedMultipliers[event.target.id - 1] = event.target.value;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateBonusTriggerSelectors() {
		if (this.step.json.wheelResults != null) {
			this.wheelResults = this.step.json.wheelResults;
		} else {
			this.wheelResults = new Array();
		}

		if (this.step.json.scattersSelectedMultipliers != null) {
			this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
		} else {
			this.scattersSelectedMultipliers = new Array(this.scattersNumber).fill("Random");
		}
	}

	public updatewheelResultCounter(direction) {
		const wheelResultsNum = this.wheelResults.length;
		if (direction) {
			this.step.json.wheelResults[wheelResultsNum] = "win";
			this.wheelResults[wheelResultsNum] = "win";
		} else {
			if (wheelResultsNum > 0) {
				this.wheelResults.pop();
				this.step.json.wheelResults = [];
				this.wheelResults.forEach((element) => {
					this.step.json.wheelResults.push(element);
				});
			} else {
				alert("no more to delete");
			}
		}
		this.$forceUpdate();
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public updateWheelResult(index, event) {
		this.step.json.wheelResults[index] = event.target.value;
		this.wheelResults[index] = event.target.value;
		bus.$emit(events.UPDATE_STEPS_DB, this.step);
	}

	public addClearingStep() {
		const state = State.state;
		bus.$emit(events.ADD_STEP, state.getClearingStep());
	}
}
