var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("FS Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFreeSpin,
              expression: "isFreeSpin",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("Reel Set:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedIndex,
                  expression: "selectedIndex",
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "reelSetIndex", id: "reelSetIndex" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedIndex = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeReelSet,
                ],
              },
            },
            _vm._l(_vm.reelSets, function (option, index) {
              return _c("option", { key: index, domProps: { value: index } }, [
                _vm._v(
                  "\n                " + _vm._s(option.label) + "\n            "
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: {
              index: reelIndex - 1,
              step: _vm.step,
              reelSetIndex: _vm.selectedReelSetIndex,
              scatters: _vm.selectedScatters,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center scatters-selector-wrapper" }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("RED trigger:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isRedTrigger,
                expression: "isRedTrigger",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "isRedTrigger", id: "isRedTrigger" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.isRedTrigger = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeTrigger,
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "" }, domProps: { value: true } },
              [_vm._v("true")]
            ),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("false")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("WHITE trigger:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isWhiteTrigger,
                expression: "isWhiteTrigger",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "isWhiteTrigger", id: "isWhiteTrigger" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.isWhiteTrigger = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeTrigger,
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "" }, domProps: { value: true } },
              [_vm._v("true")]
            ),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("false")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("BLUE trigger:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isBlueTrigger,
                expression: "isBlueTrigger",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "isBlueTrigger", id: "isBlueTrigger" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.isBlueTrigger = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onChangeTrigger,
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "" }, domProps: { value: true } },
              [_vm._v("true")]
            ),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("false")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRedTrigger,
              expression: "isRedTrigger",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c("br"),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
            [_vm._v("extra bonus:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.triggerScatters,
                  expression: "triggerScatters",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "triggerScatters", id: "triggerScatters" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.triggerScatters = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeTriggerScatters,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "" } }, [
                _vm._v("0"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }