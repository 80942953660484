import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: ["value"],
})
export default class SelectRespinSymbol extends Vue {
    constructor() {
        super();
    }

    public updateRespinSymbol(event) {
        this.$emit('input', event.target.value);
    }
}
