import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Vue from "vue";
import { GameState, model } from "./model/src/model";
import { modelDefinition } from "./model/src/model-definition";
import { createChoicesFromScenario } from "./model/src/choices";
import {
  originatorId,
  StepJson,
} from "../../../../state/models/slots/quest-of-the-3-lamps";
import { SlotWindow } from "./slot-window";
import { LineWin } from "@vgw/gdk-math-model-tools";
import {
  FeatureColour,
  GenieBonusLamp,
  GenieBonusLamps,
  Position,
} from "./model/src/operations/shared";
import { CoinPrizesScenario } from "./model/src/choices/create-choices-from-scenario";
import { matchPosition } from "./slot-window/slot-window.vue";
import { MultiSelect } from "./components";
import { identifyTriggersAndNonTriggers } from "./model/src/operations/play/spin-base";
import { SpinOutcome } from "./model/src/operations/play/outcome";

const SCROLL_THRESHOLD = 20;
let scrollCount = 0;

type GreenPrizes = (
  | {
      type: "COIN";
      prize: number;
    }
  | {
      type: "JACKPOT_MINI" | "JACKPOT_MINOR";
    }
)[];

type PrizeType =
  | number
  | "JACKPOT_MINI"
  | "JACKPOT_MINOR"
  | "JACKPOT_MAJOR"
  | "G"
  | "P"
  | "R";

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
    MultiSelect,
  },
  data(): {
    scenario: InternalScenario;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        reelStripPositions: [
          { value: 0, min: 0, max: 0 },
          { value: 0, min: 0, max: 0 },
          { value: 0, min: 0, max: 0 },
          { value: 0, min: 0, max: 0 },
          { value: 0, min: 0, max: 0 },
        ],
        mysterySymbol: "K",
      },
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome() {
      const convertedStepJSON = convertToStepJson(this.scenario);

      const fullScenario = {
        ...convertedStepJSON,
        reelStripPositions: this.scenario.reelStripPositions.map(
          ({ value }) => value
        ),
      };
      const choices = createChoicesFromScenario(fullScenario);

      const spinOutput = model.play(
        {
          coinType: "SC",
          gameRequest: { coinAmount: 1, spinId: this.currentStepIndex },
          gameState: this.currentStep?.gameState
            ? {
                ...this.currentStep?.gameState,
                coinPrizes: (this.currentStep?.gameState.coinPrizes ?? []).map(
                  (c) => {
                    return { ...c };
                  }
                ),
              }
            : undefined,
        },
        choices
      );

      const spinOutcomeWithWinCells = {
        ...spinOutput,
        winCells: getWinCells({
          lineWins: spinOutput.gameResponse.lineWins,
          playLines: model.definition.base.playLines,
        }),
      };

      return spinOutcomeWithWinCells;
    },
    currentStep(): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    currentStepIndex(): number | undefined {
      if (!this.step || !this.allSteps) {
        return;
      }

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
  },
  methods: {
    getGreenPrizes(): string[] {
      const coinPrizes = model.definition.genieGreenFeatureCoinPrizeWeights.map(
        (prize) => String(prize.outcome)
      );
      coinPrizes.push("MINI", "MINOR");
      return coinPrizes;
    },
    getGreenPrizeForCell(reelIndex: number): string[] {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;

      const greenFeature = this.currentStep?.coinPrizeCumulative?.find(
        (c) => c.position[0] === row && c.position[1] === col
      );
      if (
        !(
          greenFeature &&
          greenFeature.type === "FEATURE" &&
          greenFeature.colour === "G"
        )
      )
        return [];
      return greenFeature.prizes.map((prize) => {
        if (prize.type === "JACKPOT_MINOR") return "MINOR";
        if (prize.type === "COIN") return prize.prize.toString();
        return "MINI";
      });
    },
    handleGreenPrizeSelection(
      prizes: (number | "MINI" | "MINOR")[],
      reelIndex: number
    ) {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;

      const greenFeature = this.scenario.coinPrizesThisSpin?.find(
        (c) =>
          c.position[0] === row &&
          c.position[1] === col &&
          c.type === "FEATURE" &&
          c.colour === "G"
      );

      if (
        !(
          greenFeature &&
          greenFeature.type === "FEATURE" &&
          greenFeature.colour === "G"
        )
      )
        return;

      const greenPrizes: GreenPrizes = prizes.map((value) => {
        if (value === "MINI") return { type: "JACKPOT_MINI" };
        if (value === "MINOR") return { type: "JACKPOT_MINOR" };

        return { type: "COIN", prize: Number(value) };
      });

      greenFeature.prizes = greenPrizes;
    },
    getPurplePrizes() {
      return modelDefinition.coinPrizes.COIN_ONLY.map((c) => c.outcome);
    },
    isPurpleCoinPrizeSelected(
      reelIndex: number,
      prize: number | "JACKPOT_MINI" | "JACKPOT_MINOR"
    ) {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;
      const prizeFormatted =
        prize === "JACKPOT_MINI" || prize === "JACKPOT_MINOR"
          ? prize
          : prize.toString();
      const purpleFeature = (this.currentStep?.coinPrizeCumulative ?? []).find(
        (c) =>
          c.position[0] === row &&
          c.position[1] === col &&
          c.type === "FEATURE" &&
          c.colour === "P" &&
          c.prize === prizeFormatted
      );
      return purpleFeature !== undefined;
    },
    handlePurpleCoinPrizeSelected(
      reelIndex: number,
      prize: number | "JACKPOT_MINI" | "JACKPOT_MINOR"
    ) {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;

      const purpleFeatureIndex = (
        this.scenario.coinPrizesThisSpin ?? []
      ).findIndex(
        (c) =>
          c.position[0] === row &&
          c.position[1] === col &&
          c.type === "FEATURE" &&
          c.colour === "P"
      );

      if (!this.scenario.coinPrizesThisSpin) return;

      this.$set(this.scenario.coinPrizesThisSpin, purpleFeatureIndex, {
        ...this.scenario.coinPrizesThisSpin[purpleFeatureIndex],
        prize: prize,
      });
    },
    addClearingStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Clear Step",
        originatorId,
        gameState: "clear",
        reelStripPositions: [11, 18, 40, 36, 18],
      });
    },
    addStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [11, 18, 40, 36, 18],
      });
    },
    addGenieBonusTriggerStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Genie Bonus Trigger",
        originatorId,
        reelStripPositions: [31, 5, 0, 8, 3],
      });
    },
    addJackpotTriggerStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Jackpot Win",
        originatorId,
        reelStripPositions: [44, 40, 22, 23, 26],
      });
    },
    getMysterySymbols(): string[] {
      return modelDefinition.mysterySymbolWeights.map((value) => value.outcome);
    },
    reloadStepsFromScenario(scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) {
        return;
      }
      // For each step in the reloaded scenario, if the step"s most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) {
        bus.$emit(events.EDIT_STEPS, this.allSteps);
      }
    },
    handleMysterySymbolSelect(mysterSymbol: string) {
      this.$set(this.scenario, "mysterySymbol", mysterSymbol);
      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    handleTriggeringColourSelect(isChecked: boolean, colour: FeatureColour) {
      const indexOfTriggeringColour =
        this.scenario.triggeringColours?.findIndex((c) => c === colour) ?? -1;

      if (!isChecked) {
        if (indexOfTriggeringColour > -1)
          this.scenario.triggeringColours?.splice(indexOfTriggeringColour, 1);
      } else {
        if (!this.scenario.triggeringColours) {
          this.$set(this.scenario, "triggeringColours", []);
        }

        this.scenario.triggeringColours?.push(colour);
      }

      const triggeringLampPositions = this.getTriggeringLampPositions();
      this.addDefaultCoinPrizes(triggeringLampPositions);
    },
    addDefaultCoinPrizes(
      triggeringLamps: { position: Position; colour: FeatureColour }[]
    ): void {
      this.$set(this.scenario, "coinPrizesThisSpin", []);

      for (const triggeringLamp of triggeringLamps) {
        if (triggeringLamp.colour === "G") {
          if (!this.scenario.coinPrizesThisSpin)
            this.$set(this.scenario, "coinPrizesThisSpin", []);

          this.scenario.coinPrizesThisSpin?.push({
            type: "FEATURE",
            colour: "G",
            position: triggeringLamp.position,
            prizes: [{ type: "COIN", prize: 5 }],
          });
        } else if (triggeringLamp.colour === "P") {
          if (!this.scenario.coinPrizesThisSpin)
            this.$set(this.scenario, "coinPrizesThisSpin", []);
          this.scenario.coinPrizesThisSpin?.push({
            type: "FEATURE",
            colour: "P",
            position: triggeringLamp.position,
            prize: 5,
          });
        } else if (triggeringLamp.colour === "R") {
          if (!this.scenario.coinPrizesThisSpin)
            this.$set(this.scenario, "coinPrizesThisSpin", []);

          this.scenario.coinPrizesThisSpin?.push({
            type: "FEATURE",
            colour: "R",
            position: triggeringLamp.position,
          });
        }
      }
    },
    shouldTriggerOptionsDisplayed(): boolean {
      if (!this.scenario.genieBonusLamps) return false;
      return (
        this.scenario.genieBonusLamps &&
        this.scenario.genieBonusLamps.length > 0
      );
    },
    shouldCoinPrizeSelectionDisplayed(): boolean {
      if (this.currentStepIndex === undefined) return false;

      if (this.getTriggeringLampPositions().length > 0) return true;

      const geniePhase = this.currentStep?.gameState?.genieBonusPhase;

      if (geniePhase) return true;

      const genieBonusCount = this.spinOutcome.gameResponse.genieBonusCount;
      if (genieBonusCount && genieBonusCount >= 0) return true;

      return false;
    },
    handleGenieBonusColourUpdate(lamp: GenieBonusLamp, colour: FeatureColour) {
      const lamps = (this.scenario.genieBonusLamps ??= []);
      const lampToUpdate = this.scenario.genieBonusLamps.find((existingLamp) =>
        matchPosition(existingLamp.position, lamp.position)
      );

      if (!lampToUpdate) {
        return;
      }

      lampToUpdate.colour = colour;

      this.$set(this.scenario, "genieBonusLamps", lamps);

      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    isCoinPrizeSelected(reelIndex: number, prize: number | string): boolean {
      if (this.currentStepIndex === undefined) return false;
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;

      const cumulativeCoinPrizes = this.currentStep?.coinPrizeCumulative;
      let coinPrizeCumulative = (cumulativeCoinPrizes ?? []).filter(
        (prize) => prize.position[0] === row && prize.position[1] === col
      );

      if (typeof prize === "number") {
        coinPrizeCumulative = coinPrizeCumulative.filter(
          (coinPrize) => coinPrize.type === "COIN" && coinPrize.prize === prize
        );
      } else if (
        prize === "JACKPOT_MINI" ||
        prize === "JACKPOT_MINOR" ||
        prize === "JACKPOT_MAJOR"
      ) {
        coinPrizeCumulative = coinPrizeCumulative.filter(
          (coinPrize) => coinPrize.type === prize
        );
      } else if (prize === "G" || prize === "P" || prize === "R") {
        coinPrizeCumulative = coinPrizeCumulative.filter(
          (coinPrize) =>
            coinPrize.type === "FEATURE" && coinPrize.colour === prize
        );
      }
      if (coinPrizeCumulative.length > 0)
        if (coinPrizeCumulative && coinPrizeCumulative.length > 0) return true;
      return false;
    },
    isEditable(cellIndex: number): boolean {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);

      const isCoinInCurrentSpin =
        this.scenario.coinPrizesThisSpin?.find(
          (c) => c.position[0] === rowIndex && c.position[1] === reelIndex
        ) !== undefined;

      const isCoinInCumulative =
        this.currentStep?.coinPrizeCumulative?.find(
          (c) => c.position[0] === rowIndex && c.position[1] === reelIndex
        ) !== undefined;
      const isTriggeringLamp =
        this.getTriggeringLampPositions().find(
          (c) => c.position[0] === rowIndex && c.position[1] === reelIndex
        ) !== undefined;

      if (isTriggeringLamp) return false;

      if (
        this.isGenieBonusTrigger() &&
        !isCoinInCurrentSpin &&
        this.getNonFeatureCoinCount() >= 5
      )
        return false;

      if (!isCoinInCumulative) return true; // empty cell
      if (isCoinInCurrentSpin) return true; // new coin in current cell

      return false;
    },
    isPrizeEditable(cellIndex: number): boolean {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);

      const isCoinInCurrentSpin =
        this.scenario.coinPrizesThisSpin?.find(
          (c) => c.position[0] === rowIndex && c.position[1] === reelIndex
        ) !== undefined;

      const isCoinInCumulative =
        this.currentStep?.coinPrizeCumulative?.find(
          (c) => c.position[0] === rowIndex && c.position[1] === reelIndex
        ) !== undefined;

      if (!isCoinInCumulative) return true; // empty cell
      if (isCoinInCurrentSpin) return true; // new coin in current cell

      return false;
    },
    handleCoinPrizeSelected(
      cellIndex: number,
      prize:
        | string
        | number
        | "JACKPOT_MINI"
        | "JACKPOT_MINOR"
        | "JACKPOT_MAJOR"
        | "G"
        | "P"
        | "R"
    ): void {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);

      if (
        prize !== "JACKPOT_MINI" &&
        prize !== "JACKPOT_MAJOR" &&
        prize !== "JACKPOT_MINOR" &&
        prize !== "G" &&
        prize != "P" &&
        prize !== "R"
      ) {
        prize = Number(prize);
      }

      if (prize !== -1) {
        const coinPrizeIndex = (
          this.scenario.coinPrizesThisSpin ?? []
        ).findIndex(
          (coinPrize) =>
            coinPrize.position[0] === rowIndex &&
            coinPrize.position[1] === reelIndex
        );

        if (coinPrizeIndex !== -1) {
          if (!this.scenario.coinPrizesThisSpin) return;

          if (typeof prize === "number") {
            this.$set(this.scenario.coinPrizesThisSpin, coinPrizeIndex, {
              type: "COIN",
              position: [rowIndex, reelIndex],
              prize,
            });
          } else if (
            prize === "JACKPOT_MINI" ||
            prize === "JACKPOT_MINOR" ||
            prize === "JACKPOT_MAJOR"
          ) {
            this.$set(this.scenario.coinPrizesThisSpin, coinPrizeIndex, {
              type: prize,
              position: [rowIndex, reelIndex],
            });
          } else if (prize === "G") {
            this.$set(this.scenario.coinPrizesThisSpin, coinPrizeIndex, {
              type: "FEATURE",
              colour: "G",
              prizes: [{ type: "COIN", prize: 5 }],
              position: [rowIndex, reelIndex],
            });
          } else if (prize === "P") {
            this.$set(this.scenario.coinPrizesThisSpin, coinPrizeIndex, {
              type: "FEATURE",
              colour: "P",
              prize: 10,
              position: [rowIndex, reelIndex],
            });
          } else if (prize === "R") {
            this.$set(this.scenario.coinPrizesThisSpin, coinPrizeIndex, {
              type: "FEATURE",
              colour: "R",
              position: [rowIndex, reelIndex],
            });
          }
        } else {
          if (!this.scenario.coinPrizesThisSpin) {
            this.$set(this.scenario, "coinPrizesThisSpin", []);
          }

          if (typeof prize === "number") {
            this.scenario.coinPrizesThisSpin?.push({
              type: "COIN",
              position: [rowIndex, reelIndex],
              prize,
            });
          } else if (
            prize === "JACKPOT_MINI" ||
            prize === "JACKPOT_MINOR" ||
            prize === "JACKPOT_MAJOR"
          ) {
            this.scenario.coinPrizesThisSpin?.push({
              type: prize,
              position: [rowIndex, reelIndex],
            });
          } else if (prize === "G") {
            this.scenario.coinPrizesThisSpin?.push({
              type: "FEATURE",
              colour: "G",
              position: [rowIndex, reelIndex],
              prizes: [{ type: "COIN", prize: 5 }],
            });
          } else if (prize === "P") {
            this.scenario.coinPrizesThisSpin?.push({
              type: "FEATURE",
              colour: "P",
              position: [rowIndex, reelIndex],
              prize: 5,
            });
          } else if (prize === "R") {
            this.scenario.coinPrizesThisSpin?.push({
              type: "FEATURE",
              colour: "R",
              position: [rowIndex, reelIndex],
            });
          }
        }
      } else {
        if (this.scenario.coinPrizesThisSpin !== undefined) {
          const coinPrizeIndex = this.scenario.coinPrizesThisSpin.findIndex(
            (coinPrize) =>
              coinPrize.position[0] === rowIndex &&
              coinPrize.position[1] === reelIndex
          );
          this.scenario.coinPrizesThisSpin?.splice(coinPrizeIndex, 1);
        }
      }
      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    getApplicableCoinPrizes(reelIndex: number): PrizeType[] {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;
      const options: PrizeType[] = [
        5,
        10,
        20,
        40,
        60,
        80,
        100,
        200,
        400,
        "JACKPOT_MINI" as const,
        "JACKPOT_MINOR" as const,
        "JACKPOT_MAJOR" as const,
      ];

      const triggeringLamps = this.getTriggeringLampPositions();
      const isTrigger = triggeringLamps.length > 0;

      const isCurrentCellTriggeringLamp =
        this.getTriggeringLampPositions().find(
          (c) => c.position[0] === row && c.position[1] === col
        ) !== undefined;

      const currentCellInCumulativePrizes = (
        this.currentStep?.coinPrizeCumulative ?? []
      ).find((c) => c.position[0] === row && c.position[1] === col);

      if (isCurrentCellTriggeringLamp) {
        options.push("G", "P", "R");
      } else if (currentCellInCumulativePrizes !== undefined) {
        const featureColour =
          currentCellInCumulativePrizes.type === "FEATURE" &&
          currentCellInCumulativePrizes.colour;
        if (featureColour) options.push(featureColour);
      } else if (!isTrigger) {
        const genieBonusColours =
          this.currentStep?.gameState?.genieBonusColours;

        if (this.getCurrentFeatureCount() < modelDefinition.maxRespinFeatures) {
          options.push(...(genieBonusColours ?? []));
        }
      }

      return options;
    },
    getCurrentFeatureCount(): number {
      const features =
        this.currentStep?.coinPrizeCumulative?.filter(
          (c) => c.type === "FEATURE"
        ) ?? [];

      return features?.length;
    },
    getNonFeatureCoinCount(): number {
      const nonFeatures =
        this.currentStep?.coinPrizeCumulative?.filter(
          (c) => c.type !== "FEATURE"
        ) ?? [];

      return nonFeatures?.length;
    },
    isGenieBonusTrigger(): boolean {
      return (this.scenario.genieBonusLamps ?? []).length > 0;
    },
    getTriggeringLampPositions():
      | { position: Position; colour: FeatureColour }[] {
      const genieBonusLamps = [...(this.scenario.genieBonusLamps ?? [])];
      if (genieBonusLamps.length === 0) return [];

      const triggeringColours = this.scenario.triggeringColours;
      if (!triggeringColours) return [];

      const results = identifyTriggersAndNonTriggers(
        genieBonusLamps,
        triggeringColours
      );

      return results.filter((l) => l.isTrigger);
    },
    getFeatureColour(reelIndex: number): FeatureColour | undefined {
      const col = Math.floor(reelIndex / 3);
      const row = reelIndex % 3;
      const coinPrizes = this.currentStep?.coinPrizeCumulative ?? [];
      const coinPrize = coinPrizes.find(
        (c) => c.position[0] === row && c.position[1] === col
      );

      if (coinPrize?.type !== "FEATURE") return undefined;
      return coinPrize.colour;
    },
    onScroll(event, positionData) {
      const delta = Math.sign(event.deltaY);
      scrollCount += Math.abs(delta);

      if (scrollCount > SCROLL_THRESHOLD) {
        scrollCount = 0;
        positionData.value -= delta;
      }

      if (positionData.value < 0) {
        positionData.value = positionData.max;
      } else if (positionData.value > positionData.max) {
        positionData.value = 0;
      }
    },
  },
  watch: {
    scenario: {
      handler(newScenario) {
        if (this.currentStepIndex === undefined || !this.currentStep) {
          return;
        }

        const slotWindow = this.spinOutcome.gameResponse.slotWindow;

        const scatters = getScatterPositions(slotWindow);

        const newLamps =
          (newScenario.genieBonusLamps ?? []).length > 0
            ? updateLampColourPosition(scatters, newScenario.genieBonusLamps)
            : undefined;
        const convertedStepJSON = convertToStepJson(newScenario, newLamps);

        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertedStepJSON,
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );
        bus.$emit(events.EDIT_STEPS, this.allSteps);

        const modified = updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler(newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function convertStepToInternalScenario(step: Step): InternalScenario {
  const reelStripPositions = step.json.reelStripPositions.map(
    (value, index) => {
      return {
        value,
        min: 0,
        max: modelDefinition.reels[index].length - 1,
      };
    }
  );

  return {
    ...step.json,
    mysterySymbol: step.json.mysterySymbol,

    genieBonusLamps: step.json.genieBonusLamps,
    reelStripPositions,
    coinPrizesThisSpin: step.json.coinPrizes,
    triggeringColours: step.json.triggeringColours,
  };
}

function convertToStepJson(
  scenario: InternalScenario,
  newLamps?: GenieBonusLamps
): Partial<StepJson> {
  const genieBonusLamps = newLamps ?? scenario.genieBonusLamps;

  const reelStripPositions = scenario.reelStripPositions.map((position) => {
    return position.value;
  });

  return {
    gameState: scenario.gameState,
    reelStripPositions,
    mysterySymbol: scenario.mysterySymbol,
    genieBonusLamps: genieBonusLamps,
    coinPrizes: scenario.coinPrizesThisSpin,
    triggeringColours: scenario.triggeringColours,
  };
}

function getScatterPositions(
  slotWindow: string[][]
): Array<{ position: [number, number] }> {
  const positions: Array<{ position: [number, number] }> = [];
  slotWindow.forEach((reel, reelIndex) =>
    reel.forEach((symbolName, symbolIndex) => {
      if (symbolName === "SCAT") {
        positions.push({ position: [symbolIndex, reelIndex] });
      }
    })
  );
  return positions;
}

function convertScattersToLamps(
  scatters: Array<{ position: [number, number] }>,
  lamps?: GenieBonusLamps
): GenieBonusLamps {
  return scatters.map((scatter) => {
    const existingLamp = lamps?.find((lamp) =>
      matchPosition(lamp.position, scatter.position)
    );
    return {
      position: existingLamp?.position ?? scatter.position,
      colour: existingLamp?.colour ?? "G",
    };
  });
}

export function getGenieBonusLamps(
  slotWindow: string[][],
  lamps?: GenieBonusLamps
) {
  const scatters = getScatterPositions(slotWindow);
  if (scatters.length === 0) {
    lamps = [];
  }

  return convertScattersToLamps(scatters, lamps);
}

function updateLampColourPosition(
  scatters: Array<{ position: [number, number] }>,
  lamps: GenieBonusLamps
) {
  const newLamps: GenieBonusLamps = [];
  lamps.forEach((lamp) => {
    const scatOnReel = scatters.find(
      (scatter) => lamp.position[1] === scatter.position[1]
    );
    if (scatOnReel) {
      if (scatOnReel.position[0] !== lamp.position[0]) {
        newLamps.push({
          colour: lamp.colour,
          position: (lamp.position = [
            scatOnReel.position[0],
            lamp.position[1],
          ]),
        });
      } else {
        newLamps.push({
          colour: lamp.colour,
          position: lamp.position,
        });
      }
    }
  });
  return newLamps;
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined;
  const modified = false;

  steps[0].gameState = undefined;

  let cumulativePrizes: CoinPrizesScenario = [];

  for (const [index, step] of steps.entries()) {
    if (step.json.gameState === "clear") {
      gameState = undefined;
    }

    step.gameState =
      gameState !== undefined
        ? JSON.parse(JSON.stringify(gameState))
        : gameState;

    if (!gameState) {
      cumulativePrizes = [];
    }
    if (step.json.coinPrizes && step.json.coinPrizes.length > 0) {
      cumulativePrizes.push(...step.json.coinPrizes);
    }

    step.coinPrizeCumulative = cumulativePrizes.map((c) => {
      return { ...c };
    });

    const stepJSON: StepJson = {
      ...step.json,
    };

    const spinOutput = model.play(
      {
        coinType: "SC",
        gameRequest: { coinAmount: 1 },
        gameState,
      },
      createChoicesFromScenario(stepJSON)
    );

    const slotWindow = spinOutput.gameResponse.slotWindow;
    const lamps = step.json.genieBonusLamps;

    gameState = spinOutput.gameState;
    const genieBonusPhase = spinOutput.gameResponse.genieBonusPhase;
    step.json.genieBonusLamps =
      !genieBonusPhase || genieBonusPhase === "START"
        ? getGenieBonusLamps(slotWindow, lamps)
        : undefined;

    if (spinOutput.gameResponse.genieBonusPhase === "END")
      cumulativePrizes = [];

    step.json.name = getStepName(spinOutput);
  }

  return modified;
}

function getStepName(spinOutput: SpinOutcome) {
  const { gameResponse } = spinOutput;

  if (gameResponse.genieBonusPhase === "START") return "Genie trigger";

  if (gameResponse.genieBonusPhase && gameResponse.genieBonusPhase !== "END")
    return "Genie spin";

  if (gameResponse.winType === "JACKPOT_WIN") return "Grand jackpot";
  if (gameResponse.genieBonusPhase) return "Genie end";
  return "base";
}

function getWinCells({
  lineWins,
  playLines,
}: {
  lineWins: LineWin[];
  playLines: number[][];
}) {
  const cells: Array<[number, number]> = [];
  for (const lineWin of lineWins) {
    const playLineIndex = lineWin.playLineIndex;
    const playLine = playLines[playLineIndex];

    for (const [reelIndex, rowIndex] of playLine.entries()) {
      cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
      if (reelIndex < lineWin.length) {
        cells[reelIndex][rowIndex] = 1;
      }
    }
  }
  return cells;
}

interface Step {
  coinPrizeCumulative?: CoinPrizesScenario;
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

export interface InternalScenario {
  triggeringColours?: FeatureColour[];
  coinPrizesThisSpin?: CoinPrizesScenario;
  reelStripPositions: Array<{ value: number; min: number; max: number }>;
  gameState?: "continue" | "clear";
  mysterySymbol?: string;
  genieBonusLamps?: GenieBonusLamps;
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;
