import * as io from "socket.io-client";
import { trace } from "../helpers/helpers";
import bus from "../common/bus";
import events from "../common/events"; 
import State from "../state/state";

export default class SocketIO {
    private socket;
    private connectionCounter = 0;
    constructor(callback: any = null) { 
        const state = State.state;
        const url = window["params"] && window["params"].socketEndpoint ? window["params"].socketEndpoint : `${location.hostname}`;
        
        const socketPath = window["params"].socketPath || "/websocket";
        const isSecure = url.indexOf("https") > -1;

        trace(`connecting to: ${url}  ${isSecure}`); 
        this.socket = io.connect(url, {
            path: socketPath,
            secure: isSecure,
            transports: ["websocket", "polling"],
        }); 
        this.socket.on("connect_error", (e) => {
            trace("Socket cant connect... " + this.connectionCounter + " / 5");
            this.connectionCounter++;
            if (this.connectionCounter >= 5) {
                bus.$emit(events.DESTROY_SCENARIO_MANAGER);
            }
        });
        this.socket.on("message", (m: any) => {
            const message = typeof m === "string" ? JSON.parse(m) : m;
            const gameId = state.getGameId();
            if (message.type === "pong") {
                trace( "Connected " + message.type);
                callback && callback();

            } else if (message.type === "get-scenarios") {
                bus.$emit(events.GET_SCENARIOS, message.response);

            } else if (message.type === "update-scenarios") {
                if (message.gameId !== gameId) { return; }
                bus.$emit(events.UPDATE_SCENARIOS, message.scenarios);

            } else if (message.type === "update-steps") {
                if (message.gameId !== gameId) { return; }
                bus.$emit(events.UPDATE_STEPS, message.scenario);

            } else if (message.type === "start-scenario") {
                trace("Scenario started");
                if (!State.state.standalone) {
                    bus.$emit(events.CLOSE_SCENARIO);
                }
                bus.$emit(events.SCENARIO_STARTED);
                if (!message.response) { 
                    bus.$emit(events.SHOW_POPUP, (promise) => {}, `Session was not found, try refreshing or disabling Cookies on the top bar`, false, false, true, false, true);
                }
            }
        });

        this.socket.emit("message", { type: "ping" });
    }
    public emit(message: any) { 
        message.gameId = State.state.getGameId();
        this.socket.emit("message", message);
    }
    public destroy() {
        trace("disconnectSocket()");
        this.socket.disconnect();
    }
}
