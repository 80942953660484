import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public reel = "";
    public reelSetCascadeNumber = 1;
    public isOpenBase = false;
    public reSpinCount: number = 0;
    public showRespinBox = false;

    public reSpinData: any[] = [];
    public reSpinInput: string[] = [];
    public reSpinBF: number[][] = [];
    public reSpinFactor: string[] = [];
    public factors: string[] = ["1", "1.667", "2", "2.5", "3.3333", "5", "8.333"];

    public showReSpinInput: boolean = true;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        if (this.reelsNo !== this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
        }

        if (step.json.internal_name === "FreeGame") {
            this.showReSpinInput = false;
            this.showRespinBox = false;
        } else {
            this.showReSpinInput = true;
            this.reSpinCount = step.json.reSpinCount;

            if (this.reSpinCount > 0) {
                this.showRespinBox = true;
            }

            this.loadDataOnStepChanged();
        }
    }

    /**
     * Clears and reset the the data inside the input fields.
     */
    public resetData() {
        this.reSpinInput = [];
        this.reSpinBF[0] = [];
        this.reSpinBF[1] = [];
        this.reSpinFactor = [];
    }

    /**
     * Update the data inside the input fields according to the current saved step.
     */
    public loadDataOnStepChanged() {
        try {
            this.resetData();
            this.reSpinData = this.step.json.reSpin;

            this.reSpinData.forEach((data, index) => {
                this.reSpinInput[index] = data.json.reelStripPositions.join(",");
                this.reSpinBF[0][index] = data.json.bonusFactor[0];
                this.reSpinBF[1][index] = data.json.bonusFactor[1];
                this.reSpinFactor[index] = String(data.json.factor);
            });
        } catch { }
    }

    /**
     * Called when the reSpin count changes.
     */
    public onRespinUpdate() {
        this.showRespinBox = this.reSpinCount > 0;
        this.updateData();
    }

    /**
     * Updates and store the data into step DB whenever any row is updated for reSpin.
     * @param index Index of the updated reSpin row.
     */
    public updateRespinData(index: number) {
        try {
            this.reSpinData[index - 1] = {
                json: {
                    reelStripPositions: this.reSpinInput[index - 1].split(",").map(Number),
                    bonusFactor: this.getBonusFactors(index - 1),
                    factor: this.reSpinFactor[index - 1] === "true",
                },
            };
            this.step.json.reSpin = this.reSpinData;
            this.step.json.reSpinCount = this.reSpinCount;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        } catch {
            this.step.json.reSpin = [];
            this.step.json.reSpinCount = this.reSpinCount;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    /**
     * Add or remove fields according to the reSpin count.
     */
    private updateData() {
        if (this.reSpinData != undefined) {
            this.reSpinData.length = 0;
        }

        try {
            for (let i = 0; i < this.reSpinCount; i++) {
                this.reSpinData.push({
                    json: {
                        reelStripPositions: this.reSpinInput[i].split(",").map(Number),
                        bonusFactor: this.getBonusFactors(i),
                        factor: this.reSpinFactor[i] === "true",
                    },
                });
            }

            this.step.json.reSpin = this.reSpinData;
            this.step.json.reSpinCount = this.reSpinCount;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        } catch {
            this.step.json.reSpin = [];
            this.step.json.reSpinCount = this.reSpinCount;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    /**
     * Returns the array of bonus factors.
     * @param index Index for which the bonus factor is required.
     */
    public getBonusFactors(index: number): number[] {
        const factors: number[] = [];

        this.reSpinBF.forEach((element) => {
            factors.push(Number(element[index]));
        });

        return factors;
    }

    /***
     * Handler applied to the vue div
     */
    public getBaseStep() {
        const state = State.state;
        const stepTitle = "Base";
        this.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getBaseStep(stepTitle));
    }

    /***
     * Handler applied to the vue div
     */
    public getFreeGameStep() {
        const state = State.state;
        const stepTitle = "FreeGame";
        this.reelsNo = 6;
        bus.$emit(events.ADD_STEP, state.getFreeGameStep(stepTitle));
    }
}