import { mathModel } from "../../v3";
import { modelDefinition } from "./config";

const { mysterySymbol } = modelDefinition;

export function createSlotWindowGenerator(reelDetails: {
  reels: string[][];
  reelsLayout: number[];
}) {
  /**
   * Generates a slot window replacing the mystery symbol with `replacementSymbol`.
   */
  const generateBaseWindow = mathModel.createSlotWindowGenerator(reelDetails);

  return function (reelStripPositions: number[], replacementSymbol: string) {
    const slotWindow = generateBaseWindow(reelStripPositions);

    for (let i = 0; i < slotWindow.length; i++) {
      for (let j = 0; j < slotWindow[i].length; j++) {
        if (slotWindow[i][j] === mysterySymbol) {
          slotWindow[i][j] = replacementSymbol;
        }
      }
    }

    return slotWindow;
  };
}
