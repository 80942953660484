var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonus3Prize } },
      [
        _c("option", { attrs: { value: "FREE_SPIN" } }, [_vm._v("Freespin")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "JACKPOT" } }, [_vm._v("Jackpot")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_A" } }, [_vm._v("PRIZE_A")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_B" } }, [_vm._v("PRIZE_B")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_C" } }, [_vm._v("PRIZE_C")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_D" } }, [_vm._v("PRIZE_D")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_E" } }, [_vm._v("PRIZE_E")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE_F" } }, [_vm._v("PRIZE_F")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "UPGRADE" } }, [_vm._v("UPGRADE")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }