var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getBaseStep()
              },
            },
          },
          [_vm._v("Base Game")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreeGameStep()
              },
            },
          },
          [_vm._v("FreeGame")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isBonusAllowed,
                expression: "isBonusAllowed",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.setBonusReelStrip()
              },
            },
          },
          [_vm._v("\n      Set Bonus\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c("div", [
          _c("div", { staticClass: "select-style" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.caseNumber,
                    expression: "caseNumber",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.caseNumber = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.onCaseChange()
                    },
                  ],
                },
              },
              _vm._l(_vm.cases, function (caseNumber) {
                return _c(
                  "option",
                  { key: caseNumber, domProps: { value: caseNumber } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(caseNumber) + "\n          "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: {
              index: reelIndex - 1,
              step: _vm.step,
              reelCaseNumber: _vm.caseNumber,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { class: ["flex-center"] }, [
        _c(
          "p",
          { staticClass: "text", staticStyle: { "margin-left": "1rem" } },
          [_vm._v("Factor:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.factor,
                expression: "factor",
              },
            ],
            staticStyle: { margin: "0.5rem" },
            attrs: { name: "factor", id: "factor" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.factor = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.onUpdateFactor,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "true" } }, [_vm._v("true")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "false", selected: "" } }, [
              _vm._v("false"),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }