import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events"; 
import Reel from "./reel/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public reelsNo = 5;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public isFeatureTrigger = false;
    public freeSpinCount = 8;
    public upsCount = 1;
    public upsCount2 = 1;
    public multiplierCount = 1;
    constructor() {
        super();
    }
    @Watch("step") 
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.freeSpinCount = step.json.field0;
        this.upsCount = step.json.field1 ? Number(step.json.field1) : 1;
        this.upsCount2 = this.isFeature ? this.upsCount : 1;
        this.multiplierCount = step.json.field2; 
        const scatCount = State.state.countSymbolOnStep(this.step, "SCAT");
        trace(`${this.step.order_number} - f ${this.isFeature} SCAT COUNT ${scatCount}  -  ${step.json.field1} Render ups: ${this.upsCount}`);
        this.isFeatureTrigger = this.step.order_number === 0 && scatCount >= 3;
        
    }
    public mounted() { 
        trace("reels mounted");
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep() );
    }
    public addBigWinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBigWinStep() );
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }
    public doChangeUpsDuringFeatureStep() {
        this.upsCount = this.upsCount2;
        this.checkChangesInStep();
    }
    public doChangeFreeSpinCount() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);

        // if(!this.isFeature) {
            this.step.json.field0 = String(this.freeSpinCount);
            this.step.json.field1 = String(this.upsCount);
            this.step.json.field2 = String(this.multiplierCount);
        // }
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
