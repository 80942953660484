/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf003_medusasgoldengaze",
        "wildSymbol": "Wild",
        "scatterSymbol": "FSA",
        "freeSpinTrigger": "FS",
        "freeSpinCountArray": [5, 6, 8],
        "countToTriggerFreeSpin": 3,
        "winTable": [
            {
                "symbol": "Wild",
                "multipliers": [500, 100, 40]
            }, {
                "symbol": "H1",
                "multipliers": [200, 60, 25]
            }, {
                "symbol": "H2",
                "multipliers": [125, 50, 20]
            }, {
                "symbol": "H3",
                "multipliers": [100, 40, 15]
            }, {
                "symbol": "M1",
                "multipliers": [75, 25, 10]
            }, {
                "symbol": "M2",
                "multipliers": [75, 25, 10]
            }, {
                "symbol": "L1",
                "multipliers": [60, 20, 5]
            }, {
                "symbol": "L2",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "L3",
                "multipliers": [50, 15, 5]
            }, {
                "symbol": "L4",
                "multipliers": [40, 10, 5]
            }, {
                "symbol": "L5",
                "multipliers": [40, 10, 5]
            }, {
                "symbol": "L6",
                "multipliers": [40, 10, 5]
            }, {
                "symbol": "FS",
                "multipliers": [50, 25, 5]
            }
        ],

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 2, 5, 10, 20, 25, 50, 100],

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"2000000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"1000",

        "reelsLayout": [3, 3, 3, 3, 3],
        "reels": [
            [ "L4", "L5", "M2", "L1", "L2", "FS", "L5", "L3", "H1", "H3", "L1", "L4", "H2", "M1", "L1", "L2", "M2", "L3", "L1", "L4", "FS", "Myst", "Myst", "Myst", "Myst", "M2", "L3", "L5", "M1", "L4", "H3", "L6", "H1", "L5", "L3", "M1", "L2", "L4", "H2", "L3", "L1", "H1", "Wild"],
            [ "H2", "L1", "M2", "M1", "L4", "L5", "FS", "L2", "L3", "H1", "M2", "L2", "L1", "H3", "L5", "L3", "M1", "H1", "L2", "H2", "L5", "M2", "H1", "Wild", "L4", "L5", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "L4", "M1", "H3", "L2", "L1", "H1", "H2", "L5", "M1", "H3", "L3", "M2", "L1", "FS", "L2", "H3", "M2", "H1", "L6", "M1", "H3", "L5", "L4", "M1"],
            [ "H2", "L5", "M1", "L1", "L4", "L5", "FS", "L2", "L3", "H1", "L4", "H1", "H3", "L2", "L1", "H2", "M2", "L4", "L6", "FS", "L5", "L4", "Wild", "M1", "L1", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "L5", "M2", "FS", "L1", "H3", "H1", "L4", "M1", "L1", "L2", "Wild", "M2", "M1", "L3", "H2", "L4", "FS", "L5", "H3", "H1", "L3", "H2", "L1", "M2", "FS"],
            [ "L4", "M1", "Wild", "L5", "M2", "L2", "H1", "H2", "L4", "L5", "Wild", "L1", "L3", "FS", "L4", "L5", "H3", "H1", "L6", "H2", "M2", "L1", "M1", "H3", "L5", "Myst", "Myst", "Myst", "L4", "H1", "M2", "M1", "L1", "L3", "L4", "Wild", "L2", "H1", "H3", "L3", "M1", "H2", "L1", "L5", "M2", "L2", "L1"],
            [ "L4", "M1", "Wild", "L5", "M2", "L2", "H1", "H2", "L4", "L5", "Wild", "L1", "L2", "FS", "L4", "L5", "H3", "L1", "L6", "H2", "M2", "L1", "M1", "H3", "L5", "Myst", "Myst", "Myst", "L4", "H1", "M2", "M1", "L1", "L3", "L4", "Wild", "L2", "H1", "H3", "L3", "M2", "H2", "L2", "L3", "H1", "L6", "L1"]
        ],
        "freeSpinsReels" : [
            ["M2", "L2", "H2", "L1", "L5", "H1", "L2", "H3", "M2", "L4", "L3", "M1", "L1", "L2", "M2", "L5", "L4", "M1", "Myst", "Myst", "Myst", "Myst", "Myst", "M2", "L3", "L5", "L1", "L4", "H3", "H1", "L6", "H2", "H3", "L3", "M1", "L2", "L1", "H1", "Wild", "L4", "L5", "H2", "L6"],
            ["H2", "L1", "M1", "L2", "M2", "L5", "L4", "H1", "L3", "H2", "L2", "L1", "H3", "L5", "L6", "M1", "H1", "L4", "L3", "H3", "L5", "H1", "M2", "Wild", "Myst", "Myst", "Myst", "M1", "L6", "L2", "H3", "L1", "H1", "L3", "H2", "L4", "L5", "M1", "M2", "Wild", "H3", "L1", "L2", "M2", "L3", "H1", "L4", "L1", "H3", "L5", "L4", "M2"],
            ["L4", "L5", "H1", "M2", "H3", "L1", "L4", "M1", "Wild", "H1", "L1", "L4", "H2", "M1", "L6", "L5", "M2", "M1", "L3", "L1", "H3", "L2", "H1", "Wild", "Myst", "Myst", "Myst", "M2", "L5", "L1", "L6", "H1", "H3", "L4", "L1", "M1", "L2", "H1", "M2", "M1", "L3", "L4", "H2", "L5", "L2", "H3", "H2", "L5", "L3", "M2"],
            ["H3", "M2", "L4", "L1", "M1", "M2", "L4", "L1", "H2", "L5", "H1", "L6", "L3", "H3", "L2", "L5", "M1", "Wild", "L1", "M2", "H2", "L4", "H3", "M1", "Myst", "Myst", "Myst", "L4", "H1", "M2", "L3", "L6", "M1", "L5", "L2", "H2", "M1", "L5", "H1", "L2", "L4", "M2", "L3", "L5"],
            ["L4", "M1", "L3", "L5", "H3", "L1", "M2", "L4", "H1", "L3", "H2", "L1", "L2", "M1", "H3", "L4", "Wild", "L3", "L5", "H1", "L6", "L4", "L1", "H3", "L3", "L2", "Myst", "Myst", "Myst", "L1", "H2", "L2", "M1", "L5", "L6", "H1", "M2", "L5", "L2", "H2", "M2"]
        ],
        "freeSpinEasyFeature" : [
            ["M2", "L2", "M1", "H2", "L1", "L2", "H1", "L5", "H3", "M2", "L4", "L3", "L1", "L2", "M2", "L5", "L4", "M1", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "Myst", "M2", "L3", "L5", "L1", "L4", "H3", "H1", "L6", "H2", "H3", "L3", "M1", "L2", "L1", "H1", "Wild", "L4", "L5", "H2", "L6"],
            ["H2", "L1", "M1", "L2", "M2", "L3", "H1", "L5", "L4", "Wild", "L2", "L1", "H3", "L5", "M2", "M1", "H1", "L4", "H2", "H3", "L5", "H1", "M1", "Wild", "Myst", "Myst", "Myst", "M2", "L6", "L2", "H3", "L1", "H1", "L3", "H2", "L4", "L5", "M2", "L3", "Wild", "H3", "L1", "L6", "L2", "M1", "H1", "L4", "L1", "H3", "L3", "L4", "M2"],
            ["L4", "L5", "H1", "M2", "H3", "L2", "L1", "L4", "M1", "Wild", "H1", "L1", "L4", "H2", "M1", "L6", "L5", "M2", "M1", "L3", "L1", "H3", "L2", "H1", "Wild", "Myst", "Myst", "Myst", "M2", "L5", "L1", "L6", "H1", "H3", "L4", "L1", "M1", "L2", "H1", "M2", "M1", "L3", "L4", "H2", "L5", "Wild", "M2", "L3", "L5", "H2", "H3"],
            ["H3", "M2", "L4", "L1", "M1", "M2", "L4", "L1", "H2", "L5", "H1", "L6", "L3", "H3", "L2", "L5", "M1", "Wild", "L1", "M2", "H2", "L4", "H3", "M1", "Myst", "Myst", "Myst", "L4", "H1", "M2", "L3", "L6", "M1", "L5", "L2", "H2", "M1", "L5", "H1", "L2", "L4", "M2", "L3", "L5"],
            ["L4", "M1", "L3", "L5", "H3", "L1", "M2", "L4", "H1", "L3", "H2", "L1", "L2", "M1", "H3", "L4", "Wild", "L3", "L5", "H1", "L6", "L4", "L1", "H3", "L3", "L2", "Myst", "Myst", "Myst", "L1", "H2", "L2", "M1", "L5", "L6", "H1", "M2", "L5", "L2", "H2", "M2"]
        ],
        "copyReelsFeaturesWeights": [
            {"H1" : [
                    {"value": [1], "weight": 16},
                    {"value": [2], "weight": 16},
                    {"value": [3], "weight": 3},
                    {"value": [4], "weight": 2},
                    {"value": [1,2], "weight": 27},
                    {"value": [1,3], "weight": 9},
                    {"value": [1,4], "weight": 6},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"H2" : [
                    {"value": [1], "weight": 16},
                    {"value": [2], "weight": 16},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 2},
                    {"value": [1,2], "weight": 24},
                    {"value": [1,3], "weight": 4},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 5},
                    {"value": [3,4], "weight": 4},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"H3" : [
                    {"value": [1], "weight": 12},
                    {"value": [2], "weight": 12},
                    {"value": [3], "weight": 2},
                    {"value": [4], "weight": 2},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 2},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 1},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 5},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"M1" : [
                    {"value": [1], "weight": 9},
                    {"value": [2], "weight": 9},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 3},
                    {"value": [2,3], "weight": 5},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"M2" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 5},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L1" : [
                    {"value": [1], "weight": 8},
                    {"value": [2], "weight": 8},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 18},
                    {"value": [1,3], "weight": 5},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 5},
                    {"value": [2,3,4], "weight": 3},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L2" : [
                    {"value": [1], "weight": 5},
                    {"value": [2], "weight": 5},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 18},
                    {"value": [1,3], "weight": 6},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 5},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L3" : [
                    {"value": [1], "weight": 5},
                    {"value": [2], "weight": 5},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 17},
                    {"value": [1,3], "weight": 6},
                    {"value": [1,4], "weight": 3},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 4},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L4" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L5" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 3},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L6" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 3},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"FS" : [
                    {"value": [1], "weight": 33},
                    {"value": [2], "weight": 9},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 5},
                    {"value": [1,3], "weight": 24},
                    {"value": [1,4], "weight": 18},
                    {"value": [2,3], "weight": 18},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 0},
                    {"value": [1,2,4], "weight": 0},
                    {"value": [2,3,4], "weight": 5},
                    {"value": [1,2,3,4], "weight": 0}
                ]
            },
            {"Wild" : [
                    {"value": [1], "weight": 13},
                    {"value": [2], "weight": 13},
                    {"value": [3], "weight": 0},
                    {"value": [4], "weight": 0},
                    {"value": [1,2], "weight": 17},
                    {"value": [1,3], "weight": 6},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 0},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 0},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            }
        ],

        "reelsSymbolWeights": [
            {"value": "Wild", "weight": 1},
            {"value": "H1", "weight": 2},
            {"value": "H2", "weight": 1},
            {"value": "H3", "weight": 1},
            {"value": "M1", "weight": 3},
            {"value": "M2", "weight": 1},
            {"value": "L1", "weight": 6},
            {"value": "L2", "weight": 4},
            {"value": "L3", "weight": 5},
            {"value": "L4", "weight": 7},
            {"value": "L5", "weight": 7},
            {"value": "L6", "weight": 2},
            {"value": "FS", "weight": 6}
        ],

        "copyReelsFeaturesWeightsFS": [
            {
                "H1" : [
                    {"value": [1], "weight": 16},
                    {"value": [2], "weight": 16},
                    {"value": [3], "weight": 2},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 27},
                    {"value": [1,3], "weight": 9},
                    {"value": [1,4], "weight": 6},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 0},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"H2" : [
                    {"value": [1], "weight": 14},
                    {"value": [2], "weight": 18},
                    {"value": [3], "weight": 2},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 25},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 8},
                    {"value": [2,4], "weight": 5},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"H3" : [
                    {"value": [1], "weight": 17},
                    {"value": [2], "weight": 15},
                    {"value": [3], "weight": 4},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 28},
                    {"value": [1,3], "weight": 8},
                    {"value": [1,4], "weight": 6},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 2},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"M1" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 5},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 2},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"M2" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 2},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L1" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 2},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 9},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L2" : [
                    {"value": [1], "weight": 8},
                    {"value": [2], "weight": 8},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 9},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L3" : [
                    {"value": [1], "weight": 8},
                    {"value": [2], "weight": 8},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 12},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 4},
                    {"value": [2,3], "weight": 1},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L4" : [
                    {"value": [1], "weight": 11},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L5" : [
                    {"value": [1], "weight": 11},
                    {"value": [2], "weight": 11},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 8},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"L6" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 12},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {"Wild" : [
                    {"value": [1], "weight": 13},
                    {"value": [2], "weight": 13},
                    {"value": [3], "weight": 0},
                    {"value": [4], "weight": 0},
                    {"value": [1,2], "weight": 17},
                    {"value": [1,3], "weight": 6},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 0},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 0},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            }
        ],

        "reelsSymbolWeightsFS": [
            {"value": "Wild", "weight": 1},
            {"value": "H1", "weight": 4},
            {"value": "H2", "weight": 1},
            {"value": "H3", "weight": 1},
            {"value": "M1", "weight": 4},
            {"value": "M2", "weight": 7},
            {"value": "L1", "weight": 21},
            {"value": "L2", "weight": 7},
            {"value": "L3", "weight": 6},
            {"value": "L4", "weight": 21},
            {"value": "L5", "weight": 18},
            {"value": "L6", "weight": 6},
            {"value": "FS", "weight": 0}
        ],

        "copyReelsEFWeights": [
            {
                "H1" : [
                    {"value": [1], "weight": 16},
                    {"value": [2], "weight": 16},
                    {"value": [3], "weight": 2},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 27},
                    {"value": [1,3], "weight": 9},
                    {"value": [1,4], "weight": 6},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 2},
                    {"value": [3,4], "weight": 0},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "H2" : [
                    {"value": [1], "weight": 14},
                    {"value": [2], "weight": 18},
                    {"value": [3], "weight": 2},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 25},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 8},
                    {"value": [2,4], "weight": 5},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "H3" : [
                    {"value": [1], "weight": 17},
                    {"value": [2], "weight": 15},
                    {"value": [3], "weight": 4},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 28},
                    {"value": [1,3], "weight": 8},
                    {"value": [1,4], "weight": 6},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 2},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "M1" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 3},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 5},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 2},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "M2" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 20},
                    {"value": [1,3], "weight": 2},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L1" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 2},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 9},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L2" : [
                    {"value": [1], "weight": 8},
                    {"value": [2], "weight": 8},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 3},
                    {"value": [1,2,4], "weight": 9},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L3" : [
                    {"value": [1], "weight": 8},
                    {"value": [2], "weight": 8},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 12},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 4},
                    {"value": [2,3], "weight": 1},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 6},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L4" : [
                    {"value": [1], "weight": 11},
                    {"value": [2], "weight": 10},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 16},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 2},
                    {"value": [2,3], "weight": 2},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L5" : [
                    {"value": [1], "weight": 11},
                    {"value": [2], "weight": 11},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 3},
                    {"value": [2,4], "weight": 4},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 8},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "L6" : [
                    {"value": [1], "weight": 10},
                    {"value": [2], "weight": 12},
                    {"value": [3], "weight": 1},
                    {"value": [4], "weight": 1},
                    {"value": [1,2], "weight": 14},
                    {"value": [1,3], "weight": 1},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 4},
                    {"value": [2,4], "weight": 3},
                    {"value": [3,4], "weight": 1},
                    {"value": [1,2,3], "weight": 2},
                    {"value": [1,2,4], "weight": 7},
                    {"value": [2,3,4], "weight": 2},
                    {"value": [1,2,3,4], "weight": 1}
                ]
            },
            {
                "Wild" : [
                    {"value": [1], "weight": 13},
                    {"value": [2], "weight": 13},
                    {"value": [3], "weight": 0},
                    {"value": [4], "weight": 0},
                    {"value": [1,2], "weight": 17},
                    {"value": [1,3], "weight": 6},
                    {"value": [1,4], "weight": 1},
                    {"value": [2,3], "weight": 0},
                    {"value": [2,4], "weight": 1},
                    {"value": [3,4], "weight": 0},
                    {"value": [1,2,3], "weight": 1},
                    {"value": [1,2,4], "weight": 1},
                    {"value": [2,3,4], "weight": 1},
                    {"value": [1,2,3,4], "weight":1}
                ]
            }
        ],

        "reelsEFSpinsWeights": [
            {"value": "Wild", "weight": 1},
            {"value": "H1", "weight": 4},
            {"value": "H2", "weight": 1},
            {"value": "H3", "weight": 1},
            {"value": "M1", "weight": 4},
            {"value": "M2", "weight": 7},
            {"value": "L1", "weight": 21},
            {"value": "L2", "weight": 9},
            {"value": "L3", "weight": 4},
            {"value": "L4", "weight": 19},
            {"value": "L5", "weight": 20},
            {"value": "L6", "weight": 6},
            {"value": "FS", "weight": 0}
        ],



        "playLines": [
            [ 1,1,1,1,1 ],
            [ 0,0,0,0,0 ],
            [ 2,2,2,2,2 ],
            [ 0,1,2,1,0 ],
            [ 2,1,0,1,2 ],
            [ 1,0,1,2,1 ],
            [ 1,2,1,0,1 ],
            [ 0,1,0,1,0 ],
            [ 2,1,2,1,2 ],
            [ 0,0,1,2,2 ],
            [ 2,2,1,0,0 ],
            [ 0,0,1,0,0 ],
            [ 2,2,1,2,2 ],
            [ 1,1,0,1,1 ],
            [ 1,1,2,1,1 ],
            [ 0,1,1,1,0 ],
            [ 2,1,1,1,2 ],
            [ 0,1,1,1,2 ],
            [ 2,1,1,1,0 ],
            [ 1,0,1,0,1 ],
            [ 1,0,0,0,2 ],
            [ 1,2,2,2,0 ],
            [ 0,1,0,1,2 ],
            [ 2,1,2,1,0 ],
            [ 1,2,1,2,1 ]
        ],
        "playLinesFS": [
            [ 1,1,1,1,1 ], //1
            [ 0,0,0,0,0 ], //2
            [ 2,2,2,2,2 ], //3
            [ 0,1,2,1,0 ], //4
            [ 2,1,0,1,2 ], //5
            [ 1,0,1,2,1 ], //6
            [ 1,2,1,0,1 ], //7
            [ 0,1,0,1,0 ], //8
            [ 2,1,2,1,2 ], //9
            [ 0,0,1,2,2 ], //10
            [ 2,2,1,0,0 ], //11
            [ 0,0,1,0,0 ], //12
            [ 2,2,1,2,2 ], //13
            [ 1,1,0,1,1 ], //14
            [ 1,1,2,1,1 ], //15
            [ 0,1,1,1,0 ], //16
            [ 2,1,1,1,2 ], //17
            [ 0,1,1,1,2 ], //18
            [ 2,1,1,1,0 ], //19
            [ 1,0,1,0,1 ], //20
            [ 1,0,0,0,2 ], //21
            [ 1,2,2,2,0 ], //22
            [ 0,1,0,1,2 ], //23
            [ 2,1,2,1,0 ], //24
            [ 1,2,1,2,1 ], //25
            [ 0,0,0,0,1 ], //26
            [ 2,2,2,2,1 ], //27
            [ 0,0,1,2,1 ], //28
            [ 2,2,1,0,1 ], //29
            [ 0,0,2,0,0 ], //30
            [ 2,2,0,2,2 ], //31
            [ 0,1,2,1,2 ], //32
            [ 2,1,0,1,0 ], //33
            [ 0,1,2,2,1 ], //34
            [ 2,1,0,0,1 ], //35
            [ 0,2,0,2,0 ], //36
            [ 2,0,2,0,2 ], //37
            [ 0,2,1,2,0 ], //38
            [ 2,0,1,0,2 ], //39
            [ 0,2,2,2,0 ], //40
            [ 2,0,0,0,2 ], //41
            [ 1,0,0,1,2 ], //42
            [ 1,2,2,1,0 ], //43
            [ 1,0,1,2,2 ], //44
            [ 1,2,1,0,0 ], //45
            [ 1,0,2,0,1 ], //46
            [ 1,2,0,2,1 ], //47
            [ 1,1,0,0,0 ], //48
            [ 1,1,2,2,2 ], //49
            [ 1,1,1,1,2 ]  //50
        ],

        "suitSymbols": ["Spade", "Heart", "Diamond", "Club"],
        "highPayingSymbols": ["HV1", "HV2", "HV3", "MV1","MV2", "MV3"],
        "featureBaseGameWeight": [
            {"value": true, "weight": 32},
            {"value": false, "weight": 988}
        ],

        "highPaySymbolsWeight": [
            {"value": "HV1", "weight": 2},
            {"value": "HV2", "weight": 3},
            {"value": "HV3", "weight": 3},
            {"value": "MV1", "weight": 7},
            {"value": "MV2", "weight": 9},
            {"value": "MV3", "weight": 8}
        ],

        "highPaySymbolsFSWeight": [
            {"value": "HV1", "weight": 15},
            {"value": "HV2", "weight": 20},
            {"value": "HV3", "weight": 10},
            {"value": "MV1", "weight": 20},
            {"value": "MV2", "weight": 17},
            {"value": "MV3", "weight": 18}
        ],

        "optionsFS": {
            "regularFS": {
                "fs": 1,
                "multiplier": 1
            },
            "50LineFS": {
                "fs": 2,
                "multiplier": 1
            },
            "easyFeatureFS": {
                "fs": 2,
                "multiplier": 1
            },
            "superFS": {
                "fs": 3,
                "multiplier": 3
            },
            "megaFS": {
                "fs": 5,
                "multiplier": 5
            }
        },

        "typeFS": ["regularFS", "50LineFS", "easyFeatureFS"],
        "typeFSExtended": ["superFS", "megaFS"]

    },
}
