// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-50a1f4f8] {
    color: gray;
    font-size: 12px;
}
.sm-game-spec-content .flex[data-v-50a1f4f8] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-50a1f4f8] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-50a1f4f8] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-50a1f4f8] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-50a1f4f8] {
    margin: 5px 0 5px 0;
}
.jackpot-selector-wrapper[data-v-50a1f4f8] {
    position: relative;
}
.force-jackpot-selector[data-v-50a1f4f8] {
    padding: 20px;
}
.force-jackpot-selector select[data-v-50a1f4f8] {
    background-color: #2A2A2A;
    height: 29px;
    overflow: hidden;
    color: gray;
    top: -8px;
    position: relative;
    margin-left: 10px;
    width: auto;
    border: 1px solid grey;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw056/content.vue.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".sm-game-spec-content[data-v-50a1f4f8] {\n    color: gray;\n    font-size: 12px;\n}\n.sm-game-spec-content .flex[data-v-50a1f4f8] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-50a1f4f8] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-50a1f4f8] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-50a1f4f8] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-50a1f4f8] {\n    margin: 5px 0 5px 0;\n}\n.jackpot-selector-wrapper[data-v-50a1f4f8] {\n    position: relative;\n}\n.force-jackpot-selector[data-v-50a1f4f8] {\n    padding: 20px;\n}\n.force-jackpot-selector select[data-v-50a1f4f8] {\n    background-color: #2A2A2A;\n    height: 29px;\n    overflow: hidden;\n    color: gray;\n    top: -8px;\n    position: relative;\n    margin-left: 10px;\n    width: auto;\n    border: 1px solid grey;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
