import { mathModel } from "../../../v3";
import { modelDefinition } from "../config";
import { SpinInputs } from "./spin-inputs";

/**
 * Ensures the given player inputs are all consistent with the math model and are
 * allowable for the given game state. Throws an error if any inputs are not valid.
 */
export function ensureSpinInputsAreValid(
  inputs: Omit<SpinInputs, "choices">
): void {
  const { coinTypeId, coinSize, selectedLines, gameState } = inputs;

  if (!isValidCoinSize(coinTypeId, coinSize)) {
    throw new mathModel.MathModelError("Invalid coin size", {
      coinTypeId,
      coinSize,
    });
  }
  if (!isValidLineSelection(selectedLines)) {
    throw new mathModel.MathModelError("invalid line selection", {
      selectedLines,
    });
  }

  // Ensure that the coin size remains the same during a feature.
  if (gameState && gameState.coinSize !== coinSize) {
    throw new mathModel.MathModelError(
      "coin size changed during feature spins",
      { coinSize }
    );
  }
}

// Generate helper functions tailored to the model definition.
const isValidCoinSize = mathModel.createCoinSizeValidator(modelDefinition);
const isValidLineSelection = mathModel.createLinesValidator(modelDefinition);
