var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFreeSpinTriggerStep,
                expression: "canAddFreeSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFireshotTriggerStep,
                expression: "canAddFireshotTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFireshotTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Fireshot")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " + _vm._s(_vm.fireshotRespinsRemaining)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Coins Collected: " + _vm._s(_vm.fireshotCoinsCollected))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showStackSymbolReplacement,
                expression: "showStackSymbolReplacement",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.stackSymbolReplacement,
                    expression: "stackSymbolReplacement",
                  },
                ],
                attrs: { disabled: !_vm.canChangeStackSymbolReplacement },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.stackSymbolReplacement = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: "PIC1" } }, [
                  _vm._v("STACK ⭢ PIC1"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC2" } }, [
                  _vm._v("STACK ⭢ PIC2"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC3" } }, [
                  _vm._v("STACK ⭢ PIC3"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC4" } }, [
                  _vm._v("STACK ⭢ PIC4"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "PIC5" } }, [
                  _vm._v("STACK ⭢ PIC5"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCoinMode,
                expression: "showCoinMode",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.coinMode,
                    expression: "coinMode",
                  },
                ],
                attrs: { disabled: !_vm.canChangeCoinMode },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.coinMode = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: "NORMAL_COIN" } }, [
                  _vm._v("Normal Coin 🟡"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "DIAMOND_COIN" } }, [
                  _vm._v("Diamond Coin 💎"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showReelSetAndMultiplierSet,
                expression: "showReelSetAndMultiplierSet",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelSet,
                    expression: "reelSet",
                  },
                ],
                attrs: { disabled: !_vm.canChangeReelSetAndMultiplierSet },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.reelSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: "set1" } }, [
                  _vm._v("Feat Set 1"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set2" } }, [
                  _vm._v("Feat Set 2"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set3" } }, [
                  _vm._v("Feat Set 3"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set4" } }, [
                  _vm._v("Feat Set 4"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set5" } }, [
                  _vm._v("Feat Set 5"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set6" } }, [
                  _vm._v("Feat Set 6"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showReelSetAndMultiplierSet,
                expression: "showReelSetAndMultiplierSet",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.multiplierSet,
                    expression: "multiplierSet",
                  },
                ],
                attrs: { disabled: !_vm.canChangeReelSetAndMultiplierSet },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.multiplierSet = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: "set1" } }, [
                  _vm._v("x2, x3, x5"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set2" } }, [
                  _vm._v("x3, x5, x7"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set3" } }, [
                  _vm._v("x5, x7, x10"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set4" } }, [
                  _vm._v("x7, x10, x15"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set5" } }, [
                  _vm._v("x10, x15, x20"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: "set6" } }, [
                  _vm._v("x15, x20, x30"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFreeSpinMultiplier,
                expression: "showFreeSpinMultiplier",
              },
            ],
            staticClass: "select-style",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.freeSpinMultiplier,
                    expression: "freeSpinMultiplier",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.freeSpinMultiplier = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _vm._l(_vm.freeSpinMultipliers, function (mult) {
                  return [
                    _c("option", { domProps: { value: mult } }, [
                      _vm._v("x" + _vm._s(mult)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReelStripPositions,
              expression: "showReelStripPositions",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", { attrs: { content: this, index: 0 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 1 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 2 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 3 } }),
          _vm._v(" "),
          _c("reel", { attrs: { content: this, index: 4 } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }