import { modelDefinition } from "../config";

/**
 * Returns an array of [row, col] cells comprising the winning
 * slot window cells for the given `playLineIndex` and `winLength`.
 */
export function getWinCells(playLineIndex: number, winLength: number) {
  // Lookup precomputed/cached value.
  return precomputeWinCellsByLineAndLength[playLineIndex][winLength];
}

// The win cells for each playline never change, so we only need to compute them once at startup.
const precomputeWinCellsByLineAndLength = precomputeAllWinCells();
function precomputeAllWinCells() {
  const winCellsByLineAndLength: [row: number, col: number][][][] = [];
  const playLineIndices = modelDefinition.playLines.map((_, i) => i);
  const winLengths = [3, 4, 5];

  for (const playLineIndex of playLineIndices) {
    winCellsByLineAndLength[playLineIndex] = [];
    const playLine = modelDefinition.playLines[playLineIndex];
    for (const winLength of winLengths) {
      const winCells = playLine
        .slice(0, winLength)
        .map((row, col): [row: number, col: number] => [row, col]);
      winCellsByLineAndLength[playLineIndex][winLength] = winCells;
    }
  }
  return winCellsByLineAndLength;
}
