import { modelDefinition } from "../config";
import { GameState } from "../game-state";
import { createSlotWindowGenerator } from "../create-slot-window-generator";
import { checkScatterWin } from "./check-scatter-win";
import { createGetWinCells } from "./create-get-win-cells";
import { getWinType } from "./get-win-type";
import { SpinInputs } from "./spin-inputs";
import { SpinOutputs } from "./spin-outputs";
import { mathModel } from "../../../v3";

export function baseSpin(inputs: SpinInputs): SpinOutputs {
  const { coinSize, choices } = inputs;

  let gameState: GameState | undefined = undefined;

  // Generate the slot window
  const totalPlayAmount = coinSize * coinSizeMultiplier;
  const reelStripPositions = choices.chooseReelStripPositions("BASE");
  const mysterySymbol = choices.chooseMysterySymbol("BASE");
  const slotWindow = generateSlotWindow(reelStripPositions, mysterySymbol);

  // Evaluate line wins
  const wins = checkLineWins(slotWindow);
  const winLines = wins.map((win) => win.playLineIndex + win.length / 10);
  const lineWins = wins.map((win) => win.multiplier * coinSize);
  const winCells = wins.map((win) =>
    getWinCells(win.playLineIndex, win.length)
  );

  // Evaluate scatter win
  const scatterWin = checkScatterWin(slotWindow);
  const isFreeSpinTrigger = scatterWin !== undefined;
  if (isFreeSpinTrigger) {
    lineWins.push(scatterWin.multiplier * totalPlayAmount);
    winCells.push(scatterWin.cells);
    gameState = {
      coinSize,
      reelStripPositions,
      slotWindow,
      mysterySymbol,
      cumulativeWinAmount: 0,
      wilds: [],
      newWilds: [],
      freeSpinPhase: "START",
      freeSpinCount: modelDefinition.freeSpinTriggerCount,
    };
  }

  // Compute win type and amount
  const totalWinAmount = lineWins.reduce((sum, amt) => sum + amt, 0);
  const winType = getWinType({ totalWinAmount, isFreeSpinTrigger });
  if (gameState) {
    gameState.cumulativeWinAmount += totalWinAmount;
  }

  return {
    spinOutcome: {
      coinSize,
      totalPlayAmount,
      reelStripPositions,
      slotWindow,
      totalWinAmount,
      winType,
      winLines,
      lineWins,
      winCells,
      mysterySymbol,

      freeSpinPhase: gameState?.freeSpinPhase,
      freeSpinCount: gameState?.freeSpinCount,
      cumulativeWinAmount: gameState?.cumulativeWinAmount,
    },
    gameState,
  };
}

// Generate helper functions tailored to the model definition.
const { coinSizeMultiplier, wildSymbol, scatterSymbol, playLines, winTable } =
  modelDefinition;
const checkLineWins = mathModel.createLineWinChecker({
  wildSymbol,
  scatterSymbol,
  playLines,
  winTable,
});
const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
const getWinCells = createGetWinCells(playLines);
