import Vue from "vue";
import Component from "vue-class-component";
import ReelVgw091 from "./reel.vue";
import { Vgw091StepJson, CoinPrize } from "src/state/models/slots/vgw091/step";
import { Prop, Watch } from "vue-property-decorator";
import { getReelWindowFromIndex, trace } from "../../../../helpers/helpers";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import State from "../../../../state/state";
import { modelDefinition } from "../../../../state/models/slots/vgw091/config/model-definition";

const originatorId = new Date().getTime();

@Component({
  components: {
    reel: ReelVgw091,
  },
  data: () => {
    return {};
  },
})
export default class Content extends Vue {
  @Prop() public step: Vgw091Step | undefined;
  public isFeature = false;
  private allSteps: Vgw091Step[] = [];
  public show = false;

  constructor() {
    super();
  }

  public mounted() {
    trace("reels mounted");
    bus.$on(events.UPDATE_STEPS, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
  }

  @Watch("step")
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }
    this.show = true;
    this.isFeature = step.json.isFeature ? step.json.isFeature : false;
  }

  reloadStepsFromScenario(scenario?: {
    steps: Array<{ test_scenario_step_id: number; json: Vgw091StepJson }>;
  }) {
    const newSteps: Vgw091Step[] = [];
    for (const incomingStep of scenario?.steps ?? []) {
      const existingStep = this.allSteps.find(
        (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
      );
      const step =
        existingStep && incomingStep.json.originatorId === originatorId
          ? existingStep
          : incomingStep;
      newSteps.push(step);
    }

    // Traverse all steps, ensuring the state transitions are all valid and correct.
    if (newSteps.length > 0) {
      const isModified = this.updateStepsInPlace(newSteps);

      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    }
  }

  saveChangesToStep(): void {
    // Traverse all steps, ensuring the state transitions are all valid and correct.
    this.updateStepsInPlace(this.allSteps);

    // Marking all the steps as having originated here, and persist them.
    this.allSteps.forEach((step) => (step.json.originatorId = originatorId));
    bus.$emit(events.EDIT_STEPS, this.allSteps);
  }

  updateStepsInPlace(steps: Vgw091Step[]) {
    let isFreeSpin = false;
    let freeSpinCount = 0;
    let name = "Spin";
    let isAnyStepModified = false;
    let coinPrizes: CoinPrize[][] = [];
    const defaultReelExpansionheight = 3;
    let reelsExpansionHeight = defaultReelExpansionheight;
    let stickyWilds: Cell[] = [];
    let bonusSpin = false;

    for (const step of steps) {
      step.reelStripPositions = step.json.reelStripPositions;
      if (step.json.name === "Clear") {
        name = step.json.name;
        freeSpinCount = 0;
        coinPrizes = [];
        stickyWilds = [];
        bonusSpin = false;
      } else {
        isFreeSpin = freeSpinCount > 0;

        // Add stickyWilds from last step to the current spin
        if (isFreeSpin) {
          Object.assign(step, { stickyWilds });
        }

        let slotWindow: string[][] = getSlotWindow({
          reelStripPositions: step.json.reelStripPositions,
          isFreeSpin,
          reelHeight: defaultReelExpansionheight,
          stickyWilds,
        });

        const isWinWinBigSpin = checkWinWinBigSpinForSlotWindow(slotWindow);

        if (isWinWinBigSpin) {
          step.prizeMultiplier = computePrizeMultiplier(slotWindow);
        }

        reelsExpansionHeight =
          isWinWinBigSpin && step.json.reelsExpansionHeight
            ? step.json.reelsExpansionHeight
            : defaultReelExpansionheight;

        if (reelsExpansionHeight !== defaultReelExpansionheight) {
          slotWindow = getSlotWindow({
            isFreeSpin,
            reelStripPositions: step.json.reelStripPositions,
            reelHeight: reelsExpansionHeight,
            stickyWilds,
          });
        }

        const scatterSymbols = findCells(
          modelDefinition.scatterSymbol,
          slotWindow
        );

        const coinCells = findCells(modelDefinition.coinSymbol, slotWindow);
        coinPrizes = slotWindow.map((col) => new Array(col.length).fill(0));

        // set default coin prizes if doesn't exist
        for (const [row, col] of coinCells) {
          coinPrizes[col][row] =
            step.json.coinPrizes?.[col]?.[row] ||
            toCoinPrize(this.getAvailableCoinPrizes(col)[0].value.toString());
        }

        const freeSpinsAwarded =
          modelDefinition.freeSpinsAwarded[
            modelDefinition.reels.length - scatterSymbols.length
          ];

        const isFreeSpinTrigger = freeSpinsAwarded > 0;

        if (isFreeSpin) {
          freeSpinCount -= 1;
        }

        if (isFreeSpinTrigger) {
          freeSpinCount += freeSpinsAwarded;
        }

        name = generateStepName(
          isFreeSpin,
          isFreeSpinTrigger,
          isWinWinBigSpin,
          bonusSpin
        );

        if (isFreeSpin && freeSpinCount === 0 && !isFreeSpinTrigger) {
          const numberOfWildsInSlotWindow = findCells(
            "WILD",
            slotWindow
          ).length;
          if (numberOfWildsInSlotWindow > 0) {
            freeSpinCount++;
            bonusSpin = true;
          }
        } else {
          bonusSpin = false;
        }

        if (freeSpinCount > 0) {
          stickyWilds = computeStickyWilds(slotWindow);
        } else {
          stickyWilds = [];
        }
      }

      Object.assign(step, { freeSpinCount });
      const json = { ...step.json, name, coinPrizes };
      if (JSON.stringify(json) !== JSON.stringify(step.json)) {
        isAnyStepModified = true;
        step.json = json;
        json.originatorId = originatorId;
        json.reelsExpansionHeight = reelsExpansionHeight;
      }
    }
    return isAnyStepModified;
  }

  public isWinWinBigSpinTrigger() {
    const currentStep = this.currentStep();

    if (!currentStep) return false;

    const reelStripPositions = currentStep.json.reelStripPositions;
    const reelHeight = this.currentStep()?.json.reelsExpansionHeight || 3;

    const slotWindow = getSlotWindow({
      isFreeSpin: this.getFreeSpinsRemaining() > 0,
      reelStripPositions,
      reelHeight,
      stickyWilds: this.currentStep()?.stickyWilds,
    });

    return checkWinWinBigSpinForSlotWindow(slotWindow);
  }

  currentStep(): Vgw091Step | undefined {
    if (!this.step || !this.allSteps) return;
    return this.allSteps.find(
      (s) =>
        this.step && s.test_scenario_step_id === this.step.test_scenario_step_id
    );
  }

  public getFreeSpinsRemaining(): number {
    return this.currentStep()?.freeSpinCount ?? 0;
  }

  public getPrizeMultiplier(): number {
    return this.currentStep()?.prizeMultiplier ?? 0;
  }

  finalStep(): Vgw091Step | undefined {
    return this.allSteps[this.allSteps.length - 1];
  }

  canAddBaseSpinStep(): boolean {
    return !this.finalStep()?.freeSpinCount;
  }

  canAddFreeSpinStep(): boolean {
    const finalStep = this.finalStep();
    if (!finalStep) return false;
    if (!finalStep.freeSpinCount) return false;

    return finalStep.freeSpinCount > 0;
  }

  public addBaseStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getBaseStep());
  }

  public addWinWinBigSpinTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getWinWinBigSpinFromBaseGame());
  }

  public addFreespinTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFreeSpinTriggerFromBaseGame());
  }

  public addFeatureStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureStep());
  }

  public addFeatureRetriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
  }

  public addClearGameStateStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getClearGameStateStep());
  }

  public addWinWinBigSpinFromFeatureTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getWinWinBigSpinFromFeatureGame());
  }

  public getAvailableCoinPrizes(columnIndex: number) {
    return State.state.getAvailableCoinPrizes(columnIndex).map((prize) => {
      return {
        value: isNaN(Number(prize)) ? prize : Number(prize),
        label: `🟡 ${prize}`,
      };
    });
  }

  setCoinPrize({
    row,
    col,
    value,
  }: {
    row: number;
    col: number;
    value: string;
  }) {
    const currentStep = this.currentStep();
    if (!currentStep) return;
    const cp = toCoinPrize(value);
    const currentCoinPrizes = this.currentStep()?.json.coinPrizes;

    const reelStripPositions = currentStep.json.reelStripPositions;
    const reelHeight = this.currentStep()?.json.reelsExpansionHeight || 3;
    const stickyWilds = this.currentStep()?.stickyWilds;
    const slotWindow = getSlotWindow({
      isFreeSpin: this.getFreeSpinsRemaining() > 0,
      reelStripPositions,
      reelHeight,
      stickyWilds,
    });

    if (modelDefinition.coinSymbol !== slotWindow?.[col][row]) return;

    if (!slotWindow) return;

    const newCoinPrices = slotWindow.map(
      (slotWindowColumn, slotWindowColumnIndex) => {
        if (slotWindowColumnIndex < 2) return [];

        return slotWindowColumn.map((_row, slotWindowRowIndex) => {
          return slotWindowColumnIndex === col && slotWindowRowIndex === row
            ? cp
            : currentCoinPrizes?.[slotWindowColumnIndex]?.[
                slotWindowRowIndex
              ] ?? 0;
        });
      }
    );

    (this.currentStep() as Vgw091Step).json.coinPrizes = newCoinPrices;
    this.saveChangesToStep();
  }

  public getCoinPrize({ row, col }: { row: number; col: number }) {
    let prize = this.currentStep()?.json.coinPrizes?.[col]?.[row] ?? 0;
    return prize;
  }

  public setReelHeight(height: number) {
    const currentStep = this.currentStep();
    if (!currentStep) return;

    currentStep.json.reelsExpansionHeight = Number(height);
    this.saveChangesToStep();
  }

  public getReelHeight() {
    return this.currentStep()?.json.reelsExpansionHeight || 3;
  }

  public getReelHeights() {
    const reelExpansionHeight = this.getReelHeight();
    return State.state.getExpandedReelsLayout(reelExpansionHeight);
  }
}

function checkWinWinBigSpinForSlotWindow(slotWindow: string[][]) {
  const wildOnReel1 =
    slotWindow[0].find((symbol) => symbol === "WILD") !== undefined;
  const wildOnReel2 =
    slotWindow[1].find((symbol) => symbol === "WILD") !== undefined;
  return wildOnReel1 && wildOnReel2;
}

function computePrizeMultiplier(slotWindow: string[][]): number | undefined {
  const wildsOnReel1 = slotWindow[0].filter(
    (symbol) => symbol === "WILD"
  ).length;
  const wildsOnReel2 = slotWindow[1].filter(
    (symbol) => symbol === "WILD"
  ).length;
  return wildsOnReel1 * wildsOnReel2;
}

type Cell = [number, number];

function getSlotWindow({
  isFreeSpin,
  reelStripPositions,
  reelHeight,
  stickyWilds,
}: {
  isFreeSpin: boolean;
  reelStripPositions: number[];
  reelHeight: number;
  stickyWilds?: Cell[];
}): string[][] {
  const reelsLayout = State.state.getExpandedReelsLayout(reelHeight);

  const reels = isFreeSpin
    ? modelDefinition.freeSpinReels
    : modelDefinition.reels;

  const slotWindow = reels.map((_r, col) =>
    getReelWindowFromIndex(
      reels[col],
      reelStripPositions[col],
      reelsLayout[col]
    )
  );

  // replace slot window with sticky wilds
  for (let wild of stickyWilds || []) {
    const row = wild[0];
    const col = wild[1];
    slotWindow[col][row] = "WILD";
  }

  return slotWindow;
}

function findCells(
  symbol: string,
  slotWindow: string[][]
): Array<[number, number]> {
  const cells: Array<[number, number]> = [];

  for (let col = 0; col < slotWindow.length; col++) {
    for (let row = 0; row < slotWindow[col].length; row++) {
      if (slotWindow[col][row] === symbol) {
        cells.push([row, col]);
      }
    }
  }
  return cells;
}

function toCoinPrize(value: string): CoinPrize {
  const n = Number(value);
  return (isNaN(n) ? value : n) as CoinPrize;
}
export interface Vgw091Step {
  reelStripPositions?: number[];
  test_scenario_step_id: number;
  json: Vgw091StepJson;
  freeSpinCount?: number;
  stickyWilds?: [];
  prizeMultiplier?: number;
}

function computeStickyWilds(slotWindow: string[][]): Cell[] {
  const wilds = findCells("WILD", slotWindow);
  const stickyWilds = wilds
    .filter((wild) => wild[0] > 0)
    .map((wild) => {
      const modifiedWild: Cell = [wild[0] - 1, wild[1]];
      return modifiedWild;
    });
  return stickyWilds;
}
function generateStepName(
  isFreeSpin: boolean,
  isFreeSpinTrigger: boolean,
  isWinWinBigSpin: boolean,
  bonusSpin: boolean
): string {
  let name = isFreeSpin ? "free spin" : "base spin";

  if (isFreeSpinTrigger) {
    name = `${name} ${isFreeSpin ? " retrigger" : " f-trigger"}`;
  }

  if (isWinWinBigSpin) {
    name = `${name} wwbs`;
  }

  if (bonusSpin) {
    name = `${name} (bonus)`;
  }

  return name;
}
