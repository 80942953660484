/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],

        createDefaultStep: function(reelSetID) {
            if (reelSetID == null) reelSetID = 1;

            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, "", "Reel Set " + reelSetID);
            step.reelSetID = reelSetID;

            let reelSetDefinition = State.state.getModelDefinition().reelSetDefinitions[reelSetID - 1];

            step.reelSetDefinition0 = reelSetDefinition[0];
            step.reelSetDefinition1 = reelSetDefinition[1];
            step.reelSetDefinition2 = reelSetDefinition[2];
            step.reelSetDefinition3 = reelSetDefinition[3];
            step.reelSetDefinition4 = reelSetDefinition[4];

            step.rows = getReelsByDefinition(reelSetDefinition);

            return step;
        },
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],

        getReelStrips: (reelSetID) => {
            if (reelSetID == null) reelSetID = 1;

            let reelSetDefinition = State.state.getModelDefinition().reelSetDefinitions[reelSetID - 1];

            return getReelsByDefinition(reelSetDefinition);
        },

        // example on creating custom indexes
        getBaseStep: (reelSetID) => State.state.createDefaultStep(reelSetID),
        getFreeSpinStepRS8: function() {
            let reelSetID = 8;

            const step = State.state.createStep([0, 8, 8, 6, 0], false, 0, "", "FS Trigger RS " + reelSetID);
            step.reelSetID = reelSetID;

            let reelSetDefinition = State.state.getModelDefinition().reelSetDefinitions[reelSetID - 1];

            step.reelSetDefinition0 = reelSetDefinition[0];
            step.reelSetDefinition1 = reelSetDefinition[1];
            step.reelSetDefinition2 = reelSetDefinition[2];
            step.reelSetDefinition3 = reelSetDefinition[3];
            step.reelSetDefinition4 = reelSetDefinition[4];

            step.rows = getReelsByDefinition(reelSetDefinition);

            return step;
        },
        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips(step.reelSetID);
            const noOfReels = 5;

            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, noOfReels, state.getNumberOfRows());
            step.randoms = step.reelStripPositions;

            return step;
        }
    };
}

function getReelsByDefinition(reelSetDefinition) {
    let reelsColumns = State.state.getModelDefinition().reelsColumns;
    let reels = new Array(reelsColumns.length);

    for (let i = 0; i < reelsColumns.length; i++) {
        reels[i] = reelsColumns[i][reelSetDefinition[i]];
    }

    return reels;
}
