/* tslint:disable:variable-name */
import State from "../../../state";
import init from "./config/init";
import {rowsFromIndexes} from "../../../../helpers/helpers";

export function setStateParams() {

    return {
        variations: [""],
        getModelDefinition: () => State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Reels");
			step.topReelReelStripPosition = 0;
            step.rows = State.state.getModelDefinition().reels;

            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join("-"),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],
        getReelStrips: () => State.state.getModelDefinition().reels,
        getTopReel: () => State.state.getModelDefinition().topReel,
        getBaseStep: () => State.state.createDefaultStep(),
        getFeatureTriggerFromBaseGame: () => {
			const step = State.state.createStep([28, 16, 0, 0, 0, 0], false, 0, "", "Respin trigger");
			step.topReelReelStripPosition = 0;
			return step;
		},

        getRespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], true, 0, "", "Respin step");
            step.topReelReelStripPosition = 0;
            step.scattersSelectedMultipliers = [];
            for (let i=0;i<28;i++)
                step.scattersSelectedMultipliers.push(0);
            return step;
        },

        prepareStepForDB: (step) => {
            const state = State.state;
            const reelStrips = state.getReelStrips();

            if ( step.isFeature ) {
                step.reelStripPositions = [0, 0, 0, 0, 0, 0];
                step.topReelReelStripPosition = 0;
            }

            step.rows = rowsFromIndexes(reelStrips, step.reelStripPositions, reelStrips.length, state.getNumberOfRows());
            step.randoms = step.reelStripPositions;

            const scatters = step.scattersSelectedMultipliers;
            if (scatters) {
                step.respinScatterPrizes = [];
                step.respinScatterPrizes.push([
                    "",
                    convertScatterInfo(scatters[4]),
                    convertScatterInfo(scatters[10]),
                    convertScatterInfo(scatters[16]),
                    convertScatterInfo(scatters[22])
                ]);
                step.respinScatterPrizes.push([
                    convertScatterInfo(scatters[0]),
                    convertScatterInfo(scatters[5]),
                    convertScatterInfo(scatters[11]),
                    convertScatterInfo(scatters[17]),
                    convertScatterInfo(scatters[23])
                ]);
                step.respinScatterPrizes.push([
                    convertScatterInfo(scatters[1]),
                    convertScatterInfo(scatters[6]),
                    convertScatterInfo(scatters[12]),
                    convertScatterInfo(scatters[18]),
                    convertScatterInfo(scatters[24])
                ]);
                step.respinScatterPrizes.push([
                    convertScatterInfo(scatters[2]),
                    convertScatterInfo(scatters[7]),
                    convertScatterInfo(scatters[13]),
                    convertScatterInfo(scatters[19]),
                    convertScatterInfo(scatters[25])
                ]);
                step.respinScatterPrizes.push([
                    convertScatterInfo(scatters[3]),
                    convertScatterInfo(scatters[8]),
                    convertScatterInfo(scatters[14]),
                    convertScatterInfo(scatters[20]),
                    convertScatterInfo(scatters[26])
                ]);
                step.respinScatterPrizes.push([
                    "",
                    convertScatterInfo(scatters[9]),
                    convertScatterInfo(scatters[15]),
                    convertScatterInfo(scatters[21]),
                    convertScatterInfo(scatters[27])
                ]);
            }
            step.scattersSelectedMultipliers = undefined;

            return step;
        },

        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0, 0], false, 0, "", "Clear");
            step.gameState = "clear";
            return step;
        },

    };
}

function convertScatterInfo(info: string): string | number {
    return isNaN(Number(info)) ? info : Number(info);
}