import bus from "../../../../common/bus";
import events from "../../../../common/events";
import { originatorId, StepJson } from "../../../../state/models/slots/vgw089";
import Vue, { PropType } from "vue";
import { modelDefinition, spin, SpinOutcome } from "./math-model";
import { createChoicesFromScenarioStep } from "./math-model/choices";
import { SlotWindow } from "./slot-window";
import { GameState } from "./math-model/game-state";
import { InternalStep, StepControls } from "./step-controls";
import { spinTypeFromSpinOutcome, wrapPosition } from "./math-model-helpers";
import { NewSteps } from "./new-steps";

export default Vue.component("Content", {
  props: {
    step: Object as PropType<{ test_scenario_step_id: number }>,
  },
  components: {
    "slot-window": SlotWindow,
    "step-controls": StepControls,
    "new-steps": NewSteps,
  },
  data: function (): {
    internalStep: InternalStep;
    allSteps: Step[];
    netScroll: number[];
    testScenarioId: number;
  } {
    return {
      internalStep: {
        reelStripPositions: [
          { value: 4 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
        ],
        mysterySymbol: undefined,
        wildFury: undefined,
        wildMultipliers: undefined,
        clearGameState: false,
      },
      allSteps: [],
      netScroll: [0, 0, 0, 0, 0],
      testScenarioId: 0,
    };
  },
  computed: {
    gameState: function (): GameState | undefined {
      return this.currentStep?.gameState;
    },
    spinOutcome: function (): SpinOutcome {
      const spinOutput = spin({
        coinTypeId: 4,
        coinSize: 1,
        selectedLines: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        ],
        choices: createChoicesFromScenarioStep({
          ...convertToStepJson(this.internalStep),
        }),
        gameState: this.currentStep?.gameState,
      });

      return spinOutput.spinOutcome;
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id,
      );
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
  },
  methods: {
    onReelScroll: function (reelIndex: number, deltaY: number): void {
      let netScroll = this.netScroll[reelIndex] + deltaY;

      if (netScroll === 0 || deltaY === 0) {
        return;
      }

      const direction = Math.sign(deltaY);
      const threshold = direction * 100;

      // Scroll up:
      // direction = 1
      // netScroll > threshold -> netScroll - threshold > 0 -> (netScroll - threshold) * 1 > 0
      // Scroll down:
      // direction = -1
      // netScroll < threshold -> netScroll - threshold < 0 -> (netScroll - threshold) * -1 > 0
      while ((netScroll - threshold) * direction > 0) {
        const spinType = spinTypeFromSpinOutcome(this.spinOutcome);
        const newPosition =
          this.internalStep.reelStripPositions[reelIndex].value + direction;

        this.internalStep.reelStripPositions[reelIndex].value = wrapPosition(
          newPosition,
          spinType,
          reelIndex,
        );

        netScroll -= threshold;
      }

      this.netScroll[reelIndex] = netScroll;
    },
    onModifierChanged: function (payload: {
      modifier: number | "MINI" | "MINOR" | "MAJOR" | "GRAND";
      newWildIndex: number;
    }) {
      if (!this.spinOutcome.newWilds) {
        console.warn(
          "Received wild modifiers changed event, but there should be no new wilds.",
        );
        return;
      }

      if (this.internalStep.wildMultipliers === undefined) {
        this.internalStep.wildMultipliers = newWildModifierArray();
      }

      this.internalStep.wildMultipliers[payload.newWildIndex].value =
        payload.modifier;
    },

    // ----- Scenario persistence methods -----
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;

      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id,
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    internalStep: {
      handler: function (internalStep: InternalStep): void {
        if (this.currentStepIndex === undefined) {
          return;
        }

        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertToStepJson(internalStep),
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId),
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertToInternalStep(newStep);

        if (!compareInternalStep(newInternalScenario, this.internalStep)) {
          this.internalStep = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
        this.reloadStepsFromScenario(scenario)
    });

    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
        this.testScenarioId = scenario?.test_scenario_id ?? 0;
        this.reloadStepsFromScenario(scenario);
    });
  },
});

type Scenario = { 
    steps: Step[];
    test_scenario_id: number;
} | undefined;

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;

  for (const step of steps) {
    if (step.json.gameState === "clear") {
      gameState = undefined;
    }
    
    step.gameState = gameState;

    const spinOutput = spin({
      coinTypeId: 4,
      coinSize: 1,
      selectedLines: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
      ],
      choices: createChoicesFromScenarioStep({
        ...step.json,
      }),
      gameState,
    });

    modified = modified || updateStepInPlace(spinOutput.spinOutcome, step);

    gameState = spinOutput.gameState;
  }

  return modified;
}

function updateStepInPlace(spinOutcome: SpinOutcome, step: Step): boolean {
  let modified = false;

  const spinType = spinTypeFromSpinOutcome(spinOutcome);

  const validMysterySymbols = mysterySymbolOptions(spinType);

  if (
    step.json.mysterySymbol &&
    !validMysterySymbols.includes(step.json.mysterySymbol)
  ) {
    modified = true;
    step.json.mysterySymbol = undefined;
  }

  const reelStripPositions = step.json.reelStripPositions;
  if (reelStripPositions) {
    for (
      let reelIndex = 0;
      reelIndex < reelStripPositions.length;
      reelIndex++
    ) {
      const position = reelStripPositions[reelIndex];
      const wrappedPosition = wrapPosition(position, spinType, reelIndex);

      if (position != wrappedPosition) {
        modified = true;
        reelStripPositions[reelIndex] = wrappedPosition;
      }
    }
  }

  return modified;
}

function mysterySymbolOptions(
  spinType: "BASE" | "WILD_FURY" | "FREE_SPINS",
): string[] {
  return modelDefinition.mysterySymbolWeights[spinType].map(
    ({ outcome }) => outcome,
  );
}

function convertToStepJson(internalStep: InternalStep): Partial<StepJson> {
  return {
    reelStripPositions: internalStep.reelStripPositions.map(({ value }) => value),
    mysterySymbol: internalStep.mysterySymbol,
    wildFury: internalStep.wildFury,
    wildMultipliers: internalStep.wildMultipliers
      ? internalStep.wildMultipliers.map(({ value }) => value)
      : undefined,
    gameState: internalStep.clearGameState ? "clear" : "continue",
  };
}

function convertToInternalStep(step: Step): InternalStep {
  return {
    reelStripPositions: (step?.json.reelStripPositions ?? [0, 0, 0, 0, 0]).map(
      (value) => ({
        value,
      }),
    ),
    mysterySymbol: step?.json.mysterySymbol,
    wildFury: step?.json.wildFury,
    wildMultipliers: step?.json.wildMultipliers
      ? step.json.wildMultipliers.map((value) => ({ value }))
      : undefined,
    clearGameState: step.json.gameState === "clear",
  };
}

function compareInternalStep(a: InternalStep, b: InternalStep) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function newWildModifierArray() {
  const array: { value: number | "MINI" | "MINOR" | "MAJOR" | "GRAND" }[] = [];

  for (let i = 0; i < 12; i++) {
    array.push({ value: 1 });
  }

  return array;
}
