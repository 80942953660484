export const baseModelDefinition = {
    // prettier-ignore
    reels: [
        ["PIC6","PIC1","Q","M","M","M","M","M","PIC5","PIC6","PIC3","PIC5","A","PIC1","PIC3","J","PIC5","WILD","J","PIC2","PIC5","Q","PIC2","J","PIC3","PIC1","Q","A","WILD","Q","A","PIC3","SCAT","J","PIC6","A","J","Q","K","A","J","M","M","M","M","M","A","PIC3","PIC6","J","PIC5","PIC4","SCAT","PIC6","PIC3","J","A","PIC2", "J"],
        ["PIC6", "K","WILD","PIC6","J","K","PIC4","PIC1","K","PIC4","PIC1","M","M","M","M","M","PIC6","K","PIC5","A","K","PIC4","J","K","PIC5","PIC1","M","M","M","M","M","A","K","PIC2","PIC6","A","K","PIC6","K","WILD","J","Q","PIC5","SCAT","J","PIC5","K","Q","PIC2","PIC4","Q","PIC5","SCAT","K","PIC3","Q","PIC2","Q", "J"],
        ["SCAT","Q","PIC1","K","SCAT","PIC5","Q","PIC5","PIC3","PIC6","PIC5","M","M","M","M","M","PIC6","A","PIC2","PIC6","PIC1","A","WILD","PIC4","A","Q","PIC2","A","PIC6","PIC5","Q","PIC2","PIC5","PIC3","Q","J","Q","PIC4","PIC6","J","Q","PIC6","PIC6","PIC4","Q","M","M","M","M","M","PIC1","A","PIC2","Q","A","WILD","PIC6","J","J","PIC3"],
        ["PIC1","K","PIC6","PIC4","K","PIC6","PIC2","Q","J","SCAT","PIC5","J","M","M","M","M","M","A","PIC3","PIC6","PIC2","A","PIC4","J","Q","WILD","K","PIC6","K","PIC1","PIC5","PIC6","PIC4","M","M","M","M","M","Q","PIC5","Q","J","PIC6","SCAT","J","PIC4","PIC4","J","PIC5","K","PIC1","PIC3","A","PIC2","A","K","J","Q","WILD","J"]        ,
        ["PIC3", "J","PIC2","PIC6","PIC5","A","PIC6","PIC5","Q","PIC3","J","PIC2","PIC3","J","K","PIC3","PIC6","PIC1","A","A","PIC4","J","PIC5","A","PIC1","PIC3","A","M","M","M","M","M","PIC5","J","A","SCAT","PIC6","PIC2","PIC1","Q","PIC4","Q","PIC6","WILD","J","A","Q","M","M","M","M","M","A","SCAT","J","PIC4","WILD","PIC1","PIC3","PIC6"],   
    ],

    reelsLayout: [3, 3, 3, 3, 3],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [1, 0, 0, 0, 1], // 12
        [1, 2, 2, 2, 1], // 13
        [0, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 2], // 15
        [1, 1, 0, 1, 1], // 16
        [1, 1, 2, 1, 1], // 17
        [0, 2, 0, 2, 0], // 18
        [2, 0, 2, 0, 2], // 19
        [0, 2, 2, 2, 0], // 20
    ],
};
