/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "rv001_squish",

        "countToTriggerExtraJuice": 4,

        "countToTriggerBlendSymbol": 5,
        "winAmountPerBlendSymbol": 50,

        "countToTriggerStickyWild": 6,
        "wildSymbol": "WW",
        "wildCascadesCount": 10,

        "betMultiplier": 40,

        "coinType1Values": [ 250, 2500, 10000, 50000, 100000 ],
        "coinType4Values": [ 1, 5, 10, 15, 25 ],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "1500000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "25",
        "coinType4_spin_max_bet": "1000",

        "reels": [
            [ "EE", "FF", "AA", "AA", "BB", "FF", "DD", "FF", "EE", "BB", "FF", "FF", "DD", "BB", "BB", "DD", "CC", "EE", "CC", "AA", "BB", "EE", "CC", "CC", "AA", "DD", "BB", "DD", "FF", "EE", "BB", "AA", "FF", "FF", "CC", "BB", "FF", "AA", "DD", "BB", "DD", "FF", "EE", "EE", "EE", "BB", "DD", "DD", "AA", "AA", "BB", "FF", "CC", "FF", "CC", "EE", "FF", "FF", "EE", "CC", "FF", "DD", "EE", "DD", "DD", "AA", "BB", "EE", "AA", "DD", "DD", "CC", "AA", "CC", "CC", "EE", "CC", "FF", "EE", "AA", "AA", "DD", "EE", "AA", "CC", "DD", "AA", "FF", "DD", "FF", "BB", "FF", "DD", "BB", "FF", "BB", "AA", "AA", "CC", "DD", "AA", "BB", "DD", "EE", "EE", "DD", "EE", "EE", "BB", "EE", "BB", "DD", "AA", "FF", "EE", "DD", "AA", "CC", "EE", "FF", "EE", "CC", "DD", "CC", "DD", "BB", "CC", "AA", "BB", "BB", "EE", "DD", "EE", "FF", "FF", "CC", "DD", "EE", "DD", "BB", "DD", "CC", "DD", "AA", "FF", "DD", "AA", "BB", "DD", "CC", "EE", "DD", "AA", "DD", "BB", "FF", "AA", "FF", "EE", "EE", "CC", "AA", "CC", "DD", "CC", "DD", "CC", "BB", "EE", "AA", "EE", "DD", "AA", "DD", "FF", "EE", "CC", "BB", "EE", "EE", "EE", "AA", "BB", "AA", "DD", "EE", "CC", "BB", "AA", "AA", "CC", "FF", "BB", "FF", "CC", "FF", "FF", "BB", "DD", "CC" ],
            [ "EE", "EE", "DD", "CC", "FF", "BB", "CC", "AA", "DD", "DD", "EE", "FF", "FF", "CC", "CC", "EE", "CC", "EE", "DD", "CC", "FF", "AA", "FF", "DD", "CC", "FF", "EE", "FF", "AA", "BB", "FF", "CC", "EE", "EE", "BB", "BB", "BB", "FF", "FF", "EE", "DD", "AA", "EE", "FF", "EE", "CC", "FF", "DD", "BB", "AA", "AA", "AA", "FF", "DD", "DD", "DD", "BB", "EE", "AA", "EE", "CC", "CC", "FF", "DD", "DD", "CC", "DD", "AA", "AA", "DD", "CC", "AA", "CC", "FF", "CC", "CC", "DD", "BB", "CC", "CC", "CC", "AA", "BB", "DD", "CC", "FF", "BB", "AA", "EE", "EE", "BB", "FF", "EE", "BB", "EE", "FF", "EE", "CC", "DD", "BB", "CC", "AA", "CC", "AA", "AA", "EE", "BB", "AA", "EE", "CC", "EE", "DD", "BB", "DD", "BB", "EE", "CC", "FF", "AA", "FF", "BB", "DD", "FF", "FF", "CC", "FF", "BB", "AA", "DD", "CC", "AA", "AA", "CC", "BB", "DD", "AA", "CC", "AA", "EE", "DD", "CC", "EE", "AA", "CC", "AA", "FF", "DD", "AA", "AA", "AA", "DD", "DD", "EE", "EE", "AA", "DD", "DD", "DD", "DD", "CC", "EE", "FF", "DD", "BB", "BB", "AA", "DD", "FF", "AA", "DD", "CC", "CC", "DD", "CC", "DD", "CC", "BB", "AA", "CC", "CC", "EE", "AA", "CC", "EE", "BB", "CC", "EE", "BB", "CC", "AA", "AA", "FF", "DD", "FF", "AA", "DD", "DD", "FF", "AA", "BB" ],
            [ "CC", "EE", "EE", "BB", "AA", "DD", "FF", "EE", "FF", "DD", "FF", "BB", "AA", "CC", "EE", "FF", "EE", "AA", "AA", "EE", "DD", "FF", "FF", "BB", "EE", "FF", "DD", "CC", "BB", "BB", "BB", "EE", "FF", "DD", "DD", "FF", "FF", "DD", "CC", "DD", "CC", "CC", "BB", "AA", "BB", "AA", "CC", "EE", "AA", "DD", "CC", "BB", "CC", "FF", "BB", "FF", "AA", "EE", "DD", "BB", "BB", "CC", "DD", "FF", "AA", "CC", "BB", "DD", "BB", "FF", "AA", "CC", "DD", "EE", "AA", "BB", "AA", "AA", "EE", "CC", "DD", "DD", "AA", "BB", "FF", "DD", "AA", "CC", "FF", "FF", "AA", "AA", "AA", "CC", "BB", "AA", "FF", "AA", "FF", "EE", "AA", "CC", "DD", "DD", "FF", "BB", "BB", "EE", "FF", "FF", "CC", "BB", "DD", "EE", "FF", "EE", "FF", "EE", "FF", "AA", "BB", "FF", "AA", "DD", "CC", "AA", "BB", "EE", "FF", "AA", "CC", "EE", "BB", "DD", "FF", "FF", "CC", "EE", "EE", "CC", "EE", "EE", "FF", "BB", "EE", "BB", "AA", "FF", "FF", "EE", "FF", "AA", "BB", "DD", "BB", "FF", "AA", "CC", "AA", "EE", "DD", "DD", "DD", "DD", "DD", "AA", "DD", "BB", "DD", "CC", "AA", "FF", "DD", "BB", "CC", "EE", "FF", "AA", "DD", "DD", "CC", "EE", "FF", "EE", "EE", "DD", "CC", "BB", "AA", "CC", "DD", "CC", "BB", "CC", "FF", "BB", "BB", "BB", "DD", "EE" ],
            [ "CC", "EE", "EE", "BB", "DD", "AA", "FF", "EE", "FF", "AA", "FF", "BB", "DD", "CC", "EE", "FF", "EE", "DD", "DD", "EE", "AA", "FF", "FF", "BB", "EE", "FF", "AA", "CC", "BB", "BB", "BB", "EE", "FF", "AA", "AA", "FF", "FF", "AA", "CC", "AA", "CC", "CC", "BB", "DD", "BB", "DD", "CC", "EE", "DD", "AA", "CC", "BB", "CC", "FF", "BB", "FF", "DD", "EE", "AA", "BB", "BB", "CC", "AA", "FF", "DD", "CC", "BB", "AA", "BB", "FF", "DD", "CC", "AA", "EE", "DD", "BB", "DD", "DD", "EE", "CC", "AA", "AA", "DD", "BB", "FF", "AA", "DD", "CC", "FF", "FF", "DD", "DD", "DD", "CC", "BB", "DD", "FF", "DD", "FF", "EE", "DD", "CC", "AA", "AA", "FF", "BB", "BB", "EE", "FF", "FF", "CC", "BB", "AA", "EE", "FF", "EE", "FF", "EE", "FF", "DD", "BB", "FF", "DD", "AA", "CC", "DD", "BB", "EE", "FF", "DD", "CC", "EE", "BB", "AA", "FF", "FF", "CC", "EE", "EE", "CC", "EE", "EE", "FF", "BB", "EE", "BB", "DD", "FF", "FF", "EE", "FF", "DD", "BB", "AA", "BB", "FF", "DD", "CC", "DD", "EE", "AA", "AA", "AA", "AA", "AA", "DD", "AA", "BB", "AA", "CC", "DD", "FF", "AA", "BB", "CC", "EE", "FF", "DD", "AA", "AA", "CC", "EE", "FF", "EE", "EE", "AA", "CC", "BB", "DD", "CC", "AA", "CC", "BB", "CC", "FF", "BB", "BB", "BB", "AA", "EE" ],
            [ "CC", "EE", "EE", "BB", "AA", "DD", "FF", "EE", "FF", "DD", "FF", "BB", "AA", "CC", "EE", "FF", "EE", "AA", "AA", "EE", "DD", "FF", "FF", "BB", "EE", "FF", "DD", "CC", "BB", "BB", "BB", "EE", "FF", "DD", "DD", "FF", "FF", "DD", "CC", "DD", "CC", "CC", "BB", "AA", "BB", "AA", "CC", "EE", "AA", "DD", "CC", "BB", "CC", "FF", "BB", "FF", "AA", "EE", "DD", "BB", "BB", "CC", "DD", "FF", "AA", "CC", "BB", "DD", "BB", "FF", "AA", "CC", "DD", "EE", "AA", "BB", "AA", "AA", "EE", "CC", "DD", "DD", "AA", "BB", "FF", "DD", "AA", "CC", "FF", "FF", "AA", "AA", "AA", "CC", "BB", "AA", "FF", "AA", "FF", "EE", "AA", "CC", "DD", "DD", "FF", "BB", "BB", "EE", "FF", "FF", "CC", "BB", "DD", "EE", "FF", "EE", "FF", "EE", "FF", "AA", "BB", "FF", "AA", "DD", "CC", "AA", "BB", "EE", "FF", "AA", "CC", "EE", "BB", "DD", "FF", "FF", "CC", "EE", "EE", "CC", "EE", "EE", "FF", "BB", "EE", "BB", "AA", "FF", "FF", "EE", "FF", "AA", "BB", "DD", "BB", "FF", "AA", "CC", "AA", "EE", "DD", "DD", "DD", "DD", "DD", "AA", "DD", "BB", "DD", "CC", "AA", "FF", "DD", "BB", "CC", "EE", "FF", "AA", "DD", "DD", "CC", "EE", "FF", "EE", "EE", "DD", "CC", "BB", "AA", "CC", "DD", "CC", "BB", "CC", "FF", "BB", "BB", "BB", "DD", "EE" ],
            [ "EE", "DD", "CC", "EE", "FF", "EE", "EE", "CC", "AA", "DD", "CC", "FF", "EE", "AA", "FF", "AA", "CC", "EE", "CC", "AA", "FF", "FF", "BB", "EE", "AA", "EE", "EE", "CC", "CC", "DD", "CC", "BB", "BB", "EE", "CC", "AA", "BB", "CC", "CC", "CC", "EE", "CC", "FF", "CC", "FF", "AA", "DD", "CC", "EE", "BB", "FF", "CC", "FF", "DD", "DD", "FF", "AA", "FF", "DD", "DD", "BB", "CC", "DD", "EE", "EE", "DD", "DD", "AA", "DD", "BB", "BB", "FF", "BB", "CC", "AA", "AA", "DD", "BB", "FF", "DD", "CC", "DD", "CC", "AA", "BB", "CC", "CC", "CC", "CC", "FF", "AA", "FF", "AA", "AA", "CC", "AA", "DD", "DD", "DD", "AA", "DD", "CC", "DD", "AA", "AA", "DD", "FF", "DD", "CC", "AA", "FF", "BB", "AA", "AA", "AA", "EE", "BB", "BB", "EE", "DD", "BB", "AA", "BB", "BB", "AA", "CC", "BB", "CC", "EE", "FF", "BB", "AA", "CC", "EE", "EE", "AA", "AA", "AA", "CC", "DD", "DD", "DD", "EE", "CC", "FF", "EE", "CC", "CC", "AA", "AA", "CC", "EE", "EE", "AA", "EE", "FF", "DD", "FF", "CC", "BB", "CC", "CC", "FF", "CC", "BB", "DD", "DD", "EE", "FF", "BB", "DD", "EE", "EE", "CC", "DD", "CC", "DD", "DD", "AA", "DD", "BB", "BB", "AA", "EE", "BB", "CC", "AA", "BB", "EE", "CC", "FF", "AA", "DD", "CC", "AA", "DD", "EE", "CC", "CC", "FF" ]
        ],

        "winTable": [
            {
                "symbol": "AA",
                "multipliers": [1000, 500, 50, 10]
            }, {
                "symbol": "BB",
                "multipliers": [400, 200, 30, 7]
            }, {
                "symbol": "CC",
                "multipliers": [250, 125, 20, 5]
            }, {
                "symbol": "DD",
                "multipliers": [150, 75, 15, 4]
            }, {
                "symbol": "EE",
                "multipliers": [120, 60, 10, 3]
            }, {
                "symbol": "FF",
                "multipliers": [100, 50, 10, 2]
            }
        ],

        "reelsLayout": [6, 6, 6, 6, 6, 6]

    },
}
