import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, range} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import { SymbolInfo, ReelInfoSet } from "src/state/models/slots/vgw060/state";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {
        };
    },
})

export default class Content extends Vue {  
    @Prop() public step: any;
    public isJackpotGame: boolean = false;
    // public symbolInfos: any[][][] = [State.state.getEmptySymbolInfo]; //contains multiple symbol info reel set arrays (one for each sub-step)
    public show = false;
    public nRows = State.state.getModelDefinition().reelsLayout[0];
    public cascadeDetected : boolean = false;

    constructor() {
        super();
    }

    //Symbol info
    get symbolInfos() : SymbolInfo[][][] {
        if (this.stepHasSymbolInfos()) {
            return this.step.json.symbolInfos;
        } else {
            return [State.state.getEmptySymbolInfo()];
        }
    }

    get hasSymbolInfoSaved() : boolean {
        if (this.stepHasSymbolInfos()) {
            return true;
        }
        return false;
    }

    public showCascadeTrashButton(cascadeIndex : number) : boolean {
        return this.hasSymbolInfoSaved && cascadeIndex == this.cascadeInfos.length - 1;
    }

    public showJackpotTrashButton(siIndex : number) : boolean {
        return this.stepHasSymbolInfos() && siIndex == this.symbolInfos.length - 1;
    }

    private stepHasSymbolInfos() : boolean {
        return this.step != null && this.step.json.symbolInfos != undefined;
    }

    //Cascade Symbol Info
    get cascadeSymbolInfos() : SymbolInfo[][][] {
        if (this.stepHasCascadeSymbolInfos()) {
            return this.step.json.cascadeSymbolInfos;
        } else {
            return [State.state.getEmptySymbolInfo()];
        }
    }
    private stepHasCascadeSymbolInfos() : boolean {
        return this.step != null && this.step.json.cascadeSymbolInfos != undefined;
    }

    //Cascade Info
    get hasCascadesSaved() : boolean {
        return this.step != null && this.step.json.cascadeInfos != undefined && this.step.json.cascadeInfos.length > 0;
    }

    get cascadeInfos() : string[][][] {
        if (this.hasCascadesSaved) {
            return this.step.json.cascadeInfos;
        } else {
            return [];
        }
    }

    public removeFinalJackpotStep() : void {
        if (this.hasSymbolInfoSaved) {
            this.step.json.symbolInfos = this.step.json.symbolInfos.slice(0, this.step.json.symbolInfos.length -1);
        }
        this.updateDB();
    }

    public removeFinalCascadeStep() : void {
        if (this.hasCascadesSaved) {
            this.step.json.cascadeInfos = this.step.json.cascadeInfos.slice(0, this.step.json.cascadeInfos.length - 1);
            this.step.json.cascadeSymbolInfos = this.step.json.cascadeSymbolInfos.slice(0, this.step.json.cascadeSymbolInfos.length - 1);
            this.step.json.symbolInfos = undefined;
        }
        this.updateDB();
    }

    public getFinalCascadeSymbolInfo() : SymbolInfo[][] {
        if (this.hasCascadesSaved) {
            return this.cascadeSymbolInfos[this.cascadeSymbolInfos.length - 1]
        }

        return [];
    }

    get isJackpotGameDefined() : boolean {
        return this.symbolInfos.length > 1;
    }

    public updateCascadeStatus() {
        this.cascadeDetected = State.state.canCascade(this.step.json.rows);

        if (!this.cascadeDetected) {
            this.step.json.cascadeInfos = [];
            this.step.json.cascadeSymbolInfos = [];
        }
    }

    public canCascade(rows : string[][]) {
        return State.state.canCascade(rows);
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        if (this.symbolInfos == undefined) {
            this.step.json.symbolInfos = [State.state.getEmptySymbolInfo()];
        }
        
        this.updateJackpotGameStatus();
        this.updateCascadeStatus();

        this.$nextTick(() => bus.$emit("step-updated", step));
        this.show = true;
    }

    public addJackpotStep() {
        let newInfo : SymbolInfo[][];
        
        if (this.hasCascadesSaved && this.isLastCascadeValid && this.symbolInfos.length <= 1) {
            newInfo = this.getFinalCascadeSymbolInfo();
        } else {
            newInfo = this.symbolInfos[this.symbolInfos.length - 1];
        }

        newInfo = JSON.parse(JSON.stringify(newInfo));
        State.state.disableSymbolInfos(newInfo);
        this.step.json.symbolInfos.push(newInfo);

        this.updateDB();
    }

    get isLastCascadeValid() : boolean {
        if (this.hasCascadesSaved) {
            return this.cascadeInfos.flat().flat().filter((sym : string) => sym === State.state.getReplaceSymbol()).length === 0;
        }
        return true;
    }

    get canCascadeAgain() : boolean {
        if (this.hasCascadesSaved) {
            return this.isLastCascadeValid && this.canCascade(this.getLastCascade());
        } else {
            return this.canCascade(this.step.json.rows)
        }
        return false;
    }

    public getLastCascade() : string[][] {
        if (this.hasCascadesSaved) {
            return this.cascadeInfos[this.cascadeInfos.length - 1];
        } else {
            return [];
        }
    }

    public addCascadeStep() {
        const lastStep : ReelInfoSet = State.state.getLastReelData(this.step);
        const previousCascadeStep : string[][] = lastStep.reels;
        const previousCascadeSymbolInfoStep : SymbolInfo[][] = lastStep.symbolInfo;
        
        const nextCascadeSet : ReelInfoSet = State.state.generateNextCascadeInitial(previousCascadeStep, previousCascadeSymbolInfoStep);
        this.step.json.cascadeInfos.push(nextCascadeSet.reels);
        this.step.json.cascadeSymbolInfos.push(nextCascadeSet.symbolInfo);

        this.updateDB();
    }
    
    public updateDB() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateJackpotGameStatus() : boolean {
        let isJackpotGame : boolean = false;

        if (this.step) {
            let coinTotal = 0;
        
            const reelSource : string[][] = this.hasCascadesSaved ?
                this.getLastCascade()
                : this.step.json.rows;

                reelSource.flat().forEach((symName : string) => {
                if (symName.toString().toLowerCase() === "co") {
                    coinTotal++;
                }
            });

            isJackpotGame = coinTotal >= 6;

            if(coinTotal > 0) {
                if (this.isSymbolInfoEmpty()) {
                    this.generateInitialSymbolInfo();
                    this.updateDB();
                }
            } else {
                if (!this.isSymbolInfoEmpty()) {
                    this.step.json.symbolInfos = [State.state.getEmptySymbolInfo()];
                    this.updateDB();
                }
            }
        }
        this.isJackpotGame = isJackpotGame;

        return isJackpotGame;
    }

    private generateInitialSymbolInfo() : void {
        this.step.json.symbolInfos = [State.state.getEmptySymbolInfo()];

        this.step.json.rows.forEach((reel : [], reelIndex) => {
            reel.forEach((row : string, rowNo) => {
                if (row.toLowerCase() === "co") {
                    this.symbolInfos[0][reelIndex][rowNo] = State.state.getDefaultSymbolInfo();
                }
            })
        });

        this.step.json.symbolInfos[0] = this.symbolInfos[0];
    }
    
    public mounted() {
        trace("reels mounted");
        bus.$on("step-updated", (step) => {
            if (step.json.symbolInfos != null && step.json.symbolInfos != undefined) {
                // trace(`wtdbg: symbolInfos exist! size=${step.json.symbolInfos.length}`);
            } else {
                step.json.symbolInfos = [State.state.getEmptySymbolInfo()];
            }
        });
    }

    public isSymbolInfoEmpty() : boolean {
        let allNull : boolean = true;

        if (this.symbolInfos && this.symbolInfos[0]) {
            this.symbolInfos[0].flat().flat().forEach((symbolInfo) => {
                if (symbolInfo != null) {
                    allNull = false;
                }
            });
        }

        return allNull;
    }
        
    public addBaseStep() {
        bus.$emit(events.ADD_STEP, State.state.getBaseStep() );
    }

    public addFeatureTriggerStep() {
        bus.$emit(events.ADD_STEP, State.state.getFeatureTrigger() );
    }
    public addBonusTriggerStep() {
        bus.$emit(events.ADD_STEP, State.state.getBonusTriggerStep() );
    }
    
    public checkChangesInStep() {
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
