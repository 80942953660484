import { modelDefinition } from "../config";
import { Choices } from "./choices";
import { ScenarioStep } from "./scenario-step";

export function createChoicesFromScenarioStep(
  scenarioStep: ScenarioStep,
): Choices {
  return {
    chooseReelStripPositions() {
      return scenarioStep.reelStripPositions ?? [0, 0, 0, 0, 0];
    },
    chooseMysterySymbol(spinType) {
      return (
        scenarioStep.mysterySymbol ??
        modelDefinition.mysterySymbolWeights[spinType][0].outcome
      );
    },
    chooseWhetherWildFuryIsTriggered() {
      return scenarioStep.wildFury ?? false;
    },
    chooseWildMultipliers(_, numberOfWilds) {
      return scenarioStep.wildMultipliers !== undefined
        ? scenarioStep.wildMultipliers.map((modifier) =>
            isJackpot(modifier) ? 1 : modifier,
          )
        : Array(numberOfWilds).fill(1);
    },
    chooseIfJackpotsAreAdded(_, multipliers) {
      return scenarioStep.wildMultipliers !== undefined
        ? scenarioStep.wildMultipliers.map((modifier) => isJackpot(modifier))
        : Array(multipliers.length).fill(false);
    },
    chooseJackpotTypes(_, __, jackpotsAdded) {
      return scenarioStep.wildMultipliers !== undefined
        ? scenarioStep.wildMultipliers.map((modifier) =>
            isJackpot(modifier) ? modifier : undefined,
          )
        : Array(jackpotsAdded.length).fill(undefined);
    },
  };
}

function isJackpot(modifier: string | number): boolean {
  return typeof modifier === "string";
}
