import { modelDefinition } from "../config";
import { JackpotWinCounts } from "./compute-jackpot-win-counts";

export function computeWinAmountForFeatureSpin(
  totalPlayAmount: number,
  previousCumulativeWinAmount: number,
  jackpotWinCounts: JackpotWinCounts,
  lineWins: number[],
) {
  const jackpotWinAmount =
    jackpotWinCounts["MINI"] *
      jackpots.fixedMultipliers.MINI *
      totalPlayAmount +
    jackpotWinCounts["MINOR"] *
      jackpots.fixedMultipliers.MINOR *
      totalPlayAmount;
  const totalWinAmount =
    lineWins.reduce((sum, amt) => sum + amt, 0) + jackpotWinAmount;
  const cumulativeWinAmount = previousCumulativeWinAmount + totalWinAmount;

  return {
    totalWinAmount,
    jackpotWinAmount,
    cumulativeWinAmount,
  };
}

const { jackpots } = modelDefinition;
