var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-steps" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addClearingStep()
                },
              },
            },
            [_vm._v("Add clearing step")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.addStep()
                },
              },
            },
            [_vm._v("Add step")]
          ),
          _vm._v(" "),
          _vm.isBaseSpin() &&
          _vm.spinOutcome.gameResponse.winType !== "SCATTER_WIN"
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addJackpotTrigger()
                    },
                  },
                },
                [_vm._v("Add jackpot trigger")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isBaseSpin() || _vm.isFreeSpin()
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addFreeSpinTrigger()
                    },
                  },
                },
                [_vm._v("Add freespin trigger")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isBaseSpin() || _vm.isFreeSpin()
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addLineWin()
                    },
                  },
                },
                [_vm._v("Add line win")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isBaseSpin() || _vm.isFreeSpin()
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addReelSpinWin()
                    },
                  },
                },
                [_vm._v("Add reel spin win")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reel-position-selectors" },
          [
            _c("label", [_vm._v("Reel strips:")]),
            _vm._v(" "),
            _vm._l(_vm.scenario.reelStripPositions, function (position) {
              return _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: position.value,
                    expression: "position.value",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number" },
                domProps: { value: position.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(position, "value", _vm._n($event.target.value))
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              })
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.shouldReelSpinFeaturesDisplayed()
          ? _c("h3", { staticClass: "heading" }, [
              _vm._v("Reel Spin Features:"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.shouldReelSpinFeaturesDisplayed()
          ? _c("div", { staticClass: "reel-spin-feature-selectors" }, [
              _c("div", [
                _c("label", [
                  _vm._v(
                    "Full Reel Wilds (minimum 3)(control + click to select):"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scenario.fullReelWilds,
                        expression: "scenario.fullReelWilds",
                      },
                    ],
                    staticStyle: { width: "200px", height: "100px" },
                    attrs: { multiple: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.scenario,
                          "fullReelWilds",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("Reel 1")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("Reel 2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("Reel 3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("Reel 4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("Reel 5")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("Single wilds (minimum 3):")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "single-wilds-and-multiplier-grid-container" },
                  _vm._l(
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                    function (reelIndex) {
                      return _c(
                        "div",
                        {
                          staticClass: "single-wilds-and-multiplier-grid-item",
                        },
                        [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: _vm.isSingleWildSelected(reelIndex),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleSingleWild(
                                  $event.target.checked,
                                  reelIndex
                                )
                              },
                            },
                          }),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("Multipliers:")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "single-wilds-and-multiplier-grid-container" },
                  _vm._l(
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                    function (reelIndex) {
                      return _c(
                        "div",
                        {
                          staticClass: "single-wilds-and-multiplier-grid-item",
                        },
                        [
                          _c(
                            "select",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.handleMultiplierSelect(
                                    $event.target.value,
                                    reelIndex
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { value: "1" },
                                  domProps: {
                                    selected:
                                      _vm.isMultiplierSelected(reelIndex),
                                  },
                                },
                                [_vm._v("-")]
                              ),
                              _vm._v(" "),
                              _vm._l([2, 3, 4, 5], function (multiplier) {
                                return _c(
                                  "option",
                                  {
                                    key: "multiplier-" + multiplier,
                                    domProps: {
                                      value: multiplier,
                                      selected: _vm.isMultiplierSelected(
                                        reelIndex,
                                        multiplier
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(multiplier) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("Character wilds:")]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleCharacterWildsSelect(
                            $event.target.value
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.getCharacterWildCombinations(),
                        function (characterWild) {
                          return _c(
                            "option",
                            {
                              key: "cw-" + characterWild,
                              domProps: {
                                selected:
                                  _vm.isCharacterWildSelected(characterWild),
                                value: characterWild,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(characterWild) +
                                  "\n              "
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("Deliver features:")]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenario.reelSpinDeliverySequence,
                          expression: "scenario.reelSpinDeliverySequence",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.scenario,
                            "reelSpinDeliverySequence",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "BEFORE" } }, [
                        _vm._v("BEFORE"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "option",
                        { attrs: { value: "AFTER", selected: "" } },
                        [_vm._v("AFTER")]
                      ),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "BOTH", selected: "" } }, [
                        _vm._v("BOTH"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.scenario.reelSpinDeliverySequence === "BOTH"
                ? _c("div", [
                    _c("label", [
                      _vm._v("Deliver Non Winning features BEFORE:"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.scenario
                                  .deliverRemainingFeaturesBeforeReelsStop,
                              expression:
                                "scenario.deliverRemainingFeaturesBeforeReelsStop",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.scenario,
                                "deliverRemainingFeaturesBeforeReelsStop",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "true" } }, [
                            _vm._v("TRUE"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "false" } }, [
                            _vm._v("FALSE"),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("Feature delivery order:")]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      domProps: {
                        value: _vm.scenario.reelSpinFeatureDeliveryOrder,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleFeatureDeliveryOrderSelect(
                            $event.target.value
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.getFeatureDeliveryCombinations(),
                        function (featureOrder) {
                          return _c(
                            "option",
                            {
                              key: "cw-" + featureOrder,
                              domProps: { value: featureOrder },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(featureOrder) +
                                  "\n              "
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "additional-components" }, [
        _c("div", [
          _vm.shouldJackpotDropDownBeDisplayed()
            ? _c("h3", { staticClass: "heading" }, [
                _vm._v("Select Jackpot to award:"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldJackpotDropDownBeDisplayed()
            ? _c("label", [_vm._v("Select jackpot:")])
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldJackpotDropDownBeDisplayed()
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.jackpotAwarded,
                      expression: "scenario.jackpotAwarded",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "jackpotAwarded",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _vm._l(
                    ["MINI", "MINOR", "GRAND", "MAJOR"],
                    function (jackpot) {
                      return _c(
                        "option",
                        {
                          key: "jackpot-" + jackpot,
                          domProps: { value: jackpot },
                        },
                        [_vm._v("\n        " + _vm._s(jackpot) + "\n        ")]
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.shouldFreeSpinsSelectorBeDisplayed()
            ? _c("h3", { staticClass: "heading" }, [
                _vm._v("Select Freespins to award:"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldFreeSpinsSelectorBeDisplayed()
            ? _c("label", [_vm._v("Free spins awarded:")])
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldFreeSpinsSelectorBeDisplayed()
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenario.pickFeatureFreeSpinsAwarded,
                      expression: "scenario.pickFeatureFreeSpinsAwarded",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.scenario,
                        "pickFeatureFreeSpinsAwarded",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _vm._l([1, 2, 3], function (freeSpinCount) {
                    return _c(
                      "option",
                      {
                        key: "free-spins-count-" + freeSpinCount,
                        domProps: { value: freeSpinCount },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(freeSpinCount) + "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.shouldPickSpinFeatureSelectorBeDisplayed()
        ? _c("h3", { staticClass: "heading" }, [_vm._v("Pick features:")])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldPickSpinFeatureSelectorBeDisplayed()
        ? _c("div", { staticClass: "pick-features-container" }, [
            _c("div", { staticClass: "pick-features-item" }, [
              _c("label", [_vm._v("Select pick features:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  on: {
                    change: function ($event) {
                      return _vm.addFeature($event.target.value)
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _c("option", { key: "FREE_SPINS" }, [_vm._v("FREE_SPINS")]),
                  _vm._v(" "),
                  _c("option", { key: "PIC1" }, [_vm._v("PIC1")]),
                  _vm._v(" "),
                  _c("option", { key: "PIC2" }, [_vm._v("PIC2")]),
                  _vm._v(" "),
                  _c("option", { key: "PIC3" }, [_vm._v("PIC3")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature-list" }, [
              _c(
                "ul",
                _vm._l(
                  _vm.scenario.pickFeatureAwarded,
                  function (feature, index) {
                    return _c("li", { key: index }, [
                      _vm._v(
                        "\n            " + _vm._s(feature) + "\n            "
                      ),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "icon",
                            class: { disabled: index === 0 },
                            on: {
                              click: function ($event) {
                                return _vm.moveFeatureUp(index)
                              },
                            },
                          },
                          [_vm._v("▲")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "icon",
                            class: {
                              disabled:
                                index ===
                                _vm.scenario.pickFeatureAwarded.length - 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.moveFeatureDown(index)
                              },
                            },
                          },
                          [_vm._v("▼")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "icon",
                            on: {
                              click: function ($event) {
                                return _vm.removeFeature(index)
                              },
                            },
                          },
                          [_vm._v("❌")]
                        ),
                      ]),
                    ])
                  }
                ),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("slot-window", { attrs: { spinOutcome: _vm.spinOutcome } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }