import Vue, { PropType } from "vue";
import { Card, ScenarioStep } from "../../math-model/choices";
import { Colour } from "../../math-model/spin";

export default Vue.component("Card", {
  props: {
    details: Object as PropType<{
      card: Card;
      prize: number;
      colour: Colour;
    }>,
  },
  computed: {
    isRed: function (): boolean {
      return this.details.colour === "RED";
    },
    isPurple: function (): boolean {
      return this.details.colour === "PURPLE";
    },
    isYellow: function (): boolean {
      return this.details.colour === "YELLOW";
    },
    isBlue: function (): boolean {
      return this.details.colour === "BLUE";
    },
    isOrange: function (): boolean {
      return this.details.colour === "ORANGE";
    },
    isGreen: function (): boolean {
      return this.details.colour === "GREEN";
    },
    isPink: function (): boolean {
      return this.details.colour === "PINK";
    },

    // Check Board Game Card = PINK
    isCardPink: function () {
      if (this.details.card === "PINK") {
        return true;
      } else {
        return false;
      }
    },
  },
});
