import type { Choices } from './choices';
import { modelDefinition } from '../model-definition/index';
import { createWeightedOutcomeSelector as libCreateWeightedOutcomeSelector } from '@vgw/gdk-math-model-tools';
import { type Cell, filterSlotWindow } from '@vgw/gdk-math-model-tools';

export function createRandomChoices({
    getRandomFromRange,
}: {
    getRandomFromRange: (range: { min: number; max: number }) => number;
}): Choices {
    function createWeightedOutcomeSelector<T>(weightedOutcomes: { outcome: T; weight: number }[]) {
        return libCreateWeightedOutcomeSelector({ weightedOutcomes, getRandomFromRange });
    }

    const bankerMultiplierGameModeSelector = {
        baseSpin: createWeightedOutcomeSelector(modelDefinition.bankerMultiplierTriggerWeightsTable.baseSpin),
        freeSpin: createWeightedOutcomeSelector(modelDefinition.bankerMultiplierTriggerWeightsTable.freeSpin),
    };

    const bankerMultiplierWinSelector = createWeightedOutcomeSelector(modelDefinition.bankerMultiplierWeightsTable);

    const bankerStacksGameModeSelector = {
        baseSpin: createWeightedOutcomeSelector(modelDefinition.bankerStacksTriggerWeightsTable.baseSpin),
        freeSpin: createWeightedOutcomeSelector(modelDefinition.bankerStacksTriggerWeightsTable.freeSpin),
    };

    const bankerStacksSymbolSelector = {
        baseSpin: createWeightedOutcomeSelector(modelDefinition.bankerStacksSymbolWeightsTable.baseSpin),
        freeSpin: createWeightedOutcomeSelector(modelDefinition.bankerStacksSymbolWeightsTable.freeSpin),
    };

    const bankerStacksReelsSelector = {
        wild: createWeightedOutcomeSelector(modelDefinition.bankerStacksCells.wild),
        case: createWeightedOutcomeSelector(modelDefinition.bankerStacksCells.case),
    };

    const bankerScatGameModeSelectors = {
        baseSpin: createWeightedOutcomeSelector(modelDefinition.bankerScatTriggerWeightsTable.baseSpin),
        freeSpin: createWeightedOutcomeSelector(modelDefinition.bankerScatTriggerWeightsTable.freeSpin),
    };

    const bankerCaseIsAwardedSelectors = {
        baseSpin: createWeightedOutcomeSelector(modelDefinition.bankerCaseAwardWeights.baseSpin),
        freeSpin: createWeightedOutcomeSelector(modelDefinition.bankerCaseAwardWeights.freeSpin),
    };

    const goldCaseIsAwardedSelector = createWeightedOutcomeSelector(modelDefinition.goldCaseAwardedWeights);
    const goldCaseFeatureSelector = createWeightedOutcomeSelector(modelDefinition.goldCaseFeatureWeights);

    const goldCaseMultiplierSelectors = modelDefinition.goldCaseMultiplierFeatureWeights.map((entry) => {
        return { maxWinRatio: entry.maxWinRatio, selector: createWeightedOutcomeSelector(entry.weights) };
    });

    return {
        chooseReelStripPositions() {
            return modelDefinition.reels.map((reel) => getRandomFromRange({ min: 0, max: reel.length - 1 }));
        },
        chooseIfBankerOfferIsAwarded(winAmount, playAmount) {
            for (const value of modelDefinition.bankerOfferTriggeredWeightsTable) {
                if (value.min * playAmount <= winAmount && winAmount < value.max * playAmount) {
                    return getRandomFromRange({ min: 1, max: value.outOf }) <= value.chance;
                }
            }

            return (
                modelDefinition.bankerOfferTriggeredWeightsTable[
                    modelDefinition.bankerOfferTriggeredWeightsTable.length - 1
                ].max *
                    playAmount ===
                winAmount
            );
        },

        chooseBankerOfferWinAmount(winAmount, playAmount) {
            for (const value of modelDefinition.bankerOfferWinAmount) {
                if (value.min * playAmount <= winAmount && winAmount < value.max * playAmount) {
                    const weightsTable = value.weightTable;
                    const selectOutcome = createWeightedOutcomeSelector(weightsTable);
                    return selectOutcome() * playAmount;
                }
            }
            throw Error(`No goldCaseMultiplier entry matches the given winAmount ${winAmount}`)
        },

        chooseIfBankerMultiplierIsAwarded(isFreeSpin) {
            if (isFreeSpin) {
                return bankerMultiplierGameModeSelector.freeSpin();
            } else {
                return bankerMultiplierGameModeSelector.baseSpin();
            }
        },

        chooseBankerMultiplierAmount() {
            return bankerMultiplierWinSelector();
        },

        chooseIfBankerStacksIsAwarded(isFreeSpin) {
            if (isFreeSpin) {
                return bankerStacksGameModeSelector.freeSpin();
            } else {
                return bankerStacksGameModeSelector.baseSpin();
            }
        },
        chooseBankerStacksSymbol(isFreeSpin) {
            if (isFreeSpin) {
                return bankerStacksSymbolSelector.freeSpin();
            } else {
                return bankerStacksSymbolSelector.baseSpin();
            }
        },
        chooseBankerStacksReels(symbol) {
            if (symbol === 'WILD') {
                return bankerStacksReelsSelector.wild();
            } else {
                return bankerStacksReelsSelector.case();
            }
        },

        chooseIfBankerScatIsAwarded(isFreeSpin) {
            const chooseResult = bankerScatGameModeSelectors[isFreeSpin ? 'freeSpin' : 'baseSpin'];
            return chooseResult();
        },
        chooseBankerScatCell(candidates) {
            const index = getRandomFromRange({ min: 0, max: candidates.length - 1 });
            return candidates[index];
        },
        chooseBankerScatSymbol(candidates) {
            const index = getRandomFromRange({ min: 0, max: candidates.length - 1 });
            return candidates[index];
        },

        chooseIfBankerCaseIsAwarded(isFreeSpin) {
            const chooseResult = bankerCaseIsAwardedSelectors[isFreeSpin ? 'freeSpin' : 'baseSpin'];
            return chooseResult();
        },
        chooseBankerCaseCell(candidates) {
            const index = getRandomFromRange({ min: 0, max: candidates.length - 1 });
            return candidates[index];
        },
        chooseBankerCaseSymbol(candidates) {
            const index = getRandomFromRange({ min: 0, max: candidates.length - 1 });
            return candidates[index];
        },
        chooseIfCaseBecomesGoldCase() {
            const becomesGoldCase = goldCaseIsAwardedSelector();
            return becomesGoldCase;
        },
        chooseCasePrize(info) {
            const filteredArray = modelDefinition.casePrizeWeightsTable.filter((entry) => {
                return !info.unavailablePrizes.includes(entry.outcome);
            });
            const casePrizeSelector = createWeightedOutcomeSelector(filteredArray);
            return casePrizeSelector();
        },
        chooseHardAndMediumPositions(slotWindow) {
            const candidateCells = filterSlotWindow(slotWindow, (symbol) => symbol !== 'CASE');
            const hardIndex = getRandomFromRange({ min: 0, max: candidateCells.length - 1 });
            const hardPosition = candidateCells[hardIndex];
            candidateCells.splice(hardIndex, 1);
            let mediumPosition: Cell | undefined;
            if (candidateCells.length > 0) {
                const mediumIndex = getRandomFromRange({ min: 0, max: candidateCells.length - 1 });
                mediumPosition = candidateCells[mediumIndex];
            }
            return { hardPosition, mediumPosition };
        },
        chooseIfCellBecomesCaseOnRespin({ coinType, coinAmount, cell, hardPosition, mediumPosition }) {
            const COIN_WEIGHT = coinAmount * modelDefinition.coinWeightMultiplier[coinType];
            const positionKind = isSameCell(cell, hardPosition)
                ? 'hard'
                : mediumPosition !== undefined && isSameCell(cell, mediumPosition)
                  ? 'medium'
                  : 'easy';
            const { chance, outOf } = modelDefinition.extraCaseWeights[positionKind];
            const coinWeight = chance === 'COIN_WEIGHT' ? COIN_WEIGHT : chance;
            const becomesCase = getRandomFromRange({ min: 1, max: outOf }) <= coinWeight;
            return becomesCase;
        },
        chooseGoldCaseFeature() {
            const feature = goldCaseFeatureSelector();
            return feature;
        },
        chooseGoldCaseMultiplier(_, winRatio) {
            for (const { maxWinRatio, selector } of goldCaseMultiplierSelectors) {
                if (winRatio <= maxWinRatio) return selector();
            }
            throw Error(`No goldCaseMultiplier entry matches the given winRatio ${winRatio}`)
        },
        chooseIfGoldCaseFeatureIsKept(feature, winRatio) {
            const entries = modelDefinition.keepGoldCaseChances[feature];
            for (const { maxWinRatio, chance } of entries) {
                if (winRatio > maxWinRatio) continue;
                const isKept = getRandomFromRange({ min: 1, max: 100 }) <= chance;
                return isKept;
            }
            throw Error(`No goldCaseMultiplier entry matches the given winRatio ${winRatio}`)
        },
    };
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
