import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import MegaReel from "./reels/mega-reel.vue";

@Component({
  components: {
    reel: Reel,
    megaReel: MegaReel,
  },
})
export default class Content extends Vue {
  @Prop() public step: any;
  public isFeature = false;
  public isFeatureChecked = false;
  public show = false;
  public reelStripPositions = [];
  public reel = "";
  public isOpen = false;

  public baseReelHeightOne = 7;
  public baseReelHeightTwo = 6;
  public baseReelHeightThree = 6;
  public baseReelHeightFour = 6;
  public baseReelHeightFive = 6;
  public baseReelHeightSix = 7;

  public upgradeSource = "";
  public upgradeTarget = "";
  public removeBlockIndex = -1;
  public megaReelStripPositions: number[] = [];

  constructor() {
    super();
  }

  @Watch("step")
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }

    this.isOpen = false;
    this.show = true;
    this.isFeature = step.json.isFeature ? step.json.isFeature : false;
    this.upgradeSource = step.json.upgradeSource;
    this.upgradeTarget = step.json.upgradeTarget;
    this.removeBlockIndex = step.json.removeBlockIndex;

    this.megaReelStripPositions = [];
    this.step.json.megaReelStripPositions.forEach((element) => {
      this.megaReelStripPositions.push(element);
    });
  }

  public mounted() {
    this.isOpen = false;
    trace("reels mounted");
  }

  public addBaseStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getBaseStep());
  }

  public updateBaseReelHeight(index, value) {
    switch (index) {
      case 0:
        this.step.json.baseReelHeightOne = value;
        break;
      case 1:
        this.step.json.baseReelHeightTwo = value;
        break;
      case 2:
        this.step.json.baseReelHeightThree = value;
        break;
      case 3:
        this.step.json.baseReelHeightFour = value;
        break;
      case 4:
        this.step.json.baseReelHeightFive = value;
        break;
      case 5:
        this.step.json.baseReelHeightSix = value;
        break;
    }

    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public updateBaseReelsHeights() {
    if (this.step.json.baseReelHeightOne != null) {
      this.baseReelHeightOne = this.step.json.baseReelHeightOne;
    }

    if (this.step.json.baseReelHeightTwo != null) {
      this.baseReelHeightTwo = this.step.json.baseReelHeightTwo;
    }

    if (this.step.json.baseReelHeightThree != null) {
      this.baseReelHeightThree = this.step.json.baseReelHeightThree;
    }

    if (this.step.json.baseReelHeightFour != null) {
      this.baseReelHeightFour = this.step.json.baseReelHeightFour;
    }

    if (this.step.json.baseReelHeightFive != null) {
      this.baseReelHeightFive = this.step.json.baseReelHeightFive;
    }

    if (this.step.json.baseReelHeightSix != null) {
      this.baseReelHeightSix = this.step.json.baseReelHeightSix;
    }
  }

  public addFeatureTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureTriggerStep());
  }

  public addFeatureStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureStep());
  }

  public onChangeUpgradeSource() {
    this.step.json.upgradeSource = this.upgradeSource;
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public onChangeUpgradeTarget() {
    this.step.json.upgradeTarget = this.upgradeTarget;
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public onChangeRemoveBlockIndex() {
    this.step.json.removeBlockIndex = this.removeBlockIndex;
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public shouldDisplayMegaReelSet(reelSetIndex: number): boolean {
    if (!this.step || !this.step.json) {
      return false;
    }
    return this.step.json.megaReelStripPositions.length > reelSetIndex;
  }

  public addMegaReelSet(direction) {
    const megaStopNum = this.megaReelStripPositions.length;
    const def = State.state.getModelDefinition();
    if (direction) {
      const maxReelSetNum = this.isFeature
        ? def.megafeatureLevels
        : def.mega.levels.length;
      if (megaStopNum >= maxReelSetNum) {
        alert("cannot add more");
        return;
      }

      this.step.json.megaReelStripPositions[megaStopNum] = 0;
      this.megaReelStripPositions[megaStopNum] = 0;
    } else {
      if (megaStopNum > 0) {
        this.megaReelStripPositions.pop();
        this.step.json.megaReelStripPositions = [];
        this.megaReelStripPositions.forEach((element) => {
          this.step.json.megaReelStripPositions.push(element);
        });
        console.log(
          "mega reelset removed " + this.megaReelStripPositions.length
        );
      } else {
        alert("no more to delete");
      }
    }
    this.$forceUpdate();
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public addClearingStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getClearingStep());
  }
}
