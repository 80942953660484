/* in order to support standalone, please insert the init response from the server here */
export default {
  "modelId": "vgw005",
  "gameSessionId": "517",
  "modelDefinition": {
    "reelsLayout": [
      3,
      3,
      3,
      3,
      3
    ],
    "winLevels": [
      {
        "name": "normal1",
        "threshold": 5
      },
      {
        "name": "normal2",
        "threshold": 15
      },
      {
        "name": "normal3",
        "threshold": 25
      },
      {
        "name": "bigwin",
        "threshold": 50
      },
      {
        "name": "megawin",
        "threshold": 150
      },
      {
        "name": "epicwin"
      }
    ],
    "currency_symbol": "$",
    "modelId": "vgw005",
    "scatterSymbol": "SCAT",
    "wildSymbol": "WILD",
    "freeSpinTrigger": "SCAT",
    "countToTriggerFreeSpin": 3,
    "freeSpinCount": 12,
    "winTable": [
      {
        "symbol": "PIC1",
        "multipliers": [
          500,
          100,
          20,
          5
        ]
      },
      {
        "symbol": "PIC2",
        "multipliers": [
          200,
          40,
          10
        ]
      },
      {
        "symbol": "PIC3",
        "multipliers": [
          200,
          40,
          10
        ]
      },
      {
        "symbol": "PIC4",
        "multipliers": [
          200,
          40,
          10
        ]
      },
      {
        "symbol": "A",
        "multipliers": [
          150,
          40,
          10
        ]
      },
      {
        "symbol": "K",
        "multipliers": [
          125,
          25,
          10
        ]
      },
      {
        "symbol": "Q",
        "multipliers": [
          125,
          25,
          10
        ]
      },
      {
        "symbol": "J",
        "multipliers": [
          125,
          20,
          10
        ]
      },
      {
        "symbol": "10",
        "multipliers": [
          100,
          20,
          5
        ]
      },
      {
        "symbol": "SCAT",
        "multipliers": [
          0,
          0,
          4
        ]
      }
    ],
    "playLines": [
      [
        1,
        1,
        1,
        1,
        1
      ],
      [
        0,
        0,
        0,
        0,
        0
      ],
      [
        2,
        2,
        2,
        2,
        2
      ],
      [
        0,
        1,
        2,
        1,
        0
      ],
      [
        2,
        1,
        0,
        1,
        2
      ],
      [
        0,
        0,
        1,
        2,
        2
      ],
      [
        2,
        2,
        1,
        0,
        0
      ],
      [
        1,
        0,
        1,
        2,
        1
      ],
      [
        1,
        2,
        1,
        0,
        1
      ],
      [
        1,
        0,
        0,
        1,
        0
      ],
      [
        1,
        2,
        2,
        1,
        2
      ],
      [
        0,
        1,
        0,
        0,
        1
      ],
      [
        2,
        1,
        2,
        2,
        1
      ],
      [
        0,
        2,
        0,
        2,
        0
      ],
      [
        2,
        0,
        2,
        0,
        2
      ],
      [
        1,
        0,
        2,
        0,
        1
      ],
      [
        1,
        2,
        0,
        2,
        1
      ],
      [
        0,
        1,
        1,
        1,
        0
      ],
      [
        2,
        1,
        1,
        1,
        2
      ],
      [
        0,
        2,
        2,
        2,
        0
      ],
      [
        2,
        0,
        0,
        0,
        2
      ],
      [
        0,
        1,
        2,
        2,
        2
      ],
      [
        2,
        1,
        0,
        0,
        0
      ],
      [
        2,
        0,
        1,
        0,
        2
      ],
      [
        0,
        2,
        1,
        2,
        0
      ]
    ],
    "reels": [
      [
        "10",
        "Q",
        "PIC1",
        "PIC1",
        "PIC2",
        "K",
        "PIC3",
        "PIC2",
        "PIC1",
        "A",
        "10",
        "PIC4",
        "K",
        "SCAT",
        "10",
        "PIC1",
        "K",
        "10",
        "PIC1",
        "Q",
        "K",
        "PIC1",
        "PIC1",
        "K",
        "SCAT",
        "A",
        "K",
        "PIC3",
        "PIC4",
        "PIC2",
        "A",
        "10",
        "PIC3",
        "PIC1",
        "K",
        "Q",
        "PIC1",
        "K",
        "J",
        "SCAT",
        "A",
        "10",
        "PIC4",
        "PIC2",
        "PIC3",
        "A",
        "K",
        "PIC4",
        "A",
        "Q",
        "PIC3",
        "K",
        "A",
        "PIC4",
        "J",
        "Q",
        "A",
        "PIC2",
        "Q",
        "10",
        "PIC4",
        "A",
        "K",
        "PIC3",
        "A",
        "SCAT",
        "K",
        "Q",
        "PIC4",
        "10",
        "A",
        "PIC3",
        "Q",
        "SCAT",
        "10",
        "PIC1",
        "Q",
        "10",
        "PIC1",
        "PIC2",
        "PIC4",
        "Q",
        "SCAT",
        "K",
        "Q",
        "PIC4",
        "K",
        "Q",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "A",
        "K",
        "PIC4",
        "SCAT"
      ],
      [
        "A",
        "Q",
        "PIC3",
        "PIC4",
        "Q",
        "J",
        "PIC3",
        "Q",
        "J",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "A",
        "Q",
        "PIC2",
        "J",
        "10",
        "A",
        "PIC3",
        "Q",
        "A",
        "PIC2",
        "J",
        "Q",
        "A",
        "PIC2",
        "J",
        "SCAT",
        "A",
        "PIC2",
        "J",
        "K",
        "SCAT",
        "Q",
        "J",
        "PIC2",
        "A",
        "Q",
        "SCAT",
        "PIC1",
        "A",
        "J",
        "PIC1",
        "A",
        "J",
        "PIC2",
        "Q",
        "A",
        "PIC3",
        "SCAT",
        "J",
        "PIC3",
        "Q",
        "J",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "J",
        "Q",
        "SCAT",
        "A",
        "J",
        "PIC2",
        "PIC3",
        "PIC4",
        "PIC2",
        "Q",
        "J",
        "PIC1",
        "SCAT",
        "Q",
        "PIC1",
        "J",
        "PIC1",
        "Q",
        "PIC3",
        "PIC2",
        "J",
        "A",
        "PIC3",
        "10",
        "A",
        "J",
        "PIC2",
        "Q",
        "J",
        "PIC1",
        "A",
        "J",
        "PIC3",
        "Q",
        "A",
        "J",
        "PIC2",
        "PIC3",
        "J",
        "SCAT",
        "Q",
        "J",
        "PIC1",
        "Q",
        "J",
        "PIC1",
        "Q",
        "SCAT",
        "PIC4",
        "J",
        "A",
        "PIC1",
        "10"
      ],
      [
        "10",
        "K",
        "J",
        "10",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "10",
        "J",
        "K",
        "10",
        "J",
        "PIC4",
        "J",
        "K",
        "PIC3",
        "10",
        "SCAT",
        "K",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "J",
        "K",
        "PIC2",
        "J",
        "K",
        "PIC2",
        "Q",
        "10",
        "PIC3",
        "K",
        "J",
        "PIC2",
        "10",
        "K",
        "PIC4",
        "10",
        "J",
        "K",
        "10",
        "PIC1",
        "K",
        "J",
        "PIC1",
        "K",
        "J",
        "10",
        "SCAT",
        "PIC2",
        "J",
        "10",
        "SCAT",
        "K",
        "J",
        "PIC4",
        "K",
        "J",
        "10",
        "K",
        "PIC4",
        "J",
        "10",
        "K",
        "J",
        "PIC2",
        "A",
        "J",
        "PIC3",
        "10",
        "SCAT",
        "J",
        "PIC4"
      ],
      [
        "Q",
        "10",
        "J",
        "Q",
        "10",
        "J",
        "A",
        "Q",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "WILD",
        "A",
        "Q",
        "J",
        "10",
        "Q",
        "A",
        "J",
        "10",
        "A",
        "J",
        "PIC2",
        "Q",
        "10",
        "K",
        "Q",
        "PIC3",
        "A",
        "10",
        "Q",
        "A",
        "J",
        "PIC2",
        "10",
        "J",
        "Q",
        "10",
        "K",
        "PIC1",
        "Q",
        "10",
        "J",
        "Q",
        "A",
        "PIC2",
        "Q",
        "K",
        "PIC4",
        "A",
        "Q",
        "10",
        "A",
        "Q",
        "PIC4",
        "K",
        "J",
        "PIC3",
        "K",
        "Q",
        "PIC4",
        "10",
        "Q",
        "J",
        "A",
        "10",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "10",
        "J",
        "Q",
        "J",
        "A",
        "Q",
        "J",
        "10",
        "K"
      ],
      [
        "Q",
        "J",
        "10",
        "K",
        "A",
        "10",
        "PIC3",
        "K",
        "A",
        "Q",
        "K",
        "PIC4",
        "A",
        "K",
        "J",
        "10",
        "K",
        "Q",
        "J",
        "PIC2",
        "A",
        "Q",
        "PIC2",
        "A",
        "J",
        "Q",
        "K",
        "10",
        "A",
        "Q",
        "PIC3",
        "10",
        "Q",
        "J",
        "PIC4",
        "K",
        "A",
        "Q",
        "10",
        "J",
        "PIC4",
        "Q",
        "J",
        "K",
        "10",
        "PIC4",
        "PIC3",
        "PIC2",
        "10",
        "Q",
        "K",
        "10",
        "J",
        "PIC1",
        "Q",
        "J",
        "10",
        "K",
        "A",
        "10",
        "K",
        "A",
        "J",
        "10",
        "PIC2",
        "K",
        "J",
        "Q",
        "10",
        "K",
        "J",
        "PIC2",
        "A",
        "Q",
        "A",
        "J",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "PIC1",
        "Q",
        "K",
        "10",
        "A",
        "Q",
        "J",
        "PIC3",
        "10",
        "Q",
        "J",
        "PIC2",
        "Q",
        "K",
        "A",
        "J",
        "Q",
        "10",
        "PIC4",
        "A",
        "K",
        "J",
        "PIC4",
        "Q",
        "10",
        "K",
        "PIC3",
        "10",
        "Q",
        "J",
        "10",
        "PIC3",
        "K",
        "J",
        "10"
      ]
    ],
    "normal_count": "2",
    "coinType1Values": [
      250,
      2500,
      10000,
      50000,
      100000
    ],
    "coinType4Values": [
      1,
      2,
      5,
      10,
      50
    ],
    "defaultSelectLine": "25",
    "coinType1_min_bet": "250",
    "coinType1_max_bet": "100000",
    "coinType1_spin_max_bet": "2500000",
    "coinType4_min_bet": "1",
    "coinType4_max_bet": "50",
    "coinType4_spin_max_bet": "1250"
  },
  "success": true,
  "messages": [],
  "gold_balance": 2008650000,
  "sweeps_balance": 206529208,
  "sweeps_deposit_balance": 200000895,
  "sweeps_promo_balance": 0,
  "sweeps_win_balance": 6528313,
  "sweeps_available_balance": 6528313,
  "serverTime": "2019-06-10 07:58:29",
  "reelStripPositions": [
    46,
    117,
    47,
    32,
    74
  ],
  "slotWindow": [
    [
      "K",
      "PIC4",
      "A"
    ],
    [
      "PIC1",
      "10",
      "A"
    ],
    [
      "J",
      "PIC1",
      "K"
    ],
    [
      "A",
      "J",
      "PIC2"
    ],
    [
      "A",
      "J",
      "PIC1"
    ]
  ]
};
