import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ["index", "scattersSelectedMultipliers"],
})
export default class SelectScatterMultiplier extends Vue {
    constructor() {
        super();
    }

    public updateScatter() {
        (<Content>this.$parent).updateBonusGameScattersMultipliers(event);
    }
}
