var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      {
        domProps: { value: _vm.value },
        on: { change: _vm.updateMixAndMatchSelected },
      },
      [
        _c("option", { attrs: { value: "MINI" } }, [_vm._v("MINI")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MINOR" } }, [_vm._v("MINOR")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "MAJOR" } }, [_vm._v("MAJOR")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "GRAND" } }, [_vm._v("GRAND")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }