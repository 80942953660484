var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTrigger()
              },
            },
          },
          [_vm._v("FreeSpin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("FreeSpin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusTrigger()
              },
            },
          },
          [_vm._v("Bonus Trigger")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: true,
                expression: "true",
              },
            ],
            staticClass: "sub-title-menu",
          },
          [
            _c("div", { staticClass: "flex-center fs-selector sm-label" }, [
              _c("div", { staticClass: "select-style space" }, [
                _c("label", { attrs: { for: "bg_number" } }, [
                  _vm._v("Feature type: "),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.featureType,
                        expression: "featureType",
                      },
                    ],
                    staticStyle: {
                      color: "#00c700",
                      "font-weight": "bold",
                      "font-size": "small",
                      "padding-left": "50px",
                    },
                    attrs: { id: "bg_number" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.featureType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.updateStep(_vm.featureType)
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("Symbol")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("Wild")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("2x")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("3x")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpen,
                      expression: "isOpen",
                    },
                  ],
                  staticClass: "select-style space",
                },
                [
                  _c("label", { attrs: { for: "coin_number" } }, [
                    _vm._v("Coins number: "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.coinNumber,
                          expression: "coinNumber",
                        },
                      ],
                      staticStyle: {
                        color: "#00c700",
                        "font-weight": "bold",
                        "font-size": "small",
                        "padding-left": "50px",
                      },
                      attrs: { id: "coin_number" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.coinNumber = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.updateStep(_vm.featureType)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpen,
                    expression: "isOpen",
                  },
                ],
                staticClass: "flex-center fs-selector sm-label select-coin",
              },
              _vm._l(parseInt(_vm.coinNumber), function (n) {
                return _c("selectCoinMultiplier", {
                  key: n,
                  attrs: { index: n, coinValue: _vm.coinValue },
                })
              }),
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }