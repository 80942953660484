import { createSlotWindowGenerator } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../choices';
import { modelDefinition } from '../../model-definition';
import type { GameState } from '../shared';
import { createWinsEvaluator } from './create-wins-evaluator';
import type { GameRequest } from './game-request';
import type { SpinOutcome } from './outcome';
import { getWinType } from './get-win-type';
import type { coinType } from '../../coin-type';

export function spin({ coinType, gameRequest }: BaseSpinInputs, choices: Choices): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

    // Generate Slot Window
    const reelStripPositions = choices.chooseReelStripPositions();
    const slotWindow = generateSlotWindow(reelStripPositions);

    // Evaluate ways wins
    const winInfo = evaluateWins({ slotWindow, coinAmount, playAmount, coinType, choices });

    const winAmount = winInfo.winAmount;

    // Calculate new game state
    const freeSpinsTriggered = winInfo.bonusWin !== undefined;

    const numberOfScatters = winInfo.scatterWin?.cells.length;
    const isGrandTriggered =
        winInfo.isSuperStarAwarded && numberOfScatters === modelDefinition.scatterSymbolsToTriggerProgressiveJackpots;

    const winType = getWinType({
        winAmount,
        isScatterWin: winInfo.scatterWin !== undefined,
        isFreeSpinTrigger: freeSpinsTriggered,
        isFreeSpin: false,
        isJackpotTrigger: isGrandTriggered,
    });

    const jackpotWinCounts = isGrandTriggered ? { GRAND: 1 } : undefined;

    const roundComplete = !freeSpinsTriggered;
    const gameState: GameState | undefined =
        winInfo.bonusWin === undefined
            ? undefined
            : {
                  reelStripPositions,
                  coinAmount,
                  slotWindow,
                  cumulativeWinAmount: winAmount,
                  freeSpinCount: modelDefinition.freeSpinTriggerCount[winInfo.bonusWin?.cells.length - 1],
                  freeSpinPhase: 'START',
              };

    return {
        playSummary: {
            playAmount,
            winAmount,
            roundComplete,
            jackpotWinCounts,
        },
        gameResponse: {
            playAmount,
            winAmount,
            cumulativeWinAmount: gameState?.cumulativeWinAmount,

            reelStripPositions,
            slotWindow,

            waysWins: winInfo.waysWins,
            winCellsBySymbol: winInfo.winCellsBySymbol,
            scatterWin: winInfo.scatterWin,
            bonusWin: winInfo.bonusWin,
            winType,
            coinAmount: gameRequest.coinAmount,
            freeSpinCount: gameState?.freeSpinCount,
            freeSpinPhase: gameState?.freeSpinPhase,
            jackpotWinCounts,
            isSuperStarAwarded: winInfo.isSuperStarAwarded,
        },
        gameState,
    };
}

export type BaseSpinInputs = {
    coinType: coinType;
    gameRequest: GameRequest;
};

const evaluateWins = createWinsEvaluator(modelDefinition);
const generateSlotWindow = createSlotWindowGenerator({
    ...modelDefinition,
});
