// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content[data-v-3f5575a7] {
}
.sm-game-spec-content .flex[data-v-3f5575a7] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-3f5575a7] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-3f5575a7] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-3f5575a7] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-3f5575a7] {
    margin: 5px 0 5px 0;
}
.sm-game-spec-content .btn-toggle-is-feature[data-v-3f5575a7] {
    background-color: #024802;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw016/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".sm-game-spec-content[data-v-3f5575a7] {\n}\n.sm-game-spec-content .flex[data-v-3f5575a7] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-3f5575a7] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-3f5575a7] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-3f5575a7] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-3f5575a7] {\n    margin: 5px 0 5px 0;\n}\n.sm-game-spec-content .btn-toggle-is-feature[data-v-3f5575a7] {\n    background-color: #024802;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
