var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPopup,
          expression: "showPopup",
        },
      ],
      staticClass: "sm-input-popup",
    },
    [
      _c("div", { staticClass: "cover" }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("table", { attrs: { width: "100%", height: "100%" } }, [
          _c("tr", [
            _c("td", { attrs: { valign: "middle", align: "center" } }, [
              _c("div", { staticClass: "info-window" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.message)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputText,
                        expression: "inputText",
                      },
                    ],
                    ref: "theInput",
                    staticClass: "the-input",
                    attrs: { placeholder: "edit me" },
                    domProps: { value: _vm.inputText },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.inputText = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doOK()
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doCancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }