// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content .flex-center[data-v-34938b78] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .menu[data-v-34938b78] {
    margin: 5px 0 5px 0;
}
.sm-game-spec-content .sub-title-menu[data-v-34938b78] {
    padding-top: 20px;
    padding-bottom: 20px;
}
.sm-game-spec-content .label[data-v-34938b78] {
    font-size: 12px;
    text-align: right;
    padding: 5px 2px 2px 20px;
    color: #a0a0a0;
    border-radius: 3px;
    margin: 1px;
}
.sm-game-spec-content .select-style[data-v-34938b78] {
    width: auto;
}
.sm-game-spec-content .button-with-drop-down[data-v-34938b78] {
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    padding: 10px 7px 10px 7px;
    color: #a0a0a0;
    background-color: #474747;
    border-radius: 3px;
    margin: 1px;
}
.sm-game-spec-content .button-with-drop-down > select[data-v-34938b78] {
    cursor: pointer;
    width: 100px;
    overflow: hidden;
    color: #a0a0a0;
    background-color: #474747;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw078/content.vue.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,0BAA0B;IAC1B,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".sm-game-spec-content .flex-center[data-v-34938b78] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .menu[data-v-34938b78] {\n    margin: 5px 0 5px 0;\n}\n.sm-game-spec-content .sub-title-menu[data-v-34938b78] {\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n.sm-game-spec-content .label[data-v-34938b78] {\n    font-size: 12px;\n    text-align: right;\n    padding: 5px 2px 2px 20px;\n    color: #a0a0a0;\n    border-radius: 3px;\n    margin: 1px;\n}\n.sm-game-spec-content .select-style[data-v-34938b78] {\n    width: auto;\n}\n.sm-game-spec-content .button-with-drop-down[data-v-34938b78] {\n    cursor: pointer;\n    font-size: 12px;\n    text-align: center;\n    padding: 10px 7px 10px 7px;\n    color: #a0a0a0;\n    background-color: #474747;\n    border-radius: 3px;\n    margin: 1px;\n}\n.sm-game-spec-content .button-with-drop-down > select[data-v-34938b78] {\n    cursor: pointer;\n    width: 100px;\n    overflow: hidden;\n    color: #a0a0a0;\n    background-color: #474747;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
