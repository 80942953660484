import { modelDefinition } from '../../../../model-definition';
import { createWinsEvaluator, type WinInfo } from '../../create-wins-evaluator';
import { type CharacterWildsFeatureResult, playCharacterWildsFeature } from '../character-wilds';
import { playFullReelWildsFeature, type FullReelWildsFeatureResult } from '../full-reel-wilds';
import { type MultiplierCell, type MultiplierFeatureResult, playMultiplierFeature } from '../multipliers';
import { type SingleWildsFeatureResult, playSingleWildsFeature } from '../single-wilds';
import type { CharacterWilds, CharacterWildsWithPositions } from '../../../shared/reel-spin-features-response';

export function applyReelSpinFeatures({
    features,
    newSlotWindow,
    coinAmount,
    playAmount,
    featuresAwarded,
}: {
    features: string[];
    newSlotWindow: string[][];
    coinAmount: number;
    playAmount: number;
    featuresAwarded: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWilds;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
}): {
    winInfoAfterFeature: WinInfo;
    newSlotWindow: string[][];
    lineWinTotalAfterFeatures: number;
    featureResults: {
        fullReelWilds?: number[];
        characterWilds?: CharacterWildsWithPositions;
        singleWilds?: [number, number][];
        multiplierCells?: MultiplierCell[];
    };
} {
    let fullReelWildsFeatureResults: FullReelWildsFeatureResult | undefined;
    let characterWildsFeatureResults: CharacterWildsFeatureResult | undefined;
    let singleWildsFeatureResults: SingleWildsFeatureResult | undefined;
    let multiplierFeatureResults: MultiplierFeatureResult | undefined;
    for (const feature of features) {
        if (feature === 'FRW') {
            fullReelWildsFeatureResults = playFullReelWildsFeature(newSlotWindow, featuresAwarded?.fullReelWilds);
            newSlotWindow = fullReelWildsFeatureResults.slotWindow;
        } else if (feature === 'CW') {
            characterWildsFeatureResults = playCharacterWildsFeature({
                slotWindow: newSlotWindow,
                characterWilds: featuresAwarded.characterWilds,
            });
            newSlotWindow = characterWildsFeatureResults.slotWindow;
        } else if (feature === 'SW') {
            singleWildsFeatureResults = playSingleWildsFeature({
                slotWindow: newSlotWindow,
                singleWilds: featuresAwarded.singleWilds,
            });
            newSlotWindow = singleWildsFeatureResults.slotWindow;
        } else if (feature === 'M') {
            multiplierFeatureResults = playMultiplierFeature({
                slotWindow: newSlotWindow,
                multiplierCells: featuresAwarded.multiplierCells,
            });
        }
    }

    const winInfoAfterFeature = evaluateWins({
        slotWindow: newSlotWindow,
        coinAmount,
        playAmount,
        additionalMultiplersByCell: multiplierFeatureResults?.multiplierCells,
    });

    const lineWinTotalAfterFeatures = winInfoAfterFeature.lineWins.reduce(
        (total, lineWin) => total + lineWin.winAmount,
        0,
    );

    return {
        winInfoAfterFeature,
        newSlotWindow,
        lineWinTotalAfterFeatures,
        featureResults: {
            fullReelWilds: fullReelWildsFeatureResults?.fullReelWilds,
            characterWilds: characterWildsFeatureResults?.characterWilds,
            singleWilds: singleWildsFeatureResults?.singleWilds,
            multiplierCells: multiplierFeatureResults?.multiplierCells,
        },
    };
}

const evaluateWins = createWinsEvaluator(modelDefinition);
