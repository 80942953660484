import Vue from 'vue';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import {getReelWindowFromIndex} from "../../../../helpers/helpers";
import Reel from './reel.vue';
import {
    createClearingStep,
    createSpinStep,
    createFreeSpinTriggerStep,
    createPIC1StackReSpinTrigger,
    createPIC2StackReSpinTrigger,
    createPIC3StackReSpinTrigger,
    createPIC4StackReSpinTrigger,
    modelDefinition,
    StepJson,
    originatorId,
    StackPICSymbol,
} from '../../../../state/models/slots/vgw086';


export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel
    },

    data: () => ({
        allSteps: [] as Step[],
        selected: 'default'
    }),

    computed: {
        // ----- General step state -----
        show(): boolean {
            return this.currentStep !== undefined;
        },
        currentStep(): Step | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.find(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        currentStepIndex(): number | undefined {
            if (!this.step || !this.allSteps) return;
            return this.allSteps.findIndex(s => s.test_scenario_step_id === this.step.test_scenario_step_id);
        },
        labelForAddSpinStep(): string {
            if (['START', 'IN_PROGRESS'].includes(this.finalStep?.stackReSpinPhase ?? '')) return 'Add Stack ReSpin'
            if (this.finalStep?.freeSpinCount) return 'Add Free Spin'
            return 'Add Base Spin';
        },
        labelForAddFreeSpinTriggerStep(): string {
            if(this.finalStep?.freeSpinCount) return 'ReTrigger Free Spins'
            return 'Trigger Free Spins'
        },
        stepName(): string {
            return this.currentStep?.json.name ?? '';
        },
        finalStep(): Step | undefined {
            return this.allSteps[this.allSteps.length - 1];
        },
        isClearingStep(): boolean {
            return this.stepName === 'Clear';
        },
        stepDescription(): string {
            if (this.stepName === 'Clear') return 'Clearing Step';
            if (this.stepName === 'SR-Trigger') return 'Stack ReSpin Trigger';
            if (this.stepName === 'Stack ReSpin') return 'Stack ReSpin';
            if (this.stepName === 'SR-End') return 'Stack ReSpin End';
            if (this.stepName === 'F-Trigger') return 'Free Spin Trigger';
            if (this.stepName === 'F-Retrigger') return 'Free Spin Retrigger';
            if (this.isFreeSpin) return 'Free Spin';
            return 'Base Spin';
        },
        canAddFreeSpinTriggerStep(): boolean {
            return !this.finalStep?.stackReSpinPhase || this.finalStep?.stackReSpinPhase === 'END'
        },
        canAddStackReSpinTriggerStep(): boolean {
            return !this.finalStep?.stackReSpinPhase || this.finalStep?.stackReSpinPhase === 'END'
        },

        // ----- Base game state -----
        reels(): string[][] {
            return modelDefinition.reels;
        },
        canChangeReelStripPositions(): boolean {
            const stackReSpinPhase = this.currentStep?.stackReSpinPhase;
            const isStackReSpinSpin = ['IN_PROGRESS', 'END'].includes(stackReSpinPhase ?? '');
            return !this.isClearingStep && !isStackReSpinSpin;
        },
        isFreeSpin(): boolean {
            const freeSpinPhase = this.currentStep?.freeSpinPhase;
            return freeSpinPhase === 'IN_PROGRESS' || freeSpinPhase === 'RETRIGGER' || freeSpinPhase === 'END';
        },
        freeSpinsRemaining(): number {
            return this.currentStep?.freeSpinCount ?? 0;
        },
        reelStripPositions(): number[] {
            return this.currentStep?.json?.reelStripPositions || [];
        },
        addedWilds(): Array<[number, number]> {
            return this.currentStep?.json?.addedWilds || [];
        },
        
        // ----- Stack ReSpin -----
        isStackReSpin(): boolean {
            const stackReSpinPhase = this.currentStep?.stackReSpinPhase
            return stackReSpinPhase === 'START' || stackReSpinPhase === 'IN_PROGRESS' || stackReSpinPhase === 'END';
        },
        stackedSymbol(): string | undefined {
            return this.currentStep?.stackedPICSymbol;
        },
        canAddMoreGoldenStackSymbols(): boolean {
            if(!this.currentStep) return false;
            if(!this.currentStep.cumulativeStackPICSymbols) return false;

            const goldenStackSymbols = this.currentStep.cumulativeStackPICSymbols.filter(({isGold}) => isGold)
            return goldenStackSymbols.length < 3;
        },
    },

    methods: {
        // Step adding functions
        addClearingStep() {
            bus.$emit(events.ADD_STEP, createClearingStep());
        },
        addSpinStep() {
            bus.$emit(events.ADD_STEP, createSpinStep());
        },
        addFreeSpinTriggerStep() {
            bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep());
        },
        addStackReSpinTriggerStep(picSymbol: string) {
            this.selected = 'default';
            let createStackReSpinTrigger: () => StepJson;
            switch(picSymbol) {
                case 'PIC1':
                    createStackReSpinTrigger = createPIC1StackReSpinTrigger;
                    break;
                case 'PIC2':
                    createStackReSpinTrigger = createPIC2StackReSpinTrigger;
                    break;
                case 'PIC3':
                    createStackReSpinTrigger = createPIC3StackReSpinTrigger;
                    break;
                case 'PIC4':
                    createStackReSpinTrigger = createPIC4StackReSpinTrigger;
                    break;
                default:
                    createStackReSpinTrigger = createPIC1StackReSpinTrigger;
            }
            bus.$emit(events.ADD_STEP, createStackReSpinTrigger());
        },

        // ----- Added Wilds -----
        canCellHaveAddedWild( reelIndex: number, symbol: string) {
            if(reelIndex === 0 || reelIndex === 5) return false
            return ![modelDefinition.wildSymbol, modelDefinition.scatterSymbol].includes(symbol);
        },
        isCellAddedWild(reelIndex: number, rowIndex: number): boolean {
            return this.addedWilds.some((cell) => cell[0] === rowIndex && cell[1] === reelIndex);
        },
        toggleAddedWildSymbol(reelIndex: number, rowIndex: number) {
            if(!this.currentStep) return;
            if(!this.currentStep.json?.addedWilds) {
                this.currentStep.json.addedWilds = [];
            }
            
            const cellToAddWildTo: [number, number] = [rowIndex, reelIndex];
            const addedWilds = this.addedWilds;
            
            if(this.isCellAddedWild(reelIndex, rowIndex)) {
                const addedWildsWithoutIndex = addedWilds.filter((cell) => !areCellsEqual(cell, cellToAddWildTo));
                this.currentStep.json.addedWilds = addedWildsWithoutIndex;
            } else {
                this.currentStep.json.addedWilds?.push(cellToAddWildTo);
            }

            this.saveChangesToStep();
        },

        // ----- Stack ReSpin -----
        isStackedPICSymbol(reelIndex: number, rowIndex: number) {
            if(!this.currentStep?.cumulativeStackPICSymbols) return;
            const cellToCheck: [number, number] = [rowIndex, reelIndex];

            return this.currentStep?.cumulativeStackPICSymbols.some(({ cell }) => areCellsEqual(cell, cellToCheck))
        },
        isStackPICSymbolThisStep(reelIndex: number, rowIndex: number) {
            if(!this.currentStep || !this.currentStep?.json.stackPicSymbolCells) return;

            const cellToCheck: [number, number] = [rowIndex, reelIndex];

            return this.currentStep.json.stackPicSymbolCells?.some(({ cell }) => areCellsEqual(cell, cellToCheck))
        },
        toggleStackPICSymbol(reelIndex: number, rowIndex: number) {
            if(!this.currentStep) return;

            this.currentStep.json.stackPicSymbolCells = this.currentStep.json.stackPicSymbolCells ?? []

            const cellToCheck: [number, number] = [rowIndex, reelIndex];
            if(this.isStackedPICSymbol(reelIndex, rowIndex)) {
                const filteredStackedPICSymbols = this.currentStep?.json.stackPicSymbolCells.filter(({ cell }) => !areCellsEqual(cell, cellToCheck));
                this.currentStep.json.stackPicSymbolCells = filteredStackedPICSymbols;
            } else {
                this.currentStep.json.stackPicSymbolCells.push({ cell: [rowIndex, reelIndex], isGold: false})
            }
            
            this.saveChangesToStep();
        },
        isCellGoldenStackSymbol(reelIndex: number, rowIndex: number) {
            if(!this.currentStep) return;
            if(!this.currentStep.cumulativeStackPICSymbols) return;

            const stackedSymbol = this.currentStep.cumulativeStackPICSymbols.find(({cell}) => areCellsEqual(cell, [rowIndex, reelIndex]))

            return stackedSymbol?.isGold;
        },
        isCellGoldenStackSymbolThisStep(reelIndex: number, rowIndex: number) {
            return this.isCellGoldenStackSymbol(reelIndex, rowIndex) && this.isStackPICSymbolThisStep(reelIndex, rowIndex);
        },
        toggleGoldCell(reelIndex: number, rowIndex: number) {
            if(!this.currentStep) return;
            if(!this.isStackPICSymbolThisStep(reelIndex, rowIndex)) return;

            const cellToFind: [number, number] = [rowIndex, reelIndex];
            const stackedPICSymbol = this.currentStep.json.stackPicSymbolCells?.find(({cell}) => areCellsEqual(cell, cellToFind));
            if(!stackedPICSymbol) return;

            stackedPICSymbol.isGold = !stackedPICSymbol.isGold;

            this.saveChangesToStep();
        },

        // ----- Scenario persistence methods -----
        saveChangesToStep(): void {
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            updateStepsInPlace(this.allSteps);

            // Marking all the steps as having originated here, and persist them.
            this.allSteps.forEach(step => step.json.originatorId = originatorId);
            bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
        reloadStepsFromScenario(scenario?: {steps: Array<{test_scenario_step_id: number, json: StepJson}>}) {
            // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
            // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
            // trips result in already-outdated scenario states being sent here.
            const newSteps: Step[] = [];
            for (const incomingStep of scenario?.steps ?? []) {
                const existingStep = this.allSteps.find(s => s.test_scenario_step_id === incomingStep.test_scenario_step_id);
                const step = existingStep && incomingStep.json.originatorId === originatorId ? existingStep : incomingStep;
                newSteps.push(step);
            }
            // Traverse all steps, ensuring the state transitions are all valid and correct.
            const isModified = updateStepsInPlace(newSteps);
            this.allSteps = newSteps;
            // If any of the steps needed modifying, persist the changes.
            if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
        },
    },

    mounted() {
        // Emitted after a step is added, deleted, or edited
        bus.$on(events.UPDATE_STEPS, scenario => this.reloadStepsFromScenario(scenario));

        // Emitted after steps are re-ordered, or the users selects a different scenario
        bus.$on(events.CHANGE_SCENARIO, scenario => this.reloadStepsFromScenario(scenario));
    },
});

interface Step {
    test_scenario_step_id: number;
    json: StepJson;
    isNextStepPick?: boolean;
    freeSpinPhase?: 'START' | 'IN_PROGRESS' | 'RETRIGGER' | 'END';
    freeSpinCount?: number;
    stackReSpinPhase?: 'START' | 'IN_PROGRESS' | 'END';
    cumulativeStackPICSymbols?: StackPICSymbol[],
    stackedPICSymbol?: string,
}

function updateStepsInPlace(steps: Step[]): boolean {
    let name = '';
    let isFreeSpin = false;
    let freeSpinPhase: Step['freeSpinPhase'];
    let freeSpinCount = 0;
    let stackReSpinPhase: Step['stackReSpinPhase'];
    let cumulativeStackPICSymbols: StackPICSymbol[] = [];
    let stackedPICSymbolsThisStep: StackPICSymbol[] | undefined;
    let stackedPICSymbol: string | undefined;

    let addedWilds: Array<[number, number]> | undefined;

    let isAnyStepModified = false;
    for(const step of steps) {
        // Handle clearing step by clearing all state
        if (step.json.name === 'Clear') {
            name = step.json.name;
            freeSpinPhase = undefined;
            freeSpinCount = 0;

            addedWilds = undefined;

            stackReSpinPhase = undefined;
            cumulativeStackPICSymbols = [];
            stackedPICSymbolsThisStep = undefined;
            stackedPICSymbol = undefined;
        } else {
            const isFreeSpin = freeSpinCount > 0;
            const scatCells = findCells(step.json.reelStripPositions, modelDefinition.scatterSymbol);
            const freeSpinsAwarded = getFreeSpinsAwarded(scatCells.length);
            const isFreeSpinTrigger = freeSpinsAwarded > 0;
            addedWilds = step.json.addedWilds;
            stackedPICSymbolsThisStep = step.json.stackPicSymbolCells;
            
            const isStackReSpin = stackReSpinPhase === 'START' || stackReSpinPhase === 'IN_PROGRESS';
            if ( isStackReSpin ) {
                name = 'Stack ReSpin'
                addedWilds = undefined;

                stackedPICSymbolsThisStep = stackedPICSymbolsThisStep?.filter(
                    ({cell: cell1}) => !cumulativeStackPICSymbols.some(({cell: cell2}) => areCellsEqual(cell1, cell2) )
                );

                const numberOfGoldSymbolsBeforeStep = cumulativeStackPICSymbols.filter(({ isGold }) => isGold).length
                const numberGoldSymbols = stackedPICSymbolsThisStep?.filter(({ isGold }) => isGold).length ?? 0;
                
                if (numberOfGoldSymbolsBeforeStep + numberGoldSymbols > 3) {
                    stackedPICSymbolsThisStep = stackedPICSymbolsThisStep?.map(({ cell }) => ({cell, isGold: false}) )
                }
                
                cumulativeStackPICSymbols = [...cumulativeStackPICSymbols, ...stackedPICSymbolsThisStep ?? []];
                const hasBlankCells = cumulativeStackPICSymbols.length < 24;

                if(!stackedPICSymbolsThisStep || stackedPICSymbolsThisStep.length === 0 || !hasBlankCells) {
                    stackReSpinPhase = 'END'
                    name = 'SR-End'
                } else {
                    stackReSpinPhase = 'IN_PROGRESS'
                }
            } else {
                name = 'Spin'
                stackReSpinPhase = undefined;
                stackedPICSymbol = undefined;
                cumulativeStackPICSymbols = [];
                
                if(isFreeSpin) {
                    name = 'Free Spin'
                    freeSpinCount -= 1;
                    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';
                } else {
                    freeSpinPhase = undefined;
                }
                if (isFreeSpinTrigger) {
                    name = isFreeSpin ? 'F-Retrigger' : 'F-Trigger';
                    freeSpinCount += freeSpinsAwarded;
                    freeSpinPhase = isFreeSpin ? 'RETRIGGER' : 'START';
                }

                if(hasPICStack(step.json.reelStripPositions)) {
                    name = 'SR-Trigger'
                    stackReSpinPhase = 'START'
                    addedWilds = undefined;
                    stackedPICSymbol = modelDefinition.reels[0][step.json.reelStripPositions[0]]

                    if(!stackedPICSymbolsThisStep) stackedPICSymbolsThisStep = [];

                    const hasStackSymbolOnReel2 = stackedPICSymbolsThisStep.some(({ cell }) => cell[1] === 1);
                    if(!hasStackSymbolOnReel2) {
                        stackedPICSymbolsThisStep.push({ cell: [2, 1], isGold: false });
                    }

                    const hasStackSymbolOnReel3 = stackedPICSymbolsThisStep.some(({ cell }) => cell[1] === 2);
                    if(!hasStackSymbolOnReel3) {
                        stackedPICSymbolsThisStep.push({ cell: [0, 2], isGold: false });
                    }
                    
                    cumulativeStackPICSymbols = [...stackedPICSymbolsThisStep ?? []];
                    const reel1Height = modelDefinition.reelsLayout[0];
                    for(let rowIndex = 0; rowIndex < reel1Height; rowIndex++) {
                        cumulativeStackPICSymbols.push({
                            cell: [rowIndex, 0],
                            isGold: false,
                        });
                    }
                } else {
                    stackedPICSymbolsThisStep = undefined;
                }
            }
        }

        Object.assign(step, {
            name, isFreeSpin, 
            freeSpinPhase, freeSpinCount,
            stackReSpinPhase, cumulativeStackPICSymbols, stackedPICSymbol
        })
        console.log(step);
        const json = {...step.json, name, addedWilds, stackPicSymbolCells: stackedPICSymbolsThisStep};
        if (JSON.stringify(json) !== JSON.stringify(step.json)) {
            isAnyStepModified = true;
            step.json = json;
            json.originatorId = originatorId;
        }
    }

    return isAnyStepModified;
}

function findCells(reelStripPositions: number[], symbol: string): Array<[number, number]> {
    const reels = modelDefinition.reels;
    const cells: Array<[number, number]> = [];
    for (let col = 0; col < reels.length; ++col) {
        const windowHeight = modelDefinition.reelsLayout[col];
        const reelWindow = getReelWindowFromIndex(reels[col], reelStripPositions[col], windowHeight);
        for (let row = 0; row < windowHeight; ++row) {
            if (reelWindow[row] === symbol) {
                cells.push([row, col]);
            }
        }
    }
    return cells;
}

function getFreeSpinsAwarded(scatCount: number): number {
    return modelDefinition.freeSpinsAwarded[modelDefinition.reelsLayout.length - scatCount] ?? 0;
}

function areCellsEqual(cell1: [number, number], cell2: [number, number]) {
    return cell1[0] === cell2[0] && cell1[1] === cell2[1]
}

function hasPICStack(reelStripPositions: number[]) {
    const reel1 = modelDefinition.reels[0];
    const reelHeight = modelDefinition.reelsLayout[0];
    const reel1Window = getReelWindowFromIndex(reel1, reelStripPositions[0], reelHeight);

    const uniqueSymbols = new Set(reel1Window);

    return uniqueSymbols.size === 1 && ['PIC1', 'PIC2', 'PIC3', 'PIC4'].includes(reel1Window[0]);
}
