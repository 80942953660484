import Vue from 'vue';
import {trace} from './../../../../helpers/helpers';
import {CascadeScenario, setStateParams} from '../../../../state/models/slots/vgw078';
import bus from '../../../../common/bus';
import events from '../../../../common/events'; 
import Reel from './../reels/reel.vue';

const state = setStateParams();

export default Vue.component('Content', {
    props: ['step'],

    components: {
        reel: Reel,
    },

    data: () => ({
        normalWins: state.getCascadeScenarios('NORMAL'),
        bigWins: state.getCascadeScenarios('BIG'),
        megaWins: state.getCascadeScenarios('MEGA'),
        epicWins: state.getCascadeScenarios('EPIC'),
        jackpotCountdownNumbers: state.getJackpotCountdownNumbers(),
        selectedWin: undefined as CascadeScenario | undefined,
        dummyStep: {json: {...state.getBaseStep()}},
    }),

    computed: {
        show(): boolean {
            return this.step !== undefined;
        },
        showReels(): boolean {
            return this.step?.json?.reelStripPositions !== undefined;
        },
        showJackpotCountdownNumber(): boolean {
            return this.step?.json?.isFeatureTrigger === true;
        },
        isFeature(): boolean {
            return this.step?.json?.isFeature !== undefined;
        },
        jackpotCountdownNumber: {
            get(): number | undefined {
                return this.step?.json.jackpotCountdown;
            },
            set(newVal: number | undefined): void {
                this.step.json.jackpotCountdown = newVal;
                bus.$emit(events.UPDATE_STEPS_DB, this.step);
            },
        },
    },

    methods: {
        addClearingStep() {
            bus.$emit(events.ADD_STEP, state.getClearingStep());
        },
        addBaseStep() {
            bus.$emit(events.ADD_STEP, state.getBaseStep());
        },
        addFreeSpinStep() {
            bus.$emit(events.ADD_STEP, state.getFreeSpinStep());
        },
        addFillerStep() {
            bus.$emit(events.ADD_STEP, state.getFillerStep());
        },
        addCascadingWinSteps(scenario: CascadeScenario) {
            const steps = state.getCascadingWinSteps(scenario);
            bus.$emit(events.ADD_STEPS, steps);
        },
    },

    watch: {
        selectedWin(newVal) {
            if (newVal === undefined) return;
            this.addCascadingWinSteps(newVal);
            this.selectedWin = undefined;
        },
    },

    mounted() {
        trace('reels mounted');
    },
});
