var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Feature Retrigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer bluebg",
            on: {
              click: function ($event) {
                return _vm.addMinorJackpotStep()
              },
            },
          },
          [_vm._v("Minor Jackpot")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer greenbg",
            on: {
              click: function ($event) {
                return _vm.addMajorJackpotStep()
              },
            },
          },
          [_vm._v("Major Jackpot")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center sub-title-menu" }, [
        _c(
          "div",
          {
            class: [
              "button",
              "step-button",
              "pointer",
              _vm.isFeature ? "btn-toggle-is-feature" : "",
            ],
            on: {
              click: function ($event) {
                return _vm.toggleFeature()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("reel", {
            attrs: { index: 0, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 1, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 2, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 3, step: _vm.step, isFeature: _vm.isFeature },
          }),
          _vm._v(" "),
          _c("reel", {
            attrs: { index: 4, step: _vm.step, isFeature: _vm.isFeature },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }