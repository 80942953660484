var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "outer" }, [
    _c("h2", [_vm._v("Scenario Setup")]),
    _vm._v(" "),
    _c("div", { staticClass: "card general-controls" }, [
      _c("p", [
        _c("label", { attrs: { for: "mystery-symbol" } }, [
          _vm._v("Mystery Symbol:"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.step.mysterySymbol,
                expression: "step.mysterySymbol",
              },
            ],
            attrs: { id: "mystery-symbol" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.step,
                  "mysterySymbol",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: undefined } }, [
              _vm._v(
                "\n          Default: " +
                  _vm._s(_vm.mysterySymbolOptions[0]) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.mysterySymbolOptions, function (mysterySymbol) {
              return _c("option", { domProps: { value: mysterySymbol } }, [
                _vm._v("\n          " + _vm._s(mysterySymbol) + "\n        "),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", { attrs: { for: "wild-fury" } }, [_vm._v("Wild Fury:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.step.wildFury,
                expression: "step.wildFury",
              },
            ],
            attrs: { id: "wild-fury", disabled: !_vm.canTriggerWildFury },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.step,
                  "wildFury",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: undefined } }, [
              _vm._v("Default: No"),
            ]),
            _vm._v(" "),
            _c("option", { domProps: { value: true } }, [_vm._v("Yes")]),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("No")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", { attrs: { for: "game-state" } }, [
          _vm._v("Clear Game State:"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.step.clearGameState,
                expression: "step.clearGameState",
              },
            ],
            attrs: { id: "game-state" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.step,
                  "clearGameState",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { domProps: { value: true } }, [_vm._v("Yes")]),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [_vm._v("No")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reel-position-selectors card" },
      _vm._l(_vm.step.reelStripPositions, function (position, reelIndex) {
        return _c("input", {
          attrs: { type: "number" },
          domProps: { value: position.value },
          on: {
            input: function ($event) {
              position.value = _vm.updatePosition($event, reelIndex)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }