var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "scenario-manager" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCover,
          expression: "showCover",
        },
      ],
      staticClass: "cover",
    }),
    _vm._v(" "),
    _c("div", { staticClass: "header" }, [
      _c(
        "a",
        {
          attrs: { href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.SMShow()
            },
          },
        },
        [_c("img", { attrs: { src: _vm.smImage, width: "30", height: "30" } })]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCover,
            expression: "showCover",
          },
        ],
        staticClass: "content",
      },
      [
        _c("table", { attrs: { width: "100%", height: "100%" } }, [
          _c("tr", [
            _c(
              "td",
              { attrs: { valign: "middle", align: "center" } },
              [
                _c("div", { staticClass: "sm-info" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.standalone,
                          expression: "standalone",
                        },
                      ],
                      staticClass: "flex",
                    },
                    [
                      _c("div", { staticClass: "select-style flex" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.gameId,
                                expression: "gameId",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.gameId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.gameIds, function (g) {
                            return _c("option", [_vm._v(_vm._s(g))])
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            "padding-top": "4px",
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("Cookie? "),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sendCookies,
                                expression: "sendCookies",
                              },
                            ],
                            staticClass: "text-input",
                            staticStyle: { width: "50px" },
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.sendCookies)
                                ? _vm._i(_vm.sendCookies, null) > -1
                                : _vm.sendCookies,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.sendCookies,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.sendCookies = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.sendCookies = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.sendCookies = $$c
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.sendCookies,
                              expression: "!sendCookies",
                            },
                          ],
                          staticStyle: { "margin-left": "10px" },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("UserId: "),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.userId,
                                expression: "userId",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "text-input",
                            staticStyle: { width: "50px" },
                            attrs: { type: "number" },
                            domProps: { value: _vm.userId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.userId = _vm._n($event.target.value)
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "margin-left": "20px" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "padding-top": "6px",
                              },
                            },
                            [_vm._v("Environment: ")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "select-style flex" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.environment,
                                    expression: "environment",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.environment = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.environments, function (e) {
                                return _c("option", [_vm._v(_vm._s(e))])
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "window" },
                  [
                    _c("sm-list-scenarios"),
                    _vm._v(" "),
                    _c("sm-list-steps"),
                    _vm._v(" "),
                    _vm.showGameContent
                      ? _c(
                          "div",
                          { staticClass: "width-100" },
                          [_c("sm-game-content")],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sm-input-popup"),
                _vm._v(" "),
                _c("sm-popup"),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }