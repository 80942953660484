import {modelDefinition} from '../../../../state/models/slots/vgw077/config/init';

export function getReels(wheel2Index?: number): string[][] {
    // Work out how many PIC1s to add to the base reels.
    let pic1sAdded = 0;
    if (wheel2Index !== undefined) {
         pic1sAdded = modelDefinition.freeSpins.wheel2[wheel2Index];
    }

    // Use base game reels if we are not adding any PIC1s.
    if (pic1sAdded === 0) return modelDefinition.reels;

    // Compute feature reels.
    const {reelIndexes} = modelDefinition.freeSpins.pic1Substitutions.find(el => el.pic1sAdded === pic1sAdded)!;
    const reels = modelDefinition.reels.map((reel, col) => {
        const positionsToSubstitute = reelIndexes[col];
        return reel.map((sym, pos) => positionsToSubstitute.includes(pos) ? 'PIC1' : sym);
    });
    return reels;
}
