/* in order to support standalone, please insert the init response from the server here */
export default {
  "success" : true,
  "messages" : [ ],
  "modelDefinition" : {
    "modelId" : "vgw013",
    "reelsLayout" : [ 3, 3, 3, 3, 3 ],
    "scatterSymbol" : "SCAT",
    "wildSymbol" : "WILD",
    "freeSpinMultiplier" : 0,
    "freeSpinCount" : 15,
    "winTable" : [ {
      "symbol" : "PIC1",
      "multipliers" : [ 1000, 500, 200, 3 ]
    }, {
      "symbol" : "PIC2",
      "multipliers" : [ 500, 200, 50, 2 ]
    }, {
      "symbol" : "PIC3",
      "multipliers" : [ 500, 100, 25 ]
    }, {
      "symbol" : "PIC4",
      "multipliers" : [ 250, 100, 10 ]
    }, {
      "symbol" : "PIC5",
      "multipliers" : [ 200, 50, 10 ]
    }, {
      "symbol" : "A",
      "multipliers" : [ 200, 50, 10 ]
    }, {
      "symbol" : "K",
      "multipliers" : [ 100, 50, 5 ]
    }, {
      "symbol" : "Q",
      "multipliers" : [ 100, 50, 5 ]
    }, {
      "symbol" : "J",
      "multipliers" : [ 100, 25, 5 ]
    }, {
      "symbol" : "10",
      "multipliers" : [ 100, 25, 5 ]
    }, {
      "symbol" : "9",
      "multipliers" : [ 100, 20, 5 ]
    }, {
      "symbol" : "SCAT",
      "multipliers" : [ 50, 10, 5, 1 ]
    } ],
    "playLines" : [ [ 1, 1, 1, 1, 1 ], [ 0, 0, 0, 0, 0 ], [ 2, 2, 2, 2, 2 ], [ 0, 1, 2, 1, 0 ], [ 2, 1, 0, 1, 2 ], [ 0, 0, 1, 2, 2 ], [ 2, 2, 1, 0, 0 ], [ 1, 0, 1, 2, 1 ], [ 1, 2, 1, 0, 1 ], [ 1, 0, 0, 1, 0 ], [ 1, 2, 2, 1, 2 ], [ 0, 1, 0, 0, 1 ], [ 2, 1, 2, 2, 1 ], [ 0, 2, 0, 2, 0 ], [ 2, 0, 2, 0, 2 ], [ 1, 0, 2, 0, 1 ], [ 1, 2, 0, 2, 1 ], [ 0, 1, 1, 1, 0 ], [ 2, 1, 1, 1, 2 ], [ 0, 2, 2, 2, 0 ], [ 2, 0, 0, 0, 2 ], [ 0, 1, 2, 2, 2 ], [ 2, 1, 0, 0, 0 ], [ 2, 0, 1, 0, 2 ], [ 0, 2, 1, 2, 0 ] ],
    "reels":
        [
          ["A","PIC1","K","PIC3","10","SCAT","K","PIC2","A","PIC4","Q","PIC2","J","PIC3","A","PIC4","9","PIC1","Q","PIC3","J","PIC5","A","PIC1","9","PIC3","A","PIC2","10","PIC1","Q","PIC4","9","PIC5","J","PIC2","Q","PIC3","10","PIC4"],
          ["Q","J","WILD","Q","J","PIC3","A","K","10","PIC1","Q","K","PIC2","10","J","Q","9","SCAT","Q","PIC3","9","10","A","SCAT","J","PIC4","9","10","PIC1","A","K","WILD","A","K","PIC4","J","10","9","J","A","PIC2","9","PIC5","K","A","PIC3","A","K","10","PIC1","Q","K","PIC2","10","J","Q","9","SCAT","Q","PIC3","9","10","A","SCAT","J","PIC4","9","10","PIC1","PIC4","J","10","9","J","A","PIC2","9","PIC5","K","A","Q","J","A","K","Q","J","A","K"],
          ["10","A","SCAT","10","PIC5","A","PIC3","10","Q","J","WILD","Q","J","10","PIC4","K","PIC2","9","PIC4","J","PIC3","K","Q","J","PIC2","Q","J","PIC3","K","A","PIC1","K","9","PIC1","K","A","Q","PIC5","9","PIC2","10","A","SCAT","10","PIC5","A","PIC3","10","Q","J","Q","J","10","PIC4","K","PIC2","9","PIC4","J","PIC3","K","Q","J","PIC2","Q","J","PIC3","K","A","PIC1","K","9","PIC1","K","A","Q","PIC5","9","PIC2","10","A","SCAT","10","PIC5","A","PIC3","10","Q","J","Q","J","10","PIC4","K","PIC2","9","PIC4","J","PIC3","K","Q","J","PIC2","Q","J","PIC3","K","A","PIC1","K","9","PIC1","K","A","Q","PIC5","9","PIC2"],
          ["9","Q","PIC2","K","Q","PIC1","J","PIC3","J","PIC2","10","PIC3","J","9","Q","PIC1","A","Q","PIC2","A","K","WILD","A","K","PIC4","Q","10","PIC4","K","J","WILD","K","J","PIC3","10","Q","A","SCAT","10","PIC4","A","9","PIC4","10","PIC5","9","Q","PIC2","K","Q","PIC1","J","PIC3","J","PIC2","10","PIC3","J","9","Q","PIC1","A","Q","PIC2","A","K","A","K","PIC4","Q","10","PIC4","K","J","K","J","PIC3","10","Q","A","SCAT","10","PIC4","A","9","PIC4","10","PIC5","9","Q","PIC2","K","Q","PIC1","J","PIC3","J","PIC2","10","PIC3","J","9","Q","PIC1","A","Q","PIC2","A","K","A","K","PIC4","Q","10","PIC4","K","J","K","J","PIC3","10","Q","A","SCAT","10","PIC4","A","9","PIC4","10","PIC5"],
          ["PIC5","A","10","PIC1","J","10","A","SCAT","9","A","Q","PIC4","K","J","PIC2","10","K","PIC3","Q","10","PIC1","J","Q","PIC3","10","Q","PIC5","J","Q","SCAT","K","J","9"]
        ],
    "featureReels":
        [
          ["A","PIC1","K","PIC3","10","SCAT","K","PIC2","A","PIC4","Q","PIC2","J","PIC3","A","PIC4","9","PIC1","Q","PIC3","J","PIC5","A","PIC1","9","PIC3","A","PIC2","10","PIC1","Q","PIC4","9","PIC5","J","PIC2","Q","PIC3","10","PIC4"],
          ["Q","J","WILD","Q","J","PIC3","A","K","10","PIC1","Q","K","PIC2","10","J","Q","9","SCAT","Q","PIC3","9","10","A","SCAT","J","PIC4","9","10","PIC1","A","K","WILD","A","K","PIC4","J","10","9","J","A","PIC2","9","PIC5","K","A"],
          ["10","A","SCAT","10","PIC5","A","PIC3","10","Q","J","WILD","Q","J","10","PIC4","K","PIC2","9","PIC4","J","PIC3","K","Q","J","PIC2","Q","J","PIC3","K","A","PIC1","K","9","PIC1","K","A","Q","PIC5","9","PIC2","10","A","SCAT","10","PIC5"],
          ["9","Q","PIC2","K","Q","PIC1","J","PIC3","J","PIC2","10","PIC3","J","9","Q","PIC1","A","Q","PIC2","A","K","WILD","A","K","PIC4","Q","10","PIC4","K","J","WILD","K","J","PIC3","10","Q","A","SCAT","10","PIC4","A","9","PIC4","10","PIC5"],
          ["PIC5","A","10","PIC1","J","10","A","SCAT","9","A","Q","PIC4","K","J","PIC2","10","K","PIC3","Q","10","PIC1","J","Q","PIC3","10","Q","PIC5","J","Q","SCAT","K","J","9"]
        ],
    "defaultSelectLine" : 0,
    "coinType1Values" : [ 250, 2500, 10000, 50000, 100000 ],
    "coinType4Values" : [ 1, 2, 5, 10, 50 ],
    "coinType1_min_bet" : 250,
    "coinType1_max_bet" : 100000,
    "coinType1_spin_max_bet" : 2500000,
    "coinType4_min_bet" : 1,
    "coinType4_max_bet" : 50,
    "coinType4_spin_max_bet" : 1250,
    "winLevels" : [ {
      "name" : "normal1",
      "threshold" : 5
    }, {
      "name" : "normal2",
      "threshold" : 15
    }, {
      "name" : "normal3",
      "threshold" : 25
    }, {
      "name" : "bigwin",
      "threshold" : 50
    }, {
      "name" : "megawin",
      "threshold" : 150
    }, {
      "name" : "epicwin",
      "threshold" : 2147483647
    } ],
    "freeSpinTrigger" : "SCAT",
    "countToTriggerFreeSpin" : 3,
    "windowRowsForRectReels" : 3,
    "wildDistribution" : [ 2, 3, 4 ],
    "currency_symbol" : "$"
  },
  "modelId" : "vgw013",
  "gameSessionId" : 17,
  "reelStripPositions" : [ 5, 43, 22, 43, 26 ],
  "slotWindow" : [ [ "SCAT", "K", "PIC2" ], [ "K", "A", "Q" ], [ "Q", "J", "PIC2" ], [ "PIC5", "9", "Q" ], [ "PIC5", "J", "Q" ] ],
  "gold_balance" : 2008650000,
  "sweeps_balance" : 206529229,
  "sweeps_deposit_balance" : 200001120,
  "sweeps_promo_balance" : 0,
  "sweeps_win_balance" : 6528109,
  "sweeps_available_balance" : 6528109,
  "serverTime" : "2019-05-30 10:46:57"
};
