import Vue from "vue";
import Component from "vue-class-component";
import Content from "../content.vue";

@Component({
    props: ['index', 'carrotsSelectedMultipliers'],
})
export default class selectCarrotMultiplier extends Vue {
    constructor() {
        super();
    }

    updateCarrot() {
        (<Content>this.$parent).updateBonusGameCarrotsMultipliers(event);
    }
}
