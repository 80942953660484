var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.updateBonus1Prize } },
      [
        _c("option", { attrs: { value: "FREE_SPIN" } }, [_vm._v("Freespin")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "JACKPOT" } }, [_vm._v("Jackpot")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE5" } }, [_vm._v("Prize 5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE6" } }, [_vm._v("Prize 6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE7" } }, [_vm._v("Prize 7")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE8" } }, [_vm._v("Prize 8")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE9" } }, [_vm._v("Prize 9")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE10" } }, [_vm._v("Prize 10")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE12" } }, [_vm._v("Prize 12")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE15" } }, [_vm._v("Prize 15")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "PRIZE20" } }, [_vm._v("Prize 20")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }