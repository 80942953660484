var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canUpdate,
              expression: "canUpdate",
            },
          ],
          attrs: { align: "center" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number", disabled: !_vm.canUpdate },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canUpdate,
              expression: "canUpdate",
            },
          ],
          staticClass: "flex-center",
          staticStyle: { "font-size": "12px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "pointer",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-up",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", [
            _c(
              "div",
              { class: ["symbol", "on", sym] },
              [
                sym === "SCAT"
                  ? [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.prize,
                              expression: "prize",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.prize = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _vm._l(_vm.prizeValues, function (prizeValue) {
                            return [
                              _c(
                                "option",
                                { domProps: { value: prizeValue } },
                                [_vm._v("🎁  " + _vm._s(prizeValue))]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  : [_c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))])],
              ],
              2
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canUpdate,
              expression: "canUpdate",
            },
          ],
          staticClass: "flex-center",
          staticStyle: { "font-size": "12px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "pointer",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-down",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }