var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Feature Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStart()
              },
            },
          },
          [_vm._v("Respin Start")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinSelect()
              },
            },
          },
          [_vm._v("Bonus Game Select")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusGameStep()
              },
            },
          },
          [_vm._v("Bonus Game Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addWheelWinStep()
              },
            },
          },
          [_vm._v("Wheel Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isRespinSelect && !_vm.isBonusGameStep,
              expression: "!isRespinSelect && !isBonusGameStep",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c(
            "div",
            {
              class: ["button", "step-button", "pointer", ""],
              on: {
                click: function ($event) {
                  return _vm.toggleFeature()
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isFeature ? "Step is Feature" : "Step is Base") +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature,
              expression: "isFeature",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [
            _vm._v("Reelset (0 until first wheel win):"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.reelSetOption,
                  expression: "reelSetOption",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "reelSetOption", id: "reelSetOption" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.reelSetOption = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeReelSetOption,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "" } }, [
                _vm._v("0"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFeature,
              expression: "isFeature",
            },
          ],
          class: ["flex-center"],
        },
        [
          _c("p", { staticClass: "text" }, [
            _vm._v("Multiplier (for the last freespin):"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.freespinMultiplier,
                  expression: "freespinMultiplier",
                  modifiers: { number: true },
                },
              ],
              staticStyle: { margin: "0.5rem" },
              attrs: { name: "freespinMultiplier", id: "freespinMultiplier" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.freespinMultiplier = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeFreespinMultiplier,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0", selected: "" } }, [
                _vm._v("0"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "2", selected: "" } }, [
                _vm._v("2"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "3", selected: "" } }, [
                _vm._v("3"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "4", selected: "" } }, [
                _vm._v("4"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "5", selected: "" } }, [
                _vm._v("5"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "6", selected: "" } }, [
                _vm._v("6"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "8", selected: "" } }, [
                _vm._v("8"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "10", selected: "" } }, [
                _vm._v("10"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "12", selected: "" } }, [
                _vm._v("12"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "15", selected: "" } }, [
                _vm._v("15"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "20", selected: "" } }, [
                _vm._v("20"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "25", selected: "" } }, [
                _vm._v("25"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "35", selected: "" } }, [
                _vm._v("35"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "50", selected: "" } }, [
                _vm._v("50"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "100", selected: "" } }, [
                _vm._v("100"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.isRespinSelect &&
                !_vm.isBonusGameStep &&
                !_vm.isWheelWinStep,
              expression:
                "!isRespinSelect && !isBonusGameStep && !isWheelWinStep",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespinStart,
              expression: "isRespinStart",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Respin symbol: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectRespinSymbol", {
                on: {
                  input: function ($event) {
                    return _vm.updateRespinSymbol(_vm.respinSymbol)
                  },
                },
                model: {
                  value: _vm.respinSymbol,
                  callback: function ($$v) {
                    _vm.respinSymbol = $$v
                  },
                  expression: "respinSymbol",
                },
              }),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespinSelect || _vm.isBonusGameStep,
              expression: "isRespinSelect || isBonusGameStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonusGame", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRespinSelect,
                    expression: "isRespinSelect",
                  },
                ],
                on: {
                  input: function ($event) {
                    return _vm.updateBonusGame(_vm.bonusGame)
                  },
                },
                model: {
                  value: _vm.bonusGame,
                  callback: function ($$v) {
                    _vm.bonusGame = _vm._n($$v)
                  },
                  expression: "bonusGame",
                },
              }),
              _vm._v(" "),
              _c("selectPrizeForBonusGame", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBonusGameStep,
                    expression: "isBonusGameStep",
                  },
                ],
                on: {
                  input: function ($event) {
                    return _vm.updateBonusGame(_vm.bonusGame)
                  },
                },
                model: {
                  value: _vm.bonusGame,
                  callback: function ($$v) {
                    _vm.bonusGame = _vm._n($$v)
                  },
                  expression: "bonusGame",
                },
              }),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(0),
              expression: "isBonusGameSelected(0)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 0 (Woody) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus0Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(1),
              expression: "isBonusGameSelected(1)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 1 (Cliff) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus1Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(2),
              expression: "isBonusGameSelected(2)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 2 (Coach) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus2Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(3),
              expression: "isBonusGameSelected(3)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 3 (Rebecca) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus3Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(4),
              expression: "isBonusGameSelected(4)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 4 (Carla) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus4Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(5),
              expression: "isBonusGameSelected(5)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 5 (Diane) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus5Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(6),
              expression: "isBonusGameSelected(6)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 6 (Sam) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus6Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonusGameSelected(8),
              expression: "isBonusGameSelected(8)",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Bonus game 8 (Norm) prize: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectBonus8Prize", {
                on: {
                  input: function ($event) {
                    return _vm.updateBonusPrize(_vm.bonusGamePrize)
                  },
                },
                model: {
                  value: _vm.bonusGamePrize,
                  callback: function ($$v) {
                    _vm.bonusGamePrize = $$v
                  },
                  expression: "bonusGamePrize",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWheelWinStep,
              expression: "isWheelWinStep",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _c("label", [_vm._v(" Wheel win: ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("selectWheelWin", {
                on: {
                  input: function ($event) {
                    return _vm.updateWheelWin(_vm.wheelWin)
                  },
                },
                model: {
                  value: _vm.wheelWin,
                  callback: function ($$v) {
                    _vm.wheelWin = $$v
                  },
                  expression: "wheelWin",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }