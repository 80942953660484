var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(1)
              },
            },
          },
          [_vm._v("R1")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(2)
              },
            },
          },
          [_vm._v("R2")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(3)
              },
            },
          },
          [_vm._v("R3")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(4)
              },
            },
          },
          [_vm._v("R4")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(5)
              },
            },
          },
          [_vm._v("R5")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(6)
              },
            },
          },
          [_vm._v("R6")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(7)
              },
            },
          },
          [_vm._v("R7")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(8)
              },
            },
          },
          [_vm._v("R8")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(9)
              },
            },
          },
          [_vm._v("R9")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(10)
              },
            },
          },
          [_vm._v("R10")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(11)
              },
            },
          },
          [_vm._v("R11")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(12)
              },
            },
          },
          [_vm._v("R12")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(13)
              },
            },
          },
          [_vm._v("R13")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(14)
              },
            },
          },
          [_vm._v("R14")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(15)
              },
            },
          },
          [_vm._v("R15")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep(16)
              },
            },
          },
          [_vm._v("R16")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFSTriggerRS8()
              },
            },
          },
          [_vm._v("R8 FS Trigger")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center wild-multiplier-selector-wrapper" },
        [
          _c("div", { staticClass: "flex-center wild-multiplier-selector" }, [
            _c("label", { attrs: { for: "reelTwoWildMultiplier" } }, [
              _vm._v("Reel 2 wild multiplier: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelTwoWildMultiplier,
                    expression: "reelTwoWildMultiplier",
                  },
                ],
                attrs: { id: "reelTwoWildMultiplier" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelTwoWildMultiplier = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateWildMultiplier(
                        2,
                        _vm.reelTwoWildMultiplier
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "-1", selected: "selected" } }, [
                  _vm._v("Random"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-center wild-multiplier-selector" }, [
            _c("label", { attrs: { for: "reelThreeWildMultiplier" } }, [
              _vm._v("Reel 3 wild multiplier: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelThreeWildMultiplier,
                    expression: "reelThreeWildMultiplier",
                  },
                ],
                attrs: { id: "reelThreeWildMultiplier" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelThreeWildMultiplier = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateWildMultiplier(
                        3,
                        _vm.reelThreeWildMultiplier
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "-1", selected: "selected" } }, [
                  _vm._v("Random"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-center wild-multiplier-selector" }, [
            _c("label", { attrs: { for: "reelFourWildMultiplier" } }, [
              _vm._v("Reel 4 wild multiplier: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelFourWildMultiplier,
                    expression: "reelFourWildMultiplier",
                  },
                ],
                attrs: { id: "reelFourWildMultiplier" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelFourWildMultiplier = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateWildMultiplier(
                        4,
                        _vm.reelFourWildMultiplier
                      )
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "-1", selected: "selected" } }, [
                  _vm._v("Random"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }