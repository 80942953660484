// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm-game-spec-content .flex[data-v-6e773f23] {
    display: flex;
}
.sm-game-spec-content .flex-center[data-v-6e773f23] {
    display: flex;
    justify-content: center;
}
.sm-game-spec-content .use-feature[data-v-6e773f23] {
    margin: 0 0 20px 0;
}
.sm-game-spec-content .small[data-v-6e773f23] {
    font-size: 10px;
    color: gray;
}
.sm-game-spec-content .menu[data-v-6e773f23] {
    margin: 5px 0 5px 0;
}
.sm-game-spec-content .sub-title-menu[data-v-6e773f23] {
    padding-top: 20px;
    padding-bottom: 20px;
}
.sm-game-spec-content .label[data-v-6e773f23] {
    font-size: 12px;
    text-align: right;
    padding: 5px 2px 2px 20px;
    color: #a0a0a0;
    max-width: 150px;
    border-radius: 3px;
    margin: 1px;
}
.sm-game-spec-content .select-style[data-v-6e773f23] {
    width: auto;
}
.sm-game-spec-content .free-spin-prize[data-v-6e773f23] {
    height: 25px;
    background-color: darkgoldenrod;
    border: 1px solid gold;
    cursor: pointer;
}
.sm-game-spec-content .free-spin-prize select[data-v-6e773f23] {
    color: gold;
}
.sm-game-spec-content .free-spin-prize.label[data-v-6e773f23] {
    height: 25px;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0;
    padding: 0;
}
.sm-game-spec-content .hide[data-v-6e773f23] {
    visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw044/content.vue.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,+BAA+B;IAC/B,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,6BAA6B;IAC7B,6BAA6B;IAC7B,SAAS;IACT,UAAU;AACd;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".sm-game-spec-content .flex[data-v-6e773f23] {\n    display: flex;\n}\n.sm-game-spec-content .flex-center[data-v-6e773f23] {\n    display: flex;\n    justify-content: center;\n}\n.sm-game-spec-content .use-feature[data-v-6e773f23] {\n    margin: 0 0 20px 0;\n}\n.sm-game-spec-content .small[data-v-6e773f23] {\n    font-size: 10px;\n    color: gray;\n}\n.sm-game-spec-content .menu[data-v-6e773f23] {\n    margin: 5px 0 5px 0;\n}\n.sm-game-spec-content .sub-title-menu[data-v-6e773f23] {\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n.sm-game-spec-content .label[data-v-6e773f23] {\n    font-size: 12px;\n    text-align: right;\n    padding: 5px 2px 2px 20px;\n    color: #a0a0a0;\n    max-width: 150px;\n    border-radius: 3px;\n    margin: 1px;\n}\n.sm-game-spec-content .select-style[data-v-6e773f23] {\n    width: auto;\n}\n.sm-game-spec-content .free-spin-prize[data-v-6e773f23] {\n    height: 25px;\n    background-color: darkgoldenrod;\n    border: 1px solid gold;\n    cursor: pointer;\n}\n.sm-game-spec-content .free-spin-prize select[data-v-6e773f23] {\n    color: gold;\n}\n.sm-game-spec-content .free-spin-prize.label[data-v-6e773f23] {\n    height: 25px;\n    background-color: transparent;\n    border: 1px solid transparent;\n    margin: 0;\n    padding: 0;\n}\n.sm-game-spec-content .hide[data-v-6e773f23] {\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
