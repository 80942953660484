/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "modelId": "vgw_2by2_002",
      "wildSymbol": "Wild",
      "coinSymbol": "Coin",
      "scatterSymbol": "Bonus",
      "freeSpinTrigger": "Bonus",
      "countToTriggerFreeSpin" : 3,
      "freeSpinCountList": [7, 11, 15],
      "coinMinReel": 1,
      "minLineCount": 25,
      "criminalSymbols": ["HV1","HV2","HV3"],
      "winTable": [
        {
          "symbol": "Wild",
          "multipliers": [1000, 100, 40]
        }, {
          "symbol": "HV1",
          "multipliers": [600, 75, 30]
        }, {
          "symbol": "HV2",
          "multipliers": [200, 60, 25]
        }, {
          "symbol": "HV3",
          "multipliers": [200, 60, 25]
        }, {
          "symbol": "MV1",
          "multipliers": [100, 40, 10]
        }, {
          "symbol": "MV2",
          "multipliers": [100, 40, 10]
        }, {
          "symbol": "Ace",
          "multipliers": [50, 15, 5]
        }, {
          "symbol": "King",
          "multipliers": [50, 15, 5]
        }, {
          "symbol": "Queen",
          "multipliers": [40, 10, 5]
        }, {
          "symbol": "Jack",
          "multipliers": [40, 10, 5]
        }, {
          "symbol": "Ten",
          "multipliers": [40, 10, 5]
        }, {
          "symbol": "Bonus",
          "multipliers": [10, 3, 1]
        }
      ],
      "reelsLayout": [3, 3, 3, 3, 3],
      "playLines": [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 0, 1, 2, 1], // 6
        [1, 2, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [0, 0, 1, 0, 0], // 12
        [2, 2, 1, 2, 2], // 13
        [1, 1, 0, 1, 1], // 14
        [1, 1, 2, 1, 1], // 15
        [0, 1, 1, 1, 0], // 16
        [2, 1, 1, 1, 2], // 17
        [0, 1, 1, 1, 2], // 18
        [2, 1, 1, 1, 0], // 19
        [1, 0, 1, 0, 1], // 20
        [1, 0, 0, 0, 2], // 21
        [1, 2, 2, 2, 0], // 22
        [0, 1, 0, 1, 2], // 23
        [2, 1, 2, 1, 0], // 24
        [1, 2, 1, 2, 1] // 25
      ],
    
    
      "coinType1Values": [250, 2500, 10000, 50000, 100000],
      "coinType4Values": [1, 2, 5, 10, 20, 25, 50, 100 ],
      "coinType1_min_bet": "250",
      "coinType1_max_bet": "100000",
      "coinType1_spin_max_bet": "3000000",
      "coinType4_min_bet": "1",
      "coinType4_max_bet": "100",
      "coinType4_spin_max_bet": "2500",
      "reels": [
        ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Jack", "Bonus", "Queen", "Queen", "HV3", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "Bonus", "Jack", "Ace", "HV3", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "Bonus", "Queen", "Jack", "MV2", "MV1", "HV3", "Ace", "King", "HV2"],
        ["Queen", "Queen", "Queen", "MV1", "MV2", "Jack", "Jack", "Coin", "Ace", "Ten", "HV3", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "Bonus", "King", "King", "HV3", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "Ace", "King", "MV1", "Ten", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "HV2"],
        ["Jack", "Jack", "MV2", "King", "MV1", "Queen", "Queen", "HV3", "Ace", "Ace", "Ten", "HV2", "HV2", "HV2", "King", "Queen", "MV1", "MV2", "Jack", "King", "HV1", "HV1", "HV1", "Queen", "Jack", "Ten", "MV2", "HV3", "Ace", "Ace", "HV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Jack", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "King", "King", "Bonus", "Ace", "Ten", "HV3", "MV1", "Queen", "Ten", "Coin", "King", "HV3", "MV1", "Queen", "MV2", "King", "Bonus", "Ace", "MV1"],
        ["Queen", "Queen", "Queen", "MV1", "MV2", "Ten", "Jack", "Jack", "Coin", "Ace", "Ten", "HV3", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "Bonus", "King", "King", "HV3", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "HV2"],
        ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Bonus", "Queen", "Queen", "HV3", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "Bonus", "Jack", "Ace", "MV2", "HV3", "Queen", "Queen", "HV1", "HV1", "HV1", "Jack", "Jack", "Bonus", "Ace", "King", "HV3", "Ace", "King", "Bonus", "Jack", "HV2"]
      ],
    
     "wildReels":{
        "base":[
         ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Jack", "HV3", "Queen", "Queen", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "King", "Ace", "HV3", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "MV1", "Queen", "Jack", "HV3", "Ace", "King", "HV2"],
         ["Queen", "Queen", "Queen", "MV1", "MV2", "Jack", "Jack", "HV3", "Ace", "Ten", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "King", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "Ace", "King", "MV1", "Ten", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "HV2"],
         ["Jack", "Jack", "MV2", "King", "MV1", "Queen", "Queen", "HV3", "Ace", "Ace", "Ten", "HV2", "HV2", "HV2", "King", "Queen", "MV1", "MV2", "Jack", "King", "HV1", "HV1", "HV1", "Queen", "Jack", "Ten", "MV2", "HV3", "Ace", "Ace", "HV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Jack", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "King", "King", "HV3", "Ten", "Ace", "MV1", "Queen", "King", "Ten", "HV3", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
         ["Jack", "Jack", "MV2", "King", "MV1", "Queen", "Queen", "HV3", "Ace", "Ace", "Ten", "HV2", "HV2", "HV2", "King", "Queen", "MV1", "MV2", "Jack", "King", "HV1", "HV1", "HV1", "Queen", "Jack", "Ten", "MV2", "HV3", "Ace", "Ace", "HV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Jack", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "King", "King", "HV3", "Ten", "Ace", "MV1", "Queen", "King", "Ten", "HV3", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
         ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Queen", "Queen", "HV3", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ace", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Queen", "HV1", "HV1", "HV1", "King", "Jack", "Ace", "HV3", "King", "Jack", "Jack", "HV2"]
        ],
    
       //Free Spin Wild Reel Feature Case 1, 2, 3, 5, 6, 7
       "feature1":[
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "HV3", "King", "Queen", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "King", "Ace", "Ten", "HV3", "King", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "HV3", "Queen", "Jack", "MV2", "MV1", "Ace", "King", "HV2"],
         ["Queen", "Ten", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "MV1", "HV2"],
         ["Jack", "Queen", "MV2", "King", "MV1", "Jack", "Queen", "HV3", "MV2", "Ace", "King", "Ten", "HV2", "HV2", "HV2", "Ace", "Queen", "MV1", "MV2", "Jack", "Ten", "Ace", "MV1", "Jack", "King", "MV2", "Queen", "Jack", "Ten", "HV3", "Ace", "Ace", "MV1", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "MV2", "King", "Ten", "HV3", "Ace", "King", "MV1", "Queen", "Ten", "HV3", "MV2", "King", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
         ["Queen", "Bonus", "Jack", "MV1", "MV2", "Queen", "HV2", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Bonus", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "MV1", "HV2"],
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "Ten", "HV3", "MV1", "Queen", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "King", "Ten", "HV3", "Ace", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "King", "Queen", "HV3", "Ace", "King", "MV2", "MV1", "Ace", "King", "Jack", "HV2"]
       ],
    
      //   Free Spin Wild Reel Feature Case 4, 8, 9
       "feature2":[
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "HV3", "King", "Queen", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "King", "Ace", "Ten", "HV3", "King", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "HV3", "Queen", "Jack", "MV2", "MV1", "Ace", "King", "HV2"],
         ["Queen", "Ten", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "MV1", "HV2"],
         ["Jack", "Queen", "MV2", "King", "MV1", "Jack", "Queen", "HV3", "MV2", "Ace", "King", "Ten", "HV2", "HV2", "HV2", "Ace", "Queen", "MV1", "MV2", "Jack", "Bonus", "Ace", "MV1", "Jack", "King", "MV2", "Queen", "Jack", "Ten", "HV3", "Ace", "Ace", "MV1", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "MV2", "King", "Ten", "HV3", "Ace", "King", "MV1", "Queen", "Ten", "HV3", "MV2", "King", "MV1", "Queen", "MV2", "King", "Bonus"],
         ["Queen", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "MV1", "HV2"],
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "Ten", "HV3", "MV1", "Queen", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "King", "Ten", "HV3", "Ace", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "King", "Queen", "HV3", "Ace", "King", "MV2", "MV1", "Ace", "King", "Jack", "HV2"]
       ],
    
       //   Free Spin Wild Reel Feature Case 10
       "feature3":[
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "HV3", "King", "Queen", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "King", "Ace", "Ten", "HV3", "King", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "HV3", "Queen", "Jack", "MV2", "MV1", "Ace", "King", "HV2"],
         ["Queen", "Bonus", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Bonus", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "HV2"],
         ["Jack", "Queen", "MV2", "King", "MV1", "Jack", "Queen", "HV3", "MV2", "Ace", "King", "Ten", "HV2", "HV2", "HV2", "Ace", "Queen", "MV1", "MV2", "Jack", "Ten", "Ace", "MV1", "Jack", "King", "MV2", "Queen", "Jack", "Ten", "HV3", "Ace", "Ace", "MV1", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "MV2", "King", "Ten", "HV3", "Ace", "King", "MV1", "Queen", "Ten", "HV3", "MV2", "King", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
         ["Queen", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "MV1", "HV2"],
         ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "Ten", "HV3", "MV1", "Queen", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "King", "Ten", "HV3", "Ace", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "King", "Queen", "HV3", "Ace", "King", "MV2", "MV1", "Ace", "King", "Jack", "HV2"]
       ],
    
       "baseWildReelProbabilityList": [
         {"weight":8,  "wildReels":[1]    },
         {"weight":7,  "wildReels":[2]    },
         {"weight":7,  "wildReels":[3]    },
         {"weight":8,  "wildReels":[4, 5] },
         {"weight":9,  "wildReels":[1, 5] },
         {"weight":10, "wildReels":[2, 5] },
         {"weight":9,  "wildReels":[3, 5] },
         {"weight":14, "wildReels":[1, 4] },
         {"weight":14, "wildReels":[2, 4] },
         {"weight":14, "wildReels":[3, 4] }
       ],
    
       "featureWildReelProbabilityList": [
         {"weight":15, "wildReels":[1],    "featureReelsIndex":1},
         {"weight":16, "wildReels":[2],    "featureReelsIndex":1},
         {"weight":16, "wildReels":[3],    "featureReelsIndex":1},
         {"weight":16, "wildReels":[4, 5], "featureReelsIndex":2},
         {"weight":9,  "wildReels":[1, 5], "featureReelsIndex":1},
         {"weight":8,  "wildReels":[2, 5], "featureReelsIndex":1},
         {"weight":8,  "wildReels":[3, 5], "featureReelsIndex":1},
         {"weight":4,  "wildReels":[1, 4], "featureReelsIndex":2},
         {"weight":4,  "wildReels":[2, 4], "featureReelsIndex":2},
         {"weight":4,  "wildReels":[3, 4], "featureReelsIndex":3}
       ]
    
      },
    
      "criminalReels":{
        "base":[
          ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Jack", "HV3", "Queen", "Queen", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "King", "Ace", "HV3", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "MV1", "Queen", "Jack", "HV3", "Ace", "King", "HV2"],
          ["Queen", "Queen", "Queen", "MV1", "MV2", "Jack", "Jack", "HV3", "Ace", "Ten", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "King", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "Ace", "King", "MV1", "Ten", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "HV2"],
          ["Jack", "Jack", "MV2", "King", "MV1", "Queen", "Queen", "HV3", "Ace", "Ace", "Ten", "HV2", "HV2", "HV2", "King", "Queen", "MV1", "Jack", "King", "MV2", "Queen", "Jack", "Ten", "MV2", "HV3", "Ace", "Ace", "HV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Jack", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "King", "King", "HV3", "Ten", "Ace", "MV1", "Queen", "King", "Ten", "HV3", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
          ["Queen", "Queen", "Queen", "MV1", "MV2", "Ten", "Jack", "Jack", "HV3", "Ace", "Ten", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "King", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "HV2"],
          ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Queen", "Queen", "HV3", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ace", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Queen", "HV1", "HV1", "HV1", "King", "Jack", "Ace", "HV3", "King", "Jack", "Jack", "HV2"]
        ],
    
        "feature":[
          ["Ten", "Ace", "MV1", "MV2", "King", "Jack", "HV3", "MV2", "Queen", "Jack", "Ten", "HV3", "King", "Queen", "MV1", "Ten", "Ace", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "King", "Ace", "HV2", "King", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "Ten", "MV1", "MV2", "Queen", "Jack", "HV2", "Ace", "King", "HV3", "Queen", "Jack", "MV2", "MV1", "Ace", "King", "MV2", "HV3", "Jack", "MV1", "Queen", "MV2", "King"],
          ["Queen", "Ten", "Jack", "MV1", "MV2", "Queen", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "HV3", "Ten", "King", "Queen", "Wild", "Wild", "Wild", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "HV1", "HV1", "HV1", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV2", "HV2", "Jack", "MV1", "Ten", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "MV1", "Ten", "HV3", "Ace", "King", "MV2"],
          ["Jack", "Queen", "MV2", "King", "MV1", "Jack", "Queen", "HV3", "MV2", "Ace", "King", "Ten", "HV1", "HV1", "HV1", "Jack", "Ace", "Queen", "MV1", "MV2", "Jack", "Ten", "Ace", "MV1", "Jack", "King", "MV2", "Queen", "Jack", "Ten", "HV2", "Ace", "Ace", "MV1", "Queen", "Jack", "Wild", "Wild", "Wild", "Ten", "Ace", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "Ten", "MV1", "MV2", "King", "Ten", "HV2", "Ace", "King", "MV1", "Queen", "HV3", "MV2", "King", "MV1", "Queen", "MV2", "King", "Ace", "Ten", "MV1", "King"],
          ["Queen", "Ten", "Ace", "MV1", "MV2", "Queen", "Ten", "HV2", "Jack", "King", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV2", "Ace", "Queen", "Wild", "Wild", "Wild", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "HV1", "HV1", "HV1", "Jack", "HV3", "King", "MV1", "MV2", "Queen", "HV3", "MV1", "Ace", "MV2", "HV2", "Queen", "Ace", "Jack", "MV1", "HV3", "King", "MV2"],
          ["Ace", "Ten", "MV1", "MV2", "King", "HV2", "Queen", "MV2", "Jack", "HV3", "King", "Queen", "MV1", "Bonus", "Ten", "Ace", "HV1", "HV1", "HV1", "Jack", "Ten", "MV2", "Queen", "Bonus", "King", "MV1", "Ace", "Ten", "HV3", "King", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV1", "Ten", "Queen", "Jack", "HV2", "Ace", "King", "HV3", "Queen", "MV2", "MV1", "Ace", "MV2", "HV3", "Jack", "King", "MV1", "MV2", "Queen"]
        ]
      },
    
      "flyingWildReels":{
        "base":[
          ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Jack", "HV3", "Queen", "Queen", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "King", "Ace", "HV3", "Queen", "Jack", "HV1", "HV1", "HV1", "Ace", "King", "MV1", "Queen", "Jack", "HV3", "Ace", "King", "HV2"],
          ["Queen", "Queen", "Queen", "MV1", "MV2", "Jack", "Jack", "HV3", "Ace", "Ten", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "King", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV2", "Ace", "King", "MV1", "Ten", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ten", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "HV2"],
          ["Jack", "Jack", "MV2", "King", "MV1", "Queen", "Queen", "HV3", "Ace", "Ace", "Ten", "HV2", "HV2", "HV2", "King", "Queen", "MV1", "MV2", "Jack", "King", "HV1", "HV1", "HV1", "Queen", "Jack", "Ten", "MV2", "HV3", "Ace", "Ace", "HV2", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "Jack", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "King", "King", "HV3", "Ten", "Ace", "MV1", "Queen", "King", "Ten", "HV3", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
          ["Queen", "Queen", "Queen", "MV1", "MV2", "Ten", "Jack", "Jack", "HV3", "Ace", "Ten", "MV2", "MV1", "Ace", "Ace", "HV1", "HV1", "HV1", "Ten", "Jack", "King", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "King", "Ten", "HV2", "HV2", "HV2", "Queen", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Ace", "MV2", "HV3", "Queen", "Ace", "Jack", "HV2"],
          ["Ten", "Ace", "MV1", "MV2", "King", "King", "HV3", "MV2", "Jack", "Queen", "Queen", "HV3", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Ace", "Ace", "Ten", "HV3", "King", "King", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ace", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Jack", "Ace", "MV2", "Queen", "Queen", "HV1", "HV1", "HV1", "King", "Jack", "Ace", "HV3", "King", "Jack", "Jack", "HV2"]
        ],
    
        "feature":[
          ["Ten", "Ace", "HV3", "MV2", "King", "Jack", "MV1", "MV2", "Queen", "Jack", "HV3", "King", "Ace", "MV1", "Ten", "HV1", "HV1", "HV1", "Jack", "Queen", "MV2", "MV1", "Jack", "Ace", "Ten", "HV3", "King", "Jack", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Queen", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Ten", "Ace", "MV2", "Queen", "Jack", "King", "HV1", "HV1", "HV1", "Ace", "King", "Queen", "Jack", "MV2", "MV1", "HV3", "Ace", "King", "HV2"],
          ["Queen", "Ten", "HV2", "MV1", "MV2", "Queen", "King", "Jack", "MV1", "Ace", "HV3", "Queen", "Ten", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "MV1", "Queen", "Jack", "MV2", "King", "Ten", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Ace", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Queen", "HV1", "HV1", "HV1", "Jack", "HV2", "Queen", "Ace", "MV1", "HV3", "Queen", "Ace", "Jack", "MV1"],
          ["Jack", "Queen", "MV2", "King", "MV1", "Jack", "Queen", "HV3", "MV2", "Ace", "King", "Ten", "HV2", "HV2", "HV2", "Ace", "Queen", "MV1", "MV2", "Jack", "Ten", "Ace", "MV1", "King", "King", "MV2", "Queen", "Jack", "Ten", "HV3", "Ace", "Ace", "MV1", "Queen", "Jack", "HV1", "HV1", "HV1", "Ten", "MV2", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "MV1", "MV2", "Queen", "Ten", "HV3", "Ace", "King", "MV1", "Jack", "Queen", "Ten", "HV3", "MV2", "Ace", "King", "MV1", "Queen", "MV2", "King", "Ace", "MV1"],
          ["Queen", "Ten", "HV2", "MV1", "Queen", "MV2", "King", "Jack", "Ace", "HV3", "Queen", "MV2", "MV1", "Ace", "Jack", "HV1", "HV1", "HV1", "Ten", "Ace", "King", "HV3", "Queen", "Jack", "MV2", "King", "Ace", "HV3", "Queen", "HV2", "HV2", "HV2", "King", "Jack", "MV1", "Queen", "King", "MV2", "Ten", "MV1", "Queen", "Wild", "Wild", "Wild", "HV3", "Jack", "Ace", "King", "MV1", "MV2", "Ten", "HV1", "HV1", "HV1", "Jack", "King", "Ace", "MV2", "HV2", "Queen", "Ace", "Jack", "MV1", "HV2"],
          ["HV3", "Ace", "Jack", "Bonus", "Queen", "MV2", "King", "HV3", "Ace", "MV1", "Queen", "Jack", "Ten", "HV3", "MV1", "King", "HV1", "HV1", "HV1", "Jack", "Ten", "Queen", "MV2", "MV1", "King", "Bonus", "Ten", "HV3", "Ace", "Queen", "MV2", "Wild", "Wild", "Wild", "MV1", "Jack", "Ten", "HV2", "HV2", "HV2", "Queen", "King", "HV3", "Ten", "Ace", "MV2", "Queen", "Jack", "HV1", "HV1", "HV1", "King", "Queen", "Jack", "MV1", "King", "Ace", "MV2", "Queen", "MV1", "Ten", "Jack", "HV2", "King", "Queen"]
        ],
    
        "baseWildsIndex": [
          [2, 3, 11, 13, 21, 26, 34, 42, 45, 52, 53],
          [2, 10, 13, 17, 20, 27, 31, 34, 46, 53],
          [2, 9, 14, 16, 23, 26, 32, 37, 49, 50, 57],
          [3, 14, 18, 28, 33, 36, 50, 57, 60],
          [2, 7, 13, 18, 22, 35, 40, 43, 51, 55]
        ],
        "featureWildsIndex": [
          [6, 7, 12, 17, 22, 27, 35, 37, 42, 47, 57],
          [6, 8, 13, 18, 48, 56, 61],
          [7, 8, 12, 17, 23, 28, 33, 37, 48, 54, 59],
          [6, 11, 16, 21, 26, 31, 35, 50, 55, 61],
          [8, 9, 15, 20, 38, 43, 48, 53, 58, 63]
        ]
      }
    }
}
