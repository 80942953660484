var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("New Steps:")]),
        _vm._v(" "),
        _c("button", [_vm._v("Add new clearing step")]),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v("Add new step")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addFreeSpinStep()
              },
            },
          },
          [_vm._v("Trigger Free Spins")]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Scenario Setup:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reel-position-selectors" },
          _vm._l(_vm.scenario.reelStripPositions, function (position) {
            return _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: position.value,
                  expression: "position.value",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: position.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(position, "value", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            })
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("slot-window", { attrs: { spinOutcome: _vm.spinOutcome } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }