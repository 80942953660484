import { modelDefinition } from '../../../../../state/models/slots/vgw067';
import { CheeseBlock, CheeseSize } from './datatypes';

/** Computes the optimal arrangement of cheese blocks for the given slot window. */
export function findCheeseBlocks(slotWindow: string[][], previousBlocks: CheeseBlock[] = []): CheeseBlock[] {
    // Get all possible starting blocks. We must try them all to be sure to get the best final block list.
    let startingExclusions = slotWindow.map((col) => col.map(() => false));
    const startingBlocks = findAllPossibleNextBlocks(slotWindow, startingExclusions);
    if (!startingBlocks) return [];

    // Form block lists based on forming additional blocks around each potential starting block indentified above.
    // NB: this shallow search works for vgw067. A full DFS here would work for arbitrary sizes.
    const candidateBlockLists = startingBlocks?.map((startingBlock) => {
        const blocks = [startingBlock];
        let exclusions = addBlockToExclusions(startingExclusions, startingBlock);
        for (const size of blockSizes) {
            let block: CheeseBlock | undefined;
            while (block = findNextBlock(slotWindow, exclusions, size)) {
                blocks.push(block);
                exclusions = addBlockToExclusions(exclusions, block);
            }
        }
        return blocks;
    });

    // Add a candidate based on `previousBlocks` with any new cheeses in the slot window added as 1x1s. This candidate will
    // be used if no other candidate calculated above outranks it. This prevents unnecessary block movements between respins.
    previousBlocks = [...previousBlocks]; // shallow copy the original so we don't mutate the input.
    candidateBlockLists.unshift(previousBlocks);
    const exclusions = previousBlocks.reduce((excl, block) => addBlockToExclusions(excl, block), startingExclusions);
    for (let col = 0; col < exclusions.length; ++col) {
        for (let row = 0; row < exclusions[col].length; ++row) {
            const block: CheeseBlock = { position: [row, col], size: '1x1' };
            if (isCheeseBlock(slotWindow, exclusions, block)) previousBlocks.push(block);
        }
    }

    // We have one or more candidates. Find the one with the best rank. Return the blocks in a consistent sort order.
    const result = candidateBlockLists.reduce((bestSoFar, nextCandidate) => {
        return getRanking(bestSoFar) >= getRanking(nextCandidate) ? bestSoFar : nextCandidate;
    });
    return result.sort(sortBlocks);
}

/** Returns all the equal-best blocks found in the given slotWindow for the given exclusions. */
function findAllPossibleNextBlocks(slotWindow: string[][], exclusions: boolean[][]): CheeseBlock[] | undefined {
    for (const size of blockSizes) {
        const {width, height} = blockSizeInfo[size];
        const result: CheeseBlock[] = [];
        for (let col = 0; col <= slotWindow.length - width; ++col) {
            for (let row = 0; row <= slotWindow[col].length - height; ++row) {
                const block: CheeseBlock = { position: [row, col], size };
                if (isCheeseBlock(slotWindow, exclusions, block)) result.push(block);
            }
        }
        if (result.length > 0) return result;
    }
}

/** Returns a single block of the given size for the given exclusions, or undefined if no such block is found. */
function findNextBlock(slotWindow: string[][], exclusions: boolean[][], size: CheeseSize): CheeseBlock | undefined {
    const {width, height} = blockSizeInfo[size];
    for (let col = 0; col <= slotWindow.length - width; ++col) {
        for (let row = 0; row <= slotWindow[0].length - height; row++) {
            const block: CheeseBlock = { position: [row, col], size };
            if (isCheeseBlock(slotWindow, exclusions, block)) return block;
        }
    }
}

/** Returns true iff the given block consists entirely of non-excluded WILDs in the given slot window. */
function isCheeseBlock(slotWindow: string[][], exclusions: boolean[][], block: CheeseBlock): boolean {
    const [top, left] = block.position;
    const {width, height} = blockSizeInfo[block.size];
    for (let col = left; col < left + width; ++col) {
        for (let row = top; row < top + height; ++row) {
            const isCheese = slotWindow[col][row] === modelDefinition.wildSymbol;
            const isExcluded = exclusions[col]?.[row];
            if (!isCheese || isExcluded) return false;
        }
    }
    return true;
}

/** Creates a new exclusions window formed by exclusing the given block from the given exclusions window. Inputs are not modified. */
function addBlockToExclusions(oldExclusions: boolean[][], block: CheeseBlock): boolean[][] {
    const [top, left] = block.position;
    const {width, height} = blockSizeInfo[block.size];
    const newExclusions = oldExclusions.map((col) => [...col]);
    for (let col = left; col < left + width; ++col) {
        for (let row = top; row < top + height; ++row) {
            newExclusions[col][row] = true;
        }
    }
    return newExclusions;
}

/** Creates a ranking score for a cheese block list. Useful for choosing the best of several potential lists. */
function getRanking(blockList: CheeseBlock[]) {
    return blockList.reduce((rank, block) => rank + blockSizeInfo[block.size].score, 0);
}

/** Block compare function for sorting block lists by block size and then by position. */
function sortBlocks(a: CheeseBlock, b: CheeseBlock): -1 | 0 | 1 {
    const aSize = blockSizeInfo[a.size].score;
    const bSize = blockSizeInfo[b.size].score;
    if (aSize > bSize) return -1;
    if (aSize < bSize) return 1;
    if (a.position[1] < b.position[1]) return -1;
    if (a.position[1] > b.position[1]) return 1;
    if (a.position[0] < b.position[0]) return -1;
    if (a.position[0] > b.position[0]) return 1;
    return 0;
}

const blockSizeInfo: Record<CheeseSize, { width: number; height: number; score: number }> = {
    '4x5': { width: 5, height: 4, score: 10000 },
    '4x4': { width: 4, height: 4, score: 1000 },
    '3x3': { width: 3, height: 3, score: 100 },
    '2x2': { width: 2, height: 2, score: 10 },
    '1x1': { width: 1, height: 1, score: 0.1 },
};

const blockSizes = Object.keys(blockSizeInfo) as CheeseSize[];
