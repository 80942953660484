export const modelDefinition = {
    modelId: 'vgw096',
    gameIds: ['blackTideFortunes'],
    coinType1Values: [20, 100, 500, 1_000, 5_000, 10_000, 50_000],
    coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250, 500, 1_000, 2_500],
    coinSizeMultiplier: 20,
    reelsLayout: [4, 4, 4, 4, 4, 4],
    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',
    coinSymbol: 'COIN',
    pic1Symbol: 'PIC1' as const,
    pic2Symbol: 'PIC2' as const,
    freeSpinsAwarded: [50, 30, 20, 12],
    countToTriggerFireshot: 8,
    countToTriggerGrand: 24,
    fireshotRespinCount: 3,

    coinType1_spin_max_bet: 50_000 * 20,
    coinType4_spin_max_bet: 2_500 * 20,

    jackpotMultiplierMini: 500,
    jackpotMultiplierMinor: 2000,
    jackpotStartup: {
        MAJOR: {
            GOLD: 10_000_000,
            SWEEPS: 500_000,
        },
        GRAND: {
            GOLD: 1_000_000_000,
            SWEEPS: 50_000_000,
        },
    },
    jackpotPercentAcrueToPool: {
        MAJOR: {
            GOLD: 0.5,
            SWEEPS: 0.5,
        },
        GRAND: {
            GOLD: 0.25,
            SWEEPS: 0.25,
        },
    },

    winTable: [
        {
            symbol: 'SCAT',
            multipliers: [1_000, 100, 10, 2],
        },
        {
            symbol: 'PIC1',
            multipliers: [200, 100, 60, 40, 10],
        },
        {
            symbol: 'PIC2',
            multipliers: [200, 100, 60, 40, 10],
        },
        {
            symbol: 'PIC3',
            multipliers: [80, 40, 20, 10],
        },
        {
            symbol: 'PIC4',
            multipliers: [80, 40, 20, 10],
        },
        {
            symbol: 'PIC5',
            multipliers: [50, 20, 10, 5],
        },
        {
            symbol: 'PIC6',
            multipliers: [50, 20, 10, 5],
        },
        {
            symbol: 'HEART',
            multipliers: [20, 10, 4, 2],
        },
        {
            symbol: 'DIAMOND',
            multipliers: [20, 10, 4, 2],
        },
        {
            symbol: 'CLUB',
            multipliers: [20, 10, 4, 2],
        },
        {
            symbol: 'SPADE',
            multipliers: [20, 10, 4, 2],
        },
    ],

    playLines: [], // ways game

    // prettier-ignore
    reels: [
        ['HEART','PIC6','PIC6','CLUB','CLUB','SCAT','SPADE','PIC4','CLUB','PIC1','PIC1','HEART','PIC3','PIC6','CLUB','COIN','COIN','COIN','HEART','HEART','PIC5','PIC3','CLUB','PIC2','PIC2','SPADE','PIC4','CLUB','PIC6','PIC5','SPADE','PIC3','PIC3','CLUB','PIC6','PIC6','SPADE','PIC4','HEART','PIC1','PIC1','CLUB','PIC6','HEART','HEART','HEART','PIC2','PIC2','CLUB','PIC4','SPADE','PIC3','PIC3','CLUB','PIC5','PIC5','SPADE','HEART','PIC1','PIC1','CLUB','PIC4','DIAMOND','PIC5','HEART','HEART','PIC6','PIC6','CLUB','CLUB','SCAT','HEART','PIC5','CLUB','CLUB','PIC4','PIC4','PIC4','HEART','HEART','PIC2','PIC2','SPADE','PIC3','PIC5','CLUB','PIC6','PIC3','HEART','HEART','COIN','COIN','COIN','COIN','HEART','PIC3','CLUB','PIC4','DIAMOND','PIC6','SPADE','HEART','PIC2','PIC2','CLUB','CLUB','PIC3','SPADE','PIC6','CLUB','CLUB','PIC4','SPADE','PIC1','PIC1','CLUB','PIC3','SPADE','SPADE','PIC6','PIC6','CLUB','DIAMOND','PIC5','PIC5','PIC5','CLUB','CLUB','PIC4','SPADE','PIC6','PIC6','CLUB','CLUB','PIC5','HEART','PIC3','CLUB','PIC4','DIAMOND','SPADE','PIC5'],
        ['DIAMOND','PIC4','HEART','SCAT','SPADE','SPADE','SPADE','PIC2','PIC2','HEART','PIC5','SPADE','WILD','DIAMOND','PIC6','HEART','PIC3','PIC4','DIAMOND','COIN','COIN','COIN','HEART','HEART','HEART','PIC5','PIC5','PIC5','CLUB','PIC3','SPADE','PIC2','PIC2','DIAMOND','DIAMOND','PIC6','PIC5','HEART','PIC3','PIC6','PIC6','PIC6','DIAMOND','PIC1','PIC1','HEART','PIC3','SPADE','PIC6','HEART','PIC2','PIC2','DIAMOND','PIC6','SPADE','PIC3','PIC3','HEART','HEART','HEART','PIC1','PIC1','DIAMOND','DIAMOND','PIC4','HEART','PIC5','DIAMOND','WILD','CLUB','PIC4','DIAMOND','PIC5','SPADE','SCAT','DIAMOND','PIC6','PIC6','PIC6','DIAMOND','PIC5','SPADE','PIC4','PIC4','PIC4','DIAMOND','PIC2','PIC2','PIC6','HEART','PIC3','PIC5','DIAMOND','COIN','COIN','COIN','COIN','DIAMOND','PIC3','CLUB','PIC4','SPADE','PIC3','DIAMOND','PIC5','PIC6','SPADE','SPADE','PIC1','PIC1','DIAMOND','PIC3','PIC4','HEART','HEART','PIC5','SPADE','PIC6','DIAMOND','PIC1','PIC1','SPADE','PIC6','PIC6','PIC6','DIAMOND','PIC4','PIC4','PIC4','SPADE','SPADE','PIC3','PIC3','PIC3','HEART','PIC4','DIAMOND','PIC5','PIC5','PIC5','HEART','HEART','HEART','PIC6','DIAMOND','PIC4','SPADE','PIC6','PIC6'],
        ['SPADE','PIC1','PIC1','CLUB','HEART','PIC5','SPADE','WILD','HEART','PIC3','SPADE','COIN','COIN','COIN','HEART','HEART','PIC2','PIC2','SPADE','PIC5','DIAMOND','PIC1','PIC1','SPADE','PIC4','HEART','PIC3','PIC3','PIC3','HEART','HEART','PIC5','SPADE','PIC4','PIC6','PIC6','HEART','HEART','HEART','PIC3','PIC3','PIC3','SPADE','PIC5','HEART','PIC4','DIAMOND','PIC2','PIC2','SPADE','PIC5','PIC5','PIC5','HEART','HEART','COIN','COIN','COIN','SPADE','SPADE','SPADE','PIC1','PIC1','CLUB','PIC6','SPADE','PIC4','CLUB','PIC2','PIC2','HEART','PIC3','PIC3','SPADE','SPADE','WILD','DIAMOND','PIC5','PIC5','PIC5','SPADE','PIC1','PIC1','HEART','PIC6','SPADE','SPADE','SPADE','PIC3','HEART','PIC6','SPADE','COIN','COIN','COIN','COIN','SPADE','PIC3','CLUB','PIC6','HEART','PIC5','SPADE','SCAT','HEART','HEART','PIC4','PIC4','PIC4','SPADE','PIC5','CLUB','PIC4','HEART','SPADE','PIC2','PIC2','HEART','PIC6','PIC6','PIC6','SPADE','SPADE','SCAT','HEART','PIC3','PIC4','DIAMOND','PIC6','SPADE','PIC3','PIC4','HEART','PIC1','PIC1','SPADE','SPADE','SPADE','PIC6','PIC6','PIC6','HEART','PIC2','PIC2','SPADE','PIC5','PIC6','DIAMOND','PIC4'],
        ['CLUB','CLUB','WILD','PIC6','SPADE','PIC3','HEART','PIC1','PIC1','CLUB','CLUB','COIN','COIN','COIN','HEART','HEART','PIC4','PIC4','CLUB','PIC2','PIC2','HEART','PIC6','PIC6','PIC6','SPADE','PIC5','CLUB','PIC3','SPADE','PIC6','HEART','PIC4','PIC4','PIC4','CLUB','COIN','COIN','COIN','CLUB','CLUB','PIC5','SCAT','SPADE','SPADE','PIC3','PIC4','HEART','PIC6','CLUB','PIC4','PIC4','PIC4','SPADE','PIC6','PIC5','DIAMOND','PIC3','CLUB','PIC1','PIC1','SPADE','PIC4','PIC6','CLUB','PIC5','SPADE','PIC3','PIC4','CLUB','SPADE','PIC2','PIC2','CLUB','PIC3','PIC6','SPADE','CLUB','PIC3','PIC3','PIC3','CLUB','CLUB','COIN','COIN','COIN','COIN','HEART','HEART','PIC6','PIC5','PIC5','HEART','PIC3','CLUB','PIC2','PIC2','PIC5','CLUB','SCAT','SPADE','PIC4','PIC3','CLUB','PIC5','HEART','WILD','SPADE','PIC6','HEART','CLUB','PIC4','SPADE','PIC1','PIC1','CLUB','PIC3','SPADE','PIC6','PIC6','PIC6','CLUB','CLUB','PIC4','SPADE','PIC2','PIC2','CLUB','PIC4','HEART','PIC6','CLUB','PIC3','PIC3','SPADE','SPADE','PIC1','PIC1','DIAMOND','HEART','PIC3','CLUB','PIC5','DIAMOND','PIC4'],
        ['SPADE','PIC2','PIC2','DIAMOND','PIC3','HEART','PIC6','SPADE','DIAMOND','PIC4','PIC4','PIC4','DIAMOND','DIAMOND','COIN','COIN','COIN','DIAMOND','PIC6','PIC6','PIC6','SPADE','PIC4','DIAMOND','PIC3','HEART','WILD','DIAMOND','DIAMOND','PIC5','HEART','SCAT','DIAMOND','PIC4','HEART','HEART','PIC3','SPADE','PIC5','PIC5','PIC5','CLUB','PIC3','DIAMOND','PIC2','PIC2','SPADE','SPADE','PIC6','PIC6','PIC6','DIAMOND','PIC4','PIC5','HEART','PIC3','PIC3','DIAMOND','PIC1','PIC1','SPADE','SPADE','HEART','PIC4','DIAMOND','PIC5','HEART','PIC3','DIAMOND','WILD','CLUB','PIC6','DIAMOND','PIC4','PIC4','PIC4','HEART','PIC3','DIAMOND','PIC2','PIC2','HEART','HEART','PIC6','SPADE','PIC4','HEART','PIC1','PIC1','SPADE','PIC3','DIAMOND','COIN','COIN','COIN','COIN','HEART','PIC4','DIAMOND','DIAMOND','PIC5','PIC5','PIC5','HEART','PIC6','DIAMOND','SCAT','HEART','PIC1','PIC1','SPADE','SPADE','SPADE','PIC5','PIC4','HEART','PIC6','PIC6','PIC6','SPADE','PIC3','HEART','HEART','PIC4','DIAMOND','PIC2','PIC2','SPADE','PIC6','HEART','DIAMOND','DIAMOND','SCAT','SPADE','SPADE','PIC3','PIC3','PIC3','HEART','CLUB','SPADE','PIC5','DIAMOND','DIAMOND','PIC6','HEART','HEART','PIC5','DIAMOND','DIAMOND','PIC1','PIC1','HEART','HEART','PIC3'],
        ['HEART','PIC4','CLUB','PIC2','PIC2','HEART','HEART','PIC3','SPADE','COIN','COIN','COIN','COIN','DIAMOND','PIC6','HEART','PIC5','PIC4','SPADE','SPADE','PIC3','PIC3','HEART','HEART','SCAT','SPADE','PIC6','PIC4','HEART','PIC3','PIC3','SPADE','PIC2','PIC2','DIAMOND','PIC5','PIC4','HEART','PIC6','CLUB','PIC4','PIC4','SPADE','SPADE','PIC3','HEART','CLUB','PIC4','PIC6','DIAMOND','PIC2','PIC2','SPADE','PIC3','HEART','PIC5','SPADE','PIC6','HEART','PIC3','SPADE','PIC4','DIAMOND','SCAT','SPADE','PIC3','PIC6','CLUB','PIC1','PIC1','SPADE','PIC3','PIC4','HEART','HEART','PIC5','PIC5','CLUB','PIC6','PIC6','HEART','DIAMOND','COIN','COIN','COIN','COIN','CLUB','PIC4','PIC5','HEART','SPADE','PIC1','PIC1','DIAMOND','PIC6','PIC6','HEART','CLUB','PIC2','PIC2','DIAMOND','SPADE','PIC4','PIC4','HEART','PIC3','SPADE','PIC6','PIC6','HEART','PIC1','PIC1','CLUB','SPADE','SCAT','PIC5','HEART','SPADE','PIC1','PIC1','DIAMOND','PIC3'],
    ],

    coinPrizeWeights: {
        initial: {
            20: 324000,
            30: 120000,
            40: 60000,
            60: 30000,
            80: 19200,
            100: 14400,
            150: 10800,
            200: 8400,
            300: 4800,
            MINI: 6000,
            MINOR: 1200,
            CHEST: 0,
            MAJOR: 'COIN_WEIGHT' as const,
        },
        respin: {
            20: 672000,
            30: 240000,
            40: 120000,
            60: 60000,
            80: 36000,
            100: 26400,
            150: 18000,
            200: 13200,
            300: 4800,
            MINI: 6000,
            MINOR: 1200,
            CHEST: 86400,
            MAJOR: 'COIN_WEIGHT' as const,
        },
    },

    /** Weights used during fireshot respins to possibly turn non-COIN symbols in the slot window into COINs. */
    extraCoinWeights: {
        easy: {
            chance: 1,
            outOf: 15,
        },
        hard: {
            chance: 'COIN_WEIGHT' as const,
            outOf: 1_701_700,
        },
    },

    wildPics: {
        activationWeights: [
            { outcome: true, weight: 36 },
            { outcome: false, weight: 64 },
        ],
        wildPic1MultiplierWeights: [
            { outcome: 2, weight: 12 },
            { outcome: 3, weight: 5 },
        ],
        wildPic2ExtraWildsWeights: [
            { outcome: 2, weight: 12 },
            { outcome: 3, weight: 5 },
        ],
        chanceToKeepResult: [
            { maxWinMultiplier: 0, chanceIn100: 0 },
            { maxWinMultiplier: 1, chanceIn100: 100 },
            { maxWinMultiplier: 2, chanceIn100: 100 },
            { maxWinMultiplier: 5, chanceIn100: 100 },
            { maxWinMultiplier: 10, chanceIn100: 100 },
            { maxWinMultiplier: 20, chanceIn100: 80 },
            { maxWinMultiplier: 50, chanceIn100: 60 },
            { maxWinMultiplier: 100, chanceIn100: 35 },
            { maxWinMultiplier: 200, chanceIn100: 15 },
            { maxWinMultiplier: 500, chanceIn100: 7 },
            { maxWinMultiplier: 1000, chanceIn100: 6 },
            { maxWinMultiplier: 2000, chanceIn100: 5 },
        ],
    },

    chests: {
        chestTypeWeights: [
            { outcome: 'A', weight: 14 },
            { outcome: 'B', weight: 5 },
            { outcome: 'C', weight: 1 },
        ],
        numberOfStandardPrizesAwardedWeights: {
            A: [
                { outcome: 3, weight: 10 },
                { outcome: 4, weight: 15 },
                { outcome: 5, weight: 20 },
                { outcome: 6, weight: 15 },
                { outcome: 7, weight: 10 },
                { outcome: 8, weight: 5 },
                { outcome: 9, weight: 4 },
                { outcome: 10, weight: 3 },
                { outcome: 11, weight: 2 },
                { outcome: 12, weight: 1 },
            ],
            B: [
                { outcome: 3, weight: 0 },
                { outcome: 4, weight: 15 },
                { outcome: 5, weight: 20 },
                { outcome: 6, weight: 15 },
                { outcome: 7, weight: 10 },
                { outcome: 8, weight: 5 },
                { outcome: 9, weight: 4 },
                { outcome: 10, weight: 3 },
                { outcome: 11, weight: 2 },
                { outcome: 12, weight: 1 },
            ],
            C: [
                { outcome: 3, weight: 0 },
                { outcome: 4, weight: 0 },
                { outcome: 5, weight: 20 },
                { outcome: 6, weight: 15 },
                { outcome: 7, weight: 10 },
                { outcome: 8, weight: 5 },
                { outcome: 9, weight: 4 },
                { outcome: 10, weight: 3 },
                { outcome: 11, weight: 2 },
                { outcome: 12, weight: 1 },
            ],
        },
        standardPrizeWeights: {
            A: [
                { outcome: 5, weight: 10 },
                { outcome: 10, weight: 10 },
                { outcome: 20, weight: 10 },
                { outcome: 30, weight: 10 },
                { outcome: 40, weight: 10 },
                { outcome: 50, weight: 10 },
                { outcome: 60, weight: 10 },
                { outcome: 80, weight: 5 },
                { outcome: 100, weight: 5 },
                { outcome: 150, weight: 1 },
                { outcome: 200, weight: 1 },
                { outcome: 300, weight: 1 },
            ],
            B: [
                { outcome: 5, weight: 5 },
                { outcome: 10, weight: 5 },
                { outcome: 20, weight: 10 },
                { outcome: 30, weight: 10 },
                { outcome: 40, weight: 10 },
                { outcome: 50, weight: 10 },
                { outcome: 60, weight: 10 },
                { outcome: 80, weight: 5 },
                { outcome: 100, weight: 5 },
                { outcome: 150, weight: 3 },
                { outcome: 200, weight: 3 },
                { outcome: 300, weight: 3 },
            ],
            C: [
                { outcome: 5, weight: 2 },
                { outcome: 10, weight: 2 },
                { outcome: 20, weight: 10 },
                { outcome: 30, weight: 10 },
                { outcome: 40, weight: 10 },
                { outcome: 50, weight: 10 },
                { outcome: 60, weight: 10 },
                { outcome: 80, weight: 10 },
                { outcome: 100, weight: 10 },
                { outcome: 150, weight: 10 },
                { outcome: 200, weight: 10 },
                { outcome: 300, weight: 10 },
            ],
        },
        specialPrizeWeights: {
            A: [
                { outcome: 'NOTHING', weight: 32 },
                { outcome: 'MINI', weight: 5 },
                { outcome: 'MINOR', weight: 1 },
                { outcome: 'BOOST', weight: 7 },
                { outcome: 'COLLECT', weight: 2 },
            ],
            B: [
                { outcome: 'NOTHING', weight: 32 },
                { outcome: 'MINI', weight: 5 },
                { outcome: 'MINOR', weight: 1 },
                { outcome: 'BOOST', weight: 7 },
                { outcome: 'COLLECT', weight: 2 },
            ],
            C: [
                { outcome: 'NOTHING', weight: 32 },
                { outcome: 'MINI', weight: 5 },
                { outcome: 'MINOR', weight: 1 },
                { outcome: 'BOOST', weight: 7 },
                { outcome: 'COLLECT', weight: 2 },
            ],
        },
        boostAmountWeights: [
            { outcome: 1, weight: 75 },
            { outcome: 2, weight: 17 },
            { outcome: 3, weight: 5 },
            { outcome: 5, weight: 2 },
            { outcome: 10, weight: 1 },
        ],
    } as const,
};
