import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;

    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 3;
    public reel = "";

    public field0 = "0";
    public field1 = "0";
    
    public multiplierWheelItems = ["2X", "2X", "20X", "20X", "3X", "3X", "5X", "5X", "15X", "15X", "4X", "4X", "7X", "7X", "3x", "3x", "10X", "10X", "8X", "8X", "5X", "5X"];
    public prizeWheelItems = ["80", "50", "mini", "mini", "40", "30", "8fg", "8fg", "25", "60", "50", "minor", "minor", "75", "45", "90", "15fg", "15fg", "35", "60", "major", "major"];

    constructor() {
        super();
    }

    public mounted() {
        trace("reels mounted");
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step || !step.json) { 
            this.show = false;
            return; 
        }
        this.show = true;
        
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        this.field0 = step.json.field0 || "0";
        this.field1 = step.json.field1 || "0";
    }
    
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        this.step.json.field0 = `${this.field0}`;
        this.step.json.field1 = `${this.field1}`;
    
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }

    public addTrigger8FG() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getTrigger8FG() );
    }

    public addMiniJackpotStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getMiniJackpotStep() );
    }

    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }

    public updateMultiplierWheelStop(val) {
        this.checkChangesInStep();
    }

    public updatePrizeWheelStop() {
        this.checkChangesInStep();
    }
    
}
