var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-scatter" }, [
    _c("label", { attrs: { for: _vm.index } }, [
      _vm._v(" Pop prize #" + _vm._s(_vm.index) + ": "),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.prizeIndices[_vm.index - 1],
            expression: "prizeIndices[index - 1]",
          },
        ],
        attrs: { id: _vm.index },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.prizeIndices,
                _vm.index - 1,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
            function ($event) {
              return _vm.updatePopPrizeIndices()
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "-1" } }, [_vm._v("Not set!")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "0" } }, [_vm._v("0.6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2" } }, [_vm._v("1.2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3" } }, [_vm._v("1.8")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "4" } }, [_vm._v("2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "5" } }, [_vm._v("2.4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "6" } }, [_vm._v("3")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "7" } }, [_vm._v("3.6")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "8" } }, [_vm._v("4")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "9" } }, [_vm._v("5")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "11" } }, [_vm._v("15")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "12" } }, [_vm._v("20")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "13" } }, [_vm._v("25")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "14" } }, [_vm._v("50")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "15" } }, [_vm._v("100")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "16" } }, [_vm._v("Minor")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "17" } }, [_vm._v("Major")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "18" } }, [_vm._v("Mega")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }