import Vue, { ref } from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from '../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import Reel from './reels/reel.vue';

@Component({
    components: {
        reel: Reel,
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFreeSpin = false;
    public show = false;
    public reelStripPositions = [];
    public reel = '';
    public isOpen = false;
    public reelsNo = 5;
    public isRedTrigger = false;
    public isWhiteTrigger = false;
    public isBlueTrigger = false;
    public triggerScatters = 0;

    public selectedIndex = 0;
    public selectedReelSetIndex = 1;
    public selectedScatters = 2;
    public reelSets = [
        {
            label: 'RED Free Spin, 2 extra bonuses',
            val: [1, 2]
        },
        {
            label: 'RED Free Spin, 4 extra bonuses',
            val: [1, 4]
        },
        {
            label: 'RED Free Spin, 6 extra bonuses',
            val: [1, 6]
        },
        {
            label: 'RED Free Spin, 8 extra bonuses',
            val: [1, 8]
        },
        {
            label: 'RED Free Spin, 10 extra bonuses',
            val: [1, 10]
        },
        {
            label: 'BLUE Free Spin',
            val: [2, -1]
        },
        {
            label: 'RED&BLUE Free Spin, 2 extra bonuses',
            val: [3, 2]
        },
        {
            label: 'RED&BLUE Free Spin, 4 extra bonuses',
            val: [3, 4]
        },
        {
            label: 'RED&BLUE Free Spin, 6 extra bonuses',
            val: [3, 6]
        },
        {
            label: 'RED&BLUE Free Spin, 8 extra bonuses',
            val: [3, 8]
        },
        {
            label: 'RED&BLUE Free Spin, 10 extra bonuses',
            val: [3, 10]
        }
    ];

    @Watch('step')
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        step.fireworks = [0, 0, 0];
        step.activations = [0, 0, 0];
        this.isFreeSpin = step.json.isFeature ?? false;
        this.selectedIndex = step.json.selectedIndex ?? 0;
        this.selectedReelSetIndex = this.reelSets[this.selectedIndex].val[0] ?? 1;
        this.selectedScatters = this.reelSets[this.selectedIndex].val[1] ?? 2;

        this.isRedTrigger = step.json.redActive ?? false;
        this.isWhiteTrigger = step.json.whiteActive ?? false;
        this.isBlueTrigger = step.json.blueActive ?? false;
        this.triggerScatters = step.json.scatters ?? 0;
    }

    public toggleFreeSpin() {
        this.isFreeSpin = !this.isFreeSpin;
        this.step.json.isFeature = this.isFreeSpin;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public mounted() {
        this.isOpen = false;
        trace('reels mounted');
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addFreeSpinStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFreespinStep());
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public onUpdateStep() {}

    public onChangeReelSet() {
        this.selectedReelSetIndex = this.reelSets[this.selectedIndex].val[0];
        this.selectedScatters = this.reelSets[this.selectedIndex].val[1];

        this.step.json.reelSetIndex = this.selectedReelSetIndex;
        this.step.json.selectedIndex = this.selectedIndex;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeTrigger() {
        this.step.json.redActive = this.isRedTrigger;
        this.step.json.whiteActive = this.isWhiteTrigger;
        this.step.json.blueActive = this.isBlueTrigger;
        
        this.step.json.activations = [+this.isRedTrigger, +this.isWhiteTrigger, +this.isBlueTrigger];

        const isFireWorkRed = this.isRedTrigger ? 1 : 0;
        const isFireWorkWhite = this.isWhiteTrigger ? 2 : 0;
        const isFireWorkBlue = this.isBlueTrigger ? 3 : 0;
        this.step.json.fireworks = [isFireWorkRed, isFireWorkWhite, isFireWorkBlue];

        this.step.json.scatters = this.triggerScatters;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public onChangeTriggerScatters() {
        this.step.json.scatters = this.triggerScatters;

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
