/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "rp002_hypernovaMegaways",

        "noSymbol": "NIL",

        "reelsLayout": [7, 7, 7, 7, 7, 7],

        "reels": [
            [ "N", "N", "A", "N", "N", "A", "A", "C", "C", "C", "N", "N", "K", "K", "K", "B", "K", "K", "J", "Q", "Q", "J", "K", "K", "N", "N", "E", "E", "S", "S", "J", "J", "J", "T", "D", "D", "T", "T", "T", "B", "K", "N", "N", "Q", "Q", "D", "D", "D", "N", "J", "J", "J", "D", "D", "K", "K", "K", "J", "J", "J", "K", "K", "K", "T", "T", "D", "D", "T", "T" ],
            [ "A", "A", "A", "D", "D", "J", "J", "J", "N", "N", "E", "E", "E", "E", "T", "T", "S", "Q", "Q", "E", "E", "N", "A", "A", "A", "A", "N", "E", "Q", "Q", "Q", "Q", "Q", "B", "T", "T", "T", "C", "N", "N", "K", "C", "K", "K", "Q", "Q", "Q", "S", "S", "T", "T", "C", "A", "A", "A", "A" ],
            [ "T", "T", "T", "T", "T", "D", "Q", "Q", "D", "J", "A", "A", "E", "C", "C", "E", "J", "J", "Q", "Q", "Q", "C", "K", "K", "K", "B", "S", "S", "S", "D", "N", "N", "D", "A", "A", "A", "N", "N", "N", "D", "J", "J", "J", "B", "K", "K", "K", "E", "E", "T", "T", "T", "T", "D", "Q", "Q", "D", "T", "T", "T", "J", "D", "A", "A", "C", "J", "J", "E", "Q", "Q", "C", "K", "K", "B", "S", "S", "D", "N", "N", "S", "K", "K", "S", "D", "A", "A", "A", "C", "N", "N", "N", "D", "J", "J", "J", "B", "E", "K", "K", "E", "Q", "Q" ],
            [ "B", "K", "Q", "Q", "Q", "D", "S", "S", "T", "T", "C", "C", "C", "C", "C", "E", "Q", "Q", "N", "N", "K", "K", "Q", "Q", "A", "A", "B", "N", "N", "D", "J", "T", "T", "T", "T", "C", "K", "A", "A", "J", "J", "E", "N", "N", "N", "K", "E", "E", "J", "J", "J", "J" ],
            [ "S", "S", "Q", "J", "K", "K", "K", "T", "T", "J", "A", "K", "E", "A", "T", "T", "B", "B", "B", "B", "B", "J", "J", "N", "N", "A", "A", "C", "N", "N", "C", "C", "T", "E", "E", "T", "Q", "Q", "D", "T", "T", "T", "D", "D", "D", "N", "N", "N", "A", "Q", "E" ],
            [ "S", "J", "E", "E", "J", "T", "T", "N", "C", "C", "C", "A", "A", "A", "N", "N", "K", "K", "J", "B", "B", "N", "N", "D", "D", "Q", "Q", "C", "C", "Q", "Q", "S", "J", "J", "T", "E", "E", "B", "T", "T", "D", "D", "D", "N", "N", "K", "K", "K" ]
        ],

        "topReel": [ "Z", "Q", "N", "J", "B", "S", "N", "T", "E", "D", "J", "T", "K", "A", "A", "C", "J", "J", "D", "T", "K", "A", "T", "K", "Z", "J", "Q", "Z", "T", "N", "E", "E", "N", "E", "K", "K", "N", "A", "T", "J", "Q", "T", "K", "E", "C", "N", "A", "C", "J", "J", "D", "N", "T", "Q", "T", "J", "Q", "Q", "D", "D", "N", "A", "Q", "N", "S", "A", "K", "J", "T", "T", "N", "J", "J", "A", "Q", "K", "K" ],
        "topReelReactions": [ "J", "Q", "E", "Z", "B", "E", "Z", "Q", "Z", "D", "S", "J", "E", "Z", "Z", "A", "C", "Z", "J", "D", "T", "C", "D", "T", "K", "J", "Q", "Z", "T", "Z", "E", "Z", "K", "N", "E", "Z", "D", "Z", "A", "D", "Q", "Z", "K", "E", "C", "Z", "T", "Z", "B", "J", "D", "N", "T", "Q", "E", "J", "Q", "Q", "D", "Z", "Z", "A", "Q", "Z", "S", "A", "Z", "J", "Z", "T", "N", "J", "J", "A", "Q", "K", "K" ],

        "winTable": [
            {
                "symbol": "B",
                "multipliers": [2000, 600, 200, 100, 40]
            }, {
                "symbol": "C",
                "multipliers": [100, 50, 30, 10]
            }, {
                "symbol": "D",
                "multipliers": [50, 30, 15, 5]
            }, {
                "symbol": "E",
                "multipliers": [40, 20, 10, 5]
            },{
                "symbol": "A",
                "multipliers": [24, 12, 6, 4]
            }, {
                "symbol": "K",
                "multipliers": [20, 12, 6, 3]
            }, {
                "symbol": "Q",
                "multipliers": [18, 10, 6, 3]
            }, {
                "symbol": "J",
                "multipliers": [14, 10, 5, 3]
            }, {
                "symbol": "T",
                "multipliers": [12, 8, 5, 2]
            }, {
                "symbol": "N",
                "multipliers": [10, 8, 4, 2]
            }
        ],

        "betMultiplier": 20,

        "scatterSymbol": "S",
        "wildSymbol": "Z",

        "coinType1Values": [ 100, 500, 2000, 5000, 20000, 50000, 500000 ],
        "coinType4Values": [ 1, 5, 15, 25, 50, 150, 250 ],

        "coinType1_min_bet": "100",
        "coinType1_max_bet": "500000",
        "coinType1_spin_max_bet": "10000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "250",
        "coinType4_spin_max_bet": "5000",
    },
}
