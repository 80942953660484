var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addStacksStep()
              },
            },
          },
          [_vm._v("Stacks")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addMultiplierStep()
              },
            },
          },
          [_vm._v("Multipliers")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Free Trig")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureStep()
              },
            },
          },
          [_vm._v("Free Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureRetrigger()
              },
            },
          },
          [_vm._v("Free Retrig")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusTriggerStep()
              },
            },
          },
          [_vm._v("Bonus Trig")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-center vgw035-selector-wrapper",
          attrs: { id: "substitute-wrapper" },
        },
        [
          _c("div", { attrs: { id: "selected-label" } }, [
            _vm._v("Replace STACK with: "),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.substituteSymbol,
                  expression: "substituteSymbol",
                },
              ],
              attrs: { id: "substitute-select" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.substituteSymbol = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.updateSubstituteSymbol()
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC1" } }, [_vm._v("PIC1")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC2" } }, [_vm._v("PIC2")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC3" } }, [_vm._v("PIC3")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "PIC4" } }, [_vm._v("PIC4")]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBonus,
              expression: "isBonus",
            },
          ],
          staticClass: "flex-center vgw035-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-jackpots" },
            _vm._l(_vm.jackpotPickablesIndexes, function (n) {
              return _c("selectJackpotPickable", {
                key: n,
                attrs: { index: n, jackpotPickables: _vm.jackpotPickables },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "selected-jackpots-validity-message" }, [
            _vm.isValidJackpotPickables
              ? _c("span", { staticClass: "vgw035-ok" }, [
                  _vm._v("Jackpot input is valid"),
                ])
              : _c("span", { staticClass: "vgw035-error" }, [
                  _vm._v(
                    "Error: Please pick at least 3 of the same jackpot type"
                  ),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: {
              index: reelIndex - 1,
              step: _vm.step,
              isFeature: _vm.isFeature,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }