import cascadeScenarios from './cascade-scenarios.json';
import {modelDefinition} from './model-definition';

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => ({
            name: 'Clear',
            gameState: 'clear',
            reelStripPositions: [0, 0, 0, 0, 0],
        }),

        // unique per model
        getLayout: () => modelDefinition.reelsLayout.join('-'),
        getModelDefinition: () => modelDefinition, 
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,
        getFeatureReelStrips: () => modelDefinition.featureReels,

        // game-specific
        getClearingStep: (): StepJson => ({
            name: 'Clear',
            gameState: 'clear',
            reelStripPositions: [0, 0, 0, 0, 0],
        }),

        getBaseStep: (): StepJson => ({
            name: 'Base',
            reelStripPositions: [0, 0, 0, 0, 0],
        }),

        getFreeSpinStep: (): StepJson => ({
            name: 'Free Spin',
            reelStripPositions: [0, 0, 0, 0, 0],
            isFeature: true,
        }),

        getFillerStep: (): StepJson => ({
            name: 'Filler',
        }),

        getCascadingWinSteps: (scenario: CascadeScenario): StepJson[] => {
            const {reelStripPositions, winLevel, cascadeLength} = scenario;
            const result: StepJson[] = [];
            const isFeatureTrigger = cascadeLength >= modelDefinition.cascadeLengthToTriggerFreeSpins || undefined;
            result.push({name: `${winLevel} Win 1/${cascadeLength}`, reelStripPositions});
            for (let i = 2; i <= cascadeLength; ++i) {
                result.push({name: `${winLevel} Win ${i}/${cascadeLength}`});
            }
            result.push({name: `${winLevel} Win End`, isFeatureTrigger});
            return result;
        },
        
        getCascadeScenarios(winLevel: 'NORMAL' | 'BIG' | 'MEGA' | 'EPIC'): CascadeScenario[] {
            return cascadeScenarios[winLevel].map(rawString => {
                const [rspString, multipliers] = rawString.split('   -->   ');
                const reelStripPositions = rspString.slice(1, -1).split(', ').map(Number);
                const cascadeLength = multipliers.split(', ').length;
                const description = `${cascadeLength} win${cascadeLength > 1 ? 's' : ''}   (${multipliers})`;
                return {description, reelStripPositions, winLevel, cascadeLength};
            });
        },

        getJackpotCountdownNumbers() {
            return [
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
                50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
            ];
        }
    };
}

export interface StepJson {
    name: string;
    gameState?: 'clear';
    reelStripPositions?: number[];
    isFeatureTrigger?: boolean;
    isFeature?: boolean;
}

export interface CascadeScenario {
    description: string;
    reelStripPositions: number[];
    winLevel: 'NORMAL' | 'BIG' | 'MEGA' | 'EPIC';
    cascadeLength: number;
}
