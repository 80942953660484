var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddSpinStep,
                expression: "canAddSpinStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addSpinStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddSpinStep))]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFreeSpinTriggerStep,
                expression: "canAddFreeSpinTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFreeSpinTriggerStep()
              },
            },
          },
          [_vm._v(_vm._s(_vm.labelForAddFreeSpinTriggerStep))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddFireshotTriggerStep,
                expression: "canAddFireshotTriggerStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFireshotTriggerStep()
              },
            },
          },
          [_vm._v("Trigger Fireshot")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "60px" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddClearingStep,
                expression: "canAddClearingStep",
              },
            ],
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Add Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center sub-title-menu",
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Step " + _vm._s(_vm.currentStepIndex + 1)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.stepDescription)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [
              _vm._v(
                "Respins Remaining: " + _vm._s(_vm.fireshotRespinsRemaining)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFireshotRespin || _vm.fireshotRespinsRemaining,
                  expression: "isFireshotRespin || fireshotRespinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Coins Collected: " + _vm._s(_vm.fireshotCoinsCollected))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFreeSpin || _vm.freeSpinsRemaining,
                  expression: "isFreeSpin || freeSpinsRemaining",
                },
              ],
              staticClass: "label",
            },
            [_vm._v("Free Spins Remaining: " + _vm._s(_vm.freeSpinsRemaining))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c(
            "div",
            [
              _c("div", {
                class: [
                  "free-spin-prize",
                  "label",
                  _vm.isFreeSpin ? "" : "hide",
                ],
              }),
              _vm._v(" "),
              _c("reel", { attrs: { content: this, index: 0 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  class: [
                    "free-spin-prize",
                    "select-style",
                    _vm.isFreeSpin ? "" : "hide",
                  ],
                },
                [
                  _c(
                    "select",
                    {
                      domProps: { value: _vm.getFreeSpinPrize(1) },
                      on: {
                        input: function ($event) {
                          return _vm.setFreeSpinPrize(1, $event.target.value)
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "0" } }, [
                        _vm._v("🎁 Random"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.freeSpinPrizeLevels, function (opt) {
                        return [
                          _c("option", { domProps: { value: opt.value } }, [
                            _vm._v(_vm._s(opt.label)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c("reel", { attrs: { content: this, index: 1 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  class: [
                    "free-spin-prize",
                    "select-style",
                    _vm.isFreeSpin ? "" : "hide",
                  ],
                },
                [
                  _c(
                    "select",
                    {
                      domProps: { value: _vm.getFreeSpinPrize(2) },
                      on: {
                        input: function ($event) {
                          return _vm.setFreeSpinPrize(2, $event.target.value)
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "0" } }, [
                        _vm._v("🎁 Random"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.freeSpinPrizeLevels, function (opt) {
                        return [
                          _c("option", { domProps: { value: opt.value } }, [
                            _vm._v(_vm._s(opt.label)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c("reel", { attrs: { content: this, index: 2 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  class: [
                    "free-spin-prize",
                    "select-style",
                    _vm.isFreeSpin ? "" : "hide",
                  ],
                },
                [
                  _c(
                    "select",
                    {
                      domProps: { value: _vm.getFreeSpinPrize(3) },
                      on: {
                        input: function ($event) {
                          return _vm.setFreeSpinPrize(3, $event.target.value)
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: "0" } }, [
                        _vm._v("🎁 Random"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.freeSpinPrizeLevels, function (opt) {
                        return [
                          _c("option", { domProps: { value: opt.value } }, [
                            _vm._v(_vm._s(opt.label)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c("reel", { attrs: { content: this, index: 3 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", {
                class: [
                  "free-spin-prize",
                  "label",
                  _vm.isFreeSpin ? "" : "hide",
                ],
              }),
              _vm._v(" "),
              _c("reel", { attrs: { content: this, index: 4 } }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }