// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vgw047-game-content[data-v-447c320a] {
}
.vgw047-game-content .flex[data-v-447c320a] {
    display: flex;
}
.vgw047-game-content .flex-center[data-v-447c320a] {
    display: flex;
    justify-content: center;
}
.vgw047-game-content .use-feature[data-v-447c320a] {
    margin: 0 0 20px 0;
}
.vgw047-game-content .small[data-v-447c320a] {
    font-size: 10px;
    color: gray;
}
.vgw047-game-content .menu[data-v-447c320a] {
    margin: 5px 0 5px 0;
}
.vgw047-game-content .step-odd[data-v-447c320a],
.vgw047-game-content .step-even[data-v-447c320a] {
    padding: 15px 0 25px 0;
}
.vgw047-game-content .step-odd[data-v-447c320a] {
    background-color: #1f1f1f;
}
.vgw047-game-content .step-step[data-v-447c320a] {
    display: block;
    padding: 20px 0 10px 0;
    text-align: center;
    font-size: 22px;
    color: white;
}
.vgw047-game-content .line[data-v-447c320a] {
    border-bottom: 1px solid #00c700;
}
.vgw047-game-content .green[data-v-447c320a] {
    background-color: #267726;
    color: black;
}
.vgw047-game-content .btn-toggle-is-feature[data-v-447c320a] {
    background-color: #024802;
}
.vgw047-game-content .sub-title-menu[data-v-447c320a] { 
    color: #777;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw047/content.vue.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;;IAEI,sBAAsB;AAC1B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;AACf","sourcesContent":[".vgw047-game-content[data-v-447c320a] {\n}\n.vgw047-game-content .flex[data-v-447c320a] {\n    display: flex;\n}\n.vgw047-game-content .flex-center[data-v-447c320a] {\n    display: flex;\n    justify-content: center;\n}\n.vgw047-game-content .use-feature[data-v-447c320a] {\n    margin: 0 0 20px 0;\n}\n.vgw047-game-content .small[data-v-447c320a] {\n    font-size: 10px;\n    color: gray;\n}\n.vgw047-game-content .menu[data-v-447c320a] {\n    margin: 5px 0 5px 0;\n}\n.vgw047-game-content .step-odd[data-v-447c320a],\n.vgw047-game-content .step-even[data-v-447c320a] {\n    padding: 15px 0 25px 0;\n}\n.vgw047-game-content .step-odd[data-v-447c320a] {\n    background-color: #1f1f1f;\n}\n.vgw047-game-content .step-step[data-v-447c320a] {\n    display: block;\n    padding: 20px 0 10px 0;\n    text-align: center;\n    font-size: 22px;\n    color: white;\n}\n.vgw047-game-content .line[data-v-447c320a] {\n    border-bottom: 1px solid #00c700;\n}\n.vgw047-game-content .green[data-v-447c320a] {\n    background-color: #267726;\n    color: black;\n}\n.vgw047-game-content .btn-toggle-is-feature[data-v-447c320a] {\n    background-color: #024802;\n}\n.vgw047-game-content .sub-title-menu[data-v-447c320a] { \n    color: #777;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
