import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";
import SelectScatterMultiplier from "./components/selectScatterMultiplier.vue";

@Component({
  components: {
    reel: Reel,
    selectScatterMultiplier: SelectScatterMultiplier,
  },
})
export default class Content extends Vue {
  @Prop() public step: any;
  public isFeature = false;
  public isFeatureChecked = false;
  public show = false;
  public reelStripPositions = [];
  public reel = "";
  public isOpen = false;

  public baseReelHeightOne = 7;
  public baseReelHeightTwo = 6;
  public baseReelHeightThree = 6;
  public baseReelHeightFour = 6;
  public baseReelHeightFive = 6;
  public baseReelHeightSix = 7;

  public reelSetIndex = 0;

  public totalSymbolNum = 15;
  public scattersSelectedMultipliers = new Array(28).fill("Random");

  constructor() {
    super();
  }

  @Watch("step")
  public stepWatch(step) {
    if (!step) {
      this.show = false;
      return;
    }

    this.isOpen = false;
    this.show = true;
    this.reelSetIndex = step.json.reelSetIndex;
    this.isFeature = step.json.isFeature ? step.json.isFeature : false;
  }

  public mounted() {
    this.isOpen = false;
    trace("reels mounted");
  }

  public addBaseStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getBaseStep());
  }

  public updateTopReelReelStripPosition(value) {
    this.step.json.topReelReelStripPosition = value;

    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public updateBaseReelHeight(index, value) {
    switch (index) {
      case 0:
        this.step.json.baseReelHeightOne = value;
        break;
      case 1:
        this.step.json.baseReelHeightTwo = value;
        break;
      case 2:
        this.step.json.baseReelHeightThree = value;
        break;
      case 3:
        this.step.json.baseReelHeightFour = value;
        break;
      case 4:
        this.step.json.baseReelHeightFive = value;
        break;
      case 5:
        this.step.json.baseReelHeightSix = value;
        break;
    }

    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public updateBaseReelsHeights() {
    if (this.step.json.baseReelHeightOne != null) {
      this.baseReelHeightOne = this.step.json.baseReelHeightOne;
    }

    if (this.step.json.baseReelHeightTwo != null) {
      this.baseReelHeightTwo = this.step.json.baseReelHeightTwo;
    }

    if (this.step.json.baseReelHeightThree != null) {
      this.baseReelHeightThree = this.step.json.baseReelHeightThree;
    }

    if (this.step.json.baseReelHeightFour != null) {
      this.baseReelHeightFour = this.step.json.baseReelHeightFour;
    }

    if (this.step.json.baseReelHeightFive != null) {
      this.baseReelHeightFive = this.step.json.baseReelHeightFive;
    }

    if (this.step.json.baseReelHeightSix != null) {
      this.baseReelHeightSix = this.step.json.baseReelHeightSix;
    }
  }

  public addFeatureTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureTriggerStep());
  }

  public addStampedeTriggerStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getStampedeTriggerStep());
  }

  public addFeatureStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getFeatureStep());
  }

  public onChangeReelSet() {
    this.step.json.reelSetIndex = this.reelSetIndex as number;
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public addClearingStep() {
    const state = State.state;
    bus.$emit(events.ADD_STEP, state.getClearingStep());
  }

  public addFullRandom() {
    this.step.json.scattersSelectedMultipliers = new Array(
      this.totalSymbolNum
    ).fill("Random");
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public addFullNoScatter() {
    this.step.json.scattersSelectedMultipliers = new Array(
      this.totalSymbolNum
    ).fill("X");
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public updateRespinScattersMultipliers(event) {
    if (this.step.json.scattersSelectedMultipliers == null) {
      this.step.json.scattersSelectedMultipliers = new Array(
        this.totalSymbolNum
      ).fill("Random");
    }
    this.step.json.scattersSelectedMultipliers[event.target.id - 1] =
      event.target.value;
    bus.$emit(events.UPDATE_STEPS_DB, this.step);
  }

  public updateRespinScattersMultipliersFromReel() {
    if (this.step.json.scattersSelectedMultipliers != null) {
      this.scattersSelectedMultipliers = this.step.json.scattersSelectedMultipliers;
    } else {
      this.scattersSelectedMultipliers = new Array(this.totalSymbolNum).fill(
        "Random"
      );
    }
  }
}
