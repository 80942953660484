// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card[data-v-f7ca1e80] {
  border-radius: 10px;
  background: #737373;
  box-shadow:
    15px 15px 19px #1d1d1d,
    -15px -15px 19px #373737;

  overflow: hidden;

  padding: 1.2em;
}
.card > p[data-v-f7ca1e80] {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw089/card.vue.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB;;4BAE0B;;EAE1B,gBAAgB;;EAEhB,cAAc;AAChB;AACA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[".card[data-v-f7ca1e80] {\n  border-radius: 10px;\n  background: #737373;\n  box-shadow:\n    15px 15px 19px #1d1d1d,\n    -15px -15px 19px #373737;\n\n  overflow: hidden;\n\n  padding: 1.2em;\n}\n.card > p[data-v-f7ca1e80] {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
