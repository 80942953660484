import Vue from "vue";
import Component from "vue-class-component";
import * as casino from "./../../helpers/casino";
import {trace, TODO} from "./../../helpers/helpers";
import State from "../../state/state";
import bus from "../../common/bus";
import events from "../../common/events";

@Component({
    // define reactive variables
    data: () => {
        return {
            message: "",
        };
    },
})
export default class Popup extends Vue {
    public message = "";
    private promise: Promise<any>;
    private showPopup = false;
    private resolved = false;
    private rejected = false;
    private yes = false;
    private no = false;
    private ok = false;
    private showTrash = false;
    private showWarn = false;
    private resolve = () => {};
    private reject = () => {};
    constructor() {
        super();
        this.promise = new Promise((res, rej) => {});
        bus.$on(events.SHOW_POPUP, (callback, text, yes = false, no = false, ok = false, showTrash = false, showWarn = false) => {
            callback(this.show(text, yes, no, ok, showTrash, showWarn));
        });
    }
    public show(text: string = "", yes = false, no = false, ok = false, showTrash = false, showWarn = false) { 
        this.showPopup = true;
        this.message = text;
        this.yes = yes;
        this.no = no;
        this.ok = ok; 
        this.showTrash = showTrash;
        this.showWarn = showWarn;
        this.promise = new Promise((resolve, reject) => { 
            this.resolve = () => {
                resolve(true);
            };
            this.reject = () => {
                resolve(false);
            };
        }); 
        return this.promise;
    }
    public close() {
        this.showPopup = false;
    }
    public doYes() {
        trace("YES!");
        this.close();
        this.resolve();
    }
    public doNo() {
        trace("NO!");
        this.close();
        this.reject();
    }
    public doOK() {
        trace("OK!");
        this.close();
        this.resolve();
    }
}
