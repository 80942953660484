var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      class: ["sm-reel", _vm.isTopReel ? "sm-top-reel" : ""],
    },
    [
      _c(
        "div",
        { class: _vm.canUpdate ? "" : "hide", attrs: { align: "center" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-2x fa-chevron-up arrow" })]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canUpdate,
                    expression: "canUpdate",
                  },
                ],
                staticClass: "text",
              },
              [_vm._v(_vm._s(sym))]
            ),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.isTopReel ? "active-top-reel" : "active-reels"] },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", { staticClass: "respin" }, [
            _c(
              "div",
              {
                class: [
                  "symbol",
                  _vm.isTopReel ? "top-reel-on" : "on",
                  _vm.content.isCollapsing(
                    { row, col: _vm.index },
                    _vm.isTopReel
                  )
                    ? "collapsing"
                    : "",
                  sym,
                  _vm.content.isCoin({ row, col: _vm.index }, _vm.isTopReel)
                    ? "COIN"
                    : "NONCOIN",
                ],
              },
              [
                !_vm.content.isCoin({ row, col: _vm.index }, _vm.isTopReel)
                  ? _c("div", [
                      !_vm.content.canSetOrUnsetCoin(
                        { row, col: _vm.index },
                        _vm.isTopReel
                      )
                        ? _c("div", [
                            !_vm.content.isCandidateCell(
                              { row, col: _vm.index },
                              _vm.isTopReel
                            )
                              ? _c("div", { staticClass: "text" }, [
                                  _c("div", [_vm._v(_vm._s(sym))]),
                                ])
                              : _c("div", { staticClass: "select-wild" }, [
                                  _vm.content.isAnAddedWild({
                                    row,
                                    col: _vm.index,
                                  })
                                    ? _c("div", { staticClass: "WILD" }, [
                                        _vm._v("WILD"),
                                      ])
                                    : _c("div", [_vm._v(_vm._s(sym))]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "addedWildToggle",
                                      class: {
                                        toggled: _vm.content.isAnAddedWild({
                                          row,
                                          col: _vm.index,
                                        }),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.content.toggleWild({
                                            row,
                                            col: _vm.index,
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v("\n                            🟧")]
                                  ),
                                ]),
                          ])
                        : _vm.content.canBeClickedForCoin(
                            { row, col: _vm.index },
                            _vm.isTopReel
                          )
                        ? _c(
                            "div",
                            {
                              staticClass: "click",
                              on: {
                                click: function ($event) {
                                  return _vm.content.setCoinPrize(
                                    { row, col: _vm.index, value: "20" },
                                    _vm.isTopReel
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        (\n                        "
                              ),
                              _c("i", {
                                staticClass: "fas fa-2x fa-mouse-pointer",
                                staticStyle: { "font-size": "85%" },
                              }),
                              _vm._v(
                                " \n                        click for coin)\n                    "
                              ),
                            ]
                          )
                        : _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(sym) +
                                "\n                    "
                            ),
                          ]),
                    ])
                  : !_vm.content.canSetCoinPrize(
                      { row, col: _vm.index },
                      _vm.isTopReel
                    )
                  ? _c("div", { staticClass: "text set-coin" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            !_vm.content.isCascading
                              ? _vm.content.getCoinPrize(
                                  { row, col: _vm.index },
                                  _vm.isTopReel
                                )
                              : "COIN"
                          ) +
                          "\n                "
                      ),
                    ])
                  : _c("div", { staticClass: "text" }, [
                      _c(
                        "select",
                        {
                          class: _vm.content.getAffectedMultiplierCoinClass(
                            { row, col: _vm.index },
                            _vm.isTopReel
                          ),
                          domProps: {
                            value: _vm.content.getCoinPrize(
                              { row, col: _vm.index },
                              _vm.isTopReel
                            ),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.content.setCoinPrize(
                                {
                                  row,
                                  col: _vm.index,
                                  value: $event.target.value,
                                },
                                _vm.isTopReel
                              )
                            },
                          },
                        },
                        [
                          _vm._l(_vm.content.coinPrizeLevels, function (opt) {
                            return [
                              _c("option", { domProps: { value: opt.value } }, [
                                _vm._v(_vm._s(opt.label)),
                              ]),
                            ]
                          }),
                          _vm._v(" "),
                          _vm.content.canSetOrUnsetCoin(
                            { row, col: _vm.index },
                            _vm.isTopReel
                          )
                            ? [
                                _c("option", { domProps: { value: "0" } }, [
                                  _vm._v("❌ Remove"),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canUpdate,
                    expression: "canUpdate",
                  },
                ],
                staticClass: "text",
              },
              [_vm._v(_vm._s(sym))]
            ),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-2x fa-chevron-down arrow" })]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }