/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId" : "flambingo",
        "patterns" : [ {
            "validIndexes" : [ 0, 5, 10, 15, 20 ]
        },{
            "validIndexes" : [ 1, 6, 11, 16, 21 ]
        }, {
            "validIndexes" : [ 2, 7, 12, 17, 22 ]
        }, {
            "validIndexes" : [ 3, 8, 13, 18, 23 ]
        }, {
            "validIndexes" : [ 4, 9, 14, 19, 24 ]
        }, {
            "validIndexes" : [ 0, 1, 2, 3, 4 ]
        }, {
            "validIndexes" : [ 5, 6, 7, 8, 9 ]
        }, {
            "validIndexes" : [ 10, 11, 12, 13, 14 ]
        }, {
            "validIndexes" : [ 15, 16, 17, 18, 19 ]
        }, {
            "validIndexes" : [ 20, 21, 22, 23, 24 ]
        }, {
            "validIndexes" : [ 0, 6, 12, 18, 24 ]
        }, {
            "validIndexes" : [ 4, 8, 12, 16, 20 ]
        }, {
            "validIndexes" : [ 0, 20, 4, 24 ]
        } ],
            "weightedTables" : [ {
            "weight" : 3,
            "flambingosLeft" : [ 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9, 8, 7, 7, 6, 6, 5, 4, 3, 2, 1 ]
        }, {
            "weight" : 2,
            "flambingosLeft" : [ 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9, 8, 8, 7, 7, 6, 5, 4, 3, 2, 1, 1, 1, 1 ]
        }, {
            "weight" : 1,
            "flambingosLeft" : [ 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 8, 8, 7, 7, 7, 7, 5, 4, 3, 2, 1, 1 ]
        }, {
            "weight" : 2,
            "flambingosLeft" : [ 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9, 9, 8, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 6, 6, 6, 6, 6, 6, 6, 6, 5, 5, 4, 4, 4, 3, 3, 2, 1 ]
        }, {
            "weight" : 1,
            "flambingosLeft" : [ 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 8, 8, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 6, 6, 6, 6, 6, 6, 6, 6, 5, 5, 5, 4, 4, 4, 4, 4, 3, 3, 3, 2, 2, 1 ]
        } ],
        "firstBingoMultiplier" : 10,
        "fillAllMultiplier" : 1000,
        "normalBingoMultiplier" : 2,
        "coinType1Values" : [ 6250, 12500, 125000, 500000, 2500000, 5000000 ],
        "coinType4Values" : [ 25, 50, 100, 250, 500, 2500 ],
        "numberOfBalls" : 75,
        "maxNumberOfBingosLeft" : 0,
        "maxNumberOfCards" : 10,
        "minNumberOfCards" : 1,
        "cardIndexCount" : 25,
        "gameSessionId" : 4,
        "reelStripPositions" : null,
        "slotWindow" : null,
        "gold_balance" : 2008650000,
        "sweeps_balance" : 206537202,
        "sweeps_deposit_balance" : 200001295,
        "sweeps_promo_balance" : 0,
        "sweeps_win_balance" : 6535907,
        "sweeps_available_balance" : 6535907,
        "serverTime" : "2018-04-23 06:01:00"
    }
}
