import Vue, { PropType } from "vue";
import { modelDefinition, SpinOutcome } from "../math-model";
import {
  spinTypeFromGameState,
  spinTypeFromSpinOutcome,
  wrapPosition,
} from "../math-model-helpers";
import { GameState } from "../math-model/game-state";

/**
 * Representation of a scenario step used internally to the view.
 *
 * This is set up to allow for reactive binding to the UI.
 */
export interface InternalStep {
  reelStripPositions: { value: number }[];
  mysterySymbol: string | undefined;
  wildFury: boolean | undefined;
  wildMultipliers:
    | Array<{ value: number | "MINI" | "MINOR" | "MAJOR" | "GRAND" }>
    | undefined;
  clearGameState: boolean;
}

export default Vue.component("StepControls", {
  props: {
    step: Object as PropType<InternalStep>,
    spinOutcome: Object as PropType<SpinOutcome>,
    gameState: Object as PropType<GameState | undefined>,
  },
  computed: {
    mysterySymbolOptions: function (): string[] {
      const spinType = spinTypeFromSpinOutcome(this.spinOutcome);

      return modelDefinition.mysterySymbolWeights[spinType].map(
        ({ outcome }) => outcome,
      );
    },
    canTriggerWildFury: function (): boolean {
      const spinType = spinTypeFromGameState(this.gameState);

      return spinType === "BASE";
    },
  },
  methods: {
    updatePosition(event: Event, reelIndex: number): number {
      const spinType = spinTypeFromSpinOutcome(this.spinOutcome);
      const target = event.target as HTMLInputElement;
      const newPosition = Number(target.value);

      return wrapPosition(newPosition, spinType, reelIndex);
    },
  },
});
