var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("\n      Clearing Step\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "flex-center",
        },
        [
          _c("reel", { attrs: { index: 0, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { index: 1, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { index: 2, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { index: 3, step: _vm.step } }),
          _vm._v(" "),
          _c("reel", { attrs: { index: 4, step: _vm.step } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "centred" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "sub-title-menu",
          },
          [
            _c("div", { staticStyle: { color: "white", padding: "5px" } }, [
              _c("label", [_vm._v("Trigger MINI Jackpot")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.triggerJackpots.MINI,
                    expression: "triggerJackpots.MINI",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.triggerJackpots.MINI)
                    ? _vm._i(_vm.triggerJackpots.MINI, null) > -1
                    : _vm.triggerJackpots.MINI,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.triggerJackpots.MINI,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "MINI",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "MINI",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.triggerJackpots, "MINI", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.sendChanges()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", [_vm._v("Trigger MINOR Jackpot")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.triggerJackpots.MINOR,
                    expression: "triggerJackpots.MINOR",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.triggerJackpots.MINOR)
                    ? _vm._i(_vm.triggerJackpots.MINOR, null) > -1
                    : _vm.triggerJackpots.MINOR,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.triggerJackpots.MINOR,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "MINOR",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "MINOR",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.triggerJackpots, "MINOR", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.sendChanges()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", [_vm._v("Trigger GRAND Jackpot")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.triggerJackpots.GRAND,
                    expression: "triggerJackpots.GRAND",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.triggerJackpots.GRAND)
                    ? _vm._i(_vm.triggerJackpots.GRAND, null) > -1
                    : _vm.triggerJackpots.GRAND,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.triggerJackpots.GRAND,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "GRAND",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.triggerJackpots,
                              "GRAND",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.triggerJackpots, "GRAND", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.sendChanges()
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "select-style" },
                [
                  _vm._l(
                    _vm.freeSpinMultipliers,
                    function (freeSpinMultiplier, index) {
                      return [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.freeSpinMultipliers[index],
                                expression: "freeSpinMultipliers[index]",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.freeSpinMultipliers,
                                    index,
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.sendChanges()
                                },
                              ],
                            },
                          },
                          _vm._l(
                            _vm.freeSpinMultiplierOptions,
                            function (value) {
                              return _c(
                                "option",
                                { key: value, domProps: { value: value } },
                                [_vm._v("x" + _vm._s(value))]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.temporaryFreeSpinMultiplier,
                          expression: "temporaryFreeSpinMultiplier",
                        },
                      ],
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.temporaryFreeSpinMultiplier = $event.target
                              .multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          (event) => _vm.freeSpinMultiplierAdded(event),
                        ],
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { disabled: "", selected: "", value: "" } },
                        [_vm._v("x?")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.freeSpinMultiplierOptions, function (value) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [_vm._v(_vm._s(value))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("label", { staticClass: "label" }, [
        _c(
          "u",
          {
            attrs: {
              title:
                "Ordered list of wil multipliers that will used in free spins",
            },
          },
          [_vm._v("Free Spin Multipliers")]
        ),
      ]),
      _vm._v(" "),
      _c("i", { staticClass: "label fa-question" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }