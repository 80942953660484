import {modelDefinition} from './model-definition';
import {createClearingStep} from './steps';

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => createClearingStep(),

        // unique per model
        getLayout: () => modelDefinition.reelsLayout.join('-'),
        getModelDefinition: () => modelDefinition, 
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,
        getFeatureReelStrips: () => { throw new Error(`feature reels not available for vgw071`); },
        prepareStepForDB: step => step,
    };
}
