var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Free Spins Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBonusTriggerStep()
              },
            },
          },
          [_vm._v("Bonus Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFSThreeStep()
              },
            },
          },
          [_vm._v("Free Spins Three")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFSFourStep()
              },
            },
          },
          [_vm._v("Free Spins Four")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass: "flex-center carrots-selector-wrapper",
        },
        [
          _c("div", { staticClass: "flex-center carrots-selector" }, [
            _c("label", { attrs: { for: "carrots_number" } }, [
              _vm._v("Number of Carrots: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.carrotsNumber,
                    expression: "carrotsNumber",
                  },
                ],
                attrs: { id: "carrots_number" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.carrotsNumber = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateBonusGameCarrotsNumber(_vm.carrotsNumber)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("Random")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("1 carrot")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("2 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("3 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("4 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("5 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("6 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("7 carrots")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "8" } }, [_vm._v("8 carrots")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "selected-carrots" },
            _vm._l(parseInt(_vm.carrotsNumber), function (n) {
              return _c("selectCarrotMultiplier", {
                key: n,
                attrs: {
                  index: n,
                  carrotsSelectedMultipliers: _vm.carrotsSelectedMultipliers,
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }