/* tslint:disable:variable-name */
import State from '../../../state';
import init from './config/init';

export function setStateParams() {
    return {
        variations: [''],
        getModelDefinition: () =>
            State.state.standalone ? init.modelDefinition : State.state.slot.state.getModelDefinition(),
        createDefaultStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Reels');
            step.rows = State.state.getModelDefinition().reels;
            step.reelSetIndex = 0;
            return step;
        },
        getLayout: () => State.state.getModelDefinition().reelsLayout.join('-'),
        getRowsNo: () => State.state.getModelDefinition().getLayout(),
        getNumberOfReels: () => State.state.getModelDefinition().reelsLayout.length,
        getNumberOfRows: () => State.state.getModelDefinition().reelsLayout[0],

        getReelStrips: (index) => {
            switch (index) {
                case 0:
                    return State.state.getModelDefinition().reels;
                    break;
                case 1:
                    // red
                    return State.state.getModelDefinition().redReels;
                    break;
                case 2:
                    // blue
                    return State.state.getModelDefinition().blueReels;
                    break;
                case 3:
                    // red&blue
                    return State.state.getModelDefinition().redBlueReels;
                    break;
                default:
                    return State.state.getModelDefinition().reels;
            }
        },

        getBaseStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Reels');
            step.rows = State.state.getModelDefinition().reels;
            step.reelSetIndex = 0;
            step.scatters = 0;
            step.redActive = false;
            step.whiteActive = false;
            step.blueActive = false;
            return step;
        },
        getFreespinStep: () => {
            const step = State.state.createStep([0, 0, 0, 0, 0], true, 0, '', 'Free Spin step');
            step.reelSetIndex = 1;
            step.scatters = 0;
            step.isFeature = true;
            step.redActive = false;
            step.whiteActive = false;
            step.blueActive = false;
            step.selectedIndex = 0;
            return step;
        },
        prepareStepForDB: (step) => {
            step.rows = undefined;
            step.randoms = undefined;
            return step;
        },
        getClearingStep: function () {
            const step = State.state.createStep([0, 0, 0, 0, 0], false, 0, '', 'Clear');
            step.gameState = 'clear';
            return step;
        },
    };
}
