import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel
    }
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public reelStripPositions = [];
    public reelsNo = 0;
    public rowsNo = 0;
    public bands1 = [0,1,2,3,4];
    public bands2 = [5,6,7,8,9];
    public reel = "";
    public bands = [];
    public isOpenBase = false;
    public wildNo = -1;
    public triggerType = "";
    public isActive = false;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isActive =false;
        this.isOpenBase = false;
        this.bands = step.json.bands ? step.json.bands : this.bands1;
        this.wildNo = step.json.wildNo ? step.json.wildNo : -1;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;

        if(this.reelsNo != this.step.json.reelStripPositions.length) {
            this.reelsNo = this.step.json.reelStripPositions.length;
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
        if(step.json.internal_name != "FS_step") {
            this.isOpenBase = true;
        }
    }

    public addStepByType(type) {
        this.isOpenBase = true;
        this.triggerType = type;
        switch (type) {
            case "base1":
                this.addBaseStep(1);
                break;
            case "base2":
                this.addBaseStep(2);
                break;
            case "FS":
                this.viewFSTriggerReelsetNumber();
                break;
            case "bonus":
                this.viewBonusTrigger();
                break;
            default:
                this.addBaseStep(1);
        }
    }
    public updateStep(wildNo) {
        this.reelsNo = 5;
        this.step.json.wildNo = wildNo;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
        this.isActive = true;
    }

    public mounted() {
        this.isOpenBase = false;
        trace("reels mounted");
    }

    public addBaseStep(bands) {
        const state = State.state;
        state.rowsNo = 3;
        state.reelsNo = 5;
        if(bands == 1) {
            bus.$emit(events.ADD_STEP, state.getBaseStep("Base RS1", this.bands1));
        } else {
            bus.$emit(events.ADD_STEP, state.getBaseStep("Base RS2", this.bands2));
        }

    }

    public viewFSTriggerReelsetNumber() {
        const state = State.state;
        let stepTitle = "FS Trigger";
        state.rowsNo = 3;
        state.reelsNo = 5;
        bus.$emit(events.ADD_STEP, state.getFSTriggerStep(stepTitle, this.bands1));
    }

    public viewFSReelsetNumber() {
        this.isOpenBase = false;
        const state = State.state;
        let stepTitle = "FS Step";
        state.reelsNo = 4;
        state.rowsNo = 4;
        bus.$emit(events.ADD_STEP, state.getFSStep(stepTitle));
    }

    public viewBonusTrigger() {
        const state = State.state;
        let stepTitle = "Bonus Trigger";
        state.reelsNo = 5;
        state.rowsNo = 3;
        bus.$emit(events.ADD_STEP, state.getBonusTriggerStep(stepTitle, this.bands1));
    }

}
