import { Choices } from "../../choices";
import { modelDefinition } from "../../config";
import { Board, BoardGameState, advanceBoardPosition } from ".";

export function generateBoardGame(
  board: Board,
  choices: Choices,
  lastPinkPosition: number
): { boardGameState: BoardGameState; boardGameCount: number | undefined } {
  let boardGameState: BoardGameState = [];
  let boardGameCount: number | undefined = undefined;

  let bestPosition = 0;

  for (let run = 0; run < 3; run++) {
    let position = 0;
    const steps: BoardGameState = [];
    const initialPicks = choices.chooseNumberOfPicks();
    let picks = initialPicks;
    let index = 0;

    for (let pick = 0; pick < picks; pick++) {
      const isPinkAvailable = position < lastPinkPosition;
      const card = choices.chooseCard(isPinkAvailable, index);
      const colour = card === "PINK" ? "PINK" : choices.chooseColour(index);
      const prize =
        card === "PINK"
          ? choices.choosePinkPrize(index)
          : choices.choosePrize(index);
      const movement = card === "PINK" ? "SINGLE" : card;
      const step = advanceBoardPosition(board, position, colour, movement);
      position = step.shortcut ?? step.position;
      picks += step.extraPicks ?? 0;
      steps.push({ step, prize, card });
      index++;

      // The feature cannot go past rainbow, so break out of it at this point
      if (step.position === rainbowPosition) break;
    }

    if (position > bestPosition) {
      bestPosition = position;
      boardGameState = steps;
      boardGameCount = initialPicks;
    }
  }

  return { boardGameState, boardGameCount };
}
const { rainbowPosition } = modelDefinition;
