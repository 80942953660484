import { createSlotWindowGenerator } from "@vgw/gdk-math-model-tools";
import { modelDefinition } from "../../model-definition";
import type { CharacterWilds, GameState } from "../shared";
import { createWinsEvaluator } from "./create-wins-evaluator";
import type { GameRequest } from "./game-request";
import type { SpinOutcome } from "./outcome";
import type { Choices } from "../../choices/choices";
import { getWinType } from "./get-win-type";
import { isSymbolInValidCharacters } from "./is-symbol-in-valid-characters";
import { evaluateWinsWithReelSpinFeatures } from "./reel-spin-features";
import type { ReelSpinFeatures } from "./reel-spin-features-response";

export function spinFree(
  { gameRequest, gameState: lastGameState }: FreeSpinsInputs,
  choices: Choices
): SpinOutcome {
  const coinAmount = gameRequest.coinAmount;
  const playAmount =
    gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

  let { remaining, phase } = lastGameState.freeSpins!;
  const { picToWilds } = lastGameState.freeSpins!;
  const { cumulativeWinAmount: previousCumulativeWinAmount } = lastGameState;

  // Generate Slot Window
  const reelStripPositions = choices.chooseReelStripPositions();
  let slotWindow = generateSlotWindow(reelStripPositions);

  if (picToWilds) updateSlotWindowWithCharacterWilds(slotWindow, picToWilds);

  // Evaluate line wins
  let winInfo = evaluateWins({ slotWindow, coinAmount, playAmount });

  const featureId = choices.chooseReelSpinFeatureSet(true);
  const winsInfoAfterFeaturesApplied = evaluateWinsWithReelSpinFeatures({
    featureId,
    slotWindow,
    lineWinTotalBeforeFeatures: winInfo.lineWinAmount,
    winInfo,
    coinAmount,
    playAmount,
    isFreeSpin: true,
    choices,
    cumulativeWinAmount: 0,
  });

  let reelSpinFeatures: ReelSpinFeatures | undefined;
  let characterWildsAfterFeatures: CharacterWilds | undefined;
  if (winsInfoAfterFeaturesApplied) {
    slotWindow = winsInfoAfterFeaturesApplied.slotWindowAfterFeature;
    winInfo = winsInfoAfterFeaturesApplied.winInfoAfterFeature;
    reelSpinFeatures = winsInfoAfterFeaturesApplied?.reelSpinFeatures;
    characterWildsAfterFeatures =
      winsInfoAfterFeaturesApplied?.characterWilds?.pics;
  }

  // Calculate new game state
  remaining -= 1;
  phase = remaining > 0 ? "IN_PROGRESS" : "END";

  const freeSpinsTriggered = winInfo.scatterWin !== undefined;
  if (freeSpinsTriggered) {
    remaining += modelDefinition.freeSpinRetriggerCount;
    phase = "RETRIGGER";
  }

  const winAmount = winInfo.winAmount;

  const winType = getWinType({
    winAmount,
    isScatterWin: freeSpinsTriggered,
    isFreeSpin: true,
  });

  const cumulativeWinAmount = previousCumulativeWinAmount + winAmount;

  const roundComplete = phase === "END";
  const picToWildsUpdated = [
    ...new Set([...(picToWilds ?? []), ...(characterWildsAfterFeatures ?? [])]),
  ];
  const gameState: GameState | undefined = roundComplete
    ? undefined
    : {
        reelStripPositions,
        coinAmount,
        slotWindow,
        cumulativeWinAmount,
        freeSpins: {
          remaining,
          phase,
          picToWilds: picToWildsUpdated,
        },
      };

  return {
    playSummary: {
      playAmount: 0,
      winAmount,
      roundComplete,
    },
    gameResponse: {
      coinAmount,
      playAmount: 0,
      winAmount,
      cumulativeWinAmount,

      reelStripPositions,
      slotWindow,

      lineWins: winInfo.lineWins,
      scatterWin: winInfo.scatterWin,
      winType,

      freeSpins: {
        remaining,
        phase,
        picToWilds: picToWildsUpdated,
      },
      reelSpinFeatures,
    },
    gameState,
  };
}

function updateSlotWindowWithCharacterWilds(
  slotWindow: string[][],
  characterWilds: ("PIC1" | "PIC2" | "PIC3")[]
) {
  for (const [reelIndex, reel] of slotWindow.entries()) {
    for (const [rowIndex, symbol] of reel.entries()) {
      if (isSymbolInValidCharacters(characterWilds, symbol)) {
        slotWindow[reelIndex][rowIndex] = "WILD";
      }
    }
  }
}

export type FreeSpinsInputs = {
  gameRequest: GameRequest;
  gameState: GameState;
};

const evaluateWins = createWinsEvaluator(modelDefinition);
const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
