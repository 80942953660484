import { modelDefinition } from '../../../model-definition/index';
import { createWinsEvaluator } from '../create-wins-evaluator';
import { getWinType } from '../get-win-type';
import type { SpinOutcome } from '../outcome';
import type { BankerOfferSpinInputs } from './banker-offer-spin-inputs';

export function bankerOfferSpin({ gameState, gameRequest, coinType }: BankerOfferSpinInputs): SpinOutcome {
    if (gameState?.bankerOfferAmount === undefined) throw new Error('Game state does not exist when attempting a pick');

    // Generate Slot Window
    const reelStripPositions = gameState.reelStripPositions;
    const slotWindow = gameState.slotWindow;
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

    // Evaluate line wins
    const winInfo = evaluateWins({ slotWindow, coinAmount, playAmount, coinType });
    const winAmount = gameRequest.hasAcceptedBankerOffer ? gameState.bankerOfferAmount : winInfo.winAmount;

    const winType = getWinType({
        winAmount,
        isFreeSpinTrigger: false,
        isFreeSpin: false,
    });

    return {
        playSummary: {
            playAmount: 0,
            winAmount,
            roundComplete: true,
        },
        gameResponse: {
            hasAcceptedBankerOffer: gameRequest.hasAcceptedBankerOffer,
            bankerOfferAmount: gameState.bankerOfferAmount,
            playAmount: playAmount,
            winAmount,

            reelStripPositions,
            slotWindow,
            winType,
            lineWins: winInfo.lineWins,

            coinAmount: gameRequest.coinAmount,

            goldCaseCells: [],
        },
    };
}

const evaluateWins = createWinsEvaluator({
    ...modelDefinition,
});
