// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
	"success": true,
	"messages": [],
	"gold_balance": 6025950000,
	"sweeps_balance": 413058686,
	"sweeps_deposit_balance": 200001395,
	"sweeps_promo_balance": 6527948,
	"sweeps_win_balance": 206529343,
	"sweeps_available_balance": 206529343,
	"serverTime": "2021-01-06 06:01:58",
	"modelId": "vgw046",
	"gameSessionId": 505,
	"reelStripPositions": [
	  18,
	  7,
	  14,
	  64,
	  11
	],
	"slotWindow": [
	  [
		"PIC4",
		"S",
		"D"
	  ],
	  [
		"PIC5",
		"PIC3",
		"C"
	  ],
	  [
		"PIC2",
		"PIC4",
		"H"
	  ],
	  [
		"PIC1",
		"S",
		"PIC2"
	  ],
	  [
		"H",
		"PIC4",
		"PIC5"
	  ]
	],
	"modelDefinition": {
	  "reelsLayout": [
		3,
		3,
		3,
		3,
		3
	  ],
	  "winLevels": [
		{
		  "name": "normal1",
		  "threshold": 5
		},
		{
		  "name": "normal2",
		  "threshold": 15
		},
		{
		  "name": "normal3",
		  "threshold": 20
		},
		{
		  "name": "bigwin",
		  "threshold": 35
		},
		{
		  "name": "megawin",
		  "threshold": 70
		},
		{
		  "name": "epicwin"
		}
	  ],
	  "currency_symbol": "$",
	  "coinType1Values": [
		250,
		2500,
		10000,
		50000,
		100000
	  ],
	  "coinType4Values": [
		1,
		2,
		5,
		10,
		20,
		50,
		800
	  ],
	  "reels": [
		[
		  "H",
		  "S",
		  "PIC5",
		  "H",
		  "PIC3",
		  "C",
		  "PIC4",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "PIC2",
		  "H",
		  "D",
		  "PIC4",
		  "S",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "H",
		  "PIC5",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "H",
		  "PIC4",
		  "PIC5",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC1",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "H",
		  "PIC2",
		  "PIC1",
		  "C"
		],
		[
		  "H",
		  "D",
		  "C",
		  "PIC4",
		  "D",
		  "C",
		  "PIC4",
		  "PIC5",
		  "PIC3",
		  "C",
		  "WILD",
		  "D",
		  "H",
		  "SCAT",
		  "D",
		  "S",
		  "PIC3",
		  "D",
		  "PIC2",
		  "C",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC4",
		  "PIC1",
		  "PIC3",
		  "C",
		  "D",
		  "PIC4",
		  "PIC2",
		  "H",
		  "D",
		  "C",
		  "S",
		  "PIC1",
		  "H",
		  "PIC2",
		  "D",
		  "C",
		  "PIC4",
		  "D",
		  "C",
		  "PIC4",
		  "PIC1",
		  "PIC3",
		  "C",
		  "S"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC4",
		  "PIC2",
		  "S",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC3",
		  "PIC1",
		  "PIC2",
		  "PIC4",
		  "H",
		  "D",
		  "PIC1",
		  "C",
		  "D",
		  "PIC4",
		  "PIC3",
		  "D",
		  "PIC5",
		  "H",
		  "PIC1",
		  "S",
		  "SCAT",
		  "H",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC1",
		  "D",
		  "PIC4",
		  "C",
		  "H",
		  "PIC5",
		  "D",
		  "PIC4",
		  "H",
		  "PIC2",
		  "D",
		  "PIC5",
		  "PIC1",
		  "C"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "H",
		  "S",
		  "PIC5",
		  "D",
		  "WILD",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "H",
		  "PIC2",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "PIC5",
		  "H",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "H",
		  "PIC4",
		  "S",
		  "PIC1",
		  "D",
		  "PIC4",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "S",
		  "PIC4",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "S",
		  "PIC4",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "H",
		  "S",
		  "PIC5"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "H",
		  "PIC3",
		  "C",
		  "PIC4",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "H",
		  "PIC2",
		  "PIC1",
		  "D",
		  "PIC3",
		  "H",
		  "PIC1",
		  "D",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "H",
		  "PIC4",
		  "PIC5",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC4",
		  "H",
		  "PIC1",
		  "D",
		  "PIC2",
		  "H",
		  "PIC4",
		  "PIC1",
		  "C"
		]
	  ],
	  "freeSpinTrigger": "SCAT",
	  "countToTriggerFreeSpin": 3,
	  "scatterSymbol": "SCAT",
	  "wildSymbol": "WILD",
	  "modelId": "vgw046",
	  "suits": [
		"H",
		"D",
		"C",
		"S"
	  ],
	  "freeSpinCount": 15,
	  "defaultSelectLine": "25",
	  "maxFreeSpinReTriggerCount": 9,
	  "winTable": [
		{
		  "symbol": "WILD",
		  "multipliers": [
			250,
			25,
			8
		  ]
		},
		{
		  "symbol": "PIC1",
		  "multipliers": [
			250,
			25,
			8
		  ]
		},
		{
		  "symbol": "PIC2",
		  "multipliers": [
			200,
			20,
			8
		  ]
		},
		{
		  "symbol": "PIC3",
		  "multipliers": [
			150,
			15,
			6
		  ]
		},
		{
		  "symbol": "PIC4",
		  "multipliers": [
			100,
			10,
			5
		  ]
		},
		{
		  "symbol": "PIC5",
		  "multipliers": [
			100,
			10,
			5
		  ]
		},
		{
		  "symbol": "H",
		  "multipliers": [
			50,
			10,
			3
		  ]
		},
		{
		  "symbol": "D",
		  "multipliers": [
			50,
			10,
			3
		  ]
		},
		{
		  "symbol": "C",
		  "multipliers": [
			50,
			10,
			3
		  ]
		},
		{
		  "symbol": "S",
		  "multipliers": [
			50,
			10,
			3
		  ]
		},
		{
		  "symbol": "SCAT",
		  "multipliers": [
			100,
			5,
			2
		  ]
		}
	  ],
	  "playLines": [
		[
		  1,
		  1,
		  1,
		  1,
		  1
		],
		[
		  0,
		  0,
		  0,
		  0,
		  0
		],
		[
		  2,
		  2,
		  2,
		  2,
		  2
		],
		[
		  0,
		  1,
		  2,
		  1,
		  0
		],
		[
		  2,
		  1,
		  0,
		  1,
		  2
		],
		[
		  0,
		  0,
		  1,
		  2,
		  2
		],
		[
		  2,
		  2,
		  1,
		  0,
		  0
		],
		[
		  1,
		  0,
		  1,
		  2,
		  1
		],
		[
		  1,
		  2,
		  1,
		  0,
		  1
		],
		[
		  1,
		  0,
		  0,
		  1,
		  0
		],
		[
		  1,
		  2,
		  2,
		  1,
		  2
		],
		[
		  0,
		  1,
		  0,
		  0,
		  1
		],
		[
		  2,
		  1,
		  2,
		  2,
		  1
		],
		[
		  0,
		  2,
		  0,
		  2,
		  0
		],
		[
		  2,
		  0,
		  2,
		  0,
		  2
		],
		[
		  1,
		  0,
		  2,
		  0,
		  1
		],
		[
		  1,
		  2,
		  0,
		  2,
		  1
		],
		[
		  0,
		  1,
		  1,
		  1,
		  0
		],
		[
		  2,
		  1,
		  1,
		  1,
		  2
		],
		[
		  0,
		  2,
		  2,
		  2,
		  0
		],
		[
		  2,
		  0,
		  0,
		  0,
		  2
		],
		[
		  0,
		  1,
		  2,
		  2,
		  2
		],
		[
		  2,
		  1,
		  0,
		  0,
		  0
		],
		[
		  2,
		  0,
		  1,
		  0,
		  2
		],
		[
		  0,
		  2,
		  1,
		  2,
		  0
		]
	  ],
	  "featureReels": [
		[
		  "H",
		  "S",
		  "PIC5",
		  "H",
		  "PIC3",
		  "C",
		  "PIC4",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "PIC2",
		  "H",
		  "D",
		  "PIC4",
		  "S",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "H",
		  "PIC5",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "H",
		  "PIC4",
		  "PIC5",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC1",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "H",
		  "PIC2",
		  "PIC1",
		  "C"
		],
		[
		  "H",
		  "D",
		  "C",
		  "PIC4",
		  "D",
		  "C",
		  "PIC4",
		  "PIC5",
		  "PIC3",
		  "C",
		  "WILD",
		  "D",
		  "H",
		  "SCAT",
		  "D",
		  "S",
		  "PIC3",
		  "D",
		  "PIC2",
		  "C",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC4",
		  "PIC1",
		  "PIC3",
		  "C",
		  "D",
		  "PIC4",
		  "PIC2",
		  "H",
		  "D",
		  "C",
		  "S",
		  "PIC1",
		  "H",
		  "PIC2",
		  "D",
		  "C",
		  "PIC4",
		  "D",
		  "C",
		  "PIC4",
		  "PIC1",
		  "PIC3",
		  "C",
		  "WILD"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC4",
		  "PIC2",
		  "S",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC3",
		  "PIC1",
		  "PIC2",
		  "PIC4",
		  "H",
		  "D",
		  "PIC1",
		  "C",
		  "D",
		  "PIC4",
		  "PIC3",
		  "D",
		  "PIC5",
		  "H",
		  "PIC1",
		  "S",
		  "SCAT",
		  "H",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC1",
		  "D",
		  "PIC4",
		  "C",
		  "H",
		  "PIC5",
		  "D",
		  "PIC4",
		  "H",
		  "PIC2",
		  "D",
		  "PIC5",
		  "PIC1",
		  "C"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "H",
		  "S",
		  "PIC5",
		  "D",
		  "WILD",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "H",
		  "PIC2",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "PIC1",
		  "S",
		  "D",
		  "PIC5",
		  "H",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "H",
		  "PIC4",
		  "S",
		  "PIC1",
		  "D",
		  "PIC4",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "WILD",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "S",
		  "PIC4",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "H",
		  "S",
		  "PIC5",
		  "C",
		  "PIC3",
		  "S",
		  "PIC4",
		  "PIC5",
		  "S",
		  "PIC4",
		  "C",
		  "S",
		  "PIC4",
		  "H",
		  "PIC4",
		  "D",
		  "PIC1",
		  "S",
		  "PIC2",
		  "PIC1",
		  "C",
		  "H",
		  "S",
		  "PIC5"
		],
		[
		  "H",
		  "S",
		  "PIC5",
		  "H",
		  "PIC3",
		  "C",
		  "PIC4",
		  "H",
		  "D",
		  "PIC5",
		  "SCAT",
		  "H",
		  "PIC4",
		  "PIC5",
		  "PIC1",
		  "H",
		  "PIC2",
		  "PIC1",
		  "D",
		  "PIC3",
		  "H",
		  "PIC1",
		  "D",
		  "PIC2",
		  "S",
		  "SCAT",
		  "C",
		  "PIC3",
		  "H",
		  "PIC4",
		  "PIC5",
		  "D",
		  "PIC4",
		  "C",
		  "D",
		  "PIC4",
		  "H",
		  "PIC1",
		  "D",
		  "PIC2",
		  "H",
		  "PIC4",
		  "PIC1",
		  "C"
		]
	  ],
	  "coinType1_min_bet": 250,
	  "coinType1_max_bet": 100000,
	  "coinType1_spin_max_bet": 2500000,
	  "coinType4_min_bet": 1,
	  "coinType4_max_bet": 800,
	  "coinType4_spin_max_bet": 20000
	}
  };
