import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: ["value"],
})
export default class SelectPrizeForBonusGame extends Vue {
    constructor() {
        super();
    }

    public updateBonusGame(event) {
        this.$emit("input", event.target.value);
    }
}
