var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewBaseReelsetNumber()
              },
            },
          },
          [_vm._v("Base Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewFSTriggerReelsetNumber()
              },
            },
          },
          [_vm._v("Free Spins Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewFSReelsetNumber()
              },
            },
          },
          [_vm._v("Free Spins Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewFSTBReelsetNumber()
              },
            },
          },
          [_vm._v("FS Tackle Box Step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpenBase,
                expression: "isOpenBase",
              },
            ],
            staticClass: "flex-center fs-selector sm-label",
          },
          [
            _c("label", { attrs: { for: "bg_number" } }, [
              _vm._v("cascade number: "),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reelSetCascadeNumber,
                    expression: "reelSetCascadeNumber",
                  },
                ],
                attrs: { id: "bg_number" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.reelSetCascadeNumber = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.addBaseStep()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("reelset 1")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("reelset 2")]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-center fs-selector sm-space" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }