/* in order to support standalone, please insert the init response from the server here */
export const base = {
  "modelId" : "vgw041",
  "reelsLayout" : [ 3, 3, 3 ],
  "scatterSymbol" : "SC",
  "wildSymbol" : "WD",
  "freeSpinMultiplier" : 0,
  "freeSpinCount" : 0,
  "winTable" : [ {
    "symbol" : "WD",
    "multipliers" : [ 1000 ]
  }, {
    "symbol" : "P1",
    "multipliers" : [ 100 ]
  }, {
    "symbol" : "P2",
    "multipliers" : [ 40 ]
  }, {
    "symbol" : "P3",
    "multipliers" : [ 20 ]
  }, {
    "symbol" : "P4",
    "multipliers" : [ 10 ]
  }, {
    "symbol" : "SC",
    "multipliers" : [ 5, 1 ]
  } ],
  "playLines" : [ [ 1, 1, 1 ], [ 0, 0, 0 ], [ 2, 2, 2 ], [ 2, 1, 0 ], [ 0, 1, 2 ] ],
  "reels" : [
    ["BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","COIN","COIN","COIN","BK","P2","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P1","P1","P1","BK","SC","BK","P3","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","COIN","BK","P4","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","P4","BK","COIN","COIN","P3","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3"],
    ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P2","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P2","BK","P1","P1","P1","BK","P2","BK","P3","BK","P2","BK","SC","BK","P3","BK","P4","BK","P1","BK","COIN","COIN","BK","COIN","COIN","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","COIN","BK","P4","BK","P3","BK","P4","BK","COIN","COIN","P3","BK","P4","BK","P2","BK","P3","BK","P2","BK","P3","BK","P2","BK","P4","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P3","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4"],
    ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","COIN","COIN","BK","COIN","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P2","BK","P2","BK","P3","BK","SC","BK","P1","P1","P1","BK","SC","BK","P4","BK","SC","BK","P2","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","COIN","COIN","COIN","BK","P2","BK","P3","BK","P4","BK","COIN","COIN","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","SC","BK","P4","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","SC","BK","P3","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","WD","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4"]
  ],
  "defaultSelectLine" : 0,
  "coinType1Values" : [ 1250, 12500, 50000, 80000 ],
  "coinType4Values" : [ 5, 10, 20, 50, 100, 300 ],
  "coinType1_min_bet" : 1250,
  "coinType1_max_bet" : 80000,
  "coinType1_spin_max_bet" : 400000,
  "coinType4_min_bet" : 5,
  "coinType4_max_bet" : 300,
  "coinType4_spin_max_bet" : 1500,
  "winLevels" : [ {
    "name" : "normal1",
    "threshold" : 5
  }, {
    "name" : "normal2",
    "threshold" : 10
  }, {
    "name" : "normal3",
    "threshold" : 24
  }, {
    "name" : "bigwin",
    "threshold" : 50
  }, {
    "name" : "megawin",
    "threshold" : 100
  }, {
    "name" : "epicwin",
    "threshold" : 2147483647
  } ],
  "freeSpinTrigger" : "SC",
  "countToTriggerFreeSpin" : 3,
  "anySymbol" : "ANY",
  "coinCountToTriggerFeature" : 4,
  "coinSymbol" : "COIN",
  "jackpotFeatureLoopCount" : 3,
  "coinPricesSweeps" : {
    "jackpotTypes" : [ "MAJOR", "MINOR", "MINI" ],
    "prices" : [ 0, 100, 20, 5, 4, 3, 2, 1 ],
    "weights" : {
      "0" : {
        "5" : [ 100, 26000, 84000, 38000, 100000, 460000, 500000, 572450 ],
        "10" : [ 200, 26000, 84000, 38000, 100000, 460000, 500000, 572350 ],
        "20" : [ 400, 26000, 84000, 38000, 100000, 460000, 500000, 572450 ],
        "50" : [ 1000, 26000, 84000, 38000, 100000, 460000, 500000, 571850 ],
        "100" : [ 2000, 26000, 84000, 38000, 100000, 460000, 500000, 570850 ],
        "300" : [ 6000, 26000, 84000, 38000, 100000, 460000, 500000, 566850 ]
      }
    }
  },
  "coinPricesGold" : {
    "jackpotTypes" : [ "MAJOR", "MINOR", "MINI" ],
    "prices" : [ 0, 75, 15, 5, 4, 3, 2, 1 ],
    "weights" : {
      "0" : {
        "1250" : [ 25000, 6500000, 21000000, 9500000, 25000000, 115000000, 125000000, 143112500 ],
        "12500" : [ 250000, 6500000, 21000000, 9500000, 25000000, 115000000, 125000000, 143112500 ],
        "50000" : [ 1000000, 6500000, 21000000, 9500000, 25000000, 115000000, 125000000, 143112500 ],
        "80000" : [ 1600000, 6500000, 21000000, 9500000, 25000000, 115000000, 125000000, 143112500 ]
      }
    }
  },
  "initialJackpotAmount" : {
    "SWEEPS" : {
      "MAJOR" : 150000,
      "GRAND" : 5000000
    },
    "GOLD" : {
      "MAJOR" : 37500000,
      "GRAND" : 1250000000
    }
  },
  "fsFeatureReels" : {
    "6": [
        ["BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P1","P1","P1","BK","P2","BK","COIN","COIN","COIN","BK","SC","BK","P3","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","SC","BK","P4","BK","P4","BK","BK","COIN","BK","BK","P3","P1","P1","P1","P4","BK","P2","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","COIN","COIN","P2","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","SC","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","COIN","COIN","BK","COIN","P2","BK","P3","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","SC","BK","P4","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P2","BK","P1","P1","P1","BK","P2","BK","P3","BK","SC","BK","P2","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","COIN","BK","P4","P4","BK","P3","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P2","BK","P2","BK","P3","BK","P4","BK","COIN","COIN","P4","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P3","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","COIN","COIN","BK","COIN","P4","BK","P4","BK","SC","BK","P4","BK","P2","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","COIN","COIN","BK","COIN","COIN","BK","P1","P1","P1","BK","P3","BK","SC","BK","P1","P1","P1","BK","SC","BK","P4","BK","SC","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","SC","BK","P4","BK","COIN","COIN","COIN","BK","P4","P2","BK","P3","P4","BK","SC","BK","P4","BK","WD","WD","WD","WD","WD","BK","P4","BK","SC","BK","P2","BK","P3","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","COIN","COIN","P2","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P4","BK","SC","BK","P4","BK","SC","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","P4","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P2","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","P3","BK","WD","BK","P2","BK","P4","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","SC","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4"]
    ],
    "10": [
        ["BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","COIN","COIN","BK","COIN","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","COIN","COIN","BK","COIN","COIN","BK","P1","P1","P1","BK","COIN","BK","P4","BK","COIN","COIN","COIN","BK","P1","P1","P1","BK","SC","BK","P3","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P2","BK","P3","BK","COIN","COIN","P4","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","SC","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P3","BK","SC","BK","P4","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P2","BK","P3","COIN","COIN","BK","P2","BK","P3","BK","P2","BK","P1","P1","P1","BK","P2","BK","P3","BK","P2","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","SC","BK","P4","BK","P3","BK","P4","BK","P3","BK","COIN","COIN","COIN","BK","P3","BK","P4","BK","P2","BK","P3","BK","P2","BK","P3","BK","P2","BK","P4","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P3","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","COIN","COIN","BK","COIN","P4","BK","SC","BK","P4","BK","P4","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P4","BK","COIN","COIN","BK","COIN","COIN","BK","P1","P1","P1","COIN","COIN","BK","SC","BK","P1","P1","P1","BK","SC","BK","P4","BK","SC","BK","P2","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","SC","BK","P4","BK","P2","BK","P3","BK","COIN","COIN","COIN","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","P3","BK","SC","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P2","BK","P3","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P4","BK","SC","BK","P4","BK","P4","BK","SC","BK","P4","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","SC","BK","P3","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4"]
    ],
    "15": [
        ["BK","COIN","COIN","BK","COIN","P3","BK","P4","BK","P4","BK","SC","BK","P3","BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P1","P1","P1","BK","P3","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","COIN","BK","P2","BK","P1","P1","P1","BK","SC","BK","P3","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","COIN","COIN","P2","BK","P3","BK","COIN","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","P4","BK","P2","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","COIN","COIN","BK","COIN","P2","BK","P3","BK","P3","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P3","BK","P3","BK","P4","BK","SC","BK","P3","BK","P2","BK","P3","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P2","BK","P1","P1","P1","BK","P2","BK","P3","BK","P2","BK","SC","BK","P3","BK","P4","BK","P1","BK","P2","COIN","COIN","COIN","P4","BK","P2","BK","COIN","COIN","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","COIN","COIN","BK","COIN","COIN","BK","P2","BK","P4","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P1","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P3","BK","P4","BK","P2","BK","P3","BK","P3","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4","BK","P1","BK","P2","BK","P3","BK","P4","BK","P2","BK","P3","BK","P4","BK","SC","BK","P3","BK","P4"],
        ["BK","COIN","COIN","BK","COIN","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","COIN","COIN","BK","COIN","P2","BK","SC","BK","P4","BK","P4","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","WD","BK","P1","P1","P1","BK","P3","BK","COIN","COIN","BK","COIN","COIN","BK","SC","BK","P1","P1","P1","BK","SC","BK","P4","BK","SC","BK","P2","BK","P2","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","COIN","COIN","COIN","P2","BK","P3","BK","COIN","COIN","P3","BK","P4","BK","SC","BK","P4","BK","SC","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","SC","BK","P4","BK","WD","WD","WD","WD","WD","WD","WD","BK","P4","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","WD","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P1","P1","P1","BK","P3","BK","SC","BK","P4","BK","P2","BK","SC","BK","P4","BK","SC","BK","P2","BK","P4","BK","SC","BK","P4","BK","SC","BK","P3","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4","BK","P4","BK","P2","BK","P3","BK","P4","BK","P3","BK","P4","BK","SC","BK","P4"]
    ]
  },
  "maxBetMultiplier" : 5,
  "currency_symbol" : "$"
};

export const m93 = Object.assign({}, base, {
  "percentToJackpotPool": 0.5
});

export const m94 = Object.assign({}, base, {
  "percentToJackpotPool": 0.5
});

export const m95 = Object.assign({}, base, { 
});

