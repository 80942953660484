import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import * as casino from "./../../../../helpers/casino";
import {trace, TODO, wrapIndex, rowsFromIndexes, getReelWindowFromIndex} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./../reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public isFeature = false;
    public isFeatureChecked = false;
    public show = false;
    public isFeatureTrigger = false;
    public totalFreeSpinsToAward = 3;
    public lsFreeSpinsAmounts: number[] = [];
    public lsFreeSpinsSymbols: string[][] = [["PIC1","",""],["PIC2","",""],["PIC3","",""],["PIC4","",""],["PIC5","",""],["PIC6","",""],["PIC7","",""],["PIC8","",""],["PIC1","PIC2",""],["PIC1","PIC3",""],["PIC1","PIC4",""],["PIC1","PIC5",""],["PIC1","PIC6",""],["PIC1","PIC7",""],["PIC1","PIC8",""],["PIC2","PIC1",""],["PIC2","PIC3",""],["PIC2","PIC4",""],["PIC2","PIC5",""],["PIC2","PIC6",""],["PIC2","PIC7",""],["PIC2","PIC8",""],["PIC3","PIC1",""],["PIC3","PIC2",""],["PIC3","PIC4",""],["PIC3","PIC5",""],["PIC3","PIC6",""],["PIC3","PIC7",""],["PIC3","PIC8",""],["PIC4","PIC1",""],["PIC4","PIC2",""],["PIC4","PIC3",""],["PIC4","PIC5",""],["PIC4","PIC6",""],["PIC4","PIC7",""],["PIC4","PIC8",""],["PIC5","PIC1",""],["PIC5","PIC2",""],["PIC5","PIC3",""],["PIC5","PIC4",""],["PIC5","PIC6",""],["PIC5","PIC7",""],["PIC5","PIC8",""],["PIC6","PIC1",""],["PIC6","PIC2",""],["PIC6","PIC3",""],["PIC6","PIC4",""],["PIC6","PIC5",""],["PIC6","PIC7",""],["PIC6","PIC8",""],["PIC7","PIC1",""],["PIC7","PIC2",""],["PIC7","PIC3",""],["PIC7","PIC4",""],["PIC7","PIC5",""],["PIC7","PIC6",""],["PIC7","PIC8",""],["PIC8","PIC1",""],["PIC8","PIC2",""],["PIC8","PIC3",""],["PIC8","PIC4",""],["PIC8","PIC5",""],["PIC8","PIC6",""],["PIC8","PIC7",""],["PIC2","PIC3","PIC6"],["PIC2","PIC3","PIC7"],["PIC2","PIC4","PIC8"],["PIC2","PIC4","PIC1"],["PIC2","PIC4","PIC3"],["PIC2","PIC4","PIC5"],["PIC2","PIC4","PIC6"],["PIC2","PIC4","PIC7"],["PIC2","PIC5","PIC8"],["PIC2","PIC5","PIC1"],["PIC2","PIC5","PIC3"],["PIC2","PIC5","PIC4"],["PIC2","PIC5","PIC6"],["PIC2","PIC5","PIC7"],["PIC2","PIC6","PIC8"],["PIC2","PIC6","PIC1"],["PIC2","PIC6","PIC3"],["PIC2","PIC6","PIC4"],["PIC2","PIC6","PIC5"],["PIC2","PIC6","PIC7"],["PIC2","PIC7","PIC8"],["PIC2","PIC7","PIC1"],["PIC2","PIC7","PIC3"],["PIC2","PIC7","PIC4"],["PIC2","PIC7","PIC5"],["PIC2","PIC7","PIC6"],["PIC2","PIC8","PIC7"],["PIC2","PIC8","PIC1"]];
    public fsSymbols: string[] = [];
    constructor() {
        super();
        this.fsSymbols = this.lsFreeSpinsSymbols[0];
    }
    @Watch("step")
    public stepWatch(step) {
        const state = State.state;
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.isFeature = step.json.isFeature ? step.json.isFeature : false;
        this.totalFreeSpinsToAward = Number(step.json.field0);
        this.fsSymbols = step.json.field1 === "" ? this.lsFreeSpinsSymbols[0] : step.json.field1.split(",");
        this.lsFreeSpinsAmounts = state.getModelDefinition().fsOptions;

        this.isFeatureTrigger = isAFeatureTrigger(step.json.reelStripPositions, this.isFeature);

        console.log(JSON.stringify(step.json));
    }
    public mounted() {
        trace("reels mounted");
    }
    public toggleFeature() {
        this.isFeature = !this.isFeature;
        this.isFeatureChecked = this.isFeature;
        this.checkChangesInStep();
    }
    public doChangeTotalFreeSpinsToAward() {
        this.checkChangesInStep();
    }
    public checkChangesInStep() {
        this.step.json.isFeature = this.isFeature;
        this.step.json.field0 = String(this.totalFreeSpinsToAward);
        this.step.json.field1 = this.fsSymbols.join(",");

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep() );
    }
    public addFeatureStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureStep() );
    }
    public addFeatureTriggerStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureTriggerFromBaseGame() );
    }
    public addFeatureRetrigger() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFeatureReTriggerStep());
    }
}

const isAFeatureTrigger = (reelStripPositions: number[], isFeature: boolean) => {
    const state = State.state;
    const getReels = (i) => (isFeature ? state.getFeatureReelStrips() : state.getReelStrips())[i];
    const allWindows = reelStripPositions.map((index, i) => getReelWindowFromIndex(getReels(i), index, state.getNumberOfRows()) );
    const count = allWindows
        .map(w => w.indexOf("SCAT") > -1 ? Number(1) : Number(0))
        .reduce((a, b) => a + b, 0);
    return count >= 3;
};
