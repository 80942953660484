import { filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/index';
import type { CaseChasePhase, CasePrizesByCell, Cell, GameState } from '../../../shared/index';
import { type GameResponse } from '../game-response';
import { getWinType } from '../get-win-type';
import type { SpinInputs } from '../inputs';
import type { SpinOutcome } from '../outcome';
import { evaluateGoldCases } from './evaluate-gold-cases';
import { selectCasePrizes } from './select-case-prizes';

export function respin(spinInputs: SpinInputs, choices: Choices): SpinOutcome {
    const { coinType, gameRequest, gameState } = spinInputs;
    if(gameState === undefined){throw new Error()}
    const { coinAmount } = gameRequest;
    const { reelStripPositions = [], freeSpinPhase, freeSpinCount, caseChase } = gameState;
    if(caseChase === undefined){throw new Error()}
    const { respins: oldRespins, allPrizes: oldPrizes, hardPosition, mediumPosition } = caseChase;
    const oldSlotWindow = gameState.slotWindow;
    const oldCumulativeWinAmount = gameState.cumulativeWinAmount ?? 0;

    // Determine the non-CASE cells which will become CASEs, and update the slot window with the new CASEs
    const nonCaseCells = filterSlotWindow(oldSlotWindow, (sym) => sym !== 'CASE');
    const newCaseCells = nonCaseCells.filter((cell) =>
        choices.chooseIfCellBecomesCaseOnRespin({ coinType, coinAmount, cell, hardPosition, mediumPosition }),
    );
    const slotWindow = oldSlotWindow.map((col) => [...col]); // clone the slot window so we don't mutate the original one
    newCaseCells.forEach(([row, col]) => (slotWindow[col][row] = 'CASE'));

    // Choose a prize for each new CASE, and combine new CASEs with old ones.
    const newPrizes = selectCasePrizes(newCaseCells, choices, coinAmount, oldPrizes);

    // TODO: handle 15 CASEs - we skip gold case eval in that case

    // Go back over the new CASEs and replace any that are now GOLD_CASEs with a placeholder indicating
    // that the client will need to pick a feature for that cell.
    const goldCases = evaluateGoldCases(newCaseCells, choices, coinAmount, newPrizes);
    for (let i = 0; i < newPrizes.length; ++i) {
        const newPrize = newPrizes[i];
        const goldCase = goldCases.cells.find((cell) => isSameCell(cell, newPrize.cell));
        if (goldCase) {
            newPrizes[i] = { cell: newPrize.cell, gold: 'PICK' };
        }
    }
    const allPrizes = combineCasePrizes(oldPrizes, newPrizes);

    // Update case chase feature state
    // Jackpot Trigger (15 CASEs)
    const didAnyNewCasesAppear = newPrizes.length > 0;
    const isGrandTrigger = allPrizes.length === 15;
    const jackpotWinCounts = isGrandTrigger ? { ['GRAND']: 1 } : undefined;
    const respins = isGrandTrigger ? 0 : didAnyNewCasesAppear ? 3 : oldRespins - 1;
    const phase: CaseChasePhase = respins > 0 ? 'IN_PROGRESS' : 'END';

    const winAmount = phase === 'END' ? getTotalValueOfFixedCasePrizes(allPrizes) : 0;
    const cumulativeWinAmount = oldCumulativeWinAmount + winAmount;
    const winType = getWinType({ winAmount, isCaseChaseRespin: true });
    const roundComplete = !respins && !gameState.freeSpinCount;
    const gameResponse: GameResponse = {
        reelStripPositions,
        slotWindow,
        coinAmount,
        playAmount: 0,
        winAmount,
        lineWins: [],
        winType,
        cumulativeWinAmount,
        freeSpinPhase,
        freeSpinCount,
        goldCaseCells: goldCases.cells,
        jackpotWinCounts,
        caseChase: {
            phase,
            respins,
            newPrizes,
            allPrizes,
        },
    };
    const newGameState: GameState = {
        coinAmount,
        reelStripPositions,
        slotWindow,
        cumulativeWinAmount,
        freeSpinPhase,
        freeSpinCount,
        caseChase: {
            phase,
            respins,
            newPrizes,
            allPrizes,
            hardPosition,
            mediumPosition,
            goldCasePrizes: goldCases.prizes,
            goldCasePicks: [],
        },
    };

    // Only return GameState if the next spin will be a feature spin
    return {
        gameResponse,
        gameState: roundComplete ? undefined : newGameState,
        playSummary: { playAmount: 0, winAmount, roundComplete, jackpotWinCounts },
    };
}

function getTotalValueOfFixedCasePrizes(casePrizes: CasePrizesByCell) {
    return casePrizes.reduce((sum, prize) => sum + (prize.value ?? 0), 0);
}

/** Combine two disjoint lists of CASE prizes into a single sorted list. */
function combineCasePrizes(prizes1: CasePrizesByCell, prizes2: CasePrizesByCell) {
    const casePrizes = prizes1.concat(prizes2); // NB: this creates a new array and leaves the inputs unchanged.
    casePrizes.sort(({ cell: [arow, acol] }, { cell: [brow, bcol] }) => {
        if (acol < bcol) return -1;
        if (acol > bcol) return 1;
        if (arow < brow) return -1;
        if (arow > brow) return 1;
        throw new Error()
    });
    return casePrizes;
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
