import Vue, { PropType } from "vue";
import { SpinOutcome } from "../math-model";
import { spinTypeFromSpinOutcome } from "../math-model-helpers";
import { SpinType } from "../math-model/choices";
import { ReelSymbol } from "./reel-symbol";

export default Vue.component("SlotWindow", {
  props: {
    spinOutcome: Object as PropType<SpinOutcome>,
  },
  components: {
    "reel-symbol": ReelSymbol,
  },
  computed: {
    isFreeSpin: function (): boolean {
      return !!this.spinOutcome.freeSpinPhase;
    },
    isWildFurySpin: function (): boolean {
      return !!this.spinOutcome.wildFuryPhase;
    },
    spinType: function (): SpinType {
      return spinTypeFromSpinOutcome(this.spinOutcome);
    },
  },
});
