var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Base")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewBaseReelsetNumber()
              },
            },
          },
          [_vm._v("Wild Reel Base")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.viewFeatureReelsetNumber()
              },
            },
          },
          [_vm._v("Wild Reel Feature")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addCriminalBaseStep()
              },
            },
          },
          [_vm._v("Criminal Base")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addCriminalFeatureStep()
              },
            },
          },
          [_vm._v("Criminal Feature")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFlyingWildBaseStep()
              },
            },
          },
          [_vm._v("Flying Wild Base")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFlyingWildFeatureStep()
              },
            },
          },
          [_vm._v("Flying Wild Feature")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpenBaseWild,
                expression: "isOpenBaseWild",
              },
            ],
            staticClass: "flex-center fs-selector sm-valkyrie sm-base-wild",
          },
          [
            _c(
              "label",
              {
                staticClass: "fs_subtitle",
                attrs: { for: "base_wild_number" },
              },
              [_vm._v("Base Wild Reelset number: ")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.baseWildReelNumber,
                    expression: "baseWildReelNumber",
                  },
                ],
                attrs: { id: "base_wild_number" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.baseWildReelNumber = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.addWildReelsBaseStep()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("[1]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("[2]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("[3]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("[4,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("[1,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("[2,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("[3,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "8" } }, [_vm._v("[1,4]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "9" } }, [_vm._v("[2,4]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("[3,4]")]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpenFeatureWild,
                expression: "isOpenFeatureWild",
              },
            ],
            staticClass: "flex-center fs-selector sm-valkyrie sm-feature-wild",
          },
          [
            _c(
              "label",
              { staticClass: "fs_subtitle", attrs: { for: "fs_number" } },
              [_vm._v("Feature Wild Reelset number: ")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.featureWildReelNumber,
                    expression: "featureWildReelNumber ",
                  },
                ],
                attrs: { id: "fs_number" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.featureWildReelNumber = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.addWildReelsFeatureStep()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("[1]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("[2]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("[3]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [_vm._v("[4,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [_vm._v("[1,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } }, [_vm._v("[2,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("[3,5]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "8" } }, [_vm._v("[1,4]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "9" } }, [_vm._v("[2,4]")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("[3,4]")]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["flex-center"] },
        _vm._l(5, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: {
              index: reelIndex - 1,
              step: _vm.step,
              isFeature: _vm.isFeature,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }