import type { Static } from "@sinclair/typebox";
import { Type } from "@sinclair/typebox";
import type { Choices } from "./choices";
import { JackpotType, PickFeatureAward } from "../operations/shared";

export const ScenarioSchema = Type.Object({
  reelStripPositions: Type.Optional(Type.Array(Type.Integer())),
  reelSpinFeatureSetId: Type.Optional(Type.Integer()),
  fullReelWilds: Type.Optional(
    Type.Array(
      Type.Union([
        Type.Literal(0),
        Type.Literal(1),
        Type.Literal(2),
        Type.Literal(3),
        Type.Literal(4),
      ]),
      {
        minItems: 1,
        maxItems: 4,
      }
    )
  ),
  characterWilds: Type.Optional(
    Type.Array(
      Type.Union([
        Type.Literal("PIC1"),
        Type.Literal("PIC2"),
        Type.Literal("PIC3"),
      ]),
      {
        minItems: 1,
        maxItems: 3,
      }
    )
  ),
  singleWilds: Type.Optional(
    Type.Array(
      Type.Tuple([
        Type.Integer({ minimum: 0, maximum: 2 }),
        Type.Integer({ minimum: 0, maximum: 4 }),
      ]),
      {
        minItems: 3,
        maxItems: 7,
      }
    )
  ),
  multiplierCells: Type.Optional(
    Type.Array(
      Type.Object({
        cell: Type.Tuple([
          Type.Integer({ minimum: 0, maximum: 2 }),
          Type.Integer({ minimum: 0, maximum: 4 }),
        ]),
        multiplier: Type.Integer({ minimum: 2, maximum: 5 }),
      })
    )
  ),
  shouldReelSpinFeaturesApplied: Type.Optional(Type.Boolean()),
  reelSpinDeliverySequence: Type.Optional(
    Type.Union([
      Type.Literal("BEFORE"),
      Type.Literal("AFTER"),
      Type.Literal("BOTH"),
    ])
  ),
  reelSpinFeatureDeliveryOrder: Type.Optional(
    Type.Array(
      Type.Union([
        Type.Literal("FRW"),
        Type.Literal("CW"),
        Type.Literal("SW"),
        Type.Literal("M"),
      ])
    )
  ),
  deliverRemainingFeaturesBeforeReelsStop: Type.Optional(Type.Boolean()),
  shouldIncludeNonWinningMultipliers: Type.Optional(Type.Boolean()),
  pickFeatureFreeSpinsAwarded: Type.Optional(Type.Integer()),
  pickFeatureAwarded: Type.Optional(Type.Array(PickFeatureAward)),
  jackpotAwarded: Type.Optional(JackpotType),
});
export type Scenario = Static<typeof ScenarioSchema>;

export function createChoicesFromScenario(scenario: Scenario): Choices {
  return {
    chooseReelStripPositions() {
      return scenario.reelStripPositions ?? [0, 0, 0, 0, 0];
    },
    chooseReelSpinFeatureSet() {
      return scenario.reelSpinFeatureSetId ?? 0;
    },
    chooseFullReelWildsFeatureReels() {
      return scenario.fullReelWilds ?? [];
    },
    chooseWhetherReelSpinFeaturesApplied() {
      return scenario.shouldReelSpinFeaturesApplied ?? true;
    },
    chooseCharacterWilds(): ("PIC1" | "PIC2" | "PIC3")[] {
      return scenario.characterWilds ?? [];
    },
    chooseSingleWilds() {
      return scenario.singleWilds ?? [];
    },
    chooseMultiplierCells(): { cell: [number, number]; multiplier: number }[] {
      return scenario.multiplierCells ?? [];
    },
    chooseReelSpinDeliverySequence() {
      return scenario.reelSpinDeliverySequence ?? "BEFORE";
    },
    chooseFeatureDeliveryOrder() {
      return scenario.reelSpinFeatureDeliveryOrder ?? ["FRW", "SW", "CW", "M"];
    },
    chooseWhetherRemainingFeatureDeliveredBeforeReelsStop() {
      return scenario.deliverRemainingFeaturesBeforeReelsStop ?? true;
    },
    chooseFreeSpins(): number {
      return scenario.pickFeatureFreeSpinsAwarded ?? 1;
    },
    choosePickAwards() {
      return scenario.pickFeatureAwarded ?? ["START"];
    },
    shouldIncludeNonWinningMultipliers() {
      return scenario.shouldIncludeNonWinningMultipliers ?? true;
    },
    chooseJackpotTriggered() {
      return !!scenario.jackpotAwarded;
    },
    chooseJackpot() {
      return scenario.jackpotAwarded ?? "MINI";
    },
  };
}
