import type { Choices } from '../../choices';
import type { SpinOutcome } from './outcome';
import type { SpinInputs } from './inputs';
import { spinBase } from './spin-base';
import { assertSpinInputsValid } from './inputs';
import { spinFree } from './spin-free';
import { pickSpin } from './pick-spin';
import { spinJackpot } from './spin-jackpot';

export { GameRequestSchema, type GameRequest } from './game-request';
export { GameResponseSchema, type GameResponse } from './game-response';
export type { SpinOutcome, SpinInputs };

export function play({ coinType, gameRequest, gameState }: SpinInputs, choices: Choices): SpinOutcome {
    assertSpinInputsValid({ coinType, gameRequest, gameState });

    const isPickSpin = gameState?.picks !== undefined;
    const isFreeSpins = gameState?.freeSpins !== undefined;
    const isJackpotSpin = gameState?.jackpotPhase !== undefined;

    if (isPickSpin) {
        return pickSpin({ gameRequest, gameState }, choices);
    } else if (isJackpotSpin) {
        return spinJackpot({ gameRequest, gameState, coinType }, choices);
    } else if (isFreeSpins) {
        return spinFree({ gameRequest, gameState }, choices);
    } else {
        return spinBase({ gameRequest, coinType }, choices);
    }
}
