import Vue from "vue";
import bus from "../../../../common/bus";
import events from "../../../../common/events";

import {
  StepJson,
  originatorId,
  createSpinStep,
  createClearingStep,
  createFreeSpinTriggerStep,
  createScatterWinStep,
  createSuperDrumSpinStep,
  createJackpotTriggerStep,
} from "../../../../state/models/slots/vgw097";
import { GameState, spin, modelDefinition } from "./math-model";
import { createChoicesFromScenarioStep } from "./math-model/choices";
import { SlotWindow } from "./slot-window";
import { handlePick } from "./math-model/spin";
import { PickOptions } from "./math-model/spin/pick";

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
  },
  data: function (): {
    scenario: InternalScenario;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        pickOption: undefined,
        reelStripPositions: [
          { value: 24 },
          { value: 24 },
          { value: 24 },
          { value: 24 },
          { value: 24 },
        ],
        superDrum: {
          isSuperDrumAwarded: false,
          multiplier: 1,
        },
      },
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      if (
        this.currentStep?.gameState &&
        this.currentStep?.isPick &&
        this.currentStep?.json.pickOption
      ) {
        const pickOutcome = handlePick({
          gameState: this.currentStep.gameState,
          pick: this.currentStep?.json.pickOption,
          choices: createChoicesFromScenarioStep({
            ...this.scenario,
            reelStripPositions: this.scenario.reelStripPositions.map(
              ({ value }) => value
            ),
          }),
        });
        return pickOutcome;
      } else {
        const spinOutput = spin({
          coinTypeId: 4,
          coinSize: 1,
          choices: createChoicesFromScenarioStep({
            ...this.scenario,
            reelStripPositions: this.scenario.reelStripPositions.map(
              ({ value }) => value
            ),
          }),
          gameState: this.currentStep?.gameState,
        });

        return spinOutput.spinOutcome;
      }
    },
    // ----- Base game state -----
    reels(): string[][] {
      return modelDefinition.reels;
    },
    freeSpinMultiplierList(): number[] {
      return [...new Set(modelDefinition.freeSpinsMultiplierWheel)].sort();
    },
    freeSpinAwardList(): number[] {
      return [...new Set(modelDefinition.freeSpinsWheel)].sort();
    },
    superDrumMultiplierList(): number[] {
      return [
        ...new Set([
          ...modelDefinition.superDrumMultiplierWeights.map((w) => w.outcome),
          1,
        ]),
      ].sort();
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    isWheelSpin: function (): boolean {
      return this.currentStep?.isPick ?? false;
    },
  },
  methods: {
    addSpinStep() {
      bus.$emit(events.ADD_STEP, createSpinStep());
    },
    addClearingStep() {
      bus.$emit(events.ADD_STEP, createClearingStep());
    },
    addFreeSpinTriggerStep() {
      bus.$emit(events.ADD_STEP, createFreeSpinTriggerStep());
    },
    addScatterWinStep() {
      bus.$emit(events.ADD_STEP, createScatterWinStep());
    },
    addSuperDrumSpinStep() {
      bus.$emit(events.ADD_STEP, createSuperDrumSpinStep());
    },
    addJackpotTriggerStep() {
      bus.$emit(events.ADD_STEP, createJackpotTriggerStep());
    },
    // ----- Scenario persistence methods -----
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    handleSuperDrumSelected() {
      this.scenario.superDrum.multiplier =
        this.scenario.superDrum.multiplier ?? 1;

      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }
        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertToStepJson(newScenario),
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
  };
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  return {
    gameState: scenario.gameState,
    superDrum: scenario.superDrum,
    freeSpinsMultiplier: scenario.freeSpinsMultiplier,
    freeSpinsAwarded: scenario.freeSpinsAwarded,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
    pickOption: scenario.pickOption,
  };
}

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;
  let lastPick: PickOptions | undefined =
    undefined;

  for (const step of steps) {
    if (step.json.gameState === "clear") gameState = undefined;

    step.gameState = gameState;
    step.isPick = false;

    if (
      gameState &&
      gameState.freeSpinPhase === "START" &&
      (gameState.freeSpinCount === undefined ||
        gameState.freeSpinsAwarded === undefined ||
        gameState.freeSpinsMultiplier === undefined)
    ) {
      step.json.pickOption = step.json.pickOption
        ? step.json.pickOption
        : "freeSpinWheel";
      lastPick = lastPick
        ? getCorrectPick(step.json.pickOption, lastPick)
        : step.json.pickOption;

      step.isPick = true;
      const pickOutcome = handlePick({
        gameState,
        pick: lastPick,
        choices: createChoicesFromScenarioStep({
          ...step.json,
        }),
      });

      step.json.pickOption = lastPick;
      gameState = pickOutcome.gameState;
    } else {
      lastPick = undefined;
      const spinOutput = spin({
        coinTypeId: 4,
        coinSize: 1,
        choices: createChoicesFromScenarioStep({
          ...step.json,
        }),
        gameState,
      });

      gameState = spinOutput.gameState;
    }
  }

  return modified;
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
  isPick: boolean;
}

interface InternalScenario {
  reelStripPositions: { value: number }[];
  superDrum: {
    isSuperDrumAwarded: boolean;
    multiplier: number;
  };
  freeSpinsAwarded?: number;
  freeSpinsMultiplier?: number;
  gameState?: "continue" | "clear";
  pickOption?: PickOptions;
}

function getCorrectPick(
  pick: PickOptions,
  lastPick?: PickOptions
): PickOptions {
  if (!lastPick || lastPick === "bothWheels") return pick;
  else if (lastPick === "multiplierWheel") return "freeSpinWheel";
  else return "multiplierWheel";
}
