// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-window[data-v-601a1360] {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5em;
    justify-items: center;
    background: #707070; 
    padding: 1em;
    box-shadow: 0 4px 8px 0 #707070; 
    padding: 1em;
    border-radius: 0.5em;
    background: #707070;
}
.slot-window > div[data-v-601a1360] {
    text-align: center;
    width: 100%;
    aspect-ratio: 1;
    background: #707070; 
    color: lightgray;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    border-radius: 0.5em;
    box-shadow: inset 0 0 10px #555555; 
    font-size: 0.8em;
}
.card-generic-info[data-v-601a1360] {
    background-color: #404040; 
    color: lightgray; 
    padding: 0.5em; 
    border-radius: 0.5em; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
    margin: 1em 0;
}
.heading[data-v-601a1360]{
    color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/components/game_content/slots/vgw097/slot-window/slot-window.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,UAAU;IACV,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,+BAA+B;IAC/B,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,gBAAgB;;IAEhB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,oBAAoB;IACpB,kCAAkC;IAClC,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,oBAAoB;IACpB,wCAAwC;IACxC,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".slot-window[data-v-601a1360] {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    gap: 0.5em;\n    justify-items: center;\n    background: #707070; \n    padding: 1em;\n    box-shadow: 0 4px 8px 0 #707070; \n    padding: 1em;\n    border-radius: 0.5em;\n    background: #707070;\n}\n.slot-window > div[data-v-601a1360] {\n    text-align: center;\n    width: 100%;\n    aspect-ratio: 1;\n    background: #707070; \n    color: lightgray;\n  \n    display: flex;\n    justify-content: center;\n    align-items: center;\n  \n    border-radius: 0.5em;\n    box-shadow: inset 0 0 10px #555555; \n    font-size: 0.8em;\n}\n.card-generic-info[data-v-601a1360] {\n    background-color: #404040; \n    color: lightgray; \n    padding: 0.5em; \n    border-radius: 0.5em; \n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); \n    margin: 1em 0;\n}\n.heading[data-v-601a1360]{\n    color: lightgray;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
