// tslint:disable object-literal-key-quotes trailing-comma
/* in order to support standalone, please insert the init response from the server here */
export default {
	"modelDefinition": {
		"modelId": "vgw066",

		"scatterSymbol": "SCAT",
		"wildSymbol": "WILD",
		"levelUpSymbol": "LEVEL_UP",
		"extraSpinSymbol": "1_SPIN",
		"freeSpinTrigger": "SCAT",
		"countToTriggerFreeSpin": 3,
		"freeSpinCount": 10,
		"reelsLayout": [4, 4, 4, 4, 4],
		"defaultSelectLine": "50",
	
		"winTable": [
			{
				"symbol": "WILD",
				"multipliers": [500, 100, 20]
			},
			{
				"symbol": "PIC1",
				"multipliers": [500, 100, 20]
			},
			{
				"symbol": "PIC2",
				"multipliers": [300, 75, 15]
			},
			{
				"symbol": "PIC3",
				"multipliers": [200, 50, 10]
			},
			{
				"symbol": "PIC4",
				"multipliers": [200, 50, 10]
			},
			{
				"symbol": "PIC5",
				"multipliers": [200, 50, 10]
			},
			{
				"symbol": "HEART",
				"multipliers": [100, 20, 5]
			},
			{
				"symbol": "DIAMOND",
				"multipliers": [100, 20, 5]
			},
			{
				"symbol": "CLUB",
				"multipliers": [100, 20, 5]
			},
			{
				"symbol": "SPADE",
				"multipliers": [100, 20, 5]
			},
			{
				"symbol": "SCAT",
				"multipliers": [25, 10, 2]
			},
			{
				"symbol": "LEVEL_UP",
				"multipliers": []
			}
			,
			{
				"symbol": "1_SPIN",
				"multipliers": []
			}
		],
	
		"playLines": [
			[1, 1, 1, 1, 1], // 1
			[2, 2, 2, 2, 2], // 2
			[0, 0, 0, 0, 0], // 3
			[3, 3, 3, 3, 3], // 4
			[0, 0, 1, 1, 1], // 5
			[1, 1, 0, 0, 0], // 6
			[0, 0, 0, 1, 1], // 7
			[1, 1, 1, 0, 0], // 8
			[1, 1, 2, 2, 2], // 9
			[2, 2, 1, 1, 1], // 10
			[1, 1, 1, 2, 2], // 11
			[2, 2, 2, 1, 1], // 12
			[2, 2, 3, 3, 3], // 13
			[3, 3, 2, 2, 2], // 14
			[2, 2, 2, 3, 3], // 15
			[3, 3, 3, 2, 2], // 16
			[0, 0, 1, 2, 2], // 17
			[2, 2, 1, 0, 0], // 18
			[1, 1, 2, 3, 3], // 19
			[3, 3, 2, 1, 1], // 20
			[0, 1, 2, 1, 2], // 21
			[2, 1, 0, 1, 0], // 22
			[0, 1, 0, 1, 2], // 23
			[2, 1, 2, 1, 0], // 24
			[1, 2, 3, 2, 3], // 25
			[3, 2, 1, 2, 1], // 26
			[1, 2, 1, 2, 3], // 27
			[3, 2, 3, 2, 1], // 28
			[0, 1, 1, 1, 0], // 29
			[1, 0, 0, 0, 1], // 30
			[1, 2, 2, 2, 1], // 31
			[2, 1, 1, 1, 2], // 32
			[2, 3, 3, 3, 2], // 33
			[3, 2, 2, 2, 3], // 34
			[0, 1, 0, 1, 0], // 35
			[1, 0, 1, 0, 1], // 36
			[1, 2, 1, 2, 1], // 37
			[2, 1, 2, 1, 2], // 38
			[2, 3, 2, 3, 2], // 39
			[3, 2, 3, 2, 3], // 40
			[0, 1, 0, 2, 0], // 41
			[2, 1, 2, 0, 2], // 42
			[1, 2, 1, 3, 1], // 43
			[3, 2, 3, 1, 3], // 44
			[0, 1, 0, 2, 1], // 45
			[2, 1, 2, 0, 1], // 46
			[1, 2, 1, 3, 2], // 47
			[3, 2, 3, 1, 2], // 48
			[0, 3, 2, 0, 2], // 49
			[3, 0, 1, 3, 1], // 50
		],
	
		"reels": [
			["DIAMOND","PIC1","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","HEART","PIC2","CLUB","PIC4","HEART","DIAMOND","PIC5","CLUB","PIC2","PIC2","HEART","PIC5","PIC5","CLUB","PIC4","PIC4","SPADE","SPADE","WILD","WILD","WILD","WILD","CLUB","CLUB","PIC4","DIAMOND","PIC5","SPADE","SPADE","PIC3","PIC3","HEART","PIC4","DIAMOND","CLUB","PIC3","SPADE","PIC5","PIC5","DIAMOND","DIAMOND","PIC4","PIC4","HEART","HEART","PIC5","PIC5","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","SPADE","SCAT","DIAMOND","WILD","CLUB","WILD","HEART","PIC3","PIC3","DIAMOND","DIAMOND","DIAMOND","PIC4","PIC4","CLUB","CLUB","PIC2","PIC2","HEART","PIC4","DIAMOND","DIAMOND","SCAT","CLUB","PIC2","PIC2","HEART","PIC4","DIAMOND","CLUB","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","PIC3","DIAMOND","DIAMOND","DIAMOND","PIC4","CLUB","PIC2","HEART","PIC5","SPADE","CLUB","PIC4","PIC4","HEART","PIC3","SPADE","SPADE","SPADE","PIC5","PIC5","PIC5","HEART","PIC3","PIC3","DIAMOND","DIAMOND","PIC1","PIC1","SPADE","PIC3","CLUB","SCAT","HEART","PIC4","CLUB","PIC2","HEART","PIC3","PIC3","SPADE","SPADE","PIC5","CLUB","CLUB","PIC4","PIC4","PIC4","SPADE","WILD","HEART","HEART","PIC5","PIC5","SPADE","SPADE","SPADE","PIC2","PIC2","PIC2","CLUB","CLUB","PIC1","PIC1","DIAMOND","HEART","SPADE","PIC4","DIAMOND"],
			["CLUB","PIC1","PIC1","PIC1","PIC1","HEART","HEART","PIC4","PIC4","PIC4","SPADE","SPADE","PIC5","CLUB","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","PIC3","CLUB","CLUB","WILD","WILD","WILD","WILD","DIAMOND","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC4","PIC4","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","PIC4","HEART","HEART","SCAT","SPADE","PIC4","PIC4","CLUB","PIC3","SPADE","SPADE","SCAT","CLUB","WILD","DIAMOND","WILD","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC2","SPADE","PIC5","PIC5","HEART","PIC4","SPADE","PIC2","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","HEART","HEART","SCAT","DIAMOND","PIC2","PIC2","SPADE","PIC3","PIC3","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC3","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC4","HEART","PIC2","SPADE","SPADE","PIC5","PIC5","PIC5","DIAMOND","PIC4","CLUB","CLUB","PIC1","PIC1","DIAMOND","SCAT","SPADE","PIC3","PIC3","SPADE","SPADE","PIC2","DIAMOND","DIAMOND","PIC3","HEART","PIC4","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","CLUB","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","WILD","DIAMOND","DIAMOND","DIAMOND","PIC5","CLUB","WILD","SPADE","SPADE","PIC4","HEART","PIC1","PIC1","DIAMOND","DIAMOND","DIAMOND","PIC3"],
			["HEART","HEART","PIC1","PIC1","PIC1","PIC1","CLUB","CLUB","PIC3","PIC3","HEART","PIC5","DIAMOND","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","PIC4","HEART","WILD","WILD","WILD","WILD","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","PIC4","HEART","PIC3","CLUB","CLUB","SCAT","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","PIC4","DIAMOND","DIAMOND","SCAT","HEART","WILD","SPADE","WILD","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC5","PIC5","CLUB","PIC2","DIAMOND","PIC1","PIC1","PIC1","SPADE","PIC4","PIC4","CLUB","SCAT","SPADE","PIC2","PIC2","DIAMOND","PIC4","PIC4","SPADE","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","PIC4","CLUB","PIC3","PIC3","SPADE","SPADE","PIC2","CLUB","PIC3","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","SPADE","PIC3","PIC3","HEART","PIC1","PIC1","SPADE","SPADE","SCAT","DIAMOND","PIC4","PIC4","PIC4","SPADE","PIC2","CLUB","PIC3","DIAMOND","DIAMOND","PIC4","PIC4","HEART","PIC5","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","WILD","SPADE","SPADE","PIC3","HEART","WILD","CLUB","PIC5","DIAMOND","DIAMOND","PIC1","PIC1","SPADE","SPADE","SPADE","PIC4"],
			["SPADE","PIC1","PIC1","PIC1","PIC1","HEART","PIC4","PIC4","CLUB","CLUB","PIC2","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","WILD","WILD","WILD","WILD","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","CLUB","PIC4","HEART","HEART","SCAT","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC3","SPADE","SCAT","DIAMOND","WILD","CLUB","WILD","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","SCAT","DIAMOND","PIC5","PIC5","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC2","PIC2","HEART","HEART","PIC5","CLUB","PIC3","PIC3","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","PIC1","PIC1","PIC1","HEART","PIC4","PIC4","DIAMOND","DIAMOND","DIAMOND","PIC5","HEART","PIC4","DIAMOND","PIC3","SPADE","PIC4","PIC4","CLUB","PIC1","PIC1","DIAMOND","DIAMOND","SCAT","SPADE","SPADE","PIC3","PIC3","DIAMOND","DIAMOND","PIC4","CLUB","WILD","HEART","PIC2","SPADE","SPADE","PIC1","PIC1","DIAMOND","DIAMOND","PIC5","HEART","PIC4","SPADE","SPADE","PIC3","PIC3","PIC3","CLUB","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","WILD","DIAMOND","DIAMOND","PIC3"],
			["CLUB","CLUB","PIC1","PIC1","PIC1","PIC1","DIAMOND","PIC3","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC5","HEART","PIC4","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","HEART","HEART","PIC5","PIC5","CLUB","PIC2","SPADE","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","CLUB","WILD","WILD","WILD","WILD","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","WILD","DIAMOND","WILD","SPADE","PIC4","PIC4","DIAMOND","SCAT","HEART","PIC2","PIC2","DIAMOND","PIC3","PIC3","CLUB","PIC4","HEART","HEART","SCAT","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC3","SPADE","SPADE","PIC5","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","SCAT","DIAMOND","PIC4","PIC4","SPADE","PIC3","PIC3","CLUB","SCAT","DIAMOND","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC1","PIC1","DIAMOND","DIAMOND","SCAT","SPADE","PIC3","PIC3","PIC3","DIAMOND","DIAMOND","PIC5","HEART","PIC4","SPADE","SPADE","PIC3","PIC3","CLUB","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","WILD","DIAMOND","DIAMOND","PIC4","CLUB","WILD","HEART","PIC2","SPADE","SPADE","SCAT","DIAMOND","DIAMOND","PIC3"],
		],
		"featureReels": [
			["DIAMOND","PIC1","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","HEART","PIC2","CLUB","PIC4","HEART","DIAMOND","PIC5","CLUB","PIC2","PIC2","HEART","PIC5","PIC5","CLUB","PIC4","PIC4","SPADE","SPADE","WILD","WILD","WILD","WILD","CLUB","CLUB","PIC4","DIAMOND","PIC5","SPADE","SPADE","PIC3","PIC3","HEART","PIC4","DIAMOND","CLUB","PIC3","SPADE","PIC5","PIC5","DIAMOND","DIAMOND","PIC4","PIC4","HEART","HEART","PIC5","PIC5","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","SPADE","LEVEL_UP","DIAMOND","WILD","CLUB","WILD","HEART","PIC3","PIC3","DIAMOND","DIAMOND","DIAMOND","PIC4","PIC4","CLUB","CLUB","PIC2","PIC2","HEART","PIC4","DIAMOND","DIAMOND","1_SPIN","CLUB","PIC2","PIC2","HEART","PIC4","DIAMOND","CLUB","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","PIC3","DIAMOND","DIAMOND","DIAMOND","PIC4","CLUB","PIC2","HEART","PIC5","SPADE","CLUB","PIC4","PIC4","HEART","PIC3","SPADE","SPADE","SPADE","PIC5","PIC5","PIC5","HEART","PIC3","PIC3","DIAMOND","DIAMOND","PIC1","PIC1","SPADE","PIC3","CLUB","1_SPIN","HEART","PIC4","CLUB","PIC2","HEART","PIC3","PIC3","SPADE","SPADE","PIC5","CLUB","CLUB","PIC4","PIC4","PIC4","SPADE","WILD","HEART","HEART","PIC5","PIC5","SPADE","SPADE","SPADE","PIC2","PIC2","PIC2","CLUB","CLUB","PIC1","PIC1","DIAMOND","HEART","SPADE","PIC4","DIAMOND"],
			["CLUB","PIC1","PIC1","PIC1","PIC1","HEART","HEART","PIC4","PIC4","PIC4","SPADE","SPADE","PIC5","CLUB","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","PIC3","CLUB","CLUB","WILD","WILD","WILD","WILD","DIAMOND","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC4","PIC4","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","PIC4","HEART","HEART","LEVEL_UP","SPADE","PIC4","PIC4","CLUB","PIC3","SPADE","SPADE","1_SPIN","CLUB","WILD","DIAMOND","WILD","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC2","SPADE","PIC5","PIC5","HEART","PIC4","SPADE","PIC2","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","HEART","HEART","1_SPIN","DIAMOND","PIC2","PIC2","SPADE","PIC3","PIC3","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC3","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC4","HEART","PIC2","SPADE","SPADE","PIC5","PIC5","PIC5","DIAMOND","PIC4","CLUB","CLUB","PIC1","PIC1","DIAMOND","1_SPIN","SPADE","PIC3","PIC3","SPADE","SPADE","PIC2","DIAMOND","DIAMOND","PIC3","HEART","PIC4","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","CLUB","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","WILD","DIAMOND","DIAMOND","DIAMOND","PIC5","CLUB","WILD","SPADE","SPADE","PIC4","HEART","PIC1","PIC1","DIAMOND","DIAMOND","DIAMOND","PIC3"],
			["HEART","HEART","PIC1","PIC1","PIC1","PIC1","CLUB","CLUB","PIC3","PIC3","HEART","PIC5","DIAMOND","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","PIC4","HEART","WILD","WILD","WILD","WILD","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","PIC4","HEART","PIC3","CLUB","CLUB","LEVEL_UP","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","PIC4","DIAMOND","DIAMOND","1_SPIN","HEART","WILD","SPADE","WILD","DIAMOND","PIC3","PIC3","SPADE","SPADE","PIC5","PIC5","CLUB","PIC2","DIAMOND","PIC1","PIC1","PIC1","SPADE","PIC4","PIC4","CLUB","1_SPIN","SPADE","PIC2","PIC2","DIAMOND","PIC4","PIC4","SPADE","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","PIC4","CLUB","PIC3","PIC3","SPADE","SPADE","PIC2","CLUB","PIC3","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC4","SPADE","PIC3","PIC3","HEART","PIC1","PIC1","SPADE","SPADE","1_SPIN","DIAMOND","PIC4","PIC4","PIC4","SPADE","PIC2","CLUB","PIC3","DIAMOND","DIAMOND","PIC4","PIC4","HEART","PIC5","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","WILD","SPADE","SPADE","PIC3","HEART","WILD","CLUB","PIC5","DIAMOND","DIAMOND","PIC1","PIC1","SPADE","SPADE","SPADE","PIC4"],
			["SPADE","PIC1","PIC1","PIC1","PIC1","HEART","PIC4","PIC4","CLUB","CLUB","PIC2","SPADE","SPADE","SPADE","PIC3","PIC3","CLUB","WILD","WILD","WILD","WILD","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","CLUB","PIC4","HEART","HEART","LEVEL_UP","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC3","SPADE","1_SPIN","DIAMOND","WILD","CLUB","WILD","SPADE","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","1_SPIN","DIAMOND","PIC5","PIC5","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC2","PIC2","HEART","HEART","PIC5","CLUB","PIC3","PIC3","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","PIC1","PIC1","PIC1","HEART","PIC4","PIC4","DIAMOND","DIAMOND","DIAMOND","PIC5","HEART","PIC4","DIAMOND","PIC3","SPADE","PIC4","PIC4","CLUB","PIC1","PIC1","DIAMOND","DIAMOND","1_SPIN","SPADE","SPADE","PIC3","PIC3","DIAMOND","DIAMOND","PIC4","CLUB","WILD","HEART","PIC2","SPADE","SPADE","PIC1","PIC1","DIAMOND","DIAMOND","PIC5","HEART","PIC4","SPADE","SPADE","PIC3","PIC3","PIC3","CLUB","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","WILD","DIAMOND","DIAMOND","PIC3"],
			["CLUB","CLUB","PIC1","PIC1","PIC1","PIC1","DIAMOND","PIC3","HEART","PIC4","PIC4","DIAMOND","DIAMOND","PIC5","HEART","PIC4","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","HEART","HEART","PIC5","PIC5","CLUB","PIC2","SPADE","SPADE","SPADE","PIC4","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC3","PIC3","CLUB","WILD","WILD","WILD","WILD","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","SPADE","PIC4","PIC4","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","WILD","DIAMOND","WILD","SPADE","PIC4","PIC4","DIAMOND","1_SPIN","HEART","PIC2","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","CLUB","PIC4","HEART","PIC2","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC3","SPADE","SPADE","PIC5","SPADE","PIC1","PIC1","PIC1","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","HEART","HEART","PIC4","PIC4","SPADE","PIC3","PIC3","CLUB","CLUB","PIC5","DIAMOND","DIAMOND","DIAMOND","PIC1","PIC1","PIC1","SPADE","SPADE","PIC4","PIC4","PIC4","CLUB","PIC1","PIC1","DIAMOND","DIAMOND","1_SPIN","SPADE","PIC3","PIC3","PIC3","DIAMOND","DIAMOND","PIC5","HEART","PIC4","SPADE","SPADE","PIC3","PIC3","CLUB","PIC2","DIAMOND","DIAMOND","PIC3","PIC3","PIC3","SPADE","SPADE","WILD","DIAMOND","DIAMOND","PIC4","CLUB","WILD","HEART","PIC2","SPADE","SPADE","1_SPIN","DIAMOND","DIAMOND","PIC3"],
		],
		
		"winLevels": [
			{ "name": "normal1", "threshold": 5 },
			{ "name": "normal2", "threshold": 15 },
			{ "name": "normal3", "threshold": 20 },
			{ "name": "bigwin", "threshold": 35 },
			{ "name": "megawin", "threshold": 70 },
			{ "name": "epicwin" }
		],
	
		"beerMeter": {
			"initialLevel": 1,
			"rewardTiers": [
				{
					"minWilds": 0,
					"maxWilds": 4,
					"rewardMultiplier": 0
				},
				{
					"minWilds": 5,
					"maxWilds": 11,
					"rewardMultiplier": 3
				},
				{
					"minWilds": 12,
					"maxWilds": 19,
					"rewardMultiplier": 7
				},
				{
					"minWilds": 20,
					"maxWilds": 29,
					"rewardMultiplier": 25
				},
				{
					"minWilds": 30,
					"maxWilds": 99,
					"rewardMultiplier": 200
				}
			]
		},
	
		"coinType1Values": [200, 1000, 2000, 5000, 10000, 20000],
		"coinType4Values": [1, 2, 5, 10, 20, 50, 100, 200, 400],
	
		"coinType1_min_bet": 200,
		"coinType1_max_bet": 20000,
		"coinType1_spin_max_bet": 1000000,
	
		"coinType4_min_bet": 1,
		"coinType4_max_bet": 400,
		"coinType4_spin_max_bet": 20000,	
	}
};
