var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "reel",
      staticClass: "sm-reel",
    },
    [
      _c(
        "div",
        { class: _vm.canUpdate ? "" : "hide", attrs: { align: "center" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reelIndex,
                expression: "reelIndex",
                modifiers: { number: true },
              },
            ],
            staticClass: "text-input reel-text reel-index",
            attrs: { type: "number" },
            domProps: { value: _vm.reelIndex },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reelIndex = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.down()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-up",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.symbolsAboveWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "active-reels" },
        _vm._l(_vm.reelWindow, function (sym, row) {
          return _c("div", { staticClass: "respin" }, [
            _c(
              "div",
              {
                class: [
                  "symbol",
                  "on",
                  sym,
                  _vm.content.isCoin({ row, col: _vm.col })
                    ? "COIN"
                    : "NONCOIN",
                  _vm.content.canSetCoinPrize({ row, col: _vm.col })
                    ? "unlocked"
                    : "locked",
                  _vm.content.isWildPicTop({ row, col: _vm.col })
                    ? "wildpic-top"
                    : "",
                  _vm.content.isWildPicBottom({ row, col: _vm.col })
                    ? "wildpic-bottom"
                    : "",
                  _vm.content.isWildPicSelected({ row, col: _vm.col })
                    ? "wildpic-selected"
                    : "",
                ],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.content.selectCell({ row, col: _vm.col })
                  },
                },
              },
              [
                _vm.content.isCoin({ row, col: _vm.col })
                  ? _c("div", { staticClass: "text" }, [
                      !_vm.content.canSetCoinPrize({ row, col: _vm.col })
                        ? _c("div", { staticClass: "locked" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.content.getCoinPrize({
                                    row,
                                    col: _vm.col,
                                  })
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _c("div", [
                            _c(
                              "select",
                              {
                                domProps: {
                                  value: _vm.content.getCoinPrize({
                                    row,
                                    col: _vm.col,
                                  }),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.content.setCoinPrize({
                                      row,
                                      col: _vm.col,
                                      value: $event.target.value,
                                    })
                                  },
                                },
                              },
                              [
                                _vm._l(
                                  _vm.content.coinPrizeLevels,
                                  function (opt) {
                                    return [
                                      _c(
                                        "option",
                                        { domProps: { value: opt.value } },
                                        [_vm._v(_vm._s(opt.label))]
                                      ),
                                    ]
                                  }
                                ),
                                _vm._v(" "),
                                _vm.content.canSetOrUnsetCoin({
                                  row,
                                  col: _vm.col,
                                })
                                  ? [
                                      _c(
                                        "option",
                                        { domProps: { value: "0" } },
                                        [_vm._v("❌ Remove")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                    ])
                  : _vm.content.findWildPicAt({ row, col: _vm.col })
                  ? _c(
                      "div",
                      { staticClass: "text" },
                      [
                        _vm.content.canSetWildPicMultiplier({
                          row,
                          col: _vm.col,
                        })
                          ? [
                              _c(
                                "select",
                                {
                                  domProps: {
                                    value: _vm.content.getWildPicMultiplier({
                                      row,
                                      col: _vm.col,
                                    }),
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.content.setWildPicMultiplier({
                                        row,
                                        col: _vm.col,
                                        value: Number($event.target.value),
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.content.wildPicMultipliers,
                                    function (opt) {
                                      return [
                                        _c(
                                          "option",
                                          { domProps: { value: opt.value } },
                                          [_vm._v(_vm._s(opt.label))]
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.content.getWildPicDescription({
                                      row,
                                      col: _vm.col,
                                    })
                                  )
                                ),
                              ]),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "text" },
                      [
                        _vm.content.canSetOrUnsetCoin({ row, col: _vm.col })
                          ? [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.content.setCoinPrize({
                                        row,
                                        col: _vm.col,
                                        value: "20",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("\n                            ("),
                                  _c("i", {
                                    staticClass: "fas fa-2x fa-mouse-pointer",
                                    staticStyle: { "font-size": "85%" },
                                  }),
                                  _vm._v(
                                    " click for coin)\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : _vm.content.isExtraWild({ row, col: _vm.col })
                          ? [
                              _c("span", { staticClass: "extra-wild" }, [
                                _vm._v("EXTRA WILD"),
                              ]),
                            ]
                          : [_c("span", [_vm._v(_vm._s(sym))])],
                        _vm._v(" "),
                        _vm.content.selectedWildPic &&
                        _vm.content.canSetOrUnsetExtraWild({
                          row,
                          col: _vm.col,
                          sym,
                        })
                          ? [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: _vm.content.isExtraWild({
                                    row,
                                    col: _vm.col,
                                  }),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.content.toggleExtraWild({
                                      row,
                                      col: _vm.col,
                                    })
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _vm._l(_vm.symbolsBelowWindow, function (sym) {
        return [
          _c("div", { staticClass: "symbol dim" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(sym))]),
          ]),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center", staticStyle: { "font-size": "12px" } },
        [
          _c(
            "div",
            {
              class: ["pointer", _vm.canUpdate ? "" : "hide"],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.up()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-2x fa-chevron-down",
                staticStyle: {
                  "user-select": "none",
                  margin: "5px",
                  color: "rgb(0, 199, 0)",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }