/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
      "betMultiplier" : 50,
      "modelId" : "vgw060",
      "reelsLayout" : [ 7, 7, 7, 7, 7, 7, 4 ],
      "scatterSymbol" : "SC",
      "wildSymbol" : "WD",
      "freeSpinMultiplier" : 0,
      "freeSpinCount" : 15,
      "winTable" : [ {
        "symbol" : "SC",
        "multipliers" : [ 1, 1, 1, 1 ]
      }, {
        "symbol" : "P1",
        "multipliers" : [ 1000, 500, 200, 100 ]
      }, {
        "symbol" : "P2",
        "multipliers" : [ 150, 50, 40, 20 ]
      }, {
        "symbol" : "P3",
        "multipliers" : [ 40, 30, 20, 5 ]
      }, {
        "symbol" : "P4",
        "multipliers" : [ 40, 15, 10, 5 ]
      }, {
        "symbol" : "P5",
        "multipliers" : [ 35, 12, 8, 4 ]
      }, {
        "symbol" : "P6",
        "multipliers" : [ 35, 12, 8, 4 ]
      }, {
        "symbol" : "SE",
        "multipliers" : [ 20, 10, 5, 3 ]
      }, {
        "symbol" : "CB",
        "multipliers" : [ 20, 10, 5, 3 ]
      }, {
        "symbol" : "HT",
        "multipliers" : [ 20, 10, 5, 3 ]
      }, {
        "symbol" : "DD",
        "multipliers" : [ 20, 10, 5, 3 ]
      } ],
      "coinType1Values" : [ 1000, 2000, 3000, 5000, 10000, 50000, 100000 ],
      "coinType4Values" : [ 1, 2, 3, 5, 10, 50, 100 ],
      "coinType1_min_bet" : 1000,
      "coinType1_max_bet" : 100000,
      "coinType1_spin_max_bet" : 5000000,
      "coinType4_min_bet" : 1,
      "coinType4_max_bet" : 100,
      "coinType4_spin_max_bet" : 5000,
      "winLevels" : [ {
        "name" : "normal1",
        "threshold" : 5
      }, {
        "name" : "normal2",
        "threshold" : 15
      }, {
        "name" : "normal3",
        "threshold" : 25
      }, {
        "name" : "bigwin",
        "threshold" : 50
      }, {
        "name" : "megawin",
        "threshold" : 150
      }, {
        "name" : "epicwin",
        "threshold" : 2147483647
      } ],
      "freeSpinTrigger" : "SC",
      "countToTriggerFreeSpin" : 3,
      "jackpotTypes" : [ "GRAND", "MAJOR" ],
      "jackpotInitialPool" : {
        "gold" : {
          "grand" : 5000000000,
          "major" : 60000000
        },
        "sweeps" : {
          "grand" : 5000000,
          "major" : 60000
        }
      }
    },
    "modelId" : "vgw060",
    "gameSessionId" : 1280,
    "reelStripPositions" : [ 0, 0, 0, 0, 0, 0, 0 ],
    "gold_balance" : 2008650000,
    "sweeps_balance" : 206478189,
    "sweeps_deposit_balance" : 199792270,
    "sweeps_promo_balance" : 0,
    "sweeps_win_balance" : 6685919,
    "sweeps_available_balance" : 6685919,
    "symbolInfo" : [ [ null, null, null, null ], [ null, null, null ], [ null, null, null, null ], [ null, null, null, null, null, null ], [ null, null, null, null, null ], [ null, null ] ],
    "symbolInfoTopReel" : [ null, {
      "price" : 250,
      "enabled" : false
    }, null, null ],
    "slotWindowTopReel" : [ "P5", "CO", "HT", "P3" ],
    "serverTime" : "2020-05-20 07:25:33",
    "slotWindow" : [ [ "SE", "SC", "SE", "SE" ], [ "HT", "P3", "P3" ], [ "P6", "P5", "P5", "P3" ], [ "P5", "P5", "P6", "P1", "P4", "P3" ], [ "SE", "DD", "P5", "CB", "P4" ], [ "CB", "HT" ] ],
    "jackpotPoolCurrent" : {
      "MAJOR" : 62073,
      "GRAND" : 5001644
    }
  }