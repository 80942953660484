import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { trace } from './../../../../helpers/helpers';
import State from '../../../../state/state';
import bus from '../../../../common/bus';
import events from '../../../../common/events';
import DiceResultSelector from './components/diceResultSelector.vue';
import { tf118Step } from 'src/state/models/dice/tf118/step';

@Component({
    components: {
        DiceResultSelector: DiceResultSelector,
    },
})
export default class Content extends Vue {
    @Prop() public step: tf118Step | undefined;
    public show = false;
    public isOpen = false;
    public diceResults = [1, 1];

    constructor() {
        super();
    }

    @Watch('step')
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;
        this.updateDiceResults();
    }

    public mounted() {
        this.isOpen = false;
        trace('reels mounted');
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public addClearingStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getClearingStep());
    }

    public setDiceResults(event) {
        if (!this.step) return;
        if (this.step.json.diceResults == null) {
            this.step.json.diceResults = [1, 1];
        }
        this.step.json.diceResults[event.target.id - 1] = parseInt(event.target.value);
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    public updateDiceResults() {
        if (!this.step) return;
        if (this.step.json.diceResults != null) {
            this.diceResults = this.step.json.diceResults;
        } else {
            this.diceResults = [1, 1];
        }
    }
}
