var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add base step")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center wheel-spin-value-selector-wrapper" },
        [
          _c("div", { staticClass: "flex-center wheel-spin-value-selector" }, [
            _c("label", { attrs: { for: "wheelSpinValue" } }, [
              _vm._v("Wheel spin value: "),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.wheelSpinValue,
                  expression: "wheelSpinValue",
                  modifiers: { number: true },
                },
              ],
              attrs: {
                type: "number",
                min: "0",
                max: "36",
                id: "wheelSpinValue",
              },
              domProps: { value: _vm.wheelSpinValue },
              on: {
                change: function ($event) {
                  return _vm.updateWheelSpinValue(_vm.wheelSpinValue)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.wheelSpinValue = _vm._n($event.target.value)
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center wheel-spin-confirm-button" }, [
      _c(
        "div",
        {
          staticClass: "button pointer",
          attrs: {
            onclick:
              "alert('Wheel spin value ' + wheelSpinValue.value + ' saved on current step!');",
          },
        },
        [_vm._v("Confirm")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }