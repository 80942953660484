export const modelDefinition = {
  // prettier-ignore
  reels: [
        ['PIC5','DIAMOND','BONUS','PIC4','PIC4','HEART','PIC3','PIC5','CLUB','DIAMOND','DIAMOND','PIC3','CLUB','DIAMOND','CLUB','SPADE','SPADE','HEART','BONUS','PIC5','PIC5','PIC3','PIC3','CLUB','PIC2','CLUB','PIC4','PIC5','CLUB','PIC3','CLUB','PIC5','HEART','PIC3','SPADE','SPADE','PIC3','PIC2','HEART','PIC3','PIC5','PIC4','SPADE','SPADE','SCAT','SPADE','SPADE','PIC1','PIC1','PIC2','PIC2','HEART','SPADE','HEART','PIC3','HEART','PIC3','PIC5','PIC1','PIC4','PIC4','SCAT','PIC1','SPADE','SPADE','CLUB','PIC3','HEART'],
        ['CLUB','PIC2','BONUS','PIC5','SPADE','SPADE','PIC5','PIC2','SPADE','DIAMOND','DIAMOND','HEART','HEART','SPADE','PIC4','SPADE','PIC5','HEART','PIC4','DIAMOND','WILD','PIC5','DIAMOND','PIC5','CLUB','PIC3','WILD','DIAMOND','PIC1','CLUB','CLUB','PIC2','PIC2','HEART','DIAMOND','PIC4','PIC4','DIAMOND','DIAMOND','CLUB','SCAT','SCAT','CLUB','PIC5','HEART','HEART','SPADE','SPADE','DIAMOND','PIC1','PIC1','PIC2','DIAMOND','BONUS','DIAMOND','PIC1','PIC2','SCAT','SCAT','PIC1','CLUB','PIC4','HEART','CLUB','PIC1'],
        ['PIC3','PIC3','PIC4','CLUB','BONUS','HEART','PIC4','PIC5','PIC1','HEART','WILD','DIAMOND','DIAMOND','PIC5','BONUS','DIAMOND','HEART','HEART','SCAT','CLUB','CLUB','DIAMOND','SCAT','SCAT','SCAT','PIC4','CLUB','HEART','PIC1','BONUS','PIC4','SPADE','DIAMOND','SPADE','PIC2','CLUB','SPADE','PIC3','WILD','PIC4','HEART','CLUB','SCAT','PIC2','SPADE','PIC3','PIC3','WILD','CLUB','SPADE','SCAT','DIAMOND','PIC4','HEART','PIC3','SCAT','SCAT','SCAT','HEART','PIC4','SPADE','DIAMOND','SPADE','PIC2','PIC5','HEART','SPADE','PIC4'],
        ['PIC5','CLUB','PIC2','HEART','CLUB','CLUB','PIC3','PIC2','PIC2','PIC1','DIAMOND','SCAT','PIC5','PIC5','PIC3','WILD','DIAMOND','PIC4','PIC5','PIC5','PIC1','PIC4','DIAMOND','SCAT','SCAT','HEART','SPADE','PIC1','PIC5','PIC5','HEART','WILD','PIC2','HEART','DIAMOND','SCAT','HEART','PIC1','PIC5','BONUS','SPADE','SPADE','HEART','SCAT','CLUB','HEART','PIC2','CLUB','DIAMOND','PIC1','HEART','SPADE','PIC2','DIAMOND','PIC1','HEART','BONUS','PIC5','PIC4','PIC4','WILD','PIC3','CLUB','PIC5','DIAMOND','WILD','WILD','CLUB','CLUB','PIC3','PIC2','PIC2','PIC1','DIAMOND','SCAT','PIC5','PIC5','PIC3','HEART','DIAMOND','PIC4','PIC5','PIC5','PIC1','PIC4','DIAMOND','SCAT','SCAT','HEART','SPADE','PIC1','PIC5','PIC5','HEART','PIC2','PIC2','HEART','DIAMOND','SCAT','HEART','PIC1','PIC5','BONUS','SPADE','SPADE','HEART','SCAT','CLUB','HEART','PIC2','PIC1','PIC1','PIC1','HEART','SPADE','PIC2','DIAMOND','HEART','PIC1','PIC5','BONUS','PIC4','PIC4','DIAMOND','PIC3','CLUB'],
        ['PIC2','HEART','BONUS','SPADE','CLUB','PIC3','DIAMOND','PIC2','HEART','PIC3','PIC3','PIC5','SPADE','PIC4','CLUB','SPADE','HEART','PIC5','PIC4','PIC3','WILD','CLUB','PIC4','PIC1','PIC4','SPADE','SCAT','HEART','PIC3','PIC3','CLUB','WILD','HEART','PIC2','PIC2','PIC2','CLUB','PIC3','CLUB','SPADE','HEART','PIC1','SPADE','SCAT','PIC5','SPADE','PIC1','PIC1','DIAMOND','PIC5','SCAT','SPADE','HEART','PIC3','SPADE','PIC2','BONUS','SPADE','CLUB','HEART','PIC3','PIC2','HEART','PIC3','PIC3','PIC5','SPADE','WILD','WILD','WILD','HEART','PIC5','PIC4','PIC3','CLUB','CLUB','PIC4','PIC1','PIC4','SPADE','SCAT','HEART','PIC3','PIC3','CLUB','SPADE','HEART','DIAMOND','PIC4','PIC2','CLUB','BONUS','CLUB','SPADE','HEART','PIC1','PIC2','SCAT','PIC5','SPADE','PIC1','PIC1','DIAMOND','PIC5','SCAT','SPADE','HEART','PIC3']
    ],

  reelsLayout: [3, 3, 3, 3, 3],

  playLines: [],

  coinAmountMultiplier: 20,
  validCoinAmounts: {
    SC: [1, 2, 5, 10, 25, 50, 100, 250],
    GC: [10, 20, 50, 100, 500, 1000, 2500],
  },

  winTable: [
    {
      symbol: "PIC1",
      multipliers: [200, 60, 25],
    },
    {
      symbol: "PIC2",
      multipliers: [100, 50, 20],
    },
    {
      symbol: "PIC3",
      multipliers: [80, 40, 10],
    },
    {
      symbol: "PIC4",
      multipliers: [60, 30, 10],
    },
    {
      symbol: "PIC5",
      multipliers: [40, 20, 10],
    },
    {
      symbol: "HEART",
      multipliers: [20, 10, 5],
    },
    {
      symbol: "CLUB",
      multipliers: [20, 10, 5],
    },
    {
      symbol: "DIAMOND",
      multipliers: [20, 10, 5],
    },
    {
      symbol: "SPADE",
      multipliers: [20, 10, 5],
    },
  ],

  scatterWinTable: [
    {
      symbol: "SCAT",
      multipliers: [0, 0, 1, 5, 15, 40, 100, 500, 2000], //ordered by 1 of a kind, 2 of a kind, ..., 9 of a kind,
    },
  ],
  bonusWinTable: [
    {
      symbol: "BONUS",
      multipliers: [0, 0, 2, 10, 100], //ordered by 1 of a kind, 2 of a kind, ..., 5 of a kind
    },
  ],
  wildSymbol: "WILD",
  scatterSymbol: "SCAT",
  bonusSymbol: "BONUS",

  freeSpinTriggerCount: [0, 0, 10, 20, 30], //ordered by number of free spins starting from 1 ... n
  freeSpinRetriggerCount: [0, 5, 10, 20, 30], //ordered by number of free spins starting from 1 ... n
  winLevels: [
    { name: "normal1", threshold: 2 },
    { name: "normal2", threshold: 5 },
    { name: "normal3", threshold: 20 },
    { name: "bigwin", threshold: 35 },
    { name: "megawin", threshold: 70 },
    { name: "epicwin" },
  ],
  superStarWeights: { chance: 1, outOf: 13 },
  superStarMultiplierWeights: [
    { outcome: 2, weight: 133 },
    { outcome: 3, weight: 62 },
    { outcome: 4, weight: 40 },
    { outcome: 5, weight: 20 },
    { outcome: 10, weight: 5 },
    { outcome: 20, weight: 1 },
  ],
  superStarProgressiveJackpotWeights: {
    SC: {
      20: { chance: 1717, outOf: 500000 },
      40: { chance: 3434, outOf: 500000 },
      100: { chance: 8585, outOf: 500000 },
      200: { chance: 17170, outOf: 500000 },
      500: { chance: 42925, outOf: 500000 },
      1000: { chance: 85850, outOf: 500000 },
      2000: { chance: 171700, outOf: 500000 },
      5000: { chance: 429250, outOf: 500000 },
    },
    GC: {
      200: { chance: 1717, outOf: 500000 },
      400: { chance: 3434, outOf: 500000 },
      1000: { chance: 8585, outOf: 500000 },
      2000: { chance: 17170, outOf: 500000 },
      5000: { chance: 42925, outOf: 500000 },
      10000: { chance: 85850, outOf: 500000 },
      20000: { chance: 171700, outOf: 500000 },
      50000: { chance: 429250, outOf: 500000 },
    },
  } as const,
  scatterSymbolsToTriggerProgressiveJackpots: 9,
};
