import Vue, { PropType } from "vue";
import { FeatureColour } from "../../model/src/operations/shared";
export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    originalName: String,
    spinType: String as PropType<"FREE_SPINS" | "BASE">,
    isWinCell: Boolean,
    colour: String as PropType<FeatureColour>,
    toggleColour: Function,
  },
  computed: {
    isBonus(): boolean {
      return this.symbolName === "BONUS";
    },
    isGreen(): boolean {
      return this.colour === "G";
    },
    isPurple(): boolean {
      return this.colour === "P";
    },
    isRed(): boolean {
      return this.colour === "R";
    },
  },
});
