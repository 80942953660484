/* in order to support standalone, please insert the init response from the server here */
export default {
    messages: [],
    modelDefinition: {
        modelId: "tf130",
        coinType1Values: [250, 1000, 2500, 5000, 10000, 15000, 25000],
        coinType4Values: [1, 2, 5, 10, 20, 25, 50, 100],
        reelsLayout: [3, 3, 3, 3, 3],
        coinSizeMultiplier: 20,
        wildSymbol: "WD",
        scatterSymbol: "SC",
        // prettier-ignore
        reels: [
            ['WD', 'PC', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'TRI', 'PB', 'PC', 'PD', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PB', 'PC', 'PD', 'PB', 'PC', 'PD', 'PB', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB', 'PA', 'PB', 'PD', 'PB', 'PC', 'PC', 'PD'],
            ['PC', 'PD', 'WD', 'PA', 'PB', 'SC', 'PA', 'PB', 'TRI', 'PC', 'PD', 'SVN', 'PA', 'PB', 'BO', 'PA', 'PB', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SVN', 'PA', 'PD', 'TRI', 'PA', 'PB', 'BO', 'BO', 'PC', 'PD', 'SC', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'SVN', 'PB', 'PC', 'PD', 'BO', 'PA', 'PB', 'SC', 'PC', 'PA', 'SC', 'PC', 'PD'], 
            ['WD', 'PD', 'PB', 'SVN', 'PA', 'PC', 'TRI', 'PB', 'PD', 'SVN', 'PC', 'PD', 'DOU', 'PA', 'PB', 'SC', 'PD', 'PA', 'BO', 'PC', 'PD', 'TRI', 'PA', 'PB', 'PD', 'BO', 'BO', 'BO', 'PC', 'PD', 'SC', 'PC', 'PB', 'PD', 'SC', 'PB', 'PC', 'TRI', 'PA', 'PB', 'TRI', 'PA', 'PB', 'BO', 'PA', 'PD', 'PC', 'DOU', 'PA', 'SC', 'PD', 'PC', 'BO', 'BO', 'BO'], 
            ['PC', 'TRI', 'BO', 'PD', 'PC', 'TRI', 'PD', 'BO', 'PA', 'PB', 'BO', 'TRI', 'PA', 'PB', 'SC', 'PC', 'PD', 'TRI', 'SC', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'SC', 'PC', 'PD', 'TRI', 'PB', 'PC', 'DOU', 'PD', 'BO', 'BO', 'PB', 'PC', 'PD', 'BO', 'PB', 'PC', 'TRI', 'PD', 'BO', 'PC', 'TRI', 'PD', 'TRI', 'WD', 'PA', 'TRI', 'PB', 'TRI', 'PA', 'TRI', 'PB', 'DOU', 'PC', 'SC', 'PD', 'DOU', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PB'], 
            ['WD', 'PA', 'PB', 'SVN', 'PC', 'PD', 'SVN', 'PA', 'PB', 'TRI', 'PC', 'PD', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PC', 'PD', 'DOU', 'PA', 'PB', 'BO', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'BO', 'SVN', 'PC', 'PD'], 
        ],
        // prettier-ignore
        reelsFreespin: [
            ['WD', 'PA', 'PC', 'PD', 'SVN', 'PB', 'PC', 'PD', 'DOU', 'PA', 'PB', 'PA', 'PB', 'PB', 'PC', 'TRI', 'PD', 'PB', 'DOU', 'PA', 'PD', 'BO', 'PC', 'PD', 'DOU', 'PC', 'PA'], 
            ['PC', 'PD', 'WD', 'PA', 'PB', 'PC', 'SC', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'BO', 'PC', 'PD', 'SVN', 'PC', 'PA', 'PB', 'SC', 'PC', 'PD', 'DOU', 'PC', 'PD', 'SC', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PC', 'PD', 'PC', 'DOU', 'PD', 'PC', 'PD', 'SVN', 'PC', 'PA', 'PB', 'PA', 'PB', 'BO', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PA', 'PB'], 
            ['WD', 'PA', 'PB', 'TRI', 'PA', 'PB', 'SVN', 'PC', 'PD', 'BO', 'PA', 'PB', 'DOU', 'PC', 'PB', 'SVN', 'SC', 'PD', 'TRI', 'PB', 'SC', 'DOU', 'PB', 'PB', 'BO', 'BO', 'BO', 'PA', 'PB', 'PD'], 
            ['PC', 'PD', 'WD', 'PC', 'PD', 'SC', 'PA', 'PB', 'DOU', 'PC', 'PD', 'PB', 'BO', 'PC', 'PD', 'SVN', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PB', 'BO', 'BO', 'PB', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'TRI', 'PC', 'PD', 'PA', 'PB', 'PC', 'PD', 'DOU', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PC', 'PD', 'PC', 'SVN', 'PD', 'PC', 'PD', 'PC', 'PD'], 
            ['WD', 'PA', 'PC', 'SVN', 'PB', 'PC', 'PD', 'PB', 'TRI', 'PA', 'PB', 'TRI', 'PC', 'PD', 'BO', 'PA', 'PB', 'SVN', 'PC', 'PD', 'DOU', 'PB', 'PD', 'TRI', 'PA', 'PB', 'PC', 'PD', 'DOU', 'PA', 'PB', 'DOU', 'PC', 'PD', 'SVN', 'PC', 'PD'], 
        ],
    },
};
