import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {trace} from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";

@Component({
    components: {
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isOpen = false;
    public wheelSpinValue = 0;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }

        this.isOpen = false;
        this.show = true;

        if (this.wheelSpinValue != this.step.json.wheelSpinValue) {
            this.wheelSpinValue = Number(this.step.json.wheelSpinValue);
            bus.$emit(events.UPDATE_STEPS_DB, this.step);
        }
    }

    public mounted() {
        this.isOpen = false;
        trace("reels mounted");
    }

    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep());
    }

    public updateWheelSpinValue(wheelSpinValue) {
        if (wheelSpinValue == "" || isNaN(wheelSpinValue)) {
            wheelSpinValue = 0;
        }

        this.step.json.wheelSpinValue = Number(wheelSpinValue);

        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }
}
