import Vue, { PropType } from "vue";

export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    spinType: String as PropType<"FREE_SPINS" | "BASE">,
    isWinCell: Boolean,
  },
  computed: {
    isScatter: function (): boolean {
      return this.symbolName === "SCAT";
    },
    isCase: function (): boolean {
      return this.symbolName === "CASE";
    },
  },
});