import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { trace } from "./../../../../helpers/helpers";
import State from "../../../../state/state";
import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Reel from "./reels/reel.vue";

@Component({
    components: {
        reel: Reel,
    },
    data: () => {
        return {};
    },
})
export default class Content extends Vue {
    @Prop() public step: any;
    public show = false;
    public isWildMermaid = "false";
    public mysterySymbol = "HV1";
    public showWildMermaid = true;

    constructor() {
        super();
    }

    @Watch("step")
    public stepWatch(step) {
        if (!step) {
            this.show = false;
            return;
        }
        this.show = true;
        this.setWildMermaid(step.json.wildMermaidsFeature);
        this.mysterySymbol = step.json.mysterySymbol;

        this.showWildMermaid = !Boolean(step.json.isFeature);

        bus.$emit("update-feature");
    }

    public mounted() {
        trace("reels mounted");
    }

    /**
     * Adds a new base game step.
     */
    public addBaseStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getBaseStep(this.isWildMermaid == "true", this.mysterySymbol));
    }

    /**
     * Adds a new free game step.
     */
    public addFrequentWildStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getFrequentWildFGStep(this.isWildMermaid == "true", this.mysterySymbol));
    }

    /**
     * Adds a new Golden Mermaid step.
     */
    public addGoldenMermaidFGStep() {
        const state = State.state;
        bus.$emit(events.ADD_STEP, state.getGoldenMermaidFGStep(false, this.mysterySymbol));
    }

    /**
     * Called whenever user updates the data in select box.
     */
    public onChangeData() {
        this.step.json.wildMermaidsFeature = (this.isWildMermaid == "true");
        this.step.json.mysterySymbol = this.mysterySymbol;
        bus.$emit(events.UPDATE_STEPS_DB, this.step);
    }

    /**
     * Updates the value of isWildMermaid from boolean
     * @param val Boolean value of isWildMermaid 
     */
    public setWildMermaid(val: boolean) {
        this.isWildMermaid = String(val);
    }
}
