import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Reel from "./../reels/reel.vue";
import { trace } from "./../../../../../helpers/helpers";
import State from "../../../../../state/state";
import { SymbolInfo } from "src/state/models/slots/vgw060/state";

@Component({
    computed: {
        isActive: function() {
            return [null, "  "].every((match) => this.$props.symPicksReel[this.$props.index] != match);
        },
        isCoin: function() {
            return this.$props.symPicksReel[this.$props.index] === "CO";
        },
        isPic: function() {
            return this.$props.symPicksReel[this.$props.index][0] === "P";
        },
        isSuit: function() {
            return ["SE","HT","DD","CB"].some((sym) => this.$props.symPicksReel[this.$props.index] === sym);
        },
        isScat: function() {
            return this.$props.symPicksReel[this.$props.index] === "SC";
        },
        isWild: function() {
            return this.$props.symPicksReel[this.$props.index] === "WD";
        },
        isInvalid: function() {
            return this.$props.symPicksReel[this.$props.index] === "REPLACE";
        },
    }
})
export default class SymbolPickable extends Vue {
    @Prop() public index;
    @Prop() public symPicksReel;
    @Prop() public value;
    @Prop() public symbolInfo;
    @Prop() public isJackpotLocked;
    @Prop() public isPreviousCoin;
    @Prop() public isCascade;
    @Prop() public isLocked;

    private fullSymbols : string[] = ["  ","SC", "WD", "P1", "P2", "P3", "P4", "P5", "P6", "CO", "SE", "HT", "DD", "CB"];
    
    get symbols() : string[] {
        return this.isCascade ? this.fullSymbols.slice(1, this.fullSymbols.length) : this.fullSymbols;
    }

    public isJackpotCoin() : boolean {
        return this.symbolInfo != null && this.symbolInfo[this.index] != null;
    }

    constructor() {
        super();
    }

    updateValue() {
        if (this.isTechnicalCoin()) {
            if (this.symbolInfo[this.index] == null) {
                this.symbolInfo[this.index] = State.state.getDefaultSymbolInfo();
            }
    
            switch (parseInt(this.symbolInfo[this.index].price.toString())) {
                case 0: 
                    this.symbolInfo[this.index].jackpotType = "MAJOR";
                    break;
                case 200: 
                    this.symbolInfo[this.index].jackpotType = "MINOR";
                    break;
                case 40: 
                    this.symbolInfo[this.index].jackpotType = "MINI";
                    break;
                default:
                    break;
            }

            if (this.isJackpotLocked) {
                this.symbolInfo[this.index].enabled = true;
            }
        } else {
            if (!this.isJackpotLocked) {
                if (this.symbolInfo != null) {
                    this.symbolInfo[this.index] = null;
                }
            }
        }
        
        (<Reel>this.$parent).updateDB();
    }

    private isTechnicalCoin() : boolean {
        return this.symPicksReel[this.index] === "CO" //base reel set coin
        || this.isJackpotLocked && this.isJackpotCoin() //jackpot game step coin
        ;
    }

    setToCoin() {
        if (!this.isJackpotLocked) {
            this.symPicksReel[this.index] = "CO";
        } else {
            if (this.symbolInfo[this.index] == null) {
                this.symbolInfo[this.index] = State.state.getDefaultSymbolInfo();
            }
        }

        this.updateValue();
    }

    clearSymbol() {
        if (!this.isJackpotLocked) {
            this.symPicksReel[this.index] = "  ";
        } else {
            this.symbolInfo[this.index] = null;
        }

        this.updateValue();
    }

    getSymbolInfoDisplay() : string {
        const si : SymbolInfo = this.symbolInfo[this.index];
        
        if (si != null) {
            switch (parseInt(si.price.toString())) {
                case 0: 
                    return "MAJOR";
                    break;
                case 200: 
                    return "MINOR";
                    break;
                case 40: 
                    return "MINI";
                    break;
                default:
                    return si.price.toString();
                    break;
            }
        }

        return "";
    }
}