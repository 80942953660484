import Vue, { PropType } from "vue";

export default Vue.component("Card", {
  props: {
    details: Object as PropType<{
      reel: number;
      reelPosition: number;
      candyCaneType: "TOP_LEFT" | "TOP_RIGHT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT";
    }>,
  },
});
