/* in order to support standalone, please insert the init response from the server here */
export default {
    "success" : true,
    "messages" : [ ],
    "modelDefinition" : {
        "modelId": "tf001_spellofodin",

        "wildSymbol": "Odin",
        "scatterSymbol": "BONUS",
        "freeSpinTrigger": "BONUS",

        "countToTriggerFreeSpin": 3,
        "freeSpinCount": 12,

        "winTable": [
            {
                "symbol": "Bsiren",
                "multipliers": [800, 80, 30, 5]
            }, {
                "symbol": "Siren2",
                "multipliers": [250, 60, 25, 2]
            }, {
                "symbol": "Rsiren",
                "multipliers": [200, 50, 25, 2]
            }, {
                "symbol": "Boat",
                "multipliers": [125, 40, 25]
            }, {
                "symbol": "Ring",
                "multipliers": [100, 35, 15]
            }, {
                "symbol": "Horse",
                "multipliers": [100, 35, 15]
            }, {
                "symbol": "Ace",
                "multipliers": [80, 30, 10]
            }, {
                "symbol": "King",
                "multipliers": [80, 30, 10]
            }, {
                "symbol": "Queen",
                "multipliers": [50, 25, 5]
            }, {
                "symbol": "Jack",
                "multipliers": [50, 25, 5]
            }, {
                "symbol": "ten",
                "multipliers": [50, 25, 5]
            }
        ],

        "coinType1Values": [250, 2500, 10000, 50000, 100000],
        "coinType4Values": [1, 2, 5, 10, 50],

        "coinType1_min_bet": "250",
        "coinType1_max_bet": "100000",
        "coinType1_spin_max_bet": "2000000",

        "coinType4_min_bet": "1",
        "coinType4_max_bet": "50",
        "coinType4_spin_max_bet": "1000",

        "reelsLayout": [3, 3, 3, 3, 3],

        "reels": [
            ["Bsiren", "Bsiren", "Queen", "ten", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "Boat", "Queen", "Ring", "Rsiren", "ten", "Horse", "King", "Rsiren", "Ring", "Ace", "Boat", "Jack", "Bsiren", "Bsiren", "King", "Horse", "Ring", "Ace", "Bsiren", "Bsiren", "Jack", "Boat", "Queen", "Rsiren", "Horse", "Ring", "King", "Rsiren", "ten", "Boat", "Ace", "Horse", "Queen", "Siren2", "Siren2", "Jack"],
            ["Bsiren", "Bsiren", "ten", "Horse", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "BONUS", "King", "Queen", "Ring", "ten", "Horse", "Siren2", "Siren2", "Ace", "Ring", "King", "Boat", "Jack", "Bsiren", "Bsiren", "Ace", "BONUS", "Queen", "Horse", "Rsiren", "King", "Ring", "Ace", "Siren2", "Siren2", "Jack", "Boat", "Rsiren", "Queen", "Horse", "Ring", "King", "Rsiren", "ten", "BONUS", "Ace", "Boat", "Jack", "Rsiren", "Queen"],
            ["Jack", "Bsiren", "Bsiren", "Queen", "Boat", "King", "Ring", "Ace", "Rsiren", "Jack", "Boat", "Queen", "BONUS", "Horse", "Rsiren", "King", "Odin", "Odin", "Odin", "Queen", "Ace", "Ring", "ten", "Jack", "Ring", "Siren2", "Siren2", "Horse", "King", "Boat", "Ace", "BONUS", "Queen", "Horse", "King", "Odin", "Odin", "Odin", "Jack", "Rsiren", "ten", "Ace", "Horse", "Ring", "King", "Rsiren", "Ace", "BONUS"],
            ["Ring", "Horse", "ten", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "BONUS", "Ring", "Queen", "Bsiren", "Bsiren", "Boat", "Jack", "Rsiren", "Queen", "BONUS", "Horse", "Rsiren", "King", "Ring", "Siren2", "Siren2", "Ace", "Boat", "Horse", "Rsiren", "Ring", "BONUS", "ten", "Rsiren", "Boat", "Ace"],
            ["Bsiren", "Bsiren", "Queen", "Horse", "ten", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ring", "Rsiren", "Ace", "Boat", "Queen", "Ring", "ten", "Horse", "Bsiren", "Bsiren", "Ace", "Ring", "Jack", "Boat", "Queen", "Horse", "Rsiren", "King", "Ring", "Boat", "Ace", "Rsiren", "Jack", "Horse", "Boat", "Siren2", "Siren2", "Ring", "Ace", "Rsiren", "ten", "Horse", "King", "Rsiren", "Boat", "Ace"]
        ],

        "reelsCaseTwo": [
            ["Bsiren", "Bsiren", "ten", "Queen", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "Boat", "Queen", "Ring", "Rsiren", "ten", "Horse", "King", "Rsiren", "Ring", "Ace", "Boat", "Jack", "Bsiren", "Bsiren", "King", "Horse", "Ring", "Ace", "Bsiren", "Bsiren", "Jack", "Boat", "Queen", "Rsiren", "Horse", "Ring", "King", "Rsiren", "ten", "Boat", "Ace", "Horse", "Queen", "Siren2", "Siren2", "Jack"],
            ["Bsiren", "Bsiren", "ten", "Horse", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "BONUS", "King", "Queen", "Ring", "ten", "Horse", "Siren2", "Siren2", "Ace", "Ring", "King", "Boat", "Jack", "Bsiren", "Bsiren", "Ace", "BONUS", "Queen", "Horse", "Rsiren", "King", "Ring", "Ace", "Siren2", "Siren2", "Jack", "Boat", "Rsiren", "Queen", "Horse", "Ring", "King", "Rsiren", "ten", "BONUS", "Ace", "Boat", "Queen", "Rsiren", "Jack"],
            ["Bsiren", "Bsiren", "Rsiren", "BONUS", "Odin", "Odin", "Odin", "Rsiren", "Siren2", "Siren2", "BONUS", "Rsiren", "Odin", "Odin", "Odin", "Rsiren", "BONUS"],
            ["Ring", "Horse", "ten", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ace", "BONUS", "Ring", "Jack", "Bsiren", "Bsiren", "ten", "Boat", "Rsiren", "Queen", "BONUS", "Horse", "Rsiren", "King", "Ring", "Siren2", "Siren2", "Ace", "Boat", "Horse", "Rsiren", "Ring", "BONUS", "Queen", "Rsiren", "Boat", "Ace"],
            ["Bsiren", "Bsiren", "ten", "Horse", "Queen", "Boat", "King", "Ring", "Siren2", "Siren2", "Jack", "Horse", "Ring", "Rsiren", "Ace", "Boat", "Queen", "Ring", "Horse", "Jack", "Bsiren", "Bsiren", "ten", "Ring", "Ace", "Boat", "Queen", "Horse", "Rsiren", "Ring", "Ace", "Boat", "King", "Rsiren", "Jack", "Horse", "Boat", "Siren2", "Siren2", "Ring", "Ace", "Rsiren", "ten", "Horse", "King", "Rsiren", "Boat", "Ace"]
        ],

        "fullStackOdinReelsWeights": {
            "baseGame": [
                {
                    "weight": 28,
                    "reel": 0
                },
                {
                    "weight": 10,
                    "reel": 1
                },
                {
                    "weight": 0,
                    "reel": 2
                },
                {
                    "weight": 30,
                    "reel": 3
                },
                {
                    "weight": 32,
                    "reel": 4
                }
            ],
            "optionOne": [
                {
                    "weight": 27,
                    "reel": 0
                },
                {
                    "weight": 11,
                    "reel": 1
                },
                {
                    "weight": 0,
                    "reel": 2
                },
                {
                    "weight": 32,
                    "reel": 3
                },
                {
                    "weight": 30,
                    "reel": 4
                }
            ],
            "optionTwo": [
                {
                    "weight": 30,
                    "reel": 0
                },
                {
                    "weight": 13,
                    "reel": 1
                },
                {
                    "weight": 0,
                    "reel": 2
                },
                {
                    "weight": 28,
                    "reel": 3
                },
                {
                    "weight": 29,
                    "reel": 4
                }
            ],
            "optionThree": [
                {
                    "weight": 31,
                    "reel": 0
                },
                {
                    "weight": 19,
                    "reel": 1
                },
                {
                    "weight": 0,
                    "reel": 2
                },
                {
                    "weight": 30,
                    "reel": 3
                },
                {
                    "weight": 20,
                    "reel": 4
                }
            ]
        },

        "playLines": [
            [1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0],
            [2, 2, 2, 2, 2],
            [0, 1, 2, 1, 0],
            [2, 1, 0, 1, 2],

            [0, 1, 0, 1, 0],
            [2, 1, 2, 1, 2],
            [0, 0, 1, 2, 2],
            [2, 2, 1, 0, 0],
            [0, 1, 1, 1, 0],

            [2, 1, 1, 1, 2],
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],
            [1, 0, 1, 0, 1],
            [1, 2, 1, 2, 1],

            [1, 0, 0, 0, 1],
            [1, 2, 2, 2, 1],
            [1, 0, 2, 1, 0],
            [1, 2, 0, 1, 2],
            [0, 2, 2, 2, 0],

            [2, 0, 0, 0, 2],
            [0, 2, 1, 2, 0],
            [2, 0, 1, 0, 2],
            [0, 2, 0, 2, 0],
            [2, 0, 2, 0, 2]
        ]
    },
}
