import State from '../../../state';
import {modelDefinition} from './model-definition';
import {createClearingStep} from './steps';

export function setStateParams() {
    return {
        variations: [],

        // Must implement
        createDefaultStep: () => createClearingStep(),
        getLayout: () => State.state.getModelDefinition().reelsLayout,

        // // unique per model
        getModelDefinition: () => modelDefinition,
        getNumberOfReels: () => modelDefinition.reelsLayout.length,
        getNumberOfRows: () => modelDefinition.reelsLayout[0],
        getReelStrips: () => modelDefinition.reels,

        // example on creating custom indexes
        getBaseStep: () => State.state.createStep([0, 0, 0, 0, 0, 0]),
        getClearingStep: (order = 0) => State.state.createStep([0, 0, 0, 0, 0, 0], false, order, '', 'Clear'),
    };
}
