/* in order to support standalone, please insert the init response from the server here */
export default {
    "success": true,
    "messages": [],
    "modelDefinition": {
        "modelId" : "pf003_monsoon",

        "scatterSymbol": null,
        "freeSpinTrigger" : "FS1",
        "scatterSymbols": ["FS1", "HA1", "HA2", "HA3"],

        "wildSymbol": "WI1",
        "wildSymbols": ["WI1", "WI2", "WI3", "WI4", "WI5"],

        "countToTriggerFreeSpin" : 3,
        "countToTriggerBonus" : 3,

        "winTable": [
            {
                "symbol" : "LV1",
                "multipliers" : [50, 10, 5]
            },
            {
                "symbol" : "LV2",
                "multipliers" : [50, 10, 5]
            },
            {
                "symbol" : "LV3",
                "multipliers" : [50, 10, 5]
            },
            {
                "symbol" : "LV4",
                "multipliers" : [60, 12, 6]
            },
            {
                "symbol" : "LV5",
                "multipliers" : [60, 12, 6]
            },
            {
                "symbol" : "HV1",
                "multipliers" : [100, 15, 8]
            },
            {
                "symbol" : "HV2",
                "multipliers" : [150, 20, 10]
            },
            {
                "symbol" : "HV3",
                "multipliers" : [200, 25, 12]
            },
            {
                "symbol" : "HV4",
                "multipliers" : [500, 40, 15]
            },
            {
                "symbol" : "HV5",
                "multipliers" : [100, 15, 8]
            },
            {
                "symbol" : "HV6",
                "multipliers" : [150, 20, 10]
            },
            {
                "symbol" : "HV7",
                "multipliers" : [200, 25, 12]
            },
            {
                "symbol" : "HV8",
                "multipliers" : [500, 40, 15]
            },
            {
                "symbol" : "HA1",
                "multipliers" : [125, 100, 75, 50, 25]
            },
            {
                "symbol" : "HA2",
                "multipliers" : [250, 200, 150, 100, 50]
            },
            {
                "symbol" : "HA3",
                "multipliers" : [375, 300, 225, 150, 75]
            },
            {
                "symbol" : "FS1",
                "multipliers" : [2500, 500, 100]
            }
        ],

        "reelsLayout": [4, 4, 4, 4, 4],
        "reels" : [
            ["LV2", "LV2", "HV1", "LV5", "LV5", "LV5", "HV2", "LV3", "LV3", "HV1", "LV4", "HV2", "HV2", "HV2", "HV2", "LV4", "LV1", "LV1", "HV4", "HV4", "HV4", "HV4", "HV1", "LV4", "FS1", "LV1", "LV1", "LV1", "LV1", "HV3", "LV3", "LV1", "LV2", "LV2", "LV2", "LV2", "LV5", "LV5", "LV4", "LV2", "HV3", "HV3", "HV3", "HV3", "LV3", "LV2", "HV1", "HV1", "HV1", "HV1", "LV5", "LV2", "HV2", "LV1", "LV4", "LV4", "LV4", "LV4", "HV2", "LV3", "LV3", "LV3", "LV3", "LV5", "LV5", "HV2", "LV4"],
            ["HV2", "LV1", "LV1", "LV1", "LV1", "HV2", "LV5", "HV1", "LV3", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV1", "HV4", "HV4", "HV4", "HV4", "LV4", "HV1", "LV1", "WI1", "LV2", "LV2", "LV5", "LV5", "LV5", "LV5", "LV3", "LV1", "HV3", "HV3", "HV3", "HV3", "LV3", "FS1", "LV3", "LV4", "LV5", "HV2", "HV2", "HV2", "HV2", "LV5", "HV1", "LV5", "LV3", "HV1", "LV1", "LV1", "WI1", "LV4", "LV2", "HV1", "LV4", "LV4", "LV4", "LV4", "LV5", "HV2", "LV1", "LV3", "LV4", "LV3", "LV4", "HV1", "HV1", "HV1", "HV1", "LV5", "HV3", "LV2", "LV2", "LV2", "LV2", "LV4"],
            ["HV3", "LV4", "HV2", "LV2", "LV2", "LV3", "HV1", "LV4", "LV5", "HV1", "HV3", "LV1", "FS1", "LV3", "LV3", "LV3", "LV3", "LV5", "LV5", "LV5", "LV5", "HV2", "LV4", "HV4", "HV4", "HV4", "HV4", "LV2", "LV5", "HV2", "HV2", "HV2", "HV2", "LV3", "HV1", "HV1", "HV1", "HV1", "LV4", "WI1", "LV3", "LV5", "LV1", "LV1", "LV1", "LV1", "LV5", "HV1", "HV2", "LV2", "LV2", "LV2", "LV2", "LV1", "LV1", "LV3", "LV2", "FS1", "LV1", "LV5", "LV2", "HV3", "HV3", "HV3", "HV3", "LV4", "LV4", "LV4", "LV4", "LV3", "WI1", "LV1"],
            ["LV5", "LV1", "LV1", "LV2", "HV2", "LV3", "WI1", "LV4", "LV5", "LV5", "HV1", "LV1", "LV1", "LV2", "LV2", "LV5", "LV1", "LV2", "HV1", "FS1", "LV4", "LV4", "LV4", "LV4", "LV2", "HV1", "LV4", "LV5", "LV5", "LV5", "LV5", "LV4", "HV2", "HV3", "LV3", "FS1", "LV3", "LV3", "LV4", "HV1", "LV1", "HV3", "HV3", "HV3", "HV3", "LV1", "LV2", "HV1", "HV1", "HV1", "HV1", "LV3", "HV2", "HV2", "LV3", "FS1", "LV5", "HV2", "HV2", "HV2", "HV2", "LV4", "HV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "LV1", "LV2", "HV3", "LV3", "LV3", "LV3", "LV3", "LV2", "LV2", "LV2", "LV2", "LV4"],
            ["HV2", "LV4", "LV4", "LV2", "HV1", "LV3", "LV1", "HV3", "HV3", "HV3", "HV3", "LV4", "FS1", "LV1", "HV2", "HV2", "LV2", "LV1", "LV1", "LV1", "LV1", "HV1", "HV1", "HV1", "HV1", "LV3", "FS1", "LV1", "HV4", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "HV3", "LV5", "LV2", "HV2", "HV2", "HV2", "HV2", "HV1", "LV3", "LV5", "LV5", "LV5", "LV5", "FS1", "LV1", "LV4", "LV4", "LV4", "LV4", "LV5", "LV2", "LV2", "LV2", "LV2", "HV2", "LV5", "LV5", "LV1", "HV1", "LV4", "HV1", "LV3", "LV2", "LV2", "FS1", "LV5", "LV5", "LV3"]
        ],
        "extraReels": {
            "REEL_SET_2": {
                "layout": [4, 4, 4, 4, 4],
                "reels": [
                    ["LV2", "LV2", "HV1", "LV5", "LV5", "LV5", "HV2", "LV3", "LV3", "HV1", "LV4", "LV1", "HV2", "HV2", "HV2", "HV2", "LV4", "LV1", "LV1", "LV1", "HV4", "HV4", "HV4", "HV1", "LV4", "LV3", "LV1", "LV1", "LV1", "LV1", "HV3", "LV3", "LV1", "LV2", "LV2", "LV2", "LV2", "LV5", "LV5", "LV4", "LV2", "HV3", "HV3", "HV3", "HV3", "LV3", "LV2", "HV1", "HV1", "HV1", "HV1", "LV5", "LV2", "HV2", "LV1", "LV4", "LV4", "LV4", "LV4", "HV2", "LV3", "LV3", "LV3", "LV3", "LV5", "LV5", "HV2", "LV4"],
                    ["HV2", "LV1", "LV1", "LV1", "LV1", "HV2", "LV5", "HV1", "LV3", "LV3", "LV3", "LV3", "LV2", "LV2", "LV5", "LV1", "LV1", "HV4", "HV4", "HV4", "LV4", "HV1", "LV1", "LV2", "LV2", "LV2", "LV5", "LV5", "LV5", "LV5", "LV3", "LV1", "HV3", "HV3", "HV3", "HV3", "LV3", "LV1", "LV3", "LV4", "LV5", "HV2", "HV2", "HV2", "HV2", "LV5", "HV1", "LV5", "LV3", "HV1", "LV1", "LV1", "LV4", "LV4", "LV2", "HV1", "LV4", "LV4", "LV4", "LV4", "LV5", "HV2", "LV1", "LV3", "LV4", "LV3", "LV4", "HV1", "HV1", "HV1", "HV1", "LV5", "HV3", "LV2", "LV2", "LV2", "LV2", "LV4"],
                    ["HV3", "LV4", "HV2", "LV2", "LV2", "LV3", "HV1", "LV4", "LV5", "HV1", "HV3", "LV1", "LV1", "LV3", "LV3", "LV3", "LV3", "LV5", "LV5", "LV5", "LV5", "HV2", "LV4", "LV4", "HV4", "HV4", "HV4", "LV2", "LV2", "LV5", "HV2", "HV2", "HV2", "HV2", "LV3", "HV1", "HV1", "HV1", "HV1", "LV4", "LV5", "LV3", "LV5", "LV1", "LV1", "LV1", "LV1", "LV5", "HV1", "LV3", "HV2", "LV2", "LV2", "LV2", "LV2", "LV1", "LV1", "LV3", "LV2", "LV4", "LV1", "LV5", "LV2", "HV3", "HV3", "HV3", "HV3", "LV4", "LV4", "LV4", "LV4", "LV3", "LV1", "LV1"],
                    ["LV5", "LV1", "LV1", "LV2", "HV2", "LV3", "LV2", "LV4", "LV5", "LV5", "HV1", "LV1", "LV1", "LV2", "LV2", "LV5", "LV1", "LV2", "HV1", "LV3", "LV4", "LV4", "LV4", "LV4", "LV2", "HV1", "LV4", "LV5", "LV5", "LV5", "LV5", "LV4", "HV2", "HV3", "LV3", "HV2", "LV3", "LV3", "LV4", "HV1", "LV1", "HV3", "HV3", "HV3", "HV3", "LV1", "LV2", "HV1", "HV1", "HV1", "HV1", "LV3", "HV2", "HV2", "LV3", "LV5", "LV5", "HV2", "HV2", "HV2", "HV2", "LV4", "LV4", "HV4", "HV4", "HV4", "LV1", "LV1", "LV1", "LV1", "LV2", "HV3", "LV3", "LV3", "LV3", "LV3", "LV2", "LV2", "LV2", "LV2", "LV4"],
                    ["HV2", "LV4", "LV4", "LV2", "HV1", "LV3", "LV1", "HV3", "HV3", "HV3", "HV3", "LV4", "LV1", "LV1", "HV2", "HV2", "LV2", "LV1", "LV1", "LV1", "LV1", "HV1", "HV1", "HV1", "HV1", "LV3", "LV2", "LV1", "LV1", "HV4", "HV4", "HV4", "LV3", "LV3", "LV3", "LV3", "HV3", "LV5", "LV3", "LV2", "HV2", "HV2", "HV2", "HV2", "HV1", "LV3", "LV5", "LV5", "LV5", "LV5", "LV4", "LV1", "LV4", "LV4", "LV4", "LV4", "LV5", "LV2", "LV2", "LV2", "LV2", "LV1", "HV2", "LV5", "LV5", "LV1", "HV1", "LV4", "HV1", "LV3", "LV2", "LV2", "LV2", "LV5", "LV5", "LV3"]
                ]
            },
            "REEL_SET_3": {
                "layout": [4, 4, 4, 4, 4],
                "reels": [
                    ["LV4", "LV2", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV5", "HV8", "HV8", "HV8", "LV3", "LV3", "LV5", "HA1", "LV4", "LV2", "HV6", "HV6", "HV6", "HV6", "LV1", "LV4", "LV2", "LV1", "LV3", "HV7", "HV7", "HV7", "HV7", "LV3", "HV6", "LV3", "HV8", "LV2", "LV1", "LV5", "HV5", "LV3", "LV5", "LV1", "LV4", "LV2", "LV1", "LV4", "HV5", "HA2", "LV2", "LV1", "LV3", "LV4", "LV4", "LV3", "LV5", "LV4"],
                    ["LV3", "LV2", "LV1", "HV5", "LV4", "LV4", "LV2", "LV1", "HV6", "LV4", "LV5", "HA3", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV5", "HV8", "HV8", "HV8", "LV4", "LV4", "LV2", "LV2", "LV3", "LV5", "LV1", "LV3", "LV2", "LV1", "HV5", "HV5", "LV1", "HV6", "HV6", "HV6", "HV6", "LV3", "HV8", "LV5", "HA1", "LV4", "LV5", "HV5", "LV2", "LV1", "LV3", "HV7", "HV7", "HV7", "HV7", "LV4", "LV2", "LV2", "LV3", "LV5", "LV1", "LV4"],
                    ["LV3", "LV1", "LV5", "HV5", "HA1", "LV2", "HV8", "LV4", "LV4", "LV5", "LV5", "LV4", "HV6", "HV6", "HV6", "HV6", "LV3", "LV2", "HV5", "LV1", "LV1", "LV5", "LV1", "HV6", "LV3", "LV3", "LV4", "LV2", "HV8", "HV8", "HV8", "LV3", "LV3", "LV1", "LV5", "HA2", "LV2", "LV4", "LV1", "HV7", "HV7", "HV7", "HV7", "LV4", "LV4", "LV3", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV1", "LV2", "LV5", "LV1", "LV4", "LV3", "LV4", "LV5", "LV5", "LV2", "HV5"],
                    ["LV5", "HV8", "HV8", "HV8", "LV2", "LV2", "LV2", "LV4", "LV3", "HV7", "HV7", "HV7", "HV7", "LV2", "HV5", "LV3", "LV4", "HA3", "LV1", "LV3", "LV4", "LV5", "LV5", "LV3", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV5", "LV4", "HV6", "LV1", "LV4", "LV5", "LV2", "HV6", "HV6", "HV8", "LV3", "LV4", "LV1", "LV3", "LV4", "LV5", "HA1", "LV2", "HV5", "LV1", "LV5", "HV6", "HV6", "HV6", "HV6", "LV2", "LV1", "LV5", "LV1", "LV3"],
                    ["LV2", "LV5", "LV3", "LV4", "HA1", "LV2", "LV3", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV3", "HA2", "LV5", "HV8", "LV2", "HV6", "HV6", "HV6", "HV6", "LV3", "LV5", "LV1", "HV7", "HV7", "HV7", "HV7", "LV3", "HV5", "LV2", "LV2", "LV1", "LV4", "LV1", "LV4", "LV2", "LV2", "LV1", "LV5", "HV8", "HV8", "HV8", "LV4", "LV4", "LV5", "LV5", "LV1", "LV3", "LV4", "HV6", "LV1", "LV4", "HV5", "LV3"]
                ]
            },
            "REEL_SET_4": {
                "layout": [4, 4, 4, 4, 4],
                "reels": [
                    ["LV4", "LV2", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV5", "HV8", "HV8", "HV8", "LV3", "LV3", "LV5", "LV5", "LV4", "LV2", "HV6", "HV6", "HV6", "HV6", "LV1", "LV4", "LV2", "LV1", "LV3", "HV7", "HV7", "HV7", "HV7", "LV3", "HV6", "LV3", "HV8", "LV2", "LV1", "LV5", "HV5", "LV3", "LV5", "LV1", "LV4", "LV2", "LV1", "LV4", "HV5", "LV2", "LV2", "LV1", "LV3", "LV4", "LV4", "LV3", "LV5", "LV4"],
                    ["LV3", "LV2", "LV1", "HV5", "LV4", "LV4", "LV2", "LV1", "HV6", "LV4", "LV5", "LV4", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV3", "LV3", "LV5", "HV8", "HV8", "HV8", "LV4", "LV4", "LV2", "LV2", "LV3", "LV5", "LV1", "LV3", "LV2", "LV1", "HV5", "HV5", "LV1", "HV6", "HV6", "HV6", "HV6", "LV3", "HV8", "LV5", "LV3", "LV4", "LV5", "HV5", "LV2", "LV1", "LV3", "HV7", "HV7", "HV7", "HV7", "LV4", "LV2", "LV2", "LV3", "LV5", "LV1", "LV4"],
                    ["LV3", "LV1", "LV5", "HV5", "LV2", "LV2", "HV8", "LV4", "LV4", "LV5", "LV5", "LV4", "HV6", "HV6", "HV6", "HV6", "LV3", "LV2", "HV5", "LV1", "LV1", "LV5", "LV1", "HV6", "LV3", "LV3", "LV4", "LV2", "HV8", "HV8", "HV8", "LV3", "LV3", "LV1", "LV5", "LV5", "LV2", "LV4", "LV1", "HV7", "HV7", "HV7", "HV7", "LV4", "LV4", "LV3", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV1", "LV2", "LV5", "LV1", "LV4", "LV3", "LV4", "LV5", "LV5", "LV2", "HV5"],
                    ["LV5", "HV8", "HV8", "HV8", "LV2", "LV2", "LV2", "LV4", "LV3", "HV7", "HV7", "HV7", "HV7", "LV2", "HV5", "LV3", "LV4", "LV4", "LV1", "LV3", "LV4", "LV5", "LV5", "LV3", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV5", "LV4", "HV6", "LV1", "LV4", "LV5", "LV2", "HV6", "HV6", "HV8", "LV3", "LV4", "LV1", "LV3", "LV4", "LV5", "LV2", "LV2", "HV5", "LV1", "LV5", "HV6", "HV6", "HV6", "HV6", "LV2", "LV1", "LV5", "LV1", "LV3"],
                    ["LV2", "LV5", "LV3", "LV4", "LV4", "LV2", "LV3", "LV5", "LV2", "HV5", "HV5", "HV5", "HV5", "LV1", "LV5", "LV3", "LV3", "LV5", "HV8", "LV2", "HV6", "HV6", "HV6", "HV6", "LV3", "LV5", "LV1", "HV7", "HV7", "HV7", "HV7", "LV3", "HV5", "LV2", "LV2", "LV1", "LV4", "LV1", "LV4", "LV2", "LV2", "LV1", "LV5", "HV8", "HV8", "HV8", "LV4", "LV4", "LV5", "LV5", "LV1", "LV3", "LV4", "HV6", "LV1", "LV4", "HV5", "LV3"]
                ]
            }
        },

        "playLines" : [
            [0, 0, 0, 0, 0],
            [1, 1, 1, 1, 1],
            [2, 2, 2, 2, 2],
            [3, 3, 3, 3, 3],
            [0, 1, 2, 1, 0],
            [1, 2, 3, 2, 1],
            [2, 1, 0, 1, 2],
            [3, 2, 1, 2, 3],
            [0, 1, 0, 1, 0],
            [1, 0, 1, 0, 1],
            [1, 2, 1, 2, 1],
            [2, 1, 2, 1, 2],
            [2, 3, 2, 3, 2],
            [3, 2, 3, 2, 3],
            [0, 1, 1, 1, 0],
            [1, 0, 0, 0, 1],
            [1, 2, 2, 2, 1],
            [2, 1, 1, 1, 2],
            [2, 3, 3, 3, 2],
            [3, 2, 2, 2, 3],
            [0, 0, 1, 0, 0],
            [1, 1, 0, 1, 1],
            [1, 1, 2, 1, 1],
            [2, 2, 1, 2, 2],
            [2, 2, 3, 2, 2],
            [3, 3, 2, 3, 3],
            [0, 2, 0, 2, 0],
            [2, 0, 2, 0, 2],
            [1, 3, 1, 3, 1],
            [3, 1, 3, 1, 3],
            [0, 2, 2, 2, 0],
            [2, 0, 0, 0, 2],
            [1, 3, 3, 3, 1],
            [3, 1, 1, 1, 3],
            [0, 0, 2, 0, 0],
            [2, 2, 0, 2, 2],
            [1, 1, 3, 1, 1],
            [3, 3, 1, 3, 3],
            [0, 3, 0, 3, 0],
            [3, 0, 3, 0, 3],
            [0, 1, 3, 1, 0],
            [3, 2, 0, 2, 3],
            [0, 2, 3, 2, 0],
            [3, 1, 0, 1, 3],
            [1, 2, 0, 2, 1],
            [2, 3, 1, 3, 2],
            [1, 0, 2, 0, 1],
            [2, 1, 3, 1, 2],
            [2, 3, 0, 3, 2],
            [1, 0, 3, 0, 1]
        ],

        "coinType1Values":[ 250,2500,10000,50000,100000 ],
        "coinType4Values":[ 1,2,5,10,50],

        "defaultSelectLine":"50",

        "coinType1_min_bet":"250",
        "coinType1_max_bet":"100000",
        "coinType1_spin_max_bet":"2500000",

        "coinType4_min_bet":"1",
        "coinType4_max_bet":"50",
        "coinType4_spin_max_bet":"1250",

        "baseGameBonusWeights": [
            {
                "weight": 8523,
                "value": "NONE"
            },
            {
                "weight": 369,
                "value": "PEARL_DRAGON"
            },
            {
                "weight": 369,
                "value": "YELLOW_DRAGON"
            },
            {
                "weight": 369,
                "value": "BLACK_DRAGON"
            },
            {
                "weight": 370,
                "value": "LONG_DRAGON"
            }
        ],

        "wildFeatures": {
            "WALKING_WILD": {
                "wildSymbol": "WI2",
                "reelsWeights": [0, 0, 0, 0, 1],
                "positionsWeights": [25, 25, 25, 25],
                "reelSetId": "REEL_SET_2"
            },
            "RANDOM_WILD": {
                "wildSymbol": "WI3",
                "reelsWeights": [30, 30, 25, 10, 5],
                "positionsWeights": [25, 25, 25, 25],
                "reelSetId": "REEL_SET_2"
            },
            "STICKY_WILD": {
                "wildSymbol": "WI4",
                "reelsWeights": [315, 320, 315, 25, 25],
                "positionsWeights": [15, 35, 35, 15],
                "reelSetId": "REEL_SET_2"
            },
            "EXPANDING_WILD": {
                "wildSymbol": "WI5",
                "reelsWeights": [425, 425, 100, 25, 25],
                "positionsWeights": [25, 25, 25, 25],
                "reelSetId": "REEL_SET_2"
            }
        },
        "freeSpinsFeature": {
            "reelSetId": "REEL_SET_3",
            "bonusSpinReelSetId": "REEL_SET_4",
            "attackSymbols": ["HA1", "HA2", "HA3"],
            "initialOpponentHealth": 10,
            "dragonBonuses": {
                "PEARL_DRAGON": {
                    "freeSpinsCount": 20,
                    "finalBonusMultiplier": 2,
                    "occursWeights": [8675, 1325]
                },
                "YELLOW_DRAGON": {
                    "freeSpinsCount": 14,
                    "finalBonusMultiplier": 2,
                    "occursWeights": [7549, 2451]
                },
                "BLACK_DRAGON": {
                    "freeSpinsCount": 10,
                    "finalBonusMultiplier": 3,
                    "occursWeights": [80505, 19495]
                },
                "LONG_DRAGON": {
                    "freeSpinsCount": 10,
                    "finalBonusMultiplier": 4,
                    "occursWeights": [76857, 23143]
                }
            },
            "damageTable": {
                "HA1": [5, 4, 3, 2, 1],
                "HA2": [10, 8, 6, 4, 2],
                "HA3": [15, 12, 9, 6, 3]
            }
        }
    },
    "modelId": "pf003_monsoon",
    "gameSessionId": 1,
    "reelStripPositions": [
        61,
        30,
        66,
        66,
        9
    ],
    "slotWindow": [
        [
            "LV3",
            "LV3",
            "LV3",
            "LV5"
        ],
        [
            "LV3",
            "LV1",
            "HV3",
            "HV3"
        ],
        [
            "HV3",
            "LV4",
            "LV4",
            "LV4"
        ],
        [
            "LV1",
            "LV1",
            "LV1",
            "LV1"
        ],
        [
            "HV3",
            "HV3",
            "LV4",
            "FS1"
        ]
    ],
    "gold_balance": 2008650000,
    "sweeps_balance": 206529343,
    "sweeps_deposit_balance": 200001395,
    "sweeps_promo_balance": 0,
    "sweeps_win_balance": 6527948,
    "sweeps_available_balance": 6527948,
    "serverTime": "2019-03-26 12:12:00"
};
