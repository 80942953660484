import Vue, { PropType } from "vue";
import { FeatureColour } from "../../../questOfThe3Lamps/model/src/operations/shared";

export default Vue.component("ReelSymbol", {
  props: {
    position: Number,
    colIndex: Number,
    rowIndex: Number,
    symbolName: String,
    spinType: String as PropType<"FREE_SPINS" | "BASE">,
    isWinCell: Boolean,
    colour: String as PropType<FeatureColour | undefined>,
  },
  computed: {
    isScatter(): boolean {
      return this.symbolName === "SCAT";
    },
    isBonus(): boolean {
      return this.symbolName === "BONUS";
    },
    colour(): FeatureColour | undefined {
      return this.colour;
    },
  },
});